<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container">
      @if (isLanguagesEnabled && !isLoading()) {
        <div class="auth-language-selector-container">
          <app-language-selector
            [showFullLanguageName]="false"
            class="no-padding"
          ></app-language-selector>
        </div>
      }
      <div class="text-center">
        <div class="title">
          <h1 i18n>New password</h1>
        </div>

        <div class="subtitle" i18n>Set up your new password below.</div>

        @if (isLoading()) {
          <app-loader containerClass="no-margin"></app-loader>
        } @else {
          <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="flex-column">
            <app-input-password
              [label]="translations.passwordLabel"
              formControlName="password"
              showRequirementsTooltip
              autofocus
              autocomplete="new-password"
            ></app-input-password>

            <app-input-password
              [label]="translations.confirmPasswordLabel"
              formControlName="confirmPassword"
              class="no-padding mt-neg-12"
              autocomplete="new-password"
            ></app-input-password>
            <app-form-hint
              [errors]="formGroup.errors"
              [showError]="hasGroupValidationErrors"
            ></app-form-hint>

            <div class="submit-button">
              <app-button
                [disabled]="isSubmitButtonDisabled"
                class="fresh-green"
                accessibilityRole="submit"
                size="large"
                i18n
              >
                Set new password
              </app-button>
            </div>
          </form>

          <div class="switch-mode-container">
            <ng-container i18n>Back to</ng-container>
            <a [routerLink]="'/' + routingEnum.LOGIN" i18n>Login</a>
          </div>
        }
      </div>
    </div>
  </div>
</div>
