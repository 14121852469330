<form [formGroup]="form" (ngSubmit)="submit()">
  <h2 mat-dialog-title>
    @if (isEditing()) {
      <ng-container i18n>Edit risk mitigation</ng-container>
    } @else {
      <ng-container i18n>Add risk mitigation</ng-container>
    }
  </h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input formControlName="mitigation" [label]="translations.mitigationLabel" />

      <div class="form-field-item">
        <app-rich-text-editor
          [label]="translations.descriptionLabel"
          [placeholder]="translations.descriptionPh"
          [control]="form.controls.description"
          [isOptional]="true"
        />
      </div>

      @if (form.contains("saveToList")) {
        <app-checkbox formControlName="saveToList" [label]="translations.saveToListLabel" />
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="close()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
