import { Inject, Injectable } from "@angular/core";

import { CommonConstants } from "@shared/constants";
import { ConnectRequestStatusEnum } from "@shared/enums";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import { ApiService } from "./api.service";
import {
  IConfig,
  ICreateOutboundConnectPayload,
  IInboundConnectRequest,
  IOutboundConnectRequest,
  IPageableContent,
} from "../../interfaces";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ConnectRequestsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public get baseUrl(): string {
    return `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/connect-requests`;
  }

  public getAllOutbound = async (
    status?: ConnectRequestStatusEnum,
    invitedOrgUri?: string,
    connectionUri?: string,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
  ): Promise<IPageableContent<IOutboundConnectRequest>> => {
    const url = `${this.baseUrl}/outbound?${FormUtils.addUrlParams({ status, invitedOrgUri, connectionUri, size, page })}`;

    return await this.apiService.get<IPageableContent<IOutboundConnectRequest>>(url).toPromise();
  };

  public getAllInbound = async (
    status?: ConnectRequestStatusEnum,
    invitedOrgUri?: string,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
  ): Promise<IPageableContent<IInboundConnectRequest>> => {
    const url = `${this.baseUrl}/inbound?${FormUtils.addUrlParams({ status, invitedOrgUri, size, page })}`;

    return await this.apiService.get<IPageableContent<IInboundConnectRequest>>(url).toPromise();
  };

  public createOutbound = async (
    payload: ICreateOutboundConnectPayload,
  ): Promise<IOutboundConnectRequest> =>
    await this.apiService
      .post<IOutboundConnectRequest>(`${this.baseUrl}/outbound`, payload)
      .toPromise();

  public deleteOutbound = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.baseUrl}/outbound/${id}`).toPromise();

  public rejectInbound = async (id: string): Promise<void> =>
    await this.apiService.post<void>(`${this.baseUrl}/inbound/${id}/reject`).toPromise();

  public acceptInbound = async (id: string, payload: { connectionUri: string }): Promise<void> =>
    await this.apiService.post<void>(`${this.baseUrl}/inbound/${id}/accept`, payload).toPromise();
}
