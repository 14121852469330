import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { ISetting } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-settings-card",
  templateUrl: "./settings-card.component.html",
  styleUrl: "./settings-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCardComponent {
  @Input() setting: ISetting;

  @Output() pressed: EventEmitter<void> = new EventEmitter();

  onClick(): void {
    if (this.pressed.observed) {
      this.pressed.emit();
    }
  }
}
