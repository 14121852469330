import { ChangeDetectionStrategy, Component } from "@angular/core";

import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";

@Component({
  standalone: false,
  templateUrl: "./settings-sent-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSentInformationComponent {
  public readonly routingEnum = RouteEnum;

  public readonly translations = {
    title: $localize`Sent information`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };
}
