<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    <ng-container i18n>Share</ng-container>
    <ng-container>&nbsp;</ng-container>
    {{ data.shareTargetType.toLowerCase() }}
  </h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content [formGroup]="formGroup">
      <app-input
        hideOptionalHint
        [autofocus]="true"
        [label]="translations.emailLabel"
        formControlName="email"
        hintClass="info-hint"
        [hint]="translations.sharedHint"
        type="email"
      ></app-input>
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>share</mat-icon>

          <ng-container i18n>Share</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
