<div class="container-flex-space-between">
  <div class="left-content">
    <div
      [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
      class="name bold"
      [tp]="riskMitigation.mitigation"
      [tpDelay]="500"
      tpPlacement="top-start"
    >
      {{ riskMitigation.mitigation }}
    </div>
    <div class="description">{{ riskMitigation.description | stripHtml }}</div>
  </div>

  @if (type === cardContentTypeEnum.ADD) {
    <app-button size="medium" type="icon">
      <mat-icon icon-medium-18>add</mat-icon>
    </app-button>
  }
</div>
