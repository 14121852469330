<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routing.ADMIN"
    [title]="translations.title"
    [backText]="translations.titleBackText"
  />
  @if (canAddModifyEntities) {
    <app-button-group>
      <app-button (pressed)="openDialog()">
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add new</ng-container>
      </app-button>
    </app-button-group>
  }
</div>

<app-risk-mitigations-table
  #riskMitigationsTable
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
  [recordState]="recordState.ACTIVE"
  [areButtonsEnabled]="canAddModifyEntities"
  (edit)="openDialog($event)"
/>
