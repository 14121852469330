import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  templateUrl: "./deliveries.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveriesComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Deliveries`,
  };

  constructor(
    private router: RouterService,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd = (): void => {
    this.router.navigate(this.router.getDeliveryLink());
  };
}
