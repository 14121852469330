import { Pipe, PipeTransform } from "@angular/core";

import { round } from "lodash";

import { IBaseUnit, ICustomUnitOfMeasurement } from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";

@Pipe({
  standalone: false,
  name: "unitConversion",
})
export class UnitConversionPipe implements PipeTransform {
  constructor(private authenticationService: AuthenticationService) {}

  transform(
    value: string,
    customUnit: ICustomUnitOfMeasurement | IBaseUnit,
    systemUnit: IBaseUnit,
    returnRawValue: boolean = false,
    inverse: boolean = true,
    omitUnitSymbol: boolean = false,
    displayScale: number = 10,
  ): string {
    if (this.authenticationService.isSharedUser() || !value || !customUnit || !systemUnit) {
      return "";
    }

    const conversionFactor = customUnit.conversionFactor;

    if (!displayScale && displayScale !== 0) {
      displayScale = inverse ? (systemUnit.precision ?? 0) : (customUnit.displayScale ?? 0);
    }

    let convertedValue: number;

    if (inverse) {
      convertedValue = +value * conversionFactor;
    } else {
      convertedValue = +value / conversionFactor;
    }

    if (returnRawValue) {
      return `${convertedValue}`;
    }

    const rounded = round(convertedValue, displayScale);

    if (omitUnitSymbol) {
      return `${rounded}`;
    }

    return `${rounded} ${systemUnit.symbol}`;
  }
}
