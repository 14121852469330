<div class="container-space-between">
  <app-page-title
    [title]="supplyChain?.name ?? translations.title"
    [backText]="translations.titleBackText"
    [hint]="translations.titleHint"
    [goBackRoute]="routingEnum.SUPPLY_CHAINS"
    [tag]="supplyChain?.recordState === recordStateEnum.ARCHIVED ? 'Archived' : ''"
  ></app-page-title>
  @if (!isLoading() && canAddModifyEntities) {
    <app-button-group>
      <app-more-actions-buttons
        [label]="translations.editLabel"
        labelIcon="edit"
        [isDeleteEnabled]="true"
        [disabledMainButton]="supplyChain?.recordState !== recordStateEnum.ACTIVE"
        (clickLabel)="onEdit()"
        [element]="supplyChain"
        [service]="supplyChainsService"
        [canSend]="isCrossOrgSharingEnabled && isCrossOrgSharingSupplyChainEnabled"
        (send)="sendSupplyChain()"
        (reloadData)="loadData($event)"
      ></app-more-actions-buttons>
    </app-button-group>
  }
</div>
@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div [class.overflow-y-hidden]="selectedTabIndex === 0">
    <mat-tab-group
      animationDuration="0ms"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="onTabIndexChange($event)"
      mat-stretch-tabs="false"
      mat-align-tabs="start"
    >
      <mat-tab>
        <ng-template mat-tab-label i18n>Graph</ng-template>
        @if (supplyChain?.description) {
          <p class="supply-chain-description dark-gray no-margin-top">
            {{ supplyChain.description }}
          </p>
        }
        @if (selectedTabIndex === 0 && supplyChainHeight) {
          <app-supply-chain-flow-chart
            containerClass="flow-chart-container-class"
            [locations]="locations"
            [allDocumentTypes]="allDocumentTypes"
            [locationsRulesetsRecords]="locationsRulesetsRecords"
            [links]="locationLinks"
            [height]="supplyChainHeight"
          ></app-supply-chain-flow-chart>
        }
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label i18n>Map</ng-template>
        @if (selectedTabIndex === 1 && geographicalSupplyChainMap) {
          <app-supply-chain-map [supplyChain]="geographicalSupplyChainMap"></app-supply-chain-map>
        }
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container i18n>Locations</ng-container>

          <app-indicator [value]="locationsTable.totalElements()"></app-indicator>
        </ng-template>
        <app-locations-table
          #locationsTable
          [locationsIds]="locationsIds"
          [columns]="[
            'recordState',
            'name',
            'organisationName',
            'types',
            'address.countryName',
            'tags',
            'riskAssessmentReport.completedDate',
            'riskAssessmentReport.residualRisk',
          ]"
        ></app-locations-table>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label i18n>Certificates</ng-template>
        <app-supply-chain-locations
          [locations]="locations"
          [allStandards]="allStandards"
          [isShowCertificates]="true"
          [areCertificatesButtonsEnabled]="true"
        ></app-supply-chain-locations>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label i18n>Documents</ng-template>
        <app-supply-chain-locations
          [locations]="locations"
          [allDocumentTypes]="allDocumentTypes"
          [isShowDocuments]="true"
        ></app-supply-chain-locations>
      </mat-tab>
      @if (isRegularUser) {
        <mat-tab>
          <ng-template mat-tab-label>
            <ng-container i18n>Comments</ng-container>
            <app-indicator [value]="commentsComponent.totalElements()"></app-indicator>
          </ng-template>
          <app-comments
            #commentsComponent
            [entityId]="supplyChain?.id"
            [entityType]="entityTypeEnum.SUPPLY_CHAINS"
            [isVisible]="selectedTabIndex === 5"
          ></app-comments>
        </mat-tab>
      }
    </mat-tab-group>
  </div>
}
