import { KeyValue } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Output,
  signal,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { AgGridAngular } from "ag-grid-angular";

import { ConfirmDialogComponent } from "@components/shared/confirm-dialog/confirm-dialog.component";
import { BatchActionTypeEnum, ConfirmDialogResponseEnum } from "@shared/enums";
import { IIdName } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { AuthenticationService } from "@shared/services";
import { BatchActionService } from "@shared/services/batch-action.service";

import { TableUtils } from "./table.utils";

@Component({
  standalone: false,
  selector: "app-table-batch-actions",
  templateUrl: "./table-batch-actions.component.html",
  styleUrls: ["./table-batch-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableBatchActionsComponent implements OnInit {
  public grid = input<AgGridAngular>();

  public batchActionSettings = input<BatchActionModel.IBatchActionSettings>();

  @Output()
  public clearSelectedRows: EventEmitter<void> = new EventEmitter();

  @Output()
  public batchActionStarted: EventEmitter<void> = new EventEmitter();

  public selectionText = signal<string>(undefined);

  public selectedVisibleRows = signal<any[]>([]);

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private batchActionService: BatchActionService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.onSelectionChanged();
  }

  public onSelectionChanged(): void {
    const selectedVisibleRows = this.grid()
      .api.getSelectedNodes()
      .filter((n) => n.displayed)
      .map((n) => n.data);

    this.selectedVisibleRows.set(selectedVisibleRows);

    const selectedVisibleRowsCount = selectedVisibleRows.length;

    if (!selectedVisibleRowsCount) {
      return;
    }

    if (this.areAllVisibleRowsSelected(selectedVisibleRowsCount)) {
      this.selectionText.set(
        $localize`All (${selectedVisibleRowsCount}:selectedVisibleRowsCount:) selected`,
      );
    } else {
      this.selectionText.set(
        $localize`${selectedVisibleRowsCount}:selectedVisibleRowsCount: selected`,
      );
    }
  }

  private getConfirmationContentHtml = (
    actionDetails: BatchActionModel.IBatchActionDetails,
  ): string => {
    const recordsCount = this.selectedVisibleRows().length;

    if (actionDetails.messages.confirmation) {
      return actionDetails.messages.confirmation(recordsCount);
    }

    return $localize`Are you sure you want to ${actionDetails.title.toLowerCase()}:actionName: the <b>${recordsCount}:recordsCount:</b> selected records?`;
  };

  public onBatchAction = (
    action: KeyValue<BatchActionTypeEnum, BatchActionModel.IBatchActionDetails>,
  ): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`${action.value.title}:actionName: confirmation`,
        contentHTML: this.getConfirmationContentHtml(action.value),
        confirmButtonText: action.value.title,
        confirmButtonIcon: action.value.icon,
        confirmButtonColor: action.value.confirmButtonColor,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result !== ConfirmDialogResponseEnum.CONFIRM) {
        return;
      }

      const records: IIdName[] = this.selectedVisibleRows().map((row) => ({
        id: row.id,
        name: row[this.batchActionSettings().recordLabelProperty ?? "name"],
      }));

      if (action.key === BatchActionTypeEnum.DOWNLOAD_DOCUMENT) {
        records.forEach((record) => action.value.serviceFn({ id: record.id, name: record.name }));
      } else {
        this.batchActionStarted.emit();
        this.batchActionService.performBatchAction(records, action.value);
      }
    });
  };

  public keyValuePipeKeepOrder = (
    _a: KeyValue<BatchActionTypeEnum, BatchActionModel.IBatchActionDetails>,
    _b: KeyValue<BatchActionTypeEnum, BatchActionModel.IBatchActionDetails>,
  ): number => {
    return 0;
  };

  private areAllVisibleRowsSelected = (selectedVisibleRowsCount: number): boolean => {
    const visibleRowsCount =
      this.geLastElementShownNumber() - this.getFirstElementShownNumber() + 1;

    return selectedVisibleRowsCount === visibleRowsCount;
  };

  private getFirstElementShownNumber = (): number =>
    TableUtils.getFirstElementShownNumber(this.grid().api);

  private geLastElementShownNumber = (): number =>
    TableUtils.geLastElementShownNumber(this.grid().api);
}
