<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
    <mat-expansion-panel-header class="section-header">
      <mat-panel-title class="section-title">
        <app-form-link [link]="getOrganisationLink()" [styledLink]="true" [maintainFontSize]="true">
          <div class="organisation-name">{{ organisation().name }}</div>
        </app-form-link>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <h4 i18n>Certificates</h4>
    <app-certificates-table
      [certificates]="organisation().certificates"
      [isRecordStateFilterEnabled]="false"
      [isSearchEnabled]="false"
      [isPaginatorEnabled]="false"
      [columns]="[
        'number',
        'standard.name',
        'standardType.fullName',
        'issuanceDate',
        'validFromDate',
        'validToDate',
      ]"
    ></app-certificates-table>

    <h4 i18n>Documents</h4>
    <app-document-types-table
      [documentTypes]="documentTypes"
      [areButtonsEnabled]="false"
      [isRecordStateFilterEnabled]="false"
      [isPaginatorEnabled]="false"
      [isSearchEnabled]="false"
      [columns]="['documentType', 'documentNames']"
    ></app-document-types-table>
  </mat-expansion-panel>
</mat-accordion>
