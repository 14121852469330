<div class="form-page-container">
  <div class="container-flex-space-between">
    <app-page-title [title]="title"></app-page-title>

    @if (!isLoading()) {
      <app-button-group>
        @if (isSaving()) {
          <app-button [disabled]="true">
            <ng-container i18n>Saving</ng-container>
            ...
          </app-button>
        }

        @if (canSave) {
          <app-button (pressed)="onSave()" i18n>Save</app-button>
        }
      </app-button-group>
    }
  </div>

  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <div class="element-content element-content-padded no-bottom-margin">
      <mat-tab-group animationDuration="0ms" class="full-width">
        <mat-tab>
          <ng-template mat-tab-label>Details</ng-template>
          <div [formGroup]="formGroup" class="row-container">
            @if (locationWithoutOrgAttachment) {
              <div class="row">
                <div class="item red">
                  ERROR: Location {{ locationWithoutOrgAttachment.name }} does not have an attached
                  organisation.
                </div>
                <div
                  class="item blue-link"
                  (click)="onClickLocation(locationWithoutOrgAttachment)"
                  (keyup)="onClickLocation(locationWithoutOrgAttachment)"
                  tabindex="0"
                  i18n
                >
                  See location
                </div>
              </div>
            }
            <div class="row row-2">
              <div class="item">
                <app-input
                  formControlName="referenceNumber"
                  [label]="translations.referenceNumberLabel"
                  [placeholder]="translations.referenceNumberPh"
                ></app-input>
              </div>

              <div class="item">
                <app-form-label [label]="translations.activityLabel"></app-form-label>
                <app-radio-button-group>
                  <app-radio-button
                    (changeEvent)="onActivityChange(constants.ActivityEnum.DOMESTIC)"
                    [checked]="
                      this.formGroup.controls['activity'].value === constants.ActivityEnum.DOMESTIC
                    "
                    [disabled]="true"
                    [label]="translations.domesticLabel"
                  ></app-radio-button>
                  &nbsp;
                  <app-radio-button
                    (changeEvent)="onActivityChange(constants.ActivityEnum.IMPORT)"
                    [checked]="
                      this.formGroup.controls['activity'].value === constants.ActivityEnum.IMPORT
                    "
                    [label]="translations.importLabel"
                  ></app-radio-button>
                  &nbsp;
                  <app-radio-button
                    (changeEvent)="onActivityChange(constants.ActivityEnum.EXPORT)"
                    [checked]="
                      this.formGroup.controls['activity'].value === constants.ActivityEnum.EXPORT
                    "
                    [label]="translations.exportLabel"
                  ></app-radio-button>
                </app-radio-button-group>
              </div>
            </div>

            <div class="row row-2">
              <div>
                <app-form-label [label]="translations.operatorLabel"></app-form-label>
              </div>
              <div>
                <app-form-label [label]="translations.placeOfActivityLabel"></app-form-label>
              </div>
            </div>
            <div class="row row-operator-activity">
              <div class="item operator-trader-box">
                {{ constants.operatorSectionText }}
              </div>
              <div class="item">
                <app-input-select
                  [options]="countryOptions"
                  formControlName="activityCountry"
                  [label]="
                    isExportActivity()
                      ? translations.countryOfExitLabel
                      : translations.countryOfActivityLabel
                  "
                  [prefixTemplate]="flag"
                ></app-input-select>
              </div>
              <div class="item">
                <app-input
                  formControlName="activityCountryIsoCode"
                  [label]="translations.isoCodeLabel"
                ></app-input>
              </div>
            </div>
            @if (!isExportActivity()) {
              <div class="row row-1-half-1-half">
                <div class="item gc-3">
                  <app-input-select
                    [options]="countryOptions"
                    formControlName="entryCountry"
                    [label]="translations.countryOfEntryLabel"
                    [prefixTemplate]="flag"
                  ></app-input-select>
                </div>
                <div class="item gc-4">
                  <app-input
                    formControlName="entryCountryIsoCode"
                    [label]="translations.isoCodeLabel"
                  ></app-input>
                </div>
              </div>
            }

            <div class="row row-2"></div>
            <div>
              <app-form-label [label]="translations.communicationLabel"></app-form-label>
            </div>
            <div class="row row-1">
              <div class="item">
                <app-editable-div
                  class="bordered"
                  [placeholder]="translations.messagePh"
                  formControlName="competentAuthorityMessage"
                  [isTextarea]="true"
                ></app-editable-div>
              </div>
            </div>

            @if (!productsWithErrors.length) {
              <div><app-form-label [label]="translations.totalLabels"></app-form-label></div>
              <div class="row row-3">
                <div class="item">
                  <app-input
                    type="number"
                    formControlName="totalNetMass"
                    [label]="translations.totalNetMassLabel"
                    placeholder=""
                  ></app-input>
                </div>
                <div class="item">
                  <app-input
                    type="number"
                    formControlName="totalVolume"
                    [label]="translations.totalVolumeLabel"
                    placeholder=""
                  ></app-input>
                </div>
                <div class="item">
                  <app-input
                    formControlName="totalArea"
                    tooltipIcon="alert-triangle"
                    [isSvgIcon]="true"
                    iconClass="warn-icon"
                    [label]="translations.totalAreaLabel"
                    [tooltip]="this.totalAreaError ? translations.totalAreaTooltip : null"
                    placeholder=""
                  ></app-input>
                </div>
              </div>
            }

            <div>
              <app-form-label [label]="translations.commodityOrProductsLabel"></app-form-label>
            </div>
            @if (productsWithErrors.length) {
              <div class="missing-required-custom-fields">
                <div class="item">
                  <ng-container i18n>
                    Cannot display this section, as some of the products in this delivery's supply
                    chain do not have a filled in "HS Code" field or have different values in the
                    "Description" field
                  </ng-container>
                  :
                  @for (product of productsWithErrors; track product.id) {
                    <ul>
                      <li
                        class="blue-link"
                        (keyup)="onClickProduct(product)"
                        (click)="onClickProduct(product)"
                        tabindex="0"
                      >
                        {{ product.name }}
                      </li>
                    </ul>
                  }
                  <ng-container i18n>
                    Fill in the values for those fields and re-generate the report
                  </ng-container>
                  .
                </div>
              </div>
            } @else {
              <div class="commodities" formArrayName="commodities">
                <div
                  *ngFor="let control of commodityFormControls; let i = index"
                  [formGroupName]="i"
                >
                  <app-reports-eudr-commodity-info
                    [formGroup]="control"
                    [index]="i"
                  ></app-reports-eudr-commodity-info>
                </div>
              </div>
            }
          </div>
        </mat-tab>
        <mat-tab [disabled]="true">
          <ng-template mat-tab-label i18n>References</ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  }
</div>

<ng-template #flag let-option>
  <app-flag [country]="option.value"></app-flag>
</ng-template>
