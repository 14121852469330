<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="isPreviousHistoryAdmin ? routingEnum.ADMIN : undefined"
  ></app-page-title>

  <app-button-group [visible]="canAddModifyEntities">
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<app-materials-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [areButtonsEnabled]="canAddModifyEntities"
  [isBatchActionsEnabled]="canAddModifyEntities"
></app-materials-table>
