import { AfterViewInit, ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";
import { CrossOrgShareDataTypeEnum, TableEnum } from "src/app/shared/enums";
import { ProcessesService } from "src/app/shared/services";

import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { CommonConstants } from "@shared/constants";
import { IOutboundShare } from "@shared/interfaces";
import {
  NotificationService,
  ConnectionsService,
  RecordSharingService,
  LocationsService,
  SupplyChainsService,
  DeliveriesService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

@Component({
  standalone: false,
  selector: "app-settings-sent-information-table",
  templateUrl: "./settings-sent-information-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSentInformationTableComponent implements AfterViewInit {
  public readonly table = TableEnum.ADMIN_SENT_INFORMATION;

  public isLoading = signal(true);

  public rowData: any[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private recordSharingService: RecordSharingService,
    private notificationService: NotificationService,
    private connectionsService: ConnectionsService,
    private locationsService: LocationsService,
    private supplyChainService: SupplyChainsService,
    private deliveriesService: DeliveriesService,
    private processesService: ProcessesService,
    private routerService: RouterService,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: $localize`Recipient`,
        field: "receiver",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "receiverId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      { headerName: $localize`Record type`, field: "recordType" },
      {
        headerName: $localize`ID / Name`,
        field: "recordName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: (params: any) => {
          const routerConfig = InboundSharedRecordUtils.getLocalRouteConfig(
            params.data.recordId,
            params.data.crossOrgShareDataType,
            this.routerService,
          );

          return routerConfig;
        },
      },
      ColumnUtils.dateColumn({
        headerName: $localize`Sent on`,
        field: "createdTime",
        cellRendererParams: {
          dateFormat: CommonConstants.DATE_TIME_FORMAT,
        },
        sort: "desc",
      }),
    ];

    this.columnDefs.set(columnDefs);
  };

  private getParsedRowData = async (outboundShare: IOutboundShare): Promise<any> => {
    const crossOrgShareDataType = InboundSharedRecordUtils.getSharedDataType(
      outboundShare.rootRecordUri,
    );

    const receiverId = CommonUtils.getUriId(outboundShare.receiverUri);
    const recordId = CommonUtils.getUriId(outboundShare.rootRecordUri);

    let receiver = undefined;
    let record = undefined;

    await Promise.all([
      (receiver = await this.connectionsService.get(receiverId)),
      (record = await this.getRecord(recordId, crossOrgShareDataType)),
    ]);

    const recordType = CommonUtils.singlifyEntity(
      CommonUtils.capitaliseFirstLetter(outboundShare.rootRecordUri.split("/")[3]),
    );

    let recordName: string;

    switch (crossOrgShareDataType) {
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        recordName = record.deliveryId;
        break;
      case CrossOrgShareDataTypeEnum.PROCESSES:
        recordName = record.processId;
        break;
      default:
        recordName = record.name;
        break;
    }

    return {
      receiverId,
      receiver: receiver.name,
      recordType,
      crossOrgShareDataType,
      recordId,
      recordName,
      createdTime: outboundShare.createdTime,
    };
  };

  private getRecord = async (
    recordId: string,
    outboundShareType: CrossOrgShareDataTypeEnum,
  ): Promise<any> => {
    let record = undefined;

    switch (outboundShareType) {
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        record = await this.locationsService.get(recordId);
        break;
      case CrossOrgShareDataTypeEnum.SUPPLY_CHAINS:
        record = await this.supplyChainService.get(recordId);
        break;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        record = await this.deliveriesService.get(recordId);
        break;
      case CrossOrgShareDataTypeEnum.PROCESSES:
        record = await this.processesService.get(recordId);
        break;
      default:
        throw new Error(
          $localize`Unsupported outboundShareType: ${outboundShareType}:outboundShareType:`,
        );
    }

    return record;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const outboundShares = await this.recordSharingService.getAllOutboundShares();
      const rowData = [];

      const promises = outboundShares.map(async (outboundShare) => {
        rowData.push(await this.getParsedRowData(outboundShare));
      });

      await Promise.all(promises);
      this.rowData = rowData;

      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
