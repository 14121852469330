import { Pipe, PipeTransform } from "@angular/core";

import { CommonUtils } from "@shared/utils";

@Pipe({
  standalone: false,
  name: "stripHtml",
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: string) {
    return CommonUtils.stripHtml(value);
  }
}
