<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <app-input enableViewMode formControlName="deliveryId" label="ID"></app-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [options]="fromLocationOptions()"
        [viewModeTemplate]="locationViewTemplate()"
        enableViewMode
        formControlName="from"
        [label]="translations.fromLabel"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [options]="toLocationOptions()"
        [viewModeTemplate]="locationViewTemplate()"
        enableViewMode
        formControlName="to"
        [label]="translations.toLabel"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [options]="statusOptions()"
        enableViewMode
        formControlName="status"
        [label]="translations.statusLabel"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        [maxDate]="formGroup.controls['delivered'].value"
        enableViewMode
        formControlName="sent"
        [label]="translations.sentLabel"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        [minDate]="formGroup.controls['sent'].value"
        enableViewMode
        formControlName="delivered"
        [label]="translations.deliveredLabel"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-chips
        (tagPressed)="traderTagPressed.emit($event)"
        [isTagClickable]="true"
        [emitRawValue]="true"
        [options]="organisationsOptions()"
        enableViewMode
        formControlName="agents"
        [label]="translations.agentsLabel"
        [tooltip]="translations.agentsTooltip"
      ></app-input-chips>
    </div>
  </div>
</div>
