<div [ngClass]="[size]" class="form-field-item no-padding">
  <app-form-input
    (clearEvent)="handleClear()"
    (keyUpEvent)="handleSearch($event)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [searchQuery]="searchQuery"
    [showClear]="showClearButton"
    class="input-search"
    inputType="search"
  >
    <mat-icon class="icon-20" [class.disabled]="disabled" prefix-icon>search</mat-icon>
  </app-form-input>
</div>
