import { inject, Injectable, signal } from "@angular/core";

import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { TagsColorEnum } from "@shared/enums";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { NotificationService } from "@shared/services";
import { EudrDdsService } from "@shared/services/api/eudr-dds.service";

interface ITotals {
  netMass: number;
  volume: number;
  areaInHa: number;
}

@Injectable({
  providedIn: "root",
})
export class EudrDdsPageService {
  private eudrDdsService = inject(EudrDdsService);

  private notificationService = inject(NotificationService);

  public totals = signal<ITotals>({
    netMass: 0,
    volume: 0,
    areaInHa: 0,
  });

  public addArea(area: number): void {
    this.totals().areaInHa += area;
  }

  public addWeight(weight: number): void {
    this.totals().netMass += weight;
  }

  public addVolume(volume: number): void {
    this.totals().volume += volume;
  }

  public getStatusTagColor(status: EudrDds.StatusEnum): TagsColorEnum {
    switch (status) {
      case EudrDds.StatusEnum.AVAILABLE:
        return TagsColorEnum.GREEN;
      case EudrDds.StatusEnum.PENDING:
        return TagsColorEnum.BLUE;
      case EudrDds.StatusEnum.REJECTED:
        return TagsColorEnum.RED;
      case EudrDds.StatusEnum.SUBMITTED:
        return TagsColorEnum.YELLOW;
      default:
        return null;
    }
  }

  public async saveReferences(
    statement: EudrDds.IDetails,
    references: EudrDds.IAssociatedStatement[],
  ): Promise<boolean> {
    const payload: EudrDds.IPayload = {
      deliveryUri: statement.deliveryUri,
      operatorType: statement.operatorType,
      statement: {
        ...statement.statement,
        associatedStatements: references,
      },
    };

    try {
      await this.eudrDdsService.createOrUpdate(payload, statement.id);

      this.notificationService.showSuccess(ReportsEudrModel.ddsSavedSuccessMessage);

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }
}
