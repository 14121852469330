<div [hidden]="isLoading()" class="map-outer-container supply-chain-map" #mapContainer>
  @if (displayZoomPromptOverlay) {
    <div (click)="onClickZoomPromptOverlay($event)" class="zoom-prompt-overlay" i18n>
      Click on the map to zoom.
    </div>
  }
  <div class="legend">
    <div class="item">
      <mat-icon svgIcon="geo-pinpoint-bordered"></mat-icon>
      <ng-container i18n>Location GPS</ng-container>
    </div>
    <div class="item">
      <mat-icon svgIcon="geo-pinpoint-dashed"></mat-icon>
      <ng-container i18n>Estimated</ng-container>
    </div>
  </div>
  <div class="zoom-buttons">
    <button
      (click)="onZoomInClick()"
      [tpDelay]="500"
      class="zoom-button in"
      [tp]="translations.zoomInTp"
    >
      <mat-icon class="zoom-icon icon-16">add</mat-icon>
    </button>
    <button
      (click)="onZoomOutClick()"
      [tpDelay]="500"
      class="zoom-button out"
      [tp]="translations.zoomOutTp"
      tpPlacement="bottom"
    >
      <mat-icon class="zoom-icon icon-16">remove</mat-icon>
    </button>
    <button
      (click)="onResetZoomClick()"
      [tpDelay]="500"
      class="zoom-button reset-zoom"
      [tp]="translations.resetZoomTp"
      tpPlacement="bottom"
    >
      <mat-icon class="zoom-icon icon-18 rotate-horizontal">sync</mat-icon>
    </button>
  </div>
  <google-map
    (idle)="onMapIdle()"
    (mapClick)="onMapClick()"
    (maptypeidChanged)="onMapTypeIdChange()"
    (zoomChanged)="onZoomChange()"
    [options]="mapOptions"
    width="100%"
  >
    @for (vertices of verticesList; track vertices) {
      <map-polyline [path]="vertices" [options]="polylineOptions" />
    }
    <deprecated-map-marker-clusterer
      #clusterer="mapMarkerClusterer"
      (clusterClick)="onClusterClick($event)"
      (clusteringend)="onClusteringEnd(clusterer)"
      [gridSize]="18"
      [imagePath]="markerClustererImagePath"
      [styles]="clusterStyles"
      [zoomOnClick]="false"
    >
      @for (marker of individualMarkers; track marker) {
        <map-marker
          [icon]="marker.icon"
          [position]="marker.position"
          [options]="markerOptions"
          (markerInitialized)="onMarkerInitialized($event, marker.location)"
          (mapClick)="onMarkerClick($event, marker)"
        />
      }
    </deprecated-map-marker-clusterer>
    <map-info-window [options]="infoWindowOptions">
      <div class="map-info-window">
        @for (individualMarker of selectedMarkers; track individualMarker.location.id) {
          <div class="item">
            <mat-icon class="icon" [svgIcon]="individualMarker.iconName"></mat-icon>
            <app-form-link [link]="getLocationLink(individualMarker.location)" [styledLink]="true">
              {{ individualMarker.location.name }}
            </app-form-link>
          </div>
        }
      </div>
    </map-info-window>
  </google-map>
</div>
