import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";

export namespace EudrDds {
  export interface IDetails extends IPayload {
    id: string;
    version: string;
    createdAt: string;
    updatedAt: string;
    status: StatusEnum;
    ddsIdentifier: string;
    tracesReferenceNumber: string;
    tracesVerificationNumber: string;
  }
  export interface IPayload {
    deliveryUri: string;
    operatorType: string;
    statement: IStatement;
  }

  export enum StatusEnum {
    AVAILABLE = "AVAILABLE",
    PENDING = "PENDING",
    REJECTED = "REJECTED",
    SUBMITTED = "SUBMITTED",
  }

  interface IStatement {
    internalReferenceNumber: string;
    activityType: ReportsEudrModel.ActivityEnum;
    countryOfActivity: string;
    borderCrossCountry: string;
    commodities: ICommodity[];
    comment: string;
    geoLocationConfidential: boolean;
    associatedStatements: IAssociatedStatement[];
  }

  export interface IAssociatedStatement {
    referenceNumber: string;
    verificationNumber: string;
  }

  export interface ICommodity {
    descriptors: IDescriptor;
    hsHeading: string;
    speciesInfo: ISpeciesInfo[];
    producers: IProducer[];
  }

  export interface IDescriptor {
    descriptionOfGoods: string;
    goodsMeasure: IGoodsMeasure;
  }

  interface IGoodsMeasure {
    volume: number;
    netWeight: number;
  }

  export interface ISpeciesInfo {
    commonName: string;
    scientificName: string;
  }

  export interface IProducer {
    country: string;
    name: string;
    productionPlaces: IProductionPlace[];
  }

  export interface IProductionPlace {
    name: string;
    geoData: string;
    areaInHa?: number;
  }
}
