import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  standalone: false,
  selector: "app-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrl: "./checkbox.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CheckboxComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;

  @Input({ transform: booleanAttribute }) checked: boolean;

  @Input({ transform: booleanAttribute }) disabled: boolean;

  @Output() changeEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngControl: NgControl;

  constructor(private injector: Injector) {}

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(checked: boolean): void {
    this.checked = checked;
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  onChangeEvent(checkboxChange: MatCheckboxChange): void {
    if (this.ngControl) {
      this.writeValue(checkboxChange.checked);
      this.onChange(checkboxChange.checked);
      this.onTouch();
    } else {
      this.changeEvent.emit(checkboxChange.checked);
    }
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouch: () => void = () => {};
}
