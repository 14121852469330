<div [formGroup]="formGroup" class="row-container">
  <div class="row row-1">
    <div class="item">
      <app-input
        [autofocus]="!isEditing()"
        enableViewMode
        formControlName="itemId"
        [hint]="translations.itemIdHint"
        label="ID"
      ></app-input>
    </div>
    <div class="item">
      <app-input-select
        [options]="productOptions()"
        [viewModeTemplate]="productViewTemplate"
        enableViewMode
        formControlName="product"
        [label]="translations.productLabel"
      >
        <ng-container action>
          <app-button
            (pressed)="onAddProductClicked()"
            class="icon-add-circle"
            [tp]="translations.addNewProductTp"
            type="icon"
          >
            <mat-icon icon-small>add</mat-icon>
          </app-button>
        </ng-container>
      </app-input-select>
    </div>
  </div>
  <div class="item">
    @if (isOldMaterialsEnabled()) {
      <app-input-chips
        [options]="materialOptions()"
        [tooltip]="formGroup.controls['product'].value ? '' : translations.materialOptionsTooltip"
        enableViewMode
        formControlName="materials"
        [label]="translations.materialsLabel"
        [isTagClickable]="!overlay.editMode()"
        (tagPressed)="openMaterialOverlay($event)"
      >
        @if (formGroup.controls["product"].value) {
          <ng-container action [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
        }
      </app-input-chips>
    } @else {
      <div class="tag-group-container">
        <div class="container-flex-left">
          <app-form-label
            [label]="translations.materialsLabel"
            [tooltip]="formGroup.controls['product'].value ? '' : translations.materialsTooltip"
          ></app-form-label>
          @if (formGroup.controls["product"]?.value && overlay.editMode()) {
            <ng-container [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
          }
        </div>
        @if (formGroup.controls["materials"].value?.length) {
          <app-tag-group
            [tags]="formGroup.controls['materials'].value"
            [isTagClickable]="!overlay.editMode()"
            (tagPressed)="openMaterialOverlay($event)"
          ></app-tag-group>
        } @else {
          -
        }
      </div>
    }
  </div>
  @if (overlay.editMode()) {
    <div class="item">
      <app-input-select
        enableViewMode
        [options]="unitOfMeasurementOptions()"
        formControlName="unitOfMeasurement"
        [label]="translations.unitOfMeasurementLabel"
      ></app-input-select>
    </div>
  }

  <div class="item">
    <app-input
      [hint]="
        itemDefaultUnitOfMeasurement()?.id === unitOfMeasurement()?.id
          ? ''
          : (formGroup.controls['initialQuantity'].value
            | unitConversion: itemDefaultUnitOfMeasurement() : unitOfMeasurement())
      "
      [suffix]="itemDefaultUnitOfMeasurement()?.symbol"
      [viewModeTemplate]="initialQuantityViewTemplate"
      enableViewMode
      formControlName="initialQuantity"
      [label]="translations.initialQuantityLabel"
      type="number"
    ></app-input>
  </div>
  @if (element()?.id || duplicateItemId()) {
    <div class="item">
      <app-input
        enableViewMode
        [suffix]="itemDefaultUnitOfMeasurement()?.symbol"
        [hint]="
          itemDefaultUnitOfMeasurement()?.id === unitOfMeasurement()?.id
            ? ''
            : (formGroup.controls['remainingQuantity'].value
              | unitConversion: itemDefaultUnitOfMeasurement() : unitOfMeasurement())
        "
        [viewModeTemplate]="remainingQuantityViewTemplate"
        formControlName="remainingQuantity"
        [label]="translations.remainingQuantityLabel"
        type="number"
      ></app-input>
    </div>
  }
  <div class="row row-1">
    <div class="item">
      <app-input-select
        [options]="createdAtLocationOptions()"
        [viewModeTemplate]="locationViewTemplate"
        enableViewMode
        formControlName="createdAtLocation"
        [label]="translations.createdAtLocationLabel"
        [tooltip]="translations.createdAtLocationTooltip"
      ></app-input-select>
    </div>
  </div>
  <div class="item">
    @if (datesType() === dateTypeEnum.EXACT) {
      <app-datepicker
        #datePicker
        enableViewMode
        class="no-padding"
        [label]="translations.createdFromLabel"
        formControlName="createdFrom"
      ></app-datepicker>
    } @else if (datesType() === dateTypeEnum.RANGE) {
      <app-daterangepicker
        #datePicker
        enableViewMode
        class="no-padding"
        [label]="translations.createdFromLabel"
        formControlName="createdRange"
      ></app-daterangepicker>
    }

    @if (overlay.editMode()) {
      <div class="checkbox-row mb-14">
        <app-checkbox
          formControlName="isRangeCreationDate"
          [label]="translations.isRangeCreationDateLabel"
        ></app-checkbox>
      </div>
    }
  </div>
  <div class="row row-1">
    <div class="item">
      <app-input-select
        [options]="currentLocationOptions()"
        [viewModeTemplate]="locationViewTemplate"
        class="no-padding"
        enableViewMode
        formControlName="currentLocation"
        [label]="translations.currentLocationLabel"
        [tooltip]="translations.currentLocationTooltip"
      ></app-input-select>
    </div>

    @if (overlay.editMode()) {
      <div class="checkbox-row">
        <app-checkbox
          formControlName="isSameAsCreatedAtLocation"
          [label]="translations.isSameAsCreatedAtLocationLabel"
        ></app-checkbox>
      </div>
    }
  </div>
</div>
<ng-template #productViewTemplate let-label="label" let-option let-searchText="searchText">
  @if (authenticationService.canGoToAdminSection()) {
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <app-form-link [link]="getProductLink(option.value)" [styledLink]="true">
        {{ option.label }}
      </app-form-link>
    </div>
  } @else {
    <app-form-input-readonly [value]="searchText" [label]="label"></app-form-input-readonly>
  }
</ng-template>

<ng-template #locationViewTemplate let-label="label" let-option>
  <div class="form-field-item">
    <app-form-label [label]="label"></app-form-label>
    <div class="form-field-input-readonly">
      @if (option.label && option.value) {
        <app-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
          {{ option.label }}
        </app-form-link>
      } @else {
        @if (isSharedUser()) {
          <app-not-shared-data></app-not-shared-data>
        } @else {
          <app-loader containerClass="size-small"></app-loader>
        }
      }
    </div>
  </div>
</ng-template>
<ng-template #remainingQuantityViewTemplate let-data>
  @if (unitOfMeasurement()) {
    @if (hasDifferentDefaultUnit() && defaultUnitOfMeasurement()) {
      <app-form-input-readonly
        [label]="data.label"
        [value]="remainingQuantityFormatted()"
      ></app-form-input-readonly>
    } @else {
      @if (element().remainingQuantity) {
        <app-form-input-readonly
          [label]="data.label"
          [value]="
            (element().remainingQuantity ?? '0'
              | number: '1.0-' + unitOfMeasurement()?.precision || '0') +
            ' ' +
            unitOfMeasurement()?.symbol
          "
        ></app-form-input-readonly>
      }
    }
  } @else if (isSharedUser()) {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>
<ng-template #addMaterialBtnTemplate>
  <app-button
    (pressed)="onAddMaterialClicked()"
    class="icon-add-circle"
    [tp]="translations.addNewMaterialTp"
    type="icon"
  >
    <mat-icon icon-small>add</mat-icon>
  </app-button>
</ng-template>
<ng-template #initialQuantityViewTemplate let-data>
  @if (unitOfMeasurement()) {
    @if (hasDifferentDefaultUnit() && defaultUnitOfMeasurement()) {
      <app-form-input-readonly
        [label]="data.label"
        [value]="initialQuantityFormatted()"
      ></app-form-input-readonly>
    } @else {
      <app-form-input-readonly
        [label]="data.label"
        [value]="
          (element().initialQuantity ?? '0'
            | number: '1.0-' + unitOfMeasurement()?.precision || '0') +
          ' ' +
          unitOfMeasurement()?.symbol
        "
      ></app-form-input-readonly>
    }
  } @else if (isSharedUser()) {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>
