<div class="right-side-panel">
  <div class="top-content">
    <h3 class="title">{{ safeTitle }}</h3>
    @if (displayTopButtons) {
      <app-button-group class="cg-10 fz-18">
        <app-button
          (pressed)="addMenu ? null : onAddNewItem()"
          [tp]="translations.addNewTp"
          tpPlacement="bottom"
          size="medium"
          type="icon"
          [matMenuTriggerFor]="addMenu"
        >
          <mat-icon icon-medium-18>add</mat-icon>
        </app-button>
        <app-button
          (pressed)="onPressRefresh()"
          [tp]="translations.refreshTp"
          tpPlacement="bottom"
          size="medium"
          type="icon"
        >
          <mat-icon icon-medium-18 class="rotate-horizontal">sync</mat-icon>
        </app-button>
      </app-button-group>
    }
    @if (overlay.contentRightSidePanel()) {
      <app-button-group class="cg-10" style="margin-left: auto">
        @if (overlayTopButtons) {
          <ng-container [ngTemplateOutlet]="overlayTopButtons"></ng-container>
        }
        @if (displayCloseButton) {
          <app-button
            (pressed)="onCloseSideMenu()"
            [tp]="translations.closeTp"
            tpPlacement="bottom"
            size="large"
            type="icon"
          >
            <mat-icon icon-medium-18>close</mat-icon>
          </app-button>
        }
      </app-button-group>
    }
  </div>

  <div class="no-items-container">
    <ng-content select="[subtitle]"></ng-content>
  </div>

  @if (displayContent) {
    <app-input-search
      (searchEvent)="onSearchEvent($event)"
      [placeholder]="searchPlaceholder"
    ></app-input-search>

    @if (!isLoadingItems()) {
      @if (availableItems.length) {
        <div class="add-all">
          <div class="found-text">
            <ng-container [ngPlural]="availableItems.length">
              <ng-template ngPluralCase="=1" i18n>
                <b>1</b>
                record found
              </ng-template>
              <ng-template ngPluralCase="other" i18n>
                <b>{{ availableItems.length }}</b>
                records found
              </ng-template>
            </ng-container>
          </div>
          @if (displayAddAllButton) {
            <div class="button">
              <app-button
                class="no-border no-padding-right content-underline"
                type="ghost"
                (pressed)="onAddAll()"
              >
                <mat-icon icon-left>add</mat-icon>
                <span class="underline" i18n>Add all</span>
              </app-button>
            </div>
          }
        </div>

        <ng-content />
      } @else {
        <div class="no-items-container no-items-container-text">
          @if (noItemsFoundText) {
            <p>{{ noItemsFoundText }}</p>
          } @else {
            <p i18n>No records found</p>
          }
          @if (addItemsText) {
            <p>{{ addItemsText }}</p>
          } @else {
            <p i18n>To add records, click the "+" button</p>
            @if (isAddOpenInNewTab) {
              <p i18n>Once added, return to this screen and click the refresh button</p>
            }
          }
        </div>
      }
    } @else {
      <app-loader></app-loader>
    }
  }
</div>
