<div class="producer-info row-container">
  <div class="box-with-index">
    <div class="index">
      {{ index() }}
    </div>
    <div class="box-content">
      <div class="box-content-padded">
        <div class="row row-2 fs-14">
          <div>
            <app-form-label [label]="translations.producerName"></app-form-label>
            {{ producer().name }}
          </div>
          <div>
            <app-form-label [label]="translations.producerCountry"></app-form-label>
            <app-flag [country]="producer().country"></app-flag>
            {{ countryName() }}
          </div>
        </div>
        <app-table
          [isLoading]="productPlacesIsLoading()"
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [columnDefs]="colDefs()"
          [rowData]="rowData()"
        ></app-table>
      </div>
    </div>
  </div>
</div>
