import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditApiKeyDialogComponent } from "src/app/components/user-settings";
import { QuickActionsMenuComponent } from "src/app/shared/cell-renderers";
import { BatchActionTypeEnum, ConfirmDialogResponseEnum, TableEnum } from "src/app/shared/enums";
import { IApiKey } from "src/app/shared/interfaces";
import { ApiKeysService } from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

import { ConfirmDialogComponent } from "../../../shared/confirm-dialog/confirm-dialog.component";

@Component({
  standalone: false,
  selector: "app-api-keys-table",
  templateUrl: "./api-keys-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiKeysTableComponent implements AfterViewInit {
  @Input()
  public apiKeys: IApiKey[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.API_KEYS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: IApiKey[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private apiKeysService: ApiKeysService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.apiKeysService, true),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onRevoke,
                  tooltip: TextConstants.DELETE,
                  icon: "delete",
                },
                {
                  click: this.onEdit,
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                },
              ]
            : [],
        },
      },
      {
        headerName: TextConstants.DESCRIPTION,
        field: "note",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      { headerName: $localize`Token hint`, field: "tokenHint" },
      ColumnUtils.dateColumn({
        headerName: $localize`Expiration date`,
        field: "expiry",
      }),
      {
        headerName: $localize`Enabled`,
        field: "enabled",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (cell: any) => !!cell.data?.enabled,
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onEdit = (apiKey: IApiKey): void => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(EditApiKeyDialogComponent, {
      data: {
        apiKey,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onRevoke = (element: IApiKey): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure that you want to delete this API key?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.apiKeysService
          .delete(element.id)
          .then(async () => {
            this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.apiKeys) {
      this.rowData = this.apiKeys;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.apiKeysService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
