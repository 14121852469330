<div class="commodity row-container">
  <div class="title">
    <div class="index">
      <div class="circle">{{ index() }}</div>
    </div>
    <div class="text">
      <div>{{ hsHeading() }}</div>
    </div>
  </div>

  <div class="row commodities-description-row fs-14">
    <div>
      <app-form-label [label]="translations.commodityLabel"></app-form-label>
      {{ commodity().descriptors.descriptionOfGoods || "-" }}
    </div>
    <div>
      <app-form-label [label]="translations.massLabel"></app-form-label>
      {{ commodity().descriptors.goodsMeasure.netWeight }}
    </div>
    <div>
      <app-form-label [label]="translations.volumeLabel"></app-form-label>
      {{ commodity().descriptors.goodsMeasure.volume }}
    </div>
  </div>

  <div>
    @if (commodity().speciesInfo.length) {
      <app-table
        [isLoading]="false"
        [isSearchEnabled]="false"
        [isPaginatorEnabled]="false"
        [columnDefs]="colDefs()"
        [rowData]="rowData()"
      ></app-table>
    }
  </div>

  <div class="producers-info">
    @for (producer of commodity().producers; let producerIndex = $index; track producerIndex) {
      <app-reports-eudr-dds-producer
        [index]="producerIndex + 1"
        [producer]="producer"
      ></app-reports-eudr-dds-producer>
    }
  </div>
</div>
