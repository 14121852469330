import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ConfirmDialogResponseEnum } from "src/app/shared/enums";

import { ButtonColor } from "@components/shared/buttons/button/button.model";

@Component({
  standalone: false,
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public confirmDialogResponseEnum = ConfirmDialogResponseEnum;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      contentText?: string;
      contentHTML?: string;
      confirmButtonColor?: ButtonColor;
      confirmButtonIcon?: string;
      confirmButtonText?: string;
      cancelButtonText?: string;
      cancelButtonIcon?: string;
      thirdActionButtonColor?: ButtonColor;
      thirdActionButtonText?: string;
      thirdActionButtonIcon?: string;
      isSubmitButtonDisabled?: boolean;
      onSubmit?: () => void;
    },
  ) {}

  public isSubmitButtonDisabled = (): boolean => this.formGroup?.invalid;

  public onSubmit = async (): Promise<void> => {
    this.data.onSubmit?.();
    this.dialogRef.close(ConfirmDialogResponseEnum.CONFIRM);
  };

  public onClose() {
    this.dialogRef.close(ConfirmDialogResponseEnum.CANCEL);
  }

  thirdActionPerfomed() {
    this.dialogRef.close(ConfirmDialogResponseEnum.THIRD_ACTION);
  }
}
