import {
  Component,
  ChangeDetectionStrategy,
  input,
  signal,
  OnInit,
  inject,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { FormArray, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { degrees, PDFDocument } from "pdf-lib";
import { debounceTime, lastValueFrom, pairwise, startWith, Subject, Subscription } from "rxjs";

import { ConfirmDialogComponent } from "@components/shared/confirm-dialog/confirm-dialog.component";
import { PdfSplitterModel as Model } from "@components/shared/pdf-splitter/pdf-splitter.model";
import { CommonConstants, TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum } from "@shared/enums";
import { IFileUpload, ISelectOption } from "@shared/interfaces";
import { NotificationService } from "@shared/services";
import { FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  selector: "app-pdf-splitter",
  templateUrl: "./pdf-splitter.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
  styleUrls: ["./pdf-splitter.component.scss"],
})
export class PdfSplitterComponent implements OnInit, OnDestroy {
  @ViewChild("documentPagesListContainer") documentPagesListContainer!: ElementRef;

  @ViewChildren("documentPagesList") documentPagesList!: QueryList<ElementRef>;

  public file = input.required<IFileUpload>();

  public index = input.required<number>();

  public documentTypeOptions = input.required<ISelectOption[]>();

  public allDocumentNames = input.required<string[]>();

  public pdfSrc: string;

  public isLoading = signal<boolean>(true);

  public selectedPage = signal<FormControl<Model.IPage>>(null);

  public isSubmitButtonDisabled = signal<boolean>(false);

  public formGroup = signal<FormGroup<Model.IFormField>>(null);

  public readonly fieldEnum = Model.FieldEnum;

  public readonly documentFieldEnum = Model.DocumentFieldEnum;

  private dialog: MatDialog = inject(MatDialog);

  private notificationService: NotificationService = inject(NotificationService);

  private subscriptions: Subscription = new Subscription();

  private checkForDuplicatesSubject: Subject<void> = new Subject();

  private readonly ADD_DOCUMENT_TEXT = $localize`Add 1 document`;

  private readonly SUBMIT_ERROR_MESSAGE = $localize`Please make sure all required fields are properly filled and the document names are unique`;

  private readonly MERGE_WARNING_MESSAGE = $localize`Merging the two documents will discard all of the information entered for the second document. Are you sure you want to proceed?`;

  public submitButtonText = signal<string>(this.ADD_DOCUMENT_TEXT);

  public readonly translations: any = {
    splitPagesTp: $localize`Split pages`,
  };

  @HostListener("window:beforeunload")
  public canDeactivate(): boolean {
    return false;
  }

  @Output()
  public save = new EventEmitter<Model.IDocument[]>();

  @Output()
  public discard = new EventEmitter();

  constructor() {
    this.subscriptions.add(
      this.checkForDuplicatesSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SEARCH_TIME_MS))
        .subscribe(() => this.checkForDuplicates()),
    );
  }

  public ngOnInit(): void {
    this.displayPdf(this.file().info);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public pagesControls(
    documentForm: FormGroup<Model.IDocumentFormField>,
  ): FormArray<FormControl<Model.IPage>> {
    return documentForm.get(Model.DocumentFieldEnum.PAGES) as FormArray<FormControl<Model.IPage>>;
  }

  public async onSubmit(): Promise<void> {
    if (this.formGroup().invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup());
      this.notificationService.showError(this.SUBMIT_ERROR_MESSAGE);

      return;
    }

    await this.splitPdf(
      this.file().info,
      this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray,
    );

    const value = this.formGroup().value.documents.filter(
      (document) => document.isIncluded,
    ) as Model.IDocument[];

    this.save.emit(value);

    this.notificationService.showSuccess(
      $localize`${value.length}:documentsCount: document(s) added. They are not automatically uploaded.`,
    );
  }

  public async onCancel(): Promise<void> {
    this.discard.emit();
  }

  public async onMerge(documentIndex: number): Promise<void> {
    const documentToBeMergedInto = (
      this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray
    ).at(documentIndex) as FormGroup<Model.IDocumentFormField>;
    const documentToBeAbsorbed = (this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray).at(
      documentIndex + 1,
    ) as FormGroup<Model.IDocumentFormField>;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Merge documents`,
        contentText: this.MERGE_WARNING_MESSAGE,
        confirmButtonText: $localize`Merge`,
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    if (response === ConfirmDialogResponseEnum.CONFIRM) {
      this.mergeDocuments(documentToBeMergedInto, documentToBeAbsorbed);

      (this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray).removeAt(documentIndex + 1);

      return;
    }
  }

  public onDeleteDocument(index: number): void {
    const documentsField = Model.FieldEnum.DOCUMENTS;
    const pagesField = Model.DocumentFieldEnum.PAGES;

    const documents = this.formGroup().get(documentsField) as FormArray;

    const documentPages = documents
      .at(index)
      .get(pagesField)
      .value.map((page) => page.number);

    documents.removeAt(index);

    const firstDocument = (this.formGroup().get(documentsField) as FormArray).at(0);

    if (documentPages.includes(this.selectedPage().value.number)) {
      this.onPageClick(
        (firstDocument.get(pagesField) as FormArray).at(0) as FormControl<Model.IPage>,
      );
    }
  }

  public onPageClick(page: FormControl<Model.IPage>): void {
    this.selectedPage.set(page);
  }

  public onPageRotation(event: {
    pageForm: FormControl<Model.IPage>;
    rotation: Model.Rotation;
  }): void {
    event.pageForm.setValue({ ...event.pageForm.value, rotation: event.rotation });
  }

  public onPageInclusion(event: { pageForm: FormControl<Model.IPage>; isIncluded: boolean }): void {
    event.pageForm.setValue({ ...event.pageForm.value, isIncluded: event.isIncluded });
  }

  public onScrollToPages(index: number): void {
    const container = this.documentPagesListContainer.nativeElement;
    const target = this.documentPagesList.toArray()[index].nativeElement;

    container.scrollTo({
      top: target.offsetTop - container.offsetTop,
      behavior: "smooth",
    });
  }

  public onSplit(
    documentForm: FormGroup<Model.IDocumentFormField>,
    documentIndex: number,
    page: FormControl<Model.IPage>,
  ): void {
    const pagesFormArray = documentForm.get(Model.DocumentFieldEnum.PAGES) as FormArray<
      FormControl<Model.IPage>
    >;
    const pageIndex = pagesFormArray.controls.findIndex(
      (p) => p.value.number === page.value.number,
    );

    if (pageIndex === -1) {
      return;
    }

    const firstPartControls = pagesFormArray.controls.slice(0, pageIndex + 1);
    const secondPartControls = pagesFormArray.controls.slice(pageIndex + 1);

    pagesFormArray.clear();
    firstPartControls.forEach((control) => pagesFormArray.push(control));

    const newDocumentForm = this.createDocumentForm(secondPartControls);

    newDocumentForm
      .get(Model.DocumentFieldEnum.TYPE)
      .setValue(documentForm.get(Model.DocumentFieldEnum.TYPE).value);

    (this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray).insert(
      documentIndex + 1,
      newDocumentForm,
    );
  }

  public setIsSubmitButtonDisabled(): void {
    const includeDocumentsCount = (
      this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray
    ).controls.filter((control) => control.get(Model.DocumentFieldEnum.IS_INCLUDED).value).length;

    if (includeDocumentsCount === 1) {
      this.submitButtonText.set(this.ADD_DOCUMENT_TEXT);
    } else {
      this.submitButtonText.set($localize`Add ${includeDocumentsCount}:documentsCount: documents`);
    }

    this.isSubmitButtonDisabled.set(!includeDocumentsCount);
  }

  private async splitPdf(
    file: File,
    documents: FormArray<FormGroup<Model.IDocumentFormField>>,
  ): Promise<void> {
    const arrayBuffer = await file.arrayBuffer();
    const pdfDoc = await PDFDocument.load(arrayBuffer);

    for (let documentIndex = 0; documentIndex < documents.length; documentIndex++) {
      const newPdf = await PDFDocument.create();
      const document = documents.at(documentIndex);

      const includedPages = document
        .get(Model.DocumentFieldEnum.PAGES)
        .value.filter((page) => page.isIncluded);

      for (const page of includedPages) {
        const [copiedPage] = await newPdf.copyPages(pdfDoc, [page.number - 1]);

        copiedPage.setRotation(degrees(page.rotation));

        newPdf.addPage(copiedPage);
      }

      const pdfBytes = await newPdf.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });

      const fileName = `${document.get(Model.DocumentFieldEnum.NAME).value}.pdf`;
      const groupFile = new File([blob], fileName, { type: "application/pdf" });

      document.get(Model.DocumentFieldEnum.FILE).setValue(groupFile);
    }
  }

  private checkForDuplicates(): void {
    const documentControls = (
      this.formGroup().get(Model.FieldEnum.DOCUMENTS) as FormArray
    ).controls.filter(
      (document) => document.get(Model.DocumentFieldEnum.NAME).value?.trim()?.length > 0,
    );

    const documentNamesInSplitter = documentControls
      .filter((document) => document.get(Model.DocumentFieldEnum.IS_INCLUDED).value)
      .map(
        (document) =>
          `${document.get(Model.DocumentFieldEnum.NAME).value.trim().toLowerCase()}.pdf`,
      );

    for (const document of documentControls) {
      const nameControl = document.get(Model.DocumentFieldEnum.NAME);

      const currentDocumentName = `${nameControl.value.trim().toLowerCase()}.pdf`;

      if (currentDocumentName === "") {
        continue;
      }

      if (!document.get(Model.DocumentFieldEnum.IS_INCLUDED).value) {
        nameControl.setErrors(null);
        continue;
      }

      if (
        documentNamesInSplitter.filter((name) => name === currentDocumentName).length > 1 ||
        this.allDocumentNames().includes(currentDocumentName)
      ) {
        nameControl.setErrors({
          fileDuplication: TextConstants.DOCUMENT_NAME_DUPLICATION_ERROR,
        });

        nameControl.markAsTouched();
        nameControl.markAsDirty();
      } else {
        nameControl.setErrors(null);
      }
    }
  }

  private createDocumentForm(
    pages: FormControl<Model.IPage>[],
    setInitialValues: boolean = false,
  ): FormGroup<Model.IDocumentFormField> {
    const initialDocumentFormGroup = this.file().formGroup;

    const values: Partial<Model.IDocument> = {
      [Model.DocumentFieldEnum.NAME]: null,
      [Model.DocumentFieldEnum.TYPE]: [],
      [Model.DocumentFieldEnum.TAGS]: [],
      [Model.DocumentFieldEnum.IS_DATES_ENABLED]: false,
      [Model.DocumentFieldEnum.VALIDITY_START]: null,
      [Model.DocumentFieldEnum.VALIDITY_END]: null,
      [Model.DocumentFieldEnum.ISSUANCE]: null,
    };

    if (setInitialValues) {
      values.name = initialDocumentFormGroup.get(Model.DocumentFieldEnum.NAME).value;
      values.type = initialDocumentFormGroup.get(Model.DocumentFieldEnum.TYPE).value;
      values.tags = initialDocumentFormGroup.get(Model.DocumentFieldEnum.TAGS).value;
      values.isDatesEnabled = initialDocumentFormGroup.get(
        Model.DocumentFieldEnum.IS_DATES_ENABLED,
      ).value;
      values.validityStart = initialDocumentFormGroup.get(
        Model.DocumentFieldEnum.VALIDITY_START,
      ).value;
      values.validityEnd = initialDocumentFormGroup.get(Model.DocumentFieldEnum.VALIDITY_END).value;
      values.issuance = initialDocumentFormGroup.get(Model.DocumentFieldEnum.ISSUANCE).value;
    }

    const formGroup = new FormGroup<Model.IDocumentFormField>({
      name: new FormControl(values[Model.DocumentFieldEnum.NAME]),
      type: new FormControl(values[Model.DocumentFieldEnum.TYPE]),
      tags: new FormControl(values[Model.DocumentFieldEnum.TAGS]),
      isDatesEnabled: new FormControl(values[Model.DocumentFieldEnum.IS_DATES_ENABLED]),
      validityStart: new FormControl(values[Model.DocumentFieldEnum.VALIDITY_START]),
      validityEnd: new FormControl(values[Model.DocumentFieldEnum.VALIDITY_END]),
      issuance: new FormControl(values[Model.DocumentFieldEnum.ISSUANCE]),
      pages: new FormArray(pages),
      isIncluded: new FormControl(true),
      file: new FormControl(),
    });

    this.addValidators(formGroup);

    this.subscriptions.add(
      formGroup
        .get(Model.DocumentFieldEnum.NAME)
        .valueChanges.subscribe(() => this.checkForDuplicatesSubject.next()),
    );

    this.subscriptions.add(
      formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).valueChanges.subscribe((isIncluded) => {
        this.checkForDuplicates();

        if (isIncluded) {
          formGroup.enable({ emitEvent: false });
        } else {
          formGroup.disable({ emitEvent: false });
          formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).enable({ emitEvent: false });
        }
      }),
    );

    this.subscriptions.add(
      formGroup.get(Model.DocumentFieldEnum.PAGES).valueChanges.subscribe((pages) => {
        if (pages.every((page) => !page.isIncluded)) {
          formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).setValue(false);
          formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).disable({ emitEvent: false });
        } else {
          formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).enable({ emitEvent: false });
          formGroup.get(Model.DocumentFieldEnum.IS_INCLUDED).setValue(true);
        }
      }),
    );

    return formGroup;
  }

  private addValidators(formGroup: FormGroup<Model.IDocumentFormField>): void {
    formGroup.get(Model.DocumentFieldEnum.NAME).addValidators([CustomValidators.required]);
    formGroup.get(Model.DocumentFieldEnum.TYPE).addValidators([CustomValidators.required]);

    formGroup.addValidators([
      CustomValidators.dateRules(CommonConstants.DOCUMENT_DATE_RULES) as ValidatorFn,
    ]);
  }

  private createPageRange(start: number, end: number): Model.IPage[] {
    const length = Math.abs(end - start) + 1;

    return Array.from({ length }, (_, i) => ({
      number: start + i,
      isIncluded: true,
      rotation: 0,
    }));
  }

  private displayPdf(file: File): void {
    const reader = new FileReader();

    reader.onload = async (event: ProgressEvent<FileReader>) => {
      const blob = new Blob([event.target?.result as ArrayBuffer], { type: "application/pdf" });

      this.pdfSrc = URL.createObjectURL(blob);

      const arrayBuffer = await file.arrayBuffer();
      const pdfDoc = await PDFDocument.load(arrayBuffer);

      const pages = this.createPageRange(1, pdfDoc.getPageCount()).map(
        (page) =>
          new FormControl<Model.IPage>({
            ...page,
            rotation: 0,
          }),
      );

      const documentForm = this.createDocumentForm(pages, true);

      const formGroup = new FormGroup({
        index: new FormControl(this.index()),
        documents: new FormArray([documentForm]),
      });

      this.formGroup.set(formGroup);

      this.selectedPage.set(pages[0]);

      this.subscriptions.add(
        this.formGroup()
          .get(Model.FieldEnum.DOCUMENTS)
          .valueChanges.pipe(startWith(null), pairwise())
          .subscribe(([previousValue, currentValue]) => {
            if (previousValue && previousValue.length !== currentValue.length) {
              this.checkForDuplicates();
            }

            this.setIsSubmitButtonDisabled();
          }),
      );

      this.isLoading.set(false);
    };

    reader.readAsArrayBuffer(file);
  }

  private mergeDocuments(
    documentToBeMergedInto: FormGroup<Model.IDocumentFormField>,
    documentToBeAbsorbed: FormGroup<Model.IDocumentFormField>,
  ): FormGroup<Model.IDocumentFormField> {
    const pagesFormArray = documentToBeMergedInto.get(Model.DocumentFieldEnum.PAGES) as FormArray<
      FormControl<Model.IPage>
    >;
    const pagesToAbsorb = (
      documentToBeAbsorbed.get(Model.DocumentFieldEnum.PAGES) as FormArray<FormControl<Model.IPage>>
    ).controls;

    pagesToAbsorb.forEach((pageControl) => {
      pagesFormArray.push(new FormControl(pageControl.value));
    });

    return documentToBeMergedInto;
  }
}
