import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  OnInit,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  DateTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  OverlayTabEnum,
  RouteEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IItemExtended,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import { FeatureFlagService, RecordSharingService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./shared-item-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedItemOverlayComponent extends SlideOverlayPageClass implements OnInit {
  private readonly recordSharingService = inject(RecordSharingService);

  private readonly featureFlagService = inject(FeatureFlagService);

  private readonly unitConversionPipe = inject(UnitConversionPipe);

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.ITEM_DETAILS, isEnabled: true }],

      [OverlayTabEnum.DELIVERIES, { title: TextConstants.DELIVERIES, isEnabled: true }],
      [OverlayTabEnum.CERTIFICATES, { title: TextConstants.CERTIFICATES, isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: true }],
    ]),
  );

  override element = signal<IItemExtended>(null);

  override entityType = EntityTypeEnum.ITEMS;

  public datesType: DateTypeEnum = DateTypeEnum.EXACT;

  public productOptions = signal<ISelectOption[]>([]);

  public materialOptions = signal<ISelectOption[]>([]);

  public currentLocationOptions = signal<ISelectOption[]>([]);

  public createdAtLocationOptions = signal<ISelectOption[]>([]);

  public unitOfMeasurementOptions = signal<ISelectOption[]>([]);

  public hasDifferentDefaultUnit = signal<boolean>(false);

  public defaultUnitOfMeasurement = signal<ICustomUnitOfMeasurement>(null);

  public unitOfMeasurement = signal<IBaseUnit>(null);

  public itemDefaultUnitOfMeasurement = signal<IBaseUnit | ICustomUnitOfMeasurement>(null);

  public initialQuantityFormatted: string;

  public remainingQuantityFormatted: string;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    itemId: new UntypedFormControl(null),
    product: new UntypedFormControl(null),
    unitOfMeasurement: new UntypedFormControl(null),
    createdFrom: new UntypedFormControl(null),
    createdRange: new UntypedFormControl([null, null]),
    currentLocation: new UntypedFormControl(null),
    createdAtLocation: new UntypedFormControl(null),
    initialQuantity: new UntypedFormControl(null),
    isSameAsCreatedAtLocation: new UntypedFormControl(false),
    isRangeCreationDate: new UntypedFormControl(false),
    materials: new UntypedFormControl([]),
    remainingQuantity: new UntypedFormControl(null),
  });

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_SHARED_ITEM)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
  }

  protected override async reloadElement(id: string): Promise<void> {
    const productsFieldsInclude = ["DEFAULT_CUSTOM_UNIT"];

    if (this.isOldMaterialsEnabled) {
      productsFieldsInclude.push("ALLOWED_MATERIALS");
    } else {
      productsFieldsInclude.push("MATERIALS");
    }
    try {
      const items = await this.recordSharingService.getInboundItemsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["DOCUMENTS", "CERTIFICATES", "FULL_DELIVERIES"],
        productsFieldsInclude,
      );

      this.element.set({ ...items[0], deliveries: items[0].deliveries.filter((d) => !!d) });
      this.handleUnits();
      this.setupForm();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.overlay.dismissLoading();
    }
  }

  public override setupForm = async (): Promise<void> => {
    const productValue = { label: this.element()?.product.name, value: this.element()?.product.id };
    const materials = this.isOldMaterialsEnabled
      ? this.element()?.materials
      : (this.element()?.product as unknown as IProductExtended).materials;

    const materialsValue: ISelectOption[] = materials.map((material) => ({
      label: material.name,
      value: material.id,
    }));

    const unitOfMeasurementValue = {
      value: this.element()?.product?.unitOfMeasurement.id,
      label: this.element()?.product?.unitOfMeasurement.name,
    };
    const currentLocationValue = {
      value: this.element()?.currentLocation.id,
      label: this.element()?.currentLocation.name,
    };
    const createdAtLocationValue = {
      value: this.element()?.createdAtLocation.id,
      label: this.element()?.createdAtLocation.name,
    };
    let createdFromValue: string = null;
    let createdToValue: string = null;
    const initialQuantity = this.getQuantity(this.element()?.initialQuantity);
    const remainingQuantity = this.getQuantity(this.element()?.remainingQuantity);

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        createdFromValue = this.element()?.created?.on;
        break;
      case DateTypeEnum.RANGE:
        createdFromValue = this.element()?.created?.start;
        createdToValue = this.element()?.created?.end;
        break;
    }
    this.productOptions.set([productValue]);
    this.materialOptions.set(materialsValue);
    this.createdAtLocationOptions.set([createdAtLocationValue]);
    this.currentLocationOptions.set([currentLocationValue]);
    this.unitOfMeasurementOptions.set([unitOfMeasurementValue]);
    this.formGroup = new UntypedFormGroup({
      itemId: new UntypedFormControl(this.element()?.itemId),
      product: new UntypedFormControl(productValue),
      unitOfMeasurement: new UntypedFormControl({ value: unitOfMeasurementValue }),
      createdFrom: new UntypedFormControl(createdFromValue),
      createdRange: new UntypedFormControl([createdFromValue, createdToValue]),
      currentLocation: new UntypedFormControl(currentLocationValue),
      createdAtLocation: new UntypedFormControl(createdAtLocationValue),
      initialQuantity: new UntypedFormControl(initialQuantity),
      isSameAsCreatedAtLocation: new UntypedFormControl(false),
      isRangeCreationDate: new UntypedFormControl(this.datesType === DateTypeEnum.RANGE),
      materials: new UntypedFormControl(materialsValue),
      remainingQuantity: new UntypedFormControl(remainingQuantity),
    });
  };

  private getQuantity(quantity: number): number {
    if (this.itemDefaultUnitOfMeasurement()?.id !== this.unitOfMeasurement()?.id) {
      return +this.unitConversionPipe.transform(
        `${quantity}`,
        this.itemDefaultUnitOfMeasurement(),
        this.unitOfMeasurement(),
        false,
        false,
        true,
      );
    } else {
      return quantity;
    }
  }

  private async handleUnits(): Promise<void> {
    const product = this.element().product;
    const unitOfMeasurement = product.unitOfMeasurement;
    const defaultUnit = product.defaultCustomUnit;

    this.unitOfMeasurement.set(unitOfMeasurement);
    this.hasDifferentDefaultUnit.set(!!defaultUnit);

    if (defaultUnit && this.hasDifferentDefaultUnit) {
      this.defaultUnitOfMeasurement.set(defaultUnit);
      if (this.defaultUnitOfMeasurement()) {
        this.initialQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.element().initialQuantity,
          this.defaultUnitOfMeasurement(),
          this.unitOfMeasurement(),
        );
        this.remainingQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.element().remainingQuantity,
          this.defaultUnitOfMeasurement(),
          this.unitOfMeasurement(),
        );
      }
    }
  }

  protected override recordName(): string {
    return this.element()?.itemId;
  }
}
