@if (formValueChanges$ | async) {}
@if (riskLevelValueChanges$ | async) {}
@if (indicatorAdded$ | async) {}
@if (indicatorRemoved$ | async) {}

<app-slide-overlay-header icon="book_2" [title]="title()" [canDelete]="true" [canArchive]="true" />

<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="form" class="full-width">
        <app-input enableViewMode formControlName="name" [label]="translations.nameLabel" />

        <div [ngClass]="{ 'form-field-item': overlay.editMode() }">
          <app-editable-div
            class="bordered"
            [placeholder]="translations.descriptionPh"
            formControlName="description"
            [label]="translations.descriptionLabel"
            [isTextarea]="true"
            [isResizable]="true"
            [isOptional]="true"
            [enableViewMode]="overlay.viewMode()"
          />
        </div>

        <app-input-select
          formControlName="riskLevelSet"
          [label]="translations.riskLevelSetLabel"
          [options]="riskLevelSetsState.options()"
          [enableViewMode]="overlay.viewMode()"
        >
          <ng-container action>
            <app-button
              (pressed)="addRiskLevelSet()"
              class="icon-add-circle"
              [tp]="translations.addNewTp"
              type="icon"
            >
              <mat-icon icon-small>add</mat-icon>
            </app-button>
          </ng-container>
        </app-input-select>
        @if (riskLevelSetsState.selectedSet()) {
          <app-basic-tags
            appearance="below-form-field"
            [tags]="riskLevelSetsState.selectedLevels()"
          />
        }
      </div>
    </app-form-field-group>
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>

  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="speed"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.INDICATORS).title"
    [overlay-menu-buttons-template]="indicators()?.controller()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.INDICATORS).isEnabled"
    [overlay-menu-indicator]="indicatorsCount"
  >
    <app-risk-assessment-template-overlay-indicators [form]="form.controls.indicators" />
  </ng-template>
</app-slide-overlay-content>
