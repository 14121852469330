import { Injectable, inject, signal } from "@angular/core";

import { IRowNode } from "ag-grid-community";
import { BehaviorSubject } from "rxjs";

import { ResourceTypeEnum } from "@shared/enums";
import { IRecordResponse } from "@shared/interfaces";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { RulesetsService } from "./api";
import { AuthenticationService } from "./authentication.service";
import { NotificationService } from "./notification.service";

@Injectable({ providedIn: "root" })
export class TableWithRulesetsService {
  private readonly authService = inject(AuthenticationService);

  private readonly notification = inject(NotificationService);

  private readonly rulesetsService = inject(RulesetsService);

  readonly recordsSubject = new BehaviorSubject<IRecordResponse[]>([]);

  readonly newRecord = signal(undefined);

  private resourceType: ResourceTypeEnum;

  init(resourceType: ResourceTypeEnum) {
    this.resourceType = resourceType;
  }

  readonly getIconTooltip = () =>
    $localize`This ${this.resourceType.toLowerCase()}:resourceType: does not adhere to one or more rulesets applied to it.`;

  async onRowsVisibleUpdated<T extends { id: string }>(rows: IRowNode<T>[]) {
    const ids = rows.reduce((acc: string[], row) => {
      const id = row.group ? row.childrenAfterGroup?.[0]?.data?.id : row.data?.id;

      if (id && acc.findIndex((existingId) => existingId === id) === -1) {
        acc.push(id);
      }

      return acc;
    }, []);

    await this.getRulesetRecords(ids);
  }

  private async getRulesetRecords(currentPageData: string[]): Promise<void> {
    try {
      const route = RulesetUtils.getResourceRoute(this.resourceType);
      const organisationId = this.authService.getActiveOrganisationId();

      let newRecordRulesetResponse: IRecordResponse[] = [];

      if (this.newRecord()) {
        const newRecordUri = `/organisations/${organisationId}/${route}/${this.newRecord().id}`;
        const currentPageDataNewRecordIndex = currentPageData.findIndex(
          (id) => id === this.newRecord().id,
        );

        if (currentPageDataNewRecordIndex !== -1) {
          currentPageData.splice(currentPageDataNewRecordIndex, 1);
        }
        newRecordRulesetResponse =
          await this.rulesetsService.getInstantRulesetRecords(newRecordUri);
      }
      const uris = currentPageData.map(
        (entity) => `/organisations/${organisationId}/${route}/${entity}`,
      );

      const records = await this.rulesetsService.getRulesetBasedOnRecord({
        resourceType: this.resourceType,
        records: uris,
      });

      this.recordsSubject.next([...records, ...newRecordRulesetResponse]);
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.newRecord.set(null);
    }
  }
}
