import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";
import { ConfirmDialogComponent } from "src/app/components";

import { TextConstants } from "@shared/constants";

@Injectable({
  providedIn: "root",
})
export class CanDeactiveDialogService {
  constructor(private dialog: MatDialog) {}

  async showDialog(isSubmitButtonDisabled: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.EXIT_CONFIRMATION,
        contentText: $localize`You're about to leave this page. Would you like to save the changes you have made before leaving it?`,
        confirmButtonText: $localize`Save & leave`,
        confirmButtonIcon: "check",
        cancelButtonText: TextConstants.CANCEL,
        thirdActionButtonColor: "danger",
        thirdActionButtonText: $localize`Discard changes`,
        isSubmitButtonDisabled,
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }

  async showUploadConfirmationDialog(dialogText?: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.EXIT_CONFIRMATION,
        confirmButtonColor: "danger",
        contentText:
          dialogText ?? $localize`Upload in progress. Are you sure that you want to exit?`,
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }

  async showOverlayMenuDialog(submitDisabled: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Change menu confirmation`,
        contentText: $localize`You're about to leave this section. Would you like to save the changes you have made before leaving it?`,
        confirmButtonText: $localize`Save & leave`,
        confirmButtonIcon: "check",
        cancelButtonText: TextConstants.CANCEL,
        thirdActionButtonColor: "danger",
        thirdActionButtonText: $localize`Discard changes`,
        isSubmitButtonDisabled: submitDisabled,
      },
    });

    return await lastValueFrom(dialogRef.afterClosed());
  }
}
