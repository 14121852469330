import {
  afterNextRender,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  signal,
  viewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { InputType } from "@components/shared/form/form-input/form-input.model";

export interface IInputCellRendererParams extends ICellRendererParams {
  field: string;
  actions: any;
  inputType: InputType;
  suffix: string;
  warningTooltipText?: string;
  shouldDisplayWarning?: boolean;
}

@Component({
  standalone: false,
  template: `
    <div class="cell-renderer input-cell-renderer" [formGroup]="formGroup()">
      <div class="cell-input">
        <app-input
          #input
          [displayErrorsInTooltip]="true"
          [type]="params().inputType"
          [formControlName]="params().field"
          [suffix]="params().suffix"
        ></app-input>
        @if (params().shouldDisplayWarning) {
          <div class="cell-warning">
            <mat-icon
              class="suffix-icon error"
              class="warn-icon"
              svgIcon="alert-triangle"
              [tp]="params().warningTooltipText"
            ></mat-icon>
          </div>
        }
      </div>
      @if (params().actions) {
        <div class="cell-actions">
          @for (action of params().actions; track action.icon) {
            <app-button
              stopPropagation
              [disabled]="action.disabled"
              [tpIsLazy]="true"
              [tp]="action.tooltip"
              type="icon"
              (pressed)="action.click(params().data)"
            >
              @if (action.svgIcon) {
                <mat-icon icon-small [svgIcon]="action.svgIcon"></mat-icon>
              } @else {
                <mat-icon icon-small>{{ action.icon }}</mat-icon>
              }
            </app-button>
          }
        </div>
      }
    </div>
  `,
  styleUrls: ["./input.cell-renderer.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputCellRendererComponent implements ICellRendererAngularComp {
  public params = signal<IInputCellRendererParams>(undefined);

  public formGroup = signal<FormGroup>(undefined);

  private readonly input = viewChild<ElementRef, ElementRef>("input", { read: ElementRef });

  constructor() {
    afterNextRender(() => {
      if (this.input()?.nativeElement) {
        this.input().nativeElement.querySelector("input").focus();
      }
    });
  }

  agInit(params: IInputCellRendererParams): void {
    this.params.set(params);
    this.formGroup.set(params.value);
  }

  refresh(params: IInputCellRendererParams): boolean {
    this.params.set(params);
    this.formGroup.set(params.value);

    return true;
  }
}
