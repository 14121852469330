import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from "@angular/core";

import { TextConstants } from "@shared/constants";
import { IProduct } from "@shared/interfaces";

import { CardContentTypeEnum } from "../card-content/card-content.model";

@Component({
  standalone: false,
  selector: "app-product-card-content",
  templateUrl: "./product-card-content.component.html",
  styleUrl: "./product-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductCardContentComponent {
  @Input()
  product: IProduct;

  @Input()
  type: CardContentTypeEnum;

  @Input()
  customClass: string;

  @Input({ transform: booleanAttribute }) isSupplyChainNode: boolean = false;

  @Input({ transform: booleanAttribute }) shouldOpenInNewTab: boolean = false;

  @Output()
  remove: EventEmitter<void> = new EventEmitter();

  public readonly cardContentTypeEnum = CardContentTypeEnum;

  public readonly translations: any = {
    removeTp: TextConstants.REMOVE,
  };
}
