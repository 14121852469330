import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { EudrDdsProducersModel as Model } from "@components/reports/eudr/dds/producer/reports-eudr-dds-producer.model";
import { TextConstants } from "@shared/constants";
import { GeoJsonTypeEnum } from "@shared/enums";
import { IGeoLocation, ISelectOption } from "@shared/interfaces";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { NotificationService, CommonService } from "@shared/services";
import { GeoJSONUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-reports-eudr-dds-producer",
  templateUrl: "./reports-eudr-dds-producer.component.html",
  styleUrls: ["./reports-eudr-dds-producer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsEudrDdsProducerComponent implements OnInit, OnDestroy {
  public index = input<number>(0);

  public producer = input<EudrDds.IProducer>(null);

  public countryName = signal<string>("-");

  public rowData = signal<Model.IProductionPlacesRowData[]>([]);

  public colDefs = signal<ColDef[]>([]);

  public productPlacesIsLoading = signal<boolean>(true);

  private countryOptions = signal<ISelectOption[]>([]);

  private eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  private commonService: CommonService = inject(CommonService);

  private notificationService: NotificationService = inject(NotificationService);

  public readonly columnDefs: ColDef[] = [
    {
      headerName: "#",
      valueGetter: "node.rowIndex + 1",
      cellClass: "index-column",
      width: 5,
    },
    {
      headerName: $localize`Production place description`,
      field: "name",
    },
    {
      headerName: TextConstants.AREA_HA,
      field: "area",
    },
    {
      headerName: TextConstants.TYPE,
      field: "type",
    },
  ];

  private subscriptions = new Subscription();

  public readonly translations: any = {
    producerName: TextConstants.PRODUCER_NAME,
    producerCountry: TextConstants.PRODUCER_COUNTRY,
  };

  constructor() {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions.set(countriesOptions);
        },
      ),
    );
  }

  public ngOnInit(): void {
    const countryOption = this.countryOptions().find(
      (country) => country.value === this.producer().country,
    );

    if (countryOption) {
      this.countryName.set(countryOption.label);
    }

    this.rowData.set(this.getRowData());
    this.colDefs.set(this.columnDefs);
    this.productPlacesIsLoading.set(false);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getRowData(): Model.IProductionPlacesRowData[] {
    return this.producer().productionPlaces.map((place) => {
      let area: string = "0";
      let type: string = "None";

      if (place.geoData) {
        try {
          const geoDataJSON = JSON.parse(place.geoData) as IGeoLocation;

          area = this.getGeoJSONArea(geoDataJSON, place.areaInHa);
          type = this.getGeoJSONType(geoDataJSON);

          this.eudrDdsPageService.addArea(parseFloat(area));
        } catch (error) {
          this.notificationService.showError(error);
          console.error("Error parsing geoData", error);
        }
      }

      return {
        name: place.name,
        area,
        type,
      };
    });
  }

  private getGeoJSONType(geoData: IGeoLocation): string {
    return geoData.features[0].geometry.type;
  }

  private getGeoJSONArea(geoData: IGeoLocation, areaInHa: number): string {
    const geometry = geoData.features[0].geometry;

    if (geometry.type === GeoJsonTypeEnum.POINT) {
      return areaInHa.toFixed(2);
    }

    return (GeoJSONUtils.geometry(geometry) / 10_000).toFixed(2);
  }
}
