import {
  AfterContentInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from "@angular/core";

import { RadioButtonComponent } from "@components/shared/radio-button/radio-button.component";

@Component({
  standalone: false,
  selector: "app-radio-button-group",
  template: `
    <mat-radio-group [disabled]="disabled">
      <ng-content></ng-content>
    </mat-radio-group>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonGroupComponent implements AfterContentInit {
  @Input({ transform: booleanAttribute }) disabled: boolean;

  @ContentChildren(RadioButtonComponent) radioButtons: QueryList<RadioButtonComponent>;

  ngAfterContentInit(): void {
    this.updateRadioButtonsDisabled();
    this.radioButtons.changes.subscribe(() => {
      this.updateRadioButtonsDisabled();
    });
  }

  private updateRadioButtonsDisabled(): void {
    if (this.radioButtons) {
      this.radioButtons.forEach((button) => {
        if (button.disabled === null || button.disabled === undefined) {
          button.disabled = this.disabled;
        }
      });
    }
  }
}
