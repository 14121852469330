export const COUNTRIES_COORDINATES: {
  country: string;
  lat: number;
  lng: number;
}[] = [
  {
    country: "AD",
    lat: 42.546245,
    lng: 1.601554,
  },
  {
    country: "AE",
    lat: 23.424076,
    lng: 53.847818,
  },
  {
    country: "AF",
    lat: 33.93911,
    lng: 67.709953,
  },
  {
    country: "AG",
    lat: 17.060816,
    lng: -61.796428,
  },
  {
    country: "AI",
    lat: 18.220554,
    lng: -63.068615,
  },
  {
    country: "AL",
    lat: 41.153332,
    lng: 20.168331,
  },
  {
    country: "AM",
    lat: 40.069099,
    lng: 45.038189,
  },
  {
    country: "AN",
    lat: 12.226079,
    lng: -69.060087,
  },
  {
    country: "AO",
    lat: -11.202692,
    lng: 17.873887,
  },
  {
    country: "AQ",
    lat: -75.250973,
    lng: -0.071389,
  },
  {
    country: "AR",
    lat: -38.416097,
    lng: -63.616672,
  },
  {
    country: "AS",
    lat: -14.270972,
    lng: -170.132217,
  },
  {
    country: "AT",
    lat: 47.516231,
    lng: 14.550072,
  },
  {
    country: "AU",
    lat: -25.274398,
    lng: 133.775136,
  },
  {
    country: "AW",
    lat: 12.52111,
    lng: -69.968338,
  },
  {
    country: "AZ",
    lat: 40.143105,
    lng: 47.576927,
  },
  {
    country: "BA",
    lat: 43.915886,
    lng: 17.679076,
  },
  {
    country: "BB",
    lat: 13.193887,
    lng: -59.543198,
  },
  {
    country: "BD",
    lat: 23.684994,
    lng: 90.356331,
  },
  {
    country: "BE",
    lat: 50.503887,
    lng: 4.469936,
  },
  {
    country: "BF",
    lat: 12.238333,
    lng: -1.561593,
  },
  {
    country: "BG",
    lat: 42.733883,
    lng: 25.48583,
  },
  {
    country: "BH",
    lat: 25.930414,
    lng: 50.637772,
  },
  {
    country: "BI",
    lat: -3.373056,
    lng: 29.918886,
  },
  {
    country: "BJ",
    lat: 9.30769,
    lng: 2.315834,
  },
  {
    country: "BM",
    lat: 32.321384,
    lng: -64.75737,
  },
  {
    country: "BN",
    lat: 4.535277,
    lng: 114.727669,
  },
  {
    country: "BO",
    lat: -16.290154,
    lng: -63.588653,
  },
  {
    country: "BR",
    lat: -14.235004,
    lng: -51.92528,
  },
  {
    country: "BS",
    lat: 25.03428,
    lng: -77.39628,
  },
  {
    country: "BT",
    lat: 27.514162,
    lng: 90.433601,
  },
  {
    country: "BV",
    lat: -54.423199,
    lng: 3.413194,
  },
  {
    country: "BW",
    lat: -22.328474,
    lng: 24.684866,
  },
  {
    country: "BY",
    lat: 53.709807,
    lng: 27.953389,
  },
  {
    country: "BZ",
    lat: 17.189877,
    lng: -88.49765,
  },
  {
    country: "CA",
    lat: 56.130366,
    lng: -106.346771,
  },
  {
    country: "CC",
    lat: -12.164165,
    lng: 96.870956,
  },
  {
    country: "CD",
    lat: -4.038333,
    lng: 21.758664,
  },
  {
    country: "CF",
    lat: 6.611111,
    lng: 20.939444,
  },
  {
    country: "CG",
    lat: -0.228021,
    lng: 15.827659,
  },
  {
    country: "CH",
    lat: 46.818188,
    lng: 8.227512,
  },
  {
    country: "CI",
    lat: 7.539989,
    lng: -5.54708,
  },
  {
    country: "CK",
    lat: -21.236736,
    lng: -159.777671,
  },
  {
    country: "CL",
    lat: -35.675147,
    lng: -71.542969,
  },
  {
    country: "CM",
    lat: 7.369722,
    lng: 12.354722,
  },
  {
    country: "CN",
    lat: 35.86166,
    lng: 104.195397,
  },
  {
    country: "CO",
    lat: 4.570868,
    lng: -74.297333,
  },
  {
    country: "CR",
    lat: 9.748917,
    lng: -83.753428,
  },
  {
    country: "CU",
    lat: 21.521757,
    lng: -77.781167,
  },
  {
    country: "CV",
    lat: 16.002082,
    lng: -24.013197,
  },
  {
    country: "CX",
    lat: -10.447525,
    lng: 105.690449,
  },
  {
    country: "CY",
    lat: 35.126413,
    lng: 33.429859,
  },
  {
    country: "CZ",
    lat: 49.817492,
    lng: 15.472962,
  },
  {
    country: "DE",
    lat: 51.165691,
    lng: 10.451526,
  },
  {
    country: "DJ",
    lat: 11.825138,
    lng: 42.590275,
  },
  {
    country: "DK",
    lat: 56.26392,
    lng: 9.501785,
  },
  {
    country: "DM",
    lat: 15.414999,
    lng: -61.370976,
  },
  {
    country: "DO",
    lat: 18.735693,
    lng: -70.162651,
  },
  {
    country: "DZ",
    lat: 28.033886,
    lng: 1.659626,
  },
  {
    country: "EC",
    lat: -1.831239,
    lng: -78.183406,
  },
  {
    country: "EE",
    lat: 58.595272,
    lng: 25.013607,
  },
  {
    country: "EG",
    lat: 26.820553,
    lng: 30.802498,
  },
  {
    country: "EH",
    lat: 24.215527,
    lng: -12.885834,
  },
  {
    country: "ER",
    lat: 15.179384,
    lng: 39.782334,
  },
  {
    country: "ES",
    lat: 40.463667,
    lng: -3.74922,
  },
  {
    country: "ET",
    lat: 9.145,
    lng: 40.489673,
  },
  {
    country: "FI",
    lat: 61.92411,
    lng: 25.748151,
  },
  {
    country: "FJ",
    lat: -16.578193,
    lng: 179.414413,
  },
  {
    country: "FK",
    lat: -51.796253,
    lng: -59.523613,
  },
  {
    country: "FM",
    lat: 7.425554,
    lng: 150.550812,
  },
  {
    country: "FO",
    lat: 61.892635,
    lng: -6.911806,
  },
  {
    country: "FR",
    lat: 46.227638,
    lng: 2.213749,
  },
  {
    country: "GA",
    lat: -0.803689,
    lng: 11.609444,
  },
  {
    country: "GB",
    lat: 55.378051,
    lng: -3.435973,
  },
  {
    country: "GD",
    lat: 12.262776,
    lng: -61.604171,
  },
  {
    country: "GE",
    lat: 42.315407,
    lng: 43.356892,
  },
  {
    country: "GF",
    lat: 3.933889,
    lng: -53.125782,
  },
  {
    country: "GG",
    lat: 49.465691,
    lng: -2.585278,
  },
  {
    country: "GH",
    lat: 7.946527,
    lng: -1.023194,
  },
  {
    country: "GI",
    lat: 36.137741,
    lng: -5.345374,
  },
  {
    country: "GL",
    lat: 71.706936,
    lng: -42.604303,
  },
  {
    country: "GM",
    lat: 13.443182,
    lng: -15.310139,
  },
  {
    country: "GN",
    lat: 9.945587,
    lng: -9.696645,
  },
  {
    country: "GP",
    lat: 16.995971,
    lng: -62.067641,
  },
  {
    country: "GQ",
    lat: 1.650801,
    lng: 10.267895,
  },
  {
    country: "GR",
    lat: 39.074208,
    lng: 21.824312,
  },
  {
    country: "GS",
    lat: -54.429579,
    lng: -36.587909,
  },
  {
    country: "GT",
    lat: 15.783471,
    lng: -90.230759,
  },
  {
    country: "GU",
    lat: 13.444304,
    lng: 144.793731,
  },
  {
    country: "GW",
    lat: 11.803749,
    lng: -15.180413,
  },
  {
    country: "GY",
    lat: 4.860416,
    lng: -58.93018,
  },
  {
    country: "GZ",
    lat: 31.354676,
    lng: 34.308825,
  },
  {
    country: "HK",
    lat: 22.396428,
    lng: 114.109497,
  },
  {
    country: "HM",
    lat: -53.08181,
    lng: 73.504158,
  },
  {
    country: "HN",
    lat: 15.199999,
    lng: -86.241905,
  },
  {
    country: "HR",
    lat: 45.1,
    lng: 15.2,
  },
  {
    country: "HT",
    lat: 18.971187,
    lng: -72.285215,
  },
  {
    country: "HU",
    lat: 47.162494,
    lng: 19.503304,
  },
  {
    country: "ID",
    lat: -0.789275,
    lng: 113.921327,
  },
  {
    country: "IE",
    lat: 53.41291,
    lng: -8.24389,
  },
  {
    country: "IL",
    lat: 31.046051,
    lng: 34.851612,
  },
  {
    country: "IM",
    lat: 54.236107,
    lng: -4.548056,
  },
  {
    country: "IN",
    lat: 20.593684,
    lng: 78.96288,
  },
  {
    country: "IO",
    lat: -6.343194,
    lng: 71.876519,
  },
  {
    country: "IQ",
    lat: 33.223191,
    lng: 43.679291,
  },
  {
    country: "IR",
    lat: 32.427908,
    lng: 53.688046,
  },
  {
    country: "IS",
    lat: 64.963051,
    lng: -19.020835,
  },
  {
    country: "IT",
    lat: 41.87194,
    lng: 12.56738,
  },
  {
    country: "JE",
    lat: 49.214439,
    lng: -2.13125,
  },
  {
    country: "JM",
    lat: 18.109581,
    lng: -77.297508,
  },
  {
    country: "JO",
    lat: 30.585164,
    lng: 36.238414,
  },
  {
    country: "JP",
    lat: 36.204824,
    lng: 138.252924,
  },
  {
    country: "KE",
    lat: -0.023559,
    lng: 37.906193,
  },
  {
    country: "KG",
    lat: 41.20438,
    lng: 74.766098,
  },
  {
    country: "KH",
    lat: 12.565679,
    lng: 104.990963,
  },
  {
    country: "KI",
    lat: -3.370417,
    lng: -168.734039,
  },
  {
    country: "KM",
    lat: -11.875001,
    lng: 43.872219,
  },
  {
    country: "KN",
    lat: 17.357822,
    lng: -62.782998,
  },
  {
    country: "KP",
    lat: 40.339852,
    lng: 127.510093,
  },
  {
    country: "KR",
    lat: 35.907757,
    lng: 127.766922,
  },
  {
    country: "KW",
    lat: 29.31166,
    lng: 47.481766,
  },
  {
    country: "KY",
    lat: 19.513469,
    lng: -80.566956,
  },
  {
    country: "KZ",
    lat: 48.019573,
    lng: 66.923684,
  },
  {
    country: "LA",
    lat: 19.85627,
    lng: 102.495496,
  },
  {
    country: "LB",
    lat: 33.854721,
    lng: 35.862285,
  },
  {
    country: "LC",
    lat: 13.909444,
    lng: -60.978893,
  },
  {
    country: "LI",
    lat: 47.166,
    lng: 9.555373,
  },
  {
    country: "LK",
    lat: 7.873054,
    lng: 80.771797,
  },
  {
    country: "LR",
    lat: 6.428055,
    lng: -9.429499,
  },
  {
    country: "LS",
    lat: -29.609988,
    lng: 28.233608,
  },
  {
    country: "LT",
    lat: 55.169438,
    lng: 23.881275,
  },
  {
    country: "LU",
    lat: 49.815273,
    lng: 6.129583,
  },
  {
    country: "LV",
    lat: 56.879635,
    lng: 24.603189,
  },
  {
    country: "LY",
    lat: 26.3351,
    lng: 17.228331,
  },
  {
    country: "MA",
    lat: 31.791702,
    lng: -7.09262,
  },
  {
    country: "MC",
    lat: 43.750298,
    lng: 7.412841,
  },
  {
    country: "MD",
    lat: 47.411631,
    lng: 28.369885,
  },
  {
    country: "ME",
    lat: 42.708678,
    lng: 19.37439,
  },
  {
    country: "MG",
    lat: -18.766947,
    lng: 46.869107,
  },
  {
    country: "MH",
    lat: 7.131474,
    lng: 171.184478,
  },
  {
    country: "MK",
    lat: 41.608635,
    lng: 21.745275,
  },
  {
    country: "ML",
    lat: 17.570692,
    lng: -3.996166,
  },
  {
    country: "MM",
    lat: 21.913965,
    lng: 95.956223,
  },
  {
    country: "MN",
    lat: 46.862496,
    lng: 103.846656,
  },
  {
    country: "MO",
    lat: 22.198745,
    lng: 113.543873,
  },
  {
    country: "MP",
    lat: 17.33083,
    lng: 145.38469,
  },
  {
    country: "MQ",
    lat: 14.641528,
    lng: -61.024174,
  },
  {
    country: "MR",
    lat: 21.00789,
    lng: -10.940835,
  },
  {
    country: "MS",
    lat: 16.742498,
    lng: -62.187366,
  },
  {
    country: "MT",
    lat: 35.937496,
    lng: 14.375416,
  },
  {
    country: "MU",
    lat: -20.348404,
    lng: 57.552152,
  },
  {
    country: "MV",
    lat: 3.202778,
    lng: 73.22068,
  },
  {
    country: "MW",
    lat: -13.254308,
    lng: 34.301525,
  },
  {
    country: "MX",
    lat: 23.634501,
    lng: -102.552784,
  },
  {
    country: "MY",
    lat: 4.210484,
    lng: 101.975766,
  },
  {
    country: "MZ",
    lat: -18.665695,
    lng: 35.529562,
  },
  {
    country: "NA",
    lat: -22.95764,
    lng: 18.49041,
  },
  {
    country: "NC",
    lat: -20.904305,
    lng: 165.618042,
  },
  {
    country: "NE",
    lat: 17.607789,
    lng: 8.081666,
  },
  {
    country: "NF",
    lat: -29.040835,
    lng: 167.954712,
  },
  {
    country: "NG",
    lat: 9.081999,
    lng: 8.675277,
  },
  {
    country: "NI",
    lat: 12.865416,
    lng: -85.207229,
  },
  {
    country: "NL",
    lat: 52.132633,
    lng: 5.291266,
  },
  {
    country: "NO",
    lat: 60.472024,
    lng: 8.468946,
  },
  {
    country: "NP",
    lat: 28.394857,
    lng: 84.124008,
  },
  {
    country: "NR",
    lat: -0.522778,
    lng: 166.931503,
  },
  {
    country: "NU",
    lat: -19.054445,
    lng: -169.867233,
  },
  {
    country: "NZ",
    lat: -40.900557,
    lng: 174.885971,
  },
  {
    country: "OM",
    lat: 21.512583,
    lng: 55.923255,
  },
  {
    country: "PA",
    lat: 8.537981,
    lng: -80.782127,
  },
  {
    country: "PE",
    lat: -9.189967,
    lng: -75.015152,
  },
  {
    country: "PF",
    lat: -17.679742,
    lng: -149.406843,
  },
  {
    country: "PG",
    lat: -6.314993,
    lng: 143.95555,
  },
  {
    country: "PH",
    lat: 12.879721,
    lng: 121.774017,
  },
  {
    country: "PK",
    lat: 30.375321,
    lng: 69.345116,
  },
  {
    country: "PL",
    lat: 51.919438,
    lng: 19.145136,
  },
  {
    country: "PM",
    lat: 46.941936,
    lng: -56.27111,
  },
  {
    country: "PN",
    lat: -24.703615,
    lng: -127.439308,
  },
  {
    country: "PR",
    lat: 18.220833,
    lng: -66.590149,
  },
  {
    country: "PS",
    lat: 31.952162,
    lng: 35.233154,
  },
  {
    country: "PT",
    lat: 39.399872,
    lng: -8.224454,
  },
  {
    country: "PW",
    lat: 7.51498,
    lng: 134.58252,
  },
  {
    country: "PY",
    lat: -23.442503,
    lng: -58.443832,
  },
  {
    country: "QA",
    lat: 25.354826,
    lng: 51.183884,
  },
  {
    country: "RE",
    lat: -21.115141,
    lng: 55.536384,
  },
  {
    country: "RO",
    lat: 45.943161,
    lng: 24.96676,
  },
  {
    country: "RS",
    lat: 44.016521,
    lng: 21.005859,
  },
  {
    country: "RU",
    lat: 61.52401,
    lng: 105.318756,
  },
  {
    country: "RW",
    lat: -1.940278,
    lng: 29.873888,
  },
  {
    country: "SA",
    lat: 23.885942,
    lng: 45.079162,
  },
  {
    country: "SB",
    lat: -9.64571,
    lng: 160.156194,
  },
  {
    country: "SC",
    lat: -4.679574,
    lng: 55.491977,
  },
  {
    country: "SD",
    lat: 12.862807,
    lng: 30.217636,
  },
  {
    country: "SE",
    lat: 60.128161,
    lng: 18.643501,
  },
  {
    country: "SG",
    lat: 1.352083,
    lng: 103.819836,
  },
  {
    country: "SH",
    lat: -24.143474,
    lng: -10.030696,
  },
  {
    country: "SI",
    lat: 46.151241,
    lng: 14.995463,
  },
  {
    country: "SJ",
    lat: 77.553604,
    lng: 23.670272,
  },
  {
    country: "SK",
    lat: 48.669026,
    lng: 19.699024,
  },
  {
    country: "SL",
    lat: 8.460555,
    lng: -11.779889,
  },
  {
    country: "SM",
    lat: 43.94236,
    lng: 12.457777,
  },
  {
    country: "SN",
    lat: 14.497401,
    lng: -14.452362,
  },
  {
    country: "SO",
    lat: 5.152149,
    lng: 46.199616,
  },
  {
    country: "SR",
    lat: 3.919305,
    lng: -56.027783,
  },
  {
    country: "ST",
    lat: 0.18636,
    lng: 6.613081,
  },
  {
    country: "SV",
    lat: 13.794185,
    lng: -88.89653,
  },
  {
    country: "SY",
    lat: 34.802075,
    lng: 38.996815,
  },
  {
    country: "SZ",
    lat: -26.522503,
    lng: 31.465866,
  },
  {
    country: "TC",
    lat: 21.694025,
    lng: -71.797928,
  },
  {
    country: "TD",
    lat: 15.454166,
    lng: 18.732207,
  },
  {
    country: "TF",
    lat: -49.280366,
    lng: 69.348557,
  },
  {
    country: "TG",
    lat: 8.619543,
    lng: 0.824782,
  },
  {
    country: "TH",
    lat: 15.870032,
    lng: 100.992541,
  },
  {
    country: "TJ",
    lat: 38.861034,
    lng: 71.276093,
  },
  {
    country: "TK",
    lat: -8.967363,
    lng: -171.855881,
  },
  {
    country: "TL",
    lat: -8.874217,
    lng: 125.727539,
  },
  {
    country: "TM",
    lat: 38.969719,
    lng: 59.556278,
  },
  {
    country: "TN",
    lat: 33.886917,
    lng: 9.537499,
  },
  {
    country: "TO",
    lat: -21.178986,
    lng: -175.198242,
  },
  {
    country: "TR",
    lat: 38.963745,
    lng: 35.243322,
  },
  {
    country: "TT",
    lat: 10.691803,
    lng: -61.222503,
  },
  {
    country: "TV",
    lat: -7.109535,
    lng: 177.64933,
  },
  {
    country: "TW",
    lat: 23.69781,
    lng: 120.960515,
  },
  {
    country: "TZ",
    lat: -6.369028,
    lng: 34.888822,
  },
  {
    country: "UA",
    lat: 48.379433,
    lng: 31.16558,
  },
  {
    country: "UG",
    lat: 1.373333,
    lng: 32.290275,
  },
  {
    country: "US",
    lat: 37.09024,
    lng: -95.712891,
  },
  {
    country: "UY",
    lat: -32.522779,
    lng: -55.765835,
  },
  {
    country: "UZ",
    lat: 41.377491,
    lng: 64.585262,
  },
  {
    country: "VA",
    lat: 41.902916,
    lng: 12.453389,
  },
  {
    country: "VC",
    lat: 12.984305,
    lng: -61.287228,
  },
  {
    country: "VE",
    lat: 6.42375,
    lng: -66.58973,
  },
  {
    country: "VG",
    lat: 18.420695,
    lng: -64.639968,
  },
  {
    country: "VI",
    lat: 18.335765,
    lng: -64.896335,
  },
  {
    country: "VN",
    lat: 14.058324,
    lng: 108.277199,
  },
  {
    country: "VU",
    lat: -15.376706,
    lng: 166.959158,
  },
  {
    country: "WF",
    lat: -13.768752,
    lng: -177.156097,
  },
  {
    country: "WS",
    lat: -13.759029,
    lng: -172.104629,
  },
  {
    country: "XK",
    lat: 42.602636,
    lng: 20.902977,
  },
  {
    country: "YE",
    lat: 15.552727,
    lng: 48.516388,
  },
  {
    country: "YT",
    lat: -12.8275,
    lng: 45.166244,
  },
  {
    country: "ZA",
    lat: -30.559482,
    lng: 22.937506,
  },
  {
    country: "ZM",
    lat: -13.133897,
    lng: 27.849332,
  },
  {
    country: "ZW",
    lat: -19.015438,
    lng: 29.154857,
  },
];
