import { Pipe, PipeTransform } from "@angular/core";

import { IBaseUnit } from "src/app/shared/interfaces";

@Pipe({
  standalone: false,
  name: "unitSymbol",
})
export class UnitSymbolPipe implements PipeTransform {
  transform(value: string, units: IBaseUnit[], returnType: "symbol" | "name" = "symbol"): string {
    const unit = units.find((unit) => unit.type === value);

    if (!unit) {
      return " ";
    }
    const returnValue = returnType === "symbol" ? unit.symbol : unit.name;

    return returnValue ?? " ";
  }
}
