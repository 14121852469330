import { AsyncPipe, CommonModule, DecimalPipe, NgOptimizedImage } from "@angular/common";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { RouterModule } from "@angular/router";

import {
  TippyDirective,
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
} from "@ngneat/helipopper";
import { AgGridModule } from "ag-grid-angular";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { QuillModule } from "ngx-quill";
import { AngularMaterialModule } from "src/app/angular-material.module";

import {
  AdminLocationDetailsComponent,
  AdminOrganisationDetailsComponent,
  AdminOrganisationsComponent,
} from "@components/admin";
import {
  CertificateOverlayComponent,
  CertificateOverlayMainInformationComponent,
  SharedCertificateOverlayComponent,
} from "@components/certificates";
import {
  AddItemDialogComponent,
  DeliveryEudrDdsDetailsComponent,
  DeliveryOverlayComponent,
  DeliverySupplyChainDocumentsComponent,
  DeliverySupplyChainDocumentsTableComponent,
  EditDeliveryMainDetailsComponent,
  EditItemQuantityDialogComponent,
  SharedDeliveryOverlayComponent,
  SlideOverlayDeliveryItemsComponent,
} from "@components/deliveries";
import {
  DocumentOverlayComponent,
  EditDocumentMainDetailsComponent,
  SharedDocumentOverlayComponent,
} from "@components/documents";
import {
  ForgotPasswordComponent,
  LogoutComponent,
  ResetPasswordComponent,
  SharingComponent,
  SignUpComponent,
  VerifyComponent,
} from "@components/guest";
import {
  InboxComponent,
  InboxTableComponent,
  SharedSupplyChainComponent,
  TransferOrMapDataComponent,
  TrasferOrMapFormComponent,
} from "@components/inbox";
import { InvitationsComponent } from "@components/invitations";
import {
  AddProductDialogComponent,
  BulkAddItemsComponent,
  BulkAddItemsCreateRecordsComponent,
  BulkAddItemsEnterRecordsComponent,
  BulkAddItemsSetValuesComponent,
  ItemOverlayComponent,
  ItemOverlayMainInformationComponent,
  SharedItemOverlayComponent,
} from "@components/items";
import {
  BulkAddLocationsComponent,
  BulkAddLocationsCreateRecordsComponent,
  BulkAddLocationsEnterRecordsComponent,
  BulkAddLocationsSetValuesComponent,
} from "@components/locations/bulk-add-locations";
import {
  AddMaterialDialogComponent,
  MaterialOverlayComponent,
  MaterialsComponent,
  SharedMaterialOverlayComponent,
} from "@components/materials";
import {
  AddLocationDialogComponent,
  EditOrganisationMainDetailsComponent,
  OrganisationOverlayComponent,
  PublicOrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
} from "@components/organisations";
import {
  AddProcessInputsOutputsComponent,
  AddProcessSelectedItemsComponent,
  ProcessInputsOutputsComponent,
  ProcessOverlayComponent,
  ProcessSelectedItemsComponent,
  SharedProcessOverlayComponent,
} from "@components/processes";
import {
  ProductOverlayComponent,
  RelatedProductsComponent,
  SharedProductOverlayComponent,
} from "@components/products";
import {
  DeliveryReportCertificatesSummaryComponent,
  DeliveryReportComponent,
  DeliveryReportLocationCountryGroupComponent,
  DeliveryReportLocationDetailComponent,
  DeliveryReportLocationDetailsComponent,
  DeliveryReportLocationTypeGroupComponent,
  DeliveryReportMainLocationsTableComponent,
  DeliveryReportOrganisationCertificatesDocumentsComponent,
  DeliveryReportOrganisationDetailsComponent,
  DeliveryReportOtherDeliveriesByGroupComponent,
  DeliveryReportOtherDeliveriesComponent,
  DeliveryReportOtherDeliveriesDeliveryDetailsComponent,
  DeliveryReportProcessInfoComponent,
  DeliveryReportProcessesComponent,
  DeliveryReportSupplyChainEntitiesComponent,
  DeliveryReportSupplyChainEntityComponent,
  EudrDdsAddReferenceDialogComponent,
  EudrDdsReferencesTabComponent,
  ReportsEudrCommodityInfoComponent,
  ReportsEudrComponent,
  ReportsEudrDdsCommodityComponent,
  ReportsEudrDdsComponent,
  ReportsEudrDdsProducerComponent,
  ReportsEudrMaterialInfoComponent,
  ReportsEudrProducerInfoComponent,
  ReportsEudrProductionPlaceComponent,
} from "@components/reports";
import { DeliveryReportOrganisationsByCountryComponent } from "@components/reports/delivery-report/organisation-certificates-documents/delivery-report-organisations-by-country.component";
import {
  AddCountryDialogComponent,
  AddEditRiskLevelSetDialogComponent,
  AddEditRiskMitigationDialogComponent,
  AddUserDialogComponent,
  CountryOverlayComponent,
  EditCustomFieldDialogComponent,
  EditDocumentTypeDialogComponent,
  EditLocationTypeDialogComponent,
  EditProcessTypeDialogComponent,
  EditStandardDialogComponent,
  EditTagDialogComponent,
  EditUserDialogComponent,
  GetTypeRecordState,
  RiskAssessmentTemplateOverlayComponent,
  RiskAssessmentTemplateOverlayIndicatorsComponent,
  SettingsStandardsComponent,
} from "@components/settings";
import { PdfSplitterPageThumbnailComponent } from "@components/shared/pdf-splitter/pdf-splitter-document-fields/pdf-splitter-page-thumbnail/pdf-splitter-page-thumbnail.component";
import { SharedLinkComponent } from "@components/shared-link/shared-link.component";
import {
  BadgeLinkCellRendererComponent,
  ButtonsCellRendererComponent,
  ChipsCellRendererComponent,
  ColumnHeaderRendererComponent,
  DateCellRendererComponent,
  DateRangeCellRendererComponent,
  EnumsCellRendererComponent,
  FirstExistingValueCellRendererComponent,
  InputCellRendererComponent,
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
  RoundNumberCellRendererComponent,
  RulesetIconNameCellRendererComponent,
  SlideOverCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
  ValueWithTooltipCellRendererComponent,
} from "@shared/cell-renderers";
import { AttachProductCellRendererComponent } from "@shared/cell-renderers/attach-product/attach-product.cell-renderer.component";
import {
  AutocompletePositionUpdateDirective,
  BackNavigationDirective,
  ContentProjectionDirective,
  DragCursorDirective,
  NoScrollInputDirective,
} from "@shared/directives";
import {
  AppDatePipe,
  ConditionsMetCheckerPipe,
  EnumToTextPipe,
  LocalTimePipe,
  SafeUrlPipe,
  StripHtmlPipe,
  UnitConversionPipe,
  UnitSymbolPipe,
} from "@shared/pipes";
import { LocalizationUtils } from "@shared/utils";

import {
  AcceptInvitationDialogComponent,
  AddAnalysisDialogComponent,
  AddCertificateDialogComponent,
  AddConnectionDialogComponent,
  AddDocumentDialogComponent,
  AddGpsDataDialogComponent,
  AddOrganisationDialogComponent,
  AdminOrganisationExtensionTableComponent,
  AdminOrganisationsTableComponent,
  AnalysesTableComponent,
  AnalysisDetailsSlideOverComponent,
  AttachmentsTableComponent,
  AutoOpenMenuComponent,
  BulkAddSlideOverComponent,
  CertificateListItemsComponent,
  CommentsComponent,
  ConnectionsTableComponent,
  CoordinatesFormComponent,
  CountryComponent,
  CustomFieldsDetailsComponent,
  CustomFieldsEditComponent,
  CustomFieldsTableComponent,
  DeliveriesTableComponent,
  DocumentListItemsComponent,
  DocumentTypesTableComponent,
  DragDropListContainerComponent,
  DragDropListItemComponent,
  EntitySaveFooterComponent,
  EntityTagsComponent,
  ExternalLinkIconComponent,
  FlagComponent,
  FlowChartNodeComponent,
  FullScreenSupplyChainDialogComponent,
  IndicatorComponent,
  InvitationsTableComponent,
  ItemsListItemsComponent,
  ItemsSupplyChainComponent,
  ItemsSupplyChainMapComponent,
  ItemsTableComponent,
  LanguageSelectorComponent,
  LoaderComponent,
  LocationListItemsComponent,
  LocationOverlayAddressCheckboxComponent,
  LocationOverlayAddressComponent,
  LocationOverlayComponent,
  LocationOverlayGpsComponent,
  LocationOverlayLinksComponent,
  LocationOverlayMainInformationComponent,
  LocationTypesTableComponent,
  LocationsTableComponent,
  MapComponent,
  MaterialsListItemsComponent,
  MaterialsTableComponent,
  MembershipsTableComponent,
  MoreActionsButtonsComponent,
  NotSharedDataComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
  PageTitleComponent,
  PdfSplitterComponent,
  ProcessInOutTableComponent,
  ProcessSelectedItemsTableComponent,
  ProcessTypesTableComponent,
  ProcessesTableComponent,
  ProductListItemsComponent,
  ProductSupplyChainComponent,
  ProductsTableComponent,
  QuantitySummaryTableComponent,
  RecordDetailsComponent,
  RichTextEditorComponent,
  RulesetsTableComponent,
  SearchAndFiltersComponent,
  SelectOrganisationDialogComponent,
  SendDialogComponent,
  ShareButtonComponent,
  ShareDialogComponent,
  SharedLocationOverlayComponent,
  SharesTableComponent,
  StandardsTableComponent,
  SupplyChainFlowChartComponent,
  SupplyChainMapComponent,
  SupplyChainsTableComponent,
  TableBatchActionsComponent,
  TableComponent,
  TableFooterComponent,
  TagsSelectorDialogComponent,
  TagsTableComponent,
  UnitsOfMeasurementListItemsComponent,
  UnitsOfMeasurementTableComponent,
  UsersInvitedTableComponent,
  UsersTableComponent,
  ValueAndCopyButtonComponent,
} from "..";
import { BulkAddCreationProgressComponent } from "./bulk-add/bulk-add-creation-progress.component";
import { BulkAddEditFieldDialogComponent } from "./bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.component";
import { ButtonComponent } from "./buttons/button/button.component";
import { ButtonGroupComponent } from "./buttons/button-group/button-group.component";
import { ButtonToggleComponent } from "./buttons/button-toggle/button-toggle.component";
import { CardContentComponent } from "./cards/card-content/card-content.component";
import { CommentCardContentComponent } from "./cards/comment-card-content/comment-card-content.component";
import { DeliveryCardContentComponent } from "./cards/delivery-card-content/delivery-card-content.component";
import { DocumentContentCardComponent } from "./cards/documents/components/document-content-card/document-content-card.component";
import { DraggableCardComponent } from "./cards/draggable-card/draggable-card.component";
import { GroupedProductCardContentComponent } from "./cards/grouped-product-card-content/grouped-product-card-content.component";
import { ItemCardContentComponent } from "./cards/item-card-content/item-card-content.component";
import { LocationCardContentComponent } from "./cards/location-card-content/location-card-content.component";
import { MaterialCardContentComponent } from "./cards/material-card-content/material-card-content.component";
import { ProductCardContentComponent } from "./cards/product-card-content/product-card-content.component";
import { UnitOfMeasurementCardComponent } from "./cards/unit-of-measurement-card/unit-of-measurement-card.component";
import { CertificateContentCardComponent } from "./certificates/components/certificate-content-card.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { CopyTextButtonComponent } from "./copy-text-button/copy-text-button.component";
import { CountriesTableComponent } from "./countries/ui";
import {
  DateRangePickerComponent,
  DatepickerComponent,
  DatepickerHeaderComponent,
} from "./datepicker";
import { DocumentListComponent } from "./document-list/document-list.component";
import { DocumentUploadStatusComponent } from "./document-upload-status/document-upload-status.component";
import { DragDropContainerComponent } from "./drag-drop-container/drag-drop-container.component";
import { DropdownButtonComponent } from "./dropdown/dropdown-button/dropdown-button.component";
import { DropdownMenuComponent } from "./dropdown/dropdown-menu/dropdown-menu.component";
import { DropdownMenuItemComponent } from "./dropdown/dropdown-menu-item/dropdown-menu-item.component";
import { EditableDivComponent } from "./editable-div/editable-div.component";
import { EmptySearchResultsComponent } from "./empty-search-results/empty-search-results.component";
import { FormFieldGroupComponent } from "./form/form-field-group/form-field-group.component";
import { FormHintComponent } from "./form/form-hint/form-hint.component";
import { FormInputComponent } from "./form/form-input/form-input.component";
import { FormInputReadonlyComponent } from "./form/form-input-readonly/form-input-readonly.component";
import { FormLabelComponent } from "./form/form-label/form-label.component";
import { FormLinkComponent } from "./form/form-link/form-link.component";
import { InfoDialogComponent } from "./info-dialog/info-dialog.component";
import { InputComponent } from "./inputs/input/input.component";
import { InputChipsComponent } from "./inputs/input-chips/input-chips.component";
import { InputPasswordComponent } from "./inputs/input-password/input-password.component";
import { InputSearchComponent } from "./inputs/input-search/input-search.component";
import { InputSelectComponent } from "./inputs/input-select/input-select.component";
import { SlideOverlayContentComponent } from "./overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayContentHeaderComponent } from "./overlay/slide-overlay-content-header/slide-overlay-content-header.component";
import { SlideOverlayHeaderComponent } from "./overlay/slide-overlay-header/slide-overlay-header.component";
import { SlideOverlayMenuItemDirective } from "./overlay/slide-overlay-menu-item/slide-overlay-menu-item.directive";
import { SlideOverlayPageComponent } from "./overlay/slide-overlay-page/slide-overlay-page.component";
import { SlideOverlayWrapperComponent } from "./overlay/slide-overlay-wrapper/slide-overlay-wrapper.component";
import { PdfSplitterDocumentFieldsComponent } from "./pdf-splitter/pdf-splitter-document-fields/pdf-splitter-document-fields.component";
import { RadioButtonGroupComponent } from "./radio-button/radio-button-group.component";
import { RadioButtonComponent } from "./radio-button/radio-button.component";
import { RightSidePanelComponent } from "./right-side-panel/right-side-panel.component";
import { RightSidePanelNoItemsComponent } from "./right-side-panel-no-items/right-side-panel-no-items.component";
import {
  AddRiskAssessmentReportDialogComponent,
  OverlayRiskAssessmentReportsComponent,
  RelatedRiskAssessmentReportsTableComponent,
  RiskAssessmentReportOverlayComponent,
  RiskAssessmentReportsTableComponent,
} from "./risk-assessment-reports/ui";
import { RiskAssessmentTemplatesTableComponent } from "./risk-assessment-templates/ui";
import { RiskLevelSetsTableComponent } from "./risk-level-sets/ui";
import {
  RiskMitigationCardComponent,
  RiskMitigationContentCardComponent,
  RiskMitigationsTableComponent,
} from "./risk-mitigations/ui";
import { OverlayRiskMitigationsRightPanelComponent } from "./risk-mitigations/ui/overlay-risk-mitigations-right-panel.component";
import { SettingsCardComponent } from "./settings-card/settings-card.component";
import { ShortcutAddMenuComponent } from "./shorcut-add-menu/shortcut-add-menu.component";
import { SidebarDropdownComponent } from "./sidebar/sidebar-dropdown/sidebar-dropdown.component";
import { SidebarGroupItemComponent } from "./sidebar/sidebar-group-item/sidebar-group-item.component";
import { SidebarItemComponent } from "./sidebar/sidebar-item/sidebar-item.component";
import { SidebarMenuComponent } from "./sidebar/sidebar-menu/sidebar-menu.component";
import { StepDirective } from "./stepper/step.directive";
import { StepperComponent } from "./stepper/stepper/stepper.component";
import { TableDatepickerComponent } from "./table-datepicker";
import { CertificatesTableComponent, DocumentsTableComponent } from "./tables";
import { TagGroupComponent } from "./tag/tag-group.component";
import { TagComponent } from "./tag/tag.component";
import { BasicTagsComponent } from "./tags/ui";
import {
  UploadDocumentsButtonsComponent,
  UploadDocumentsComponent,
  ValidateUploadDialogComponent,
} from "./upload-documents";
import { UploadFilesBoxComponent } from "./upload-files-box/upload-files-box.component";
import { UserAccountButtonComponent } from "./user-account-button/user-account-button.component";

const components = [
  LocationOverlayAddressCheckboxComponent,
  LocationOverlayLinksComponent,
  LocationOverlayAddressComponent,
  LocationOverlayMainInformationComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  SharingComponent,
  SignUpComponent,
  VerifyComponent,
  UploadDocumentsComponent,
  UploadDocumentsButtonsComponent,
  ValidateUploadDialogComponent,
  QuantitySummaryTableComponent,
  SupplyChainFlowChartComponent,
  CertificatesTableComponent,
  DeliveriesTableComponent,
  DocumentsTableComponent,
  ItemsTableComponent,
  AddMaterialDialogComponent,
  EditDocumentTypeDialogComponent,
  AddUserDialogComponent,
  LocationsTableComponent,
  MaterialsTableComponent,
  DocumentTypesTableComponent,
  UsersTableComponent,
  PageTitleComponent,
  AdminOrganisationsComponent,
  AdminLocationDetailsComponent,
  AdminOrganisationDetailsComponent,
  ConfirmDialogComponent,
  CopyTextButtonComponent,
  InfoDialogComponent,
  MaterialsListItemsComponent,
  CertificateListItemsComponent,
  DocumentListItemsComponent,
  LocationListItemsComponent,
  ItemsListItemsComponent,
  DragCursorDirective,
  ContentProjectionDirective,
  AutocompletePositionUpdateDirective,
  BackNavigationDirective,
  NoScrollInputDirective,
  FlagComponent,
  RightSidePanelNoItemsComponent,
  AddConnectionDialogComponent,
  MapComponent,
  FlowChartNodeComponent,
  InvitationsTableComponent,
  InvitationsComponent,
  AddOrganisationDialogComponent,
  SelectOrganisationDialogComponent,
  AcceptInvitationDialogComponent,
  AttachmentsTableComponent,
  MoreActionsButtonsComponent,
  SearchAndFiltersComponent,
  EditLocationTypeDialogComponent,
  LocationTypesTableComponent,
  EditProcessTypeDialogComponent,
  ProcessTypesTableComponent,
  ProcessesTableComponent,
  ProcessInOutTableComponent,
  ProcessSelectedItemsTableComponent,
  SettingsStandardsComponent,
  StandardsTableComponent,
  EditStandardDialogComponent,
  GetTypeRecordState,
  AddAnalysisDialogComponent,
  AnalysisDetailsSlideOverComponent,
  EntitySaveFooterComponent,
  ItemsSupplyChainComponent,
  UsersInvitedTableComponent,
  EditUserDialogComponent,
  TableComponent,
  TableBatchActionsComponent,
  TableFooterComponent,
  TagsSelectorDialogComponent,
  RulesetsTableComponent,
  LinkCellRendererComponent,
  DateCellRendererComponent,
  BadgeLinkCellRendererComponent,
  ColumnHeaderRendererComponent,
  ChipsCellRendererComponent,
  FirstExistingValueCellRendererComponent,
  ButtonsCellRendererComponent,
  ConnectionsTableComponent,
  SupplyChainsTableComponent,
  ProductsTableComponent,
  MembershipsTableComponent,
  AdminOrganisationsTableComponent,
  DateRangeCellRendererComponent,
  RoundNumberCellRendererComponent,
  SlideOverCellRendererComponent,
  AttachmentsTableComponent,
  UnitOfMeasurementCellRendererComponent,
  RulesetIconNameCellRendererComponent,
  QuickActionsMenuComponent,
  AttachProductCellRendererComponent,
  ShareButtonComponent,
  ShareDialogComponent,
  SharesTableComponent,
  ProductListItemsComponent,
  NotSharedDataComponent,
  SupplyChainMapComponent,
  LogoutComponent,
  LocationOverlayGpsComponent,
  ProductSupplyChainComponent,
  LocationOverlayComponent,
  SharedLocationOverlayComponent,
  EnumsCellRendererComponent,
  UnitsOfMeasurementTableComponent,
  EditCustomFieldDialogComponent,
  CustomFieldsTableComponent,
  CustomFieldsDetailsComponent,
  CustomFieldsEditComponent,
  DocumentOverlayComponent,
  EditDocumentMainDetailsComponent,
  OrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
  PublicOrganisationOverlayComponent,
  EditOrganisationMainDetailsComponent,
  AddLocationDialogComponent,
  CertificateOverlayComponent,
  OverlayDocumentAttachmentsComponent,
  OverlayCertificateAttachmentsComponent,
  DeliveryOverlayComponent,
  SlideOverlayDeliveryItemsComponent,
  EditItemQuantityDialogComponent,
  EditTagDialogComponent,
  TagsTableComponent,
  UnitsOfMeasurementListItemsComponent,
  AddItemDialogComponent,
  ItemsSupplyChainMapComponent,
  CommentsComponent,
  AdminOrganisationExtensionTableComponent,
  ProcessSelectedItemsComponent,
  AddProcessInputsOutputsComponent,
  AddProcessSelectedItemsComponent,
  ProcessInputsOutputsComponent,
  ProcessOverlayComponent,
  ItemOverlayComponent,
  FullScreenSupplyChainDialogComponent,
  ValueWithTooltipCellRendererComponent,
  SendDialogComponent,
  ReportsEudrComponent,
  ReportsEudrDdsComponent,
  ReportsEudrDdsCommodityComponent,
  ReportsEudrDdsProducerComponent,
  ReportsEudrProductionPlaceComponent,
  ReportsEudrProducerInfoComponent,
  ReportsEudrMaterialInfoComponent,
  ReportsEudrCommodityInfoComponent,
  MaterialsComponent,
  InboxComponent,
  InboxTableComponent,
  AddProductDialogComponent,
  TransferOrMapDataComponent,
  TrasferOrMapFormComponent,
  SharedLinkComponent,
  AnalysesTableComponent,
  MaterialOverlayComponent,
  BulkAddItemsComponent,
  BulkAddItemsSetValuesComponent,
  BulkAddItemsEnterRecordsComponent,
  BulkAddItemsCreateRecordsComponent,
  SharedDocumentOverlayComponent,
  AddCertificateDialogComponent,
  SharedCertificateOverlayComponent,
  CertificateOverlayMainInformationComponent,
  BulkAddSlideOverComponent,
  DeliveryReportComponent,
  SharedSupplyChainComponent,
  SharedDeliveryOverlayComponent,
  EditDeliveryMainDetailsComponent,
  DeliveryReportProcessesComponent,
  DeliveryReportProcessInfoComponent,
  DeliveryReportSupplyChainEntitiesComponent,
  DeliveryReportSupplyChainEntityComponent,
  DeliveryReportMainLocationsTableComponent,
  AddDocumentDialogComponent,
  RiskAssessmentTemplateOverlayComponent,
  RiskAssessmentTemplateOverlayIndicatorsComponent,
  DragDropListContainerComponent,
  DragDropListItemComponent,
  InputCellRendererComponent,
  DeliveryReportLocationDetailComponent,
  DeliveryReportLocationDetailsComponent,
  DeliveryReportLocationTypeGroupComponent,
  DeliveryReportCertificatesSummaryComponent,
  DeliveryReportLocationCountryGroupComponent,
  AddGpsDataDialogComponent,
  CoordinatesFormComponent,
  DeliveryReportOrganisationCertificatesDocumentsComponent,
  DeliveryReportOrganisationsByCountryComponent,
  DeliveryReportOrganisationDetailsComponent,
  AddEditRiskLevelSetDialogComponent,
  DeliveryReportOtherDeliveriesComponent,
  DeliveryReportOtherDeliveriesByGroupComponent,
  DeliveryReportOtherDeliveriesDeliveryDetailsComponent,
  BulkAddLocationsComponent,
  BulkAddLocationsSetValuesComponent,
  BulkAddLocationsEnterRecordsComponent,
  BulkAddLocationsCreateRecordsComponent,
  BulkAddEditFieldDialogComponent,
  BulkAddCreationProgressComponent,
  RiskAssessmentReportsTableComponent,
  RelatedRiskAssessmentReportsTableComponent,
  OverlayRiskAssessmentReportsComponent,
  BasicTagsComponent,
  RiskAssessmentReportOverlayComponent,
  AddRiskAssessmentReportDialogComponent,
  SharedItemOverlayComponent,
  ItemOverlayMainInformationComponent,
  SharedMaterialOverlayComponent,
  AddEditRiskMitigationDialogComponent,
  RiskMitigationContentCardComponent,
  ProductOverlayComponent,
  SharedProductOverlayComponent,
  RelatedProductsComponent,
  OverlayRiskMitigationsRightPanelComponent,
  RiskMitigationCardComponent,
  AddCountryDialogComponent,
  CountryOverlayComponent,
  DeliverySupplyChainDocumentsComponent,
  DeliverySupplyChainDocumentsTableComponent,
  DeliveryEudrDdsDetailsComponent,
  ButtonComponent,
  ButtonGroupComponent,
  CardContentComponent,
  CertificateContentCardComponent,
  CertificateContentCardComponent,
  DateRangePickerComponent,
  DatepickerComponent,
  DocumentContentCardComponent,
  DraggableCardComponent,
  DraggableCardComponent,
  DropdownButtonComponent,
  DropdownMenuComponent,
  DropdownMenuItemComponent,
  EmptySearchResultsComponent,
  FormHintComponent,
  FormInputComponent,
  FormLabelComponent,
  LocationCardContentComponent,
  ProductCardContentComponent,
  InputComponent,
  InputSearchComponent,
  InputSelectComponent,
  SidebarDropdownComponent,
  SidebarItemComponent,
  SidebarGroupItemComponent,
  SidebarMenuComponent,
  InputPasswordComponent,
  ButtonToggleComponent,
  SettingsCardComponent,
  UploadFilesBoxComponent,
  DocumentListComponent,
  RadioButtonComponent,
  RadioButtonGroupComponent,
  CheckboxComponent,
  DocumentUploadStatusComponent,
  MaterialCardContentComponent,
  ItemCardContentComponent,
  UserAccountButtonComponent,
  RightSidePanelComponent,
  TagComponent,
  TagGroupComponent,
  DragDropContainerComponent,
  DeliveryCardContentComponent,
  SlideOverlayPageComponent,
  SlideOverlayHeaderComponent,
  SlideOverlayContentComponent,
  SlideOverlayContentHeaderComponent,
  SlideOverlayMenuItemDirective,
  FormFieldGroupComponent,
  FormInputReadonlyComponent,
  CountryComponent,
  SlideOverlayWrapperComponent,
  UnitOfMeasurementCardComponent,
  CommentCardContentComponent,
  EditableDivComponent,
  InputChipsComponent,
  StepperComponent,
  StepDirective,
  ShortcutAddMenuComponent,
  LanguageSelectorComponent,
  GroupedProductCardContentComponent,
  PdfSplitterComponent,
  PdfSplitterDocumentFieldsComponent,
  CountriesTableComponent,
  BulkAddCreationProgressComponent,
  BulkAddEditFieldDialogComponent,
  AddRiskAssessmentReportDialogComponent,
  OverlayRiskAssessmentReportsComponent,
  RiskAssessmentReportOverlayComponent,
  RiskAssessmentReportsTableComponent,
  RiskAssessmentTemplatesTableComponent,
  RiskLevelSetsTableComponent,
  OverlayRiskMitigationsRightPanelComponent,
  RiskMitigationsTableComponent,
  DatepickerHeaderComponent,
  RecordDetailsComponent,
  EudrDdsAddReferenceDialogComponent,
  EudrDdsReferencesTabComponent,
  PdfSplitterPageThumbnailComponent,
  RichTextEditorComponent,
  TableDatepickerComponent,

  ConditionsMetCheckerPipe,
  EnumToTextPipe,
  LocalTimePipe,
  SafeUrlPipe,
  StripHtmlPipe,
  UnitConversionPipe,
  UnitSymbolPipe,
  SharedProcessOverlayComponent,

  AppDatePipe,
];

const standaloneComponents = [
  ValueAndCopyButtonComponent,
  EntityTagsComponent,
  AutoOpenMenuComponent,
  ExternalLinkIconComponent,
  FormLinkComponent,
  IndicatorComponent,
  LoaderComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    TippyDirective,
    AngularMaterialModule,
    AgGridModule,
    NgOptimizedImage,
    PdfViewerModule,
    QuillModule,
    ...standaloneComponents,
  ],
  exports: [...components, TippyDirective, ...standaloneComponents],
  providers: [
    AppDatePipe,
    DecimalPipe,
    AsyncPipe,
    UnitConversionPipe,
    { provide: LOCALE_ID, useValue: LocalizationUtils.getLocaleForAngularConfig() },
    provideTippyConfig({
      defaultVariation: "whiteTooltip",
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        whiteTooltip: {
          ...tooltipVariation,
          theme: "white",
          arrow: true,
        },
        whiteTooltipNoArrow: {
          ...tooltipVariation,
          theme: "white",
          arrow: false,
        },
      },
    }),
  ],
})
export class ShareComponentsModule {}
