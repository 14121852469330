import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";

import { CountriesApiService } from "@components/shared/countries/api";
import { CountryPayload } from "@components/shared/countries/models";
import { CommonConstants, TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";
import { NotificationService, CommonService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./add-country-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCountryDialogComponent implements OnInit, OnDestroy {
  private readonly fb = inject(FormBuilder);

  private readonly dialogRef: MatDialogRef<AddCountryDialogComponent> = inject(MatDialogRef);

  private readonly commonService = inject(CommonService);

  private readonly api = inject(CountriesApiService);

  private readonly notification = inject(NotificationService);

  readonly form = this.fb.group({
    name: this.fb.control<ISelectOption>(null, CustomValidators.required),
  });

  readonly isLoading = signal(true);

  readonly countryOptions = signal<ISelectOption[]>([]);

  private subscriptions = new Subscription();

  public readonly translations: any = {
    countryLabel: TextConstants.COUNTRY,
  };

  async ngOnInit(): Promise<void> {
    const existingCountriesResponse = await this.api.getAll({
      page: 0,
      size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
    });

    const existingCountriesCodes = existingCountriesResponse.content.map((c) => c.code);

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          if (countriesOptions?.length) {
            this.countryOptions.set(
              countriesOptions.filter((c) => !existingCountriesCodes.some((ec) => ec === c.value)),
            );

            this.isLoading.set(false);
          }
        },
      ),
    );
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isLoading.set(true);

    try {
      await this.api.create(this.buildPayload());
      this.notification.showSuccess($localize`Country created`);

      this.close(true);
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  close(hasSaved = false) {
    this.dialogRef.close(hasSaved);
  }

  private buildPayload(): CountryPayload {
    const { label, value } = this.form.getRawValue().name;

    return {
      name: label,
      code: value.toString(),
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
