import { Injectable } from "@angular/core";
import { Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { RouteEnum } from "../enums";
import { AuthenticationService } from "../services";

@Injectable()
export class LoggedInGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private overlay: SlideOverlayPageService,
    private notificationService: NotificationService,
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authenticationService.isLocked()) {
      this.router.navigate([`/${RouteEnum.LOGIN}`], { queryParams: { returnUrl: state.url } });

      return false;
    } else if (this.authenticationService.haveTokensExpired()) {
      this.notificationService.showError(TextConstants.SESSION_HAS_EXPIRED);
      this.overlay.close(true);
      this.authenticationService.logout();

      return false;
    } else {
      return true;
    }
  }
}
