import { inject, Component, ChangeDetectionStrategy, signal } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { AttachProductCellRendererConstants } from "@shared/cell-renderers/attach-product/attach-product.cell-renderer.model";
import { TextConstants } from "@shared/constants";
import { ILocationExtendedWithProducts } from "@shared/interfaces";
import { RouterService } from "@shared/services/router.service";

export interface IAttachProductCellRendererParams
  extends ICellRendererParams<ILocationExtendedWithProducts> {
  customParams: {
    canAddModifyEntities: boolean;
    onClickRemoveAttachment: (locationId: string, attachmentId: string) => void;
  };
}

@Component({
  standalone: false,
  templateUrl: "./attach-product.cell-renderer.component.html",
  styleUrls: ["./attach-product.cell-renderer.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AttachProductCellRendererComponent implements ICellRendererAngularComp {
  public params!: IAttachProductCellRendererParams;

  public isShowAll = signal(false);

  public readonly defaultMaxNumberProducts =
    AttachProductCellRendererConstants.DEFAULT_MAX_NUMBER_PRODUCTS;

  private routerService = inject(RouterService);

  public readonly translations: any = {
    removeTp: TextConstants.REMOVE,
  };

  agInit(params: IAttachProductCellRendererParams): void {
    this.params = params;
    this.params.data.attachedProductsData.cellRenderer = this;
  }

  refresh(): boolean {
    return false;
  }

  get allAttachments() {
    return this.params.data.attachedProductsData.attachments;
  }

  get attachmentsToDisplay() {
    if (this.isShowAll()) {
      return this.allAttachments;
    } else {
      return this.allAttachments.slice(0, this.defaultMaxNumberProducts);
    }
  }

  get displayShowMoreButton(): boolean {
    return this.allAttachments.length > this.defaultMaxNumberProducts && !this.isShowAll();
  }

  public onClickRemoveAttachment(attachmentId: string): void {
    this.params.customParams.onClickRemoveAttachment(this.params.data.id, attachmentId);
  }

  public onClickShowMore(): void {
    this.isShowAll.set(true);

    this.params.api.resetRowHeights();
  }

  public onClickRow(productId: string): void {
    this.routerService.openNewTab(this.routerService.getProductLink(productId, false));
  }
}
