<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Edit user</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <app-input formControlName="firstName" [label]="translations.firstNameLabel"></app-input>

      <app-input formControlName="lastName" [label]="translations.lastNameLabel"></app-input>

      <app-input
        formControlName="emailAddress"
        [label]="translations.emailAddressLabel"
        type="email"
      ></app-input>

      <app-input-select
        [emitRawValue]="true"
        [options]="userRolesOptions"
        formControlName="role"
        [label]="translations.roleLabel"
      ></app-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
