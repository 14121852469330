<div class="slide-over-content">
  <div class="slide-over-title-container container-space-between">
    <app-page-title icon="library_add" [title]="title()"></app-page-title>
  </div>
  <app-stepper>
    <ng-template
      appStep
      [title]="translations.firstStepTitle"
      [canGoBackTo]="true"
      (backClick)="onClickBackToFirstStep()"
    >
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-set-values
          [formGroup]="formGroup"
          [productOptions]="productOptions"
          [locationOptions]="locationOptions"
        ></app-bulk-add-items-set-values>
      }
    </ng-template>
    <ng-template
      appStep
      [title]="translations.secondStepTitle"
      [canGoForwardTo]="true"
      (forwardClick)="onClickNext()"
    >
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-enter-records
          #bulkAddItemsEnterRecords
          [formGroup]="formGroup"
          [locationOptions]="locationOptions"
          [productOptions]="productOptions"
        ></app-bulk-add-items-enter-records>
      }
    </ng-template>
    <ng-template appStep [title]="thirdStepTitle()">
      @if (isLoading()) {
        <app-loader></app-loader>
      } @else {
        <app-bulk-add-items-create-records
          (creationProcessComplete)="onFinishedCreationProcess()"
          [formGroup]="formGroup"
        ></app-bulk-add-items-create-records>
      }
    </ng-template>
  </app-stepper>
</div>

<div class="slide-over-buttons">
  <app-button-group class="bottom-buttons flex-end-content">
    @if (isCurrentStepEnterItems) {
      <app-button (pressed)="onClickBackToFirstStep()" type="outlined" i18n>Back</app-button>
    }

    @if (stepper.currentStepIndex() < stepsEnum.CREATE_ITEMS) {
      <app-button
        (pressed)="onClickNext()"
        [disabled]="isLoading() || isNextButtonDisabled"
        type="filled"
      >
        @if (isCurrentStepEnterItems) {
          {{ createItemsMsg() }}
        } @else {
          <ng-container i18n>Next</ng-container>
        }
      </app-button>
    } @else if (displayDoneButton()) {
      <app-button (pressed)="onClickDone()" type="filled" i18n>Done</app-button>
    } @else {
      <app-button (pressed)="onClickCancel()" type="filled" i18n>Cancel</app-button>
    }
  </app-button-group>
</div>
