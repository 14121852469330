import { computed, Injectable, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef, ValueGetterParams } from "ag-grid-community";

import { AddAnalysisDialogComponent } from "@components/locations";
import { ConfirmDialogComponent } from "@components/shared";
import {
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
  RoundNumberCellRendererComponent,
} from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  ExtensionProviderEnum,
  ConfirmDialogResponseEnum,
  RouteEnum,
  BatchActionTypeEnum,
} from "@shared/enums";
import { AnalysisTypeEnum } from "@shared/enums/analysis-type.enum";
import { IAnalysis } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import {
  NotificationService,
  AnalysisExtensionService,
  AuthenticationService,
  FeatureFlagService,
  LocationsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class AnalysesTableService {
  analyses = signal<IAnalysis[]>([]);

  isLoading = signal<boolean>(false);

  analysisCounter = computed(() => (this.isLoading() ? null : this.analyses().length));

  isAnalysisDetailOverlayVisible = signal<boolean>(false);

  analysisDetailOverlayItem = signal<IAnalysis>(undefined);

  locationId = signal<string>(undefined);

  hasGpsData = signal<boolean>(null);

  columnDefs = signal<ColDef[]>([]);

  isBatchActionsEnabled = signal<boolean>(false);

  batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private analysisExtensionService: AnalysisExtensionService,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private locationsService: LocationsService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public setLocationId = (locationId?: string): void => {
    this.locationId.set(locationId);
  };

  public setHasGpsData = (hasGpsData?: boolean): void => {
    this.hasGpsData.set(hasGpsData);
  };

  public setIsBatchActionsEnabled = (enabled: boolean): void => {
    this.isBatchActionsEnabled.set(enabled);
  };

  public setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled()) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DELETE,
            this.analysisExtensionService,
          ),
        ],
      ]),
    };
  };

  public setColumnDefs() {
    const referenceColumnQuickActions = [];

    if (this.featureFlagService.isDevOrStgEnv() && this.locationId()) {
      referenceColumnQuickActions.push({
        icon: "info",
        tooltipTemplate: "orbifyDeforestation",
        show: (row: IAnalysis) =>
          row.parameters?.type === AnalysisTypeEnum.ORBIFY_DEFORESTATION &&
          this.isAnalysisReady(row),
      });
    }

    if (this.authenticationService.canAddModifyEntities()) {
      referenceColumnQuickActions.push({
        icon: "delete",
        tooltip: TextConstants.DELETE,
        click: (row: IAnalysis) => this.remove(row),
      });
    }

    const commonColumnDefs: any[] = [
      {
        headerName: $localize`Reference`,
        field: "providerResponse.reportId",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          isLinkStyle: true,
          actions: referenceColumnQuickActions,
        },
      },
      {
        headerName: TextConstants.TYPE,
        field: "parameters.type",
      },
      {
        headerName: TextConstants.NAME,
        field: "name",
      },
      ColumnUtils.dateRangeColumn({
        headerName: $localize`Date range`,
        field: "startTime",
        cellRendererParams: {
          startDateParam: "startTime",
          endDateParam: "endTime",
        },
        filterValueGetter: (params: ValueGetterParams<IAnalysis>) => {
          const { startTime, endTime } = params.data;

          if (startTime && endTime) {
            return {
              start: ColumnUtils.dateFilterDate(startTime),
              end: ColumnUtils.dateFilterDate(endTime),
              filterOption: params.api.getFilterModel()["startTime"]["type"],
            };
          }

          return undefined;
        },
      }),
    ];

    if (!this.locationId()) {
      commonColumnDefs.push(
        ...[
          {
            headerName: $localize`Project area (ha)`,
            field: "providerResponse.projectAreaHa",
            cellRenderer: RoundNumberCellRendererComponent,
          },
          {
            headerName: $localize`Lost forest area (ha)`,
            field: "providerResponse.lostForestAreaHa",
            cellRenderer: RoundNumberCellRendererComponent,
          },
          {
            headerName: $localize`Lost forest area (%)`,
            field: "providerResponse.lostForestAreaPercent",
            cellRenderer: RoundNumberCellRendererComponent,
          },
        ],
      );
    }

    commonColumnDefs.push(
      ...[
        ColumnUtils.dateColumn({
          headerName: $localize`Created`,
          field: "created",
          cellRendererParams: {
            dateFormat: CommonConstants.DATE_TIME_FORMAT,
          },
        }),
        {
          headerName: TextConstants.STATUS,
          field: "status",
        },
      ],
    );

    if (this.isBatchActionsEnabled()) {
      commonColumnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.locationId()) {
      this.columnDefs.set(commonColumnDefs);
    } else {
      const locationColumnDefs = [
        {
          headerName: $localize`Location`,
          field: "location.name",
          cellRenderer: LinkCellRendererComponent,
          cellRendererParams: {
            linkRoute: this.authenticationService.isSystemAdminOrganisation()
              ? RouteEnum.ADMIN_LOCATIONS_DETAILS
              : undefined,
            linkRouteIdParam: "location.id",
            linkRouteFn: this.authenticationService.isSystemAdminOrganisation()
              ? undefined
              : this.routerService.getLocationLink,
          },
          suppressSizeToFit: true,
        },
        ColumnUtils.chips("Location type(s)", "location.types", {
          textParam: "type",
          showIcon: (locationType) => locationType.pointOfOrigin,
          icon: "target",
          iconTooltip: TextConstants.POINT_OF_ORIGIN_CHIP,
        }),
      ];

      this.columnDefs.set([...locationColumnDefs, ...commonColumnDefs]);
    }
  }

  public add() {
    if (!this.locationId()) {
      return;
    }
    const dialogRef = this.dialog.open(AddAnalysisDialogComponent, {
      data: {
        locationId: this.locationId(),
        hasGpsData: this.hasGpsData(),
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  }

  public reset(): void {
    this.isLoading.set(false);
    this.isAnalysisDetailOverlayVisible.set(false);
    this.analysisDetailOverlayItem.set(undefined);
  }

  public closeView = (): void => {
    this.analysisDetailOverlayItem.set(undefined);
    this.isAnalysisDetailOverlayVisible.set(false);
  };

  public openView = (row: IAnalysis): void => {
    if (this.isAnalysisReady(row)) {
      this.analysisDetailOverlayItem.set(row);
      this.isAnalysisDetailOverlayVisible.set(true);
    } else if (row.status === "PENDING") {
      this.notificationService.showError($localize`
        This report is still being generated. It will be available shortly`);
    } else if (row.status === "ERROR") {
      this.notificationService.showError($localize`An error occurred when generating this report`);
    }
  };

  public async getAll(): Promise<void> {
    this.isLoading.set(true);
    try {
      let rowData: any = await this.analysisExtensionService.getAll(
        this.locationId(),
        ExtensionProviderEnum.ORBIFY,
      );

      if (!this.locationId()) {
        const uniqueLocationIds: any[] = [
          ...new Set(rowData.map((r: IAnalysis) => CommonUtils.getUriId(r.location))),
        ];

        const uniqueLocations = await this.locationsService.getByIdsGraphQL(uniqueLocationIds);

        rowData = rowData.map((a) => {
          const locationId = CommonUtils.getUriId(a.location);
          const location = uniqueLocations.find((l) => l.id === locationId);

          return {
            ...a,
            location: {
              id: location?.id,
              name: location?.name,
              types: location?.types,
            },
          };
        });
      }

      this.analyses.set(rowData);
      this.isLoading.set(false);
    } catch (error) {
      this.analyses.set([]);
      this.notificationService.showError(error);
    }
  }

  private isAnalysisReady = (row: IAnalysis): boolean =>
    row.status === "AVAILABLE" && !!row.providerResponse?.response;

  private remove(row: IAnalysis): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this analysis report?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.analysisExtensionService.delete(row.id);
          this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
        }
      }
    });
  }
}
