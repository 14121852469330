import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
} from "@angular/core";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";

@Component({
  standalone: false,
  selector: "app-form-input-readonly",
  templateUrl: "./form-input-readonly.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormInputReadonlyComponent {
  label = input.required<string>();

  value = input<string>();

  valueTooltip = input<string>();

  loading = input<boolean>();

  tags = input<InputSelectOption[] | string[]>();

  copyMode = input<CopyModeEnum>(CopyModeEnum.CONTAINER);

  @Input({ transform: booleanAttribute }) isTagClickable: boolean = false;

  @Input() isTextarea = false;

  @Output() clickOnValue: EventEmitter<void> = new EventEmitter();

  @Output() tagPressed: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  readonly copyModeEnum = CopyModeEnum;

  public onValueClick(): void {
    this.clickOnValue.emit();
  }

  onTagClick(tag: InputSelectOption): void {
    if (this.isTagClickable) {
      this.tagPressed.emit(tag);
    }
  }

  public get valueClass(): string {
    let valueClass = "details-value";

    if (this.copyMode() === CopyModeEnum.ICON) {
      valueClass += " blue-link-color";
    }

    return valueClass;
  }
}
