import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-card-content",
  templateUrl: "./card-content.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContentComponent {
  @Input() type: CardContentTypeEnum = CardContentTypeEnum.ADD;

  @Output() remove: EventEmitter<void> = new EventEmitter();

  public readonly MAX_TAG_CHARS = 24;

  readonly cardContentTypeEnum = CardContentTypeEnum;

  public readonly translations: any = {
    removeTp: TextConstants.REMOVE,
  };

  onClickRemove(): void {
    this.remove.emit();
  }

  truncateTag(tag: string): string {
    return tag.length > this.MAX_TAG_CHARS ? `${tag.slice(0, this.MAX_TAG_CHARS)}...` : tag;
  }
}
