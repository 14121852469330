import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from "@angular/core";

import { FileUtils, FormUtils } from "src/app/shared/utils";

import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-upload-files-box",
  templateUrl: "./upload-files-box.component.html",
  styleUrl: "./upload-files-box.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilesBoxComponent {
  @Input() footerLeftText: string;

  @Input() footerRightText: string;

  @Input() maxSizeInMb: number;

  @Input() supportedFileTypes: string[];

  @Input() files: File[];

  @Input({ transform: booleanAttribute }) multiple: boolean = true;

  @Input({ transform: booleanAttribute }) validate: boolean = false;

  @Output() filesSelected: EventEmitter<File[]> = new EventEmitter();

  constructor(private notificationService: NotificationService) {}

  onFilesSelected(event: Event): void {
    this.files = [];

    const inputElement = event.target as HTMLInputElement;
    const fileList = inputElement.files;

    if (fileList) {
      const filesArray: File[] = Array.prototype.slice.call(fileList);

      this.files.push(...filesArray);
    }

    FormUtils.removeInputValue("files");

    if (this.multiple) {
      this.filesSelected.emit(this.files);
      this.files = null;

      return;
    }

    const file = this.files[0];

    if (this.validate) {
      const isValid = this.isValid(file);

      if (!isValid) {
        this.files = null;

        return;
      }
    }

    this.filesSelected.emit(this.files);
  }

  private isValid(file: File): boolean {
    if (this.maxSizeInMb && file.size > this.maxSizeInMb * 1000000) {
      this.notificationService.showError(
        $localize`Maximum file size is ${this.maxSizeInMb}:maxSizeInMb:MB.`,
      );

      return false;
    }

    if (this.supportedFileTypes?.length) {
      if (!this.supportedFileTypes.includes(FileUtils.getFileExtension(file.name).toUpperCase())) {
        this.notificationService.showError(
          $localize`Supported files: ${this.supportedFileTypes.join(", ")}:files:`,
        );

        return false;
      }
    }

    return true;
  }
}
