import { HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { catchError, debounceTime, EMPTY, Subject, Subscription, tap } from "rxjs";
import { CanDeactivateClass } from "src/app/shared/classes/can-deactivate.class";
import { CommonConstants, TextConstants } from "src/app/shared/constants";
import {
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  EntityTypeEnum,
  RecordStateEnum,
  RouteEnum,
  StartEndEnum,
  UploadFileErrorTypeEnum,
  UploadFileStatusEnum,
  UploadStepEnum,
} from "src/app/shared/enums";
import {
  IAttachmentPayload,
  IDocument,
  IDocumentPayload,
  IDocumentType,
  IFileUpload,
  IFileUploadStatusText,
  IName,
  ISelectOption,
  ITag,
  ITagExtended,
  ITagPayload,
} from "src/app/shared/interfaces";
import {
  AttachmentsService,
  DocumentsService,
  DocumentTypesService,
  TagsService,
} from "src/app/shared/services";
import { CommonUtils, FileUtils, FormUtils } from "src/app/shared/utils";

import { PdfSplitterModel } from "@components/shared/pdf-splitter/pdf-splitter.model";
import { UploadFileTestErrorEnum } from "@shared/enums/upload-file-test-error.enum";
import { NotificationService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

import { ValidateUploadDialogComponent } from ".";
import { ConfirmDialogComponent, InfoDialogComponent } from "..";

@Component({
  standalone: false,
  selector: "app-upload-documents",
  templateUrl: "./upload-documents.component.html",
  styleUrls: ["./upload-documents.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentsComponent extends CanDeactivateClass implements OnDestroy {
  @Input()
  public title = $localize`Upload documents`;

  @Input()
  public isShowTitleButtons = true;

  @Input()
  public isShowBackButton = true;

  @Input()
  public containerClass = "";

  @Input()
  public isShowDialogButtons = false;

  @Input()
  public isAttachProcess = false;

  @Input()
  public attachTargetUri: string = null;

  @Output()
  public filesAdded: EventEmitter<void> = new EventEmitter();

  @Output()
  public attachDocumentsCompleted: EventEmitter<any> = new EventEmitter();

  public readonly maxSizeInMb: number = CommonConstants.MAX_DOCUMENT_SIZE_IN_MB;

  public files: IFileUpload[] = [];

  public step: UploadStepEnum = UploadStepEnum.SELECT_FILES;

  public uploadStatusTexts = signal<IFileUploadStatusText[]>([]);

  public documentTypeOptions: ISelectOption[] = [];

  public readonly uploadStepEnum = UploadStepEnum;

  public readonly uploadFileStatusEnum = UploadFileStatusEnum;

  public readonly uploadFileErrorTypeEnum = UploadFileErrorTypeEnum;

  public readonly uploadBoxFooterLeftText = $localize`Supported files: ${CommonConstants.DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS.join(", ")}:files:`;

  public readonly uploadBoxFooterRightText = $localize`Max size: ${this.maxSizeInMb}:size:MB`;

  public readonly routingEnum = RouteEnum;

  validityStart = null;

  public allDocumentNames: string[] = [];

  private activeOrganisationId: string = null;

  private checkForDuplicatesSubject = new Subject();

  private subscriptions = new Subscription();

  constructor(
    private router: Router,
    private documentsService: DocumentsService,
    private documentTypesService: DocumentTypesService,
    private notificationService: NotificationService,
    private attachmentsService: AttachmentsService,
    private tagsService: TagsService,
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.onReloadDocumentTypes();
    this.onReloadDocumentNames();
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    this.subscriptions.add(
      this.checkForDuplicatesSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SEARCH_TIME_MS))
        .subscribe(() => {
          this.checkForDuplicatedFileNames();
        }),
    );
  }

  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    return this.step !== UploadStepEnum.UPLOAD_START && this.step !== UploadStepEnum.ATTACH_START;
  }

  public onFilesSelected = (files: File[], documentsValues?: PdfSplitterModel.ISplitData): void => {
    if (!files?.length) {
      return;
    }

    const addedFiles: IFileUpload[] = [];

    for (let i = 0; i < files.length; i++) {
      const addedFile = this.addFile(
        files[i],
        documentsValues?.documents?.length ? documentsValues.documents[i] : undefined,
      );

      addedFiles.push(addedFile);
    }

    const index = documentsValues?.index;

    if (index !== undefined && index !== null) {
      this.files = [...this.files.slice(0, index), ...addedFiles, ...this.files.slice(index + 1)];
    } else {
      this.files = [...this.files, ...addedFiles];
    }

    this.filesAdded.emit();

    this.checkForDuplicatedFileNames();
  };

  public onRemoveFile = (index: number): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure that you want to remove this document?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.files.splice(index, 1);
        this.files = [...this.files];
        this.checkForDuplicatedFileNames();
        this.changeDetectorRef.markForCheck();
      }
    });
  };

  public onSplitFile(splitData: PdfSplitterModel.ISplitData): void {
    this.onFilesSelected(
      splitData.documents.map((d) => d.file),
      { documents: splitData.documents, index: splitData.index },
    );
  }

  public onCancel = async (): Promise<void> => {
    if (this.isAttachProcess) {
      this.attachDocumentsCompleted.emit();
    } else {
      await this.router.navigate([`/${RouteEnum.DOCUMENTS}`]);
    }
  };

  public onDone = (): void => {
    if (this.isAttachProcess) {
      const attachedIds = this.files
        .filter((f) => f.status === UploadFileStatusEnum.ATTACHED)
        .map((f: any) => f.id);
      const uploadedIds = this.files
        .filter((f) => f.status === UploadFileStatusEnum.UPLOADED)
        .map((f: any) => f.id);

      this.attachDocumentsCompleted.emit({ attachedIds, uploadedIds });
    } else {
      this.router.navigate([`/${RouteEnum.DOCUMENTS}`]);
    }
  };

  override isSubmitButtonDisabled = (): boolean => !this.files.length;

  public isDoneButtonDisabled = (): boolean =>
    ![
      UploadStepEnum.UPLOAD_END_SUCCESS,
      UploadStepEnum.UPLOAD_END_ERROR,
      UploadStepEnum.ATTACH_END_ERROR,
      UploadStepEnum.ATTACH_END_SUCCESS,
    ].includes(this.step);

  public onViewFile = async (index: number): Promise<void> => {
    const fileInfo = this.files[index].info;
    const base64FileData = await FileUtils.getBase64File(fileInfo);

    FileUtils.openFileInNewTab(base64FileData, fileInfo.type);
  };

  public onRetryAll = async (): Promise<void> => {
    const failedFiles = this.files.filter((f) =>
      [
        UploadFileStatusEnum.DOCUMENT_CREATION_FAILED,
        UploadFileStatusEnum.FILE_UPLOAD_FAILED,
        UploadFileStatusEnum.FILE_ATTACH_FAILED,
        UploadFileStatusEnum.FILE_ADD_TAGS_FAILED,
      ].includes(f.status),
    );

    for (const file of failedFiles) {
      this.onUpload(file, true);
    }
  };

  public onTryUploadAll = async (): Promise<void> => {
    const formInvalid = this.files.find((f) => f.formGroup.invalid);

    if (formInvalid) {
      this.files.forEach((f) => FormUtils.findAndMarkInvalidControls(f.formGroup));
      this.files = [...this.files];
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    const filesWithErrors = this.files.filter((f) => f.errors.length);

    if (!filesWithErrors.length) {
      await this.onUploadAll();
    } else {
      const isAllFilesHaveErrors = filesWithErrors.length === this.files.length;

      if (isAllFilesHaveErrors) {
        this.dialog.open(InfoDialogComponent, {
          data: {
            icon: "error",
            iconColor: "red",
            contentText: $localize`All selected documents have errors`,
          },
        });
      } else {
        const dialogRef = this.dialog.open(ValidateUploadDialogComponent, {
          data: {
            filesWithErrors,
          },
        });

        dialogRef.afterClosed().subscribe(async (result: { hasConfirmed: boolean }) => {
          if (result?.hasConfirmed) {
            this.files = [...this.files.filter((f) => !f.errors?.length)];
            await this.onUploadAll();
          }
        });
      }
    }
  };

  public onUploadWithRetry = async (file: IFileUpload): Promise<void> => {
    await this.onUpload(file, true);
  };

  public onUpload = async (file: IFileUpload, isRetry = false): Promise<void> => {
    this.step = UploadStepEnum.UPLOAD_START;

    if (file.id && file.uploadProgress === 100 && this.isAttachProcess) {
      file.status = UploadFileStatusEnum.FILE_ATTACH_START;
    } else {
      file.uploadProgress = 0;

      if (!file.status || file.status === UploadFileStatusEnum.DOCUMENT_CREATION_FAILED) {
        file.status = UploadFileStatusEnum.DOCUMENT_CREATION_START;
      } else if (file.status === UploadFileStatusEnum.FILE_ADD_TAGS_FAILED) {
        file.status = UploadFileStatusEnum.DOCUMENT_CREATED;
      } else if (file.status === UploadFileStatusEnum.FILE_UPLOAD_FAILED) {
        file.status = UploadFileStatusEnum.TAGS_CREATED;
      }
    }

    this.setUploadStatus();

    if (file.status === UploadFileStatusEnum.DOCUMENT_CREATION_START) {
      await this.createDocument(file, isRetry);
    }
    if (file.status === UploadFileStatusEnum.DOCUMENT_CREATED) {
      await this.createTags(file, isRetry);
    }
    if (file.status === UploadFileStatusEnum.TAGS_CREATED) {
      await this.createContent(file, isRetry);
    }
    if (file.status === UploadFileStatusEnum.FILE_ATTACH_START && this.isAttachProcess && isRetry) {
      await this.createAttachment(file, isRetry);
    }
  };

  override async canExit(): Promise<boolean> {
    if (this.authenticationService.haveTokensExpired()) {
      return true;
    }
    let dialogText: string;

    if (this.requiresConfirmation() && this.canDeactivate()) {
      dialogText = $localize`You have unsaved changes. Are you sure that you want to exit?`;
    }
    if (this.requiresConfirmation() || !this.canDeactivate()) {
      const response =
        await this.canDeactivateDialogService.showUploadConfirmationDialog(dialogText);

      return response === ConfirmDialogResponseEnum.CONFIRM;
    }

    return true;
  }

  override requiresConfirmation(): boolean {
    return this.files?.some(
      (file) =>
        file.status !== UploadFileStatusEnum.UPLOADED &&
        file.status !== UploadFileStatusEnum.ATTACHED,
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private addFile = (info: File, documentValues?: PdfSplitterModel.IDocument): IFileUpload => {
    const file: IFileUpload = {
      id: null,
      status: null,
      uploadProgress: 0,
      errors: [],
      canBeSplit: signal(false),
      extension: `${FileUtils.getFileExtension(info.name).toLowerCase()}`,
      info,
      formGroup: new UntypedFormGroup(
        {
          name: new UntypedFormControl(
            documentValues?.name || FileUtils.getFileNameWithoutExtension(info.name),
            [
              CustomValidators.required,
              CustomValidators.fileSize(info, this.maxSizeInMb * 1000000),
              CustomValidators.fileExtension(
                info,
                CommonConstants.DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS,
              ),
            ],
          ),
          type: new UntypedFormControl(documentValues?.type, [CustomValidators.required]),
          issuance: new UntypedFormControl(documentValues?.issuance),
          validityStart: new UntypedFormControl(documentValues?.validityStart),
          validityEnd: new UntypedFormControl(documentValues?.validityEnd),
          isDatesEnabled: new UntypedFormControl(documentValues?.isDatesEnabled),
          tags: new UntypedFormControl(documentValues?.tags || []),
        },
        CustomValidators.dateRules(CommonConstants.DOCUMENT_DATE_RULES),
      ),
    };

    this.subscriptions.add(
      file.formGroup
        .get("name")
        .valueChanges.subscribe(() => this.checkForDuplicatesSubject.next(true)),
    );

    file.formGroup.get("name").markAsTouched();

    FileUtils.isPdfFile(info).then((result) => file.canBeSplit.set(result));

    return file;
  };

  private removeFileError = (file: IFileUpload, errorType: UploadFileErrorTypeEnum): void => {
    const duplicatedErrorIndex = file.errors.findIndex((e) => e.type === errorType);

    if (duplicatedErrorIndex !== -1) {
      file.errors.splice(duplicatedErrorIndex, 1);
    }
  };

  private checkForDuplicatedFileNames = (): void => {
    for (const file of this.files) {
      const fullFilename =
        `${file.formGroup.controls["name"].value.trim()}.${file.extension}`.toLowerCase();

      this.removeFileError(file, UploadFileErrorTypeEnum.DUPLICATED_NAME);

      if (
        this.files.filter(
          (f) =>
            `${f.formGroup.controls["name"].value.trim()}.${f.extension}`.toLowerCase() ===
            fullFilename,
        ).length > 1 ||
        this.allDocumentNames.some((d) => d === fullFilename)
      ) {
        file.errors = [
          ...file.errors,
          {
            type: UploadFileErrorTypeEnum.DUPLICATED_NAME,
          },
        ];
      }
    }
  };

  private onUploadAll = async (): Promise<void> => {
    await this.createNewDocumentTypes();
    for (const file of this.files) {
      this.onUpload(file);
    }
  };

  private getEntityUri(file: IFileUpload): string {
    return `/organisations/${this.activeOrganisationId}/${EntityTypeEnum.DOCUMENTS}/${file.id}`;
  }

  private async loadTags(entityUri: string): Promise<ITag[]> {
    return await this.tagsService.getAll(entityUri);
  }

  private async createTags(file: IFileUpload, isRetry?: boolean): Promise<void> {
    const fileTypeOption = file.formGroup.controls["type"].value;

    if (fileTypeOption.label === UploadFileTestErrorEnum.CREATE_TAGS_FAIL_TEST && !isRetry) {
      file.status = UploadFileStatusEnum.FILE_ADD_TAGS_FAILED;
      this.setStepAndStatus();

      return;
    }

    let tags: ITagExtended[] = file.formGroup.controls["tags"].value;
    const entityUri = this.getEntityUri(file);

    if (isRetry) {
      const persistedTags = await this.loadTags(entityUri);

      tags = tags.filter((tag) => {
        return !persistedTags.some(
          (pTag) => CommonUtils.getUriId(pTag.definition) === tag.tagDefinition.id,
        );
      });
    }

    const promises = tags.map((tag) => {
      const payload: ITagPayload = { entity: entityUri, definition: tag.definition };

      return this.tagsService.addToRecord(payload);
    });

    try {
      await Promise.all(promises);
      file.status = UploadFileStatusEnum.TAGS_CREATED;
    } catch {
      file.status = UploadFileStatusEnum.FILE_ADD_TAGS_FAILED;
    }
  }

  private createDocument = async (file: IFileUpload, isRetry = false): Promise<void> => {
    const fileTypeOption = file.formGroup.controls["type"].value;

    if (fileTypeOption.label === UploadFileTestErrorEnum.CREATE_DOCUMENT_FAIL_TEST && !isRetry) {
      file.status = UploadFileStatusEnum.DOCUMENT_CREATION_FAILED;
      this.setStepAndStatus();

      return;
    }

    const payload: IDocumentPayload = {
      name: `${file.formGroup.controls["name"].value.trim()}.${file.extension}`,
      type: `/organisations/${this.activeOrganisationId}/document-types/${fileTypeOption.value}`,
    };

    if (file.formGroup.controls["isDatesEnabled"].value) {
      payload.issuance = FormUtils.getDateValueForPayload(
        file.formGroup.controls["issuance"].value,
      );
      payload.validityStart = FormUtils.getDateValueForPayload(
        file.formGroup.controls["validityStart"].value,
      );
      payload.validityEnd = FormUtils.getDateValueForPayload(
        file.formGroup.controls["validityEnd"].value,
        StartEndEnum.END,
      );
    }

    try {
      const response = await this.documentsService.createOrUpdate(payload);

      file.id = response.id;
      file.status = UploadFileStatusEnum.DOCUMENT_CREATED;

      this.allDocumentNames = [...this.allDocumentNames, payload.name.toLowerCase()];
    } catch (error) {
      file.status = UploadFileStatusEnum.DOCUMENT_CREATION_FAILED;
      console.error(error);
    } finally {
      this.setStepAndStatus();
    }
  };

  private createContent = async (file: IFileUpload, isRetry = false): Promise<void> => {
    const fileType = file.formGroup.controls["type"].value.label;

    if (fileType === UploadFileTestErrorEnum.CREATE_CONTENT_FAIL_TEST && !isRetry) {
      file.status = UploadFileStatusEnum.FILE_UPLOAD_FAILED;
      this.setStepAndStatus();

      return;
    }

    this.subscriptions.add(
      this.documentsService
        .uploadContent(file.id, file.info)
        .pipe(
          tap((event: HttpEvent<HttpEventType.UploadProgress | HttpEventType.Response>) => {
            switch (event.type) {
              case HttpEventType.UploadProgress: {
                const progress = Math.round((event.loaded / (event.total ?? 0)) * 100);

                // This is because on fast networks, the file will upload very quickly to 100%
                // However the backend is still doing its thing, so it will take time to finish the request.
                file.uploadProgress = progress === 100 ? 99 : progress;
                this.changeDetectorRef.markForCheck();
                break;
              }
              case HttpEventType.Response: {
                file.status = UploadFileStatusEnum.UPLOADED;
                file.uploadProgress = 100;
                this.changeDetectorRef.markForCheck();
                if (this.isAttachProcess) {
                  file.status = UploadFileStatusEnum.FILE_ATTACH_START;
                  this.createAttachment(file, isRetry);
                }
                this.setStepAndStatus();
                break;
              }
            }

            this.files = [...this.files];
          }),
          catchError((error) => {
            console.error(error);
            file.status = UploadFileStatusEnum.FILE_UPLOAD_FAILED;
            this.setStepAndStatus();

            return EMPTY;
          }),
        )
        .subscribe(),
    );
  };

  private createAttachment = async (file: IFileUpload, isRetry = false): Promise<void> => {
    const fileType = file.formGroup.controls["type"].value.label;

    if (fileType === UploadFileTestErrorEnum.ATTACH_FAIL_TEST && !isRetry) {
      file.status = UploadFileStatusEnum.FILE_ATTACH_FAILED;
      this.setStepAndStatus();

      return;
    }

    file.status = UploadFileStatusEnum.FILE_ATTACH_START;

    const payload: IAttachmentPayload = {
      targetUri: this.attachTargetUri,
      attachmentUri: CommonUtils.getAttachmentUri(
        this.activeOrganisationId,
        AttachmentTypeEnum.DOCUMENT,
        file.id,
      ),
    };

    await this.attachmentsService
      .create(payload)
      .then(() => {
        file.status = UploadFileStatusEnum.ATTACHED;
        this.setStepAndStatus();
      })
      .catch((error: HttpErrorResponse) => {
        console.error(error);
        //todo what to display here?
        file.status = UploadFileStatusEnum.FILE_ATTACH_FAILED;
        this.setStepAndStatus();
      });
  };

  private setStepAndStatus = (): void => {
    const isUploading = this.files.some((f) =>
      [
        UploadFileStatusEnum.FILE_UPLOAD_START,
        UploadFileStatusEnum.DOCUMENT_CREATED,
        UploadFileStatusEnum.TAGS_CREATED,
      ].includes(f.status),
    );
    const isAttaching = this.files.some((f) =>
      [UploadFileStatusEnum.FILE_ATTACH_START].includes(f.status),
    );
    let step = this.step;

    if (!isUploading && !isAttaching) {
      const uploadedFilesCount = this.files.filter(
        (f) => f.status === UploadFileStatusEnum.UPLOADED,
      ).length;

      step =
        uploadedFilesCount === this.files.length
          ? UploadStepEnum.UPLOAD_END_SUCCESS
          : UploadStepEnum.UPLOAD_END_ERROR;

      if (this.isAttachProcess) {
        const attachedFilesCount = this.files.filter(
          (f) => f.status === UploadFileStatusEnum.ATTACHED,
        ).length;

        step =
          attachedFilesCount === this.files.length
            ? UploadStepEnum.ATTACH_END_SUCCESS
            : UploadStepEnum.ATTACH_END_ERROR;
        if (step === UploadStepEnum.ATTACH_END_SUCCESS) {
          this.step = step;
          this.setUploadStatus();
          this.onDone();

          return;
        }
      }
    }

    this.step = step;
    this.setUploadStatus();
  };

  private setUploadStatus = (): void => {
    this.uploadStatusTexts.set([]);
    let uploadedCount = 0;

    if (this.isAttachProcess) {
      uploadedCount = this.files.filter((f) => f.status === UploadFileStatusEnum.ATTACHED).length;
    } else {
      uploadedCount = this.files.filter((f) => f.status === UploadFileStatusEnum.UPLOADED).length;
    }
    const totalCount = this.files.length;

    switch (this.step) {
      case UploadStepEnum.UPLOAD_START:
        this.uploadStatusTexts.set([
          {
            text:
              totalCount > 1
                ? $localize`Uploading ${uploadedCount + 1}:count: of ${totalCount}:total: documents...`
                : $localize`Uploading document...`,
          },
        ]);
        break;
      case UploadStepEnum.UPLOAD_END_SUCCESS:
      case UploadStepEnum.ATTACH_END_SUCCESS:
        this.uploadStatusTexts.set([
          {
            text:
              totalCount > 1
                ? $localize`${totalCount}:total: documents uploaded`
                : $localize`Document uploaded`,
            class: "green",
          },
        ]);
        break;
      case UploadStepEnum.UPLOAD_END_ERROR:
      case UploadStepEnum.ATTACH_END_ERROR:
        if (uploadedCount > 0) {
          this.uploadStatusTexts.set([
            ...this.uploadStatusTexts(),
            {
              text: $localize`${uploadedCount}:count: documents uploaded`,
              class: "green",
            },
          ]);
        }

        this.uploadStatusTexts.set([
          ...this.uploadStatusTexts(),
          {
            text: $localize`${totalCount - uploadedCount}:count: documents upload failed`,
            class: "red",
          },
        ]);

        break;
    }
  };

  private createNewDocumentTypes = async (): Promise<void> => {
    for (const file of this.files) {
      const isNewType = !file.formGroup.controls["type"].value.value;

      if (isNewType) {
        await this.createNewDocumentType(file);
      }
    }
  };

  private createNewDocumentType = async (file: IFileUpload): Promise<void> => {
    const typeLabel = file.formGroup.controls["type"].value.label.trim().toLowerCase();
    const existingType = this.documentTypeOptions.find(
      (t) => t.label.trim().toLowerCase() === typeLabel,
    );

    if (existingType?.value) {
      file.formGroup.controls["type"].setValue(existingType);
    } else {
      const payload: IName = { name: file.formGroup.controls["type"].value.label.trim() };

      await this.documentTypesService
        .createOrUpdate(payload)
        .then((response: IDocumentType) => {
          const newType: ISelectOption = { label: response.name, value: response.id };

          existingType.value = newType.value;
          file.formGroup.controls["type"].setValue(newType);
        })
        .catch((error: HttpErrorResponse) => {
          this.notificationService.showError(error);
        });
    }
  };

  private onReloadDocumentTypes = async (): Promise<void> => {
    await this.documentTypesService
      .getAll(RecordStateEnum.ACTIVE)
      .then((response: IDocumentType[]) => {
        this.documentTypeOptions = [
          ...this.documentTypeOptions,
          ...response.map((d: IDocumentType) => ({
            label: d.name,
            value: d.id,
          })),
        ];
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private onReloadDocumentNames = async (): Promise<void> => {
    await this.documentsService
      .getAll(false)
      .then((response: IDocument[]) => {
        this.allDocumentNames = response.map((d: IDocument) => d.name.trim().toLowerCase());
        this.checkForDuplicatedFileNames();
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };
}
