import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-drag-drop-container",
  templateUrl: "./drag-drop-container.component.html",
  styleUrl: "./drag-drop-container.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropContainerComponent<T> {
  @Input() itemType: string;

  @Input() class: string;

  @Input() placeHolder: string = $localize`Drag and drop records here or click "+" button`;

  @Output() itemDropped: EventEmitter<CdkDragDrop<T>> = new EventEmitter();

  onItemDropped(event: CdkDragDrop<T>): void {
    this.itemDropped.emit(event);
  }
}
