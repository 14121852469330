import { ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { NavigationParams } from "@shared/services/router.service";
import { CellRendererUtils } from "@shared/utils";

@Component({
  standalone: false,
  template: `
    @if (params()["badgeIcon"]) {
      <div class="container-flex">
        <app-form-link [link]="link()">
          {{ value() }}
        </app-form-link>
        &nbsp; &nbsp;
        @if (tooltipArray()?.length) {
          <mat-icon [tp]="params()['tooltipTemplate'] ? tooltipTemplate : ''">
            {{ params()["badgeIcon"] }}
          </mat-icon>
        }
      </div>
    } @else if (!!value()) {
      <app-form-link
        [tp]="params()['tooltipTemplate'] ? tooltipTemplate : ''"
        [tpIsEnabled]="!!tooltipArray()?.length"
        [tpIsLazy]="true"
        [link]="link()"
      >
        <div class="app-badge clickable">
          {{ value() }}
        </div>
      </app-form-link>
    } @else {
      -
    }

    <ng-template #tooltipTemplate>
      @switch (params()["tooltipTemplate"]) {
        @case ("keyCount") {
          @if (params()["tooltipHeader"]) {
            <div class="title text-center">{{ params()["tooltipHeader"] }}</div>
          }
          @for (element of tooltipArray(); track element) {
            {{ element }}
            <br />
          }
        }
      }
    </ng-template>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeLinkCellRendererComponent implements ICellRendererAngularComp {
  params = signal<ICellRendererParams>(undefined);

  link = signal<NavigationParams>(undefined);

  value = signal<number>(0);

  tooltipArray = signal<any[]>([]);

  public agInit(params: ICellRendererParams): void {
    this.params.set(params);
    const data = this.getData();

    this.link.set(CellRendererUtils.getLink(params, data));
    this.value.set(params["badgeValue"] ? params["badgeValue"](data) : 0);
    this.tooltipArray.set(params["tooltipArray"] ? params["tooltipArray"](data) : []);
  }

  public refresh(): boolean {
    return false;
  }

  private getData(): unknown {
    const params = this.params();

    return params.data ?? params.node.allLeafChildren[0].data;
  }
}
