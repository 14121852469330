import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { defaultIfEmpty, filter, lastValueFrom } from "rxjs";

import {
  AddEditRiskMitigationDialogComponent,
  AddEditRiskMitigationDialogData,
} from "@components/settings/settings-risk-mitigations";
import { TextConstants } from "@shared/constants";
import { RecordStateEnum } from "@shared/enums";

import { RiskMitigationsApiService } from "../api";
import { RiskMitigation } from "../models";

@Component({
  standalone: false,
  selector: "app-overlay-risk-mitigations-right-panel",
  templateUrl: "./overlay-risk-mitigations-right-panel.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayRiskMitigationsRightPanelComponent implements OnInit {
  private readonly dialog = inject(MatDialog);

  private readonly riskMitigationsApi = inject(RiskMitigationsApiService);

  readonly saveToMitigationsListOption = input(false);

  readonly excludeIds = input<string[]>([]);

  readonly add = output<RiskMitigation>();

  private readonly searchText = signal<string>(undefined);

  private readonly mitigations = signal<RiskMitigation[]>([]);

  readonly availableMitigations = signal<RiskMitigation[]>([]);

  readonly isMitigationsLoading = signal(true);

  public readonly translations: any = {
    addNewTp: TextConstants.ADD_NEW,
  };

  private readonly filteredMitigations = computed(() =>
    this.excludeIds().length
      ? this.mitigations().filter((mitigation) => !this.excludeIds().includes(mitigation.id))
      : this.mitigations(),
  );

  constructor() {
    effect(() => this.onSearchMitigations(this.searchText()));
  }

  async ngOnInit() {
    await this.loadMitigations();
  }

  async onSearchMitigations(text: string) {
    this.searchText.set(text);

    if (!text) {
      this.availableMitigations.set(this.filteredMitigations());
    } else {
      const matches = this.filteredMitigations().filter((riskMitigation) =>
        riskMitigation.mitigation.toLowerCase().includes(text.toLowerCase()),
      );

      this.availableMitigations.set(matches);
    }
  }

  async onAdd() {
    const dialogRef = this.dialog.open<
      AddEditRiskMitigationDialogComponent,
      AddEditRiskMitigationDialogData,
      RiskMitigation
    >(AddEditRiskMitigationDialogComponent, {
      data: { saveToListOption: this.saveToMitigationsListOption() },
    });

    const mitigation = await lastValueFrom(
      dialogRef.afterClosed().pipe(filter(Boolean), defaultIfEmpty(null)),
    );

    if (mitigation) {
      this.add.emit(mitigation);
    }

    if (mitigation.id) {
      this.mitigations.update((currentMitigations) => [...currentMitigations, mitigation]);
    }
  }

  private async loadMitigations(): Promise<void> {
    this.isMitigationsLoading.set(true);

    const { content } = await this.riskMitigationsApi.getAll({
      recordState: RecordStateEnum.ACTIVE,
    });

    this.mitigations.set(content);
    this.isMitigationsLoading.set(false);
  }
}
