import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  input,
  OnInit,
  Output,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";

import { TippyDirective } from "@ngneat/helipopper";

import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  selector: "app-value-and-copy-button",
  templateUrl: "./value-and-copy-button.component.html",
  styleUrls: ["./value-and-copy-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIcon, TippyDirective, CommonModule],
  standalone: true,
})
export class ValueAndCopyButtonComponent implements OnInit {
  value = input<string>();

  valueTooltip = input<string>();

  showCopyIcon = input<boolean>(true);

  @Input() valueClass: string;

  @Input() copyMode: CopyModeEnum = CopyModeEnum.CONTAINER;

  @HostBinding("class")
  @Input()
  appearance: "input" | "textarea" = "input";

  @Output() clickOnValue: EventEmitter<void> = new EventEmitter();

  public hasValue = false;

  readonly copyModeEnum = CopyModeEnum;

  readonly translations = {
    copy: $localize`Copy`,
  };

  constructor(private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.hasValue = !!this.value();
  }

  public onValueClick(): void {
    this.clickOnValue.emit();
  }

  public onClick = (): void => {
    CommonUtils.textToClipboard(this.value());
    this.notificationService.showSuccess(TextConstants.COPIED);
  };
}
