import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { AppDatePipe, LocalTimePipe } from "@shared/pipes";
import { CellRendererUtils, DateUtils } from "@shared/utils";

import { CommonConstants } from "../constants";

@Component({
  standalone: false,
  template: `
    @if (hasLink) {
      <app-form-link [link]="getLink()">{{ getDate() }}</app-form-link>
    } @else {
      {{ getDate() }}
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  hasLink = false;

  constructor(
    private appDatePipe: AppDatePipe,
    private localTimePipe: LocalTimePipe,
  ) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;

    this.hasLink = !!(
      this.params.value &&
      (this.params["linkRoute"] || this.params["linkRouteIdParam"] || this.params["linkRouteFn"])
    );
  }

  refresh(): boolean {
    return false;
  }

  getDate = (): string => {
    if (!this.params.value) {
      return "-";
    }
    if (this.params["dateFormat"] === CommonConstants.DATE_TIME_FORMAT) {
      return this.localTimePipe.transform(
        DateUtils.getFormattedDateForPipe(this.params.value),
        CommonConstants.DATE_TIME_FORMAT,
      );
    }

    return this.appDatePipe.transform(
      DateUtils.getFormattedDateForPipe(this.params.value),
      this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
    );
  };

  getLink = () => CellRendererUtils.getLink(this.params, this.getData());

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
