import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { PdfSplitterModel } from "@components/shared/pdf-splitter/pdf-splitter.model";

@Component({
  selector: "app-pdf-splitter-page-thumbnail",
  templateUrl: "./pdf-splitter-page-thumbnail.component.html",
  styleUrls: ["./pdf-splitter-page-thumbnail.component.scss"],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfSplitterPageThumbnailComponent {
  page = input.required<FormControl<PdfSplitterModel.IPage>>();

  documentForm = input.required<FormGroup<PdfSplitterModel.IDocumentFormField>>();

  selectedPage = input.required<number>();

  pdfSrc = input.required<string>();

  pageClick = output<number>();

  public readonly translations: any = {
    excludeTp: $localize`Exclude`,
    includeTp: $localize`Include`,
  };

  pageRotation = output<{
    pageForm: FormControl<PdfSplitterModel.IPage>;
    rotation: PdfSplitterModel.Rotation;
  }>();

  pageInclusion = output<{
    pageForm: FormControl<PdfSplitterModel.IPage>;
    isIncluded: boolean;
  }>();

  rotationDirectionEnum = PdfSplitterModel.RotationDirectionEnum;

  rotatePage(event: MouseEvent, direction: PdfSplitterModel.RotationDirectionEnum): void {
    event.stopPropagation();

    const currentRotation = this.page().value.rotation;
    const rotationAmount = direction === PdfSplitterModel.RotationDirectionEnum.RIGHT ? 90 : -90;

    const newRotation = ((((currentRotation + rotationAmount) % 360) + 360) %
      360) as PdfSplitterModel.Rotation;

    this.pageRotation.emit({
      pageForm: this.page(),
      rotation: newRotation,
    });
  }

  onExcludePage(event: MouseEvent): void {
    event.stopPropagation();

    this.pageInclusion.emit({
      pageForm: this.page(),
      isIncluded: false,
    });
  }

  onIncludePage(event: MouseEvent): void {
    event.stopPropagation();

    this.pageInclusion.emit({
      pageForm: this.page(),
      isIncluded: true,
    });
  }
}
