@if (formValueChanges$ | async) {}

<app-slide-overlay-header
  icon="select_check_box"
  [title]="title()"
  [canDelete]="true"
  [canArchive]="true"
/>

<app-slide-overlay-content #slideOverlayContent contentClass="full-height">
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
    [overlay-menu-tag]="completedIndicatorsCount()"
    [overlay-menu-tooltip]="indicatorsCountTooltip"
  >
    <div [formGroup]="form" class="full-height full-width">
      <div class="content-container">
        <div class="content-container__left">
          @for (
            group of form.controls.indicators.controls;
            track group;
            let index = $index;
            let first = $first
          ) {
            @let status = getRiskIndicatorState(group.value._id).riskAssessmentIndicatorStatus;
            @let mitigationsCompleted =
              getRiskIndicatorState(group.value._id).allMitigationsCompleted;
            @let label = getIndicatorLabel(status, mitigationsCompleted);
            <div [formGroup]="group">
              @if (group.value._categoryName && first) {
                <h4>{{ group.value._categoryName }}</h4>
              }

              <mat-card class="flat-card">
                <div class="indicator-card-content">
                  <div class="indicator-card-content__left">
                    <span class="indicator-count">{{ index + 1 }}</span>
                    @if (
                      status === riskAssessmentIndicatorStatusEnum.RISK_ABSENT ||
                      status === riskAssessmentIndicatorStatusEnum.NOT_APPLICABLE ||
                      (status === riskAssessmentIndicatorStatusEnum.RISK_PRESENT &&
                        mitigationsCompleted)
                    ) {
                      <mat-icon class="indicator-icon" icon-medium-18>check</mat-icon>
                    }
                  </div>
                  <div class="indicator-card-content__right">
                    <mat-card-header>
                      <mat-card-title>
                        <span>{{ group.value._indicator }}</span>
                      </mat-card-title>
                    </mat-card-header>

                    <mat-card-content>
                      <app-button-group class="indicator-actions">
                        @if (group.value._guidance) {
                          <app-button type="ghost" (pressed)="toggleShowGuidance(index)">
                            @if (group.value._showGuidance) {
                              <ng-container i18n>Hide guidance</ng-container>
                            } @else {
                              <ng-container i18n>Show guidance</ng-container>
                            }
                          </app-button>
                        }
                      </app-button-group>

                      @if (group.value._showGuidance) {
                        <div
                          class="guidance rich-text-value"
                          [innerHTML]="group.value._guidance"
                        ></div>
                      }
                      @if (overlay.viewMode() && label) {
                        <div class="tag-container">
                          <div
                            class="app-tag"
                            [ngClass]="{
                              RED:
                                status === riskAssessmentIndicatorStatusEnum.RISK_PRESENT &&
                                !mitigationsCompleted,
                              GREEN:
                                status === riskAssessmentIndicatorStatusEnum.RISK_ABSENT ||
                                (status === riskAssessmentIndicatorStatusEnum.RISK_PRESENT &&
                                  mitigationsCompleted),
                              LIGHT_GREY:
                                status === riskAssessmentIndicatorStatusEnum.NOT_APPLICABLE,
                            }"
                          >
                            {{ label }}
                          </div>
                        </div>
                      }
                      @if (overlay.editMode()) {
                        <div class="radio-button-group-container">
                          <app-radio-button-group>
                            @for (
                              option of riskAssessmentIndicatorStatusOptions;
                              track option.value
                            ) {
                              @if (option.value && !loadingIndicatorStatus()) {
                                <app-radio-button
                                  [checked]="
                                    getRiskIndicatorState(group.value._id)
                                      ?.riskAssessmentIndicatorStatus === option.value
                                  "
                                  [label]="
                                    option.value === riskAssessmentIndicatorStatusEnum.RISK_PRESENT
                                      ? option.label +
                                        (getRiskIndicatorState(group.value._id)
                                          ?.allMitigationsCompleted
                                          ? ' (mitigated)'
                                          : '')
                                      : option.label
                                  "
                                  [name]="'risk-group-' + group.value._id"
                                  (changeEvent)="
                                    onIndicatorRiskStatusChange(option.value, group.value._id)
                                  "
                                ></app-radio-button>
                                &nbsp;
                              }
                            }
                          </app-radio-button-group>

                          @if (
                            !!getRiskIndicatorState(group.value._id)?.riskAssessmentIndicatorStatus
                          ) {
                            <app-button
                              type="icon"
                              size="medium"
                              [tp]="translations.resetResponseTp"
                              [tpIsLazy]="true"
                              (pressed)="resetIndicatorResponse(group.value._id)"
                            >
                              <mat-icon icon-medium-18>replay</mat-icon>
                            </app-button>
                          }
                        </div>
                      }

                      @if (
                        getRiskIndicatorState(group.value._id)?.riskAssessmentIndicatorStatus ===
                        riskAssessmentIndicatorStatusEnum.RISK_PRESENT
                      ) {
                        <div class="add-mitigation-btn-container">
                          <app-form-label [label]="translations.mitigationsLabel"></app-form-label>

                          @if (overlay.editMode()) {
                            <app-button
                              [disabled]="overlay.loading()"
                              class="icon-add-circle"
                              type="icon"
                              (pressed)="openMitigationsPanel(index)"
                            >
                              <mat-icon icon-medium-18>add</mat-icon>
                            </app-button>
                          }
                        </div>
                        @if (group.value._mitigations.length) {
                          @for (
                            riskMitigation of group.value._mitigations;
                            track riskMitigation.id
                          ) {
                            <app-risk-mitigation-card
                              [riskMitigation]="riskMitigation"
                              [editMode]="overlay.editMode()"
                              (statusChange)="
                                onUpdateIndicatorMitigationStatus(
                                  group.value._id,
                                  riskMitigation,
                                  $event
                                )
                              "
                              (remove)="
                                onRemoveIndicatorMitigation(group.value._id, riskMitigation.id)
                              "
                            />
                          }
                        } @else {
                          @if (overlay.viewMode()) {
                            <span class="no-mitigations-placeholder">-</span>
                          }
                        }
                      }

                      <app-rich-text-editor
                        [label]="translations.noteLabel"
                        [placeholder]="translations.optionalNotePh"
                        [control]="group.controls.note"
                        [enableViewMode]="overlay.viewMode()"
                        [showLabel]="overlay.viewMode() ? !!group.controls.note.value : true"
                      />

                      @if (overlay.editMode()) {
                        <div class="save-container container-flex-right">
                          <app-button
                            type="ghost"
                            [disabled]="group.pristine"
                            (pressed)="saveNote(index)"
                            i18n
                          >
                            Save
                          </app-button>
                        </div>
                      }
                    </mat-card-content>
                  </div>
                </div>
              </mat-card>
            </div>
          }
        </div>

        @if (!overlay.contentRightSidePanel()) {
          <div class="content-container__right">
            <app-input-select
              formControlName="status"
              [label]="translations.statusLabel"
              enableViewMode
              [options]="statusOptions"
            />

            @if (overlay.editMode()) {
              <app-input-select
                formControlName="calculatedRisk"
                [label]="translations.calculatedRiskLabel"
                enableViewMode
                [options]="riskLevelOptions()"
                [prefixTemplate]="colorBox"
              />

              <app-input-select
                formControlName="residualRisk"
                [label]="translations.residualRiskLabel"
                enableViewMode
                [options]="riskLevelOptions()"
                [prefixTemplate]="colorBox"
              />
            } @else {
              <div class="form-field-item">
                <app-form-label [label]="translations.calculatedRiskLabel" />
                <app-basic-tags [tags]="element.calculatedRisk ? [element.calculatedRisk] : []" />
              </div>

              <div class="form-field-item">
                <app-form-label [label]="translations.residualRiskLabel" />
                <app-basic-tags [tags]="element.residualRisk ? [element.residualRisk] : []" />
              </div>
            }

            <app-datepicker
              formControlName="completedDate"
              [label]="translations.completedDateLabel"
              enableViewMode
            />

            <app-datepicker
              formControlName="validUntilDate"
              [label]="translations.validUntilDateLabel"
              enableViewMode
              [minDate]="form.controls['completedDate'].value"
            />

            <app-editable-div
              formControlName="note"
              [label]="translations.noteLabel"
              class="bordered"
              [placeholder]="translations.notePh"
              hintErrorClass="initial"
              [isTextarea]="true"
              [isOptional]="true"
              [enableViewMode]="overlay.viewMode()"
            />

            @if (element && isRegularUser && overlay.viewMode()) {
              <app-record-details [element]="element"></app-record-details>
            }
          </div>
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>

<ng-template #colorBox let-option>
  <div [class]="option.value" class="color-box"></div>
</ng-template>

<ng-template #mitigationsPanel>
  <app-overlay-risk-mitigations-right-panel
    [saveToMitigationsListOption]="true"
    (add)="onAddIndicatorMitigation($event)"
  />
</ng-template>
