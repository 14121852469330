@if (showError) {
  @for (error of errors | keyvalue; track error.key) {
    <mat-hint align="start" class="red" [ngClass]="customErrorClass">
      {{ error.value }}
    </mat-hint>
  }
} @else if (isOptional && !isDisabled) {
  <mat-hint align="start" i18n>optional</mat-hint>
}
@if (hint) {
  <mat-hint align="start" [ngClass]="customClass">{{ hint }}</mat-hint>
}
