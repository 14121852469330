import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  signal,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddEditFieldDialogModel as Model } from "@components/shared/bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.model";
import { InputChipsComponent } from "@components/shared/inputs/input-chips/input-chips.component";
import { TextConstants } from "@shared/constants";
import { DateTypeEnum, EntityTypeEnum } from "@shared/enums";
import { ISelectOption, ITagExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  templateUrl: "./bulk-add-edit-field-dialog.component.html",
  styleUrls: ["./bulk-add-edit-field-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddEditFieldDialogComponent {
  @ViewChild("inputChips") inputChips: InputChipsComponent;

  public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  public fieldType: Model.FieldTypeEnum;

  public initialValue: any;

  public readonly fieldTypeEnum = Model.FieldTypeEnum;

  public readonly dateTypeEnum = DateTypeEnum;

  public params = signal<any>({});

  public isLoading = signal<boolean>(true);

  public entityTypeEnum = EntityTypeEnum;

  public readonly translations: any = {
    isRangeDateTypeLabel: TextConstants.RANGE_FROM_TO,
  };

  constructor(
    public dialogRef: MatDialogRef<BulkAddEditFieldDialogComponent>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.formGroup = data.formGroup;

    this.fieldType = data.fieldType || Model.defaultFieldType;

    this.params.set(data.params);

    this.initialValue = this.formGroup.controls[this.formControlName].value;

    this.isLoading.set(false);
  }

  public get isDatePickerFieldType(): boolean {
    return this.fieldType === Model.FieldTypeEnum.DATE_PICKER;
  }

  public get formControlName(): string {
    if (!this.isDatePickerFieldType) {
      return this.data.formControlName;
    }

    if (
      this.data.formGroup.controls[BulkAddItemsModel.FieldEnum.DATE_TYPE].value ===
      DateTypeEnum.EXACT
    ) {
      return this.params().fromFormControlName;
    } else {
      return this.params().rangeFormControlName;
    }
  }

  public get control(): FormControl {
    return this.formGroup.controls[this.formControlName];
  }

  public get label(): string {
    return this.params().label || BulkAddItemsModel.fieldEnumToLabelMap[this.formControlName];
  }

  public get isSubmitButtonDisabled(): boolean {
    return this.control.invalid || this.control.pending || this.control.pristine;
  }

  public async onSubmit(): Promise<void> {
    if (this.fieldType === Model.FieldTypeEnum.CHIPS) {
      await this.saveChips();
    }

    this.dialogRef.close({ hasSaved: true, newValue: this.control.value });
  }

  private async saveChips(): Promise<void> {
    const unsavedChips = this.control.value.filter((item) => !item.value);

    if (unsavedChips.length && this.params().allowCreateTag && this.params().tagUrl) {
      for (let i = 0; i < unsavedChips.length; i++) {
        await this.inputChips.createTag(unsavedChips[i], true);
      }
    }
  }

  public replaceOptions(options: ISelectOption[], setOption?: ISelectOption): void {
    this.params.set({ ...this.params(), options });
    this.cdr.detectChanges();

    if (!setOption) {
      return;
    }

    const option = (this.params().options as ISelectOption[]).find(
      (o) => o.value === setOption.value,
    );

    this.control.setValue(option);
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  public onTagsChanged(tags: ITagExtended[]): void {
    this.control.setValue(tags);
    this.control.markAsTouched();
    this.control.markAsDirty();
  }

  public onClose(hasSaved = false): void {
    this.dialogRef.close({ hasSaved, initialValue: this.initialValue });
  }

  public onClickAddNewOrganisation(): void {}
}
