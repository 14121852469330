import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from "@angular/core";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import {
  LinkCellRendererComponent,
  SlideOverCellRendererComponent,
} from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RouteEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IRuleset } from "src/app/shared/interfaces";
import { AuthenticationService, RulesetsService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-rulesets-table",
  templateUrl: "./rulesets-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetsTableComponent implements AfterViewInit {
  @Input()
  public rulesets: IRuleset[];

  @Input()
  public resourceType: ResourceTypeEnum;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState = RecordStateEnum.ALL;

  public readonly table = TableEnum.RULESETS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  public isLoading = signal(true);

  public rowData = signal<IRuleset[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  private readonly canAddOrModifyRulesets = this.authenticationService.canAddOrModifyRulesets;

  constructor(
    private router: Router,
    private rulesetsService: RulesetsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ACTIVATE_RULESET,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.ACTIVATE_RULESET,
            this.rulesetsService,
          ),
        ],
        [
          BatchActionTypeEnum.DEACTIVATE_RULESET,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DEACTIVATE_RULESET,
            this.rulesetsService,
          ),
        ],
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.rulesetsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.rulesetsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.rulesetsService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RouteEnum.ADMIN_RULESETS_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: TextConstants.DESCRIPTION,
        field: "description",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: $localize`Active`,
        field: "active",
        resizable: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        cellClass: "slide-over-cell-renderer-container",
        cellRenderer: SlideOverCellRendererComponent,
        cellRendererParams: {
          hide: (row) => row.recordState === RecordStateEnum.ARCHIVED,
          statusChange: this.onStatusChange,
          isDisabled: !this.canAddOrModifyRulesets,
          tooltip: (row) => {
            if (this.canAddOrModifyRulesets) {
              return row.active ? TextConstants.DEACTIVATE : TextConstants.ACTIVATE;
            } else {
              return $localize`Your user profile does not have permissions to activate/deactivate rulesets`;
            }
          },
        },
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onViewDetails = async (row: IRuleset): Promise<void> => {
    await this.router.navigate([`/${RouteEnum.ADMIN_RULESETS_DETAILS}/${row.id}`]);
  };

  public onStatusChange = async (element: IRuleset) => {
    element.active = !element.active;
    try {
      await this.rulesetsService.setActiveState({ active: element.active }, element.id);
      this.notificationService.showSuccess(
        element.active ? $localize`Ruleset activated` : $localize`Ruleset deactivated`,
      );
    } catch (error) {
      this.notificationService.showError(error);
      await this.getAll(); //todo find a better way of resetting the slide UI component.
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.rulesets) {
      this.rowData.set(this.rulesets);

      this.isLoading.set(false);
    } else {
      try {
        this.rowData.set(await this.rulesetsService.getAll(this.resourceType));

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
