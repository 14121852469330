import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  signal,
} from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";

import { filter, Subscription } from "rxjs";

import { TextConstants } from "@shared/constants";
import { RecordStateEnum, RouteEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import {
  NotificationService,
  AdminLocationService,
  AdminOrganisationsService,
  CommonService,
  UsersService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./admin-location-details.component.html",
  styleUrls: ["./admin-location-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminLocationDetailsComponent implements AfterViewInit, OnDestroy {
  public isLoading = signal(true);

  public element: any = null;

  public readonly routingEnum = RouteEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;

  private countryOptions: ISelectOption[] = [];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private adminLocationService: AdminLocationService,
    private adminOrganisationsService: AdminOrganisationsService,
    private usersService: UsersService,
  ) {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(filter((countries) => !!countries))
        .subscribe((countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        }),
    );
  }

  public async ngAfterViewInit(): Promise<void> {
    this.subscriptions.add(
      this.route.params.subscribe(async (params: Params) => {
        if (params["id"]) {
          await this.reloadData(params["id"]);
        }
      }),
    );
  }

  public reloadData = async (id: string): Promise<void> => {
    this.isLoading.set(true);
    try {
      this.element = await this.adminLocationService.get(id);
      this.element = CommonUtils.getElementsWithCountryName(this.countryOptions, this.element);

      this.element.organisation = await this.adminOrganisationsService.get(
        CommonUtils.getUriId(this.element.organisation),
      );

      this.element.createdBy = await this.usersService.get(
        CommonUtils.getUriId(this.element.createdBy),
      );
      this.element.recordedByName = this.element.createdBy
        ? `${this.element.createdBy.firstName} ${this.element.createdBy.lastName}`
        : null;
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error, true);
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
