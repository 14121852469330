<app-table
  *ngIf="columnDefs().length"
  [areButtonsEnabled]="areButtonsEnabled()"
  [columnDefs]="columnDefs()"
  [isFixedBottomPaginator]="isFixedBottomPaginator()"
  [isLoading]="isLoading()"
  [isPaginatorEnabled]="isPaginatorEnabled()"
  [isRecordStateFilterEnabled]="isRecordStateFilterEnabled()"
  [isSaveState]="isSaveTableState()"
  [isSearchEnabled]="isSearchEnabled()"
  [recordState]="recordState()"
  [rowData]="rowData()"
  [table]="table()"
  [batchActionSettings]="batchActionSettings"
  (getAll)="getAll()"
  (rowClick)="onViewDetails($event)"
  (rowsVisibleUpdated)="onRowsVisibleUpdated($event)"
  (rowDataUpdated)="onRowDataUpdated($event)"
></app-table>
