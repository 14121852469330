<div
  trigger
  (click)="pageClick.emit(page().value.number)"
  class="clickable container-flex-center main-container"
  [ngClass]="{
    'is-selected': selectedPage() === page().value.number,
  }"
>
  <div class="container-page container-flex">
    <div
      class="container-flex-space-between header"
      [ngClass]="{ 'is-excluded': !page().value.isIncluded }"
    >
      <div>
        {{ page().value.number }}
      </div>
    </div>
    <div class="page-pdf-container" [attr.page]="page().value.number">
      <pdf-viewer
        class="page-thumbnail"
        [ngClass]="{ 'is-excluded': !page().value.isIncluded }"
        [src]="pdfSrc()"
        [page]="page().value.number"
        [show-all]="false"
        [rotation]="page().value.rotation"
        [original-size]="false"
        [fit-to-page]="true"
        [zoom]="1"
        [render-text]="false"
        [autoresize]="true"
        [show-borders]="true"
        [zoom-scale]="'page-fit'"
      ></pdf-viewer>
      <div class="rotation-icons">
        @if (page().value.isIncluded) {
          <mat-icon
            (click)="onExcludePage($event)"
            [tp]="translations.excludeTp"
            svgIcon="x-circle"
          ></mat-icon>
        } @else {
          <mat-icon
            (click)="onIncludePage($event)"
            [tp]="translations.includeTp"
            svgIcon="check-1"
          ></mat-icon>
        }
        <mat-icon (click)="rotatePage($event, rotationDirectionEnum.LEFT)">rotate_left</mat-icon>
        <mat-icon (click)="rotatePage($event, rotationDirectionEnum.RIGHT)">rotate_right</mat-icon>
      </div>
    </div>
  </div>
</div>
