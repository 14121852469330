<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container">
      @if (isLanguagesEnabled && !isLoading()) {
        <div class="auth-language-selector-container">
          <app-language-selector
            [showFullLanguageName]="false"
            class="no-padding"
          ></app-language-selector>
        </div>
      }
      <div class="title">
        <h1 i18n>Access shared data</h1>
      </div>

      @if (isLoading()) {
        <app-loader containerClass="no-margin"></app-loader>
      } @else {
        <div class="subtitle">
          <ng-container i18n>We have sent you a verification code to</ng-container>
          &nbsp;
          <b>{{ recipient }}</b>
          <span>.&nbsp;</span>
          <ng-container i18n>Please, enter it below:</ng-container>
        </div>

        <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="flex-column">
          <app-input
            type="text"
            formControlName="verificationCode"
            [label]="translations.verificationCodeLabel"
            autofocus
          ></app-input>

          <div class="details">
            <a
              class="clickable underline"
              (click)="onSendVerificationCode()"
              (keydown.enter)="onSendVerificationCode()"
              tabindex="0"
              i18n
            >
              Re-send verification code
            </a>
          </div>

          <div class="login-button">
            <app-button
              class="fresh-green"
              [disabled]="formGroup.invalid"
              accessibilityRole="submit"
              size="large"
              i18n
            >
              Access
            </app-button>
          </div>
        </form>
      }
    </div>
  </div>
</div>
