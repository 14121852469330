import { getProcessProperties } from "./processes.query";
import { QueriesUtils } from "./queries.utils";

/**
 * Generates a GraphQL query string to fetch inbound processes by their IDs.
 *
 * @param orgId - The organization ID.
 * @param senderId - The sender ID.
 * @param ids - An array of processes IDs to fetch.
 * @param first - The number of items to fetch after the cursor.
 * @param last - The number of items to fetch before the cursor.
 * @param after - The cursor to fetch items after.
 * @param before - The cursor to fetch items before.
 * @param include - An array of properties to include in the response.
 * @returns The GraphQL query string.
 */
export const getInboundProcessesByIdsGraphQLQuery = (
  orgId: string,
  senderId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
     {
      inboundLoadProcessesByIds(   
      orgId: "${orgId}",
      senderId: "${senderId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {       
        ${getProcessProperties(include)}
    }
  }
`;
};
