<div class="container-space-between"></div>
<app-page-title
  [title]="inboundSupplyChain()?.name ?? translations.title"
  [backText]="translations.titleBackText"
  [hint]="translations.titleHint"
  [goBackRoute]="routingEnum.INBOX"
></app-page-title>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <app-supply-chain-flow-chart
    [isZoomEnabled]="true"
    [fullScreenOptionEnabled]="false"
    [locations]="locations()"
    [links]="locationLinks()"
    [height]="supplyChainHeight()"
    [isInboundShared]="true"
    [inboundSharedSenderOrgId]="senderOrganisationId"
  ></app-supply-chain-flow-chart>
}
