import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";

import { DeliveryReportMainLocationsTableComponentModel as Model } from "@components/reports/delivery-report/delivery-report-main-locations-table.component.model";
import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { ILocationExtended } from "@shared/interfaces";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-delivery-report-main-locations-table",
  templateUrl: "./delivery-report-main-locations-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportMainLocationsTableComponent implements OnInit {
  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  public sender = input<ILocationExtended>();

  public receiver = input<ILocationExtended>();

  public rowData = signal<Model.IRowData[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  private routerService: RouterService = inject(RouterService);

  public ngOnInit(): void {
    const [sender, receiver] = CommonUtils.getElementsWithCountryName(
      this.deliveryReportService.countryOptions(),
      [this.sender(), this.receiver()],
    ) as [ILocationExtended, ILocationExtended];

    const rowData: Model.IRowData[] = [
      {
        fieldName: "Location name",
        sender: sender.name,
        receiver: receiver.name,
        senderColumnId: sender.id,
        receiverColumnId: receiver.id,
        senderLinkParams: {
          linkRouteIdParam: "senderColumnId",
          linkRouteFn: this.routerService.getLocationLink,
        },
        receiverLinkParams: {
          linkRouteIdParam: "receiverColumnId",
          linkRouteFn: this.routerService.getLocationLink,
        },
      },
      {
        fieldName: "Location type",
        sender: this.getLocationTypes(sender),
        receiver: this.getLocationTypes(receiver),
      },
      {
        fieldName: "Organisation",
        sender: sender.organisationName,
        receiver: receiver.organisationName,
        senderColumnId: sender.organisationId,
        receiverColumnId: receiver.organisationId,
        senderLinkParams: {
          linkRouteIdParam: "senderColumnId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
        receiverLinkParams: {
          linkRouteIdParam: "receiverColumnId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      {
        fieldName: "Country",
        sender: sender.address.countryName,
        receiver: receiver.address.countryName,
      },
      {
        fieldName: "Address",
        sender: sender.address.street,
        receiver: receiver.address.street,
      },
      {
        fieldName: "ZIP / Post code",
        sender: sender.address.zipCode,
        receiver: receiver.address.zipCode,
      },
      {
        fieldName: "Region/State",
        sender: sender.address.region,
        receiver: receiver.address.region,
      },
      {
        fieldName: "Certificates",
        sender: this.getCertificates(sender),
        receiver: this.getCertificates(receiver),
        senderLinkParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getCertificateLink,
          textParam: "name",
        },
        receiverLinkParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getCertificateLink,
          textParam: "name",
        },
      },
      {
        fieldName: "EORI",
        sender: this.getCustomFieldValue(sender, "EORI"),
        receiver: this.getCustomFieldValue(receiver, "EORI"),
      },
      {
        fieldName: "Email",
        sender: this.getCustomFieldValue(sender, "Email"),
        receiver: this.getCustomFieldValue(receiver, "Email"),
      },
    ];

    const columnDefs: ColDef[] = [
      {
        field: "fieldName",
        headerName: "",
        pinned: "left",
        lockPinned: true,
        maxWidth: 200,
      },
      {
        field: "sender",
        sortable: false,
        valueFormatter: ColumnUtils.defaultValueFormatter(),
        cellRendererSelector: (params: { data: Model.IRowData }) => {
          if (params.data.senderLinkParams) {
            const cellRendererParams = params.data.senderLinkParams;

            return {
              component: LinkCellRendererComponent,
              params: {
                linkRouteIdParam: cellRendererParams.linkRouteIdParam,
                linkRouteFn: cellRendererParams.linkRouteFn,
                textParam: cellRendererParams.textParam,
              },
            };
          }

          return null;
        },
      },
      {
        field: "receiver",
        sortable: false,
        valueFormatter: ColumnUtils.defaultValueFormatter(),
        cellRendererSelector: (params: { data: Model.IRowData }) => {
          if (params.data.receiverLinkParams) {
            const cellRendererParams = params.data.receiverLinkParams;

            return {
              component: LinkCellRendererComponent,
              params: {
                linkRouteIdParam: cellRendererParams.linkRouteIdParam,
                linkRouteFn: cellRendererParams.linkRouteFn,
                textParam: cellRendererParams.textParam,
              },
            };
          }

          return null;
        },
      },
    ];

    this.rowData.set(rowData);
    this.columnDefs.set(columnDefs);
  }

  private getLocationTypes(location: ILocationExtended): string {
    return location.types.map((type) => type.type).join(", ");
  }

  private getCertificates(location: ILocationExtended): Model.ICellData[] {
    return location.certificates.map((certificate) => {
      const values: string[] = [
        certificate.standard.name,
        certificate.standardType?.fullName,
        certificate.number,
      ];

      const name = values.filter((value) => !!value).join(" - ");

      return { name, id: certificate.id };
    });
  }

  private getCustomFieldValue(location: ILocationExtended, label: string): string {
    return (location.customFields || []).find((cf) => cf.definition.label === label)?.value;
  }
}
