import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { MatMenuModule, MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-auto-open-menu",
  templateUrl: "./auto-open-menu.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatMenuModule],
  standalone: true,
})
export class AutoOpenMenuComponent {
  public isCollapsed = input.required<boolean>();

  private timedOutCloser: ReturnType<typeof setTimeout>;

  private readonly defaultClass: string = "menu-item-dropdown";

  public get matMenuClass(): string {
    if (this.isCollapsed()) {
      return `${this.defaultClass} is-collapsed`;
    }

    return this.defaultClass;
  }

  public onMouseEnter(trigger: MatMenuTrigger): void {
    if (this.timedOutCloser) {
      clearTimeout(this.timedOutCloser);
    }
    trigger.openMenu();
  }

  public onMouseLeave(trigger: MatMenuTrigger): void {
    this.timedOutCloser = setTimeout(() => {
      trigger.closeMenu();
    }, 50);
  }
}
