<div
  [class.has-filter]="isRecordStateFilterEnabled"
  [formGroup]="formGroup"
  class="search-filters-container"
>
  <app-input-search
    (searchEvent)="onSearch($event)"
    [searchQuery]="searchText"
    [placeholder]="translations.searchPlaceholder"
    size="small"
  ></app-input-search>
  @if (isRecordStateFilterEnabled) {
    <app-input-select
      [options]="recordStateOptions"
      [showClear]="canClearSelection"
      emitRawValue
      formControlName="recordState"
      inputOnlyMode
      [label]="translations.recordStateLabel"
    ></app-input-select>
  }
</div>
