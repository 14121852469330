import { CdkDrag } from "@angular/cdk/drag-drop";
import { Directive, Inject, OnDestroy, OnInit } from "@angular/core";

import { Subject, takeUntil } from "rxjs";

@Directive({
  standalone: false,
  selector: "[appDragCursor]",
})
export class DragCursorDirective implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    @Inject("Window") private window: Window,
    private cdkDrag: CdkDrag,
  ) {}

  public ngOnInit(): void {
    this.cdkDrag.started.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.window.document.body.style.cursor = "grabbing";
    });

    this.cdkDrag.ended.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.window.document.body.style.cursor = "auto";
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
