import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { Subscription } from "rxjs";
import { FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import { IOrganisationRegPayload, ISelectOption, IUserPayload } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  AuthService,
  CommonService,
  FeatureFlagService,
  LoginService,
} from "src/app/shared/services";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { CustomValidators, NEW_PASSWORD_VALIDATORS } from "../../../shared/validators";

@Component({
  standalone: false,
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup = null;

  public readonly routingEnum = RouteEnum;

  public currentStep = "initial";

  public countryOptions: ISelectOption[] = [];

  public isLoading = signal(false);

  public newOrganisationRegistrationId: string = null;

  public invitation: any = null;

  public hasInvitation = false;

  private email: string = null;

  private subscriptions = new Subscription();

  public readonly translations: any = {
    firstNameLabel: $localize`First name`,
    lastNameLabel: $localize`Last name`,
    emailLabel: TextConstants.EMAIL,
    passwordLabel: TextConstants.PASSWORD,
    nameLabel: TextConstants.NAME,
    countryLabel: TextConstants.COUNTRY,
  };

  readonly isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

  constructor(
    public authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private commonService: CommonService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
  }

  get isSubmitButtonDisabled(): boolean {
    return this.formGroup.invalid;
  }

  public async ngOnInit(): Promise<void> {
    this.invitation = {
      id: this.route.snapshot.queryParams["invitationId"],
      organisationName: this.route.snapshot.queryParams["org"] ?? "N/A",
    };
    this.hasInvitation = this.invitation?.id && this.invitation?.organisationName;
    this.email = this.route.snapshot.queryParams["email"];

    this.formGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl(null, [CustomValidators.required]),
      lastName: new UntypedFormControl(null, [CustomValidators.required]),
      email: new UntypedFormControl({ value: this.email ?? null, disabled: this.hasInvitation }, [
        CustomValidators.required,
        CustomValidators.email,
      ]),
      password: new UntypedFormControl(null, NEW_PASSWORD_VALIDATORS),
    });

    if (!this.hasInvitation) {
      this.formGroup.addControl(
        "organisationName",
        new UntypedFormControl(null, [CustomValidators.required]),
      );
      this.formGroup.addControl(
        "country",
        new UntypedFormControl(null, [CustomValidators.required]),
      );
    }
  }

  public onChangeStep = (step: string): void => {
    this.currentStep = step;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.hasInvitation) {
      await this.onRegisterUser();
    } else {
      await this.onRegisterOrganisation();
    }
  };

  public onResendEmail = async (): Promise<void> => {
    this.isLoading.set(true);
    await this.authService
      .resendRegisterOrganisationEmail(this.newOrganisationRegistrationId)
      .then(() => {
        this.notificationService.showSuccess($localize`Email sent`);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onRegisterUser = async (): Promise<void> => {
    this.isLoading.set(true);

    const email = this.formGroup.controls["email"].value;
    const password = this.formGroup.controls["password"].value;

    const payload: IUserPayload = {
      regType: "USER",
      firstName: this.formGroup.controls["firstName"].value.trim(),
      lastName: this.formGroup.controls["lastName"].value.trim(),
      email,
      password,
    };

    await this.authService
      .registerUser(this.invitation.id, payload)
      .then(async () => {
        this.notificationService.showSuccess($localize`User registered and invitation accepted`);
        await this.loginService.login(email, password);
      })
      .catch((error: HttpErrorResponse) => {
        switch (error.status) {
          case 401:
            this.notificationService.showError(TextConstants.EMAIL_OR_PASS_INCORRECT);
            break;
          case 404:
            this.notificationService.showError(
              $localize`Invitation no longer exists. Please contact ${this.invitation.organisationName}:organisationName:`,
            );
            break;
          case 409:
            this.notificationService.showError(
              $localize`A user with this email already exists, please login to accept this invitation`,
            );
            break;
          default:
            this.notificationService.showError(TextConstants.SERVICE_UNAVAILABLE);
            break;
        }
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  private onRegisterOrganisation = async (): Promise<void> => {
    this.isLoading.set(true);
    const payload: IOrganisationRegPayload = {
      regType: "ORGANISATION",
      firstName: this.formGroup.controls["firstName"].value.trim(),
      lastName: this.formGroup.controls["lastName"].value.trim(),
      email: this.formGroup.controls["email"].value.trim(),
      password: this.formGroup.controls["password"].value,
      organisation: this.formGroup.controls["organisationName"].value.trim(),
      country: this.formGroup.controls["country"].value.value,
      // for now static orgRoles
      orgRoles: ["PARTICIPANT"],
    };

    await this.authService
      .registerOrganisation(payload)
      .then(async (response: any) => {
        if (response?.status === "AWAITING_VERIFICATION" && response?.id) {
          this.notificationService.showSuccess($localize`Organisation registered`);
          this.newOrganisationRegistrationId = response.id;
          this.onChangeStep("verify");
        } else {
          this.notificationService.showError(null);
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };
}
