import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { TextConstants } from "@shared/constants";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";

@Component({
  standalone: false,
  selector: "app-reports-eudr-dds-commodity",
  templateUrl: "./reports-eudr-dds-commodity.component.html",
  styleUrls: ["./reports-eudr-dds-commodity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsEudrDdsCommodityComponent implements OnInit {
  public index = input<number>(0);

  public commodity = input<EudrDds.ICommodity>(null);

  public hsCodes = input<ReportsEudrModel.IHsCode[]>([]);

  public rowData = signal<EudrDds.ISpeciesInfo[]>([]);

  public colDefs = signal<ColDef[]>([]);

  public productPlacesIsLoading = signal<boolean>(true);

  public hsHeading = signal<string>("");

  private eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  public readonly translations: any = {
    volumeLabel: TextConstants.VOLUME_M3,
    massLabel: TextConstants.NET_MASS_KG,
    commodityLabel: $localize`Commodity(-ies) or Product(s) Description`,
  };

  public ngOnInit(): void {
    const colDefs: ColDef<EudrDds.ISpeciesInfo>[] = [
      {
        headerName: $localize`Scientific name`,
        field: "scientificName",
      },
      {
        headerName: $localize`Common name`,
        field: "commonName",
      },
    ];

    const { goodsMeasure } = this.commodity().descriptors;

    this.eudrDdsPageService.addWeight(goodsMeasure.netWeight);
    this.eudrDdsPageService.addVolume(goodsMeasure.volume);

    const rowData = this.commodity().speciesInfo;

    this.rowData.set(rowData);
    this.colDefs.set(colDefs);

    this.setHsHeading();

    this.productPlacesIsLoading.set(false);
  }

  private setHsHeading(): void {
    const hsCode = this.hsCodes().find(
      (hs) => String(hs.hscode) === String(this.commodity().hsHeading),
    );

    if (hsCode) {
      this.hsHeading.set(`${hsCode.hscode} - ${hsCode.description}`);
    }
  }
}
