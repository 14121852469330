import { ChangeDetectionStrategy, Component } from "@angular/core";

import { TextConstants } from "@shared/constants";
import { RecordStateEnum, RouteEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  templateUrl: "./products.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly routingEnum = RouteEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Products`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  public readonly isPreviousHistoryAdmin =
    this.routerService.getCurrentHistory() === `/${RouteEnum.ADMIN}`;

  constructor(
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.routerService.navigate(this.routerService.getProductLink());
  };
}
