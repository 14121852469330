import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

import { ButtonAccessibility, ButtonColor, ButtonSize, ButtonType } from "./button.model";

@Component({
  standalone: false,
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() accessibilityRole: ButtonAccessibility = "button";

  @Input() class: string = "";

  @Input() color: ButtonColor = "primary";

  @Input() size: ButtonSize = "default";

  @Input() type: ButtonType = "filled";

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) visible: boolean = true;

  @Input({ transform: booleanAttribute }) stopPropagation: boolean = false;

  @Output() pressed: EventEmitter<MouseEvent> = new EventEmitter();

  @HostBinding("class")
  get hostClasses(): string {
    return this.disabled ? "disabled" : "";
  }

  onClick(event: MouseEvent): void {
    if (this.stopPropagation) {
      event.stopPropagation();
    }

    if (!this.disabled) {
      this.pressed.emit(event);
    }
  }
}
