import { FormControl } from "@angular/forms";

export namespace BulkAddEnterRecordsModel {
  export enum BulkAddFieldEnum {
    RECORDS = "records",
    CUSTOM_FIELDS = "customFields",
  }

  export const editDialogMaxWidth: number = 350;
  export const editDialogHeight: number = 400;
  export const editDialogViewportMargin: number = 10;

  export type FormGroupRawValue<T> = {
    [K in keyof T]: T[K] extends FormControl<infer U> ? U : never;
  };
}
