<app-slide-overlay-header
  [isInboundShare]="true"
  [title]="title()"
  icon="location_on"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <app-location-overlay-main-information
        #informationView
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
        [isEditing]="true"
        [organisationOptions]="organisationOptions"
        [isInboundShare]="true"
      ></app-location-overlay-main-information>
    </app-form-field-group>
    <app-form-field-group name="Address">
      <app-location-overlay-address
        [countryOptions]="allCountries"
        [formGroup]="formGroup"
      ></app-location-overlay-address>
    </app-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.GPS_DATA).isEnabled"
    [overlay-menu-indicator]="gpsIndicator"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.GPS_DATA).title"
    appSlideOverlayMenuItem
    overlay-menu-svg-icon="map"
  >
    <app-location-overlay-gps [formGroup]="formGroup" [isEditing]="true"></app-location-overlay-gps>
  </ng-template>
  <ng-template #gpsIndicator>
    @if (formGroup?.controls["geoLocation"]?.value) {
      <div class="gps-indicator"></div>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
</app-slide-overlay-content>
