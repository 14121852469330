<article class="field-group-container">
  @if (isSelectedAttachmentsLoading()) {
    <app-loader></app-loader>
  } @else {
    @if (allMissingDocumentTypes().length) {
      <div class="document-title container-flex">
        <ng-container i18n>Missing documents</ng-container>

        <app-indicator [value]="allMissingDocumentTypes().length"></app-indicator>
      </div>

      <section class="document-table-container">
        <app-table
          [columnDefs]="allMissingDocumentTypeColumnDefs()"
          [isLoading]="false"
          [isRecordStateFilterEnabled]="true"
          [isSearchEnabled]="true"
          [rowData]="allMissingDocumentTypes()"
          [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
        ></app-table>
      </section>

      <div class="document-title container-flex">
        <ng-container i18n>Attached documents</ng-container>

        <app-indicator [value]="selectedDocumentsIds().length"></app-indicator>
      </div>
    }

    <section class="document-table-container">
      <app-documents-table
        [documentsIds]="selectedDocumentsIds()"
        [allDocumentTypes]="allDocumentTypes()"
        [columns]="documentColumns()"
        [canRemove]="!isReadOnly() && canAddModifyEntities"
        (remove)="onRemove($event)"
        [isSaveTableState]="true"
        [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
      ></app-documents-table>
    </section>
  }
</article>

<ng-template #attachmentsRightMenu>
  <app-right-side-panel
    (pressAddAll)="onAddAll()"
    (searchEvent)="onSearchAvailableAttachments($event)"
    [availableItems]="availableDocuments()"
    [displayTopButtons]="false"
    [isLoadingItems]="isAvailableAttachmentsLoading()"
    [itemType]="attachmentTypeEnum.DOCUMENT"
    [overlayTopButtons]="overlayTopButtons"
    class="overlay-right-panel"
    itemTypePlural="documents"
  >
    <div class="items-container">
      @if (isAvailableAttachmentsLoading()) {
        <app-loader></app-loader>
      } @else {
        @for (document of availableDocuments(); track document.id) {
          <app-draggable-card (pressed)="onAdd(document)" [draggable]="false">
            <app-document-content-card [document]="document" [type]="cardContentTypeEnum.ADD" />
          </app-draggable-card>
        }
      }
    </div>
  </app-right-side-panel>
</ng-template>
<ng-template #overlayTopButtons>
  <app-button
    (pressed)="addNewDocument()"
    size="large"
    [tp]="translations.addNewTp"
    tpPlacement="bottom"
    type="icon"
  >
    <mat-icon icon-medium-18>add</mat-icon>
  </app-button>
</ng-template>

<app-slide-overlay-wrapper [close]="closeOverlay()" (clickClose)="onCloseAttachSlideOver()">
  <div class="full-width">
    @if (closeOverlay()) {
      <div class="slide-over-content full-height">
        <app-loader></app-loader>
      </div>
    } @else {
      <app-upload-documents
        title="Attach new documents"
        [isShowTitleButtons]="false"
        [isShowBackButton]="false"
        containerClass="slide-over-content"
        [isShowDialogButtons]="true"
        [isAttachProcess]="true"
        [attachTargetUri]="attachTargetUri"
        (attachDocumentsCompleted)="onAttachDocumentsCompleted($event)"
        (filesAdded)="onFilesAdded()"
      ></app-upload-documents>
    }
  </div>
</app-slide-overlay-wrapper>
