import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { BulkAddLocationsModel } from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { BulkAddLocationsService } from "@components/locations/bulk-add-locations/bulk-add-locations.service";
import { BulkAddLocationsCreateRecordsModel as Model } from "@components/locations/bulk-add-locations/create-records/bulk-add-locations-create-records.model";
import { LocationOverlayService } from "@components/locations/pages/location-overlay/location-overlay.service";
import { BulkAddCreateRecords } from "@components/shared/bulk-add/bulk-add-create-records";
import { BulkAddCreateRecordsModel } from "@components/shared/bulk-add/bulk-add-create-records.model";
import { EntityTypeEnum } from "@shared/enums";
import { IAttachmentPayload, ILocationDetails } from "@shared/interfaces";
import { AttachmentsService, LocationsService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-bulk-add-locations-create-records",
  templateUrl: "./bulk-add-locations-create-records.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddLocationsCreateRecordsComponent
  extends BulkAddCreateRecords<Model.IPayloadWithStatus, Model.ILocationPayloadWithTags>
  implements AfterViewInit
{
  @Input() public formGroup: FormGroup<BulkAddLocationsModel.SetValuesFormGroup>;

  public readonly statusEnum = BulkAddCreateRecordsModel.StatusEnum;

  private readonly fieldEnum = BulkAddLocationsModel.FieldEnum;

  private locationsService: LocationsService = inject(LocationsService);

  private readonly bulkAddLocationsService: BulkAddLocationsService =
    inject(BulkAddLocationsService);

  private attachmentsService: AttachmentsService = inject(AttachmentsService);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private entityType = EntityTypeEnum.LOCATIONS;

  public async ngAfterViewInit(): Promise<void> {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    this.payloadsWithStatus.set(
      this.formGroup.controls[this.fieldEnum.RECORDS].controls.map((record) => {
        return this.getPayloadWithStatusForRecord(record);
      }),
    );

    await this.createRecords(this.payloadsWithStatus());

    const successfulRecordsCreatedCount = this.payloadsWithStatus().filter(
      (payload) => payload.status === this.statusEnum.SUCCESS,
    ).length;

    if (successfulRecordsCreatedCount) {
      this.notificationService.showSuccess(
        $localize`${successfulRecordsCreatedCount}:successfulRecordsCreatedCount: location(s) created`,
      );
    } else {
      this.notificationService.showError($localize`No locations were created`);
    }

    this.hasProcessCompleted.set(true);

    this.creationProcessComplete.emit();
  }

  public async createRecord(payloadWithStatus: Model.IPayloadWithStatus): Promise<void> {
    let location: ILocationDetails;

    const locationPayload = payloadWithStatus.recordPayload;

    try {
      location = await this.locationsService.createOrUpdate(locationPayload);
    } catch {
      payloadWithStatus.status = BulkAddCreateRecordsModel.StatusEnum.ERROR;

      return;
    }

    const entityUri = `/organisations/${this.activeOrganisationId}/${this.entityType}/${location.id}`;

    await Promise.all([
      this.saveTagsForNewRecord(locationPayload.tags, entityUri),
      this.createOrganisationAttachment(location.id, locationPayload.organisationId),
    ]);

    payloadWithStatus.status = BulkAddCreateRecordsModel.StatusEnum.SUCCESS;
  }

  public async createRecords(payloadsWithStatus: Model.IPayloadWithStatus[]): Promise<void> {
    for (let i = 0; i < payloadsWithStatus.length; i += 1) {
      if (this.bulkAddLocationsService.abortController.signal.aborted) {
        payloadsWithStatus[i].status = BulkAddCreateRecordsModel.StatusEnum.CANCELED;
        continue;
      }

      await this.createRecord(payloadsWithStatus[i]);

      this.cdr.detectChanges();
    }
  }

  private async createOrganisationAttachment(
    locationId: string,
    organisationId: string,
  ): Promise<void> {
    const isLoggedInUserOrganisation = organisationId === this.activeOrganisationId;

    const payload: IAttachmentPayload = {
      targetUri: isLoggedInUserOrganisation
        ? `/organisations/${this.activeOrganisationId}`
        : `/organisations/${this.activeOrganisationId}/connections/${organisationId}`,
      attachmentUri: `/organisations/${this.activeOrganisationId}/locations/${locationId}`,
    };

    await this.attachmentsService.create(payload);
  }

  private getPayloadWithStatusForRecord(
    formGroup: FormGroup<BulkAddLocationsModel.LocationFormGroup>,
  ): Model.IPayloadWithStatus {
    const values = formGroup.getRawValue();
    const { fieldEnum } = this;
    const uriPrefix = `/organisations/${this.activeOrganisationId}`;

    const types = values[fieldEnum.LOCATION_TYPES].map(
      (option) => `${uriPrefix}/location-types/${option.value}`,
    );

    const recordPayload: Model.ILocationPayloadWithTags = {
      id: undefined,
      name: values[fieldEnum.NAME],
      types,
      tags: values[fieldEnum.TAGS],
      geoLocation: LocationOverlayService.transformGeoLocationForPayload(
        values[fieldEnum.GEOLOCATION]?.geoLocation,
      ) as any,
      organisationId: values[fieldEnum.ORGANISATION].value as string,
      address: {
        street: values[fieldEnum.ADDRESS],
        region: values[fieldEnum.REGION],
        zipCode: values[fieldEnum.ZIP_CODE],
        country: values[fieldEnum.COUNTRY]?.value as string,
      },
    };

    this.addCustomFieldsToPayload(
      values[fieldEnum.CUSTOM_FIELDS],
      recordPayload,
      this.bulkAddLocationsService,
    );

    return { recordPayload, status: this.statusEnum.PENDING };
  }
}
