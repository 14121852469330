import { ChangeDetectionStrategy, Component, computed, input, Input } from "@angular/core";

import {
  IAvailableOrganisation,
  IGroupedSidebarMenuItem,
  ISidebarMenuItem,
  IUserData,
} from "src/app/shared/interfaces";

import { TextConstants } from "@shared/constants";
import { AuthUserTypeEnum, RouteEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { OrganisationService } from "@shared/services/organisation.service";
import { CommonUtils } from "@shared/utils";

import { SidebarMenuService } from "./sidebar-menu.service";

@Component({
  standalone: false,
  selector: "app-sidebar-menu",
  templateUrl: "./sidebar-menu.component.html",
  styleUrls: ["./sidebar-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent {
  @Input() isCrossOrgSharingEnabled: boolean;

  @Input() isAccountOwnerOrAdminOrContributor: boolean;

  @Input() isSharedUser: boolean;

  @Input() isAdminMenuEnabled: boolean;

  @Input() canAddModifyEntities: boolean;

  @Input() userType: AuthUserTypeEnum;

  @Input() userName: string;

  pendingInvitationsCount = input<number>(0);

  newInboundSharesCount = input<number>(0);

  @Input() currentOrganisation: IAvailableOrganisation;

  @Input() userData: IUserData;

  public isBackToInitialSharedPageVisible = computed(() => {
    if (this.isSharedUser) {
      const mainShareLinkRoute = `/${this.authenticationService.getMainShareLinkRoute()}`;

      return (
        this.currentUrl !== mainShareLinkRoute &&
        !CommonUtils.isSameUrlPathButOnly1ParamHasChanged(
          this.currentUrl,
          mainShareLinkRoute,
          "tab",
        )
      );
    } else {
      return false;
    }
  });

  public readonly authUserTypeEnum = AuthUserTypeEnum;

  private readonly adminMenuItem: ISidebarMenuItem = {
    title: $localize`Admin`,
    route: `/${RouteEnum.ADMIN}`,
    icon: "settings",
    isSvgIcon: true,
    isActiveRouteExact: true,
    isDisabledFn: () => !this.isAdminMenuEnabled,
  };

  private readonly inboxMenuItem: ISidebarMenuItem = {
    title: $localize`Inbox`,
    route: `/${RouteEnum.INBOX}`,
    icon: "inbox-menu",
    isSvgIcon: true,
    badgeFn: () => this.newInboundSharesCount(),
    isDisabledFn: () => !this.isCrossOrgSharingEnabled || !this.isAccountOwnerOrAdminOrContributor,
  };

  public readonly systemAdminMenuItems: ISidebarMenuItem[] = [
    {
      title: $localize`Organisations`,
      route: `/${RouteEnum.ADMIN_ORGANISATIONS}`,
      icon: "organisations",
      isSvgIcon: true,
    },
    {
      title: $localize`Users`,
      route: `/${RouteEnum.ADMIN_USERS}`,
      icon: "group",
    },
    this.adminMenuItem,
  ];

  public readonly locationMenuItem: ISidebarMenuItem = {
    title: $localize`Locations`,
    route: `/${RouteEnum.LOCATIONS}`,
    icon: "location_on",
    isSvgIcon: false,
  };

  public readonly organisationsMenuItem: ISidebarMenuItem = {
    title: $localize`Organisations`,
    route: `/${RouteEnum.ORGANISATIONS}`,
    icon: "organisations",
    isSvgIcon: true,
  };

  public readonly supplyChainsMenuItem: ISidebarMenuItem = {
    title: $localize`Supply chains`,
    route: `/${RouteEnum.SUPPLY_CHAINS}`,
    icon: "supply-chain",
    isSvgIcon: true,
  };

  public readonly certificatesMenuItem: ISidebarMenuItem = {
    title: $localize`Certificates`,
    route: `/${RouteEnum.CERTIFICATES}`,
    icon: "verified_user",
    isSvgIcon: false,
  };

  public readonly documentsMenuItem: ISidebarMenuItem = {
    title: $localize`Documents`,
    route: `/${RouteEnum.DOCUMENTS}`,
    icon: "docs",
    isSvgIcon: false,
  };

  public readonly itemsMenuItem: ISidebarMenuItem = {
    title: $localize`Items`,
    route: `/${RouteEnum.ITEMS}`,
    icon: "package_2",
    isSvgIcon: false,
  };

  public readonly deliveriesMenuItem: ISidebarMenuItem = {
    title: $localize`Deliveries`,
    route: `/${RouteEnum.DELIVERIES}`,
    icon: "local_shipping",
    isSvgIcon: false,
  };

  public readonly processesMenuItem: ISidebarMenuItem = {
    title: $localize`Processes`,
    route: `/${RouteEnum.PROCESSES}`,
    icon: "sync",
    isSvgIcon: false,
    class: "rotate-horizontal",
  };

  public readonly productsMenuItem: ISidebarMenuItem = {
    title: $localize`Products`,
    route: `/${RouteEnum.PRODUCTS}`,
    icon: "deployed_code",
    isSvgIcon: false,
  };

  public readonly materialsMenuItem: ISidebarMenuItem = {
    title: TextConstants.MATERIALS,
    route: `/${RouteEnum.MATERIALS}`,
    icon: "category",
    isSvgIcon: false,
  };

  public readonly sectionsMenuItems: IGroupedSidebarMenuItem[] = [
    {
      title: $localize`Static`,
      icon: "supply-chain",
      isSvgIcon: true,
      menuItems: [
        this.locationMenuItem,
        this.organisationsMenuItem,
        this.supplyChainsMenuItem,
        this.productsMenuItem,
        this.materialsMenuItem,
      ],
    },
    {
      title: $localize`Transactional`,
      icon: "local_shipping",
      menuItems: [this.itemsMenuItem, this.deliveriesMenuItem, this.processesMenuItem],
      isSvgIcon: false,
    },
    {
      title: $localize`Evidence`,
      icon: "docs",
      menuItems: [this.documentsMenuItem, this.certificatesMenuItem],
      isSvgIcon: false,
    },
  ];

  public readonly groupedMenuItems: (ISidebarMenuItem | IGroupedSidebarMenuItem)[] = [
    this.inboxMenuItem,
    ...this.sectionsMenuItems,
    this.adminMenuItem,
  ];

  public readonly nonGroupedMenuItems: ISidebarMenuItem[] = [
    this.locationMenuItem,
    this.organisationsMenuItem,
    this.supplyChainsMenuItem,
    this.certificatesMenuItem,
    this.documentsMenuItem,
    this.itemsMenuItem,
    this.deliveriesMenuItem,
    this.processesMenuItem,
    this.inboxMenuItem,
    this.adminMenuItem,
  ];

  constructor(
    public sidebarMenuService: SidebarMenuService,
    private organisationService: OrganisationService,
    private authenticationService: AuthenticationService,
  ) {}

  get menuItems(): (ISidebarMenuItem | IGroupedSidebarMenuItem)[] {
    if (this.currentOrganisation.isSystemAdmin) {
      return this.systemAdminMenuItems;
    }

    if (this.sidebarMenuService.isGroupedSideMenuEnabled()) {
      return this.groupedMenuItems;
    }

    return this.nonGroupedMenuItems;
  }

  public get currentUrl(): string {
    return this.sidebarMenuService.currentUrl();
  }

  get hasOrganisationOptions(): boolean {
    return !!this.userData?.availableOrganisations?.length;
  }

  get urlStartsWithInvitations(): boolean {
    return this.currentUrl.startsWith("/invitations");
  }

  public onBackToInitialSharedPage = async (): Promise<void> => {
    await this.authenticationService.goToMainShareLink();
  };

  onToggleMenuCollapse(): void {
    this.sidebarMenuService.toggleMenu(true);
  }

  createOrganisation(): void {
    this.organisationService.createOrganisation();
  }
}
