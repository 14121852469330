<div class="container-space-between">
  <app-page-title
    title="Standards"
    backText="Back to Admin"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>
  <app-button-group>
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<app-standards-table
  #standardsTable
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
></app-standards-table>
