<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Edit delivery item</h2>
  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <app-input
              [suffix]="
                item.productDefaultUnit
                  ? item.productDefaultUnit.symbol
                  : item.unitOfMeasurement?.symbol
              "
              [hint]="
                item.productDefaultUnit
                  ? (formGroup.controls['remainingQty'].value
                    | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                  : ''
              "
              class="max-width-160 no-padding"
              formControlName="remainingQty"
              [label]="translations.remainingQtyLabel"
              type="number"
            ></app-input>
          </div>
          <div class="item">
            <app-input
              [suffix]="
                item.productDefaultUnit
                  ? item.productDefaultUnit.symbol
                  : item.unitOfMeasurement?.symbol
              "
              [hint]="
                item.productDefaultUnit
                  ? (formGroup.controls['deliveredQty'].value
                    | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                  : ''
              "
              class="max-width-160 no-padding"
              formControlName="deliveredQty"
              [label]="translations.deliveredQtyLabel"
              type="number"
            ></app-input>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost">Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled" accessibilityRole="submit" cdkFocusInitial>
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
