<app-slide-overlay-header [title]="title()" icon="verified_user"></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <app-certificate-overlay-main-information
        class="full-width"
        [formGroup]="formGroup"
        [standardTypeOptions]="standardTypeOptions()"
        [standardOptions]="standardOptions()"
      ></app-certificate-overlay-main-information>
    </app-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ATTACHED_TO).isEnabled"
    [overlay-menu-indicator]="selectedAttachments()?.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.ATTACHED_TO).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="attach_file"
  >
    <article class="field-group-container no-padding-bottom">
      <app-attachments-table
        [isInboundShared]="true"
        [attachments]="selectedAttachments()"
      ></app-attachments-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="attachedDocuments()?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="attachedDocuments()"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
</app-slide-overlay-content>
