<section class="slide-overlay-content-header-container">
  <div class="slide-overlay-content-header">
    {{ title() }}

    @if (tag()) {
      <app-tag-group [tags]="[tag()]"></app-tag-group>
    }

    @if (infoIconTooltip()) {
      <mat-icon [tp]="infoIconTooltip()" [tpIsLazy]="true">help</mat-icon>
    }
  </div>
  @if (controllersTemplate()) {
    <ng-container [ngTemplateOutlet]="controllersTemplate()"></ng-container>

    @if (showSaveButton()) {
      <app-button (pressed)="overlay.save()" [disabled]="isSubmitButtonDisabled" i18n>
        Save
      </app-button>
    }
  } @else {
    @if (overlay.editMode()) {
      <app-button type="ghost" (pressed)="cancel()" i18n>Cancel</app-button>
      @if (showSaveButton()) {
        <app-button (pressed)="overlay.save()" [disabled]="isSubmitButtonDisabled" i18n>
          Save
        </app-button>
      }
    } @else {
      @if (showEditButton() && recordIsActive) {
        <app-button
          type="ghost"
          (pressed)="overlay.enableEditMode()"
          [disabled]="overlay.loading()"
          i18n
        >
          Edit
        </app-button>
      }
    }
  }
</section>
