import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";

import { ConfirmDialogComponent } from "@components/shared";
import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import { BatchActionTypeEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { RiskMitigationsApiService } from "../api";
import { RiskMitigation } from "../models";

@Component({
  standalone: false,
  selector: "app-risk-mitigations-table",
  templateUrl: "./risk-mitigations-table.component.html",
  // standalone: true,
  // imports: [AsyncPipe, MatDialogModule, ShareComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMitigationsTableComponent implements AfterViewInit {
  private readonly dialog = inject(MatDialog);

  private readonly api = inject(RiskMitigationsApiService);

  private readonly notification = inject(NotificationService);

  readonly areButtonsEnabled = input(true);

  readonly isFixedBottomPaginator = input(false);

  readonly isPaginatorEnabled = input(true);

  readonly isRecordStateFilterEnabled = input(true);

  readonly isSaveTableState = input(false);

  readonly isSearchEnabled = input(true);

  readonly recordState = input(RecordStateEnum.ACTIVE);

  readonly isBatchActionsEnabled = input(false);

  readonly edit = output<RiskMitigation>();

  readonly isLoading = signal(true);

  readonly columnDefs = signal<ColDef[]>([]);

  readonly rowData = signal<RiskMitigation[]>([]);

  readonly batchActionSettings = signal<BatchActionModel.IBatchActionSettings>(undefined);

  readonly table = TableEnum.RISK_MITIGATIONS;

  async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  async getAll() {
    this.isLoading.set(true);

    try {
      const { content } = await this.api.getAll({
        page: 0,
        size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
      });

      this.rowData.set(content);
      this.isLoading.set(false);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  onEdit(row: RiskMitigation) {
    if (this.areButtonsEnabled()) {
      this.edit.emit(row);
    }
  }

  private setColumnDefs() {
    const columnDefs = [
      ColumnUtils.recordState(),
      {
        headerName: $localize`Mitigation`,
        field: "mitigation",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled()
            ? [
                {
                  click: (row) => this.onEdit(row),
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                },
                {
                  click: (row) => this.onToggleArchiveStatus(row),
                  tooltip: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE
                      ? TextConstants.ARCHIVE
                      : TextConstants.UNARCHIVE,
                  icon: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory",
                },
                {
                  click: (row) => this.onDelete(row),
                  tooltip: TextConstants.DELETE,
                  icon: "delete",
                },
              ]
            : [],
        },
      },
      {
        headerName: TextConstants.DESCRIPTION,
        field: "description",
        valueGetter: (params) => CommonUtils.stripHtml(params.data.description),
        valueFormatter: ColumnUtils.longTextValueFormatter(250),
      },
    ];

    if (this.batchActionSettings()) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  }

  private setBatchActionSettings() {
    if (this.isBatchActionsEnabled()) {
      this.batchActionSettings.set({
        actions: new Map([
          [
            BatchActionTypeEnum.ARCHIVE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.api),
          ],
          [
            BatchActionTypeEnum.UNARCHIVE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.api),
          ],
          [
            BatchActionTypeEnum.DELETE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.api),
          ],
        ]),
      });
    }
  }

  private onToggleArchiveStatus(row: RiskMitigation) {
    const isArchived = row.recordState === RecordStateEnum.ARCHIVED;

    const onSubmit = async () => {
      this.isLoading.set(true);

      try {
        await this.api.setRecordState(
          { recordState: isArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED },
          row.id,
        );
        this.notification.showSuccess(
          isArchived ? TextConstants.UNARCHIVED : TextConstants.ARCHIVED,
        );
        await this.getAll();
      } catch (error) {
        this.notification.showError(error);
        this.isLoading.set(false);
      }
    };

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
        confirmButtonColor: isArchived ? undefined : "danger",
        onSubmit,
      },
    });
  }

  private onDelete(row: RiskMitigation) {
    const onSubmit = async () => {
      this.isLoading.set(true);

      try {
        await this.api.delete(row.id);
        this.notification.showSuccess(TextConstants.RECORD_DELETED);
        await this.getAll();
      } catch (error) {
        this.notification.showError(error);
        this.isLoading.set(false);
      }
    };

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this risk mitigation?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
        onSubmit,
      },
    });
  }
}
