import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  model,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";
import {
  EditDocumentTypeDialogComponent,
  EditLocationTypeDialogComponent,
  EditProcessTypeDialogComponent,
} from "src/app/components/settings";

import { AddItemDialogComponent } from "@components/deliveries";
import { AddProductDialogComponent } from "@components/items";
import { AddConnectionDialogComponent } from "@components/locations";
import { AddMaterialDialogComponent } from "@components/materials";
import { AddLocationDialogComponent } from "@components/organisations";
import {
  AddCertificateDialogComponent,
  AddDocumentDialogComponent,
  ConfirmDialogComponent,
  InfoDialogComponent,
} from "@components/shared";
import { AddCertificateDialogModel } from "@components/shared/add-certificate-dialog/add-certificate-dialog.model";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  CrossOrgShareDataTypeEnum,
  RecordStateEnum,
} from "@shared/enums";
import {
  ICertificate,
  IDeliveryPayload,
  IDocument,
  IDocumentType,
  IInboundMapping,
  IInboundShare,
  IItem,
  IItemDetails,
  ILocationExtended,
  ILocationType,
  IMaterial,
  IOrganisation,
  IProcessType,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import {
  NotificationService,
  RecordSharingService,
  AuthenticationService,
  ConnectionsService,
  DeliveriesService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

import { UnmappedDependenciesService } from "../unmapped-dependencies.service";

@Component({
  standalone: false,
  selector: "app-trasfer-or-map-form",
  templateUrl: "./trasfer-or-map-form.component.html",
  styleUrl: "./trasfer-or-map-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrasferOrMapFormComponent implements OnInit, OnChanges {
  private recordSharingService = inject(RecordSharingService);

  private authenticationService = inject(AuthenticationService);

  private notificationsService = inject(NotificationService);

  private router = inject(RouterService);

  private connectionsService = inject(ConnectionsService);

  private unmappedDependenciesService = inject(UnmappedDependenciesService);

  private deliveriesService = inject(DeliveriesService);

  public sharedData = model<any[]>([]);

  public localRecords = input<InputSelectOption[]>([]);

  public locationTypesOptions = input<InputSelectOption[]>([]);

  public materialsOptions = input<InputSelectOption[]>([]);

  public locationsOptions = input<InputSelectOption[]>([]);

  public productsOptions = input<InputSelectOption[]>([]);

  public documentTypesOptions = input<InputSelectOption[]>([]);

  public shareRecordType = input<CrossOrgShareDataTypeEnum>(null);

  public inboundShare = input<IInboundShare>(null);

  public inputSelectPlaceholder = input<string>(null);

  public countryOptions = input<ISelectOption[]>([]);

  public isViewButtonDisabled = input(true);

  public isTransferButtonDisabled = input(false);

  public existingMappings = model<IInboundMapping[]>([]);

  @Output() newRecordCreated: EventEmitter<void> = new EventEmitter<void>();

  @Output() inboundMappingAdded: EventEmitter<{
    recordId: string;
    recordType: CrossOrgShareDataTypeEnum;
  }> = new EventEmitter(null);

  @Output() inboundMappingRemoved: EventEmitter<{
    recordId: string;
    recordType: CrossOrgShareDataTypeEnum;
  }> = new EventEmitter(null);

  public formGroup: FormGroup = new FormGroup({});

  public isLoading = signal(true);

  private activeOrganisationId: string;

  public readonly inboundShareDataTypeEnum = CrossOrgShareDataTypeEnum;

  private overlay = inject(SlideOverlayPageService);

  public readonly translations: any = {
    deleteTp: TextConstants.DELETE,
    transferTp: $localize`Transfer record to own records`,
    addTp: $localize`Add`,
    viewTp: $localize`View`,
  };

  constructor(private dialog: MatDialog) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading.set(true);
    this.initializeForm();
    await this.checkRecordExistance();
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes["sharedData"]) {
      this.initializeForm();
      await this.checkRecordExistance();
    }
  }

  private initializeForm(): void {
    this.isLoading.set(true);
    const controls: { [key: string]: FormControl } = {};

    this.sharedData().forEach((data, index) => {
      controls[index] = new FormControl(data.mappedRecordInfo || null);
    });
    this.formGroup = new FormGroup(controls);
  }

  private async checkRecordExistance(): Promise<void> {
    try {
      const records = await this.recordSharingService.getCheckExistingRecord(
        this.inboundShare().rootRecordUri,
      );

      const sharedData = this.sharedData().map((data, index) => {
        const mappedRecordInfo = records.mappedRecordsInfo.find(
          (r) => CommonUtils.getUriId(r.inboundRecord) === data.id,
        );

        if (mappedRecordInfo) {
          const localValue = this.localRecords().find(
            (t) => t.value === CommonUtils.getUriId(mappedRecordInfo.localRecord),
          );

          this.formGroup.controls[index].setValue(localValue);
        } else {
          this.formGroup.controls[index].setValue("");
        }

        return data;
      });

      this.sharedData.update((_) => sharedData);
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public async transferInboundRecord(inboundRecord: any, index: number): Promise<void> {
    switch (this.shareRecordType()) {
      case CrossOrgShareDataTypeEnum.DOCUMENT_TYPES:
        this.addNewDocumentType(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.LOCATION_TYPES:
        this.addNewLocationType(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.CONNECTIONS:
        await this.addNewConnection(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        this.addNewLocation(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        this.addNewDelivery(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.DOCUMENTS:
        this.addNewDocument(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.CERTIFICATES:
        this.addNewCertificate(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.MATERIALS:
        this.addNewMaterial(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.PRODUCTS:
        this.addNewProduct(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.ITEMS:
        this.addNewItem(inboundRecord, index);
        break;
      case CrossOrgShareDataTypeEnum.PROCESS_TYPES:
        this.addNewProcessType(inboundRecord, index);
        break;
      default:
        break;
    }
  }

  private async addNewCertificate(certificate: ICertificate, index: number): Promise<void> {
    const result = await this.overlay.openDialog<
      AddCertificateDialogModel.IResult,
      AddCertificateDialogModel.IDialogData
    >(AddCertificateDialogComponent, {
      certificate,
    });

    if (result?.newCertificate) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.newCertificate);
    }
  }

  private async addNewMaterial(material: IMaterial, index: number): Promise<void> {
    const result: { addedMaterial: IMaterial; hasSaved: boolean } = await this.overlay.openDialog(
      AddMaterialDialogComponent,
      {
        material,
      },
    );

    if (result?.addedMaterial) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.addedMaterial);
    }
  }

  private async addNewProduct(product: IProduct, index: number): Promise<void> {
    const materialsIds = product.allowedMaterials.map((materialUri) =>
      CommonUtils.getUriId(materialUri),
    );
    const mappedMaterials = this.getMappedEntities(materialsIds);
    const selectedMaterials = this.getMaterialsOptions(mappedMaterials);

    const result: { createdProduct: IProduct; hasSaved: boolean } = await this.overlay.openDialog(
      AddProductDialogComponent,
      {
        product,
        selectedMaterials,
      },
    );

    if (result?.createdProduct) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.createdProduct);
    }
  }

  private async addNewItem(item: IItem, index: number): Promise<void> {
    const productId = CommonUtils.getUriId(item.product);
    const createAtLocationUri = CommonUtils.getUriId(item.createdAtLocation);
    const currentLocationUri = CommonUtils.getUriId(item.currentLocation);
    const itemsMaterials = this.getMappedEntities(
      item.materials.map((m) => CommonUtils.getUriId(m)),
    );

    const mappedProductId = this.getMappedEntities([productId])[0];
    const [createAtLocationId, currentLocationId] = this.getMappedEntities([
      createAtLocationUri,
      currentLocationUri,
    ]);

    const originalItem: IItemDetails = {
      id: null,
      itemId: item.itemId,
      productId: mappedProductId,
      createdAtLocation: createAtLocationId,
      currentLocation: currentLocationId,
      initialQuantity: item.initialQuantity,
      created: item.created,
      product: null,
      materials: itemsMaterials,
    };
    const result: { hasSaved: boolean; item: IItem } = await this.overlay.openDialog(
      AddItemDialogComponent,
      {
        originalItem,
        shouldExcludeDeliveryQty: true,
      },
    );

    if (result?.item) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.item);
    }
  }

  private async addNewDocument(document: IDocument, index: number): Promise<void> {
    const existingMappings = await this.recordSharingService.getAllMappings();

    this.existingMappings.update((_) => existingMappings);
    const inboundDocumentTypesIds = this.getInboundRecordIds(
      CrossOrgShareDataTypeEnum.DOCUMENT_TYPES,
    );

    if (
      this.unmappedDependenciesService.hasUnmappedDependencies(
        inboundDocumentTypesIds,
        this.existingMappings(),
        "inboundUri",
      )
    ) {
      this.showUnmappedDependenciesDialog();
    } else {
      await this.openAddDocumentDialog(index, document);
    }
  }

  private async addNewDelivery(inboundRecord: any, index: number): Promise<void> {
    try {
      const existingMappings = await this.recordSharingService.getAllMappings();
      const inboundFromLocationId = CommonUtils.getUriId(inboundRecord.from);
      const inboundToLocationId = CommonUtils.getUriId(inboundRecord.to);
      const mappedToLocationUri = existingMappings.find((m) =>
        m.inboundUri.includes(inboundToLocationId),
      )?.localUri;
      const mappedFromLocationUri = existingMappings.find((m) =>
        m.inboundUri.includes(inboundFromLocationId),
      )?.localUri;
      const payload: IDeliveryPayload = {
        deliveryId: inboundRecord.deliveryId,
        from: mappedFromLocationUri,
        to: mappedToLocationUri,
        sent: inboundRecord.sent,
        status: inboundRecord.status,
        delivered: inboundRecord.delivered,
        items: [],
      };

      const result = await this.deliveriesService.createOrUpdate(payload);

      this.newRecordCreated.emit();

      await this.addInboundMapping(index, result);
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private async addNewLocation(location: ILocationExtended, index: number): Promise<void> {
    const existingMappings = await this.recordSharingService.getAllMappings();

    this.existingMappings.update((_) => existingMappings);
    const inboundLocationTypesIds = this.getInboundRecordIds(
      CrossOrgShareDataTypeEnum.LOCATION_TYPES,
    );

    const inboundConnectionsIds = this.getInboundRecordIds(CrossOrgShareDataTypeEnum.CONNECTIONS);

    if (
      this.unmappedDependenciesService.hasUnmappedDependencies(
        [...inboundLocationTypesIds, ...inboundConnectionsIds],
        this.existingMappings(),
        "inboundUri",
      )
    ) {
      this.showUnmappedDependenciesDialog();
    } else {
      await this.openAddLocationDialog(index, location, inboundConnectionsIds);
    }
  }

  private async addNewProcessType(processType: IProcessType, index: number): Promise<void> {
    const dialogRef = this.dialog.open(EditProcessTypeDialogComponent, {
      data: {
        processType: { ...processType, id: null },
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newProcessType: IProcessType }) => {
        if (result?.hasSaved) {
          this.newRecordCreated.emit();
          await this.addInboundMapping(index, result?.newProcessType);
        }
      });
  }

  private getInboundRecordIds(recordType: CrossOrgShareDataTypeEnum): string[] {
    return this.inboundShare()
      .recordUris.filter((uri) => uri.includes(recordType))
      .map((uri) => CommonUtils.getUriId(uri));
  }

  private showUnmappedDependenciesDialog(): void {
    this.dialog.open(InfoDialogComponent, {
      data: {
        title: $localize`Unmapped dependencies found`,
        contentText: $localize`Seems this record has some unmapped dependencies. Please refresh the page, as new / updated data might have appeared.`,
      },
    });
  }

  private async openAddLocationDialog(
    index: number,
    location: ILocationExtended,
    connectionsIds: string[],
  ): Promise<void> {
    try {
      const types = location.types as any as string[];
      const locationTypesIds = types.map((type) => CommonUtils.getUriId(type));
      const mappedLocationTypes = this.getMappedEntities(locationTypesIds);
      const mappedConnection = this.getMappedEntities(connectionsIds)[0];

      let organisation: IOrganisation | undefined;

      if (mappedConnection) {
        organisation = await this.connectionsService.get(CommonUtils.getUriId(mappedConnection));
      }

      const locationTypes = this.getLocationTypesOptions(mappedLocationTypes);

      const dialogResult = await this.overlay.openDialog<
        {
          hasSaved: boolean;
          location: ILocationExtended;
        },
        {
          countryOptions: ISelectOption[];
          location: ILocationExtended;
          locationTypes: InputSelectOption[];
          organisation: IOrganisation;
        }
      >(AddLocationDialogComponent, {
        countryOptions: this.countryOptions(),
        location,
        organisation,
        locationTypes,
      });

      if (dialogResult?.hasSaved) {
        this.newRecordCreated.emit();
        await this.addInboundMapping(index, dialogResult?.location);
      }
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private async openAddDocumentDialog(index: number, document: IDocument): Promise<void> {
    try {
      const inboundTypeId = CommonUtils.getUriId(document.type);
      const mappedDocumentTypes = this.getMappedEntities([inboundTypeId]);
      const inboundDocumentUri = this.inboundShare().recordUris.find((uri) =>
        uri.includes(document.id),
      );

      const documentTypeOption = this.documentTypesOptions().find(
        (t) => t.value === mappedDocumentTypes[0],
      );

      const dialogRef = this.dialog.open(AddDocumentDialogComponent, {
        data: {
          document,
          documentTypesOptions: this.documentTypesOptions(),
          selectedDocumentType: documentTypeOption,
          inboundDocumentUri,
        },
      });

      dialogRef
        .afterClosed()
        .subscribe(async (result: { hasSaved: boolean; newDocument: IDocument }) => {
          if (result?.hasSaved) {
            this.newRecordCreated.emit();
            await this.addInboundMapping(index, result?.newDocument);
          }
        });
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  private getMappedEntities(entitiesIds: string[]): string[] {
    return entitiesIds.map((id) =>
      CommonUtils.getUriId(
        this.existingMappings().find((m) => m.inboundUri.includes(id))?.localUri,
      ),
    );
  }

  private getLocationTypesOptions(mappedLocationTypes: string[]): InputSelectOption[] {
    return this.locationTypesOptions().filter((type) =>
      mappedLocationTypes.includes(type.value as string),
    );
  }

  private getMaterialsOptions(mappedMaterials: string[]): InputSelectOption[] {
    return this.materialsOptions().filter((material) =>
      mappedMaterials.includes(material.value as string),
    );
  }

  private async addNewConnection(inboundRecord: IOrganisation, index: number) {
    const result = await this.overlay.openDialog<
      { savedOrganisation: IOrganisation },
      { connection: IOrganisation; countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.countryOptions(),
      connection: inboundRecord,
    });

    if (result?.savedOrganisation) {
      this.newRecordCreated.emit();
      await this.addInboundMapping(index, result?.savedOrganisation);
    }
  }

  private addNewLocationType(type: ILocationType, index: number): void {
    const dialogRef = this.dialog.open(EditLocationTypeDialogComponent, {
      data: {
        locationType: type,
        isForceAdd: true,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newType: ILocationType }) => {
        if (result?.hasSaved) {
          this.newRecordCreated.emit();
          await this.addInboundMapping(index, result?.newType);
        }
      });
  }

  private addNewDocumentType(type: IDocumentType, index: number): void {
    const dialogRef = this.dialog.open(EditDocumentTypeDialogComponent, {
      data: {
        documentType: type,
        isForceAdd: true,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newDocumentType: IDocumentType }) => {
        if (result?.hasSaved) {
          this.newRecordCreated.emit();
          await this.addInboundMapping(index, result?.newDocumentType);
        }
      });
  }

  public async addInboundMapping(index: number, newRecord: any = null): Promise<void> {
    try {
      const type = this.sharedData()[index];
      const formControlValue = this.formGroup.controls[index].value;
      const typePath = this.shareRecordType().toLocaleLowerCase().replace("_", "-");

      if (formControlValue.recordState === RecordStateEnum.ARCHIVED) {
        const response = await lastValueFrom(
          this.dialog
            .open(ConfirmDialogComponent, {
              data: {
                title: $localize`Archived Entity`,
                contentText: $localize`The selected entity is currently archived. Would you like to unarchive it and create the mapping?`,
                confirmButtonText: TextConstants.CONTINUE,
              },
            })
            .afterClosed(),
        );

        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.unarchiveRecord(formControlValue.value, typePath);
        } else {
          return;
        }
      }
      const localTypeId = newRecord?.id ?? formControlValue.value;
      const inboundUri = `/organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${CommonUtils.getUriId(this.inboundShare().senderUri)}/${typePath}/${type.id}`;
      const localUri =
        this.activeOrganisationId === formControlValue.value
          ? `/organisations/${formControlValue.value}`
          : `/organisations/${this.activeOrganisationId}/${typePath}/${localTypeId}`;

      await this.recordSharingService.addInboundMapping(inboundUri, localUri);
      await this.checkRecordExistance();
      this.notificationsService.showSuccess($localize`New mapping added`);
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.inboundMappingAdded.emit({
        recordId: this.sharedData()[index].id,
        recordType: this.shareRecordType(),
      });
    }
  }

  private async unarchiveRecord(id: string, typePath: string): Promise<void> {
    try {
      await this.recordSharingService.unarchiveRecord(id, typePath);

      this.notificationsService.showSuccess($localize`Record unarchived`);
    } catch (error) {
      this.notificationsService.showError(error);
    }
  }

  public removeMapping(type: any): void {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: TextConstants.DELETE_CONFIRMATION,
          contentText: $localize`Are you sure you want to delete this mapping?`,
          confirmButtonColor: "danger",
          confirmButtonText: TextConstants.DELETE,
          confirmButtonIcon: "delete",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.delete(type.mappedRecordInfo.mappingId, type.id);
        }
      });
  }

  private async delete(mappingId: string, recordId: string): Promise<void> {
    try {
      await this.recordSharingService.removeInboundMapping(mappingId);
      await this.checkRecordExistance();
      this.notificationsService.showSuccess($localize`Mapping removed`);
    } catch (error) {
      this.notificationsService.showError(error);
    } finally {
      this.inboundMappingRemoved.emit({
        recordId,
        recordType: this.shareRecordType(),
      });
    }
  }

  public onView(record: any): void {
    const routerConfig = InboundSharedRecordUtils.getSharedRouteConfig(
      record.id,
      this.shareRecordType(),
      this.router,
      CommonUtils.getUriId(this.inboundShare().senderUri),
    );

    this.router.navigate(routerConfig.linkRouteFn());
  }
}
