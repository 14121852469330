import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBar } from "@angular/material/progress-bar";

import { Toast } from "ngx-toastr";
import { Observable, map } from "rxjs";

import { IDocumentToDownload } from "@shared/interfaces";
import { DownloadDocumentsService } from "@shared/services";

@Component({
  standalone: true,
  template: `
    <div class="full-width" (click)="toggleCollapse()" (keyup)="toggleCollapse()" tabindex="0">
      <div class="container-flex-space-between">
        <div class="container-flex">
          <mat-icon class="alert-icon mr-6">download</mat-icon>
          <div class="title">
            {{ title$ | async }}
          </div>
        </div>
        <mat-icon>
          {{ isCollapsed ? "arrow_drop_down" : "arrow_drop_up" }}
        </mat-icon>
      </div>
      @if (!isCollapsed) {
        <div class="elements-container mt-15 transparent-scrollbar">
          @for (documentToDownload of options.payload | async; track documentToDownload.id) {
            <div class="document">
              <div class="name-container">
                <div class="one-line-wrap-ellipsis">
                  {{ documentToDownload.name }}
                </div>
                @if (documentToDownload.progress !== 100) {
                  <div class="icon-value-container">
                    <mat-icon
                      class="cancel-icon"
                      (click)="$event.stopPropagation(); onCancel(documentToDownload)"
                    >
                      close
                    </mat-icon>
                  </div>
                }
              </div>
              <div class="progress">
                <mat-progress-bar [value]="documentToDownload.progress"></mat-progress-bar>
                <div class="icon-value-container">
                  @if (documentToDownload.progress === 100) {
                    <mat-icon class="success-icon">check</mat-icon>
                  } @else if (documentToDownload.hasError) {
                    <mat-icon class="error">exclamation</mat-icon>
                  } @else {
                    {{ documentToDownload.progress }}%
                  }
                </div>
              </div>
            </div>
          }
        </div>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule, MatProgressBar],
})
export class NotificationDownloadComponent extends Toast<Observable<IDocumentToDownload[]>> {
  private downloadDocumentsService = inject(DownloadDocumentsService);

  public isCollapsed = false;

  public title$ = this.options.payload.pipe(
    map((documents) => {
      const documentsDownloadedCount = documents.filter((d) => d.progress === 100).length;
      const totalDocumentsCount = documents.length;
      const allDocumentsDownloaded = documentsDownloadedCount === totalDocumentsCount;

      return allDocumentsDownloaded
        ? `${$localize`Download completed`}${totalDocumentsCount > 1 ? ` (${totalDocumentsCount})` : ""}`
        : `${$localize`Downloading`} (${documentsDownloadedCount}/${totalDocumentsCount})`;
    }),
  );

  public toggleCollapse = (): void => {
    this.isCollapsed = !this.isCollapsed;
  };

  public onCancel = (documentToDownload: IDocumentToDownload): void => {
    this.downloadDocumentsService.cancelDownload(documentToDownload);
  };
}
