<app-slide-overlay-header
  [canDelete]="true"
  [canShare]="true"
  [canArchive]="true"
  [enableTags]="true"
  (share)="confirmShareDialog()"
  [canSend]="isCrossOrgSharingEnabled && isCrossOrgSharingDeliveryEnabled"
  (send)="confirmSendDialog()"
  [title]="title()"
  icon="local_shipping"
>
  @if (isEudrExtensionEnabled && isEudrReportEnabled && !eudrDdsReport) {
    <app-dropdown-menu-item additional-options (pressed)="onClickGenerateEudrReport()">
      <mat-icon>quick_reference</mat-icon>
      <ng-container i18n>Generate EUDR due diligence statement</ng-container>
    </app-dropdown-menu-item>
  }

  <app-dropdown-menu-item additional-options (pressed)="onClickGenerateReport()">
    <mat-icon>quick_reference</mat-icon>
    <ng-container i18n>Generate report</ng-container>
  </app-dropdown-menu-item>
</app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent (menuItemChanged)="onMenuItemChanged($event)">
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    @if (overlay.viewMode() && isRegularUser) {
      @if (eudrDdsReport) {
        <app-form-field-group name="EUDR DDS">
          <app-delivery-eudr-dds-details
            [eudrDdsReport]="eudrDdsReport"
            [canAddModifyEntities]="canAddModifyEntities"
            [hasDdsStatusUpdateFailed]="hasDdsStatusUpdateFailed()"
          ></app-delivery-eudr-dds-details>
        </app-form-field-group>
      } @else if (isDdsServiceUnavailable) {
        <app-form-field-group name="EUDR DDS">
          {{ serviceUnavailableMsg }}
        </app-form-field-group>
      }
    }
    <app-form-field-group [name]="mainInformationText">
      <app-edit-delivery-main-details
        class="full-width"
        [formGroup]="formGroup"
        [fromLocationOptions]="fromLocationOptions"
        [toLocationOptions]="toLocationOptions"
        [organisationsOptions]="organisationsOptions"
        [statusOptions]="statusOptions"
        (traderTagPressed)="traderTagPressed($event)"
        [locationViewTemplate]="locationViewTemplate"
      ></app-edit-delivery-main-details>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    @if (selectedItems().length) {
      <app-form-field-group name="Product summary" class="pagination-fix display-block">
        <app-quantity-summary-table
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [allProducts]="allProducts"
          [allMaterials]="allMaterials"
          [allUnitOfMeasurements]="allUnitOfMeasurements"
          [selectedItems]="selectedItems()"
          [columns]="['productName', 'materialNames', 'totalQuantity', 'materialData']"
        ></app-quantity-summary-table>
      </app-form-field-group>
    }
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="itemsController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ITEMS).isEnabled"
    [overlay-menu-indicator]="selectedItems().length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.ITEMS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="package_2"
  >
    <article class="field-group-container">
      <app-slide-overlay-delivery-items
        #itemSection
        (addNewItem)="onAddNewItem($event)"
        (reloadItems)="onReloadItems($event)"
        (setSelectedItems)="onSetSelectedItems($event)"
        (updateDeliveredQuantity)="onUpdateDeliveredQuantity($event)"
        (openBulkAddItemsSlideOver)="onOpenBulkAddItemsSlideOver()"
        [allMaterials]="allMaterials"
        [allProducts]="allProducts"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [availableItems]="availableItems"
        [isLoadingItems]="isLoadingItems()"
        [isReadOnly]="isRecordArchived"
        [selectedItems]="selectedItems()"
      ></app-slide-overlay-delivery-items>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="deliveryOverlayService.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.DELIVERY"
      [isReadOnly]="isRecordArchived"
      [overlayService]="deliveryOverlayService"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="documentLinkedCounter"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <app-overlay-document-attachments
      #documentSection
      (selectedAttachmentsUpdated)="setRulesetRecords()"
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.DELIVERY"
      [isReadOnly]="isRecordArchived"
      [overlayService]="deliveryOverlayService"
    ></app-overlay-document-attachments>
  </ng-template>

  <ng-template
    [overlay-menu-buttons-template]="supplyChainController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SUPPLY_CHAIN).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.SUPPLY_CHAIN).title"
    appSlideOverlayMenuItem
    overlay-menu-svg-icon="supply-chain"
  >
    @if (supplyChainTabIndex === supplyChainTabEnum.GRAPH) {
      <app-items-supply-chain
        [allProcessTypes]="allProcessTypes"
        [height]="supplyChainHeight"
        [itemIds]="itemIds"
      ></app-items-supply-chain>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.LOCATIONS) {
      <article class="field-group-container">
        @if (isLoadingSupplyChainLocations()) {
          <app-loader></app-loader>
        } @else {
          <app-locations-table
            [locationsIds]="supplyChainLocationIds"
            [columns]="[
              'recordState',
              'name',
              'organisationName',
              'types',
              'address.countryName',
              'geoLocation',
              'tags',
              'riskAssessmentReport.completedDate',
              'riskAssessmentReport.residualRisk',
            ]"
          ></app-locations-table>
        }
      </article>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.MAP) {
      <article class="field-group-container">
        @if (isLoadingMap()) {
          <app-loader></app-loader>
        } @else {
          <app-items-supply-chain-map [itemIds]="itemIds" [delivery]="deliveryDetails" />
        }
      </article>
    } @else if (
      supplyChainTabIndex === supplyChainTabEnum.DOCUMENTS && isDeliverySupplyChainDocumentsEnabled
    ) {
      <article class="field-group-container">
        <app-delivery-supply-chain-documents
          [deliveryId]="element?.deliveryId"
          [itemIds]="itemIds"
        ></app-delivery-supply-chain-documents>
      </article>
    }
  </ng-template>
  @if (
    !menuItems().get(overlayTabEnum.SHARES).isHidden && deliveryOverlayService.linkedSharesCount()
  ) {
    <ng-template
      [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SHARES).isEnabled"
      [overlay-menu-show-edit-button]="false"
      [overlay-menu-show-save-button]="false"
      [overlay-menu-title]="menuItems().get(overlayTabEnum.SHARES).title"
      [overlay-menu-indicator]="deliveryOverlayService.linkedSharesCount()"
      appSlideOverlayMenuItem
      overlay-menu-icon="share"
    >
      <article class="field-group-container">
        @if (isLoadingShares()) {
          <app-loader></app-loader>
        } @else {
          <app-shares-table
            [shares]="shares"
            [isInOverlay]="true"
            (deleted)="onShareDeleted()"
          ></app-shares-table>
        }
      </article>
    </ng-template>
  }
  @if (!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isHidden) {
    <ng-template
      appSlideOverlayMenuItem
      overlay-menu-icon="select_check_box"
      [overlay-menu-title]="menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).title"
      [overlay-menu-buttons-template]="riskAssessmentController"
      [overlay-menu-show-edit-button]="false"
      [overlay-menu-show-save-button]="false"
      [overlay-menu-indicator]="deliveryOverlayService.reportsCounter()"
      [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isEnabled"
    >
      @if (isLoadingRiskAssessmentRecords()) {
        <app-loader></app-loader>
      } @else {
        <app-overlay-risk-assessment-reports
          #riskAssessmentsView
          [activeOrganisationId]="activeOrganisationId"
          [recordUri]="entityUri"
          [recordName]="element.deliveryId"
          [resourceType]="templateResourceType"
          [overlayService]="deliveryOverlayService"
          [relatedRiskAssessmentReportTableParams]="
            relatedDeliveriesRiskAssessmentReportsService.tableParams()
          "
        />
      }
    </ng-template>
    <ng-template #riskAssessmentController>
      @if (isRecordActive && canAddModifyEntities) {
        <app-button
          type="ghost"
          [disabled]="overlay.loading()"
          (pressed)="riskAssessmentsView.openDialog()"
        >
          <mat-icon class="action-icon" icon-left>add</mat-icon>

          <ng-container i18n>Add</ng-container>
        </app-button>
      }
    </ng-template>
  }
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="deliveryOverlayService.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(deliveryOverlayService)"
        (deleted)="onCommentDeleted(deliveryOverlayService)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #itemsController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(itemSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #documentLinkedCounter>
    @if (deliveryOverlayService.documentCounter() === null) {
      <div class="indicator">
        <mat-icon class="rotate-animation loading-icon">cached</mat-icon>
      </div>
    } @else {
      @if (deliveryOverlayService.hasMissingDocumentTypes()) {
        <div class="indicator no-background">
          <mat-icon class="warn-icon" svgIcon="alert-triangle" [tp]="translations.missingTypesTp" />
        </div>
      }
      @if (deliveryOverlayService.documentCounter()) {
        <div class="indicator">
          {{ deliveryOverlayService.documentCounter() }}
        </div>
      }
    }
  </ng-template>
  <ng-template #supplyChainController>
    <div class="supply-chain-tabs">
      <mat-tab-group
        animationDuration="0ms"
        (selectedIndexChange)="onSelectSupplyChainTab($event)"
        [selectedIndex]="supplyChainTabIndex"
      >
        <mat-tab>
          <ng-template mat-tab-label i18n>Graph</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label i18n>Locations</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label i18n>Map</ng-template>
        </mat-tab>
        @if (isDeliverySupplyChainDocumentsEnabled) {
          <mat-tab>
            <ng-template mat-tab-label i18n>Documents</ng-template>
          </mat-tab>
        }
      </mat-tab-group>
    </div>
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <app-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </app-form-link>
        } @else {
          @if (isSharedUser) {
            <app-not-shared-data></app-not-shared-data>
          } @else {
            <app-loader containerClass="size-small"></app-loader>
          }
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>
