import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  FeatureFlagEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  ICustomField,
  IMaterial,
  IProduct,
  IProductPayload,
  ISelectOption,
} from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  CustomFieldsService,
  FeatureFlagService,
  MaterialsService,
  ProductsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { AttachmentsService } from "./../../../shared/services/api/attachments.service";

@Component({
  standalone: false,
  templateUrl: "./add-product-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(
      null,
      [CustomValidators.required],
      [CustomValidators.entityAlreadyExists(this.productsService, null)],
    ),
    baseUnit: new UntypedFormControl(null, [CustomValidators.required]),
    existingMaterial: new UntypedFormControl(null),
  });

  public isLoading = signal(false);

  public unitOfMeasurementTypesOptions: ISelectOption[] = [];

  public systemUnitsOfMeasurement: IBaseUnit[] = [];

  public visibleCustomFields: ICustomField[] = [];

  private allCustomFields: ICustomField[] = [];

  public materialOptions: ISelectOption[] = [];

  public allMaterials: IMaterial[] = [];

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    baseUnitLabel: $localize`Main measurement type`,
    existingMaterialLabel: $localize`Material`,
  };

  private activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  constructor(
    private dialogRef: MatDialogRef<AddProductDialogComponent>,
    private productsService: ProductsService,
    private notificationService: NotificationService,
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private customFieldService: CustomFieldsService,
    private materialsService: MaterialsService,
    private featureFlagService: FeatureFlagService,
    private authenticationService: AuthenticationService,
    private attachmentsService: AttachmentsService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      product?: IProduct;
      selectedMaterials?: IMaterial[];
    },
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isLoading.set(true);
    await Promise.all([
      await this.setMaterialsOptions(),
      (this.allCustomFields = await this.customFieldService.getAll(
        CustomFieldsResourceTypeEnum.PRODUCT,
      )),
      await this.setUnitsOfMeasurementData(),
    ]);

    this.setUpForm();
  }

  private setUpForm(): void {
    const selectedMaterials = this.data?.selectedMaterials ?? [];

    this.formGroup.controls["name"].setValue(this.data?.product?.name ?? "");
    this.formGroup.controls["baseUnit"].setValue(
      this.unitOfMeasurementTypesOptions.find(
        (unit) => unit.value === CommonUtils.getUriId(this.data?.product?.baseUnit ?? ""),
      ),
    );
    this.formGroup.controls["existingMaterial"].setValue(selectedMaterials);
    this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, []);
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.data?.product?.customFields ?? [],
    );
    this.isLoading.set(false);
  }

  public async onSubmit(): Promise<void> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    const baseUnit = `/common/base-units/${this.formGroup.controls["baseUnit"].value.value}`;
    const defaultUnit = baseUnit;
    let allowedMaterials = [];

    if (this.isOldMaterialsEnabled) {
      allowedMaterials = this.formGroup.controls["existingMaterial"].value.map(
        (material) => `/organisations/${this.activeOrganisationId}/materials/${material.value}`,
      );
    }
    const payload: IProductPayload = {
      baseUnit,
      defaultUnit,
      name: this.formGroup.controls["name"].value.trim(),
      allowedMaterials,
    };

    const createdProduct = await this.productsService.createOrUpdate(payload);

    if (!this.isOldMaterialsEnabled) {
      await Promise.all(
        this.formGroup.controls["existingMaterial"].value.map(
          async (material) => await this.attachMaterial(material.value, createdProduct.id),
        ),
      );
    }

    createdProduct.customUnits = [];

    this.notificationService.showSuccess($localize`Product created`);

    this.isLoading.set(false);

    this.onClose(true, createdProduct);
  }

  private async setUnitsOfMeasurementData(): Promise<void> {
    this.systemUnitsOfMeasurement = await this.unitsOfMeasurementService.getSystemUnits();

    this.unitOfMeasurementTypesOptions = this.systemUnitsOfMeasurement.map((unit) => ({
      label: CommonUtils.capitaliseFirstLetter(unit.type),
      value: unit.id,
    }));
  }

  private async setMaterialsOptions(): Promise<void> {
    this.allMaterials = await this.materialsService.getAll();

    this.materialOptions = this.allMaterials.map((material) => ({
      label: material.name,
      value: material.id,
    }));
  }

  public onClose(hasSaved: boolean = false, createdProduct?: IProduct): void {
    this.dialogRef.close({ hasSaved, createdProduct });
  }

  public attachMaterial = async (materialId: string, productId: string): Promise<void> => {
    try {
      const payload = CommonUtils.getSaveAttachmentPayload(
        this.activeOrganisationId,
        AttachmentTargetEnum.PRODUCT,
        productId,
        AttachmentTypeEnum.MATERIAL,
        materialId,
      );

      await this.attachmentsService.create(payload);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
