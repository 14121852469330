import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CommonUtils } from "src/app/shared/utils";

import { ButtonType } from "@components/shared/buttons/button/button.model";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-copy-text-button",
  templateUrl: "./copy-text-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyTextButtonComponent {
  @Input()
  public text: string;

  @Input()
  public buttonText: string;

  @Input()
  public isShowLinkText?: boolean = true;

  @Input()
  public buttonType?: ButtonType;

  public isShowingText = false;

  constructor(private notificationService: NotificationService) {}

  public onButtonClick = (): void => {
    CommonUtils.textToClipboard(this.text);
    this.notificationService.showSuccess(TextConstants.COPIED);
  };

  public onToggleShowText = (): void => {
    this.isShowingText = !this.isShowingText;
  };
}
