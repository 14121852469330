<h1 *ngIf="data.title" mat-dialog-title class="text-center">
  {{ data.title }}
</h1>
<mat-dialog-content class="text-center">
  <mat-icon *ngIf="data.icon" [class]="data.iconColor">{{ data.icon }}</mat-icon>

  <p *ngIf="data.contentText">
    {{ data.contentText }}
  </p>

  <div *ngIf="data.contentSafeHTML" [innerHTML]="data.contentSafeHTML"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <app-button (pressed)="onClose()" cdkFocusInitial i18n>Close</app-button>
</mat-dialog-actions>
