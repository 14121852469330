<app-drag-drop-list-container
  [placeholder]="translations.indicatorsPh"
  [enableViewMode]="overlay.viewMode()"
  (dropEvent)="onDrop($event)"
>
  @for (item of controls; track item; let index = $index; let first = $first; let last = $last) {
    <app-drag-drop-list-item
      [isDeleteDisabled]="isDeleteButtonDisabled(index)"
      [deleteDisabledText]="translations.validationMessage"
      (moveUp)="onMoveUp(index)"
      (moveDown)="onMoveDown(index)"
      (remove)="onRemove(index)"
    >
      @if (first) {
        <div dragDropListItemTopContent>
          <ng-container *ngTemplateOutlet="addButtons; context: { position: index - 1 }" />
        </div>
      }

      <div dragDropListItemBottomContent>
        <ng-container
          *ngTemplateOutlet="addButtons; context: { position: index + 1, hover: !last }"
        />
      </div>

      <div [formGroup]="item">
        @if (overlay.viewMode() && item.value._type === "category") {
          <h4 class="no-margin">{{ item.value.name }}</h4>
        } @else {
          <app-input
            formControlName="name"
            class="name-field"
            [label]="item.value._title"
            [enableViewMode]="overlay.viewMode()"
            [viewModeCopyMode]="copyModeEnum.NONE"
          />
        }

        @if (item.value._type === "indicator") {
          <div [ngClass]="{ 'form-field-item': overlay.editMode() }">
            <app-rich-text-editor
              [label]="translations.guidanceLabel"
              [placeholder]="translations.guidancePh"
              [control]="item.controls['guidance']"
              [isOptional]="true"
              [enableViewMode]="overlay.viewMode()"
            />
          </div>

          @if (item.value.mitigations.length) {
            <app-form-label [label]="translations.suggestedMitigationsLabel" />

            @for (
              mitigation of item.value.mitigations;
              track mitigation;
              let mitigationIndex = $index
            ) {
              <app-risk-mitigation-card
                [riskMitigation]="mitigation"
                [editMode]="overlay.editMode()"
                (remove)="onRemoveMitigation(index, mitigationIndex, mitigation.id)"
              />
            }
          }

          @if (overlay.editMode()) {
            <app-button
              type="ghost"
              [tp]="translations.mitigationsTp"
              [tpIsLazy]="true"
              (pressed)="openMitigationsPanel(index)"
            >
              <mat-icon icon-left>add</mat-icon>
              <ng-container i18n>Add suggested mitigation(s)</ng-container>
            </app-button>
          }
        }
      </div>
    </app-drag-drop-list-item>
  }
</app-drag-drop-list-container>

<ng-template #addButtons let-position="position" let-hover="hover">
  @if (canAddModifyEntities && overlay.editMode()) {
    <app-button-group class="list-actions" [ngClass]="{ 'hover-visibility-container': hover }">
      <app-button
        type="ghost"
        [ngClass]="{ 'on-hover-visibility-show': hover }"
        (pressed)="add('indicator', position)"
      >
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add indicator</ng-container>
      </app-button>
      <app-button
        type="ghost"
        [ngClass]="{ 'on-hover-visibility-show': hover }"
        (pressed)="add('category', position)"
      >
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add category</ng-container>
      </app-button>
    </app-button-group>
  }
</ng-template>

<ng-template #controller>
  @if (canAddModifyEntities) {
    @if (overlay.editMode()) {
      <app-button type="ghost" (pressed)="cancel()" i18n>Cancel</app-button>
      <app-button
        [disabled]="isSubmitButtonDisabled"
        [tp]="translations.validationMessage"
        [tpIsLazy]="true"
        [tpIsEnabled]="isSubmitButtonDisabled"
        (pressed)="save()"
        i18n
      >
        Save
      </app-button>
    } @else {
      <app-button
        type="ghost"
        (pressed)="overlay.enableEditMode()"
        [disabled]="overlay.loading()"
        i18n
      >
        Edit
      </app-button>
    }
  }
</ng-template>

<ng-template #mitigationsPanel>
  <app-overlay-risk-mitigations-right-panel
    [excludeIds]="excludedMitigationIds()"
    (add)="onAddIndicatorMitigation($event)"
  />
</ng-template>
