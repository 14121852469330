@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="full-width-items-container">
    @for (item of items; track $index) {
      <div class="item hover-visibility-container">
        <app-product-card-content
          [product]="item"
          (remove)="remove.emit(item)"
        ></app-product-card-content>
      </div>
    }
  </div>
}
