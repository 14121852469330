import { Component, inject, ViewChild } from "@angular/core";

import { RecordSharingService } from "@shared/services";

import { InboxTableComponent } from "./inbox-table/inbox-table.component";

@Component({ standalone: false, templateUrl: "./inbox.component.html" })
export class InboxComponent {
  @ViewChild("updatesTable") updatesTable: InboxTableComponent;

  private readonly recordSharingService = inject(RecordSharingService);

  public readonly translations: any = {
    title: $localize`Inbox`,
  };

  public newInboundSharesCountObservable$ =
    this.recordSharingService.newInboundSharesCountObservable$;
}
