<div class="row-container">
  <form [formGroup]="formGroup">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>{{ mainInformationText }}</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row row-2 bottom-row">
          <div class="item">
            <app-input formControlName="name" [label]="translations.nameLabel"></app-input>
          </div>
          <div class="item">
            <app-input
              formControlName="description"
              [label]="translations.descriptionName"
            ></app-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              <ng-container i18n>Criteria</ng-container>
              <mat-icon class="info-icon" [tp]="translations.criteriaTp">help</mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="dark-gray no-margin-top">
          <small i18n>
            Specifying no criteria will make the ruleset applicable to all deliveries.
          </small>
        </p>

        <h5>
          <ng-container i18n>Deliveries "from" location</ng-container>
          <mat-icon class="info-icon" [tp]="translations.fromLocationsTp">help</mat-icon>
        </h5>
        <div>
          <mat-radio-group>
            <mat-radio-button
              (change)="deliveryFromCriteriaChanged.emit(CriteriaTypeEnum.PARAMETERS)"
              [checked]="deliveryFromCriteria === CriteriaTypeEnum.PARAMETERS"
              [value]="CriteriaTypeEnum.PARAMETERS"
              i18n
            >
              Parameters
            </mat-radio-button>
            <mat-radio-button
              (change)="deliveryFromCriteriaChanged.emit(CriteriaTypeEnum.EXACT)"
              [checked]="deliveryFromCriteria === CriteriaTypeEnum.EXACT"
              [value]="CriteriaTypeEnum.EXACT"
              i18n
            >
              Exact
            </mat-radio-button>
          </mat-radio-group>
          <br />
          <br />
          @switch (deliveryFromCriteria) {
            @case (CriteriaTypeEnum.PARAMETERS) {
              <app-location-criteria-info
                #deliveryFrom
                formControlPrefix="from"
                [formGroup]="formGroup"
                (newLocationTypeCreated)="newLocationTypeCreated.emit()"
                [allCountriesOption]="allCountriesOption"
              ></app-location-criteria-info>
            }
            @case (CriteriaTypeEnum.EXACT) {
              <div class="row row-1">
                <div class="item">
                  <app-input-chips
                    #fromLocationSelect
                    formControlName="fromLocations"
                    [options]="allLocationOptionsExceptSelectedBy('toLocations')"
                    [label]="translations.fromLocationsLabel"
                    [tooltip]="translations.fromLocationsTooltip"
                  ></app-input-chips>
                </div>
              </div>
            }
          }
        </div>

        <h5>
          <ng-container i18n>Deliveries "to" location</ng-container>
          <mat-icon class="info-icon" [tp]="translations.toLocationsTp">help</mat-icon>
        </h5>
        <div>
          <mat-radio-group>
            <mat-radio-button
              (change)="deliveryToCriteriaChanged.emit(CriteriaTypeEnum.PARAMETERS)"
              [checked]="deliveryToCriteria === CriteriaTypeEnum.PARAMETERS"
              [value]="CriteriaTypeEnum.PARAMETERS"
              i18n
            >
              Parameters
            </mat-radio-button>
            <mat-radio-button
              (change)="deliveryToCriteriaChanged.emit(CriteriaTypeEnum.EXACT)"
              [checked]="deliveryToCriteria === CriteriaTypeEnum.EXACT"
              [value]="CriteriaTypeEnum.EXACT"
              i18n
            >
              Exact
            </mat-radio-button>
          </mat-radio-group>
          <br />
          <br />
          @switch (deliveryToCriteria) {
            @case (CriteriaTypeEnum.PARAMETERS) {
              <app-location-criteria-info
                #deliveryTo
                formControlPrefix="to"
                [formGroup]="formGroup"
                (newLocationTypeCreated)="newLocationTypeCreated.emit()"
                [allCountriesOption]="allCountriesOption"
              ></app-location-criteria-info>
            }
            @case (CriteriaTypeEnum.EXACT) {
              <div class="row row-1">
                <div class="item">
                  <app-input-chips
                    #toLocationSelect
                    formControlName="toLocations"
                    [options]="allLocationOptionsExceptSelectedBy('fromLocations')"
                    [label]="translations.toLocationsLabel"
                    [tooltip]="translations.toLocationsTooltip"
                  ></app-input-chips>
                </div>
              </div>
            }
          }
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              <ng-container i18n>Expectations</ng-container>
              <mat-icon class="info-icon" [tp]="translations.expectationsTp">help</mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <h4 class="dark-gray no-margin-top">
          <ng-container i18n>Document types</ng-container>

          <app-button
            (pressed)="openDocumentTypeDialog.emit()"
            class="icon-add-circle"
            type="icon"
            [tp]="translations.addDocumentTypeTp"
          >
            <mat-icon icon-medium-18>add</mat-icon>
          </app-button>
        </h4>
        <p class="dark-gray">
          <small i18n>
            Specify which documents should be attached to a delivery based on the ruleset criteria.
            At least one should be specified.
          </small>
        </p>
        <ng-content></ng-content>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
