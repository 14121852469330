import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  computed,
  inject,
  input,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ColDef } from "ag-grid-community";

import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CommonConstants, TextConstants } from "@shared/constants";
import { BatchActionTypeEnum, EntityTypeEnum, RecordStateEnum, TableEnum } from "@shared/enums";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { RiskAssessmentTemplatesApiService } from "../api";
import { RiskAssessmentTemplateResourceType } from "../constants";
import { RiskAssessmentTemplate } from "../models";

@Component({
  standalone: false,
  selector: "app-risk-assessment-templates-table",
  templateUrl: "./risk-assessment-templates-table.component.html",
  // standalone: true,
  // imports: [AsyncPipe, ShareComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAssessmentTemplatesTableComponent implements AfterViewInit {
  private readonly destroyRef = inject(DestroyRef);

  private readonly overlay = inject(SlideOverlayPageService);

  private readonly router = inject(RouterService);

  private readonly api = inject(RiskAssessmentTemplatesApiService);

  private readonly notification = inject(NotificationService);

  readonly areButtonsEnabled = input(true);

  readonly isFixedBottomPaginator = input(false);

  readonly isPaginatorEnabled = input(true);

  readonly isRecordStateFilterEnabled = input(true);

  readonly isSaveTableState = input(false);

  readonly isSearchEnabled = input(true);

  readonly recordState = input(RecordStateEnum.ALL);

  readonly resourceType = input.required<RiskAssessmentTemplateResourceType>();

  readonly isBatchActionsEnabled = input(false);

  readonly isLoading = signal(true);

  readonly columnDefs = signal<ColDef[]>([]);

  readonly rowData = signal<RiskAssessmentTemplate[]>([]);

  readonly batchActionSettings = signal<BatchActionModel.IBatchActionSettings>(undefined);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  readonly table = TableEnum.RISK_ASSESSMENT_TEMPLATES;

  constructor() {
    this.overlay.refreshTable$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((instance: SlideOverlayPageClass) => {
        if (
          instance.entityType === EntityTypeEnum.RISK_ASSESSMENT_TEMPLATES &&
          instance.element.appliesTo === this.resourceType()
        ) {
          this.getAll();
        }
      });
  }

  async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  async getAll() {
    this.isLoading.set(true);

    try {
      const { content } = await this.api.getAll({
        appliesTo: this.resourceType(),
        page: 0,
        size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
      });

      this.rowData.set(content);
      this.isLoading.set(false);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  onView(row: RiskAssessmentTemplate) {
    if (this.areButtonsEnabled()) {
      this.router.navigate(
        this.router.getRiskAssessmentTemplateLink(row.id, true, { type: this.resourceType() }),
      );
    }
  }

  private setColumnDefs() {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
      },
      {
        headerName: TextConstants.DESCRIPTION,
        field: "description",
        valueGetter: (params) => CommonUtils.stripHtml(params.data.description),
        valueFormatter: ColumnUtils.longTextValueFormatter(),
      },
    ];

    if (this.batchActionSettings()) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  }

  private setBatchActionSettings() {
    if (this.isBatchActionsEnabled()) {
      this.batchActionSettings.set({
        actions: new Map([
          [
            BatchActionTypeEnum.ARCHIVE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.api),
          ],
          [
            BatchActionTypeEnum.UNARCHIVE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.api),
          ],
          [
            BatchActionTypeEnum.DELETE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.api),
          ],
        ]),
      });
    }
  }
}
