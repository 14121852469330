<mat-card class="flat-card hover-inline-block-container">
  <mat-card-content class="mitigation-card-content">
    <div class="mitigation-card-content__left">
      <span class="mitigation-text" [tp]="riskMitigation().mitigation" [tpIsLazy]="true">
        {{ riskMitigation().mitigation }}
      </span>
      @if (riskMitigation()?.description?.length) {
        <mat-icon class="icon-18" [tp]="riskMitigation().description | stripHtml">help</mat-icon>
      }
    </div>
    <div class="mitigation-card-content__right">
      @if (editMode()) {
        <div class="mitigations-actions-container on-hover-show">
          <app-button-group class="cg-10">
            @if (isIndicatorMitigation && status) {
              <app-button
                type="icon"
                size="medium"
                [tp]="translations.resetTp"
                [tpIsLazy]="true"
                (pressed)="statusChange.emit(null)"
              >
                <mat-icon icon-medium-18>replay</mat-icon>
              </app-button>
            }

            @if (isIndicatorMitigation && !status) {
              <app-button
                type="icon"
                size="medium"
                [tp]="translations.markAsNotApplicableTp"
                [tpIsLazy]="true"
                (pressed)="statusChange.emit(mitigationStatus.NOT_APPLICABLE)"
              >
                <mat-icon icon-medium-18>block</mat-icon>
              </app-button>
            }

            <app-button
              type="icon"
              size="medium"
              [tp]="translations.removeTp"
              [tpIsLazy]="true"
              (pressed)="remove.emit()"
            >
              <mat-icon icon-medium-18>delete</mat-icon>
            </app-button>

            @if (isIndicatorMitigation && !status) {
              <app-button
                type="icon"
                size="medium"
                [tp]="translations.markAsCompletedTp"
                [tpIsLazy]="true"
                (pressed)="statusChange.emit(mitigationStatus.COMPLETED)"
              >
                <mat-icon icon-medium-18>check</mat-icon>
              </app-button>
            }
          </app-button-group>
        </div>
      }

      @if (isIndicatorMitigation && status) {
        <span class="mitigation-status container-flex">
          @if (status === mitigationStatus.COMPLETED) {
            <mat-icon class="icon-18 green" [tp]="translations.completedTp" [tpIsLazy]="true">
              check
            </mat-icon>
          } @else if (status === mitigationStatus.NOT_APPLICABLE) {
            <mat-icon
              class="icon-18 dark-gray"
              [tp]="translations.notApplicableTp"
              [tpIsLazy]="true"
            >
              block
            </mat-icon>
          }
        </span>
      }
    </div>
  </mat-card-content>
</mat-card>
