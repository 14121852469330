import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-right-side-panel-no-items",
  templateUrl: "./right-side-panel-no-items.component.html",
  styleUrls: ["./right-side-panel-no-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RightSidePanelNoItemsComponent {
  @Input()
  public itemType: string;

  @Input()
  public isAddOpenInNewTab = true;
}
