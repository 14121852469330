@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-1">
          <div class="item">
            @switch (fieldType) {
              @case (fieldTypeEnum.INPUT) {
                <app-input
                  [formControlName]="formControlName"
                  [label]="label"
                  [type]="params().inputType"
                  [suffix]="params().suffix"
                  [hint]="params().hint"
                ></app-input>
              }
              @case (fieldTypeEnum.SELECT) {
                <app-input-select
                  class="no-padding"
                  [options]="params().options"
                  [label]="label"
                  [tooltip]="params().tooltip"
                  [formControlName]="formControlName"
                  [prefixTemplate]="params().prefixTemplate === 'flag' ? flag : undefined"
                ></app-input-select>
                <ng-template #flag let-option>
                  <app-flag [country]="option.value"></app-flag>
                </ng-template>
              }

              @case (fieldTypeEnum.CHIPS) {
                <app-input-chips
                  #inputChips
                  [label]="label"
                  [allowCreateTag]="params().allowCreateTag"
                  [tagUrl]="params().tagUrl"
                  [allowDeleteTag]="false"
                  [options]="params().options"
                  [formControlName]="formControlName"
                ></app-input-chips>
              }
              @case (fieldTypeEnum.TAGS) {
                <app-form-label [label]="label"></app-form-label>
                <app-entity-tags
                  [initialTags]="params().initialTags"
                  [entityType]="entityTypeEnum.ITEMS"
                  (tagsChanged)="onTagsChanged($event)"
                ></app-entity-tags>
              }
              @case (fieldTypeEnum.DATE_PICKER) {
                <div>
                  @if (
                    formGroup.controls[params().dateTypeFormControlName].value ===
                    dateTypeEnum.EXACT
                  ) {
                    <app-datepicker
                      [label]="label"
                      class="no-padding"
                      [formControlName]="params().fromFormControlName"
                    ></app-datepicker>
                  } @else if (
                    formGroup.controls[params().dateTypeFormControlName].value ===
                    dateTypeEnum.RANGE
                  ) {
                    <app-daterangepicker
                      [label]="label"
                      class="no-padding"
                      [formControlName]="params().rangeFormControlName"
                    ></app-daterangepicker>
                  }

                  <app-checkbox
                    [formControlName]="params().isRangeDateTypeFormControlName"
                    [label]="translations.isRangeDateTypeLabel"
                  ></app-checkbox>
                </div>
              }
            }
          </div>
        </div>
        @if (params().templateRef) {
          <div class="row row-1">
            <ng-container *ngTemplateOutlet="params().templateRef"></ng-container>
          </div>
        }
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </form>
}
