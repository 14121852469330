<div [formGroup]="formGroup()" class="container">
  <div class="index">
    <div class="container-flex add-gap">
      <div class="circle clickable" (click)="scrollToPages()">{{ index() }}</div>
      {{ pageRangeText() }}
    </div>

    <div class="container-flex">
      @if (canDelete()) {
        <mat-icon class="clickable" (click)="onDelete()">delete</mat-icon>
      }
      <app-checkbox
        [tp]="translations.includesTp"
        [formControlName]="documentFieldEnum.IS_INCLUDED"
      ></app-checkbox>
    </div>
  </div>

  <app-input
    [formControlName]="documentFieldEnum.NAME"
    [label]="translations.nameLabel"
  ></app-input>

  <app-input-select
    [label]="translations.typeLabel"
    [formControlName]="documentFieldEnum.TYPE"
    [addOnWrite]="true"
    [options]="documentTypeOptions()"
    [tooltip]="typeTooltipHint"
  ></app-input-select>

  <app-form-label [label]="translations.tagsLabel"></app-form-label>
  <app-entity-tags
    (tagsChanged)="onTagsChanged($event)"
    [initialTags]="formGroup().get(documentFieldEnum.TAGS).value"
    [entityType]="entityTypeEnum.DOCUMENTS"
    [setEditMode]="true"
  ></app-entity-tags>

  <div>
    <app-checkbox
      [formControlName]="documentFieldEnum.IS_DATES_ENABLED"
      [label]="translations.checkboxPeriodLabel"
    ></app-checkbox>
  </div>

  @if (formGroup().controls[documentFieldEnum.IS_DATES_ENABLED].value) {
    <div class="dates">
      <app-datepicker
        [label]="translations.issuanceLabel"
        [formControlName]="documentFieldEnum.ISSUANCE"
        [maxDate]="
          formGroup().get(documentFieldEnum.VALIDITY_START).value ??
          formGroup().get(documentFieldEnum.VALIDITY_END).value
        "
        class="datepicker"
      ></app-datepicker>
      <app-datepicker
        [label]="translations.validityStartLabel"
        [formControlName]="documentFieldEnum.VALIDITY_START"
        [minDate]="formGroup().get(documentFieldEnum.ISSUANCE).value"
        [maxDate]="formGroup().get(documentFieldEnum.VALIDITY_END).value"
        class="datepicker"
      ></app-datepicker>
      <app-datepicker
        [label]="translations.validityEndLabel"
        [formControlName]="documentFieldEnum.VALIDITY_END"
        [minDate]="
          formGroup().get(documentFieldEnum.VALIDITY_START).value ??
          formGroup().get(documentFieldEnum.ISSUANCE).value
        "
        class="datepicker"
      ></app-datepicker>
    </div>
  }
</div>
