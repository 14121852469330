@if (isLoading()) {
  <app-loader containerClass="no-margin"></app-loader>
} @else {
  <div class="row-container">
    @for (chunk of chunkFields(); track chunk) {
      <div class="row row-{{ columns() }}">
        @for (field of chunk; track field) {
          <app-form-input-readonly
            [label]="field.label"
            [value]="field.value"
            [valueTooltip]="field.valueTooltip"
          ></app-form-input-readonly>
        }
      </div>
    }
  </div>
}
