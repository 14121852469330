<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>{{ isEditing() ? translations.editStandard : translations.addStandard }}</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input
        [label]="translations.nameLabel"
        formControlName="name"
        [autofocus]="!isLoading() && !isEditing()"
      ></app-input>
      <div class="type-title">
        <h3>Standard types</h3>
        <button
          type="button"
          color="primary"
          mat-icon-button
          [tp]="translations.addNewStandardTp"
          (click)="onAddStandardType()"
        >
          <mat-icon>add_circle</mat-icon>
        </button>
      </div>
      @for (controlName of formGroup.controls | keyvalue; track controlName.key) {
        @if (controlName.key.startsWith("new-type-")) {
          <div class="new-controls">
            <app-input
              [placeholder]="translations.newStandardTypeNamePh"
              class="full-width"
              [formControlName]="controlName.key"
            ></app-input>
            <app-button
              class="no-border"
              type="ghost"
              [tp]="translations.removeTp"
              (pressed)="removeFormControl(controlName.key)"
            >
              <mat-icon>delete</mat-icon>
            </app-button>
          </div>
        }
      }
      @for (controlName of formGroup.controls | keyvalue; track controlName.key) {
        @if (controlName.key !== "name" && !controlName.key.startsWith("new-type-")) {
          <div class="new-controls">
            <app-input
              [placeholder]="translations.newStandardTypeNamePh"
              class="full-width"
              [formGroup]="formGroup"
              [formControlName]="controlName.key"
            ></app-input>
            <app-button
              type="ghost"
              class="no-border"
              (pressed)="toggleArchiveStatus(controlName.key)"
              [tp]="
                (controlName.key | getTypeRecordState: data?.standard?.standardTypes) ===
                recordStateEnum.ACTIVE
                  ? 'Archive'
                  : 'Unarchive'
              "
            >
              <mat-icon>
                {{
                  (controlName.key | getTypeRecordState: data?.standard?.standardTypes) ===
                  recordStateEnum.ACTIVE
                    ? "archive"
                    : "inventory"
                }}
              </mat-icon>
            </app-button>
          </div>
        }
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost">Cancel</app-button>
        <app-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          Save
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
