import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { TextConstants } from "@shared/constants";
import { RouteEnum, UnitOfMeasurementSourceTypeEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";

import { EditCustomUnitDialogComponent, UnitsOfMeasurementTableComponent } from "../..";

@Component({
  standalone: false,
  templateUrl: "./settings-units-of-measurement.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsUnitsOfMeasurementComponent {
  public readonly unitSourceTypes = UnitOfMeasurementSourceTypeEnum;

  @ViewChild("customUnitsTable") customUnitsTable: UnitsOfMeasurementTableComponent;

  public readonly routingEnum = RouteEnum;

  public selectedTabIndex = 0;

  public readonly translations = {
    title: TextConstants.UNITS_OF_MEASUREMENT,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  constructor(
    private dialog: MatDialog,
    public authenticationService: AuthenticationService,
  ) {}

  onAdd() {
    const dialogRef = this.dialog.open(EditCustomUnitDialogComponent, { width: "665px" });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        this.selectedTabIndex = 1;
        await this.customUnitsTable.getAll();
      }
    });
  }
}
