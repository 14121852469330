import { FileExtensionEnum, GeoJsonTypeEnum, LanguageEnum, TagsColorEnum } from "../enums";

const NOTIFICATION_DURATION_MS = 3500;
const NOTIFICATION_ERROR_DURATION_MS = 5000;
const NOTIFICATION_DOWNLOADS_DURATION_MS = 5000;
const DEBOUNCE_SEARCH_TIME_MS = 500;
const DEBOUNCE_COPY_PASTE_MS = 250;
const DEBOUNCE_RESIZE_WINDOW_TIME_MS = 50;
const DEBOUNCE_TABLE_SAVE_STATE_TIME_MS = 50;
const DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS = 25;
const DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS = 50;
const DEBOUNCE_10_MS = 10;

const DATE_FORMAT = "MMM dd, yyyy";
const DATE_TIME_FORMAT = "MMM dd, yyyy - HH:mm";

const TOKEN_CLIENT_ID = "1";

const MAX_DOCUMENT_SIZE_IN_MB = 500;
const MAX_GEO_JSON_SIZE_IN_MB = 1;
const DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS = [
  FileExtensionEnum.PDF,
  FileExtensionEnum.PNG,
  FileExtensionEnum.JPG,
  FileExtensionEnum.JPEG,
  FileExtensionEnum.DOC,
  FileExtensionEnum.DOCX,
  FileExtensionEnum.XLS,
  FileExtensionEnum.XLSX,
  FileExtensionEnum.PPT,
  FileExtensionEnum.PPTX,
  FileExtensionEnum.CSV,
  FileExtensionEnum.TXT,
  FileExtensionEnum.GEOJSON,
  FileExtensionEnum.JSON,
];
const DOCUMENT_ALLOW_TO_VIEW_FILE_EXTENSIONS = [
  FileExtensionEnum.PDF,
  FileExtensionEnum.PNG,
  FileExtensionEnum.JPG,
  FileExtensionEnum.JPEG,
  FileExtensionEnum.TXT,
  FileExtensionEnum.GEOJSON,
  FileExtensionEnum.JSON,
];

const GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS = [FileExtensionEnum.GEOJSON, FileExtensionEnum.JSON];

const GEO_JSON_VALID_TYPES = [
  GeoJsonTypeEnum.POINT,
  GeoJsonTypeEnum.POLYGON,
  GeoJsonTypeEnum.MULTI_POLYGON,
];

const DEFAULT_NUMBER_PIPE_ROUNDING = "1.0-2";

const AVAILABLE_LANGUAGE_CODES = [LanguageEnum.ENGLISH, LanguageEnum.SPANISH, LanguageEnum.FRENCH];
const DEFAULT_LANGUAGE_CODE = LanguageEnum.ENGLISH;
const DEFAULT_LANGUAGE_LOCALE_ID = "en-GB";

const MAX_API_GET_ITEMS_SIZE = 2000;
const COMMENTS_MAX_LENGTH = 2000;

const TABLES_AVAILABLE_PAGE_SIZES = [10, 25, 50, 100];
const DEFAULT_TABLES_PAGE_SIZE = TABLES_AVAILABLE_PAGE_SIZES[0];

const CERTIFICATE_DATE_RULES = [
  {
    name: "issuanceDate",
    rules: [
      {
        type: ">",
        target: "validFromDate",
        errorMessage: $localize`Cannot be later than Valid from date`,
      },
      {
        type: ">",
        target: "validToDate",
        errorMessage: $localize`Cannot be later than Valid to date`,
      },
    ],
  },
  {
    name: "validFromDate",
    rules: [
      {
        type: "<",
        target: "issuanceDate",
        errorMessage: $localize`Cannot be before than Issuance date`,
      },
      {
        type: ">",
        target: "validToDate",
        errorMessage: $localize`Cannot be later than Valid to date`,
      },
    ],
  },
  {
    name: "validToDate",
    rules: [
      {
        type: "<",
        target: "issuanceDate",
        errorMessage: $localize`Cannot be before than Issuance date`,
      },
      {
        type: "<",
        target: "validFromDate",
        errorMessage: $localize`Cannot be before than Valid from date`,
      },
    ],
  },
];

const DOCUMENT_DATE_RULES = [
  {
    name: "issuance",
    rules: [
      {
        type: ">",
        target: "validityStart",
        errorMessage: $localize`Cannot be later than Valid from date`,
      },
      {
        type: ">",
        target: "validityEnd",
        errorMessage: $localize`Cannot be later than Valid to date`,
      },
    ],
  },
  {
    name: "validityStart",
    rules: [
      {
        type: "<",
        target: "issuance",
        errorMessage: $localize`Cannot be before than Issuance date`,
      },
      {
        type: ">",
        target: "validityEnd",
        errorMessage: $localize`Cannot be later than Valid from date`,
      },
    ],
  },
  {
    name: "validityEnd",
    rules: [
      {
        type: "<",
        target: "validityStart",
        errorMessage: $localize`Cannot be before than Valid from date`,
      },
    ],
  },
];

const SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS = "full-screen-supply-chain-dialog";

const MAX_TOOLTIP_LINES_AMOUNT = 10;

const DEFAULT_TAG_COLOUR = TagsColorEnum.LIGHT_GREY;

const MAX_CHIPS_TEXT_LENGTH_TO_SHOW = 25; // if this value changes, we need to change the value on .max-chip-characters

const ANGULAR_GOOGLE_MAPS_SCRIPT_URL = (language: LanguageEnum): string =>
  //todo move the key to the config file (all envs)
  `https://maps.googleapis.com/maps/api/js?key=AIzaSyA1ux4nkqX1krnehjYSQHyuXZMfB4QUDEk&loading=async&region=GB&language=${language}`;

export const CommonConstants = {
  NOTIFICATION_DURATION_MS,
  NOTIFICATION_ERROR_DURATION_MS,
  NOTIFICATION_DOWNLOADS_DURATION_MS,
  DEBOUNCE_SEARCH_TIME_MS,
  DEBOUNCE_COPY_PASTE_MS,
  DEBOUNCE_RESIZE_WINDOW_TIME_MS,
  DEBOUNCE_TABLE_SAVE_STATE_TIME_MS,
  DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS,
  DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS,
  DEBOUNCE_10_MS,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TOKEN_CLIENT_ID,
  MAX_DOCUMENT_SIZE_IN_MB,
  MAX_GEO_JSON_SIZE_IN_MB,
  DOCUMENT_UPLOAD_ALLOWED_FILE_EXTENSIONS,
  DOCUMENT_ALLOW_TO_VIEW_FILE_EXTENSIONS,
  GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS,
  GEO_JSON_VALID_TYPES,
  DEFAULT_NUMBER_PIPE_ROUNDING,
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_LANGUAGE_LOCALE_ID,
  AVAILABLE_LANGUAGE_CODES,
  MAX_API_GET_ITEMS_SIZE,
  TABLES_AVAILABLE_PAGE_SIZES,
  DEFAULT_TABLES_PAGE_SIZE,
  CERTIFICATE_DATE_RULES,
  SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS,
  MAX_TOOLTIP_LINES_AMOUNT,
  DEFAULT_TAG_COLOUR,
  MAX_CHIPS_TEXT_LENGTH_TO_SHOW,
  COMMENTS_MAX_LENGTH,
  DOCUMENT_DATE_RULES,
  ANGULAR_GOOGLE_MAPS_SCRIPT_URL,
};
