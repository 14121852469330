import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import {
  BulkAddItemsCreateRecordsModel,
  BulkAddItemsCreateRecordsModel as Model,
} from "@components/items/bulk-add-items/create-records/bulk-add-items-create-records.model";
import { BulkAddCreateRecords } from "@components/shared/bulk-add/bulk-add-create-records";
import { DateTypeEnum, EntityTypeEnum, StartEndEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IDeliveryPayload,
  IItem,
  IItemPayloadWithTags,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import { DeliveriesService, ItemsService } from "@shared/services";
import { CommonUtils, FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-bulk-add-items-create-records",
  templateUrl: "./bulk-add-items-create-records.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddItemsCreateRecordsComponent
  extends BulkAddCreateRecords<Model.IPayloadWithStatus, IItemPayloadWithTags>
  implements AfterViewInit
{
  public bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  private unitConversionPipe: UnitConversionPipe = inject(UnitConversionPipe);

  private itemsService: ItemsService = inject(ItemsService);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  @Input() public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  public readonly statusEnum = Model.StatusEnum;

  private readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  private entityType = EntityTypeEnum.ITEMS;

  public async ngAfterViewInit(): Promise<void> {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    this.payloadsWithStatus.set(
      this.formGroup.controls[this.fieldEnum.RECORDS].controls.map((item) =>
        this.getPayloadWithStatusForItem(item),
      ),
    );

    const items = await this.createRecords(this.payloadsWithStatus());

    const successfulItemsCreatedCount = this.payloadsWithStatus().filter(
      (payload) => payload.status === this.statusEnum.SUCCESS,
    ).length;

    if (successfulItemsCreatedCount) {
      this.notificationService.showSuccess(
        $localize`${successfulItemsCreatedCount}:successfulItemsCreatedCount: item(s) created`,
      );

      if (this.bulkAddItemsService.delivery()) {
        try {
          await this.addItemsToDelivery(items);
        } catch {
          this.notificationService.showError($localize`Failed to add items to the delivery`);
        }
      }
    } else {
      this.notificationService.showError($localize`No items were created`);
    }

    this.hasProcessCompleted.set(true);

    this.bulkAddItemsService.resultSubject.next({ newRecords: items });

    this.creationProcessComplete.emit();
  }

  public async createRecord(
    payloadsWithStatus: BulkAddItemsCreateRecordsModel.IPayloadWithStatus,
  ): Promise<IItem> {
    let item: IItem;

    const recordPayload = payloadsWithStatus.recordPayload;

    try {
      item = await this.itemsService.createOrUpdate(recordPayload);
    } catch {
      payloadsWithStatus.status = BulkAddItemsCreateRecordsModel.StatusEnum.ERROR;

      return null;
    }

    const entityUri = `/organisations/${this.activeOrganisationId}/${this.entityType}/${item.id}`;

    await this.saveTagsForNewRecord(recordPayload.tags, entityUri);

    payloadsWithStatus.status = BulkAddItemsCreateRecordsModel.StatusEnum.SUCCESS;

    return item;
  }

  public async createRecords(
    payloadsWithStatus: BulkAddItemsCreateRecordsModel.IPayloadWithStatus[],
  ): Promise<IItem[]> {
    const items: IItem[] = [];

    for (let i = 0; i < payloadsWithStatus.length; i += 1) {
      if (this.bulkAddItemsService.abortController.signal.aborted) {
        payloadsWithStatus[i].status = BulkAddItemsCreateRecordsModel.StatusEnum.CANCELED;
        continue;
      }

      const item = await this.createRecord(payloadsWithStatus[i]);

      if (item) {
        item.deliveredQuantity = payloadsWithStatus[i].recordPayload.deliveredQuantity;
        item.selectedQuantity = payloadsWithStatus[i].recordPayload.selectedQuantity;

        items.push(item);
      }

      this.cdr.detectChanges();
    }

    return items;
  }

  public async addItemsToDelivery(items: IItem[]): Promise<void> {
    const delivery = this.bulkAddItemsService.delivery();

    const newDeliveryItems = items.map((item) => {
      return {
        item: `/organisations/${this.activeOrganisationId}/items/${item.id}`,
        quantity: item.deliveredQuantity,
      };
    });

    const payload: IDeliveryPayload = {
      id: delivery.id,
      deliveryId: delivery.deliveryId,
      from: delivery.from,
      to: delivery.to,
      status: delivery.status,
      sent: delivery.sent,
      delivered: delivery.delivered,
      agents: delivery.agents,
      customFields: delivery.customFields,
      items: [...delivery.items, ...newDeliveryItems],
    };

    await this.deliveriesService.createOrUpdate(payload, delivery.id);
  }

  private get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  private get baseUnitOfMeasurements(): IBaseUnit[] {
    return this.bulkAddItemsService.baseUnitOfMeasurements;
  }

  private get customUnitOfMeasurements(): ICustomUnitOfMeasurement[] {
    return this.bulkAddItemsService.customUnitOfMeasurements;
  }

  private getPayloadWithStatusForItem(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): Model.IPayloadWithStatus {
    const values = formGroup.getRawValue();
    const { fieldEnum } = this;
    const uriPrefix = `/organisations/${this.activeOrganisationId}`;

    let initialQuantity = values[fieldEnum.INITIAL_QUANTITY];
    let deliveredQuantity = values[fieldEnum.DELIVERY_QUANTITY];
    let processInputQuantity = values[fieldEnum.PROCESS_INPUT_QUANTITY];

    const productId = values[fieldEnum.PRODUCT].value;
    const product = this.allProducts.find((product) => product.id === productId);

    const baseUnitId = CommonUtils.getUriId(product.baseUnit);
    const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

    const unitOfMeasurementOption = values[fieldEnum.UNIT_OF_MEASUREMENT];

    if (baseUnit.id !== unitOfMeasurementOption.value) {
      const selectedUnitOfMeasurement = [
        ...this.baseUnitOfMeasurements,
        ...this.customUnitOfMeasurements,
      ].find((unit) => {
        return unit.id === unitOfMeasurementOption.value;
      });

      initialQuantity = +this.unitConversionPipe.transform(
        `${initialQuantity}`,
        selectedUnitOfMeasurement,
        baseUnit,
        true,
      );

      deliveredQuantity = +this.unitConversionPipe.transform(
        `${deliveredQuantity}`,
        selectedUnitOfMeasurement,
        baseUnit,
        true,
      );

      processInputQuantity = +this.unitConversionPipe.transform(
        `${processInputQuantity}`,
        selectedUnitOfMeasurement,
        baseUnit,
        true,
      );
    }

    const payload: IItemPayloadWithTags = {
      id: undefined,
      itemId: values[fieldEnum.ITEM_ID].trim(),
      product: `${uriPrefix}/products/${values[fieldEnum.PRODUCT].value}`,
      materials: values[fieldEnum.MATERIALS].map(
        (materialOption: ISelectOption) => `${uriPrefix}/materials/${materialOption.value}`,
      ),
      currentLocation: `${uriPrefix}/locations/${values[fieldEnum.CURRENT_LOCATION].value}`,
      createdAtLocation: `${uriPrefix}/locations/${values[fieldEnum.CREATED_AT_LOCATION].value}`,
      tags: values[fieldEnum.TAGS],
      initialQuantity,
      deliveredQuantity,
      selectedQuantity: processInputQuantity,
    };

    switch (values[fieldEnum.DATE_TYPE]) {
      case DateTypeEnum.EXACT:
        payload.created = {
          type: DateTypeEnum.EXACT,
          on: FormUtils.getDateValueForPayload(values[fieldEnum.CREATED_FROM]),
        };
        break;
      case DateTypeEnum.RANGE:
        payload.created = {
          type: DateTypeEnum.RANGE,
          start: FormUtils.getDateValueForPayload(values[fieldEnum.CREATED_RANGE][0]),
          end: FormUtils.getDateValueForPayload(
            values[fieldEnum.CREATED_RANGE][1],
            StartEndEnum.END,
          ),
        };
        break;
    }

    this.addCustomFieldsToPayload(
      values[fieldEnum.CUSTOM_FIELDS],
      payload,
      this.bulkAddItemsService,
    );

    return { recordPayload: payload, status: this.statusEnum.PENDING };
  }
}
