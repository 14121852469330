import { inject, Injectable } from "@angular/core";

import { CommonService } from "./api";
import { AuthenticationService } from "./authentication.service";
import { FeatureFlagService } from "./feature-flag.service";
import { IconService } from "./icon.service";
import { LocalizationService } from "./localization.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  private readonly commonService = inject(CommonService);

  private readonly iconService = inject(IconService);

  private readonly localizationService = inject(LocalizationService);

  private readonly authenticationService = inject(AuthenticationService);

  private readonly featureFlagService = inject(FeatureFlagService);

  public init = async (): Promise<void> => {
    return await this.loadData();
  };

  private loadData = async () => {
    this.iconService.registerIcons();
    await this.featureFlagService.init(
      this.authenticationService.getActiveOrganisationId(),
      this.authenticationService.getUserId(),
    );
    await this.localizationService.setInitialLanguage();
    this.localizationService.registerLocale();
    await this.commonService.loadCountries();
  };
}
