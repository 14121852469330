<h1 class="flex-column" title="">
  @if (isBackLinkEnabled) {
    <div (click)="onBack($event)" class="back-link clickable underline">
      <mat-icon class="back-icon icon-16">arrow_back</mat-icon>
      {{ getBackText() }}
    </div>
  }

  <div class="title-container">
    @if (icon) {
      <mat-icon class="title-icon">{{ icon }}</mat-icon>
    }
    <span class="title">{{ title }}</span>
    @if (hint) {
      <mat-icon class="info-icon" [tp]="hint" tpPlacement="right">help</mat-icon>
    }

    @if (tag) {
      <app-tag-group class="ml-12 no-padding" [tags]="[tag]"></app-tag-group>
    }
  </div>
</h1>
