import {
  AfterViewInit,
  ChangeDetectionStrategy,
  OnDestroy,
  Component,
  computed,
  input,
} from "@angular/core";

import { ColDef, IRowNode } from "ag-grid-community";
import { Subscription } from "rxjs";
import {
  EnumsCellRendererComponent,
  LinkCellRendererComponent,
  RulesetIconNameCellRendererComponent,
} from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IDelivery, IDeliveryExtended } from "src/app/shared/interfaces";
import {
  DeliveriesService,
  LocationsService,
  TableWithRulesetsService,
} from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TableWithRiskAssessmentReportsService } from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { RouterService } from "@shared/services/router.service";

import { TableBaseClass } from "../table/models";

@Component({
  standalone: false,
  selector: "app-deliveries-table",
  templateUrl: "./deliveries-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveriesTableComponent
  extends TableBaseClass<IDeliveryExtended>
  implements AfterViewInit, OnDestroy
{
  deliveries = input<IDelivery[]>(null);

  deliveriesIds = input<string[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.DELIVERIES);

  public isInboundShared = input<boolean>(false);

  public inboundSharedSenderOrgId = input<string>(null);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "deliveryId",
    "from.name",
    "to.name",
    "status",
    "sent",
    "delivered",
    "tags",
  ]);

  isBatchActionsEnabled = input(false);

  batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  private readonly shouldGetRulesets = computed(
    () => this.columns().includes("deliveryIdWithRulesets") && this.isRegularUser,
  );

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private deliveriesService: DeliveriesService,
    private locationsService: LocationsService,
    private overlay: SlideOverlayPageService,
    private tableWithRulesets: TableWithRulesetsService,
    private tableWithReports: TableWithRiskAssessmentReportsService,
  ) {
    super();

    this.tableWithRulesets.init(ResourceTypeEnum.DELIVERY);

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.DELIVERY);
    }

    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(async ({ entityType, element }) => {
        if (
          entityType === EntityTypeEnum.DELIVERIES ||
          entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS
        ) {
          await this.getAll();
          if (this.rowData().some((d) => d.id === element.id)) {
            this.tableWithRulesets.newRecord.set(element);
          }
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "deliveryId",
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.deliveriesService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.deliveriesService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.deliveriesService),
        ],
      ]),
    };
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onViewDetails = async (row: IDeliveryExtended): Promise<void> => {
    if (!this.areButtonsEnabled()) {
      return;
    }

    await this.routerService.navigate(this.routerService.getDeliveryLink(row.id));
  };

  override setColumnDefs(): void {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "deliveryId",
        headerName: $localize`ID`,
        field: "deliveryId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.routerService.getSharedDeliveryLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.routerService.getDeliveryLink,
        },
        suppressSizeToFit: true,
      },
      {
        colId: "deliveryIdWithRulesets",
        headerName: $localize`ID`,
        field: "deliveryId",
        lockVisible: true,
        cellClass: "container-flex-left",
        cellRenderer: RulesetIconNameCellRendererComponent,
        cellRendererParams: {
          rulesetRecordsSubject: this.tableWithRulesets.recordsSubject,
          iconTooltip: this.tableWithRulesets.getIconTooltip(),
          linkRouteIdParam: "id",
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.routerService.getSharedDeliveryLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.routerService.getDeliveryLink,
        },
        suppressSizeToFit: true,
      },
      {
        headerName: $localize`From`,
        field: "from.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "from.id",
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.routerService.getSharedLocationLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.routerService.getLocationLink,
        },
      },
      {
        headerName: $localize`To`,
        field: "to.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "to.id",
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.routerService.getSharedLocationLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.routerService.getLocationLink,
        },
      },
      {
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: EnumsCellRendererComponent,
      },
      ColumnUtils.dateColumn({
        headerName: $localize`Sent`,
        field: "sent",
      }),
      ColumnUtils.dateColumn({
        headerName: $localize`Delivered`,
        field: "delivered",
      }),
    ];

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags());
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.isRegularUser) {
      const lastRiskAssessed = this.tableWithReports.lastRiskAssessedColDef;
      const residualRiskLevel = this.tableWithReports.residualRiskLevelColDef;
      const linkRouteNameParam = "deliveryId";

      columnDefs.push(
        {
          ...lastRiskAssessed,
          cellRendererParams: {
            ...lastRiskAssessed.cellRendererParams,
            linkRouteNameParam,
          },
        },
        {
          ...residualRiskLevel,
          cellRendererParams: {
            ...residualRiskLevel.cellRendererParams,
            linkRouteNameParam,
          },
        },
      );
    }

    this.columnDefs.set(columnDefs);
  }

  private getParsedDeliveries = async (deliveries: any[]): Promise<IDeliveryExtended[]> => {
    return await Promise.all(
      deliveries.map(async (delivery) => {
        if (typeof delivery.from !== "string" && typeof delivery.to !== "string") {
          return delivery;
        }

        const fromId = CommonUtils.getUriId(delivery.from);
        const locationFrom = await this.locationsService.get(fromId);

        const toId = CommonUtils.getUriId(delivery.to);
        const locationTo = await this.locationsService.get(toId);

        return {
          ...delivery,
          from: locationFrom,
          to: locationTo,
        };
      }),
    );
  };

  override async getAll(): Promise<void> {
    this.isLoading.set(true);

    const deliveriesIds = this.deliveriesIds();
    const columnDefs = this.columnDefs();

    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }

    if (this.deliveries()) {
      const deliveries = await this.getParsedDeliveries(this.deliveries());

      this.rowData.set(deliveries);
    } else if (deliveriesIds) {
      try {
        const deliveries = await this.deliveriesService.getByIdsGraphQL(
          deliveriesIds,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(deliveries);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const deliveries = await this.deliveriesService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(deliveries);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  }

  onRowsVisibleUpdated(rows: IRowNode[]) {
    if (this.shouldGetRulesets()) {
      this.tableWithRulesets.onRowsVisibleUpdated(rows);
    }
  }

  onRowDataUpdated(rows: IRowNode[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement(), "deliveryId");
    }
  }
}
