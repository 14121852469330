import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import { IConfig } from "./../../interfaces";
import { ApiService } from "./api.service";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class EudrDdsService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private get getBaseUrl(): string {
    const organisationId = this.authenticationService.getActiveOrganisationId();

    return `${this.environment.baseUrl}organisations/${organisationId}/extensions/eudr/statements`;
  }

  public async get(statementId: string): Promise<EudrDds.IDetails> {
    return await lastValueFrom(
      this.apiService.get<EudrDds.IDetails>(`${this.getBaseUrl}/${statementId}`),
    );
  }

  public async getAll(
    deliveryUri?: string,
    internalReference?: string,
  ): Promise<EudrDds.IDetails[]> {
    const urlParams: any = { deliveryUri, internalReference };

    const url = `${this.getBaseUrl}?${FormUtils.addUrlParams(urlParams)}`;

    try {
      return await lastValueFrom(this.apiService.get<EudrDds.IDetails[]>(url));
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return [];
      } else {
        throw error;
      }
    }
  }

  public async createOrUpdate(payload: EudrDds.IPayload, id?: string): Promise<EudrDds.IDetails> {
    if (id) {
      return await lastValueFrom(
        this.apiService.put<EudrDds.IDetails>(`${this.getBaseUrl}/${id}`, payload),
      );
    } else {
      return await lastValueFrom(
        this.apiService.post<EudrDds.IDetails>(`${this.getBaseUrl}`, payload),
      );
    }
  }

  public async delete(id: string): Promise<void> {
    return await lastValueFrom(this.apiService.delete<void>(`${this.getBaseUrl}/${id}`));
  }

  public async submit(id: string): Promise<EudrDds.IDetails> {
    return await lastValueFrom(
      this.apiService.post<EudrDds.IDetails>(`${this.getBaseUrl}/${id}/submit`),
    );
  }

  public async updateStatus(id: string): Promise<EudrDds.IDetails> {
    return await lastValueFrom(
      this.apiService.post<EudrDds.IDetails>(`${this.getBaseUrl}/${id}/update-status`),
    );
  }
}
