import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

import { CommonConstants, TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { GeoJSONUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./add-gps-data-dialog.component.html",
  styleUrl: "./add-gps-data-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddGpsDataDialogComponent {
  public readonly commonConstants = CommonConstants;

  public shouldShowConversionPrompt = signal<boolean>(false);

  public temporaryGeojson = signal<any>(null);

  private readonly notificationService = inject(NotificationService);

  readonly uploadBoxFooterLeftText: string = `Upload a (${CommonConstants.GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS.map((e) => `.${e.toLowerCase()}`).join(" or ")}) file with geo data in geojson format and it will be displayed on a map`;

  readonly uploadBoxFooterRightText: string = `Maximum size: ${CommonConstants.MAX_GEO_JSON_SIZE_IN_MB}MB`;

  constructor(private dialogRef: MatDialogRef<AddGpsDataDialogComponent>) {}

  onClose(enterCoordinatesManually = false, file = null): void {
    this.dialogRef.close({ enterCoordinatesManually, geojsonFile: file });
  }

  onConfirm(): void {
    if (this.temporaryGeojson()) {
      const convertedGeojson = GeoJSONUtils.convertGeometryCollectionToFeatureCollection(
        this.temporaryGeojson(),
      );

      this.onClose(false, convertedGeojson);
    }
  }

  onCancel(): void {
    this.shouldShowConversionPrompt.set(false);
    this.temporaryGeojson.set(null);
  }

  public onFileSelected = async (file: File): Promise<void> => {
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = () => {
      try {
        const geojson = JSON.parse(reader.result.toString());

        // Check if it's a GeometryCollection
        if (
          geojson?.type === "FeatureCollection" &&
          geojson.features?.length &&
          geojson.features[0]?.geometry?.type === "GeometryCollection"
        ) {
          // Store the geojson and show confirmation dialog
          this.temporaryGeojson.set(geojson);
          this.shouldShowConversionPrompt.set(true);

          return;
        }

        if (!GeoJSONUtils.isValid(geojson)) {
          const geojsonError = GeoJSONUtils.getGeoJsonError(geojson);

          this.notificationService.showError(geojsonError);

          return;
        }

        this.onClose(false, geojson);
      } catch {
        this.notificationService.showError(TextConstants.FILE_NOT_SUPPORTED_ERROR);
      }
    };
  };
}
