import { ChangeDetectionStrategy, Component, Input, booleanAttribute } from "@angular/core";

import { IDeliveryExtended } from "src/app/shared/interfaces";

@Component({
  standalone: false,
  selector: "app-delivery-card-content",
  templateUrl: "./delivery-card-content.component.html",
  styleUrl: "./delivery-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryCardContentComponent {
  @Input() delivery: IDeliveryExtended;

  @Input({ transform: booleanAttribute }) shouldOpenInNewTab: boolean = false;

  @Input({ transform: booleanAttribute }) showWarning: boolean;

  public readonly translations: any = {
    tranderTp: $localize`Trader`,
  };
}
