<div class="container-flex-space-between">
  <div class="left-content">
    <div class="type" [tp]="document.type" tpPlacement="top-start">{{ document.type }}</div>
    <div
      [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
      class="name"
      [tp]="document.name"
      tpPlacement="top-start"
    >
      {{ document.name }}
    </div>
    @if (document.tags?.length) {
      <div class="tags">
        @for (tag of document.tags; track tag.title) {
          <div
            class="tag-chip {{ tag.color }}"
            [tp]="tag.title.length > MAX_TAG_CHARS ? tag.title : ''"
            tpPlacement="top-start"
            [tpIsLazy]="true"
          >
            {{ truncateTag(tag.title) }}
          </div>
        }
      </div>
    }
  </div>
  <app-button-group>
    @if (displayTooltipIcon) {
      <mat-icon
        class="gray black-hover"
        [tp]="tooltipContent"
        tpVariation="whiteTooltipNoArrow"
        tpClassName="tooltip-document-dates"
        tpPlacement="bottom-end"
      >
        info
      </mat-icon>
    }
    @if (type === cardContentTypeEnum.ADD) {
      <app-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </app-button>
    } @else {
      <app-button
        (pressed)="onClickRemove()"
        [tp]="translations.removeTp"
        size="medium"
        type="icon"
      >
        <mat-icon icon-medium-18>close</mat-icon>
      </app-button>
    }
  </app-button-group>
</div>

<ng-template #tooltipContent>
  @if (document.issuance) {
    <p>
      <ng-container i18n>Issued on</ng-container>
      :

      <span class="date">{{ document.issuance | appDate: "yyyy.MM.dd" }}</span>
    </p>
  }

  @if (document.validityStart) {
    <p>
      <ng-container i18n>Validity start</ng-container>
      :

      <span class="date">{{ document.validityStart | appDate: "yyyy.MM.dd" }}</span>
    </p>
  }

  @if (document.validityEnd) {
    <p>
      <ng-container i18n>Validity end</ng-container>
      :

      <span class="date">{{ document.validityEnd | appDate: "yyyy.MM.dd" }}</span>
    </p>
  }
</ng-template>
