import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";

import { RiskAssessmentReportIndicatorMitigationStatus } from "@components/shared/risk-assessment-reports/constants";
import { RiskAssessmentReportIndicatorMitigation } from "@components/shared/risk-assessment-reports/models";
import { TextConstants } from "@shared/constants";

import { RiskMitigation } from "../models";

@Component({
  standalone: false,
  selector: "app-risk-mitigation-card",
  templateUrl: "./risk-mitigation-card.component.html",
  styleUrl: "./risk-mitigation-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMitigationCardComponent {
  readonly riskMitigation = input<RiskMitigation | RiskAssessmentReportIndicatorMitigation>();

  readonly editMode = input(false);

  readonly statusChange = output<RiskAssessmentReportIndicatorMitigationStatus | null>();

  readonly remove = output();

  readonly mitigationStatus = RiskAssessmentReportIndicatorMitigationStatus;

  get isIndicatorMitigation() {
    return "status" in this.riskMitigation();
  }

  get status() {
    if (this.isIndicatorMitigation) {
      return (this.riskMitigation() as RiskAssessmentReportIndicatorMitigation).status;
    }

    return undefined;
  }

  public readonly translations: any = {
    removeTp: TextConstants.REMOVE,
    resetTp: $localize`Reset status`,
    markAsNotApplicableTp: $localize`Mark as not applicable`,
    markAsCompletedTp: $localize`Mark as completed`,
    completedTp: $localize`Completed`,
    notApplicableTp: $localize`Not applicable`,
  };
}
