<app-form-label [label]="translations.fixed"></app-form-label>
<div [formGroup]="formGroup" class="row-container">
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_TAGS"></app-checkbox>
    </div>
    <div class="item">
      <app-form-label [label]="translations.tags"></app-form-label>
      <app-entity-tags
        [initialTags]="formGroup.controls[fieldEnum.TAGS].value"
        [entityType]="entityTypeEnum.LOCATIONS"
        (tagsChanged)="onTagsChanged($event)"
      ></app-entity-tags>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_ORGANISATION"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [formControlName]="fieldEnum.ORGANISATION"
        [options]="bulkAddLocationsService.organisationOptions()"
        [label]="translations.organisationLabel"
        [placeholder]="translations.organisationPh"
      >
        <ng-container action>
          <app-button
            (pressed)="onAddOrganisationClicked()"
            class="icon-add-circle"
            [tp]="translations.organisationTp"
            type="icon"
          >
            <mat-icon icon-small>add</mat-icon>
          </app-button>
        </ng-container>
      </app-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_LOCATION_TYPES"></app-checkbox>
    </div>
    <div class="item">
      <app-input-chips
        #locationTypesInputChips
        [allowCreateTag]="true"
        [tagUrl]="locationTypesService.getBaseUrl"
        [options]="bulkAddLocationsService.locationTypeOptions()"
        [formControlName]="fieldEnum.LOCATION_TYPES"
        [label]="translations.locationTypesLabel"
        [placeholder]="translations.locationTypesPh"
        [tooltip]="translations.locationTypesTooltip"
      ></app-input-chips>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_COUNTRY"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [formControlName]="fieldEnum.COUNTRY"
        [options]="bulkAddLocationsService.countryOptions()"
        [label]="translations.countryLabel"
        [placeholder]="translations.countryPh"
        [prefixTemplate]="flag"
      ></app-input-select>
      <ng-template #flag let-option>
        <app-flag [country]="option.value"></app-flag>
      </ng-template>
    </div>
  </div>
  <ng-container [formArrayName]="fieldEnum.CUSTOM_FIELDS">
    @for (
      control of bulkAddLocationsService.customFieldsFormArray.controls;
      let i = $index;
      track control
    ) {
      <div class="row fields-row" [formGroupName]="i">
        <div class="item">
          <app-checkbox [formControlName]="customFieldEnum.IS_FIXED_CUSTOM_FIELD"></app-checkbox>
        </div>
        <div class="item">
          <app-input
            [label]="control.get(customFieldEnum.CUSTOM_FIELD_LABEL).value"
            [formControlName]="customFieldEnum.CUSTOM_FIELD_VALUE"
          ></app-input>
        </div>
      </div>
    }
  </ng-container>
</div>
