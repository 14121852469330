import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ExtensionProviderEnum, FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import {
  AuthenticationService,
  ExtensionsService,
  FeatureFlagService,
} from "src/app/shared/services";

import { TextConstants } from "@shared/constants";
import { ISetting } from "@shared/interfaces";

@Component({
  standalone: false,
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsComponent implements OnInit {
  public readonly isSystemAdmin = this.authenticationService.isSystemAdminOrganisation();

  private readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  private readonly isEudrReportEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.EUDR_REPORT,
  );

  public operationsGeneralSettings: ISetting[] = [];

  public operationsUserSettings: ISetting[] = [];

  public operationsActiveExtensionsSettings: ISetting[] = [];

  public operationsDataSharingSettings: ISetting[] = [];

  public operationsRiskAssessmentSettings: ISetting[] = [];

  public readonly translations: any = {
    title: $localize`Admin`,
  };

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    public featureFlagService: FeatureFlagService,
    private extensionsService: ExtensionsService,
    private cd: ChangeDetectorRef,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.setupSettings();
  }

  private setupSettings = async (): Promise<void> => {
    const isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();

    if (this.isSystemAdmin) {
      this.operationsGeneralSettings.push({
        route: RouteEnum.ADMIN_STANDARDS,
        icon: "security",
        title: $localize`Standards`,
        description: $localize`Manage system standards`,
      });
    } else {
      this.operationsGeneralSettings.push(
        ...[
          {
            route: RouteEnum.PRODUCTS,
            icon: "deployed_code",
            title: $localize`Products`,
            description: $localize`Define products of your org.`,
          },
          {
            route: RouteEnum.MATERIALS,
            icon: "category",
            title: TextConstants.MATERIALS,
            description: $localize`Define materials of your org.`,
          },
          {
            route: RouteEnum.ADMIN_DOCUMENT_TYPES,
            icon: "file_copy",
            title: $localize`Document types`,
            description: $localize`Define document types of your org.`,
          },
          {
            route: RouteEnum.ADMIN_LOCATION_TYPES,
            icon: "distance",
            title: $localize`Location types`,
            description: $localize`Define location types of your org.`,
          },
          {
            route: RouteEnum.ADMIN_PROCESS_TYPES,
            icon: "shuffle",
            title: $localize`Process types`,
            description: $localize`Define process types of your org.`,
          },
          {
            route: RouteEnum.ADMIN_RULESETS,
            icon: "flag",
            title: $localize`Rulesets`,
            description: $localize`Define rulesets for your operations.`,
          },
        ],
      );

      if (isAccountOwnerOrAdmin) {
        this.operationsUserSettings.push({
          route: RouteEnum.ADMIN_MEMBERSHIPS,
          icon: "group",
          title: $localize`Users`,
          description: $localize`Manage users for your org.`,
        });
        this.operationsGeneralSettings.push({
          route: RouteEnum.ADMIN_CUSTOM_FIELDS,
          icon: "format_align_left",
          title: $localize`Custom fields`,
          description: $localize`Define custom fields of your org.`,
        });
      }

      this.operationsGeneralSettings.push({
        route: RouteEnum.ADMIN_TAGS,
        icon: "sell",
        title: TextConstants.TAGS,
        description: $localize`Define tags of your org.`,
      });

      if (this.isCrossOrgSharingEnabled) {
        if (isAccountOwnerOrAdmin) {
          this.operationsDataSharingSettings.push({
            route: RouteEnum.ADMIN_CONNECTIONS,
            icon: "link",
            title: $localize`Connections`,
            description: $localize`See existing connections & add new`,
          });
        }

        this.operationsDataSharingSettings.push({
          route: RouteEnum.ADMIN_SENT_INFORMATION,
          icon: "send",
          title: $localize`Sent information`,
          description: $localize`See which infromation was sent from your org.`,
        });
      }

      this.operationsRiskAssessmentSettings = [
        ...this.operationsRiskAssessmentSettings,
        {
          route: RouteEnum.ADMIN_RISK_ASSESSMENT_TEMPLATES,
          icon: "book_2",
          title: $localize`Risk assessment templates`,
          description: $localize`Define templates for risk assessment.`,
        },
        {
          route: RouteEnum.ADMIN_RISK_LEVEL_SETS,
          icon: "list",
          title: $localize`Risk level sets`,
          description: $localize`Configure risk levels for risk assessments.`,
        },
        {
          route: RouteEnum.ADMIN_RISK_MITIGATIONS,
          icon: "verified_user",
          title: $localize`Risk mitigations`,
          description: $localize`Specify risk mitigation measures.`,
        },
      ];

      if (isAccountOwnerOrAdmin) {
        this.operationsGeneralSettings.push(
          {
            route: RouteEnum.ADMIN_UNITS_OF_MEASUREMENT,
            svgIcon: "tape",
            title: TextConstants.UNITS_OF_MEASUREMENT,
            description: $localize`Define units of measurement of your org.`,
          },
          {
            route: RouteEnum.ADMIN_COUNTRIES,
            icon: "language",
            title: $localize`Countries`,
            description: $localize`Countries your org. deals with.`,
          },
        );
      }

      this.operationsGeneralSettings.push({
        route: RouteEnum.ADMIN_EXTENSIONS,
        icon: "extension",
        title: $localize`Extensions`,
        description: $localize`Manage your organisation's extensions`,
      });

      // we don't await this call not to block the page loading.
      this.extensionsService
        .getAllEnabled(this.authenticationService.getActiveOrganisationId())
        .then((enabledExtensions: string[]) => {
          if (enabledExtensions.length) {
            if (enabledExtensions.includes(ExtensionProviderEnum.ORBIFY)) {
              this.operationsActiveExtensionsSettings.push({
                route: RouteEnum.ADMIN_EXTENSIONS_ORBIFY,
                svgIcon: "orbify",
                title: "Orbify",
                description: $localize`See all analyses generated via Orbify`,
              });
            }

            if (
              this.isEudrReportEnabled &&
              enabledExtensions.includes(ExtensionProviderEnum.EUDR)
            ) {
              this.operationsActiveExtensionsSettings.push({
                route: RouteEnum.ADMIN_EXTENSIONS_EUDR,
                icon: "summarize",
                title: "EUDR",
                description: $localize`Manage EUDR connection & statements`,
              });
            }

            this.cd.markForCheck();
          }
        });
    }
  };

  public onGoToRoute = async (route: RouteEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
