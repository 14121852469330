import { DatePipe } from "@angular/common";
import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

import { CommonConstants } from "@shared/constants";
import { LocalizationService } from "@shared/services";

@Pipe({
  standalone: false,
  name: "appDate",
})
export class AppDatePipe extends DatePipe implements PipeTransform {
  constructor(
    @Inject(LOCALE_ID) locale: string,
    private localizationService: LocalizationService,
  ) {
    super(locale);
  }

  override transform(
    value: Date | string | number,
    format: string = CommonConstants.DATE_FORMAT,
    timezone: string = "UTC",
    locale: string = this.localizationService.getLanguage(),
  ): any {
    return super.transform(value, format, timezone, locale);
  }
}
