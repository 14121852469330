import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

import { AgGridAngular } from "ag-grid-angular";
import {
  CellValueChangedEvent,
  ColDef,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ProcessCellForExportParams,
  ProcessDataFromClipboardParams,
  RowDragEndEvent,
  RowDragEnterEvent,
} from "ag-grid-community";

import { TableUtils } from "@components/index";
import { TableTooltip } from "@components/shared/tables/table/table-tooltip";
import { TextConstants } from "@shared/constants";
import { ColumnUtils, GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-coordinates-form",
  templateUrl: "./coordinates-form.component.html",
  styleUrl: "./coordinates-form.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoordinatesFormComponent implements OnInit {
  @Input() formGroup!: FormGroup;

  hasMultipleAreas = input<boolean>(false);

  @ViewChild("grid") public grid: AgGridAngular;

  private api!: GridApi;

  @Output() coordinatesChanged = new EventEmitter<any>();

  public rowData = signal<any[]>([]);

  public columnDefs = [];

  private previousIndex: number | null = null;

  public pinnedBottomRowData: any[] = [
    {
      isFooter: true,
      cellClass: "clickable",
    },
  ];

  private readonly pinnedRowHtml = `
  <div class="clickable">
    <span class="pinned-row-btn">
      <span>+</span>
      Add new row
    </span>
  </div>`;

  private readonly coordinateColumnTooltip = $localize`Type to add the coordinates`;

  constructor(private fb: FormBuilder) {}

  public gridOptions: GridOptions = {
    suppressRowTransform: true,
    defaultColDef: {
      width: 137,
      maxWidth: 137,
      sortable: false,
      suppressMovable: true,
      rowDrag: false,
      editable: false,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      cellRenderer: this.renderFooterButton.bind(this),
      lockPinned: true,
      tooltipComponent: TableTooltip,
    },
    suppressHorizontalScroll: true,
    suppressContextMenu: true,
    columnDefs: this.getColumnDefs(),
    domLayout: "autoHeight",
    rowData: this.rowData(),
    singleClickEdit: false,
    enableRangeSelection: false,
    enableCellTextSelection: true,
    copyHeadersToClipboard: true,
    rowSelection: "multiple",
    tooltipShowDelay: 100,
    tooltipHideDelay: 3000,
    tooltipTrigger: "focus",
    enableFillHandle: true,
    suppressClipboardPaste: false,
    processCellFromClipboard: this.processCellFromClipboard.bind(this),
    processDataFromClipboard: this.processDataFromClipboard.bind(this),
    pinnedBottomRowData: this.pinnedBottomRowData,
    rowDragManaged: true,
    stopEditingWhenCellsLoseFocus: true,
  };

  ngOnInit(): void {
    this.columnDefs = this.getColumnDefs();
    const rowData = this.formGroup.value.rows.map((row: any, index: number) => ({
      ...row,
      index: index,
    }));

    this.rowData.set(rowData);
  }

  onGridReady(params: GridReadyEvent): void {
    this.api = params.api;
    if (!this.grid) {
      return;
    }

    this.grid.api.autoSizeAllColumns();
    this.setGridDomLayout();
  }

  private setGridDomLayout() {
    const maxRowLenght = this.hasMultipleAreas() ? 10 : 11;

    if (this.rowData().length > maxRowLenght) {
      this.grid.api.setGridOption("domLayout", "normal");
    }
  }

  getColumnDefs(): ColDef[] {
    return [
      {
        ...ColumnUtils.iconColumnCommonValues,
        valueGetter: (params) => (params.node ? params.node.rowIndex + 1 : null),
        width: 70,
        maxWidth: 70,
        rowDrag: (params) => !params.data.isFooter,
        colSpan: (params) => (params.data.isFooter ? 4 : 1),
        cellClass: (params) => `cell-index${params.data.isFooter ? " clickable no-border" : ""}`,
        onCellClicked: (params) => {
          if (params.data.isFooter) {
            this.addRow();
          }
        },
      },
      {
        field: "longitude",
        headerName: $localize`Long (X)`,
        tooltipValueGetter: () => this.coordinateColumnTooltip,
        editable: (params) => {
          return !params.data.isFooter;
        },
        onCellValueChanged: this.onCellValueChanged.bind(this),
        cellClass: (params) => {
          return params.data.isFooter ? "" : "coordinates-row";
        },
      },
      {
        field: "latitude",
        headerName: $localize`Lat (Y)`,
        tooltipValueGetter: () => this.coordinateColumnTooltip,
        editable: (params) => {
          return !params.data.isFooter;
        },
        onCellValueChanged: this.onCellValueChanged.bind(this),
        cellClass: (params) => {
          return params.data.isFooter ? "" : "coordinates-row";
        },
      },
      {
        ...ColumnUtils.buttons([
          {
            onClick: this.onDelete,
            icon: "delete",
            tooltip: () =>
              this.rowDataLength > 1
                ? TextConstants.REMOVE
                : $localize`You need at least one pair of coordinates`,
            buttonClass: () => (this.rowDataLength > 1 ? "" : "disabled"),
            isVisible: (params) => {
              return !params?.isFooter;
            },
          },
        ]),
        width: 65,
        maxWidth: 65,
        cellClass: (params) => {
          return params.data.isFooter ? "no-border" : "no-border cell-index";
        },
      },
    ];
  }

  renderFooterButton(params: any): string {
    if (params.data && params.data.isFooter) {
      if (params.column.getColId() === "0") {
        return this.pinnedRowHtml;
      }

      return "";
    }

    return params.value;
  }

  onDelete = (rowData: { latitude: number; longitude: number; index: number }): void => {
    const rowsArray = this.formGroup.get("rows") as FormArray;

    if (rowsArray.length > 1) {
      rowsArray.removeAt(rowData.index);
      const rows = rowsArray.value.map((row: any, index) => ({
        ...row,
        index: index,
      }));

      this.rowData.set(rows);
      this.coordinatesChanged.emit(this.formGroup.value);
      this.setGridDomLayout();
    }
  };

  onCellValueChanged(event: CellValueChangedEvent): void {
    const newValue = event.newValue;
    const rowIndex = event.node.rowIndex;
    const field = event.colDef.field;
    const cellElement = TableUtils.getCellHtmlElement(rowIndex, field);

    const rowsArray = this.formGroup.get("rows") as FormArray;

    if (field === "longitude") {
      rowsArray.at(rowIndex).patchValue({ longitude: newValue });
      const isValid = GeoJSONUtils.isValidLongitude(newValue);

      TableUtils.updateCellValidationState(cellElement, isValid);
    } else if (field === "latitude") {
      rowsArray.at(rowIndex).patchValue({ latitude: newValue });
      const isValid = GeoJSONUtils.isValidLatitude(newValue);

      TableUtils.updateCellValidationState(cellElement, isValid);
    }
    this.coordinatesChanged.emit(this.formGroup.value);
  }

  processDataFromClipboard(params: ProcessDataFromClipboardParams) {
    const rowsArray = this.formGroup.get("rows") as FormArray;

    const emptyLastRow =
      params.data[params.data.length - 1][0] === "" &&
      params.data[params.data.length - 1].length === 1;

    if (emptyLastRow) {
      params.data.splice(params.data.length - 1, 1);
    }

    const lastIndex = this.api.getModel().getRowCount() - 1;
    const focusedCell = this.api.getFocusedCell();
    const focusedIndex = focusedCell.rowIndex;

    params.data.forEach((rowData, rowIndex) => {
      const targetRowIndex = focusedIndex + rowIndex;

      if (targetRowIndex < rowsArray.length) {
        const rowFormGroup = rowsArray.at(targetRowIndex) as FormGroup;
        let currColumn = focusedCell.column;

        rowData.forEach((cellValue) => {
          const colId = currColumn.getColId();
          const value = cellValue != null ? +cellValue : null;

          rowFormGroup.patchValue({ [colId]: value });
          currColumn = this.api.getDisplayedColAfter(currColumn);
        });
      }
    });

    if (focusedIndex + params.data.length - 1 > lastIndex) {
      const resultLastIndex = focusedIndex + (params.data.length - 1);
      const addRowCount = resultLastIndex - lastIndex;
      let rowsToAdd = [];
      let addedRows = 0;
      let currIndex = params.data.length - 1;

      while (addedRows < addRowCount) {
        rowsToAdd.push(params.data.splice(currIndex, 1)[0]);
        addedRows++;
        currIndex--;
      }

      rowsToAdd = rowsToAdd.reverse();
      const newRowData = [];

      rowsToAdd.forEach((r) => {
        const row = {};
        let currColumn = focusedCell.column;

        r.forEach((i) => {
          const colId = currColumn.getColId();

          row[colId] = i || "";
          currColumn = this.api.getDisplayedColAfter(currColumn);
        });

        newRowData.push(row);
      });

      newRowData.forEach((row) => {
        const newRow = this.createCoordinateGroup(+row.latitude, +row.longitude);

        rowsArray.push(newRow);
      });

      this.updateRowData();
      this.setGridDomLayout();
    }

    return params.data;
  }

  processCellFromClipboard(params: ProcessCellForExportParams) {
    const newValue = params.value;
    const rowIndex = params.node.rowIndex;

    const rowsArray = this.formGroup.get("rows") as FormArray;

    if (params.column.getColId() === "longitude") {
      if (!isNaN(+newValue) && GeoJSONUtils.isValidLongitude(newValue)) {
        rowsArray.at(rowIndex).patchValue({ longitude: +newValue });
      }
    } else if (params.column.getColId() === "latitude") {
      if (!isNaN(+newValue) && GeoJSONUtils.isValidLongitude(newValue)) {
        rowsArray.at(rowIndex).patchValue({ latitude: newValue });
      }
    }

    this.coordinatesChanged.emit(this.formGroup.value);

    return +newValue;
  }

  addRow() {
    const rowsArray = this.formGroup.get("rows") as FormArray;

    const newRow = this.createCoordinateGroup();

    const closingRowIndex = rowsArray.controls.findIndex(
      (row) => row.get("rowAddedToClosePolygon")?.value === true,
    );

    if (closingRowIndex !== -1) {
      rowsArray.removeAt(closingRowIndex);
    }

    rowsArray.push(newRow);

    this.updateRowData();
    this.coordinatesChanged.emit(this.formGroup.value);

    setTimeout(() => {
      this.setGridDomLayout();

      const rowCount = this.api.getDisplayedRowCount();

      this.api.ensureIndexVisible(rowCount - 1, "bottom");
    });
  }

  public createCoordinateGroup(
    latitude: number | null = null,
    longitude: number | null = null,
  ): FormGroup {
    return this.fb.group({
      longitude: [longitude, [CustomValidators.longitude, CustomValidators.required]],
      latitude: [latitude, [CustomValidators.latitude, CustomValidators.required]],
    });
  }

  swapColumns() {
    this.api.moveColumnByIndex(1, 2);
  }

  onRowDragEnd(event: RowDragEndEvent) {
    const currentIndex = event.overIndex;

    if (
      this.previousIndex === null ||
      this.previousIndex === currentIndex ||
      currentIndex == null
    ) {
      return;
    }

    const formArray = this.formGroup.get("rows") as FormArray;
    const movedItem = formArray.at(this.previousIndex);

    formArray.removeAt(this.previousIndex);
    formArray.insert(currentIndex, movedItem);

    this.updateRowData();

    this.coordinatesChanged.emit(this.formGroup.value);
    this.previousIndex = null;
  }

  onRowDragEnter(event: RowDragEnterEvent): void {
    this.previousIndex = event.node.rowIndex;
  }

  updateRowData(): void {
    const rowsArray = this.formGroup.get("rows") as FormArray;
    const data = rowsArray.value.map((row: any, index: number) => ({
      ...row,
      index: index,
    }));

    this.rowData.set(data);
    this.grid.rowData = this.rowData();
  }

  private get rowDataLength(): number {
    return this.rowData().length;
  }
}
