<app-slide-overlay-header
  [isInboundShare]="true"
  [title]="title()"
  icon="category"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="name"
              [label]="translations.nameLabel"
            ></app-input>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <app-input-select
              enableViewMode
              [options]="categoriesOptions()"
              [addOnWrite]="true"
              formControlName="category"
              [hint]="translations.categoryHint"
              [label]="translations.categoryLabel"
            ></app-input-select>
          </div>
        </div>
      </div>
    </app-form-field-group>
  </ng-template>
</app-slide-overlay-content>
