import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  input,
  Output,
} from "@angular/core";

import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { ITag, ITagExtended } from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-slide-overlay-header",
  templateUrl: "./slide-overlay-header.component.html",
  styleUrls: ["./slide-overlay-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayHeaderComponent {
  icon = input.required<string>();

  isSvgIcon = input(false);

  iconClass = input<string>("");

  title = input.required<string>();

  canDuplicate = input<boolean>(false);

  canDelete = input<boolean>(false);

  canShare = input<boolean>(false);

  canSend = input<boolean>(false);

  canArchive = input<boolean>(false);

  enableTags = input<boolean>(false);

  isInboundShare = input<boolean>(false);

  initialTags = input<ITag[]>([]);

  @Output() share = new EventEmitter<void>();

  @Output() send = new EventEmitter<void>();

  public readonly isRegularUser = this.authService.isRegularUser();

  private readonly isSystemAdmin = this.authService.isSystemAdminOrganisation();

  private readonly canModifyEntities = this.authService.canAddModifyEntities();

  public readonly translations: any = {
    duplicateTp: $localize`This will only duplicate the item details and will allow the user to edit them
                    before saving the new item.`,
  };

  constructor(
    protected overlay: SlideOverlayPageService,
    private authService: AuthenticationService,
  ) {}

  public shouldShowMoreActionsButton = computed(() => {
    return (
      !this.overlay.loading() &&
      this.isRegularUser &&
      !this.isSystemAdmin &&
      this.canModifyEntities &&
      !this.isInboundShare() &&
      this.doesDropdownMenuHaveItems()
    );
  });

  public doesDropdownMenuHaveItems = computed(() => {
    return (
      this.canDuplicate() ||
      this.canDelete() ||
      this.canShare() ||
      this.canSend() ||
      this.canArchive()
    );
  });

  get instance(): SlideOverlayPageClass {
    return this.overlay?.componentRef()?.instance;
  }

  get isRecordActive(): boolean {
    return this.instance?.isRecordArchived;
  }

  async toggleArchive(): Promise<void> {
    if (this.isRecordActive) {
      await this.instance.confirmUnarchiveDialog();
    } else {
      await this.instance.confirmArchiveDialog();
    }
  }

  async deleteHandler(): Promise<void> {
    await this.instance.confirmDeleteDialog();
  }

  async duplicateHandler(): Promise<void> {
    await this.instance.confirmDuplicateDialog();
  }

  shareHandler(): void {
    this.share.emit();
  }

  sendHandler(): void {
    this.send.emit();
  }

  onTagsChanged(selectedTags: ITagExtended[]): void {
    if (!this.instance) {
      return;
    }

    if (this.instance.recordId) {
      this.instance.updateTable();
    } else {
      this.instance.selectedTagsForNewRecord = selectedTags;
    }
  }
}
