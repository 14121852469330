<div class="form-page-container">
  <div class="header-container">
    <app-page-title
      [title]="isEditing() ? translations.titleEdit : translations.titleAdd"
    ></app-page-title>
  </div>
  <div class="element-content hidden-overflow-y">
    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <div cdkDropListGroup class="right-side-panel-container">
        <div [formGroup]="formGroup" class="content">
          <mat-accordion>
            <mat-expansion-panel
              class="mat-elevation-z0 section-box no-padding-body"
              [expanded]="true"
            >
              <mat-expansion-panel-header class="section-header">
                <mat-panel-title class="section-title">{{ mainInformationText }}</mat-panel-title>
              </mat-expansion-panel-header>
              <div class="row-container">
                <div class="row row-1-2">
                  <div class="item">
                    <app-input
                      [autofocus]="!isEditing()"
                      formControlName="name"
                      [label]="translations.nameLabel"
                      [tooltip]="translations.nameTooltip"
                    ></app-input>
                  </div>
                  <div class="item">
                    <app-input
                      formControlName="description"
                      [label]="translations.descriptionLabel"
                    ></app-input>
                  </div>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>

          <mat-accordion>
            <mat-expansion-panel
              [class.scroll-body]="!isZoomEnabled"
              [expanded]="true"
              class="mat-elevation-z0 section-box no-padding-body"
            >
              <mat-expansion-panel-header class="section-header">
                <mat-panel-title class="section-title">
                  <ng-container i18n>Locations</ng-container>
                  <mat-icon class="info-icon" [tp]="translations.clickAndDragTp">help</mat-icon>
                </mat-panel-title>
              </mat-expansion-panel-header>
              @if (isLoadingSelectedLocations()) {
                <app-loader containerClass="on-small-supply-chain"></app-loader>
              } @else {
                <div
                  (cdkDropListDropped)="onItemDropped($event)"
                  [cdkDropListSortingDisabled]="true"
                  cdkDropList
                  class="flow-chart-container"
                >
                  <div
                    [class.opaque]="!selectedLocations()?.length"
                    class="flow-chart-drop-layover"
                    i18n
                  >
                    Drop locations here
                  </div>
                  @if (selectedLocations().length) {
                    @if (!isReloadingSupplyChainFlowChart()) {
                      <app-supply-chain-flow-chart
                        (remove)="onRemove($event)"
                        [canRemove]="true"
                        [height]="300"
                        [isZoomEnabled]="isZoomEnabled"
                        [links]="locationLinks"
                        [locations]="selectedLocations()"
                      ></app-supply-chain-flow-chart>
                    }
                  } @else {
                    <app-drag-drop-container
                      (itemDropped)="onItemDropped($event)"
                      class="big"
                      itemType="locations"
                      [placeHolder]="translations.dragAndDropPh"
                    ></app-drag-drop-container>
                  }
                </div>
              }
            </mat-expansion-panel>
          </mat-accordion>

          @if (!isLoadingSelectedLocations()) {
            <br />
            <app-supply-chain-locations
              [locations]="selectedLocations()"
              [allStandards]="allStandards"
              [allDocumentTypes]="allDocumentTypes"
              [isShowDocuments]="true"
              [isShowLinks]="true"
              [expandArrowPosition]="'before'"
              [isShowTableSelectCheckbox]="true"
              [canRemove]="true"
              (remove)="onRemove($event)"
              (selectedCertificatesChanged)="onSelectedLocationsChanged($event)"
              (selectedDocumentsChanged)="onSelectedLocationsChanged($event)"
              [isShowCertificates]="true"
            ></app-supply-chain-locations>
          }
        </div>

        <app-right-side-panel
          itemType="Location"
          itemTypePlural="Locations"
          [isLoadingItems]="isLoadingAvailableLocations()"
          [availableItems]="availableLocations"
          (pressAdd)="onAddNewLocation()"
          (pressAddAll)="onAddAll()"
          (pressRefresh)="onReloadLocations()"
          (searchEvent)="onSearchAvailable($event)"
        >
          <div
            class="items-container"
            cdkDropList
            [cdkDropListSortingDisabled]="true"
            [cdkDropListData]="availableLocations"
          >
            @for (item of availableLocations; track $index) {
              <app-draggable-card (pressed)="onAdd(item.id)" [item]="item">
                <app-location-card-content [location]="item" [type]="cardContentTypeEnum.ADD" />
              </app-draggable-card>
            }
          </div>
        </app-right-side-panel>
      </div>
    }
  </div>
  <app-entity-save-footer
    (save)="onSave()"
    [overrideCancel]="true"
    (cancelEvent)="onCancel()"
    [isDisabled]="isSubmitButtonDisabled()"
    [showSubmitButton]="!isLoading()"
  ></app-entity-save-footer>
</div>
