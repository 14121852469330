import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatDialog } from "@angular/material/dialog";

import { ColDef, IRowNode } from "ag-grid-community";
import { filter, Subscription, tap } from "rxjs";

import { ConfirmDialogComponent } from "@components/shared";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import {
  LatestRiskAssessmentReportRowData,
  TableWithRiskAssessmentReportsService,
} from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TableBaseClass } from "@components/shared/tables/table/models";
import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { BatchActionTypeEnum, EntityTypeEnum, TableEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { CommonService } from "@shared/services";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { CountriesApiService } from "../api";
import { Country, CountryFieldsToInclude } from "../models";

type CountryRow = Country & {
  address: { country: string; countryName: string };
} & LatestRiskAssessmentReportRowData;

@Component({
  standalone: false,
  selector: "app-countries-table",
  templateUrl: "./countries-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountriesTableComponent extends TableBaseClass<CountryRow> implements AfterViewInit {
  private readonly commonService = inject(CommonService);

  private readonly dialog = inject(MatDialog);

  private readonly api = inject(CountriesApiService);

  private readonly overlay = inject(SlideOverlayPageService);

  private readonly tableWithReports = inject(TableWithRiskAssessmentReportsService);

  readonly areButtonsEnabled = input(true);

  readonly isFixedBottomPaginator = input(false);

  readonly isPaginatorEnabled = input(true);

  readonly isSaveTableState = input(false);

  readonly isSearchEnabled = input(true);

  readonly isBatchActionsEnabled = input(false);

  readonly edit = output<Country>();

  readonly batchActionSettings = signal<BatchActionModel.IBatchActionSettings>(undefined);

  readonly table = TableEnum.COUNTRY;

  @ViewChild("countryFlag") countryFlag!: TemplateRef<{ $implicit: string }>;

  private countryOptions = signal<ISelectOption[]>([]);

  private subscriptions = new Subscription();

  readonly refreshTable$ = this.overlay.refreshTable$.pipe(
    filter(
      ({ entityType }) =>
        entityType === EntityTypeEnum.COUNTRIES ||
        entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS,
    ),
    tap(() => this.getAll()),
  );

  constructor() {
    super();

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.COUNTRY);
    }

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.countryOptions.set(countriesOptions),
        ),
    );
  }

  async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  override async getAll() {
    this.isLoading.set(true);

    try {
      const fieldsToInclude: CountryFieldsToInclude[] = [];

      if (this.isRegularUser) {
        fieldsToInclude.push("TAGS");
      }

      const countries = await this.api.getAllGraphQL(undefined, undefined, fieldsToInclude);

      this.rowData.set(await this.getParsedRowData(countries));
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  override setColumnDefs() {
    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.COUNTRY,
        field: "address.countryName",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params) => {
          let templateWithContext: { template: TemplateRef<any>; context: any } = null;

          templateWithContext = {
            template: this.countryFlag,
            context: {
              $implicit: params?.data.code,
            },
          };

          return {
            templateWithContext,
            actions: this.areButtonsEnabled()
              ? [
                  {
                    click: (row) => this.onDelete(row),
                    tooltip: TextConstants.DELETE,
                    icon: "delete",
                  },
                ]
              : [],
          };
        },
      },
    ];

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags());
    }

    if (this.batchActionSettings()) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.isRegularUser) {
      columnDefs.push(
        this.tableWithReports.lastRiskAssessedColDef,
        this.tableWithReports.residualRiskLevelColDef,
      );
    }

    this.columnDefs.set(columnDefs);
  }

  onEdit(row: Country) {
    if (this.areButtonsEnabled()) {
      this.edit.emit(row);
    }
  }

  onRowDataUpdated(rows: IRowNode<CountryRow>[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement());
    }
  }

  private setBatchActionSettings() {
    if (this.isBatchActionsEnabled()) {
      this.batchActionSettings.set({
        actions: new Map([
          [
            BatchActionTypeEnum.DELETE,
            BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.api),
          ],
        ]),
      });
    }
  }

  private onDelete(row: Country) {
    const onSubmit = async () => {
      this.isLoading.set(true);

      try {
        await this.api.delete(row.id);
        this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
        await this.getAll();
      } catch (error) {
        this.notificationService.showError(error);
        this.isLoading.set(false);
      }
    };

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this country?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
        onSubmit,
      },
    });
  }

  private getParsedRowData = (rowData: Country[]): Promise<CountryRow[]> => {
    const result: CountryRow[] = rowData.map((countryRow) => ({
      ...countryRow,
      address: {
        country: countryRow.code,
        countryName: "",
      },
    }));

    return CommonUtils.getElementsWithCountryName(this.countryOptions(), result);
  };
}
