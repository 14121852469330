<h2 mat-dialog-title>
  @if (isEditing()) {
    <ng-container i18n>EU Information System credentialsd</ng-container>
  } @else {
    <ng-container i18n>Connect to the EU Information System</ng-container>
  }
</h2>

@if (isLoading()) {
  <app-loader containerClass="on-dialog"></app-loader>
} @else {
  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
    <mat-dialog-content>
      <app-input
        [autofocus]="true"
        formControlName="username"
        [label]="translations.usernameLabel"
      ></app-input>
      <app-input-password
        formControlName="authKey"
        [label]="translations.authKeyLabel"
        autocomplete="new-password"
      ></app-input-password>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="formGroup.invalid || formGroup.pending" accessibilityRole="submit">
          <mat-icon icon-left>conversion_path</mat-icon>

          @if (isEditing()) {
            <ng-container i18n>Save & connect</ng-container>
          } @else {
            <ng-container i18n>Connect</ng-container>
          }
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </form>
}
