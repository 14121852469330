<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <app-input
        [autofocus]="!isEditing()"
        enableViewMode
        formControlName="name"
        [label]="translations.nameLabel"
      ></app-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-chips
        #locationTypesInputChips
        [allowCreateTag]="true"
        [tagUrl]="locationTypesService.getBaseUrl"
        [isGetTagsFromForm]="isInboundShare"
        enableViewMode
        formControlName="types"
        [label]="translations.typesLabel"
        [placeholder]="translations.typesPh"
        [tooltip]="translations.typesTooltip"
      ></app-input-chips>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [options]="organisationOptions"
        [viewModeTemplate]="organisationViewMode"
        enableViewMode
        formControlName="organisation"
        [label]="translations.organisationLabel"
      >
        <ng-container action>
          <app-button
            (pressed)="onAddOrganisationClicked()"
            class="icon-add-circle"
            [tp]="translations.organisationTp"
            type="icon"
          >
            <mat-icon icon-small>add</mat-icon>
          </app-button>
        </ng-container>
      </app-input-select>

      <ng-template #organisationViewMode let-label="label" let-option>
        <app-form-label [label]="label"></app-form-label>
        <div class="form-field-input-readonly">
          @if (option.label && option.value) {
            <app-form-link [link]="getOrganisationLink(option.value)" [styledLink]="true">
              {{ option.label }}
            </app-form-link>
          } @else {
            @if (isSharedUser) {
              <app-not-shared-data></app-not-shared-data>
            } @else {
              <app-loader containerClass="size-small"></app-loader>
            }
          }
        </div>
      </ng-template>
    </div>
  </div>
</div>
