<app-slide-overlay-header
  [canArchive]="!isActiveOrganisation"
  [enableTags]="!isActiveOrganisation"
  [canDelete]="!isActiveOrganisation"
  [title]="title()"
  [isSvgIcon]="true"
  icon="organisations"
>
  @if (!isActiveOrganisation && element?.publicUri) {
    <app-dropdown-menu-item additional-options (pressed)="onViewExternalProfile()">
      <mat-icon>preview</mat-icon>
      <ng-container i18n>View external profile</ng-container>
    </app-dropdown-menu-item>
  }
</app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="name"
              [label]="translations.nameLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              [viewModeTemplate]="urlTemplate"
              formControlName="website"
              [label]="translations.websiteLabel"
            ></app-input>
          </div>
        </div>
      </div>
    </app-form-field-group>
    <app-form-field-group [name]="translations.addressLabel">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="street"
              [label]="translations.addressLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="region"
              [label]="translations.regionLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="zipCode"
              [label]="translations.zipCodeLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input-select
              [options]="countryOptions"
              [viewModeTemplate]="countryViewMode"
              enableViewMode
              formControlName="country"
              [label]="translations.countryLabel"
              [prefixTemplate]="flag"
            ></app-input-select>
            <ng-template #flag let-option>
              <app-flag [country]="option.value"></app-flag>
            </ng-template>
            <ng-template #countryViewMode let-label="label" let-option>
              <div class="form-field-item">
                <app-form-label [label]="label"></app-form-label>
                <div class="form-field-input-readonly">
                  <app-country [option]="option"></app-country>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="linkedController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.LOCATIONS).isEnabled"
    [overlay-menu-indicator]="locationCount"
    [overlay-menu-show-save-button]="false"
    appSlideOverlayMenuItem
    overlay-menu-icon="location_on"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.LOCATIONS).title"
  >
    @if (isLoadingLocations()) {
      <app-loader></app-loader>
    } @else {
      <article class="field-group-container block no-padding-bottom">
        <app-locations-table
          #locationsTable
          [areButtonsEnabled]="false"
          [locationsIds]="selectedLocationsIds"
          [columns]="[
            'recordState',
            'name',
            'types',
            'address.countryName',
            'tags',
            'riskAssessmentReport.completedDate',
            'riskAssessmentReport.residualRisk',
          ]"
        ></app-locations-table>
      </article>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ORGANISATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="organisationOverlay"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ORGANISATION"
      [isReadOnly]="isRecordArchived"
      [overlayService]="organisationOverlay"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    *ngIf="
      !menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isHidden &&
      element?.id !== activeOrganisationId
    "
    appSlideOverlayMenuItem
    overlay-menu-icon="select_check_box"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).title"
    [overlay-menu-buttons-template]="riskAssessmentController"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="organisationOverlay.reportsCounter()"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isEnabled"
  >
    <app-overlay-risk-assessment-reports
      #riskAssessmentsView
      [activeOrganisationId]="activeOrganisationId"
      [recordUri]="entityUri"
      [recordName]="element.name"
      [resourceType]="templateResourceType"
      [overlayService]="organisationOverlay"
      [relatedRiskAssessmentReportTableParams]="relatedRiskAssessmentReportsTableParams()"
    />
  </ng-template>
  <ng-template #riskAssessmentController>
    @if (isRecordActive && canAddModifyEntities) {
      <app-button
        type="ghost"
        [disabled]="overlay.loading()"
        (pressed)="riskAssessmentsView.openDialog()"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="organisationOverlay.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(organisationOverlay)"
        (deleted)="onCommentDeleted(organisationOverlay)"
        [entityId]="element?.id"
        [entityType]="entityTypeEnum.CONNECTIONS"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #linkedController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button (pressed)="onAddLocationClicked()" type="ghost">
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #urlTemplate let-data>
    <div class="form-field-item hover-visibility-container">
      <app-form-label [label]="data.label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (data.value) {
          <div class="one-line-wrap-ellipsis">
            <a
              class="blue-link"
              [href]="data.value"
              target="_blank"
              tpPlacement="top-start"
              [tp]="data.value"
            >
              {{ data.value }}
              <app-external-link-icon></app-external-link-icon>
            </a>
          </div>
        } @else {
          -
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>
