<div class="container-space-between">
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>

  <app-button-group>
    <app-button type="outlined" (pressed)="onNavigate(routingEnum.FULL_SUPPLY_CHAIN)" i18n>
      View full supply chain
    </app-button>
    <app-button
      [visible]="canAddModifyEntities"
      (pressed)="onNavigate(routingEnum.SUPPLY_CHAINS_ADD)"
    >
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<app-supply-chains-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
></app-supply-chains-table>
