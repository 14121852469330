<div class="auth-container">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container">
      @if (isLanguagesEnabled && !isLoading()) {
        <div class="auth-language-selector-container">
          <app-language-selector
            [showFullLanguageName]="false"
            class="no-padding"
          ></app-language-selector>
        </div>
      }
      <div class="text-center">
        @switch (currentStep) {
          @case ("initial") {
            <div class="title">
              <h1 i18n>Recover password</h1>
            </div>

            <div class="subtitle" i18n>
              Please enter your email and we will send you a password recovery link.
            </div>

            @if (isLoading()) {
              <app-loader containerClass="no-margin"></app-loader>
            } @else {
              <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="flex-column">
                <app-input
                  type="email"
                  [label]="translations.emailLabel"
                  formControlName="email"
                  autofocus
                ></app-input>

                <app-button
                  [disabled]="isSubmitButtonDisabled"
                  class="fresh-green"
                  size="large"
                  accessibilityRole="submit"
                  cdkFocusInitial
                  i18n
                >
                  Send
                </app-button>
              </form>
            }
          }
          @case ("email-sent") {
            <div class="title">
              <h1 i18n>Email sent</h1>
            </div>
            @if (isLoading()) {
              <app-loader containerClass="no-margin"></app-loader>
            } @else {
              <div class="subtitle">
                <p>
                  <ng-container i18n>We have sent you a password restore link to:</ng-container>
                  <ng-container>&nbsp;</ng-container>
                  <span class="email-address">{{ formGroup.controls["email"].value }}</span>
                </p>
                <br />
                <p>
                  <ng-container i18n>
                    Didn’t receive the email? Check you spam folder or try with
                  </ng-container>
                  <a
                    class="underline clickable"
                    (click)="onChangeStep('initial')"
                    (keydown.enter)="onChangeStep('initial')"
                    tabindex="0"
                    i18n
                  >
                    another email address
                  </a>
                </p>
              </div>
            }
          }
        }

        @if (!isLoading()) {
          <div class="switch-mode-container">
            <ng-container i18n>Back to</ng-container>
            <a [routerLink]="'/' + routingEnum.LOGIN" i18n>Login</a>
          </div>
        }
      </div>
    </div>
  </div>
</div>
