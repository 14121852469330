@if (label() && showLabel()) {
  <app-form-label [label]="label()" />
}

@if (enableViewMode()) {
  <div class="rich-text-value" [innerHTML]="control().value"></div>
} @else {
  <quill-editor
    bounds="self"
    [formControl]="control()"
    [placeholder]="placeholder()"
    [readOnly]="control().disabled"
    (onEditorCreated)="onCreated()"
  />

  <app-form-hint
    [isOptional]="isOptional()"
    [hint]="hint()"
    [isDisabled]="control().disabled"
    [errors]="control().errors"
    [showError]="control().errors && (control().dirty || control().touched)"
  />
}
