<div class="container-space-between">
  <app-page-title [title]="translations.title"></app-page-title>
</div>

<mat-tab-group animationDuration="0ms" mat-align-tabs="start" [mat-stretch-tabs]="false">
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Received records</ng-container>
      <app-indicator [value]="newInboundSharesCountObservable$ | async"></app-indicator>
    </ng-template>
    <app-inbox-table [isBatchActionsEnabled]="true"></app-inbox-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Updates</ng-container>
      <app-indicator [value]="updatesTable?.totalElements()"></app-indicator>
    </ng-template>
    <app-inbox-table
      #updatesTable
      [isBatchActionsEnabled]="true"
      [isUpdatesTable]="true"
      [columns]="['sender', 'recordType', 'recordName', 'receivedOn', 'mappedRecordName']"
    ></app-inbox-table>
  </mat-tab>
</mat-tab-group>
