import { AfterViewInit, Directive, EventEmitter, inject, Output, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { BulkAddBaseService } from "@components/shared/bulk-add/bulk-add-base.service";
import { BulkAddCreateRecordsModel as Model } from "@components/shared/bulk-add/bulk-add-create-records.model";
import { ITagExtended, ITagPayload } from "@shared/interfaces";
import { NotificationService, AuthenticationService, TagsService } from "@shared/services";
import { CustomFieldsUtils } from "@shared/utils";

@Directive()
export abstract class BulkAddCreateRecords<TPayload extends Model.IWithStatus, TRecordPayload>
  implements AfterViewInit
{
  abstract ngAfterViewInit(): Promise<void>;

  protected payloadsWithStatus = signal<TPayload[]>([]);

  protected hasProcessCompleted = signal<boolean>(false);

  protected authenticationService: AuthenticationService = inject(AuthenticationService);

  protected notificationService: NotificationService = inject(NotificationService);

  private tagsService: TagsService = inject(TagsService);

  @Output() public creationProcessComplete: EventEmitter<void> = new EventEmitter();

  protected activeOrganisationId: string;

  protected async saveTagsForNewRecord(tags: ITagExtended[], entityUri: string): Promise<void> {
    const promises = tags.map((tag) => {
      const payload: ITagPayload = { entity: entityUri, definition: tag.definition };

      return this.tagsService.addToRecord(payload);
    });

    await Promise.all(promises);
  }

  protected addCustomFieldsToPayload(
    customFields: {
      customFieldId: string;
      customFieldValue: string;
      customFieldLabel: string;
      isFixedCustomField?: boolean;
    }[],
    payload: TRecordPayload,
    service: BulkAddBaseService,
  ) {
    const customFieldsFormGroup = new UntypedFormGroup({});

    customFields.forEach((customField) => {
      customFieldsFormGroup.addControl(
        customField.customFieldId,
        new UntypedFormControl(customField.customFieldValue),
      );
    });

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      customFieldsFormGroup,
      service.allCustomFields(),
    );
  }
}
