import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import { AuthService, FeatureFlagService } from "src/app/shared/services";
import { CustomValidators, NEW_PASSWORD_VALIDATORS } from "src/app/shared/validators";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup(
    {
      password: new UntypedFormControl(null, NEW_PASSWORD_VALIDATORS),
      confirmPassword: new UntypedFormControl(null, [CustomValidators.required]),
    },
    {
      validators: CustomValidators.passwordMatch,
    },
  );

  public isLoading = signal(false);

  public readonly routingEnum = RouteEnum;

  private token: string = null;

  public readonly translations: any = {
    passwordLabel: $localize`New password`,
    confirmPasswordLabel: $localize`Confirm new password`,
  };

  readonly isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private authService: AuthService,
    private featureFlagService: FeatureFlagService,
  ) {}

  get isSubmitButtonDisabled(): boolean {
    return this.formGroup.invalid;
  }

  get hasGroupValidationErrors() {
    return (
      this.formGroup.errors &&
      (this.formGroup.controls["confirmPassword"].dirty ||
        this.formGroup.controls["confirmPassword"].touched)
    );
  }

  public async ngOnInit(): Promise<void> {
    this.token = this.route.snapshot.queryParams["t"];

    if (!this.token) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);
      await this.router.navigate([`/${RouteEnum.LOGIN}`]);
    }
  }

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const payload = {
      password: this.formGroup.controls["password"].value,
    };

    await this.authService
      .resetPassword(payload, this.token)
      .then(async () => {
        this.notificationService.showSuccess($localize`Password changed`);
        await this.router.navigate([`/${RouteEnum.LOGIN}`]);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };
}
