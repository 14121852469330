import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ColDef, IRowNode } from "ag-grid-community";
import { Subscription } from "rxjs";
import { LinkCellRendererComponent } from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  ConnectionStatusEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IConnectionExtended, ISelectOption } from "src/app/shared/interfaces";
import { CommonService, ConnectionsService, FeatureFlagService } from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TableWithRiskAssessmentReportsService } from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { RouterService } from "@shared/services/router.service";

import { TableBaseClass } from "../table/models";

@Component({
  standalone: false,
  selector: "app-connections-table",
  templateUrl: "./connections-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectionsTableComponent
  extends TableBaseClass<IConnectionExtended>
  implements AfterViewInit, OnDestroy
{
  @Input()
  public connections: IConnectionExtended[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public subscribeToOverlayRefreshTable = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public table = TableEnum.ORGANISATIONS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public allowClickOnNameRow: boolean = true;

  @Input()
  public columns: string[] = [
    "recordState",
    "name",
    "address.countryName",
    "tags",
    "riskAssessmentReport.completedDate",
    "riskAssessmentReport.residualRisk",
  ];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  private countryOptions: ISelectOption[];

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private connectionsService: ConnectionsService,
    private commonService: CommonService,
    private overlay: SlideOverlayPageService,
    private featureFlagService: FeatureFlagService,
    private tableWithReports: TableWithRiskAssessmentReportsService,
  ) {
    super();

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.ORGANISATION);
    }

    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions = countriesOptions;
      });
  }

  public async ngAfterViewInit() {
    if (this.subscribeToOverlayRefreshTable) {
      this.subscriptions.add(
        this.overlay.refreshTable$.subscribe(({ entityType }) => {
          if (
            entityType === EntityTypeEnum.CONNECTIONS ||
            entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS
          ) {
            this.getAll();
          }
        }),
      );
    }

    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.connectionsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.connectionsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.connectionsService),
        ],
      ]),
    };
  };

  override setColumnDefs = (): void => {
    const isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
      FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
    );
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        suppressAutoSize: true,
        suppressSizeToFit: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          icon: "link",
          iconTooltip: $localize`Connected`,
          showIcon: (row: IConnectionExtended) =>
            isCrossOrgSharingEnabled && row.connectStatus === ConnectionStatusEnum.CONNECTED,
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      {
        headerName: TextConstants.COUNTRY,
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      {
        headerName: $localize`Address`,
        field: "address.street",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: $localize`ZIP / Post code`,
        field: "address.zipCode",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: $localize`Region/State`,
        field: "address.region",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: $localize`Certificates`,
        field: "certificates",
        valueGetter: (cell: { data: IConnectionExtended }) => {
          return (
            cell.data.certificates
              .map((certificate) => {
                const values: string[] = [
                  certificate.standard.name,
                  certificate.standardType?.fullName,
                  certificate.number,
                ];

                return values.filter((value) => !!value).join(" - ");
              })
              .join(", ") || "-"
          );
        },
      },
      {
        headerName: "EORI",
        field: "eori",
        valueGetter: this.getCustomFieldValue("EORI"),
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: TextConstants.EMAIL,
        field: "email",
        valueGetter: this.getCustomFieldValue("Email"),
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
    ];

    if (this.isRegularUser) {
      columnDefs.push(
        ColumnUtils.tags(),
        this.tableWithReports.lastRiskAssessedColDef,
        this.tableWithReports.residualRiskLevelColDef,
      );
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onViewDetails = (row: IConnectionExtended): void => {
    if (!this.areButtonsEnabled) {
      return;
    }

    this.routerService.navigate(this.routerService.getOrganisationLink(row.id));
  };

  private getCustomFieldValue =
    (field: string) =>
    (cell: { data: IConnectionExtended }): string => {
      return (cell.data.customFields || []).find((cf) => cf.definition.label === field)?.value;
    };

  override getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.connections) {
      this.rowData.set(await this.getParsedRowData(this.connections));

      this.isLoading.set(false);
    } else {
      try {
        const connections = await this.connectionsService.getAllGraphQL(
          undefined,
          undefined,
          this.columnDefs().some((c) => c.field === "tags") ? ["TAGS"] : undefined,
        );

        this.rowData.set(await this.getParsedRowData(connections));

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  onRowDataUpdated(rows: IRowNode<IConnectionExtended>[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement());
    }
  }

  private getParsedRowData = async (rowData: any[]) => {
    rowData = CommonUtils.getElementsWithCountryName(this.countryOptions, rowData);

    return rowData;
  };
}
