<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content>
    <p *ngIf="data.contentText">{{ data.contentText }}</p>
    <div *ngIf="data.contentHTML" [innerHTML]="data.contentHTML"></div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-button-group>
      <app-button (pressed)="onClose()" type="ghost">
        @if (data.cancelButtonIcon) {
          <mat-icon icon-left>{{ data.cancelButtonIcon }}</mat-icon>
        }
        @if (data.cancelButtonText) {
          {{ data.cancelButtonText }}
        } @else {
          <ng-container i18n>Cancel</ng-container>
        }
      </app-button>

      @if (data.thirdActionButtonText) {
        <app-button (pressed)="thirdActionPerfomed()" [color]="data.thirdActionButtonColor">
          @if (data.thirdActionButtonIcon) {
            <mat-icon icon-left>
              {{ data.thirdActionButtonIcon }}
            </mat-icon>
          }
          {{ data.thirdActionButtonText }}
        </app-button>
      }
      <app-button
        [color]="data.confirmButtonColor"
        [disabled]="isSubmitButtonDisabled() || data.isSubmitButtonDisabled"
        accessibilityRole="submit"
        cdkFocusInitial
      >
        <mat-icon icon-left>{{ data.confirmButtonIcon ?? "check" }}</mat-icon>
        @if (data.confirmButtonText) {
          {{ data.confirmButtonText }}
        } @else {
          <ng-container i18n>Confirm</ng-container>
        }
      </app-button>
    </app-button-group>
  </mat-dialog-actions>
</form>
