import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  input,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";

import { AgGridAngular } from "ag-grid-angular";
import {
  CellClickedEvent,
  ColDef,
  DomLayoutType,
  GridState,
  IRowNode,
  RowClassRules,
  SelectionChangedEvent,
  StateUpdatedEvent,
} from "ag-grid-community";
import { debounceTime, Subject, Subscription } from "rxjs";
import { CommonConstants } from "src/app/shared/constants";
import { RecordStateEnum, TableEnum } from "src/app/shared/enums";
import { LocalizationService, LocalStorageService } from "src/app/shared/services";

import { TableBatchActionsComponent, TableFooterComponent } from "@components/index";
import { TableDatepickerComponent } from "@components/shared/table-datepicker";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { BatchActionService } from "@shared/services/batch-action.service";
import { CommonUtils } from "@shared/utils";

import { TableTooltip } from "./table-tooltip";

@Component({
  standalone: false,
  selector: "app-table",
  templateUrl: "./table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  public table: TableEnum;

  @Input()
  public class: string;

  public isLoading = input<boolean>(true);

  public rowData = input<any[]>([]);

  @Input()
  public batchActionSettings: BatchActionModel.IBatchActionSettings;

  @Input()
  public columnDefs: any = [];

  @Input()
  public areButtonsEnabled: boolean = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  public areExpandButtonsEnabled = input<boolean>(false);

  public moreOptionsTemplate = input<TemplateRef<unknown>>();

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public masterDetail: boolean = false;

  @Input()
  public detailCellRendererParams: any;

  @Input()
  public detailCellRenderer: any;

  @Input()
  public getRowClass: (params: any) => string | string[];

  @Input()
  public autoExpandDetailGrids: boolean = false;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public getGridHeightFn: any;

  @Input()
  public isSaveState = false;

  @Input()
  public isSaveSearch = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public getRowHeight;

  @Input()
  public rowGroupColumns: string[];

  @Input()
  public emptyResultsTemplate: TemplateRef<unknown>;

  @Input()
  public autoGroupColumnDefParams: ColDef = {};

  @Output() rowClick = new EventEmitter();

  @Output() selectionChanged = new EventEmitter();

  @Output() rowsVisibleUpdated = new EventEmitter<IRowNode[]>();

  @Output() rowDataUpdated = new EventEmitter<IRowNode[]>();

  @Output() getAll = new EventEmitter<void>();

  @ViewChild("grid") public grid: AgGridAngular;

  @ViewChild("gridFooter") public gridFooter: TableFooterComponent;

  @ViewChild("gridBatchActions") public gridBatchActions: TableBatchActionsComponent;

  readonly components = {
    agDateInput: TableDatepickerComponent,
  };

  public readonly tableAvailablePageSizes = CommonConstants.TABLES_AVAILABLE_PAGE_SIZES;

  public readonly initialPageSize: number = CommonConstants.TABLES_AVAILABLE_PAGE_SIZES[0];

  public readonly overlayNoRowsTemplate = `<div class='content no-results-found'><img src='/assets/images/no-results-found.svg' height='100' i18n-alt alt='No records found' /><div class='title'>${$localize`No records found`}</div></div>`;

  public readonly popupParent: HTMLElement | null = document.querySelector("body");

  public readonly localeText = this.localizationService.agGridLocaleText;

  public rowClassRules: RowClassRules = null;

  public previousAggregationData: any;

  public autoGroupColumnDef: ColDef;

  public readonly defaultColDef: ColDef = {
    minWidth: 150,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    enableValue: true,
    enablePivot: true,
    filter: true,
    filterParams: { newRowsAction: "keep" },
    tooltipComponent: TableTooltip,
    mainMenuItems: (params: any) => {
      const resetColumnsIndex = params.defaultItems.findIndex((i) => i === "resetColumns");

      if (resetColumnsIndex !== -1) {
        params.defaultItems[resetColumnsIndex] = {
          name: $localize`Reset Columns`,
          action: () => {
            this.grid.api.resetColumnState();
            this.autoSizeGridSubject.next(true);
          },
        };
      }

      return params.defaultItems;
    },
  };

  public initialState: GridState;

  public searchText: string = null;

  public domLayout: DomLayoutType = "autoHeight";

  public heightStyle = "";

  public isBatchInProgress = signal(false);

  public isBatchActionsVisible = signal(false);

  @ViewChild("grid", { read: ElementRef }) private gridHtml: ElementRef<HTMLElement>;

  private readonly eventsToIgnoreForSaveState = [
    "gridInitializing",
    "columnSizing",
    "focusedCell",
    "scroll",
  ];

  private readonly eventsToIgnoreForRowsVisible = [
    ...this.eventsToIgnoreForSaveState,
    "rowGroupExpansion",
    "columnPinning",
    "columnOrder",
  ];

  private readonly eventsToTriggerSelectionChanged = [
    "uiSelectAllFiltered",
    "uiSelectAllCurrentPage",
    "checkboxSelected",
  ]; // only user using UI triggered events

  private readonly searchLocalStorageSuffix = "-search";

  private readonly keyLocalStoragePreffix = "table-";

  private currentState: GridState = undefined;

  private saveStateSubject = new Subject();

  private autoSizeGridSubject = new Subject();

  private setGridHeightSubject = new Subject();

  private showHideNoRecordsSubject = new Subject();

  private renderedRowsSubject = new Subject();

  private subscriptions = new Subscription();

  constructor(
    private localStorageService: LocalStorageService,
    private localizationService: LocalizationService,
    private batchActionService: BatchActionService,
  ) {
    this.subscriptions.add(
      this.setGridHeightSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_RESIZE_WINDOW_TIME_MS))
        .subscribe(() => {
          this.setGridHeight();
        }),
    );

    this.subscriptions.add(
      this.autoSizeGridSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_RESIZE_WINDOW_TIME_MS))
        .subscribe(() => {
          this.autoSizeGrid();
        }),
    );

    this.subscriptions.add(
      this.saveStateSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SAVE_STATE_TIME_MS))
        .subscribe((state: GridState) => {
          if (this.isSaveState && this.table) {
            this.localStorageService.set(
              `${this.keyLocalStoragePreffix}${this.table}`,
              JSON.stringify(state),
            );
          }
        }),
    );

    this.subscriptions.add(
      this.showHideNoRecordsSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS))
        .subscribe(() => {
          this.showHideNoRecords();
        }),
    );

    this.subscriptions.add(
      this.renderedRowsSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_TABLE_SHOW_HIDE_NO_RECORDS_TIME_MS))
        .subscribe((rows: IRowNode<any>[]) => {
          this.rowsVisibleUpdated.emit(rows);
        }),
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.setGridHeightSubject.next(true);
    this.autoSizeGridSubject.next(true);
  }

  public ngOnInit(): void {
    this.setBatchActionsSubscription();
    this.setInitialState();

    if (this.areButtonsEnabled) {
      this.rowClassRules = {
        clickable: () => this.areButtonsEnabled && this.rowClick.observed,
      };
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["rowData"] && !changes["rowData"].firstChange) {
      this.initialState = this.currentState;
      this.setGridHeightSubject.next(true);
      this.autoSizeGridSubject.next(true);
    }
  }

  public onGridReady = async (): Promise<void> => {
    this.grid.api["autoSizeGridSubject"] = this.autoSizeGridSubject;

    this.onClearSelectedRows();
    this.setGridHeightSubject.next(true);
    this.autoSizeGridSubject.next(true);

    if (this.rowGroupColumns?.length) {
      this.grid.api.setRowGroupColumns(this.rowGroupColumns);

      this.autoGroupColumnDef = {
        headerName: "",
        pinned: "left",
        minWidth: 250,
        ...this.autoGroupColumnDefParams,
      };
    }
  };

  public onFirstDataRendered = async (): Promise<void> => {
    this.setNodesSelected();

    if (this.autoExpandDetailGrids) {
      this.grid.api.forEachNode((node) => node.setExpanded(true));
    }

    this.autoSizeGridSubject.next(true);
  };

  onRowDataUpdated = () => {
    const rows = [];

    this.grid.api.forEachNode((node) => rows.push(node));
    this.rowDataUpdated.emit(rows);
  };

  public onGridSizeChanged = (): void => {
    this.autoSizeGridSubject.next(true);
  };

  public onSelectionChanged = (event: SelectionChangedEvent): void => {
    if (this.batchActionSettings) {
      const hasSelectedVisibleNodes = !!this.grid.api.getSelectedNodes().filter((n) => n.displayed)
        .length;

      this.isBatchActionsVisible.set(hasSelectedVisibleNodes);

      if (hasSelectedVisibleNodes && this.gridBatchActions) {
        this.gridBatchActions.onSelectionChanged();
      }
    }

    if (!this.eventsToTriggerSelectionChanged.includes(event.source)) {
      return;
    }

    const selectedRowIds = this.grid.api.getSelectedRows().map((row) => row.id);

    const rowData = [];

    for (let i = 0; i < this.rowData().length; i++) {
      const row = this.rowData()[i];
      const isSelected = selectedRowIds.some((id) => id === row.id);

      rowData.push({ ...row, isSelected });
    }

    this.selectionChanged.emit(rowData);
  };

  public onPaginationChanged = (): void => {
    this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
    this.autoSizeGridSubject.next(true);
  };

  private setBatchActionsSubscription = (): void => {
    if (!this.batchActionSettings) {
      return;
    }
    this.subscriptions.add(
      this.batchActionService.dataSubject$.subscribe((actionData) => {
        if (!actionData?.records.length) {
          return;
        }

        if (
          !actionData.records.some(
            (record) => record.status === BatchActionModel.BatchStatusEnum.PENDING,
          )
        ) {
          this.getAll.emit();
          this.isBatchInProgress.set(false);
        }
      }),
    );
  };

  public onClearSelectedRows(): void {
    if (this.batchActionSettings) {
      this.grid.api.deselectAll();
    }
  }

  public onCellClicked = (event: CellClickedEvent): void => {
    if (event.column.getColId() === "selectCheckbox") {
      const rowNode = this.grid.api.getRowNode(event.node.id);

      rowNode.setSelected(!rowNode.isSelected(), false, "checkboxSelected");

      return;
    }

    if (!this.areButtonsEnabled || !event.data || event.node?.detail) {
      return;
    }

    this.rowClick.emit(event.data);
  };

  public onSearchChanged = async (searchText: string): Promise<void> => {
    this.searchText = searchText;
    if (!this.grid) {
      return;
    }

    this.grid.api.setGridOption("quickFilterText", searchText);
    this.gridFooter?.updateValues();

    if (this.isSaveSearch && this.table) {
      if (searchText) {
        this.localStorageService.set(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
          searchText,
        );
      } else {
        this.localStorageService.remove(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
        );
      }
    }
    this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
    this.showHideNoRecordsSubject.next(true);
  };

  public onRecordStateChanged = async (recordState: RecordStateEnum): Promise<void> => {
    if (!this.grid) {
      return;
    }

    this.onClearSelectedRows();

    this.recordState = recordState ?? RecordStateEnum.ALL;

    let filterModel = null;

    if (recordState !== RecordStateEnum.ALL) {
      filterModel = {
        filterType: "text",
        type: "contains",
        filter: recordState,
      };
    }

    await this.grid.api.setColumnFilterModel("recordState", filterModel);
    this.grid.api.onFilterChanged();
    this.showHideNoRecordsSubject.next(true);
  };

  public onFilterChanged = (): void => {
    this.onClearSelectedRows();
  };

  public onStateUpdated = (event: StateUpdatedEvent): void => {
    this.currentState = event.state;
    this.showHideNoRecordsSubject.next(true);

    const eventSource = event.sources[0];

    if (eventSource === "aggregation") {
      this.onClearSelectedRows();
    }

    if (!this.eventsToIgnoreForRowsVisible.includes(eventSource)) {
      this.renderedRowsSubject.next(this.grid.api.getRenderedNodes());
    }

    if (this.isSaveState && !this.eventsToIgnoreForSaveState.includes(eventSource)) {
      this.saveStateSubject.next(event.state);
    }
  };

  public onColumnRowGroupChanged = (): void => {
    this.onClearSelectedRows();
    this.autoSizeGridSubject.next(true);
  };

  public onSortChanged = (): void => {
    this.onClearSelectedRows();
  };

  public expandAll = (): void => {
    if (!this.grid) {
      return;
    }
    this.grid.api.expandAll();
  };

  public collapseAll = (): void => {
    if (!this.grid) {
      return;
    }
    this.grid.api.collapseAll();
  };

  private setNodesSelected = (): void => {
    if (!this.grid) {
      return;
    }
    const nodesToSelect: IRowNode[] = [];

    this.grid.api.forEachNode((node: IRowNode) => {
      if (node.data?.isSelected) {
        nodesToSelect.push(node);
      }
    });
    this.grid.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  };

  private setInitialState = (): void => {
    this.initialState = {};

    if (this.isRecordStateFilterEnabled && this.recordState !== RecordStateEnum.ALL) {
      this.initialState = {
        ...this.initialState,
        filter: {
          filterModel: {
            recordState: {
              filterType: "text",
              type: "contains",
              filter: this.recordState,
            },
          },
        },
      };
    }

    if (this.isSaveState && this.table) {
      const storedStateString = this.localStorageService.get(
        `${this.keyLocalStoragePreffix}${this.table}`,
      );

      if (storedStateString) {
        const storedStateJson = JSON.parse(storedStateString);

        this.initialState = {
          ...storedStateJson,
          pagination: { ...this.initialState.pagination, page: 0 },
          rowSelection: [],
          scroll: null,
        };
        if (this.isRecordStateFilterEnabled) {
          const recordStateFilter = this.initialState?.filter?.filterModel["recordState"];

          this.recordState = recordStateFilter?.filter ?? RecordStateEnum.ALL;
        }
      }
      if (this.isSearchEnabled && this.isSaveSearch) {
        const storedSearch = this.localStorageService.get(
          `${this.keyLocalStoragePreffix}${this.table}${this.searchLocalStorageSuffix}`,
        );

        if (storedSearch) {
          this.searchText = storedSearch;
        }
      }
    }

    this.currentState = { ...this.initialState };
  };

  private showHideNoRecords = (): void => {
    if (!this.grid) {
      return;
    }
    const hasVisibleRows = !!this.grid.api.getDisplayedRowCount();

    if (hasVisibleRows) {
      this.grid.api.hideOverlay();
    } else {
      this.grid.api.showNoRowsOverlay();
    }
  };

  private autoSizeGrid = (): void => {
    if (!this.grid) {
      return;
    }

    this.grid.api.autoSizeAllColumns();
    const firstRow = this.gridHtml.nativeElement.querySelector(".ag-row") as HTMLElement;
    const gridWidth = this.gridHtml.nativeElement.offsetWidth;

    if (!firstRow || firstRow.offsetWidth < gridWidth) {
      this.grid.api.sizeColumnsToFit();
    }
  };

  private setGridHeight = (): void => {
    if (this.isFixedBottomPaginator || this.getGridHeightFn) {
      this.domLayout = "normal";
      this.heightStyle = `${this.getGridHeightFn ? this.getGridHeightFn() : CommonUtils.getOverviewPageHeight()}px`;
      this.autoSizeGridSubject.next(true);
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
