@if (columnDefs().length) {
  @if (isDdsServiceUnavailable) {
    <app-empty-search-results
      [message]="serviceUnavailableMsg"
      imageSrc="/assets/images/service-unavailable.svg"
    ></app-empty-search-results>
  }
} @else {
  <app-table
    (rowClick)="onViewDetails($event)"
    [areButtonsEnabled]="areButtonsEnabled()"
    [columnDefs]="columnDefs()"
    [isFixedBottomPaginator]="isFixedBottomPaginator()"
    [isLoading]="isLoading()"
    [isPaginatorEnabled]="isPaginatorEnabled()"
    [isRecordStateFilterEnabled]="isRecordStateFilterEnabled()"
    [isSaveState]="isSaveTableState()"
    [isSearchEnabled]="isSearchEnabled()"
    [rowData]="rowData()"
    [table]="table()"
    (getAll)="getAll()"
  ></app-table>
}
