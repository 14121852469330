import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SafeHtml } from "@angular/platform-browser";

@Component({
  standalone: false,
  templateUrl: "./info-dialog.component.html",
  styleUrls: ["./info-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      icon?: string;
      iconColor?: string;
      title?: string;
      contentText?: string;
      contentSafeHTML?: SafeHtml;
    },
  ) {}

  public onClose = (): void => {
    this.dialogRef.close();
  };
}
