import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnDestroy,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { EudrDdsTableModel as Model } from "@components/settings/settings-extensions/settings-eudr/eudr-table/eudr-table.model";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { RouteEnum, TableEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { NotificationService, CommonService } from "@shared/services";
import { EudrDdsService } from "@shared/services/api/eudr-dds.service";
import { CellRendererUtils, ColumnUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-eudr-table",
  templateUrl: "./eudr-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EudrTableComponent implements AfterViewInit, OnDestroy {
  public statements = input<EudrDds.IDetails[]>(null);

  public areButtonsEnabled = input<boolean>(true);

  public isSearchEnabled = input<boolean>(true);

  public isRecordStateFilterEnabled = input<boolean>(false);

  public isPaginatorEnabled = input<boolean>(true);

  public isFixedBottomPaginator = input<boolean>(false);

  public isSaveTableState = input<boolean>(false);

  public isLoading = signal(true);

  public rowData = signal<Model.IRowData[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  private countryOptions = signal<ISelectOption[]>([]);

  public readonly table = input<TableEnum>(TableEnum.ADMIN_EUDR);

  private subscriptions = new Subscription();

  private router: Router = inject(Router);

  private eudrDdsService: EudrDdsService = inject(EudrDdsService);

  private commonService: CommonService = inject(CommonService);

  private notificationService: NotificationService = inject(NotificationService);

  private eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  public isDdsServiceUnavailable = false;

  public readonly serviceUnavailableMsg = TextConstants.SERVICE_UNAVAILABLE;

  constructor() {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.countryOptions.set(countriesOptions),
        ),
    );
  }

  public async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setColumnDefs(): void {
    const columnDefs: ColDef[] = [
      {
        headerName: $localize`Internal reference number`,
        field: "statement.internalReferenceNumber",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RouteEnum.EUDR_DDS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: $localize`Activity type`,
        field: "statement.activityType",
      },
      {
        headerName: $localize`Country of activity`,
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      ColumnUtils.tags($localize`Status`, "statusTags"),
    ];

    this.columnDefs.set(columnDefs);
  }

  public onViewDetails = async (statement: EudrDds.IDetails): Promise<void> => {
    await this.router.navigate([`/${RouteEnum.EUDR_DDS}/${statement.id}`]);
  };

  public async getAll(): Promise<void> {
    this.isLoading.set(true);

    if (this.statements()) {
      this.rowData.set(this.addCountryToStatements(this.statements()));

      return;
    }

    try {
      const statements = await this.eudrDdsService.getAll();

      this.rowData.set(this.addCountryToStatements(statements));
      this.isLoading.set(false);
    } catch (error) {
      if (error.status === 503) {
        this.isDdsServiceUnavailable = true;
      }
      this.notificationService.showError(error);
    }
  }

  private addCountryToStatements(statements: EudrDds.IDetails[]): Model.IRowData[] {
    const countryOptions = this.countryOptions();

    return statements.map((statement) => {
      return {
        ...statement,
        statusTags: [
          {
            title: statement.status,
            color: this.eudrDdsPageService.getStatusTagColor(statement.status),
          },
        ],
        address: {
          country: statement.statement.countryOfActivity,
          countryName: countryOptions.find(
            (country) => country.value === statement.statement.countryOfActivity,
          ).label,
        },
      };
    });
  }
}
