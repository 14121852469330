import { Directive, OnDestroy, OnInit } from "@angular/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Directive({
  standalone: false,
  selector: "[appAutocompletePositionUpdate]",
})
export class AutocompletePositionUpdateDirective implements OnInit, OnDestroy {
  public constructor(private readonly matAutocompleteTrigger: MatAutocompleteTrigger) {}

  ngOnInit(): void {
    window.addEventListener("scroll", this.scrollEvent, true);
  }

  ngOnDestroy(): void {
    window.removeEventListener("scroll", this.scrollEvent, true);
  }

  private scrollEvent = (): void => {
    if (this.matAutocompleteTrigger == null) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  };
}
