import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmDialogResponseEnum, DateTypeEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  IItemExtended,
  IMaterial,
  IProcessInput,
  IProcessOutput,
  IProductExtended,
} from "src/app/shared/interfaces";

import { TextConstants } from "@shared/constants";

import { ConfirmDialogComponent } from "../../shared";

@Component({
  standalone: false,
  selector: "app-process-selected-items",
  templateUrl: "./process-selected-items.component.html",
  styleUrl: "./process-selected-items.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProcessSelectedItemsComponent {
  @Input()
  public type: string;

  @Input()
  public elements: IProcessInput[] | IProcessOutput[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public canEdit: boolean = false;

  @Input()
  public canRemove: boolean = false;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  @Output()
  public edit: EventEmitter<IProcessInput | IProcessOutput> = new EventEmitter();

  public readonly dateTypeEnum = DateTypeEnum;

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public inboundItems: IItemExtended[] = [];

  public readonly translations: any = {
    editTp: TextConstants.EDIT,
    removeTp: TextConstants.REMOVE,
  };

  constructor(private dialog: MatDialog) {}

  public onRemove = (elementId: string): void => {
    const typeText =
      this.type === "input"
        ? $localize`Removing it will have an effect on all or some of the output items of this process`
        : $localize`Removing it will have an effect on all of the items listed in it, as they will no longer be linked to this process and all of its inputs`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Remove ${this.type}:type:`,
        contentText: $localize`Are you sure that you want to remove this ${this.type}:type:? ${typeText}:text:`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.remove.emit(elementId);
      }
    });
  };

  public onEdit = (element: IProcessInput | IProcessOutput): void => {
    this.edit.emit(element);
  };
}
