<app-table
  *ngIf="columnDefs().length"
  [table]="table"
  [isLoading]="isLoading()"
  [rowData]="rowData"
  [columnDefs]="columnDefs()"
  [areButtonsEnabled]="areButtonsEnabled"
  [isSearchEnabled]="isSearchEnabled"
  [isRecordStateFilterEnabled]="isRecordStateFilterEnabled"
  [isPaginatorEnabled]="isPaginatorEnabled"
  [isFixedBottomPaginator]="false"
  [rowGroupColumns]="rowGroupColumns"
  [class]="class"
  [isSaveState]="false"
  [recordState]="recordState"
  [areExpandButtonsEnabled]="true"
  [moreOptionsTemplate]="moreOptionsTemplate"
  (rowClick)="onRowClick($event)"
></app-table>

<ng-template #moreOptionsTemplate let-grid>
  <app-button type="ghost" (pressed)="downloadAll(grid)">
    <mat-icon icon-left>download</mat-icon>
    <ng-container i18n>Download all</ng-container>
  </app-button>
</ng-template>
