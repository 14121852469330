import { ChangeDetectionStrategy, Component, inject, signal } from "@angular/core";
import { FormBuilder, FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { RiskMitigationsApiService } from "@components/shared/risk-mitigations/api";
import { RiskMitigation, RiskMitigationPayload } from "@components/shared/risk-mitigations/models";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

export interface AddEditRiskMitigationDialogData {
  riskMitigation?: RiskMitigation;
  saveToListOption?: boolean;
}

interface RiskMitigationFormGroup {
  description: FormControl<string>;
  mitigation: FormControl<string>;
  saveToList?: FormControl<boolean>;
}

@Component({
  standalone: false,
  templateUrl: "./add-edit-risk-mitigation-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditRiskMitigationDialogComponent {
  private readonly fb = inject(FormBuilder);

  private readonly dialogRef: MatDialogRef<AddEditRiskMitigationDialogComponent> =
    inject(MatDialogRef);

  private readonly data: AddEditRiskMitigationDialogData = inject(MAT_DIALOG_DATA);

  private readonly api = inject(RiskMitigationsApiService);

  private readonly notification = inject(NotificationService);

  readonly form = this.fb.group<RiskMitigationFormGroup>({
    mitigation: this.fb.control("", [CustomValidators.required, CustomValidators.maxLength(500)]),
    description: this.fb.control<string>(null, [CustomValidators.maxLength(5000)]),
  });

  readonly isLoading = signal(true);

  readonly isEditing = signal(false);

  readonly translations: any = {
    mitigationLabel: $localize`Mitigation`,
    descriptionLabel: TextConstants.DESCRIPTION,
    saveToListLabel: $localize`Save to mitigations list`,
    descriptionPh: $localize`Type your description here…`,
  };

  constructor() {
    if (this.data?.riskMitigation?.id) {
      this.isEditing.set(true);
    }

    if (this.data?.saveToListOption) {
      this.form.addControl("saveToList", this.fb.control(false));
    }

    if (this.isEditing()) {
      const { mitigation, description } = this.data.riskMitigation;

      this.form.setValue({
        mitigation: CommonUtils.nl2br(mitigation),
        description,
      });
    }

    this.isLoading.set(false);
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isLoading.set(true);

    try {
      const shouldSaveToList = this.form.contains("saveToList") ? this.form.value.saveToList : true;

      if (shouldSaveToList) {
        const mitigation = await this.api.createOrUpdate(
          this.buildPayload(),
          this.data?.riskMitigation?.id,
        );

        this.notification.showSuccess(
          this.isEditing()
            ? $localize`Risk mitigation modified`
            : $localize`Risk mitigation created`,
        );

        this.close(mitigation);
      } else {
        const { mitigation, description } = this.form.getRawValue();

        this.close({ mitigation, description, id: undefined, recordState: undefined });
      }
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  close(mitigation: RiskMitigation = undefined) {
    this.dialogRef.close(mitigation);
  }

  private buildPayload(): RiskMitigationPayload {
    return {
      ...this.form.getRawValue(),
      recordState: null,
    };
  }
}
