@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  @if (unitsOfMeasurement.length) {
    <div class="default-unit-text">
      <small class="container-flex">
        <ng-container i18n>Default unit of measurement</ng-container>
        <mat-icon [tp]="translations.unitOfMeasurementTp">help</mat-icon>
      </small>
    </div>
  }

  <div class="full-width-items-container">
    @for (unit of unitsOfMeasurement; track $index) {
      <div class="item hover-visibility-container">
        <app-unit-of-measurement-card
          [type]="cardContentTypeEnum.REMOVE"
          [isSystemUnit]="$index === 0"
          [defaultUnitOfMeasurementId]="defaultUnitOfMeasurementId"
          [isToggleDisabled]="isToggleDisabled"
          (remove)="onRemove(unit.id)"
          (defaultUnitChanged)="onDefaultUnitChanged($event)"
          [unitOfMeasurement]="unit"
        ></app-unit-of-measurement-card>
      </div>
    }
  </div>
}
