import { Injectable } from "@angular/core";

import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";

@Injectable({
  providedIn: "root",
})
export class CertificateOverlayService extends OverlayCommonService {
  constructor() {
    super();
  }
}
