import { Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import {
  IAddressBase,
  IAvailableOrganisation,
  ICertificateDetails,
  ICustomFieldWithValue,
  IDeliveryExtended,
  IDocument,
  IItemExtended,
  IItemSupplyChain,
  ILocationExtended,
  IProcessExtended,
  ISelectOption,
  ISummarisedItem,
} from "src/app/shared/interfaces";
import { AuthenticationService, CommonService, ItemsService } from "src/app/shared/services";

import { RecordSharingService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

import {
  IDeliveryResponse,
  ILocationResponse,
  IGraphDeliveryItem,
  IDocumentResponse,
} from "./item-supply-chain.interface";

@Injectable({
  providedIn: "root",
})
export class ItemSupplyChainMapperService {
  private countryOptions: ISelectOption[];

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private itemsService: ItemsService,
    private recordSharingService: RecordSharingService,
  ) {
    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions = countriesOptions;
      });
  }

  public async mapItemSupplyChain(itemIds: string[], isInboundShared = false, senderId = null) {
    const activeOrganisation = this.authenticationService.getActiveOrganisation();
    let itemResponse;

    if (isInboundShared) {
      itemResponse = (
        await this.recordSharingService.getInboundItemsSupplyChainGraphQl(senderId, itemIds)
      ).items;
    } else {
      itemResponse = (await this.itemsService.getItemsSupplyChain(activeOrganisation.id, itemIds))
        .loadItemsSupplyChain?.items;
    }
    const allProcesses = itemResponse?.map((i) => i.processes).flat();
    const items: IItemSupplyChain[] = itemResponse?.map((itemResponse) => {
      const item = itemResponse?.item as unknown as IItemExtended;
      const locations: ILocationExtended[] = itemResponse?.locations?.map((l) =>
        this.mapLocation(l, activeOrganisation, allProcesses),
      );

      const deliveries: IDeliveryExtended[] = itemResponse?.deliveries?.map((delivery) =>
        this.mapDelivery(delivery),
      );
      const processes = itemResponse?.processes;

      return {
        item,
        locations,
        deliveries,
        processes,
      };
    });

    return items;
  }

  private mapLocation(
    location: ILocationResponse,
    activeOrganisation: IAvailableOrganisation,
    processes: IProcessExtended[],
  ): ILocationExtended {
    const organisationName = location?.connections[0]?.name ?? activeOrganisation?.name;
    const address: IAddressBase = {
      ...location?.address,
      countryName: this.countryOptions?.find((c) => c.value === location?.address?.country)?.label,
    };
    const locationProcesses: IProcessExtended[] = processes
      ?.filter((p) => p?.location?.id === location?.id)
      ?.filter((value, index, self) => self.findIndex((v) => v.id === value.id) === index)
      ?.map((process) => ({
        ...process,
        location: process?.location?.id,
        locationName: process?.location?.name,
      })) as unknown as IProcessExtended[];

    const documents = this.mapDocuments(location?.documents);
    const certificates = location?.certificates?.map((c) => ({
      ...c,
      standardName: c?.standard?.name,
      isSelected: true,
    })) as unknown as ICertificateDetails[];

    const customFieldsWithValues: ICustomFieldWithValue[] = location?.customFields?.map(
      (customField) => {
        return {
          label: customField.definition.label,
          value: customField.value,
        };
      },
    );

    return {
      id: location?.id,
      geoLocation: location?.geoLocation?.featureCollection,
      types: location?.types,
      address,
      name: location?.name,
      certificates,
      selectedCertificates: certificates,
      documents,
      selectedDocuments: documents,
      connections: location?.connections,
      organisationName,
      organisationId: location?.connections[0]?.id ?? activeOrganisation?.id,
      processes: locationProcesses,
      customFieldsWithValues,
      filteredAndSelectedDocuments: documents,
      filteredAndSelectedCertificates: certificates,
    } as ILocationExtended;
  }

  private mapDelivery(delivery: IDeliveryResponse): IDeliveryExtended {
    const { id, delivered, sent, deliveryId, documents, from, to, items, agents, certificates } =
      delivery;

    const groupedItems = items.reduce((accumulator, currentItem) => {
      const productId = currentItem?.item?.product?.id;
      const quantity = currentItem?.quantity;

      if (!accumulator[productId]) {
        accumulator[productId] = {
          id: currentItem?.item?.product?.id,
          productName: currentItem?.item?.product?.name,
          totalQuantity: quantity,
          itemId: currentItem?.item?.itemId,
          unitOfMeasurement: currentItem?.item?.product?.unitOfMeasurement,
          defaultCustomUnit: currentItem?.item?.product?.defaultCustomUnit,
        };
      } else {
        accumulator[productId].totalQuantity += quantity;
      }

      return accumulator;
    }, {});

    for (const productId in groupedItems) {
      const product = groupedItems[productId];

      if (product?.defaultCustomUnit) {
        product.totalQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          product.totalQuantity,
          product.defaultCustomUnit,
          product.unitOfMeasurement,
        );
      }
    }

    const summarisedItems: ISummarisedItem[] = Object.values(groupedItems);
    const itemsExtended = this.mapItems(items);

    const mappedDocuments = this.mapDocuments(documents);

    const mappedFrom = { ...from } as unknown as ILocationExtended;
    const mappedTo = { ...to } as unknown as ILocationExtended;
    const mappedAgents = agents
      .filter((agent) => agent)
      .map((agent) => ({ ...agent, documents: this.mapDocuments(agent.documents) }));

    return {
      id,
      deliveryId,
      delivered,
      sent,
      from: mappedFrom,
      to: mappedTo,
      summarisedItems,
      items: itemsExtended,
      agents: mappedAgents,
      documents: mappedDocuments,
      certificates,
    };
  }

  private mapItems(graphItems: IGraphDeliveryItem[]): any[] {
    //todo fix, should return IItemExtended
    return graphItems.map((item) => ({
      id: item?.item?.id,
      itemId: item?.item?.itemId,
      currentLocation: item?.item?.currentLocation,
      createdAtLocation: undefined,
      initialQuantity: item?.item?.initialQuantity,
      deliveredQuantity: item?.quantity,
      materials: item?.item?.materials,
      materialNames: item?.item?.materials?.map((material) => material?.name),
      product: item?.item?.product,
      unitOfMeasurement: { ...item?.item?.product?.unitOfMeasurement },
      productId: item?.item?.product?.id,
      productName: item?.item?.product?.name,
      remainingQuantity: item?.item?.remainingQuantity,
      recordState: item?.item?.recordState,
      initialQuantityFormatted: item?.item?.product?.defaultCustomUnit
        ? CommonUtils.formatQuantityWithDefaultUnit(
            item?.item?.initialQuantity,
            item?.item?.product?.defaultCustomUnit,
            item?.item?.product?.unitOfMeasurement,
          )
        : null,
      deliveredQuantityFormatted: item?.item?.product?.defaultCustomUnit
        ? CommonUtils.formatQuantityWithDefaultUnit(
            item?.quantity,
            item?.item?.product?.defaultCustomUnit,
            item?.item?.product?.unitOfMeasurement,
          )
        : null,
    }));
  }

  private mapDocuments(documents: IDocumentResponse[]): IDocument[] {
    return documents?.map((d) => ({
      ...d,
      isSelected: true,
      typeName: d?.type?.name,
    })) as unknown as IDocument[];
  }
}
