<div
  class="card"
  [class.clickable]="pressed.observed"
  (keyup)="onClick()"
  (click)="onClick()"
  tabindex="0"
>
  <div class="content flex-column full-width">
    @if (setting.svgIcon) {
      <mat-icon class="icon" [svgIcon]="setting.svgIcon" />
    } @else {
      <mat-icon class="icon">{{ setting.icon }}</mat-icon>
    }
    <div class="container-flex-space-between">
      <div class="text flex-column">
        <div class="title bold">{{ setting.title }}</div>
        <div class="description">{{ setting.description }}</div>
      </div>
      @if (pressed.observed && !setting.slideOverButton) {
        <div class="arrow-icon-container">
          <mat-icon>arrow_forward</mat-icon>
        </div>
      }
      @if (setting.slideOverButton) {
        <mat-slide-toggle
          [tp]="setting.slideOverButton.tooltip"
          [tpIsLazy]="true"
          [checked]="setting.slideOverButton.isChecked"
          [disabled]="setting.slideOverButton.isDisabled"
          (click)="$event.stopPropagation()"
          (change)="setting.slideOverButton.onChange()"
        ></mat-slide-toggle>
      }
    </div>
  </div>
</div>
