import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, Observable } from "rxjs";
import { LocalStorageService } from "src/app/shared/services/local-storage.service";

import { IUserData } from "@shared/interfaces";

import { CommonService } from "./api";
import { FeatureFlagService } from "./feature-flag.service";
import { RouterService } from "./router.service";
import {
  AuthUserTypeEnum,
  EntityTypeEnum,
  FeatureAttributeEnum,
  RouteEnum,
  StorageKeyEnum,
  UserRoleEnum,
} from "../enums";
import { IAvailableOrganisation, IParsedSharedToken, IParsedToken } from "../interfaces";
import { CommonUtils, TokenUtils } from "../utils";

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  public userDataObservable$: Observable<IUserData>;

  public isApiAvailable = true;

  private userDataSubject: BehaviorSubject<IUserData>;

  constructor(
    private router: Router,
    private localStorageService: LocalStorageService,
    private commonService: CommonService,
    private featureFlagService: FeatureFlagService,
    private routerService: RouterService,
  ) {
    this.userDataSubject = new BehaviorSubject<IUserData>(null);
    this.userDataObservable$ = this.userDataSubject.asObservable();

    if (!this.isLocked()) {
      this.userDataSubject.next(
        this.localStorageService.get(StorageKeyEnum.USER_DATA)
          ? JSON.parse(this.localStorageService.get(StorageKeyEnum.USER_DATA))
          : null,
      );
    }
  }

  get canAddOrModifyRulesets(): boolean {
    return this.isAccountOwnerOrAdmin();
  }

  public isLocked = (): boolean => !this.getToken(StorageKeyEnum.ACCESS_TOKEN);

  public getUserId = (): string | null => this.userDataSubject.getValue()?.id ?? null;

  public getUserEmail = (): string | null => this.userDataSubject.getValue()?.email ?? null;

  public getUserType = (): AuthUserTypeEnum => this.userDataSubject.getValue()?.type ?? null;

  public isSystemAdminOrganisation = (): boolean =>
    this.getActiveOrganisation()?.isSystemAdmin || false;

  public isRegularUser = (): boolean => this.getUserType() === AuthUserTypeEnum.REGULAR;

  public isSharedUser = (): boolean => this.getUserType() === AuthUserTypeEnum.SHARED;

  public canAddModifyEntities = (): boolean => this.isAccountOwnerOrAdminOrContributor();

  public canGoToAdminSection = (): boolean => this.isAccountOwnerOrAdminOrContributor();

  public isAccountOwnerOrAdmin = (): boolean =>
    (this.isRegularUser() &&
      this.getActiveOrganisation()?.roles.some((role: UserRoleEnum) =>
        [UserRoleEnum.ACCOUNT_OWNER, UserRoleEnum.ADMIN].includes(role),
      )) ||
    false;

  public isAccountOwnerOrAdminOrContributor = (): boolean =>
    (this.isRegularUser() &&
      this.getActiveOrganisation()?.roles.some((role: UserRoleEnum) =>
        [UserRoleEnum.ACCOUNT_OWNER, UserRoleEnum.ADMIN, UserRoleEnum.CONTRIBUTOR].includes(role),
      )) ||
    false;

  public isReviewer = (): boolean =>
    (this.isRegularUser() &&
      this.getActiveOrganisation()?.roles.some((role: UserRoleEnum) =>
        [UserRoleEnum.REVIEWER].includes(role),
      )) ||
    false;

  public setIsApiAvailable = (isApiAvailable: boolean): void => {
    this.isApiAvailable = isApiAvailable;
  };

  public getIsApiAvailable = (): boolean => this.isApiAvailable;

  public setTokens = async (
    userType: AuthUserTypeEnum,
    accessToken: string,
    refreshToken: string = undefined,
  ): Promise<void> => {
    let newUserData: IUserData;
    const userData = this.userDataSubject.getValue();

    this.localStorageService.set(StorageKeyEnum.ACCESS_TOKEN, accessToken);
    switch (userType) {
      case AuthUserTypeEnum.REGULAR:
        {
          this.localStorageService.set(StorageKeyEnum.REFRESH_TOKEN, refreshToken);
          const parsedAccessToken = this.getParsedToken<IParsedToken>(StorageKeyEnum.ACCESS_TOKEN);

          newUserData = {
            id: parsedAccessToken.uid,
            type: userType,
            email: parsedAccessToken.un,
            tokenExpiration: parsedAccessToken.exp,
            tokenId: parsedAccessToken.jti,
            availableOrganisations: userData?.availableOrganisations ?? [],
            activeOrganisationIndex: userData?.activeOrganisationIndex ?? null,
          };
        }
        break;
      case AuthUserTypeEnum.SHARED:
        {
          const parsedSharedToken = this.getParsedToken<IParsedSharedToken>(
            StorageKeyEnum.ACCESS_TOKEN,
          );

          newUserData = {
            id: `SHARE_LINK_USER-${parsedSharedToken.un}`,
            type: userType,
            email: parsedSharedToken.un,
            tokenExpiration: parsedSharedToken.exp,
            tokenId: parsedSharedToken.jti,
            availableOrganisations: userData?.availableOrganisations ?? [
              { id: parsedSharedToken.oid },
            ],
            activeOrganisationIndex: 0,
          };
        }
        break;
    }
    this.userDataSubject.next(newUserData);
    this.localStorageService.set(StorageKeyEnum.USER_DATA, JSON.stringify(newUserData));
    await this.featureFlagService.setAttribute(FeatureAttributeEnum.USER_ID, newUserData?.id);
  };

  public addAvailableOrganisation = async (
    organisation: IAvailableOrganisation,
    isSetAsActive = false,
  ): Promise<void> => {
    const userData = this.userDataSubject.getValue();
    const doesOrgAlreadyExist = userData.availableOrganisations.some(
      (o) => o.id === organisation.id,
    );

    if (!doesOrgAlreadyExist) {
      const availableOrganisations = userData.availableOrganisations;

      availableOrganisations.unshift(organisation);
      const newUserData = {
        ...userData,
        availableOrganisations,
      };

      if (isSetAsActive) {
        newUserData.activeOrganisationIndex = 0;
        this.localStorageService.setUserLastActiveOrganisationId(userData.id, organisation.id);
      }
      this.userDataSubject.next(newUserData);
      this.localStorageService.set(StorageKeyEnum.USER_DATA, JSON.stringify(newUserData));
    } else {
      if (isSetAsActive) {
        const index = this.userDataSubject
          .getValue()
          .availableOrganisations.findIndex((o) => o.id === organisation.id);

        await this.setActiveOrganisationIndex(index);
      }
    }

    if (isSetAsActive) {
      const currentUrl = this.router.routerState.snapshot.url;
      const defaultRoute = this.getDefaultRoute();

      if (currentUrl === `/${defaultRoute}`) {
        location.reload();
      } else {
        await this.router.navigate([`/${defaultRoute}`]);
      }
    }
  };

  public removeAvailableOrganisation = async (organisationId: string): Promise<void> => {
    const userData = this.userDataSubject.getValue();
    const availableOrganisations = userData.availableOrganisations;
    const indexToRemove = availableOrganisations.findIndex((o) => o.id === organisationId);

    if (indexToRemove === -1) {
      return;
    }
    availableOrganisations.splice(indexToRemove, 1);
    const newUserData = { ...userData, availableOrganisations };

    this.userDataSubject.next(newUserData);
    this.localStorageService.set(StorageKeyEnum.USER_DATA, JSON.stringify(newUserData));
    if (organisationId === this.localStorageService.getUserLastActiveOrganisationId(userData.id)) {
      await this.setActiveOrganisationIndex(0);
    }
  };

  public setAvailableOrganisations = (availableOrganisations: IAvailableOrganisation[]): void => {
    const userData = this.userDataSubject.getValue();
    const newUserData = { ...userData, availableOrganisations };

    this.userDataSubject.next(newUserData);
    this.localStorageService.set(StorageKeyEnum.USER_DATA, JSON.stringify(newUserData));
  };

  public setActiveOrganisationIndex = async (activeOrganisationIndex: number): Promise<void> => {
    const userData = this.userDataSubject.getValue();
    const newUserData = { ...userData, activeOrganisationIndex };

    const activeOrganisation = newUserData.availableOrganisations[activeOrganisationIndex];

    await this.featureFlagService.setAttribute(
      FeatureAttributeEnum.ORGANISATION_ID,
      activeOrganisation?.id,
    );
    this.localStorageService.set(StorageKeyEnum.USER_DATA, JSON.stringify(newUserData));
    this.localStorageService.removeSavedTableItems();
    this.userDataSubject.next(newUserData);
    if (activeOrganisation?.id) {
      this.localStorageService.setUserLastActiveOrganisationId(userData.id, activeOrganisation.id);
    } else {
      this.localStorageService.removeUserLastActiveOrganisationId(userData.id);
    }
    this.routerService.clearHistory();
  };

  public setActiveOrganisationName = (name: string): void => {
    const userData = this.userDataSubject.getValue();
    const availableOrganisations = this.getAvailableOrganisations();
    const activeOrganisation = availableOrganisations[userData.activeOrganisationIndex];

    if (activeOrganisation) {
      activeOrganisation.name = name;
    }

    this.setAvailableOrganisations(availableOrganisations);
  };

  public getAvailableOrganisations = (): IAvailableOrganisation[] => {
    const userData = this.userDataSubject.getValue();

    return userData?.availableOrganisations;
  };

  public getActiveOrganisation = (): IAvailableOrganisation => {
    const userData = this.userDataSubject.getValue();

    if (!userData?.availableOrganisations?.length) {
      return null;
    }

    return userData.availableOrganisations[userData.activeOrganisationIndex];
  };

  public getActiveOrganisationId = (): string => this.getActiveOrganisation()?.id || null;

  public getToken = (token: StorageKeyEnum): string => this.localStorageService.get(token);

  public haveTokensExpired = (): boolean => {
    const now = Date.now() / 1000;

    switch (this.getUserType()) {
      case AuthUserTypeEnum.REGULAR: {
        const accessToken = this.getParsedToken<IParsedToken>(StorageKeyEnum.ACCESS_TOKEN);
        const refreshToken = this.getParsedToken<IParsedToken>(StorageKeyEnum.REFRESH_TOKEN);

        return !accessToken || !refreshToken || (now > refreshToken.exp && now > accessToken.exp);
      }
      case AuthUserTypeEnum.SHARED: {
        const sharedToken = this.getParsedToken<IParsedSharedToken>(StorageKeyEnum.ACCESS_TOKEN);

        return !sharedToken || now > sharedToken.exp;
      }
    }
  };

  public goToMainShareLink = async (): Promise<void> => {
    const shareLinkRoute = this.getMainShareLinkRoute();
    const fullUrl = `${window.location.origin}/${shareLinkRoute}`;
    const url = new URL(fullUrl);
    const shareType = url.searchParams.get("view");
    const shareRecordId = url.searchParams.get("id");

    await this.routerService.navigateByType(
      CommonUtils.pluraliseEntity(shareType) as EntityTypeEnum,
      shareRecordId,
    );
  };

  public getMainShareLinkRoute = (): string => {
    if (!this.isSharedUser()) {
      return null;
    }
    const sharedToken = this.getParsedToken<IParsedSharedToken>(StorageKeyEnum.ACCESS_TOKEN);
    const sharedUri = sharedToken.sr.replace(`/organisations/${sharedToken.oid}/`, "").split("/");
    const entityId = sharedUri[1];
    const entityType = sharedUri[0];

    return `${RouteEnum.SHARED_LINK}?view=${CommonUtils.singlifyEntity(entityType)}&id=${entityId}`;
  };

  public getDefaultRoute = (): string => {
    switch (this.getUserType()) {
      case AuthUserTypeEnum.REGULAR:
        return this.isSystemAdminOrganisation()
          ? RouteEnum.ADMIN_ORGANISATIONS
          : RouteEnum.ORGANISATIONS;
      case AuthUserTypeEnum.SHARED: {
        return RouteEnum.SHARED_LINK;
      }
      default: {
        //todo remove this fix when this logic has been deployed to PROD as no longer needed then.
        this.logout(false);

        return RouteEnum.LOGIN;
      }
    }
  };

  public logout = async (isRedirectToLogin = true): Promise<void> => {
    this.localStorageService.removeMany([
      StorageKeyEnum.ACCESS_TOKEN,
      StorageKeyEnum.REFRESH_TOKEN,
      StorageKeyEnum.USER_DATA,
    ]);

    // Clean up old/no longer used localStorage keys
    this.localStorageService.removeNoLongerUsedItems();
    // Clean up saved table localStorage keys as it can cause issues when new columns are added etc.
    this.localStorageService.removeSavedTableItems();

    this.userDataSubject.next(null);
    await this.featureFlagService.setAttribute(FeatureAttributeEnum.ORGANISATION_ID, undefined);
    await this.featureFlagService.setAttribute(FeatureAttributeEnum.USER_ID, undefined);

    if (this.isApiAvailable) {
      await this.commonService.loadCountries();
    }

    if (isRedirectToLogin) {
      await this.router.navigate([`/${RouteEnum.LOGIN}`]);
    }
  };

  private getParsedToken = <T>(token: StorageKeyEnum): T =>
    TokenUtils.parseJwt(this.localStorageService.get(token));
}
