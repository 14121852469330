<div
  *ngIf="userData?.availableOrganisations?.length || !this.acceptInvitationId"
  class="container-space-between"
>
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>
</div>
<app-invitations-table
  #invitationsTable
  (tryReject)="onTryReject($event)"
  (tryAccept)="onTryAccept($event)"
  [hidden]="!userData?.availableOrganisations?.length && this.acceptInvitationId"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-invitations-table>
