<div class="form-page-container">
  <div class="header-container">
    <app-page-title
      [title]="isEditing() ? translations.titleEdit : translations.titleAdd"
      [backText]="translations.titleBackText"
      [goBackRoute]="routingEnum.ADMIN_RULESETS"
    ></app-page-title>
  </div>
  <div class="element-content">
    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <app-ruleset-delivery-info
        #rulesetDeliveryInfo
        [formGroup]="formGroup"
        (openDocumentTypeDialog)="openDocumentTypeDialog()"
        [allCountriesOption]="allCountries()"
        [allLocations]="allLocations()"
        (newLocationTypeCreated)="reloadLocationTypes()"
        [deliveryFromCriteria]="deliveryFromCriteria()"
        [deliveryToCriteria]="deliveryToCriteria()"
        (deliveryFromCriteriaChanged)="
          deliveryFromCriteriaChanged($event); updateSelectedListsChangedState()
        "
        (deliveryToCriteriaChanged)="
          deliveryToCriteriaChanged($event); updateSelectedListsChangedState()
        "
      >
        <app-document-list-items
          [documents]="selectedDocumentTypes()"
          (remove)="removeDocumentType($event)"
        ></app-document-list-items>
      </app-ruleset-delivery-info>
    }
  </div>
  @if (!isLoading()) {
    <app-entity-save-footer
      (save)="onSave()"
      [isDisabled]="isSubmitButtonDisabled()"
    ></app-entity-save-footer>
  }
</div>
