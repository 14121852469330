<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title i18n>Accept connection request</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <p i18n>Would you like to accept this organisation’s connection request?</p>
      <p>
        Select
        <b>{{ data.publicOrganisation.name }}</b>
        from your organisations:
      </p>
      <app-input-select
        [options]="connectionsOptions"
        formControlName="connection"
        [label]="translations.connectionLabel"
      ></app-input-select>
      <div class="font-size-14">
        <ng-container i18n>Or</ng-container>
        <ng-container>&nbsp;</ng-container>
        <a class="blue-link" (click)="onAddNewConnection()" i18n>add a new organisation</a>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button
          accessibilityRole="submit"
          cdkFocusInitial
          [disabled]="formGroup.invalid || formGroup.pending"
        >
          <mat-icon icon-left>check_circle</mat-icon>
          <ng-container i18n>Accept</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
