import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  HostListener,
  ChangeDetectionStrategy,
  signal,
} from "@angular/core";
import { GoogleMap } from "@angular/google-maps";

import { GeoJsonTypeEnum } from "src/app/shared/enums";
import { CommonUtils, FileUtils, GeoJSONUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  public locationName: string = null;

  @Input()
  public geoLocation?: any = null;

  @Input()
  public height = "500px";

  @Input()
  public canDelete = false;

  @Input()
  public scrollWheelEnabled: boolean = false;

  @Output()
  public delete: EventEmitter<void> = new EventEmitter();

  @ViewChild(GoogleMap)
  public googleMap: GoogleMap;

  public isLoading = signal(true);

  public mapOptions: google.maps.MapOptions = null;

  public polygonOptions: google.maps.PolygonOptions = null;

  public markerOptions: google.maps.MarkerOptions = null;

  public type: GeoJsonTypeEnum = null;

  public readonly geoJsonTypeEnum = GeoJsonTypeEnum;

  private readonly markerIconUrl = "../../../assets/icons/geo-pinpoint.svg";

  public readonly COORDINATE_PRECISION = 8;

  private readonly DEFAULT_POLYGON_OPTIONS: google.maps.PolygonOptions = {
    strokeColor: "#38B5D0",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#00B2D9",
    fillOpacity: 0.25,
  };

  private bounds: google.maps.LatLngBounds;

  private fitBoundsTimes = 0;

  public polygonArea: number;

  public readonly translations: any = {
    zoomInTp: TextConstants.ZOOM_IN,
    zoomOutTp: TextConstants.ZOOM_OUT,
    removeTp: TextConstants.REMOVE,
    downloadTp: TextConstants.DOWNLOAD,
    copyCoorsTp: $localize`Copy coordinates`,
    polygonAreaTp: $localize`This calculation is based on the uploaded GEO data and may not match the official measurements`,
  };

  constructor(private notificationService: NotificationService) {}

  @HostListener("document:visibilitychange", ["$event"])
  appVisibility() {
    // This is the only way I found to "refresh" the map
    // after the user switches back to the browser tab containing
    // the map, as on that situation polygons would randonmly dissapear.
    this.toggleMapType();
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges["geoLocation"]) {
      this.loadMap();
    }
  }

  public ngAfterViewInit(): void {
    this.loadMap();
  }

  private loadMap = (): void => {
    if (!this.geoLocation || !this.googleMap?.data) {
      return;
    }

    this.isLoading.set(true);
    this.mapOptions = null;
    this.polygonOptions = null;
    this.markerOptions = null;
    this.type = null;
    this.bounds = null;
    this.fitBoundsTimes = 0;
    const bounds = new google.maps.LatLngBounds();

    this.mapOptions = {
      zoom: 12,
      mapTypeControl: true,
      streetViewControl: false,
      fullscreenControl: false,
      zoomControl: false,
      scrollwheel: this.scrollWheelEnabled,
      rotateControl: false,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_LEFT,
        mapTypeIds: ["satellite", "roadmap"],
        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      },
    };

    this.type = GeoJSONUtils.determineGeometryType(this.geoLocation);

    switch (this.type) {
      case GeoJsonTypeEnum.POINT: {
        const lat = this.geoLocation.features[0].geometry.coordinates[1];
        const lng = this.geoLocation.features[0].geometry.coordinates[0];

        this.mapOptions.center = { lat, lng };
        this.markerOptions = {
          draggable: false,
          position: { lat, lng },
          icon: {
            url: this.markerIconUrl,
          },
        };
        this.isLoading.set(false);
        break;
      }

      case GeoJsonTypeEnum.MULTI_POINT: {
        this.geoLocation.features.forEach((feature) => {
          const lat = feature.geometry.coordinates[1];
          const lng = feature.geometry.coordinates[0];

          new google.maps.Marker({
            position: { lat, lng },
            map: this.googleMap?.googleMap,
            icon: {
              url: this.markerIconUrl,
            },
          });

          bounds.extend({ lat, lng });
        });

        this.googleMap.fitBounds(bounds);
        this.isLoading.set(false);
        break;
      }
      case GeoJsonTypeEnum.POLYGON: {
        this.geoLocation.features.forEach((element) => {
          element.geometry.coordinates[0].forEach((c) => {
            const coordinates = { lat: c[1], lng: c[0] };

            bounds.extend(coordinates);
          });
        });
        this.calculatePolygonArea();
        this.googleMap.data.addGeoJson(this.geoLocation);
        this.googleMap.data.setStyle(() => this.DEFAULT_POLYGON_OPTIONS);
        this.bounds = bounds;
        break;
      }
      case GeoJsonTypeEnum.MULTI_POLYGON: {
        this.calculatePolygonArea();

        const features = this.geoLocation.features || [this.geoLocation];

        features.forEach((feature) => {
          if (feature.geometry.type === GeoJsonTypeEnum.POLYGON) {
            const polygonCoordinates: number[][][] = feature.geometry.coordinates;

            polygonCoordinates.forEach((coordinates) => {
              coordinates.forEach((c) => {
                const coordinate = {
                  lat: c[1],
                  lng: c[0],
                };

                bounds.extend(coordinate);
              });
            });
          } else if (feature.geometry.type === GeoJsonTypeEnum.MULTI_POLYGON) {
            const multiPolygonCoordinates: number[][][][] = feature.geometry.coordinates;

            multiPolygonCoordinates.forEach((polygon) => {
              polygon.forEach((coordinates) => {
                coordinates.forEach((c) => {
                  const coordinate = {
                    lat: c[1],
                    lng: c[0],
                  };

                  bounds.extend(coordinate);
                });
              });
            });
          }
        });

        this.googleMap.data.addGeoJson(this.geoLocation);
        this.googleMap.data.setStyle(() => this.DEFAULT_POLYGON_OPTIONS);
        this.bounds = bounds;
        break;
      }
    }
  };

  calculatePolygonArea() {
    let polygonArea = 0;

    this.geoLocation.features.forEach((feature) => {
      const area = GeoJSONUtils.geometry(feature.geometry);

      polygonArea += area / 10000;
    });
    this.polygonArea = polygonArea;
  }

  public onSetZoom = (zoomModifier: number): void => {
    this.googleMap.googleMap.setZoom(this.googleMap.googleMap.getZoom() + zoomModifier);
  };

  public onDownload = (): void => {
    let coordinates: any[] = [];

    switch (this.type) {
      case GeoJsonTypeEnum.POINT:
        coordinates = [
          this.getFormattedCoordinateProperty(this.mapOptions.center.lng),
          this.getFormattedCoordinateProperty(this.mapOptions.center.lat),
        ];
        break;
      case GeoJsonTypeEnum.POLYGON:
      case GeoJsonTypeEnum.MULTI_POLYGON:
        coordinates =
          this.geoLocation?.geometry?.coordinates ||
          this.geoLocation.features[0]?.geometry?.coordinates;
        break;
    }

    const geoJsonObject = GeoJSONUtils.getObject(this.type, coordinates);
    const name = `${this.locationName ? this.locationName.trim().replaceAll(" ", "_") : "Location"}_geojson.json`;

    FileUtils.downloadJsonFile(geoJsonObject, name);
    this.notificationService.showSuccess($localize`Downloading geojson...`);
  };

  public getFormattedCoordinateProperty = (property: number | (() => number)): string =>
    (property as number).toFixed(this.COORDINATE_PRECISION);

  public onCopyCoordinates = (): void => {
    CommonUtils.textToClipboard(
      `${this.getFormattedCoordinateProperty(this.mapOptions.center.lat)},${this.getFormattedCoordinateProperty(this.mapOptions.center.lng)}`,
    );
    this.notificationService.showSuccess(TextConstants.COPIED);
  };

  public onDelete = (): void => {
    if (this.canDelete) {
      this.delete.emit();
    }
  };

  public onMapIdle = () => {
    if (this.bounds && this.isLoading()) {
      this.googleMap.fitBounds(this.bounds, 10);
      this.fitBoundsTimes++;
      this.isLoading.set(this.fitBoundsTimes < 2);
    }
  };

  private toggleMapType = (): void => {
    if (this.googleMap.googleMap.getMapTypeId() === "roadmap") {
      this.googleMap.googleMap.setMapTypeId("satellite");
    } else {
      this.googleMap.googleMap.setMapTypeId("roadmap");
    }
  };

  public ngOnDestroy(): void {
    if (this.googleMap) {
      this.googleMap.ngOnDestroy();
    }
  }
}
