import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Input,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

import { ColDef } from "ag-grid-community";
import {
  AcceptInboundShareErrorEnum,
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  CrossOrgShareDataTypeEnum,
  InboundShareStatusEnum,
  RouteEnum,
  TableEnum,
  TagsColorEnum,
} from "src/app/shared/enums";

import { ConfirmDialogComponent, InfoDialogComponent } from "@components/shared";
import { LinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  ICheckExistenceRecord,
  IInboundMapping,
  IInboundShare,
  IOrganisation,
} from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import {
  NotificationService,
  ConnectionsService,
  RecordSharingService,
  ProductsService,
  AuthenticationService,
  MaterialsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";
import { InboundSharedRecordUtils } from "@shared/utils/inboud-shared-record.utils";

@Component({
  standalone: false,
  selector: "app-inbox-table",
  templateUrl: "./inbox-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxTableComponent implements AfterViewInit {
  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public readonly table = TableEnum.INBOX;

  public isLoading = signal(true);

  public rowData = signal<any[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  public columns = input<string[]>([
    "status",
    "sender",
    "recordType",
    "recordName",
    "receivedOn",
    "newExistingTags",
    "selectCheckbox",
  ]);

  private allInboundShares: IInboundShare[] = [];

  public isUpdatesTable = input<boolean>(false);

  public existingMappings = signal<IInboundMapping[]>([]);

  private senderOrganisations: IOrganisation[] = [];

  private readonly productsService = inject(ProductsService);

  private readonly materialsService = inject(MaterialsService);

  private readonly authenticationService = inject(AuthenticationService);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  constructor(
    private recordSharingService: RecordSharingService,
    private notificationService: NotificationService,
    private connectionsService: ConnectionsService,
    private routerService: RouterService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "recordName",
      actions: new Map([
        [
          BatchActionTypeEnum.DELETE_INBOUND_SHARE,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DELETE_INBOUND_SHARE,
            this.recordSharingService,
          ),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      {
        headerName: $localize`New`,
        field: "status",
        cellRenderer: (cell) => {
          return CellRendererUtils.showIconIfValue(
            cell,
            "fiber_manual_record",
            "red filled-icon",
            "",
            (cell) => cell.value === InboundShareStatusEnum.NEW,
          );
        },
        ...ColumnUtils.iconColumnCommonValues,
        tooltipValueGetter: (params: any) =>
          params.value === InboundShareStatusEnum.NEW ? "New" : "",
      },
      {
        headerName: $localize`Sender`,
        field: "sender",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "senderId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      { headerName: $localize`Record type`, field: "recordType" },
      {
        headerName: $localize`ID / Name`,
        field: "recordName",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          isLinkStyle: true,
          actions: this.isUpdatesTable()
            ? [
                {
                  icon: "check_mark",
                  tooltip: $localize`Mark the update as viewed indicating that you have updated your record based on the received update or chose to ignore the update`,
                  click: this.markUpdateAsViewed,
                },
              ]
            : [
                {
                  icon: "delete",
                  tooltip: TextConstants.DELETE,
                  click: this.onDelete,
                  show: (row) => row.canDelete,
                },
                {
                  icon: "sync_alt",
                  tooltip: $localize`Map`,
                  click: this.onMap,
                  show: (row) => row.canMap,
                },
                {
                  icon: "arrow_forward",
                  tooltip: TextConstants.ACCEPT,
                  click: this.onAccept,
                  show: (row) => row.canAccept,
                },
              ],
        },
      },
      ColumnUtils.dateColumn({
        headerName: $localize`Received on`,
        field: "receivedOn",
        cellRendererParams: {
          dateFormat: CommonConstants.DATE_TIME_FORMAT,
        },
        sort: "desc",
      }),
      {
        headerName: $localize`Mapped record`,
        field: "mappedRecordName",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: (params) => {
          const type: CrossOrgShareDataTypeEnum = params?.data?.recordType;

          return InboundSharedRecordUtils.getLocalRouteConfig(
            params?.data?.mappedRecordId,
            CommonUtils.pluraliseEntity(type.toLowerCase()) as CrossOrgShareDataTypeEnum,
            this.routerService,
          );
        },
      },
      {
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
      ColumnUtils.tags($localize`New / Existing records`, "newExistingTags"),
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }
    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());

    if (!this.isUpdatesTable()) {
      columnDefs.push({
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      });
    }

    this.columnDefs.set(columnDefs);
  };

  public onViewOnOverlay = async (row: any): Promise<void> => {
    if (row.status === InboundShareStatusEnum.NEW) {
      if (!this.isUpdatesTable()) {
        //update the NEW badge count on the "Inbox" left side menu
        await this.recordSharingService.setInboundShareStatus(
          { status: InboundShareStatusEnum.VIEWED },
          row.id,
        );
        //we don't await for these requests, they run in the background while the overlay is opening
        this.recordSharingService.getAllInboundShares();
        this.getAll();
      }
    }

    const routerConfig = InboundSharedRecordUtils.getSharedRouteConfig(
      row.recordId,
      row.crossOrgShareDataType,
      this.routerService,
      row.senderId,
    );

    if (routerConfig) {
      await this.routerService.navigate(routerConfig.linkRouteFn());
    }
  };

  private onDelete = (row: any): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Delete shared data`,
        contentText: $localize`Are you sure that you want to delete this shared data?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.delete(row.id);
      }
    });
  };

  private onMap = async (row: any): Promise<void> => {
    await this.routerService.navigate(`${RouteEnum.INBOX_TRANSFER_OR_MAP}/${row.id}`);
  };

  private onAccept = (row: any): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Accept data`,
        contentText: $localize`Are you sure that you want to accept this shared data?`,
        confirmButtonText: TextConstants.ACCEPT,
        confirmButtonIcon: "check",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.accept(row.id);
      }
    });
  };

  private markUpdateAsViewed = async (row: any): Promise<void> => {
    try {
      await this.recordSharingService.setInboundShareStatus(
        { status: InboundShareStatusEnum.VIEWED },
        row.id,
        true,
      );
      this.notificationService.showSuccess($localize`Update marked as viewed`);
      await this.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private async delete(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this.recordSharingService.deleteInboundShare(id);
      this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
      await this.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async accept(id: string): Promise<void> {
    this.isLoading.set(true);
    try {
      await this._accept(id);
    } catch (error) {
      await this.handleAcceptError(error, id);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async _accept(id: string) {
    await this.recordSharingService.acceptInboundShare(id);
    this.notificationService.showSuccess($localize`Shared data accepted`);
    await this.getAll();
  }

  private handleAcceptError = async (error: any, recordId: string): Promise<void> => {
    if (error.status === 422 && error.error?.errors?.length) {
      let contentSafeHTML: string = "";

      const alreadyExistsErrors = error.error.errors.filter(
        (e) => e.code === AcceptInboundShareErrorEnum.SHARED_ENTITY_ALREADY_EXISTS,
      );

      if (alreadyExistsErrors.length) {
        let errorsListHtml = "";

        for (const errorObj of alreadyExistsErrors) {
          const recordType = errorObj.arguments.uri.split("/")[3];
          const formattedRecordType = CommonUtils.capitaliseFirstLetter(
            CommonUtils.singlifyEntity(recordType),
          ).replace("-", " ");

          errorsListHtml += `<li><b>${formattedRecordType}:</b> ${errorObj.arguments.id}</li>`;
        }

        contentSafeHTML += `<p>These records already exist locally and have not yet been mapped:</p><ul class="text-left">${errorsListHtml}</ul>`;
      }

      const addArchivedErrors = error.error.errors.filter(
        (e) => e.code === AcceptInboundShareErrorEnum.ADD_ARCHIVED_ENTITY_FORBIDDEN,
      );

      if (addArchivedErrors.length) {
        let errorsListHtml = "";

        for (const errorObj of addArchivedErrors) {
          // The archived element here is the active organisation element, not the inbound record.
          // To get the name we'd have to switch case the entity_name and use the related service to get the record name
          // We are not implementing that just yet as there is a high chance we will not allow records to be mapped to
          // archived elements so this error should not happen. For now we just display the archived element ID
          const recordName = errorObj.arguments.id;

          errorsListHtml += `<li><b>${errorObj.arguments.entity_name}:</b> ${recordName}</li>`;
        }

        contentSafeHTML += `<p>These records cannot be added because they are archived:</p><ul class="text-left">${errorsListHtml}</ul>`;
      }

      // Handling INVALID_PRODUCT_MATERIAL errors
      const invalidProductMaterialErrors = error.error.errors.filter(
        (e) => e.code === "INVALID_PRODUCT_MATERIAL",
      );

      if (invalidProductMaterialErrors.length) {
        // Group errors by product
        const groupedErrors = invalidProductMaterialErrors.reduce((acc, errorObj) => {
          const productId = errorObj.arguments.other_id;
          const productName = errorObj.arguments.other_entity_name;
          const materialId = errorObj.arguments.id;
          const materialName = errorObj.arguments.entity_name;

          if (!acc[productId]) {
            acc[productId] = {
              productName,
              materials: [],
            };
          }
          acc[productId].materials.push({ materialId, materialName });

          return acc;
        }, {});

        let groupedErrorsHtml = "";

        for (const productId in groupedErrors) {
          const { productName, materials } = groupedErrors[productId];
          let materialsListHtml = "";

          for (const { materialName } of materials) {
            materialsListHtml += `<li>${materialName}</li>`;
          }

          groupedErrorsHtml += `<p>The following materials are not part of the allowed materials of <b>${productName}</b> product:</p>`;
          groupedErrorsHtml += `<ul class="text-left">${materialsListHtml}</ul>`;
        }

        contentSafeHTML += groupedErrorsHtml;

        this.dialog
          .open(ConfirmDialogComponent, {
            data: {
              title: $localize`Accept error`,
              contentHTML: contentSafeHTML,
              confirmButtonText: $localize`Add & proceed`,
            },
          })
          .afterClosed()
          .subscribe(async (result) => {
            if (result === ConfirmDialogResponseEnum.CONFIRM) {
              try {
                // Add materials for each product
                for (const productId in groupedErrors) {
                  const { materials } = groupedErrors[productId];

                  await this.addMaterialsToProductAndProceed(productId, materials, recordId);
                }
              } catch {
                this.notificationService.showError($localize`Failed to add materials to products`);
              }
            }
          });

        return;
      }

      const otherErrors = error.error.errors.filter(
        (e) =>
          ![
            AcceptInboundShareErrorEnum.SHARED_ENTITY_ALREADY_EXISTS,
            AcceptInboundShareErrorEnum.ADD_ARCHIVED_ENTITY_FORBIDDEN,
            AcceptInboundShareErrorEnum.INVALID_PRODUCT_MATERIAL,
          ].includes(e.code),
      );

      for (const otherError of otherErrors) {
        contentSafeHTML += `<p>${CommonUtils.enumToText(otherError)}</p>`;
      }

      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          title: $localize`Can’t accept share`,
          contentSafeHTML: this.sanitizer.bypassSecurityTrustHtml(contentSafeHTML),
        },
      });
    } else {
      this.notificationService.showError(error);
    }
  };

  private getParsedRowData = async (inboundShare: IInboundShare): Promise<any> => {
    const mainRecordUri = this.isUpdatesTable()
      ? inboundShare.recordUri
      : inboundShare.rootRecordUri;
    const crossOrgShareDataType = InboundSharedRecordUtils.getSharedDataType(mainRecordUri);

    const senderId = CommonUtils.getUriId(inboundShare.senderUri);
    const recordId = CommonUtils.getUriId(mainRecordUri);

    const sender: IOrganisation = this.senderOrganisations.find((org) => org.id === senderId);
    let record: any = undefined;
    let checkExistenceRecords: ICheckExistenceRecord = undefined;
    let mappedRecordName: string = null;
    let mappedRecordId: string = null;

    if (this.isUpdatesTable() && this.existingMappings().length) {
      const localUri = this.existingMappings()?.find(
        (m) => m.inboundUri === mainRecordUri,
      )?.localUri;

      const mappedRecord = (await this.getMappedRecord(localUri)) as any;

      mappedRecordName =
        mappedRecord?.name ||
        mappedRecord.deliveryId ||
        mappedRecord.processId ||
        mappedRecord.itemId ||
        mappedRecord.type ||
        mappedRecord.number;

      mappedRecordId = mappedRecord?.id;
    }

    const promises = [
      (record = await InboundSharedRecordUtils.getSharedRecord(
        senderId,
        crossOrgShareDataType,
        recordId,
        this.recordSharingService,
      )),
    ];

    if (!this.isUpdatesTable()) {
      promises.push(
        (checkExistenceRecords =
          await this.recordSharingService.getCheckExistingRecord(mainRecordUri)),
      );
    }

    await Promise.all(promises);

    const newExistingTags = [];

    if (checkExistenceRecords?.newRecords?.length > 0) {
      newExistingTags.push({
        title: `${$localize`New`} (${checkExistenceRecords.newRecords.length})`,
        color: TagsColorEnum.BLUE,
      });
    }
    if (checkExistenceRecords?.existingRecordsInfo?.length > 0) {
      newExistingTags.push({
        title: `${$localize`Existing`} (${checkExistenceRecords.existingRecordsInfo.length})`,
        color: TagsColorEnum.ORANGE,
      });
    }

    const recordType = CommonUtils.singlifyEntity(
      CommonUtils.capitaliseFirstLetter(mainRecordUri.split("/")[7]),
    );
    const status = inboundShare.status;

    let recordName: string;

    switch (crossOrgShareDataType) {
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        recordName = record.deliveryId;
        break;
      case CrossOrgShareDataTypeEnum.PROCESSES:
        recordName = record.processId;
        break;
      case CrossOrgShareDataTypeEnum.ITEMS:
        recordName = record.itemId;
        break;
      case CrossOrgShareDataTypeEnum.LOCATION_TYPES:
        recordName = record.type;
        break;
      case CrossOrgShareDataTypeEnum.CERTIFICATES:
        recordName = record.number;
        break;
      default:
        recordName = record.name;
        break;
    }

    return {
      id: inboundShare.id,
      senderId,
      sender: sender.name,
      recordType,
      crossOrgShareDataType,
      recordId,
      recordName,
      receivedOn: inboundShare.receivedOn,
      status,
      newExistingTags,
      canDelete: true,
      canMap: true,
      mappedRecordName,
      mappedRecordId,
      canAccept: status !== InboundShareStatusEnum.ACCEPTED,
    };
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    try {
      this.allInboundShares = !this.isUpdatesTable()
        ? await this.recordSharingService.getAllInboundShares()
        : await this.recordSharingService.getInboundRecords();

      if (this.isUpdatesTable()) {
        const existingMappings = await this.recordSharingService.getAllMappings();

        this.existingMappings.set(existingMappings);
      }

      const organisationsUris = Array.from(
        new Set(this.allInboundShares.map((share) => share.senderUri)),
      );

      const senderPromises = organisationsUris.map(async (uri) => {
        const sender = await this.connectionsService.get(CommonUtils.getUriId(uri));

        this.senderOrganisations.push(sender);
      });

      await Promise.all(senderPromises);

      const rowData = [];
      const rowPromises = this.allInboundShares.map(async (inboundShare) => {
        rowData.push(await this.getParsedRowData(inboundShare));
      });

      await Promise.all(rowPromises);

      this.rowData.set(rowData);
      this.isLoading.set(false);
    } catch (error) {
      this.isLoading.set(false);
      this.notificationService.showError(error);
    }
  };

  async addMaterialsToProductAndProceed(
    productId: string,
    materials: { materialId: string; materialName: string }[],
    recordId: string,
  ): Promise<void> {
    const product = await this.productsService.get(productId);
    const inboundShare = this.allInboundShares.find((share) => share.id === recordId);
    const inboundMaterialsUris = inboundShare.recordUris.filter((uri) => uri.includes("materials"));

    const inboundMaterials = await this.recordSharingService.getInboundMaterialsByIdsGraphQL(
      inboundMaterialsUris.map((materialUri) => CommonUtils.getUriId(materialUri)),
      CommonUtils.getUriId(inboundShare.senderUri),
    );
    const materialsNames = materials.map((material) => material.materialName);
    const inboundMaterial = inboundMaterials.find((im) => materialsNames.includes(im.name));

    if (!inboundMaterial) {
      throw new Error($localize`Inbound material not found for conflict resolution`);
    }

    // Create a new material based on the inbound material
    const newMaterial = await this.materialsService.createOrUpdate({
      name: inboundMaterial.name,
      category: inboundMaterial.category,
      id: null,
    });

    const inboundRecordUri = inboundMaterialsUris.find((uri) => uri.includes(inboundMaterial.id));
    const materialUri = `/organisations/${this.authenticationService.getActiveOrganisationId()}/materials/${newMaterial.id}`;

    await this.recordSharingService.addInboundMapping(inboundRecordUri, materialUri);

    const updatedAllowedMaterials = [materialUri, ...product.allowedMaterials];
    const updatedProduct = { ...product, allowedMaterials: updatedAllowedMaterials };

    delete updatedProduct.recordState;

    await this.productsService.createOrUpdate(updatedProduct, product.id);

    // Proceed with accepting the record
    await this._accept(recordId);
  }

  async getMappedRecord<T>(localRecordId: string): Promise<T> {
    return await this.recordSharingService.getLocalRecordByUri<T>(localRecordId?.slice(1));
  }
}
