@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <article class="field-group-container">
    @if (shouldIncludeLocationsLinks && !locationIds?.length) {
      <div class="container-flex-center">
        <h4 i18n>Please attach products to location links to view the supply chain.</h4>
      </div>
    } @else {
      @if (fullScreenOptionEnabled) {
        <div class="supply-chain-buttons">
          <app-button (pressed)="fullScreen()" [tp]="translations.fullScreenTp" type="outlined">
            <mat-icon>open_in_full</mat-icon>
          </app-button>
        </div>
      }
      <div
        class="flow-chart-container"
        [style.height]="height + 'px'"
        [style.cursor]="isZoomEnabled ? 'move' : 'unset'"
        [ngClass]="containerClass"
      >
        <svg class="svg-content" [id]="svgId">
          <g>
            @if (hasError) {
              <text
                x="50%"
                y="50%"
                dominant-baseline="middle"
                text-anchor="middle"
                fill="black"
                i18n
              >
                Could not load the supply chain.
              </text>
            }
          </g>
        </svg>
      </div>
    }
  </article>

  <app-slide-overlay-wrapper [close]="!isSlideOverVisible()" (clickClose)="closeSlideOver()">
    <div class="full-width">
      <div class="slide-over-content full-height">
        <div class="container-space-between">
          <app-page-title [title]="slideOverTitle"></app-page-title>
        </div>
        @if (selectedCertificates?.length) {
          <app-certificates-table
            [certificates]="selectedCertificates"
            [shouldOpenInNewTab]="true"
          ></app-certificates-table>
        } @else if (selectedDocuments?.length || missingDocumentTypes?.length) {
          @if (missingDocumentTypes?.length && !isLoadingMissingData()) {
            <p class="dark-gray bold container-flex">
              <ng-container i18n>Missing documents</ng-container>
              <app-indicator [value]="missingDocumentTypes.length"></app-indicator>
            </p>
            <app-document-types-table
              [documentTypes]="missingDocumentTypes"
              [isSearchEnabled]="false"
              [areButtonsEnabled]="false"
              [columns]="['type', 'rulesetsNames']"
            ></app-document-types-table>
          }
          <p *ngIf="missingDocumentTypes?.length" class="dark-gray bold container-flex">
            <ng-container i18n>Attached documents</ng-container>

            <app-indicator [value]="selectedDocuments.length"></app-indicator>
          </p>
          <app-documents-table
            [documents]="selectedDocuments"
            [shouldOpenInNewTab]="true"
          ></app-documents-table>
        }
      </div>
    </div>
  </app-slide-overlay-wrapper>
}
