<div class="container-space-between">
  <app-page-title [title]="translations.title"></app-page-title>

  <app-button-group [visible]="canAddModifyEntities">
    <app-more-actions-buttons
      (clickLabel)="onAdd()"
      [isArchiveEnabled]="false"
      [label]="translations.addNewLabel"
      labelIcon="Add"
    >
      <app-dropdown-menu-item (pressed)="onOpenBulkAddItemsSlideOver()">
        <mat-icon>library_add</mat-icon>
        <ng-container i18n>Bulk add items</ng-container>
      </app-dropdown-menu-item>
    </app-more-actions-buttons>
  </app-button-group>
</div>

<app-items-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
></app-items-table>
