<app-loader *ngIf="isLoading()"></app-loader>
<div *ngIf="!isLoading()" class="full-width-items-container">
  @for (documentDetails of documents; let index = $index; track $index) {
    @if (isGroupingByType) {
      @if (shouldDisplayType(documentDetails.typeId, index)) {
        <h4>
          {{ documentDetails.type }}
          @if (typeToolTips[documentDetails.typeId]) {
            <mat-icon class="info-icon" [tp]="typeToolTips[documentDetails.typeId]">help</mat-icon>
          }
        </h4>
      }
    }

    @if (documentDetails?.isMissing) {
      <p class="text-center" i18n>Missing document(s)</p>
    } @else {
      <div class="item hover-visibility-container">
        <app-document-content-card
          [document]="documentDetails"
          [type]="contentCardTypeEnum.REMOVE"
          (remove)="onRemove(documentDetails.id)"
        />
      </div>
    }
  }
</div>
