import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from "@angular/material/menu";

import { IAvailableOrganisation, IUserData } from "../../../../shared/interfaces";
import { OrganisationService } from "../../../../shared/services/organisation.service";

@Component({
  standalone: false,
  selector: "app-sidebar-dropdown",
  templateUrl: "./sidebar-dropdown.component.html",
  styleUrl: "./sidebar-dropdown.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: "organisation-menu-overlay",
      },
    },
  ],
})
export class SidebarDropdownComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input() currentUrl: string;

  @Input() isSidebarCollapsed: boolean;

  @Input() currentOrganisation: IAvailableOrganisation;

  @Input() userData: IUserData;

  hasNoTopBorder: boolean = false;

  sortedOrganisations = computed(() => {
    return [...this.userData.availableOrganisations].sort((a, b) => a.name.localeCompare(b.name));
  });

  constructor(private organisationService: OrganisationService) {}

  updateDropdownState(): void {
    this.hasNoTopBorder = this.trigger.menuOpen && !this.isSidebarCollapsed;
  }

  changeOrganisation = async (id: string): Promise<void> => {
    const index = this.userData.availableOrganisations.findIndex(
      (organisation) => organisation.id === id,
    );

    await this.organisationService.changeOrganisation(index, this.userData, this.currentUrl);
  };

  createOrganisation(): void {
    this.organisationService.createOrganisation();
  }
}
