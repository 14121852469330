import { inject, signal } from "@angular/core";

import { ResourceTypeEnum } from "@shared/enums";
import {
  IItem,
  ICertificate,
  IDocument,
  IRecordResponse,
  IDocumentType,
  IProcessExtended,
} from "@shared/interfaces";
import { RulesetsService, AuthenticationService, DocumentTypesService } from "@shared/services";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { SetNodeTooltipClass } from "./set-node-tooltip.class";

export abstract class SupplyChainWithOverlaysClass extends SetNodeTooltipClass {
  protected rulesetsService = inject(RulesetsService);

  protected authenticationService = inject(AuthenticationService);

  protected documentTypesService = inject(DocumentTypesService);

  protected allDocumentTypes: IDocumentType[];

  public isSlideOverVisible = signal(false);

  public selectedProcesses: IProcessExtended[];

  public selectedItems: IItem[];

  public selectedCertificates: ICertificate[];

  public selectedDocuments: IDocument[];

  public slideOverTitle: string;

  public missingDocumentTypes = [];

  public isLoadingMissingData = signal(true);

  constructor() {
    super();
  }

  protected closeSlideOver() {
    this.slideOverTitle = null;
    this.selectedItems = null;
    this.selectedCertificates = null;
    this.selectedDocuments = null;
    this.selectedProcesses = null;
    this.missingDocumentTypes = null;
    this.isSlideOverVisible.set(false);
  }

  protected setSelectedProcesses(processes: IProcessExtended[], locationName: string) {
    this.selectedProcesses = processes;
    this.slideOverTitle = `${locationName} ${$localize`processes`}`;
    this.openSlideOver();
  }

  protected setSelectedCertificates(certificates: ICertificate[], locationName: string) {
    this.selectedCertificates = certificates;
    this.slideOverTitle = `${locationName} ${$localize`certificates`}`;
    this.openSlideOver();
  }

  protected async getRulesetRecords(
    entitiesIds: string[],
    resourceType: ResourceTypeEnum,
  ): Promise<IRecordResponse[]> {
    const route = RulesetUtils.getResourceRoute(resourceType);
    const uniqueIds = new Set(entitiesIds);
    const organisationId = this.authenticationService.getActiveOrganisationId();
    const records = [...uniqueIds].map((id) => `/organisations/${organisationId}/${route}/${id}`);

    return await this.rulesetsService.getRulesetBasedOnRecord({
      resourceType,
      records,
    });
  }

  protected setMissingDocuments(record: IRecordResponse): void {
    if (!record) {
      return;
    }
    this.isLoadingMissingData.set(true);
    this.missingDocumentTypes = RulesetUtils.getDocumentTypesWithRulesets(
      record,
      false,
      this.allDocumentTypes,
    );
    this.isLoadingMissingData.set(false);
  }

  protected setSelectedDocuments(documents: IDocument[], name: string) {
    this.selectedDocuments = documents;
    this.slideOverTitle = `${name} ${$localize`documents`}`;
    this.openSlideOver();
  }

  protected setSelectedItems(items: IItem[], deliveryId: string) {
    this.selectedItems = items;
    this.slideOverTitle = `${deliveryId} ${$localize`items`}`;
    this.openSlideOver();
  }

  protected openSlideOver() {
    this.isSlideOverVisible.set(true);
  }
}
