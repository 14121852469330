<div class="container-flex-space-between">
  <div class="left-content">
    <div class="name" [tp]="name" tpPlacement="top-start" [tpOffset]="[-25, 0]">{{ name }}</div>
    <div class="number" [tp]="certificate.number" tpPlacement="top-start" [tpOffset]="[-25, 0]">
      {{ certificate.number }}
    </div>
    <div class="validity">
      <ng-container i18n>Validity</ng-container>
      : {{ certificate.validFromDate | appDate }} -
      {{ certificate.validToDate | appDate }}
    </div>
  </div>
  <app-button-group>
    @if (type === "add") {
      <app-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </app-button>
    } @else {
      <app-button
        (pressed)="onClickRemove()"
        [tp]="translations.removeTp"
        size="medium"
        type="icon"
      >
        <mat-icon icon-medium-18>close</mat-icon>
      </app-button>
    }
  </app-button-group>
</div>
