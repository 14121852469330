<div [hidden]="overlayHidden()" class="slide-over">
  <div (click)="dismiss()" (keyup.escape)="dismiss()" class="overlay" tabindex="0"></div>
  <div [@slideOver]="contentHidden() ? 'closed' : 'open'" class="slide-content">
    <app-button (click)="dismiss()" class="slide-button-close" type="icon">
      <mat-icon>close</mat-icon>
    </app-button>
    @if (!isClosingOverlay && isShowGoBackButton) {
      <app-button (click)="onBack()" class="slide-button-back">
        <mat-icon class="icon-20">arrow_back</mat-icon>
        <ng-container i18n>Go back</ng-container>
      </app-button>
    }
    <div class="slide-form-container">
      <ng-template #dynamicContent appBackNavigation [canExitFn]="canExit.bind(this)"></ng-template>
    </div>
  </div>
</div>
