import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { ResourceTypeEnum, RouteEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  templateUrl: "./rulesets.component.html",
  styleUrl: "./rulesets.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetsComponent {
  public readonly userCanAddOrModifyRulesets = this.authenticationService.canAddOrModifyRulesets;

  public readonly resourceTypeEnum = ResourceTypeEnum;

  public readonly routingEnum = RouteEnum;

  public selectedTabIndex = 0;

  public readonly translations = {
    title: $localize`Rulesets`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) {
    const selectedTabResourceType =
      this.router.getCurrentNavigation()?.extras?.state?.["selectedTabResourceType"];

    if (selectedTabResourceType) {
      switch (selectedTabResourceType) {
        case this.resourceTypeEnum.LOCATION:
          this.selectedTabIndex = 0;
          break;
        case this.resourceTypeEnum.DELIVERY:
          this.selectedTabIndex = 1;
          break;
      }
    }
  }

  onAdd = async (): Promise<void> => {
    switch (this.selectedTabIndex) {
      case 0:
        await this.router.navigateByUrl(`${RouteEnum.ADMIN_RULESETS_ADD_LOCATION}`);
        break;
      case 1:
        await this.router.navigateByUrl(`${RouteEnum.ADMIN_RULESETS_ADD_DELIVERY}`);
        break;
    }
  };
}
