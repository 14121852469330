<div [ngClass]="areExpandButtonsEnabled() ? 'flex gap-col-20' : ''">
  @if (isSearchEnabled) {
    <app-search-and-filters
      (recordStateChanged)="onRecordStateChanged($event)"
      (searchChanged)="onSearchChanged($event)"
      [isRecordStateFilterEnabled]="isRecordStateFilterEnabled"
      [recordState]="recordState"
      [searchText]="searchText"
    ></app-search-and-filters>
  }

  @if (grid?.api && (areExpandButtonsEnabled() || moreOptionsTemplate())) {
    <div class="flex gap-col-12">
      @if (areExpandButtonsEnabled()) {
        <app-button type="ghost" (pressed)="expandAll()">
          <mat-icon icon-left>unfold_more</mat-icon>
          <ng-container i18n>Expand all</ng-container>
        </app-button>
        <app-button type="ghost" (pressed)="collapseAll()">
          <mat-icon icon-left>unfold_less</mat-icon>
          <ng-container i18n>Collapse all</ng-container>
        </app-button>
      }
      @if (moreOptionsTemplate()) {
        <ng-container
          [ngTemplateOutlet]="moreOptionsTemplate()"
          [ngTemplateOutletContext]="{
            $implicit: grid,
          }"
        ></ng-container>
      }
    </div>
  }
</div>

<ng-content select="[tag-filters]"></ng-content>

@if (isLoading() || isBatchInProgress()) {
  <app-loader></app-loader>
} @else {
  @if (!rowData()?.length) {
    <app-empty-search-results></app-empty-search-results>
  } @else {
    <ag-grid-angular
      #grid
      class="grid ag-theme-material"
      [components]="components"
      [class]="class"
      [class.no-displayed-rows]="grid?.api?.getDisplayedRowCount() === 0"
      [ngClass]="grid?.api?.getDisplayedRowCount() === 0 ? 'no-rows' : 'has-rows'"
      [domLayout]="domLayout"
      [style.height]="heightStyle"
      [initialState]="initialState"
      [rowData]="rowData()"
      [rowGroupPanelShow]="'never'"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [masterDetail]="masterDetail"
      [detailCellRendererParams]="detailCellRendererParams"
      [detailCellRenderer]="detailCellRenderer"
      [getRowClass]="getRowClass"
      [getRowHeight]="getRowHeight"
      [pagination]="isPaginatorEnabled"
      [suppressPaginationPanel]="!isPaginatorEnabled || isFixedBottomPaginator"
      [paginationPageSizeSelector]="tableAvailablePageSizes"
      [paginationPageSize]="grid?.api?.paginationGetPageSize() ?? initialPageSize"
      [rowClassRules]="rowClassRules"
      [tooltipShowDelay]="100"
      [tooltipHideDelay]="3000"
      [quickFilterText]="searchText"
      [suppressContextMenu]="true"
      [suppressCellFocus]="true"
      [overlayNoRowsTemplate]="overlayNoRowsTemplate"
      [localeText]="localeText"
      [suppressRowClickSelection]="true"
      [suppressRowDeselection]="true"
      [rowSelection]="'multiple'"
      [popupParent]="popupParent"
      [groupSelectsChildren]="true"
      [groupSelectsFiltered]="true"
      (gridReady)="onGridReady()"
      (gridSizeChanged)="onGridSizeChanged()"
      (cellClicked)="onCellClicked($event)"
      (stateUpdated)="onStateUpdated($event)"
      (filterChanged)="onFilterChanged()"
      (selectionChanged)="onSelectionChanged($event)"
      (firstDataRendered)="onFirstDataRendered()"
      (rowDataUpdated)="onRowDataUpdated()"
      (paginationChanged)="onPaginationChanged()"
      (columnRowGroupChanged)="onColumnRowGroupChanged()"
      (sortChanged)="onSortChanged()"
    ></ag-grid-angular>
    @if (!!grid?.api) {
      @if (isFixedBottomPaginator) {
        <app-table-footer
          #gridFooter
          [grid]="grid"
          [tableAvailablePageSizes]="tableAvailablePageSizes"
        ></app-table-footer>
      }
      @if (isBatchActionsVisible()) {
        <app-table-batch-actions
          #gridBatchActions
          [grid]="grid"
          [batchActionSettings]="batchActionSettings"
          (clearSelectedRows)="onClearSelectedRows()"
          (batchActionStarted)="isBatchInProgress.set(true)"
        ></app-table-batch-actions>
      }
    }
  }
}
