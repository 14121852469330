import { ChangeDetectionStrategy, Component } from "@angular/core";

import { AuthenticationService } from "src/app/shared/services";

@Component({
  standalone: false,
  selector: "app-not-shared-data",
  templateUrl: "./not-shared-data.component.html",
  styleUrls: ["./not-shared-data.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotSharedDataComponent {
  public readonly isVisible = this.authenticationService.isSharedUser();

  constructor(private authenticationService: AuthenticationService) {}

  public readonly translations: any = {
    noSharedDataTp: $localize`The owner of this share did not expose this information to you.`,
  };
}
