<div class="slide-over-content">
  <div class="container-space-between">
    <app-page-title [title]="title"></app-page-title>
  </div>

  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <div class="right-side-panel-container" cdkDropListGroup>
      <div class="content" [formGroup]="formGroup">
        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ mainInformationText }}</mat-panel-title>
            </mat-expansion-panel-header>
            <h5 class="date-title">
              @if (type === "input") {
                <ng-container i18n>Date added</ng-container>
              } @else {
                <ng-container i18n>Date produced</ng-container>
              }
              <mat-icon
                class="info-icon"
                [tp]="
                  type === 'input'
                    ? 'Specify the exact date or a period when this input has been added'
                    : 'Specify the exact date or a period when this output has been produced'
                "
              >
                help
              </mat-icon>
            </h5>

            <app-radio-button-group>
              <app-radio-button
                (changeEvent)="onChangeDatesType(dateTypeEnum.EXACT)"
                [checked]="datesType === 'EXACT'"
                value="EXACT"
                [label]="translations.exactDate"
              ></app-radio-button>
              &nbsp;
              <app-radio-button
                (changeEvent)="onChangeDatesType(dateTypeEnum.RANGE)"
                [checked]="datesType === 'RANGE'"
                value="RANGE"
                [label]="translations.rangeDate"
              ></app-radio-button>
            </app-radio-button-group>
            <br />
            <br />
            <div class="row-container">
              <div class="row row-1">
                <div class="item">
                  @if (datesType === dateTypeEnum.EXACT) {
                    <app-datepicker #datePicker formControlName="date"></app-datepicker>
                  }

                  @if (datesType === dateTypeEnum.RANGE) {
                    <app-daterangepicker
                      class="no-padding-top-input"
                      #datePicker
                      formControlName="rangeDate"
                    ></app-daterangepicker>
                  }
                </div>
              </div>
              <div class="row row-1">
                <app-input [label]="translations.noteLabel" formControlName="note"></app-input>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title i18n>Quantity summary</mat-panel-title>
            </mat-expansion-panel-header>

            <div *ngIf="!selectedItems()?.length" class="text-center empty-quantity-summary">
              Once you add {{ type }}s to this process, a summary of their quantities will show up
              here.
            </div>
            <app-quantity-summary-table
              #summaryTable
              *ngIf="selectedItems()?.length"
              class="no-min-height"
              [selectedItems]="selectedItems()"
              [allProducts]="allProducts"
              [allMaterials]="allMaterials"
              [allUnitOfMeasurements]="allUnitOfMeasurements"
              [isPaginatorEnabled]="false"
            ></app-quantity-summary-table>
          </mat-expansion-panel>
        </mat-accordion>

        <mat-accordion>
          <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
            <mat-expansion-panel-header>
              <mat-panel-title i18n>Items</mat-panel-title>
            </mat-expansion-panel-header>

            <app-drag-drop-container
              [itemType]="'items'"
              (itemDropped)="onItemDropped($event)"
            ></app-drag-drop-container>
            <app-add-process-selected-items
              [type]="type"
              [processLocation]="processLocation"
              [items]="selectedItems()"
              [allProducts]="allProducts"
              [allMaterials]="allMaterials"
              [allUnitOfMeasurements]="allUnitOfMeasurements"
              (selectedQuantityChanged)="onSelectedQuantityChanged($event)"
              (remove)="onRemove($event)"
            ></app-add-process-selected-items>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <app-right-side-panel
        itemType="Item"
        itemTypePlural="Items"
        [isLoadingItems]="isLoadingItems()"
        [availableItems]="availableItems()"
        [addMenu]="type === 'input' ? menu : null"
        (pressAdd)="onAddNewItem()"
        (pressAddAll)="onAddAll()"
        (pressRefresh)="onReloadItems()"
        (searchEvent)="onSearchAvailable($event)"
      >
        <div
          class="items-container"
          cdkDropList
          [cdkDropListSortingDisabled]="true"
          [cdkDropListData]="availableItems()"
        >
          @for (item of availableItems(); track item.id) {
            <app-draggable-card (pressed)="onAdd(item.id)" [item]="item">
              <app-item-card-content [item]="item"></app-item-card-content>
            </app-draggable-card>
          }
        </div>
      </app-right-side-panel>
    </div>
  }
</div>
<div class="slide-over-buttons">
  <app-button-group [visible]="!isLoading()">
    <app-button (pressed)="onCancel()" type="ghost" i18n>Cancel</app-button>
    <app-button (pressed)="onSave()">
      <mat-icon icon-left>check</mat-icon>
      <ng-container i18n>Save</ng-container>
    </app-button>
  </app-button-group>
</div>

<mat-menu #menu class="dropdown-menu">
  <app-dropdown-menu>
    <app-dropdown-menu-item (pressed)="onAddNewItem()">
      <mat-icon>package_2</mat-icon>
      <ng-container i18n>Add one item</ng-container>
    </app-dropdown-menu-item>
    <app-dropdown-menu-item (pressed)="onOpenBulkAddItemsSlideOver()">
      <mat-icon>library_add</mat-icon>
      <ng-container i18n>Add multiple items</ng-container>
    </app-dropdown-menu-item>
  </app-dropdown-menu>
</mat-menu>
