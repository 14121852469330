export enum FeatureFlagEnum {
  EUDR_REPORT = "eudr-report",
  NEW_MATERIALS_BEHAVIOUR = "new-materials-behaviour",
  OVERLAY_NAVIGATION_EXPANSION = "overlay-navigation-expansion",
  CROSS_ORGANISATION_SHARING = "cross-organisation-sharing",
  CROSS_ORGANISATION_SHARING_SUPPLY_CHAIN = "cross-organisation-sharing-supply-chain",
  CROSS_ORGANISATION_SHARING_PROCESS = "cross-organisation-sharing-process",
  CROSS_ORGANISATION_SHARING_LOCATION = "cross-organisation-sharing-location",
  CROSS_ORGANISATION_SHARING_DELIVERY = "cross-organisation-sharing-delivery",
  LANGUAGES = "languages",
  GROUPED_SIDE_MENU = "grouped-side-menu",
  DELIVERY_SUPPLY_CHAIN_DOCUMENTS = "delivery-supply-chain-documents",
}
