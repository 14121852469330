@if (enableViewMode && overlay.viewMode()) {
  @if (viewModeTemplate) {
    <ng-container
      [ngTemplateOutlet]="viewModeTemplate"
      [ngTemplateOutletContext]="{
        $implicit: selectedTags,
        label,
        searchText,
        loading: loadingOptionTags,
      }"
    ></ng-container>
  } @else {
    <app-form-input-readonly
      [tags]="selectedTags"
      [isTagClickable]="isTagClickable"
      (tagPressed)="onTagClick($event)"
      [label]="label"
      [loading]="loadingOptionTags"
    ></app-form-input-readonly>
  }
} @else {
  <ng-container [ngTemplateOutlet]="editView"></ng-container>
}

<ng-template #editView>
  <div
    class="form-field-item"
    [class.invalid]="hasValidationError"
    [class.no-padding]="inputOnlyMode"
    [ngClass]="class ?? ''"
  >
    @if (!inputOnlyMode) {
      <app-form-label [label]="label" [tooltip]="tooltip">
        <ng-container action>
          <ng-content select="[action]"></ng-content>
        </ng-container>
      </app-form-label>
    }
    <div
      #origin="matAutocompleteOrigin"
      [ngClass]="{ disabled: isDisabled() || disabled }"
      class="form-field-tags"
      [class.red-border]="hasValidationError"
      matAutocompleteOrigin
    >
      @if (prefixTemplate && hasSelectedTags) {
        <ng-container
          [ngTemplateOutlet]="prefixTemplate"
          [ngTemplateOutletContext]="{
            $implicit: selectedTags,
            label,
            searchText,
            loading: loadingOptionTags,
          }"
        ></ng-container>
      }
      <mat-chip-grid #chipGrid>
        @for (tag of selectedTags; track tag) {
          @if (!tag.temporary) {
            <mat-chip-row class="tag-chip" [removable]="true" (removed)="removeTag(tag)">
              <div class="container-flex">
                @if (tag.icon) {
                  <mat-icon class="tag-icon" [tp]="tag.iconTooltip ?? ''">{{ tag.icon }}</mat-icon>
                }
                <div
                  class="text"
                  [tp]="tag.label.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? tag.label : ''"
                  [tpIsLazy]="true"
                >
                  {{ tag.label }}
                </div>

                @if (!disabled) {
                  <mat-icon matChipRemove>cancel</mat-icon>
                }
              </div>
            </mat-chip-row>
          }
        }

        <input
          #autoInput
          (focus)="onInputFocus()"
          (keyup)="onInputKeyUp($event)"
          [disabled]="isDisabled() || disabled"
          [matAutocompleteConnectedTo]="origin"
          [matAutocomplete]="auto"
          [placeholder]="placeholder || label"
          [value]="searchText"
          appAutocompletePositionUpdate
          matInput
          [matChipInputFor]="chipGrid"
          type="text"
        />
      </mat-chip-grid>

      @if (hasValidationError) {
        <mat-icon class="suffix-icon error-icon" svgIcon="action_pending" matSuffix></mat-icon>
      }

      <mat-icon
        (click)="onToggleDropdown()"
        [ngClass]="{ disabled: isDisabled() || disabled }"
        class="suffix-icon"
        matSuffix
      >
        {{ dropdownIndicatorIcon }}
      </mat-icon>
    </div>

    <mat-autocomplete
      #auto="matAutocomplete"
      (closed)="onAutocompleteClose()"
      (optionSelected)="onAutocompleteOptionSelected($event.option.value)"
      [autoActiveFirstOption]="!allowCreateTag"
      [autoSelectActiveOption]="!allowCreateTag"
      [displayWith]="getDisplayLabel"
      [hideSingleSelectionIndicator]="true"
      class="dropdown-input-menu"
    >
      @for (option of filteredOptions; track option.label) {
        <mat-option [value]="option">
          @if (prefixTemplate) {
            <ng-container
              [ngTemplateOutlet]="prefixTemplate"
              [ngTemplateOutletContext]="{ $implicit: option, label, searchText }"
            ></ng-container>
          }
          @if (option.icon) {
            <mat-icon class="dropdown-option-icon" [tp]="option.iconTooltip ?? ''">
              {{ option.icon }}
            </mat-icon>
          }
          {{ option.label }}
        </mat-option>
      }
    </mat-autocomplete>

    @if (!inputOnlyMode) {
      <app-form-hint
        [errors]="ngControl.errors"
        [hint]="hint"
        [isOptional]="isOptional()"
        [isDisabled]="disabled || isDisabled()"
        [showError]="hasValidationError"
      ></app-form-hint>
    }
  </div>
</ng-template>
