import { TextConstants } from "@shared/constants";

import { CommonUtils } from "./common.utils";
import { ResourceTypeEnum, TargetPathEnum } from "../enums";
import {
  ICondition,
  IDocumentType,
  IExpectation,
  IExpectationGroup,
  IRecordResponse,
  IRuleset,
} from "../interfaces";

const getExpectationsGroups = (rulesets: IRuleset[]): IExpectationGroup[] => {
  const expectationGroupsMap: { [key: string]: IExpectationGroup } = {};

  rulesets.forEach((ruleset) => {
    ruleset.expectations?.forEach((expectation) => {
      const { path, condition, conditionsMet } = expectation;
      const key = `${path}_${conditionsMet}`;

      if (!expectationGroupsMap[key]) {
        expectationGroupsMap[key] = {
          path,
          conditions: [],
          conditionsMet,
        };
      }

      expectationGroupsMap[key].conditions.push(condition);
    });
  });

  return Object.values(expectationGroupsMap) as IExpectationGroup[];
};

const extractValueByPath = (
  condition: ICondition,
  targetPath: string,
): string[] | undefined | string => {
  if (!condition) {
    return undefined;
  }
  for (const conditionObject of condition.and) {
    if ("any" in conditionObject && conditionObject.any.path === targetPath) {
      return conditionObject.any.value;
    } else if ("eq" in conditionObject && conditionObject.eq.path === targetPath) {
      return conditionObject.eq.value;
    }
  }

  return undefined;
};

const getDocumentTypesWithRulesets = (
  record: IRecordResponse,
  conditionsMet: boolean,
  allDocumentTypes: IDocumentType[],
): IDocumentType[] => {
  const filteredRulesets = filterRulesetsByConditionsMet(record, conditionsMet);
  const allTypeIds = filteredRulesets
    .map((r) => r.typeUris)
    .flat()
    .map((uri) => CommonUtils.getUriId(uri))
    .filter((value, index, self) => self.findIndex((v) => v === value) === index);

  return allTypeIds.map((id) => {
    const rulesetsNames = filteredRulesets.reduce((acc, s) => {
      if (s.typeUris.some((u) => u.includes(id))) {
        acc.push(s.name);
      }

      return acc;
    }, []);

    return {
      ...allDocumentTypes.find((t) => t.id === id),
      rulesetsNames,
    };
  });
};

const filterRulesetsByConditionsMet = (
  record: IRecordResponse,
  conditionsMet: boolean,
): { name: string; expectations: IExpectation[]; typeUris: string[] }[] => {
  return record.rulesets
    .filter((ruleset) =>
      ruleset.expectations.some((expectation) => expectation.conditionsMet === conditionsMet),
    )
    .map((r) => {
      const filteredExpectations = r.expectations.filter(
        (expectation) => expectation.conditionsMet === conditionsMet,
      );

      return {
        name: r.name,
        expectations: filteredExpectations,
        typeUris: filteredExpectations
          .map((e) => RulesetUtils.extractValueByPath(e.condition, TargetPathEnum.TYPE))
          .flat(),
      };
    });
};

const tagExistsInCondition = (condition: ICondition, targetPath: string): boolean => {
  if (!condition) {
    return undefined;
  }
  for (const conditionObject of condition.and) {
    if ("any" in conditionObject && conditionObject.any.path === targetPath) {
      return true;
    } else if ("eq" in conditionObject && conditionObject.eq.path === targetPath) {
      return true;
    }
  }

  return false;
};

const getResourceRoute = (resourceType: ResourceTypeEnum): string => {
  switch (resourceType) {
    case ResourceTypeEnum.LOCATION:
      return "locations";
    case ResourceTypeEnum.DELIVERY:
      return "deliveries";
    default:
      throw new Error(TextConstants.UNSUPPORTED_RESOURCE_TYPE);
  }
};

export const RulesetUtils = {
  getExpectationsGroups,
  extractValueByPath,
  filterRulesetsByConditionsMet,
  getDocumentTypesWithRulesets,
  tagExistsInCondition,
  getResourceRoute,
};
