@if (fixedFields.length) {
  <div class="fixed-fields">
    <mat-icon>lock</mat-icon>
    @for (field of fixedFields; track field.label) {
      <div class="fixed-field">
        <div class="label">
          {{ field.label }}
        </div>
        <div>
          {{ field.value }}
        </div>
      </div>
    }
  </div>
}

@if (columnDefs().length) {
  <app-table
    #table
    class="bulk-add-records-table"
    [isPaginatorEnabled]="false"
    [getGridHeightFn]="getGridHeightFn"
    [isSearchEnabled]="false"
    [isLoading]="false"
    [columnDefs]="columnDefs()"
    [rowData]="rowData"
  ></app-table>
}

@if (formGroup.controls[fieldEnum.RECORDS].controls.length < maxRecordsCount) {
  <div class="add-records" [formGroup]="recordsQuantityFormGroup">
    <span
      class="label blue-link add-record-button mt-12"
      [class.disabled]="
        !recordsQuantityFormGroup.controls['quantity'].value ||
        recordsQuantityFormGroup.invalid ||
        recordsQuantityFormGroup.pending
      "
      (click)="buildRecords()"
      (keyup)="onKeyUpAddRecords($event)"
      tabindex="0"
    >
      +
      <ng-container i18n>Add</ng-container>
    </span>
    <app-input
      hideOptionalHint
      type="number"
      formControlName="quantity"
      formInputClass="reduced-width"
      customErrorClass="reduced-width"
    ></app-input>
    <span class="mt-12" i18n>item(s)</span>
  </div>
}
