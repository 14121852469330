@if (option()) {
  @if (link()) {
    <app-form-link [link]="link()" [styledLink]="true">
      <ng-container [ngTemplateOutlet]="countryTemplate"></ng-container>
    </app-form-link>
  } @else {
    <ng-container [ngTemplateOutlet]="countryTemplate"></ng-container>
  }
  <ng-template #countryTemplate>
    <app-flag [country]="option().value.toString()"></app-flag>
    {{ option().label }}
  </ng-template>
} @else {
  -
}
