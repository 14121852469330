@if (enableViewMode && overlay.viewMode()) {
  <app-form-input-readonly
    [value]="(textValues[0] | appDate) + ' - ' + (textValues[1] | appDate)"
    [label]="label"
  ></app-form-input-readonly>
} @else {
  <ng-container [ngTemplateOutlet]="editView"></ng-container>
}

<ng-template #editView>
  <div [class]="class" [ngClass]="{ invalid: hasValidationError, 'form-field-item': true }">
    @if (label) {
      <div class="label-container">
        <app-form-label [label]="label"></app-form-label>
      </div>
    }

    <div class="input-container with-datepicker-toggle">
      <mat-date-range-input
        #dateRangeInput
        [class.disabled]="isDisabled()"
        [rangePicker]="picker"
        class="datepicker-range-inputs"
      >
        <input
          #dateInputStart
          (dateInput)="handleChange(dateRangeValueTypeEnum.FROM, $event)"
          (input)="handleChange(dateRangeValueTypeEnum.FROM, $event)"
          [disabled]="isDisabled()"
          [max]="maxDateValue"
          [min]="minDateValue"
          [value]="textValues[0]"
          matStartDate
          [placeholder]="datePickerDateFormat"
        />
        <input
          #dateInputEnd
          (dateInput)="handleChange(dateRangeValueTypeEnum.TO, $event)"
          (input)="handleChange(dateRangeValueTypeEnum.TO, $event)"
          [disabled]="isDisabled()"
          [max]="maxDateValue"
          [min]="minDateValue"
          [value]="textValues[1]"
          matEndDate
          [placeholder]="datePickerDateFormat"
        />
      </mat-date-range-input>
      <mat-icon
        (click)="onInputClear()"
        class="suffix-icon clear-icon"
        [hidden]="shouldHideClearButton"
        matSuffix
        [tp]="translations.clearTp"
      >
        cancel
      </mat-icon>

      @if (!isDisabled()) {
        <button class="datepicker-toggle clickable" (click)="handleCalendarClick($event)">
          <mat-icon>calendar_month</mat-icon>
        </button>
      }
    </div>

    <mat-date-range-picker
      #picker
      [calendarHeaderComponent]="datepickerHeader"
      panelClass="design-makeover"
    ></mat-date-range-picker>

    <app-form-hint
      [errors]="ngControl.errors"
      [isDisabled]="isDisabled()"
      [isOptional]="isOptional()"
      [showError]="hasValidationError"
    ></app-form-hint>
  </div>
</ng-template>
