import { ChangeDetectionStrategy, Component } from "@angular/core";

import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";

@Component({
  standalone: false,
  templateUrl: "./user-settings-profile.component.html",
  styleUrls: ["./user-settings-profile.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsProfileComponent {
  //@ViewChild("languageSelector") languageSelector: LanguageSelectorComponent;

  // private readonly notificationService = inject(NotificationService);

  // private readonly cdr = inject(ChangeDetectorRef);

  public readonly routingEnum = RouteEnum;

  //public isEditing = signal(false);

  //public isLoading = signal(true);

  public readonly translations = {
    title: $localize`Profile`,
    titleBackText: TextConstants.BACK_TO_USER_SETTINGS,
    language: $localize`Language`,
  };

  constructor() {
    //this.setupForm();
    //this.isLoading.set(false);
  }

  // public toggleEdit = (): void => {
  //   this.isEditing.set(!this.isEditing());

  //   if (this.isEditing()) {
  //     this.formGroup().enable();
  //   } else {
  //     this.formGroup().disable();
  //   }
  // };

  // public onCancel = (): void => {
  //   this.isLoading.set(true);
  //   this.cdr.detectChanges();
  //   this.setupForm();
  //   this.toggleEdit();
  //   this.isLoading.set(false);
  // };

  // public onSubmit = async (): Promise<void> => {
  //   if (!this.isEditing()) {
  //     return;
  //   }
  //   this.toggleEdit();

  //   this.notificationService.showSuccess($localize`Saved`);

  //   setTimeout(async () => {
  //     await this.languageSelector.changeLanguage();
  //     // Delay added on purpose so the user has enough time to see the "Saved" notification
  //     // Before the browser redirects to the new lang URL
  //   }, 150);
  // };
}
