<mat-radio-button
  [disabled]="disabled"
  [disableRipple]="true"
  (change)="onRadioChange($event)"
  [checked]="checked"
  [value]="value"
  [name]="name"
>
  {{ label }}
</mat-radio-button>
