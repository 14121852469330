import { Injectable, inject } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { CommonConstants } from "@shared/constants";
import { ApiService, AuthenticationService } from "@shared/services";
import { GraphService } from "@shared/services/api/graph.service";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import {
  Country,
  CountryByIdsGraphQLResponse,
  CountryFieldsToInclude,
  CountryGraphQLFilter,
  CountryGraphQLResponse,
  CountryParams,
  CountryPayload,
  PageableCountries,
} from "../models";
import { getCountriesByIdsGraphQLQuery, getCountriesGraphQLQuery } from "../queries";

@Injectable({ providedIn: "root" })
export class CountriesApiService {
  private readonly api = inject(ApiService);

  private readonly graph = inject(GraphService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/countries`;

  async getAllGraphQL(
    filter: CountryGraphQLFilter = null,
    first = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: CountryFieldsToInclude[],
  ): Promise<Country[]> {
    const result: Country[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;

    const orgId = this.authService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getCountriesGraphQLQuery(orgId, filter, first, null, cursor, null, include);

      const { content, pageInfo, totalCount } = await this.graph.fetchSinglePage<
        CountryGraphQLResponse,
        Country
      >("loadCountries", query);

      result.push(...content);
      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: CountryFieldsToInclude[] = [],
  ): Promise<Country[]> {
    if (!ids.length) {
      return [];
    }

    const result: Country[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const orgId = this.authService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getCountriesByIdsGraphQLQuery(orgId, ids, first, null, cursor, null, include);
      const { content, pageInfo, totalCount } = await this.graph.fetchSinglePage<
        CountryByIdsGraphQLResponse,
        Country
      >("loadCountriesByIds", query);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  getAll(params: CountryParams) {
    const query = FormUtils.addUrlParams(params);

    return lastValueFrom(this.api.get<PageableCountries>(`${this.getBaseUrl()}?${query}`));
  }

  get(id: string) {
    return lastValueFrom(this.api.get<Country>(`${this.getBaseUrl()}/${id}`));
  }

  create(payload: CountryPayload) {
    return lastValueFrom(this.api.post<Country>(this.getBaseUrl(), payload));
  }

  delete(id: string) {
    return lastValueFrom(this.api.delete<void>(`${this.getBaseUrl()}/${id}`));
  }
}
