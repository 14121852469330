<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add new product</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input
        [autofocus]="true"
        formControlName="name"
        [label]="translations.nameLabel"
      ></app-input>

      <app-input-select
        [label]="translations.baseUnitLabel"
        formControlName="baseUnit"
        [options]="unitOfMeasurementTypesOptions"
      ></app-input-select>

      <app-input-chips
        [options]="materialOptions"
        formControlName="existingMaterial"
        [label]="translations.existingMaterialLabel"
      ></app-input-chips>
      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [elementCustomFields]="[]"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost">Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Add</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
