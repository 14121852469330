import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";

import { Subscription } from "rxjs";

import { DeliveryOverlayService } from "@components/deliveries/pages/delivery-overlay/delivery-overlay.service";
import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { AttachmentTargetEnum, AttachmentTypeEnum, RouteEnum } from "@shared/enums";
import {
  IBaseUnit,
  IConnectionExtended,
  IDelivery,
  IDeliveryDetails,
  IDeliveryExtended,
  IDocument,
  IDocumentType,
  IItem,
  IItemSupplyChain,
  ILocationDetails,
  ILocationExtended,
  IMaterial,
  IProcessType,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  CommonService,
  ConnectionsService,
  DeliveriesService,
  DocumentTypesService,
  ItemsService,
  LocationsService,
  ProcessTypesService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./delivery-report.component.html",
  styleUrls: ["./delivery-report.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportComponent implements OnInit, OnDestroy {
  private route: ActivatedRoute = inject(ActivatedRoute);

  private notificationService: NotificationService = inject(NotificationService);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  private locationsService: LocationsService = inject(LocationsService);

  private itemsService: ItemsService = inject(ItemsService);

  private connectionsService: ConnectionsService = inject(ConnectionsService);

  private documentTypesService: DocumentTypesService = inject(DocumentTypesService);

  private routerService: RouterService = inject(RouterService);

  private itemSupplyChainMapperService: ItemSupplyChainMapperService = inject(
    ItemSupplyChainMapperService,
  );

  private commonService: CommonService = inject(CommonService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private deliveryOverlayService: DeliveryOverlayService = inject(DeliveryOverlayService);

  private slideOverlayPageService: SlideOverlayPageService = inject(SlideOverlayPageService);

  private processTypesService: ProcessTypesService = inject(ProcessTypesService);

  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  public delivery: IDelivery;

  public customDeliveryFieldsValues: { label: string; value: string }[] = [];

  public sender: ILocationExtended;

  public receiver: ILocationExtended;

  public allProcessTypes: IProcessType[] = [];

  public traders: IConnectionExtended[] = [];

  public documentTypes: IDocumentType[] = [];

  public isLoading = signal<boolean>(true);

  public isLoadingSupplyChainInfo = signal<boolean>(true);

  public supplyChainItems: IItemSupplyChain[];

  public documents: IDocument[] = [];

  public supplyChainLocations: ILocationExtended[] = [];

  public supplyChainDeliveries: IDeliveryExtended[] = [];

  public items: IItem[] = [];

  public deliveryItemIds: string[] = [];

  public deliveryDetails: IDeliveryDetails;

  protected subscriptions = new Subscription();

  public readonly additionalInformationText = TextConstants.ADDITIONAL_INFORMATION;

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  public readonly isRegularUser = this.authenticationService.isRegularUser();

  private readonly isSharedUser = this.authenticationService.isSharedUser();

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;

  public readonly supplyChainHeight = CommonUtils.getOverlaySupplyChainHeight();

  public readonly translations: any = {
    title: $localize`Delivery report`,
  };

  constructor() {
    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.deliveryReportService.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );

    this.subscriptions.add(
      this.slideOverlayPageService.trigger$.subscribe(async (params) => {
        if (params?.hasSaved) {
          this.isLoading.set(true);
          const deliveryId = this.route.snapshot.params["id"];

          await this.loadData(deliveryId);
          this.isLoading.set(false);
        }
      }),
    );

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.deliveryReportService.countryOptions.set(countriesOptions),
        ),
    );
  }

  public async ngOnInit(): Promise<void> {
    const deliveryId = this.route.snapshot.params["id"];

    if (!deliveryId) {
      this.handleDeliveryNotFoundError();

      return;
    }

    try {
      await this.loadData(deliveryId);
      this.isLoading.set(false);
      this.loadAdditionalData();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get allProducts(): IProductExtended[] {
    return this.deliveryReportService.allProducts;
  }

  public get allUnitOfMeasurements(): IBaseUnit[] {
    return this.deliveryReportService.allUnitOfMeasurements;
  }

  public get allMaterials(): IMaterial[] {
    return this.deliveryReportService.allMaterials;
  }

  private async loadData(deliveryId: string): Promise<void> {
    try {
      this.delivery = await this.deliveriesService.get(deliveryId);
    } catch {
      this.handleDeliveryNotFoundError();

      return;
    }

    await Promise.all([
      this.loadCustomFields(),
      this.loadTraders(),
      this.loadLocations(),
      this.loadProducts(),
      this.loadMaterials(),
      this.loadItems(),
      this.loadDocumentTypes(),
    ]);
  }

  private async loadAdditionalData(): Promise<void> {
    this.loadSupplyChainInfo();
  }

  private async loadSupplyChainInfo(): Promise<void> {
    await Promise.all([this.loadProcessTypes(), this.setDeliveryDetailsForMap(this.delivery)]);

    this.deliveryItemIds = this.delivery.items.map((item) => CommonUtils.getUriId(item.item));
    this.supplyChainItems = await this.itemSupplyChainMapperService.mapItemSupplyChain(
      this.deliveryItemIds,
    );

    this.supplyChainLocations = this.supplyChainItems
      .flatMap((item) => item.locations)
      .filter((location, index, self) => index === self.findIndex((l) => l.id === location.id));

    const supplyChainDeliveryIds = this.supplyChainItems
      .flatMap((item) => item.deliveries)
      .filter(
        (delivery, index, self) =>
          delivery.id !== this.delivery.id && index === self.findIndex((d) => d.id === delivery.id),
      )
      .map((delivery) => delivery.id);

    this.supplyChainDeliveries = await this.deliveriesService.getByIdsGraphQL(
      supplyChainDeliveryIds,
      undefined,
      ["ITEMS", "CERTIFICATES_WITH_DOCUMENTS", "DOCUMENTS", "CUSTOM_FIELDS"],
    );

    this.isLoadingSupplyChainInfo.set(false);
  }

  private async loadCustomFields(): Promise<void> {
    await this.deliveryReportService.loadAllCustomDeliveryFields();

    this.customDeliveryFieldsValues = this.deliveryReportService.getCustomFieldValuesForDelivery(
      this.delivery,
    );
  }

  private async loadTraders(): Promise<void> {
    const traderIds = this.delivery.agents.map((agentUri) => CommonUtils.getUriId(agentUri));

    this.traders = await this.connectionsService.getByIdsGraphQL(traderIds, undefined, [
      "FULL_ADDRESS",
      "CERTIFICATES",
      "CUSTOM_FIELDS",
    ]);
  }

  private async loadLocations(): Promise<void> {
    const fromLocationId = CommonUtils.getUriId(this.delivery.from);
    const toLocationId = CommonUtils.getUriId(this.delivery.to);

    const locations = await this.locationsService.getByIdsGraphQL(
      [fromLocationId, toLocationId],
      undefined,
      ["CERTIFICATES", "CUSTOM_FIELDS", "FULL_ADDRESS"],
    );

    this.sender = locations[0].id === fromLocationId ? locations[0] : locations[1];
    this.receiver = locations[0].id === fromLocationId ? locations[1] : locations[0];
  }

  private async loadProducts(): Promise<void> {
    await this.deliveryReportService.loadProducts();
  }

  private async loadDocumentTypes(): Promise<void> {
    const [deliveryDocuments, allDocumentTypes] = await Promise.all([
      this.deliveryOverlayService.loadSelectedAttachments(
        AttachmentTypeEnum.DOCUMENT,
        AttachmentTargetEnum.DELIVERY,
        this.delivery.id,
      ) as Promise<IDocument[]>,
      this.documentTypesService.getAll(),
    ]);

    const missingDocumentTypes = await this.deliveryOverlayService.allMissingDocumentTypesByRuleSet(
      AttachmentTargetEnum.DELIVERY,
      this.delivery.id,
      allDocumentTypes,
    );

    const deliveryDocumentTypes: IDocumentType[] = [];

    for (const documentType of allDocumentTypes) {
      const documents = deliveryDocuments.filter(
        (document) => CommonUtils.getUriId(document.type) === documentType.id,
      );

      if (!documents.length) {
        continue;
      }

      deliveryDocumentTypes.push({ ...documentType, documents });
    }

    this.documentTypes = [...deliveryDocumentTypes, ...missingDocumentTypes];
  }

  private async loadMaterials(): Promise<void> {
    this.deliveryReportService.loadMaterials();
  }

  private async loadItems(): Promise<void> {
    const itemIds = this.delivery.items.map((item) => CommonUtils.getUriId(item.item));

    const itemsExtended = await this.itemsService.getByIdsGraphQL(itemIds);

    this.items = itemsExtended.map((item) => {
      const deliveredQuantity = this.delivery.items.find(
        (deliveryItem) => CommonUtils.getUriId(deliveryItem.item) === item.id,
      )?.quantity;

      return CommonUtils.convertExtendedItemToItem(
        { ...item, deliveredQuantity },
        this.activeOrganisationId,
        this.isSharedUser,
      );
    });
  }

  private async setDeliveryDetailsForMap(element: IDelivery): Promise<void> {
    let from: ILocationDetails;
    let to: ILocationDetails;
    const fromId = CommonUtils.getUriId(element.from);
    const toId = CommonUtils.getUriId(element.to);

    try {
      const [fromLocation, toLocation] = await Promise.all([
        this.locationsService.get(fromId),
        this.locationsService.get(toId),
      ]);

      from = fromLocation;
      to = toLocation;
    } catch (error) {
      if (this.isRegularUser) {
        this.notificationService.showError(error);
      }
    }

    this.deliveryDetails = { ...element, from, to };
  }

  private async loadProcessTypes(): Promise<void> {
    this.allProcessTypes = await this.processTypesService.getAll();
  }

  private handleDeliveryNotFoundError(): void {
    this.notificationService.showError($localize`Delivery not found`);

    if (this.routerService.hasHistory()) {
      this.routerService.goBackInHistory();
    } else {
      this.routerService.navigate(RouteEnum.DELIVERIES);
    }
  }
}
