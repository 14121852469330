import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  input,
  Output,
  signal,
  untracked,
} from "@angular/core";

import { slideOverAnimation } from "@shared/animations";

@Component({
  standalone: false,
  selector: "app-slide-overlay-wrapper",
  templateUrl: "./slide-overlay-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideOverAnimation],
})
export class SlideOverlayWrapperComponent {
  closeOverlay = signal<boolean>(true);

  closeContent = input<boolean>(true, { alias: "close" });

  class = input<string>("");

  @Output() clickClose: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {
    effect((onCleanup) => {
      const state = this.closeContent();
      let timer: number;

      untracked(() => {
        if (state) {
          timer = setTimeout(() => {
            this.closeOverlay.set(state);
          }, 300);
        } else {
          this.closeOverlay.set(state);
        }
      });
      onCleanup(() => {
        clearTimeout(timer);
      });
    });
  }

  protected async dismiss(): Promise<void> {
    this.closeOverlay.set(true);
  }

  public onClickClose(event: MouseEvent): void {
    this.clickClose.emit(event);
  }

  public onClickOutside(event: MouseEvent): void {
    this.onClickClose(event);
  }
}
