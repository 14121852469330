import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { DateRange, MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";

import "moment/locale/en-gb";
import moment, { Moment } from "moment";

import { DatepickerHeaderComponent } from "@components/shared/datepicker";
import { DateRangeValueTypeEnum } from "@components/shared/datepicker/daterangepicker.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { FormUtils, LocalizationUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-daterangepicker",
  templateUrl: "./date-range-picker.component.html",
  styleUrls: ["./date-range-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangePickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DateRangePickerComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild("dateInputStart") dateInputEndRef: ElementRef;

  @ViewChild("dateInputEnd") dateInputStartRef: ElementRef;

  @ViewChild("picker") picker: MatDatepicker<Date>;

  @Input() label: string;

  @Input() minDate: string | Date;

  @Input() maxDate: string | Date;

  @Input() class: string;

  @Input({ transform: booleanAttribute }) enableViewMode: boolean = false;

  public isOptional = signal<boolean>(false);

  public isDisabled = signal<boolean>(false);

  textValues: [string, string];

  fromValue: string;

  toValue: string;

  ngControl: NgControl;

  datepickerHeader: typeof DatepickerHeaderComponent = DatepickerHeaderComponent;

  dateRangeValueTypeEnum = DateRangeValueTypeEnum;

  readonly datePickerDateFormat = LocalizationUtils.getLocaleDatepickerFormat();

  public readonly translations: any = {
    clearTp: TextConstants.CLEAR,
  };

  constructor(
    private injector: Injector,
    protected overlay: SlideOverlayPageService,
  ) {}

  get hasValidationError(): boolean {
    return this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched);
  }

  get minDateValue(): Date {
    return this.minDate
      ? moment.isDate(this.minDate)
        ? this.minDate
        : moment(this.minDate).toDate()
      : null;
  }

  get maxDateValue(): Date {
    return this.maxDate
      ? moment.isDate(this.maxDate)
        ? this.maxDate
        : moment(this.maxDate).toDate()
      : null;
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl) {
      this.isOptional.set(FormUtils.isOptional(this.ngControl.control));
    }
  }

  onChange: (_: unknown | null) => void = () => {};

  onTouch: () => void = () => {};

  writeValue(inputValue: [string, string]): void {
    this.textValues = inputValue;
    this.fromValue = inputValue[0];
    this.toValue = inputValue[1];
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  handleCalendarClick(event: Event): void {
    event.preventDefault();
    this.picker.open();
  }

  handleChange(
    type: DateRangeValueTypeEnum,
    event: MatDatepickerInputEvent<string, DateRange<string>> | Event,
  ): void {
    if (type === DateRangeValueTypeEnum.FROM) {
      this.handleFromChange(event);
    } else {
      this.handleToChange(event);
    }
  }

  handleFromChange(event: MatDatepickerInputEvent<string, DateRange<string>> | Event): void {
    const value = this.normalizeValue(event);

    this.fromValue = value;

    this.onChange([value, this.toValue]);
    this.onTouch();
  }

  handleToChange(event: MatDatepickerInputEvent<string, DateRange<string>> | Event): void {
    const value = this.normalizeValue(event);

    this.toValue = value;

    this.onChange([this.fromValue, value]);
    this.onTouch();
  }

  private normalizeValue(
    event: MatDatepickerInputEvent<string, DateRange<string>> | Event,
  ): string {
    let value: string | Moment = (event.target as unknown as HTMLInputElement).value;

    if (moment.isMoment(value)) {
      value = value.format();
    } else if (typeof value === "string") {
      const momentValue = moment.utc(value, this.datePickerDateFormat, true);

      value = momentValue.isValid() ? momentValue.format() : value;
    }

    return value;
  }

  private clearInput(): void {
    this.dateInputStartRef.nativeElement.value = "";
    this.dateInputEndRef.nativeElement.value = "";
  }

  get shouldHideClearButton(): boolean {
    const isEmpty = !this.ngControl.value[0] && !this.ngControl.value[1];

    return this.isDisabled() || isEmpty;
  }

  onInputClear(): void {
    this.picker.select(null);
    this.clearInput();
    this.onChange([null, null]);
    this.onTouch();
  }
}
