<app-button class="mr" type="icon-primary" size="medium" [matMenuTriggerFor]="menu">
  <mat-icon>add</mat-icon>
</app-button>
<mat-menu #menu class="shortcut-add-dropdown">
  <div class="menu-container flex-column">
    @for (group of groupedMenuItems; let index = $index; track $index; let last = $last) {
      <div class="flex gap-col-20">
        @for (menuItem of group; let menuItemIndex = $index; track menuItemIndex) {
          <button type="button" (click)="onClick(menuItem.entityType)">
            @if (menuItem.isSvgIcon) {
              <mat-icon class="icon" [svgIcon]="menuItem.icon"></mat-icon>
            } @else {
              <mat-icon class="icon" [ngClass]="menuItem.iconClass ?? ''">
                {{ menuItem.icon }}
              </mat-icon>
            }
            <span class="text">{{ menuItem.title }}</span>
          </button>
        }
      </div>
      @if (!last) {
        <div class="divider"></div>
      }
    }
  </div>
</mat-menu>
