<a
  class="sidebar-item"
  [class]="class"
  [class.active]="active"
  (click)="navigateToRoute()"
  mat-list-item
  #tooltipTarget
>
  <mat-icon
    [svgIcon]="menuItem().isSvgIcon ? menuItem().icon : undefined"
    [tp]="tooltipContent"
    tpPlacement="right"
    [tpTriggerTarget]="tooltipTarget._elementRef.nativeElement"
    [tpIsEnabled]="showTooltip"
    [tpOffset]="[0, 10]"
    [matBadge]="badge"
    matBadgeSize="small"
    matBadgePosition="after"
    matBadgeColor="warn"
    [matBadgeHidden]="!badge"
    [ngClass]="menuItem().class ?? ''"
  >
    @if (!menuItem().isSvgIcon) {
      {{ menuItem().icon }}
    }
  </mat-icon>
  <span #contentRef>
    <ng-content></ng-content>
  </span>
</a>
