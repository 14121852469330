import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from "@angular/core";

import { slideOverAnimation } from "src/app/shared/animations";
import {
  IBaseUnit,
  IItem,
  IItemExtended,
  IMaterial,
  IProcessInput,
  IProcessOutput,
  IProductExtended,
  ISelectOption,
} from "src/app/shared/interfaces";
import { ItemsService, ProcessesService } from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import {
  SummaryComponentType,
  SummaryComponentTypeEnum,
} from "@components/processes/edit-process/process-inputs-outputs/process-inputs-outputs.model";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-process-inputs-outputs",
  templateUrl: "./process-inputs-outputs.component.html",
  styleUrls: ["./process-inputs-outputs.component.scss"],
  animations: [slideOverAnimation],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProcessInputsOutputsComponent implements OnInit {
  @Input()
  public processId: string;

  @Input()
  public processLocation: ISelectOption;

  @Input()
  public type: string;

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public displayTitle: boolean = true;

  @Input()
  public summaryComponentType: SummaryComponentType = SummaryComponentTypeEnum.QUANTITY_SUMMARY;

  @Input()
  public isReadOnly: boolean = false;

  @Input()
  public canAddModifyEntities: boolean = false;

  @Output()
  public changed: EventEmitter<number> = new EventEmitter<number>();

  public selectedElements: IProcessInput[] | IProcessOutput[] = [];

  public selectedItems: IItem[] = [];

  public isSlideOverVisible = signal(false);

  public isLoading = signal(true);

  public processInputOrOutput: IProcessInput | IProcessOutput;

  public readonly summaryComponentTypeEnum = SummaryComponentTypeEnum;

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public inboundProcessResources: IProcessInput[] | IProcessOutput[];

  @Input()
  public inboundItems: IItemExtended[] = [];

  public readonly translations: any = {
    addNewTp: TextConstants.ADD_NEW,
  };

  constructor(
    private processesService: ProcessesService,
    private notificationService: NotificationService,
    private itemsService: ItemsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    if (this.processId) {
      await this.onReloadElements();
    }
    if (this.isInboundShared) {
      this.selectedItems = this.inboundItems as any as IItem[];
      this.selectedElements = this.inboundProcessResources;
      this.allMaterials = this.allProducts.map((p) => p.materials).flat();
      this.allUnitOfMeasurements = this.allProducts
        .map((p) => p.unitOfMeasurement)
        .filter((u) => !!u);
      this.isLoading.set(false);
    }
  }

  public onAdd = (element?: IProcessInput | IProcessOutput): void => {
    this.processInputOrOutput = element;
    this.isSlideOverVisible.set(true);
  };

  public onAddCompleted = async (result: { hasSaved: boolean }): Promise<void> => {
    this.isSlideOverVisible.set(false);

    if (result?.hasSaved) {
      await this.onReloadElements();
    }
  };

  public onRemove = async (elementId: string): Promise<void> => {
    try {
      if (this.type === "input") {
        await this.processesService.deleteInput(this.processId, elementId);
      } else {
        await this.processesService.deleteOutput(this.processId, elementId);
      }
      this.notificationService.showSuccess(
        `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)} removed`,
      );

      await this.onReloadElements();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onCloseSlideOverlay(): void {
    this.isSlideOverVisible.set(false);
  }

  private onReloadElements = async (): Promise<void> => {
    this.isLoading.set(true);
    this.selectedElements = [];
    this.selectedItems = [];

    try {
      this.selectedElements =
        this.type === "input"
          ? (await this.processesService.getManyInputs(this.processId)).content
          : (await this.processesService.getManyOutputs(this.processId)).content;

      for (const element of this.selectedElements) {
        for (const elementItem of element.items) {
          const itemId = CommonUtils.getUriId(elementItem.item);
          const item = await this.itemsService.get(itemId);
          const quantity =
            this.type === "input" ? elementItem.selectedQuantity : item.initialQuantity;

          this.selectedItems.push({ ...item, selectedQuantity: quantity });
        }
      }
      this.changed.emit(this.selectedElements.length);
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  async getSelectedElements() {
    if (this.isInboundShared) {
      return this.inboundProcessResources;
    }

    return this.type === "input"
      ? (await this.processesService.getManyInputs(this.processId)).content
      : (await this.processesService.getManyOutputs(this.processId)).content;
  }
}
