import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { IItemSupplyChain, ILocationExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-delivery-report-supply-chain-entities",
  templateUrl: "./delivery-report-supply-chain-entities.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportSupplyChainEntitiesComponent implements OnInit {
  @Input()
  public supplyChainItems: IItemSupplyChain[];

  public groupedLocations: { [key: string]: ILocationExtended[] } = {};

  public ngOnInit() {
    const uniqueLocations = this.supplyChainItems
      .flatMap((item) => item.locations)
      .filter((location, index, self) => {
        return self.findIndex((l) => l.id === location.id) === index;
      });

    this.groupedLocations = uniqueLocations.reduce((locationGroups, location) => {
      if (!locationGroups[location.address.country]) {
        locationGroups[location.address.country] = [];
      }

      locationGroups[location.address.country].push(location);

      return locationGroups;
    }, {});
  }
}
