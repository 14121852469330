import { ChangeDetectionStrategy, Component, inject, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { TextConstants } from "@shared/constants";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./eudr-dds-add-reference-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EudrDdsAddReferenceDialogComponent implements OnInit {
  public formGroup: FormGroup<ReportsEudrModel.ReferenceFormData>;

  public statement: EudrDds.IDetails;

  private readonly notificationService: NotificationService = inject(NotificationService);

  private readonly eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  public readonly translations: any = {
    referenceNumberLabel: $localize`Reference number`,
    verificationNumberLabel: $localize`Verification code`,
  };

  constructor(
    private readonly dialogRef: MatDialogRef<EudrDdsAddReferenceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { statement: EudrDds.IDetails },
  ) {
    this.statement = data.statement;
  }

  public async ngOnInit(): Promise<void> {
    this.formGroup = new FormGroup<ReportsEudrModel.ReferenceFormData>({
      referenceNumber: new FormControl(null, [CustomValidators.required]),
      verificationNumber: new FormControl(null, [CustomValidators.required]),
    });
  }

  public async onSubmit(): Promise<void> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    const newAssociatedStatement: EudrDds.IAssociatedStatement = {
      referenceNumber: this.formGroup.get("referenceNumber").value,
      verificationNumber: this.formGroup.get("verificationNumber").value,
    };

    const associatedStatements = [
      ...this.statement.statement.associatedStatements,
      newAssociatedStatement,
    ];

    const hasSaved = await this.eudrDdsPageService.saveReferences(
      this.statement,
      associatedStatements,
    );

    this.onClose(hasSaved);
  }

  public onClose(hasSaved: boolean = false): void {
    this.dialogRef.close({ hasSaved });
  }
}
