import { ChangeDetectionStrategy, Component, signal } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { CellRendererUtils } from "@shared/utils";

@Component({
  standalone: false,
  template: `
    @if (links()?.length) {
      <div class="flex">
        @for (link of links(); track $index) {
          @if ($index > 0) {
            ,&nbsp;
          }
          <app-form-link
            [ngClass]="links().length > 1 ? 'width-fit-content' : 'full-width'"
            [link]="link.link"
            [openInNewTab]="link.openInNewTab"
          >
            @if (link.showIcon) {
              <mat-icon class="cell-icon" [tp]="link.iconTooltip ?? ''" [tpIsLazy]="true">
                {{ link.icon }}
              </mat-icon>
            }
            <span [tp]="link.tooltip ?? ''" [tpIsLazy]="true" class="one-line-wrap-ellipsis">
              {{ link.text }}
            </span>
          </app-form-link>
        }
      </div>
    } @else {
      -
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkCellRendererComponent implements ICellRendererAngularComp {
  public links = signal<any[]>([]);

  private params: ICellRendererParams;

  public agInit(params: ICellRendererParams): void {
    this.params = params;

    if (!params.value) {
      // Either no value or it's a groupedby row.
      this.links.set([]);

      return;
    }

    const links = [];

    if (Array.isArray(params.value)) {
      for (let i = 0; i < params.value.length; i++) {
        const element = params.value[i];

        links.push(this.getParsedLink(element[params["textParam"]], element));
      }
    } else {
      links.push(this.getParsedLink(params.value, this.getData()));
    }

    this.links.set(links);
  }

  private getParsedLink = (text: string, data: any): any => {
    return {
      text,
      link: CellRendererUtils.getLink(this.params, data),
      tooltip: this.params["tooltip"] ?? undefined,
      openInNewTab: this.params["openInNewTab"] ?? false,
      showIcon: this.params["showIcon"] ? this.params["showIcon"](data) : false,
      iconTooltip: this.params["iconTooltip"] ?? undefined,
      icon: this.params["icon"] ?? undefined,
    };
  };

  public refresh(): boolean {
    return false;
  }

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
