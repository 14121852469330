<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    @if (isEditing()) {
      <ng-container i18n>Edit process type</ng-container>
    } @else {
      <ng-container i18n>Add process type</ng-container>
    }
  </h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <app-input
        [label]="translations.nameLabel"
        formControlName="name"
        [autofocus]="!isLoading() && !isEditing()"
      ></app-input>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
