import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { BadgeLinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RouteEnum,
  TableEnum,
} from "@shared/enums";
import { IDocument, IDocumentExtended, IDocumentType } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import {
  NotificationService,
  AuthenticationService,
  DocumentsService,
  DownloadDocumentsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-documents-table",
  templateUrl: "./documents-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsTableComponent implements AfterViewInit, OnDestroy {
  public documents = input<IDocument[]>();

  @Input()
  public documentsIds: string[] = null;

  @Input()
  public allDocumentTypes: IDocumentType[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public isShowSelectCheckbox = false;

  @Input()
  public canNavigateToDetail = true;

  @Input()
  public shouldOpenInNewTab = false;

  public readonly table = TableEnum.DOCUMENTS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public allowClickOnNameRow: boolean = true;

  @Input()
  public columns: string[] = [
    "recordState",
    "name",
    "type.name",
    "issuance",
    "validityStart",
    "validityEnd",
    "tags",
  ];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  @Input()
  public class: string;

  @Input()
  public canRemove = false;

  @Output()
  public remove: EventEmitter<IDocument> = new EventEmitter();

  @Output()
  public setDocuments: EventEmitter<IDocument[]> = new EventEmitter();

  public columnDefs = signal<ColDef[]>([]);

  public isLoading = signal(true);

  public readonly rowGroupColumns: string[] = [];

  public rowData: any[] = []; //todo use IDocumentExtended when graphQL implemented everywhere

  protected readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private documentsService: DocumentsService,
    private notificationService: NotificationService,
    private overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
    protected downloadDocumentsService: DownloadDocumentsService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe((instance: SlideOverlayPageClass) => {
        if (instance.entityType === EntityTypeEnum.DOCUMENTS) {
          this.getAll();
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.DOWNLOAD_DOCUMENT,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DOWNLOAD_DOCUMENT,
            this.downloadDocumentsService,
            true,
          ),
        ],
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.documentsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.documentsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.documentsService),
        ],
      ]),
    };
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onDownload = async (document: IDocument): Promise<void> => {
    if (!document.contentType) {
      return;
    }

    let urlPath: string = undefined;

    if (this.isInboundShared && this.inboundSharedSenderOrgId) {
      urlPath = `organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${this.inboundSharedSenderOrgId}/documents/${document.id}/content`;
    }

    this.downloadDocumentsService.add(
      {
        id: document.id,
        name: document.name,
      },
      urlPath,
    );
  };

  public onView = async (document: IDocument): Promise<void> => {
    if (!document.contentType) {
      return;
    }

    let url = `${RouteEnum.DOCUMENTS_VIEW}/${document.id}`;

    if (this.isInboundShared && this.inboundSharedSenderOrgId) {
      url = `${url}?senderOrgId=${this.inboundSharedSenderOrgId}`;
    }

    CommonUtils.openInNewTab(url);
  };

  public onRowClick = async (row: IDocument): Promise<void> => {
    if (this.canNavigateToDetail) {
      if (this.shouldOpenInNewTab) {
        if (this.inboundSharedSenderOrgId) {
          this.routerService.openNewTab(
            this.routerService.getSharedDocumentLink(row.id, false, {
              organisationId: this.inboundSharedSenderOrgId,
            }),
          );
        } else {
          this.routerService.openNewTab(this.routerService.getDocumentLink(row.id, false));
        }
      } else {
        if (this.inboundSharedSenderOrgId) {
          this.routerService.navigate(
            this.routerService.getSharedDocumentLink(row.id, false, {
              organisationId: this.inboundSharedSenderOrgId,
            }),
          );
        } else {
          this.routerService.navigate(this.routerService.getDocumentLink(row.id));
        }
      }
    } else {
      if (row.canView) {
        await this.onView(row);
      } else {
        await this.onDownload(row);
      }
    }
  };

  private setColumnDefs = (): void => {
    const nameActions: any[] = [
      {
        click: this.onView,
        tooltip: (row: IDocument) =>
          !row.contentType ? TextConstants.DOCUMENT_NO_CONTENT : $localize`View`,
        icon: "visibility",
        iconClass: (row: IDocument) => (!row.contentType ? "red" : ""),
        show: (row) => row.canView,
      },
      {
        click: this.onDownload,
        tooltip: (row: IDocument) =>
          !row.contentType ? TextConstants.DOCUMENT_NO_CONTENT : $localize`Download`,
        icon: "download",
        iconClass: (row: IDocument) => (!row.contentType ? "red" : ""),
      },
    ];

    if (this.canRemove) {
      nameActions.unshift({
        icon: "close",
        tooltip: TextConstants.REMOVE,
        show: () => this.canRemove,
        click: (document: IDocument) => this.remove.emit(document),
      });
    }

    const nameColOptions: ColDef = {
      ...ColumnUtils.quickActionsMenuColumnCommonValues,
      cellRenderer: QuickActionsMenuComponent,
      cellRendererParams: {
        linkRouteIdParam: "id",
        linkRouteFn: this.isInboundShared
          ? (id) =>
              this.routerService.getSharedDocumentLink(id, false, {
                organisationId: this.inboundSharedSenderOrgId,
              })
          : this.routerService.getDocumentLink,
        openInNewTab: this.shouldOpenInNewTab,
        actions: nameActions,
      },
    };

    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: TextConstants.NAME,
        field: "name",
        ...nameColOptions,
      },
      {
        colId: "documentName",
        headerName: $localize`Document Name`,
        field: "name",
        ...nameColOptions,
      },
      { colId: "type.name", headerName: TextConstants.TYPE, field: "type.name" },
      { colId: "documentType", headerName: $localize`Document Type`, field: "type.name" },
      ColumnUtils.chips("Rulesets applied", "rulesetsNames"),
      ColumnUtils.dateColumn({
        headerName: $localize`Issued on`,
        field: "issuance",
      }),
      ColumnUtils.dateColumn({
        headerName: TextConstants.VALID_FROM,
        field: "validityStart",
      }),
      ColumnUtils.dateColumn({
        headerName: TextConstants.VALID_TO,
        field: "validityEnd",
      }),
      {
        headerName: $localize`Target`,
        field: "targetType",
      },
      {
        headerName: $localize`Target name`,
        field: "targetName",
      },
      {
        headerName: $localize`Attached to`,
        field: "attachedTo",
        cellClass: "container-flex-left",
        valueGetter: (cell: any) => String(cell.data?.attachedTo?.length ?? 0),
        cellRenderer: BadgeLinkCellRendererComponent,
        cellRendererParams: {
          tooltipArray: (row) => {
            if (!row.attachedTo?.length) {
              return undefined;
            }

            return CommonUtils.groupArrayByKeyAndCount(row.attachedTo, "type").map(
              (g) =>
                `${CommonUtils.pluraliseEntity(CommonUtils.capitaliseFirstLetter(g.key))}: ${g.count}`,
            );
          },
          tooltipTemplate: "keyCount",
          badgeValue: (row) => row.attachedTo?.length ?? 0,
          linkRouteIdParam: "id",
          linkRouteTab: CommonUtils.textToEnum(OverlayTabEnum.ATTACHED_TO),
          linkRouteFn: this.routerService.getDocumentLink,
        },
      },
    ];

    if (this.authenticationService.isRegularUser() && !this.isInboundShared) {
      columnDefs.push(ColumnUtils.tags());
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.isShowSelectCheckbox || this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.rowGroupColumns.length) {
      columnDefs = columnDefs.map((col) => {
        return {
          ...col,
          suppressMovable: true,
          lockPinned: true,
          pivot: false,
        };
      });
    }

    this.columnDefs.set(columnDefs);
  };

  protected setDocumentType = (documents: IDocument[]): void => {
    for (const document of documents) {
      if (typeof document.type === "object" && document.type?.name) {
        document.typeName = document.type.name;
      } else {
        document.type ||= this.allDocumentTypes.find(
          (d) => d.id === CommonUtils.getUriId(document.type),
        );
      }
    }
  };

  protected setDocumentsRulesetsNames(documents: IDocument[] | IDocumentExtended[]): void {
    if (
      !this.allDocumentTypes?.length ||
      !this.authenticationService.isRegularUser() ||
      !this.columnDefs().some((c) => c.field === "rulesetsNames")
    ) {
      return;
    }

    for (const document of documents) {
      document.rulesetsNames = this.allDocumentTypes.find(
        (t) => document.type.name === t.name,
      )?.rulesetsNames;
    }
  }

  public getAll = async (): Promise<void> => {
    this.rowData = [];
    this.isLoading.set(true);

    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");
    const attachedToFieldPresent = this.columnDefs().some((c) => c.field === "attachedTo");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (attachedToFieldPresent) {
      fieldsToInclude.push("ATTACHED_TO");
    }

    if (this.documents()) {
      const documents = [...this.documents()];

      this.setDocumentType(documents);
      CommonUtils.setDocumentsCanView(documents);
      this.setDocumentsRulesetsNames(documents);
      this.rowData = documents;
      this.isLoading.set(false);
    } else if (this.documentsIds) {
      try {
        if (!this.documentsIds.length) {
          this.rowData = [];
          this.isLoading.set(false);

          return;
        }
        const documents = await this.documentsService.getByIdsGraphQL(
          this.documentsIds,
          undefined,
          fieldsToInclude,
        );

        CommonUtils.setDocumentsCanView(documents);
        this.setDocumentsRulesetsNames(documents);
        this.rowData = documents;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const documents = await this.documentsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        CommonUtils.setDocumentsCanView(documents);
        this.setDocumentsRulesetsNames(documents);
        this.rowData = documents;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
