import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { ProcessTypesService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./edit-process-type-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditProcessTypeDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(false);

  public isEditing = signal(false);

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
  };

  constructor(
    public dialogRef: MatDialogRef<EditProcessTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { processType?: any },
    private processTypesService: ProcessTypesService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.processType?.id);

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.processTypesService,
            this.data?.processType?.id ?? null,
          ),
        ],
      ),
    });

    if (this.data?.processType?.name) {
      this.formGroup.controls["name"].setValue(this.data.processType.name);
    }
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.invalid || this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    const payload: { id: string; name: string } = {
      id: this.isEditing() ? this.data.processType.id : undefined,
      name: this.formGroup.controls["name"].value.trim(),
    };

    try {
      const newProcessType = await this.processTypesService.createOrUpdate(
        payload,
        this.data?.processType?.id,
      );

      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Process type modified` : $localize`Process type created`,
      );
      this.onClose(true, newProcessType);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false, newProcessType = null): void => {
    this.dialogRef.close({ hasSaved, newProcessType });
  };
}
