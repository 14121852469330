import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, contentChildren, input, output } from "@angular/core";

import { DragDropListItemComponent } from "./drag-drop-list-item.component";

@Component({
  standalone: false,
  selector: "app-drag-drop-list-container",
  templateUrl: "./drag-drop-list-container.component.html",
  styleUrl: "./drag-drop-list-container.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropListContainerComponent {
  readonly placeholder = input.required<string>();

  readonly enableViewMode = input(false);

  readonly dropEvent = output<CdkDragDrop<DragDropListItemComponent>>();

  readonly items = contentChildren(DragDropListItemComponent);
}
