import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  inject,
  signal,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import * as d3 from "d3";
import { graphlib, render } from "dagre-d3";
import { debounceTime, Subject, Subscription } from "rxjs";
import { GraphScaler } from "src/app/shared/classes/graph-scaler.class";
import { ResourceTypeEnum } from "src/app/shared/enums";
import { IProductSupplyChain } from "src/app/shared/interfaces/product-supply-chain.interface";
import { CommonService, ProductsService } from "src/app/shared/services";

import { ProductCardContentComponent } from "@components/shared/cards/product-card-content/product-card-content.component";
import { FullScreenSupplyChainDialogComponent } from "@components/shared/fullscreen-supply-chain-dialog/fullscreen-supply-chain-dialog.component";
import { FlowChartTypeEnum } from "@components/shared/fullscreen-supply-chain-dialog/fullscreen-supply-chain-dialog.model";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  IAvailableOrganisation,
  ILocationExtended,
  IProductExtended,
  IRecordResponse,
  ISelectOption,
} from "@shared/interfaces";
import { RouterService } from "@shared/services/router.service";

import { FlowChartNodeComponent } from "../supply-chain-flow-chart";

@Component({
  standalone: false,
  selector: "app-product-supply-chain",
  templateUrl: "./product-supply-chain.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSupplyChainComponent
  extends GraphScaler
  implements AfterViewInit, AfterContentChecked, OnDestroy, OnChanges
{
  @Input()
  public containerClass: string = "product-supply-chain-flow-chart";

  @Input()
  override height: number;

  @Input()
  public productId: string;

  @Input()
  public activeOrganisationId: string;

  @Input()
  public fullScreenOptionEnabled: boolean = true;

  @Input()
  public shouldIncludeLocationsLinks: boolean = false;

  @Input()
  public shouldIncludeDocuments: boolean = false;

  @Input()
  public shouldHandleProductReferences = true;

  @Input()
  public override svgId: string = "node-svg";

  @Input()
  public productSupplyChainItems: IProductSupplyChain[];

  public hasError: boolean = false;

  public isLoading = signal(true);

  public override isZoomEnabled: boolean = true;

  private svg: d3.Selection<SVGElement, object, HTMLElement, any>;

  private svgGroup: any;

  private graph: graphlib.Graph;

  private zoom: any;

  private productsService = inject(ProductsService);

  private componentFactoryProduct: ComponentFactory<any>;

  private componentFactoryLocation: ComponentFactory<any>;

  public relatedProductsLength: number;

  public locations: ILocationExtended[] = [];

  public locationIds: string[] = [];

  private locationsRulesetsRecords: IRecordResponse[];

  private autoScaleGraphSubject = new Subject();

  private subscriptions = new Subscription();

  private countryOptions: ISelectOption[];

  private activeOrganisation: IAvailableOrganisation;

  override getSvg() {
    return this.svg;
  }

  override getSvgGroup() {
    return this.svgGroup;
  }

  override getZoom() {
    return this.zoom;
  }

  override getGraph() {
    return this.graph;
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.autoScaleGraphSubject.next(true);
  }

  public readonly translations: any = {
    fullScreenTp: TextConstants.FULL_SCREEN,
  };

  constructor(
    private _resolver: ComponentFactoryResolver,
    private _injector: Injector,
    private dialog: MatDialog,
    private routerService: RouterService,
    private commonService: CommonService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.subscriptions.add(
      this.autoScaleGraphSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS))
        .subscribe(() => this.scaleAndCenterGraph(`#${this.svgId}`)),
    );
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
    this.activeOrganisation = this.authenticationService.getActiveOrganisation();
  }

  async ngAfterViewInit(): Promise<void> {
    this.isLoading.set(true);

    this.componentFactoryProduct = this._resolver.resolveComponentFactory(
      ProductCardContentComponent,
    );
    this.componentFactoryLocation = this._resolver.resolveComponentFactory(FlowChartNodeComponent);

    const fieldsToInclude = [];

    if (this.shouldIncludeLocationsLinks) {
      fieldsToInclude.push("FULL_LOCATION_LINKS");
    }
    if (this.shouldIncludeDocuments) {
      fieldsToInclude.push("DOCUMENTS");
    }

    try {
      this.productSupplyChainItems = (
        await this.productsService.getProductSupplyChain(
          this.activeOrganisationId,
          this.productId,
          fieldsToInclude,
        )
      ).loadProductSupplyChain?.products;
      if (this.shouldIncludeLocationsLinks) {
        this.locationIds = this.extractLocationIds();
        if (this.authenticationService.isRegularUser()) {
          this.locationsRulesetsRecords = await this.getRulesetRecords(
            this.locationIds,
            ResourceTypeEnum.LOCATION,
          );
        }
        this.allDocumentTypes = await this.documentTypesService.getAll();
      }
    } catch (error) {
      this.hasError = true;
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
      this.changeDetectorRef.detectChanges();
      this.drawGraph();
    }
  }

  public ngAfterContentChecked(): void {
    const drawnNodes = this.mainContainer()?.getElementsByClassName("flow-chart-node");

    if (drawnNodes && !drawnNodes.length) {
      try {
        this.drawGraph();
      } catch (error) {
        this.notificationService.showError(error);
        this.hasError = true;
      } finally {
        this.isLoading.set(false);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes["height"] &&
      changes["height"].previousValue &&
      changes["height"].previousValue !== changes["height"].currentValue
    ) {
      this.scaleAndCenterGraph(`#${this.svgId}`);
    }
  }

  private extractLocationIds(): string[] {
    const locationIds: string[] = [];

    for (const item of this.productSupplyChainItems) {
      const product = item.product;

      if (product.locationLinks) {
        for (const link of product.locationLinks) {
          if (link.from && link.from.id) {
            locationIds.push(link.from.id);
          }
          if (link.to && link.to.id) {
            locationIds.push(link.to.id);
          }
        }
      }
    }

    return locationIds;
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public mainContainer() {
    return document.querySelector(`.${this.getMainClass()}`);
  }

  public fullScreen(): void {
    const dialogRef = this.dialog.open(FullScreenSupplyChainDialogComponent, {
      width: window.innerWidth + "px",
      height: window.innerHeight - 25 + "px",
      panelClass: CommonConstants.SUPPLY_CHAIN_FULL_SCREEN_PANEL_CLASS,
      data: {
        containerClass: "flow-chart-container-class-dialog",
        flowChartType: FlowChartTypeEnum.PRODUCT_SUPPLY_CHAIN,
        productId: this.productId,
        activeOrganisationId: this.activeOrganisationId,
        shouldIncludeLocationsLinks: this.shouldIncludeLocationsLinks,
        shouldIncludeDocuments: this.shouldIncludeDocuments,
        shouldHandleProductReferences: this.shouldHandleProductReferences,
      },
    });

    dialogRef.afterOpened().subscribe(() => {
      dialogRef.componentInstance.setIsOpened(true);
    });
  }

  public getMainClass(): string {
    return this.containerClass;
  }

  private drawGraph = (): void => {
    if (
      !this.productSupplyChainItems?.length ||
      !this.componentFactoryProduct ||
      !this.componentFactoryLocation
    ) {
      return;
    }

    this.graph = new graphlib.Graph().setGraph({});
    (this.graph.graph() as any).rankdir = "LR";
    (this.graph.graph() as any).nodesep = 0;

    const locationNodes = new Set<string>();

    for (const item of this.productSupplyChainItems) {
      const product = item.product;

      // If location links must be included and the product has location links, handle them
      if (this.shouldIncludeLocationsLinks && product.locationLinks?.length) {
        this.handleLocationLinks(product, locationNodes);
      }

      if (this.shouldHandleProductReferences) {
        this.addProductNode(product);

        // Handle product references if location links should not be included
        this.handleProductReferences(item.usedIn, product);
      }
    }

    // After adding all nodes and links, continue with the remaining code for drawing the graph
    this.svg = d3.select(`.${this.getMainClass()} svg#${this.svgId}`);
    this.svgGroup = this.svg.select("g");

    if (this.isZoomEnabled) {
      this.zoom = d3
        .zoom()
        .scaleExtent([0.03, 1])
        .on("zoom", (event) => {
          this.svgGroup.attr("transform", event.transform);
        });
      this.svg.call(this.zoom);
    }

    new render()(this.svgGroup, this.graph as any);

    // Iterate over the graph nodes and configure behaviors as appropriate
    for (const nodeElement of this.svgGroup.selectAll("g.node")) {
      const nodeId: string = nodeElement.__data__;
      const location = this.locations.find((l) => l.id === nodeId);

      if (location) {
        this.setupLocation(location, nodeElement);
      } else {
        const name = d3.select(nodeElement).select(".product-name");
        const productId = nodeId.split("|")[0];
        const product = [
          this.productSupplyChainItems.map((item) => item.product),
          this.productSupplyChainItems.map((item) => item.usedIn).flat(),
          this.productSupplyChainItems.map((item) => item.createdFrom).flat(),
        ]
          .flat()
          .find((p) => p.id === productId);

        const documentsIcon = d3.select(nodeElement).select(".documents");

        documentsIcon.classed("clickable", true);

        name.on("click", () => {
          this.routerService.openNewTab(this.routerService.getProductLink(productId, false));
        });
        if (product) {
          documentsIcon.on("click", () => {
            this.setSelectedDocuments(product.documents, product.name);
          });
          this.setDocumentsTooltip(product.documents, nodeElement);
        }
      }
      const organisation = d3.select(nodeElement).select(".organisation");

      organisation.on("click", () => {
        this.routerService.openNewTab(
          this.routerService.getOrganisationLink(location.organisationId, false),
        );
      });
    }

    this.scaleAndCenterGraph(`#${this.svgId}`);
  };

  private handleLocationLinks(product: IProductExtended, locationNodes: any): void {
    for (const link of product.locationLinks) {
      const fromLocation = this.findLocationById(link.from.id);
      const toLocation = this.findLocationById(link.to.id);

      if (!locationNodes.has(link.from.id) && fromLocation) {
        this.addLocationNode(fromLocation);
        locationNodes.add(link.from.id);
      }
      if (!locationNodes.has(link.to.id) && toLocation) {
        this.addLocationNode(toLocation);
        locationNodes.add(link.to.id);
      }

      // Create a unique ID for the product using the location link IDs
      const uniqueProductId = `${product.id}|${link.from.id}|${link.to.id}`;
      const clonedProduct = { ...product, id: uniqueProductId };

      this.addProductNode(clonedProduct);

      this.graph.setEdge(link.from.id, uniqueProductId, { label: "" });
      this.graph.setEdge(uniqueProductId, link.to.id, { label: "" });
    }
  }

  private handleProductReferences(usedIn: IProductExtended[], product: IProductExtended): void {
    if (!usedIn || !usedIn.length || !product || !product.id) {
      return;
    }

    for (const usedProduct of usedIn) {
      if (usedProduct && usedProduct.id) {
        this.addProductNode(usedProduct);
        this.graph.setEdge(product.id, usedProduct.id, { label: "" });
      }
    }
  }

  private setupLocation(location: ILocationExtended, nodeElement: any): void {
    const certificatesIcon = d3.select(nodeElement).select(".certificates");
    const documentsIcon = d3.select(nodeElement).select(".documents");
    const name = d3.select(nodeElement).select(".name");

    certificatesIcon.classed("clickable", true);
    documentsIcon.classed("clickable", true);
    name.on("click", () => {
      this.routerService.openNewTab(this.routerService.getLocationLink(location.id, false));
    });

    certificatesIcon.on("click", () => {
      this.setSelectedCertificates(location.certificates, location.name);
    });

    documentsIcon.on("click", () => {
      this.setSelectedDocuments(location.documents, location.name);
      const record = this.locationsRulesetsRecords?.find((r) => r.uri.includes(location.id));

      this.setMissingDocuments(record);
    });
    this.setDocumentsTooltip(location.documents, nodeElement);
    this.setCertificatesTooltip(location.certificates, nodeElement);
    this.setLocationTypesTooltips(nodeElement);
  }

  private addProductNode(product: IProductExtended): void {
    if (
      !product ||
      !product.id ||
      this.graph.hasNode(product.id) ||
      !this.componentFactoryProduct
    ) {
      return;
    }
    const classList = [];

    if (product.id.startsWith(this.productId)) {
      classList.push("current-product-name");
    }

    const nodeComponent = this.componentFactoryProduct.create(this._injector);

    nodeComponent.instance.product = product;
    nodeComponent.instance.isSupplyChainNode = true;
    nodeComponent.instance.customClass = classList.join("");
    nodeComponent.instance.shouldOpenInNewTab = true;
    nodeComponent.changeDetectorRef.detectChanges();
    const templateNativeElement = nodeComponent.location.nativeElement;

    this.graph.setNode(product.id, {
      label: templateNativeElement,
      labelType: "html",
    });
  }

  private addLocationNode(location: ILocationExtended): void {
    if (
      !location ||
      !location.id ||
      this.graph.hasNode(location.id) ||
      !this.componentFactoryLocation
    ) {
      return;
    }
    this.locations.push(location);
    const nodeComponent = this.componentFactoryLocation.create(this._injector);

    nodeComponent.instance.location = {
      ...location,
      filteredAndSelectedDocuments: location.selectedDocuments,
    };
    nodeComponent.instance.locationsRulesetsRecords = this.locationsRulesetsRecords;
    nodeComponent.instance.hasAvailableCertificates = !!location.certificates?.length;
    nodeComponent.instance.hasAvailableDocuments = !!location.documents?.length;
    nodeComponent.instance.isSafari = this.isSafari;
    nodeComponent.instance.shouldOpenInNewTab = true;
    nodeComponent.changeDetectorRef.detectChanges();
    const templateNativeElement = nodeComponent.location.nativeElement;

    this.graph.setNode(location.id, {
      label: templateNativeElement,
      labelType: "html",
    });
  }

  private findLocationById(locationId: string): ILocationExtended {
    for (const item of this.productSupplyChainItems) {
      const product = item.product;

      if (product.locationLinks?.length) {
        for (const link of product.locationLinks) {
          if (link.from.id === locationId) {
            return this.extendLocation(link.from);
          }
          if (link.to.id === locationId) {
            return this.extendLocation(link.to);
          }
        }
      }
    }

    return null;
  }

  private extendLocation(location: ILocationExtended): ILocationExtended {
    const countryName = this.countryOptions.find(
      (o) => o.value === location.address?.country,
    ).label;
    const selectedDocuments = location?.documents?.map((d) => ({
      ...d,
      isSelected: true,
      typeName: d.type?.name,
      type: d.type.id,
    }));
    const selectedCertificates = location?.certificates?.map((c) => ({
      ...c,
      isSelected: true,
    }));

    return {
      ...location,
      organisationName: location.connections[0]?.name ?? this.activeOrganisation.name,
      organisationId: location.connections[0]?.id ?? this.activeOrganisation.id,
      address: { ...location.address, countryName },
      selectedDocuments,
      selectedCertificates,
    };
  }
}
