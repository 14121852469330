import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import {
  IDeliveryExtended,
  IDocumentType,
  IItem,
  IItemExtended,
  ILocationExtended,
} from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-delivery-report-other-deliveries-delivery-details",
  templateUrl: "./delivery-report-other-deliveries-delivery-details.component.html",
  styleUrls: ["./delivery-report-other-deliveries-delivery-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportOtherDeliveriesDeliveryDetailsComponent implements OnInit {
  public delivery = input.required<IDeliveryExtended>();

  public isLoading = signal<boolean>(true);

  public documentTypes: IDocumentType[] = [];

  public fromLocationTypes: InputSelectOption[] = [];

  public toLocationTypes: InputSelectOption[] = [];

  public fromLocationCountryName: string;

  public toLocationCountryName: string;

  public items: IItem[] = [];

  public customFieldsValues: { label: string; value: string }[] = [];

  public deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private routerService: RouterService = inject(RouterService);

  public readonly additionalInformationText = TextConstants.ADDITIONAL_INFORMATION;

  private readonly isSharedUser = this.authenticationService.isSharedUser();

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  public ngOnInit(): void {
    this.documentTypes = CommonUtils.getDocumentTypesByDocuments(this.delivery().documents);

    this.fromLocationTypes = DeliveryReportService.getLocationTypesForLocation(
      this.delivery().from,
    );

    this.toLocationTypes = DeliveryReportService.getLocationTypesForLocation(this.delivery().to);

    this.fromLocationCountryName = this.getCountryNameForLocation(this.delivery().from);
    this.toLocationCountryName = this.getCountryNameForLocation(this.delivery().to);

    this.items = (this.delivery().items as { item: IItemExtended; quantity: number }[]).map(
      (item) => {
        return CommonUtils.convertExtendedItemToItem(
          { ...item.item, deliveredQuantity: item.quantity },
          this.activeOrganisationId,
          this.isSharedUser,
        );
      },
    );

    this.customFieldsValues = this.delivery().customFields.map((customField) => {
      return {
        label: customField.definition.label,
        value: customField.value,
      };
    });

    this.isLoading.set(false);
  }

  private getCountryNameForLocation(location: ILocationExtended): string {
    return this.deliveryReportService
      .countryOptions()
      .find((country) => country.value === location.address.country).label;
  }

  public getDeliveryLink(): NavigationParams {
    return <NavigationParams>this.routerService.getDeliveryLink(this.delivery().id, false);
  }

  public getLocationLink(locationId: string): NavigationParams {
    return <NavigationParams>this.routerService.getLocationLink(locationId, false);
  }
}
