import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";

import { Toast } from "ngx-toastr";

@Component({
  standalone: true,
  template: `
    <mat-icon class="alert-icon">check</mat-icon>
    <div role="alert" [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <mat-icon (click)="remove()" class="close-icon">close</mat-icon>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatIconModule],
})
export class NotificationSuccessComponent extends Toast {}
