import { CountryGraphQLFilter } from "@components/shared/countries/models";

import { RecordStateEnum } from "../enums";
import { IItemGraphQLFilter, ILocationGraphQLFilter } from "../interfaces";
import { fieldsToIncludeMap } from "./fields-to-include-map.constant";

const getParsedIds = (ids: string[]): string => `[${ids.map((id) => `"${id}"`).join(",")}]`;

const generateFilterClause = (
  filter: ILocationGraphQLFilter | IItemGraphQLFilter | CountryGraphQLFilter,
) => {
  if (!filter) {
    return "";
  }

  const clauses = [];

  for (const key in filter) {
    switch (key) {
      case "recordState":
        if (
          filter["recordState"] !== undefined &&
          filter["recordState"] !== null &&
          filter["recordState"] !== RecordStateEnum.ALL
        ) {
          clauses.push(`recordState: ${filter["recordState"]}`);
        }
        break;
      case "hasContent":
        if (filter["hasContent"]) {
          clauses.push(`hasContent: true`);
        }
        break;
      default:
        if (filter[key] !== undefined && filter[key] !== null) {
          clauses.push(`${key}: "${filter[key]}"`);
        }
        break;
    }
  }

  return clauses.length > 0 ? `filter: { ${clauses.join(",")} }` : "";
};

const generateClause = (key: string, value: string | number | null): string => {
  return value !== null && value !== undefined
    ? `${key}: ${typeof value === "string" ? `"${value}"` : value}`
    : "";
};

const getQueryIncludes = (include: string[] = []): string => {
  return include
    .map((field) => {
      const func = fieldsToIncludeMap[field];

      if (typeof func === "function") {
        return func();
      } else {
        return "";
      }
    })
    .filter(Boolean)
    .join("\n");
};

export const QueriesUtils = {
  getParsedIds,
  generateFilterClause,
  generateClause,
  getQueryIncludes,
};
