import { GraphFieldToInclude } from "./graph-field-to-include.type";
import {
  customUnitOfMeasurementFields,
  getLinkFields,
  locationFields,
  materialsFields,
} from "./queries.constants";

const certificatesFields = `
  id
  number
  issuanceDate
  validFromDate
  validToDate
  standardType {
    id
    fullName
    recordState
  }
  standard {
    id
    name
    recordState
  }
  recordState
`;

const documentsFields = `
  id
  name
  issuance
  validityStart
  validityEnd
  contentType
  contentHash
  createdTime
  originalHash
  type {
    id
    name
  }
  recordState
`;

const tagsFields = `
  title
  color
`;

export const fieldsToIncludeMap: Record<GraphFieldToInclude, () => string> = {
  DOCUMENTS: () => `
    documents {
      ${documentsFields}
    }
  `,
  DOCUMENTS_WITH_TAGS: () => `
    documents {
      ${documentsFields}   
      tags {
        ${tagsFields}
      }         
    }
  `,
  CERTIFICATES: () => `
    certificates {
      ${certificatesFields}
    }
  `,
  CERTIFICATES_WITH_DOCUMENTS: () => `
    certificates {
      ${certificatesFields}
      documents {
        id
        name
      }
    }
  `,
  TAGS: () => `
    tags {
      ${tagsFields}
    }
  `,
  ALLOWED_MATERIALS: () => `
    allowedMaterials {
      ${materialsFields}
    }
  `,
  MATERIALS: () => `
    materials { 
      ${materialsFields} 
    }
  `,
  DEFAULT_CUSTOM_UNIT: () => `
    defaultCustomUnit {
      id
      name
      symbol
      displayScale
      conversionFactor
    }
  `,
  AGENTS: () => `
    agents {
      id
      name
    }
  `,
  FULL_LOCATION_LINKS: () => `
    locationLinks {
      id
      from { 
        ${locationFields} 
      }
      to { 
        ${locationFields} 
      }
    }
  `,
  LOCATION_LINKS: () => getLinkFields(true),
  INBOUND_LOCATION_LINKS: () => getLinkFields(false),
  GEOLOCATION: () => `geoLocation`,
  CUSTOM_FIELDS: () => `
    customFields {
      definition {
        type
        label
      }
      value
    }
  `,
  ATTACHED_TO: () => `
    attachedTo {
      id
      type
    }
  `,
  DELIVERIES: () => `
    deliveries {
      id
      deliveryId
    }
  `,
  LOCATIONS: () => `
    locations {
      location {
        ${locationFields} 
      }
    }
  `,
  CUSTOM_UNITS: () => `
    customUnits { 
      ${customUnitOfMeasurementFields}
    }
  `,
  FULL_ADDRESS: () => `
    address {
      street
      country
      region
      zipCode
    }
  `,
  FULL_DELIVERIES: () => `
    deliveries {
      id
      deliveryId
      sent
      from {
        id
        name
      }
      to {
        id
        name
      }
    }
  `,
};
