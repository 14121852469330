import {
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  input,
  TemplateRef,
} from "@angular/core";

import { BulkAddCreateRecordsModel } from "@components/shared/bulk-add/bulk-add-create-records.model";

@Component({
  standalone: false,
  selector: "app-bulk-add-creation-progress",
  templateUrl: "./bulk-add-creation-progress.component.html",
  styleUrls: ["./bulk-add-creation-progress.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BulkAddCreationProgressComponent<
  TPayload extends { status: BulkAddCreateRecordsModel.StatusEnum },
> {
  public recordType = input.required<string>();

  public hasProcessCompleted = input.required<boolean>();

  public payloadsWithStatus = input.required<TPayload[]>();

  @Input() recordTemplate: TemplateRef<{ $implicit: TPayload }>;

  public readonly statusEnum = BulkAddCreateRecordsModel.StatusEnum;

  public warningText = computed(() => {
    return $localize`Please, do not exit this page until all ${this.recordType()}:recordType: are created.`;
  });

  protected get processedRecordsCount(): number {
    return this.payloadsWithStatus().filter((payloadWithStatus) =>
      [
        BulkAddCreateRecordsModel.StatusEnum.ERROR,
        BulkAddCreateRecordsModel.StatusEnum.SUCCESS,
      ].includes(payloadWithStatus.status),
    ).length;
  }

  protected get completionPercentage(): number {
    return (this.processedRecordsCount * 100) / this.payloadsWithStatus().length;
  }
}
