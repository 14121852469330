import {
  AttachmentTypeEnum,
  AttachmentTargetEnum,
  CrossOrgShareDataTypeEnum,
  RouteEnum,
} from "@shared/enums";
import { IAttachment } from "@shared/interfaces";
import { RecordSharingService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

import { CommonUtils } from "./common.utils";

const setupSelectedAttachments = async (
  element: any,
  attachmentType: AttachmentTypeEnum,
  inboundSharedSenderOrgId: string,
  activeOrganisationId: string,
  recordSharingService: RecordSharingService,
  routerService: RouterService,
): Promise<IAttachment[]> => {
  const attachmentUri = `/organisations/${activeOrganisationId}/record-sharing/inbound/records/${inboundSharedSenderOrgId}/${attachmentType.toLowerCase()}s/${element.id}`;
  const attachedTo = element.attachedTo;

  const attachments = await recordSharingService.getInbountAttachments(
    inboundSharedSenderOrgId,
    attachmentType,
    attachmentUri,
  );

  return await Promise.all(
    attachments.map(async (attachment) => {
      const targetId = CommonUtils.getUriId(attachment.targetUri);
      const targetType =
        getTargetType(attachment.targetUri) ||
        AttachmentTargetEnum[attachedTo.find((a) => a.id === targetId)?.type];
      const targetFn = getAttachmentTargetFn(targetType, routerService, inboundSharedSenderOrgId);
      const targetName = await getAttachmentTargetName(
        targetType,
        targetId,
        inboundSharedSenderOrgId,
        recordSharingService,
      );

      return {
        ...attachment,
        targetType,
        targetId,
        targetFn,
        targetName,
      };
    }),
  );
};

const getAttachmentTargetFn = (
  targetType: AttachmentTargetEnum,
  routerService: RouterService,
  organisationIdQueryParam: string,
): any => {
  switch (targetType) {
    case AttachmentTargetEnum.LOCATION:
      return (id: string) =>
        routerService.getSharedLocationLink(id, false, {
          organisationId: organisationIdQueryParam,
        });
    case AttachmentTargetEnum.ORGANISATION:
      return (id: string) =>
        routerService.getSharedConnectionLink(id, false, {
          organisationId: organisationIdQueryParam,
        });
    case AttachmentTargetEnum.CERTIFICATE:
      return (id: string) =>
        routerService.getSharedCertificateLink(id, false, {
          organisationId: organisationIdQueryParam,
        });
    case AttachmentTargetEnum.DELIVERY:
      return (id: string) =>
        routerService.getSharedDeliveryLink(id, false, {
          organisationId: organisationIdQueryParam,
        });
    default:
      return null;
  }
};

const getAttachmentTargetName = async (
  targetType: AttachmentTargetEnum,
  targetId: string,
  organisationIdQueryParam: string,
  recordSharingService: RecordSharingService,
): Promise<string> => {
  switch (targetType) {
    case AttachmentTargetEnum.LOCATION:
      return (
        await recordSharingService.getSharedRecord(
          organisationIdQueryParam,
          CrossOrgShareDataTypeEnum.LOCATIONS,
          targetId,
        )
      ).name;
    case AttachmentTargetEnum.ORGANISATION:
      return (
        await recordSharingService.getSharedRecord(
          organisationIdQueryParam,
          CrossOrgShareDataTypeEnum.CONNECTIONS,
          targetId,
        )
      ).name;
    case AttachmentTargetEnum.CERTIFICATE:
      return (
        await recordSharingService.getSharedRecord(
          organisationIdQueryParam,
          CrossOrgShareDataTypeEnum.CERTIFICATES,
          targetId,
        )
      ).number;
    case AttachmentTargetEnum.DELIVERY:
      return (
        await recordSharingService.getSharedRecord(
          organisationIdQueryParam,
          CrossOrgShareDataTypeEnum.DELIVERIES,
          targetId,
        )
      ).deliveryId;
    default:
      return "";
  }
};

const getTargetType = (targetUri: string): AttachmentTargetEnum => {
  if (targetUri.includes("connections")) {
    return AttachmentTargetEnum.ORGANISATION;
  } else if (targetUri.includes("locations")) {
    return AttachmentTargetEnum.LOCATION;
  } else if (targetUri.includes("certificates")) {
    return AttachmentTargetEnum.CERTIFICATE;
  } else if (targetUri.includes("deliveries")) {
    return AttachmentTargetEnum.DELIVERY;
  } else if (targetUri.includes("items")) {
    return AttachmentTargetEnum.ITEM;
  } else if (targetUri.includes("processes")) {
    return AttachmentTargetEnum.PROCESS;
  } else if (targetUri.includes("products")) {
    return AttachmentTargetEnum.PRODUCT;
  } else if (targetUri.includes("organisations")) {
    return AttachmentTargetEnum.ORGANISATION;
  }

  return null;
};

const getSharedRecord = async (
  senderId: string,
  crossOrgShareDataType: CrossOrgShareDataTypeEnum,
  recordId: string,
  recordSharingService: RecordSharingService,
): Promise<any> => {
  if (crossOrgShareDataType === CrossOrgShareDataTypeEnum.SUPPLY_CHAINS) {
    return recordSharingService.getInboundSupplyChainGraphQl(senderId, recordId);
  } else {
    return recordSharingService.getSharedRecord(senderId, crossOrgShareDataType, recordId);
  }
};

const getSharedDataType = (uri: string): CrossOrgShareDataTypeEnum | null => {
  if (!uri) {
    return null;
  }

  for (const type of Object.values(CrossOrgShareDataTypeEnum)) {
    if (uri.includes(type)) {
      return type as CrossOrgShareDataTypeEnum;
    }
  }

  return null;
};

const getSharedRouteConfig = (
  recordId: string,
  crossOrgShareDataType: CrossOrgShareDataTypeEnum,
  routerService: RouterService,
  senderOrganisationId: string,
) => {
  switch (crossOrgShareDataType) {
    case CrossOrgShareDataTypeEnum.LOCATIONS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedLocationLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.SUPPLY_CHAINS:
      return {
        linkRouteIdParam: recordId,
        openInNewTab: true,
        linkRouteFn: () =>
          `${RouteEnum.INBOX_SHARED_SUPPLY_CHAIN}/${recordId}?&senderOrgId=${senderOrganisationId}`,
      };
    case CrossOrgShareDataTypeEnum.DELIVERIES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedDeliveryLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.ITEMS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedItemLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.PRODUCTS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedProductLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.MATERIALS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedMaterialLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.CONNECTIONS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedConnectionLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.DOCUMENTS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedDocumentLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.CERTIFICATES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedCertificateLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    case CrossOrgShareDataTypeEnum.PROCESSES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () =>
          routerService.getSharedProcessLink(recordId, false, {
            organisationId: senderOrganisationId,
          }),
      };
    default:
      return null;
  }
};

const getLocalRouteConfig = (
  recordId: string,
  crossOrgShareDataType: CrossOrgShareDataTypeEnum,
  routerService: RouterService,
) => {
  switch (crossOrgShareDataType) {
    case CrossOrgShareDataTypeEnum.LOCATIONS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getLocationLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.SUPPLY_CHAINS:
      return {
        linkRouteIdParam: "recordId",
        openInNewTab: true,
        linkRoute: RouteEnum.SUPPLY_CHAINS_DETAILS,
      };
    case CrossOrgShareDataTypeEnum.DELIVERIES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getDeliveryLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.ITEMS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getItemLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.PRODUCTS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getProductLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.MATERIALS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getMaterialLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.CONNECTIONS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getOrganisationLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.DOCUMENTS:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getDocumentLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.PROCESSES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getProcessLink(recordId, false),
      };
    case CrossOrgShareDataTypeEnum.CERTIFICATES:
      return {
        linkRouteIdParam: recordId,
        linkRouteFn: () => routerService.getCertificateLink(recordId, false),
      };
    default:
      return null;
  }
};

export const InboundSharedRecordUtils = {
  setupSelectedAttachments,
  getAttachmentTargetFn,
  getAttachmentTargetName,
  getTargetType,
  getSharedRecord,
  getSharedDataType,
  getSharedRouteConfig,
  getLocalRouteConfig,
};
