<div class="container-space-between">
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>
  <app-button-group [visible]="canAddModifyEntities">
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>
<app-documents-table
  [allowClickOnNameRow]="false"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
  [columns]="[
    'recordState',
    'name',
    'type.name',
    'issuance',
    'validityStart',
    'validityEnd',
    'attachedTo',
    'tags',
  ]"
></app-documents-table>
