<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    @if (isEditing()) {
      <ng-container i18n>Edit unit of measurement</ng-container>
    } @else {
      <ng-container i18n>Add unit of measurement</ng-container>
    }
  </h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <app-input
              [autofocus]="!isEditing()"
              [label]="translations.nameLabel"
              formControlName="name"
              type="text"
            ></app-input>
          </div>
          <div class="item">
            <app-input-select
              [label]="translations.categoryLabel"
              formControlName="category"
              [options]="unitTypesOptions"
            ></app-input-select>
          </div>
        </div>
      </div>
      <div class="container-flex symbol-container">
        <app-input
          [prefix]="categoryControl.valid || categoryControl.disabled ? '1' : ' '"
          [class]="isSafari ? 'width-165' : ''"
          [label]="translations.symbolLabel"
          formControlName="symbol"
          customErrorClass="absolute"
          type="text"
        ></app-input>
        =
        <app-input
          [label]="translations.conversionFactorLabel"
          formControlName="conversionFactor"
          [placeholder]="
            categoryControl.invalid ? translations.categoryFirtPh : translations.conversionFactorPh
          "
          type="number"
          [disabled]="
            categoryControl.invalid || categoryControl.value?.value === unitTypesEnum.UNITS
          "
          customErrorClass="absolute"
          [suffixTooltip]="categoryControl.value?.value | unitSymbol: baseUnits : 'name'"
          [suffix]="categoryControl.value?.value | unitSymbol: baseUnits"
        ></app-input>
        <app-input
          [class]="isSafari ? 'width-165' : ''"
          [label]="translations.displayScaleLabel"
          type="number"
          customErrorClass="absolute"
          [disabled]="categoryControl.value?.value === unitTypesEnum.UNITS"
          [tooltip]="translations.displayScaleTooltip"
          formControlName="displayScale"
        ></app-input>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
