<div class="card">
  <div class="content flex-column">
    <div class="center">
      <div class="entities-count">{{ locations.length }}</div>
    </div>

    <div>
      <app-flag [country]="country"></app-flag>
      <span class="country-name">{{ countryName }}</span>
    </div>

    <div>
      <app-tag-group [tags]="locationTypesTags"></app-tag-group>
    </div>
  </div>
</div>
