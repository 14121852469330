import {
  Component,
  computed,
  effect,
  EventEmitter,
  input,
  Input,
  Output,
  signal,
  TemplateRef,
  untracked,
  ViewChild,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef, RowClassParams } from "ag-grid-community";
import { RowHeightParams } from "ag-grid-enterprise";

import { LocationOverlayService } from "@components/locations/pages/location-overlay/location-overlay.service";
import { AddLocationDialogComponent } from "@components/organisations";
import { ConfirmDialogComponent, TableComponent } from "@components/shared";
import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { AttachProductCellRendererComponent } from "@shared/cell-renderers/attach-product/attach-product.cell-renderer.component";
import { AttachProductCellRendererConstants } from "@shared/cell-renderers/attach-product/attach-product.cell-renderer.model";
import { QuickActionsMenuComponent } from "@shared/cell-renderers/quick-actions-menu/quick-actions-menu.component";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  LinkDirectionEnum,
  RecordStateEnum,
} from "@shared/enums";
import {
  ILocationDetails,
  ILocationExtended,
  ILocationExtendedWithProducts,
  ILocationType,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import {
  NotificationService,
  AttachmentsService,
  AuthenticationService,
  ProductsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

export interface IAttachmentWithProduct {
  attachmentId: string;
  product: IProduct;
}

@Component({
  standalone: false,
  selector: "app-location-overlay-links",
  templateUrl: "./location-overlay-links.component.html",
  styleUrl: "./location-overlay-links.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayLinksComponent implements OnInit {
  @ViewChild("linkedSideMenu") linkedSideMenu: TemplateRef<unknown>;

  @ViewChild("linkedAttachProductsSideMenu") linkedAttachProductsSideMenu: TemplateRef<unknown>;

  @ViewChild("suppliedByTable") suppliedByTable: TableComponent;

  @ViewChild("suppliedToTable") suppliedToTable: TableComponent;

  @Input() isReadOnly: boolean = false;

  public locationId = input.required<string>();

  public allCountries = input.required<ISelectOption[]>();

  public allLocationTypes = input.required<ILocationType[]>();

  @Output() changes: EventEmitter<void> = new EventEmitter();

  public selectedTabIndex = signal<number>(0);

  private selectedAttachProductsLocationId = signal<string>(null);

  public suppliedByLocations = signal<ILocationExtendedWithProducts[]>([]);

  public suppliedToLocations = signal<ILocationExtendedWithProducts[]>([]);

  public readonly attachProductCellRenderer = AttachProductCellRendererComponent;

  public isLoadingAvailableLocations = signal(false);

  public isLoadingAvailableProducts = signal(false);

  public isLoadingSuppliedBy = signal(true);

  public isLoadingSuppliedTo = signal(true);

  public availableProducts = signal<IProduct[]>([]);

  public availableLocations = signal<ILocationExtended[]>([]);

  private searchAvailableProductsText: string = undefined;

  private searchAvailableLocationsText: string = undefined;

  private readonly defaultMaxNumberProducts =
    AttachProductCellRendererConstants.DEFAULT_MAX_NUMBER_PRODUCTS;

  private readonly productRowHeight: number = 31.33;

  private readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private suppliedLocations = computed(() => {
    return this.selectedTabIndex() === 0 ? this.suppliedByLocations() : this.suppliedToLocations();
  });

  attachProductsPanelTitle = computed(() => {
    return $localize`Attach products to ${this.selectedAttachProductsLocation.name}:locationName:`;
  });

  detailCellRendererParams = {
    customParams: {
      canAddModifyEntities: this.canAddModifyEntities,
      onClickRemoveAttachment: (locationId: string, attachmentId: string) => {
        this.onRemoveProduct(locationId, attachmentId);
      },
    },
  };

  columnDefs = signal<ColDef[]>([]);

  readonly cardContentTypeEnum = CardContentTypeEnum;

  constructor(
    public locationOverlayService: LocationOverlayService,
    public overlay: SlideOverlayPageService,
    private productsService: ProductsService,
    private dialog: MatDialog,
    private notification: NotificationService,
    private attachmentsService: AttachmentsService,
    private routerService: RouterService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
  ) {
    effect(() => {
      this.locationId();
      this.allLocationTypes();
      this.allCountries();

      untracked(async () => {
        await this.loadLocationsLinks();
        this.onReloadAvailableLocations();
      });
    });
  }

  public ngOnInit(): void {
    this.setColumnDefs();
  }

  private setColumnDefs = (): void => {
    const nameColumnRendererParams: any = {
      cellRenderer: "agGroupCellRenderer",
      cellRendererParams: {
        innerRenderer: QuickActionsMenuComponent,
        innerRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getLocationLink,
          actions: [
            {
              icon: "close",
              tooltip: TextConstants.REMOVE,
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: async (data: ILocationExtendedWithProducts) => {
                await this.onRemoveLocation(data.linkId);
              },
            },
            {
              icon: "deployed_code",
              tooltip: $localize`Add products`,
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: async (data: ILocationExtendedWithProducts) => {
                this.selectedAttachProductsLocationId.set(data.id);
                this.overlay.openSideMenu(this.linkedAttachProductsSideMenu);
                this.selectedTable.grid.api.redrawRows();
                await this.onSearchAvailableProducts();
              },
            },
          ],
        },
      },
    };

    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.NAME,
        field: "name",
        suppressSizeToFit: true,
        suppressAutoSize: true,
        lockVisible: true,
        rowDrag: false,
        minWidth: 200,
        ...nameColumnRendererParams,
      },
      {
        headerName: TextConstants.ORGANISATION,
        field: "organisationName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "organisationId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      ColumnUtils.chips("Type(s)", "types", {
        textParam: "type",
        showIcon: (locationType) => locationType.pointOfOrigin,
        icon: "target",
        iconTooltip: TextConstants.POINT_OF_ORIGIN_CHIP,
      }),
      {
        headerName: TextConstants.COUNTRY,
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
    ];

    this.columnDefs.set(columnDefs);
  };

  public async onSearchAvailableLocations(searchText?: string): Promise<void> {
    this.searchAvailableLocationsText = searchText || undefined;
    await this.onReloadAvailableLocations();
  }

  public onReloadAvailableLocations = async (): Promise<void> => {
    this.isLoadingAvailableLocations.set(true);
    this.availableLocations.set([]);

    const filteredLocations = await this.locationOverlayService.getLocations(
      this.searchAvailableLocationsText || undefined,
      this.allCountries(),
    );

    const selectedLocations =
      this.selectedTabIndex() === 0 ? this.suppliedByLocations() : this.suppliedToLocations();

    this.availableLocations.set(this.getAvailableLocations(filteredLocations, selectedLocations));

    this.isLoadingAvailableLocations.set(false);
  };

  private getAvailableLocations(
    filteredLocations: ILocationExtended[],
    selectedLocations: ILocationExtended[],
  ): ILocationExtended[] {
    return filteredLocations.filter((location) => {
      if (
        location.id !== this.locationId() &&
        !selectedLocations.some((i) => i.id === location.id)
      ) {
        return location;
      }

      return false;
    });
  }

  public async onAddNewLocation(): Promise<void> {
    const result = await this.overlay.openDialog<
      {
        hasSaved: boolean;
        location?: ILocationDetails;
      },
      { countryOptions: ISelectOption[] }
    >(AddLocationDialogComponent, {
      countryOptions: this.allCountries(),
    });

    if (result?.hasSaved) {
      const newLocation = await this.locationOverlayService.getLocation(
        result.location.id,
        this.allCountries(),
      );

      await this.onAddLocation(newLocation);
    }
  }

  public addAllAvailableLocations = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${this.availableLocations().length}:count:</b> locations?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingAvailableLocations.set(true);
        this.setLoadingLocationLinks(true);

        const promises = this.availableLocations().map(async (location) => {
          await this.onAddLocation(location, false);
        });

        await Promise.all(promises);

        this.notification.showSuccess($localize`Links created`);

        await this.loadLocationsLinks();
        await this.onReloadAvailableLocations();
        this.changes.emit();
      }
    });
  };

  public onSearchAvailableProducts = async (searchText?: string): Promise<void> => {
    this.searchAvailableProductsText = searchText || undefined;
    await this.onReloadAvailableProducts();
  };

  public getRowHeight: ((params: RowHeightParams) => number | undefined | null) | undefined = (
    params: RowHeightParams,
  ) => {
    const isDetailRow = params.node.detail;

    if (!isDetailRow) {
      return undefined;
    }

    const { attachedProductsData } = params.data;
    const { cellRenderer } = params.data.attachedProductsData;

    if (cellRenderer) {
      if (cellRenderer.displayShowMoreButton) {
        return (
          cellRenderer.attachmentsToDisplay.length * this.productRowHeight + this.productRowHeight
        );
      } else {
        return cellRenderer.attachmentsToDisplay.length * this.productRowHeight;
      }
    }

    if (attachedProductsData.attachments.length > this.defaultMaxNumberProducts) {
      return this.productRowHeight * this.defaultMaxNumberProducts + this.productRowHeight;
    }

    return attachedProductsData.attachments.length * this.productRowHeight;
  };

  public get selectedTable(): TableComponent {
    return this.selectedTabIndex() === 1 ? this.suppliedToTable : this.suppliedByTable;
  }

  public onAttachAllProducts = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${this.availableProducts().length}:count:</b> products?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoadingAvailableProducts.set(true);

        const promises = this.availableProducts().map((product) =>
          this.onAttachProduct(product.id, false),
        );

        await Promise.all(promises);

        this.notification.showSuccess($localize`Relations created`);

        this.selectedTable.grid.api.resetRowHeights();
        this.selectedTable.grid.api.redrawRows();

        await this.onReloadAvailableProducts();
      }
    });
  };

  public onTabChanged = async (id: number): Promise<void> => {
    this.overlay.closeSideMenu();
    this.selectedTabIndex.set(id);
    this.selectedAttachProductsLocationId.set(null);

    await this.loadLocationsLinks();
    await this.onReloadAvailableLocations();
  };

  public async onAddLocation(location: ILocationExtended, isSingleAdd = true): Promise<void> {
    try {
      if (isSingleAdd) {
        this.isLoadingAvailableLocations.set(true);
      }

      const locationDirection =
        this.selectedTabIndex() === 0 ? LinkDirectionEnum.FROM : LinkDirectionEnum.TO;

      await this.locationOverlayService.createLocationLink(
        this.locationId(),
        location.id,
        locationDirection,
      );

      if (locationDirection === LinkDirectionEnum.FROM) {
        this.locationOverlayService.linkedLocationSuppliedByCounter.update((value) => value + 1);
      } else {
        this.locationOverlayService.linkedLocationSuppliedToCounter.update((value) => value + 1);
      }

      if (isSingleAdd) {
        this.notification.showSuccess($localize`Link created`);
        await this.loadLocationsLinks();
        await this.onReloadAvailableLocations();
        this.changes.emit();
      }
    } catch {
      if (isSingleAdd) {
        this.notification.showError($localize`An error occurred when adding a link`);
      }
    }
  }

  private async onRemoveLocation(locationLinkId: string): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this location from the linked locations?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result !== ConfirmDialogResponseEnum.CONFIRM) {
        return;
      }

      this.isLoadingAvailableLocations.set(true);

      const locationDirection =
        this.selectedTabIndex() === 0 ? LinkDirectionEnum.FROM : LinkDirectionEnum.TO;

      this.setLoadingLocationLinks(true);

      await this.locationOverlayService.removeLocationLink(locationLinkId);

      this.changes.emit();

      if (locationDirection === LinkDirectionEnum.FROM) {
        this.locationOverlayService.linkedLocationSuppliedByCounter.update((value) => value - 1);
      } else {
        this.locationOverlayService.linkedLocationSuppliedToCounter.update((value) => value - 1);
      }

      if (this.selectedAttachProductsLocation?.linkId === locationLinkId) {
        this.overlay.closeSideMenu();
        this.selectedAttachProductsLocationId.set(null);
      }

      await this.loadLocationsLinks();
      await this.onReloadAvailableLocations();
    });
  }

  public getRowClass: (params: RowClassParams<ILocationExtendedWithProducts>) => string[] = (
    params: RowClassParams<ILocationExtendedWithProducts>,
  ) => {
    const rowClass: string[] = [];

    if (params.data.id === this.selectedAttachProductsLocationId()) {
      rowClass.push("selected-link-row");
    }

    if (!params.data.attachedProductsData?.attachments?.length) {
      rowClass.push("no-children");
    }

    return rowClass;
  };

  public onClickRow = (row: ILocationExtendedWithProducts): void => {
    this.routerService.navigate(this.routerService.getLocationLink(row.id));
  };

  public onAddNewProduct = async (): Promise<void> => {
    this.routerService.openNewTab(this.routerService.getProductLink(undefined, false));
  };

  public onCloseAttachProductsSideMenu = (): void => {
    this.selectedAttachProductsLocationId.set(null);
  };

  public onRemoveProduct = async (locationId: string, attachmentId: string): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this product?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result !== ConfirmDialogResponseEnum.CONFIRM) {
        return;
      }

      try {
        const location = this.suppliedLocations().find((location) => location.id === locationId);

        if (!location) {
          return;
        }

        const attachmentIndex = location.attachedProductsData.attachments.findIndex(
          (attachment) => attachment.attachmentId === attachmentId,
        );

        if (attachmentIndex === -1) {
          return;
        }

        this.isLoadingAvailableProducts.set(true);

        await this.attachmentsService.delete(attachmentId);

        location.attachedProductsData.attachments.splice(attachmentIndex, 1);

        this.notificationService.showSuccess($localize`Relation removed`);

        this.selectedTable.grid.api.resetRowHeights();
        this.selectedTable.grid.api.redrawRows();

        await this.onReloadAvailableProducts();
      } catch (error) {
        this.notificationService.showError(error);
      }
    });
  };

  private get selectedAttachProductsLocation(): ILocationExtendedWithProducts {
    return this.suppliedLocations().find(
      (location) => location.id === this.selectedAttachProductsLocationId(),
    );
  }

  private setLoadingLocationLinks = (value: boolean): void => {
    switch (this.selectedTabIndex()) {
      case 0:
        this.isLoadingSuppliedBy.set(value);
        break;
      case 1:
        this.isLoadingSuppliedTo.set(value);
        break;
    }
  };

  public onAttachProduct = async (
    productId: string,
    isSingleAdd: boolean = true,
  ): Promise<void> => {
    const location = this.suppliedLocations().find(
      (location) => location.id === this.selectedAttachProductsLocationId(),
    );
    const product = await this.productsService.get(productId);

    if (!location || !product) {
      return;
    }

    try {
      if (isSingleAdd) {
        this.isLoadingAvailableProducts.set(true);
      }

      const payload = CommonUtils.getSaveAttachmentPayload(
        this.activeOrganisationId,
        AttachmentTargetEnum.LINK,
        this.selectedAttachProductsLocation.linkId,
        AttachmentTypeEnum.PRODUCT,
        productId,
      );

      const attachment = await this.attachmentsService.create(payload);

      location.attachedProductsData.attachments.unshift({ attachmentId: attachment.id, product });

      if (isSingleAdd) {
        this.notificationService.showSuccess($localize`Relation created`);

        this.selectedTable.grid.api.resetRowHeights();
        this.selectedTable.grid.api.redrawRows();

        await this.onReloadAvailableProducts();
      }
    } catch {
      if (isSingleAdd) {
        this.notification.showError($localize`An error occurred when attaching a product`);
      }
    }
  };

  public onReloadAvailableProducts = async (): Promise<void> => {
    this.isLoadingAvailableProducts.set(true);
    this.availableProducts.set([]);

    try {
      const filteredProducts = (
        await this.productsService.getPage(
          this.searchAvailableProductsText || undefined,
          CommonConstants.MAX_API_GET_ITEMS_SIZE,
          0,
          undefined,
          RecordStateEnum.ACTIVE,
        )
      ).content;

      this.availableProducts.set(this.getAvailableProducts(filteredProducts));

      this.isLoadingAvailableProducts.set(false);
    } catch (error) {
      this.notification.showError(error);
    }
  };

  private getAvailableProducts = (filteredProducts: IProduct[]) => {
    return filteredProducts.filter((currentProduct) => {
      const location = this.suppliedLocations().find(
        (location) => location.id === this.selectedAttachProductsLocationId(),
      );

      if (!location) {
        return false;
      }

      return !location.attachedProductsData.attachments.some(
        (attachment) => attachment.product.id === currentProduct.id,
      );
    });
  };

  private async loadLocationsLinks(): Promise<void> {
    const selectedTabIndex = this.selectedTabIndex();

    const linkDirection = selectedTabIndex === 0 ? LinkDirectionEnum.TO : LinkDirectionEnum.FROM;

    if (!this.locationId()) {
      return;
    }

    this.setLoadingLocationLinks(true);

    const locationLinks = (await this.locationOverlayService.loadLocationsLinks(
      this.locationId(),
      linkDirection,
      this.allLocationTypes(),
      this.allCountries(),
    )) as ILocationExtended[];

    const rowData = await this.getLocationLinksWithAttachments(locationLinks);

    switch (selectedTabIndex) {
      case 0:
        this.suppliedByLocations.set(rowData);
        break;
      case 1:
        this.suppliedToLocations.set(rowData);
        break;
    }
    this.setLoadingLocationLinks(false);
  }

  private async getLocationLinksWithAttachments(
    locationLinked: ILocationExtendedWithProducts[],
  ): Promise<ILocationExtendedWithProducts[]> {
    const rowData: ILocationExtendedWithProducts[] = [];

    const promises = locationLinked.map(async (row) => {
      const attachments = await this.getProductAtachmentsForLink(row.linkId);

      row.attachedProductsData = { attachments };

      rowData.push(row);
    });

    await Promise.all(promises);

    return rowData;
  }

  private async getProductAtachmentsForLink(linkId: string): Promise<IAttachmentWithProduct[]> {
    const attachments = await this.attachmentsService.getAll(
      AttachmentTypeEnum.PRODUCT,
      CommonUtils.getTargetUri(this.activeOrganisationId, AttachmentTargetEnum.LINK, linkId),
      null,
      undefined,
      undefined,
      undefined,
    );

    if (!attachments.length) {
      return [];
    }

    const attachmentsWithProducts: IAttachmentWithProduct[] = [];

    const promises = attachments.map(async (attachment) => {
      const product = await this.productsService.get(
        CommonUtils.getUriId(attachment.attachmentUri),
      );

      attachmentsWithProducts.push({ attachmentId: attachment.id, product });
    });

    await Promise.all(promises);

    return attachmentsWithProducts;
  }
}
