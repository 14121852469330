import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  effect,
  inject,
  signal,
  viewChildren,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { RiskLevelSetsApiService } from "@components/shared/risk-level-sets/api";
import {
  RiskLevel,
  RiskLevelSet,
  RiskLevelSetPayload,
} from "@components/shared/risk-level-sets/models";
import { TextConstants } from "@shared/constants";
import { TagsColorEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { NotificationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

export interface AddEditRiskLevelSetDialogData {
  riskLevelSet?: RiskLevelSet;
}

interface ColorOption extends ISelectOption {
  value: TagsColorEnum;
}

interface RiskLevelFormGroup {
  color: FormControl<ColorOption>;
  title: FormControl<string>;
}

@Component({
  standalone: false,
  templateUrl: "./add-edit-risk-level-set-dialog.component.html",
  styleUrl: "./add-edit-risk-level-set-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditRiskLevelSetDialogComponent {
  private readonly fb = inject(FormBuilder);

  private readonly dialogRef: MatDialogRef<AddEditRiskLevelSetDialogComponent> =
    inject(MatDialogRef);

  private readonly data: AddEditRiskLevelSetDialogData = inject(MAT_DIALOG_DATA);

  private readonly api = inject(RiskLevelSetsApiService);

  private readonly notification = inject(NotificationService);

  private readonly titleFields = viewChildren("titleField", { read: ElementRef });

  readonly form = this.fb.group({
    name: [
      "",
      [CustomValidators.required],
      [CustomValidators.entityAlreadyExists(this.api, this.data?.riskLevelSet?.id ?? null)],
    ],
    riskLevels: this.fb.array<FormGroup<RiskLevelFormGroup>>([]),
  });

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    colorPh: $localize`Color`,
    titlePh: $localize`Title`,
  };

  private readonly titleFieldIndex = signal<number>(-1);

  readonly isLoading = signal(true);

  readonly isEditing = signal(false);

  readonly colorOptions: ColorOption[] = Object.values(TagsColorEnum).map((value) => ({
    label: CommonUtils.enumToText(TagsColorEnum[value]),
    value,
  }));

  private readonly colorMap = this.colorOptions.reduce(
    (map, { value, label }) => ({
      ...map,
      [value]: label,
    }),
    {},
  );

  public readonly minAmountOfRiskLevels = 2;

  public readonly maxAmountOfRiskLevels = 10;

  get isAddButtonDisabled() {
    return this.riskLevels.length === this.maxAmountOfRiskLevels;
  }

  get riskLevels() {
    return this.form.controls.riskLevels;
  }

  constructor() {
    if (this.data?.riskLevelSet?.id) {
      this.isEditing.set(true);
    }

    if (this.isEditing()) {
      const { name, riskLevels } = this.data.riskLevelSet;

      this.form.patchValue({ name });

      riskLevels.forEach((riskLevel) => this.add(riskLevel));
    } else {
      for (let i = 0; i < this.minAmountOfRiskLevels; i++) {
        this.add();
      }
    }

    this.isLoading.set(false);

    effect(() => this.focusInput(this.titleFieldIndex()));
  }

  add(riskLevel?: RiskLevel) {
    const group = this.fb.group({
      title: ["", [CustomValidators.required, CustomValidators.maxLength(50)]],
      color: this.fb.control(null, CustomValidators.required),
    });

    if (riskLevel) {
      group.setValue({
        title: riskLevel.title,
        color: { label: this.colorMap[riskLevel.color], value: riskLevel.color },
      });
    }

    group.updateValueAndValidity();

    this.riskLevels.push(group);
    this.form.updateValueAndValidity();

    if (!riskLevel) {
      this.titleFieldIndex.set(this.riskLevels.length - 1);
    }
  }

  remove(index: number) {
    if (index > this.minAmountOfRiskLevels - 1) {
      this.riskLevels.removeAt(index);
      this.form.updateValueAndValidity();
    }
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isLoading.set(true);

    try {
      const riskLevelSet = await this.api.createOrUpdate(
        this.buildPayload(),
        this.data?.riskLevelSet?.id,
      );

      this.notification.showSuccess(
        this.isEditing() ? $localize`Risk level set modified` : $localize`Risk level set created`,
      );
      this.close(riskLevelSet);
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  close(riskLevelSet?: RiskLevelSet) {
    this.dialogRef.close(riskLevelSet);
  }

  private buildPayload(): RiskLevelSetPayload {
    const { name, riskLevels } = this.form.getRawValue();

    return {
      name,
      riskLevels: riskLevels.map(({ color, title }) => ({ title, color: color.value })),
      recordState: undefined,
    };
  }

  private focusInput(index: number) {
    if (this.titleFieldIndex() >= 0 && this.titleFields().length > 0) {
      const field = this.titleFields()[index];

      if (field) {
        const input = field.nativeElement as HTMLInputElement;

        input.querySelector("input").focus();
      }
    }
  }
}
