import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

import { Subscription } from "rxjs";
import { FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import { AuthenticationService, FeatureFlagService, LoginService } from "src/app/shared/services";
import { BrowserUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { CustomValidators } from "../../../shared/validators";

@Component({
  standalone: false,
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
    password: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public readonly routingEnum = RouteEnum;

  public isLoading = signal(true);

  public isApiAvailable = true;

  public invitation: any = null;

  public hasInvitation = false;

  public readonly isFirefox: boolean = BrowserUtils.isFirefox();

  private returnUrl: string = null;

  private subscriptions = new Subscription();

  public readonly translations: any = {
    emailLabel: TextConstants.EMAIL,
    passwordLabel: TextConstants.PASSWORD,
  };

  readonly isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.isApiAvailable = this.authenticationService.getIsApiAvailable();
    this.authenticationService.logout(false);

    const navState = this.router.getCurrentNavigation()?.extras?.state;

    if (navState && navState["email"]) {
      this.formGroup.controls["email"].setValue(navState["email"]);
    }
  }

  public async ngOnInit(): Promise<void> {
    this.returnUrl = this.route.snapshot.queryParams["returnUrl"] ?? "";

    if (this.returnUrl) {
      const fullUrl = new URL(`${location.origin}${this.returnUrl}`);
      const acceptInvitationId = fullUrl.searchParams.get("acceptInvitationId");

      if (acceptInvitationId) {
        this.invitation = {
          id: acceptInvitationId,
          organisationName: fullUrl.searchParams.get("org") ?? "N/A",
        };
        this.hasInvitation = this.invitation?.id && this.invitation?.organisationName;
      }
    }

    this.isLoading.set(false);
  }

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);
    const email = this.formGroup.controls["email"].value;
    const password = this.formGroup.controls["password"].value;

    const fullUrl = new URL(`${location.origin}${this.returnUrl}`);
    const redirectToPath = fullUrl.pathname;
    const redirectToExtras: NavigationExtras = {};
    const searchParams = new URLSearchParams(this.returnUrl.replace(`${redirectToPath}`, ""));

    if (searchParams) {
      redirectToExtras.queryParams = [];
      searchParams.forEach((value: string, key: string) => {
        redirectToExtras.queryParams[key] = value;
      });
    }

    await this.loginService
      .login(email, password, redirectToPath, redirectToExtras)
      .catch((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.notificationService.showError(TextConstants.EMAIL_OR_PASS_INCORRECT);
        } else {
          this.notificationService.showError(TextConstants.SERVICE_UNAVAILABLE);
        }
      })
      .finally(async () => {
        this.isLoading.set(false);
      });
  };

  public onForgotPasswordClicked = async (): Promise<void> => {
    await this.router.navigate([`/${RouteEnum.FORGOT_PASSWORD}`], {
      state: { email: this.formGroup.controls["email"].value },
    });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
