import { inject, Injectable } from "@angular/core";

import { ActiveToast, ToastrService } from "ngx-toastr";
import { Subject, debounceTime, BehaviorSubject, Observable } from "rxjs";

import { NotificationBatchActionComponent } from "@components/shared/notification/notification-batch-action.component";
import { CommonConstants, TextConstants } from "@shared/constants";
import { IIdName } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class BatchActionService {
  private actionData: BatchActionModel.IBatchActionData;

  public dataSubject$ = new BehaviorSubject<BatchActionModel.IBatchActionData>(null);

  public closeToastSubject = new Subject<boolean>();

  private toast: ActiveToast<any> = undefined;

  private toastService: ToastrService = inject(ToastrService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private notificationService: NotificationService = inject(NotificationService);

  constructor() {
    this.closeToastSubject
      .pipe(debounceTime(CommonConstants.NOTIFICATION_DOWNLOADS_DURATION_MS))
      .subscribe((isClosing: boolean) => {
        if (isClosing) {
          this.closeToast();
        }
      });
  }

  public get closeToastSubject$(): Observable<unknown> {
    return this.closeToastSubject.asObservable();
  }

  public isInProgress(): boolean {
    if (!this.actionData?.records?.length) {
      return false;
    }

    return this.actionData.records.some(
      (record) => record.status === BatchActionModel.BatchStatusEnum.PENDING,
    );
  }

  public haveAllSucceeded(): boolean {
    if (!this.actionData?.records?.length) {
      return false;
    }

    return this.actionData.records.every(
      (record) => record.status === BatchActionModel.BatchStatusEnum.SUCCESS,
    );
  }

  public haveAllFailed(): boolean {
    if (!this.actionData?.records?.length) {
      return false;
    }

    return this.actionData.records.every(
      (record) => record.status === BatchActionModel.BatchStatusEnum.ERROR,
    );
  }

  public performBatchAction(
    baseRecords: IIdName[],
    actionDetails: BatchActionModel.IBatchActionDetails,
  ) {
    this.closeToast();

    this.actionData = {
      records: [],
      actionDetails,
    };

    baseRecords.forEach((baseRecord) => {
      const record: BatchActionModel.IBatchRecord = {
        id: baseRecord.id,
        name: baseRecord.name,
        status: BatchActionModel.BatchStatusEnum.PENDING,
        serviceFn: actionDetails.serviceFn,
      };

      this.add(record);
    });
  }

  public add(record: BatchActionModel.IBatchRecord): void {
    if (this.authenticationService.haveTokensExpired()) {
      this.notificationService.showError(TextConstants.SESSION_HAS_EXPIRED);
      this.authenticationService.logout();

      return;
    }

    const existingRecord = this.actionData.records.find((r) => r.id === record.id);

    if (existingRecord) {
      this.showOrUpdateToast(record);
    } else {
      this.updateRecords([...this.actionData.records, record]);
      this.showOrUpdateToast(record);
    }
  }

  private showOrUpdateToast(record: BatchActionModel.IBatchRecord): void {
    this.closeToastSubject.next(false);

    if (this.toast?.toastRef) {
      this.dataSubject$.next(this.actionData);
      this.beginAction(record);

      return;
    }

    this.toast = this.toastService.info(undefined, undefined, {
      toastComponent: NotificationBatchActionComponent,
      disableTimeOut: true,
      tapToDismiss: false,
      onActivateTick: true,
      payload: this.dataSubject$.asObservable(),
    });

    this.toast.toastRef.afterActivate().subscribe(() => {
      this.beginAction(record);
    });
  }

  private async beginAction(record: BatchActionModel.IBatchRecord): Promise<void> {
    record.status = BatchActionModel.BatchStatusEnum.PENDING;

    const recordIndex = this.actionData.records.findIndex((r) => r.id === record.id);

    if (recordIndex !== -1) {
      this.actionData.records[recordIndex] = record;
      this.updateRecords(this.actionData.records);
    }

    try {
      await record.serviceFn(record.id);
      record.status = BatchActionModel.BatchStatusEnum.SUCCESS;
    } catch {
      record.status = BatchActionModel.BatchStatusEnum.ERROR;
    }

    this.updateRecords(this.actionData.records);
  }

  private closeToast(): void {
    if (this.toast?.toastRef) {
      this.updateRecords([], true);
      this.closeToastSubject.next(false);
      this.toastService.remove(this.toast.toastId);
      this.toast = undefined;
    }
  }

  private updateRecords(
    records: BatchActionModel.IBatchRecord[],
    clearactionData: boolean = false,
  ): void {
    if (clearactionData) {
      this.actionData = null;
    } else {
      this.actionData.records = records;

      if (!this.isInProgress()) {
        if (this.haveAllSucceeded()) {
          this.toast.toastRef.componentInstance.toastClasses = "toast-success ngx-toastr";
          this.closeToastSubject.next(true);
        } else if (this.haveAllFailed()) {
          this.toast.toastRef.componentInstance.toastClasses = "toast-error ngx-toastr";
        } else {
          this.toast.toastRef.componentInstance.toastClasses = "toast-warning ngx-toastr";
        }
      }
    }

    this.dataSubject$.next(this.actionData);
  }

  public cancelAction(): void {
    this.closeToast();
  }

  public retryFailedActions(): void {
    this.toast.toastRef.componentInstance.toastClasses = "toast-info ngx-toastr";

    const recordsToRetry = [
      ...this.actionData.records.filter(
        (record) => record.status === BatchActionModel.BatchStatusEnum.ERROR,
      ),
    ];

    this.actionData.records = [];
    recordsToRetry.forEach((record) => this.add(record));
  }
}
