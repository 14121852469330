<div class="flex-column add-gap fs-14">
  <div>
    <app-form-label [label]="translations.internalReferenceNumber"></app-form-label>
    <app-form-link [link]="link()" [styledLink]="true" [openInNewTab]="true">
      {{ eudrDdsReport().statement.internalReferenceNumber }}
    </app-form-link>
  </div>
  @if (canAddModifyEntities()) {
    <div>
      <app-form-label [label]="translations.status"></app-form-label>
      @if (this.hasDdsStatusUpdateFailed() === null) {
        <app-loader containerClass="size-small"></app-loader>
      } @else if (this.hasDdsStatusUpdateFailed()) {
        <div class="error" i18n>Couldn't retrieve the status</div>
      } @else {
        <div class="tags-container">
          <app-tag [class]="statusTagClass()" [tag]="{ label: eudrDdsReport().status }"></app-tag>
        </div>
      }
    </div>
    @if (!isPending() && !isSubmitted()) {
      <div>
        <app-form-label [label]="translations.tracesReferenceNumber"></app-form-label>
        {{ eudrDdsReport().tracesReferenceNumber || "-" }}
      </div>
      <div>
        <app-form-label [label]="translations.tracesVerificationNumber"></app-form-label>
        <div class="flex add-gap">
          <div>
            @if (displayVerificationNumber()) {
              {{ eudrDdsReport().tracesVerificationNumber || "-" }}
            } @else {
              ******
            }
          </div>
          <mat-icon class="clickable" (click)="toggleDisplayVerificationNumber()">
            {{ visibilityIcon() }}
          </mat-icon>
        </div>
      </div>
    }
  }
</div>
