import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  signal,
  ViewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { cloneDeep, isEqual } from "lodash";
import {
  CriteriaTypeEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RouteEnum,
  TagSelectorTypeEnum,
  TargetPathEnum,
} from "src/app/shared/enums";
import { ICondition, IConditionAny, IConditionEq, IRuleset } from "src/app/shared/interfaces";
import { RulesetUtils } from "src/app/shared/utils/ruleset.utils";
import { CustomValidators } from "src/app/shared/validators";

import { TagsSelectorDialogComponent } from "@components/shared";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import { FormUtils } from "@shared/utils";

import { RulesetBaseClass } from "../ruleset-base.class";
import { RulesetDeliveryInfoComponent } from "../ruleset-info/ruleset-delivery-info.component";

@Component({
  standalone: false,
  templateUrl: "./edit-delivery-ruleset.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDeliveryRulesetComponent extends RulesetBaseClass {
  @ViewChild("rulesetDeliveryInfo") rulesetDeliveryInfo: RulesetDeliveryInfoComponent;

  public formGroup: UntypedFormGroup;

  public isEditing = signal<boolean>(false);

  private selectedDocumentTypesIds = signal<string[]>([]);

  private originalSelectedDocumentTypesIds = signal<string[]>([]);

  private hasInitialValuesChanged = signal<boolean>(false);

  private originalDeliveryFromCriteria = signal<CriteriaTypeEnum>(undefined);

  private originalDeliveryToCriteria = signal<CriteriaTypeEnum>(undefined);

  private destroyRef = inject(DestroyRef);

  public readonly translations: any = {
    titleAdd: $localize`Add ruleset`,
    titleEdit: $localize`Edit ruleset`,
    titleBackText: $localize`Back to Rulesets`,
  };

  constructor(private dialog: MatDialog) {
    super();
  }

  public openDocumentTypeDialog() {
    const dialogRef = this.dialog.open(TagsSelectorDialogComponent, {
      autoFocus: false,
      data: {
        tagUrl: this.documentTypesService.baseUrl,
        type: TagSelectorTypeEnum.DOCUMENT_TYPES,
        placeholder: "Document type",
        canDelete: false,
        title: $localize`Add document types`,
        selectedIds: [...this.selectedDocumentTypesIds()],
        isNewValueAllowed: false,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(
        async (response: {
          hasSaved: boolean;
          selectedIds: string[];
          isNewTagCreated: boolean;
        }) => {
          if (response.hasSaved && response.isNewTagCreated) {
            this.allDocumentTypes.set(await this.getAllDocumentTypes());
          }
          if (response.hasSaved) {
            this.selectedDocumentTypesIds.set([...response.selectedIds]);
            this.selectedDocumentTypes.set(
              this.allDocumentTypes().filter((d) => this.selectedDocumentTypesIds().includes(d.id)),
            );
            this.updateSelectedListsChangedState();
          }
        },
      );
  }

  public removeDocumentType(documentTypeId: string) {
    this.selectedDocumentTypes.set(
      this.selectedDocumentTypes().filter((type) => type.id !== documentTypeId),
    );
    this.selectedDocumentTypesIds.set(
      this.selectedDocumentTypesIds().filter((typeId) => typeId !== documentTypeId),
    );
    this.updateSelectedListsChangedState();
  }

  public async loadData(rulesetId?: string): Promise<void> {
    this.isLoading.set(true);
    try {
      if (rulesetId) {
        this.element.set(await this.rulesetsService.get(rulesetId));
        this.isEditing.set(true);
        if (this.element().recordState === RecordStateEnum.ARCHIVED) {
          this.notificationService.showError(TextConstants.EDIT_ARCHIVED_RECORD_ERROR);
          await this.router.navigate([`/${RouteEnum.ADMIN_RULESETS_DETAILS}/${this.element().id}`]);

          return;
        }
        this.deliveryFromCriteria.set(
          RulesetUtils.tagExistsInCondition(this.element().condition, TargetPathEnum.FROM_LOCATION)
            ? CriteriaTypeEnum.EXACT
            : CriteriaTypeEnum.PARAMETERS,
        );

        this.deliveryToCriteria.set(
          RulesetUtils.tagExistsInCondition(this.element().condition, TargetPathEnum.TO_LOCATION)
            ? CriteriaTypeEnum.EXACT
            : CriteriaTypeEnum.PARAMETERS,
        );

        this.setSelectedLocationFromCountries();
        this.setSelectedLocationFromTypes();
        this.setSelectedLocationToCountries();
        this.setSelectedLocationToTypes();
        this.setSelectedLocationsFrom();
        this.setSelectedLocationsTo();
        this.setSelectedDocumentTypes();
        this.selectedDocumentTypesIds.set(this.selectedDocumentTypes().map((type) => type.id));
      }

      this.setUpForm();
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error, !!rulesetId);
    }
  }

  public async reloadLocationTypes() {
    this.allLocationTypes.set(await this.getAllLocationTypes());
  }

  public async onSave(isSaveOnly: boolean = true) {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);
    try {
      const payload = await this.getPayload();
      const ruleset = await this.rulesetsService.createOrUpdate(payload, this.element()?.id);

      this.hasFormValuesChanged = false;
      this.hasInitialValuesChanged.set(false);
      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Ruleset modified` : $localize`Ruleset created`,
      );
      if (isSaveOnly) {
        if (this.isEditing()) {
          if (this.newTypesCreated()) {
            await this.reloadLocationTypes();
            this.newTypesCreated.set(false);
          }
          await this.loadData(ruleset.id);
        } else {
          await this.router.navigate([`/${RouteEnum.ADMIN_RULESETS_EDIT_DELIVERY}/${ruleset.id}`], {
            replaceUrl: true,
          });
        }
      }
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public updateSelectedListsChangedState = (): void => {
    this.hasInitialValuesChanged.set(
      !isEqual(this.originalSelectedDocumentTypesIds(), this.selectedDocumentTypesIds()?.sort()) ||
        (this.isEditing() &&
          (this.originalDeliveryFromCriteria() !== this.deliveryFromCriteria() ||
            this.originalDeliveryToCriteria() !== this.deliveryToCriteria())),
    );
  };

  public deliveryFromCriteriaChanged(criteriaType: CriteriaTypeEnum): void {
    this.deliveryFromCriteria.set(criteriaType);
  }

  public deliveryToCriteriaChanged(criteriaType: CriteriaTypeEnum): void {
    this.deliveryToCriteria.set(criteriaType);
  }

  override requiresConfirmation(): boolean {
    return this.hasFormValuesChanged || this.hasInitialValuesChanged();
  }

  override async saveBeforeClosing(): Promise<void> {
    return this.onSave(false);
  }

  public override isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return (
      this.formGroup?.invalid ||
      !(this.hasFormValuesChanged || this.hasInitialValuesChanged()) ||
      !this.selectedDocumentTypesIds()?.length
    );
  };

  private setUpForm() {
    const entityExistsValidatorArgs: any = {
      resourceType: ResourceTypeEnum.DELIVERY,
    };

    this.originalSelectedDocumentTypesIds.set(cloneDeep(this.selectedDocumentTypesIds()?.sort()));
    this.originalDeliveryFromCriteria.set(this.deliveryFromCriteria());
    this.originalDeliveryToCriteria.set(this.deliveryToCriteria());

    const fromLocationTypesIds = this.selectedLocationFromTypes()?.map((l) => ({
      value: l.id,
    }));
    const toLocationTypesIds = this.selectedLocationToTypes()?.map((l) => ({
      value: l.id,
    }));
    const fromCountriesCodes = this.selectedLocationFromCountries();
    const toCountriesCodes = this.selectedLocationToCountries();
    const selectedLocationsFromIds: InputSelectOption[] = this.selectedLocationsFrom()?.map(
      (l) => ({
        label: l.name,
        value: l.id,
      }),
    );
    const selectedLocationsToIds = this.selectedLocationsTo()?.map((l) => ({
      label: l.name,
      value: l.id,
    }));

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name ?? "", CustomValidators.required, [
        CustomValidators.entityAlreadyExists(
          this.rulesetsService,
          this.element()?.id ?? this.route.snapshot.params["id"] ?? null,
          entityExistsValidatorArgs,
        ),
      ]),
      description: new UntypedFormControl(this.element()?.description ?? ""),
      fromLocationTypes: new UntypedFormControl(fromLocationTypesIds || []),
      fromCountryCodes: new UntypedFormControl(fromCountriesCodes || []),
      toLocationTypes: new UntypedFormControl(toLocationTypesIds || []),
      toCountryCodes: new UntypedFormControl(toCountriesCodes || []),
      fromLocations: new UntypedFormControl(selectedLocationsFromIds || []),
      toLocations: new UntypedFormControl(selectedLocationsToIds || []),
    });

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;
    this.formGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.value);
    });
  }

  private async getPayload(): Promise<IRuleset> {
    const { name, description } = this.formGroup.value;
    const documentTypesUris = this.selectedDocumentTypesIds().map(
      (typeId) => `/organisations/${this.activeOrganisationId}/document-types/${typeId}`,
    );
    const condition: ICondition = await this.getRulesetConditions();

    const expectations = documentTypesUris.map((uri) => ({
      path: TargetPathEnum.DOCUMENT,
      condition: {
        and: [
          {
            eq: {
              path: TargetPathEnum.TYPE,
              value: uri,
            },
          },
        ],
      },
    }));

    return {
      name,
      description: description || undefined,
      active: true,
      resourceType: ResourceTypeEnum.DELIVERY,
      condition,
      expectations,
    };
  }

  private async getRulesetConditions(): Promise<ICondition> {
    const fromLocationTypesValue = this.formGroup.get("fromLocationTypes").value;
    const toLocationTypesValue = this.formGroup.get("toLocationTypes").value;
    const fromLocationTypesSelection: InputSelectOption[] = fromLocationTypesValue?.filter(
      (type: InputSelectOption) => type.value,
    );
    const fromLocationTypesToCreate: InputSelectOption[] = fromLocationTypesValue?.filter(
      (type: InputSelectOption) => !type.value,
    );

    if (fromLocationTypesToCreate?.length) {
      this.newTypesCreated.set(true);
      for (const type of fromLocationTypesToCreate) {
        const newType = await this.locationTypesService.createOrUpdate({
          type: type.label,
          pointOfOrigin: false,
        });

        fromLocationTypesSelection.push({ label: newType.type, value: newType.id });
      }
    }

    const fromLocationTypesUris = fromLocationTypesSelection.map(
      (type: InputSelectOption) =>
        `/organisations/${this.activeOrganisationId}/location-types/${type.value}`,
    );

    const toLocationTypesSelection: InputSelectOption[] = toLocationTypesValue?.filter(
      (type: InputSelectOption) => type.value,
    );
    const toLocationTypesToCreate: InputSelectOption[] = toLocationTypesValue?.filter(
      (type: InputSelectOption) => !type.value,
    );

    if (toLocationTypesToCreate?.length) {
      this.newTypesCreated.set(true);
      for (const type of toLocationTypesToCreate) {
        const typeAlreadyCreated = fromLocationTypesSelection?.find(
          (t) => t.label?.toLowerCase() === type.label?.toLowerCase(),
        );

        if (!typeAlreadyCreated) {
          const newType = await this.locationTypesService.createOrUpdate({
            type: type.label,
            pointOfOrigin: false,
          });

          toLocationTypesSelection.push({ label: newType.type, value: newType.id });
        } else {
          toLocationTypesSelection.push(typeAlreadyCreated);
        }
      }
    }

    const toLocationTypesUris = toLocationTypesSelection?.map(
      (type: InputSelectOption) =>
        `/organisations/${this.activeOrganisationId}/location-types/${type.value}`,
    );

    const locationsFromUris = this.formGroup
      .get("fromLocations")
      .value?.map(
        (location: InputSelectOption) =>
          `/organisations/${this.activeOrganisationId}/locations/${location.value}`,
      );

    const locationsToUris = this.formGroup
      .get("toLocations")
      .value?.map(
        (location: InputSelectOption) =>
          `/organisations/${this.activeOrganisationId}/locations/${location.value}`,
      );

    const deliveryToCountriesCodes = this.formGroup
      .get("toCountryCodes")
      .value.map((country: InputSelectOption) => country.value);
    const deliveryFromCountriesCodes = this.formGroup
      .get("fromCountryCodes")
      .value.map((country: InputSelectOption) => country.value);

    const conditions: (IConditionEq | IConditionAny)[] = [];

    if (this.deliveryFromCriteria() === this.criteriaTypeEnum.PARAMETERS) {
      if (deliveryFromCountriesCodes?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.FROM_COUNTRIES,
            value: deliveryFromCountriesCodes,
          },
        });
      }
      if (fromLocationTypesUris?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.FROM_TYPES,
            value: fromLocationTypesUris,
          },
        });
      }
    }
    if (this.deliveryFromCriteria() === this.criteriaTypeEnum.EXACT) {
      if (locationsFromUris?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.FROM_LOCATION,
            value: locationsFromUris,
          },
        });
      }
    }

    // Set criteria for deliveries TO locations
    if (this.deliveryToCriteria() === this.criteriaTypeEnum.EXACT) {
      if (locationsToUris?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.TO_LOCATION,
            value: locationsToUris,
          },
        });
      }
    }

    if (this.deliveryToCriteria() === this.criteriaTypeEnum.PARAMETERS) {
      if (deliveryToCountriesCodes?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.TO_COUNTRIES,
            value: deliveryToCountriesCodes,
          },
        });
      }
      if (toLocationTypesUris?.length) {
        conditions.push({
          any: {
            path: TargetPathEnum.TO_TYPES,
            value: toLocationTypesUris,
          },
        });
      }
    }

    const conditionAnd = conditions.length ? { and: conditions } : null;

    return conditionAnd;
  }
}
