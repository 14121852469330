import { ChangeDetectionStrategy, Component, input } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-form-label",
  templateUrl: "./form-label.component.html",
  styleUrl: "./form-label.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLabelComponent {
  label = input.required<string>();

  tooltip = input<string>();

  icon = input<string>("help");

  isSvgIcon = input<boolean>(false);

  iconClass = input<string>();

  class = input<string>();
}
