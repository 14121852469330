@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <section>
    <h3 i18n>Product transformations</h3>
    @if (hasAnyProcesses()) {
      @for (processesByType of processesByTypeGroups | keyvalue; track processesByType.key) {
        <h4>
          {{ processesByType.value[0].type.name }}
        </h4>

        @for (process of processesByType.value; track process.id) {
          <app-delivery-report-process-info
            [process]="process"
            [allProducts]="allProducts"
            [allUnitOfMeasurements]="allUnitOfMeasurements"
          ></app-delivery-report-process-info>
        }
      }
    } @else {
      <p i18n>No product transformations occured</p>
    }
  </section>
}
