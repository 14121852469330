<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title>
    <ng-container i18n>Send</ng-container>
    <ng-container>&nbsp;</ng-container>
    {{ targetTypeText }}
  </h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input-select
        [options]="connectionsOptions"
        formControlName="connection"
        [label]="translations.connectionLabel"
        hintClass="info-hint"
        [hint]="hint"
      ></app-input-select>
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost">
          <ng-container i18n>Cancel</ng-container>
        </app-button>
        <app-button
          accessibilityRole="submit"
          cdkFocusInitial
          [disabled]="formGroup.invalid || formGroup.pending"
        >
          <mat-icon icon-left>send</mat-icon>
          <ng-container i18n>Send</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
