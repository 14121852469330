import {
  AbstractControl,
  AsyncValidatorFn,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";

import moment from "moment";
import { map, Observable, of, switchMap, timer } from "rxjs";
import { CommonUtils, FileUtils, GeoJSONUtils } from "src/app/shared/utils";

import { RiskAssessmentTemplatesApiService } from "@components/shared/risk-assessment-templates/api";
import { RiskLevelSetsApiService } from "@components/shared/risk-level-sets/api";

import {
  AnalysisExtensionService,
  ApiKeysService,
  CertificatesService,
  ConnectionsService,
  DeliveriesService,
  DocumentsService,
  DocumentTypesService,
  ItemsService,
  LocationsService,
  LocationTypesService,
  MaterialsService,
  ProcessesService,
  ProcessTypesService,
  ProductsService,
  RulesetsService,
  SupplyChainsService,
  SharesService,
  CustomFieldsService,
  UnitsOfMeasurementService,
  TagDefinitionsService,
} from "../services";

interface ValidationErrorMessage {
  [errorType: string]: string;
}

export interface ListItem {
  key?: string;
  value: unknown;
}

export class CustomValidators {
  public static isInValues = (values: string[], customMessage: string): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      if (!values.includes(value)) {
        return { notInValuesMatch: customMessage };
      }

      return null;
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  public static = (warning: string, fn: Function): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      const result = fn(control);

      if (result === null) {
        return null;
      }

      return { warning };
    };
  };

  public static required = (
    control: AbstractControl,
    onlyWarning: boolean = false,
  ): ValidationErrorMessage | null => {
    const error = Validators.required(control);

    if (error === null) {
      return null;
    }

    const key = onlyWarning ? "warning" : "required";

    return { [key]: $localize`This field is mandatory` };
  };

  public static date = (control: AbstractControl): ValidationErrorMessage | null => {
    if (!control.value) {
      return null;
    }

    if (!moment(control.value, true).isValid()) {
      return { date: $localize`Invalid date format` };
    }

    return null;
  };

  public static latitude = (control: AbstractControl): ValidationErrorMessage | null =>
    this.coordinate(control, "latitude");

  public static longitude = (control: AbstractControl): ValidationErrorMessage | null =>
    this.coordinate(control, "longitude");

  private static coordinate = (
    control: AbstractControl,
    type: string,
  ): ValidationErrorMessage | null => {
    if (!control.value) {
      return null;
    }
    if (type === "latitude") {
      if (!GeoJSONUtils.isValidLatitude(control.value)) {
        return { latitude: $localize`Invalid latitude` };
      }
    } else {
      if (!GeoJSONUtils.isValidLongitude(control.value)) {
        return { longitude: $localize`Invalid longitude` };
      }
    }

    return null;
  };

  public static fileSize = (file: File, maxSize: number): ValidatorFn => {
    return (_: AbstractControl): ValidationErrors | null => {
      if (file.size > maxSize) {
        return {
          fileSize: $localize`File is too large (${CommonUtils.formatFileSizeMessage(file.size)}):fileSize:`,
        };
      }

      return null;
    };
  };

  public static fileExtension = (file: File, allowedExtensions: string[]): ValidatorFn => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_: AbstractControl): ValidationErrors | null => {
      const extension = FileUtils.getFileExtension(file.name).toUpperCase();

      if (!allowedExtensions.includes(extension)) {
        return {
          fileExtension: $localize`File extension not supported: ${extension.toLowerCase()}:fileExtensions:`,
        };
      }

      return null;
    };
  };

  public static email = (control: AbstractControl): ValidationErrorMessage | null => {
    const error = Validators.email(control);

    if (error === null) {
      return null;
    }

    return { email: $localize`Please enter a valid email address` };
  };

  public static minLength = (minLength: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      const error = Validators.minLength(minLength)(control);

      if (error === null) {
        return null;
      }

      return { minLength: $localize`Please enter at least ${minLength}:minNumber: characters` };
    };
  };

  public static min = (min: number): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      const error = Validators.min(min)(control);

      if (error === null) {
        return null;
      }

      return { minNumber: $localize`Please enter a number greater than or equal to ${min}:min:` };
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  public static max = (max: number | Function): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      let maxNumber: number;

      if (typeof max === "function") {
        maxNumber = max();
      } else {
        maxNumber = max;
      }

      const error = Validators.max(maxNumber)(control);

      if (error === null) {
        return null;
      }

      return {
        maxNumber: $localize`Please enter a number lesser than or equal to ${maxNumber}:maxNumber:`,
      };
    };
  };

  public static isInList = (list: ListItem[]): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      if (!control.value) {
        return null;
      }

      let valueToCompare = control.value;

      if (typeof control.value === "object" && "value" in control.value) {
        valueToCompare = control.value.value;

        if (!valueToCompare) {
          return { noObjectSelectedMatch: $localize`"No valid object selected` };
        }
      }
      const isValueInList = list.some((item) => item.value === valueToCompare);

      return isValueInList
        ? null
        : { notInListMatch: $localize`Selected value does not match any list item` };
    };
  };

  public static dateRange = (control: AbstractControl): ValidationErrorMessage | null => {
    const [from, to] = control.value;

    if (!from && !to) {
      if (control.hasValidator(CustomValidators.required)) {
        return { dateRange: $localize`This field is mandatory` };
      } else {
        return null;
      }
    }

    const fromMoment = moment(from, true);
    const toMoment = moment(to, true);

    if (!fromMoment.isValid() || !toMoment.isValid()) {
      return { dateRange: $localize`Invalid date format` };
    }

    if (fromMoment.isAfter(toMoment)) {
      return { dateRange: $localize`From date cannot be later than to date` };
    }

    return null;
  };

  public static dateRules = (controls: any[]): ValidationErrors | ValidatorFn | null => {
    return (formGroup: UntypedFormGroup): ValidationErrors => {
      if (!controls?.length) {
        return null;
      }

      let hasFormError = false;

      for (const control of controls) {
        const formControl = formGroup.get(control.name);

        formControl.setErrors(null);
        const value = formControl?.value;

        if (!value) {
          continue;
        }

        const momentValue = moment(value);
        const isValidMomentValue = moment(momentValue).isValid();

        if (formControl.hasError("matDatepickerParse") || !isValidMomentValue) {
          formControl.setErrors({ invalidDate: $localize`Invalid date format` });

          return formControl.errors;
        }

        for (const rule of control.rules) {
          const targetControl = formGroup.get(rule.target);

          const targetValue = targetControl?.value;

          if (!targetValue || targetControl.hasError("matDatepickerParse") || !isValidMomentValue) {
            continue;
          }

          const momentTargetValue = moment(targetValue);

          switch (rule.type) {
            case "<":
              {
                if (momentValue < momentTargetValue) {
                  formControl.setErrors({ noDateRulesMatch: rule.errorMessage });
                  hasFormError = true;
                }
              }
              break;
            case ">":
              {
                if (momentValue > momentTargetValue) {
                  formControl.setErrors({ noDateRulesMatch: rule.errorMessage });
                  hasFormError = true;
                }
              }
              break;
          }
        }
      }

      return hasFormError ? { noDateRulesMatch: $localize`Invalid date` } : null;
    };
  };

  public static passwordMatch = (formGroup: UntypedFormGroup): ValidationErrors | null => {
    if (formGroup.get("password").value !== formGroup.get("confirmPassword").value) {
      return { noPasswordMatch: $localize`Passwords do not match` };
    }

    return null;
  };

  public static pattern = (regex: RegExp, errorMessage: ValidationErrors): ValidatorFn => {
    return (control: AbstractControl): ValidationErrorMessage | null => {
      const error = Validators.pattern(regex)(control);

      if (error === null) {
        return null;
      }

      return errorMessage;
    };
  };

  public static url = (): ValidatorFn => {
    return this.pattern(
      new RegExp(
        // eslint-disable-next-line no-useless-escape
        /^(?:(http(s)?)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        "i",
      ),
      { noUrlMatch: $localize`Please enter a valid URL` },
    );
  };

  public static greaterThan = (number: number, ignoreEmptyValue: boolean = false): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null || control.value === undefined) {
        // if control is empty return no error
        return null;
      }

      const valid =
        (ignoreEmptyValue && control.value === "") ||
        (!isNaN(control.value) && +control.value > number);

      return valid
        ? null
        : { noGreaterThanMatch: $localize`Must be greater than ${number}:number:` };
    };
  };

  public static integer = (): ValidatorFn => {
    return this.pattern(new RegExp(/^[0-9]*$/), {
      noIntegerMatch: $localize`Only integers allowed`,
    });
  };

  public static guid(args?: any): ValidatorFn {
    return this.pattern(
      new RegExp(/^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/),
      {
        noGuidMatch: args && args["errorMessage"] ? args["errorMessage"] : $localize`Invalid value`,
      },
    );
  }

  private static getExistingElements(service: any, value: string, args: any): Observable<any> {
    if (service instanceof AnalysisExtensionService) {
      return service.getPageSubscription(
        value.toLowerCase(),
        args["locationId"],
        undefined,
        undefined,
        1,
        0,
      );
    } else if (service instanceof DocumentsService) {
      return service.getPageSubscription(
        false,
        `${value.toLowerCase()}.${args["fileExtension"]}`,
        1,
        0,
      );
    } else if (service instanceof RulesetsService) {
      return service.getPageSubscription(args["resourceType"], value.toLowerCase(), 1, 0);
    } else if (service instanceof SharesService) {
      return service.getPageSubscription(args["rootRecordUri"], value.toLowerCase(), 1, 0);
    } else if (service instanceof CustomFieldsService) {
      return service.getPageSubscription(value.toLowerCase(), undefined, 1, 0);
    } else if (service instanceof UnitsOfMeasurementService) {
      return service.getPageSubscription(value.toLowerCase());
    } else if (service instanceof RiskLevelSetsApiService) {
      return service.getPageSubscription({ name: value.toLowerCase(), page: 0, size: 1 });
    } else if (service instanceof RiskAssessmentTemplatesApiService) {
      return service.getPageSubscription({
        name: value.toLowerCase(),
        appliesTo: args["appliesTo"],
        page: 0,
        size: 1,
      });
    } else {
      return service.getPageSubscription(value.toLowerCase(), 1, 0);
    }
  }

  private static processExistingElementsResponse(
    response: any,
    service: any,
    control: AbstractControl,
    elementId: string,
    args: any,
  ): any {
    let existingElements = [];

    if (service instanceof ApiKeysService) {
      existingElements = response;
    } else {
      existingElements = response?.content;
    }
    if (!existingElements?.length) {
      return null;
    }

    let controlValue = control.value.toLowerCase();

    if (service instanceof DocumentsService) {
      controlValue = `${control.value.toLowerCase()}.${args["fileExtension"]}`;
    }

    const searchPropertyName =
      args && args["searchPropertyName"] ? args["searchPropertyName"] : "name";
    const searchPropertyErrorDisplayName =
      args && args["searchPropertyErrorDisplayName"]
        ? args["searchPropertyErrorDisplayName"]
        : "name";
    const errorMessage =
      args && args["errorMessage"]
        ? args["errorMessage"]
        : $localize`This ${searchPropertyErrorDisplayName}:name: already exists`;

    return existingElements.some(
      (o) =>
        o[searchPropertyName].toLowerCase() === controlValue && (!elementId || o.id !== elementId),
    )
      ? {
          entityAlreadyExistsMatch: errorMessage,
        }
      : null;
  }

  public static entityAlreadyExists(
    service:
      | LocationsService
      | ConnectionsService
      | AnalysisExtensionService
      | DocumentsService
      | ProcessesService
      | SupplyChainsService
      | CertificatesService
      | ItemsService
      | DeliveriesService
      | ProductsService
      | RulesetsService
      | ApiKeysService
      | MaterialsService
      | DocumentTypesService
      | LocationTypesService
      | ProcessTypesService
      | SharesService
      | CustomFieldsService
      | UnitsOfMeasurementService
      | TagDefinitionsService
      | RiskLevelSetsApiService
      | RiskAssessmentTemplatesApiService,
    elementId: string = null,
    args: any = null,
  ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.valueChanges || !control.value || control.pristine) {
        return of(null);
      }

      return timer(500).pipe(
        switchMap(() => this.getExistingElements(service, control.value, args)),
        map((response: any) =>
          this.processExistingElementsResponse(response, service, control, elementId, args),
        ),
      );
    };
  }

  public static maxLength = (number: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null || control.value === undefined) {
        return null;
      }

      const valid = control.value.length <= number;

      return valid ? null : { maxLength: $localize`Up to ${number}:number: characters allowed` };
    };
  };

  public static rangeValidator = (minValue: number, maxValue: number): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value === null || control.value === undefined || isNaN(control.value)) {
        return null;
      }

      const value = parseFloat(control.value);
      const valid = value >= minValue && value <= maxValue;

      return valid
        ? null
        : {
            range: $localize`Should be number between ${minValue}:minValue: and ${maxValue}:maxValue:`,
          };
    };
  };

  public static forbiddenValueValidator = (
    forbiddenValue: string,
    errorMessage: string,
  ): ValidatorFn => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || control.value === undefined) {
        return null;
      }
      const value: string = control.value;
      const isForbidden = value.trim().toLowerCase() === forbiddenValue.trim().toLowerCase();

      return isForbidden ? { forbiddenValue: errorMessage } : null;
    };
  };

  public static polygonClosedValidator = (formArray: AbstractControl): ValidationErrors | null => {
    const coordinates = formArray.value;

    if (!coordinates || coordinates.length <= 1) {
      return null;
    }

    if (coordinates.length < 3) {
      return { polygonNotClosed: $localize`A polygon needs at least 3 points` };
    }

    const firstPoint = coordinates[0];
    const lastPoint = coordinates[coordinates.length - 1];

    const epsilon = 0.001;

    const areLongitudesEqual = Math.abs(firstPoint.longitude - lastPoint.longitude) < epsilon;
    const areLatitudesEqual = Math.abs(firstPoint.latitude - lastPoint.latitude) < epsilon;

    if (!areLongitudesEqual || !areLatitudesEqual) {
      return {
        polygonNotClosed: $localize`The polygon is not closed. Add more points to close it`,
      };
    }

    return null;
  };
}

export const NEW_PASSWORD_MIN_LENGTH = 8;
export const NEW_PASSWORD_VALIDATORS = [
  // Field is required
  CustomValidators.required,
  // Has a number
  CustomValidators.pattern(/\d/, {
    noNumber: $localize`The field must contain at least one number`,
  }),
  // Has a character
  CustomValidators.pattern(/[a-zA-Z]/, {
    noLetter: $localize`The field must contain at least one letter`,
  }),
  // Has a special character
  CustomValidators.pattern(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/, {
    noSpecialCharacter: $localize`The field must contain at least one special character`,
  }),
  // Has a minimum length of 8 characters
  CustomValidators.minLength(NEW_PASSWORD_MIN_LENGTH),
];
