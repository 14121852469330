<div
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (mouseenter)="onMouseEnter(menuTrigger)"
  (mouseleave)="onMouseLeave(menuTrigger)"
>
  <ng-content select="[trigger]"></ng-content>
</div>
<mat-menu [class]="matMenuClass" #menu="matMenu" xPosition="after" [hasBackdrop]="false">
  <div (mouseenter)="onMouseEnter(menuTrigger)" (mouseleave)="onMouseLeave(menuTrigger)">
    <ng-content select="[content]"></ng-content>
  </div>
</mat-menu>
