import { ComponentType } from "@angular/cdk/overlay";
import { Injectable } from "@angular/core";

import {
  CertificateOverlayComponent,
  SharedCertificateOverlayComponent,
} from "@components/certificates";
import { DeliveryOverlayComponent, SharedDeliveryOverlayComponent } from "@components/deliveries";
import { DocumentOverlayComponent, SharedDocumentOverlayComponent } from "@components/documents";
import { ItemOverlayComponent, SharedItemOverlayComponent } from "@components/items";
import { LocationOverlayComponent, SharedLocationOverlayComponent } from "@components/locations";
import { MaterialOverlayComponent, SharedMaterialOverlayComponent } from "@components/materials";
import {
  OrganisationOverlayComponent,
  PublicOrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
} from "@components/organisations";
import { ProcessOverlayComponent, SharedProcessOverlayComponent } from "@components/processes";
import { ProductOverlayComponent, SharedProductOverlayComponent } from "@components/products";
import {
  CountryOverlayComponent,
  RiskAssessmentTemplateOverlayComponent,
} from "@components/settings";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { RiskAssessmentReportOverlayComponent } from "@components/shared/risk-assessment-reports/ui";
import { RouteEnum } from "@shared/enums";

@Injectable({
  providedIn: "root",
})
export class OverlayBridgeService {
  getComponent(view: string): ComponentType<SlideOverlayPageClass> | null {
    switch (view) {
      case RouteEnum.OVERLAY_SHARED_LOCATION:
        return SharedLocationOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_ORGANISATION:
        return SharedOrganisationOverlayComponent;
      case RouteEnum.OVERLAY_PUBLIC_ORGANISATION:
        return PublicOrganisationOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_DOCUMENT:
        return SharedDocumentOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_CERTIFICATE:
        return SharedCertificateOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_DELIVERY:
        return SharedDeliveryOverlayComponent;
      case RouteEnum.OVERLAY_LOCATION:
        return LocationOverlayComponent;
      case RouteEnum.OVERLAY_ORGANISATION:
        return OrganisationOverlayComponent;
      case RouteEnum.OVERLAY_DOCUMENT:
        return DocumentOverlayComponent;
      case RouteEnum.OVERLAY_CERTIFICATE:
        return CertificateOverlayComponent;
      case RouteEnum.OVERLAY_DELIVERY:
        return DeliveryOverlayComponent;
      case RouteEnum.OVERLAY_ITEM:
        return ItemOverlayComponent;
      case RouteEnum.OVERLAY_PROCESS:
        return ProcessOverlayComponent;
      case RouteEnum.OVERLAY_PRODUCT:
        return ProductOverlayComponent;
      case RouteEnum.OVERLAY_MATERIAL:
        return MaterialOverlayComponent;
      case RouteEnum.OVERLAY_RISK_ASSESSMENT_TEMPLATE:
        return RiskAssessmentTemplateOverlayComponent;
      case RouteEnum.OVERLAY_RISK_ASSESSMENT_REPORT:
        return RiskAssessmentReportOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_PRODUCT:
        return SharedProductOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_ITEM:
        return SharedItemOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_MATERIAL:
        return SharedMaterialOverlayComponent;
      case RouteEnum.OVERLAY_COUNTRY:
        return CountryOverlayComponent;
      case RouteEnum.OVERLAY_SHARED_PROCESS:
        return SharedProcessOverlayComponent;
      default:
        return null;
    }
  }
}
