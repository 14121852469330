<div class="form-field-item">
  <app-form-label [label]="label()"></app-form-label>
  <div class="form-field-input-readonly">
    @if (loading()) {
      <app-loader containerClass="size-small"></app-loader>
    } @else {
      <ng-content select="[input-readonly-prefix]" />
      @if (tags()?.length) {
        <app-tag-group
          [tags]="tags()"
          (tagPressed)="onTagClick($event)"
          [isTagClickable]="isTagClickable"
        ></app-tag-group>
      } @else if (value()) {
        <app-value-and-copy-button
          [value]="value()"
          [copyMode]="copyMode()"
          (clickOnValue)="onValueClick()"
          [valueClass]="valueClass"
          [appearance]="isTextarea ? 'textarea' : 'input'"
          [valueTooltip]="valueTooltip()"
        ></app-value-and-copy-button>
      } @else {
        -
      }
      <ng-content select="[input-readonly-suffix]" />
    }
  </div>
</div>
