import { getItemsSupplyChainFields } from "./items-supply-chain.query";
import { QueriesUtils } from "./queries.utils";

export const getInboundItemsSupplyChainGraphQLQuery = (
  orgId: string,
  senderId: string,
  itemIds: string[],
): string => {
  return `
      {
          inboundLoadItemsSupplyChain(   
              orgId: "${orgId}",
              senderId: "${senderId}",
              itemIds: ${QueriesUtils.getParsedIds(itemIds)}
          ) {
            ${getItemsSupplyChainFields()}
          }
      }
      `;
};
