<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
    <mat-expansion-panel-header class="section-header">
      <mat-panel-title class="section-title">
        <div class="location-type-group-title">
          <ng-container i18n>Location type</ng-container>
          - {{ locationsGroupByType().key }} ({{ locationsGroupByType().value.length }})
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <div class="locations-group-by-type">
        @for (
          locationWithMissingDocumentTypes of locationsWithMissingDocumentTypes;
          track locationWithMissingDocumentTypes.location.id
        ) {
          <div>
            <app-delivery-report-location-detail
              [locationWithMissingDocumentTypes]="locationWithMissingDocumentTypes"
            ></app-delivery-report-location-detail>
          </div>
        }
      </div>

      <h3 i18n>Documents summary</h3>
      <app-table
        [columnDefs]="documentsSummaryColumnDefs"
        [rowData]="displayDocumentSummariesTable() ? documentsSummaryRowData : []"
        [isLoading]="!documentsSummaryColumnDefs.length"
        [isSearchEnabled]="false"
        [isRecordStateFilterEnabled]="false"
        [isPaginatorEnabled]="false"
      ></app-table>

      <h3 i18n>Certificates summary</h3>
      <app-delivery-report-certificates-summary
        [rowData]="certificatesWithLocation"
        [isRecordStateFilterEnabled]="false"
        [isSearchEnabled]="false"
        [isPaginatorEnabled]="false"
        [columns]="columns"
      ></app-delivery-report-certificates-summary>
    }
  </mat-expansion-panel>
</mat-accordion>
