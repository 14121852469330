<article class="field-group-container no-top-padding" cdkDropListGroup>
  <div class="content">
    <h3>
      <ng-container i18n>Products</ng-container>
      <mat-icon class="info-icon" [tp]="translations.relatedProductsTp">help</mat-icon>
    </h3>
    <mat-tab-group
      animationDuration="0ms"
      class="full-width"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="onTabChanged($event)"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container i18n>Created from</ng-container>

          @if (createdFromProducts.length) {
            <div class="indicator link-indicator">
              {{ createdFromProducts.length }}
            </div>
          }
        </ng-template>
        @if (isLoading()) {
          <app-loader></app-loader>
        } @else {
          <app-drag-drop-container
            itemType="products"
            (itemDropped)="onItemDropped($event)"
          ></app-drag-drop-container>
          <app-product-list-items
            [items]="createdFromProducts"
            (remove)="onRemove($event)"
          ></app-product-list-items>
        }
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container i18n>Used in</ng-container>

          @if (usedInProducts.length) {
            <div class="indicator link-indicator">
              {{ usedInProducts.length }}
            </div>
          }
        </ng-template>
        @if (isLoading()) {
          <app-loader></app-loader>
        } @else {
          <app-drag-drop-container
            itemType="products"
            (itemDropped)="onItemDropped($event)"
          ></app-drag-drop-container>
          <app-product-list-items
            [items]="usedInProducts"
            (remove)="onRemove($event)"
          ></app-product-list-items>
        }
      </mat-tab>
    </mat-tab-group>
  </div>

  <ng-template #attachmentsRightMenu>
    <app-right-side-panel
      class="overlay-right-panel"
      itemType="Product"
      itemTypePlural="Products"
      [isLoadingItems]="isLoadingProducts()"
      [availableItems]="availableProducts"
      [displayCloseButton]="false"
      (pressAdd)="onAddNewProduct()"
      (pressAddAll)="onAddAll()"
      (pressRefresh)="onReloadProducts()"
      (searchEvent)="onSearchAvailable($event)"
    >
      <div
        class="items-container"
        cdkDropList
        [cdkDropListSortingDisabled]="true"
        [cdkDropListData]="availableProducts"
      >
        @for (item of availableProducts; track item.id) {
          <app-draggable-card (pressed)="onAdd(item.id)" [item]="item">
            <app-product-card-content
              [product]="item"
              [type]="cardContentTypeEnum.ADD"
            ></app-product-card-content>
          </app-draggable-card>
        }
      </div>
    </app-right-side-panel>
  </ng-template>
</article>

<ng-template #overlayTopButtons>
  <app-button size="large" [tp]="translations.addNewTp" tpPlacement="bottom" type="icon">
    <mat-icon icon-medium-18>add</mat-icon>
  </app-button>
</ng-template>
