import { ChangeDetectionStrategy, Component, inject, viewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { defaultIfEmpty, filter, lastValueFrom } from "rxjs";

import { InfoDialogComponent } from "@components/shared";
import { RiskLevelSet } from "@components/shared/risk-level-sets/models";
import { RiskLevelSetsTableComponent } from "@components/shared/risk-level-sets/ui";
import { TextConstants } from "@shared/constants";
import { RecordStateEnum, RouteEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";

import {
  AddEditRiskLevelSetDialogComponent,
  AddEditRiskLevelSetDialogData,
} from "./add-edit-risk-level-set-dialog.component";

@Component({
  standalone: false,
  selector: "app-settings-risk-level-sets",
  templateUrl: "./settings-risk-level-sets.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsRiskLevelSetsComponent {
  private readonly dialog = inject(MatDialog);

  private readonly authService = inject(AuthenticationService);

  private readonly table = viewChild<RiskLevelSetsTableComponent>("riskLevelSetsTable");

  readonly canAddModifyEntities = this.authService.canAddModifyEntities();

  readonly recordState = RecordStateEnum;

  readonly routing = RouteEnum;

  public readonly translations: any = {
    title: $localize`Risk level sets`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  async openDialog(riskLevelSet?: RiskLevelSet) {
    if (riskLevelSet?.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentText: TextConstants.EDIT_ARCHIVED_RECORD_ERROR,
        },
      });

      return;
    }

    const dialogRef = this.dialog.open<
      AddEditRiskLevelSetDialogComponent,
      AddEditRiskLevelSetDialogData
    >(AddEditRiskLevelSetDialogComponent, {
      data: { riskLevelSet },
    });

    await lastValueFrom(dialogRef.afterClosed().pipe(filter(Boolean), defaultIfEmpty(null)));
    await this.table().getAll();
  }
}
