import { getProductFields } from "@shared/queries/queries.constants";

import { QueriesUtils } from "./queries.utils";
import { IDeliveryGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load deliveries based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which deliveries are queried.
 * @param {IDeliveryGraphQLFilter} filter - An object containing filtering criteria for deliveries.
 * @param {number} first - The number of deliveries to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of deliveries to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading deliveries.
 */

export const getDeliveriesGraphQLQuery = (
  orgId: string,
  filter: IDeliveryGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadDeliveries(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getDeliveryProperties(include)}     
    }
}
`;
};

/**
 * Generates a GraphQL query to load deliveries based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which deliveries are queried.
 * @param {string[]} ids - (Required) A list of deliveries ids
 * @param {number} first - The number of deliveries to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of deliveries to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading deliveries.
 */

export const getDeliveriesByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadDeliveriesByIds(   
    orgId: "${orgId}",
    ids: ${QueriesUtils.getParsedIds(ids)},
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getDeliveryProperties(include)}        
    }
}
`;
};

const itemFields = `items {
    quantity
    item {
        id
        itemId
        recordState
        remainingQuantity
        initialQuantity
        createdAtLocation { id }
        currentLocation { id }
        product ${getProductFields(["DEFAULT_CUSTOM_UNIT", "CUSTOM_FIELDS", "MATERIALS"])}
        materials {
            id
            name
        }
    }
}`;

export const getDeliveryProperties = (include: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                id
                deliveryId
                delivered
                sent
                from { 
                    id
                    name
                    types {
                        type
                        pointOfOrigin
                    }
                    address {
                        country
                    }
                }
                to {
                    id
                    name
                    types {
                        type
                        pointOfOrigin
                    }
                    address {
                        country
                    }
                }
                ${include.includes("ITEMS") ? itemFields : ""}
                status
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        }`;
