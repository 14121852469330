import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Router } from "@angular/router";

import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-entity-save-footer",
  templateUrl: "./entity-save-footer.component.html",
  styleUrls: ["./entity-save-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntitySaveFooterComponent {
  @Output() public save: EventEmitter<void> = new EventEmitter();

  @Output() public cancelEvent: EventEmitter<void> = new EventEmitter();

  @Input() overrideCancel: boolean = false;

  @Input() isDisabled: boolean = false;

  @Input() showSubmitButton: boolean = true;

  @Input() showCancelButton: boolean = true;

  constructor(
    private routerService: RouterService,
    private router: Router,
  ) {}

  onSave() {
    this.save.emit();
  }

  onCancel(): void {
    if (this.overrideCancel) {
      this.cancelEvent.emit();

      return;
    }

    if (this.routerService.hasHistory()) {
      this.routerService.goBackInHistory();
    } else {
      const currentUrlTree = this.router.parseUrl(this.router.url);
      const segments = currentUrlTree.root.children["primary"].segments;
      let parentUrl = "";

      if (segments.length > 1) {
        segments.pop();
        parentUrl = segments.map((segment) => segment.path).join("/");
      }

      if (parentUrl) {
        this.router.navigateByUrl(parentUrl);
      } else {
        this.router.navigateByUrl("/");
      }
    }
  }
}
