import { ChangeDetectionStrategy, Component, HostBinding, input } from "@angular/core";

import { CommonConstants } from "@shared/constants";

import { Tag } from "../models";

@Component({
  standalone: false,
  selector: "app-basic-tags",
  templateUrl: "./basic-tags.component.html",
  styleUrl: "./basic-tags.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BasicTagsComponent {
  readonly tags = input.required<Tag[]>();

  readonly appearance = input<"below-form-field" | "">("");

  readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  @HostBinding("class")
  get appearanceClass() {
    return this.appearance();
  }
}
