<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>

  @if (authenticationService.canAddModifyEntities()) {
    <app-button-group>
      <app-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add new</ng-container>
      </app-button>
    </app-button-group>
  }
</div>
<mat-tab-group
  animationDuration="0ms"
  [mat-stretch-tabs]="false"
  mat-align-tabs="start"
  [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="selectedTabIndex = $event"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>System</ng-container>

      <app-indicator [value]="systemUnitsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-units-of-measurement-table
      #systemUnitsTable
      [areButtonsEnabled]="false"
      [isRecordStateFilterEnabled]="false"
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
    ></app-units-of-measurement-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Custom</ng-container>

      <app-indicator [value]="customUnitsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-units-of-measurement-table
      #customUnitsTable
      [unitSourceType]="unitSourceTypes.CUSTOM"
      [columns]="['name', 'symbol', 'type', 'systemUnit.name', 'conversionFactorWithSymbol']"
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
      [isRecordStateFilterEnabled]="false"
      [isBatchActionsEnabled]="true"
    ></app-units-of-measurement-table>
  </mat-tab>
</mat-tab-group>
