import { ChangeDetectorRef, Directive, HostListener, inject, ViewChild } from "@angular/core";
import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { DialogPosition, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TableComponent } from "@components/index";
import {
  BulkAddCustomFieldEnum,
  BulkAddCustomFieldFormGroup,
  BulkAddFixedField,
} from "@components/shared/bulk-add/bulk-add.interface";
import { BulkAddEnterRecordsModel as Model } from "@components/shared/bulk-add/bulk-add.model";
import { BulkAddEditFieldDialogComponent } from "@components/shared/bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.component";
import { BulkAddEditFieldDialogModel } from "@components/shared/bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.model";
import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";
import { NotificationService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";
import { CellClickedEvent, ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";

@Directive()
export abstract class BulkAddEnterRecords<
  TFormGroup extends { [K in keyof TFormGroup]: AbstractControl<any> },
  TRecordFormGroup extends { [K in keyof TRecordFormGroup]: AbstractControl<any> },
  TRowData extends { formGroup: FormGroup<TRecordFormGroup> },
> {
  abstract formGroup: FormGroup<TFormGroup>;

  protected dialogRef: MatDialogRef<BulkAddEditFieldDialogComponent, any>;

  protected cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  protected abstract setColumnDefs(): void;

  protected abstract buildRecord(
    initialData?: FormGroup<TRecordFormGroup>,
  ): FormGroup<TRecordFormGroup>;

  protected dialog: MatDialog = inject(MatDialog);

  protected notificationService: NotificationService = inject(NotificationService);

  abstract rowData: TRowData[];

  protected abstract fieldToCheckForDuplicates: string;

  protected abstract isDuplicatedField: string;

  protected abstract readonly maxRecordsCount: number;

  protected subscriptions: Subscription = new Subscription();

  public isGridReady: boolean = false;

  public recordsQuantityFormGroup = new FormGroup({
    quantity: new FormControl(1, [
      CustomValidators.integer(),
      CustomValidators.min(1),
      CustomValidators.max(this.maxNewRecordsThatCanBeAdded.bind(this)),
    ]),
  });

  private getFixedCustomFields(): BulkAddFixedField[] {
    return this.formGroup.controls[Model.BulkAddFieldEnum.CUSTOM_FIELDS]
      .getRawValue()
      .filter((cf) => cf.isFixedCustomField)
      .map((cf) => {
        return {
          label: cf.customFieldLabel,
          value: cf.customFieldValue,
        };
      });
  }

  public get fixedFields(): BulkAddFixedField[] {
    const formGroupValue = this.formGroup.getRawValue();

    const fixedKeys = Object.keys(formGroupValue).filter((key) => {
      return key.startsWith("isFixed") && formGroupValue[key];
    });

    const fixedFields: BulkAddFixedField[] = fixedKeys.map((key) => {
      const label = key.replace("isFixed", "");

      const fixedField = this.getFixedFieldForSpecialFields(key);

      if (fixedField) {
        return fixedField;
      }

      const keyForValue = label.charAt(0).toLowerCase() + label.slice(1);

      return {
        label: this.getLabelForFixedField(key),
        value: this.getFieldValue(formGroupValue[keyForValue], key),
      };
    });

    return [...fixedFields, ...this.getFixedCustomFields()];
  }

  protected abstract getLabelForFixedField(key: string): string;

  protected abstract getFieldValue(value: any, key?: string): string;

  protected getFixedFieldForSpecialFields(_key: string): BulkAddFixedField {
    return null;
  }

  protected getFieldValueForSelectField(field: keyof TRecordFormGroup): ISelectOption {
    return this.formGroup.controls[field as string].value as ISelectOption;
  }

  protected getFieldValueForInput(field: keyof TRecordFormGroup): string {
    return this.formGroup.controls[field as string].value as string;
  }

  protected isFieldDisabled(field: keyof TFormGroup): boolean {
    return this.formGroup.controls[field as string].value as boolean;
  }

  protected defaultColDefPropertiesForCustomField(
    control: FormGroup<BulkAddCustomFieldFormGroup>,
  ): Partial<ColDef> {
    return {
      lockVisible: true,
      sortable: false,
      suppressSizeToFit: true,
      cellClass: "clickable",
      headerName: `${control.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL).value} (${TextConstants.OPTIONAL})`,
      valueGetter: (params) => {
        const customFieldId = control.value.customFieldId;

        const customField = params.data.formGroup.controls["customFields"].controls.find(
          (customField) => customField.value.customFieldId === customFieldId,
        );

        return customField.value.customFieldValue;
      },
      onCellClicked: (event: CellClickedEvent<{ formGroup: FormGroup<TRecordFormGroup> }>) => {
        const customFieldId = control.value.customFieldId;

        const customFieldControl = event.data.formGroup.controls["customFields"].controls.find(
          (customField) => customField.value.customFieldId === customFieldId,
        );

        this.onClickEditCustomField(
          { formGroup: customFieldControl } as TRowData,
          event.event?.target,
          control.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL).value,
        );
      },
      ...ColumnUtils.quickActionsMenuColumnCommonValues,
      cellRenderer: QuickActionsMenuComponent,
      cellRendererParams: {
        actions: [
          {
            icon: "edit",
            tooltip: TextConstants.EDIT,
            click: (data: { formGroup: FormGroup<TRecordFormGroup> }, event: PointerEvent) => {
              const customFieldId = control.value.customFieldId;

              const customFieldControl = data.formGroup.controls[
                Model.BulkAddFieldEnum.CUSTOM_FIELDS
              ].controls.find((customField) => customField.value.customFieldId === customFieldId);

              this.onClickEditCustomField(
                { formGroup: customFieldControl } as TRowData,
                event.currentTarget,
                control.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL).value,
              );
            },
          },
        ],
      },
    };
  }

  private onClickEditCustomField(data: TRowData, eventTarget: EventTarget, label: string): void {
    this.openEditFieldDialog(
      data,
      BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE,
      BulkAddEditFieldDialogModel.FieldTypeEnum.INPUT,
      eventTarget,
      { label },
    );
  }

  protected async openEditFieldDialog<T>(
    data: TRowData,
    formControlName: keyof TRecordFormGroup | BulkAddCustomFieldEnum,
    fieldType: BulkAddEditFieldDialogModel.FieldTypeEnum,
    eventTarget: EventTarget,
    params?: object,
    onCancel?: (initialValues: Model.FormGroupRawValue<TRecordFormGroup>) => void,
    onSuccess?: (newValue: T) => void,
  ) {
    const initialValues = data.formGroup.getRawValue() as Model.FormGroupRawValue<TRecordFormGroup>;

    this.dialogRef = this.dialog.open(BulkAddEditFieldDialogComponent, {
      position: this.getEditFieldDialogPosition(eventTarget),
      panelClass: "reduced-padding",
      maxWidth: `${Model.editDialogMaxWidth}px`,
      data: {
        formGroup: data.formGroup,
        formControlName,
        fieldType,
        params: params || {},
      },
    });

    window.addEventListener("resize", this.centerEditDialog.bind(this));

    this.dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; newValue?: T; initialValue?: T }) => {
        if (result.hasSaved) {
          await onSuccess?.(result.newValue);

          this.setColumnDefs();

          this.cdr.detectChanges();

          return;
        }

        if (onCancel) {
          onCancel(initialValues);
        } else {
          data.formGroup.controls[formControlName as string].setValue(result.initialValue);
        }
      });
  }

  protected defaultColDefProperties(formControlName: keyof TRecordFormGroup): Partial<ColDef> {
    return {
      lockVisible: true,
      sortable: false,
      suppressSizeToFit: true,
      cellClass: (params: { data: TRowData }) => {
        const statusClass = params.data.formGroup.controls[formControlName as string].disabled
          ? "disabled"
          : "clickable";

        return [formControlName, statusClass].join(" ");
      },
    };
  }

  protected onRemoveRecord(data: TRowData): void {
    const recordsControl = this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS];
    const controlsOfRecords = recordsControl.controls;

    if (controlsOfRecords.length <= 1) {
      return;
    }

    const index = controlsOfRecords.findIndex((control) => control === data.formGroup);

    recordsControl.removeAt(index);

    const rowNode = this.table.grid.api.getDisplayedRowAtIndex(index);

    if (rowNode) {
      this.table.grid.api.applyTransaction({ remove: [rowNode.data] });
    }

    this.detectDuplicates();

    const quantityControl = this.recordsQuantityFormGroup.controls["quantity"];

    quantityControl.updateValueAndValidity();
  }

  protected getWarningTooltipText(control: AbstractControl): string {
    if (control.pristine || !control.invalid) {
      return null;
    }

    return Object.values(control.errors).join(". ");
  }

  public getGridHeightFn = (): number => {
    const windowHeight = window.innerHeight;

    const slideOverContentPadding = 70;

    const title = CommonUtils.getHtmlElementHeightWithMargins(".slide-over-title-container");
    const stepper = CommonUtils.getHtmlElementHeightWithMargins(".stepper-container");

    let fixedFieldsHeight = 0;
    const fixedFields = document.querySelector(".fixed-fields");

    if (fixedFields) {
      fixedFieldsHeight = CommonUtils.getHtmlElementHeightWithMargins(".fixed-fields");
    }

    const gridMargin = 5;

    const addItems = CommonUtils.getHtmlElementHeightWithMargins(".add-records");

    const buttons = CommonUtils.getHtmlElementHeightWithMargins(".slide-over-buttons");

    const availableHeight =
      windowHeight -
      slideOverContentPadding -
      title -
      stepper -
      fixedFieldsHeight -
      gridMargin -
      addItems -
      buttons;

    return availableHeight;
  };

  public onClickNext(): boolean {
    if (this.formGroup.invalid) {
      this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].controls.forEach((control) => {
        FormUtils.findAndMarkInvalidControls(control);
      });
    }

    const areThereDuplicates = this.detectDuplicates();

    return !(this.formGroup.invalid || areThereDuplicates);
  }

  protected onDuplicateRecord(data: TRowData): void {
    if (
      this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].controls.length >=
      this.maxRecordsCount
    ) {
      return;
    }

    const itemsControls = this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].controls;
    const index = itemsControls.findIndex((control) => control === data.formGroup);
    const formGroup = itemsControls[index];
    const duplicatedItem = this.buildRecord(formGroup);
    const duplicatedItemIndex = index + 1;

    this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].insert(
      duplicatedItemIndex,
      duplicatedItem,
    );

    this.table.grid.api.applyTransaction({
      add: [{ formGroup: duplicatedItem }],
      addIndex: duplicatedItemIndex,
    });

    const quantityControl = this.recordsQuantityFormGroup.controls["quantity"];

    quantityControl.updateValueAndValidity();

    this.detectDuplicates();
  }

  public maxNewRecordsThatCanBeAdded(): number {
    return (
      this.maxRecordsCount -
      (this.formGroup?.controls[Model.BulkAddFieldEnum.RECORDS]?.controls?.length || 1)
    );
  }

  protected onKeyUpAddRecords(event: KeyboardEvent): void {
    if (event.key === "Enter") {
      this.buildRecords();
    }
  }

  @ViewChild("table") table: TableComponent;

  @HostListener("window:beforeunload")
  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    window.removeEventListener("resize", this.centerEditDialog.bind(this));
  }

  protected getCustomFieldsFormArray(initialData: FormGroup): FormArray<FormGroup> {
    return new FormArray<FormGroup>(
      this.formGroup.controls[Model.BulkAddFieldEnum.CUSTOM_FIELDS].controls.map((customField) => {
        const customFieldValue = initialData?.value?.customFields?.find(
          (cf) =>
            cf.customFieldId === customField.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_ID).value,
        )?.customFieldValue;

        const customFieldValueFormGroup = this.formGroup.controls[
          Model.BulkAddFieldEnum.CUSTOM_FIELDS
        ].controls.find(
          (cf) =>
            cf.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_ID).value ===
            customField.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_ID).value,
        );

        return new FormGroup<BulkAddCustomFieldFormGroup>({
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_ID]: new FormControl(
            customField.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_ID).value,
          ),
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE]: new FormControl(
            customFieldValue ||
              customFieldValueFormGroup.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE).value,
          ),
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL]: new FormControl(
            customField.get(BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL).value,
          ),
        });
      }),
    );
  }

  protected getEditFieldDialogPosition(eventTarget: EventTarget): DialogPosition {
    let cellContainer = (eventTarget as HTMLElement).closest(
      CellRendererUtils.cellContainerSelector,
    );

    if (!cellContainer) {
      cellContainer = (eventTarget as HTMLElement).querySelector(
        CellRendererUtils.cellContainerSelector,
      );
    }

    const boundingRect = cellContainer.getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const dialogWidth = Model.editDialogMaxWidth;
    const dialogHeight = Model.editDialogHeight;

    let topPosition = boundingRect.top - 60;
    let leftPosition = boundingRect.left - 20;

    if (topPosition + dialogHeight > viewportHeight) {
      topPosition = viewportHeight - dialogHeight - Model.editDialogViewportMargin;
    }
    if (leftPosition + dialogWidth > viewportWidth) {
      leftPosition = viewportWidth - dialogWidth - Model.editDialogViewportMargin;
    }

    if (topPosition < 0) {
      topPosition = Model.editDialogViewportMargin;
    }
    if (leftPosition < 0) {
      leftPosition = Model.editDialogViewportMargin;
    }

    return { top: `${topPosition}px`, left: `${leftPosition}px` };
  }

  protected centerEditDialog(): void {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    const dialogWidth = Model.editDialogViewportMargin;
    const dialogHeight = Model.editDialogHeight;

    const topPosition = (viewportHeight - dialogHeight) / 2;
    const leftPosition = (viewportWidth - dialogWidth) / 2;

    this.dialogRef.updatePosition({ top: `${topPosition}px`, left: `${leftPosition}px` });
  }

  protected detectDuplicates(): boolean {
    const allFieldValues = this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].controls
      .map((recordControl) => recordControl.controls[this.fieldToCheckForDuplicates].value)
      .filter((value) => !!value);

    let areThereDuplicates = false;

    this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS].controls.forEach((control) => {
      const fieldControl = control.controls[this.fieldToCheckForDuplicates];
      const isDuplicate = allFieldValues.filter((value) => value === fieldControl.value).length > 1;

      if (isDuplicate) {
        areThereDuplicates = true;
      }

      control.controls[this.isDuplicatedField].setValue(isDuplicate);
    });

    const rowIndexes: number[] = [];

    this.table.grid.api.forEachNode((node: { rowIndex: number }) => {
      rowIndexes.push(node.rowIndex);
    });

    rowIndexes.forEach((rowIndex) => {
      this.table.grid.api.refreshCells({
        rowNodes: [this.table.grid.api.getDisplayedRowAtIndex(rowIndex)],
        force: true,
      });
    });

    return areThereDuplicates;
  }

  public buildRecords(isInitialFill: boolean = false): void {
    const recordsControl = this.formGroup.controls[Model.BulkAddFieldEnum.RECORDS];

    if (recordsControl.length >= this.maxRecordsCount) {
      return;
    }

    const quantityControl = this.recordsQuantityFormGroup.controls["quantity"];

    if (!quantityControl.value || quantityControl.invalid || quantityControl.pending) {
      return;
    }

    const quantity = quantityControl.value;

    for (let i = 0; i < quantity; i++) {
      const newRecord = this.buildRecord();

      recordsControl.push(newRecord);

      if (!isInitialFill) {
        this.table.grid.api.applyTransaction({ add: [{ formGroup: newRecord }] });
      }
    }

    if (isInitialFill) {
      this.rowData = recordsControl.controls.map((control) => {
        return { formGroup: control };
      });
    }

    quantityControl.updateValueAndValidity();

    this.setColumnDefs();
  }
}
