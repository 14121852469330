<div class="full-width-items-container">
  @for (material of materials; track material.id) {
    <div class="item hover-visibility-container">
      <app-material-card-content
        [material]="material"
        [type]="cardContentTypeEnum.REMOVE"
        (remove)="onRemove(material.linkId)"
      ></app-material-card-content>
    </div>
  }
</div>
