import { DecimalPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  input,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { AgGridAngular } from "ag-grid-angular";
import { debounceTime, Subject } from "rxjs";

import { CommonConstants } from "@shared/constants";

import { TableUtils } from "./table.utils";

@Component({
  standalone: false,
  selector: "app-table-footer",
  templateUrl: "./table-footer.component.html",
  styleUrls: ["./table-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableFooterComponent implements OnInit {
  public grid = input<AgGridAngular>();

  public tableAvailablePageSizes = input<number[]>();

  public firstElementShownNumber = signal<number>(0);

  public lastElementShownNumber = signal<number>(0);

  public paginationPageSize = signal<number>(0);

  public paginationRowCount = signal<number>(0);

  public paginationCurrentPage = signal<number>(0);

  public paginationTotalPages = signal<number>(0);

  public canGoToPrevPage = signal(false);

  public canGoToNextPage = signal(false);

  private setPropertiesTableSubject = new Subject();

  private destroyRef = inject(DestroyRef);

  private decimalPipe: DecimalPipe = inject(DecimalPipe);

  public recordsInfo = computed(
    () =>
      $localize`${this.firstElementShownNumber()}:fromNumber: to ${this.lastElementShownNumber()}:toNumber: of
    ${this.decimalPipe.transform(this.paginationRowCount(), "1.0-0")}:totalNumber:`,
  );

  public pagesInfo = computed(
    () =>
      $localize`Page ${this.paginationRowCount() ? this.paginationCurrentPage() : 0}:currentPage: of ${this.paginationTotalPages()}:totalPages:`,
  );

  public ngOnInit(): void {
    this.updateValues();

    this.setPropertiesTableSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .pipe(debounceTime(CommonConstants.DEBOUNCE_10_MS))
      .subscribe(() => {
        this.updateValues();
      });

    this.grid()
      .stateUpdated.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.setPropertiesTableSubject.next(true);
      });
  }

  public onPaginationPageSizeChanged = (pageSize: number): void => {
    this.grid().api.setGridOption("paginationPageSize", +pageSize);
  };

  public updateValues = (): void => {
    this.firstElementShownNumber.set(TableUtils.getFirstElementShownNumber(this.grid().api));
    this.lastElementShownNumber.set(TableUtils.geLastElementShownNumber(this.grid().api));
    this.canGoToPrevPage.set(this.grid().api.paginationGetCurrentPage() > 0);
    this.canGoToNextPage.set(
      this.grid().api.paginationGetCurrentPage() + 1 < this.grid().api.paginationGetTotalPages(),
    );
    this.paginationPageSize.set(this.grid().api.paginationGetPageSize());
    this.paginationRowCount.set(this.grid().api.paginationGetRowCount());
    this.paginationCurrentPage.set(this.grid().api.paginationGetCurrentPage() + 1);
    this.paginationTotalPages.set(this.grid().api.paginationGetTotalPages());
  };
}
