import { Injectable, inject, signal } from "@angular/core";

import { CountriesApiService } from "@components/shared/countries/api";
import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { RelatedRiskAssessmentReportsTableModel } from "@components/shared/risk-assessment-reports/models";
import {
  IDeliveryExtended,
  IItemExtended,
  ILocationExtended,
  IMaterial,
  IProduct,
} from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class RelatedDeliveriesRiskAssessmentReportsService {
  private readonly authService = inject(AuthenticationService);

  private readonly countriesApi = inject(CountriesApiService);

  private readonly itemSupplyChainMapperService = inject(ItemSupplyChainMapperService);

  readonly tableParams = signal<RelatedRiskAssessmentReportsTableModel.Params>(null);

  async setRelatedRiskAssessmentReportTableParams(itemIds: string[]): Promise<void> {
    const activeOrganisationId = this.authService.getActiveOrganisationId();

    const [{ content: countries }, supplyChainItems] = await Promise.all([
      this.countriesApi.getAll({}),
      this.itemSupplyChainMapperService.mapItemSupplyChain(itemIds),
    ]);

    const items = [
      ...new Map(
        supplyChainItems
          .flatMap((supplyChainItem) =>
            supplyChainItem.deliveries.flatMap((delivery) => delivery.items),
          )
          .map((item) => [item.id, item]),
      ).values(),
    ] as IItemExtended[];

    const { materials, products } = items.reduce(
      (acc, item) => ({
        materials: [...acc.materials, ...item.materials],
        products: [...acc.products, ...(item.product ? [item.product] : [])],
      }),
      { materials: [] as IMaterial[], products: [] as IProduct[] },
    );

    const { locations, deliveries } = supplyChainItems.reduce(
      (acc, supplyChainItem) => ({
        locations: [...acc.locations, ...supplyChainItem.locations],
        deliveries: [...acc.deliveries, ...supplyChainItem.deliveries],
      }),
      { locations: [] as ILocationExtended[], deliveries: [] as IDeliveryExtended[] },
    );

    const countryRecords = locations.map((location) => {
      const country = countries.find((c) => c.code === location.address.country);
      const label = country ? country.name : location.address.countryName;

      return {
        type: RelatedRiskAssessmentReportsTableModel.RecordType.COUNTRIES,
        id: country?.id,
        label,
        uri: country ? `/organisations/${activeOrganisationId}/countries/${country.id}` : null,
      };
    });

    const organisationRecords = locations.map((location) => ({
      type: RelatedRiskAssessmentReportsTableModel.RecordType.ORGANISATIONS,
      id: location.organisationId,
      label: location.organisationName,
      uri: `/organisations/${activeOrganisationId}/connections/${location.organisationId}`,
    }));

    const locationRecords = locations.map((location) => {
      return {
        type: RelatedRiskAssessmentReportsTableModel.RecordType.LOCATIONS,
        id: location.id,
        label: location.name,
        uri: `/organisations/${activeOrganisationId}/locations/${location.id}`,
      };
    });

    const materialRecords = materials.map((material) => {
      return {
        type: RelatedRiskAssessmentReportsTableModel.RecordType.MATERIALS,
        id: material.id,
        label: material.name,
        uri: `/organisations/${activeOrganisationId}/materials/${material.id}`,
      };
    });

    const productRecords = products.map((product) => {
      return {
        type: RelatedRiskAssessmentReportsTableModel.RecordType.PRODUCTS,
        id: product.id,
        label: product.name,
        uri: `/organisations/${activeOrganisationId}/products/${product.id}`,
      };
    });

    const deliveryRecords = deliveries.map((delivery) => {
      return {
        type: RelatedRiskAssessmentReportsTableModel.RecordType.DELIVERIES,
        id: delivery.id,
        label: delivery.deliveryId,
        uri: `/organisations/${activeOrganisationId}/deliveries/${delivery.id}`,
      };
    });

    this.tableParams.set({
      records: [
        ...this.uniqBy(countryRecords, "label"),
        ...this.uniqBy(locationRecords, "id"),
        ...this.uniqBy(organisationRecords, "id"),
        ...this.uniqBy(materialRecords, "id"),
        ...this.uniqBy(productRecords, "id"),
        ...this.uniqBy(deliveryRecords, "id"),
      ],
    });
  }

  private uniqBy<T>(arr: T[], key: keyof T): T[] {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }
}
