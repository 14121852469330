import { ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";

import { TableEnum } from "@shared/enums";

import { AnalysesTableService } from "./analyses-table.service";

@Component({
  standalone: false,
  selector: "app-analyses-table",
  templateUrl: "./analyses-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysesTableComponent implements OnDestroy {
  @Input()
  public tableContainerClass: string = "";

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.ANALYSIS;

  @Input()
  public isSaveTableState = false;

  constructor(public analysesTableService: AnalysesTableService) {}

  ngOnDestroy() {
    this.analysesTableService.reset();
  }
}
