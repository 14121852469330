<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add reference number</h2>

  <mat-dialog-content>
    <app-input
      formControlName="referenceNumber"
      [label]="translations.referenceNumberLabel"
    ></app-input>

    <app-input
      formControlName="verificationNumber"
      [label]="translations.verificationNumberLabel"
    ></app-input>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-button-group>
      <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
      <app-button accessibilityRole="submit">
        <mat-icon icon-left>check</mat-icon>
        <ng-container i18n>Save</ng-container>
      </app-button>
    </app-button-group>
  </mat-dialog-actions>
</form>
