import { AbstractControl, FormArray, FormGroup, ValidatorFn, Validators } from "@angular/forms";

import { isEqual } from "lodash";
import moment, { Moment } from "moment";

import { DateUtils } from "./date.utils";
import { StartEndEnum } from "../enums";
import { CustomValidators } from "../validators";

const getBooleanValue = (value: boolean | string | number): boolean => {
  switch (value) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
    default:
      return false;
  }
};

const isOptional = (abstractControl: AbstractControl): boolean => {
  return [
    abstractControl.hasValidator(CustomValidators.required),
    abstractControl.hasValidator(Validators.required),
  ].every((value) => value === false);
};

const disableControls = (
  formGroup: FormGroup,
  formControls: string[],
  emitEvent: boolean = true,
): void => {
  for (const formControl of formControls) {
    formGroup.controls[formControl].disable({ emitEvent });
  }
};

const enableControls = (
  formGroup: FormGroup,
  formControls: string[],
  emitEvent: boolean = true,
): void => {
  for (const formControl of formControls) {
    formGroup.controls[formControl].enable({ emitEvent });
  }
};

const setControlsRequiredValidator = (
  formGroup: FormGroup,
  formControls: string[],
  isRequired: boolean = true,
  emitEvent: boolean = true,
): void => {
  for (const formControl of formControls) {
    if (isRequired) {
      formGroup.controls[formControl].addValidators([CustomValidators.required]);
    } else {
      formGroup.controls[formControl].removeValidators([CustomValidators.required]);
    }
    formGroup.controls[formControl].updateValueAndValidity({ emitEvent });
  }
  formGroup.updateValueAndValidity({ emitEvent });
};

const removeInputValue = (inputId: string): void => {
  const input = <HTMLInputElement>document.getElementById(inputId);

  if (input) {
    input.value = "";
  }
};

const getDateValueForPayload = (
  momentDate: Moment | string,
  type: StartEndEnum = StartEndEnum.START,
): string | null => {
  if (!momentDate) {
    return null;
  }

  if (typeof momentDate === "string") {
    momentDate = moment.utc(momentDate, true);
  }

  return `${momentDate.year().toString().padStart(4, "0")}-${(momentDate.month() + 1)
    .toString()
    .padStart(2, "0")}-${momentDate.date().toString().padStart(2, "0")}T${
    type === StartEndEnum.START ? "00:00:00.000" : "23:59:59.999"
  }Z`;
};

const addUrlProtocol = (url: string): string => {
  if (!url) {
    return null;
  }
  const lowerCaseUrl = url.toLowerCase();

  if (lowerCaseUrl.startsWith("http://") || lowerCaseUrl.startsWith("https://")) {
    return url;
  }

  return `https://${url}`;
};

const addUrlParams = (params: object): string => {
  let result = "";

  for (const key in params) {
    const value = params[key];

    if (value !== null && value !== undefined) {
      result += `${result ? "&" : ""}${key}=${value}`;
    }
  }

  return result;
};

/**
 * Combines validators, and returns only 1 error msg.
 * @param validators
 * @returns ValidatorFn
 */
const combinedValidators = (validators: ValidatorFn[]): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    for (const validator of validators) {
      const validationResult = validator(control);

      if (validationResult !== null) {
        return validationResult;
      }
    }

    return null;
  };
};

const findAndMarkInvalidControls = (control: AbstractControl): void => {
  if (control instanceof FormGroup) {
    for (const key of Object.keys(control.controls)) {
      const childControl = control.controls[key];

      findAndMarkInvalidControls(childControl);
    }
  } else if (control instanceof FormArray) {
    for (const childControl of control.controls) {
      findAndMarkInvalidControls(childControl);
    }
  } else {
    if (control.invalid) {
      control.markAsDirty();
      control.markAsTouched();
    }
  }
};

const hasInitialFormValueChanged = (
  initialFormValue: object,
  currentFormValue: object,
): boolean => {
  let hasFieldChanges = false;

  for (const key of Object.keys(initialFormValue)) {
    const currentValue = currentFormValue[key];
    const initialValue = initialFormValue[key];

    if (!isEqual(currentValue, initialValue)) {
      let hasChanged = false;

      if (DateUtils.isValidDate(currentValue) && DateUtils.isValidDate(initialValue)) {
        const momentCurrentValue = moment.utc(currentValue);
        const momentInitialValue = moment.utc(initialValue);

        if (momentCurrentValue.isValid() && momentInitialValue.isValid()) {
          hasChanged = !momentCurrentValue.startOf("day").isSame(momentInitialValue.startOf("day"));
        }
      } else {
        hasChanged = !isEqual(currentValue, initialValue);
      }
      if (hasChanged) {
        hasFieldChanges = true;
        break;
      }
    }
  }

  return hasFieldChanges;
};

export const FormUtils = {
  getBooleanValue,
  isOptional,
  disableControls,
  enableControls,
  setControlsRequiredValidator,
  removeInputValue,
  getDateValueForPayload,
  addUrlProtocol,
  addUrlParams,
  hasInitialFormValueChanged,
  combinedValidators,
  findAndMarkInvalidControls,
};
