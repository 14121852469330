import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-location-overlay-address",
  templateUrl: "./location-overlay-address.component.html",
  styleUrl: "./location-overlay-address.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayAddressComponent {
  @Input() formGroup: FormGroup;

  @Input() countryOptions: ISelectOption[] = [];

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    addressLabel: TextConstants.ADDRESS,
    countryLabel: TextConstants.COUNTRY,
    zipCodeLabel: TextConstants.ZIP_POSTCODE,
    regionLabel: TextConstants.STATE_REGION,
  };
}
