import { inject } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { BulkAddSlideOverService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.service";

export abstract class BulkAddSlideOverClass<TFormGroup extends FormGroup = FormGroup> {
  protected bulkAddService = inject(BulkAddSlideOverService);

  abstract canClose(): Promise<boolean>;

  public canRemoveAssociatedQueryParams(): boolean {
    return true;
  }

  public afterClose(): void {}

  public slideOverWrapperClass(): string {
    return "";
  }

  public abstract get formGroup(): TFormGroup;
}
