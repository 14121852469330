import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { debounceTime, Subject, Subscription } from "rxjs";
import {
  ICreateOutboundConnectPayload,
  IOrganisation,
  IPublicOrganisation,
  ISelectOption,
} from "src/app/shared/interfaces";

import { AddConnectionDialogComponent } from "@components/locations";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CommonConstants, TextConstants } from "@shared/constants";
import { ConnectionStatusEnum } from "@shared/enums";
import {
  NotificationService,
  ConnectionsService,
  ConnectRequestsService,
  OrganisationsService,
} from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./settings-add-connection-dialog.component.html",
  styleUrl: "./settings-add-connection-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsAddConnectionDialogComponent implements OnInit, OnDestroy {
  public isLoading = signal(true);

  public isLoadingConnectionDetails = signal(false);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    organisationId: new UntypedFormControl(null, [
      CustomValidators.required,
      CustomValidators.guid({ errorMessage: $localize`Invalid code` }),
      CustomValidators.forbiddenValueValidator(
        this.data.activeOrganisationId,
        $localize`You can't connect to your own organisation`,
      ),
    ]),
    connection: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public selectedPublicOrganisation = signal<IPublicOrganisation>(undefined);

  public selectedOrganisationExistingConnection = signal<IOrganisation>(undefined);

  public connectionsOptions: ISelectOption[] = [];

  public readonly connectionStatusEnum = ConnectionStatusEnum;

  private selectedPublicOrganisationSubject = new Subject<string>();

  private subscriptions = new Subscription();

  public readonly translations: any = {
    organisationCodeHint: $localize`The organisation's information will appear here as soon as you enter a valid code`,
    organisationIdLabel: $localize`Organisation's code`,
    connectionLabel: TextConstants.ORGANISATION,
  };

  constructor(
    public dialogRef: MatDialogRef<SettingsAddConnectionDialogComponent>,
    private notificationService: NotificationService,
    private organisationsService: OrganisationsService,
    private connectionsService: ConnectionsService,
    private connectRequestsService: ConnectRequestsService,
    private routerService: RouterService,
    private overlay: SlideOverlayPageService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      activeOrganisationId: string;
      countryOptions: ISelectOption[];
    },
  ) {
    this.subscriptions.add(
      this.selectedPublicOrganisationSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_COPY_PASTE_MS))
        .subscribe(async (organisationId: string) => {
          this.selectedPublicOrganisation.set(undefined);
          this.selectedOrganisationExistingConnection.set(undefined);
          this.connectionFormControl.setValue(undefined, { emitValue: false });
          if (this.organisationIdFormControl.valid) {
            this.organisationIdFormControl.disable({ emitEvent: false });
            await this.setSelectedPublicOrganisation(organisationId);
          }
        }),
    );

    this.subscriptions.add(
      this.organisationIdFormControl.valueChanges.subscribe((organisationId: string) => {
        this.selectedPublicOrganisationSubject.next(organisationId);
      }),
    );
  }

  public async ngOnInit(): Promise<void> {
    this.isLoading.set(false);
  }

  private get organisationIdFormControl() {
    return this.formGroup.controls["organisationId"];
  }

  private get connectionFormControl() {
    return this.formGroup.controls["connection"];
  }

  private setSelectedPublicOrganisation = async (id: string): Promise<void> => {
    if (!id) {
      return;
    }
    this.isLoadingConnectionDetails.set(true);
    try {
      let selectedPublicOrganisation = await this.organisationsService.getPublic(id);

      selectedPublicOrganisation = CommonUtils.getElementsWithCountryName(
        this.data.countryOptions,
        selectedPublicOrganisation,
      );

      this.selectedPublicOrganisation.set(selectedPublicOrganisation);

      await this.checkForExistingConnection(id);
    } catch (error) {
      this.notificationService.showError(
        error.status === 404
          ? $localize`Wrong or non existing code. Please, ensure you've entered it correctly`
          : error,
      );
      this.organisationIdFormControl.enable({ emitEvent: false });
      this.selectedPublicOrganisation.set(undefined);
      this.connectionFormControl.setValue(undefined, { emitValue: false });
      this.isLoadingConnectionDetails.set(false);
    }
  };

  private checkForExistingConnection = async (id: string): Promise<void> => {
    this.connectionsOptions = [];
    const publicUri = `/organisations/${id}`;

    const allConnections = await this.connectionsService.getAll();

    const existingConnection = allConnections.find((c) => c.publicUri === publicUri);

    this.selectedOrganisationExistingConnection.set(existingConnection);

    if (!existingConnection) {
      this.connectionsOptions = allConnections
        .filter((c) => !c.publicUri)
        .map((c) => ({ label: c.name, value: c.id }) as ISelectOption);
    } else {
      this.connectionFormControl.setValue(
        {
          label: existingConnection.name,
          value: existingConnection.id,
        },
        { emitEvent: false },
      );
      this.connectionFormControl.updateValueAndValidity();
    }

    this.organisationIdFormControl.enable({ emitEvent: false });
    this.isLoadingConnectionDetails.set(false);
    CommonUtils.scrollToBottom(".mat-mdc-dialog-content");
  };

  public getConnectionLink(id: string): NavigationParams {
    return <NavigationParams>this.routerService.getOrganisationLink(id, false);
  }

  public onAddNewConnection = async (): Promise<void> => {
    const result = await this.overlay.openDialog<
      { savedOrganisation: IOrganisation },
      { connection: IOrganisation; countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.data.countryOptions,
      connection: this.selectedPublicOrganisation(),
    });

    if (result?.savedOrganisation) {
      const newConnectionOption = {
        label: result.savedOrganisation.name,
        value: result.savedOrganisation.id,
      };

      this.connectionsOptions.unshift(newConnectionOption);
      this.formGroup.controls["connection"].setValue(newConnectionOption);
    }
  };

  public isSubmitButtonDisabled = (): boolean =>
    this.formGroup.invalid ||
    this.formGroup.pending ||
    this.isLoadingConnectionDetails() ||
    !this.selectedPublicOrganisation() ||
    this.selectedOrganisationExistingConnection()?.connectStatus === ConnectionStatusEnum.CONNECTED;

  public onSubmit = async (): Promise<void> => {
    try {
      this.isLoading.set(true);

      const payload: ICreateOutboundConnectPayload = {
        orgUri: `/organisations/${this.formGroup.controls["organisationId"].value}`,
        connectionUri: `/organisations/${this.data.activeOrganisationId}/connections/${this.formGroup.controls["connection"].value.value}`,
      };

      await this.connectRequestsService.createOutbound(payload);

      this.notificationService.showSuccess($localize`Connection requested`);
      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
