import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RecordStateEnum, RouteEnum } from "src/app/shared/enums";

import { TextConstants } from "@shared/constants";

import { EditCustomFieldDialogComponent } from "..";
import { CustomFieldsTableComponent } from "../../shared";

@Component({
  standalone: false,
  templateUrl: "./settings-custom-fields.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCustomFieldsComponent {
  @ViewChild("customFieldsTable") customFieldsTable: CustomFieldsTableComponent;

  public readonly routingEnum = RouteEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public readonly translations = {
    title: $localize`Custom fields`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditCustomFieldDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.customFieldsTable.getAll();
      }
    });
  };
}
