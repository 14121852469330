<app-slide-overlay-header
  [canArchive]="true"
  [enableTags]="true"
  [canDelete]="true"
  [title]="title()"
  [canSend]="isCrossOrgSharingEnabled && isCrossOrgSharingProcessEnabled"
  iconClass="rotate-horizontal"
  icon="sync"
  (send)="confirmSendDialog()"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group
      [class.no-separator]="
        !visibleCustomFields?.length &&
        !processOutputTable?.rowData?.length &&
        !processInputTable?.rowData?.length
      "
      [name]="mainInformationText"
    >
      <div [formGroup]="formGroup" class="row-container">
        <div class="item">
          <app-input enableViewMode formControlName="processId" label="ID"></app-input>
        </div>
        <div class="item">
          <app-input-select
            [options]="allProcessTypesOptions"
            addOnWrite
            enableViewMode
            formControlName="type"
            [label]="translations.typeLabel"
          ></app-input-select>
        </div>
        <div class="item">
          <app-input-select
            [options]="allLocationsOptions"
            [viewModeTemplate]="locationViewTemplate"
            enableViewMode
            formControlName="location"
            [label]="translations.locationLabel"
          ></app-input-select>
        </div>

        @if (overlay.viewMode()) {
          <div class="item">
            <app-form-input-readonly
              [value]="element.firstInputDate | appDate"
              [label]="translations.firstInputLabel"
            ></app-form-input-readonly>
          </div>
          <div class="item">
            <app-form-input-readonly
              [value]="element.lastOutputDate | appDate"
              [label]="translations.lastOutputLabel"
            ></app-form-input-readonly>
          </div>
        }
      </div>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group
        [class.no-separator]="
          !processOutputTable?.rowData?.length && !processInputTable?.rowData?.length
        "
        [name]="additionalInformationText"
      >
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    @if (isEditing() && overlay.viewMode()) {
      <app-form-field-group
        class="display-block"
        [class.no-separator]="!processOutputTable?.rowData?.length"
        [class.hide]="!processInputTable.isLoading() && !processInputTable.rowData.length"
        [class.mb-25]="!processOutputTable.rowData.length"
        [name]="translations.inputSummaryLabel"
      >
        <app-process-in-out-table
          #processInputTable
          class="no-min-height"
          [class.mb-25]="!processOutputTable.rowData.length"
          type="input"
          [processId]="element.id"
          [allProducts]="allProducts"
          [allUnitOfMeasurements]="allUnitOfMeasurements"
          [isSearchEnabled]="false"
        ></app-process-in-out-table>
      </app-form-field-group>
      <app-form-field-group
        class="display-block"
        [class.hide]="!processInputTable.isLoading() && !processOutputTable.rowData.length"
        [name]="translations.outputSummaryLabel"
      >
        <app-process-in-out-table
          #processOutputTable
          class="no-min-height"
          type="output"
          [processId]="element.id"
          [allProducts]="allProducts"
          [allUnitOfMeasurements]="allUnitOfMeasurements"
          [isShowSelectCheckbox]="true"
          [isShowPercentages]="true"
          [isSearchEnabled]="false"
        ></app-process-in-out-table>
      </app-form-field-group>
    }
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.INPUTS).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.INPUTS).title"
    [overlay-menu-indicator]="processOverlayService.inputCounter()"
    appSlideOverlayMenuItem
    overlay-menu-icon="login"
  >
    <article class="field-group-container no-top-padding">
      <app-process-inputs-outputs
        (changed)="onInputsChanged($event)"
        [allMaterials]="allMaterials"
        [allProducts]="allProducts"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [displayTitle]="false"
        [isReadOnly]="isRecordArchived"
        [canAddModifyEntities]="canAddModifyEntities"
        [processId]="element?.id"
        [summaryComponentType]="summaryComponentEnum.PROCESS_INPUTS_OUTPUTS"
        type="input"
      ></app-process-inputs-outputs>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.OUTPUTS).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="processOverlayService.outputCounter()"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.OUTPUTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="logout"
  >
    <article class="field-group-container no-top-padding">
      <app-process-inputs-outputs
        (changed)="onOutputsChanged($event)"
        [allMaterials]="allMaterials"
        [allProducts]="allProducts"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [displayTitle]="false"
        [isReadOnly]="isRecordArchived"
        [canAddModifyEntities]="canAddModifyEntities"
        [processId]="element?.id"
        [processLocation]="formGroup.controls['location'].value"
        [summaryComponentType]="summaryComponentEnum.PROCESS_INPUTS_OUTPUTS"
        type="output"
      ></app-process-inputs-outputs>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="processOverlayService.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.PROCESS"
      [isReadOnly]="isRecordArchived"
      [overlayService]="processOverlayService"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="processOverlayService.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.PROCESS"
      [isReadOnly]="isRecordArchived"
      [overlayService]="processOverlayService"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="processOverlayService.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(processOverlayService)"
        (deleted)="onCommentDeleted(processOverlayService)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <app-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </app-form-link>
        } @else {
          @if (isSharedUser) {
            <app-not-shared-data></app-not-shared-data>
          } @else {
            <app-loader containerClass="size-small"></app-loader>
          }
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>
