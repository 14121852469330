<app-slide-overlay-header
  [canArchive]="false"
  [enableTags]="false"
  [canDelete]="false"
  [title]="title()"
  icon="sync"
  iconClass="rotate-horizontal"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="item">
          <app-input enableViewMode formControlName="processId" label="ID"></app-input>
        </div>
        <div class="item">
          <app-input-select
            [options]="allProcessTypesOptions()"
            addOnWrite
            enableViewMode
            formControlName="type"
            [label]="translations.typeLabel"
          ></app-input-select>
        </div>
        <div class="item">
          <app-input-select
            [options]="allLocationsOptions()"
            [viewModeTemplate]="locationViewTemplate"
            enableViewMode
            formControlName="location"
            [label]="translations.locationLabel"
          ></app-input-select>
        </div>

        @if (overlay.viewMode()) {
          <div class="item">
            <app-form-input-readonly
              [value]="element()?.firstInputDate | appDate"
              [label]="translations.firstInputLabel"
            ></app-form-input-readonly>
          </div>
          <div class="item">
            <app-form-input-readonly
              [value]="element()?.lastOutputDate | appDate"
              [label]="translations.lastOutputLabel"
            ></app-form-input-readonly>
          </div>
        }
      </div>
    </app-form-field-group>
    <app-form-field-group
      class="display-block"
      [class.no-separator]="!processOutputTable?.rowData?.length"
      [class.hide]="!processInputTable.isLoading() && !processInputTable.rowData.length"
      [class.mb-25]="!processOutputTable.rowData.length"
      [name]="translations.inputSummaryLabel"
    >
      <app-process-in-out-table
        #processInputTable
        class="no-min-height"
        [class.mb-25]="!processOutputTable.rowData.length"
        type="input"
        [allProducts]="allProducts()"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [isSearchEnabled]="false"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [inboundProcessResources]="processInputs()"
        [inboundItems]="items()"
      ></app-process-in-out-table>
    </app-form-field-group>
    <app-form-field-group
      class="display-block"
      [class.hide]="!processInputTable.isLoading() && !processOutputTable.rowData.length"
      [name]="translations.outputSummaryLabel"
    >
      <app-process-in-out-table
        #processOutputTable
        class="no-min-height"
        type="output"
        [allProducts]="allProducts()"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [isShowPercentages]="true"
        [isSearchEnabled]="false"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [inboundProcessResources]="processOutputs()"
        [inboundInputs]="processInputs()"
        [inboundItems]="items()"
      ></app-process-in-out-table>
    </app-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="false"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.INPUTS).title"
    [overlay-menu-indicator]="processInputs().length"
    appSlideOverlayMenuItem
    overlay-menu-icon="login"
  >
    <article class="field-group-container no-top-padding">
      <app-process-inputs-outputs
        [allMaterials]="allMaterials"
        [allProducts]="allProducts()"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [displayTitle]="false"
        [canAddModifyEntities]="false"
        [summaryComponentType]="summaryComponentEnum.PROCESS_INPUTS_OUTPUTS"
        type="input"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [inboundProcessResources]="processInputs()"
        [inboundItems]="items()"
        [isReadOnly]="true"
      ></app-process-inputs-outputs>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="processOutputs().length"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.OUTPUTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="logout"
  >
    <article class="field-group-container no-top-padding">
      <app-process-inputs-outputs
        [allMaterials]="allMaterials"
        [allProducts]="allProducts()"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [displayTitle]="false"
        [isReadOnly]="true"
        [canAddModifyEntities]="false"
        [summaryComponentType]="summaryComponentEnum.PROCESS_INPUTS_OUTPUTS"
        type="output"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [inboundProcessResources]="processOutputs()"
        [inboundItems]="items()"
      ></app-process-inputs-outputs>
    </article>
  </ng-template>

  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <app-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </app-form-link>
        } @else {
          <app-loader containerClass="size-small"></app-loader>
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>
