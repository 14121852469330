import { HttpEvent, HttpEventType } from "@angular/common/http";
import { Component, inject, OnDestroy } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { Subscription, catchError, tap } from "rxjs";
import { CommonUtils, FileUtils } from "src/app/shared/utils";

import { CommonConstants, TextConstants } from "@shared/constants";
import { FileExtensionEnum } from "@shared/enums";
import { IDocument } from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  DocumentsService,
  RecordSharingService,
} from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./view-document.component.html",
  styleUrls: ["./view-document.component.scss"],
})
export class ViewDocumentComponent implements OnDestroy {
  public downloadProgress = 0;

  public errorMessage: string;

  public document: IDocument;

  public documentExtension: FileExtensionEnum;

  public documentUrl: SafeResourceUrl;

  public documentBodyAsText: string;

  public fileExtensionEnum = FileExtensionEnum;

  public readonly DOCUMENT_CANT_BE_DISPLAYED_TEXT = $localize`This document cannot be displayed on the browser, please download it instead`;

  private subscriptions = new Subscription();

  private senderOrgId: string;

  private recordSharingService = inject(RecordSharingService);

  private activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  public readonly translations: any = {
    downloadTp: TextConstants.DOWNLOAD,
  };

  constructor(
    private documentsService: DocumentsService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
  ) {
    const documentId = this.route.snapshot.params["id"];

    this.senderOrgId = this.route.snapshot.queryParams["senderOrgId"];

    if (documentId) {
      this.loadDocument(documentId);
    } else {
      this.errorMessage = "Document ID is missing";
    }
  }

  private loadDocument = async (documentId: string): Promise<void> => {
    try {
      if (this.senderOrgId) {
        const inboundUri = `organisations/${this.activeOrganisationId}/record-sharing/inbound/records/${this.senderOrgId}/documents/${documentId}/content`;
        const inboundDocuments = await this.recordSharingService.getInboundDocumentsByIdsGraphQL(
          [documentId],
          this.senderOrgId,
          CommonConstants.MAX_API_GET_ITEMS_SIZE,
        );

        const document = inboundDocuments[0];

        this.document = document as any;

        this.downloadAndDisplayDocument(documentId, inboundUri);

        return;
      }
      this.document = await this.documentsService.get(documentId);
      if (!this.document.contentType) {
        throw TextConstants.DOCUMENT_NO_CONTENT;
      }
      CommonUtils.setDocumentsCanView([this.document]);
      if (!this.document.canView) {
        throw this.DOCUMENT_CANT_BE_DISPLAYED_TEXT;
      }
      this.downloadAndDisplayDocument(documentId);
    } catch (error) {
      this.errorMessage = typeof error === "string" ? error : $localize`Document not found`;
    }
  };

  private downloadAndDisplayDocument = (documentId: string, urlPath?: string): void => {
    this.subscriptions.add(
      this.documentsService
        .downloadContentWithProgress(documentId, urlPath)
        .pipe(
          tap((event: HttpEvent<HttpEventType.DownloadProgress | HttpEventType.Response>) => {
            switch (event.type) {
              case HttpEventType.DownloadProgress: {
                const progress = Math.round((event.loaded / (event.total ?? 0)) * 100);

                this.downloadProgress = progress >= 100 ? 99 : progress;
                break;
              }
              case HttpEventType.Response: {
                this.documentExtension = FileUtils.getFileExtension(
                  this.document.name,
                ).toUpperCase() as FileExtensionEnum;

                switch (this.documentExtension) {
                  case FileExtensionEnum.JSON:
                  case FileExtensionEnum.GEOJSON:
                  case FileExtensionEnum.TXT: {
                    this.setDocumentBodyAsText(
                      event.body,
                      [FileExtensionEnum.JSON, FileExtensionEnum.GEOJSON].includes(
                        this.documentExtension,
                      ),
                    );
                    break;
                  }
                }
                this.documentUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
                  window.URL.createObjectURL(event.body as any),
                );

                this.downloadProgress = 100;
                break;
              }
            }
          }),
          catchError((error) => {
            console.error(error);
            throw $localize`An error occurred when downloading this document`;
          }),
        )
        .subscribe(),
    );
  };

  private setDocumentBodyAsText = (documentBody: any, isJsonFormat: boolean): void => {
    const reader = new FileReader();

    reader.readAsText(documentBody);

    reader.onload = () => {
      try {
        if (isJsonFormat) {
          this.documentBodyAsText = JSON.stringify(JSON.parse(reader.result.toString()), null, 2);
        } else {
          this.documentBodyAsText = reader.result.toString();
        }
      } catch {
        this.notificationService.showError(this.DOCUMENT_CANT_BE_DISPLAYED_TEXT);
      }
    };
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
