<div class="container-flex-space-between">
  <div class="left-content">
    <div
      [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
      class="name"
      [tp]="material.name"
      [tpDelay]="500"
      tpPlacement="top-start"
    >
      {{ material.name }}
    </div>
    <div class="type gray">
      {{ material.category }}
    </div>
  </div>
  <app-button-group>
    @if (type === cardContentTypeEnum.ADD) {
      <app-button size="medium" type="icon">
        <mat-icon icon-medium-18>add</mat-icon>
      </app-button>
    } @else {
      <app-button
        (pressed)="onClickRemove()"
        [tp]="translations.removeTp"
        size="medium"
        type="icon"
      >
        <mat-icon icon-medium-18>close</mat-icon>
      </app-button>
    }
  </app-button-group>
</div>
