<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routing.ADMIN"
  />
  @if (canAddModifyEntities) {
    <app-button-group>
      <app-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add new</ng-container>
      </app-button>
    </app-button-group>
  }
</div>

<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [mat-stretch-tabs]="false"
  [selectedIndex]="selectedTabIndex()"
  (selectedIndexChange)="selectedTabIndex.set($event)"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Countries</ng-container>

      <app-indicator [value]="countriesTable.totalElements()" />
    </ng-template>
    <app-risk-assessment-templates-table
      #countriesTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.COUNTRY"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Organisations</ng-container>

      <app-indicator [value]="organisationsTable.totalElements()" />
    </ng-template>
    <app-risk-assessment-templates-table
      #organisationsTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.ORGANISATION"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Locations</ng-container>

      <app-indicator [value]="locationsTable.totalElements()" />
    </ng-template>
    <app-risk-assessment-templates-table
      #locationsTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.LOCATION"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Materials</ng-container>

      <app-indicator [value]="materialsTable.totalElements()" />
    </ng-template>
    <app-risk-assessment-templates-table
      #materialsTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.MATERIAL"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Products</ng-container>

      <app-indicator [value]="productsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-risk-assessment-templates-table
      #productsTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.PRODUCT"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Deliveries</ng-container>

      <app-indicator [value]="deliveriesTable.totalElements()" />
    </ng-template>
    <app-risk-assessment-templates-table
      #deliveriesTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
      [resourceType]="resourceType.DELIVERY"
    />
  </mat-tab>
</mat-tab-group>
