<div class="commodity row-container" [formGroup]="formGroup">
  <div class="title">
    <div class="index">
      <div class="circle">{{ index + 1 }}</div>
    </div>
    <div class="text">
      <div>{{ formGroup.value["hsCodeTitle"] }}</div>
    </div>
  </div>

  <div class="row commodities-description-row">
    @if (displayFields) {
      <app-input formControlName="description" [label]="translations.descriptionLabel"></app-input>

      <app-input
        tooltipIcon="alert-triangle"
        [isSvgIcon]="true"
        iconClass="warn-icon"
        [tooltip]="formGroup.value['displayUnitWarning'] ? translations.netMassTooltip : null"
        formControlName="netMass"
        type="number"
        [label]="translations.netMassLabel"
      ></app-input>
      <app-input
        tooltipIcon="alert-triangle"
        [isSvgIcon]="true"
        iconClass="warn-icon"
        [tooltip]="formGroup.value['displayUnitWarning'] ? translations.netMassTooltip : null"
        formControlName="volume"
        type="number"
        [label]="translations.volumeLabel"
      ></app-input>
    } @else {
      <ng-container i18n>No information on points of origin could be found</ng-container>
      .
    }
  </div>
  @if (materialsInfoFormGroups.length) {
    <div class="materials-info" formArrayName="materialsInfo">
      <div *ngFor="let materialInfo of materialsInfoFormGroups; let j = index" [formGroupName]="j">
        <app-reports-eudr-material-info [formGroup]="materialInfo" [index]="j" />
      </div>
    </div>
  }
  <div class="producers-info" formArrayName="producersInfo">
    <div *ngFor="let producerInfo of producersInfoFormGroups; let j = index" [formGroupName]="j">
      <app-reports-eudr-producer-info [formGroup]="producerInfo" [index]="j" />
    </div>
  </div>
</div>
