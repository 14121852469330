import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  signal,
} from "@angular/core";

import { ColDef, ValueGetterParams } from "ag-grid-community";
import { Subscription } from "rxjs";
import {
  LinkCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
} from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IBaseUnit, IItem, IItemExtended, IMaterial, IProduct } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  ItemsService,
  LocationsService,
  FeatureFlagService,
} from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-items-table",
  templateUrl: "./items-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public items: IItem[] = null;

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public allProducts: IProduct[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  @Input()
  public shouldOpenInNewTab = false;

  public readonly table = TableEnum.ITEMS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public columns: string[] = [
    "recordState",
    "itemId",
    "product.name",
    "materials",
    "initialQuantity",
    "created",
    "currentLocation.name",
    "deliveries",
    "tags",
  ];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: IItemExtended[] = [];

  public columnDefs = signal<ColDef[]>([]);

  private subscriptions = new Subscription();

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor(
    private routerService: RouterService,
    public itemsService: ItemsService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private overlay: SlideOverlayPageService,
    private bulkAddItemsService: BulkAddItemsService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe((instance: SlideOverlayPageClass) => {
        if (instance.entityType === EntityTypeEnum.ITEMS) {
          this.getAll();
        }
      }),
    );

    this.subscriptions.add(
      this.bulkAddItemsService.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "itemId",
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.itemsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.itemsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.itemsService),
        ],
      ]),
    };
  };

  public onViewDetails = async (row: IItem): Promise<void> => {
    const link = this.isInboundShared
      ? this.routerService.getSharedItemLink(row.id, false, {
          organisationId: this.inboundSharedSenderOrgId,
        })
      : this.routerService.getItemLink(row.id, !this.shouldOpenInNewTab);

    if (this.shouldOpenInNewTab) {
      this.routerService.openNewTab(link);
    } else {
      await this.routerService.navigate(link);
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: $localize`ID`,
        field: "itemId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.isInboundShared
            ? (id) => {
                return this.routerService.getSharedItemLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                });
              }
            : this.routerService.getItemLink,
          openInNewTab: this.shouldOpenInNewTab,
        },
        suppressSizeToFit: true,
      },
      {
        headerName: TextConstants.PRODUCT,
        field: "product.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "product.id",
          linkRouteFn: this.isInboundShared
            ? (id) =>
                this.routerService.getSharedProductLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
            : this.routerService.getProductLink,
          openInNewTab: this.shouldOpenInNewTab,
        },
      },
      {
        headerName: TextConstants.INITIAL_QTY,
        field: "initialQuantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "product.unitOfMeasurement.precision",
          symbolParam: "product.unitOfMeasurement.symbol",
          formattedQuantityParam: "initialQuantityFormatted",
        },
      },
      {
        headerName: $localize`Delivered q-ty`,
        field: "deliveredQuantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "product.unitOfMeasurement.precision",
          symbolParam: "product.unitOfMeasurement.symbol",
          formattedQuantityParam: "deliveredQuantityFormatted",
        },
      },
      ColumnUtils.dateRangeColumn({
        headerName: $localize`Created on`,
        field: "created",
        valueFormatter: (row) => row.value?.on ?? `${row.value?.start} - ${row.value?.end}`,
        filterValueGetter: (params: ValueGetterParams<IItem>) => {
          const { on, start, end } = params.data.created;

          if (on) {
            return ColumnUtils.dateFilterDate(on);
          } else if (start) {
            return {
              start: ColumnUtils.dateFilterDate(start),
              end: ColumnUtils.dateFilterDate(end),
              filterOption: params.api.getFilterModel()["created"]["type"],
            };
          }

          return undefined;
        },
      }),
      {
        headerName: $localize`Current / last location`,
        field: "currentLocation.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "currentLocation.id",
          linkRouteFn: this.routerService.getLocationLink,
          openInNewTab: this.shouldOpenInNewTab,
        },
      },
      ColumnUtils.multipleLinks("Deliveries", "deliveries", {
        textParam: "deliveryId",
        linkRouteIdParam: "id",
        linkRouteFn: this.routerService.getDeliveryLink,
        openInNewTab: this.shouldOpenInNewTab,
      }),
    ];

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags());
    }
    if (this.isOldMaterialsEnabled) {
      columnDefs.push(
        ColumnUtils.chips("Materials", "materials", {
          textParam: "name",
          linkRouteIdParam: "id",
          linkRouteFn: (id) =>
            this.isInboundShared
              ? this.routerService.getSharedMaterialLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
              : this.routerService.getMaterialLink(id, false),
          openInNewTab: this.shouldOpenInNewTab,
        }),
      );
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);
    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private getParsedRowData = async (items: any[]): Promise<any[]> => {
    for (const item of items) {
      if (typeof item.currentLocation === "string") {
        const location = await this.locationsService.get(
          CommonUtils.getUriId(item.currentLocation),
        );

        item.currentLocation = {
          id: location?.id,
          name: location?.name,
        };
      }

      if (!item.materialNames?.length) {
        item.materialNames = [];
        if (item.materials?.length) {
          for (const material of item.materials) {
            if (typeof material === "string") {
              const materialId = CommonUtils.getUriId(material);
              const materialObj = this.allMaterials.find((c) => c.id === materialId);

              if (materialObj) {
                item.materialNames.push(materialObj.name);
              }
            } else {
              item.materialNames.push(material.name);
            }
          }
        }
      }

      if (typeof item.product === "string") {
        const productId = CommonUtils.getUriId(item.product);
        const product = this.allProducts.find((p) => p.id === productId);

        if (product) {
          const baseUnitId = CommonUtils.getUriId(product.baseUnit);

          item.product = {
            id: productId,
            name: product.name,
            unitOfMeasurement: this.allUnitOfMeasurements.find((u) => u.id === baseUnitId),
          };
        }
      }
    }

    return items;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    const fieldsToInclude = [];
    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    const deliveriesFieldPresent = this.columnDefs().some((c) => c.field === "deliveries");

    if (deliveriesFieldPresent) {
      fieldsToInclude.push("DELIVERIES");
    }

    if (this.items) {
      this.rowData = await this.getParsedRowData(this.items);
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.itemsService.getAllGraphQL(undefined, undefined, fieldsToInclude);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
