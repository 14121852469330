import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  TemplateRef,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-edit-delivery-main-details",
  templateUrl: "./edit-delivery-main-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDeliveryMainDetailsComponent {
  @Input() formGroup: FormGroup;

  public fromLocationOptions = input<ISelectOption[]>([]);

  public toLocationOptions = input<ISelectOption[]>([]);

  public organisationsOptions = input<ISelectOption[]>([]);

  public statusOptions = input<ISelectOption[]>([]);

  public isEditing = input<boolean>(false);

  public locationViewTemplate = input<TemplateRef<unknown>>(null);

  public readonly translations: any = {
    fromLabel: $localize`From`,
    toLabel: $localize`To`,
    statusLabel: TextConstants.STATUS,
    sentLabel: $localize`Sent`,
    deliveredLabel: $localize`Delivered`,
    agentsLabel: $localize`Trader(s)`,
    agentsTooltip: $localize`State which organisation(s) performed the trade. Up to 5 organisations can be stated.`,
  };

  @Output() traderTagPressed: EventEmitter<InputSelectOption> =
    new EventEmitter<InputSelectOption>();
}
