import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-certificate-overlay-main-information",
  templateUrl: "./certificate-overlay-main-information.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateOverlayMainInformationComponent {
  @Input() formGroup: FormGroup;

  standardOptions = input<ISelectOption[]>([]);

  standardTypeOptions = input<ISelectOption[]>([]);

  public readonly translations: any = {
    number: $localize`Number`,
    standard: $localize`Standard`,
    standardType: $localize`Standard type`,
    issuanceDate: TextConstants.ISSUANCE_DATE,
    validFrom: TextConstants.VALID_FROM,
    validTo: TextConstants.VALID_TO,
    urlTooltip: $localize`Specify a link to an external source that contains up-to-date information about this
                   certificate for ease of navigating to it to get the most recent information.`,
  };
}
