import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  input,
  signal,
  OnDestroy,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import { LinkCellRendererComponent } from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";
import {
  ILocationExtended,
  IProcess,
  IProcessExtended,
  IProcessType,
} from "src/app/shared/interfaces";
import { AuthenticationService, LocationsService, ProcessesService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-processes-table",
  templateUrl: "./processes-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessesTableComponent implements AfterViewInit, OnDestroy {
  //todo use only IProcessExtended when GraphQL is used for reference tables
  processes = input<IProcessExtended[] | IProcess[]>(null);

  shouldOpenInNewTab = input<boolean>(false);

  allProcessTypes = input<IProcessType[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.PROCESSES);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "processId",
    "type.name",
    "location.name",
    "firstInputDate",
    "lastOutputDate",
    "tags",
  ]);

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  isLoading = signal<boolean>(true);

  rowData = signal<IProcessExtended[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private processesService: ProcessesService,
    private notificationService: NotificationService,
    private locationsService: LocationsService,
    private authenticationService: AuthenticationService,
    private overlay: SlideOverlayPageService,
  ) {
    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe((instance: SlideOverlayPageClass) => {
        if (instance.entityType === EntityTypeEnum.PROCESSES) {
          this.getAll();
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "processId",
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.processesService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.processesService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.processesService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: $localize`ID`,
        field: "processId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getProcessLink,
          openInNewTab: this.shouldOpenInNewTab(),
        },
        suppressSizeToFit: true,
      },
      { headerName: TextConstants.TYPE, field: "type.name" },
      {
        headerName: $localize`Location`,
        field: "location.name",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "location.id",
          linkRouteFn: this.routerService.getLocationLink,
          openInNewTab: this.shouldOpenInNewTab(),
        },
      },
      ColumnUtils.dateColumn({
        headerName: $localize`First input date`,
        field: "firstInputDate",
      }),
      ColumnUtils.dateColumn({
        headerName: $localize`Last output date`,
        field: "lastOutputDate",
      }),
    ];

    if (this.authenticationService.isRegularUser()) {
      columnDefs.push(ColumnUtils.tags());
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onClick = async (row: IProcess): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }

    if (this.shouldOpenInNewTab()) {
      this.routerService.openNewTab(this.routerService.getProcessLink(row.id, false));
    } else {
      this.routerService.navigate(this.routerService.getProcessLink(row.id));
    }
  };

  private getParsedRowData = async (processes: any): Promise<void> => {
    const allProcessTypes = this.allProcessTypes();

    for (let process of processes) {
      if (typeof process?.location === "string") {
        const location = await CommonUtils.handleApiCall(
          this.locationsService.get(CommonUtils.getUriId(process.location)),
          "Failed to fetch location",
        );

        process.location = location as unknown as ILocationExtended;
        // It's ok like this because this will be refactored when we use graphql everywhere
      }
      if (typeof process?.type === "string") {
        process = CommonUtils.getElementsWithType<IProcessExtended>(
          allProcessTypes,
          process,
        ) as IProcessExtended;
      }
    }
  };

  public getAll = async (): Promise<void> => {
    const processes = this.processes();
    const columnDefs = this.columnDefs();

    this.isLoading.set(true);
    if (processes) {
      await this.getParsedRowData(processes);
      this.rowData.set(processes as IProcessExtended[]);
      this.isLoading.set(false);
    } else {
      try {
        const rowData = await this.processesService.getAllGraphQL(
          undefined,
          undefined,
          columnDefs.some((c) => c.field === "tags") ? ["TAGS"] : undefined,
        );

        this.rowData.set(rowData);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
