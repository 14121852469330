import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";

import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { ErrorReportingService } from "@shared/services";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly errorReportingService = inject(ErrorReportingService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          return event;
        }),
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          this.errorReportingService.captureException(err);
          // switch (err.status) {
          //     case 0:
          //     case 503:
          //         this.notificationService.showError(
          //             "iov42 platform is not reachable at the moment. Please try again later",
          //         );
          //         // this.authenticationService.setisApiAvailable(false);
          //         // this.authenticationService.logout();
          //         break;
          //     case 403:
          //         this.notificationService.showError($localize`Your user profile has no permissions for this`);
          //         return throwError(() => "Forbidden");
          //     case 500:
          //         this.notificationService.showError(null);
          //         break;
          // }

          return throwError(() => err);
        }),
      );
  }
}
