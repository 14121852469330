<app-dropdown-button
  (clickLabel)="onClickLabel()"
  [disabledMainButton]="disabledMainButton"
  [labelIcon]="labelIcon"
  [label]="label"
>
  <app-dropdown-menu>
    @if (isShareEnabled) {
      <app-dropdown-menu-item (pressed)="share.emit()">
        <mat-icon>share</mat-icon>
        <ng-container i18n>Share</ng-container>
      </app-dropdown-menu-item>
    }
    @if (isDuplicateEnabled) {
      <app-dropdown-menu-item (pressed)="onDuplicate()">
        <mat-icon>file_copy</mat-icon>
        <ng-container i18n>Duplicate</ng-container>

        <mat-icon class="duplicate-icon" [tp]="translations.duplicateTp">help</mat-icon>
      </app-dropdown-menu-item>
    }
    @if (isArchiveEnabled) {
      <app-dropdown-menu-item (pressed)="onToggleArchiveStatus()">
        <mat-icon>
          {{ element?.recordState === recordStateEnum.ARCHIVED ? "inventory" : "archive" }}
        </mat-icon>
        {{ element?.recordState === recordStateEnum.ARCHIVED ? "Unarchive" : "Archive" }}
      </app-dropdown-menu-item>
    }
    @if (isDeleteEnabled) {
      <app-dropdown-menu-item (pressed)="onDelete()">
        <mat-icon>delete</mat-icon>
        <ng-container i18n>Delete</ng-container>
      </app-dropdown-menu-item>
    }
    @if (canSend()) {
      <app-dropdown-menu-item (pressed)="sendHandler()">
        <mat-icon>send</mat-icon>
        <ng-container i18n>Send</ng-container>
      </app-dropdown-menu-item>
    }
    <ng-content></ng-content>
  </app-dropdown-menu>
</app-dropdown-button>
