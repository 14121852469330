import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum, RouteEnum } from "src/app/shared/enums";

import { TextConstants } from "@shared/constants";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  templateUrl: "./materials.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsComponent {
  public readonly routingEnum = RouteEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: TextConstants.MATERIALS,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  public readonly isPreviousHistoryAdmin =
    this.routerService.getCurrentHistory() === `/${RouteEnum.ADMIN}`;

  constructor(
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.routerService.navigate(this.routerService.getMaterialLink());
  };
}
