import { QueriesUtils } from "@shared/queries";

import { getCountriesProperties } from "../constants";
import { CountryFieldsToInclude } from "../models";

/**
 * Generates a GraphQL query to load countries based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which locations are queried.
 * @param {string[]} ids - (Required) A list of countries ids
 * @param {number} first - The number of locations to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of locations to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {CountryFieldsToInclude[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading locations.
 */

export const getCountriesByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: CountryFieldsToInclude[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
    loadCountriesByIds(   
      orgId: "${orgId}",
      ids: ${QueriesUtils.getParsedIds(ids)},
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
  ) {
    ${getCountriesProperties(include)}      
    }
  }`;
};
