import { ChangeDetectionStrategy, Component, signal, OnInit } from "@angular/core";

import { TextConstants } from "@shared/constants";
import {
  ExtensionAdminTypeEnum,
  ExtensionProviderEnum,
  FeatureFlagEnum,
  RouteEnum,
} from "@shared/enums";
import { IAvailableExtension, IExtension, ISetting } from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  ExtensionsService,
  FeatureFlagService,
} from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./settings-extensions.component.html",
  styleUrls: ["./settings-extensions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsExtensionsComponent implements OnInit {
  public isLoading = signal(true);

  public readonly routingEnum = RouteEnum;

  public settings: ISetting[] = [];

  public readonly translations: any = {
    title: $localize`Extensions`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private readonly isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();

  private readonly isEudrReportEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.EUDR_REPORT,
  );

  private availableExtensions: IAvailableExtension[] = [];

  constructor(
    private extensionsService: ExtensionsService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.loadAvailableExtensions();
  }

  public loadAvailableExtensions = async (): Promise<void> => {
    try {
      this.availableExtensions = await this.extensionsService.getAllAvailableForRegularUser(
        this.activeOrganisationId,
      );

      if (!this.isEudrReportEnabled) {
        const index = this.availableExtensions.findIndex(
          (a) => a.name === ExtensionProviderEnum.EUDR,
        );

        if (index !== -1) {
          this.availableExtensions.splice(index, 1);
        }
      }

      const isOrbifyAvailable = this.availableExtensions.some(
        (a) => a.name === ExtensionProviderEnum.ORBIFY,
      );
      const isEudrAvailable = this.availableExtensions.some(
        (a) => a.name === ExtensionProviderEnum.EUDR,
      );

      if (isOrbifyAvailable) {
        this.settings.push({
          id: ExtensionProviderEnum.ORBIFY,
          title: "Orbify",
          description: $localize`Deforestation data from Orbify`,
          svgIcon: "orbify",
        });
      }

      if (isEudrAvailable) {
        this.settings.push({
          id: ExtensionProviderEnum.EUDR,
          title: "EUDR",
          description: $localize`Connection to EU IS for EUDR statements`,
          icon: "summarize",
        });
      }

      if (this.settings.length) {
        await this.reloadExtensionsStatus();
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public reloadExtensionsStatus = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const enabledExtensions = await this.extensionsService.getAllEnabled(
        this.activeOrganisationId,
      );

      for (const availableExtension of this.availableExtensions) {
        const setting = this.settings.find((e) => e.id === availableExtension.name);
        const isChecked = enabledExtensions.some((e) => e === availableExtension.name);
        const isDisabled = availableExtension.adminType === ExtensionAdminTypeEnum.SYSTEM;

        let tooltip: string;

        if (isDisabled) {
          tooltip = `Please contact ${this.isAccountOwnerOrAdmin ? "Interu support" : "your organisation's admin"} to ${isChecked ? "deactivate" : "activate"} this extension`;
        } else {
          tooltip = `${isChecked ? "Deactivate" : "Activate"}`;
        }

        setting.slideOverButton = {
          tooltip,
          isChecked,
          isDisabled,
          onChange: () =>
            this.toggleExtensionStatus({
              providerId: availableExtension.name,
              enable: !isChecked,
            }),
        };
      }

      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private toggleExtensionStatus = async (payload: IExtension): Promise<void> => {
    this.isLoading.set(true);
    try {
      await this.extensionsService.enableExtension(this.activeOrganisationId, payload);
      this.notificationService.showSuccess(
        payload.enable ? $localize`Extension activated` : $localize`Extension deactivated`,
      );
      await this.reloadExtensionsStatus();
    } catch (error) {
      this.notificationService.showError(error);
      this.isLoading.set(false);
    }
  };
}
