import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { RecordStateEnum, RouteEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./documents.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Documents`,
    titleHint: $localize`View and manage all of the documents that your organisation
        has captured related to its supply chain, products, deliveries, etc.`,
  };

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.router.navigate([`/${RouteEnum.DOCUMENTS_UPLOAD}`]);
  };
}
