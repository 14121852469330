import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@components/shared/cards/card-content/card-content.component";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import { ILocation, ILocationExtended, ILocationType } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-location-card-content",
  templateUrl: "./location-card-content.component.html",
  styleUrl: "./location-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationCardContentComponent extends CardContentComponent {
  @Input() location: ILocation | ILocationExtended;

  @Input() maxNumDisplayableLocationTypes: number;

  public readonly POINT_OF_ORIGIN_CHIP = TextConstants.POINT_OF_ORIGIN_CHIP;

  buildTag(label: string): InputSelectOption {
    return { label };
  }

  buildLocationTypeTag = (locationType: ILocationType): InputSelectOption => ({
    label: locationType.type,
    value: locationType.type,
    icon: locationType.pointOfOrigin ? "target" : undefined,
    iconTooltip: locationType.pointOfOrigin ? TextConstants.POINT_OF_ORIGIN_CHIP : undefined,
  });

  get locationTypes(): ILocationType[] {
    if (this.maxNumDisplayableLocationTypes) {
      return this.location.types?.slice(0, this.maxNumDisplayableLocationTypes);
    }

    return this.location.types;
  }

  get moreLocationTypes(): string {
    return this.location.types.slice(4).join(", ");
  }
}
