<div class="container">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0" [expanded]="true" togglePosition="before">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="title">
            <ng-container i18n>ID</ng-container>
            :

            <app-form-link [link]="getProcessLink()" [styledLink]="true" [maintainFontSize]="true">
              <span class="value">{{ process().processId }}</span>
            </app-form-link>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="row location-info">
        <ng-container i18n>Location</ng-container>
        :

        <app-form-link [link]="getLocationLink()" [styledLink]="true" [maintainFontSize]="true">
          <span class="value">
            {{ process().location.name }}
          </span>
        </app-form-link>
        <app-tag-group [tags]="locationTypes()"></app-tag-group>
        <app-flag [country]="process().location.address.country"></app-flag>
        {{ locationCountryName() }}
      </div>
      <div class="row more-gap">
        <div>
          <ng-container i18n>Input date</ng-container>
          :

          <span class="value">{{ process().firstInputDate | appDate }}</span>
        </div>
        <div>
          <ng-container i18n>Output date</ng-container>
          :

          <span class="value">{{ process().lastOutputDate | appDate }}</span>
        </div>
      </div>

      <div class="row no-alignment">
        <div class="column">
          <h4 i18n>Input summary</h4>
          <app-process-in-out-table
            type="input"
            [processId]="process().id"
            [allProducts]="allProducts()"
            [allUnitOfMeasurements]="allUnitOfMeasurements()"
            [isSearchEnabled]="false"
          ></app-process-in-out-table>
        </div>

        <div class="column">
          <h4 i18n>Output summary</h4>
          <app-process-in-out-table
            type="output"
            [processId]="process().id"
            [allProducts]="allProducts()"
            [allUnitOfMeasurements]="allUnitOfMeasurements()"
            [isShowSelectCheckbox]="true"
            [isShowPercentages]="true"
            [isSearchEnabled]="false"
          ></app-process-in-out-table>
        </div>
      </div>

      <div class="row">
        <app-document-types-table
          [documentTypes]="documentTypes"
          [areButtonsEnabled]="false"
          [isRecordStateFilterEnabled]="false"
          [isPaginatorEnabled]="false"
          [isSearchEnabled]="false"
          [columns]="['documentType', 'documentNames']"
        ></app-document-types-table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
