export const TextConstants = {
  POINT_OF_ORIGIN_ADMIN: $localize`Indicates that any location of this type is one where the raw materials originate from`,
  POINT_OF_ORIGIN_CHIP: $localize`This location type is marked as a point of origin, meaning, this is a location where raw materials originate from`,
  SAME_ORG_NAME_ERROR: $localize`Cannot be the same as your main organisation name`,
  SAME_AS_ORG_ADDRESS: $localize`Same as organisation address`,
  DOCUMENT_NO_CONTENT: $localize`This document has no content`,
  DOCUMENT_TYPE_TOOLTIP_HINT: $localize`Determines which type of document this is. E.g. Delivery order, Purchase contract, etc`,
  DOCUMENT_NAME_DUPLICATION_ERROR: $localize`This name is already being used by another document`,
  MAIN_INFORMATION: $localize`Main information`,
  ADDITIONAL_INFORMATION: $localize`Additional information`,
  CERTIFICATES_DOCUMENTS_HINT: $localize`Certificate documents (e.g. scan) can be added in the Documents tab once the main details are saved`,
  SERVICE_UNAVAILABLE: $localize`Service is unavailable. Please try again later`,
  EDIT_ARCHIVED_RECORD_ERROR: $localize`Editing an archived record is not allowed`,
  FILL_REQUIRED_FIELDS: $localize`Please fill in all required fields`,
  SESSION_HAS_EXPIRED: $localize`Session has expired. Please, log in again`,
  FILE_NOT_SUPPORTED_ERROR: $localize`File not supported`,
  MISSING_PARAMETERS: $localize`Missing parameters`,
  EMAIL_OR_PASS_INCORRECT: $localize`Email or password is incorrect`,
  BACK_TO_ADMIN: $localize`Back to Admin`,
  BACK_TO_USER_SETTINGS: $localize`Back to User settings`,
  BACK_TO_INBOX: $localize`Back to Inbox`,
  BACK_TO_SUPPLY_CHAINS: $localize`Back to Supply chains`,
  NAME: $localize`Name`,
  DESCRIPTION: $localize`Description`,
  REMOVE: $localize`Remove`,
  RECORD_DELETED: $localize`Record deleted`,
  COPIED: $localize`Copied`,
  UNSUPPORTED_RESOURCE_TYPE: $localize`Unsupported resource type`,
  SAVE: $localize`Save`,
  EDIT: $localize`Edit`,
  DELETE: $localize`Delete`,
  DOWNLOAD: $localize`Download`,
  ARCHIVE: $localize`Archive`,
  UNARCHIVE: $localize`Unarchive`,
  TYPE: $localize`Type`,
  COUNTRY: $localize`Country`,
  STATUS: $localize`Status`,
  ORGANISATION: $localize`Organisation`,
  TAGS: $localize`Tags`,
  MATERIALS: $localize`Materials`,
  PRODUCT: $localize`Product`,
  CREATION_DATE: $localize`Creation date`,
  EMAIL: $localize`Email`,
  PASSWORD: $localize`Password`,
  ISSUANCE_DATE: $localize`Issuance date`,
  VALID_FROM: $localize`Valid from`,
  VALID_TO: $localize`Valid to`,
  CATEGORY_HINT: $localize`E.g. tree species, alloys, meat, etc.`,
  CATEGORY: $localize`Category`,
  LOCATION_TYPES_TOOLTIP: $localize`Determines the type(s) of the location. E.g. Forest, Sawmill, Exporter, Importer, Agent, etc.`,
  MISSING_DOC_TYPES_TOOLTIP: $localize`One or more documents are missing that are required by a ruleset(s).`,
  ADD_NEW: $localize`Add new`,
  CLEAR: $localize`Clear`,
  CLOSE: $localize`Close`,
  ADD_LOCATION: $localize`Add location`,
  FULL_SCREEN: $localize`Full screen`,
  ZOOM_OUT: $localize`Zoom out`,
  ZOOM_IN: $localize`Zoom in`,
  REMOVE_CONFIRMATION: $localize`Remove confirmation`,
  DELETE_CONFIRMATION: $localize`Delete confirmation`,
  ADD_ALL_CONFIRMATION: $localize`Add all confirmation`,
  UNARCHIVE_CONFIRMATION: $localize`Unarchive confirmation`,
  ARCHIVE_CONFIRMATION: $localize`Archive confirmation`,
  UNARCHIVE_CONFIRMATION_TEXT: $localize`Are you sure that you want to unarchive this record?`,
  ARCHIVE_CONFIRMATION_TEXT: $localize`Are you sure that you want to archive this record?`,
  AREA_HA: $localize`Area (ha)`,
  ARCHIVED: $localize`Archived`,
  UNARCHIVED: $localize`Unarchived`,
  RECORD_DETAILS: $localize`Record details`,
  TEMPLATE_DETAILS: $localize`Template details`,
  CERTIFICATE_DETAILS: $localize`Certificate details`,
  PRODUCT_DETAILS: $localize`Product details`,
  PROCESS_DETAILS: $localize`Process details`,
  LOCATION_DETAILS: $localize`Location details`,
  DELIVERY_DETAILS: $localize`Delivery details`,
  DOCUMENT_DETAILS: $localize`Document details`,
  ITEM_DETAILS: $localize`Item details`,
  ORGANISATION_DETAILS: $localize`Organisation details`,
  MATERIAL_DETAILS: $localize`Material details`,
  COUNTRY_DETAILS: $localize`Country details`,
  ATTACHED_TO: $localize`Attached to`,
  UNITS_OF_MEASUREMENT: $localize`Units of measurement`,
  LOCATION: $localize`Location`,
  FIRTS_INPUT_DATE: $localize`First input date`,
  LAST_OUTPUT_DATE: $localize`Last output date`,
  INPUT_SUMMARY: $localize`Input summary`,
  OUTPUT_SUMMARY: $localize`Output summary`,
  WEBSITE: $localize`Website`,
  ADDRESS: $localize`Address`,
  ZIP_POSTCODE: $localize`ZIP / Postcode`,
  STATE_REGION: $localize`State / Region`,
  ACCEPT: $localize`Accept`,
  DISCARD_CHANGES_GO_BACK: $localize`Discard changes & go back`,
  DISCARD_CHANGES_LEAVE: $localize`Discard changes & leave`,
  CANCEL: $localize`Cancel`,
  CONTINUE: $localize`Continue`,
  EXIT_CONFIRMATION: $localize`Exit confirmation.`,
  DEACTIVATE: $localize`Deactivate`,
  ACTIVATE: $localize`Activate`,
  LOCATION_TYPES: $localize`Location types`,
  GEOLOCATION: $localize`Geolocation`,
  OPTIONAL: $localize`Optional`,
  NOTE: $localize`Note`,
  RANGE_FROM_TO: $localize`Range (from/to)`,
  SELECTED_QTY: $localize`Selected q-ty`,
  INITIAL_QTY: $localize`Initial q-ty`,
  VOLUME_M3: $localize`Volume (m3)`,
  NET_MASS_KG: $localize`Net mass (kg)`,
  PRODUCER_COUNTRY: $localize`Producer country`,
  PRODUCER_NAME: $localize`Producer name`,
  CERTIFICATES: $localize`Certificates`,
  DOCUMENTS: $localize`Documents`,
  SHARES: $localize`Shares`,
  COMMENTS: $localize`Comments`,
  RISK_ASSESSMENT_REPORTS: $localize`Risk assessments`,
  ITEMS: $localize`Items`,
  DELIVERIES: $localize`Deliveries`,
  PROCESSES: $localize`Processes`,
  LOCATIONS: $localize`Locations`,
  SUPPLY_CHAIN: $localize`Supply chain`,
  GPS_DATA: $localize`GPS data`,
  INPUTS: $localize`Inputs`,
  OUTPUTS: $localize`Outputs`,
  INDICATORS: $localize`Indicators`,
  RELATED_PRODUCTS: $localize`Related products`,
  ANALYSES: $localize`Analyses`,
  LINKED_LOCATIONS: $localize`Linked locations`,
};
