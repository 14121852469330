@if (!isLoading()) {
  <form [formGroup]="formGroup">
    <app-input-select
      [showClear]="false"
      [options]="languageOptions"
      [formControlName]="formControlName()"
      [label]="label()"
      [prefixTemplate]="flag"
      [class]="class()"
    ></app-input-select>

    <ng-template #flag let-option>
      <app-flag [country]="option.flag"></app-flag>
    </ng-template>
  </form>
}
