<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="settings-container flex-wrap">
    @for (setting of settings; track $index) {
      <app-settings-card [setting]="setting"></app-settings-card>
    }
  </div>
}
