<div class="tags-container flex-wrap">
  @if (tags()?.length) {
    @for (tag of tags(); track tag.title) {
      <div
        class="app-tag"
        [ngClass]="tag.color"
        [tp]="tag.title.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? tag.title : ''"
        [tpIsLazy]="true"
      >
        {{ tag.title }}
      </div>
    }
  } @else {
    -
  }
</div>
