const DEFAULT_POLYGON_OPTIONS: any = {
  //google.maps.PolygonOptions
  strokeColor: "#38B5D0",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#00B2D9",
  fillOpacity: 0.25,
};

const DEFAULT_MAP_OPTIONS = {
  zoom: 12,
  mapTypeControl: true,
  streetViewControl: false,
  fullscreenControl: false,
  zoomControl: false,
  rotateControl: false,
  mapTypeControlOptions: {
    position: 7, //google.maps.ControlPosition.BOTTOM_LEFT
    mapTypeIds: ["satellite", "roadmap"],
    style: 2, //google.maps.MapTypeControlStyle.HORIZONTAL_BAR
  },
};

const COORDINATE_FORMAT_ERROR_MESSAGE = $localize`Some coordinates are not displayed due to errors in their format`;

export const MapConstants = {
  DEFAULT_POLYGON_OPTIONS,
  DEFAULT_MAP_OPTIONS,
  COORDINATE_FORMAT_ERROR_MESSAGE,
};
