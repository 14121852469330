/// <reference types="@angular/localize" />

import { LOCALE_ID } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
// import { bootstrapApplication, provideClientHydration } from "@angular/platform-browser";

import * as Sentry from "@sentry/angular";

import { APP_CONFIG } from "@shared/tokens";
import { LocalizationUtils } from "@shared/utils";

import { AppModule } from "./app/app.module";
import { IConfig } from "./app/shared/interfaces";

fetch("./assets/config/config.json")
  .then((res) => res.json())
  .then((config: IConfig) => {
    if (config.sentryDsn) {
      Sentry.init({
        beforeSend: (event, hint) => {
          const error = hint.originalException as Error;

          if (error && error.message) {
            const shouldExclude =
              /ExpressionChangedAfterItHasBeenCheckedError|View has already been destroyed/i.test(
                error.message,
              );

            if (shouldExclude) {
              return null;
            }
          }

          return event;
        },
        dsn: config.sentryDsn,
        environment: config.name,
        integrations: [
          // Registers and configures the Tracing integration,
          // which automatically instruments your application to monitor its
          // performance, including custom Angular routing instrumentation
          Sentry.browserTracingIntegration(),
          // Registers the Replay integration,
          // which automatically captures Session Replays
          Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: config.production ? 1.0 : 0,

        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [
          "https://app.dev.interu.io",
          "https://app.staging.interu.io",
          "https://app.uat.interu.io",
          "https://app.interu.io",
        ],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    return platformBrowserDynamic([
      {
        provide: APP_CONFIG,
        useValue: config,
        multi: false,
      },
      { provide: LOCALE_ID, useValue: LocalizationUtils.getLocaleForAngularConfig() },
    ]).bootstrapModule(AppModule);
  })
  .catch((err) => console.error(err));
//todo
// bootstrapApplication(RootCmp, {
//     providers: [provideClientHydration()],
// });
