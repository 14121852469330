import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { IOrganisationMember, ISelectOption } from "src/app/shared/interfaces";
import { AuthenticationService, OrganisationsService } from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./edit-user-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUserDialogComponent {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(false);

  public readonly userRolesOptions: ISelectOption[] = [];

  public readonly translations: any = {
    firstNameLabel: $localize`First name`,
    lastNameLabel: $localize`Last name`,
    emailAddressLabel: TextConstants.EMAIL,
    roleLabel: $localize`Role`,
  };

  constructor(
    public dialogRef: MatDialogRef<EditUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { user: IOrganisationMember },
    private notificationService: NotificationService,
    private organisationsService: OrganisationsService,
    private authenticationService: AuthenticationService,
  ) {
    this.formGroup = new UntypedFormGroup({
      firstName: new UntypedFormControl(
        {
          value: this.data.user.firstName,
          disabled: true,
        },
        [Validators.required],
      ),
      lastName: new UntypedFormControl({ value: this.data.user.lastName, disabled: true }, [
        Validators.required,
      ]),
      emailAddress: new UntypedFormControl({ value: this.data.user.emailAddress, disabled: true }, [
        Validators.required,
      ]),
      role: new UntypedFormControl(this.data.user.roles[0], [Validators.required]),
    });

    this.userRolesOptions = CommonUtils.getUserRolesOptions(
      this.authenticationService.getActiveOrganisation().roles,
    );
  }

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid;

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    await this.organisationsService
      .editMember(this.data.user.id, this.formGroup.controls["role"].value)
      .then(() => {
        this.notificationService.showSuccess($localize`User modified`);
        this.onClose(true);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
