import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-reports-eudr-production-place",
  templateUrl: "./reports-eudr-production-place.component.html",
  styleUrls: ["./reports-eudr-production-place.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrProductionPlaceComponent {
  @Input() index: number;

  @Input() formGroup: FormGroup;

  public readonly translations: any = {
    descriptionLabel: $localize`Production Place Description`,
    areaLabel: TextConstants.AREA_HA,
    typeLabel: TextConstants.TYPE,
    noGpsTooltip: $localize`This location has no GPS data.`,
  };
}
