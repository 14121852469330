import { HttpClient } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import {
  FormArray,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { firstValueFrom, pairwise, startWith, Subscription } from "rxjs";

import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { ICustomFieldResponse } from "@components/shared/items-supply-chain/item-supply-chain.interface";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CommonConstants, TextConstants } from "@shared/constants";
import { FeatureFlagEnum, RouteEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomField,
  IDelivery,
  IItem,
  ILocationDetails,
  IMaterialExtended,
  IOrganisation,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import {
  NotificationService,
  AuthenticationService,
  CommonService,
  ConnectionsService,
  CustomFieldsService,
  DeliveriesService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  MaterialsService,
  OrganisationsService,
  ProductsService,
} from "@shared/services";
import { EudrDdsService } from "@shared/services/api/eudr-dds.service";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { EudrItem } from "./eudr-item";
import { EudrItemGroup } from "./eudr-item-group";
import { ReportsEudrModel as Model, ReportsEudrModel } from "./reports-eudr.model";

@Component({
  standalone: false,
  templateUrl: "./reports-eudr.component.html",
  styleUrls: ["./reports-eudr.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ReportsEudrComponent implements OnInit {
  public delivery: IDelivery;

  public items: IItem[] = [];

  public products: IProductExtended[] = [];

  public materials: IMaterialExtended[] = [];

  public isLoading = signal(true);

  public isSaving = signal(false);

  public isExportActivity = signal(false);

  public totalAreaError: boolean = false;

  public activeOrganisationId: string;

  public eudrItemGroups: EudrItemGroup[] = [];

  public countryOptions: ISelectOption[] = [];

  public productsWithErrors: IProductExtended[] = [];

  public locationWithoutOrgAttachment: ILocationDetails;

  public activityEnum: typeof Model.ActivityEnum = Model.ActivityEnum;

  public formGroup: UntypedFormGroup;

  public readonly constants = Model;

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  private toLocation: ILocationDetails;

  private allCustomFields: ICustomField[] = [];

  private allUnitOfMeasurements: IBaseUnit[] = [];

  private subscriptions = new Subscription();

  public readonly translations: any = {
    referenceNumberPh: $localize`Reference number`,
    referenceNumberLabel: $localize`1. Reference number`,
    messagePh: $localize`Message`,
    activityLabel: $localize`2. Activity`,
    domesticLabel: $localize`Domestic`,
    importLabel: $localize`Import`,
    exportLabel: $localize`Export`,
    operatorLabel: $localize`3. Operator / Trader name and address`,
    placeOfActivityLabel: $localize`4. Place of activity`,
    isoCodeLabel: $localize`ISO Code`,
    countryOfEntryLabel: $localize`Country of entry`,
    countryOfExitLabel: $localize`Country of exit`,
    countryOfActivityLabel: $localize`Country of activity`,
    totalsLabel: $localize`Totals`,
    totalNetMassLabel: TextConstants.NET_MASS_KG,
    totalVolumeLabel: TextConstants.VOLUME_M3,
    totalAreaLabel: $localize`Total area (ha)`,
    totalAreaTooltip: $localize`Some of the Area (ha) fields may contain non-number values. Please correct them to see the total sum.`,
    communicationLabel: $localize`5. Communication for Competent Authority`,
    commodityOrProductsLabel: $localize`6. Commodity(-ies) or Product(-s)`,
  };

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private deliveriesService: DeliveriesService,
    private locationsService: LocationsService,
    private connectionsService: ConnectionsService,
    private organisationsService: OrganisationsService,
    private itemsService: ItemsService,
    private productsService: ProductsService,
    private materialsService: MaterialsService,
    private customFieldsService: CustomFieldsService,
    private commonService: CommonService,
    private itemSupplyChainMapperService: ItemSupplyChainMapperService,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private slideOverlayPageService: SlideOverlayPageService,
    private featureFlagService: FeatureFlagService,
    private httpClient: HttpClient,
    private eudrDdsService: EudrDdsService,
  ) {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );

    this.subscriptions.add(
      this.slideOverlayPageService.trigger$.subscribe(async (params) => {
        if (params?.hasSaved) {
          this.isLoading.set(true);
          await this.setupForm();
          this.isLoading.set(false);
        }
      }),
    );

    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  public async ngOnInit(): Promise<void> {
    const deliveryId = this.route.snapshot.params["id"];

    if (!deliveryId) {
      this.handleDeliveryNotFoundError();

      return;
    }

    try {
      this.delivery = await this.deliveriesService.get(deliveryId);
    } catch {
      this.handleDeliveryNotFoundError();

      return;
    }

    await this.setupForm();

    this.isLoading.set(false);
  }

  private handleDeliveryNotFoundError(): void {
    this.notificationService.showError($localize`Delivery not found`);
    this.routerService.navigate(RouteEnum.DELIVERIES);
  }

  public get title(): string {
    return `Delivery ${this.delivery?.deliveryId ? `${this.delivery.deliveryId} ` : ""}EUDR due diligence statement`;
  }

  public onActivityChange(activity: Model.ActivityEnum): void {
    this.formGroup.controls["activity"].setValue(activity);

    this.setActivityDependantValues();
  }

  private setActivityDependantValues(): void {
    const { toLocation, formGroup } = this;

    if (!toLocation || formGroup.get("activity").value !== Model.ActivityEnum.IMPORT) {
      return;
    }

    const values = {
      activityCountry: formGroup.get("activityCountry").value ?? toLocation.address.country,
      entryCountry: toLocation.address.country,
      activityCountryIsoCode:
        formGroup.get("activityCountryIsoCode").value ?? toLocation.address.country,
      entryCountryIsoCode: toLocation.address.country,
    };

    Object.keys(values).forEach((key) => formGroup.get(key).setValue(values[key]));
  }

  private async setupForm(): Promise<void> {
    this.formGroup = new FormGroup({
      referenceNumber: new UntypedFormControl(this.delivery.deliveryId, [
        CustomValidators.required,
      ]),
      activity: new UntypedFormControl(Model.ActivityEnum.IMPORT, [CustomValidators.required]),
      activityCountry: new UntypedFormControl(null, [CustomValidators.required]),
      activityCountryIsoCode: new UntypedFormControl({ value: null, disabled: true }),
      entryCountry: new UntypedFormControl(null, [CustomValidators.required]),
      entryCountryIsoCode: new UntypedFormControl({ value: null, disabled: true }),
      competentAuthorityMessage: new UntypedFormControl(),
      totalNetMass: new UntypedFormControl({ value: null, disabled: true }, [
        CustomValidators.required,
      ]),
      totalVolume: new UntypedFormControl({ value: null, disabled: true }, [
        CustomValidators.required,
      ]),
      totalArea: new UntypedFormControl({ value: null, disabled: true }, [
        CustomValidators.required,
      ]),
      commodities: new UntypedFormArray([]),
    });

    const [allCustomFields, toLocation] = await Promise.all([
      this.customFieldsService.getAll(),
      this.locationsService.get(CommonUtils.getUriId(this.delivery.to)),
    ]);

    this.toLocation = toLocation;
    this.allCustomFields = allCustomFields;

    this.setActivityDependantValues();

    this.subscribeToCountryChanges("activityCountry", "activityCountryIsoCode");
    this.subscribeToCountryChanges("entryCountry", "entryCountryIsoCode");

    this.formGroup.get("activity").valueChanges.subscribe((activity: Model.ActivityEnum) => {
      this.setIsExportActivity(activity);
    });

    this.setIsExportActivity(this.formGroup.get("activity").value as Model.ActivityEnum);

    await this.handleItems();
  }

  private setIsExportActivity(activity: Model.ActivityEnum): void {
    this.isExportActivity.set(activity === Model.ActivityEnum.EXPORT);
  }

  private subscribeToCountryChanges = (
    formControlName: string,
    isoCodeControlName: string,
  ): void => {
    this.formGroup.get(formControlName).valueChanges.subscribe((countryOption: ISelectOption) => {
      this.formGroup.get(isoCodeControlName).setValue(countryOption?.value ?? null);
    });
  };

  public async onClickProduct(product: IProductExtended): Promise<void> {
    this.routerService.navigate(this.routerService.getProductLink(product.id));
  }

  public get commodityFormControls(): FormGroup[] {
    return (this.formGroup.get("commodities") as UntypedFormArray).controls as FormGroup[];
  }

  private getProductsWithErrors(): IProductExtended[] {
    if (
      this.requiredCustomFieldsNeedingValues.length !==
      Model.requiredCustomFieldsNeedingValuesLabels.length
    ) {
      return this.products;
    }

    const products = [
      ...this.productsMissingRequiredCustomFields(),
      ...this.groupProductsByCustomFields(),
    ];

    return Array.from(new Map(products.map((product) => [product.id, product])).values());
  }

  private productsMissingRequiredCustomFields(): IProductExtended[] {
    const customFieldWhichNeedValueLabels: string[] = this.requiredCustomFieldsNeedingValues.map(
      (customField) => customField.label,
    );

    return this.products.filter((product) => {
      const productCustomFieldLabels: string[] = (product.customFields || []).map(
        (customField) => customField.definition.label,
      );

      const allRequiredFieldsPresent = customFieldWhichNeedValueLabels.every((label) =>
        productCustomFieldLabels.includes(label),
      );

      return !allRequiredFieldsPresent;
    });
  }

  private get descriptionCustomField(): ICustomField {
    return this.allCustomFields.find(
      (customField) => customField.label === Model.ReportCustomFieldEnum.DESCRIPTION,
    );
  }

  public get canSave(): boolean {
    return (
      !this.isSaving() &&
      !this.productsWithErrors.length &&
      !this.locationWithoutOrgAttachment &&
      (this.formGroup.get("commodities") as UntypedFormArray).length > 0
    );
  }

  private groupProductsByCustomFields(): IProductExtended[] {
    const products = this.products.map((product) => {
      const productCustomFields = product.customFields || [];

      let description: ICustomFieldResponse;

      if (this.descriptionCustomField) {
        description = productCustomFields.find(
          (customField) => customField.definition.label === this.descriptionCustomField.label,
        );
      }

      const customFieldValuesToGroup: string[] = this.requiredCustomFieldsNeedingValues.map(
        (requiredField) => {
          const customField = productCustomFields.find(
            (customField) => customField.definition.label === requiredField.label,
          );

          return customField?.value;
        },
      );

      return {
        product,
        descriptionCustomFieldValue: description?.value,
        customFieldValuesToGroup,
      };
    });

    const groups: { [key: string]: Model.IProductWithDescription[] } = {};

    products.forEach((element) => {
      const key = JSON.stringify(element.customFieldValuesToGroup);

      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(element);
    });

    const result: Model.IProductWithDescription[] = [];

    Object.values(groups).forEach((group) => {
      const descriptions = new Set(group.map((element) => element.descriptionCustomFieldValue));

      if (descriptions.size > 1) {
        result.push(...group);
      }
    });

    return result.map((element) => element.product);
  }

  private async handleItems(): Promise<void> {
    const itemPromises = this.delivery.items.map((deliveryItem) => {
      const itemId = CommonUtils.getUriId(deliveryItem.item);

      return this.itemsService.get(itemId);
    });

    const items = await Promise.all(itemPromises);

    const productIds: string[] = Array.from(
      new Set(items.map((item) => CommonUtils.getUriId(item.product))),
    );

    const productsPromise = this.productsService.getByIdsGraphQL(
      productIds,
      CommonConstants.MAX_API_GET_ITEMS_SIZE,
      ["CUSTOM_FIELDS"],
    );

    let materialsPromise: Promise<IMaterialExtended[]> | null = null;

    if (this.isOldMaterialsEnabled) {
      const materialIds: string[] = Array.from(
        new Set(items.map((item) => item.materials).flat()),
      ).map((materialUri) => CommonUtils.getUriId(materialUri));

      materialsPromise = this.materialsService.getByIdsGraphQL(
        materialIds,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["CUSTOM_FIELDS"],
      );
    }

    const [products, materials] = await Promise.all([
      productsPromise,
      materialsPromise ?? Promise.resolve([]),
    ]);

    this.products = products;

    if (this.isOldMaterialsEnabled) {
      this.materials = materials;
    }

    this.productsWithErrors = this.getProductsWithErrors();

    if (this.productsWithErrors.length) {
      return;
    }

    const [hsCodes, organisations] = await Promise.all([
      firstValueFrom(this.httpClient.get<Model.IHsCode[]>(Model.hsCodesJsonPath)),
      this.getAllOrganisations(),
    ]);

    const groupedItems = this.groupItems(items);

    this.eudrItemGroups = EudrItemGroup.buildGroups(
      groupedItems,
      this.itemSupplyChainMapperService,
    );

    for (const itemGroup of this.eudrItemGroups) {
      const fields = itemGroup.customRequiredFields;

      let materialForms: UntypedFormGroup[] = [];

      if (this.isOldMaterialsEnabled) {
        materialForms = await itemGroup.buildMaterialsInfoForms(this.materials);
      }

      const hsCode = hsCodes.find((hsCode) => {
        return String(hsCode.hscode) === String(fields[Model.ReportCustomFieldEnum.HS_CODE]);
      });

      const eudrProducers = (await itemGroup.buildEudrProducers(organisations)).sort((a, b) => {
        return a.organisation.name.localeCompare(b.organisation.name);
      });

      const producerForms = eudrProducers.map((producer) => producer.buildForm());
      const hsCodeTitle = hsCode ? `${hsCode.hscode} - ${hsCode.description}` : "UNKNOWN";

      const commodityForm = new UntypedFormGroup({
        hsCodeTitle: new UntypedFormControl(hsCodeTitle),
        hsCode: new UntypedFormControl(hsCode?.hscode || ""),
        description: new UntypedFormControl(fields[Model.ReportCustomFieldEnum.DESCRIPTION], [
          CustomValidators.required,
        ]),
        netMass: new UntypedFormControl(itemGroup.netMass, [
          CustomValidators.required,
          CustomValidators.greaterThan(0, true),
        ]),
        volume: new UntypedFormControl(itemGroup.volume),
        displayUnitWarning: new UntypedFormControl(itemGroup.displayUnitWarning),
        materialsInfo: new UntypedFormArray(materialForms),
        producersInfo: new UntypedFormArray(producerForms),
      });

      (this.formGroup.get("commodities") as UntypedFormArray).push(commodityForm);
    }

    this.calculateTotals(true);
  }

  private calculateTotals(subscribeToValueChanges: boolean = false): void {
    this.totalAreaError = false;

    let totalNetMass = 0;
    let totalVolume = 0;
    let totalArea = 0;

    const { controls } = this.formGroup;

    for (const commodityFormControl of (controls["commodities"] as UntypedFormArray)
      .controls as UntypedFormGroup[]) {
      totalNetMass += Number(commodityFormControl.controls["netMass"].value) || 0;
      totalVolume += Number(commodityFormControl.controls["volume"].value) || 0;
      totalArea += this.calculateTotalAreaForCommodity(
        commodityFormControl,
        subscribeToValueChanges,
      );

      if (subscribeToValueChanges) {
        this.subscribeToValueChanges(commodityFormControl, "netMass");
        this.subscribeToValueChanges(commodityFormControl, "volume");
      }
    }

    controls["totalNetMass"].setValue(totalNetMass);
    controls["totalVolume"].setValue(totalVolume);

    if (this.totalAreaError) {
      controls["totalArea"].setValue("n/a");
    } else {
      controls["totalArea"].setValue(totalArea.toFixed(2));
    }
  }

  private subscribeToValueChanges(formGroup: UntypedFormGroup, property: string): void {
    formGroup.controls[property].valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(() => this.calculateTotals());
  }

  private calculateTotalAreaForCommodity(
    commodityFormControl: UntypedFormGroup,
    subscribeToValueChanges: boolean = false,
  ): number {
    const producersInfo = commodityFormControl.controls["producersInfo"] as UntypedFormArray;

    let totalArea: number = 0;

    producersInfo.controls.forEach((producerInfo: UntypedFormGroup) => {
      return (producerInfo.controls["productionPlaces"] as FormArray).controls.forEach(
        (productionPlace: UntypedFormGroup) => {
          const productionPlaceArea = productionPlace.controls["area"].value;

          if (CommonUtils.isNumber(productionPlaceArea)) {
            totalArea += Number(productionPlaceArea) || 0;
          } else {
            this.totalAreaError = true;
          }

          if (subscribeToValueChanges) {
            this.subscribeToValueChanges(productionPlace, "area");
          }
        },
      );
    });

    return totalArea;
  }

  private async getAllOrganisations(): Promise<IOrganisation[]> {
    const loggedInUserOrganisationPromise = this.organisationsService.get(
      this.activeOrganisationId,
    );
    const otherOrganisationsPromise = this.connectionsService.getAll();

    const [loggedInUserOrganisation, otherOrganisations] = await Promise.all([
      loggedInUserOrganisationPromise,
      otherOrganisationsPromise,
    ]);

    return [loggedInUserOrganisation, ...otherOrganisations];
  }

  private get requiredCustomFieldsNeedingValues(): ICustomField[] {
    return this.allCustomFields.filter(({ label }) => {
      return (Model.requiredCustomFieldsNeedingValuesLabels as string[]).includes(label);
    });
  }

  private groupItems(items: IItem[]): Map<Model.requiredCustomFieldValue, EudrItem[]> {
    const eudrItems = items.map((item) => {
      const productId = CommonUtils.getUriId(item.product);
      const product = this.products.find(
        (product) => CommonUtils.getUriId(product.id) === productId,
      );

      const baseUnit = this.allUnitOfMeasurements.find(
        (unit) => unit.id === product.unitOfMeasurement.id,
      );

      const deliveryItem = this.delivery.items.find((deliveryItem) => {
        return CommonUtils.getUriId(deliveryItem.item) === item.id;
      });

      return new EudrItem(item, product, baseUnit, deliveryItem.quantity);
    });

    return this.groupEudrItemsByCustomFieldValues(eudrItems);
  }

  private stringifyCustomFieldValues(customFieldValues: Model.RequiredCustomFieldValues): string {
    const sortedValues: string[] = [];

    for (const label of Model.reportCustomFieldLabels) {
      sortedValues.push(customFieldValues[label]);
    }

    return JSON.stringify(sortedValues);
  }

  private groupEudrItemsByCustomFieldValues(
    items: EudrItem[],
  ): Map<Model.requiredCustomFieldValue, EudrItem[]> {
    const groups = new Map<Model.requiredCustomFieldValue, EudrItem[]>();

    for (const item of items) {
      const key = this.stringifyCustomFieldValues(item.customFieldValues);

      if (!groups.has(key)) {
        groups.set(key, []);
      }

      groups.get(key)!.push(item);
    }

    return groups;
  }

  public onClickLocation(location: ILocationDetails): void {
    this.routerService.navigate(this.routerService.getLocationLink(location.id));
  }

  private getProductionPlace(productionPlace: FormGroup): EudrDds.IProductionPlace {
    let geoData: string;

    const geoDataObject = productionPlace.controls["geoData"].value;

    if (geoDataObject) {
      geoData = JSON.stringify(geoDataObject);
    }

    return {
      name: productionPlace.controls["description"].value,
      geoData,
      areaInHa: Number(productionPlace.controls["area"].value || ""),
    };
  }

  private getProducerPayload(producer: FormGroup): EudrDds.IProducer {
    const productionPlaces = (producer.controls["productionPlaces"] as FormArray).controls
      .map((productionPlace) => {
        return this.getProductionPlace(productionPlace as FormGroup);
      })
      .slice(0, 10_000);

    const countryName = (producer.controls["producerCountry"].value as string).substring(0, 250);

    const countryIsoCode = this.countryOptions.find((country) => {
      return country.label === countryName;
    })?.value as string;

    return {
      country: countryIsoCode,
      name: producer.controls["producerName"].value,
      productionPlaces,
    };
  }

  private getSpeciesInfo(materialInfo: FormGroup): EudrDds.ISpeciesInfo {
    return {
      commonName: ((materialInfo.controls["commonName"].value as string) || "").substring(0, 50),
      scientificName: ((materialInfo.controls["scientificName"].value as string) || "").substring(
        0,
        50,
      ),
    };
  }

  private getCommodityPayload(commodity): EudrDds.ICommodity {
    const producers = (commodity.controls["producersInfo"] as FormArray).controls
      .map((producer) => {
        return this.getProducerPayload(producer as FormGroup);
      })
      .slice(0, 50);

    const descriptors: EudrDds.IDescriptor = {
      descriptionOfGoods: ((commodity.controls["description"].value as string) || "").substring(
        0,
        250,
      ),
      goodsMeasure: {
        volume: commodity.controls["volume"].value,
        netWeight: commodity.controls["netMass"].value,
      },
    };

    const speciesInfo = (commodity.controls["materialsInfo"] as FormArray).controls
      .map((materialInfo) => {
        return this.getSpeciesInfo(materialInfo as FormGroup);
      })
      .slice(0, 50);

    return {
      hsHeading: `${commodity.controls["hsCode"].value}`,
      producers,
      speciesInfo,
      descriptors,
    };
  }

  public async onSave(): Promise<void> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);

      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isSaving.set(true);

    const { controls } = this.formGroup;

    const commodities = (controls["commodities"] as FormArray).controls;

    const commoditiesPayload = commodities.map((commodity) => this.getCommodityPayload(commodity));

    const borderCrossCountry = this.isExportActivity()
      ? controls["activityCountryIsoCode"].value
      : controls["entryCountryIsoCode"].value;

    const payload: EudrDds.IPayload = {
      deliveryUri: `/organisations/${this.activeOrganisationId}/deliveries/${this.delivery.id}`,
      operatorType: "OPERATOR",
      statement: {
        internalReferenceNumber: controls["referenceNumber"].value,
        activityType: controls["activity"].value,
        countryOfActivity: controls["activityCountryIsoCode"].value,
        borderCrossCountry,
        comment: (controls["competentAuthorityMessage"].value as string)?.slice(0, 5_000),
        commodities: commoditiesPayload,
        geoLocationConfidential: false,
        associatedStatements: [],
      },
    };

    try {
      const statement = await this.eudrDdsService.createOrUpdate(payload);

      this.notificationService.showSuccess(ReportsEudrModel.ddsSavedSuccessMessage);

      this.routerService.navigate(`${RouteEnum.EUDR_DDS}/${statement.id}`);
    } catch (error) {
      this.notificationService.showError(error);
      this.isSaving.set(false);
    }
  }
}
