import { inject, Inject, Injectable, Optional } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";

import moment from "moment";

import { LocalizationService } from "@shared/services";

@Injectable()
export class AppDateAdapter extends MomentDateAdapter {
  private readonly localizationService = inject(LocalizationService);

  private readonly localeData = moment.localeData(this.localizationService.getLanguage());

  constructor(@Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string) {
    super(dateLocale);
    console.log(`App date adapter language: ${this.localizationService.getLanguage()}`);
  }

  override getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    if (style === "long") {
      return this.localeData.weekdays();
    }

    if (style === "short") {
      return this.localeData.weekdaysShort();
    }

    return this.localeData.weekdaysMin();
  }

  override getMonthNames(style: "long" | "short" | "narrow"): string[] {
    if (style === "long") {
      return this.localeData.months();
    }

    if (style === "short") {
      return this.localeData.monthsShort();
    }

    return this.localeData.monthsShort().map((month) => month.charAt(0));
  }
}
