<div class="form-page-container">
  <div class="container-flex-space-between mb-14">
    @if (!isLoading()) {
      <app-page-title [title]="title()"></app-page-title>

      @if (this.canAddModifyEntities && isPending()) {
        <app-button-group>
          <app-button
            type="ghost"
            (pressed)="onDelete()"
            [disabled]="isSubmitting()"
            [tp]="translations.delete"
          >
            <mat-icon>delete</mat-icon>
          </app-button>
          <app-button
            (pressed)="onSubmit()"
            [disabled]="!hasEudrCredentials() || isSubmitting()"
            [tp]="!hasEudrCredentials() ? translations.eudrCredentialsTooltip : null"
          >
            {{ isSubmitting() ? translations.submitting : translations.submit }}
          </app-button>
        </app-button-group>
      }
    }
  </div>

  <div class="element-content element-content-padded no-bottom-margin">
    @if (isDdsServiceUnavailable) {
      <app-empty-search-results
        [message]="serviceUnavailableMsg"
        imageSrc="/assets/images/service-unavailable.svg"
      ></app-empty-search-results>
    } @else {
      <mat-tab-group #tabGroup animationDuration="0ms" class="full-width">
        <mat-tab>
          <ng-template mat-tab-label i18n>Details</ng-template>
          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <div class="row-container">
              <div class="row row-2">
                <div class="item">
                  <app-form-label
                    [label]="
                      this.canAddModifyEntities
                        ? translations.referenceNumbersStatus
                        : translations.referenceNumber
                    "
                    class="column"
                  >
                    <div action class="flex-column">
                      <app-form-label
                        [label]="translations.internalReferenceNumber"
                        class="flex-column"
                      >
                        <span action class="fs-14">
                          {{ statement().statement.internalReferenceNumber }}
                        </span>
                      </app-form-label>
                      @if (this.canAddModifyEntities) {
                        <app-form-label [label]="translations.status" class="flex-column">
                          <span action class="fs-14">
                            <div class="tags-container">
                              <app-tag
                                [class]="statusTagClass()"
                                [tag]="{ label: statement().status }"
                              ></app-tag>
                            </div>
                          </span>
                        </app-form-label>
                        @if (!isPending() && !isSubmitted()) {
                          <app-form-label
                            [label]="translations.tracesReferenceNumber"
                            class="flex-column"
                          >
                            <span action class="fs-14">
                              {{ statement().tracesReferenceNumber || "-" }}
                            </span>
                          </app-form-label>
                          <app-form-label
                            [label]="translations.tracesVerificationNumber"
                            class="flex-column"
                          >
                            <span action class="fs-14">
                              {{ statement().tracesVerificationNumber || "-" }}
                            </span>
                          </app-form-label>
                        }
                      }
                    </div>
                  </app-form-label>
                </div>
                <div class="item">
                  <app-form-label [label]="translations.activity" class="column">
                    <span action class="fs-14 uppercase">
                      {{ statement().statement.activityType }}
                    </span>
                  </app-form-label>
                </div>
              </div>
              <div class="row row-2">
                <div>
                  <app-form-label [label]="translations.operatorTrader"></app-form-label>
                  <div class="operator-trader-box fs-14">
                    {{ operatorSectionText }}
                  </div>
                </div>

                <div class="place-of-activity">
                  <app-form-label [label]="translations.placeOfActivity"></app-form-label>

                  @if (isExportActivity()) {
                    <div class="container-flex-space-between">
                      <app-form-label [label]="translations.countryOfExit" class="column">
                        <div class="container-flex" action>
                          <app-flag [country]="statement().statement.borderCrossCountry"></app-flag>
                          <span class="fs-14">{{ countryOfActivityName() }}</span>
                        </div>
                      </app-form-label>
                      <app-form-label [label]="translations.isoCode" class="column">
                        <span action class="fs-14">
                          {{ statement().statement.borderCrossCountry }}
                        </span>
                      </app-form-label>
                    </div>
                  } @else {
                    <div class="container-flex-space-between">
                      <app-form-label [label]="translations.countryOfActivity" class="column">
                        <div class="container-flex" action>
                          <app-flag [country]="statement().statement.countryOfActivity"></app-flag>
                          <span class="fs-14">{{ countryOfActivityName() }}</span>
                        </div>
                      </app-form-label>
                      <app-form-label [label]="translations.isoCode" class="column">
                        <span action class="fs-14">
                          {{ statement().statement.countryOfActivity }}
                        </span>
                      </app-form-label>
                    </div>
                    <div class="container-flex-space-between">
                      <app-form-label [label]="translations.countryOfEntry" class="column">
                        <div class="container-flex" action>
                          <app-flag [country]="statement().statement.borderCrossCountry"></app-flag>
                          <span class="fs-14">{{ borderCrossCountryName() }}</span>
                        </div>
                      </app-form-label>
                      <app-form-label [label]="translations.isoCode" class="column">
                        <span action class="fs-14">
                          {{ statement().statement.borderCrossCountry || "-" }}
                        </span>
                      </app-form-label>
                    </div>
                  }
                </div>
              </div>

              <div>
                <app-form-label [label]="translations.additionalInfo" class="column">
                  <span action class="fs-14">
                    {{ statement().statement.comment || "-" }}
                  </span>
                </app-form-label>
              </div>

              <div>
                <app-form-label [label]="translations.totals"></app-form-label>
                <div class="container-flex-space-between reduced-width">
                  <app-form-label [label]="translations.netMass" class="column">
                    <span action class="fs-14">
                      {{ eudrDdsPageService.totals().netMass | number: "1.0-2" }}
                    </span>
                  </app-form-label>
                  <app-form-label [label]="translations.volume" class="column">
                    <span action class="fs-14">
                      {{ eudrDdsPageService.totals().volume | number: "1.0-2" }}
                    </span>
                  </app-form-label>
                  <app-form-label [label]="translations.totalArea" class="column">
                    <span action class="fs-14">
                      {{ eudrDdsPageService.totals().areaInHa | number: "1.0-2" }}
                    </span>
                  </app-form-label>
                </div>
              </div>

              <div>
                <app-form-label [label]="translations.commodities"></app-form-label>
                @for (
                  commodity of statement().statement.commodities;
                  let index = $index;
                  track index
                ) {
                  <app-reports-eudr-dds-commodity
                    [index]="index + 1"
                    [commodity]="commodity"
                    [hsCodes]="hsCodes()"
                  ></app-reports-eudr-dds-commodity>
                }
              </div>
            </div>
          }
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label i18n>References</ng-template>
          @if (isLoading()) {
            <app-loader></app-loader>
          } @else {
            <app-eudr-dds-references-tab
              (saved)="onSavedReferences()"
              [statement]="statement()"
              [canAddModifyEntities]="canAddModifyEntities"
            ></app-eudr-dds-references-tab>
          }
        </mat-tab>
      </mat-tab-group>
    }
  </div>
</div>
