import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { AddProductDialogComponent } from "@components/items/add-product-dialog/add-product-dialog.component";
import { ItemOverlayService } from "@components/items/pages/item-overlay/item-overlay.service";
import { AddMaterialDialogComponent } from "@components/materials";
import {
  ConfirmDialogComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlaySupplyChainClass } from "@components/shared/overlay/slide-overlay-supply-chain/slide-overlay-supply-chain.component";
import { TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  DateTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RouteEnum,
  StartEndEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IDate,
  IItem,
  IItemDetails,
  IItemPayload,
  ILocationExtended,
  IMaterial,
  IProcessExtended,
  IProcessType,
  IProduct,
  IProductExtended,
  IProductPayload,
  IRecordState,
  ISelectOption,
  ITag,
} from "@shared/interfaces";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import {
  AttachmentsService,
  CommonService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  MaterialsService,
  ProcessesService,
  ProcessTypesService,
  ProductsService,
  TagsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { NavigationParams } from "@shared/services/router.service";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-item-overlay",
  templateUrl: "./item-overlay.component.html",
  styleUrl: "./item-overlay.component.scss",
})
export class ItemOverlayComponent
  extends SlideOverlaySupplyChainClass
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("certificateSection") certificateSection: OverlayCertificateAttachmentsComponent;

  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public readonly dateTypeEnum = DateTypeEnum;

  public readonly recordStateEnum = RecordStateEnum;

  public readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  public override attachmentTargetType = AttachmentTargetEnum.ITEM;

  public readonly isSharedUser = this.authenticationService.isSharedUser();

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    itemId: new UntypedFormControl(null, [CustomValidators.required]),
    product: new UntypedFormControl(null, [CustomValidators.required]),
    unitOfMeasurement: new UntypedFormControl(null, [CustomValidators.required]),
    createdFrom: new UntypedFormControl(null),
    createdRange: new UntypedFormControl([null, null]),
    currentLocation: new UntypedFormControl(null, [CustomValidators.required]),
    createdAtLocation: new UntypedFormControl(null, [CustomValidators.required]),
    initialQuantity: new UntypedFormControl(null, [
      CustomValidators.required,
      CustomValidators.min(0),
    ]),
    isSameAsCreatedAtLocation: new UntypedFormControl(false),
    isRangeCreationDate: new UntypedFormControl(false),
    materials: new UntypedFormControl([]),
    remainingQuantity: new UntypedFormControl(null),
  });

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.ITEM_DETAILS, isEnabled: true }],
      [OverlayTabEnum.PROCESSES, { title: TextConstants.PROCESSES, isEnabled: false }],
      [OverlayTabEnum.DELIVERIES, { title: TextConstants.DELIVERIES, isEnabled: false }],
      [OverlayTabEnum.CERTIFICATES, { title: TextConstants.CERTIFICATES, isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: false }],
      [OverlayTabEnum.SUPPLY_CHAIN, { title: TextConstants.SUPPLY_CHAIN, isEnabled: false }],
      [
        OverlayTabEnum.COMMENTS,
        { title: TextConstants.COMMENTS, isEnabled: false, isHidden: !this.isRegularUser },
      ],
    ]),
  );

  public override element: IItemDetails;

  public isLoadingMaterials = signal<boolean>(false);

  public supplyChainHeight: number;

  public productOptions: ISelectOption[] = [];

  public materialOptions: ISelectOption[] = [];

  public productAllowedMaterials: IMaterial[] = [];

  public createdAtLocationOptions: ISelectOption[] = [];

  public currentLocationOptions: ISelectOption[] = [];

  public allProcessTypes: IProcessType[] = [];

  public deliveriesIds: string[];

  public unitOfMeasurementOptions: ISelectOption[] = [];

  public attachedProcesses: IProcessExtended[];

  public unitOfMeasurement: IBaseUnit;

  public itemDefaultUnitOfMeasurement: IBaseUnit | ICustomUnitOfMeasurement;

  public defaultUnitOfMeasurement: ICustomUnitOfMeasurement;

  public initialQuantityFormatted: string;

  public remainingQuantityFormatted: string;

  public hasDifferentDefaultUnit: boolean;

  public datesType: DateTypeEnum = DateTypeEnum.EXACT;

  public duplicateItemId: string;

  public override entityType = EntityTypeEnum.ITEMS;

  public override initialTags: ITag[] = [];

  private initialDateType: DateTypeEnum;

  private hasInitialDateTypeChanged: boolean = false;

  private allProducts: IProductExtended[] = [];

  private allMaterials: IMaterial[] = [];

  private allUnitOfMeasurements: IBaseUnit[] = [];

  private lastProductIdSelected: string;

  private initialQuantity: number;

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public readonly translations: any = {
    addNewMaterialTp: $localize`Add new material`,
  };

  constructor(
    public itemOverlayService: ItemOverlayService,
    public itemsService: ItemsService,
    private productsService: ProductsService,
    private locationsService: LocationsService,
    private commonService: CommonService,
    private materialsService: MaterialsService,
    private processesService: ProcessesService,
    private processTypesService: ProcessTypesService,
    private attachmentsService: AttachmentsService,
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private unitConversionPipe: UnitConversionPipe,
    private cdr: ChangeDetectorRef,
    private tagsService: TagsService,
    private featureFlagService: FeatureFlagService,
  ) {
    super();
    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  public override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return (
      !this.formGroup ||
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !(this.hasFormValuesChanged || this.hasInitialDateTypeChanged)
    );
  }

  protected override get requiresConfirmation(): boolean {
    return this.hasFormValuesChanged;
  }

  private get unitOfMeasurementControl() {
    return this.formGroup.controls["unitOfMeasurement"];
  }

  loadCounters(): void {
    if (this.element.id) {
      this.loadDocuments();
      this.loadCertificates();
      this.loadComments();
    }
  }

  setCountersToLoadingState() {
    this.itemOverlayService.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.itemOverlayService.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.itemOverlayService.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.itemOverlayService.loadCertificateCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.itemOverlayService.loadCommentCounter(this.entityUri);
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_ITEM)) {
      return;
    }

    await this.setAllCustomFields(CustomFieldsResourceTypeEnum.ITEM);

    this.duplicateItemId = window.history?.state?.duplicateItemId;
    const recordId = this.recordId;

    if (recordId) {
      this.setCountersToLoadingState();
      this.isEditing.set(true);
      await this.reloadElement(recordId);

      this.loadCounters();

      await Promise.all([
        this.fetchAttachedDeliveries(recordId),
        this.fetchAttachedProcesses(recordId),
        this.onReloadProcessTypes(),
      ]);
      await this.setMenuItemFromURLParam();
    } else {
      await Promise.all([this.onReloadLocations(), this.onReloadProducts()]);
      if (this.duplicateItemId) {
        await this.duplicateItemInfo();
      } else {
        await this.onReloadAllowedMaterials();
        await this.setupForm();
      }
      this.overlay.dismissLoading();
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    this.supplyChainHeight = CommonUtils.getOverlaySupplyChainHeight();
  }

  public override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    const payload = await this.getSavePayload();

    try {
      this.element = await this.itemsService.createOrUpdate(payload, this.element?.id);
      this.hasFormValuesChanged = false;

      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Item modified` : $localize`Item created`,
      );
      this.initialTags = [];

      return true;
    } catch (error) {
      const productId = CommonUtils.getUriId(this.element?.product);

      if (
        this.isEditing() &&
        error.status === 422 &&
        productId !== this.formGroup.controls["product"].value
      ) {
        const currentProduct = this.allProducts.find((p) => p.id === productId);

        this.formGroup.controls["product"].setValue(
          {
            value: productId,
            label: currentProduct.name,
          },
          { emitEvent: false },
        );
        await this.onReloadAllowedMaterials();
      }
      this.notificationService.showError(error);

      return false;
    }
  }

  public override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly) {
      if (!this.isEditing()) {
        await this.routerService.navigate(this.routerService.getItemLink(this.element.id), {
          replaceUrl: true,
        });
      } else {
        await this.reloadElement(this.element.id);
        await this.setupForm();
      }
    }
  }

  public override reloadElement = async (id: string): Promise<void> => {
    this.overlay.showLoading();

    await this.itemsService
      .get(id)
      .then(async (element: IItem) => {
        this.element = element;
        this.setEditMode();

        await Promise.all([this.onReloadLocations(), this.onReloadProducts()]);
        await this.onReloadAllowedMaterials();

        await this.handleUnits();

        await this.setupForm();

        this.initialDateType = element.created.type;
        this.overlay.dismissLoading();
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  public getLocationLink(id: string): NavigationParams {
    return <NavigationParams>this.routerService.getLocationLink(id, false);
  }

  public getProductLink(id: string): NavigationParams {
    return <NavigationParams>this.routerService.getProductLink(id, false);
  }

  public onReloadAllowedMaterials = async (resetFormValue: boolean = true): Promise<void> => {
    let productAllowedMaterials: any[] = [];

    const productId =
      this.formGroup.controls["product"].value?.value || CommonUtils.getUriId(this.element.product);

    if (!productId) {
      return;
    }

    this.isLoadingMaterials.set(true);
    try {
      this.allMaterials = await this.materialsService.getAll();
      const product = this.allProducts.find((c) => c.id === productId);

      if (this.isOldMaterialsEnabled) {
        productAllowedMaterials = [...product.allowedMaterials];
      } else {
        productAllowedMaterials = [...product.materials];
      }

      this.productAllowedMaterials = productAllowedMaterials;
      this.materialOptions = this.productAllowedMaterials.map((material) => ({
        label: `${material.category}: ${material.name}`,
        value: material.id,
      }));
      this.cdr.detectChanges();
      if (!this.isOldMaterialsEnabled) {
        this.formGroup.controls["materials"].setValue(this.materialOptions);
      } else if (resetFormValue) {
        this.formGroup.controls["materials"].setValue([]);
      }
      this.isLoadingMaterials.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public async onAddProductClicked(): Promise<void> {
    const dialogRef = await this.dialog.open(AddProductDialogComponent, {});

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; createdProduct?: IProduct }) => {
        if (result?.hasSaved) {
          await this.onReloadProducts();
          this.cdr.detectChanges();
          const productOption = this.productOptions.find(
            (p) => p.value === result.createdProduct.id,
          );

          this.formGroup.controls["product"].markAsDirty();
          this.formGroup.controls["product"].setValue(productOption);
        }
      });
  }

  public async onAddMaterialClicked(): Promise<void> {
    const productOption = this.formGroup.controls["product"].value as InputSelectOption;
    const product = this.allProducts.find((p) => p.id === productOption.value);

    const selectedMaterialOptions = this.formGroup.controls["materials"].value as ISelectOption[];

    const selectableMaterials = this.allMaterials.filter((material) =>
      selectedMaterialOptions.every((m) => m.value !== material.id),
    );

    const dialogRef = await this.dialog.open(AddMaterialDialogComponent, {
      data: {
        selectableMaterials,
        allowModeChange: true,
        productTarget: product,
        saveButtonText: "Add",
        isOldMaterialsEnabled: this.isOldMaterialsEnabled,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; addedMaterial?: IMaterial }) => {
        if (result?.hasSaved) {
          await this.updateAllowedMaterialsForProduct(result.addedMaterial);
          await this.onReloadProducts();
          await this.onReloadAllowedMaterials(false);
          this.cdr.detectChanges();

          if (this.isOldMaterialsEnabled) {
            const materialOption = this.materialOptions.find(
              (m) => m.value === result.addedMaterial.id,
            );

            this.formGroup.controls["materials"].markAsDirty();
            this.formGroup.controls["materials"].setValue([
              materialOption,
              ...this.formGroup.controls["materials"].value,
            ]);
          }
        }
      });
  }

  private async updateAllowedMaterialsForProduct(addedMaterial: IMaterial): Promise<void> {
    const productOption = this.formGroup.controls["product"].value as InputSelectOption;
    const product = this.allProducts.find((p) => p.id === productOption.value);

    if (this.isOldMaterialsEnabled) {
      const isMaterialAlreadyAllowed = product.allowedMaterials.some(
        (material) => material.id === addedMaterial.id,
      );

      if (isMaterialAlreadyAllowed) {
        return;
      }

      const newAllowedMaterialUri = `/organisations/${this.activeOrganisationId}/materials/${addedMaterial.id}`;
      const allowedMaterials: string[] = [
        newAllowedMaterialUri,
        ...product.allowedMaterials.map(
          (m) => `/organisations/${this.activeOrganisationId}/materials/${m.id}`,
        ),
      ];

      const payload: IProductPayload = {
        id: product.id,
        name: product.name,
        baseUnit: `/common/base-units/${product.unitOfMeasurement.id}`,
        allowedMaterials,
      };

      try {
        await this.productsService.createOrUpdate(payload, product.id);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      const isMaterialAlreadyAttached = product.materials.some(
        (material) => material.id === addedMaterial.id,
      );

      if (isMaterialAlreadyAttached) {
        return;
      }

      try {
        await this.itemOverlayService.createAttachment(
          AttachmentTargetEnum.PRODUCT,
          product.id,
          AttachmentTypeEnum.MATERIAL,
          addedMaterial.id,
        );
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  }

  public override setupForm = async (duplicate = false): Promise<void> => {
    let productValue = null;
    let materialsValue: InputSelectOption[] = [];
    let unitOfMeasurementValue = null;
    let currentLocationValue = null;
    let createdAtLocationValue = null;
    let createdFromValue: string = null;
    let createdToValue: string = null;
    let initialQuantity: number;
    let remainingQuantity: number;

    const editingOrDuplicating = this.isEditing() || duplicate;

    if (editingOrDuplicating) {
      const productId = CommonUtils.getUriId(this.element.product);

      productValue = this.productOptions.find((c) => c.value === productId);

      materialsValue = this.isOldMaterialsEnabled
        ? this.materialOptions.filter((m) =>
            this.element.materials.some((e) => m.value === CommonUtils.getUriId(e)),
          )
        : this.materialOptions;

      this.lastProductIdSelected = productValue?.value;
      const product = this.allProducts.find((p) => p.id === productId);
      const unitOfMeasurement = product.unitOfMeasurement;

      unitOfMeasurementValue = unitOfMeasurement?.name ?? null;
      this.unitOfMeasurement = unitOfMeasurement;
      const defaultUnitId = product.defaultCustomUnit?.id ?? product.unitOfMeasurement.id;

      await this.setItemDefaultUnitOfMeasurement(CommonUtils.getUriId(defaultUnitId));
      await this.setUnitOfMeasurementOptions(product, unitOfMeasurement);

      unitOfMeasurementValue = {
        value: this.itemDefaultUnitOfMeasurement?.id,
        label: this.itemDefaultUnitOfMeasurement?.name,
      };

      initialQuantity = this.getQuantity(this.element?.initialQuantity);
      remainingQuantity = this.getQuantity(this.element?.remainingQuantity);
      const locationId = CommonUtils.getUriId(this.element.currentLocation);
      const createdAtLocationId = CommonUtils.getUriId(this.element.createdAtLocation);

      currentLocationValue = this.currentLocationOptions.find(
        (l) => l.value.toString() === locationId,
      );
      createdAtLocationValue = this.createdAtLocationOptions.find(
        (l) => l.value.toString() === createdAtLocationId,
      );
      this.datesType = this.element.created.type;

      switch (this.datesType) {
        case DateTypeEnum.EXACT:
          createdFromValue = this.element.created.on;
          break;
        case DateTypeEnum.RANGE:
          createdFromValue = this.element.created.start;
          createdToValue = this.element.created.end;
          break;
      }
    }

    const entityExistsValidatorArgs = {
      searchPropertyName: "itemId",
      searchPropertyErrorDisplayName: "ID",
    };

    this.formGroup = new UntypedFormGroup({
      itemId: new UntypedFormControl(
        this.element?.itemId ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.itemsService,
            this.element?.id ?? this.route.snapshot.params["id"] ?? null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      product: new UntypedFormControl(productValue, [CustomValidators.required]),
      unitOfMeasurement: new UntypedFormControl(
        { value: unitOfMeasurementValue, disabled: !editingOrDuplicating },
        [CustomValidators.required],
      ),
      createdFrom: new UntypedFormControl(createdFromValue),
      createdRange: new UntypedFormControl([createdFromValue, createdToValue]),
      currentLocation: new UntypedFormControl(currentLocationValue, [CustomValidators.required]),
      createdAtLocation: new UntypedFormControl(createdAtLocationValue, [
        CustomValidators.required,
      ]),
      initialQuantity: new UntypedFormControl(
        { value: initialQuantity ?? null, disabled: !editingOrDuplicating },
        [CustomValidators.required, CustomValidators.min(0)],
      ),
      isSameAsCreatedAtLocation: new UntypedFormControl(false),
      isRangeCreationDate: new UntypedFormControl(this.datesType === DateTypeEnum.RANGE),
      materials: new UntypedFormControl({ value: materialsValue, disabled: !editingOrDuplicating }),
      remainingQuantity: new UntypedFormControl({
        value: remainingQuantity ?? null,
        disabled: true,
      }),
    });

    if (duplicate) {
      this.formGroup.controls["itemId"].markAsDirty();
      this.formGroup.controls["itemId"].updateValueAndValidity();
    }

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        this.formGroup.controls["createdFrom"].addValidators([
          CustomValidators.required,
          CustomValidators.date,
        ]);
        this.formGroup.controls["createdRange"].clearValidators();
        break;
      case DateTypeEnum.RANGE:
        this.formGroup.controls["createdRange"].addValidators([
          CustomValidators.required,
          CustomValidators.dateRange,
        ]);
        this.formGroup.controls["createdFrom"].clearValidators();
        break;
    }

    this.formGroup.controls["createdRange"].updateValueAndValidity();
    this.formGroup.controls["createdFrom"].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();

    if (this.isEditing()) {
      this.formGroup.controls["isSameAsCreatedAtLocation"].setValue(
        this.element.createdAtLocation === this.element.currentLocation,
        { emitEvent: false },
      );
    }

    this.subscriptions.add(
      this.formGroup.controls["product"].valueChanges.subscribe(async (product: ISelectOption) => {
        await this.onProductSelected(product);
      }),
    );
    this.subscriptions.add(
      this.formGroup.controls["isSameAsCreatedAtLocation"].valueChanges.subscribe((v) =>
        this.onSameAsCreatedChanged(v),
      ),
    );
    this.subscriptions.add(
      this.formGroup.controls["currentLocation"].valueChanges.subscribe(() =>
        this.setIsSameAsCreatedAtLocation(),
      ),
    );
    this.subscriptions.add(
      this.formGroup.controls["createdAtLocation"].valueChanges.subscribe(() =>
        this.setIsSameAsCreatedAtLocation(),
      ),
    );

    this.subscriptions.add(
      this.unitOfMeasurementControl.valueChanges.subscribe(async (option: ISelectOption) => {
        if (!option?.value) {
          return;
        }
        await this.setItemDefaultUnitOfMeasurement(option.value as string);
      }),
    );
    this.subscriptions.add(
      this.formGroup.controls["initialQuantity"].valueChanges.subscribe((value: string) => {
        if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
          this.initialQuantity = +this.unitConversionPipe.transform(
            value,
            this.itemDefaultUnitOfMeasurement,
            this.unitOfMeasurement,
            true,
          );
        } else {
          this.initialQuantity = +value;
        }
      }),
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = duplicate;

    if (!duplicate) {
      this.subscriptions.add(
        this.formGroup.valueChanges.subscribe(() => {
          const currentValue = this.formGroup.value;

          this.hasFormValuesChanged =
            !this.formGroup.pristine &&
            this.hasInitialFormValueChanged({
              ...currentValue,
              initialQuantity: +currentValue.initialQuantity,
            });
        }),
      );
    }
  };

  public setInitialQuantityValue() {
    const quantity = this.getQuantity(this.element?.initialQuantity);

    this.formGroup.controls["initialQuantity"].setValue(quantity, { emitEvent: false });
  }

  public setRemainingQuantityValue() {
    const quantity = this.getQuantity(this.element?.remainingQuantity);

    this.formGroup.controls["remainingQuantity"].setValue(quantity, { emitEvent: false });
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  protected override recordName(): string {
    return this.element?.itemId;
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.itemsService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.itemsService.delete(id);
  }

  protected override async duplicateRecord(id: string): Promise<void> {
    await this.routerService.navigate(this.routerService.getItemLink(), {
      state: { duplicateItemId: id },
    });
  }

  protected override saveBeforeClosing = async (): Promise<void> => await this.overlay.save(false);

  public override get itemIds(): string[] {
    return [this.element.id];
  }

  private async handleUnits(): Promise<void> {
    const product = await this.productsService.get(CommonUtils.getUriId(this.element.product));
    const baseUnit = CommonUtils.getUriId(product.baseUnit);
    const defaultUnitOfMeasurementId = CommonUtils.getUriId(product.defaultUnit ?? "");
    const unitOfMeasurement = this.allUnitOfMeasurements.find((m) => m.id === baseUnit);

    this.unitOfMeasurement = unitOfMeasurement;
    this.hasDifferentDefaultUnit = defaultUnitOfMeasurementId !== baseUnit;

    if (defaultUnitOfMeasurementId && this.hasDifferentDefaultUnit) {
      this.defaultUnitOfMeasurement = await this.unitsOfMeasurementService.getDefaultUnit(
        defaultUnitOfMeasurementId,
      );
      if (this.defaultUnitOfMeasurement) {
        this.initialQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.element.initialQuantity,
          this.defaultUnitOfMeasurement,
          this.unitOfMeasurement,
        );
        this.remainingQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.element.remainingQuantity,
          this.defaultUnitOfMeasurement,
          this.unitOfMeasurement,
        );
      }
    }
  }

  private async fetchAttachedDeliveries(itemId: string): Promise<void> {
    this.deliveriesIds = (await this.fetchAttachments(AttachmentTypeEnum.DELIVERY, itemId))?.map(
      (attachment) => CommonUtils.getUriId(attachment.attachmentUri),
    );

    this.itemOverlayService.linkedDeliveryCount.set(this.deliveriesIds.length);
  }

  private fetchAttachments = async (type: AttachmentTypeEnum, itemId: string) => {
    return this.attachmentsService.getAll(
      type,
      `/organisations/${this.activeOrganisationId}/items/${itemId}`,
    );
  };

  private fetchAttachedProcesses = async (itemId: string) => {
    const processAttachmentsIds = (
      await this.fetchAttachments(AttachmentTypeEnum.PROCESS, itemId)
    )?.map((attachment) => CommonUtils.getUriId(attachment.attachmentUri));
    const processes = await this.processesService.getAllGraphQL();

    this.attachedProcesses = processes.filter((p) => processAttachmentsIds.includes(p.id));

    this.itemOverlayService.linkedProcessCount.set(this.attachedProcesses.length);
  };

  private onReloadProcessTypes = async (): Promise<void> => {
    await this.processTypesService
      .getAll()
      .then((response: IProcessType[]) => {
        this.allProcessTypes = response;
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private duplicateItemInfo = async (): Promise<void> => {
    try {
      const item = await this.itemsService.get(this.duplicateItemId);

      const elementUri = `/organisations/${this.activeOrganisationId}/${this.entityType}/${this.duplicateItemId}`;

      this.initialTags = await this.tagsService.getAll(elementUri);

      const itemId = `${item.itemId}`;

      this.element = { ...item, itemId, id: null };
      this.initialDateType = this.element.created.type;

      await this.onReloadAllowedMaterials();
      await this.setupForm(true);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private getSavePayload = (): IItemPayload => {
    const { controls } = this.formGroup;

    let initialQuantity = controls["initialQuantity"].value;

    if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
      initialQuantity = this.unitConversionPipe.transform(
        initialQuantity,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        true,
      );
    }

    let created: IDate;

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        created = {
          type: DateTypeEnum.EXACT,
          on: FormUtils.getDateValueForPayload(controls["createdFrom"].value),
        };
        break;
      case DateTypeEnum.RANGE:
        created = {
          type: DateTypeEnum.RANGE,
          start: FormUtils.getDateValueForPayload(controls["createdRange"].value[0]),
          end: FormUtils.getDateValueForPayload(
            controls["createdRange"].value[1],
            StartEndEnum.END,
          ),
        };
        break;
    }

    const prefix = `/organisations/${this.activeOrganisationId}`;

    const payload: IItemPayload = {
      id: this.element?.id ?? undefined,
      itemId: controls["itemId"].value.trim(),
      materials: this.isOldMaterialsEnabled
        ? controls["materials"].value.map(
            (material: ISelectOption) => `${prefix}/materials/${material.value}`,
          )
        : (this.element?.materials ?? []),
      product: `${prefix}/products/${controls["product"].value.value}`,
      currentLocation: `${prefix}/locations/${controls["currentLocation"].value.value}`,
      createdAtLocation: `${prefix}/locations/${controls["createdAtLocation"].value.value}`,
      created,
      initialQuantity,
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };

  private onReloadProducts = async (): Promise<void> => {
    const includes = ["CUSTOM_UNITS"];

    if (this.isOldMaterialsEnabled) {
      includes.push("ALLOWED_MATERIALS");
    } else {
      includes.push("MATERIALS");
    }
    await this.productsService
      .getAllGraphQL(undefined, undefined, includes)
      .then((response: IProductExtended[]) => {
        this.allProducts = response;
        const selectedProductId = this.element?.product
          ? CommonUtils.getUriId(this.element.product)
          : null;

        this.productOptions = response
          .filter(
            (p) =>
              p.recordState === RecordStateEnum.ACTIVE ||
              (selectedProductId && p.id === selectedProductId),
          )
          .map((p) => ({ label: p.name, value: p.id }));
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private onReloadLocations = async (): Promise<void> => {
    await this.locationsService
      .getAllGraphQL()
      .then((response: ILocationExtended[]) => {
        const allLocations = response;

        const selectedCurrentLocationId = this.element?.currentLocation
          ? CommonUtils.getUriId(this.element.currentLocation)
          : null;

        const selectedCreatedAtLocationId = this.element?.createdAtLocation
          ? CommonUtils.getUriId(this.element.createdAtLocation)
          : null;

        this.currentLocationOptions = allLocations
          .filter(
            (location) =>
              location.recordState === RecordStateEnum.ACTIVE ||
              (selectedCurrentLocationId && location.id === selectedCurrentLocationId),
          )
          .map((location) => ({ label: location.name, value: location.id }));

        this.createdAtLocationOptions = allLocations
          .filter(
            (location) =>
              location.recordState === RecordStateEnum.ACTIVE ||
              (selectedCreatedAtLocationId && location.id === selectedCreatedAtLocationId),
          )
          .map((location) => ({ label: location.name, value: location.id }));
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private onChangeProduct = async (newProductOption: ISelectOption): Promise<void> => {
    const product = this.allProducts.find((product) => product.id === newProductOption.value);

    if (!product) {
      this.lastProductIdSelected = null;
    }

    if (product.id === this.lastProductIdSelected) {
      return;
    }

    const unitOfMeasurement = product.unitOfMeasurement;

    this.formGroup.controls["unitOfMeasurement"].setValue(unitOfMeasurement?.name ?? null);

    FormUtils.enableControls(this.formGroup, ["initialQuantity", "materials", "unitOfMeasurement"]);

    this.unitOfMeasurement = unitOfMeasurement;

    if (product.defaultCustomUnit) {
      await this.setItemDefaultUnitOfMeasurement(product.defaultCustomUnit.id);
    } else {
      this.itemDefaultUnitOfMeasurement = unitOfMeasurement;
    }

    await this.onReloadAllowedMaterials();

    await this.setUnitOfMeasurementOptions(product, unitOfMeasurement);

    this.lastProductIdSelected = product.id;
  };

  private setUnitOfMeasurementOptions = async (
    product: IProductExtended,
    systemUnitOfMeasurement: IBaseUnit,
  ): Promise<void> => {
    try {
      const customUnitsOfMeasurement: ICustomUnitOfMeasurement[] = product.customUnits ?? [];

      this.unitOfMeasurementOptions = [systemUnitOfMeasurement, ...customUnitsOfMeasurement].map(
        (unit) => ({
          label: unit.name,
          value: unit.id,
        }),
      );
      const defaultUnit = this.unitOfMeasurementOptions.find(
        (unit) => unit.value === this.itemDefaultUnitOfMeasurement?.id,
      );

      // TODO: improve.
      setTimeout(() => {
        this.unitOfMeasurementControl.setValue(defaultUnit);
        this.unitOfMeasurementControl.updateValueAndValidity();
        this.formGroup.updateValueAndValidity();
      });
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private setItemDefaultUnitOfMeasurement = async (unitId: string): Promise<void> => {
    if (unitId === this.unitOfMeasurement?.id) {
      this.itemDefaultUnitOfMeasurement = this.unitOfMeasurement;
    } else {
      this.itemDefaultUnitOfMeasurement = await this.unitsOfMeasurementService.get(unitId);
    }

    if (this.isEditing() || this.duplicateItemId) {
      this.setInitialQuantityValue();
      this.setRemainingQuantityValue();
    } else {
      let value: number;

      if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
        value = +this.unitConversionPipe.transform(
          `${this.initialQuantity}`,
          this.itemDefaultUnitOfMeasurement,
          this.unitOfMeasurement,
          true,
          false,
          true,
        );
        if (value) {
          this.formGroup.controls["initialQuantity"].setValue(value, { emitEvent: false });
        }
      } else {
        this.formGroup.controls["initialQuantity"].setValue(this.initialQuantity, {
          emitEvent: false,
        });
      }
    }
  };

  private onProductSelected = async (newProductOption: ISelectOption): Promise<void> => {
    if (!newProductOption || typeof newProductOption === "string") {
      this.formGroup.controls["initialQuantity"].setValue(null);
      this.formGroup.controls["materials"].setValue([]);
      this.formGroup.controls["unitOfMeasurement"].setValue(null);
      FormUtils.disableControls(this.formGroup, [
        "initialQuantity",
        "materials",
        "unitOfMeasurement",
      ]);
      this.lastProductIdSelected = null;

      return;
    }
    if (
      this.lastProductIdSelected &&
      newProductOption?.value &&
      this.lastProductIdSelected !== newProductOption.value
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: $localize`Resetting quantities and materials`,
          contentText: $localize`Changing the item’s product will reset all of the specified materials and might result in a change of the unit of measurement (which will reset the item’s initial quantity). Are you sure you want to change the product?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
        if (result === ConfirmDialogResponseEnum.CONFIRM) {
          const previousProduct = this.allProducts.find((p) => p.id === this.lastProductIdSelected);
          const newProduct = this.allProducts.find((p) => p.id === newProductOption.value);

          if (previousProduct.unitOfMeasurement?.id !== newProduct.unitOfMeasurement?.id) {
            this.formGroup.controls["initialQuantity"].setValue(null);
          }
          await this.onChangeProduct(newProductOption);
        } else {
          const previousProductOption = this.productOptions.find(
            (p) => p.value === this.lastProductIdSelected,
          );

          this.formGroup.controls["product"].setValue(previousProductOption);
        }
      });
    } else {
      await this.onChangeProduct(newProductOption);
    }
  };

  private setIsSameAsCreatedAtLocation = (): void => {
    this.formGroup.controls["isSameAsCreatedAtLocation"].setValue(
      this.formGroup.controls["createdAtLocation"].value?.value ===
        this.formGroup.controls["currentLocation"].value?.value,
      { emitEvent: false },
    );
  };

  private getQuantity(quantity: number): number {
    if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
      return +this.unitConversionPipe.transform(
        `${quantity}`,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        false,
        false,
        true,
      );
    } else {
      return quantity;
    }
  }

  private onSameAsCreatedChanged(isTheSame: boolean) {
    if (isTheSame) {
      const createdAtLocation = this.formGroup.controls["createdAtLocation"].value;

      this.formGroup.controls["currentLocation"].setValue(createdAtLocation, { emitEvent: false });
    }
  }

  public setHasInitialDateTypeChanged(datesType: DateTypeEnum) {
    this.datesType = datesType;
    this.hasInitialDateTypeChanged = this.initialDateType !== this.datesType;
  }
}
