<app-form-label [label]="translations.itemIds"></app-form-label>
<div>
  <app-radio-button-group>
    <app-radio-button
      (changeEvent)="onModeChange(modeEnum.MANUAL_ENTRY)"
      [checked]="this.formGroup.controls['mode'].value === modeEnum.MANUAL_ENTRY"
      [label]="translations.manualEntry"
    ></app-radio-button>
    &nbsp;
    <app-radio-button
      (changeEvent)="onModeChange(modeEnum.AUTO_GENERATE)"
      [checked]="this.formGroup.controls['mode'].value === modeEnum.AUTO_GENERATE"
      [disabled]="true"
      [label]="translations.autoGenerate"
    ></app-radio-button>
  </app-radio-button-group>
</div>

<br />

<app-form-label [label]="translations.fixed"></app-form-label>
<div [formGroup]="formGroup" class="row-container">
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_TAGS"></app-checkbox>
    </div>
    <div class="item">
      <app-form-label [label]="translations.tags"></app-form-label>
      <app-entity-tags
        [initialTags]="formGroup.controls[fieldEnum.TAGS].value"
        [entityType]="enityTypeEnum.ITEMS"
        (tagsChanged)="onTagsChanged($event)"
      ></app-entity-tags>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_PRODUCT"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [formControlName]="fieldEnum.PRODUCT"
        [options]="productOptions"
        [label]="translations.product"
        [placeholder]="translations.productPh"
      ></app-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_MATERIALS"></app-checkbox>
    </div>
    <div class="item">
      @if (isOldMaterialsEnabled) {
        <app-input-chips
          [allowCreateTag]="false"
          [allowDeleteTag]="false"
          [options]="materialOptions"
          [tooltip]="formGroup.controls['product'].value ? '' : translations.materialsTooltip"
          [formControlName]="fieldEnum.MATERIALS"
          [label]="translations.materials"
        ></app-input-chips>
      } @else {
        <div class="tag-group-container">
          <app-form-label
            [label]="translations.materials"
            [tooltip]="formGroup.controls['product'].value ? '' : translations.materialsTooltipNew"
          ></app-form-label>
          @if (formGroup.controls["materials"].value?.length) {
            <app-tag-group [tags]="formGroup.controls['materials'].value"></app-tag-group>
          } @else {
            -
          }
        </div>
      }
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [options]="formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value"
        [formControlName]="fieldEnum.UNIT_OF_MEASUREMENT"
        [label]="translations.unitOfMeasurement"
      ></app-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_INITIAL_QUANTITY"></app-checkbox>
    </div>
    <div class="item">
      <app-input
        [hint]="
          selectedUnitOfMeasurement?.id !== systemUnitOfMeasurement?.id &&
          formGroup.controls[fieldEnum.INITIAL_QUANTITY].value
            ? ('' + formGroup.controls[fieldEnum.INITIAL_QUANTITY].value
              | unitConversion: selectedUnitOfMeasurement : systemUnitOfMeasurement)
            : ''
        "
        [suffix]="selectedUnitOfMeasurement?.symbol"
        [formControlName]="fieldEnum.INITIAL_QUANTITY"
        [label]="translations.initialQuantity"
        type="number"
      ></app-input>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_CREATED_AT_LOCATION"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [options]="locationOptions"
        [formControlName]="fieldEnum.CREATED_AT_LOCATION"
        [label]="translations.createdAtLocation"
        [tooltip]="translations.createdAtLocationTooltip"
      ></app-input-select>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_CREATION_DATE"></app-checkbox>
    </div>
    <div class="item">
      @if (dateType === dateTypeEnum.EXACT) {
        <app-datepicker
          #datePicker
          class="no-padding"
          [label]="translations.creationDate"
          [formControlName]="fieldEnum.CREATED_FROM"
        ></app-datepicker>
      } @else if (dateType === dateTypeEnum.RANGE) {
        <app-daterangepicker
          #dateRangePicker
          class="no-padding"
          [label]="translations.createdRangeLabel"
          [formControlName]="fieldEnum.CREATED_RANGE"
        ></app-daterangepicker>
      }

      <div class="checkbox-row mb-14">
        <app-checkbox
          [formControlName]="fieldEnum.IS_RANGE_DATE_TYPE"
          [label]="translations.range"
        ></app-checkbox>
      </div>
    </div>
  </div>
  <div class="row fields-row">
    <div class="item">
      <app-checkbox [formControlName]="fieldEnum.IS_FIXED_CURRENT_LOCATION"></app-checkbox>
    </div>
    <div class="item">
      <app-input-select
        [options]="locationOptions"
        [formControlName]="fieldEnum.CURRENT_LOCATION"
        class="no-padding"
        [label]="translations.currentLocation"
        [tooltip]="translations.currentLocationTooltip"
      ></app-input-select>
      <div class="checkbox-row mb-14">
        <app-checkbox
          [formControlName]="fieldEnum.IS_SAME_AS_CREATED_AT_LOCATION"
          [label]="translations.sameAsLocation"
        ></app-checkbox>
      </div>
    </div>
  </div>
  <ng-container [formArrayName]="fieldEnum.CUSTOM_FIELDS">
    @for (
      control of bulkAddItemsService.customFieldsFormArray.controls;
      let i = $index;
      track control
    ) {
      <div class="row fields-row" [formGroupName]="i">
        <div class="item">
          <app-checkbox [formControlName]="customFieldEnum.IS_FIXED_CUSTOM_FIELD"></app-checkbox>
        </div>
        <div class="item">
          <app-input
            [label]="control.get(customFieldEnum.CUSTOM_FIELD_LABEL).value"
            [formControlName]="customFieldEnum.CUSTOM_FIELD_VALUE"
          ></app-input>
        </div>
      </div>
    }
  </ng-container>
</div>
