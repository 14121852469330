<div [formGroup]="formGroup" class="row-container">
  <div class="row row-2">
    <div class="item">
      <app-input-chips
        #locationSelect
        (tagCreatedEvent)="newLocationTypeCreated.emit()"
        [allowCreateTag]="true"
        [formControlName]="locationFormControlName"
        [tagUrl]="locationTypesService.getBaseUrl"
        [label]="translations.typesLabel"
        [tooltip]="translations.typesTooltip"
      ></app-input-chips>
    </div>
    <div class="item">
      <app-input-chips
        #countrySelect
        [formControlName]="countryFormControlName"
        [options]="allCountriesOption"
        [label]="translations.countriesLabel"
        [tooltip]="translations.countriesTooltip"
      ></app-input-chips>
    </div>
  </div>
</div>
