import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
} from "@angular/core";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { CommonConstants, TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-tag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() tag: InputSelectOption;

  @Input() class: string;

  @Input({ transform: booleanAttribute }) isTagClickable: boolean = false;

  @Input({ transform: booleanAttribute }) canRemove: boolean = false;

  @Input() maxTextLengthToShow = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  @Output() remove: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  @Output() pressed: EventEmitter<InputSelectOption> = new EventEmitter<InputSelectOption>();

  public readonly translations: any = {
    removeTp: TextConstants.REMOVE,
  };

  clickRemoveTag(): void {
    this.remove.emit(this.tag);
  }

  onClick(): void {
    if (this.isTagClickable) {
      this.pressed.emit(this.tag);
    }
  }
}
