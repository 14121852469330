import { Injectable, inject } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { IRecordState } from "@shared/interfaces";
import { ApiService, AuthenticationService } from "@shared/services";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import {
  PageableRiskLevelSets,
  RiskLevelSet,
  RiskLevelSetPayload,
  RiskLevelSetsParams,
} from "../models";

@Injectable({ providedIn: "root" })
export class RiskLevelSetsApiService {
  private readonly api = inject(ApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/risk-level-sets`;

  getPageSubscription(params: RiskLevelSetsParams) {
    const query = FormUtils.addUrlParams(params);

    return this.api.get<PageableRiskLevelSets>(`${this.getBaseUrl()}?${query}`);
  }

  getAll(params: RiskLevelSetsParams) {
    const query = FormUtils.addUrlParams(params);

    return lastValueFrom(this.api.get<PageableRiskLevelSets>(`${this.getBaseUrl()}?${query}`));
  }

  get(id: string) {
    return lastValueFrom(this.api.get<RiskLevelSet>(`${this.getBaseUrl()}/${id}`));
  }

  createOrUpdate(payload: RiskLevelSetPayload, id?: string) {
    const request = id
      ? this.api.put<RiskLevelSet>(`${this.getBaseUrl()}/${id}`, payload)
      : this.api.post<RiskLevelSet>(this.getBaseUrl(), payload);

    return lastValueFrom(request);
  }

  delete(id: string) {
    return lastValueFrom(this.api.delete<void>(`${this.getBaseUrl()}/${id}`));
  }

  setRecordState(payload: IRecordState, id: string) {
    return lastValueFrom(this.api.put<RiskLevelSet>(`${this.getBaseUrl()}/${id}`, payload));
  }
}
