<div class="map-outer-container" [hidden]="isLoading()">
  <app-button
    (pressed)="onDelete()"
    [visible]="canDelete"
    class="delete-button"
    [tp]="translations.removeTp"
    size="large"
    type="icon"
  >
    <mat-icon>delete</mat-icon>
  </app-button>
  <div class="zoom-buttons">
    <button
      (click)="onSetZoom(1)"
      class="zoom-button in"
      [tp]="translations.zoomInTp"
      [tpDelay]="500"
    >
      <mat-icon class="zoom-icon icon-16">add</mat-icon>
    </button>
    <button
      (click)="onSetZoom(-1)"
      class="zoom-button out"
      [tp]="translations.zoomOutTp"
      tpPlacement="bottom"
      [tpDelay]="500"
    >
      <mat-icon class="zoom-icon icon-16">remove</mat-icon>
    </button>
  </div>

  <div class="info">
    @switch (type) {
      @case (geoJsonTypeEnum.POINT) {
        <span class="coordinates-text">
          <ng-container i18n>Lat</ng-container>
          : {{ getFormattedCoordinateProperty(mapOptions.center.lat) }},
          <ng-container i18n>Lon</ng-container>
          :
          {{ getFormattedCoordinateProperty(mapOptions.center.lng) }}
        </span>
        <div class="icons">
          <mat-icon class="clickable" [tp]="translations.copyCoorsTp" (click)="onCopyCoordinates()">
            content_copy
          </mat-icon>
          <mat-icon class="clickable" [tp]="translations.downloadTp" (click)="onDownload()">
            download
          </mat-icon>
        </div>
      }
      @default {
        @if (polygonArea) {
          <span [tp]="translations.polygonAreaTp">
            <ng-container i18n>Est. area (ha)</ng-container>
            : {{ polygonArea | number }}
          </span>
        }
        <mat-icon class="clickable icon-24" [tp]="translations.downloadTp" (click)="onDownload()">
          download
        </mat-icon>
      }
    }
  </div>

  <google-map (idle)="onMapIdle()" [options]="mapOptions" [height]="height" [width]="'100%'">
    @if (type === geoJsonTypeEnum.POINT && markerOptions) {
      <map-marker [options]="markerOptions"></map-marker>
    }
  </google-map>
</div>
