import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@components/shared/cards/card-content/card-content.component";
import { IMaterial } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-material-card-content",
  templateUrl: "./material-card-content.component.html",
  styleUrl: "./material-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialCardContentComponent extends CardContentComponent {
  @Input() material: IMaterial;
}
