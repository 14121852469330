import { idAndNameFields } from "./queries.constants";
import { QueriesUtils } from "./queries.utils";
import { IConnectionGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load connections based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which connections are queried.
 * @param {IConnectionGraphQLFilter} filter - An object containing filtering criteria for connections.
 * @param {number} first - The number of connections to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of connections to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading connections.
 */

export const getConnectionsGraphQLQuery = (
  orgId: string,
  filter: IConnectionGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadConnections(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
        ${getConnectionProperties(include)}  
    }
}
`;
};

/**
 * Generates a GraphQL query to load products based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which connections are queried.
 * @param {string[]} ids - (Required) A list of products ids
 * @param {number} first - The number of connections to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of connections to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading connections.
 */

export const getConnectionsByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
    {
        loadConnectionsByIds(   
        orgId: "${orgId}",
        ids: ${QueriesUtils.getParsedIds(ids)},
        ${afterCursorClause ? `${afterCursorClause},` : ""}
        ${beforeCursorClause ? `${beforeCursorClause},` : ""}
        ${firstClause ? `${firstClause},` : ""}
        ${lastClause ? `${lastClause},` : ""}
    ) {
            ${getConnectionProperties(include)}      
        }
    }
    `;
};

export const getConnectionProperties = (include: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                ${idAndNameFields}
                address {
                    country
                }
                publicUri
                connectStatus
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        }
`;
