<app-slide-overlay-header
  [title]="title()"
  [canArchive]="true"
  [canDelete]="true"
  [enableTags]="true"
  icon="category"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="name"
              [label]="translations.nameLabel"
            ></app-input>
          </div>
        </div>

        <div class="row">
          <div class="item">
            <app-input-select
              enableViewMode
              [options]="categoriesOptions"
              [addOnWrite]="true"
              formControlName="category"
              [hint]="translations.categoryHint"
              [label]="translations.categoryLabel"
            ></app-input-select>
          </div>
        </div>
      </div>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isHidden"
    appSlideOverlayMenuItem
    overlay-menu-icon="select_check_box"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).title"
    [overlay-menu-buttons-template]="riskAssessmentController"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="materialOverlay.reportsCounter()"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isEnabled"
  >
    <app-overlay-risk-assessment-reports
      #riskAssessmentsView
      [activeOrganisationId]="activeOrganisationId"
      [recordUri]="entityUri"
      [recordName]="element.name"
      [resourceType]="templateResourceType"
      [overlayService]="materialOverlay"
    />
  </ng-template>
  <ng-template #riskAssessmentController>
    @if (isRecordActive && canAddModifyEntities) {
      <app-button
        type="ghost"
        [disabled]="overlay.loading()"
        (pressed)="riskAssessmentsView.openDialog()"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
</app-slide-overlay-content>
