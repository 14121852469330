<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Invite user</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <app-input
        [autofocus]="!isLoading()"
        formControlName="email"
        [label]="translations.emailLabel"
        type="email"
      ></app-input>
      <app-input-select
        [options]="userRolesOptions"
        formControlName="role"
        [label]="translations.roleLabel"
      ></app-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>send</mat-icon>
          <ng-container i18n>Send invitation</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
