<app-table
  *ngIf="columnDefs().length"
  [table]="table"
  [isLoading]="isLoading()"
  [rowData]="rowData"
  [columnDefs]="columnDefs()"
  [areButtonsEnabled]="areButtonsEnabled"
  [isSearchEnabled]="isSearchEnabled"
  [isRecordStateFilterEnabled]="isRecordStateFilterEnabled"
  [isPaginatorEnabled]="isPaginatorEnabled"
  [isFixedBottomPaginator]="isFixedBottomPaginator"
  [isSaveState]="isSaveTableState"
  [recordState]="recordState"
  (rowClick)="onEdit($event)"
  [batchActionSettings]="batchActionSettings"
  (getAll)="getAll()"
></app-table>
