<div class="batch-actions">
  <div class="selected-container container-flex">
    <div class="text bold">{{ selectionText() }}</div>
    <a
      class="blue-link"
      (keyup)="clearSelectedRows.emit()"
      (click)="clearSelectedRows.emit()"
      tabindex="0"
      i18n
    >
      Clear
    </a>
  </div>

  <div class="buttons-container container-flex">
    @for (action of batchActionSettings().actions | keyvalue: keyValuePipeKeepOrder; track $index) {
      @if (
        (action.value.doesNotNeedAddEditRights || canAddModifyEntities) &&
        (!action.value.isHiddenBySelectedRowsFn ||
          !action.value.isHiddenBySelectedRowsFn(selectedVisibleRows()))
      ) {
        <app-button
          type="ghost"
          [tp]="action.value.title"
          [tpIsLazy]="true"
          (pressed)="onBatchAction(action)"
        >
          <mat-icon>{{ action.value.icon }}</mat-icon>
        </app-button>
      }
    }
  </div>
</div>
