import {
  ColDef,
  IDateFilterParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { ISimpleFilterModelType } from "ag-grid-community/dist/types/core/filter/provided/iSimpleFilter";

import { TextConstants } from "@shared/constants";
import { CommonUtils } from "@shared/utils/common.utils";

import {
  ButtonsCellRendererComponent,
  ChipsCellRendererComponent,
  DateCellRendererComponent,
  DateRangeCellRendererComponent,
  LinkCellRendererComponent,
} from "../cell-renderers";
import { RecordStateEnum } from "../enums";
import { CellRendererUtils } from "./cell-renderer.utils";

const iconColumnCommonValues = {
  resizable: false,
  width: 40,
  maxWidth: 40,
  suppressSizeToFit: true,
  suppressAutoSize: true,
  suppressHeaderMenuButton: true,
  suppressFiltersToolPanel: true,
  suppressMovable: true,
  suppressHeaderFilterButton: true,
  suppressColumnsToolPanel: true,
  sortable: false,
  cellClass: "container-flex-center",
};

const quickActionsMenuColumnCommonValues = {
  suppressSizeToFit: true,
  suppressAutoSize: true,
  lockVisible: true,
  rowDrag: false,
  minWidth: 200,
};

const recordState = (): ColDef => ({
  ...iconColumnCommonValues,
  headerName: $localize`Record state`,
  field: "recordState",
  cellRenderer: CellRendererUtils.recordState,
  filter: "agTextColumnFilter",
  tooltipValueGetter: (params: any) =>
    params.value === RecordStateEnum.ARCHIVED ? TextConstants.ARCHIVED : "",
});

const buttons = (buttons: any[]): ColDef => ({
  ...iconColumnCommonValues,
  width: Math.max(90, buttons.length * 65),
  maxWidth: Math.max(90, buttons.length * 65),
  cellRenderer: ButtonsCellRendererComponent,
  cellRendererParams: {
    buttons,
  },
});

export const arrayValueFormatter = (row: any, cellRendererParams?: any): any => {
  if (typeof cellRendererParams === "function") {
    cellRendererParams = cellRendererParams(row);
  }

  if (!row.value) {
    return "-";
  }
  if (Array.isArray(row.value)) {
    return row.value
      .map((t) =>
        typeof t === "string"
          ? t
          : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, t),
      )
      .join(", ");
  }

  return typeof row.value === "string"
    ? row.value
    : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, row.value);
};

const arrayQuickFilterText = (row: any, cellRendererParams?: any): any => {
  if (typeof cellRendererParams === "function") {
    cellRendererParams = cellRendererParams(row);
  }

  if (!row.value) {
    return "";
  }
  if (Array.isArray(row.value)) {
    return row.value
      .map((t) =>
        typeof t === "string"
          ? t
          : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, t),
      )
      .join(", ");
  }

  return typeof row.value === "string"
    ? row.value
    : CommonUtils.getObjectNestedProperty(cellRendererParams["textParam"] as string, row.value);
};

const multipleLinks = (headerName: string, field: string, cellRendererParams: any): ColDef => ({
  headerName,
  field,
  cellRenderer: LinkCellRendererComponent,
  cellRendererParams,
  suppressAutoSize: true,
  suppressSizeToFit: true,
  valueFormatter: (row: any) => arrayValueFormatter(row, cellRendererParams),
  getQuickFilterText: (row) => arrayQuickFilterText(row, cellRendererParams),
});

const chips = (
  headerName: string,
  field: string,
  cellRendererParams?: any,
  options?: Partial<ColDef>,
): ColDef => {
  const defaultColDef: ColDef = {
    headerName,
    field,
    cellRenderer: ChipsCellRendererComponent,
    cellRendererParams,
    cellClass: "container-flex",
    suppressSizeToFit: true,
    suppressAutoSize: true,
    valueFormatter: (row: any) => arrayValueFormatter(row, cellRendererParams),
    getQuickFilterText: (row) => arrayQuickFilterText(row, cellRendererParams),
  };

  if (options) {
    return { ...defaultColDef, ...options };
  }

  return defaultColDef;
};

const tags = (headerName: string = TextConstants.TAGS, field: string = "tags"): ColDef =>
  chips(headerName, field, {
    textParam: "title",
    classParam: "color",
  });

const selectCheckbox = (): ColDef => ({
  ...iconColumnCommonValues,
  colId: "selectCheckbox",
  cellClass: "clickable",
  width: 60,
  maxWidth: 60,
  pinned: "left",
  checkboxSelection: true,
  headerCheckboxSelection: true,
  showDisabledCheckboxes: true,
  headerCheckboxSelectionFilteredOnly: true,
  headerCheckboxSelectionCurrentPageOnly: true,
  lockVisible: true,
  lockPinned: true,
  lockPosition: true,
  headerTooltip: $localize`Select/Deselect all`,
});

const formatterFn =
  <TData, TValue extends string | number | boolean>() =>
  (params: ValueFormatterParams<TData, TValue>): string => {
    return params?.value?.toString() ?? "-";
  };

const defaultValueFormatter = <
  TData,
  TValue extends string | number | boolean,
>(): ValueFormatterFunc<TData, TValue> => {
  return formatterFn<TData, TValue>();
};

const longTextValueFormatter =
  <TData, TValue extends string>(maxLength = 120) =>
  (params: ValueFormatterParams<TData, TValue>): string =>
    params?.value
      ? params.value.length > maxLength
        ? params.value.slice(0, maxLength) + "..."
        : params.value
      : "-";

const dateColumn = (column: ColDef & Required<Pick<ColDef, "field">>): ColDef => ({
  cellRenderer: DateCellRendererComponent,
  filter: "agDateColumnFilter",
  filterValueGetter: (params) => {
    const value = CommonUtils.getObjectNestedProperty(column.field, params.data);

    if (value) {
      return dateFilterDate(value);
    }

    return undefined;
  },
  filterParams: dateFilterParams,
  ...column,
});

type DateRangeCellValue = Date | { start: Date; end: Date; filterOption: ISimpleFilterModelType };

const dateRangeColumn = (
  column: ColDef &
    Required<
      Pick<ColDef, "field"> & {
        filterValueGetter: (params: ValueGetterParams) => DateRangeCellValue;
      }
    >,
) =>
  dateColumn({
    ...column,
    cellRenderer: DateRangeCellRendererComponent,
    filterParams: {
      ...dateFilterParams,
      comparator: (filterDate, cellValue: DateRangeCellValue) => {
        if (!cellValue) {
          return -1;
        }

        const filterTime = filterDate.getTime();

        if (cellValue instanceof Date) {
          const cellTime = cellValue.getTime();

          return cellTime === filterTime ? 0 : cellTime < filterTime ? -1 : 1;
        }

        if (cellValue.start) {
          const { start, end, filterOption } = cellValue;
          const startTime = start.getTime();
          const endTime = end.getTime();

          switch (filterOption) {
            case "equals":
              return filterTime >= startTime && filterTime <= endTime ? 0 : -1;
            case "greaterThan":
              return filterTime < startTime ? 1 : -1;
            case "lessThan":
              return filterTime > endTime ? -1 : 1;
            case "inRange":
              return filterTime >= startTime && filterTime <= endTime
                ? 0
                : filterTime < startTime
                  ? 1
                  : -1;
          }
        }

        return -1;
      },
    } as IDateFilterParams,
  });

const dateFilterParams: IDateFilterParams = {
  filterOptions: ["inRange", "equals", "lessThan", "greaterThan", "blank"],
  maxNumConditions: 1,
  includeBlanksInRange: false,
  includeBlanksInEquals: false,
  includeBlanksInLessThan: false,
  includeBlanksInGreaterThan: false,
  inRangeInclusive: true,
};

const dateFilterDate = (value: string) => {
  if (!value) {
    return undefined;
  }

  const date = new Date(value);

  // strip time out to avoid mismatches when filtering
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

export const ColumnUtils = {
  iconColumnCommonValues,
  quickActionsMenuColumnCommonValues,
  recordState,
  buttons,
  multipleLinks,
  chips,
  tags,
  selectCheckbox,
  defaultValueFormatter,
  longTextValueFormatter,
  dateColumn,
  dateRangeColumn,
  dateFilterDate,
};
