import { ChangeDetectionStrategy, Component, inject, viewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { defaultIfEmpty, filter, lastValueFrom } from "rxjs";

import { Country } from "@components/shared/countries/models";
import { CountriesTableComponent } from "@components/shared/countries/ui";
import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";
import { RouterService } from "@shared/services/router.service";

import { AddCountryDialogComponent } from "./add-country-dialog.component";

@Component({
  standalone: false,
  selector: "app-settings-countries",
  templateUrl: "./settings-countries.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsCountriesComponent {
  private readonly dialog = inject(MatDialog);

  private readonly router = inject(RouterService);

  private readonly table = viewChild<CountriesTableComponent>("countriesTable");

  readonly routing = RouteEnum;

  public readonly translations: any = {
    title: $localize`Countries`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  async openDialog() {
    const dialogRef = this.dialog.open(AddCountryDialogComponent);

    await lastValueFrom(dialogRef.afterClosed().pipe(filter(Boolean), defaultIfEmpty(null)));
    await this.table().getAll();
  }

  onEdit(country: Country) {
    this.router.navigate(this.router.getCountryLink(country.id));
  }
}
