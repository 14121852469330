import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Observable, Subject, Subscription } from "rxjs";

import { AddProductDialogComponent } from "@components/items";
import { ItemOverlayService } from "@components/items/pages/item-overlay/item-overlay.service";
import { AddMaterialDialogComponent } from "@components/materials";
import { ConfirmDialogComponent } from "@components/shared";
import { DatepickerComponent, DateRangePickerComponent } from "@components/shared/datepicker";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  DateTypeEnum,
  FeatureFlagEnum,
  RecordStateEnum,
  StartEndEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomField,
  ICustomUnitOfMeasurement,
  IItem,
  IItemDetails,
  IItemPayload,
  ILocationExtended,
  IMaterial,
  IProduct,
  IProductExtended,
  IProductPayload,
  ISelectOption,
} from "@shared/interfaces";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import {
  NotificationService,
  AuthenticationService,
  CommonService,
  CustomFieldsService,
  FeatureFlagService,
  ItemsService,
  LocationsService,
  LocationTypesService,
  MaterialsService,
  ProductsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./add-item-dialog.component.html",
  styleUrls: ["./add-item-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddItemDialogComponent implements OnInit {
  @ViewChild("datePicker") datePickerComponent: DatepickerComponent;

  @ViewChild("dateRangePicker") dateRangePickerComponent: DateRangePickerComponent;

  public readonly dateTypeEnum = DateTypeEnum;

  public organisationOptions: InputSelectOption[] = [];

  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public isLoading = signal(true);

  private activeOrganisationId: string;

  private refreshTableSubject = new Subject<unknown>();

  private subscriptions = new Subscription();

  private lastProductIdSelected: string;

  public hasDifferentDefaultUnit: boolean;

  public createdAtLocationOptions: ISelectOption[] = [];

  public currentLocationOptions: ISelectOption[] = [];

  public productAllowedMaterials: IMaterial[] = [];

  private allCustomFields: ICustomField[] = [];

  public visibleCustomFields: ICustomField[] = [];

  private allUnitOfMeasurements: IBaseUnit[] = [];

  public allProducts: IProductExtended[] = [];

  public allProductsOptions: ISelectOption[] = [];

  public allMaterials: IMaterial[] = [];

  public datesType = DateTypeEnum.EXACT;

  public unitOfMeasurement: IBaseUnit;

  public materialOptions: InputSelectOption[] = [];

  public unitOfMeasurementOptions: ISelectOption[] = [];

  public initialQuantityFormatted: string;

  public deliveredQuantityFormatted: string;

  public itemDefaultUnitOfMeasurement: IBaseUnit | ICustomUnitOfMeasurement;

  public defaultUnitOfMeasurement: ICustomUnitOfMeasurement;

  private initialQuantity: number;

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;

  private materialAttachments: IAttachment[];

  public readonly translations: any = {
    id: $localize`ID`,
    product: TextConstants.PRODUCT,
    materials: TextConstants.MATERIALS,
    unitOfMeasurement: $localize`Unit of measurement`,
    initialQty: $localize`Initial quantity`,
    createdAtLocation: $localize`Created at location`,
    creationDate: TextConstants.CREATION_DATE,
    range: TextConstants.RANGE_FROM_TO,
    currentLocation: $localize`Current / final location`,
    sameAsLocation: $localize`Same as created at location`,
    deliveredQty: $localize`Delivery quantity`,
    sameAsQty: $localize`Same as initial quantity`,
    itemIdHint: $localize`E.g. stock / batch number, etc.`,
    materialsTooltip: $localize`Please, first select a product to see which materials are allowed.`,
    productsTooltip: $localize`Please select a product to see which materials it comprises of.`,
    createdAtLocationTooltip: $localize`Provide information about where the item was first created /
                            got a new identifier.`,
    currentLocationTooltip: $localize`Provide information on what is the item's current location
                    (if the item still physically exists) or what was its last location if it has
                    been already transformed into other items / destroyed.`,
    materialTp: $localize`Add new material`,
    productTp: $localize`Add new product`,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      dialogTitle: string;
      originalItem?: IItemDetails;
      shouldExcludeDeliveryQty?: boolean;
    },
    public dialogRef: MatDialogRef<AddItemDialogComponent>,
    public locationTypesService: LocationTypesService,
    private locationsService: LocationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private itemsService: ItemsService,
    private commonService: CommonService,
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private dialog: MatDialog,
    private materialsService: MaterialsService,
    private productsService: ProductsService,
    private unitConversionPipe: UnitConversionPipe,
    private cdr: ChangeDetectorRef,
    private customFieldsService: CustomFieldsService,
    private featureFlagService: FeatureFlagService,
    private itemOverlayService: ItemOverlayService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  public async ngOnInit(): Promise<void> {
    await Promise.all([
      this.onReloadLocations(),
      this.onReloadMaterials(),
      this.onReloadProducts(),
      this.getCustomFields(),
    ]);

    await this.setupForm();
    this.isLoading.set(false);
  }

  private get originalItem(): IItemDetails {
    return this.data.originalItem;
  }

  private onReloadLocations = async (): Promise<void> => {
    await this.locationsService
      .getAllGraphQL()
      .then((response: ILocationExtended[]) => {
        const allLocations = response;
        const selectedCurrentLocationId: string = null;
        const selectedCreatedAtLocationId: string = null;

        this.currentLocationOptions = allLocations
          .filter(
            (p) =>
              p.recordState === RecordStateEnum.ACTIVE ||
              (selectedCurrentLocationId && p.id === selectedCurrentLocationId),
          )
          .map((p) => ({ label: p.name, value: p.id }));

        this.createdAtLocationOptions = allLocations
          .filter(
            (p) =>
              p.recordState === RecordStateEnum.ACTIVE ||
              (selectedCreatedAtLocationId && p.id === selectedCreatedAtLocationId),
          )
          .map((p) => ({ label: p.name, value: p.id }));
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  private setItemDefaultUnitOfMeasurement = async (unitId: string): Promise<void> => {
    if (unitId === this.unitOfMeasurement.id) {
      this.itemDefaultUnitOfMeasurement = this.unitOfMeasurement;
    } else {
      this.itemDefaultUnitOfMeasurement = await this.unitsOfMeasurementService.get(unitId);
    }

    let value: number;
    const initialQuantityControl = this.formGroup?.controls["initialQuantity"];

    if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
      value = +this.unitConversionPipe.transform(
        `${this.initialQuantity}`,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        true,
        false,
        true,
      );
      if (value && initialQuantityControl) {
        initialQuantityControl.setValue(value, { emitEvent: false });
      }
    } else if (initialQuantityControl) {
      initialQuantityControl.setValue(this.initialQuantity, { emitEvent: false });
    }
  };

  private getQuantity(quantity: number): number {
    if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
      return +this.unitConversionPipe.transform(
        `${quantity}`,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        false,
        false,
        true,
      );
    } else {
      return quantity;
    }
  }

  async getCustomFields() {
    this.allCustomFields = await this.customFieldsService.getAll(CustomFieldsResourceTypeEnum.ITEM);
  }

  private async setupForm(): Promise<void> {
    const entityExistsValidatorArgs = {
      searchPropertyName: "itemId",
      searchPropertyErrorDisplayName: "ID",
    };

    let itemId: string;
    let productOption: InputSelectOption;
    let createdAtLocation: ISelectOption;
    let currentLocation: ISelectOption;
    let initialQuantity: number;
    let deliveredQty: number;
    let isDeliveredQtySameAsInitial: boolean = false;
    let createdFrom: string;
    let createdRange: [string, string] = [null, null];
    let materials: InputSelectOption[] = [];

    if (this.originalItem) {
      itemId = `${this.originalItem.itemId}`;

      productOption = this.allProductsOptions.find((p) => p.value === this.originalItem.productId);
      createdAtLocation = this.createdAtLocationOptions.find(
        (l) => l.value === CommonUtils.getUriId(this.originalItem.createdAtLocation),
      );
      currentLocation = this.currentLocationOptions.find(
        (l) => l.value === CommonUtils.getUriId(this.originalItem.currentLocation),
      );

      const product = this.allProducts.find((p) => p.id === this.originalItem.productId);

      this.unitOfMeasurement = product?.unitOfMeasurement;

      await this.setItemDefaultUnitOfMeasurement(product?.unitOfMeasurement?.id);
      await this.setUnitOfMeasurementOptions(product);

      initialQuantity = this.getQuantity(this.originalItem.initialQuantity);
      deliveredQty = this.getQuantity(this.originalItem.deliveredQuantity);

      isDeliveredQtySameAsInitial = initialQuantity === deliveredQty;

      if (this.originalItem.created.type) {
        this.datesType = this.originalItem.created.type;
      } else if (this.originalItem.created.on) {
        this.datesType = DateTypeEnum.EXACT;
      } else {
        this.datesType = DateTypeEnum.RANGE;
      }

      if (this.datesType === DateTypeEnum.RANGE) {
        createdRange = [this.originalItem.created.start, this.originalItem.created.end];
      } else {
        createdFrom = this.originalItem.created.on;
      }
    }

    this.formGroup = new UntypedFormGroup({
      itemId: new UntypedFormControl(
        itemId,
        [CustomValidators.required],
        [CustomValidators.entityAlreadyExists(this.itemsService, null, entityExistsValidatorArgs)],
      ),
      product: new UntypedFormControl(productOption, [CustomValidators.required]),
      unitOfMeasurement: new UntypedFormControl({ value: null, disabled: true }, [
        CustomValidators.required,
      ]),
      createdFrom: new UntypedFormControl(createdFrom),
      createdRange: new UntypedFormControl(createdRange),
      currentLocation: new UntypedFormControl(currentLocation, [CustomValidators.required]),
      createdAtLocation: new UntypedFormControl(createdAtLocation, [CustomValidators.required]),
      initialQuantity: new UntypedFormControl(
        { value: initialQuantity, disabled: !initialQuantity },
        [CustomValidators.required, CustomValidators.min(0)],
      ),
      isSameAsCreatedAtLocation: new UntypedFormControl(false),
      isDeliveredQtySameAsInitial: new UntypedFormControl(isDeliveredQtySameAsInitial),
      materials: new UntypedFormControl({ value: [], disabled: true }),
      deliveredQty: new UntypedFormControl(
        { value: deliveredQty ?? null, disabled: isDeliveredQtySameAsInitial },
        [CustomValidators.min(0)],
      ),
      datesType: new UntypedFormControl(this.datesType === DateTypeEnum.RANGE, [
        CustomValidators.required,
      ]),
    });

    if (this.originalItem) {
      await this.onChangeProduct(productOption as ISelectOption);

      materials = this.materialOptions.filter((m) => {
        return this.data.originalItem.materials.some(
          (mat) => m.value === CommonUtils.getUriId(mat),
        );
      });

      this.formGroup.controls["materials"].enable();
      this.formGroup.controls["materials"].setValue(materials);

      await this.handleUnits();
    }

    this.data.originalItem = null;

    const { formGroup } = this;
    const { controls } = formGroup;

    this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, null);
    CustomFieldsUtils.addToFormGroup(formGroup, this.visibleCustomFields, null);

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        controls["createdFrom"].addValidators([CustomValidators.required, CustomValidators.date]);
        controls["createdRange"].clearValidators();
        break;
      case DateTypeEnum.RANGE:
        controls["createdRange"].addValidators([
          CustomValidators.required,
          CustomValidators.dateRange,
        ]);
        controls["createdFrom"].clearValidators();
        break;
    }

    controls["createdRange"].updateValueAndValidity();
    controls["createdFrom"].updateValueAndValidity();
    formGroup.updateValueAndValidity();

    this.subscriptions.add(
      controls["product"].valueChanges.subscribe(async (product: ISelectOption) => {
        await this.onProductSelected(product);
      }),
    );
    this.subscriptions.add(
      controls["isSameAsCreatedAtLocation"].valueChanges.subscribe((v: boolean) =>
        this.onSameAsCreatedChanged(v),
      ),
    );
    this.subscriptions.add(
      controls["initialQuantity"].valueChanges.subscribe((value) => {
        this.onDeliveredQtySameAsInitial(
          this.formGroup.controls["isDeliveredQtySameAsInitial"].value,
        );
        if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
          this.initialQuantity = +this.unitConversionPipe.transform(
            value,
            this.itemDefaultUnitOfMeasurement,
            this.unitOfMeasurement,
            true,
          );
        } else {
          this.initialQuantity = value ? +value : 0;
        }
      }),
    );

    this.subscriptions.add(
      controls["isDeliveredQtySameAsInitial"].valueChanges.subscribe((value: boolean) =>
        this.onIsDeliveredQtySameAsInitialChange(value),
      ),
    );

    this.subscriptions.add(
      controls["currentLocation"].valueChanges.subscribe(() => this.setIsSameAsCreatedAtLocation()),
    );
    this.subscriptions.add(
      controls["createdAtLocation"].valueChanges.subscribe(() =>
        this.setIsSameAsCreatedAtLocation(),
      ),
    );

    this.subscriptions.add(
      controls["datesType"].valueChanges.subscribe((isRanged: boolean) =>
        this.handleDatepickerTypeChange(isRanged),
      ),
    );

    this.subscriptions.add(
      this.unitOfMeasurementControl.valueChanges.subscribe(async (option: ISelectOption) => {
        if (option?.value) {
          await this.setItemDefaultUnitOfMeasurement(option.value as string);
        }
      }),
    );
  }

  private async onReloadProducts(): Promise<void> {
    const includes = ["CUSTOM_UNITS"];

    if (this.isOldMaterialsEnabled) {
      includes.push("ALLOWED_MATERIALS");
    } else {
      includes.push("MATERIALS");
    }
    try {
      this.allProducts = await this.productsService.getAllGraphQL(undefined, undefined, includes);
      this.allProductsOptions = this.allProducts
        .filter((p) => p.recordState === RecordStateEnum.ACTIVE)
        .map((p: IProductExtended) => ({ label: p.name, value: p.id }));
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private async onReloadMaterials(): Promise<void> {
    try {
      this.allMaterials = await this.materialsService.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public async onAddProductClicked(): Promise<void> {
    const dialogRef = await this.dialog.open(AddProductDialogComponent, {});

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; createdProduct?: IProduct }) => {
        if (result?.hasSaved) {
          await this.onReloadProducts();

          this.cdr.detectChanges();

          const productOption = this.allProductsOptions.find(
            (p) => p.value === result.createdProduct.id,
          );

          this.formGroup.controls["product"].markAsDirty();
          this.formGroup.controls["product"].setValue(productOption);
        }
      });
  }

  public async onAddMaterialClicked(): Promise<void> {
    const productOption = this.formGroup.controls["product"].value as InputSelectOption;
    const product = this.allProducts.find((p) => p.id === productOption.value);

    const selectedMaterialOptions = this.formGroup.controls["materials"].value as ISelectOption[];

    const selectableMaterials = this.allMaterials.filter((material) =>
      selectedMaterialOptions.every((m) => m.value !== material.id),
    );

    const dialogRef = await this.dialog.open(AddMaterialDialogComponent, {
      data: {
        selectableMaterials,
        allowModeChange: true,
        productTarget: product,
        saveButtonText: "Add",
        isOldMaterialsEnabled: this.isOldMaterialsEnabled,
      },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { hasSaved: boolean; addedMaterial?: IMaterial }) => {
        if (result?.hasSaved) {
          await this.updateAllowedMaterialsForProduct(result.addedMaterial);
          await this.onReloadProducts();
          await this.onReloadAllowedMaterials(false);
          await this.onReloadMaterials();

          this.cdr.detectChanges();
          if (this.isOldMaterialsEnabled) {
            const materialOption = this.materialOptions.find(
              (m) => m.value === result.addedMaterial.id,
            );

            this.formGroup.controls["materials"].markAsDirty();
            this.formGroup.controls["materials"].setValue([
              ...this.formGroup.controls["materials"].value,
              materialOption,
            ]);
          }
        }
      });
  }

  private async updateAllowedMaterialsForProduct(addedMaterial: IMaterial): Promise<void> {
    const productOption = this.formGroup.controls["product"].value as InputSelectOption;
    const product = this.allProducts.find((p) => p.id === productOption.value);

    if (!product) {
      return;
    }

    if (this.isOldMaterialsEnabled) {
      const isMaterialAlreadyAllowed = product.allowedMaterials.some(
        (material) => material.id === addedMaterial.id,
      );

      if (isMaterialAlreadyAllowed) {
        return;
      }

      const newAllowedMaterialUri = `/organisations/${this.activeOrganisationId}/materials/${addedMaterial.id}`;
      const allowedMaterials: string[] = [
        newAllowedMaterialUri,
        ...product.allowedMaterials.map(
          (m) => `/organisations/${this.activeOrganisationId}/materials/${m.id}`,
        ),
      ];

      const payload: IProductPayload = {
        id: product.id,
        name: product.name,
        baseUnit: `/common/base-units/${product.unitOfMeasurement.id}`,
        allowedMaterials,
      };

      try {
        await this.productsService.createOrUpdate(payload, product.id);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      const isMaterialAlreadyAttached = product?.materials.some(
        (material) => material.id === addedMaterial.id,
      );

      if (isMaterialAlreadyAttached) {
        return;
      }

      try {
        await this.itemOverlayService.createAttachment(
          AttachmentTargetEnum.PRODUCT,
          product.id,
          AttachmentTypeEnum.MATERIAL,
          addedMaterial.id,
        );
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  }

  public onIsDeliveredQtySameAsInitialChange(checked: boolean): void {
    if (checked) {
      this.formGroup.controls["deliveredQty"].disable();
      this.formGroup.controls["deliveredQty"].setValue(
        this.formGroup.controls["initialQuantity"].value,
      );

      return;
    }

    if (this.formGroup.controls["initialQuantity"].enabled) {
      this.formGroup.controls["deliveredQty"].enable();
    }
  }

  private handleDatepickerTypeChange(checked: boolean): void {
    this.datesType = checked ? this.dateTypeEnum.RANGE : DateTypeEnum.EXACT;

    const { formGroup } = this;
    const { controls } = formGroup;

    if (this.datesType === DateTypeEnum.RANGE) {
      controls["createdFrom"].clearValidators();
      controls["createdRange"].patchValue([controls["createdFrom"].value, null]);
      controls["createdRange"].addValidators([
        CustomValidators.required,
        CustomValidators.dateRange,
      ]);
    }

    if (this.datesType === DateTypeEnum.EXACT) {
      controls["createdRange"].clearValidators();
      controls["createdFrom"].patchValue(controls["createdRange"].value[0]);
      controls["createdFrom"].addValidators([CustomValidators.required, CustomValidators.date]);
    }
    controls["createdRange"].updateValueAndValidity();
    controls["createdFrom"].updateValueAndValidity();
    formGroup.updateValueAndValidity();

    const createdFrom = controls["createdFrom"].value;

    if (this.datesType === DateTypeEnum.RANGE) {
      setTimeout(() => this.dateRangePickerComponent.picker.open());
    } else if (this.datesType === DateTypeEnum.EXACT && !createdFrom) {
      setTimeout(() => this.datePickerComponent.picker.open());
    }
  }

  private onSameAsCreatedChanged(isTheSame: boolean) {
    if (isTheSame) {
      const createdAtLocation = this.formGroup.controls["createdAtLocation"].value;

      this.formGroup.controls["currentLocation"].setValue(createdAtLocation, { emitEvent: false });
    }
  }

  private onDeliveredQtySameAsInitial(isTheSame: boolean) {
    if (isTheSame) {
      const initialQuantity = this.formGroup.controls["initialQuantity"].value;

      this.formGroup.controls["deliveredQty"].setValue(initialQuantity, { emitEvent: false });
    }
  }

  private setIsSameAsCreatedAtLocation = (): void => {
    this.formGroup.controls["isSameAsCreatedAtLocation"].setValue(
      this.formGroup.controls["createdAtLocation"].value?.value ===
        this.formGroup.controls["currentLocation"].value?.value,
      { emitEvent: false },
    );
  };

  get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  private onProductSelected = async (newProductOption: ISelectOption): Promise<void> => {
    if (!newProductOption || typeof newProductOption === "string") {
      this.formGroup.controls["initialQuantity"].setValue(null);
      this.formGroup.controls["materials"].setValue([]);
      this.formGroup.controls["unitOfMeasurement"].setValue(null);
      FormUtils.disableControls(this.formGroup, [
        "initialQuantity",
        "materials",
        "unitOfMeasurement",
      ]);

      return;
    }

    if (
      this.lastProductIdSelected &&
      newProductOption?.value &&
      this.lastProductIdSelected !== newProductOption.value
    ) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: $localize`Resetting quantities and materials`,
          contentText: $localize`Changing the item’s product will reset all of the specified materials and might result in a change of the unit of measurement (which will reset the item’s initial quantity). Are you sure you want to change the product?`,
        },
      });

      dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
        if (result === ConfirmDialogResponseEnum.CONFIRM) {
          const previousProduct = this.allProducts.find((p) => p.id === this.lastProductIdSelected);
          const newProduct = this.allProducts.find((p) => p.id === newProductOption.value);

          if (previousProduct.unitOfMeasurement?.id !== newProduct.unitOfMeasurement?.id) {
            this.formGroup.controls["initialQuantity"].setValue(null);
            this.formGroup.controls["materials"].setValue([]);
          }
          await this.onChangeProduct(newProductOption);
        } else {
          const previousProductOption = this.allProductsOptions.find(
            (p) => p.value === this.lastProductIdSelected,
          );

          this.formGroup.controls["product"].setValue(previousProductOption);
        }
      });
    } else {
      await this.onChangeProduct(newProductOption);
    }
  };

  private onChangeProduct = async (newProductOption: ISelectOption): Promise<void> => {
    const product = this.allProducts.find((c) => c.id === newProductOption.value);

    if (product && product.id !== this.lastProductIdSelected) {
      this.formGroup.controls["unitOfMeasurement"].setValue(
        product?.unitOfMeasurement?.name ?? null,
      );

      FormUtils.enableControls(this.formGroup, [
        "initialQuantity",
        "materials",
        "unitOfMeasurement",
      ]);

      this.unitOfMeasurement = product.unitOfMeasurement;

      if (product?.defaultCustomUnit) {
        await this.setItemDefaultUnitOfMeasurement(product?.defaultCustomUnit.id);
      } else {
        this.itemDefaultUnitOfMeasurement = product.unitOfMeasurement;
      }

      await this.onReloadAllowedMaterials();
      await this.setUnitOfMeasurementOptions(product);
      this.lastProductIdSelected = product.id;
    }
  };

  private setUnitOfMeasurementOptions = async (product: IProductExtended): Promise<void> => {
    const customUnitsOfMeasurement = product.customUnits || [];

    this.unitOfMeasurementOptions = [product.unitOfMeasurement, ...customUnitsOfMeasurement].map(
      (unit) => ({
        label: unit.name,
        value: unit.id,
      }),
    );
    const defaultUnit = this.unitOfMeasurementOptions.find(
      (unit) => unit.value === this.itemDefaultUnitOfMeasurement.id,
    );

    // TODO: improve.
    setTimeout(() => {
      this.unitOfMeasurementControl.setValue(defaultUnit);
      this.unitOfMeasurementControl.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    });
  };

  private async handleUnits(): Promise<void> {
    const productId = CommonUtils.getUriId(this.formGroup.controls["product"].value.value);
    const product = await this.productsService.get(productId);
    const baseUnit = CommonUtils.getUriId(product.baseUnit);
    const defaultUnitOfMeasurementId = CommonUtils.getUriId(product.defaultUnit ?? "");
    const unitOfMeasurement = this.allUnitOfMeasurements.find((m) => m.id === baseUnit);

    this.unitOfMeasurement = unitOfMeasurement;
    this.hasDifferentDefaultUnit = defaultUnitOfMeasurementId !== baseUnit;

    if (defaultUnitOfMeasurementId && this.hasDifferentDefaultUnit) {
      this.defaultUnitOfMeasurement = await this.unitsOfMeasurementService.getDefaultUnit(
        defaultUnitOfMeasurementId,
      );
      if (this.defaultUnitOfMeasurement) {
        this.initialQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.formGroup.controls["initialQuantity"].value,
          this.defaultUnitOfMeasurement,
          this.unitOfMeasurement,
        );
        this.deliveredQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
          this.formGroup.controls["deliveredQty"].value,
          this.defaultUnitOfMeasurement,
          this.unitOfMeasurement,
        );
      }
    }
  }

  private get unitOfMeasurementControl() {
    return this.formGroup.controls["unitOfMeasurement"];
  }

  public onReloadAllowedMaterials = async (resetFormValue: boolean = true): Promise<void> => {
    this.productAllowedMaterials = [];

    let productId: string;

    if (this.data.originalItem) {
      productId = this.data.originalItem.productId;
    } else {
      productId = this.formGroup.controls["product"].value?.value;
    }

    if (!productId) {
      return;
    }

    try {
      const response = await this.materialsService.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        0,
        undefined,
        RecordStateEnum.ACTIVE,
      );

      this.allMaterials = response.content;

      let productAllowedMaterials: any[] = [];

      if (this.isOldMaterialsEnabled) {
        const product = this.allProducts.find((c) => c.id === productId);

        if (product) {
          productAllowedMaterials = product.allowedMaterials;
        }
      } else {
        this.materialAttachments = (await this.itemOverlayService.loadSelectedAttachments(
          AttachmentTypeEnum.MATERIAL,
          AttachmentTargetEnum.PRODUCT,
          productId,
          true,
        )) as IAttachment[];

        for (const attachedMaterial of this.materialAttachments || []) {
          const attachedMaterialId = CommonUtils.getUriId(attachedMaterial.attachmentUri);
          const material = this.allMaterials.find((m) => m.id === attachedMaterialId);

          if (material) {
            productAllowedMaterials.push(material);
          }
        }
      }

      this.productAllowedMaterials = productAllowedMaterials;

      this.materialOptions = this.productAllowedMaterials.map((m) => ({
        label: m.name,
        value: m.id,
      }));
      this.cdr.detectChanges();
      if (!this.isOldMaterialsEnabled) {
        this.formGroup.controls["materials"].setValue(this.materialOptions);
      } else if (!this.data.originalItem && resetFormValue) {
        this.formGroup.controls["materials"].setValue([]);
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isLoading.set(true);
    const payload = this.getSavePayload();

    await this.itemsService
      .createOrUpdate(payload)
      .then(async (response: IItem) => {
        this.notificationService.showSuccess($localize`Item created`);

        response.deliveredQuantity = Number(payload.deliveredQuantity ?? payload.initialQuantity);

        this.onClose(true, response);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false, item: IItem = null): void => {
    this.dialogRef.close({ hasSaved, item });
  };

  private getSavePayload = (): IItemPayload => {
    const { formGroup } = this;
    const { controls } = formGroup;

    let initialQuantity = controls["initialQuantity"].value;
    let deliveredQuantity = controls["deliveredQty"].value;

    if (this.itemDefaultUnitOfMeasurement?.id !== this.unitOfMeasurement?.id) {
      initialQuantity = this.unitConversionPipe.transform(
        initialQuantity,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        true,
      );

      deliveredQuantity = this.unitConversionPipe.transform(
        deliveredQuantity,
        this.itemDefaultUnitOfMeasurement,
        this.unitOfMeasurement,
        true,
      );
    }

    const payload: IItemPayload = {
      id: undefined,
      itemId: controls["itemId"].value.trim(),
      product: `/organisations/${this.activeOrganisationId}/products/${controls["product"].value.value}`,
      materials: this.isOldMaterialsEnabled
        ? controls["materials"].value.map(
            (m: InputSelectOption) =>
              `/organisations/${this.activeOrganisationId}/materials/${m.value}`,
          )
        : [],
      currentLocation: `/organisations/${this.activeOrganisationId}/locations/${controls["currentLocation"].value.value}`,
      createdAtLocation: `/organisations/${this.activeOrganisationId}/locations/${controls["createdAtLocation"].value.value}`,
      initialQuantity,
      deliveredQuantity,
    };

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        payload.created = {
          type: DateTypeEnum.EXACT,
          on: FormUtils.getDateValueForPayload(controls["createdFrom"].value),
        };
        break;
      case DateTypeEnum.RANGE:
        payload.created = {
          type: DateTypeEnum.RANGE,
          start: FormUtils.getDateValueForPayload(controls["createdRange"].value[0]),
          end: FormUtils.getDateValueForPayload(
            this.formGroup.controls["createdRange"].value[1],
            StartEndEnum.END,
          ),
        };
        break;
    }
    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };
}
