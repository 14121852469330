import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { Subscription } from "rxjs";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { IOrganisation, ISelectOption } from "@shared/interfaces";
import { AuthenticationService, OrganisationsService } from "@shared/services";
import { FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-location-overlay-address-checkbox",
  templateUrl: "./location-overlay-address-checkbox.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayAddressCheckboxComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) formGroup: FormGroup;

  @Input({ required: true }) countryOptions: ISelectOption[] = [];

  @Input({ required: true }) allOrganisations: IOrganisation[];

  public readonly sameAsOrganisationAddressText = TextConstants.SAME_AS_ORG_ADDRESS;

  private selectedOrganisationDetails: IOrganisation;

  private activeOrganisationId: string = null;

  private subscriptions = new Subscription();

  constructor(
    protected overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
    private organisationsService: OrganisationsService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
  }

  get formGroupOrganisationId(): number | null {
    return this.formGroup.controls["organisation"]?.value?.value;
  }

  private get isLoggedInUserOrganisation(): boolean {
    return this.formGroup.controls["organisation"].value.value === this.activeOrganisationId;
  }

  public ngAfterViewInit(): void {
    this.subscriptions.add(
      this.formGroup.controls["organisation"].valueChanges.subscribe(async () => {
        await this.setSelectedOrganisationDetails();

        if (!this.selectedOrganisationDetails) {
          this.formGroup.controls["sameAsOrganisationAddress"].setValue(false);
          FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

          return;
        }

        this.setAddressAsOrganisationAddress(
          this.formGroup.controls["sameAsOrganisationAddress"].value,
        );
      }),
    );

    this.subscriptions.add(
      this.formGroup.controls["sameAsOrganisationAddress"].valueChanges.subscribe(
        (checked: boolean) => {
          this.setAddressAsOrganisationAddress(checked);
        },
      ),
    );

    if (this.formGroup.controls["organisation"].value) {
      this.setSelectedOrganisationDetails();
    }
  }

  public setAddressAsOrganisationAddress = async (isSet: boolean): Promise<void> => {
    if (!isSet) {
      FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

      return;
    }

    if (!this.selectedOrganisationDetails) {
      FormUtils.enableControls(this.formGroup, ["street", "region", "zipCode", "country"]);

      return;
    }

    this.formGroup.controls["street"].setValue(this.selectedOrganisationDetails.address.street);
    this.formGroup.controls["region"].setValue(this.selectedOrganisationDetails.address.region);
    this.formGroup.controls["zipCode"].setValue(this.selectedOrganisationDetails.address.zipCode);
    FormUtils.disableControls(this.formGroup, ["street", "region", "zipCode"]);
    const countryValue = this.countryOptions.find(
      (c) => c.value === this.selectedOrganisationDetails.address.country,
    );

    if (countryValue) {
      this.formGroup.controls["country"].setValue(countryValue);
      this.formGroup.controls["country"].disable();
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async setSelectedOrganisationDetails(): Promise<void> {
    const organisationId = this.formGroup.controls["organisation"].value?.value;

    if (!organisationId) {
      this.selectedOrganisationDetails = null;

      return;
    }

    if (this.isLoggedInUserOrganisation) {
      this.selectedOrganisationDetails = await this.organisationsService.get(
        this.activeOrganisationId,
      );
    } else {
      this.selectedOrganisationDetails = this.allOrganisations.find((o) => o.id === organisationId);
    }

    const countryValue = this.countryOptions.find(
      (c) => c.value === this.selectedOrganisationDetails.address.country,
    );

    if (countryValue) {
      this.selectedOrganisationDetails.address.countryName = countryValue.label;
    }
  }
}
