import moment from "moment";

import { CommonConstants } from "@shared/constants";
import { LanguageEnum } from "@shared/enums";

export const getLanguageFromBrowserLocale = (): string => {
  const browserLocale = getBrowserLocale();

  return browserLocale
    .substring(0, browserLocale.indexOf("-") !== -1 ? browserLocale.indexOf("-") : undefined)
    .toLowerCase();
};

export const getBrowserLocale = (): string => navigator.language || navigator["userLanguage"];

export const getLocaleForAngularConfig = (): string => {
  let localeId = CommonConstants.DEFAULT_LANGUAGE_LOCALE_ID;
  const browserLanguage = LocalizationUtils.getLanguageFromBrowserLocale();

  //todo check for featureFlagLanguages here too.
  if (CommonConstants.AVAILABLE_LANGUAGE_CODES.includes(browserLanguage as LanguageEnum)) {
    localeId = LocalizationUtils.getBrowserLocale();
  }

  return localeId;
};

export const getLocaleDatepickerFormat = (): string =>
  moment.localeData(getLocaleForAngularConfig()).longDateFormat("L");

export const LocalizationUtils = {
  getLanguageFromBrowserLocale,
  getBrowserLocale,
  getLocaleForAngularConfig,
  getLocaleDatepickerFormat,
};
