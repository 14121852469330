import { FormControl } from "@angular/forms";

export interface BulkAddFixedField {
  label: string;
  value: string;
}

export enum BulkAddCustomFieldEnum {
  CUSTOM_FIELD_ID = "customFieldId",
  CUSTOM_FIELD_VALUE = "customFieldValue",
  CUSTOM_FIELD_LABEL = "customFieldLabel",
  IS_FIXED_CUSTOM_FIELD = "isFixedCustomField",
}

export interface BulkAddCustomFieldFormGroup {
  [BulkAddCustomFieldEnum.CUSTOM_FIELD_ID]: FormControl<string>;
  [BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE]: FormControl<string>;
  [BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL]: FormControl<string>;
  [BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD]?: FormControl<boolean>;
}
