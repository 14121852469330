import { ChangeDetectionStrategy, Component, input } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-empty-search-results",
  templateUrl: "./empty-search-results.component.html",
  styleUrls: ["./empty-search-results.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptySearchResultsComponent {
  imageSrc = input<string>("/assets/images/no-results-found.svg");

  message = input<string>($localize`No records found`);
}
