import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from "@angular/core";

import {
  IDeliveryDetails,
  IDeliveryExtended,
  IItemSupplyChain,
  ILocationDetails,
  ILocationExtended,
  ILocationLinkBase,
} from "src/app/shared/interfaces";

import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { GeographicalSupplyChainMap } from "@components/shared/map/geographical-supply-map";
import { IGeographicalMapLocation } from "@components/shared/map/supply-chain-map.model";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-items-supply-chain-map",
  templateUrl: "./items-supply-chain-map.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsSupplyChainMapComponent implements OnInit {
  @Input() public itemIds: string[];

  @Input() public delivery: IDeliveryDetails;

  @Input() public isZoomPromptEnabled: boolean = false;

  public supplyChainItems: IItemSupplyChain[];

  public geographicalSupplyChainMap = signal<GeographicalSupplyChainMap>(null);

  constructor(
    private itemSupplyChainMapperService: ItemSupplyChainMapperService,
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    try {
      this.setProperties();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private async setProperties(): Promise<void> {
    let locations: IGeographicalMapLocation[] = [];
    let links: ILocationLinkBase[] = [];

    if (this.delivery && !this.itemIds?.length) {
      locations = [
        this.convertLocation(this.delivery.from),
        this.convertLocation(this.delivery.to),
      ];
      links = [this.convertDeliveryToLink(this.delivery)];
    } else {
      this.supplyChainItems = await this.itemSupplyChainMapperService.mapItemSupplyChain(
        this.itemIds,
      );

      locations = this.getLocations();
      links = this.getLinks();
    }

    this.geographicalSupplyChainMap.set(new GeographicalSupplyChainMap(locations, links));
  }

  private getLocations(): IGeographicalMapLocation[] {
    return this.supplyChainItems
      .flatMap((item) => item.locations)
      .map((location: ILocationExtended) => this.convertLocation(location))
      .reduce((acc: IGeographicalMapLocation[], curr: IGeographicalMapLocation) => {
        if (!acc.find((location) => location.id === curr.id)) {
          acc.push(curr);
        }

        return acc;
      }, []);
  }

  private getLinks(): ILocationLinkBase[] {
    return this.supplyChainItems
      .flatMap((item) => item.deliveries || [])
      .map((delivery) => this.convertDeliveryToLink(delivery))
      .reduce((acc: ILocationLinkBase[], curr: ILocationLinkBase) => {
        if (!acc.find((link) => link.from === curr.from && link.to === curr.to)) {
          acc.push(curr);
        }

        return acc;
      }, []);
  }

  private convertLocation(
    location: ILocationExtended | ILocationDetails,
  ): IGeographicalMapLocation {
    return {
      id: location.id,
      name: location.name,
      geoLocation: location.geoLocation,
      address: {
        country: location.address?.country,
      },
    };
  }

  private convertDeliveryToLink(delivery: IDeliveryExtended | IDeliveryDetails): ILocationLinkBase {
    return {
      from: delivery.from.id,
      to: delivery.to.id,
    };
  }
}
