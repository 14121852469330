import { computed, inject, Injectable, OnDestroy, signal } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { lastValueFrom, Observable, Subject, Subscription } from "rxjs";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddSetValuesModel } from "@components/items/bulk-add-items/set-values/bulk-add-set-values.model";
import { ConfirmDialogComponent } from "@components/shared";
import { BulkAddBaseService } from "@components/shared/bulk-add/bulk-add-base.service";
import { BulkAddSlideOverModel } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.model";
import { BulkAddSlideOverService } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.service";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  DateTypeEnum,
  RecordStateEnum,
} from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IDelivery,
  IItem,
  IMaterial,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import {
  NotificationService,
  CommonService,
  DeliveriesService,
  MaterialsService,
  ProductsService,
  UnitsOfMeasurementService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Injectable({
  providedIn: "root",
})
export class BulkAddItemsService extends BulkAddBaseService implements OnDestroy {
  private productsService: ProductsService = inject(ProductsService);

  private unitsOfMeasurementsService: UnitsOfMeasurementService = inject(UnitsOfMeasurementService);

  private materialsService: MaterialsService = inject(MaterialsService);

  private unitConversionPipe: UnitConversionPipe = inject(UnitConversionPipe);

  private commonService: CommonService = inject(CommonService);

  private dialog: MatDialog = inject(MatDialog);

  private notificationService: NotificationService = inject(NotificationService);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  private route: ActivatedRoute = inject(ActivatedRoute);

  private readonly routerService: RouterService = inject(RouterService);

  private bulkAddSlideOverService: BulkAddSlideOverService = inject(BulkAddSlideOverService);

  public refreshTableSubject = new Subject();

  public abortController: AbortController;

  public allProducts: IProduct[] = [];

  public allMaterials: IMaterial[] = [];

  public materialOptionsForSetValuesStep: ISelectOption[] = [];

  public customUnitOfMeasurements: ICustomUnitOfMeasurement[] = [];

  public baseUnitOfMeasurements: IBaseUnit[] = [];

  public initialFormValue: object;

  public override formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  public delivery = signal<IDelivery>(null);

  public isToBeAttachedToProcessInput = signal<boolean>(false);

  public noRouteDependantMode = computed(() => this.isToBeAttachedToProcessInput());

  private readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  public resultSubject: Subject<{ newRecords: IItem[] }> = new Subject();

  constructor() {
    super();

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (baseUnitOfMeasurements: IBaseUnit[]) => {
          this.baseUnitOfMeasurements = baseUnitOfMeasurements;
        },
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async initializeProperties(): Promise<void> {
    this.delivery.set(null);
    this.isNextButtonDisabled.set(false);
    this.abortController = new AbortController();
    this.materialOptionsForSetValuesStep = [];

    await this.setAllCustomFields();
  }

  get result$(): Observable<{ newRecords: IItem[] }> {
    return this.resultSubject.asObservable();
  }

  public async openSlideOver(params?: BulkAddItemsModel.SlideOverParams): Promise<void> {
    if (params?.isToBeAttachedToProcessInput) {
      this.isToBeAttachedToProcessInput.set(true);
      this.bulkAddSlideOverService.toggle(BulkAddSlideOverModel.ResourceTypeEnum.ITEMS);

      return;
    }

    this.isToBeAttachedToProcessInput.set(false);

    if (params?.deliveryId) {
      await this.routerService.navigate(
        this.routerService.getBulkAddItemsLink(true, { deliveryId: params.deliveryId }),
      );

      return;
    }

    await this.routerService.navigate(this.routerService.getBulkAddItemsLink());
  }

  private async setAllCustomFields(): Promise<void> {
    const customFields = await this.customFieldsService.getAll(CustomFieldsResourceTypeEnum.ITEM);

    this.allCustomFields.set(customFields);
  }

  public setupForm(): void {
    const { fieldEnum } = this;

    const customFieldsFormArray = this.createCustomFieldsFormArray(this.allCustomFields());

    this.formGroup = new FormGroup<BulkAddItemsModel.SetValuesFormGroup>({
      mode: new FormControl(BulkAddSetValuesModel.defaultMode),
      [fieldEnum.TAGS]: new FormControl([]),
      [fieldEnum.PRODUCT]: new FormControl(),
      [fieldEnum.MATERIALS]: new FormControl({ value: [], disabled: true }),
      [fieldEnum.INITIAL_QUANTITY]: new FormControl({ value: null, disabled: true }, [
        CustomValidators.greaterThan(0, true),
      ]),
      [fieldEnum.UNIT_OF_MEASUREMENT]: new FormControl({ value: null, disabled: true }),
      [fieldEnum.CREATED_AT_LOCATION]: new FormControl(),
      [fieldEnum.CREATED_FROM]: new FormControl(null, [CustomValidators.date]),
      [fieldEnum.CREATED_RANGE]: new FormControl([null, null], [CustomValidators.dateRange]),
      [fieldEnum.IS_RANGE_DATE_TYPE]: new FormControl(false),
      [fieldEnum.DATE_TYPE]: new FormControl(DateTypeEnum.EXACT),
      [fieldEnum.CURRENT_LOCATION]: new FormControl(),
      [fieldEnum.BASE_INITIAL_QUANTITY]: new FormControl(),
      [fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS]: new FormControl([]),
      [fieldEnum.IS_FIXED_TAGS]: new FormControl(false),
      [fieldEnum.IS_FIXED_PRODUCT]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_MATERIALS]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_INITIAL_QUANTITY]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CREATED_AT_LOCATION]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CREATION_DATE]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_FIXED_CURRENT_LOCATION]: new FormControl({ value: false, disabled: true }),
      [fieldEnum.IS_SAME_AS_CREATED_AT_LOCATION]: new FormControl(false),
      [fieldEnum.CUSTOM_FIELDS]: customFieldsFormArray,
      [fieldEnum.RECORDS]: new FormArray([]),
    });

    this.initialFormValue = this.formGroup.getRawValue();

    this.subscribeToPendingFormStatusChanges();
    this.subscribeToRecordsLengthChanges();
  }

  public get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  public async setDelivery(): Promise<void> {
    const deliveryId = this.route.snapshot.queryParams["deliveryId"];

    if (deliveryId) {
      this.delivery.set(await this.deliveriesService.get(deliveryId));
    }
  }

  public async getCustomUnitOfMeasurements(): Promise<ICustomUnitOfMeasurement[]> {
    return (this.customUnitOfMeasurements = await this.unitsOfMeasurementsService.getAll());
  }

  public async getAllProducts(): Promise<IProduct[]> {
    try {
      return (this.allProducts = await this.productsService.getAll(RecordStateEnum.ACTIVE));
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  }

  public async getAllMaterials(): Promise<IMaterial[]> {
    try {
      return (this.allMaterials = await this.materialsService.getAll(RecordStateEnum.ACTIVE));
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  }

  public abortCreation(): void {
    this.abortController.abort();
  }

  public hasInitialFormValueChanged(currentFormValue: object): boolean {
    return FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue);
  }

  public async canExitSlideOver(): Promise<boolean> {
    const hasChanged = this.hasInitialFormValueChanged(this.formGroup.getRawValue());

    if (!hasChanged) {
      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.EXIT_CONFIRMATION,
        contentText: $localize`You're about to leave this page. Are you sure you want to discard your changes?`,
        confirmButtonText: TextConstants.DISCARD_CHANGES_LEAVE,
        confirmButtonColor: "danger",
        confirmButtonIcon: "close",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    return response === ConfirmDialogResponseEnum.CONFIRM;
  }

  private buildQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    quantityField: string,
    baseQuantityField: string,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[quantityField].valueChanges.subscribe((quantity) => {
      const productId = controls[fieldEnum.PRODUCT].value?.value;

      if (!productId) {
        return;
      }

      const product = this.allProducts.find((product) => product.id === productId);
      const baseUnitId = CommonUtils.getUriId(product.baseUnit);
      const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);
      const unitOfMeasurementOption = controls[fieldEnum.UNIT_OF_MEASUREMENT].value;

      if (!unitOfMeasurementOption) {
        return;
      }

      if (baseUnit.id !== unitOfMeasurementOption.value) {
        const selectedUnitOfMeasurement = [
          ...this.baseUnitOfMeasurements,
          ...this.customUnitOfMeasurements,
        ].find((unit) => unit.id === unitOfMeasurementOption.value);

        const value = +this.unitConversionPipe.transform(
          `${quantity}`,
          selectedUnitOfMeasurement,
          baseUnit,
          true,
        );

        controls[baseQuantityField].setValue(value, { emitEvent: false });
      } else {
        controls[baseQuantityField].setValue(quantity ? +quantity : null, {
          emitEvent: false,
        });
      }
    });
  }

  public buildDeliveryQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
  ): Subscription {
    return this.buildQuantitySubscription(
      formGroup,
      this.fieldEnum.DELIVERY_QUANTITY,
      this.fieldEnum.BASE_DELIVERY_QUANTITY,
    );
  }

  public buildProcessInputQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
  ): Subscription {
    return this.buildQuantitySubscription(
      formGroup,
      this.fieldEnum.PROCESS_INPUT_QUANTITY,
      this.fieldEnum.BASE_PROCESS_INPUT_QUANTITY,
    );
  }

  public buildInitialQuantitySubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[fieldEnum.INITIAL_QUANTITY].valueChanges.subscribe((initialQuantity) => {
      const productId = controls[fieldEnum.PRODUCT].value?.value;

      if (!productId) {
        return;
      }

      if (initialQuantity === null || String(initialQuantity) === "") {
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.setValue(false);
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.disable();
      } else {
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.enable();
        controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY]?.setValue(true);
      }

      const product = this.allProducts.find((product) => product.id === productId);

      const baseUnitId = CommonUtils.getUriId(product.baseUnit);
      const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

      const unitOfMeasurementOption = controls[fieldEnum.UNIT_OF_MEASUREMENT].value;

      if (!unitOfMeasurementOption) {
        return;
      }

      if (baseUnit.id !== unitOfMeasurementOption.value) {
        const selectedUnitOfMeasurement = [
          ...this.baseUnitOfMeasurements,
          ...this.customUnitOfMeasurements,
        ].find((unit) => {
          return unit.id === unitOfMeasurementOption.value;
        });

        const value = +this.unitConversionPipe.transform(
          `${initialQuantity}`,
          selectedUnitOfMeasurement,
          baseUnit,
          true,
        );

        controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(value, { emitEvent: false });
      } else {
        controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(
          initialQuantity ? +initialQuantity : null,
          {
            emitEvent: false,
          },
        );
      }
    });
  }

  private transformUnitValue(
    value: number,
    selectedUnitOfMeasurement: IBaseUnit,
    baseUnit: IBaseUnit,
  ) {
    return +this.unitConversionPipe.transform(
      `${value}`,
      selectedUnitOfMeasurement,
      baseUnit,
      true,
      false,
      true,
    );
  }

  private handleUnitConversion(
    controls: BulkAddItemsModel.IItemFieldSharedFormGroup,
    selectedUnitOfMeasurement: IBaseUnit | ICustomUnitOfMeasurement,
    baseUnit: IBaseUnit,
  ): void {
    const { fieldEnum } = this;
    const setValueOptions = { emitEvent: false };

    const quantityMappings = [
      {
        baseValue: controls[fieldEnum.BASE_INITIAL_QUANTITY].value,
        baseField: fieldEnum.BASE_INITIAL_QUANTITY,
        targetField: fieldEnum.INITIAL_QUANTITY,
      },
      {
        baseValue: controls[fieldEnum.BASE_DELIVERY_QUANTITY]?.value,
        baseField: fieldEnum.BASE_DELIVERY_QUANTITY,
        targetField: fieldEnum.DELIVERY_QUANTITY,
      },
      {
        baseValue: controls[fieldEnum.BASE_PROCESS_INPUT_QUANTITY]?.value,
        baseField: fieldEnum.BASE_PROCESS_INPUT_QUANTITY,
        targetField: fieldEnum.PROCESS_INPUT_QUANTITY,
      },
    ];

    quantityMappings.forEach(({ baseValue, baseField, targetField }) => {
      if (!controls[baseField]) {
        return;
      }

      const transformedValue = this.transformUnitValue(
        baseValue,
        selectedUnitOfMeasurement,
        baseUnit,
      );

      if (transformedValue || targetField === fieldEnum.INITIAL_QUANTITY) {
        controls[targetField].setValue(transformedValue, setValueOptions);
      }
    });
  }

  public buildUnitOfMeasurementOptionsSubscription(
    formGroup: FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    onAfter?: any,
  ): Subscription {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    return controls[fieldEnum.UNIT_OF_MEASUREMENT].valueChanges.subscribe(
      (unitOfMeasurementOption) => {
        if (!unitOfMeasurementOption) {
          controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.setValue(false);
          controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.disable();

          return;
        }

        const productId = controls[fieldEnum.PRODUCT].value?.value;

        if (!productId) {
          return;
        }

        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.enable();
        controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]?.setValue(true);

        const product = this.allProducts.find((product) => product.id === productId);
        const baseUnitId = CommonUtils.getUriId(product.baseUnit);
        const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);
        const setValueOptions = { emitEvent: false };

        if (baseUnit.id === unitOfMeasurementOption.value) {
          controls[fieldEnum.INITIAL_QUANTITY].setValue(
            controls[fieldEnum.BASE_INITIAL_QUANTITY].value,
            setValueOptions,
          );

          controls[fieldEnum.DELIVERY_QUANTITY]?.setValue(
            controls[fieldEnum.BASE_DELIVERY_QUANTITY].value,
            setValueOptions,
          );

          controls[fieldEnum.PROCESS_INPUT_QUANTITY]?.setValue(
            controls[fieldEnum.BASE_PROCESS_INPUT_QUANTITY].value,
            setValueOptions,
          );
        } else {
          const selectedUnitOfMeasurement = [
            ...this.baseUnitOfMeasurements,
            ...this.customUnitOfMeasurements,
          ].find((unit) => unit.id === unitOfMeasurementOption.value);

          this.handleUnitConversion(controls, selectedUnitOfMeasurement, baseUnit);
        }

        if (onAfter) {
          onAfter();
        }
      },
    );
  }
}
