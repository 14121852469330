import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { UnitOfMeasurementCategoryTypeEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  ISelectOption,
  IUnitOfMeasurementPayload,
} from "src/app/shared/interfaces";
import { UnitsOfMeasurementService } from "src/app/shared/services";
import { BrowserUtils, CommonUtils, FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./edit-custom-unit-dialog.component.html",
  styleUrl: "./edit-custom-unit-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditCustomUnitDialogComponent implements OnInit {
  public readonly isSafari = BrowserUtils.isSafari();

  private destroyRef = inject(DestroyRef);

  private entityExistsValidatorArgs: any = {
    searchPropertyName: "name",
    searchPropertyErrorDisplayName: "Name",
  };

  private symbolExistsValidatorArgs: any = {
    searchPropertyName: "symbol",
    searchPropertyErrorDisplayName: "Symbol",
  };

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    category: new UntypedFormControl(null, [CustomValidators.required]),
    name: new UntypedFormControl(
      null,
      [CustomValidators.required],
      [
        CustomValidators.entityAlreadyExists(
          this.unitsOfMeasurementService,
          this.data?.customUnit?.id,
          this.entityExistsValidatorArgs,
        ),
      ],
    ),
    displayScale: new UntypedFormControl(null, {
      validators: [
        CustomValidators.required,
        FormUtils.combinedValidators([
          CustomValidators.required,
          CustomValidators.rangeValidator(0, 10),
          CustomValidators.integer(),
        ]),
      ],
    }),
    symbol: new UntypedFormControl(
      null,
      [CustomValidators.required, CustomValidators.maxLength(4)],
      [
        CustomValidators.entityAlreadyExists(
          this.unitsOfMeasurementService,
          this.data?.customUnit?.id,
          this.symbolExistsValidatorArgs,
        ),
      ],
    ),
    conversionFactor: new UntypedFormControl(null, [
      CustomValidators.required,
      CustomValidators.min(0),
    ]),
  });

  public isLoading = signal(true);

  public isEditing = signal(false);

  public unitTypesOptions: InputSelectOption[];

  public unitTypesEnum = UnitOfMeasurementCategoryTypeEnum;

  public baseUnits: IBaseUnit[];

  public readonly translations: any = {
    displayScaleTooltip: $localize`Determines how many digits after the decimal point will be displayed on quantities
                    that are measured in this unit of measurement. E.g. if you state 2, the value will be
                    rounded to the first 2 decimals: e.g. 5.716 will be rounded to 5.72.`,
    conversionFactorLabel: $localize`Conversion factor`,
    conversionFactorPh: $localize`Conversion factor`,
    categoryFirtPh: $localize`Select category first`,
    displayScaleLabel: $localize`Display scale`,
    nameLabel: TextConstants.NAME,
    categoryLabel: TextConstants.CATEGORY,
    symbolLabel: $localize`Symbol`,
  };

  constructor(
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private _dialogRef: MatDialogRef<EditCustomUnitDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { customUnit: ICustomUnitOfMeasurement; type: UnitOfMeasurementCategoryTypeEnum },
    private notificationService: NotificationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.customUnit);
    this.baseUnits = await this.unitsOfMeasurementService.getSystemUnits();
    const unitTypes = await this.unitsOfMeasurementService.getUnitTypes();

    this.unitTypesOptions = unitTypes.map((unit) => ({
      label: CommonUtils.capitaliseFirstLetter(unit),
      value: unit,
    }));
    if (this.isEditing()) {
      const { name, symbol, conversionFactor, displayScale } = this.data.customUnit;
      const categoryValue: ISelectOption = {
        label: this.data?.customUnit?.type,
        value: this.data?.customUnit?.type,
      };

      this.categoryControl.setValue(categoryValue);
      this.categoryControl.disable();
      this.nameControl.setValue(name);
      this.symbolControl.setValue(symbol);
      this.conversionFactorControl.setValue(conversionFactor);
      this.displayScaleControl.setValue(displayScale);
      if (categoryValue.value === UnitOfMeasurementCategoryTypeEnum.UNITS) {
        this.conversionFactorControl.disable();
        this.displayScaleControl.disable();
      }
    }

    if (this.data?.type) {
      const categoryValue: ISelectOption = {
        label: this.data?.type,
        value: this.data?.type,
      };

      this.categoryControl.setValue(categoryValue);
      this.categoryControl.disable();
    }

    this.categoryControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: InputSelectOption) => {
        if (value.value === this.unitTypesEnum.UNITS) {
          this.conversionFactorControl.setValue(1);
          this.displayScaleControl.setValue(0);
        }
      });
    this.isLoading.set(false);
  }

  async onSubmit() {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    try {
      this.isLoading.set(true);
      const formValue = this.formGroup.getRawValue();
      const baseUnit = this.baseUnits.find((unit) => unit.type === formValue?.category?.value);
      const payload: IUnitOfMeasurementPayload = {
        name: formValue.name,
        symbol: formValue.symbol,
        displayScale: formValue.displayScale,
        baseUnit: `/common/base-units/${baseUnit.id}`,
        conversionFactor: formValue.conversionFactor,
      };

      await this.unitsOfMeasurementService.createOrUpdate(payload, this.data?.customUnit?.id);
      this.notificationService.showSuccess(
        this.isEditing()
          ? $localize`Unit of measurement modified`
          : $localize`Unit of measurement created`,
      );
      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.pending;
  };

  onClose(hasSaved = false) {
    this._dialogRef.close({ hasSaved });
  }

  get categoryControl() {
    return this.formGroup.controls["category"];
  }

  get conversionFactorControl() {
    return this.formGroup.controls["conversionFactor"];
  }

  get displayScaleControl() {
    return this.formGroup.controls["displayScale"];
  }

  get nameControl() {
    return this.formGroup.controls["name"];
  }

  get symbolControl() {
    return this.formGroup.controls["symbol"];
  }
}
