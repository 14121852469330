<form [formGroup]="form">
  @if (data.title) {
    <h2 mat-dialog-title>{{ data.title }}</h2>
  }
  <mat-dialog-content>
    <app-input-chips
      #inputChips
      (tagCreatedEvent)="isNewTagCreated = true"
      [allowDeleteTag]="data.canDelete"
      [allowCreateTag]="true"
      [label]="data.placeholder"
      [tagUrl]="data.tagUrl"
      formControlName="tags"
      [hint]="translations.tagsHint"
      [tooltip]="translations.tagsTooltip"
    ></app-input-chips>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-button-group>
      <app-button (pressed)="close()" type="ghost" i18n>Cancel</app-button>
      <app-button (pressed)="save()" [disabled]="form.invalid">
        <mat-icon icon-left>check</mat-icon>
        <ng-container i18n>Save</ng-container>
      </app-button>
    </app-button-group>
  </mat-dialog-actions>
</form>
