<app-auto-open-menu [isCollapsed]="isCollapsed()">
  <div trigger>
    <a
      class="sidebar-item container-flex-space-between"
      [class]="class"
      [class.active]="active"
      mat-list-item
    >
      @if (groupItem.isSvgIcon) {
        <mat-icon [svgIcon]="groupItem.icon" />
      } @else {
        <mat-icon>{{ groupItem.icon }}</mat-icon>
      }

      <span class="menu-title">{{ groupItem.title }}</span>
      <div class="vertical-dots flex-column" [class.is-collapsed]="isCollapsed()">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </a>
  </div>
  <div content>
    <div class="group-menu-title">
      {{ groupItem.title }}
    </div>
    <div class="divider"></div>
    @for (menuItem of groupItem.menuItems; track menuItem.title) {
      <app-sidebar-item
        class="less-padding"
        [menuItem]="menuItem"
        [active]="isActiveRoute(menuItem)"
      >
        <span class="menu-title">{{ menuItem.title }}</span>
      </app-sidebar-item>
    }
  </div>
</app-auto-open-menu>
