import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import * as Sentry from "@sentry/angular";

import { IConfig, IUserData } from "@shared/interfaces";
import { APP_CONFIG } from "@shared/tokens";

@Injectable({
  providedIn: "root",
})
export class ErrorReportingService {
  constructor(@Inject(APP_CONFIG) private environment: IConfig) {}

  captureException(err: any) {
    if (this.environment.sentryDsn) {
      Sentry.captureException(this.getError(err));
    } else {
      console.log(err);
    }
  }

  public setUserContext(user: IUserData | null): void {
    if (user) {
      const { activeOrganisationIndex, availableOrganisations } = user;
      const activeOrganisation = availableOrganisations[activeOrganisationIndex];

      Sentry.setUser({
        id: user.id,
        email: user.email,
        type: user.type,
        activeOrganisationId: activeOrganisation?.id,
        activeOrganisationName: activeOrganisation?.name,
        roles: activeOrganisation?.roles?.length ? activeOrganisation.roles.join(", ") : undefined,
      });
    } else {
      Sentry.setUser(null);
    }
  }

  private getError(err: any): Error | HttpErrorResponse {
    if (err instanceof Error) {
      return err;
    }
    const newErr = new Error(err.message || "unexpected");

    for (const [key, value] of Object.entries(err)) {
      newErr[key] = value;
    }

    return newErr;
  }
}
