import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { OverlayRiskAssessmentReportsComponent } from "@components/shared/risk-assessment-reports/ui";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TextConstants } from "@shared/constants";
import {
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RouteEnum,
} from "@shared/enums";
import {
  IMaterialDetails,
  IMaterialPayload,
  ISelectOption,
  IRecordState,
} from "@shared/interfaces";
import { MaterialsService } from "@shared/services";
import { FormUtils, CustomFieldsUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { MaterialOverlayService } from "./material-overlay.service";

@Component({
  standalone: false,
  selector: "app-material-overlay",
  templateUrl: "./material-overlay.component.html",
  styleUrl: "./material-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class MaterialOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("riskAssessmentsView")
  readonly riskAssessmentsView: OverlayRiskAssessmentReportsComponent;

  public formGroup: UntypedFormGroup;

  public categoriesOptions: ISelectOption[] = [];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.MATERIAL_DETAILS, isEnabled: true }],
      [
        OverlayTabEnum.RISK_ASSESSMENT_REPORTS,
        {
          title: TextConstants.RISK_ASSESSMENT_REPORTS,
          isEnabled: false,
          isHidden: !this.isRegularUser,
        },
      ],
    ]),
  );

  override element: IMaterialDetails;

  override entityType = EntityTypeEnum.MATERIALS;

  readonly templateResourceType = RiskAssessmentTemplateResourceType.MATERIAL;

  public readonly translations: any = {
    categoryHint: TextConstants.CATEGORY_HINT,
    categoryLabel: TextConstants.CATEGORY,
    nameLabel: TextConstants.NAME,
  };

  constructor(
    public materialOverlay: MaterialOverlayService,
    private materialsService: MaterialsService,
  ) {
    super();
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_MATERIAL)) {
      return;
    }

    await Promise.all([
      this.reloadCategories(),
      this.setAllCustomFields(CustomFieldsResourceTypeEnum.MATERIAL),
    ]);

    if (this.recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    } else {
      this.setupForm();
      this.overlay.dismissLoading();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  loadCounters(): void {
    if (this.recordId) {
      if (this.isRegularUser) {
        this.materialOverlay.loadReportsCounter(this.entityUri);
      }
    }
  }

  setCountersToLoadingState() {
    this.materialOverlay.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.materialOverlay.setCountersEmptyState();
  }

  public override setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.element?.name ?? null,
        [CustomValidators.required],
        [CustomValidators.entityAlreadyExists(this.materialsService, this.element?.id ?? null)],
      ),
      category: new UntypedFormControl(
        this.isEditing()
          ? {
              label: this.element?.category,
              value: this.element?.category,
            }
          : null,
        [CustomValidators.required],
      ),
    });

    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;

    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  };

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.materialsService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.materialsService.delete(id);
  }

  private reloadCategories = async (): Promise<void> => {
    await this.materialsService
      .getAllCategories()
      .then((response: string[]) => {
        this.categoriesOptions = response.map((c) => ({
          label: c,
          value: c,
        }));
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  protected override reloadElement = async (id: string): Promise<void> => {
    this.overlay.showLoading();
    try {
      this.element = await this.materialsService.get(id);
      this.setEditMode();
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return (
      !this.formGroup ||
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !this.hasFormValuesChanged
    );
  }

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    const payload = this.getSavePayload();

    try {
      this.element = await this.materialsService.createOrUpdate(payload, this.element?.id);
      this.hasFormValuesChanged = false;
      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Material modified` : $localize`Material created`,
      );

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(this.routerService.getMaterialLink(this.element.id), {
        replaceUrl: true,
      });
    }
  }

  private getSavePayload = (): IMaterialPayload => {
    const payload: IMaterialPayload = {
      id: this.isEditing() ? this.element.id : undefined,
      name: this.formGroup.controls["name"].value.trim(),
      category: this.formGroup.controls["category"].value.label.trim(),
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };
}
