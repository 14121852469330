import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

import {
  ICustomField,
  IMaterial,
  IMaterialPayload,
  IProduct,
  IProductExtended,
  ISelectOption,
} from "src/app/shared/interfaces";
import {
  AuthenticationService,
  CustomFieldsService,
  MaterialsService,
} from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { AddMaterialDialogModel as Model } from "@components/materials/add-material-dialog/add-material-dialog.model";
import { ConfirmDialogComponent } from "@components/shared";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, CustomFieldsResourceTypeEnum } from "@shared/enums";
import { NotificationService } from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./add-material-dialog.component.html",
  styleUrls: ["./add-material-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMaterialDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public isLoading = signal(true);

  public allowModeChange: boolean = false;

  public saveButtonText: string = "Save";

  public activeOrganisationId: string;

  public productTarget: IProduct;

  private allCustomFields: ICustomField[] = [];

  public visibleCustomFields: ICustomField[] = [];

  public selectableMaterials: IMaterial[] = [];

  public categoriesOptions: ISelectOption[] = [];

  public materialOptions: ISelectOption[] = [];

  public mode: Model.ModeEnum = Model.defaultMode;

  public readonly modeEnum = Model.ModeEnum;

  public readonly translations: any = {
    categoryHint: TextConstants.CATEGORY_HINT,
    categoryLabel: TextConstants.CATEGORY,
    nameLabel: TextConstants.NAME,
    materialLabel: $localize`Material`,
    addNewLabel: TextConstants.ADD_NEW,
    addExistingLabel: $localize`Add existing`,
  };

  constructor(
    public dialogRef: MatDialogRef<AddMaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      saveButtonText?: string;
      allowModeChange?: boolean;
      productTarget?: IProduct;
      selectableMaterials: IMaterial[];
      isOldMaterialsEnabled?: boolean;
      material?: IMaterial;
    },
    private materialsService: MaterialsService,
    private notificationService: NotificationService,
    private customFieldService: CustomFieldsService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    this.saveButtonText = this.data?.saveButtonText ?? "Save";
    this.productTarget = this.data?.productTarget;
    this.allowModeChange = this.data?.allowModeChange ?? false;
    this.selectableMaterials = this.data?.selectableMaterials || [];

    this.materialOptions = this.selectableMaterials.map((material) => ({
      label: material.name,
      value: material.id,
    }));
  }

  public async ngOnInit(): Promise<void> {
    await Promise.all([
      (this.allCustomFields = await this.customFieldService.getAll(
        CustomFieldsResourceTypeEnum.MATERIAL,
      )),
      await this.reloadCategories(),
    ]);
    this.setForm();
  }

  private setForm(): void {
    const currentCategory = this.data?.material?.category;
    let currentCategoryOption: ISelectOption = null;

    if (currentCategory) {
      currentCategoryOption = {
        label: currentCategory,
        value: currentCategory,
      };
      this.categoriesOptions.push(currentCategoryOption);
    }
    if (this.mode === Model.ModeEnum.ADD_NEW) {
      this.formGroup = new UntypedFormGroup({
        name: new UntypedFormControl(
          this.data?.material?.name ?? null,
          [CustomValidators.required],
          [CustomValidators.entityAlreadyExists(this.materialsService, null)],
        ),
        category: new UntypedFormControl(currentCategoryOption ?? null, [
          CustomValidators.required,
        ]),
      });

      this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, []);
      CustomFieldsUtils.addToFormGroup(
        this.formGroup,
        this.visibleCustomFields,
        this.data?.material?.customFields ?? [],
      );
    } else if (this.mode === Model.ModeEnum.ADD_EXISTING) {
      this.formGroup = new UntypedFormGroup({
        existingMaterial: new UntypedFormControl(null, [CustomValidators.required]),
      });
    }
  }

  public onChangeType(mode: Model.ModeEnum): void {
    this.mode = mode;
    this.setForm();
  }

  private reloadCategories = async (): Promise<void> => {
    this.isLoading.set(true);
    await this.materialsService
      .getAllCategories()
      .then((response: string[]) => {
        this.categoriesOptions = response.map((c) => ({
          label: c,
          value: c,
        }));
        this.isLoading.set(false);
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  public get isSubmitButtonDisabled(): boolean {
    return false;
  }

  public async onSubmit(): Promise<void> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    if (this.mode === this.modeEnum.ADD_NEW) {
      await this.submitNewMaterial();
    } else {
      await this.submitExistingMaterial();
    }
  }

  private async submitNewMaterial(): Promise<void> {
    this.isLoading.set(true);

    try {
      const payload: IMaterialPayload = {
        id: undefined,
        name: this.formGroup.controls["name"].value.trim(),
        category: this.formGroup.controls["category"].value.label.trim(),
      };

      CustomFieldsUtils.addToPayload(
        payload,
        this.activeOrganisationId,
        this.formGroup,
        this.visibleCustomFields,
      );

      const material = await this.materialsService.createOrUpdate(payload);

      this.notificationService.showSuccess($localize`Material created`);
      this.onClose(true, material);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async submitExistingMaterial(): Promise<void> {
    const material = this.selectableMaterials.find(
      (material) => material.id === this.formGroup.controls["existingMaterial"].value.value,
    );
    //TODO: Temporal fix. Change this when we use IProductExtended with this dialog.
    let isMaterialAlreadyAllowed: boolean;

    if (this.data?.isOldMaterialsEnabled) {
      isMaterialAlreadyAllowed = this.productTarget.allowedMaterials.some((materialItem: any) => {
        if (typeof materialItem === "string") {
          return CommonUtils.getUriId(materialItem) === material.id;
        } else if (typeof materialItem === "object" && materialItem !== null) {
          return materialItem.id === material.id;
        }

        return false;
      });
    } else {
      isMaterialAlreadyAllowed = (this.productTarget as any as IProductExtended).materials.some(
        (m) => m.id === material.id,
      );
    }
    if (isMaterialAlreadyAllowed) {
      this.onClose(true, material);

      return;
    }
    const contentText = this.data?.isOldMaterialsEnabled
      ? $localize`Adding this material will add it to the product's allowed materials.`
      : $localize`Adding this material will attach it to the product's materials.`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        confirmButtonText: TextConstants.CONTINUE,
        contentText: $localize`${contentText}:content:  Would you like to continue?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.notificationService.showSuccess($localize`Material added`);
        this.onClose(true, material);
      }
    });
  }

  public onClose = (hasSaved = false, material?: IMaterial): void => {
    this.dialogRef.close({ hasSaved, addedMaterial: material });
  };
}
