<app-slide-overlay-header
  [canDelete]="true"
  [canDuplicate]="true"
  [canArchive]="true"
  [enableTags]="true"
  [initialTags]="initialTags"
  [title]="title()"
  icon="package_2"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText" class="display-block">
      <app-item-overlay-main-information
        [formGroup]="formGroup"
        [element]="element"
        [isSharedUser]="isSharedUser"
        [isOldMaterialsEnabled]="isOldMaterialsEnabled"
        [materialOptions]="materialOptions"
        [productOptions]="productOptions"
        [unitOfMeasurementOptions]="unitOfMeasurementOptions"
        [currentLocationOptions]="currentLocationOptions"
        [createdAtLocationOptions]="createdAtLocationOptions"
        [datesType]="datesType"
        [unitOfMeasurement]="unitOfMeasurement"
        [hasDifferentDefaultUnit]="hasDifferentDefaultUnit"
        [remainingQuantityFormatted]="remainingQuantityFormatted"
        [initialQuantityFormatted]="initialQuantityFormatted"
        [defaultUnitOfMeasurement]="defaultUnitOfMeasurement"
        [duplicateItemId]="duplicateItemId"
        [itemDefaultUnitOfMeasurement]="itemDefaultUnitOfMeasurement"
        (addProduct)="onAddProductClicked()"
        (addMaterial)="onAddMaterialClicked()"
        (dateTypeChanged)="setHasInitialDateTypeChanged($event)"
      ></app-item-overlay-main-information>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.PROCESSES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.linkedProcessCount()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.PROCESSES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="sync"
    overlay-menu-icon-class="rotate-horizontal"
  >
    <article class="field-group-container">
      @if (attachedProcesses) {
        <app-processes-table
          [allProcessTypes]="allProcessTypes"
          [columns]="[
            'recordState',
            'processId',
            'type.name',
            'location.name',
            'firstInputDate',
            'lastOutputDate',
          ]"
          [processes]="attachedProcesses"
        ></app-processes-table>
      } @else {
        <app-loader></app-loader>
      }
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DELIVERIES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.linkedDeliveryCount()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DELIVERIES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="local_shipping"
  >
    <article class="field-group-container">
      @if (deliveriesIds) {
        <app-deliveries-table
          [columns]="['recordState', 'deliveryId', 'sent', 'from.name', 'to.name', 'tags']"
          [deliveriesIds]="deliveriesIds"
        ></app-deliveries-table>
      } @else {
        <app-loader></app-loader>
      }
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="certificateController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.certificateCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <app-overlay-certificate-attachments
      #certificateSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ITEM"
      [overlayService]="itemOverlayService"
    ></app-overlay-certificate-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetEnum.ITEM"
      [isReadOnly]="isRecordArchived"
      [overlayService]="itemOverlayService"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="supplyChainController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SUPPLY_CHAIN).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.SUPPLY_CHAIN).title"
    appSlideOverlayMenuItem
    overlay-menu-svg-icon="supply-chain"
  >
    @if (supplyChainTabIndex === supplyChainTabEnum.GRAPH) {
      <app-items-supply-chain
        [allProcessTypes]="allProcessTypes"
        [height]="supplyChainHeight"
        [itemIds]="itemIds"
      ></app-items-supply-chain>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.LOCATIONS) {
      <article class="field-group-container">
        @if (isLoadingSupplyChainLocations()) {
          <app-loader></app-loader>
        } @else {
          <app-locations-table
            [locationsIds]="supplyChainLocationIds"
            [columns]="[
              'recordState',
              'name',
              'organisationName',
              'types',
              'address.countryName',
              'geoLocation',
              'tags',
              'riskAssessmentReport.completedDate',
              'riskAssessmentReport.residualRisk',
            ]"
          ></app-locations-table>
        }
      </article>
    } @else if (supplyChainTabIndex === supplyChainTabEnum.MAP) {
      <article class="field-group-container">
        <app-items-supply-chain-map [itemIds]="itemIds" />
      </article>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="itemOverlayService.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(itemOverlayService)"
        (deleted)="onCommentDeleted(itemOverlayService)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #certificateController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(certificateSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <app-form-link [link]="getLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </app-form-link>
        } @else {
          @if (isSharedUser) {
            <app-not-shared-data></app-not-shared-data>
          } @else {
            <app-loader containerClass="size-small"></app-loader>
          }
        }
      </div>
    </div>
  </ng-template>
  <ng-template #productViewTemplate let-label="label" let-option let-searchText="searchText">
    @if (authenticationService.canGoToAdminSection()) {
      <div class="form-field-item">
        <app-form-label [label]="label"></app-form-label>
        <app-form-link [link]="getProductLink(option.value)" [styledLink]="true">
          {{ option.label }}
        </app-form-link>
      </div>
    } @else {
      <app-form-input-readonly [value]="searchText" [label]="label"></app-form-input-readonly>
    }
  </ng-template>
  <ng-template #supplyChainController>
    <div class="supply-chain-tabs">
      <mat-tab-group
        animationDuration="0ms"
        (selectedIndexChange)="onSelectSupplyChainTab($event)"
        [selectedIndex]="supplyChainTabIndex"
      >
        <mat-tab>
          <ng-template mat-tab-label i18n>Graph</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label i18n>Locations</ng-template>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label i18n>Map</ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-template>
  <ng-template #initialQuantityViewTemplate let-data>
    @if (unitOfMeasurement) {
      @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
        <app-form-input-readonly
          [label]="data.label"
          [value]="initialQuantityFormatted"
        ></app-form-input-readonly>
      } @else {
        <app-form-input-readonly
          [label]="data.label"
          [value]="
            (element.initialQuantity | number: '1.0-' + (unitOfMeasurement?.precision ?? '0')) +
            ' ' +
            unitOfMeasurement?.symbol
          "
        ></app-form-input-readonly>
      }
    } @else if (isSharedUser) {
      <app-not-shared-data></app-not-shared-data>
    }
  </ng-template>
  <ng-template #remainingQuantityViewTemplate let-data>
    @if (unitOfMeasurement) {
      @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
        <app-form-input-readonly
          [label]="data.label"
          [value]="remainingQuantityFormatted"
        ></app-form-input-readonly>
      } @else {
        <app-form-input-readonly
          [label]="data.label"
          [value]="
            (element.remainingQuantity | number: '1.0-' + (unitOfMeasurement?.precision ?? '0')) +
            ' ' +
            unitOfMeasurement?.symbol
          "
        ></app-form-input-readonly>
      }
    } @else if (isSharedUser) {
      <app-not-shared-data></app-not-shared-data>
    }
  </ng-template>
</app-slide-overlay-content>
<ng-template #addMaterialBtnTemplate>
  <app-button
    (pressed)="onAddMaterialClicked()"
    class="icon-add-circle"
    [tp]="translations.addNewMaterialTp"
    type="icon"
  >
    <mat-icon icon-small>add</mat-icon>
  </app-button>
</ng-template>
