<app-bulk-add-creation-progress
  recordType="items"
  [hasProcessCompleted]="hasProcessCompleted()"
  [payloadsWithStatus]="payloadsWithStatus()"
  [recordTemplate]="recordTemplate"
></app-bulk-add-creation-progress>

<ng-template #recordTemplate let-payloadWithStatus>
  {{ payloadWithStatus.recordPayload.itemId }}
</ng-template>
