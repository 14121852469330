<div class="container-space-between">
  <app-page-title
    [goBackRoute]="routingEnum.ADMIN_RULESETS"
    [goBackRouteState]="resourceType ? { selectedTabResourceType: resourceType } : undefined"
    [tag]="element()?.recordState === recordStateEnum.ARCHIVED ? 'Archived' : ''"
    [title]="element()?.name"
    [backText]="translations.titleBackText"
  ></app-page-title>

  @if (canAddOrModifyRulesets && !isLoading()) {
    <app-button-group>
      <app-more-actions-buttons
        (clickLabel)="onEdit()"
        (reloadData)="loadData($event)"
        [isDeleteEnabled]="true"
        [disabledMainButton]="element()?.recordState !== recordStateEnum.ACTIVE"
        [element]="element()"
        [service]="rulesetsService"
        [label]="translations.editLabel"
        labelIcon="edit"
      ></app-more-actions-buttons>
    </app-button-group>
  }
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>{{ mainInformationText }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row row-4">
          <div class="item">
            <p class="details-label" i18n>Ruleset for</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element().resourceType | enumToText: true"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label" i18n>Description</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element().description"
            ></app-value-and-copy-button>
          </div>
        </div>
      </div>
      <app-record-details [element]="element()" [columns]="4"></app-record-details>
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title"><h3>Criteria</h3></mat-panel-title>
      </mat-expansion-panel-header>
      @switch (resourceType) {
        @case (resourceTypeEnum.LOCATION) {
          <div class="row-container">
            <div class="row row-1">
              <div class="item">
                <p class="details-label" i18n>Location types for</p>
                <app-tag-group
                  [tags]="getLocationTypesTags(selectedLocationTypes())"
                ></app-tag-group>
              </div>
            </div>

            <div class="row row-1">
              <div class="item">
                <p class="details-label" i18n>Location countries</p>
                <div class="tags-container flex-wrap">
                  @for (country of selectedCountries(); track country.value) {
                    <app-tag [tag]="{ label: country.label }">
                      <app-flag [country]="country.value.toString()"></app-flag>
                    </app-tag>
                  } @empty {
                    <app-tag [tag]="{ label: 'All' }"></app-tag>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        @case (resourceTypeEnum.DELIVERY) {
          <h4 i18n>Deliveries "from" locations</h4>
          <div class="row-container">
            @if (deliveryFromCriteria() === criteriaTypeEnum.PARAMETERS) {
              <ng-container
                [ngTemplateOutlet]="parametersCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  countries: selectedLocationFromCountries(),
                  locationTypeTags: getLocationTypesTags(selectedLocationFromTypes()),
                }"
              ></ng-container>
            } @else {
              <ng-container
                [ngTemplateOutlet]="exactCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  locationNames: getLocationNames(selectedLocationsFrom()),
                }"
              ></ng-container>
            }
          </div>
          <h4 i18n>Deliveries "to" locations</h4>
          <div class="row-container">
            @if (deliveryToCriteria() === criteriaTypeEnum.PARAMETERS) {
              <ng-container
                [ngTemplateOutlet]="parametersCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  countries: selectedLocationToCountries(),
                  locationTypeTags: getLocationTypesTags(selectedLocationToTypes()),
                }"
              ></ng-container>
            } @else {
              <ng-container
                [ngTemplateOutlet]="exactCriteriaTemplate"
                [ngTemplateOutletContext]="{
                  locationNames: getLocationNames(selectedLocationsTo()),
                }"
              ></ng-container>
            }
          </div>
        }
      }
    </mat-expansion-panel>
  </mat-accordion>
  <br />
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3 i18n>Expectations</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <p class="details-label" i18n>Required documents</p>
        <app-document-types-table
          [documentTypes]="selectedDocumentTypes()"
          [areButtonsEnabled]="false"
          [isSearchEnabled]="false"
          [columns]="['type']"
        ></app-document-types-table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}

<ng-template #exactCriteriaTemplate let-locationNames="locationNames">
  <div class="row row-1">
    <div class="item">
      <app-tag-group [tags]="locationNames"></app-tag-group>
    </div>
  </div>
</ng-template>

<ng-template
  #parametersCriteriaTemplate
  let-countries="countries"
  let-locationTypeTags="locationTypeTags"
>
  <div class="row row-1">
    <div class="item">
      <p class="details-label" i18n>Type(s)</p>
      <app-tag-group [tags]="locationTypeTags"></app-tag-group>
    </div>
  </div>

  <div class="row row-1">
    <div class="item">
      <p class="details-label" i18n>Country(ies)</p>
      <div class="tags-container flex-wrap">
        @for (country of countries; track country.value) {
          <app-tag [tag]="{ label: country.label }">
            <app-flag [country]="country.value.toString()"></app-flag>
          </app-tag>
        } @empty {
          <app-tag [tag]="{ label: 'All' }"></app-tag>
        }
      </div>
    </div>
  </div>
</ng-template>
