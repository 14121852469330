import { certificatesFields, documentFields, getProductFields } from "./queries.constants";
import { QueriesUtils } from "./queries.utils";

export const getItemsSupplyChainQuery = (orgId: string, itemIds: string[]): string => {
  return `
    {
        loadItemsSupplyChain(   
            orgId: "${orgId}",
            itemIds: ${QueriesUtils.getParsedIds(itemIds)}
        ) {
            ${getItemsSupplyChainFields()}
        }
    }
    `;
};
export const getItemsSupplyChainFields = (): string => `items {
                item {
                    id
                    itemId
                    initialQuantity
                    createdAtLocation {
                        id
                        name
                    }
                    currentLocation {
                        id
                        name
                    }
                    materials {
                        id
                        name
                        category
                    }
                    created {
                        ... on ExactDateTime {
                            on
                          }
                        ... on DateTimeRange {
                            start
                            end
                        }
                    }
                    product ${getProductFields()}
                    documents {
                        ${documentFields}
                    }
                }
                locations {
                    id
                    name
                    geoLocation
                    types {
                        type
                        pointOfOrigin
                    }
                    address {
                        country
                        zipCode
                        street
                        region
                    }
                    connections {
                        id
                        name
                    }
                    documents {
                        ${documentFields}
                    }
                    certificates {
                        id
                        number
                        issuanceDate
                        validFromDate
                        validToDate
                        standard {
                            id
                            name
                        }
                        standardType {
                            id
                            fullName
                        }
                        tags {
                            title
                            color
                        }
                        documents {
                            id
                            name
                        }
                        recordState
                    }
                    customFields {
                        definition {
                            type
                            label
                        }
                        value
                    }
                }
                deliveries {
                    id
                    deliveryId
                    agents {
                        id
                        name
                        documents {
                            ${documentFields}
                        }
                        certificates {
                            ${certificatesFields}
                        }
                    }
                    from { 
                        id
                        name
                     }
                    to { 
                        id
                        name
                     }
                    sent
                    delivered
                    items {
                        quantity
                        item {
                            id
                            itemId
                            recordState
                            remainingQuantity
                            initialQuantity
                            currentLocation { id }
                            product ${getProductFields(["DEFAULT_CUSTOM_UNIT"])}
                            materials {
                                id
                                name
                            }
                        }
                    }
                    documents {
                        ${documentFields}
                    }
                    certificates {
                        ${certificatesFields}
                    }
                }
                processes {
                    id
                    processId
                    lastOutputDate
                    firstInputDate
                    recordState
                    documents {
                        ${documentFields}
                    }
                    location {
                        id
                        name
                        types {
                            type
                        }
                        address  {
                            country
                        }
                    } 
                    type {
                        id
                        name
                    }            
                }    
            }`;
