import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
} from "@angular/core";

import { ICertificateDetails, IStandard } from "src/app/shared/interfaces";
import { CertificatesService, StandardTypesService } from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";

@Component({
  standalone: false,
  selector: "app-certificate-list-items",
  templateUrl: "./certificate-list-items.component.html",
  styleUrls: ["./certificate-list-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateListItemsComponent implements OnChanges {
  @Input()
  public certificates: ICertificateDetails[];

  @Input()
  public allStandards: IStandard[] = [];

  @Input()
  public propertyName: string;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  public isLoading = signal(true);

  public certificateDetails: ICertificateDetails[] = [];

  public readonly contentCardTypeEnum = CardContentTypeEnum;

  constructor(
    private certificatesService: CertificatesService,
    private standardTypesService: StandardTypesService,
  ) {}

  public async ngOnChanges(): Promise<void> {
    this.isLoading.set(true);

    this.certificateDetails = [];

    if (this.certificates?.length && this.allStandards?.length) {
      for (const item of this.certificates) {
        const certificateId = CommonUtils.getUriId(item[this.propertyName]);
        const certificate = await this.certificatesService.get(certificateId);

        const certificateDetail = { ...item };

        certificateDetail.number = certificate.number;
        certificateDetail.validFromDate = certificate.validFromDate;
        certificateDetail.validToDate = certificate.validToDate;
        const standardId = CommonUtils.getUriId(certificate.standard);

        certificateDetail.standard = this.allStandards.find((s) => s.id === standardId);

        if (certificate.standardType) {
          const standardTypeId = CommonUtils.getUriId(certificate.standardType);

          certificateDetail.standardType = await this.standardTypesService.get(
            standardId,
            standardTypeId,
          );
        }

        this.certificateDetails.push(certificateDetail);
      }
    }

    this.isLoading.set(false);
  }

  public onRemove = (id: string): void => {
    this.remove.emit(id);
  };
}
