@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="full-width-items-container">
    @for (item of items; track item.id) {
      <div class="item hover-visibility-container">
        <app-item-card-content
          [item]="item"
          [type]="contentCardTypeEnum.REMOVE"
          (remove)="onRemove(item.id)"
          (itemQuantityChanged)="onItemQuantityChange(item)"
        >
          <div form-fields [formGroup]="item.formGroup" class="options no-margin">
            <app-input
              [suffix]="
                item.productDefaultUnit
                  ? item.productDefaultUnit.symbol
                  : item.unitOfMeasurement?.symbol
              "
              [hint]="
                item.productDefaultUnit
                  ? (item.formGroup.controls['deliveredQty'].value
                    | unitConversion
                      : item.productDefaultUnit
                      : item.unitOfMeasurement
                      : false
                      : true)
                  : ''
              "
              class="max-width-160 no-padding"
              formControlName="deliveredQty"
              [label]="translations.deliveredQtyLabel"
              type="number"
            ></app-input>
          </div>
        </app-item-card-content>
      </div>
    }
  </div>
}
