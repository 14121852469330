import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
  OnDestroy,
} from "@angular/core";

import { ColDef } from "ag-grid-community";
import { Subscription } from "rxjs";
import {
  ConnectionStatusEnum,
  EntityTypeEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";

import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, ConnectionsService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-settings-existing-connections-table",
  templateUrl: "./settings-existing-connections-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsExistingConnectionsTableComponent implements AfterViewInit, OnDestroy {
  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public readonly table = TableEnum.ADMIN_CONNECTIONS_EXISTING;

  public readonly recordState = RecordStateEnum.ALL;

  public isLoading = signal(true);

  public rowData = signal<any[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  private subscriptions = new Subscription();

  constructor(
    private notificationService: NotificationService,
    private connectionsService: ConnectionsService,
    private routerService: RouterService,
    private overlayService: SlideOverlayPageService,
  ) {
    this.subscriptions.add(
      this.overlayService.refreshTable$.subscribe((instance: SlideOverlayPageClass) => {
        if (instance.entityType === EntityTypeEnum.CONNECTIONS) {
          this.getAll();
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    //todo uncomment and create "Remove connection" Batch action when BE is ready
    // this.batchActionSettings = {
    //   actions: new Map([
    //     [
    //       BatchActionTypeEnum.DELETE,
    //       BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.connectRequestsService),
    //     ],
    //   ]),
    // };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.ORGANISATION,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          icon: "link",
          iconTooltip: $localize`Connected`,
          showIcon: () => true,
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
    ];

    // todo uncomment when BE is ready
    // const buttons = [
    //   {
    //     onClick: this.onRemove,
    //     tooltip: $localize`Remove connection`,
    //     icon: "block",
    //   },
    // ];

    // columnDefs.push(ColumnUtils.buttons(buttons));

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  // todo uncomment when BE is ready
  // private onRemove = (row: IOrganisation): void => {
  //   const dialogRef = this.dialog.open(ConfirmDialogComponent, {
  //     data: {
  //       title: $localize`Remove existing connection`,
  //       contentText: $localize`Are you sure that you want to remove this existing connection?`,
  //       confirmButtonColor: "danger",
  //       confirmButtonText: TextConstants.REMOVE,
  //       confirmButtonIcon: "delete",
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
  //     if (result === ConfirmDialogResponseEnum.CONFIRM) {
  //       this.isLoading.set(true);
  //       try {
  //         //todo Remove existing connection
  //         console.log(row);
  //         this.notificationService.showSuccess($localize`Connection removed`);
  //         await this.getAll();
  //       } catch (error) {
  //         this.notificationService.showError(error);
  //         this.isLoading.set(false);
  //       }
  //     }
  //   });
  // };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const connectedConnections = await this.connectionsService.getAll(
        undefined,
        ConnectionStatusEnum.CONNECTED,
      );

      this.rowData.set(connectedConnections);

      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
