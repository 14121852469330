<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>
    @if (isEditing()) {
      <ng-container i18n>Edit custom field</ng-container>
    } @else {
      <ng-container i18n>Add custom field</ng-container>
    }
  </h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <app-input
        [autofocus]="!isLoading() && !isEditing()"
        formControlName="label"
        [label]="translations.fieldLabel"
      ></app-input>

      <app-input-select
        [options]="typeOptions"
        formControlName="type"
        [label]="translations.typeLabel"
      ></app-input-select>

      <app-input-chips
        [options]="resourceTypeOptions"
        formControlName="resourceTypes"
        [label]="translations.modulesLabel"
      ></app-input-chips>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
