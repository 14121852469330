import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditDocumentTypeDialogComponent } from "src/app/components/settings";

import {
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
  ValueWithTooltipCellRendererComponent,
} from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
  TableEnum,
} from "@shared/enums";
import { IDocumentType } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, DocumentTypesService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  standalone: false,
  selector: "app-document-types-table",
  templateUrl: "./document-types-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentTypesTableComponent implements AfterViewInit {
  @Input()
  public documentTypes: IDocumentType[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.DOCUMENT_TYPES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public rulesetWarningTooltipText =
    $localize`This delivery does not adhere to one or more rulesets applied to it`;

  @Input()
  public columns: string[] = ["recordState", "name"];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: IDocumentType[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private documentTypesService: DocumentTypesService,
    private routerService: RouterService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.documentTypesService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.documentTypesService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.documentTypesService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        suppressSizeToFit: false,
        suppressAutoSize: false,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onDelete,
                  tooltip: TextConstants.DELETE,
                  icon: "delete",
                },
                {
                  click: this.onToggleArchiveStatus,
                  tooltip: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE
                      ? TextConstants.ARCHIVE
                      : TextConstants.UNARCHIVE,
                  icon: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory",
                },
                {
                  click: this.onEdit,
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                },
              ]
            : [],
        },
      },
      { colId: "type", headerName: TextConstants.TYPE, field: "name", lockVisible: true },
      {
        colId: "documentType",
        headerName: $localize`Document type`,
        field: "name",
        lockVisible: true,
        cellRenderer: ValueWithTooltipCellRendererComponent,
        cellRendererParams: (cell: { data: IDocumentType }) => {
          return {
            shouldDisplayIcon: () => cell.data.rulesetsNames?.length,
            icon: "warning",
            iconTooltip: this.rulesetWarningTooltipText,
          };
        },
      },
      ColumnUtils.chips("Rulesets", "rulesetsNames"),
      {
        colId: "documentNames",
        headerName: $localize`Document name`,
        field: "documentNames",
        lockVisible: true,
        valueGetter: (cell: { data: IDocumentType }) => {
          return (cell.data.documents || []).map((document) => {
            return { name: document.name, id: document.id };
          });
        },
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getDocumentLink,
          textParam: "name",
        },
      },
    ];

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);
    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onDelete = async (element: IDocumentType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this document type?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.documentTypesService.delete(element.id);
          this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: IDocumentType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentText: TextConstants.EDIT_ARCHIVED_RECORD_ERROR,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditDocumentTypeDialogComponent, {
      data: {
        documentType: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IDocumentType): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.documentTypesService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.documentTypes) {
      this.rowData = this.documentTypes;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.documentTypesService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
