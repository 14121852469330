export enum RouteEnum {
  LOGIN = "login",
  LOGOUT = "logout",
  SIGN_UP = "sign-up",
  VERIFY = "verify",
  FORGOT_PASSWORD = "forgot-password",
  RESET_PASSWORD = "reset-password",
  SHARING = "sharing",

  DASHBOARD = "dashboard",

  DELIVERIES = "deliveries",

  EUDR_REPORT = "reports/eudr",
  EUDR_DDS = "reports/eudr/dds",
  DELIVERY_REPORT = "reports/delivery",

  DOCUMENTS = "documents",
  DOCUMENTS_UPLOAD = "documents/upload",
  DOCUMENTS_VIEW = "documents/view",

  ITEMS = "items",

  INVITATIONS = "invitations",

  USER_SETTINGS = "user-settings",
  USER_SETTINGS_API_KEYS = "user-settings/api-keys",
  USER_SETTINGS_MEMBERSHIPS = "user-settings/memberships",
  USER_SETTINGS_PROFILE = "user-settings/profile",

  SUPPLY_CHAINS = "supply-chains",
  SUPPLY_CHAINS_ADD = "supply-chains/add",
  SUPPLY_CHAINS_EDIT = "supply-chains/edit",
  SUPPLY_CHAINS_DETAILS = "supply-chains/details",
  FULL_SUPPLY_CHAIN = "supply-chains/full-supply-chain",

  LOCATIONS = "locations",
  ORGANISATIONS = "organisations",
  CERTIFICATES = "certificates",
  PROCESSES = "processes",
  MATERIALS = "materials",
  PRODUCTS = "products",

  ADMIN_ORGANISATIONS = "admin-organisations",
  ADMIN_ORGANISATION_DETAILS = "admin-organisations/details",
  ADMIN_LOCATIONS_DETAILS = "admin-locations/details",

  ADMIN_USERS = "admin-users",
  ADMIN_USER_DETAILS = "admin-users/details",

  ADMIN = "admin",
  ADMIN_MEMBERSHIPS = "admin/memberships",

  ADMIN_CUSTOM_FIELDS = "admin/custom-fields",
  ADMIN_TAGS = "admin/tags",
  ADMIN_STANDARDS = "admin/standards",
  ADMIN_UNITS_OF_MEASUREMENT = "admin/units",

  ADMIN_CONNECTIONS = "admin/connections",
  ADMIN_SENT_INFORMATION = "admin/sent-information",
  ADMIN_EXTENSIONS = "admin/extensions",
  ADMIN_EXTENSIONS_ORBIFY = "admin/extensions/orbify",
  ADMIN_EXTENSIONS_EUDR = "admin/extensions/eudr",

  ADMIN_DOCUMENT_TYPES = "admin/document-types",
  ADMIN_LOCATION_TYPES = "admin/location-types",
  ADMIN_PROCESS_TYPES = "admin/process-types",

  ADMIN_RULESETS = "admin/rulesets",
  ADMIN_RULESETS_ADD_LOCATION = "admin/rulesets/add/location",
  ADMIN_RULESETS_ADD_DELIVERY = "admin/rulesets/add/delivery",
  ADMIN_RULESETS_EDIT_LOCATION = "admin/rulesets/edit/location",
  ADMIN_RULESETS_EDIT_DELIVERY = "admin/rulesets/edit/delivery",
  ADMIN_RULESETS_DETAILS = "admin/rulesets/details",

  ADMIN_RISK_ASSESSMENT_TEMPLATES = "admin/risk-assessment-templates",
  ADMIN_RISK_LEVEL_SETS = "admin/risk-level-sets",
  ADMIN_RISK_MITIGATIONS = "admin/risk-mitigations",

  ADMIN_COUNTRIES = "admin/countries",

  OVERLAY_LOCATION = "location",
  OVERLAY_ORGANISATION = "organisation",
  OVERLAY_CERTIFICATE = "certificate",
  OVERLAY_DOCUMENT = "document",
  OVERLAY_DELIVERY = "delivery",
  OVERLAY_ITEM = "item",
  OVERLAY_PROCESS = "process",
  OVERLAY_PRODUCT = "product",
  OVERLAY_MATERIAL = "material",
  OVERLAY_RISK_ASSESSMENT_TEMPLATE = "risk-assessment-template",
  OVERLAY_RISK_ASSESSMENT_REPORT = "risk-assessment-report",
  OVERLAY_COUNTRY = "country",

  OVERLAY_SHARED_LOCATION = "shared-location",
  OVERLAY_SHARED_ORGANISATION = "shared-organisation",
  OVERLAY_SHARED_DOCUMENT = "shared-document",
  OVERLAY_SHARED_CERTIFICATE = "shared-certificate",
  OVERLAY_SHARED_DELIVERY = "shared-delivery",
  OVERLAY_SHARED_PRODUCT = "shared-product",
  OVERLAY_SHARED_ITEM = "shared-item",
  OVERLAY_SHARED_MATERIAL = "shared-material",
  OVERLAY_SHARED_PROCESS = "shared-process",

  OVERLAY_PUBLIC_ORGANISATION = "public-organisation",

  INBOX = "inbox",
  INBOX_TRANSFER_OR_MAP = "inbox/transfer-or-map",
  INBOX_SHARED_SUPPLY_CHAIN = "inbox/shared-supply-chain",

  SHARED_LINK = "shared-link",
}
