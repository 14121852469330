import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
  ViewChild,
} from "@angular/core";

import { ColDef } from "ag-grid-community";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { RiskAssessmentReportsApiService } from "@components/shared/risk-assessment-reports/api";
import { RiskLevel } from "@components/shared/risk-level-sets/models";
import { TableComponent } from "@components/shared/tables/table/table.component";
import { LinkCellRendererComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { RecordStateEnum, TableEnum } from "@shared/enums";
import { NotificationService } from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { ColumnUtils } from "@shared/utils";

import { RelatedRiskAssessmentReportsTableModel as Model, RiskAssessmentReport } from "../models";

@Component({
  selector: "app-related-risk-assessment-reports-table",
  templateUrl: "./related-risk-assessment-reports-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RelatedRiskAssessmentReportsTableComponent implements AfterViewInit {
  private readonly notification = inject(NotificationService);

  private readonly routerService = inject(RouterService);

  private readonly reportsApi = inject(RiskAssessmentReportsApiService);

  readonly areButtonsEnabled = input(true);

  readonly isFixedBottomPaginator = input(false);

  readonly isPaginatorEnabled = input(true);

  readonly isRecordStateFilterEnabled = input(true);

  readonly isSaveTableState = input(false);

  readonly isSearchEnabled = input(true);

  readonly recordState = input(RecordStateEnum.ALL);

  readonly relatedRiskAssessmentReportTableParams = input.required<Model.Params>();

  readonly view = output<RiskAssessmentReport>();

  readonly isLoading = signal(true);

  readonly columnDefs = signal<ColDef[]>([]);

  readonly riskLevels = signal<RiskLevel[]>([]);

  readonly rowData = signal<Model.RelatedRiskAssessmentReport[]>([]);

  readonly totalElements = computed(() => this.rowData().length);

  readonly table = TableEnum.RISK_ASSESSMENT_REPORTS;

  readonly rowGroupColumns = signal<string[]>(["additionalData.recordType"]);

  readonly autoGroupColumnDef = signal<ColDef>({
    headerName: $localize`Record type`,
    pinned: null,
    minWidth: 150,
  });

  private readonly residualRisks = signal<Model.ResidualRiskTag[]>([]);

  @ViewChild("tableComponent") tableComponent: TableComponent;

  riskLevelsTags = computed(() => {
    return Object.entries(this.residualRisks()).map(([_key, residualRisk]) => ({
      label: `${residualRisk.label} (${residualRisk.count})`,
      value: residualRisk.value,
      class: [...residualRisk.classes(), "no-underline"].join(" "),
      icon: residualRisk.icon(),
    }));
  });

  async ngAfterViewInit() {
    this.setColumnDefs();

    await this.getAll();
  }

  async getAll() {
    try {
      this.isLoading.set(true);

      const relatedRiskAssessmentReports = await this.loadRelatedRiskAssessmentReports();

      this.rowData.set(relatedRiskAssessmentReports);

      this.setResidualRisks(this.rowData());

      this.isLoading.set(false);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private setResidualRisks(riskAssessmentReports: Model.RelatedRiskAssessmentReport[]) {
    const residualRisks: Model.ResidualRiskTag[] = [];

    residualRisks[Model.ALL_RISK_LEVELS.value] = {
      label: Model.ALL_RISK_LEVELS.label,
      value: Model.ALL_RISK_LEVELS.value,
      icon: signal(Model.CHECKED_ICON),
      count: riskAssessmentReports.length,
      classes: signal([Model.REVERSE_ORDER_CLASS]),
    };

    for (const riskAssessmentReport of riskAssessmentReports) {
      const residualRisk = riskAssessmentReport.residualRisk;

      if (!residualRisk) {
        if (residualRisks[Model.NONE_RISK_LEVEL.value]) {
          residualRisks[Model.NONE_RISK_LEVEL.value].count++;
        } else {
          residualRisks[Model.NONE_RISK_LEVEL.value] = {
            label: Model.NONE_RISK_LEVEL.label,
            value: Model.NONE_RISK_LEVEL.value,
            icon: signal(null),
            count: 1,
            classes: signal([Model.NOT_SELECTED_TAG_CLASS, Model.REVERSE_ORDER_CLASS]),
          };
        }

        continue;
      }

      if (residualRisks[residualRisk.title]) {
        residualRisks[residualRisk.title].count++;
      } else {
        residualRisks[residualRisk.title] = {
          label: residualRisk.title,
          value: residualRisk.title,
          classes: signal([
            residualRisk.color,
            Model.NOT_SELECTED_TAG_CLASS,
            Model.REVERSE_ORDER_CLASS,
          ]),
          icon: signal(Model.CHECKED_ICON),
          count: 1,
        };
      }
    }

    this.residualRisks.set(residualRisks);
  }

  onView(row: RiskAssessmentReport) {
    if (this.areButtonsEnabled() && row.id) {
      this.view.emit(row);
    }
  }

  async onSelectedRiskLevel(riskLevel: InputSelectOption) {
    const model = this.createFilterModel(riskLevel.value as string);

    this.updateRiskLevelTags(riskLevel.value as string);

    this.tableComponent.grid.api.setColumnFilterModel<Model.FilterModel>("residualRisk", model);

    this.tableComponent.grid.api.onFilterChanged();
  }

  private createFilterModel(riskLevelValue: string): Model.FilterModel {
    if (riskLevelValue === Model.NONE_RISK_LEVEL.value) {
      return {
        filterType: "text",
        type: "blank",
        filter: null,
      };
    }

    if (riskLevelValue !== Model.ALL_RISK_LEVELS.value) {
      return {
        filterType: "text",
        type: "equals",
        filter: riskLevelValue,
      };
    }

    return null;
  }

  private updateRiskLevelTags(selectedValue: string): void {
    Object.values(this.residualRisks()).forEach((risk) => {
      const isSelected = risk.value === selectedValue;
      const isAllSelected = selectedValue === Model.ALL_RISK_LEVELS.value;

      risk.icon.set(isSelected || isAllSelected ? Model.CHECKED_ICON : null);

      const classes = risk.classes().filter((c) => c !== Model.NOT_SELECTED_TAG_CLASS);

      if (!isSelected) {
        classes.push(Model.NOT_SELECTED_TAG_CLASS);
      }
      risk.classes.set(classes);
    });
  }

  private getLinkRouteFn(
    recordType: Model.RecordType,
  ): (id?: string, serializeTree?: boolean, extraParams?: any) => NavigationParams | string {
    switch (recordType) {
      case Model.RecordType.COUNTRIES:
        return this.routerService.getCountryLink;
      case Model.RecordType.ORGANISATIONS:
        return this.routerService.getOrganisationLink;
      case Model.RecordType.LOCATIONS:
        return this.routerService.getLocationLink;
      case Model.RecordType.MATERIALS:
        return this.routerService.getMaterialLink;
      case Model.RecordType.PRODUCTS:
        return this.routerService.getProductLink;
      case Model.RecordType.DELIVERIES:
        return this.routerService.getDeliveryLink;
      default:
        return null;
    }
  }

  private setColumnDefs() {
    const tagsColDef = {
      ...ColumnUtils.tags($localize`Residual risk`, "residualRisk"),
      filter: "agTextColumnFilter",
      filterValueGetter: (params) => params.data?.residualRisk?.title,
    };

    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.TYPE,
        field: "additionalData.recordType",
        rowGroup: true,
        hide: true,
      },
      {
        headerName: $localize`Name / ID`,
        field: "additionalData.name",
        cellRendererSelector: (params: {
          data: Model.RelatedRiskAssessmentReport;
          node: { allLeafChildren: { data: Model.RelatedRiskAssessmentReport }[] };
        }) => {
          const data = params.data || params.node?.allLeafChildren?.[0]?.data;

          if (data?.additionalData?.id) {
            return {
              component: LinkCellRendererComponent,
              params: {
                icon: "link",
                linkRouteIdParam: "additionalData.id",
                linkRouteFn: this.getLinkRouteFn(data.additionalData.recordType),
              },
            };
          }

          return null;
        },
      },
      ColumnUtils.dateColumn({
        headerName: $localize`Date completed`,
        field: "completedDate",
        sort: "desc",
      }),
      tagsColDef,
      ColumnUtils.dateColumn({
        headerName: $localize`Valid until`,
        field: "validUntilDate",
      }),
    ];

    this.columnDefs.set(columnDefs);
  }

  private buildEmptyRelatedRiskAssessmentReport(
    record?: Model.Record,
  ): Model.RelatedRiskAssessmentReport {
    return {
      calculatedRisk: null,
      completedDate: null,
      id: null,
      recordState: RecordStateEnum.ACTIVE,
      recordUris: [],
      residualRisk: null,
      status: null,
      validUntilDate: null,
      note: null,
      additionalData: {
        recordType: record?.type,
        name: record?.label,
        id: record?.id,
      },
    };
  }

  async loadRelatedRiskAssessmentReports(): Promise<Model.RelatedRiskAssessmentReport[]> {
    const uris = this.relatedRiskAssessmentReportTableParams()
      .records.filter((r) => r.uri)
      .map((r) => r.uri);

    const riskAssessments = await this.reportsApi.getLatest({
      records: uris,
    });

    const riskAssessmentReports: Model.RelatedRiskAssessmentReport[] = [];

    for (const record of this.relatedRiskAssessmentReportTableParams().records) {
      const report = riskAssessments.find((r) => r.uri === record.uri);

      if (report) {
        riskAssessmentReports.push({
          ...report.report,
          additionalData: {
            recordType: record.type,
            name: record.label,
            id: record.id,
          },
        });
      } else {
        riskAssessmentReports.push(this.buildEmptyRelatedRiskAssessmentReport(record));
      }
    }

    return riskAssessmentReports;
  }
}
