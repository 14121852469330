export namespace BulkAddCreateRecordsModel {
  export enum StatusEnum {
    CANCELED = "CANCELED",
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
  }

  export interface IWithStatus {
    status: BulkAddCreateRecordsModel.StatusEnum;
  }
}
