import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";
import {
  BadgeLinkCellRendererComponent,
  LinkCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { FeatureFlagEnum, TableEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  IItemExtended,
  IPageableContent,
  IProcessInput,
  IProcessOutput,
  IProcessProduct,
  IProductExtended,
} from "src/app/shared/interfaces";
import { FeatureFlagService, ItemsService, ProcessesService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { ValueWithTooltipCellRendererComponent } from "@shared/cell-renderers/value-with-tooltip.cell-renderer";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-process-in-out-table",
  templateUrl: "./process-in-out-table.component.html",
  styleUrl: "./process-in-out-table.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessInOutTableComponent implements AfterViewInit {
  @Input()
  public processId: string;

  @Input()
  public type: string;

  @Input()
  public class: string;

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = false;

  @Input()
  public isPaginatorEnabled = false;

  public readonly table = TableEnum.PROCESS_IN_OUT;

  @Input()
  public columns: string[] = ["name", "quantity", "percentage"];

  @Input()
  public isSaveTableState = false;

  @Input()
  public isShowSelectCheckbox = false;

  @Input()
  public isShowPercentages = false;

  public isLoading = signal(true);

  public rowData: any[] = [];

  public totalSummary: string;

  public columnDefs = signal<ColDef[]>([]);

  public shouldDisplayPercentageValue = false;

  private inputProducts: IProcessProduct[];

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public inboundProcessResources: IProcessInput[] | IProcessOutput[];

  @Input()
  public inboundInputs: IProcessInput[];

  @Input()
  public inboundItems: IItemExtended[] = [];

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor(
    private itemService: ItemsService,
    private processesService: ProcessesService,
    private notificationService: NotificationService,
    private router: RouterService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    if (!this.isInboundShared) {
      await this.getAll();
    } else {
      this.rowData = await this.getParsedRowData();
      if (this.isShowPercentages) {
        this.inputProducts = await this.parseProcessElements(this.inboundInputs, true);
      }
      this.calculateTotals(this.rowData);

      this.isLoading.set(false);
    }
  }

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      {
        headerName: $localize`Quantity`,
        field: "quantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "precision",
          symbolParam: "symbol",
          formattedQuantityParam: "quantityFormatted",
        },
      },
    ];

    if (this.isShowPercentages) {
      columnDefs.push({
        headerName: $localize`% of input sum`,
        field: "percentage",
        cellRenderer: ValueWithTooltipCellRendererComponent,
        cellRendererParams: {
          shouldDisplayIcon: () => !this.shouldDisplayPercentageValue,
          icon: "help",
          iconTooltip: $localize`Input and/or output products have different units of measurement.`,
        },
      });
    }
    if (this.isOldMaterialsEnabled) {
      columnDefs.push({
        headerName: TextConstants.PRODUCT,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteFn: this.isInboundShared
            ? (id) =>
                this.router.getSharedProductLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
            : this.router.getProductLink,
          linkRouteIdParam: "id",
        },
      });
    } else {
      columnDefs.push({
        headerName: TextConstants.PRODUCT,
        field: "name",
        cellClass: "container-flex-left",
        lockVisible: true,
        valueGetter: (cell: any) => String(cell.data?.name ?? "-"),
        cellRenderer: BadgeLinkCellRendererComponent,
        cellRendererParams: {
          tooltipArray: (row) => {
            if (!row?.materials?.length) {
              return undefined;
            }

            return row.materials.map((m) => `${m.category}: ${m.name}`);
          },
          badgeValue: (row) => row.name,
          badgeIcon: "category",
          tooltipTemplate: "keyCount",
          tooltipHeader: "Materials",
          linkRouteIdParam: "id",
          linkRouteFn: this.isInboundShared
            ? (id) =>
                this.router.getSharedProductLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
            : this.router.getProductLink,
        },
      });
    }
    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);
    if (this.isShowSelectCheckbox) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private async getAllProcessInputsOrOutput(
    type: string,
  ): Promise<IPageableContent<IProcessInput | IProcessOutput>> {
    if (type === "input") {
      return await this.processesService.getManyInputs(this.processId);
    } else {
      return await this.processesService.getManyOutputs(this.processId);
    }
  }

  private getParsedRowData = async (): Promise<any[]> => {
    try {
      const processElements = this.isInboundShared
        ? this.inboundProcessResources
        : (await this.getAllProcessInputsOrOutput(this.type)).content;

      return await this.parseProcessElements(processElements, this.type === "input");
    } catch (error) {
      this.notificationService.showError(error);

      return [];
    }
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    this.rowData = await this.getParsedRowData();
    if (this.isShowSelectCheckbox) {
      this.rowData = this.rowData.map((r) => ({ ...r, isSelected: true }));
    }
    if (this.isShowPercentages) {
      const inputElements = await this.processesService.getManyInputs(this.processId);

      this.inputProducts = await this.parseProcessElements(inputElements.content, true);
    }
    this.calculateTotals(this.rowData);

    this.isLoading.set(false);
  };

  selectionChanged(rowData: any[]) {
    this.calculateTotals(rowData);
  }

  private calculateTotals(rowData: any[]) {
    const selectedProducts = rowData.filter(
      (product) => !this.isShowSelectCheckbox || product.isSelected,
    );

    if (selectedProducts.length === 0) {
      this.totalSummary = "";

      return;
    }
    let totalSummary = "Sum: ";

    // Get a list of all units to know if we need to calculate the percentage
    let allUnits = [...new Set(rowData.map((product) => product.symbol))];
    let totalInputQuantity = 0;

    if (this.isShowPercentages) {
      if (this.inputProducts?.length) {
        totalInputQuantity = this.inputProducts.reduce((acc, product) => acc + product.quantity, 0);
        const inputUnits = new Set(this.inputProducts.map((product) => product.symbol));

        allUnits = [...new Set([...allUnits, ...inputUnits])];
      }
    }

    const sumsByUnit = this.calculateSumsByUnit(selectedProducts);

    const units = Object.keys(sumsByUnit);

    // Display the percentage only for outputs with not distinct units.
    this.shouldDisplayPercentageValue = allUnits.length === 1 && this.isShowPercentages;

    units.forEach((unit, index) => {
      const { totalQuantity } = sumsByUnit[unit];

      totalSummary += `${totalQuantity.toLocaleString()} ${unit}`;
      if (this.shouldDisplayPercentageValue && totalInputQuantity > 0) {
        const totalPercentage = ((totalQuantity / totalInputQuantity) * 100).toFixed(2);

        totalSummary += ` (${totalPercentage}%)`;
      }
      if (index < units.length - 1) {
        totalSummary += ", ";
      }
    });
    this.totalSummary = totalSummary;

    rowData.forEach((product) => {
      if (
        this.shouldDisplayPercentageValue &&
        product.symbol === units[0] &&
        (product.isSelected || this.isInboundShared)
      ) {
        product.percentage = ((product.quantity / totalInputQuantity) * 100).toFixed(2) + "%";
      } else {
        product.percentage = "n/a";
      }
    });
  }

  private calculateSumsByUnit(products: any[]): { [key: string]: { totalQuantity: number } } {
    return products.reduce((acc, product) => {
      if (!acc[product.symbol]) {
        acc[product.symbol] = { totalQuantity: 0 };
      }
      acc[product.symbol].totalQuantity += product.quantity;

      return acc;
    }, {});
  }

  private async parseProcessElements(
    processElements: IProcessInput[] | IProcessOutput[],
    useSelectedQuantity: boolean,
  ): Promise<IProcessProduct[]> {
    const extendedProcessElements = [];

    for (const processElement of processElements) {
      const products = [];

      for (const item of processElement.items) {
        const itemId = CommonUtils.getUriId(item.item);
        const { currentItem, currentProduct } = await this.getCurrentItemAndProduct(itemId);
        const unitOfMeasurement = currentProduct?.unitOfMeasurement;

        products.push({
          id: currentProduct?.id,
          name: currentProduct?.name,
          materials: this.isOldMaterialsEnabled
            ? currentItem?.materials
            : currentProduct?.materials,
          precision: unitOfMeasurement?.precision,
          symbol: unitOfMeasurement?.symbol,
          quantity: useSelectedQuantity ? item.selectedQuantity : currentItem.initialQuantity,
        });
      }

      extendedProcessElements.push({ processElement, products });
    }
    const products = extendedProcessElements.map((i) => i.products).flat();
    const uniqueProducts: IProcessProduct[] = products.reduce((acc, product) => {
      const existingProduct = acc.find((p) => p.id === product.id);

      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        acc.push({ ...product });
      }

      return acc;
    }, []);

    // Calculate valueWithDefaultUnit for each unique product
    for (const product of uniqueProducts) {
      const currentProduct = this.allProducts.find((p) => p.id === product.id);
      const defaultUnit = currentProduct?.defaultCustomUnit;
      const baseUnit = currentProduct?.unitOfMeasurement;

      if (defaultUnit?.id !== baseUnit?.id) {
        const productDefaultUnit = defaultUnit;

        if (productDefaultUnit) {
          product.quantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
            product.quantity,
            productDefaultUnit,
            baseUnit,
          );
        }
      }
    }

    return uniqueProducts;
  }

  async getCurrentItemAndProduct(itemId: string) {
    if (this.isInboundShared) {
      const currentItem = this.inboundItems.find((item) => item.id === itemId);
      const currentProduct = currentItem?.product as any;

      return { currentItem, currentProduct };
    } else {
      const currentItem = await this.itemService.get(itemId);
      const currentProduct = this.allProducts.find(
        (p) => p.id === CommonUtils.getUriId(currentItem.product),
      );

      return { currentItem, currentProduct };
    }
  }
}
