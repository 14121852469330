import { Injectable, inject } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { RiskLevel } from "@components/shared/risk-level-sets/models";
import { IRecordState } from "@shared/interfaces";
import { ApiService, AuthenticationService } from "@shared/services";
import { APP_CONFIG } from "@shared/tokens";
import { FormUtils } from "@shared/utils";

import {
  LatestRiskAssessmentReport,
  PageableRiskAssessmentReports,
  RiskAssessmentReport,
  RiskAssessmentReportCreatePayload,
  RiskAssessmentReportLatestPayload,
  RiskAssessmentReportPutPayload,
  RiskAssessmentReportsParams,
} from "../models";

@Injectable({ providedIn: "root" })
export class RiskAssessmentReportsApiService {
  private readonly api = inject(ApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/reports`;

  getAll(params: RiskAssessmentReportsParams) {
    const query = FormUtils.addUrlParams(params);

    return lastValueFrom(
      this.api.get<PageableRiskAssessmentReports>(`${this.getBaseUrl()}?${query}`),
    );
  }

  get(id: string) {
    return lastValueFrom(this.api.get<RiskAssessmentReport>(`${this.getBaseUrl()}/${id}`));
  }

  create(payload: RiskAssessmentReportCreatePayload) {
    return lastValueFrom(this.api.post<RiskAssessmentReport>(this.getBaseUrl(), payload));
  }

  update(id: string, payload: RiskAssessmentReportPutPayload) {
    return lastValueFrom(this.api.put<RiskAssessmentReport>(`${this.getBaseUrl()}/${id}`, payload));
  }

  delete(id: string) {
    return lastValueFrom(this.api.delete<void>(`${this.getBaseUrl()}/${id}`));
  }

  setRecordState(id: string, payload: IRecordState) {
    return lastValueFrom(this.api.put<RiskAssessmentReport>(`${this.getBaseUrl()}/${id}`, payload));
  }

  getRiskLevels(id: string) {
    return lastValueFrom(
      this.api.get<RiskLevel[]>(`${this.getBaseUrl()}/${id}/risk-level-options`),
    );
  }

  getLatest(payload: RiskAssessmentReportLatestPayload) {
    return lastValueFrom(
      this.api.post<LatestRiskAssessmentReport[]>(`${this.getBaseUrl()}/latest`, payload),
    );
  }
}
