<mat-accordion
  *ngFor="let location of locations; let last = last"
  [togglePosition]="expandArrowPosition"
>
  <mat-expansion-panel class="mat-elevation-z0" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="header-title">
          {{ location.name }}
        </div>
        <div class="tags-container flex-wrap no-padding">
          @for (locationType of location.types; track $index) {
            <app-tag
              [tag]="buildLocationTypeTag(locationType)"
              [maxTextLengthToShow]="20"
            ></app-tag>
          }

          <app-tag [tag]="buildTag(location.address.countryName)">
            <app-flag [country]="location.address.country"></app-flag>
          </app-tag>
        </div>
      </mat-panel-title>
      <app-button
        (pressed)="onRemove(location.id)"
        [visible]="canRemove"
        [tp]="translations.removeLocationTp"
        tpPlacement="bottom"
        size="large"
        type="icon"
      >
        <mat-icon>close</mat-icon>
      </app-button>
    </mat-expansion-panel-header>
    <ng-container *ngIf="isShowCertificates">
      <b class="dark-gray" i18n>Certificates</b>
      <div *ngIf="!location.certificates?.length" class="no-items" i18n>
        No certificates available
      </div>
      <app-certificates-table
        *ngIf="location.certificates?.length"
        [certificates]="location.certificates"
        [allStandards]="allStandards"
        [areButtonsEnabled]="areCertificatesButtonsEnabled"
        [isSearchEnabled]="false"
        class="no-min-height"
        [isRecordStateFilterEnabled]="false"
        [isPaginatorEnabled]="false"
        [isShowSelectCheckbox]="isShowTableSelectCheckbox"
        (setCertificates)="onSetCertificates(location, $event)"
      ></app-certificates-table>
      <br />
    </ng-container>
    <ng-container *ngIf="isShowDocuments">
      <b class="dark-gray" i18n>Documents</b>
      <div *ngIf="!location.documents?.length" class="no-items" i18n>No documents available</div>
      <app-documents-table
        *ngIf="location.documents?.length"
        [documents]="location.documents"
        [allDocumentTypes]="allDocumentTypes"
        [areButtonsEnabled]="true"
        [isSearchEnabled]="false"
        class="no-min-height"
        [isRecordStateFilterEnabled]="false"
        [isPaginatorEnabled]="false"
        [isShowSelectCheckbox]="isShowTableSelectCheckbox"
        (setDocuments)="onSetDocuments(location, $event)"
      ></app-documents-table>
      <br />
    </ng-container>
    <ng-container *ngIf="isShowLinks">
      <b class="dark-gray" i18n>Links</b>
      <div *ngIf="!location.links?.length" class="no-items" i18n>No links available</div>
      @for (link of location.links; track link.from.id) {
        @if (link.from.id === location.id || link.to.id === location.id) {
          <div class="link">
            <div
              class="text-center one-line-wrap-ellipsis"
              [class.bold]="location.name === link.from.name"
            >
              {{ link.from.name }}
            </div>
            <mat-icon>arrow_right_alt</mat-icon>
            <div
              class="text-center one-line-wrap-ellipsis"
              [class.bold]="location.name === link.to.name"
            >
              {{ link.to.name }}
            </div>
          </div>
        }
      }
    </ng-container>
  </mat-expansion-panel>
  <hr *ngIf="!last" />
</mat-accordion>
