<article [class]="tableContainerClass">
  <app-table
    *ngIf="analysesTableService.columnDefs().length"
    (rowClick)="analysesTableService.openView($event)"
    [columnDefs]="analysesTableService.columnDefs()"
    [isLoading]="analysesTableService.isLoading()"
    [isRecordStateFilterEnabled]="false"
    [isFixedBottomPaginator]="isFixedBottomPaginator"
    [isSaveState]="isSaveTableState"
    [table]="table"
    [rowData]="analysesTableService.analyses()"
    [batchActionSettings]="analysesTableService.batchActionSettings"
    (getAll)="analysesTableService.getAll()"
  ></app-table>
</article>

<app-slide-overlay-wrapper
  [close]="!analysesTableService.isAnalysisDetailOverlayVisible()"
  (clickClose)="analysesTableService.closeView()"
>
  <div class="full-width">
    @if (analysesTableService.analysisDetailOverlayItem()) {
      <app-analysis-details-slide-over
        [element]="analysesTableService.analysisDetailOverlayItem()"
        (closeSlideOver)="analysesTableService.closeView()"
      ></app-analysis-details-slide-over>
    } @else {
      <div class="slide-over-content full-height">
        <app-loader></app-loader>
      </div>
    }
  </div>
</app-slide-overlay-wrapper>
