import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostListener,
  Input,
  QueryList,
  TemplateRef,
  OnDestroy,
  output,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { debounceTime, Subject, Subscription } from "rxjs";

import { SlideOverlayMenuItemDirective } from "@components/shared/overlay/slide-overlay-menu-item/slide-overlay-menu-item.directive";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CommonConstants, TextConstants } from "@shared/constants";
import { FeatureFlagEnum, OverlayTabEnum } from "@shared/enums";
import { NotificationService, AuthenticationService, FeatureFlagService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-slide-overlay-content",
  templateUrl: "./slide-overlay-content.component.html",
  styleUrls: ["./slide-overlay-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayContentComponent implements AfterViewInit, OnDestroy {
  @ContentChildren(SlideOverlayMenuItemDirective)
  menuItems: QueryList<SlideOverlayMenuItemDirective> = new QueryList();

  selectedContent: TemplateRef<unknown>;

  selectedMenuItem: SlideOverlayMenuItemDirective;

  @Input() contentClass: string;

  menuItemChanged = output<SlideOverlayMenuItemDirective>();

  protected readonly TemplateRef = TemplateRef;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly canExpandMenu = this.featureFlagService.isEnabled(
    FeatureFlagEnum.OVERLAY_NAVIGATION_EXPANSION,
  );

  private autoSizeMenuSubject = new Subject();

  private subscriptions = new Subscription();

  public slideOverMenuHeightStyle: string = "100%";

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.autoSizeMenuSubject.next(true);
  }

  constructor(
    protected overlay: SlideOverlayPageService,
    private changeDetectorRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
    private routerService: RouterService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.subscriptions.add(
      this.autoSizeMenuSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_RESIZE_WINDOW_TIME_MS))
        .subscribe(() => this.setSlideOverMenuHeightStyle()),
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.autoSizeMenuSubject.next(true);
    const idParam = this.route.snapshot.queryParams["id"];
    const tabParam = this.route.snapshot.queryParams["tab"];

    if (!idParam || !tabParam) {
      await this.selectDefaultMenuItem();
    } else {
      const tabTitle = CommonUtils.enumToText(tabParam);

      if (!this.doesMenuItemExist(tabTitle) && tabTitle !== OverlayTabEnum.SHARES) {
        await this.selectDefaultMenuItem();
      }
    }
  }

  private setSlideOverMenuHeightStyle = (): void => {
    const windowHeight = window.innerHeight;
    const slideOverMenuVerticalPadding = CommonUtils.getHtmlElementStyleValue(
      ".slide-overlay-menu",
      "padding",
      ["top", "bottom"],
    );
    const pageTitleContainerHeight =
      CommonUtils.getHtmlElementHeightWithMargins(".page-title-container");

    const availableHeight = windowHeight - slideOverMenuVerticalPadding - pageTitleContainerHeight;

    this.slideOverMenuHeightStyle = `${availableHeight}px`;
  };

  private doesMenuItemExist = (title: string): boolean => {
    if (!title) {
      return false;
    }

    title = title.toLowerCase();

    return this.menuItems.some((m) => m.title().toLowerCase() === title);
  };

  async selectDefaultMenuItem(): Promise<void> {
    await this.selectMenuItem(this.menuItems.first, false);
  }

  async selectMenuItem(
    menuItem: SlideOverlayMenuItemDirective,
    disableEditMode: boolean = true,
  ): Promise<void> {
    if (this.authenticationService.haveTokensExpired()) {
      this.notificationService.showError(TextConstants.SESSION_HAS_EXPIRED);
      this.overlay.close(true);
      this.authenticationService.logout();

      return;
    }

    let canChangeMenuItem: boolean;

    if (menuItem.disabled() || menuItem.active()) {
      return;
    }

    if (disableEditMode) {
      canChangeMenuItem = await this.overlay.enableViewMode();
    } else {
      canChangeMenuItem = true;
    }

    if (canChangeMenuItem) {
      this.menuItems.toArray().forEach((item) => item.active.set(false));
      menuItem.active.set(true);

      this.overlay.closeSideMenu();
      this.selectedMenuItem = menuItem;
      this.selectedContent = menuItem.templateRef;

      const isFirstMenuItemSelected = this.menuItems.toArray()[0].active();

      const queryParams = {
        tab: isFirstMenuItemSelected ? null : CommonUtils.textToEnum(menuItem.title()),
      };

      this.routerService.updateCurrentUrlParams(queryParams);

      this.changeDetectorRef.detectChanges();
      window.scrollTo({ top: 0, behavior: "smooth" });

      this.menuItemChanged.emit(menuItem);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
