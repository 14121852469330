import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";
import { ShareTargetTypeEnum, ShareTypeEnum } from "src/app/shared/enums";
import { ISharePayload } from "src/app/shared/interfaces";
import { SharesService } from "src/app/shared/services";
import { CustomValidators } from "src/app/shared/validators";

import { NotificationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./share-dialog.component.html",
  styleUrl: "./share-dialog.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareDialogComponent {
  public readonly shareTargetTypeEnum = ShareTargetTypeEnum;

  public isLoading = signal(false);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null),
  });

  public readonly translations: any = {
    emailLabel: $localize`Email address`,
    sharedHint: $localize`Note: any changes to the shared record or the related records or addition of any extra information (documents, certificates, etc.) will not be shared automatically. In case you make the aforementioned changes and need to include them in a share(s) - delete the old share(s) and create new ones.
                Rulesets and tags will not be shared.`,
  };

  constructor(
    public dialogRef: MatDialogRef<ShareDialogComponent>,
    private sharesService: SharesService,
    private notificationService: NotificationService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shareTargetType: ShareTargetTypeEnum;
      rootRecordUri: string;
    },
  ) {}

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };

  public onSubmit = async (): Promise<void> => {
    try {
      const isNotValidEmail = !(await this.validateEmail());

      if (isNotValidEmail) {
        return;
      }
      this.isLoading.set(true);

      const recipient = this.formGroup.controls["email"].value;
      const payload = await this.getSharePayload(recipient);

      await this.sharesService.create(payload);
      this.notificationService.showSuccess(
        `${CommonUtils.capitaliseFirstLetter(this.data.shareTargetType)} shared`,
      );
      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  private async getSharePayload(recipient: string): Promise<ISharePayload> {
    const records = await this.sharesService.getShareStructure(this.data.rootRecordUri);

    return {
      recipient,
      rootRecordUri: this.data.rootRecordUri,
      shareType: ShareTypeEnum.LINK,
      records,
    };
  }

  private async validateEmail(): Promise<boolean> {
    const control = this.formGroup.controls["email"];
    const errors = CustomValidators.email(control) || CustomValidators.required(control);

    if (errors) {
      control.setErrors(errors);
      control.markAsDirty();

      return false;
    }
    const nameExists = (
      await lastValueFrom(
        this.sharesService.getPageSubscription(this.data.rootRecordUri, control.value),
      )
    ).content.length;

    if (nameExists) {
      control.setErrors({
        entityAlreadyExistsMatch: "This record has already been shared with this email",
      });

      return false;
    }

    return true;
  }
}
