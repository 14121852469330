@if (isLoading()) {
  <app-loader />
} @else {
  <app-table
    #tableComponent
    [table]="table"
    [isLoading]="isLoading()"
    [rowData]="rowData()"
    [columnDefs]="columnDefs()"
    [areButtonsEnabled]="areButtonsEnabled()"
    [isSearchEnabled]="isSearchEnabled()"
    [isRecordStateFilterEnabled]="isRecordStateFilterEnabled()"
    [isPaginatorEnabled]="isPaginatorEnabled()"
    [isFixedBottomPaginator]="isFixedBottomPaginator()"
    [isSaveState]="isSaveTableState()"
    [recordState]="recordState()"
    [areExpandButtonsEnabled]="true"
    [rowGroupColumns]="rowGroupColumns()"
    [autoGroupColumnDefParams]="autoGroupColumnDef()"
    (rowClick)="onView($event)"
  >
    <div tag-filters class="container-flex mt-12">
      <app-tag-group
        (tagPressed)="onSelectedRiskLevel($event)"
        [tags]="riskLevelsTags()"
        [isTagClickable]="true"
      ></app-tag-group>
    </div>
  </app-table>
}
