import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import {
  AdminOrganisationDetailsComponent,
  AdminOrganisationsComponent,
  ForgotPasswordComponent,
  InvitationsComponent,
  ResetPasswordComponent,
  SignUpComponent,
  VerifyComponent,
  SettingsStandardsComponent,
  SharingComponent,
  LogoutComponent,
  ReportsEudrComponent,
  InboxComponent,
  AdminLocationDetailsComponent,
  TransferOrMapDataComponent,
  SharedLinkComponent,
  DeliveryReportComponent,
  SharedSupplyChainComponent,
  MaterialsComponent,
  ReportsEudrDdsComponent,
} from "./components";
import { ExtensionProviderEnum, FeatureFlagEnum, RouteEnum } from "./shared/enums";
import {
  LoggedInGuard,
  NotLoggedInGuard,
  DefaultRouteGuard,
  AdminSectionGuard,
  SystemAdminGuard,
  RegularUserGuard,
  AccountOwnerOrAdminOrContributorGuard,
  FeatureFlagGuard,
  SharedUserGuard,
} from "./shared/guards";

const routes: Routes = [
  {
    path: RouteEnum.LOCATIONS,
    loadChildren: () =>
      import("./components/locations/locations.module").then((m) => m.LocationsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.ORGANISATIONS,
    loadChildren: () =>
      import("./components/organisations/organisations.module").then((m) => m.OrganisationsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.CERTIFICATES,
    loadChildren: () =>
      import("./components/certificates/certificates.module").then((m) => m.CertificatesModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.DELIVERIES,
    loadChildren: () =>
      import("./components/deliveries/deliveries.module").then((m) => m.DeliveriesModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.ITEMS,
    loadChildren: () => import("./components/items/items.module").then((m) => m.ItemsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.DOCUMENTS,
    loadChildren: () =>
      import("./components/documents/documents.module").then((m) => m.DocumentsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.ADMIN_ORGANISATIONS,
    component: AdminOrganisationsComponent,
    canActivate: [LoggedInGuard, SystemAdminGuard],
  },
  {
    path: `${RouteEnum.ADMIN_ORGANISATION_DETAILS}/:id`,
    component: AdminOrganisationDetailsComponent,
    canActivate: [LoggedInGuard, SystemAdminGuard],
  },
  {
    path: `${RouteEnum.ADMIN_LOCATIONS_DETAILS}/:id`,
    component: AdminLocationDetailsComponent,
    canActivate: [LoggedInGuard, SystemAdminGuard],
  },
  {
    path: RouteEnum.ADMIN_USERS,
    loadChildren: () =>
      import("./components/admin/users/admin-users.module").then((m) => m.AdminUsersModule),
    canActivate: [LoggedInGuard, SystemAdminGuard],
  },
  {
    path: RouteEnum.ADMIN_STANDARDS,
    component: SettingsStandardsComponent,
    canActivate: [LoggedInGuard, SystemAdminGuard],
  },
  {
    path: RouteEnum.ADMIN,
    loadChildren: () => import("./components/settings/admin.module").then((m) => m.AdminModule),
    canActivate: [LoggedInGuard, AdminSectionGuard],
  },
  {
    path: RouteEnum.USER_SETTINGS,
    loadChildren: () =>
      import("./components/user-settings/user-settings.module").then((m) => m.UserSettingsModule),
    canActivate: [LoggedInGuard, RegularUserGuard],
  },
  {
    path: RouteEnum.INVITATIONS,
    component: InvitationsComponent,
    canActivate: [LoggedInGuard, RegularUserGuard],
  },
  {
    path: RouteEnum.PRODUCTS,
    loadChildren: () =>
      import("./components/products/products.module").then((m) => m.ProductsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.MATERIALS,
    component: MaterialsComponent,
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.SUPPLY_CHAINS,
    loadChildren: () =>
      import("./components/supply-chains/supply-chains.module").then((m) => m.SupplyChainsModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.PROCESSES,
    loadChildren: () =>
      import("./components/processes/processes.module").then((m) => m.ProcessesModule),
    canActivate: [LoggedInGuard],
  },
  {
    path: RouteEnum.INBOX,
    component: InboxComponent,
    canActivate: [LoggedInGuard, FeatureFlagGuard, AccountOwnerOrAdminOrContributorGuard],
    data: { featureFlag: FeatureFlagEnum.CROSS_ORGANISATION_SHARING },
  },
  {
    path: `${RouteEnum.INBOX_TRANSFER_OR_MAP}/:id`,
    component: TransferOrMapDataComponent,
    canActivate: [LoggedInGuard, FeatureFlagGuard, AccountOwnerOrAdminOrContributorGuard],
    data: { featureFlag: FeatureFlagEnum.CROSS_ORGANISATION_SHARING },
  },
  {
    path: `${RouteEnum.INBOX_SHARED_SUPPLY_CHAIN}/:id`,
    component: SharedSupplyChainComponent,
    canActivate: [LoggedInGuard, FeatureFlagGuard, AccountOwnerOrAdminOrContributorGuard],
    data: { featureFlag: FeatureFlagEnum.CROSS_ORGANISATION_SHARING },
  },
  {
    path: RouteEnum.LOGIN,
    loadChildren: () => import("./components/guest/login/login.module").then((m) => m.LoginModule),
    canActivate: [NotLoggedInGuard],
  },
  {
    path: RouteEnum.LOGOUT,
    component: LogoutComponent,
  },
  {
    path: RouteEnum.SIGN_UP,
    component: SignUpComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: RouteEnum.VERIFY,
    component: VerifyComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: RouteEnum.FORGOT_PASSWORD,
    component: ForgotPasswordComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: RouteEnum.RESET_PASSWORD,
    component: ResetPasswordComponent,
    canActivate: [NotLoggedInGuard],
  },
  {
    path: RouteEnum.SHARING,
    component: SharingComponent,
  },
  {
    path: `${RouteEnum.EUDR_REPORT}/:id`,
    component: ReportsEudrComponent,
    canActivate: [LoggedInGuard, RegularUserGuard, FeatureFlagGuard],
    data: {
      extensionProvider: ExtensionProviderEnum.EUDR,
      featureFlag: FeatureFlagEnum.EUDR_REPORT,
    },
  },
  {
    path: `${RouteEnum.EUDR_DDS}/:id`,
    component: ReportsEudrDdsComponent,
    canActivate: [LoggedInGuard, RegularUserGuard, FeatureFlagGuard],
    data: {
      extensionProvider: ExtensionProviderEnum.EUDR,
      featureFlag: FeatureFlagEnum.EUDR_REPORT,
    },
  },
  {
    path: `${RouteEnum.DELIVERY_REPORT}/:id`,
    component: DeliveryReportComponent,
    canActivate: [LoggedInGuard, RegularUserGuard],
  },
  {
    path: RouteEnum.SHARED_LINK,
    component: SharedLinkComponent,
    canActivate: [SharedUserGuard],
  },
  {
    path: "",
    pathMatch: "full",
    canActivate: [DefaultRouteGuard],
    children: [],
  },
  {
    path: "**",
    canActivate: [DefaultRouteGuard],
    children: [],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
