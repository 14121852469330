import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { EudrProducer } from "@components/reports/eudr/eudr-producer";
import { EudrProducerGroup } from "@components/reports/eudr/eudr-producer-group";
import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { UnitOfMeasurementCategoryTypeEnum } from "@shared/enums";
import {
  IItemSupplyChain,
  ILocationExtended,
  ILocationType,
  IMaterialExtended,
  IOrganisation,
} from "@shared/interfaces";

import { EudrItem } from "./eudr-item";
import { ReportsEudrModel as Model } from "./reports-eudr.model";

export interface LocationWithOrganisationCountry {
  location: ILocationExtended;
  organisation: IOrganisation;
  countryName: string;
  organisationName: string;
}

export class EudrItemGroup {
  public customRequiredFields: Model.RequiredCustomFieldValues =
    {} as Model.RequiredCustomFieldValues;

  public eudrItems: EudrItem[] = [];

  public locationTypesWithPointOfOrigin: ILocationType[] = [];

  private supplyChains: IItemSupplyChain[];

  private itemSupplyChainMapperService: ItemSupplyChainMapperService;

  constructor(
    customRequiredFields: Model.RequiredCustomFieldValues,
    eudrItems: EudrItem[],
    itemSupplyChainMapperService: ItemSupplyChainMapperService,
  ) {
    this.customRequiredFields = customRequiredFields;
    this.eudrItems = eudrItems;
    this.itemSupplyChainMapperService = itemSupplyChainMapperService;
  }

  public static buildGroups(
    eudrItemsMap: Map<Model.requiredCustomFieldValue, EudrItem[]>,
    itemSupplyChainMapperService: ItemSupplyChainMapperService,
  ): EudrItemGroup[] {
    const eudrItemsMapList = Array.from(eudrItemsMap);
    const eudrItemGroups: EudrItemGroup[] = [];

    for (const eudrItemRaw of eudrItemsMapList) {
      const customRequiredFieldsValues = JSON.parse(eudrItemRaw[0]);

      const customRequiredFields: Model.RequiredCustomFieldValues =
        {} as Model.RequiredCustomFieldValues;

      for (let index = 0; index < customRequiredFieldsValues.length; index++) {
        const label = Model.reportCustomFieldLabels[index];

        customRequiredFields[label] = customRequiredFieldsValues[index];
      }

      const eudrItems = eudrItemRaw[1];

      eudrItemGroups.push(
        new EudrItemGroup(customRequiredFields, eudrItems, itemSupplyChainMapperService),
      );
    }

    return eudrItemGroups.sort((itemGroup1, itemGroup2) => {
      return itemGroup1.customRequiredFields[Model.ReportCustomFieldEnum.HS_CODE].localeCompare(
        itemGroup2.customRequiredFields[Model.ReportCustomFieldEnum.HS_CODE],
      );
    });
  }

  public async buildMaterialsInfoForms(
    materials: IMaterialExtended[],
  ): Promise<UntypedFormGroup[]> {
    await this.buildSupplyChains();

    return this.getUniqueMaterialsFromUris(materials).map((material) => {
      return new UntypedFormGroup({
        scientificName: new UntypedFormControl(
          this.getMaterialCustomFieldValue(material, Model.MaterialCustomFieldEnum.SCIENTIFIC_NAME),
        ),
        commonName: new UntypedFormControl(
          this.getMaterialCustomFieldValue(material, Model.MaterialCustomFieldEnum.COMMON_NAME),
        ),
        materialName: new UntypedFormControl(material.name),
        materialId: new UntypedFormControl(material.id),
      });
    });
  }

  private getMaterialCustomFieldValue(
    material: IMaterialExtended,
    customFieldLabel: Model.MaterialCustomFieldEnum,
  ): string {
    return (
      material.customFields.find(
        (customField) =>
          customField.definition.label.toLowerCase() === customFieldLabel.toLowerCase(),
      )?.value || "-"
    );
  }

  private getUniqueMaterialsFromUris(materials: IMaterialExtended[]): IMaterialExtended[] {
    const items = this.supplyChains
      .filter((supplyChain) => {
        return supplyChain.locations.some((location) =>
          location.types.some((type) => type.pointOfOrigin),
        );
      })
      .map((supplyChain) => supplyChain.item);

    const materialIds = items.map((item) => item.materials.map((material) => material.id)).flat();
    const uniqueMaterialIds = Array.from(new Set(materialIds));

    return uniqueMaterialIds
      .map((materialId) => {
        return materials.find((material) => material.id === materialId);
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  private get sameBaseUnitForAll(): boolean {
    const baseUnit = this.eudrItems[0].baseUnit;

    return this.eudrItems.every((eudrItem) => eudrItem.baseUnit.id === baseUnit.id);
  }

  public get netMass(): number {
    if (
      !this.sameBaseUnitForAll ||
      this.eudrItems[0].baseUnit.type !== UnitOfMeasurementCategoryTypeEnum.MASS
    ) {
      return null;
    }

    return this.sumQuantity;
  }

  public get volume(): number {
    if (
      !this.sameBaseUnitForAll ||
      this.eudrItems[0].baseUnit.type !== UnitOfMeasurementCategoryTypeEnum.VOLUME
    ) {
      return null;
    }

    return this.sumQuantity;
  }

  public get displayUnitWarning(): boolean {
    return this.netMass === null && this.volume === null;
  }

  public get sumQuantity(): number {
    return this.eudrItems.reduce((acc, eudrItem) => acc + eudrItem.quantity, 0);
  }

  private async buildSupplyChains(): Promise<void> {
    if (this.supplyChains) {
      return;
    }

    const itemIds = this.eudrItems.map((eudrItem) => eudrItem.item.id);

    this.supplyChains = await this.itemSupplyChainMapperService.mapItemSupplyChain(itemIds);
  }

  public async buildEudrProducers(organisations: IOrganisation[]): Promise<EudrProducer[]> {
    await this.buildSupplyChains();

    const filteredLocations = this.supplyChains
      .flatMap((supplyChain) => supplyChain.locations)
      .filter(
        (location1, index, location2) =>
          index === location2.findIndex((l) => l.id === location1.id),
      )
      .filter((location) =>
        (location.types || []).some((locationType) => locationType.pointOfOrigin),
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    const locations: LocationWithOrganisationCountry[] = filteredLocations.map((location) => {
      const organisation = this.getOrganisationForLocation(location, organisations);

      return {
        countryName: location.address.country,
        organisationName: organisation.name,
        location,
        organisation,
      };
    });

    const groupedLocations = locations.reduce(
      (acc, location) => {
        const key = `${location.organisationName}-${location.countryName}`;

        if (!acc[key]) {
          acc[key] = {
            organisationName: location.organisationName,
            countryName: location.countryName,
            organisation: location.organisation,
            locations: [],
          };
        }

        acc[key].locations.push(location.location);

        return acc;
      },
      {} as Record<
        string,
        {
          organisation: IOrganisation;
          organisationName: string;
          countryName: string;
          locations: ILocationExtended[];
        }
      >,
    );

    return EudrProducerGroup.buildProducers(groupedLocations);
  }

  private getOrganisationForLocation(
    location: ILocationExtended,
    organisations: IOrganisation[],
  ): IOrganisation {
    return organisations.find((organisation) => organisation.name === location.organisationName);
  }
}
