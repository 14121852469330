import { FormControl } from "@angular/forms";

import { TextConstants } from "@shared/constants";
import { IProductExtended } from "@shared/interfaces";

export namespace ReportsEudrModel {
  export enum ActivityEnum {
    DOMESTIC = "DOMESTIC",
    IMPORT = "IMPORT",
    EXPORT = "EXPORT",
  }

  export enum ReportCustomFieldEnum {
    HS_CODE = "HS Code",
    DESCRIPTION = "Description",
  }

  export const areaCustomFieldLabel = TextConstants.AREA_HA;
  export const noGPSDataValue = $localize`None`;
  export const operatorSectionText = $localize`Information depends on which data you have in the EU IS system.`;

  export const requiredCustomFieldsNeedingValuesLabels = [ReportCustomFieldEnum.HS_CODE];

  export const reportCustomFieldLabels = [
    ...requiredCustomFieldsNeedingValuesLabels,
    ReportCustomFieldEnum.DESCRIPTION,
  ];

  export type requiredCustomFieldValue = string;
  export type RequiredCustomFieldValues = {
    [label in ReportCustomFieldEnum]: requiredCustomFieldValue;
  };

  export interface IProductWithDescription {
    product: IProductExtended;
    descriptionCustomFieldValue: string;
    customFieldValuesToGroup: string[];
  }

  export interface IHsCode {
    hscode: string | number;
    description: string;
  }

  export enum MaterialCustomFieldEnum {
    SCIENTIFIC_NAME = "Scientific name",
    COMMON_NAME = "Common name",
  }

  export interface ReferenceFormData {
    referenceNumber: FormControl<string>;
    verificationNumber: FormControl<string>;
  }

  export const hsCodesJsonPath = "/assets/data/hs_codes.json";

  export const ddsSavedSuccessMessage = $localize`EUDR due diligence statement saved`;
}
