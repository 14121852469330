<div class="row-container">
  <form [formGroup]="formGroup">
    <div class="row row-4 header-row">
      <div class="details-label" i18n>Received record</div>
      <div class="details-label"></div>
      <div class="details-label" i18n>Your record</div>
      <div class="details-label"></div>
    </div>
    @if (isLoading()) {
      <div *ngFor="let _ of sharedData()" class="row row-4 info-box with-border">
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
        <div class="details-value">
          <div class="skeleton"></div>
        </div>
      </div>
    } @else {
      @for (type of sharedData(); track type.id; let idx = $index) {
        <div class="row row-4 info-box with-border">
          <div class="details-value type-flex">
            @if (
              shareRecordType() === inboundShareDataTypeEnum.DOCUMENT_TYPES ||
              shareRecordType() === inboundShareDataTypeEnum.LOCATION_TYPES ||
              shareRecordType() === inboundShareDataTypeEnum.PROCESS_TYPES
            ) {
              <p>
                {{
                  type.name ||
                    type.processId ||
                    type.type ||
                    type.deliveryId ||
                    type.number ||
                    type.itemId
                }}
              </p>
            } @else {
              <p class="clickable" (keyup)="onView(type)" tabindex="0" (click)="onView(type)">
                {{
                  type.name ||
                    type.processId ||
                    type.type ||
                    type.deliveryId ||
                    type.number ||
                    type.itemId
                }}
              </p>
            }
          </div>
          <div class="details-value">
            @if (type.mappedRecordInfo) {
              <app-button
                class="large"
                type="icon"
                [tp]="translations.deleteTp"
                (pressed)="removeMapping(type)"
              >
                <mat-icon>delete</mat-icon>
              </app-button>
            } @else {
              <app-button
                class="large"
                [tp]="translations.transferTp"
                type="icon"
                [disabled]="isTransferButtonDisabled()"
                (pressed)="transferInboundRecord(type, idx)"
              >
                <mat-icon>arrow_forward</mat-icon>
              </app-button>
            }
          </div>
          <div class="details-value">
            <app-input-select
              class="no-padding"
              [showOptionalHint]="false"
              [placeholder]="inputSelectPlaceholder()"
              [options]="localRecords()"
              [disabled]="!!type.mappedRecordInfo"
              [formControlName]="idx"
            ></app-input-select>
            @if (formGroup.controls[idx].value && !type.mappedRecordInfo) {
              <app-button
                class="large"
                type="ghost"
                (pressed)="addInboundMapping(idx)"
                [tp]="translations.addTp"
              >
                <ng-container i18n>Confirm</ng-container>
                <mat-icon>check</mat-icon>
              </app-button>
            }
          </div>
          <div class="details-value">
            <app-button
              [disabled]="isViewButtonDisabled()"
              class="large"
              type="icon"
              [tp]="translations.viewTp"
              (pressed)="onView(type)"
            >
              <mat-icon>visibility</mat-icon>
            </app-button>
          </div>
        </div>
      }
    }
  </form>
</div>
