<div [formGroup]="formGroup" class="row-container row row-with-index">
  <div class="production-place-index">
    {{ index + 1 }}
  </div>
  <app-input formControlName="description" [label]="translations.descriptionLabel"></app-input>
  <app-input formControlName="area" [label]="translations.areaLabel"></app-input>
  <app-input
    formControlName="type"
    [label]="translations.typeLabel"
    tooltipIcon="alert-triangle"
    [isSvgIcon]="true"
    iconClass="warn-icon"
    [tooltip]="formGroup.controls['type'].value === 'None' ? translations.noGpsTooltip : null"
  ></app-input>
</div>
