import {
  Component,
  inject,
  OnInit,
  ViewChild,
  HostListener,
  signal,
  ChangeDetectionStrategy,
  computed,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import {
  BulkAddLocationsModel,
  BulkAddLocationsModel as Model,
} from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { BulkAddLocationsService } from "@components/locations/bulk-add-locations/bulk-add-locations.service";
import { BulkAddLocationsEnterRecordsComponent } from "@components/locations/bulk-add-locations/enter-records/bulk-add-locations-enter-records.component";
import { BulkAddLocationsSetValuesComponent } from "@components/locations/bulk-add-locations/set-values/bulk-add-locations-set-values.component";
import { ConfirmDialogComponent } from "@components/shared";
import { BulkAddSlideOverClass } from "@components/shared/bulk-add-slide-over/bulk-add-slide-over.class";
import { StepperService } from "@components/shared/stepper/stepper.service";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum } from "@shared/enums";
import { NotificationService, AuthenticationService } from "@shared/services";
import { FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-bulk-add-locations",
  templateUrl: "./bulk-add-locations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddLocationsComponent
  extends BulkAddSlideOverClass<FormGroup<BulkAddLocationsModel.SetValuesFormGroup>>
  implements OnInit
{
  @ViewChild("bulkAddLocationsSetValues")
  public bulkAddLocationsSetValuesComponent: BulkAddLocationsSetValuesComponent;

  @ViewChild("bulkAddLocationsEnterRecords")
  public bulkAddLocationsEnterRecordsComponent: BulkAddLocationsEnterRecordsComponent;

  public bulkAddLocationsService: BulkAddLocationsService = inject(BulkAddLocationsService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private notificationService: NotificationService = inject(NotificationService);

  private dialog: MatDialog = inject(MatDialog);

  public stepper: StepperService = inject(StepperService);

  public activeOrganisationId: string = this.authenticationService.getActiveOrganisationId();

  public isLoading = signal<boolean>(true);

  public isWaitingForNextStep = signal<boolean>(false);

  public displayDoneButton = signal<boolean>(false);

  private readonly fieldEnum = Model.FieldEnum;

  public readonly stepsEnum = Model.StepsEnum;

  public createLocationsMsg = computed(() => {
    return $localize`Create ${this.bulkAddLocationsService.recordsLength()}:recordsCount: location(s)`;
  });

  public readonly translations: any = {
    title: $localize`Bulk add locations`,
    firstStepTitle: $localize`Set values`,
    secondStepTitle: $localize`Enter locations`,
    thirdStepTitle: $localize`Create locations`,
  };

  @HostListener("window:beforeunload")
  canDeactivate(): boolean {
    const hasChanged = this.bulkAddLocationsService.hasInitialFormValueChanged(
      this.formGroup.getRawValue(),
    );

    return !hasChanged;
  }

  public async ngOnInit(): Promise<void> {
    await this.bulkAddLocationsService.initializeProperties();

    this.bulkAddLocationsService.setupForm();

    this.isLoading.set(false);
  }

  public override afterClose(): void {
    this.bulkAddService.removeAssociatedQueryParams();
  }

  public get formGroup(): FormGroup<Model.SetValuesFormGroup> {
    return this.bulkAddLocationsService.formGroup;
  }

  public override slideOverWrapperClass(): string {
    return this.stepper.currentStepIndex() === 1 ? "wide" : "";
  }

  public override async canClose(): Promise<boolean> {
    return await this.bulkAddLocationsService.canExitSlideOver();
  }

  public onClickBackToFirstStep(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        contentText: $localize`Are you sure you want to go back to the 1st step? Doing so will discard all of the already added locations.`,
        confirmButtonText: TextConstants.DISCARD_CHANGES_GO_BACK,
        confirmButtonColor: "danger",
        confirmButtonIcon: "arrow_back",
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.formGroup.controls[this.fieldEnum.RECORDS].clear();
        this.stepper.goToPreviousStep();
      }
    });
  }

  public get isCurrentStepEnterRecords(): boolean {
    return this.stepper.currentStepIndex() === Model.StepsEnum.ENTER_RECORDS;
  }

  public get isNextButtonDisabled(): boolean {
    return this.bulkAddLocationsService.isNextButtonDisabled();
  }

  public async onClickNext(): Promise<void> {
    let canGoNext: boolean = true;

    if (this.stepper.currentStepIndex() === Model.StepsEnum.SET_VALUES) {
      this.isWaitingForNextStep.set(true);
      canGoNext = await this.bulkAddLocationsSetValuesComponent.onClickNext();
      this.isWaitingForNextStep.set(false);
    }

    if (this.stepper.currentStepIndex() === Model.StepsEnum.SET_VALUES && this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);

      canGoNext = false;
    }

    if (this.isCurrentStepEnterRecords) {
      canGoNext = this.bulkAddLocationsEnterRecordsComponent.onClickNext();
    }

    if (canGoNext) {
      this.stepper.goToNextStep();
    } else {
      this.notificationService.showError($localize`Please resolve all errors before proceeding`);
    }
  }

  public onClickCancel(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        contentText: $localize`Are you sure you want to cancel the creation of the remaining locations?`,
      },
    });

    dialogRef.afterClosed().subscribe((result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.bulkAddLocationsService.abortCreation();
      }
    });
  }

  public onClickDone(): void {
    this.bulkAddLocationsService.refreshTableSubject.next(undefined);

    this.bulkAddService.removeAssociatedQueryParams();
  }

  public onFinishedCreationProcess(): void {
    this.displayDoneButton.set(true);
  }
}
