import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";

import { filter } from "rxjs";

import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";
import { ILocationLinkDetail, ISelectOption, ISupplyChainGraphQl } from "@shared/interfaces";
import {
  NotificationService,
  CommonService,
  ConnectionsService,
  RecordSharingService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-shared-supply-chain",
  templateUrl: "./shared-supply-chain.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedSupplyChainComponent implements AfterViewInit {
  private readonly route = inject(ActivatedRoute);

  private readonly notification = inject(NotificationService);

  private readonly recordSharingService = inject(RecordSharingService);

  private readonly commonService = inject(CommonService);

  private connectionsService = inject(ConnectionsService);

  private readonly cdr = inject(ChangeDetectorRef);

  public supplyChainHeight = signal<number>(CommonUtils.getNonOverlaySupplyChainHeight());

  public inboundSupplyChain = signal<ISupplyChainGraphQl>(null);

  public locations = signal<any[]>([]);

  public locationLinks = signal<ILocationLinkDetail[]>([]);

  public isLoading = signal<boolean>(true);

  private countryOptions = signal<ISelectOption[]>([]);

  private senderOrgId = this.route.snapshot.queryParams["senderOrgId"];

  public readonly routingEnum = RouteEnum;

  public readonly translations: any = {
    title: $localize`Supply chain details`,
    titleBackText: TextConstants.BACK_TO_INBOX,
    titleHint: $localize`Click and drag to navigate the supply chain and use the mouse wheel / touch pad to zoom in/out.`,
  };

  constructor() {
    this.commonService.countriesOptionsObservable$
      .pipe(
        filter((countries) => !!countries),
        takeUntilDestroyed(),
      )
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions.set(countriesOptions);
      });
  }

  async loadRecord(): Promise<void> {
    if (!this.recordId || !this.senderOrganisationId) {
      this.notification.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }
    try {
      const inboundSupplyChain = await this.recordSharingService.getInboundSupplyChainGraphQl(
        this.senderOrganisationId,
        this.recordId,
        ["INBOUND_LOCATION_LINKS", "LOCATIONS"],
      );

      this.inboundSupplyChain.set(inboundSupplyChain);
      const connection = await this.connectionsService.get(this.senderOrganisationId);

      this.locationLinks.set(inboundSupplyChain.links);
      let locations = inboundSupplyChain.locations.map((l) => {
        const documents = l.location.documents.map((d) => ({
          ...d,
          isSelected: true,
        }));
        const certificates = l.location.certificates.map((d) => ({
          ...d,
          isSelected: true,
        }));

        return {
          ...l.location,
          documents,
          certificates,
          organisationName: connection.name,
          organisationId: connection.id,
        };
      });

      locations = CommonUtils.getElementsWithCountryName(this.countryOptions(), locations);
      this.locations.set(locations);
      this.cdr.markForCheck();

      this.isLoading.set(false);
      this.cdr.detectChanges();
    } catch (error) {
      this.notification.showError(error);
    }
  }

  async ngAfterViewInit() {
    await this.loadRecord();
  }

  get recordId(): string {
    return this.route.snapshot.params["id"];
  }

  get senderOrganisationId(): string {
    return this.senderOrgId;
  }
}
