@if (refreshTable$ | async) {}

@if (columnDefs().length) {
  <app-table
    [table]="table"
    [columnDefs]="columnDefs()"
    [rowData]="rowData()"
    [areButtonsEnabled]="areButtonsEnabled()"
    [isLoading]="isLoading()"
    [isFixedBottomPaginator]="isFixedBottomPaginator()"
    [isPaginatorEnabled]="isPaginatorEnabled()"
    [isRecordStateFilterEnabled]="false"
    [isSaveState]="isSaveTableState()"
    [isSearchEnabled]="isSearchEnabled()"
    [batchActionSettings]="batchActionSettings()"
    (getAll)="getAll()"
    (rowClick)="onEdit($event)"
    (rowDataUpdated)="onRowDataUpdated($event)"
  />
}
<ng-template #countryFlag let-country>
  <app-flag [country]="country"></app-flag>
</ng-template>
