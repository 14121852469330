<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title *ngIf="!isShowSavedApiKey">
    @if (isEditing()) {
      <ng-container i18n>Edit API key</ng-container>
    } @else {
      <ng-container i18n>Add API key</ng-container>
    }
  </h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <div *ngIf="isShowSavedApiKey" class="text-center">
        <p i18n>Here's your new key</p>
        <p i18n>Copy it now! This is the only time we'll show it to you.</p>
        <div class="info-hint">
          <div class="text-center">
            <app-value-and-copy-button [value]="savedApiKey"></app-value-and-copy-button>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isShowSavedApiKey">
        <app-input
          [autofocus]="!isLoading() && !isEditing()"
          formControlName="name"
          [label]="translations.name"
        ></app-input>

        <app-input formControlName="note" [label]="translations.description"></app-input>

        @if (isEditing()) {
          <app-datepicker
            [label]="translations.expirationDate"
            formControlName="expiry"
          ></app-datepicker>

          <app-button-toggle formControlName="enabled" i18n>Enabled</app-button-toggle>
        } @else {
          <app-input-select
            [options]="expiryOptions"
            formControlName="expiry"
            [label]="translations.expirationDate"
          ></app-input-select>
        }
      </ng-container>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button (pressed)="onClose(true)" [visible]="isShowSavedApiKey" i18n>Close</app-button>

      <app-button-group [visible]="!isShowSavedApiKey">
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button
          [disabled]="isSubmitButtonDisabled()"
          [visible]="!isShowSavedApiKey"
          accessibilityRole="submit"
        >
          <mat-icon icon-left>check</mat-icon>

          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  </ng-container>
</form>
