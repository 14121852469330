import { registerLocaleData } from "@angular/common";
import esAngularlocale from "@angular/common/locales/es";
import frAngularlocale from "@angular/common/locales/fr";
import { Inject, inject, Injectable } from "@angular/core";

import { AG_GRID_LOCALE_EN, AG_GRID_LOCALE_ES, AG_GRID_LOCALE_FR } from "@ag-grid-community/locale";
import * as i18nIsoCountries from "i18n-iso-countries";
import enCountryLocale from "i18n-iso-countries/langs/en.json";
import esCountryLocale from "i18n-iso-countries/langs/es.json";
import frCountryLocale from "i18n-iso-countries/langs/fr.json";
import moment from "moment";

import { IConfig } from "@shared/interfaces";
import { APP_CONFIG } from "@shared/tokens";
import { CommonUtils, LocalizationUtils } from "@shared/utils";

import { CommonConstants } from "../constants";
import {
  EnvironmentEnum,
  FeatureFlagEnum,
  LanguageEnum,
  RouteEnum,
  StorageKeyEnum,
} from "../enums";
import { FeatureFlagService } from "./feature-flag.service";
import { LocalStorageService } from "./local-storage.service";
import "moment/locale/es";
import "moment/locale/fr";

@Injectable({
  providedIn: "root",
})
export class LocalizationService {
  public agGridLocaleText: any;

  private language: LanguageEnum;

  private readonly localStorageService = inject(LocalStorageService);

  private readonly featureFlagService = inject(FeatureFlagService);

  constructor(@Inject(APP_CONFIG) private environment: IConfig) {}

  public isLanguageAvailable = (language: LanguageEnum): boolean => {
    const featureFlagLanguages = this.isLanguagesEnabled()
      ? this.featureFlagService.getStringFeatureValue(FeatureFlagEnum.LANGUAGES)
      : "";

    const featureFlagLanguageCodes = featureFlagLanguages?.length
      ? featureFlagLanguages.trim().toLowerCase().split(",")
      : [];

    return (
      featureFlagLanguageCodes.includes(language as LanguageEnum) &&
      CommonConstants.AVAILABLE_LANGUAGE_CODES.includes(language as LanguageEnum)
    );
  };

  public setInitialLanguage = async (): Promise<void> => {
    const storedLanguage = this.localStorageService.get(StorageKeyEnum.LANGUAGE) as LanguageEnum;
    const languageFromUrl = this.getLanguageFromUrl();
    const languageFromBrowserLocale =
      LocalizationUtils.getLanguageFromBrowserLocale() as LanguageEnum;
    let language: LanguageEnum;

    console.log(`Local storage language: ${storedLanguage}`);
    console.log(`URL language: ${languageFromUrl}`);
    console.log(`Browser locale language: ${languageFromBrowserLocale}`);

    if (storedLanguage) {
      if (this.isLanguageAvailable(storedLanguage)) {
        language = storedLanguage;
      } else {
        this.localStorageService.remove(StorageKeyEnum.LANGUAGE);
      }
    } else if (this.isLanguageAvailable(languageFromBrowserLocale)) {
      language = languageFromBrowserLocale;
    } else if (this.isLanguageAvailable(languageFromUrl)) {
      language = languageFromUrl;
    }

    if (!language) {
      language = CommonConstants.DEFAULT_LANGUAGE_CODE;
    }

    this.language = language;
    this.localStorageService.set(StorageKeyEnum.LANGUAGE, language);

    if (
      this.environment.name !== EnvironmentEnum.LOCAL_DEVELOPMENT &&
      language !== languageFromUrl
    ) {
      await this.changeLanguage(language);
    }
  };

  public changeLanguage = async (
    newLanguage: LanguageEnum,
    redirectRoute?: RouteEnum,
  ): Promise<void> => {
    const languageFromUrl = this.getLanguageFromUrl();

    if (!this.isLanguagesEnabled() || newLanguage === languageFromUrl) {
      return;
    }

    const oldLanguagePath =
      languageFromUrl === CommonConstants.DEFAULT_LANGUAGE_CODE ? "" : `/${languageFromUrl}`;
    const newLanguagePath =
      newLanguage === CommonConstants.DEFAULT_LANGUAGE_CODE ? "" : `/${newLanguage}`;

    let redirectPath: string;

    if (redirectRoute) {
      redirectPath = `${newLanguagePath}/${redirectRoute}`;
    } else {
      if (oldLanguagePath === "") {
        redirectPath = `${newLanguagePath}${location.pathname}`;
      } else {
        redirectPath = `${location.pathname.replace(oldLanguagePath, newLanguagePath)}`;
      }
    }

    console.log(
      `Language has changed. Redirecting to: ${`${location.origin}${redirectPath}${location.search}`}`,
    );

    this.language = newLanguage;
    this.localStorageService.set(StorageKeyEnum.LANGUAGE, newLanguage);

    CommonUtils.redirectToExternalUrl(`${location.origin}${redirectPath}${location.search}`);
    if (this.environment.name === EnvironmentEnum.LOCAL_DEVELOPMENT) {
      location.reload();
    }
  };

  private getLanguageFromUrl = (): LanguageEnum => {
    if (!this.isLanguagesEnabled() || location.pathname === "/") {
      return CommonConstants.DEFAULT_LANGUAGE_CODE;
    }
    const languageCode = location.pathname.toLowerCase().split("/")[1] as LanguageEnum;

    if (this.isLanguageAvailable(languageCode)) {
      return languageCode;
    }

    return CommonConstants.DEFAULT_LANGUAGE_CODE;
  };

  public getLanguage = (): LanguageEnum => this.language;

  public registerLocale = (): void => {
    registerLocaleData(esAngularlocale);
    registerLocaleData(frAngularlocale);

    let countryLocale: any;

    const pageSizeSelectorLabel = `${$localize`Records per page`}:`;

    switch (this.language) {
      case LanguageEnum.SPANISH:
        moment.locale(LanguageEnum.SPANISH);
        countryLocale = esCountryLocale;
        this.agGridLocaleText = {
          ...AG_GRID_LOCALE_ES,
          pageSizeSelectorLabel,
        };
        break;
      case LanguageEnum.FRENCH:
        moment.locale(LanguageEnum.FRENCH);
        countryLocale = frCountryLocale;
        this.agGridLocaleText = {
          ...AG_GRID_LOCALE_FR,
          pageSizeSelectorLabel,
        };
        break;
      default: {
        countryLocale = enCountryLocale;
        this.agGridLocaleText = {
          ...AG_GRID_LOCALE_EN,
          pageSizeSelectorLabel,
        };
        break;
      }
    }

    i18nIsoCountries.registerLocale(countryLocale);
    CommonUtils.loadScripts([
      {
        async: true,
        source: CommonConstants.ANGULAR_GOOGLE_MAPS_SCRIPT_URL(this.language),
      },
    ]);

    console.log(`Language selected: ${this.language}`);
    console.log(`Locale in browser: ${LocalizationUtils.getBrowserLocale()}`);
    console.log(`Locale in angular: ${LocalizationUtils.getLocaleForAngularConfig()}`);
    console.log(`Datepicker format: ${LocalizationUtils.getLocaleDatepickerFormat()}`);
  };

  private isLanguagesEnabled = () => this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);
}
