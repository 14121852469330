import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  inject,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { OverlayDocumentAttachmentsComponent } from "@components/shared";
import { CountriesApiService } from "@components/shared/countries/api";
import { Country } from "@components/shared/countries/models";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { OverlayRiskAssessmentReportsComponent } from "@components/shared/risk-assessment-reports/ui";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TextConstants } from "@shared/constants";
import { AttachmentTargetEnum, EntityTypeEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { CommonService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

import { CountryOverlayService } from "./country-overlay.service";

@Component({
  standalone: false,
  selector: "app-country-overlay",
  templateUrl: "./country-overlay.component.html",
  styleUrls: ["./country-overlay.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CountryOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("documentView") documentView: OverlayDocumentAttachmentsComponent;

  @ViewChild("riskAssessmentsView")
  readonly riskAssessmentsView: OverlayRiskAssessmentReportsComponent;

  private readonly countriesApi = inject(CountriesApiService);

  readonly countryOverlay = inject(CountryOverlayService);

  private readonly commonService = inject(CommonService);

  readonly form = this.formBuilder.group({
    name: ["", [CustomValidators.required]],
  });

  override element: Country;

  override readonly entityType = EntityTypeEnum.COUNTRIES;

  override readonly attachmentTargetType = AttachmentTargetEnum.COUNTRY;

  readonly templateResourceType = RiskAssessmentTemplateResourceType.COUNTRY;

  override readonly menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.COUNTRY_DETAILS, isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: true }],
      [
        OverlayTabEnum.RISK_ASSESSMENT_REPORTS,
        {
          title: TextConstants.RISK_ASSESSMENT_REPORTS,
          isEnabled: true,
          isHidden: !this.isRegularUser,
        },
      ],
      [
        OverlayTabEnum.COMMENTS,
        { title: TextConstants.COMMENTS, isEnabled: true, isHidden: !this.isRegularUser },
      ],
    ]),
  );

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
  };

  private countryOptions = signal<ISelectOption[]>([]);

  constructor() {
    super();
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.countryOptions.set(countriesOptions),
        ),
    );
  }

  async ngOnInit() {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_COUNTRY)) {
      return;
    }

    if (!this.recordId) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    this.setCountersToLoadingState();
    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
    this.loadCounters();
  }

  ngOnDestroy() {
    this.setCountersEmptyState();
  }

  loadCounters(): void {
    this.countryOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);

    if (this.isRegularUser) {
      this.countryOverlay.loadCommentCounter(this.entityUri);

      this.countryOverlay.loadReportsCounter(this.entityUri);
    }
  }

  setCountersToLoadingState() {
    this.countryOverlay.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.countryOverlay.setCountersEmptyState();
  }

  override async deleteRecord(id: string) {
    await this.countriesApi.delete(id);
  }

  override async reloadElement(id: string) {
    this.overlay.showLoading();

    try {
      this.element = await this.countriesApi.get(id);
      this.element.name = this.countryOptions().find((c) => c.value === this.element.code)?.label;
      await this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  override async setupForm() {
    this.form.patchValue({ name: this.element.name });
  }
}
