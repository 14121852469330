import { FormArray, FormControl, FormGroup } from "@angular/forms";

import { BulkAddCustomFieldFormGroup } from "@components/shared/bulk-add/bulk-add.interface";
import { TextConstants } from "@shared/constants";
import { IGeoLocation, ISelectOption, ITagExtended } from "@shared/interfaces";

export namespace BulkAddLocationsModel {
  export interface ILocationFieldSharedFormGroup {
    [FieldEnum.TAGS]: FormControl<ITagExtended[]>;
    [FieldEnum.ORGANISATION]: FormControl<ISelectOption>;
    [FieldEnum.LOCATION_TYPES]: FormControl<ISelectOption[]>;
    [FieldEnum.COUNTRY]: FormControl<ISelectOption>;
    [FieldEnum.CUSTOM_FIELDS]?: FormArray<FormGroup<BulkAddCustomFieldFormGroup>>;
  }

  export interface SetValuesFormGroup extends ILocationFieldSharedFormGroup {
    [FieldEnum.IS_FIXED_TAGS]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_ORGANISATION]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_LOCATION_TYPES]: FormControl<boolean>;
    [FieldEnum.IS_FIXED_COUNTRY]: FormControl<boolean>;
    [FieldEnum.RECORDS]: FormArray<FormGroup<LocationFormGroup>>;
  }

  export interface IGeoLocationWithFileName {
    geoLocation: IGeoLocation;
    fileName: string;
  }

  export interface LocationFormGroup extends ILocationFieldSharedFormGroup {
    [FieldEnum.NAME]: FormControl<string>;
    [FieldEnum.IS_NAME_DUPLICATED]: FormControl<boolean>;
    [FieldEnum.REGION]: FormControl<string>;
    [FieldEnum.ZIP_CODE]: FormControl<string>;
    [FieldEnum.ADDRESS]: FormControl<string>;
    [FieldEnum.GEOLOCATION]: FormControl<IGeoLocationWithFileName>;
  }

  export enum FieldEnum {
    NAME = "name",
    TAGS = "tags",
    ORGANISATION = "organisation",
    LOCATION_TYPES = "locationTypes",
    COUNTRY = "country",
    REGION = "region",
    ZIP_CODE = "zipCode",
    ADDRESS = "address",
    GEOLOCATION = "geolocation",
    IS_FIXED_TAGS = "isFixedTags",
    IS_FIXED_ORGANISATION = "isFixedOrganisation",
    IS_FIXED_LOCATION_TYPES = "isFixedLocationTypes",
    IS_FIXED_COUNTRY = "isFixedCountry",
    IS_NAME_DUPLICATED = "isNameDuplicated",
    RECORDS = "records",
    CUSTOM_FIELDS = "customFields",
  }

  export const fieldEnumToLabelMap: Partial<Record<FieldEnum, string>> = {
    [FieldEnum.NAME]: TextConstants.NAME,
    [FieldEnum.TAGS]: `${TextConstants.TAGS} (${TextConstants.OPTIONAL})`,
    [FieldEnum.ORGANISATION]: TextConstants.ORGANISATION,
    [FieldEnum.LOCATION_TYPES]: TextConstants.LOCATION_TYPES,
    [FieldEnum.COUNTRY]: TextConstants.COUNTRY,
    [FieldEnum.REGION]: `${TextConstants.STATE_REGION} (${TextConstants.OPTIONAL})`,
    [FieldEnum.ZIP_CODE]: `${TextConstants.ZIP_POSTCODE} (${TextConstants.OPTIONAL})`,
    [FieldEnum.ADDRESS]: `${TextConstants.ADDRESS} (${TextConstants.OPTIONAL})`,
    [FieldEnum.GEOLOCATION]: `${TextConstants.GEOLOCATION} (${TextConstants.OPTIONAL})`,
  };

  export const entityExistsValidatorArgs = {
    searchPropertyName: FieldEnum.NAME,
    searchPropertyErrorDisplayName: "name",
  };

  export enum StepsEnum {
    SET_VALUES = 0,
    ENTER_RECORDS = 1,
    CREATE_RECORDS = 2,
  }
}
