export enum TableEnum {
  LOCATIONS = "locations",
  DOCUMENT_TYPES = "document-types",
  LOCATION_TYPES = "location-types",
  PROCESS_TYPES = "process-types",
  MATERIALS = "materials",
  ORGANISATIONS = "organisations",
  SUPPLY_CHAINS = "supply-chains",
  DOCUMENTS = "documents",
  PROCESSES = "processes",
  PRODUCTS = "products",
  RULESETS = "rulesets",
  USERS_EXISTING = "users-existing",
  USERS_INVITED = "users-invited",
  CERTIFICATES = "certificates",
  ITEMS = "items",
  DELIVERIES = "deliveries",
  INVITATIONS = "invitations",
  MEMBERSHIPS = "memberships",
  API_KEYS = "apy-keys",
  ADMIN_ORGANISATIONS = "admin-organisations",
  ADMIN_ORGANISATION_EXTENSIONS = "admin-organisation-extensions",
  ADMIN_USERS = "admin-users",
  ADMIN_STANDARDS = "admin-standards",
  ADMIN_EUDR = "admin-eudr",
  ANALYSIS = "analysis",
  ATTACHMENTS = "attachments",
  PROCESS_SELECTED_ITEMS = "process-selected-items",
  PROCESS_IN_OUT = "process-in-out",
  QUANTITY_SUMMARY = "quantity-summary",
  SHARES = "shares",
  ADMIN_UNITS_OF_MEASUREMENT = "admin-units-of-measurement",
  CUSTOM_FIELDS = "admin-custom-fields",
  TAGS = "admin-tags",
  ADMIN_SENT_INFORMATION = "admin-sent-information",
  ADMIN_CONNECTIONS_EXISTING = "admin-connections-existing",
  ADMIN_CONNECTIONS_REQUESTED = "admin-connections-requested",
  ADMIN_CONNECTIONS_RECEIVED = "admin-connections-received",
  INBOX = "inbox",
  RISK_ASSESSMENT_TEMPLATES = "risk-assessment-templates",
  RISK_ASSESSMENT_REPORTS = "risk-assessment-reports",
  RELATED_RISK_ASSESSMENT_REPORTS = "related-risk-assessment-reports",
  RISK_LEVEL_SETS = "risk-level-sets",
  RISK_MITIGATIONS = "risk-mitigations",
  COUNTRY = "country",
}
