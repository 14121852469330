import { Injectable, signal } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";

import { FeatureFlagEnum, StorageKeyEnum } from "@shared/enums";
import { FeatureFlagService, LocalStorageService } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class SidebarMenuService {
  private menuCollapsedState: BehaviorSubject<boolean>;

  public currentUrl = signal<string>("");

  public isGroupedSideMenuEnabled = signal(false);

  constructor(
    private localStorageService: LocalStorageService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.init();
  }

  get menuCollapsedState$(): Observable<boolean> {
    return this.menuCollapsedState.asObservable();
  }

  toggleMenu(persist = false): void {
    const newCollapsedValue = !this.menuCollapsedState.value;

    this.menuCollapsedState.next(newCollapsedValue);

    if (persist) {
      this.localStorageService.set(StorageKeyEnum.IS_MENU_COLLAPSED, newCollapsedValue.toString());
    }
  }

  public isActiveRoute = (url: string, isExact?: boolean): boolean =>
    isExact ? this.currentUrl() === url : this.currentUrl().startsWith(url);

  public init(): void {
    this.isGroupedSideMenuEnabled.set(
      this.featureFlagService.isEnabled(FeatureFlagEnum.GROUPED_SIDE_MENU),
    );

    let isCollapsed: boolean = true;

    if (!this.isGroupedSideMenuEnabled()) {
      isCollapsed = this.localStorageService.get(StorageKeyEnum.IS_MENU_COLLAPSED) === "true";
    }

    this.menuCollapsedState = new BehaviorSubject<boolean>(isCollapsed);
  }
}
