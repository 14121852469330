<article class="field-group-container">
  <ng-template #riskAssessmentReportsTable>
    <app-risk-assessment-reports-table
      [recordUri]="recordUri()"
      [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
      (view)="openReport($event)"
    />
  </ng-template>

  @if (relatedRiskAssessmentReportTableParams()) {
    <mat-tab-group animationDuration="0ms" class="full-width">
      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container i18n>This record</ng-container>
          <app-indicator [value]="currentRecordCount()" />
        </ng-template>
        <ng-container *ngTemplateOutlet="riskAssessmentReportsTable"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <ng-container i18n>Related records</ng-container>
          <div class="container-flex gap-col-12">
            <app-indicator [value]="relatedRecordsCount()" />

            <mat-icon class="suffix-icon icon-20" [tp]="translations.relatedRecordsTp">
              help
            </mat-icon>
          </div>
        </ng-template>
        @if (!relatedRiskAssessmentReportTableParams()) {
          <app-loader></app-loader>
        } @else {
          <app-related-risk-assessment-reports-table
            (view)="openReport($event)"
            [relatedRiskAssessmentReportTableParams]="relatedRiskAssessmentReportTableParams()"
          />
        }
      </mat-tab>
    </mat-tab-group>
  } @else {
    <ng-container *ngTemplateOutlet="riskAssessmentReportsTable"></ng-container>
  }
</article>
