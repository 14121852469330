@for (attachment of attachmentsToDisplay; track attachment.attachmentId) {
  <div
    class="product product-row"
    (click)="onClickRow(attachment.product.id)"
    (keyup)="onClickRow(attachment.product.id)"
    tabindex="0"
  >
    {{ attachment.product.name }}
    <app-external-link-icon></app-external-link-icon>
    @if (params.customParams.canAddModifyEntities) {
      <div class="button">
        <app-button
          (pressed)="onClickRemoveAttachment(attachment.attachmentId)"
          [tp]="translations.removeTp"
          [tpIsLazy]="true"
          stopPropagation
          type="icon"
        >
          <mat-icon icon-small>close</mat-icon>
        </app-button>
      </div>
    }
  </div>
}
@if (displayShowMoreButton) {
  <div class="product-row" (keyup)="onClickShowMore()" (click)="onClickShowMore()" tabindex="0">
    Show {{ allAttachments.length - this.defaultMaxNumberProducts }} more...
  </div>
}
