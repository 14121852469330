<div class="producer-info row-container" [formGroup]="formGroup">
  <div class="box-with-index">
    <div class="index">
      {{ index + 1 }}
    </div>
    <div class="box-content">
      <div class="box-content-padded">
        <div class="row row-2">
          <app-input
            formControlName="producerName"
            [label]="translations.producerNameLabel"
          ></app-input>
          <app-input
            formControlName="producerCountry"
            [label]="translations.producerCountryLabel"
          ></app-input>
        </div>
      </div>
      <div class="divider"></div>
      <div class="box-content-padded">
        <div class="production-places" formArrayName="productionPlaces">
          <div
            *ngFor="let productionPlace of productionPlacesFormGroups; let k = index"
            [formGroupName]="k"
          >
            <app-reports-eudr-production-place
              [formGroup]="productionPlace"
              [index]="k"
            ></app-reports-eudr-production-place>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
