import { AsyncPipe, NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressBar } from "@angular/material/progress-bar";

import { Toast } from "ngx-toastr";
import { Observable, map, shareReplay } from "rxjs";

import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { BatchActionService } from "@shared/services/batch-action.service";

@Component({
  standalone: true,
  template: `
    <ng-container *ngIf="recordsData$ | async as recordsData">
      <div
        class="full-width"
        (click)="onClickNotification(recordsData)"
        (keyup)="onClickNotification(recordsData)"
        tabindex="0"
      >
        <div class="container-flex-space-between">
          <div class="container-flex">
            <mat-icon class="alert-icon mr-6">{{ actionIcon$ | async }}</mat-icon>
            <div class="title">
              {{ title$ | async }}
            </div>
          </div>
          @if (!recordsData.pending) {
            @if (recordsData.failedRecords.length) {
              <mat-icon>
                {{ isCollapsed ? "arrow_drop_down" : "arrow_drop_up" }}
              </mat-icon>
            } @else {
              <mat-icon class="close-icon">close</mat-icon>
            }
          }
        </div>

        @if (recordsData.pending) {
          <div class="progress-bar mt-15">
            <mat-progress-bar [value]="progress$ | async"></mat-progress-bar>
          </div>
        }

        @if (!isCollapsed && !recordsData.pending && recordsData.failedRecords.length) {
          <div class="elements-container mt-15 transparent-scrollbar">
            {{ subtitle$ | async }}
            <div class="mt-15">
              @for (record of recordsData.failedRecords; track record.id) {
                <div class="document">
                  <div class="name-container">
                    <div class="one-line-wrap-ellipsis">
                      {{ record.name }}
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>

          <div class="container-flex-space-between bottom-buttons mt-15">
            <a class="clickable" (click)="onClose()" i18n>Close</a>
            <a class="clickable" (click)="$event.stopPropagation(); onRetryAllFailed()" i18n>
              Retry failed records
            </a>
          </div>
        }
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AsyncPipe, MatIconModule, MatProgressBar, NgIf],
})
export class NotificationBatchActionComponent extends Toast<
  Observable<BatchActionModel.IBatchActionData>
> {
  private batchActionService: BatchActionService = inject(BatchActionService);

  public isCollapsed = true;

  public recordsData$ = this.options.payload.pipe(
    map((actionData) => {
      if (!actionData) {
        return {
          actionDetails: null,
          total: 0,
          pending: 0,
          failedRecords: [],
          records: [],
        };
      }

      const { records, actionDetails } = actionData;

      const total = records.length;
      const pending = records.filter(
        (r) => r.status === BatchActionModel.BatchStatusEnum.PENDING,
      ).length;
      const failedRecords = records.filter(
        (r) => r.status === BatchActionModel.BatchStatusEnum.ERROR,
      );

      if (failedRecords.length) {
        this.isCollapsed = false;
      }

      return { actionDetails, total, pending, failedRecords, records };
    }),
    shareReplay(1),
  );

  public actionIcon$ = this.recordsData$.pipe(
    map(({ pending, failedRecords, total, actionDetails }) => {
      if (pending) {
        return actionDetails.icon;
      } else {
        if (failedRecords.length) {
          return failedRecords.length === total ? "error" : "warning";
        }

        return "check";
      }
    }),
  );

  public failedRecords$ = this.recordsData$.pipe(map(({ failedRecords }) => failedRecords));

  public title$ = this.recordsData$.pipe(
    map(({ actionDetails, total, pending, failedRecords }) => {
      if (!actionDetails) {
        return "";
      }

      if (pending) {
        return this.pendingTitleText(actionDetails, pending, total);
      }

      if (failedRecords.length) {
        return failedRecords.length === total
          ? actionDetails.messages.allFailed
          : actionDetails.messages.someFailed;
      }

      return this.completedTitleText(actionDetails, total);
    }),
  );

  public subtitle$ = this.recordsData$.pipe(
    map(({ actionDetails, failedRecords }) => {
      if (failedRecords.length) {
        return (
          actionDetails.messages.errorMessage ??
          `These records could not be ${actionDetails.messages.completed.toLowerCase()}:`
        );
      }

      return "";
    }),
  );

  public progress$ = this.recordsData$.pipe(
    map(({ total, pending }) => {
      if (pending === 0) {
        return 100;
      }

      return Math.round(((total - pending) * 100) / total);
    }),
  );

  private completedTitleText(
    actionDetails: BatchActionModel.IBatchActionDetails,
    total: number,
  ): string {
    if (total === 1) {
      return `Record ${actionDetails.messages.completed.toLocaleLowerCase()}`;
    }

    return `${total} records ${actionDetails.messages.completed.toLocaleLowerCase()}`;
  }

  private pendingTitleText(
    actionDetails: BatchActionModel.IBatchActionDetails,
    pending: number,
    total: number,
  ): string {
    if (total === 1) {
      return `${actionDetails.messages.pending} record`;
    }

    return `${actionDetails.messages.pending} ${total - pending}/${total} records`;
  }

  public onClickNotification = (recordsData: { pending; failedRecords }): void => {
    if (recordsData.pending) {
      return;
    }

    if (recordsData.failedRecords.length) {
      this.isCollapsed = !this.isCollapsed;
    } else {
      this.onClose();
    }
  };

  public onRetryAllFailed(): void {
    this.batchActionService.retryFailedActions();
  }

  public onClose(): void {
    this.batchActionService.cancelAction();
  }
}
