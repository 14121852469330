@if ({ isCollapsed: this.sidebarMenuService.menuCollapsedState$ | async }; as sidebar) {
  <mat-toolbar [ngClass]="{ 'flex-end': !hasOrganisationOptions }">
    <div *ngIf="hasOrganisationOptions" class="container-flex">
      <mat-icon inline="true" class="logo" svgIcon="logo-symbol-night"></mat-icon>
      <div *ngIf="currentOrganisation" class="user-data-dropdown-container">
        <app-sidebar-dropdown
          [currentUrl]="currentUrl"
          [currentOrganisation]="currentOrganisation"
          [userData]="userData"
        ></app-sidebar-dropdown>
      </div>
      @if (isBackToInitialSharedPageVisible()) {
        <app-button (pressed)="onBackToInitialSharedPage()" type="ghost" i18n>
          Back to main shared record
        </app-button>
      }
    </div>
    @if (userData) {
      <div class="container-flex">
        @if (currentOrganisation && !currentOrganisation.isSystemAdmin && canAddModifyEntities) {
          <app-shortcut-add-menu></app-shortcut-add-menu>
        }
        <app-user-account-button
          [userType]="userType"
          [userName]="userName"
          [userData]="userData"
          [pendingInvitationsCount]="pendingInvitationsCount()"
        ></app-user-account-button>
      </div>
    }
  </mat-toolbar>
  <div
    class="main-container"
    [ngClass]="{ 'menu-collapsed': sidebar.isCollapsed, 'no-border-top': !hasOrganisationOptions }"
  >
    @if (!hasOrganisationOptions) {
      <div class="no-organisations-container">
        @if (urlStartsWithInvitations) {
          <div class="router-container">
            <router-outlet></router-outlet>
          </div>
        } @else {
          <div class="info">
            <p i18n>It seems that you are not part of any organisation</p>
            <app-button (pressed)="createOrganisation()">
              <mat-icon>add</mat-icon>
              <ng-container i18n>Create new organisation</ng-container>
            </app-button>
          </div>
        }
      </div>
    } @else {
      <mat-sidenav-container class="sidenav-container">
        @if (userType === authUserTypeEnum.REGULAR && !!currentOrganisation) {
          <mat-sidenav mode="side" opened disableClose [fixedInViewport]="true" role="navigation">
            <mat-nav-list>
              <ng-container
                [ngTemplateOutlet]="sidebarMenuItemTemplate"
                [ngTemplateOutletContext]="{
                  items: menuItems,
                }"
              ></ng-container>
            </mat-nav-list>
          </mat-sidenav>
        }
        <ng-template #sidebarMenuItemTemplate let-items="items">
          @for (item of items; track $index) {
            @if (!item.isDisabledFn || !item.isDisabledFn()) {
              @if (item.menuItems) {
                <app-sidebar-group-item [groupItem]="item" [isCollapsed]="sidebar.isCollapsed" />
              } @else {
                <app-sidebar-item
                  [menuItem]="item"
                  [showTooltip]="sidebar.isCollapsed"
                  [active]="sidebarMenuService.isActiveRoute(item.route, item.isActiveRouteExact)"
                >
                  <span class="menu-title">{{ item.title }}</span>
                </app-sidebar-item>
              }
            }
          }
        </ng-template>

        <mat-sidenav-content
          [ngClass]="
            sidebar.isCollapsed
              ? 'sidenav-content-with-collapsed-menu'
              : 'sidenav-content-with-expanded-menu'
          "
        >
          @if (
            !sidebarMenuService.isGroupedSideMenuEnabled() && userType === authUserTypeEnum.REGULAR
          ) {
            <div
              class="menu-collapse-button-container"
              [class]="sidebar.isCollapsed ? 'collapsed' : 'expanded'"
            >
              <button
                type="button"
                [tp]="(sidebar.isCollapsed ? 'Expand' : 'Collapse') + ' menu'"
                tpPlacement="right"
                mat-mini-fab
                (click)="onToggleMenuCollapse()"
              >
                <mat-icon>
                  {{ "'keyboard_double_arrow_'" + (sidebar.isCollapsed ? "right" : "left") }}
                </mat-icon>
              </button>
            </div>
          }
          <router-outlet></router-outlet>
        </mat-sidenav-content>
      </mat-sidenav-container>
    }
  </div>
}
