import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";

import { Subscription } from "rxjs";
import { ConfirmDialogResponseEnum } from "src/app/shared/enums";
import { IInvitation, IUserData } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  InvitationsService,
  SetUserOrganisationsService,
} from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

import {
  AcceptInvitationDialogComponent,
  ConfirmDialogComponent,
  InvitationsTableComponent,
} from "../shared";

@Component({
  standalone: false,
  templateUrl: "./invitations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationsComponent implements OnInit, OnDestroy {
  @ViewChild("invitationsTable") invitationsTable: InvitationsTableComponent;

  public userData: IUserData = null;

  public acceptInvitationId: string = null;

  private subscriptions = new Subscription();

  public readonly translations: any = {
    title: $localize`Invitations`,
    titleHint: $localize`See and manage your invitations from other organisations.`,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private invitationsService: InvitationsService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private routerService: RouterService,
    private setUserOrganisationsService: SetUserOrganisationsService,
  ) {
    this.subscriptions.add(
      this.authenticationService.userDataObservable$.subscribe((userData: IUserData) => {
        this.userData = userData;
      }),
    );
  }

  public async ngOnInit(): Promise<void> {
    this.acceptInvitationId = this.route.snapshot.queryParams["acceptInvitationId"];
    if (this.acceptInvitationId) {
      await this.onOpenAcceptDialog(this.acceptInvitationId);
    }
  }

  public onTryAccept = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Accept invitation`,
        contentText: $localize`Are you sure that you want to accept this invitation?`,
        confirmButtonText: TextConstants.ACCEPT,
        confirmButtonIcon: "check_circle",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.onAccept(invitation);
      }
    });
  };

  public onTryReject = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Reject invitation`,
        contentText: $localize`Are you sure that you want to reject this invitation?`,
        confirmButtonColor: "danger",
        confirmButtonText: $localize`Reject`,
        confirmButtonIcon: "block",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.onReject(invitation);
      }
    });
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private onOpenAcceptDialog = async (acceptInvitationId: string): Promise<void> => {
    await this.invitationsService
      .get(acceptInvitationId)
      .then((response: IInvitation) => {
        if (response.email !== this.authenticationService.getUserEmail()) {
          throw $localize`Invitation does not exist`;
        }
        const invitation = response;
        const dialogRef = this.dialog.open(AcceptInvitationDialogComponent, {
          data: {
            organisationName: invitation.orgName,
          },
        });

        dialogRef.afterClosed().subscribe(async (result: { hasAccepted?: boolean }) => {
          this.removeInvitationQueryParams();
          if (result?.hasAccepted !== null) {
            if (result?.hasAccepted) {
              await this.onAccept(invitation);
            } else {
              await this.onReject(invitation);
            }
          }
        });
      })
      .catch(() => {
        this.notificationService.showError($localize`Invitation does not exist`);
        this.removeInvitationQueryParams();
      });
  };

  private removeInvitationQueryParams = (): void => {
    this.acceptInvitationId = null;

    this.routerService.updateCurrentUrlParams({ acceptInvitationId: null, org: null });
  };

  private onAccept = async (invitation: IInvitation): Promise<void> => {
    this.invitationsTable.isLoading.set(true);
    await this.invitationsService
      .accept(invitation.id)
      .then(async () => {
        this.notificationService.showSuccess($localize`Invitation accepted`);

        await this.setUserOrganisationsService.setOrganisations(this.removeInvitationQueryParams);
        const availableOrganisations = this.authenticationService.getAvailableOrganisations();
        const organisationId = CommonUtils.getUriId(invitation.invitingOrg);
        const organisationIndex = availableOrganisations.findIndex((o) => o.id === organisationId);

        await this.authenticationService.setActiveOrganisationIndex(organisationIndex);
        //todo when we have a dashboard -> perhaps navigate to dashboard instead of reloading data here?
        await this.invitationsTable.getAll();
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.invitationsTable.isLoading.set(false);
      });
  };

  private onReject = async (invitation: IInvitation): Promise<void> => {
    this.invitationsTable.isLoading.set(true);
    await this.invitationsService
      .reject(invitation.id)
      .then(async () => {
        this.notificationService.showSuccess($localize`Invitation rejected`);
        await this.invitationsTable.getAll();
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.invitationsTable.isLoading.set(false);
      });
  };
}
