import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  TemplateRef,
  input,
  output,
  viewChild,
} from "@angular/core";

@Component({
  standalone: false,
  selector: "app-drag-drop-list-item",
  templateUrl: "./drag-drop-list-item.component.html",
  styleUrl: "./drag-drop-list-item.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DragDropListItemComponent {
  readonly isDeleteDisabled = input(false);

  readonly deleteDisabledText = input($localize`This item cannot be deleted`);

  readonly moveUp = output();

  readonly moveDown = output();

  readonly remove = output();

  readonly template = viewChild(TemplateRef<DragDropListItemComponent>, { read: TemplateRef });

  readonly content = viewChild("content", { read: ElementRef });

  readonly topContent = viewChild("topContent", { read: TemplateRef });

  readonly bottomContent = viewChild("bottomContent", { read: TemplateRef });

  readonly translations: any = {
    moveUpTp: $localize`Move up`,
    moveDownTp: $localize`Move down`,
  };
}
