import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  output,
  signal,
  computed,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";

import { EudrDdsAddReferenceDialogComponent } from "@components/reports/eudr/dds/add-reference-dialog/eudr-dds-add-reference-dialog.component";
import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { ConfirmDialogComponent } from "@components/shared/confirm-dialog/confirm-dialog.component";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum } from "@shared/enums/confirm-dialog-response.enum";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { ColumnUtils } from "@shared/utils/column.utils";

@Component({
  standalone: false,
  selector: "app-eudr-dds-references-tab",
  templateUrl: "./eudr-dds-references-tab.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EudrDdsReferencesTabComponent implements AfterViewInit {
  public statement = input.required<EudrDds.IDetails>();

  public canAddModifyEntities = input<boolean>(false);

  public rowData = signal<EudrDds.IAssociatedStatement[]>([]);

  public colDefs = signal<ColDef[]>([]);

  public isLoading = signal<boolean>(true);

  private eudrDdsPageService = inject(EudrDdsPageService);

  private dialog = inject(MatDialog);

  public saved = output<void>();

  public displayAddAndDeleteButtons = computed(
    () => this.canAddModifyEntities() && this.statement().status === EudrDds.StatusEnum.PENDING,
  );

  public onAddReference(): void {
    const dialogRef = this.dialog.open(EudrDdsAddReferenceDialogComponent, {
      data: {
        statement: this.statement(),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.hasSaved) {
        this.saved.emit();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.rowData.set(this.statement().statement.associatedStatements);

    const buttons = [
      {
        onClick: this.onDelete.bind(this),
        tooltip: TextConstants.DELETE,
        icon: "delete",
      },
    ];

    const colDefs: ColDef[] = [
      { field: "referenceNumber", headerName: "Reference number" },
      { field: "verificationNumber", headerName: "Verification code" },
    ];

    if (this.displayAddAndDeleteButtons()) {
      colDefs.push(ColumnUtils.buttons(buttons));
    }

    this.colDefs.set(colDefs);

    this.isLoading.set(false);
  }

  private onDelete(reference: EudrDds.IAssociatedStatement): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this reference?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const associatedStatements = this.statement().statement.associatedStatements.filter(
          (r) =>
            r.referenceNumber !== reference.referenceNumber &&
            r.verificationNumber !== reference.verificationNumber,
        );

        const hasSaved = await this.eudrDdsPageService.saveReferences(
          this.statement(),
          associatedStatements,
        );

        if (hasSaved) {
          this.saved.emit();
        }
      }
    });
  }
}
