import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { ConfirmDialogResponseEnum, RecordStateEnum, RouteEnum } from "src/app/shared/enums";
import {
  CertificatesService,
  ConnectionsService,
  DeliveriesService,
  DocumentsService,
  DownloadDocumentsService,
  ItemsService,
  LocationsService,
  ProcessesService,
  ProductsService,
  RulesetsService,
  SupplyChainsService,
} from "src/app/shared/services";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { ConfirmDialogComponent } from "../confirm-dialog/confirm-dialog.component";

@Component({
  standalone: false,
  selector: "app-more-actions-buttons",
  templateUrl: "./more-actions-buttons.component.html",
  styleUrls: ["./more-actions-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreActionsButtonsComponent {
  @Input() label: string;

  @Input() labelIcon: string;

  @Input({ transform: booleanAttribute }) disabledMainButton: boolean;

  canSend = input<boolean>(false);

  @Output() send = new EventEmitter<void>();

  @Output() clickLabel: EventEmitter<void> = new EventEmitter();

  @Input()
  public element: any;

  @Input()
  public service: any;

  @Input()
  public isDeleteEnabled = false; //todo remove this when isDeleteEnabled is true for all entities.

  @Input({ transform: booleanAttribute })
  public isArchiveEnabled: boolean = true;

  @Input({ transform: booleanAttribute })
  public isShareEnabled = false;

  @Input({ transform: booleanAttribute })
  public isDuplicateEnabled: boolean = false;

  // @Output()
  // public setLoading: EventEmitter<boolean> = new EventEmitter(); //TODO, see below, not working on error.

  @Output()
  public reloadData: EventEmitter<string> = new EventEmitter();

  @Output()
  public share: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public duplicate: EventEmitter<void> = new EventEmitter<void>();

  public readonly recordStateEnum = RecordStateEnum;

  public readonly translations: any = {
    duplicateTp: $localize`This will only duplicate the item details and will allow the user to edit them before saving the new item.`,
  };

  constructor(
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private router: Router,
    private downloadDocumentsService: DownloadDocumentsService,
  ) {}

  public onToggleArchiveStatus = (): void => {
    const isArchived = this.element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Confirmation`,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          const wasArchived = this.element.recordState === RecordStateEnum.ARCHIVED;

          await this.service.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            this.element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          this.reloadData.emit(this.element.id);
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onDelete = (): void => {
    if (!this.isDeleteEnabled) {
      return;
    }
    if (
      this.service instanceof DocumentsService &&
      this.downloadDocumentsService.isDocumentBeingDownloaded(this.element.id)
    ) {
      this.notificationService.showError($localize`This document is being downloaded`);

      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: `Are you sure that you want to delete this record?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          //this.setLoading.emit(true); //TODO, see below, not working on error.
          await this.service.delete(this.element.id);
          this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
          await this.redirectBasedOnService();
        } catch (error) {
          //this.setLoading.emit(false); //TODO, this does not work.
          this.notificationService.showError(error);
        }
      }
    });
  };

  public onDuplicate = (): void => {
    if (!this.isDuplicateEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Duplicate record`,
        contentText: $localize`Are you sure that you want to duplicate this record?`,
        confirmButtonText: $localize`Duplicate`,
        confirmButtonIcon: "content_copy",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.duplicate.emit();
      }
    });
  };

  public onClickLabel(): void {
    this.clickLabel.emit();
  }

  private redirectBasedOnService = async (): Promise<void> => {
    let route: RouteEnum;

    switch (this.service.constructor) {
      case DeliveriesService:
        route = RouteEnum.DELIVERIES;
        break;
      case DocumentsService:
        route = RouteEnum.DOCUMENTS;
        break;
      case ItemsService:
        route = RouteEnum.ITEMS;
        break;
      case LocationsService:
        route = RouteEnum.LOCATIONS;
        break;
      case ConnectionsService:
        route = RouteEnum.ORGANISATIONS;
        break;
      case CertificatesService:
        route = RouteEnum.CERTIFICATES;
        break;
      case ProcessesService:
        route = RouteEnum.PROCESSES;
        break;
      case RulesetsService:
        route = RouteEnum.ADMIN_RULESETS;
        break;
      case ProductsService:
        route = RouteEnum.PRODUCTS;
        break;
      case SupplyChainsService:
        route = RouteEnum.SUPPLY_CHAINS;
        break;
    }
    await this.router.navigate([`/${route}`]);
  };

  sendHandler(): void {
    this.send.emit();
  }
}
