import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { TagsColorEnum } from "src/app/shared/enums";
import { ITagDefinitionPayload, ISelectOption, ITagDefinition } from "src/app/shared/interfaces";
import { TagDefinitionsService } from "src/app/shared/services";
import { CommonUtils, FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./edit-tag-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditTagDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public isEditing = signal(false);

  public readonly colorOptions: ISelectOption[] = Object.values(TagsColorEnum).map(
    (value: any) => ({
      label: CommonUtils.enumToText(TagsColorEnum[value]),
      value,
    }),
  );

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    colorLabel: $localize`Color`,
  };

  constructor(
    public dialogRef: MatDialogRef<EditTagDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { tagDefinition?: any },
    private tagDefinitionsService: TagDefinitionsService,
    private notificationService: NotificationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.isEditing.set(!!this.data?.tagDefinition?.id);

    let colorValue: ISelectOption = null;

    if (this.isEditing()) {
      colorValue = {
        label: this.colorOptions.find((c) => c.value === this.data.tagDefinition.color).label,
        value: this.data.tagDefinition.color,
      };
    }

    const entityExistsValidatorArgs: any = {
      searchPropertyName: "title",
      searchPropertyErrorDisplayName: "title",
    };

    this.formGroup = new UntypedFormGroup({
      title: new UntypedFormControl(
        this.isEditing() ? this.data.tagDefinition.title : null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.tagDefinitionsService,
            this.data?.tagDefinition?.id ?? null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      color: new UntypedFormControl(colorValue, [CustomValidators.required]),
    });

    this.isLoading.set(false);
  }

  public isSubmitButtonDisabled = (): boolean => {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup.invalid || this.formGroup.pending;
  };

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    const payload: ITagDefinitionPayload = {
      id: this.isEditing() ? this.data.tagDefinition.id : undefined,
      title: this.formGroup.controls["title"].value.trim(),
      color: this.formGroup.controls["color"].value.value,
    };

    await this.tagDefinitionsService
      .createOrUpdate(payload, this.data?.tagDefinition?.id)
      .then((savedTag: ITagDefinition) => {
        this.notificationService.showSuccess(
          this.isEditing() ? $localize`Tag modified` : $localize`Tag created`,
        );
        this.onClose(true, savedTag);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false, tag: ITagDefinition = null): void => {
    this.dialogRef.close({ hasSaved, tag });
  };
}
