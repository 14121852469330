<app-slide-overlay-header [title]="title()" icon="local_shipping"></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <app-edit-delivery-main-details
        class="full-width"
        [formGroup]="formGroup"
        [isEditing]="false"
        [fromLocationOptions]="fromLocationOptions()"
        [toLocationOptions]="toLocationOptions()"
        [organisationsOptions]="organisationsOptions()"
        [locationViewTemplate]="locationViewTemplate"
        [statusOptions]="statusOptions()"
        (traderTagPressed)="traderTagPressed($event)"
      ></app-edit-delivery-main-details>
    </app-form-field-group>
    @if (selectedItems.length) {
      <app-form-field-group name="Product summary" class="pagination-fix display-block">
        <app-quantity-summary-table
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [allProducts]="allProducts"
          [allMaterials]="allMaterials"
          [allUnitOfMeasurements]="allUnitOfMeasurements"
          [selectedItems]="selectedItems"
          [isInboundShared]="true"
          [inboundSharedSenderOrgId]="organisationIdQueryParam"
        ></app-quantity-summary-table>
      </app-form-field-group>
    }
  </ng-template>

  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ITEMS).isEnabled"
    [overlay-menu-indicator]="selectedItems.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.ITEMS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="package_2"
  >
    <article class="field-group-container">
      <app-slide-overlay-delivery-items
        [allMaterials]="allMaterials"
        [allProducts]="allProducts"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [isReadOnly]="true"
        [selectedItems]="selectedItems"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-slide-overlay-delivery-items>
    </article>
  </ng-template>

  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    [overlay-menu-indicator]="element()?.documents?.length"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.SUPPLY_CHAIN).title"
    appSlideOverlayMenuItem
    overlay-menu-svg-icon="supply-chain"
  >
    <article class="field-group-container">
      <app-items-supply-chain
        [itemIds]="selectedItemsIds"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [height]="supplyChainHeight"
      ></app-items-supply-chain>
    </article>
  </ng-template>
  <ng-template #locationViewTemplate let-label="label" let-option>
    <div class="form-field-item">
      <app-form-label [label]="label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (option.label && option.value) {
          <app-form-link [link]="getSharedLocationLink(option.value)" [styledLink]="true">
            {{ option.label }}
          </app-form-link>
        } @else {
          <app-loader containerClass="size-small"></app-loader>
        }
      </div>
    </div>
  </ng-template>
</app-slide-overlay-content>
