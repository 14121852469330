import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { IMaterial } from "src/app/shared/interfaces";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";

@Component({
  standalone: false,
  selector: "app-materials-list-items",
  templateUrl: "./materials-list-items.component.html",
  styleUrls: ["./materials-list-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsListItemsComponent {
  @Input()
  public materials: IMaterial[] = [];

  @Input()
  public canRemove = true;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  readonly cardContentTypeEnum = CardContentTypeEnum;

  public onRemove = (id: string): void => {
    this.remove.emit(id);
  };
}
