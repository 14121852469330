<ag-grid-angular
  #grid
  class="grid ag-theme-material cell-borders no-min-height"
  [rowData]="rowData()"
  (rowDragEnd)="onRowDragEnd($event)"
  (rowDragEnter)="onRowDragEnter($event)"
  [columnDefs]="columnDefs"
  (gridReady)="onGridReady($event)"
  [gridOptions]="gridOptions"
  [style.height]="hasMultipleAreas() ? '611px' : '659px'"
></ag-grid-angular>
