import { inject, signal } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";

import { distinctUntilChanged, Subscription } from "rxjs";

import { BulkAddEnterRecordsModel } from "@components/shared/bulk-add/bulk-add.model";
import { ICustomField } from "@shared/interfaces";
import { CustomFieldsService } from "@shared/services";

import { BulkAddCustomFieldEnum, BulkAddCustomFieldFormGroup } from "./bulk-add.interface";

export abstract class BulkAddBaseService {
  public allCustomFields = signal<ICustomField[]>([]);

  protected customFieldsService: CustomFieldsService = inject(CustomFieldsService);

  public formGroup: FormGroup;

  public recordsLength = signal<number>(1);

  public isNextButtonDisabled = signal<boolean>(false);

  protected subscriptions: Subscription = new Subscription();

  protected subscribeToRecordsLengthChanges(): void {
    this.subscriptions.add(
      this.formGroup.controls[BulkAddEnterRecordsModel.BulkAddFieldEnum.RECORDS].valueChanges
        .pipe(
          distinctUntilChanged(
            (previousRecords, currentRecords) => previousRecords.length === currentRecords.length,
          ),
        )
        .subscribe((records) => this.recordsLength.set(records.length)),
    );
  }

  protected subscribeToPendingFormStatusChanges(): void {
    this.subscriptions.add(
      this.formGroup.statusChanges.subscribe((status) => {
        this.isNextButtonDisabled.set(status === "PENDING");
      }),
    );
  }

  public get customFieldsFormArray(): FormArray<FormGroup<BulkAddCustomFieldFormGroup>> {
    return this.formGroup.get(BulkAddEnterRecordsModel.BulkAddFieldEnum.CUSTOM_FIELDS) as FormArray<
      FormGroup<BulkAddCustomFieldFormGroup>
    >;
  }

  protected createCustomFieldsFormArray(
    customFields: ICustomField[],
  ): FormArray<FormGroup<BulkAddCustomFieldFormGroup>> {
    return new FormArray<FormGroup<BulkAddCustomFieldFormGroup>>(
      customFields.map((customField) => {
        const formGroup = new FormGroup<BulkAddCustomFieldFormGroup>({
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_ID]: new FormControl(`cf_${customField.id}`),
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE]: new FormControl(),
          [BulkAddCustomFieldEnum.CUSTOM_FIELD_LABEL]: new FormControl(customField.label),
          [BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD]: new FormControl(false),
        });

        formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].disable();

        formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].valueChanges.subscribe(
          (value) => {
            const control = formGroup.controls[BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE];

            if (value) {
              control.disable({ emitEvent: false });
            } else {
              control.enable({ emitEvent: false });
            }
          },
        );

        formGroup.controls[BulkAddCustomFieldEnum.CUSTOM_FIELD_VALUE].valueChanges.subscribe(
          (value) => {
            if (value) {
              formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].enable({
                emitEvent: false,
              });

              formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].setValue(true, {
                emitEvent: false,
              });
            } else {
              formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].disable({
                emitEvent: false,
              });

              formGroup.controls[BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD].setValue(false, {
                emitEvent: false,
              });
            }
          },
        );

        return formGroup;
      }),
    );
  }
}
