import { ChangeDetectionStrategy, Component, computed, inject, input, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { NavigationParams, RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-reports-eudr-material-info",
  templateUrl: "./reports-eudr-material-info.component.html",
  styleUrls: ["./reports-eudr-material-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsEudrMaterialInfoComponent {
  @Input() index: number;

  formGroup = input.required<FormGroup>();

  private readonly routerService = inject(RouterService);

  materialName = computed(() => {
    return this.formGroup().get("materialName").value;
  });

  materialId = computed(() => {
    return this.formGroup().get("materialId").value;
  });

  public readonly reportsEudrModel: typeof ReportsEudrModel = ReportsEudrModel;

  public readonly translations: any = {
    scientificNameTooltip: $localize`Value taken from the custom field ‘Scientific name’ on the material. Please ensure such a field exists and is filled in.`,
    commonNameTooltip: $localize`Value taken from the custom field ‘Common name’ on the material. Please ensure such a field exists and is filled in.`,
    materialLabel: $localize`Material`,
  };

  public getMaterialLink(): NavigationParams {
    return <NavigationParams>this.routerService.getMaterialLink(this.materialId(), false);
  }
}
