import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

type ConditionalFunction<T> = (data: any) => T;
type ClickFunction = (data: any) => Promise<void>;

@Component({
  standalone: false,
  template: `
    <div class="container-flex-right on-hover-visibility-show">
      <ng-container *ngFor="let button of params['buttons']">
        <button
          *ngIf="isVisible(button.isVisible)"
          type="button"
          [class]="getButtonClass(button.buttonClass)"
          (click)="$event.stopPropagation(); onClick(button.onClick)"
          mat-icon-button
          [tp]="getTooltip(button.tooltip)"
          [tpIsLazy]="true"
        >
          <mat-icon [class]="getIconClass(button.iconClass)">{{ getIcon(button.icon) }}</mat-icon>
        </button>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonsCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  isVisible = (isVisible: boolean | ConditionalFunction<boolean>): boolean => {
    if (isVisible === undefined) {
      return true;
    }
    if (typeof isVisible === "boolean") {
      return isVisible;
    }

    return isVisible(this.getData());
  };

  getTooltip = (tooltip: string | ConditionalFunction<string>): string => {
    if (!tooltip) {
      return "";
    }
    if (typeof tooltip === "string") {
      return tooltip;
    }

    return tooltip(this.getData());
  };

  getIcon = (icon: string | ConditionalFunction<string>): string => {
    if (typeof icon === "string") {
      return icon;
    }

    return icon(this.getData());
  };

  getIconClass = (iconClass: string | ConditionalFunction<string>): string => {
    if (!iconClass) {
      return "";
    }
    if (typeof iconClass === "string") {
      return iconClass;
    }

    return iconClass(this.getData());
  };

  getButtonClass = (buttonClass: string | ConditionalFunction<string>): string => {
    if (!buttonClass) {
      return "";
    }
    if (typeof buttonClass === "string") {
      return buttonClass;
    }

    return buttonClass(this.getData());
  };

  onClick = async (clickFunction: ClickFunction): Promise<void> => {
    await clickFunction(this.getData());
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
