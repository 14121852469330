<app-right-side-panel
  class="overlay-right-panel"
  itemType="Mitigation"
  itemTypePlural="mitigations"
  [displayTopButtons]="false"
  [displayAddAllButton]="false"
  [overlayTopButtons]="mitigationsPanelTopButtons"
  [availableItems]="availableMitigations()"
  (searchEvent)="onSearchMitigations($event)"
>
  <div class="items-container">
    @if (isMitigationsLoading()) {
      <app-loader />
    } @else {
      @for (mitigation of availableMitigations(); track $index) {
        <app-draggable-card [draggable]="false" (pressed)="add.emit(mitigation)">
          <app-risk-mitigation-content-card [riskMitigation]="mitigation" />
        </app-draggable-card>
      }
    }
  </div>
</app-right-side-panel>

<ng-template #mitigationsPanelTopButtons>
  <app-button
    size="large"
    [tp]="translations.addNewTp"
    tpPlacement="bottom"
    type="icon"
    (pressed)="onAdd()"
  >
    <mat-icon icon-medium-18>add</mat-icon>
  </app-button>
</ng-template>
