import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { ColDef, ValueGetterParams } from "ag-grid-community";

import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import { EntityTypeEnum, FeatureFlagEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { FeatureFlagService, RecordSharingService } from "@shared/services";
import { CellRendererUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  templateUrl: "./shared-product-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedProductOverlayComponent extends SlideOverlayPageClass implements OnInit {
  private readonly recordSharingService = inject(RecordSharingService);

  private readonly featureFlagService = inject(FeatureFlagService);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null),
    baseUnit: new UntypedFormControl(null),
  });

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.PRODUCT_DETAILS, isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: true }],
    ]),
  );

  override element = signal<IProductExtended>(null);

  override entityType = EntityTypeEnum.PRODUCTS;

  public unitOfMeasurementTypesOptions = signal<ISelectOption[]>([]);

  public unitsOfMeasurementColDefs: ColDef[] = [];

  public selectedUnitsOfMeasurement: ICustomUnitOfMeasurement[] & IBaseUnit[] = [];

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    baseUnitLabel: $localize`Main measurement type`,
    unitsLabel: TextConstants.UNITS_OF_MEASUREMENT,
    materialsLabel: TextConstants.MATERIALS,
    allowedMaterialsLabel: $localize`Allowed materials`,
  };

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_SHARED_PRODUCT)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      const includes = ["DOCUMENTS", "CUSTOM_UNITS"];

      if (this.isOldMaterialsEnabled) {
        includes.push("ALLOWED_MATERIALS");
      } else {
        includes.push("MATERIALS");
      }
      const inboundProducts = await this.recordSharingService.getInboundProductsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        includes,
      );

      this.element.set(inboundProducts[0]);
      const unitType = this.element()?.unitOfMeasurement?.type;

      this.unitOfMeasurementTypesOptions.set([
        {
          label: CommonUtils.capitaliseFirstLetter(unitType),
          value: unitType,
        },
      ]);
      this.selectedUnitsOfMeasurement = [
        ...this.element().customUnits,
        this.element().unitOfMeasurement as any,
        this.element().defaultCustomUnit,
      ].filter((unit) => !!unit);

      this.setColumnDefsForUnitsOfMeasurements();

      this.setupForm();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.overlay.dismissLoading();
    }
  }

  override setupForm(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name),
      baseUnit: new UntypedFormControl({
        label: this.element()?.unitOfMeasurement.type,
        value: this.element()?.unitOfMeasurement.type,
      }),
    });
  }

  private setColumnDefsForUnitsOfMeasurements = (): void => {
    this.unitsOfMeasurementColDefs = [
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
      },
      {
        headerName: $localize`Default`,
        field: "id",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (params: ValueGetterParams<ICustomUnitOfMeasurement & IBaseUnit>) => {
          const defaultUnitOfMeasurementId =
            this.element()?.unitOfMeasurement?.id ?? this.element().defaultCustomUnit?.id;

          return params.data?.id === defaultUnitOfMeasurementId;
        },
      },
    ];
  };

  protected override recordName(): string {
    return this.element()?.name;
  }
}
