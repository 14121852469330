import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";

import { ISetting } from "@shared/interfaces";
import { FeatureFlagService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsComponent {
  public readonly userGeneralSettings: ISetting[] = [
    {
      route: RouteEnum.USER_SETTINGS_MEMBERSHIPS,
      icon: "group",
      title: $localize`Memberships`,
      description: $localize`Manage your org memberships`,
    },
    {
      route: RouteEnum.USER_SETTINGS_API_KEYS,
      icon: "key",
      title: $localize`API keys`,
      description: $localize`Manage your API keys`,
    },
  ];

  public readonly translations: any = {
    title: $localize`User settings`,
  };

  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {
    const isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

    if (isLanguagesEnabled) {
      this.userGeneralSettings.unshift({
        route: RouteEnum.USER_SETTINGS_PROFILE,
        icon: "assignment_ind",
        title: $localize`Profile`,
        description: $localize`User profile settings`,
      });
    }
  }

  public onGoToRoute = async (route: RouteEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
