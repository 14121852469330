import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { TextConstants } from "@shared/constants";
import { RouteEnum, UserRoleEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";

import { AddUserDialogComponent } from "..";
import { UsersInvitedTableComponent } from "../../shared";

@Component({
  standalone: false,
  templateUrl: "./settings-users.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsUsersComponent {
  @ViewChild("usersInvitedTable") usersInvitedTable: UsersInvitedTableComponent;

  public selectedTabIndex = 0;

  public isInviteUserEnabled = false;

  public readonly routingEnum = RouteEnum;

  public readonly translations = {
    title: $localize`Users`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {
    this.isInviteUserEnabled = this.authenticationService
      .getActiveOrganisation()
      .roles.some((role: UserRoleEnum) =>
        [UserRoleEnum.ACCOUNT_OWNER, UserRoleEnum.ADMIN].includes(role),
      );
  }

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(AddUserDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        this.selectedTabIndex = 1;
        await this.usersInvitedTable.getAll();
      }
    });
  };
}
