import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SummaryComponentTypeEnum } from "@components/processes/edit-process/process-inputs-outputs/process-inputs-outputs.model";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import { FeatureFlagEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import {
  IBaseUnit,
  IItemExtended,
  IMaterial,
  IProcessExtended,
  IProcessInput,
  IProcessOutput,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { FeatureFlagService, RecordSharingService } from "@shared/services";
import { NavigationParams } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-shared-process-overlay",
  templateUrl: "./shared-process-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedProcessOverlayComponent extends SlideOverlayPageClass implements OnInit {
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  private readonly recordSharingService = inject(RecordSharingService);

  private readonly featureFlagService = inject(FeatureFlagService);

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.PROCESS_DETAILS, isEnabled: true }],
      [OverlayTabEnum.INPUTS, { title: TextConstants.INPUTS, isEnabled: true }],
      [OverlayTabEnum.OUTPUTS, { title: TextConstants.OUTPUTS, isEnabled: true }],
      [OverlayTabEnum.CERTIFICATES, { title: TextConstants.CERTIFICATES, isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: true }],
    ]),
  );

  override element = signal<IProcessExtended>(null);

  public formGroup: UntypedFormGroup;

  public allLocationsOptions = signal<ISelectOption[]>([]);

  public allProcessTypesOptions = signal<ISelectOption[]>([]);

  public processInputs = signal<IProcessInput[]>([]);

  public processOutputs = signal<IProcessOutput[]>([]);

  public allProducts = signal<IProductExtended[]>([]);

  public items = signal<IItemExtended[]>([]);

  public allMaterials: IMaterial[] = [];

  public allUnitOfMeasurements: IBaseUnit[] = [];

  public readonly summaryComponentEnum = SummaryComponentTypeEnum;

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public readonly translations: any = {
    typeLabel: TextConstants.TYPE,
    locationLabel: TextConstants.LOCATION,
    firstInputLabel: TextConstants.FIRTS_INPUT_DATE,
    lastOutputLabel: TextConstants.LAST_OUTPUT_DATE,
    inputSummaryLabel: TextConstants.INPUT_SUMMARY,
    outputSummaryLabel: TextConstants.OUTPUT_SUMMARY,
  };

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_SHARED_PROCESS)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      const processes = await this.recordSharingService.getInboundProcessesByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["DOCUMENTS", "CERTIFICATES"],
      );

      this.element.set(processes[0]);
      const locationOption = {
        value: this.element().location.id,
        label: this.element().location.name,
      };
      const processTypeOption = {
        value: this.element().type.id,
        label: this.element().type.name,
      };

      this.allLocationsOptions.set([locationOption]);
      this.allProcessTypesOptions.set([processTypeOption]);

      const outputs = (
        await this.recordSharingService.getInboundProcessInputOrOutput<IProcessOutput>(
          "outputs",
          id,
          this.organisationIdQueryParam,
        )
      ).content;
      const inputs = (
        await this.recordSharingService.getInboundProcessInputOrOutput<IProcessInput>(
          "inputs",
          id,
          this.organisationIdQueryParam,
        )
      ).content;
      const processItems = [...outputs, ...inputs].map((r) => r.items).flat();

      const itemsIds = processItems.map((i) => CommonUtils.getUriId(i.item));
      const productsFieldsInclude = ["DEFAULT_CUSTOM_UNIT"];

      if (this.isOldMaterialsEnabled) {
        productsFieldsInclude.push("ALLOWED_MATERIALS");
      } else {
        productsFieldsInclude.push("MATERIALS");
      }

      const items = await this.recordSharingService.getInboundItemsByIdsGraphQL(
        itemsIds,
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        [],
        productsFieldsInclude,
      );

      this.items.set(
        items.map((item) => {
          const selectedQuantity = processItems.find((i) =>
            i.item.includes(item.id),
          ).selectedQuantity;

          return { ...item, selectedQuantity: selectedQuantity };
        }),
      );

      const products = items.map((item) => item.product) as any as IProductExtended[];

      this.allProducts.set(products);

      this.processInputs.set(inputs);
      this.processOutputs.set(outputs);
      this.setupForm();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.overlay.dismissLoading();
    }
  }

  public override setupForm(): void {
    this.formGroup = new UntypedFormGroup({
      processId: new UntypedFormControl(this.element().processId),
      type: new UntypedFormControl(this.allProcessTypesOptions()[0]),
      location: new UntypedFormControl(this.allLocationsOptions()[0]),
      firstInputDate: new UntypedFormControl(this.element()?.firstInputDate),
      lastOutputDate: new UntypedFormControl(this.element()?.lastOutputDate),
    });
  }

  getLocationLink(id: string) {
    return this.routerService.getSharedLocationLink(id, false, {
      organisationId: this.organisationIdQueryParam,
    }) as NavigationParams;
  }

  protected override recordName(): string {
    return this.element()?.processId;
  }
}
