@if (showImage()) {
  <img
    *ngIf="url"
    [src]="url"
    class="flag"
    i18n-alt
    alt="flag"
    [ngClass]="size"
    (error)="onError()"
  />
}
