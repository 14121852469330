<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
  <h2 mat-dialog-title i18n>Add connection</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <p i18n>
        Copy and send your organisation's code to another organisation for them to connect:
      </p>
      <div class="info-hint">
        <div class="text-center">
          <app-value-and-copy-button
            [value]="data.activeOrganisationId"
          ></app-value-and-copy-button>
        </div>
      </div>
      <p class="text-center" i18n>OR</p>
      <p i18n>Enter another organisation's code</p>
      <app-input
        [autofocus]="true"
        formControlName="organisationId"
        [label]="translations.organisationIdLabel"
        hintClass="info-hint"
        [hint]="
          isLoadingConnectionDetails() || selectedPublicOrganisation()
            ? ''
            : translations.organisationCodeHint
        "
      ></app-input>

      @if (isLoadingConnectionDetails()) {
        <app-loader containerClass="on-dialog"></app-loader>
      } @else {
        @if (selectedPublicOrganisation()) {
          <div class="info-hint">
            <div class="row-container">
              <div class="row row-1">
                <div class="bold" i18n>Name</div>
                {{ selectedPublicOrganisation().name }}
              </div>
            </div>
            <div class="row-container">
              <div class="row row-1">
                <div class="bold" i18n>Website</div>
                {{ selectedPublicOrganisation().website ?? "-" }}
              </div>
            </div>
            <div class="row-container">
              <div class="row row-1">
                <div class="bold" i18n>Address</div>
                @if (selectedPublicOrganisation().address.street) {
                  {{ selectedPublicOrganisation().address.street }}
                  <br />
                }
                @if (selectedPublicOrganisation().address.region) {
                  {{ selectedPublicOrganisation().address.region }}
                  <br />
                }

                @if (selectedPublicOrganisation().address.zipCode) {
                  {{ selectedPublicOrganisation().address.zipCode }}
                  <br />
                }
                <div>
                  <app-flag [country]="selectedPublicOrganisation().address.country"></app-flag>
                  {{ selectedPublicOrganisation().address.countryName }}
                </div>
              </div>
            </div>
          </div>

          @if (selectedOrganisationExistingConnection()) {
            <div class="info-hint info-hint-bg-yellow mb-14">
              <div class="row-container">
                <div class="row row-1">
                  @switch (selectedOrganisationExistingConnection().connectStatus) {
                    @case (connectionStatusEnum.CONNECTED) {
                      <div class="bold" i18n>Connection already exists:</div>
                      <ng-container
                        [ngTemplateOutlet]="selectedOrganisationExistingConnectionLinkTemplate"
                      ></ng-container>
                    }
                    @case (connectionStatusEnum.DISCONNECTED) {
                      <div class="bold" i18n>
                        An organisation with this code already exists, but is disconnected. Please
                        request connection if you would like to re-connect.
                      </div>
                    }
                    @case (null) {
                      <div class="bold" i18n>
                        An organisation with this code is already in your records. Please ensure
                        it’s the correct one before requesting connection
                      </div>
                      <ng-container
                        [ngTemplateOutlet]="selectedOrganisationExistingConnectionLinkTemplate"
                      ></ng-container>
                    }
                  }
                </div>
              </div>
            </div>
            <ng-template #selectedOrganisationExistingConnectionLinkTemplate>
              <app-form-link
                [link]="getConnectionLink(selectedOrganisationExistingConnection().id)"
                [styledLink]="true"
                [openInNewTab]="true"
              >
                {{ selectedOrganisationExistingConnection().name }}
              </app-form-link>
            </ng-template>
          } @else {
            <p>
              Select
              <b>{{ selectedPublicOrganisation().name }}</b>
              from your organisations:
            </p>
            <app-input-select
              [options]="connectionsOptions"
              formControlName="connection"
              [label]="translations.connectionLabel"
            ></app-input-select>
            <div class="font-size-14 mb-14">
              <ng-container i18n>Or</ng-container>
              <ng-container>&nbsp;</ng-container>
              <a class="blue-link" (click)="onAddNewConnection()" i18n>add a new organisation</a>
            </div>
          }
        }
      }
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>

        <app-button accessibilityRole="submit" [disabled]="isSubmitButtonDisabled()">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Request connection</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
