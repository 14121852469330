<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.INBOX"
  ></app-page-title>
</div>
<div class="form-page-container container">
  <div class="element-content">
    @if (isLoading()) {
      <div *ngFor="let _ of [1, 2]" class="skeleton-container">
        <div class="skeleton-header"></div>
        <div class="skeleton-content flex-column">
          <div class="skeleton-item" *ngFor="let _ of [].constructor(5)"></div>
        </div>
      </div>
    } @else if (configs.length) {
      @if (existingRecordsConfig().length) {
        <h2>
          <ng-container i18n>Existing</ng-container>
          <mat-icon class="info-icon" [tp]="translations.existingTp" tpPlacement="right">
            help
          </mat-icon>
        </h2>

        @for (config of existingRecordsConfig(); let idx = $index; track idx) {
          <h3>
            {{ config.title }}
          </h3>
          <app-trasfer-or-map-form
            [shareRecordType]="config.shareRecordType"
            [sharedData]="config.sharedData"
            [localRecords]="config.localRecords"
            [inputSelectPlaceholder]="config.inputSelectPlaceholder"
            [existingMappings]="config.existingMappings"
            [isViewButtonDisabled]="config.isViewButtonDisabled"
            (newRecordCreated)="config.reloadFn()"
            [inboundShare]="inboundShare"
            [isTransferButtonDisabled]="
              config.isTransferButtonDisabled && !!existingRecordsConfig().length
            "
            (inboundMappingAdded)="inboundMappingAdded($event)"
            (inboundMappingRemoved)="inboundMappingRemoved($event)"
          ></app-trasfer-or-map-form>
        }
      }

      @if (newRecordsConfig().length) {
        <h2>
          <ng-container i18n>New records</ng-container>
          <mat-icon class="info-icon" [tp]="translations.newRecordsTp" tpPlacement="right">
            help
          </mat-icon>
        </h2>
        @for (config of newRecordsConfig(); let idx = $index; track idx) {
          <h3
            [ngClass]="{
              disabled: config.isTransferButtonDisabled && !!existingRecordsConfig().length,
            }"
          >
            {{ config.title }}
            @if (config.isTransferButtonDisabled && !!existingRecordsConfig().length) {
              <mat-icon>lock</mat-icon>
              <mat-icon
                class="info-icon"
                [tp]="config.unmappedDependenciesTooltip"
                tpPlacement="right"
              >
                help
              </mat-icon>
            }
          </h3>
          <app-trasfer-or-map-form
            [shareRecordType]="config.shareRecordType"
            [sharedData]="config.sharedData"
            [localRecords]="config.localRecords"
            [inputSelectPlaceholder]="config.inputSelectPlaceholder"
            [existingMappings]="config.existingMappings"
            [isViewButtonDisabled]="config.isViewButtonDisabled"
            [isTransferButtonDisabled]="
              config.isTransferButtonDisabled && !!existingRecordsConfig().length
            "
            (newRecordCreated)="config.reloadFn()"
            [inboundShare]="inboundShare"
            [isTransferButtonDisabled]="config.isTransferButtonDisabled"
            (inboundMappingAdded)="inboundMappingAdded($event)"
            (inboundMappingRemoved)="inboundMappingRemoved($event)"
          ></app-trasfer-or-map-form>
        }
      }

      @if (mappedRecordsConfig().length) {
        <h2 i18n>Mapped records</h2>
        @for (config of mappedRecordsConfig(); let idx = $index; track idx) {
          <h3>{{ config.title }}</h3>
          <app-trasfer-or-map-form
            [shareRecordType]="config.shareRecordType"
            [sharedData]="config.sharedData"
            [localRecords]="config.localRecords"
            [inputSelectPlaceholder]="config.inputSelectPlaceholder"
            [existingMappings]="config.existingMappings"
            [isViewButtonDisabled]="config.isViewButtonDisabled"
            (newRecordCreated)="config.reloadFn()"
            [inboundShare]="inboundShare"
            (inboundMappingAdded)="inboundMappingAdded($event)"
            (inboundMappingRemoved)="inboundMappingRemoved($event)"
          ></app-trasfer-or-map-form>
        }
      }
    }
  </div>
</div>
