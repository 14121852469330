import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input, OnInit } from "@angular/core";

import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { ILocationExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-delivery-report-location-country-group",
  templateUrl: "./delivery-report-location-country-group.component.html",
  styleUrls: ["./delivery-report-location-country-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportLocationCountryGroupComponent implements OnInit {
  public locationsCountryGroup =
    input<KeyValue<string, { [locationType: string]: ILocationExtended[] }>>();

  public countryName: string;

  public locationsCount: number;

  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  public ngOnInit(): void {
    this.countryName = this.deliveryReportService
      .countryOptions()
      .find((option) => option.value === this.locationsCountryGroup().key)?.label;

    this.locationsCount = Object.values(this.locationsCountryGroup().value).reduce(
      (acc, locations) => acc + locations.length,
      0,
    );
  }
}
