import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import {
  ConfirmDialogComponent,
  EditCustomFieldDialogComponent,
  InfoDialogComponent,
} from "src/app/components";

import { EnumsCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
  TableEnum,
} from "@shared/enums";
import { ICustomField } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, CustomFieldsService } from "@shared/services";
import { ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-custom-fields-table",
  templateUrl: "./custom-fields-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsTableComponent implements AfterViewInit {
  @Input()
  public customFields: ICustomField[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.CUSTOM_FIELDS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: ICustomField[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private customFieldsService: CustomFieldsService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "label",
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.customFieldsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.customFieldsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.customFieldsService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: $localize`Label`,
        field: "label",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onDelete,
                  tooltip: TextConstants.DELETE,
                  icon: "delete",
                },
                {
                  click: this.onToggleArchiveStatus,
                  tooltip: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE
                      ? TextConstants.ARCHIVE
                      : TextConstants.UNARCHIVE,
                  icon: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory",
                },
                {
                  click: this.onEdit,
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                },
              ]
            : [],
        },
      },
      { headerName: TextConstants.TYPE, field: "type", cellRenderer: EnumsCellRendererComponent },
      ColumnUtils.chips("Modules", "resourceTypes"),
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }
    this.columnDefs.set(columnDefs);
  };

  public onDelete = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this custom field?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.customFieldsService.delete(element.id);
          this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentText: TextConstants.EDIT_ARCHIVED_RECORD_ERROR,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditCustomFieldDialogComponent, {
      data: {
        customField: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: ICustomField): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.customFieldsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  private getParsedRowData = (customFields: any[]): ICustomField[] => {
    for (const customField of customFields) {
      const resourceTypes = [];

      for (const resourceType of customField.resourceTypes) {
        resourceTypes.push(CommonUtils.getParsedCustomFieldResourceType(resourceType));
      }
      customField.resourceTypes = resourceTypes;
    }

    return customFields;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.customFields) {
      this.rowData = this.getParsedRowData(this.customFields);
      this.isLoading.set(false);
    } else {
      try {
        const customFields = await this.customFieldsService.getAll();

        this.rowData = this.getParsedRowData(customFields);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
