import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmDialogComponent, InfoDialogComponent } from "@components/shared";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  CustomFieldsTypeEnum,
  RouteEnum,
} from "@shared/enums";
import { ICustomField, IEudrCredentials } from "@shared/interfaces";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import {
  NotificationService,
  AuthenticationService,
  CustomFieldsService,
  EudrExtensionService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

import { EudrConnectDialogComponent } from ".";

@Component({
  standalone: false,
  templateUrl: "./settings-eudr.component.html",
  styleUrl: "./settings-eudr.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsEudrComponent implements OnInit {
  public isLoading = signal(true);

  public eudrCredentials = signal<IEudrCredentials>(undefined);

  public statements = signal<EudrDds.IDetails[]>([]);

  public readonly routingEnum = RouteEnum;

  public readonly isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();

  private allCustomFields: ICustomField[] = [];

  private requiredCustomFieldsGroups: {
    resourceType: CustomFieldsResourceTypeEnum;
    labels: string[];
  }[] = [];

  public missingCustomFieldGroups: {
    resourceType: CustomFieldsResourceTypeEnum;
    labels: string[];
  }[] = [];

  public isDdsServiceUnavailable = false;

  public readonly serviceUnavailableMsg = TextConstants.SERVICE_UNAVAILABLE;

  public readonly translations: any = {
    titleBackText: TextConstants.BACK_TO_ADMIN,
    optionsTp: $localize`Options`,
    missingCustomFieldsTp: $localize`Some custom fields that are required for EUDR integration are not present. Click here to add them`,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private eudrExtensionService: EudrExtensionService,
    private customFieldsService: CustomFieldsService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await Promise.all([this.setMissingCustomFields(), this.getCredentials()]);

    this.isLoading.set(false);
  }

  private getCredentials = async (): Promise<void> => {
    try {
      const credentials = await this.eudrExtensionService.get();

      this.eudrCredentials.set(credentials);
    } catch (error) {
      if (error.status === 503) {
        this.isDdsServiceUnavailable = true;
      } else if (error.status !== 404) {
        this.notificationService.showError(error);
      }
    }
  };

  private setMissingCustomFields = async (): Promise<void> => {
    this.missingCustomFieldGroups = [];
    this.allCustomFields = await this.customFieldsService.getAll();

    this.requiredCustomFieldsGroups = [
      {
        resourceType: CustomFieldsResourceTypeEnum.PRODUCT,
        labels: ["HS Code", "Description"],
      },
      {
        resourceType: CustomFieldsResourceTypeEnum.MATERIAL,
        labels: ["Scientific name", "Common name"],
      },
      {
        resourceType: CustomFieldsResourceTypeEnum.LOCATION,
        labels: ["Area (ha)"],
      },
    ];

    for (const group of this.requiredCustomFieldsGroups) {
      for (const label of group.labels) {
        if (
          !this.allCustomFields.some(
            (cf) => cf.resourceTypes.includes(group.resourceType) && cf.label === label,
          )
        ) {
          this.addMissingCustomField(group.resourceType, label);
        }
      }
    }
  };

  private addMissingCustomField = (
    resourceType: CustomFieldsResourceTypeEnum,
    label: string,
  ): void => {
    const group = this.missingCustomFieldGroups.find((m) => m.resourceType === resourceType);

    if (group) {
      group.labels.push(label);
    } else {
      this.missingCustomFieldGroups.push({ resourceType, labels: [label] });
    }
  };

  public onOpenMissingCustomFieldsDialog = (): void => {
    let contentListHtml = "";

    for (const group of this.missingCustomFieldGroups) {
      let labelsHtml = "";

      for (const label of group.labels) {
        labelsHtml += `<li>${label}</li>`;
      }
      contentListHtml += `<li>${CommonUtils.capitaliseFirstLetter(group.resourceType)}<ul>${labelsHtml}</ul></li>`;
    }

    const contentHTML = `The following custom fields will be added to the corresponding modules to ensure proper functioning of the EUDR integration:<br><ul>${contentListHtml}</ul>`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Add custom fields`,
        contentHTML,
        confirmButtonText: $localize`Add`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result !== ConfirmDialogResponseEnum.CONFIRM) {
        return;
      }

      this.isLoading.set(true);

      const promises = [];

      for (const group of this.missingCustomFieldGroups) {
        for (const label of group.labels) {
          const existingCustomField = this.allCustomFields.find((cf) => cf.label === label);

          const resourceTypes = existingCustomField ? existingCustomField.resourceTypes : [];

          resourceTypes.push(group.resourceType);
          const type = existingCustomField?.type ?? CustomFieldsTypeEnum.TEXT;

          promises.push(
            await this.customFieldsService.createOrUpdate(
              {
                id: existingCustomField?.id ?? undefined,
                label,
                resourceTypes,
                type,
              },
              existingCustomField?.id ?? undefined,
            ),
          );
        }
      }

      try {
        await Promise.all(promises);
        this.notificationService.showSuccess($localize`Custom fields added`);
      } catch (error) {
        this.notificationService.showError(error);
      } finally {
        await this.setMissingCustomFields();
        this.isLoading.set(false);
      }
    });
  };

  public onTestConnection = async (): Promise<void> => {
    try {
      const credentials = this.eudrCredentials();
      const payload = {
        username: credentials.username,
        authKey: credentials.authKey,
      };

      await this.eudrExtensionService.ping(payload);
      this.notificationService.showSuccess($localize`Connection test successful`);
    } catch (error) {
      let contentText = $localize`We couldn't establish a connection with the EU Information System using the stored credentials. Please ensure the validity of the credentials or try later.`;

      if (error?.code === 503) {
        contentText = TextConstants.SERVICE_UNAVAILABLE;
      }
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          title: $localize`Connection test failed`,
          contentText,
        },
      });
    }
  };

  public onRemoveConnection = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`This will remove your EU IS credentials from Interu, but will keep all EUDR DDS records. Would you like to proceed?`,
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonColor: "danger",
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoading.set(true);
        try {
          await this.eudrExtensionService.delete();
          this.eudrCredentials.set(undefined);
          this.notificationService.showSuccess($localize`Connection removed`);
        } catch (error) {
          this.notificationService.showError(error);
        } finally {
          this.isLoading.set(false);
        }
      }
    });
  };

  public onOpenConnectDialog = (): void => {
    const dialogRef = this.dialog.open(EudrConnectDialogComponent, {
      data: { eudrCredentials: this.eudrCredentials() },
    });

    dialogRef
      .afterClosed()
      .subscribe((result: { hasSaved: boolean; credentials: IEudrCredentials }) => {
        if (result?.hasSaved) {
          this.eudrCredentials.set(result.credentials);
        }
      });
  };
}
