import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";

import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, TableEnum } from "@shared/enums";
import { IOrganisation } from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  OrganisationsService,
  UsersService,
} from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent, SelectOrganisationDialogComponent } from "../..";

@Component({
  standalone: false,
  selector: "app-memberships-table",
  templateUrl: "./memberships-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembershipsTableComponent implements AfterViewInit {
  @Input()
  public memberships: IOrganisation[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.MEMBERSHIPS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public rowData: IOrganisation[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private usersService: UsersService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private organisationsService: OrganisationsService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.ORGANISATION,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          isLinkStyle: true,
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onLeaveOrganisation,
                  tooltip: $localize`Leave organisation`,
                  icon: "logout",
                },
              ]
            : [],
        },
      },
      { headerName: $localize`Role`, field: "roles", cellRenderer: CellRendererUtils.roleNames },
    ];

    this.columnDefs.set(columnDefs);
  };

  public onRowClick = async (row: IOrganisation): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    await this.onSelectOrganisation(row);
  };

  private onSelectOrganisation = async (organisation: IOrganisation): Promise<void> => {
    this.isLoading.set(true);

    const availableOrganisations = this.authenticationService.getAvailableOrganisations();
    const selectedAvailableOrganisationIndex = availableOrganisations.findIndex(
      (a) => a.id === organisation.id,
    );

    if (selectedAvailableOrganisationIndex !== -1) {
      await this.authenticationService.setActiveOrganisationIndex(
        selectedAvailableOrganisationIndex,
      );
    }

    this.isLoading.set(false);

    this.notificationService.showSuccess(
      $localize`${organisation.name}:organisationName: is now active`,
    );
  };

  public onLeaveOrganisation = (organisation: IOrganisation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Leave organisation`,
        contentText: $localize`Are you sure that you want to leave this organisation?`,
        confirmButtonColor: "danger",
        confirmButtonText: $localize`Leave`,
        confirmButtonIcon: "logout",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoading.set(true);
        const userId = this.authenticationService.getUserId();

        await this.usersService
          .leaveOrganisation(userId, organisation.id)
          .then(async () => {
            this.notificationService.showSuccess(
              $localize`You left '${organisation.name}:organisationName:'`,
            );
            const previouslyActiveOrgId = this.authenticationService.getActiveOrganisationId();

            await this.authenticationService.removeAvailableOrganisation(organisation.id);
            this.isLoading.set(false);

            if (this.authenticationService.getAvailableOrganisations()?.length) {
              if (organisation.id === previouslyActiveOrgId) {
                this.onOpenSelectOrganisationDialog();
              } else {
                await this.getAll();
              }
            }
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
            this.isLoading.set(false);
          });
      }
    });
  };

  private onOpenSelectOrganisationDialog = (): void => {
    const dialogRef = this.dialog.open(SelectOrganisationDialogComponent);

    dialogRef.afterClosed().subscribe(async () => {
      await this.getAll();
    });
  };

  private getParsedRowData = async (memberships: any[]): Promise<any[]> => {
    const result = [];

    for (const userOrganisation of memberships) {
      const organisationId = CommonUtils.getUriId(userOrganisation.organisation);
      const organisation = await this.organisationsService.get(organisationId);

      const memberId = CommonUtils.getUriId(userOrganisation.membership);
      const member = await this.organisationsService.getOrganisationMember(
        organisationId,
        memberId,
      );

      result.push({ id: organisationId, name: organisation.name, roles: member.roles });
    }

    return result;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.memberships) {
      this.rowData = await this.getParsedRowData(this.memberships);
      this.isLoading.set(false);
    } else {
      try {
        const userId = this.authenticationService.getUserId();
        const memberships = await this.usersService.getAllUserOrganisationMemberships(userId);

        this.rowData = await this.getParsedRowData(memberships);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
