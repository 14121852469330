import { Injectable, inject } from "@angular/core";

import { ColDef, IRowNode, ValueGetterParams } from "ag-grid-community";

import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TableComponent } from "@components/shared/tables";
import { TextConstants } from "@shared/constants";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { RiskAssessmentReportsApiService } from "../api";
import { RiskAssessmentReport } from "../models";

export interface LatestRiskAssessmentReportRowData {
  riskAssessmentReport?: Pick<RiskAssessmentReport, "id" | "completedDate"> & {
    residualRisk: RiskAssessmentReport["residualRisk"] & { [key: string]: unknown; id: string };
  };
}

@Injectable({ providedIn: "root" })
export class TableWithRiskAssessmentReportsService {
  private readonly authService = inject(AuthenticationService);

  private readonly router = inject(RouterService);

  private readonly reportsApi = inject(RiskAssessmentReportsApiService);

  private readonly resourceMap: Record<RiskAssessmentTemplateResourceType, string> = {
    [RiskAssessmentTemplateResourceType.DELIVERY]: "deliveries",
    [RiskAssessmentTemplateResourceType.LOCATION]: "locations",
    [RiskAssessmentTemplateResourceType.MATERIAL]: "materials",
    [RiskAssessmentTemplateResourceType.ORGANISATION]: "connections",
    [RiskAssessmentTemplateResourceType.PRODUCT]: "products",
    [RiskAssessmentTemplateResourceType.COUNTRY]: "countries",
  };

  private readonly cellRendererParams = {
    linkRouteIdParam: "riskAssessmentReport.id",
    linkRouteNameParam: "name",
    linkRouteFn: this.router.getRiskAssessmentReportLink,
  };

  readonly lastRiskAssessedColDef = ColumnUtils.dateColumn({
    headerName: $localize`Last risk assessed`,
    field: "riskAssessmentReport.completedDate",
    cellRendererParams: this.cellRendererParams,
  });

  readonly residualRiskLevelColDef: ColDef = {
    ...ColumnUtils.chips("Risk level", "riskAssessmentReport.residualRisk", {
      ...this.cellRendererParams,
      linkRouteIdParam: "id",
      textParam: "title",
      classParam: "color",
    }),
    filterValueGetter: (params: ValueGetterParams<LatestRiskAssessmentReportRowData>) =>
      params.data.riskAssessmentReport?.residualRisk
        ? params.data.riskAssessmentReport.residualRisk.title
        : "-",
  };

  private resourceType: RiskAssessmentTemplateResourceType;

  init(resourceType: RiskAssessmentTemplateResourceType) {
    this.resourceType = resourceType;
  }

  async updateRowData<T extends { id: string }>(
    rows: IRowNode<T>[],
    table: TableComponent,
    nameField = "name",
  ) {
    const updatedRows = await this.getElementsWithReport(this.resourceType, rows, nameField);

    if (updatedRows.length) {
      table.grid.api.refreshCells({
        rowNodes: updatedRows,
        columns: [this.lastRiskAssessedColDef.field, this.residualRiskLevelColDef.field],
      });

      table.grid.api.onFilterChanged();
    }
  }

  /**
   * @note have to mutate row fields directly in order to maintain object references for AG Grid
   */
  private async getElementsWithReport<T extends { id: string } & LatestRiskAssessmentReportRowData>(
    type: RiskAssessmentTemplateResourceType,
    rows: IRowNode<T>[],
    nameField: string,
  ): Promise<IRowNode<T>[]> {
    const resource = this.resourceMap[type];

    if (!resource) {
      throw new Error(TextConstants.UNSUPPORTED_RESOURCE_TYPE);
    }

    const ids = rows
      .filter(({ data }) => !("riskAssessmentReport" in data))
      .map(({ data }) => data.id);

    if (!ids.length) {
      return [];
    }

    const recordBaseUri = `/organisations/${this.authService.getActiveOrganisationId()}/${resource}`;

    const reports = await this.reportsApi.getLatest({
      records: ids.map((id) => `${recordBaseUri}/${id}`),
    });

    const reportsMap = new Map<string, RiskAssessmentReport>();

    for (const { uri, report } of reports) {
      reportsMap.set(CommonUtils.getUriId(uri), report);
    }

    return rows.map((row) => {
      const report = reportsMap.get(row.data.id);

      if (!report) {
        row.data.riskAssessmentReport = undefined;

        return row;
      }

      row.data.riskAssessmentReport = {
        id: report.id,
        completedDate: report.completedDate,
        residualRisk: {
          ...report.residualRisk,
          id: report.id,
          [nameField]: row.data[nameField],
        },
      };

      return row;
    });
  }
}
