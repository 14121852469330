<div [attr.data-environment]="environmentName"></div>
@if (isLoggedIn() && userData && isShowSideMenuAndNavRoute()) {
  <app-sidebar-menu
    [isCrossOrgSharingEnabled]="isCrossOrgSharingEnabled"
    [isAccountOwnerOrAdminOrContributor]="isAccountOwnerOrAdminOrContributor"
    [isSharedUser]="isSharedUser"
    [isAdminMenuEnabled]="isAdminMenuEnabled"
    [canAddModifyEntities]="canAddModifyEntities"
    [currentOrganisation]="activeOrganisation"
    [pendingInvitationsCount]="pendingInvitationsCount()"
    [newInboundSharesCount]="newInboundSharesCount()"
    [userData]="userData"
    [userName]="userName"
    [userType]="userType"
  ></app-sidebar-menu>

  <app-slide-overlay-page></app-slide-overlay-page>

  <app-bulk-add-slide-over></app-bulk-add-slide-over>
} @else {
  <router-outlet></router-outlet>
}
