import { inject, Injectable, signal } from "@angular/core";

import { Observable } from "rxjs";

import { SendDialogComponent, ShareDialogComponent } from "@components/shared";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";
import {
  FeatureFlagEnum,
  RecordStateEnum,
  SendTargetTypeEnum,
  ShareTargetTypeEnum,
} from "@shared/enums";
import { IMaterial, IProductExtended } from "@shared/interfaces";
import { FeatureFlagService, MaterialsService, ProductsService } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class DeliveryOverlayService extends OverlayCommonService {
  public linkedSharesCount = signal<number>(0);

  public allProducts: IProductExtended[] = [];

  public allMaterials: IMaterial[] = [];

  private productsService: ProductsService = inject(ProductsService);

  private featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  private materialsService: MaterialsService = inject(MaterialsService);

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor() {
    super();
  }

  public share(
    activeOrganisationId: string,
    deliveryId: string,
  ): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(ShareDialogComponent, {
        data: {
          shareTargetType: ShareTargetTypeEnum.DELIVERY,
          rootRecordUri: `/organisations/${activeOrganisationId}/deliveries/${deliveryId}`,
        },
      })
      .afterClosed();
  }

  public send(activeOrganisationId: string, deliveryId: string): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(SendDialogComponent, {
        data: {
          sendTargetType: SendTargetTypeEnum.DELIVERY,
          rootRecordUri: `/organisations/${activeOrganisationId}/deliveries/${deliveryId}`,
        },
      })
      .afterClosed();
  }

  override setCountersToLoadingState() {
    super.setCountersToLoadingState();
    this.linkedSharesCount.set(null);
  }

  override setCountersEmptyState() {
    super.setCountersEmptyState();
    this.linkedSharesCount.set(0);
  }

  public async onReloadProducts(includes = []): Promise<void> {
    try {
      this.allProducts = await this.productsService.getAllGraphQL(undefined, undefined, includes);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public get productOptions(): InputSelectOption[] {
    return this.allProducts
      .filter((p) => p.recordState === RecordStateEnum.ACTIVE)
      .map((p: IProductExtended) => ({ label: p.name, value: p.id }));
  }

  public async onReloadMaterials(): Promise<void> {
    try {
      this.allMaterials = await this.materialsService.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }
}
