import { booleanAttribute, Input, Component, ChangeDetectionStrategy } from "@angular/core";
import { ValidationErrors } from "@angular/forms";

@Component({
  standalone: false,
  selector: "app-form-hint",
  templateUrl: "./form-hint.component.html",
  styleUrl: "./form-hint.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHintComponent {
  @Input() hint: string = "";

  @Input() customClass: string = "";

  @Input() customErrorClass: string = "";

  @Input({ transform: booleanAttribute }) showError: boolean = false;

  @Input({ transform: booleanAttribute }) isOptional: boolean = false;

  @Input({ transform: booleanAttribute }) isDisabled: boolean = false;

  @Input() errors: ValidationErrors = {};
}
