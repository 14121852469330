import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  Output,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  ConnectRequestStatusEnum,
  TableEnum,
} from "src/app/shared/enums";

import { ConfirmDialogComponent } from "@components/shared";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import { IInboundConnectRequest, ISelectOption } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import {
  NotificationService,
  ConnectRequestsService,
  OrganisationsService,
} from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { SettingsAcceptConnectionDialogComponent } from "..";

@Component({
  standalone: false,
  selector: "app-settings-received-connections-table",
  templateUrl: "./settings-received-connections-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsReceivedConnectionsTableComponent implements AfterViewInit {
  @Input()
  public activeOrganisationId: string;

  @Input()
  public countryOptions: ISelectOption[];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  @Output() requestAccepted: EventEmitter<void> = new EventEmitter<void>();

  public readonly table = TableEnum.ADMIN_CONNECTIONS_RECEIVED;

  public isLoading = signal(true);

  public rowData = signal<any[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  constructor(
    private notificationService: NotificationService,
    private connectRequestsService: ConnectRequestsService,
    private organisationsService: OrganisationsService,
    private dialog: MatDialog,
    private overlayPageService: SlideOverlayPageService,
    private routerService: RouterService,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.REJECT_CONNECTION_REQUEST,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.REJECT_CONNECTION_REQUEST,
            this.connectRequestsService,
          ),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.ORGANISATION,
        field: "publicOrganisation.name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          linkRouteIdParam: "publicOrganisation.id",
          linkRouteFn: this.routerService.getPublicOrganisationLink,
          actions: [
            {
              click: this.onReject,
              tooltip: $localize`Reject`,
              icon: "block",
            },
            {
              click: this.onAccept,
              tooltip: TextConstants.ACCEPT,
              icon: "check_circle",
            },
          ],
        },
      },
      ColumnUtils.dateColumn({
        headerName: $localize`Date`,
        field: "createdTime",
        cellRendererParams: {
          dateFormat: CommonConstants.DATE_TIME_FORMAT,
        },
      }),
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private onReject = (row: IInboundConnectRequest): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Reject connection request`,
        contentText: $localize`Are you sure that you want to reject this connection request?`,
        confirmButtonColor: "danger",
        confirmButtonText: $localize`Reject`,
        confirmButtonIcon: "block",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoading.set(true);
        try {
          await this.connectRequestsService.rejectInbound(row.id);
          this.notificationService.showSuccess($localize`Connection request rejected`);
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
        } finally {
          this.isLoading.set(false);
        }
      }
    });
  };

  private onAccept = async (row: any): Promise<void> => {
    const result = await this.overlayPageService.openDialog<
      { hasSaved: boolean },
      {
        activeOrganisationId: string;
        connectionRequestId: string;
        publicOrganisation: any;
        countryOptions: ISelectOption[];
      }
    >(SettingsAcceptConnectionDialogComponent, {
      activeOrganisationId: this.activeOrganisationId,
      connectionRequestId: row.id,
      publicOrganisation: row.publicOrganisation,
      countryOptions: this.countryOptions,
    });

    if (result?.hasSaved) {
      this.requestAccepted.emit();
      await this.getAll();
    }
  };

  private getParsedRowData = async (inboundRequests: IInboundConnectRequest[]) => {
    const result: any[] = [];

    for (const inboundRequest of inboundRequests) {
      const publicOrganisationId = CommonUtils.getUriId(inboundRequest.requestingOrgUri);
      const publicOrganisation = await this.organisationsService.getPublic(
        CommonUtils.getUriId(publicOrganisationId),
      );

      result.push({
        id: inboundRequest.id,
        createdTime: inboundRequest.createdTime,
        publicOrganisation: {
          ...publicOrganisation,
          id: publicOrganisationId,
        },
      });
    }

    return result;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const inboundRequests = await this.connectRequestsService.getAllInbound(
        ConnectRequestStatusEnum.PENDING,
      );

      this.rowData.set(await this.getParsedRowData(inboundRequests.content));

      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
