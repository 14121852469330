<div class="container-space-between">
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>
  <app-button-group [visible]="canAddModifyEntities">
    <app-more-actions-buttons
      (clickLabel)="onAdd()"
      [isArchiveEnabled]="false"
      [label]="translations.addNewLabel"
      labelIcon="Add"
    >
      <app-dropdown-menu-item (pressed)="onOpenBulkAddLocationsSlideOver()">
        <mat-icon>library_add</mat-icon>
        <ng-container i18n>Bulk add locations</ng-container>
      </app-dropdown-menu-item>
    </app-more-actions-buttons>
  </app-button-group>
</div>

<app-locations-table
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
  [columns]="[
    'recordState',
    'nameWithRulesets',
    'organisationName',
    'types',
    'address.countryName',
    'geoLocation',
    'tags',
    'riskAssessmentReport.completedDate',
    'riskAssessmentReport.residualRisk',
  ]"
></app-locations-table>
