<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Create new organisation</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input [autofocus]="true" formControlName="name" [label]="translations.name"></app-input>

      <app-input-select
        [options]="countryOptions"
        formControlName="country"
        [label]="translations.country"
        [prefixTemplate]="flag"
      ></app-input-select>
      <ng-template #flag let-option>
        <app-flag [country]="option.value"></app-flag>
      </ng-template>

      <app-button-toggle formControlName="specifyAddress" i18n>
        Specify full address
      </app-button-toggle>
      <br />
      <br />
      @if (isSpecifyAddress) {
        <app-input formControlName="street" [label]="translations.address"></app-input>

        <app-input formControlName="region" [label]="translations.region"></app-input>

        <app-input formControlName="zipCode" [label]="translations.zipCode"></app-input>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Create organisation</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
