import { Injectable, inject } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";

import { Subject } from "rxjs";

import {
  Indicator,
  IndicatorGroup,
  RiskAssessmentTemplatePayload,
} from "@components/shared/risk-assessment-templates/models";
import { RiskMitigationsApiService } from "@components/shared/risk-mitigations/api";
import { RiskMitigation } from "@components/shared/risk-mitigations/models";
import { RecordStateEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { CategoryFormGroup, IndicatorFormGroup, IndicatorType } from "./models";
import { uniqueCategoryNameValidator } from "./unique-category-name.validator";

type IndicatorsForm = FormArray<FormGroup<IndicatorFormGroup | CategoryFormGroup>>;

@Injectable()
export class RiskAssessmentTemplateOverlayIndicatorsFormService {
  private readonly fb = inject(FormBuilder);

  private readonly authService = inject(AuthenticationService);

  readonly mitigationsApi = inject(RiskMitigationsApiService);

  readonly mitigationsMap = new Map<string, RiskMitigation>();

  private readonly indicatorTitleMap = {
    indicator: "Indicator",
    category: "Category",
  };

  private form: IndicatorsForm;

  readonly controlAdded$ = new Subject<FormGroup>();

  readonly controlRemoved$ = new Subject<FormGroup>();

  init(form: IndicatorsForm) {
    this.form = form;
  }

  async set(indicatorGroups: IndicatorGroup[]) {
    this.form.clear();

    const mitigationsPresent = indicatorGroups.some((indicatorGroup) =>
      indicatorGroup.indicators.some((indicator) => indicator.mitigations.length > 0),
    );

    if (mitigationsPresent) {
      const { content } = await this.mitigationsApi.getAll({
        recordState: RecordStateEnum.ACTIVE,
      });

      content.forEach((mitigation) => this.mitigationsMap.set(mitigation.id, mitigation));
    }

    indicatorGroups.forEach((category) => {
      if (category.name !== null) {
        this.addCategoryFormGroup(category);
      }

      category.indicators.forEach((indicator) => this.addIndicatorFormGroup(indicator));
    });
  }

  addIndicatorFormGroup(indicator?: Indicator, position?: number) {
    const group = this.getBaseFormGroup("indicator");

    group.addControl("guidance", this.fb.control("", [CustomValidators.maxLength(5000)]));
    group.addControl("mitigations", this.fb.array([]));
    group.updateValueAndValidity();

    if (indicator) {
      group.patchValue({
        name: indicator.indicator,
        guidance: CommonUtils.nl2br(indicator.guidance),
        mitigations:
          this.mitigationsMap?.size > 0
            ? indicator.mitigations
                .map((mitigationUri) => CommonUtils.getUriId(mitigationUri))
                .filter((mitigationId) => this.mitigationsMap.has(mitigationId))
                .map((mitigationId) => this.fb.control(this.mitigationsMap.get(mitigationId)))
            : [],
      });

      const mitigationControls = group.get("mitigations") as IndicatorFormGroup["mitigations"];

      mitigationControls.clear();

      if (this.mitigationsMap?.size > 0) {
        indicator.mitigations
          .map((mitigationUri) => CommonUtils.getUriId(mitigationUri))
          .filter((mitigationId) => this.mitigationsMap.has(mitigationId))
          .map((mitigationId) => this.mitigationsMap.get(mitigationId))
          .forEach((mitigation) => {
            mitigationControls.push(this.fb.control(mitigation));
          });
      }
    }

    this.updateControl(group, position);
  }

  addCategoryFormGroup(category?: IndicatorGroup, position?: number) {
    const group = this.getBaseFormGroup("category");

    group.get("name").addValidators([uniqueCategoryNameValidator(() => this.form.controls)]);
    group.updateValueAndValidity();

    if (category) {
      group.patchValue({ name: category.name });
    }

    this.updateControl(group, position);
  }

  remove(index: number) {
    const formGroup = this.form.at(index);

    this.controlRemoved$.next(formGroup);
    this.form.removeAt(index);
    this.form.updateValueAndValidity();
  }

  addMitigation(group: FormGroup<IndicatorFormGroup>, riskMitigation: RiskMitigation) {
    group.controls.mitigations.push(this.fb.control(riskMitigation));
    this.form.updateValueAndValidity();
  }

  removeMitigation(indicatorFormGroupIndex: number, mitigationIndex: number) {
    const formGroup = this.form.at(indicatorFormGroupIndex) as FormGroup<IndicatorFormGroup>;

    formGroup.controls.mitigations.removeAt(mitigationIndex);
    this.form.updateValueAndValidity();
  }

  buildPayload(): RiskAssessmentTemplatePayload["indicatorGroups"] {
    let payload = [];
    let currentGroup: RiskAssessmentTemplatePayload["indicatorGroups"][0];

    const updatePayload = (group) => (payload = [...payload, group]);
    const orgId = this.authService.getActiveOrganisationId();

    this.form.getRawValue().forEach((indicator) => {
      if (indicator._type === "indicator") {
        if (currentGroup === undefined) {
          currentGroup = { name: null, indicators: [] };
        }

        currentGroup.indicators = [
          ...currentGroup.indicators,
          {
            guidance: indicator.guidance,
            indicator: indicator.name,
            mitigations: indicator.mitigations.map(
              (mitigation) =>
                `/organisations/${orgId}/risk-assessment/risk-mitigations/${mitigation.id}`,
            ),
          },
        ];
      } else if (indicator._type === "category") {
        if (currentGroup) {
          updatePayload(currentGroup);
        }

        currentGroup = { name: indicator.name, indicators: [] };
      }
    });

    if (currentGroup?.indicators.length) {
      updatePayload(currentGroup);
    }

    return payload;
  }

  private getBaseFormGroup(type: IndicatorType): FormGroup {
    return this.fb.group({
      _type: [type],
      _title: [this.indicatorTitleMap[type]],
      name: ["", [CustomValidators.required]],
    });
  }

  private updateControl(group: FormGroup, position?: number) {
    if (position !== undefined && position >= 0) {
      this.form.insert(position, group);
    } else {
      this.form.push(group);
    }

    this.form.updateValueAndValidity();
    this.controlAdded$.next(group);
  }
}
