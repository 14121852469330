import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import { EntityTypeEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import {
  IBaseUnit,
  IDeliveryExtended,
  IItem,
  IMaterial,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import { CommonService, RecordSharingService } from "@shared/services";
import { NavigationParams } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

import { DeliveryOverlayService } from "../delivery-overlay/delivery-overlay.service";

@Component({
  standalone: false,
  selector: "app-shared-delivery-overlay",
  templateUrl: "./shared-delivery-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SharedDeliveryOverlayComponent extends SlideOverlayPageClass implements OnInit {
  override element = signal<IDeliveryExtended>(null);

  override entityType = EntityTypeEnum.DELIVERIES;

  private readonly recordSharingService = inject(RecordSharingService);

  public fromLocationOptions = signal<ISelectOption[]>([]);

  public toLocationOptions = signal<ISelectOption[]>([]);

  public organisationsOptions = signal<ISelectOption[]>([]);

  public statusOptions = signal<ISelectOption[]>([]);

  public allUnitOfMeasurements: IBaseUnit[] = [];

  public selectedItems: IItem[] = [];

  public selectedItemsIds: string[] = [];

  public supplyChainHeight: number;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.DELIVERY_DETAILS, isEnabled: true }],
      [OverlayTabEnum.ITEMS, { title: TextConstants.ITEMS, isEnabled: true }],
      [OverlayTabEnum.CERTIFICATES, { title: TextConstants.CERTIFICATES, isEnabled: true }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: true }],
    ]),
  );

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    deliveryId: new UntypedFormControl(null),
    from: new UntypedFormControl(null),
    to: new UntypedFormControl(null),
    status: new UntypedFormControl(null),
    sent: new UntypedFormControl(null),
    delivered: new UntypedFormControl(null),
    agents: new UntypedFormControl(null),
  });

  constructor(
    public deliveryOverlayService: DeliveryOverlayService,
    private commonService: CommonService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_SHARED_DELIVERY)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
    this.supplyChainHeight = CommonUtils.getOverlaySupplyChainHeight();
  }

  get allProducts(): IProductExtended[] {
    return this.element().items?.map((i) => i.item.product);
  }

  get allMaterials(): IMaterial[] {
    return this.element().items?.map((i) => i.item.product.materials);
  }

  protected override async reloadElement(id: string): Promise<void> {
    this.overlay.showLoading();

    try {
      const inboundDeliveries = await this.recordSharingService.getInboundDeliveriesByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        ["DOCUMENTS", "AGENTS", "CERTIFICATES", "ITEMS"],
      );

      this.element.set(inboundDeliveries[0]);
      this.setSelectedItems();
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private setSelectedItems = (): void => {
    const selectedItems = this.element().items.map((i) => ({
      ...CommonUtils.convertExtendedItemToItem(i.item, this.activeOrganisationId, false),
      deliveredQuantity: i.quantity,
    }));

    this.selectedItems = selectedItems;
    this.selectedItemsIds = selectedItems.map((i) => i.id);
  };

  public override setupForm = (): void => {
    const fromValue: ISelectOption = {
      label: this.element()?.from?.name,
      value: this.element()?.from?.id,
    };

    this.fromLocationOptions.set([fromValue]);
    const toValue: ISelectOption = {
      label: this.element()?.to?.name,
      value: this.element()?.to?.id,
    };

    this.toLocationOptions.set([toValue]);
    const status = CommonUtils.capitaliseFirstLetter(this.element().status);
    const statusValue: ISelectOption = {
      label: status,
      value: status,
    };

    this.statusOptions.set([statusValue]);

    const agentsValue: ISelectOption[] = this.element()?.agents?.map((agent) => ({
      label: agent.name,
      value: agent.id,
    }));

    this.organisationsOptions.set(agentsValue);

    this.formGroup = new UntypedFormGroup({
      deliveryId: new UntypedFormControl(this.element()?.deliveryId),
      from: new UntypedFormControl(fromValue),
      to: new UntypedFormControl(toValue),
      status: new UntypedFormControl(statusValue),
      sent: new UntypedFormControl(this.element()?.sent),
      delivered: new UntypedFormControl(this.element()?.delivered),
      agents: new UntypedFormControl(agentsValue),
    });
  };

  public traderTagPressed(traderTag: InputSelectOption): void {
    this.routerService.navigate(
      this.routerService.getSharedOrganisationLink(traderTag.value as string, false, {
        organisationId: this.organisationIdQueryParam,
      }),
    );
  }

  public getSharedLocationLink = (id: string): NavigationParams => {
    return this.routerService.getSharedLocationLink(id, false, {
      organisationId: this.organisationIdQueryParam,
    }) as NavigationParams;
  };

  protected override recordName(): string {
    return this.element()?.deliveryId;
  }
}
