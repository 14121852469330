import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@components/shared/cards/card-content/card-content.component";
import { ICertificateExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-certificate-content-card",
  templateUrl: "./certificate-content-card.component.html",
  styleUrl: "./certificate-content-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CertificateContentCardComponent extends CardContentComponent {
  @Input() certificate: ICertificateExtended;

  get name(): string {
    return `${this.certificate.standard?.name} ${this.certificate.standardType?.fullName ?? ""}`.trim();
  }
}
