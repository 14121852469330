<article class="field-group-container">
  <mat-tab-group
    animationDuration="0ms"
    (selectedIndexChange)="onTabChanged($event)"
    [selectedIndex]="selectedTabIndex()"
    class="full-width"
  >
    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Supplied by</ng-container>
        @if (locationOverlayService.linkedLocationSuppliedByCounter()) {
          <div class="indicator link-indicator">
            {{ locationOverlayService.linkedLocationSuppliedByCounter() }}
          </div>
        }
      </ng-template>

      <app-table
        #suppliedByTable
        *ngIf="!!columnDefs().length"
        [isLoading]="isLoadingSuppliedBy()"
        (rowClick)="onClickRow($event)"
        [columnDefs]="columnDefs()"
        [emptyResultsTemplate]="suppliedByEmptyResults"
        [masterDetail]="true"
        [getRowClass]="getRowClass"
        [detailCellRenderer]="attachProductCellRenderer"
        [detailCellRendererParams]="detailCellRendererParams"
        [autoExpandDetailGrids]="true"
        [isRecordStateFilterEnabled]="false"
        [getRowHeight]="getRowHeight"
        [rowData]="suppliedByLocations()"
        [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
      ></app-table>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <ng-container i18n>Supplies to</ng-container>

        @if (locationOverlayService.linkedLocationSuppliedToCounter()) {
          <div class="indicator link-indicator">
            {{ locationOverlayService.linkedLocationSuppliedToCounter() }}
          </div>
        }
      </ng-template>

      <app-table
        #suppliedToTable
        *ngIf="!!columnDefs().length"
        [isLoading]="isLoadingSuppliedTo()"
        (rowClick)="onClickRow($event)"
        [columnDefs]="columnDefs()"
        [emptyResultsTemplate]="suppliedToEmptyResults"
        [masterDetail]="true"
        [getRowClass]="getRowClass"
        [detailCellRenderer]="attachProductCellRenderer"
        [detailCellRendererParams]="detailCellRendererParams"
        [autoExpandDetailGrids]="true"
        [isRecordStateFilterEnabled]="false"
        [getRowHeight]="getRowHeight"
        [rowData]="suppliedToLocations()"
        [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
      ></app-table>
    </mat-tab>
  </mat-tab-group>
</article>

<ng-template #suppliedByEmptyResults>
  <div class="no-location-provided" i18n>This location has no suppliers.</div>
</ng-template>

<ng-template #suppliedToEmptyResults>
  <div class="no-location-provided" i18n>This location does not supply any other location(s).</div>
</ng-template>

<ng-template #linkedSideMenu>
  <app-right-side-panel
    (pressAddAll)="addAllAvailableLocations()"
    (searchEvent)="onSearchAvailableLocations($event)"
    [availableItems]="availableLocations()"
    [isLoadingItems]="isLoadingAvailableLocations()"
    (pressRefresh)="onReloadAvailableLocations()"
    (pressAdd)="onAddNewLocation()"
    class="overlay-right-panel"
    itemType="Location"
    itemTypePlural="Locations"
  >
    <div class="items-container">
      @if (isLoadingAvailableLocations()) {
        <app-loader></app-loader>
      } @else {
        @for (item of availableLocations(); let index = $index; track index) {
          <app-draggable-card (pressed)="onAddLocation(item)" [draggable]="false">
            <app-location-card-content
              [maxNumDisplayableLocationTypes]="3"
              [location]="item"
              [type]="cardContentTypeEnum.ADD"
            />
          </app-draggable-card>
        }
      }
    </div>
  </app-right-side-panel>
</ng-template>

<ng-template #linkedAttachProductsSideMenu>
  <app-right-side-panel
    [title]="attachProductsPanelTitle()"
    itemType="Product"
    itemTypePlural="Products"
    [availableItems]="availableProducts()"
    [isLoadingItems]="isLoadingAvailableProducts()"
    class="overlay-right-panel"
    (pressAdd)="onAddNewProduct()"
    (pressAddAll)="onAttachAllProducts()"
    (pressRefresh)="onReloadAvailableProducts()"
    (closeMenu)="onCloseAttachProductsSideMenu()"
    (searchEvent)="onSearchAvailableProducts($event)"
  >
    <div class="items-container">
      @if (isLoadingAvailableProducts()) {
        <app-loader></app-loader>
      } @else {
        @for (item of availableProducts(); let index = $index; track item.id) {
          <app-draggable-card
            [draggable]="false"
            (pressed)="onAttachProduct(item.id)"
            [item]="item"
          >
            <app-product-card-content
              [product]="item"
              [type]="cardContentTypeEnum.ADD"
            ></app-product-card-content>
          </app-draggable-card>
        }
      }
    </div>
  </app-right-side-panel>
</ng-template>
