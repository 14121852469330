import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  standalone: false,
  selector: "app-dropdown-button",
  templateUrl: "./dropdown-button.component.html",
  styleUrls: ["./dropdown-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownButtonComponent {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  @Input({ transform: booleanAttribute }) disabledMainButton: boolean = false;

  @Input({ transform: booleanAttribute }) disabledToggle: boolean = false;

  @Input() label: string;

  @Input() labelIcon: string;

  @Output() clickLabel: EventEmitter<void> = new EventEmitter();

  open = false;

  setState(): void {
    this.open = this.trigger.menuOpen;
  }

  onClickLabel(): void {
    this.clickLabel.emit();
  }

  get icon(): string {
    return this.open ? "keyboard_arrow_up" : "keyboard_arrow_down";
  }
}
