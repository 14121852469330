import { QueriesUtils } from "@shared/queries";

import { getCountriesProperties } from "../constants";
import { CountryFieldsToInclude, CountryGraphQLFilter } from "../models";

/**
 * Generates a GraphQL query to load countries based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which countries are queried.
 * @param {CountryGraphQLFilter} filter - An object containing filtering criteria for countries.
 * @param {number} first - The number of countries to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of countries to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {CountryFieldsToInclude[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading countries.
 */
export const getCountriesGraphQLQuery = (
  orgId: string,
  filter: CountryGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: CountryFieldsToInclude[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
  {
    loadCountries(   
      orgId: "${orgId}",
      ${filterClause ? `${filterClause},` : ""}
      ${afterCursorClause ? `${afterCursorClause},` : ""}
      ${beforeCursorClause ? `${beforeCursorClause},` : ""}
      ${firstClause ? `${firstClause},` : ""}
      ${lastClause ? `${lastClause},` : ""}
    ) {
      ${getCountriesProperties(include)}  
    }
  }`;
};
