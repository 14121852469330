<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title>{{ data.dialogTitle }}</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <app-input
              formControlName="itemId"
              [hint]="translations.itemIdHint"
              [label]="translations.id"
            ></app-input>
          </div>
          <div class="item">
            <app-input-select
              [options]="allProductsOptions"
              formControlName="product"
              [label]="translations.product"
            >
              <ng-container action>
                <app-button
                  (pressed)="onAddProductClicked()"
                  class="icon-add-circle"
                  [tp]="translations.productTp"
                  type="icon"
                >
                  <mat-icon icon-small>add</mat-icon>
                </app-button>
              </ng-container>
            </app-input-select>
          </div>
          <div class="item">
            @if (isOldMaterialsEnabled) {
              <app-input-chips
                [options]="materialOptions"
                [tooltip]="formGroup.controls['product'].value ? '' : translations.materialsTooltip"
                formControlName="materials"
                [label]="translations.materials"
              >
                @if (formGroup.controls["product"].value) {
                  <ng-container action [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
                }
              </app-input-chips>
            } @else {
              <div class="tag-group-container">
                <div class="container-flex-left">
                  <app-form-label
                    [label]="translations.materials"
                    [tooltip]="
                      formGroup.controls['product'].value ? '' : translations.productsTooltip
                    "
                  ></app-form-label>
                  @if (formGroup.controls["product"]?.value) {
                    <ng-container [ngTemplateOutlet]="addMaterialBtnTemplate"></ng-container>
                  }
                </div>
                @if (formGroup.controls["materials"].value?.length) {
                  <app-tag-group [tags]="formGroup.controls['materials'].value"></app-tag-group>
                } @else {
                  -
                }
              </div>
            }
          </div>
          <div class="item">
            <app-input-select
              [options]="unitOfMeasurementOptions"
              formControlName="unitOfMeasurement"
              [label]="translations.unitOfMeasurement"
            ></app-input-select>
          </div>
          <div class="item">
            <app-input
              [hint]="
                itemDefaultUnitOfMeasurement?.id === unitOfMeasurement?.id
                  ? ''
                  : (formGroup.controls['initialQuantity'].value
                    | unitConversion: itemDefaultUnitOfMeasurement : unitOfMeasurement)
              "
              [suffix]="itemDefaultUnitOfMeasurement?.symbol"
              formControlName="initialQuantity"
              [viewModeTemplate]="initialQuantityViewTemplate"
              [label]="translations.initialQty"
              type="number"
            ></app-input>
          </div>
          <div class="item">
            <div class="item">
              <app-input-select
                [options]="createdAtLocationOptions"
                formControlName="createdAtLocation"
                [label]="translations.createdAtLocation"
                [tooltip]="translations.createdAtLocationTooltip"
              ></app-input-select>
            </div>
          </div>

          <div class="item">
            @if (datesType === dateTypeEnum.EXACT) {
              <app-datepicker
                #datePicker
                class="no-padding"
                [label]="translations.creationDate"
                formControlName="createdFrom"
              ></app-datepicker>
            } @else if (datesType === dateTypeEnum.RANGE) {
              <app-daterangepicker
                #datePicker
                class="no-padding"
                [label]="translations.creationDate"
                formControlName="createdRange"
              ></app-daterangepicker>
            }

            <div class="checkbox-row">
              <app-checkbox formControlName="datesType" [label]="translations.range"></app-checkbox>
            </div>
          </div>

          <div class="item">
            <app-input-select
              [options]="currentLocationOptions"
              class="no-padding"
              formControlName="currentLocation"
              [label]="translations.currentLocation"
              [tooltip]="translations.currentLocationTooltip"
            ></app-input-select>
          </div>

          <div class="checkbox-row">
            <app-checkbox
              formControlName="isSameAsCreatedAtLocation"
              [label]="translations.sameAsLocation"
            ></app-checkbox>
          </div>
          @if (!data?.shouldExcludeDeliveryQty) {
            <div class="item">
              <app-input
                [suffix]="itemDefaultUnitOfMeasurement?.symbol"
                [viewModeTemplate]="deliveryQuantityViewTemplate"
                class="no-padding"
                formControlName="deliveredQty"
                [label]="translations.deliveredQty"
                type="number"
              ></app-input>
            </div>

            <div class="checkbox-row">
              <app-checkbox
                formControlName="isDeliveredQtySameAsInitial"
                [label]="translations.sameAsQty"
              ></app-checkbox>
            </div>
          }
        </div>
      </div>
      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose(false)" type="ghost">Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
<ng-template #addMaterialBtnTemplate>
  <app-button
    (pressed)="onAddMaterialClicked()"
    class="icon-add-circle"
    [tp]="translations.materialTp"
    type="icon"
  >
    <mat-icon icon-small>add</mat-icon>
  </app-button>
</ng-template>
<ng-template #initialQuantityViewTemplate let-data>
  @if (unitOfMeasurement) {
    @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
      <app-form-input-readonly
        [label]="data.label"
        [value]="initialQuantityFormatted"
      ></app-form-input-readonly>
    } @else {
      <app-form-input-readonly
        [label]="data.label"
        [value]="
          (formGroup.controls['initialQuantity'].value
            | number: '1.0-' + (unitOfMeasurement?.precision ?? '0')) +
          ' ' +
          unitOfMeasurement?.symbol
        "
      ></app-form-input-readonly>
    }
  } @else {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>

<ng-template #deliveryQuantityViewTemplate let-data>
  @if (unitOfMeasurement) {
    @if (hasDifferentDefaultUnit && defaultUnitOfMeasurement) {
      <app-form-input-readonly
        [label]="data.label"
        [value]="deliveredQuantityFormatted"
      ></app-form-input-readonly>
    } @else {
      <app-form-input-readonly
        [label]="data.label"
        [value]="
          (formGroup.controls['deliveryQty'].value
            | number: '1.0-' + (unitOfMeasurement?.precision ?? '0')) +
          ' ' +
          unitOfMeasurement?.symbol
        "
      ></app-form-input-readonly>
    }
  } @else {
    <app-not-shared-data></app-not-shared-data>
  }
</ng-template>
