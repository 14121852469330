import { Directive, InputSignal, computed, inject, signal, viewChild } from "@angular/core";

import { ColDef } from "ag-grid-community";

import { TableEnum } from "@shared/enums";
import { AuthenticationService, NotificationService } from "@shared/services";

import { TableComponent } from "../table.component";

@Directive()
export abstract class TableBaseClass<T> {
  protected readonly authService = inject(AuthenticationService);

  protected readonly notificationService = inject(NotificationService);

  protected readonly tableElement = viewChild(TableComponent);

  protected readonly rowData = signal<T[]>([]);

  protected readonly columnDefs = signal<ColDef[]>([]);

  protected readonly isLoading = signal<boolean>(true);

  protected readonly isSystemAdminOrganisation = this.authService.isSystemAdminOrganisation();

  protected readonly isRegularUser = this.authService.isRegularUser();

  readonly totalElements = computed(() => (this.isLoading() ? undefined : this.rowData().length));

  protected abstract readonly table: TableEnum | InputSignal<TableEnum>;

  protected abstract getAll(): Promise<void>;

  protected abstract setColumnDefs(): void;
}
