import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-edit-document-main-details",
  templateUrl: "./edit-document-main-details.component.html",
  styleUrls: ["./edit-document-main-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditDocumentMainDetailsComponent {
  @Input() formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  @Input() fileExtension: string;

  @Input() documentTypesOptions: ISelectOption[];

  @Input() hasContent: boolean;

  @Output() clickOnName: EventEmitter<void> = new EventEmitter();

  readonly copyModeEnum = CopyModeEnum;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    typeLabel: TextConstants.TYPE,
    issuanceLabel: TextConstants.ISSUANCE_DATE,
    validityStartLabel: TextConstants.VALID_FROM,
    validityEndLabel: TextConstants.VALID_TO,
  };

  constructor(protected overlay: SlideOverlayPageService) {}

  onClickName() {
    this.clickOnName.emit();
  }
}
