import { FormArray, FormControl, FormGroup } from "@angular/forms";

import { ISelectOption, ITagExtended } from "@shared/interfaces";

export namespace PdfSplitterModel {
  export interface IDocumentFormField {
    [DocumentFieldEnum.NAME]: FormControl<string>;
    [DocumentFieldEnum.TYPE]: FormControl<ISelectOption[]>;
    [DocumentFieldEnum.TAGS]: FormControl<ITagExtended[]>;
    [DocumentFieldEnum.PAGES]: FormArray<FormControl<IPage>>;
    [DocumentFieldEnum.FILE]: FormControl<File>;
    [DocumentFieldEnum.IS_INCLUDED]: FormControl<boolean>;
    [DocumentFieldEnum.IS_DATES_ENABLED]: FormControl<boolean>;
    [DocumentFieldEnum.VALIDITY_START]?: FormControl<string>;
    [DocumentFieldEnum.VALIDITY_END]?: FormControl<string>;
    [DocumentFieldEnum.ISSUANCE]?: FormControl<string>;
  }

  export interface IFormField {
    [FieldEnum.INDEX]: FormControl<number>;
    [FieldEnum.DOCUMENTS]: FormArray<FormGroup<IDocumentFormField>>;
  }

  export interface IDocument {
    name: string;
    type: ISelectOption[];
    tags: ITagExtended[];
    pages: IPage[];
    file: File;
    isIncluded: boolean;
    isDatesEnabled: boolean;
    validityStart?: string;
    validityEnd?: string;
    issuance?: string;
  }

  export interface IPage {
    number: number;
    isIncluded: boolean;
    rotation: Rotation;
  }

  export type Rotation = 0 | 90 | 180 | 270;

  export enum RotationDirectionEnum {
    LEFT = "left",
    RIGHT = "right",
  }

  export interface ISplitData {
    documents: IDocument[];
    index: number;
  }

  export enum FieldEnum {
    INDEX = "index",
    DOCUMENTS = "documents",
  }

  export enum DocumentFieldEnum {
    NAME = "name",
    TYPE = "type",
    TAGS = "tags",
    PAGES = "pages",
    FILE = "file",
    IS_INCLUDED = "isIncluded",
    IS_DATES_ENABLED = "isDatesEnabled",
    VALIDITY_START = "validityStart",
    VALIDITY_END = "validityEnd",
    ISSUANCE = "issuance",
  }
}
