import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { ISelectOption } from "src/app/shared/interfaces";
import { LocationTypesService } from "src/app/shared/services";

import { InputSelectComponent } from "@components/shared/inputs/input-select/input-select.component";
import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-location-criteria-info",
  templateUrl: "./location-criteria-info.component.html",
  styleUrl: "./location-criteria-info.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationCriteriaInfoComponent {
  @ViewChild("locationSelect") locationSelect: InputSelectComponent;

  @ViewChild("countrySelect") countrySelect: InputSelectComponent;

  @Input() formGroup: UntypedFormGroup;

  @Input() formControlPrefix: string = "";

  @Input() allCountriesOption: ISelectOption[] = [];

  @Output() newLocationTypeCreated: EventEmitter<void> = new EventEmitter<void>();

  public readonly translations: any = {
    typesLabel: $localize`Type(s)`,
    countriesLabel: $localize`Country(ies)`,
    typesTooltip: TextConstants.LOCATION_TYPES_TOOLTIP,
    countriesTooltip: $localize`Select the country(ies) from the list`,
  };

  constructor(public locationTypesService: LocationTypesService) {}

  public get locationFormControlName() {
    return `${this.formControlPrefix}${this.formControlPrefix ? "LocationTypes" : "locationTypes"}`;
  }

  public get countryFormControlName() {
    return `${this.formControlPrefix}${this.formControlPrefix ? "CountryCodes" : "countryCodes"}`;
  }
}
