import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditUserDialogComponent } from "src/app/components/settings";

import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  MemberStatusEnum,
  TableEnum,
  UserRoleEnum,
} from "@shared/enums";
import { IOrganisationMember } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, AuthenticationService, OrganisationsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  standalone: false,
  selector: "app-users-table",
  templateUrl: "./users-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent implements AfterViewInit {
  @Input()
  public users: IOrganisationMember[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.USERS_EXISTING;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  public rowData = signal<IOrganisationMember[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private notificationService: NotificationService,
    private organisationsService: OrganisationsService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "emailAddress",
      actions: new Map([
        [
          BatchActionTypeEnum.ACTIVATE_USER,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.ACTIVATE_USER,
            this.organisationsService,
          ),
        ],
        [
          BatchActionTypeEnum.DEACTIVATE_USER,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DEACTIVATE_USER,
            this.organisationsService,
          ),
        ],
        [
          BatchActionTypeEnum.ARCHIVE_USER,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.ARCHIVE_USER,
            this.organisationsService,
          ),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: $localize`First name`,
        field: "firstName",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onToggleActivate,
                  tooltip: (row) =>
                    row.status === MemberStatusEnum.ACTIVE
                      ? TextConstants.DEACTIVATE
                      : TextConstants.ACTIVATE,
                  icon: (row) =>
                    row.status === MemberStatusEnum.ACTIVE ? "block" : "check_circle",
                  show: (row) => row.canLoggedInUserEdit,
                },
                {
                  click: this.onArchive,
                  tooltip: TextConstants.ARCHIVE,
                  icon: "archive",
                  show: (row) => row.canLoggedInUserEdit,
                },
                {
                  click: this.onEdit,
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                  show: (row) => row.canLoggedInUserEdit,
                },
              ]
            : [],
        },
      },
      { headerName: $localize`Last name`, field: "lastName", lockVisible: true },
      { headerName: TextConstants.EMAIL, field: "emailAddress" },
      { headerName: $localize`Roles`, field: "roles", cellRenderer: CellRendererUtils.roleNames },
      {
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }
    this.columnDefs.set(columnDefs);
  };

  public onToggleActivate = (user: IOrganisationMember): void => {
    if (user.status === MemberStatusEnum.ACTIVE) {
      this.onDeactivate(user);
    } else {
      this.onActivate(user);
    }
  };

  private onActivate = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Activate confirmation`,
        contentText: $localize`Are you sure that you want to activate this user?`,
        confirmButtonText: TextConstants.ACTIVATE,
        confirmButtonIcon: "check_circle",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberRecordState(user.id, MemberStatusEnum.ACTIVE)
          .then(async () => {
            this.notificationService.showSuccess($localize`User activated`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  private onDeactivate = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Deactivate confirmation`,
        contentText: $localize`Are you sure that you want to deactivate this user?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DEACTIVATE,
        confirmButtonIcon: "block",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberRecordState(user.id, MemberStatusEnum.DEACTIVATED)
          .then(async () => {
            this.notificationService.showSuccess($localize`User deactivated`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onArchive = (user: IOrganisationMember): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Archive confirmation`,
        contentText: $localize`Are you sure that you want to archive this user?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.ARCHIVE,
        confirmButtonIcon: "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.organisationsService
          .setMemberRecordState(user.id, MemberStatusEnum.ARCHIVED)
          .then(async () => {
            this.notificationService.showSuccess(TextConstants.ARCHIVED);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onEdit = (user: IOrganisationMember): void => {
    if (!this.areButtonsEnabled || !user.canLoggedInUserEdit) {
      return;
    }
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      autoFocus: false,
      data: {
        user,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  private isLoggedInUser = (user: IOrganisationMember): boolean =>
    CommonUtils.getUriId(user.uri) === this.authenticationService.getUserId();

  private setCanEdit = (users: IOrganisationMember[]): void => {
    const isLoggedInUserAccountOwner: boolean = this.authenticationService
      .getActiveOrganisation()
      .roles.some((role: UserRoleEnum) => [UserRoleEnum.ACCOUNT_OWNER].includes(role));
    const isLoggedInUserAdmin: boolean = this.authenticationService
      .getActiveOrganisation()
      .roles.some((role: UserRoleEnum) => [UserRoleEnum.ADMIN].includes(role));

    for (const user of users) {
      user.canLoggedInUserEdit =
        !this.isLoggedInUser(user) &&
        (user.canLoggedInUserEdit =
          isLoggedInUserAccountOwner ||
          !user.roles[0] ||
          (isLoggedInUserAdmin &&
            user.roles[0] !== UserRoleEnum.ACCOUNT_OWNER &&
            user.roles[0] !== UserRoleEnum.ADMIN));
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.users) {
      const rowData = this.users;

      this.setCanEdit(rowData);
      this.rowData.set(rowData);
      this.isLoading.set(false);
    } else {
      try {
        const rowData = await this.organisationsService.getAllOrganisationMembers(
          `${MemberStatusEnum.ACTIVE},${MemberStatusEnum.DEACTIVATED}`,
        );

        this.setCanEdit(rowData);
        this.rowData.set(rowData);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
