import { Injectable, computed, inject, signal } from "@angular/core";

import { CommonConstants } from "@shared/constants";
import { RecordStateEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { NotificationService, AuthenticationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

import { RiskLevelSetsApiService } from "../api";
import { RiskLevelSet } from "../models";

interface RiskLevelOption extends ISelectOption {
  value: string;
}

@Injectable({
  providedIn: "root",
})
export class RiskLevelSetsStateService {
  private readonly api = inject(RiskLevelSetsApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly notification = inject(NotificationService);

  readonly sets = signal<RiskLevelSet[]>([]);

  readonly selectedSet = signal<RiskLevelSet>(undefined);

  readonly selectedLevels = computed(() => this.selectedSet()?.riskLevels);

  readonly options = computed<RiskLevelOption[]>(() =>
    this.sets()?.map((item) => ({
      label: item.name,
      value: `/organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/risk-level-sets/${item.id}`,
    })),
  );

  async init() {
    this.selectedSet.set(undefined);
    await this.fetchData();
  }

  async fetchData() {
    try {
      const { content } = await this.api.getAll({
        recordState: RecordStateEnum.ACTIVE,
        page: 0,
        size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
      });

      this.sets.set(content);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  setSelectedSet(setUri: string) {
    if (!setUri) {
      this.selectedSet.set(undefined);

      return;
    }

    const setId = CommonUtils.getUriId(setUri);

    const set = this.sets().find((item) => item.id === setId);

    if (set) {
      this.selectedSet.set(set);
    }
  }
}
