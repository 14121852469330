<div class="container-space-between">
  <app-page-title
    title="EUDR"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>
  @if (!isLoading() && isAccountOwnerOrAdmin) {
    <div class="buttons-container">
      @if (eudrCredentials()) {
        <ng-container [ngTemplateOutlet]="missingCustomFieldsButtonTemplate"></ng-container>
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu"
          [tp]="translations.optionsTp"
          [tpIsLazy]="true"
        >
          <mat-icon>settings</mat-icon>
        </button>
        <mat-menu #menu class="dropdown-menu">
          <app-dropdown-menu>
            <app-dropdown-menu-item (pressed)="onTestConnection()">
              <mat-icon>conversion_path</mat-icon>
              <ng-container i18n>Test connection</ng-container>
            </app-dropdown-menu-item>
            <app-dropdown-menu-item (pressed)="onOpenConnectDialog()">
              <mat-icon>edit</mat-icon>
              <ng-container i18n>Edit credentials</ng-container>
            </app-dropdown-menu-item>
            <app-dropdown-menu-item (pressed)="onRemoveConnection()">
              <mat-icon>delete</mat-icon>
              <ng-container i18n>Remove connection</ng-container>
            </app-dropdown-menu-item>
          </app-dropdown-menu>
        </mat-menu>
      } @else {
        <app-button-group>
          <ng-container [ngTemplateOutlet]="missingCustomFieldsButtonTemplate"></ng-container>
          <app-button (pressed)="onOpenConnectDialog()">
            <mat-icon icon-left>conversion_path</mat-icon>
            <ng-container i18n>Connect</ng-container>
          </app-button>
        </app-button-group>
      }
    </div>
    <ng-template #missingCustomFieldsButtonTemplate>
      @if (missingCustomFieldGroups.length) {
        <button
          mat-icon-button
          type="button"
          [tp]="translations.missingCustomFieldsTp"
          [tpIsLazy]="true"
          (click)="onOpenMissingCustomFieldsDialog()"
        >
          <mat-icon class="warn-icon">warning</mat-icon>
        </button>
      }
    </ng-template>
  }
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else if (isDdsServiceUnavailable) {
  <app-empty-search-results
    [message]="serviceUnavailableMsg"
    imageSrc="/assets/images/service-unavailable.svg"
  ></app-empty-search-results>
} @else {
  <app-eudr-table [isSaveTableState]="true" [isFixedBottomPaginator]="true"></app-eudr-table>
}
