import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { SlideOverCellRendererComponent } from "src/app/shared/cell-renderers";
import { ConfirmDialogResponseEnum, ExtensionAdminTypeEnum, TableEnum } from "src/app/shared/enums";
import { IExtension } from "src/app/shared/interfaces";
import { ExtensionsService } from "src/app/shared/services";

import { ConfirmDialogComponent } from "@components/shared";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { CellRendererUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-admin-organisation-extensions-table",
  templateUrl: "./admin-organisation-extensions-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminOrganisationExtensionTableComponent implements AfterViewInit {
  @Input()
  public organisationId: string;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.ADMIN_ORGANISATION_EXTENSIONS;

  @Input()
  public isSaveTableState = false;

  public isLoading = signal(true);

  public readonly enabledExtensionsCount = computed(() =>
    this.isLoading() ? undefined : this.rowData().filter((r) => r.enable).length,
  );

  public rowData = signal<IExtension[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private notificationService: NotificationService,
    private extensionsService: ExtensionsService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    this.columnDefs.set([
      {
        headerName: TextConstants.NAME,
        field: "providerId",
        lockVisible: true,
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
      {
        headerName: TextConstants.TYPE,
        field: "adminType",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
      {
        headerName: $localize`Active`,
        field: "enable",
        resizable: false,
        suppressSizeToFit: true,
        suppressAutoSize: true,
        lockVisible: true,
        cellClass: "slide-over-cell-renderer-container",
        cellRenderer: SlideOverCellRendererComponent,
        cellRendererParams: {
          statusChange: this.onTryStatusChange,
          isDisabled: (row) => row.adminType === ExtensionAdminTypeEnum.USER,
          tooltip: (row) => {
            if (row.adminType === ExtensionAdminTypeEnum.SYSTEM) {
              return row.enable ? TextConstants.DEACTIVATE : TextConstants.ACTIVATE;
            } else {
              return $localize`Can't activate/deactivate user type extensions`;
            }
          },
        },
      },
    ]);
  };

  public onTryStatusChange = async (element: IExtension) => {
    const isActivating = !element.enable;

    const title = isActivating ? $localize`Activate extension` : $localize`Deactivate extension`;

    const contentText = isActivating
      ? $localize`Are you sure that you want to activate this extension?`
      : $localize`Are you sure that you want to deactivate this extension?`;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title,
        contentText,
        confirmButtonColor: `${isActivating ? undefined : "danger"}`,
        confirmButtonText: isActivating ? TextConstants.ACTIVATE : TextConstants.DEACTIVATE,
        confirmButtonIcon: `${isActivating ? "check" : "close"}`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.statusChange(element);
      } else {
        await this.getAll(); // TODO find a better way to restore the 'enable' value on the UI
      }
    });
  };

  private statusChange = async (element: IExtension) => {
    element.enable = !element.enable;
    const payload: IExtension = {
      enable: element.enable,
      providerId: element.providerId,
    };

    try {
      await this.extensionsService.enableExtension(this.organisationId, payload);
      this.notificationService.showSuccess(
        element.enable ? $localize`Extension activated` : $localize`Extension deactivated`,
      );
      this.rowData.set([...this.rowData()]);
    } catch (error) {
      this.notificationService.showError(error);
      await this.getAll(); // TODO find a better way to restore the 'enable' value on the UI
    }
  };

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    try {
      const availableExtensions = await this.extensionsService.getAllAvailableForSystemAdmin();
      const enabled = await this.extensionsService.getAllEnabled(this.organisationId);

      this.rowData.set(
        availableExtensions.map((availableExtension) => ({
          providerId: availableExtension.name,
          adminType: availableExtension.adminType,
          enable: enabled.some((e) => e === availableExtension.name),
        })),
      );
      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
