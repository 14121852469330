import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";

import { ApiKeysTableComponent } from ".";
import { EditApiKeyDialogComponent } from "./edit-api-key-dialog/edit-api-key-dialog.component";

@Component({
  standalone: false,
  templateUrl: "./user-settings-api-keys.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSettingsApiKeysComponent {
  @ViewChild("apiKeysTable") apiKeysTable: ApiKeysTableComponent;

  public readonly routingEnum = RouteEnum;

  public readonly translations = {
    title: $localize`API keys`,
    titleBackText: TextConstants.BACK_TO_USER_SETTINGS,
  };

  constructor(private dialog: MatDialog) {}

  public onAdd = (): void => {
    const dialogRef = this.dialog.open(EditApiKeyDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.apiKeysTable.getAll();
      }
    });
  };
}
