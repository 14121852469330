import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { EditableDivComponent } from "@components/shared/editable-div/editable-div.component";
import { CommonConstants } from "@shared/constants";
import { IComment } from "@shared/interfaces/comment.interface";
import { AuthenticationService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-comment-card-content",
  templateUrl: "./comment-card-content.component.html",
  styleUrl: "./comment-card-content.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentCardContentComponent implements OnInit, AfterViewInit {
  @Input()
  public comment: IComment;

  @Output()
  public editComment: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public deleteComment: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild("textElement") textElement: ElementRef<HTMLParagraphElement>;

  @ViewChild("editableDiv") editableDiv: EditableDivComponent;

  public isLoading = signal(false);

  public isEditing = signal(false);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    text: new UntypedFormControl("", [
      CustomValidators.maxLength(CommonConstants.COMMENTS_MAX_LENGTH),
    ]),
  });

  public isAccountOwnerOrAdmin: boolean;

  public currentUserEmail: string;

  public commentText: string;

  public isCollapsed: boolean = true;

  public isShowReadMoreLess: boolean = false;

  private cd = inject(ChangeDetectorRef);

  constructor(private authenticationService: AuthenticationService) {
    this.isAccountOwnerOrAdmin = this.authenticationService.isAccountOwnerOrAdmin();
    this.currentUserEmail = this.authenticationService.getUserEmail();
  }

  ngOnInit(): void {
    this.commentText = this.comment.text.replace(/\n/g, "<br>");
  }

  ngAfterViewInit(): void {
    this.setShowReadMoreLess();
  }

  private setShowReadMoreLess = (newCollapsedValue: boolean = undefined): void => {
    const element = this.textElement?.nativeElement;

    this.isShowReadMoreLess = element?.scrollHeight > element?.clientHeight;

    if (newCollapsedValue !== undefined) {
      this.isCollapsed = newCollapsedValue;
    }

    this.cd.detectChanges();
  };

  public toggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public onEdit(): void {
    this.isEditing.set(true);
    setTimeout(() => {
      this.formGroup.controls["text"].setValue(this.comment.text);
      this.editableDiv.setfocus();
    });
  }

  public onDelete(): void {
    this.deleteComment.emit();
  }

  public onSave(): void {
    this.editComment.emit(this.formGroup.controls["text"].value);
  }

  public onCancel(): void {
    this.isEditing.set(false);
  }

  public isSubmitButtonDisabled(): boolean {
    return (
      this.formGroup.invalid ||
      this.formGroup.pending ||
      !this.formGroup.dirty ||
      !this.formGroup.controls["text"].value
    );
  }
}
