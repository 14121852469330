import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { DateRangePickerComponent } from "@components/shared/datepicker";
import { DatepickerComponent } from "@components/shared/datepicker/datepicker.component";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { TextConstants } from "@shared/constants";
import { DateTypeEnum } from "@shared/enums";
import {
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IItem,
  IItemExtended,
  ISelectOption,
} from "@shared/interfaces";
import { NavigationParams } from "@shared/services/router.service";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-item-overlay-main-information",
  templateUrl: "./item-overlay-main-information.component.html",
  styleUrl: "./item-overlay-main-information.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ItemOverlayMainInformationComponent
  extends SlideOverlayPageClass
  implements AfterViewInit
{
  @ViewChild("datePicker") datePickerComponent: DatepickerComponent | DateRangePickerComponent;

  override element = input<IItem | IItemExtended>(null);

  public isOldMaterialsEnabled = input<boolean>(false);

  public isSharedUser = input<boolean>(false);

  public materialOptions = input<ISelectOption[]>([]);

  public productOptions = input<ISelectOption[]>([]);

  public unitOfMeasurementOptions = input<ISelectOption[]>([]);

  public currentLocationOptions = input<ISelectOption[]>([]);

  public createdAtLocationOptions = input<ISelectOption[]>([]);

  public unitOfMeasurement = input<IBaseUnit>(null);

  public hasDifferentDefaultUnit = input<boolean>(false);

  public remainingQuantityFormatted = input<string>(null);

  public initialQuantityFormatted = input<string>(null);

  public defaultUnitOfMeasurement = input<ICustomUnitOfMeasurement>(null);

  public duplicateItemId = input<string>(null);

  public readonly dateTypeEnum = DateTypeEnum;

  public itemDefaultUnitOfMeasurement = input<IBaseUnit | ICustomUnitOfMeasurement>(null);

  public isInboundShared = input<boolean>(false);

  public inboundSharedSenderOrgId = input<string>(null);

  public readonly translations: any = {
    productLabel: TextConstants.PRODUCT,
    materialsLabel: TextConstants.MATERIALS,
    unitOfMeasurementLabel: $localize`Unit of measurement`,
    initialQuantityLabel: $localize`Initial quantity`,
    remainingQuantityLabel: $localize`Remaining quantity`,
    createdAtLocationLabel: $localize`Created at location`,
    createdFromLabel: TextConstants.CREATION_DATE,
    isRangeCreationDateLabel: TextConstants.RANGE_FROM_TO,
    currentLocationLabel: $localize`Current / last location`,
    isSameAsCreatedAtLocationLabel: $localize`Same as created at location`,
    currentLocationTooltip: $localize`Provide information on what is the item's current location
                (if the item still physically exists) or what was its last location if it has
                been already transformed into other items / destroyed.`,
    createdAtLocationTooltip: $localize`Provide information about where the item was first created /
                got a new identifier.`,
    itemIdHint: $localize`E.g. stock / batch number, etc.`,
    addNewProductTp: $localize`Add new product`,
    addNewMaterialTp: $localize`Add new material`,
    materialOptionsTooltip: $localize`Please, first select a product to see which materials are allowed.`,
    materialsTooltip: $localize`Please select a product to see which materials it comprises of.`,
  };

  @Output()
  public addProduct: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public dateTypeChanged: EventEmitter<DateTypeEnum> = new EventEmitter<DateTypeEnum>();

  @Output()
  public addMaterial: EventEmitter<void> = new EventEmitter<void>();

  public datesType = input<DateTypeEnum>(DateTypeEnum.EXACT);

  @Input()
  formGroup: FormGroup;

  public getProductLink(id: string): NavigationParams {
    if (this.isInboundShared()) {
      return <NavigationParams>this.routerService.getSharedProductLink(id, false, {
        organisationId: this.inboundSharedSenderOrgId(),
      });
    } else {
      return <NavigationParams>this.routerService.getProductLink(id, false);
    }
  }

  public getLocationLink(id: string): NavigationParams {
    if (this.isInboundShared()) {
      return <NavigationParams>this.routerService.getSharedLocationLink(id, false, {
        organisationId: this.inboundSharedSenderOrgId(),
      });
    } else {
      return <NavigationParams>this.routerService.getLocationLink(id, false);
    }
  }

  onAddProductClicked() {
    this.addProduct.emit();
  }

  public openMaterialOverlay = async (tag: InputSelectOption): Promise<void> => {
    const manterialLink = this.isInboundShared()
      ? this.routerService.getSharedMaterialLink(tag.value.toString(), false, {
          organisationId: this.inboundSharedSenderOrgId(),
        })
      : this.routerService.getMaterialLink(tag.value.toString());

    await this.routerService.navigate(manterialLink);
  };

  onAddMaterialClicked() {
    this.addMaterial.emit();
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.formGroup.controls["isRangeCreationDate"].valueChanges.subscribe((checked: boolean) => {
        this.onChangeDatesType(checked);
      }),
    );
  }

  public onChangeDatesType(checked: boolean): void {
    const datesType = checked ? this.dateTypeEnum.RANGE : DateTypeEnum.EXACT;

    const { formGroup } = this;
    const { controls } = formGroup;

    if (datesType === DateTypeEnum.RANGE) {
      controls["createdFrom"].clearValidators();
      controls["createdRange"].patchValue([controls["createdFrom"].value, null]);
      controls["createdRange"].addValidators([
        CustomValidators.required,
        CustomValidators.dateRange,
      ]);
    }

    if (datesType === DateTypeEnum.EXACT) {
      controls["createdRange"].clearValidators();
      controls["createdFrom"].patchValue(controls["createdRange"].value[0]);
      controls["createdFrom"].addValidators([CustomValidators.required, CustomValidators.date]);
    }

    controls["createdRange"].updateValueAndValidity();
    controls["createdFrom"].updateValueAndValidity();

    formGroup.updateValueAndValidity();
    this.dateTypeChanged.emit(datesType);
    const createdFrom = controls["createdFrom"].value;

    if (datesType === DateTypeEnum.RANGE || (datesType === DateTypeEnum.EXACT && !createdFrom)) {
      setTimeout(() => this.datePickerComponent.picker.open());
    }
  }
}
