<form [formGroup]="form" (ngSubmit)="submit()">
  <h2 mat-dialog-title i18n>Add country</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog" />
  } @else {
    <mat-dialog-content>
      <app-input-select
        formControlName="name"
        [label]="translations.countryLabel"
        [options]="countryOptions()"
        [prefixTemplate]="flag"
      />

      <ng-template #flag let-option>
        <app-flag [country]="option.value" />
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="close()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
