import { Injectable, inject, signal } from "@angular/core";

import { RiskAssessmentReportIndicatorMitigationsApiService } from "../api";
import { RiskAssessmentReportIndicator, RiskAssessmentReportIndicatorMitigation } from "../models";

@Injectable({ providedIn: "root" })
export class RiskAssessmentReportIndicatorMitigationsMapService {
  private readonly reportIndicatorMitigationsApi = inject(
    RiskAssessmentReportIndicatorMitigationsApiService,
  );

  readonly map = signal<Map<string, RiskAssessmentReportIndicatorMitigation[]>>(undefined);

  async setMap(reportId: string, indicators: RiskAssessmentReportIndicator[]) {
    const map = new Map<string, RiskAssessmentReportIndicatorMitigation[]>();

    const promises = indicators.flatMap(({ indicators }) =>
      indicators.map(async ({ id }) => {
        const mitigations = await this.reportIndicatorMitigationsApi.getAll(reportId, id);

        map.set(id, mitigations);
      }),
    );

    await Promise.all(promises);

    this.map.set(map);
  }

  updateMap(
    indicatorId: string,
    riskMitigationId: string,
    riskMitigation?: RiskAssessmentReportIndicatorMitigation,
  ) {
    if (this.map().has(indicatorId)) {
      const mitigations = this.map().get(indicatorId);

      const index = mitigations.findIndex((mitigation) => mitigation.id === riskMitigationId);

      if (index !== -1) {
        if (riskMitigation) {
          mitigations[index] = riskMitigation;
        } else {
          mitigations.splice(index, 1);
        }
      } else {
        mitigations.push(riskMitigation);
      }

      this.map().set(indicatorId, mitigations);
    }
  }
}
