import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { DeliverySupplyChainDocumentsModel as Model } from "@components/deliveries/pages/delivery-overlay/delivery-supply-chain-documents/delivery-supply-chain-documents.model";
import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import {
  ICertificateExtended,
  IDeliveryExtended,
  IDocument,
  IItemSupplyChain,
} from "@shared/interfaces";
import {
  CertificatesService,
  ConnectionsService,
  DeliveriesService,
  ItemsService,
  LocationsService,
  ProcessesService,
  ProductsService,
} from "@shared/services";

@Component({
  standalone: false,
  selector: "app-delivery-supply-chain-documents",
  templateUrl: "./delivery-supply-chain-documents.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverySupplyChainDocumentsComponent implements OnInit {
  public deliveryId = input.required<string>();

  public itemIds = input.required<string[]>();

  public supplyChainItems: IItemSupplyChain[] = [];

  private itemSupplyChainMapperService: ItemSupplyChainMapperService = inject(
    ItemSupplyChainMapperService,
  );

  private itemsService: ItemsService = inject(ItemsService);

  private locationsService: LocationsService = inject(LocationsService);

  private connectionsService: ConnectionsService = inject(ConnectionsService);

  private certificatesService: CertificatesService = inject(CertificatesService);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  private productsService: ProductsService = inject(ProductsService);

  private processesService: ProcessesService = inject(ProcessesService);

  public isLoading = signal<boolean>(true);

  public documents: Model.IDocumentRowData[] = [];

  public rowData: Model.IDocumentRowData[] = [];

  public readonly columns: string[] = [
    "recordState",
    "type.name",
    "name",
    "issuance",
    "validityStart",
    "validityEnd",
    "tags",
    "targetName",
    "targetType",
  ];

  public async ngOnInit(): Promise<void> {
    this.supplyChainItems = await this.itemSupplyChainMapperService.mapItemSupplyChain(
      this.itemIds(),
    );

    await this.setDocuments();

    this.isLoading.set(false);
  }

  private async setDocuments(): Promise<void> {
    const ids = this.getIdsFromSupplyChain();
    const records = await this.fetchAllRecords(ids);
    const agentOrganisations = await this.getAgentOrganisations(records.deliveries);

    const certificates = await this.getCertificates([
      ...records.items,
      ...records.organisations,
      ...records.locations,
      ...records.deliveries,
      ...records.processes,
      ...agentOrganisations,
    ]);

    const documents = [
      ...this.getDocumentsForEntities(records.organisations, "Organisations", (org) => org.name),
      ...this.getDocumentsForEntities(records.locations, "Locations", (loc) => loc.name),
      ...this.getDocumentsForEntities(
        certificates,
        "Certificates",
        (cert) => `${cert.standard.name} - ${cert.number}`,
      ),
      ...this.getDocumentsForEntities(records.deliveries, "Deliveries", (del) => del.deliveryId),
      ...this.getDocumentsForEntities(records.processes, "Processes", (proc) => proc.processId),
      ...this.getDocumentsForEntities(records.items, "Items", (item) => item.itemId),
      ...this.getDocumentsForEntities(records.products, "Products", (prod) => prod.name),
      ...this.getDocumentsForEntities(agentOrganisations, "Organisations", (org) => org.name),
    ];

    this.documents = documents.filter(
      (doc, index, self) =>
        index ===
        self.findIndex(
          (d) =>
            d.id === doc.id && d.targetName === doc.targetName && d.targetType === doc.targetType,
        ),
    );
  }

  private getIdsFromSupplyChain() {
    const ids = {
      items: new Set<string>(),
      organisations: new Set<string>(),
      locations: new Set<string>(),
      deliveries: new Set<string>(),
      products: new Set<string>(),
      processes: new Set<string>(),
    };

    this.supplyChainItems.forEach((itemSupplyChain) => {
      const item = itemSupplyChain.item;

      ids.items.add(item.id);
      ids.products.add(item.product.id);

      itemSupplyChain.deliveries?.forEach((delivery) => ids.deliveries.add(delivery.id));
      itemSupplyChain.processes?.forEach((process) => ids.processes.add(process.id));

      itemSupplyChain.locations.forEach((location) => {
        ids.organisations.add(location.organisationId);
        ids.locations.add(location.id);
      });
    });

    return {
      items: Array.from(ids.items),
      organisations: Array.from(ids.organisations),
      locations: Array.from(ids.locations),
      deliveries: Array.from(ids.deliveries),
      products: Array.from(ids.products),
      processes: Array.from(ids.processes),
    };
  }

  private async fetchAllRecords(ids: Record<string, string[]>) {
    const [items, locations, deliveries, organisations, products, processes] = await Promise.all([
      this.itemsService.getByIdsGraphQL(ids["items"], undefined, [
        "DOCUMENTS_WITH_TAGS",
        "CERTIFICATES",
      ]),
      this.locationsService.getByIdsGraphQL(ids["locations"], undefined, [
        "DOCUMENTS_WITH_TAGS",
        "CERTIFICATES",
      ]),
      this.deliveriesService.getByIdsGraphQL(ids["deliveries"], undefined, [
        "AGENTS",
        "DOCUMENTS_WITH_TAGS",
        "CERTIFICATES",
      ]),
      this.connectionsService.getByIdsGraphQL(ids["organisations"], undefined, [
        "DOCUMENTS_WITH_TAGS",
        "CERTIFICATES",
      ]),
      this.productsService.getByIdsGraphQL(ids["products"], undefined, ["DOCUMENTS_WITH_TAGS"]),
      this.processesService.getByIdsGraphQL(ids["processes"], undefined, [
        "DOCUMENTS_WITH_TAGS",
        "CERTIFICATES",
      ]),
    ]);

    return { items, locations, deliveries, organisations, products, processes };
  }

  private async getAgentOrganisations(deliveries: IDeliveryExtended[]) {
    const agentIds = deliveries.flatMap((d) => d.agents).map((a) => a.id);

    return await this.connectionsService.getByIdsGraphQL(agentIds, undefined, [
      "CERTIFICATES",
      "DOCUMENTS_WITH_TAGS",
    ]);
  }

  private async getCertificates(records: { certificates?: ICertificateExtended[] }[]) {
    const certificateIds = records.flatMap((r) => (r.certificates || []).map((c) => c.id));

    return await this.certificatesService.getByIdsGraphQL(certificateIds, undefined, [
      "DOCUMENTS_WITH_TAGS",
    ]);
  }

  private getDocumentsForEntities<T extends { documents?: IDocument[] }>(
    entities: T[],
    targetType: string,
    getTargetName: (entity: T) => string,
  ): Model.IDocumentRowData[] {
    return (entities || []).flatMap((entity) =>
      this.addTargetDataToDocuments(entity.documents, targetType, getTargetName(entity)),
    );
  }

  private addTargetDataToDocuments(
    documents: IDocument[],
    targetType: string,
    targetName: string,
  ): Model.IDocumentRowData[] {
    return (documents || [])
      .map((document) => ({ ...document, targetType, targetName }))
      .sort((doc1, doc2) => doc1.name.localeCompare(doc2.name));
  }
}
