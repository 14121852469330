@if (columnDefs().length) {
  <app-table
    [table]="table"
    [columnDefs]="columnDefs()"
    [rowData]="rowData()"
    [areButtonsEnabled]="areButtonsEnabled()"
    [isLoading]="isLoading()"
    [isFixedBottomPaginator]="isFixedBottomPaginator()"
    [isPaginatorEnabled]="isPaginatorEnabled()"
    [isRecordStateFilterEnabled]="isRecordStateFilterEnabled()"
    [isSaveState]="isSaveTableState()"
    [isSearchEnabled]="isSearchEnabled()"
    [recordState]="recordState()"
    (rowClick)="onEdit($event)"
    [batchActionSettings]="batchActionSettings()"
    (getAll)="getAll()"
  />
}
