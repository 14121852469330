import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  input,
  Input,
  ViewChild,
} from "@angular/core";

import { SidebarMenuService } from "@components/shared/sidebar/sidebar-menu/sidebar-menu.service";
import { IGroupedSidebarMenuItem, ISidebarMenuItem } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-sidebar-group-item",
  templateUrl: "./sidebar-group-item.component.html",
  styleUrls: ["./sidebar-group-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarGroupItemComponent {
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public sidebarMenuService: SidebarMenuService = inject(SidebarMenuService);

  @ViewChild("contentRef") set content(value: ElementRef) {
    this.textElement = value;
    this.cdr.detectChanges();
  }

  @Input() class: string;

  @Input() groupItem: IGroupedSidebarMenuItem;

  public isCollapsed = input.required<boolean>();

  public textElement: ElementRef;

  public get active(): boolean {
    return this.groupItem.menuItems.some(this.isActiveRoute.bind(this));
  }

  public isActiveRoute(menuItem: ISidebarMenuItem): boolean {
    return this.sidebarMenuService.isActiveRoute(menuItem.route, menuItem.isActiveRouteExact);
  }
}
