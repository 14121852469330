import { Injectable, signal } from "@angular/core";

import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";
import { IBaseUnit, IItem, IItemQuantityDetails, IMaterial, IProduct } from "@shared/interfaces";
import { CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class ItemOverlayService extends OverlayCommonService {
  public linkedDeliveryCount = signal<number>(0);

  public linkedProcessCount = signal<number>(0);

  constructor() {
    super();
  }

  getParsedRowData(
    allProducts: IProduct[],
    allUnitOfMeasurements: IBaseUnit[],
    allMaterials: IMaterial[],
    selectedItems: IItem[],
  ): IItemQuantityDetails[] {
    const result: IItemQuantityDetails[] = [];

    for (const item of selectedItems) {
      const itemProductId = CommonUtils.getUriId(item.product);
      const itemMaterialsIds = item.materials.map((m) => CommonUtils.getUriId(m));

      const existingItem = result.find(
        (r) =>
          r.productId === itemProductId &&
          r.materialsIds.length === itemMaterialsIds.length &&
          r.materialsIds.every((m) => itemMaterialsIds.includes(m)),
      );

      if (existingItem) {
        existingItem.totalQuantity +=
          item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity;
      } else {
        const product = allProducts.find((p) => p.id === itemProductId);
        const baseUnitId = CommonUtils.getUriId(product.baseUnit);
        const unitOfMeasurement = allUnitOfMeasurements.find((u) => u.id === baseUnitId);
        const materialNames: string[] = [];

        for (const itemMaterialId of itemMaterialsIds) {
          const material = allMaterials.find((m) => m.id === itemMaterialId);

          if (material) {
            materialNames.push(material.name);
          }
        }
        result.push({
          productId: itemProductId,
          productName: product?.name || "-",
          materialsIds: itemMaterialsIds,
          materialNames,
          totalQuantity: item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity,
          quantitySymbol: unitOfMeasurement?.symbol,
          quantityPrecision: unitOfMeasurement?.precision,
        });
      }
    }

    return result;
  }

  override setCountersToLoadingState() {
    super.setCountersToLoadingState();
    this.linkedDeliveryCount.set(null);
    this.linkedProcessCount.set(null);
  }

  override setCountersEmptyState() {
    super.setCountersEmptyState();
    this.linkedDeliveryCount.set(0);
    this.linkedProcessCount.set(0);
  }
}
