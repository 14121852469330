import {
  ChangeDetectionStrategy,
  Component,
  signal,
  ViewChild,
  OnInit,
  inject,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import { EntityTypeEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import { IMaterialExtended } from "@shared/interfaces";
import { RecordSharingService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./shared-material-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedMaterialOverlayComponent extends SlideOverlayPageClass implements OnInit {
  override menuItems = signal(
    new Map([[OverlayTabEnum.DETAILS, { title: TextConstants.MATERIAL_DETAILS, isEnabled: true }]]),
  );

  override element = signal<IMaterialExtended>(null);

  override entityType = EntityTypeEnum.MATERIALS;

  private readonly recordSharingService = inject(RecordSharingService);

  public categoriesOptions = signal<InputSelectOption[]>([]);

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  formGroup: UntypedFormGroup;

  public readonly translations: any = {
    categoryHint: TextConstants.CATEGORY_HINT,
    categoryLabel: TextConstants.CATEGORY,
    nameLabel: TextConstants.NAME,
  };

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_SHARED_MATERIAL)) {
      return;
    }
    if (!this.recordId || !this.organisationIdQueryParam) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }

    await this.reloadElement(this.recordId);
    await this.setMenuItemFromURLParam();
  }

  protected override async reloadElement(id: string): Promise<void> {
    this.overlay.showLoading();
    try {
      const inboundMaterials = await this.recordSharingService.getInboundMaterialsByIdsGraphQL(
        [id],
        this.organisationIdQueryParam,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
      );

      this.element.set(inboundMaterials[0]);
      this.categoriesOptions.set([
        {
          label: this.element()?.category,
          value: this.element()?.category,
        },
      ]);
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public override setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name ?? null),
      category: new UntypedFormControl({
        label: this.element()?.category,
        value: this.element()?.category,
      }),
    });

    this.initialFormValue = this.formGroup.value;
    this.hasFormValuesChanged = false;

    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  };

  protected override recordName(): string {
    return this.element()?.name;
  }
}
