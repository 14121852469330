import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  viewChild,
  OnInit,
  OnDestroy,
} from "@angular/core";
import { FormControl } from "@angular/forms";

import { TippyService } from "@ngneat/helipopper";
import { QuillEditorComponent } from "ngx-quill";

import { TextConstants } from "@shared/constants";

@Component({
  selector: "app-rich-text-editor",
  templateUrl: "./rich-text-editor.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: false,
})
export class RichTextEditorComponent implements OnInit, OnDestroy {
  private readonly tippyService = inject(TippyService);

  private readonly editor = viewChild(QuillEditorComponent, { read: ElementRef });

  readonly control = input.required<FormControl>();

  readonly label = input<string>();

  readonly placeholder = input($localize`Type your content here…`);

  readonly hint = input<string>();

  readonly isOptional = input(false);

  readonly enableViewMode = input(false);

  readonly showLabel = input(true);

  private styleElement: HTMLStyleElement;

  private readonly translations = {
    toolbar: [
      { selector: ".ql-bold", text: $localize`Bold` },
      { selector: ".ql-italic", text: $localize`Italic` },
      { selector: ".ql-underline", text: $localize`Underline` },
      { selector: ".ql-list[value='ordered']", text: $localize`Ordered list` },
      { selector: ".ql-list[value='bullet']", text: $localize`Bullet list` },
      { selector: ".ql-link", text: $localize`Link` },
      { selector: ".ql-clean", text: $localize`Clear formatting` },
    ],
    linkTooltip: [
      { selector: ".ql-tooltip[data-mode='link']:before", text: $localize`Enter link:` },
      { selector: ".ql-tooltip[data-mode='link'] .ql-action:after", text: TextConstants.SAVE },
      { selector: ".ql-tooltip:before", text: $localize`Visit URL:` },
      { selector: ".ql-tooltip .ql-action:after", text: TextConstants.EDIT },
      { selector: ".ql-tooltip .ql-remove:after", text: TextConstants.REMOVE },
    ],
  };

  ngOnInit() {
    this.replaceLinkTooltipselectors();
  }

  ngOnDestroy() {
    if (this.styleElement) {
      document.head.removeChild(this.styleElement);
    }
  }

  onCreated() {
    this.addToolbarTooltips();
  }

  private addToolbarTooltips() {
    const editor = this.editor().nativeElement as Element;

    this.translations.toolbar.forEach(async (item) => {
      const element = editor.querySelector(`button${item.selector}`);

      if (element) {
        this.tippyService.create(element, item.text);
      }
    });
  }

  private replaceLinkTooltipselectors() {
    if (!this.styleElement) {
      this.styleElement = document.createElement("style");

      const styles = this.translations.linkTooltip
        .map((item) => `${item.selector} { content: "${item.text}" !important; }`)
        .join("\n");

      this.styleElement.appendChild(document.createTextNode(styles));
      document.head.appendChild(this.styleElement);
    }
  }
}
