import { ChangeDetectionStrategy, Component, Inject, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { IEudrCredentials } from "@shared/interfaces";
import { NotificationService, EudrExtensionService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./eudr-connect-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EudrConnectDialogComponent {
  public readonly translations: any = {
    usernameLabel: $localize`Username`,
    authKeyLabel: $localize`Authentication key`,
  };

  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public isEditing = signal(false);

  constructor(
    public dialogRef: MatDialogRef<EudrConnectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { eudrCredentials?: IEudrCredentials },
    private eudrExtensionService: EudrExtensionService,
    private notificationService: NotificationService,
  ) {
    if (this.data.eudrCredentials) {
      this.isEditing.set(true);
    }

    this.formGroup = new UntypedFormGroup({
      username: new UntypedFormControl(this.data.eudrCredentials?.username, [
        CustomValidators.required,
        CustomValidators.maxLength(50),
      ]),
      authKey: new UntypedFormControl(this.data.eudrCredentials?.authKey, [
        CustomValidators.required,
        CustomValidators.maxLength(100),
      ]),
    });

    this.isLoading.set(false);
  }

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const credentials: IEudrCredentials = {
      username: this.formGroup.controls["username"].value,
      authKey: this.formGroup.controls["authKey"].value,
    };

    try {
      await this.eudrExtensionService.createOrUpdate(credentials);
      this.notificationService.showSuccess($localize`Connected`);
      this.onClose(true, credentials);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false, credentials?: IEudrCredentials): void => {
    this.dialogRef.close({ hasSaved, credentials });
  };
}
