export enum RouteSegmentEnum {
  ADD = "add",
  EDIT = "edit",
  DETAILS = "details",
  FULL_SUPPLY_CHAIN = "full-supply-chain",
  UPLOAD = "upload",
  VIEW = "view",
  CUSTOM_FIELDS = "custom-fields",
  TAGS = "tags",
  STANDARDS = "standards",
  UNITS_OF_MEASUREMENT = "units",
  EXTENSIONS = "extensions",
  EXTENSIONS_ORBIFY = "extensions/orbify",
  EXTENSIONS_EUDR = "extensions/eudr",
  DOCUMENT_TYPES = "document-types",
  LOCATION_TYPES = "location-types",
  PROCESS_TYPES = "process-types",
  MEMBERSHIPS = "memberships",
  PROFILE = "profile",
  RULESETS = "rulesets",
  API_KEYS = "api-keys",
  CONNECTIONS = "connections",
  SENT_INFORMATION = "sent-information",
  RISK_ASSESSMENT_TEMPLATES = "risk-assessment-templates",
  RISK_LEVEL_SETS = "risk-level-sets",
  RISK_MITIGATIONS = "risk-mitigations",
  COUNTRIES = "countries",
}
