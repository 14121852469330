import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditCustomUnitDialogComponent } from "src/app/components/settings/settings-units-of-measurement";
import {
  EnumsCellRendererComponent,
  QuickActionsMenuComponent,
} from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  TableEnum,
  UnitOfMeasurementSourceTypeEnum,
} from "src/app/shared/enums";
import { IBaseUnit, ICustomUnitOfMeasurement } from "src/app/shared/interfaces";
import { AuthenticationService, UnitsOfMeasurementService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  standalone: false,
  selector: "app-units-of-measurement-table",
  templateUrl: "./units-of-measurement-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsOfMeasurementTableComponent implements AfterViewInit {
  @Input()
  public units: IBaseUnit[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.ADMIN_UNITS_OF_MEASUREMENT;

  @Input()
  public isSaveTableState = false;

  @Input()
  public unitSourceType: UnitOfMeasurementSourceTypeEnum = UnitOfMeasurementSourceTypeEnum.SYSTEM;

  @Input()
  public columns: string[] = ["name", "symbol", "type"];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  public rowData = signal<IBaseUnit[] | ICustomUnitOfMeasurement[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  private readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private unitsOfMeasurementService: UnitsOfMeasurementService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.DELETE,
            this.unitsOfMeasurementService,
          ),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      {
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions:
            this.areButtonsEnabled && this.canAddModifyEntities
              ? [
                  {
                    icon: "delete",
                    tooltip: TextConstants.DELETE,
                    click: this.onDelete,
                  },
                  {
                    tooltip: TextConstants.EDIT,
                    icon: "edit",
                    click: this.onEdit,
                  },
                ]
              : [],
        },
      },
      { headerName: $localize`Symbol`, field: "symbol" },
      { headerName: $localize`Category`, field: "type", cellRenderer: EnumsCellRendererComponent },
      { headerName: $localize`System unit`, field: "systemUnit.name" },
      { headerName: $localize`Conversion factor`, field: "conversionFactorWithSymbol" },
    ];

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);
    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }
    this.columnDefs.set(columnDefs);
  };

  public onEdit = async (customUnit: ICustomUnitOfMeasurement): Promise<void> => {
    const dialogRef = this.dialog.open(EditCustomUnitDialogComponent, {
      width: "665px",
      data: { customUnit },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onDelete = async (unit: ICustomUnitOfMeasurement): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure that you want to delete this custom unit?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.remove(unit.id);
      }
    });
  };

  private remove = async (unitId: string): Promise<void> => {
    try {
      await this.unitsOfMeasurementService.delete(unitId);
      this.notificationService.showSuccess($localize`Record deleted`);
      await this.getAll();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    if (this.units) {
      this.rowData.set(this.units);
      this.isLoading.set(false);
    } else {
      try {
        switch (this.unitSourceType) {
          case UnitOfMeasurementSourceTypeEnum.CUSTOM: {
            const customUnits = await this.unitsOfMeasurementService.getAll();
            const systemUnits = await this.unitsOfMeasurementService.getSystemUnits();

            this.rowData.set(
              customUnits.map((unit) => {
                const systemUnit = systemUnits.find((u) => unit.baseUnit.includes(u.id));

                return {
                  ...unit,
                  conversionFactorWithSymbol: `${unit.conversionFactor} ${systemUnit.symbol}`,
                  systemUnit,
                };
              }),
            );

            break;
          }
          default:
            this.rowData.set(await this.unitsOfMeasurementService.getSystemUnits());
            break;
        }
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
