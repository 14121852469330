<app-table
  *ngIf="columnDefs().length"
  [table]="table"
  [isLoading]="isLoading()"
  [rowData]="rowData()"
  [columnDefs]="columnDefs()"
  [areButtonsEnabled]="true"
  [isSearchEnabled]="true"
  [isRecordStateFilterEnabled]="false"
  [isPaginatorEnabled]="true"
  [isFixedBottomPaginator]="true"
  [isSaveState]="true"
  (rowClick)="onViewOnOverlay($event)"
  [batchActionSettings]="batchActionSettings"
  (getAll)="getAll()"
></app-table>
