import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";

import { TextConstants } from "@shared/constants";

import { BaseRulesetInfoClass } from "./base-ruleset-info.class";
import { LocationCriteriaInfoComponent } from "../location-criteria-info/location-criteria-info.component";

@Component({
  standalone: false,
  selector: "app-ruleset-location-info",
  templateUrl: "./ruleset-location-info.component.html",
  styleUrl: "./ruleset-shared-info.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RulesetLocationInfoComponent extends BaseRulesetInfoClass {
  @ViewChild("locationCriteria") locationCriteria: LocationCriteriaInfoComponent;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    descriptionLabel: TextConstants.DESCRIPTION,
    criteriaTp: $localize`Specify the characteristics of the locations to which this ruleset should apply`,
    expectationsTp: $localize`Specify which expectations need to be met for the location not
                                to be flagged as not meeting the ruleset requirements`,
    addDocumentTypeTp: $localize`Add document type`,
  };

  constructor() {
    super();
  }
}
