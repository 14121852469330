import { inject, Injectable, OnDestroy, signal } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom, Observable, Subject } from "rxjs";

import { AddConnectionDialogComponent } from "@components/index";
import { BulkAddLocationsModel } from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { ConfirmDialogComponent } from "@components/shared";
import { BulkAddBaseService } from "@components/shared/bulk-add/bulk-add-base.service";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  CustomFieldsResourceTypeEnum,
  RecordStateEnum,
} from "@shared/enums";
import { ILocationType, IOrganisation, ISelectOption, IUserData } from "@shared/interfaces";
import {
  NotificationService,
  AuthenticationService,
  CommonService,
  ConnectionsService,
  LocationTypesService,
} from "@shared/services";
import { FormUtils } from "@shared/utils";

interface IConnectionResult {
  hasSaved: boolean;
  savedOrganisation: IOrganisation;
}

@Injectable({
  providedIn: "root",
})
export class BulkAddLocationsService extends BulkAddBaseService implements OnDestroy {
  private dialog: MatDialog = inject(MatDialog);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private locationTypesService: LocationTypesService = inject(LocationTypesService);

  private connectionsService: ConnectionsService = inject(ConnectionsService);

  private overlay: SlideOverlayPageService = inject(SlideOverlayPageService);

  private commonService: CommonService = inject(CommonService);

  private notificationService: NotificationService = inject(NotificationService);

  public readonly isRegularUser = this.authenticationService.isRegularUser();

  public refreshTableSubject = new Subject();

  public abortController: AbortController;

  public initialFormValue: object;

  public override formGroup: FormGroup<BulkAddLocationsModel.SetValuesFormGroup>;

  public countryOptions = signal<ISelectOption[]>([]);

  public locationTypeOptions = signal<InputSelectOption[]>([]);

  public organisationOptions = signal<ISelectOption[]>([]);

  private activeOrganisationOption = signal<ISelectOption>(null);

  public allLocationTypes: ILocationType[] = [];

  private readonly fieldEnum = BulkAddLocationsModel.FieldEnum;

  constructor() {
    super();

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe((countriesOptions) => {
        this.countryOptions.set(countriesOptions);
      }),
    );

    this.subscriptions.add(
      this.authenticationService.userDataObservable$.subscribe((userData: IUserData) => {
        if (
          !userData ||
          !userData.availableOrganisations?.length ||
          userData.activeOrganisationIndex === null ||
          userData.activeOrganisationIndex === undefined ||
          !this.isRegularUser
        ) {
          this.activeOrganisationOption.set(null);

          return;
        }

        const activeOrganisation =
          userData.availableOrganisations[userData.activeOrganisationIndex];

        const activeOrganisationOption: ISelectOption = {
          label: activeOrganisation.name,
          value: activeOrganisation.id,
        };

        this.activeOrganisationOption.set(activeOrganisationOption);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.activeOrganisationOption.set(null);
    this.countryOptions.set([]);
    this.organisationOptions.set([]);
    this.allCustomFields.set([]);
  }

  public async initializeProperties(): Promise<void> {
    this.abortController = new AbortController();

    await Promise.all([
      this.setLocationTypes(),
      this.setOrganisations(),
      this.setAllCustomFields(),
    ]);
  }

  public setupForm(): void {
    const { fieldEnum } = this;

    const customFieldsFormArray = this.createCustomFieldsFormArray(this.allCustomFields());

    this.formGroup = new FormGroup<BulkAddLocationsModel.SetValuesFormGroup>({
      [fieldEnum.TAGS]: new FormControl([]),
      [fieldEnum.ORGANISATION]: new FormControl(),
      [fieldEnum.LOCATION_TYPES]: new FormControl([]),
      [fieldEnum.COUNTRY]: new FormControl(),
      [fieldEnum.IS_FIXED_TAGS]: new FormControl(false),
      [fieldEnum.IS_FIXED_ORGANISATION]: new FormControl(false),
      [fieldEnum.IS_FIXED_LOCATION_TYPES]: new FormControl(false),
      [fieldEnum.IS_FIXED_COUNTRY]: new FormControl(false),
      [fieldEnum.CUSTOM_FIELDS]: customFieldsFormArray,
      [fieldEnum.RECORDS]: new FormArray([]),
    });

    this.initialFormValue = this.formGroup.getRawValue();

    this.subscribeToPendingFormStatusChanges();
    this.subscribeToRecordsLengthChanges();
  }

  public get refreshTable$(): Observable<unknown> {
    return this.refreshTableSubject.asObservable();
  }

  private async setAllCustomFields(): Promise<void> {
    const customFields = await this.customFieldsService.getAll(
      CustomFieldsResourceTypeEnum.LOCATION,
    );

    this.allCustomFields.set(customFields);
  }

  public abortCreation(): void {
    this.abortController.abort();
  }

  public hasInitialFormValueChanged(currentFormValue: object): boolean {
    return FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue);
  }

  public async canExitSlideOver(): Promise<boolean> {
    const hasChanged = this.hasInitialFormValueChanged(this.formGroup.getRawValue());

    if (!hasChanged) {
      return true;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.EXIT_CONFIRMATION,
        contentText: $localize`You're about to leave this page. Are you sure you want to discard your changes?`,
        confirmButtonText: TextConstants.DISCARD_CHANGES_LEAVE,
        confirmButtonColor: "danger",
        confirmButtonIcon: "close",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    return response === ConfirmDialogResponseEnum.CONFIRM;
  }

  public addOrganisation(organisation: IOrganisation): ISelectOption {
    const organisationOption: ISelectOption = {
      label: organisation.name,
      value: organisation.id,
    };

    this.organisationOptions.set([organisationOption, ...this.organisationOptions()]);

    return this.organisationOptions()[0];
  }

  public async setLocationTypes(): Promise<void> {
    try {
      this.allLocationTypes = await this.locationTypesService.getAll(RecordStateEnum.ACTIVE);

      const locationTypeOptions: InputSelectOption[] = this.allLocationTypes
        .map((locationType) => ({
          label: locationType.type,
          value: locationType.id,
          icon: locationType.pointOfOrigin ? "target" : null,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      this.locationTypeOptions.set(locationTypeOptions);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public async setOrganisations(): Promise<void> {
    try {
      const organisations = await this.connectionsService.getAll(RecordStateEnum.ACTIVE);

      const organisationOptions: ISelectOption[] = organisations
        .map((organisation) => ({
          label: organisation.name,
          value: organisation.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

      if (this.activeOrganisationOption()) {
        this.organisationOptions.set([this.activeOrganisationOption(), ...organisationOptions]);
      } else {
        this.organisationOptions.set(organisationOptions);
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  public async openAddOrganisationDialog(
    onAfterFn?: (option: ISelectOption) => void,
  ): Promise<void> {
    const result = await this.overlay.openDialog<
      IConnectionResult,
      { countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.countryOptions(),
    });

    if (result?.hasSaved) {
      const option = this.addOrganisation(result.savedOrganisation);

      onAfterFn?.(option);
    }
  }
}
