<app-button (pressed)="onButtonClick()" [type]="buttonType">
  {{ buttonText }}
</app-button>
<ng-container *ngIf="isShowLinkText">
  <br />
  <br />
  <a class="clickable underline" (click)="onToggleShowText()">
    @if (isShowingText) {
      <ng-container i18n>Hide link</ng-container>
    } @else {
      <ng-container i18n>Show link</ng-container>
    }
  </a>
  <br />
  <br />
  <p *ngIf="isShowingText" class="break-word">{{ text }}</p>
</ng-container>
