@if (visible) {
  <button
    (click)="onClick($event)"
    [disabled]="disabled"
    [ngClass]="['app-filled-button', type, size, color || '', class]"
    [type]="accessibilityRole"
    color="primary"
    mat-flat-button
  >
    <ng-content select="[icon-left]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[icon-right]"></ng-content>
  </button>
}
