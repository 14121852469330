import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  signal,
  OnInit,
  OnDestroy,
} from "@angular/core";

import { Subscription } from "rxjs";

import { ItemSupplyChainMapperService } from "@components/shared/items-supply-chain/item-supply-chain-mapper.service";
import { FeatureFlagEnum } from "@shared/enums";
import { IBaseUnit, IProcessExtended, IProductExtended } from "@shared/interfaces";
import { CommonService, FeatureFlagService, ProductsService } from "@shared/services";

interface IProcessesByTypeGroups {
  [key: number]: IProcessExtended[];
}

@Component({
  standalone: false,
  selector: "app-delivery-report-processes",
  templateUrl: "./delivery-report-processes.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      h3 {
        font-size: 30px;
      }
    `,
  ],
})
export class DeliveryReportProcessesComponent implements OnInit, OnDestroy {
  public itemIds = input<string[]>([]);

  public isLoading = signal<boolean>(true);

  public hasAnyProcesses = signal<boolean>(false);

  public processesByTypeGroups: IProcessesByTypeGroups = {};

  public allProducts: IProductExtended[] = [];

  public allUnitOfMeasurements: IBaseUnit[] = [];

  private itemSupplyChainMapperService: ItemSupplyChainMapperService = inject(
    ItemSupplyChainMapperService,
  );

  private productsService: ProductsService = inject(ProductsService);

  private featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  private subscriptions = new Subscription();

  private commonService: CommonService = inject(CommonService);

  constructor() {
    this.subscriptions.add(
      this.commonService.unitOfMeasurementsObservable$.subscribe(
        (unitOfMeasurements: IBaseUnit[]) => {
          this.allUnitOfMeasurements = unitOfMeasurements;
        },
      ),
    );
  }

  public async ngOnInit(): Promise<void> {
    const includes: string[] = [];

    if (!this.isOldMaterialsEnabled) {
      includes.push("MATERIALS");
    }

    const [supplyChainItems, allProducts] = await Promise.all([
      this.itemSupplyChainMapperService.mapItemSupplyChain(this.itemIds()),
      this.productsService.getAllGraphQL(undefined, undefined, includes),
    ]);

    this.allProducts = allProducts;

    const processes = supplyChainItems.map((supplyChainItem) => supplyChainItem.processes).flat();

    const uniqueProcesses = Array.from(
      new Map(processes.map((process) => [process.id, process])).values(),
    );

    this.processesByTypeGroups = uniqueProcesses.reduce((groups, process) => {
      const typeId = process.type.id;

      if (!groups[typeId]) {
        groups[typeId] = [];
      }
      groups[typeId].push(process);

      return groups;
    }, {});

    this.hasAnyProcesses.set(Object.keys(this.processesByTypeGroups).length > 0);
    this.isLoading.set(false);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
