import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  TemplateRef,
  effect,
  inject,
  input,
  signal,
  viewChild,
  viewChildren,
} from "@angular/core";
import { FormArray, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmDialogComponent, DragDropListItemComponent } from "@components/index";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { MAX_INDICATOR_MITIGATIONS_ALLOWED } from "@components/shared/risk-assessment-templates/constants";
import { RiskMitigation } from "@components/shared/risk-mitigations/models";
import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { TextConstants } from "@shared/constants";
import { NotificationService, AuthenticationService } from "@shared/services";

import { CategoryFormGroup, IndicatorFormGroup, IndicatorType } from "./models";
import { RiskAssessmentTemplateOverlayIndicatorsFormService } from "./risk-assessment-template-overlay-indicators-form.service";

@Component({
  standalone: false,
  selector: "app-risk-assessment-template-overlay-indicators",
  templateUrl: "./risk-assessment-template-overlay-indicators.component.html",
  styleUrl: "./risk-assessment-template-overlay-indicators.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RiskAssessmentTemplateOverlayIndicatorsComponent {
  private readonly dialog = inject(MatDialog);

  private readonly indicatorsFormService = inject(
    RiskAssessmentTemplateOverlayIndicatorsFormService,
  );

  private readonly authenticationService = inject(AuthenticationService);

  private readonly notification = inject(NotificationService);

  readonly overlay = inject(SlideOverlayPageService);

  readonly form = input.required<FormArray<FormGroup<IndicatorFormGroup | CategoryFormGroup>>>();

  private readonly dragDropListItems = viewChildren(DragDropListItemComponent);

  public readonly copyModeEnum = CopyModeEnum;

  readonly controller = viewChild("controller", { read: TemplateRef });

  private readonly mitigationsPanel = viewChild("mitigationsPanel", { read: TemplateRef });

  private readonly currentIndicatorFormGroup = signal<FormGroup<IndicatorFormGroup>>(undefined);

  readonly excludedMitigationIds = signal<string[]>([]);

  readonly addedIndicatorIndex = signal<number>(undefined);

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    validationMessage: $localize`There must be at least one indicator provided within or outside of a category`,
    mitigationsTp: $localize`Specify which mitigations should be suggested in case the risk is present`,
    suggestedMitigationsLabel: $localize`Suggested mitigations`,
    guidanceLabel: $localize`Guidance`,
    indicatorsPh: $localize`Please add indicators and categories`,
    guidancePh: $localize`Type your guidance here…`,
  };

  get controls() {
    return this.form().controls;
  }

  get isSubmitButtonDisabled() {
    return (
      !this.controls.some((control) => control.value._type === "indicator") ||
      this.controls[this.controls.length - 1].value._type === "category"
    );
  }

  constructor() {
    effect(() => this.scrollToDragDropListItem(this.addedIndicatorIndex()));
  }

  add(type: IndicatorType, position: number) {
    position = position < 0 ? 0 : position;

    if (type === "indicator") {
      this.indicatorsFormService.addIndicatorFormGroup(null, position);
    } else {
      this.indicatorsFormService.addCategoryFormGroup(null, position);
    }

    this.addedIndicatorIndex.set(position);
  }

  onDrop(event: CdkDragDrop<DragDropListItemComponent>) {
    moveItemInArray(this.controls, event.previousIndex, event.currentIndex);
  }

  onMoveUp(index: number) {
    moveItemInArray(this.controls, index, index - 1);
  }

  onMoveDown(index: number) {
    moveItemInArray(this.controls, index, index + 1);
  }

  onRemove(index: number) {
    const controlValue = this.controls[index].getRawValue();

    const remove = () => this.indicatorsFormService.remove(index);

    if (controlValue.name.length) {
      this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: TextConstants.REMOVE_CONFIRMATION,
          contentText: $localize`Are you sure you want to remove this ${controlValue._type}:valueType:?`,
          confirmButtonColor: "danger",
          confirmButtonText: TextConstants.REMOVE,
          confirmButtonIcon: "delete",
          onSubmit: () => remove(),
        },
      });
    } else {
      remove();
    }
  }

  isDeleteButtonDisabled(index: number) {
    return (
      this.controls.at(index).value._type === "indicator" &&
      this.controls.filter((control) => control.value._type === "indicator").length === 1
    );
  }

  async openMitigationsPanel(index: number) {
    const control = this.controls.at(index);
    const value = control.getRawValue();

    if (value._type === "indicator") {
      const { mitigations } = value;

      if (mitigations.length === MAX_INDICATOR_MITIGATIONS_ALLOWED) {
        this.notification.showError(
          $localize`No more than ${MAX_INDICATOR_MITIGATIONS_ALLOWED}:MAX_INDICATOR_MITIGATIONS_ALLOWED: mitigations are allowed per indicator`,
        );

        return;
      }

      const mitigationIds = mitigations.map((mitigation) => mitigation.id);

      this.excludedMitigationIds.set(mitigationIds);
      this.currentIndicatorFormGroup.set(control as FormGroup<IndicatorFormGroup>);
      this.overlay.openSideMenu(this.mitigationsPanel());
    }
  }

  async onAddIndicatorMitigation(riskMitigation: RiskMitigation) {
    const group = this.currentIndicatorFormGroup();

    this.indicatorsFormService.addMitigation(group, riskMitigation);
    this.excludedMitigationIds.update((currentIds) => [...currentIds, riskMitigation.id]);

    if (group.getRawValue().mitigations.length === MAX_INDICATOR_MITIGATIONS_ALLOWED) {
      this.overlay.closeSideMenu();
    }
  }

  onRemoveMitigation(
    indicatorFormGroupIndex: number,
    mitigationIndex: number,
    mitigationId: string,
  ) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this mitigation?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
        onSubmit: () => {
          this.indicatorsFormService.removeMitigation(indicatorFormGroupIndex, mitigationIndex);
          this.excludedMitigationIds.update((currentIds) =>
            currentIds.filter((id) => id !== mitigationId),
          );
        },
      },
    });
  }

  async save() {
    await this.overlay.save();
    await this.overlay.closeSideMenu();
  }

  async cancel() {
    const canExit = await this.overlay.enableViewMode(false);

    if (canExit) {
      this.overlay.closeSideMenu();
    }
  }

  private scrollToDragDropListItem(index: number) {
    const listItems = this.dragDropListItems();

    if (typeof index === "number" && listItems.length) {
      const item = listItems[index];

      if (item) {
        const element = item.content().nativeElement as HTMLElement;

        setTimeout(() =>
          element.scrollIntoView({ behavior: "smooth", block: index === 0 ? "nearest" : "center" }),
        );

        element.querySelector<HTMLInputElement>(".name-field input").focus({ preventScroll: true });
      }
    }
  }
}
