import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";

@Component({
  standalone: false,
  selector: "app-dropdown-menu-item",
  templateUrl: "./dropdown-menu-item.component.html",
  styleUrls: ["./dropdown-menu-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class DropdownMenuItemComponent {
  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Output() pressed: EventEmitter<MouseEvent> = new EventEmitter();

  onClick(event: MouseEvent): void {
    if (!this.disabled) {
      this.pressed.emit(event);
    }
  }
}
