import { inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";

import { combineLatest, filter, tap } from "rxjs";
import { CanDeactivateClass } from "src/app/shared/classes/can-deactivate.class";
import {
  CriteriaTypeEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RouteEnum,
  TargetPathEnum,
} from "src/app/shared/enums";
import {
  ICondition,
  IDocumentType,
  ILocationExtended,
  ILocationType,
  IRuleset,
  ISelectOption,
} from "src/app/shared/interfaces";
import {
  CommonService,
  DocumentTypesService,
  LocationsService,
  LocationTypesService,
  RulesetsService,
} from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";
import { RulesetUtils } from "src/app/shared/utils/ruleset.utils";

import { NotificationService } from "@shared/services";

export abstract class RulesetBaseClass extends CanDeactivateClass {
  public element = signal<IRuleset>(undefined);

  public isLoading = signal(true);

  protected newTypesCreated = signal(false);

  public selectedLocationTypes = signal<ILocationType[]>([]);

  public selectedLocationFromTypes = signal<ILocationType[]>([]);

  public selectedLocationToTypes = signal<ILocationType[]>([]);

  protected allLocationTypes = signal<ILocationType[]>([]);

  public selectedDocumentTypes = signal<IDocumentType[]>([]);

  protected allDocumentTypes = signal<IDocumentType[]>([]);

  protected allLocations = signal<ILocationExtended[]>([]);

  public selectedLocationsFrom = signal<ILocationExtended[]>([]);

  public selectedLocationsTo = signal<ILocationExtended[]>([]);

  public allCountries = signal<ISelectOption[]>([]);

  protected selectedCountries = signal<ISelectOption[]>([]);

  protected selectedLocationFromCountries = signal<ISelectOption[]>([]);

  protected selectedLocationToCountries = signal<ISelectOption[]>([]);

  protected selectedCountriesCodes = signal<string[]>([]);

  protected selectedLocationFromCountriesCodes = signal<string[]>([]);

  protected selectedLocationToCountriesCodes = signal<string[]>([]);

  protected selectedLocationTypesUris = signal<string[]>([]);

  protected selectedLocationFromTypesUris = signal<string[]>([]);

  protected selectedLocationToTypesUris = signal<string[]>([]);

  protected selectedDocumentTypesUris = signal<string[]>([]);

  public deliveryFromCriteria = signal<CriteriaTypeEnum>(CriteriaTypeEnum.PARAMETERS);

  public deliveryToCriteria = signal<CriteriaTypeEnum>(CriteriaTypeEnum.PARAMETERS);

  public readonly criteriaTypeEnum = CriteriaTypeEnum;

  public resourceType: ResourceTypeEnum;

  public readonly resourceTypeEnum = ResourceTypeEnum;

  public readonly routingEnum = RouteEnum;

  public readonly recordStateEnum = RecordStateEnum;

  protected readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  protected readonly canAddOrModifyRulesets = this.authenticationService.getActiveOrganisationId();

  protected route = inject(ActivatedRoute);

  protected router = inject(Router);

  protected documentTypesService = inject(DocumentTypesService);

  protected notificationService = inject(NotificationService);

  protected commonService = inject(CommonService);

  protected rulesetsService = inject(RulesetsService);

  protected locationsService = inject(LocationsService);

  public locationTypesService = inject(LocationTypesService);

  constructor() {
    super();
    combineLatest([
      this.route.params,
      this.commonService.countriesOptionsObservable$.pipe(filter((countries) => !!countries)),
    ])
      .pipe(
        takeUntilDestroyed(),
        tap(async ([params, countries]) => {
          this.allDocumentTypes.set(await this.getAllDocumentTypes());
          this.allLocationTypes.set(await this.getAllLocationTypes());
          this.allLocations.set(await this.getAllLocations());
          this.allCountries.set(countries);
          await this.loadData(params["id"]);
        }),
      )
      .subscribe();
  }

  protected abstract loadData(rulesetId: string): Promise<void>;

  protected async getAllDocumentTypes(): Promise<IDocumentType[]> {
    return await this.documentTypesService.getAll();
  }

  protected async getAllLocationTypes(): Promise<ILocationType[]> {
    return await this.locationTypesService.getAll();
  }

  protected async getAllLocations(): Promise<ILocationExtended[]> {
    return await this.locationsService.getAllGraphQL({
      recordState: this.recordStateEnum.ACTIVE,
    });
  }

  protected setSelectedDocumentTypes(): void {
    this.selectedDocumentTypesUris.set(
      this.element()
        .expectations.map((e) => this.extractValueByPath(e.condition, TargetPathEnum.TYPE))
        .flat(),
    );
    const selectedDocumentTypesIds = this.selectedDocumentTypesUris().map((uri) =>
      CommonUtils.getUriId(uri),
    );

    this.selectedDocumentTypes.set(
      this.allDocumentTypes().filter((type) => selectedDocumentTypesIds.includes(type.id)),
    );
  }

  protected setSelectedLocationTypes(): void {
    this.selectedLocationTypesUris.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.TYPES) ?? [],
    );
    const selectedLocationTypesIds = this.selectedLocationTypesUris().map((uri) =>
      CommonUtils.getUriId(uri),
    );

    this.selectedLocationTypes.set(
      this.allLocationTypes().filter((type) => selectedLocationTypesIds?.includes(type.id)),
    );
  }

  protected setSelectedCountries(): void {
    this.selectedCountriesCodes.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.COUNTRY) ?? [],
    );
    this.selectedCountries.set(
      this.allCountries().filter((country) =>
        this.selectedCountriesCodes()?.includes(`${country.value}`),
      ),
    );
  }

  protected setSelectedLocationFromCountries(): void {
    this.selectedLocationFromCountriesCodes.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.FROM_COUNTRIES) ?? [],
    );
    this.selectedLocationFromCountries.set(
      this.allCountries().filter((country) =>
        this.selectedLocationFromCountriesCodes()?.includes(`${country.value}`),
      ),
    );
  }

  protected setSelectedLocationToCountries(): void {
    this.selectedLocationToCountriesCodes.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.TO_COUNTRIES) ?? [],
    );
    this.selectedLocationToCountries.set(
      this.allCountries().filter((country) =>
        this.selectedLocationToCountriesCodes()?.includes(`${country.value}`),
      ),
    );
  }

  protected setSelectedLocationFromTypes(): void {
    this.selectedLocationFromTypesUris.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.FROM_TYPES) ?? [],
    );
    const selectedLocationFromTypesIds = this.selectedLocationFromTypesUris().map((uri) =>
      CommonUtils.getUriId(uri),
    );

    this.selectedLocationFromTypes.set(
      this.allLocationTypes().filter((type) => selectedLocationFromTypesIds?.includes(type.id)),
    );
  }

  protected setSelectedLocationToTypes(): void {
    this.selectedLocationToTypesUris.set(
      this.extractValueByPath(this.element().condition, TargetPathEnum.TO_TYPES) ?? [],
    );
    const selectedLocationToTypesIds = this.selectedLocationToTypesUris().map((uri) =>
      CommonUtils.getUriId(uri),
    );

    this.selectedLocationToTypes.set(
      this.allLocationTypes().filter((type) => selectedLocationToTypesIds?.includes(type.id)),
    );
  }

  protected setSelectedLocationsTo(): void {
    const selectedLocationToUris =
      this.extractValueByPath(this.element().condition, TargetPathEnum.TO_LOCATION) ?? [];
    const selectedLocationToIds = selectedLocationToUris.map((uri) => CommonUtils.getUriId(uri));

    this.selectedLocationsTo.set(
      this.allLocations().filter((location) => selectedLocationToIds?.includes(location.id)),
    );
  }

  protected setSelectedLocationsFrom(): void {
    const selectedLocationFromUris =
      this.extractValueByPath(this.element().condition, TargetPathEnum.FROM_LOCATION) ?? [];
    const selectedLocationFromIds = selectedLocationFromUris.map((uri) =>
      CommonUtils.getUriId(uri),
    );

    this.selectedLocationsFrom.set(
      this.allLocations().filter((location) => selectedLocationFromIds?.includes(location.id)),
    );
  }

  private extractValueByPath(condition: ICondition, targetPath: string): string[] | undefined {
    const value = RulesetUtils.extractValueByPath(condition, targetPath);

    if (typeof value === "string") {
      return [value];
    }

    return value;
  }
}
