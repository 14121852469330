import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  input,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { GoogleMap } from "@angular/google-maps";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmDialogComponent } from "@components/shared";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { CommonConstants, MapConstants, TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, GeoJsonTypeEnum } from "@shared/enums";
import { NotificationService } from "@shared/services";
import { GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import { CoordinatesFormComponent } from "./../coordinates-form/coordinates-form.component";

@Component({
  standalone: false,
  selector: "app-location-overlay-gps",
  templateUrl: "./location-overlay-gps.component.html",
  styleUrl: "./location-overlay-gps.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayGpsComponent implements OnChanges, AfterViewInit {
  @Input({ required: true }) formGroup: FormGroup;

  @Input() coordinatesformGroups: FormArray;

  public geojson = input<any>(null);

  public showCoordinateForm = input<boolean>(false);

  vertices = signal<google.maps.LatLngLiteral[]>([]);

  @ViewChild(GoogleMap)
  public googleMap: GoogleMap;

  @ViewChild(CoordinatesFormComponent)
  public coordinatesFormComponent: CoordinatesFormComponent;

  private bounds: google.maps.LatLngBounds;

  public isEditing = input<boolean>(false);

  loadingMap = signal(false);

  mapDetailsType = "coordinates";

  file: File;

  readonly commonConstants = CommonConstants;

  readonly uploadBoxFooterRightText: string = `Maximum size: ${this.commonConstants.MAX_GEO_JSON_SIZE_IN_MB}MB`;

  public mapCenter = signal<{ lat: number; lng: number }>({ lat: 0, lng: 0 });

  zoom = 1;

  type: GeoJsonTypeEnum = null;

  public markerOptions: google.maps.MarkerOptions = null;

  marker: google.maps.Marker | null = null;

  private readonly markerIconUrl = "../../../assets/icons/geo-pinpoint.svg";

  selectedTabIndex = signal<number>(0);

  private fitBoundsTimes = 0;

  @Output() removeMap = new EventEmitter<void>();

  @Output() editGeojsonCoordinates = new EventEmitter<void>();

  private readonly DEFAULT_POLYGON_OPTIONS = MapConstants.DEFAULT_POLYGON_OPTIONS;

  public readonly mapOptions = MapConstants.DEFAULT_MAP_OPTIONS;

  private polygons: google.maps.Polygon[] = [];

  private markers: google.maps.Marker[] = [];

  public errorMessage: string;

  public errorMessageVisible = false;

  public canEditCoordinates = input<boolean>(true);

  public readonly translations: any = {
    editCoordinatesTp: $localize`Edit coordinates`,
    editNotSupportedTp: $localize`Editing of this geojson file’s structure is not supported.`,
    removeTp: TextConstants.REMOVE,
    swapTp: $localize`Swap longitude & latitude column positions`,
    pasteTp: $localize`You can paste coordinates directly or enter them manually by typing the longitude and latitude values.`,
    removeGpsTp: $localize`Remove GPS data`,
    areaTp: $localize`GPS data area`,
    removeAreaTp: $localize`Remove this area`,
  };

  constructor(
    private notificationService: NotificationService,
    protected overlay: SlideOverlayPageService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {}

  ngAfterViewInit(): void {
    this.setupMap();
  }

  getValidFormGroups(): FormGroup[] {
    return this.coordinatesformGroups.controls.filter((control) => {
      if (control instanceof FormGroup) {
        const rows = control.get("rows") as FormArray;

        return rows && rows.length > 0;
      }

      return false;
    }) as FormGroup[];
  }

  getAreaLabel(index: number): string {
    return String.fromCharCode(65 + index);
  }

  getFormGroup(index: number): FormGroup {
    return this.coordinatesformGroups.at(index) as FormGroup;
  }

  addArea(): void {
    const newFormGroup = this.fb.group({
      rows: this.fb.array(
        [this.createCoordinateGroup()],
        [CustomValidators.polygonClosedValidator],
      ),
    });

    this.coordinatesformGroups.push(newFormGroup);

    if (this.coordinatesformGroups.length > 1) {
      this.type = GeoJsonTypeEnum.MULTI_POLYGON;
    } else {
      this.type = GeoJsonTypeEnum.POLYGON;
    }

    this.selectedTabIndex.set(this.getValidFormGroups().length - 1);

    this.onCoordinatesChanged();
  }

  private createCoordinateGroup(
    latitude: number | null = null,
    longitude: number | null = null,
  ): FormGroup {
    return this.fb.group({
      longitude: [longitude, [CustomValidators.longitude, CustomValidators.required]],
      latitude: [latitude, [CustomValidators.latitude, CustomValidators.required]],
    });
  }

  get hasGeoLocationValue(): boolean {
    return !!this.formGroup.controls["geoLocation"].value;
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges["geojson"] && this.geojson()) {
      this.type = GeoJSONUtils.determineGeometryType(this.geojson());
    }

    if (!this.googleMap) {
      this.fitBoundsTimes = 0;
    }
  }

  public onCoordinatesChanged(): void {
    const selectedGroup = this.coordinatesformGroups.at(this.selectedTabIndex());

    const rows = selectedGroup.get("rows").value;
    let updatedCoordinates = rows.map((row) => {
      const latitude = row.latitude != null ? +row.latitude : null;
      const longitude = row.longitude != null ? +row.longitude : null;

      return [longitude, latitude];
    });

    if (updatedCoordinates.length > 2 && !this.isPolygonClosed(updatedCoordinates)) {
      if (
        GeoJSONUtils.isValidLatitude(updatedCoordinates[updatedCoordinates.length - 1][1]) &&
        GeoJSONUtils.isValidLongitude(updatedCoordinates[updatedCoordinates.length - 1][0])
      ) {
        updatedCoordinates = [...updatedCoordinates, updatedCoordinates[0]];
        const formArray = selectedGroup.get("rows") as FormArray;
        const firstRow = formArray.at(0);

        const newRow = this.fb.group({
          longitude: firstRow.get("longitude").value,
          latitude: firstRow.get("latitude").value,
          rowAddedToClosePolygon: true,
        });

        formArray.push(newRow);
        formArray.updateValueAndValidity();
        this.cdr.detectChanges();
      }
    }

    if (
      this.coordinatesformGroups.length > this.selectedTabIndex() &&
      this.coordinatesformGroups.at(this.selectedTabIndex()).get("rows").value ===
        updatedCoordinates
    ) {
      return;
    }

    this.setupMap();
    this.updateFormControls();
  }

  setupMap() {
    this.errorMessageVisible = false;
    if (!this.type) {
      this.type = GeoJSONUtils.determineGeometryType(this.geojson());
    }
    switch (this.type) {
      case GeoJsonTypeEnum.POINT:
        this.updatePoint();
        break;
      case GeoJsonTypeEnum.MULTI_POINT:
        this.updateMultiPoint();
        break;
      case GeoJsonTypeEnum.POLYGON:
        this.updatePolygon(this.selectedTabIndex());
        break;
      case GeoJsonTypeEnum.MULTI_POLYGON:
        this.updateMultiPolygon(true);
        break;
      default:
        return;
    }
    this.updateFormControls();
  }

  public onFileSelected = async (file: File): Promise<void> => {
    this.file = file;
    const reader = new FileReader();

    reader.readAsText(file);
    reader.onload = () => {
      try {
        const geojson = JSON.parse(reader.result.toString());

        if (!GeoJSONUtils.isValid(geojson)) {
          this.notificationService.showError(TextConstants.FILE_NOT_SUPPORTED_ERROR);
          this.file = null;

          return;
        }
        this.file = null;
        this.formGroup.controls["gpsX"].setValue(null);
        this.formGroup.controls["gpsY"].setValue(null);
        this.formGroup.controls["geoLocation"].setValue(geojson);
      } catch {
        this.notificationService.showError(TextConstants.FILE_NOT_SUPPORTED_ERROR);
        this.file = null;
      }
    };
  };

  public onDeleteMap = (shouldRemoveAll = false): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove all of the GPS data`,
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
        confirmButtonColor: "danger",
        cancelButtonText: TextConstants.CANCEL,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.resetAreasAndMap(shouldRemoveAll);
      }
    });
  };

  resetAreasAndMap(shouldEmitChanges: boolean) {
    this.formGroup.controls["gpsX"].setValue(null);
    this.formGroup.controls["gpsY"].setValue(null);
    this.formGroup.controls["gpsX"].clearValidators();
    this.formGroup.controls["gpsY"].clearValidators();
    this.formGroup.controls["gpsX"].updateValueAndValidity();
    this.formGroup.controls["gpsY"].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
    this.mapDetailsType = "coordinates";
    this.marker?.setMap(null);
    this.marker = null;
    this.polygons.forEach((polygon) => {
      if (polygon) {
        polygon.setMap(null);
      }
    });
    this.polygons = [];
    this.markers.forEach((marker) => {
      if (marker) {
        marker.setMap(null);
      }
    });
    this.markers = [];
    this.vertices.set([]);
    this.coordinatesformGroups.clear();
    this.coordinatesformGroups.setValue([]);
    this.errorMessageVisible = false;
    this.errorMessage = "";
    this.cdr.detectChanges();
    if (shouldEmitChanges) {
      this.removeMap.emit();
    } else {
      this.googleMap?.googleMap.setCenter({ lat: 0, lng: -40 });
      this.googleMap?.googleMap.setZoom(2);
    }
  }

  updatePoint() {
    const rows = [...this.coordinatesformGroups.at(0).get("rows").value];

    if (rows.length === 1) {
      const lat = +rows[0].latitude;
      const lng = +rows[0].longitude;

      const position = { lat, lng };

      const polygonIndex = this.selectedTabIndex();

      if (this.polygons[polygonIndex]) {
        this.polygons[polygonIndex].setMap(null);
        this.polygons[polygonIndex] = null;
      }

      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];

      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }

      this.marker = new google.maps.Marker({
        position,
        map: this.googleMap?.googleMap,
        icon: {
          url: this.markerIconUrl,
          scaledSize: new google.maps.Size(40, 40),
        },
      });

      this.googleMap?.googleMap.setCenter(position);
      this.googleMap?.googleMap.setZoom(10);
    }
  }

  public onMapIdle = () => {
    if (this.fitBoundsTimes < 1) {
      this.setupMap();
      this.fitBoundsTimes++;
    }
  };

  private updateMultiPoint() {
    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];

    this.coordinatesformGroups.controls.forEach((formGroup) => {
      const rows = formGroup.get("rows").value;

      if (rows.length === 1) {
        const latitude = +rows[0].latitude;
        const longitude = +rows[0].longitude;

        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: this.googleMap?.googleMap,
          icon: {
            url: this.markerIconUrl,
            scaledSize: new google.maps.Size(40, 40),
          },
        });

        this.markers.push(marker);
      }
    });

    const bounds = new google.maps.LatLngBounds();

    this.markers.forEach((marker) => bounds.extend(marker.getPosition()));
    this.googleMap?.googleMap.fitBounds(bounds, 10);

    this.cdr.detectChanges();
  }

  updatePolygon(index: number) {
    if (!this.hasGeoLocationValue) {
      return;
    }
    const rows = this.coordinatesformGroups.at(index).get("rows").value;

    const lastRow = rows[rows.length - 1];

    if (lastRow.latitude === null || lastRow.longitude === null) {
      return;
    }

    this.vertices.set([]);
    const vertices = rows.map((row) => ({
      lat: +row.latitude,
      lng: +row.longitude,
    }));

    this.vertices.set(vertices);
    if (this.marker) {
      this.marker.setMap(null);
      this.marker = null;
    }

    if (this.polygons[index]) {
      this.polygons[index].setMap(null);
      this.polygons[index] = null;
    }

    const polygon = new google.maps.Polygon({
      paths: this.vertices(),
      ...this.DEFAULT_POLYGON_OPTIONS,
    });

    polygon.setMap(this.googleMap?.googleMap);
    this.polygons[index] = polygon;

    this.markers.forEach((marker) => marker.setMap(null));
    this.markers = [];

    this.removeOverlappingMarkers(vertices);

    this.addMarkersForVertices(vertices);
    if (this.getValidFormGroups().length > 1) {
      const centroid = this.calculateCentroid(vertices);
      const labelMarker = this.addLabelToPolygon(centroid, index);

      this.markers.push(labelMarker);
    }

    const bounds = new google.maps.LatLngBounds();

    this.vertices().forEach((c) => {
      bounds.extend(c);
    });
    this.bounds = bounds;
    this.googleMap?.googleMap.fitBounds(this.bounds, 10);

    this.cdr.detectChanges();
  }

  updateMultiPolygon(shouldClear: boolean = false) {
    const allFormGroupsHaveCoordinates = this.coordinatesformGroups.controls.every((formGroup) => {
      const rows = formGroup.get("rows").value;
      const lastRow = rows[rows.length - 1];

      return lastRow.latitude !== null && lastRow.longitude !== null;
    });

    if (!allFormGroupsHaveCoordinates) {
      return;
    }
    if (shouldClear) {
      this.polygons.forEach((polygon) => polygon.setMap(null));
      this.markers.forEach((marker) => marker.setMap(null));

      this.polygons = [];
      this.markers = [];
    }

    const allVertices = [];
    let polygonIndex = 0;

    this.coordinatesformGroups.controls.forEach((formGroup) => {
      const rows = formGroup.get("rows").value;

      if (rows.length === 1) {
        const singlePoint = {
          lat: +rows[0].latitude,
          lng: +rows[0].longitude,
        };

        const marker = new google.maps.Marker({
          position: singlePoint,
          map: this.googleMap?.googleMap,
          icon: {
            url: this.markerIconUrl,
            scaledSize: new google.maps.Size(40, 40),
          },
        });

        this.markers.push(marker);
        allVertices.push(singlePoint);
      } else if (rows.length > 1) {
        const vertices = rows.map((row) => ({
          lat: +row.latitude,
          lng: +row.longitude,
        }));

        const polygon = new google.maps.Polygon({
          paths: vertices,
          ...this.DEFAULT_POLYGON_OPTIONS,
        });

        polygon.setMap(this.googleMap?.googleMap);
        this.polygons.push(polygon);

        this.removeOverlappingMarkers(vertices);
        this.addMarkersForVertices(vertices);

        if (this.getValidFormGroups().length > 1) {
          const centroid = this.calculateCentroid(vertices);
          const labelMarker = this.addLabelToPolygon(centroid, polygonIndex);

          this.markers.push(labelMarker);
        }

        polygonIndex++;
        allVertices.push(...vertices);
      }
    });

    const bounds = new google.maps.LatLngBounds();

    allVertices.forEach((c) => bounds.extend(c));
    this.googleMap?.googleMap.fitBounds(bounds, 10);
    this.cdr.detectChanges();
  }

  updateFormControls(): void {
    const geojson = this.updateGeoJsonFromCoordinates(
      this.geojson() ?? this.formGroup.controls["geoLocation"].value,
    );

    if (!this.type) {
      this.type = GeoJSONUtils.determineGeometryType(geojson);
    }
    if (!this.type) {
      return;
    }

    if (this.type === GeoJsonTypeEnum.POINT) {
      const rows = this.coordinatesformGroups.at(0).get("rows").value;

      if (rows.length > 0) {
        this.formGroup.controls["gpsX"].setValue(`${rows[0].longitude}`);
        this.formGroup.controls["gpsY"].setValue(`${rows[0].latitude}`);
      } else {
        this.formGroup.controls["gpsX"].setValue(null);
        this.formGroup.controls["gpsY"].setValue(null);
      }
    } else {
      this.formGroup.controls["geoLocation"].setValue(geojson);
    }
  }

  private updateGeoJsonFromCoordinates(originalGeoJson: any): any {
    const features = [];

    this.coordinatesformGroups.controls.forEach((formGroup, index) => {
      const rows = formGroup.get("rows") as FormArray;
      let coordinates = rows.controls.map((row) => [
        +row.get("longitude").value,
        +row.get("latitude").value,
      ]);

      if (coordinates.length === 1) {
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: coordinates[0],
          },
          properties: originalGeoJson?.features[index]?.properties || {},
        });
      } else {
        if (coordinates.length > 2 && !this.isPolygonClosed(coordinates)) {
          coordinates = [...coordinates, coordinates[0]];
        }

        if (
          !this.isPolygonClosed(coordinates) &&
          this.type &&
          this.type !== GeoJsonTypeEnum.POINT &&
          rows.length > 2
        ) {
          this.displayErrorMessage(MapConstants.COORDINATE_FORMAT_ERROR_MESSAGE);
        }

        features.push({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [coordinates],
          },
          properties: originalGeoJson?.features[index]?.properties || {},
        });
      }
    });

    return {
      type: "FeatureCollection",
      features: features,
    };
  }

  public isPolygonClosed(coordinates: number[][]): boolean {
    if (coordinates.length < 3) {
      return false;
    }

    const firstPoint = coordinates[0];
    const lastPoint = coordinates[coordinates.length - 1];

    const epsilon = 0.001;

    const areLongitudesEqual = Math.abs(firstPoint[0] - lastPoint[0]) < epsilon;
    const areLatitudesEqual = Math.abs(firstPoint[1] - lastPoint[1]) < epsilon;

    return areLongitudesEqual && areLatitudesEqual;
  }

  private updateGeoJson(geojson = null): void {
    const updatedGeoJson = this.updateGeoJsonFromCoordinates(geojson ?? this.geojson());

    this.formGroup.controls["geoLocation"].setValue(updatedGeoJson);
  }

  public onDeleteArea(index: number): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to delete this area and all of the coordinates in it? Doing so will potentially result in re-labeling of other areas according to the alphabetical order.`,
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
        confirmButtonColor: "danger",
        cancelButtonText: TextConstants.CANCEL,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.deleteArea(index);
      }
    });
  }

  private deleteArea(index: number): void {
    this.coordinatesformGroups.removeAt(index);

    this.relabelAreas();
    this.selectedTabIndex.set(Math.max(0, index - 1));

    this.onCoordinatesChanged();

    const remainingGroupsCount = this.coordinatesformGroups.length;

    if (remainingGroupsCount === 1) {
      const rows = this.coordinatesformGroups.at(0).get("rows").value;

      if (this.type === GeoJsonTypeEnum.MULTI_POINT && rows.length === 1) {
        this.type = GeoJsonTypeEnum.MULTI_POINT;
        this.updateMultiPoint();
      } else if (rows.length === 1) {
        this.type = GeoJsonTypeEnum.POINT;
        this.updatePoint();
      } else {
        this.type = GeoJsonTypeEnum.POLYGON;
        this.updatePolygon(0);
      }
    } else if (remainingGroupsCount > 1) {
      const allSinglePoints = this.coordinatesformGroups.controls.every(
        (formGroup) => formGroup.get("rows").value.length === 1,
      );

      this.type = allSinglePoints ? GeoJsonTypeEnum.MULTI_POINT : GeoJsonTypeEnum.MULTI_POLYGON;

      if (this.type === GeoJsonTypeEnum.MULTI_POINT) {
        this.updateMultiPoint();
      } else {
        this.updateMultiPolygon(true);
      }
    }

    this.updateGeoJson();
  }

  private relabelAreas(): void {
    this.getValidFormGroups().forEach((_, i) => {
      this.getAreaLabel(i);
    });
  }

  private calculateCentroid(vertices: google.maps.LatLngLiteral[]): google.maps.LatLngLiteral {
    let area = 0;
    let centroidLat = 0;
    let centroidLng = 0;

    const numPoints = vertices.length;

    for (let i = 0; i < numPoints; i++) {
      const current = vertices[i];
      const next = vertices[(i + 1) % numPoints];

      const tempArea = current.lat * next.lng - next.lat * current.lng;

      area += tempArea;

      centroidLat += (current.lat + next.lat) * tempArea;
      centroidLng += (current.lng + next.lng) * tempArea;
    }

    area /= 2;

    centroidLat /= 6 * area;
    centroidLng /= 6 * area;

    return { lat: centroidLat, lng: centroidLng };
  }

  private addLabelToPolygon(
    centroid: google.maps.LatLngLiteral,
    polygonIndex: number,
  ): google.maps.Marker {
    const marker = new google.maps.Marker({
      position: centroid,
      map: this.googleMap?.googleMap,
      label: {
        text: this.getAreaLabel(polygonIndex),
        color: "#000",
        fontSize: "16px",
        fontWeight: "bold",
      },
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0,
      },
    });

    return marker;
  }

  private displayErrorMessage(message: string): void {
    this.errorMessage = message;
    this.errorMessageVisible = true;
  }

  private addMarkersForVertices(vertices: google.maps.LatLngLiteral[]): void {
    const lastIndex = vertices.length - 1;

    vertices.forEach((vertex, index) => {
      if (index === lastIndex && vertices[0].lat === vertex.lat && vertices[0].lng === vertex.lng) {
        return;
      }

      const marker = new google.maps.Marker({
        position: vertex,
        map: this.googleMap?.googleMap,
        label: {
          text: (index + 1).toString(),
          color: "#000",
          fontSize: "12px",
          fontWeight: "bold",
        },
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 0,
        },
      });

      this.markers.push(marker);
    });
  }

  editCoordinates() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Edit coordinates`,
        contentText: $localize`Are you sure you want to edit the coordinates of the location? This will lead to changes in the underlying coordinate file as it will be overridden.`,
        confirmButtonText: $localize`Edit`,
        confirmButtonIcon: "edit",
        cancelButtonText: TextConstants.CANCEL,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.editGeojsonCoordinates.emit();
      }
    });
  }

  private removeOverlappingMarkers(vertices: google.maps.LatLngLiteral[]): void {
    const epsilon = 0.0001; //Margin of error for comparison of coordinates

    if (this.marker) {
      const markerPosition = this.marker.getPosition();
      const isMarkerOverlapping = vertices.some(
        (vertex) =>
          Math.abs(vertex.lat - markerPosition.lat()) < epsilon &&
          Math.abs(vertex.lng - markerPosition.lng()) < epsilon,
      );

      if (isMarkerOverlapping) {
        this.marker.setMap(null);
        this.marker = null;
      }
    }

    const newMarkers = [];

    this.markers.forEach((marker) => {
      const position = marker.getPosition();
      const isOverlapping = vertices.some(
        (vertex) =>
          Math.abs(vertex.lat - position.lat()) < epsilon &&
          Math.abs(vertex.lng - position.lng()) < epsilon,
      );

      if (!isOverlapping) {
        newMarkers.push(marker);
      } else {
        marker.setMap(null);
      }
    });

    this.markers = newMarkers;
  }
}
