import { ChangeDetectionStrategy, Component, computed, signal } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { BaseActionsCellRenderer } from "@shared/cell-renderers/base-actions.cell-renderer";
import { CommonConstants } from "@shared/constants";
import { CellRendererUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  template: `
    <div [class]="cellContainerClass()">
      <div class="cell-value" [class.with-warning]="warningTooltipText()">
        @if (params().value?.length) {
          <div class="tags-container" [class.with-buttons]="buttons().length">
            @for (chip of params().value; track $index) {
              @if (hasLink) {
                <app-form-link
                  class="app-tag"
                  [ngClass]="params()['classParam'] ? getChipClass(chip) : ''"
                  [link]="getChipLink(chip)"
                  [openInNewTab]="params()['openInNewTab'] ?? false"
                >
                  <ng-container
                    [ngTemplateOutlet]="chipContent"
                    [ngTemplateOutletContext]="{ chip, chipText: getChipText(chip) }"
                  ></ng-container>
                </app-form-link>
              } @else {
                <div class="app-tag" [ngClass]="params()['classParam'] ? getChipClass(chip) : ''">
                  <ng-container
                    [ngTemplateOutlet]="chipContent"
                    [ngTemplateOutletContext]="{ chip, chipText: getChipText(chip) }"
                  ></ng-container>
                </div>
              }
            }
            <ng-template #chipContent let-chip="chip" let-chipText="chipText">
              @if (params()["showIcon"] && params()["showIcon"](chip)) {
                <mat-icon [tpIsLazy]="true" [tp]="params()['iconTooltip'] ?? ''">
                  {{ params()["icon"] }}
                </mat-icon>
              }
              <div
                class="text"
                [tpIsLazy]="true"
                [tp]="chipText?.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? chipText : ''"
              >
                {{ chipText }}
              </div>
            </ng-template>
          </div>
        } @else {
          -
        }
      </div>

      @if (warningTooltipText()) {
        <div class="cell-warning">
          <mat-icon
            class="suffix-icon error warn-icon"
            svgIcon="action_pending"
            [tp]="warningTooltipText()"
          ></mat-icon>
        </div>
      }

      <div class="cell-actions">
        @for (button of buttons(); track button) {
          @if (show(button)) {
            <app-button
              stopPropagation
              [tp]="tooltip(button)"
              [tpIsLazy]="true"
              [disabled]="isDisabled(button)"
              (pressed)="button.click && button.click(this.data(), $event)"
              type="icon"
            >
              @if (button.svgIcon) {
                <mat-icon
                  [class]="iconClass(button)"
                  icon-small
                  [svgIcon]="button.svgIcon"
                ></mat-icon>
              } @else {
                <mat-icon [class]="iconClass(button)" icon-small>{{ button.icon }}</mat-icon>
              }
            </app-button>
          }
        }
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ["./shared-styles.scss", "./chips.cell-renderer.scss"],
})
export class ChipsCellRendererComponent
  extends BaseActionsCellRenderer
  implements ICellRendererAngularComp
{
  public readonly MAX_CHIPS_TEXT_LENGTH_TO_SHOW = CommonConstants.MAX_CHIPS_TEXT_LENGTH_TO_SHOW;

  public readonly DEFAULT_CELL_CONTAINER_CLASS = CellRendererUtils.cellContainerClass;

  public hasLink = false;

  public cellContainerClass = signal<string>(this.DEFAULT_CELL_CONTAINER_CLASS);

  agInit(params: ICellRendererParams): void {
    let value = params.value;

    value = !!value && typeof value === "object" && !Array.isArray(value) ? [value] : value; // If single chip, put in an array

    this.params.set({ ...params, value });

    this.hasLink = !!(
      this.params()["linkRoute"] ||
      this.params()["linkRouteIdParam"] ||
      this.params()["linkRouteFn"]
    );

    this.cellContainerClass.set(
      `${this.DEFAULT_CELL_CONTAINER_CLASS} ${this.params()["cellContainerClass"]}`,
    );
  }

  override refresh(): boolean {
    return false;
  }

  warningTooltipText = computed<string>(() => {
    return this.params()["warningTooltipText"];
  });

  getChipClass = (chip: any): string => {
    return CommonUtils.getObjectNestedProperty(this.params()["classParam"], chip);
  };

  getChipText = (chip: any): string => {
    return typeof chip === "string"
      ? chip
      : CommonUtils.getObjectNestedProperty(this.params()["textParam"], chip);
  };

  getChipLink = (chip: any): any => CellRendererUtils.getLink(this.params(), chip);
}
