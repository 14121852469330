import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TextConstants } from "@shared/constants";
import { IDocument, ISelectOption } from "@shared/interfaces";
import { NotificationService, AuthenticationService, DocumentsService } from "@shared/services";
import { FileUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-add-document-dialog",
  templateUrl: "./add-document-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddDocumentDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = null;

  public isLoading = signal(true);

  public fileExtension: string = "";

  public fileName: string = "";

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    typeLabel: TextConstants.TYPE,
  };

  constructor(
    private dialogRef: MatDialogRef<AddDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      documentTypesOptions: ISelectOption[];
      document?: IDocument;
      inboundDocumentUri?: string;
      selectedDocumentType?: ISelectOption;
    },
    private documentsService: DocumentsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.fileExtension = FileUtils.getFileExtension(this.data?.document?.name);
    this.fileName = FileUtils.getFileNameWithoutExtension(this.data?.document?.name);
    const entityExistsValidatorArgs = {
      fileExtension: this.fileExtension.toLowerCase(),
    };

    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.fileName,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.documentsService,
            null,
            entityExistsValidatorArgs,
          ),
        ],
      ),
      type: new UntypedFormControl(this.data?.selectedDocumentType, [CustomValidators.required]),
    });
    this.isLoading.set(false);
  }

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    try {
      const payload: any = {
        name: this.formGroup.controls["name"].value.trim() + `.${this.fileExtension}`,
        type: `/organisations/${this.authenticationService.getActiveOrganisationId()}/document-types/${this.formGroup.controls["type"].value.value}`,
      };
      const newDocument = await this.documentsService.createOrUpdate(payload);

      await this.documentsService.transferInboundBinaryData(
        this.data?.inboundDocumentUri,
        newDocument.id,
      );
      this.notificationService.showSuccess($localize`Document created`);
      this.onClose(true, newDocument);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false, newDocument?: IDocument): void => {
    this.dialogRef.close({ hasSaved, newDocument });
  };
}
