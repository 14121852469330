import { ChangeDetectionStrategy, Component, input } from "@angular/core";

import { AgGridAngular } from "ag-grid-angular";
import { uniq } from "lodash";

import { DeliverySupplyChainDocumentsModel } from "@components/deliveries/pages/delivery-overlay/delivery-supply-chain-documents/delivery-supply-chain-documents.model";
import { DocumentsTableComponent } from "@components/shared";
import { IDocumentToDownload } from "@shared/interfaces";
import { FileUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-delivery-supply-chain-documents-table",
  templateUrl: "./delivery-supply-chain-documents-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliverySupplyChainDocumentsTableComponent extends DocumentsTableComponent {
  public deliveryId = input.required<string>();

  public override documents =
    input.required<DeliverySupplyChainDocumentsModel.IDocumentRowData[]>();

  public override rowData: DeliverySupplyChainDocumentsModel.IDocumentRowData[] = [];

  public override readonly rowGroupColumns: string[] = ["targetType", "targetName"];

  public downloadAll = async (grid: AgGridAngular): Promise<void> => {
    const filename = `${FileUtils.getFileNameWithoutInvalidCharacters(this.deliveryId())}.zip`;
    const documentToDownload: IDocumentToDownload = {
      id: filename,
      name: filename,
      uris: uniq(
        grid.rowData
          .filter((d) => !!d.contentType)
          .map((d) => `/organisations/${this.activeOrganisationId}/documents/${d.id}`),
      ),
    };

    this.downloadDocumentsService.add(documentToDownload);
  };
}
