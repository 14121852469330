import { KeyValue } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";

import { LocationOverlayService } from "@components/locations/pages/location-overlay/location-overlay.service";
import { ICertificateExtendedWithLocation } from "@components/reports/delivery-report/location-details/delivery-report-certificates-summary.component";
import { DeliveryReportLocationTypeGroupModel as Model } from "@components/reports/delivery-report/location-details/delivery-report-location-type-group.model";
import {
  LinkCellRendererComponent,
  ValueWithTooltipCellRendererComponent,
} from "@shared/cell-renderers";
import { AttachmentTargetEnum } from "@shared/enums";
import { IDocumentType, ILocationExtended } from "@shared/interfaces";
import { DocumentTypesService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-delivery-report-location-type-group",
  templateUrl: "./delivery-report-location-type-group.component.html",
  styleUrls: ["./delivery-report-location-type-group.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportLocationTypeGroupComponent implements OnInit {
  public locationsGroupByType = input<KeyValue<string, ILocationExtended[]>>();

  public certificatesWithLocation: ICertificateExtendedWithLocation[] = [];

  public readonly columns: string[] = [
    "location",
    "number",
    "standard.name",
    "standardType.fullName",
    "issuanceDate",
    "validFromDate",
    "validToDate",
  ];

  public documentsSummaryColumnDefs: ColDef[];

  public allDocumentTypes: IDocumentType[] = [];

  public isLoading = signal<boolean>(true);

  public displayDocumentSummariesTable = signal<boolean>(false);

  public locationsWithMissingDocumentTypes: Model.ILocationWithMissingDocumentTypes[] = [];

  public documentsSummaryRowData: Model.IDocumentSummaryRowData[];

  private documentTypesService: DocumentTypesService = inject(DocumentTypesService);

  private locationOverlayService: LocationOverlayService = inject(LocationOverlayService);

  private routerService: RouterService = inject(RouterService);

  public async ngOnInit(): Promise<void> {
    const locationsWithMissingDocumentTypes: Model.ILocationWithMissingDocumentTypes[] =
      this.locationsGroupByType().value.flatMap((location) => ({
        location,
        missingDocumentTypesByRuleSet: [],
      }));

    this.certificatesWithLocation = locationsWithMissingDocumentTypes.flatMap((locationInfo) =>
      locationInfo.location.certificates.map((certificate) => ({
        ...certificate,
        location: locationInfo.location,
      })),
    );

    this.allDocumentTypes = await this.documentTypesService.getAll();

    const rulesetPromises = locationsWithMissingDocumentTypes.map(async (locationInfo) => {
      locationInfo.missingDocumentTypesByRuleSet =
        await this.locationOverlayService.allMissingDocumentTypesByRuleSet(
          AttachmentTargetEnum.LOCATION,
          locationInfo.location.id,
          this.allDocumentTypes,
        );
    });

    await Promise.all(rulesetPromises);

    this.locationsWithMissingDocumentTypes = locationsWithMissingDocumentTypes;

    this.documentsSummaryColumnDefs = [
      {
        colId: "location",
        field: "location.name",
        headerName: $localize`Location`,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "location.id",
          linkRouteFn: this.routerService.getLocationLink,
        },
      },
    ];

    this.documentsSummaryRowData = locationsWithMissingDocumentTypes.map((locationInfo) => {
      return this.getDocumentSummaryRowValuesForLocation(locationInfo);
    });

    this.displayDocumentSummariesTable.set(this.documentsSummaryColumnDefs.length > 1);

    this.isLoading.set(false);
  }

  private addDocumentsSummaryColumnDef(fieldName: string): void {
    fieldName = fieldName === "location" ? "location document type" : fieldName;

    if (this.documentsSummaryColumnDefs.some((columnDef) => columnDef.field === fieldName)) {
      return;
    }

    this.documentsSummaryColumnDefs.push({
      field: fieldName,
      valueGetter: (cell: { data: Model.IDocumentSummaryRowData }) => {
        const cellData = cell.data[fieldName] as Model.IDocumentSummaryCellData;

        return cellData?.document?.name;
      },
      cellRendererSelector: (cell: { data: Model.IDocumentSummaryRowData }) => {
        const cellData = cell.data[fieldName] as Model.IDocumentSummaryCellData;

        if (cellData?.displayWarning) {
          return {
            component: ValueWithTooltipCellRendererComponent,
            params: {
              shouldDisplayIcon: () => true,
              icon: "warning",
              iconTooltip: $localize`Document of this type is required but is missing.`,
            },
          };
        }

        return {
          component: LinkCellRendererComponent,
          params: {
            linkRouteIdParam: `${fieldName}.document.id`,
            linkRouteFn: this.routerService.getDocumentLink,
          },
        };
      },
    });
  }

  private getDocumentSummaryRowValuesForLocation(
    locationWithMissingDocumentTypes: Model.ILocationWithMissingDocumentTypes,
  ): Model.IDocumentSummaryRowData {
    const documentSummary: Model.IDocumentSummaryRowData = {};

    const location = locationWithMissingDocumentTypes.location;

    location.documents.forEach((document) => {
      this.addDocumentsSummaryColumnDef(document.typeName);
      documentSummary[document.typeName] = { document, displayWarning: false };
    });

    locationWithMissingDocumentTypes.missingDocumentTypesByRuleSet.forEach(
      (missingDocumentType) => {
        this.addDocumentsSummaryColumnDef(missingDocumentType.name);
        documentSummary[missingDocumentType.name] = { document: null, displayWarning: true };
      },
    );

    return { location, ...documentSummary };
  }
}
