import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  OnDestroy,
  signal,
  viewChild,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";

import { Subscription } from "rxjs";

import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { RelatedRiskAssessmentReportsTableComponent } from "@components/shared/risk-assessment-reports/ui/related-risk-assessment-reports-table.component";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { ISelectOption } from "@shared/interfaces";
import { CommonService, NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

import {
  AddRiskAssessmentReportDialogComponent,
  AddRiskAssessmentReportDialogData,
} from "./add-risk-assessment-report-dialog.component";
import { RiskAssessmentReportsTableComponent } from "./risk-assessment-reports-table.component";
import { RiskAssessmentReportsApiService } from "../api";
import { RelatedRiskAssessmentReportsTableModel, RiskAssessmentReport } from "../models";

@Component({
  standalone: false,
  selector: "app-overlay-risk-assessment-reports",
  templateUrl: "./overlay-risk-assessment-reports.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayRiskAssessmentReportsComponent implements OnDestroy {
  private readonly commonService = inject(CommonService);

  private readonly dialog = inject(MatDialog);

  private readonly notification = inject(NotificationService);

  private readonly reportsApi = inject(RiskAssessmentReportsApiService);

  private readonly router = inject(RouterService);

  readonly overlay = inject(SlideOverlayPageService);

  readonly overlayService = input.required<OverlayCommonService>();

  readonly activeOrganisationId = input.required<string>();

  readonly recordName = input.required<string>();

  readonly recordUri = input.required<string>();

  readonly resourceType = input.required<RiskAssessmentTemplateResourceType>();

  readonly relatedRiskAssessmentReportTableParams =
    input<RelatedRiskAssessmentReportsTableModel.Params>();

  readonly relatedTable = viewChild(RelatedRiskAssessmentReportsTableComponent);

  readonly table = viewChild(RiskAssessmentReportsTableComponent);

  readonly translations: any = {
    relatedRecordsTp: $localize`Display all of the latest completed risk assessments of records related to this record.`,
  };

  countryOptions: ISelectOption[] = [];

  dialogRef: MatDialogRef<AddRiskAssessmentReportDialogComponent>;

  isLoadingRelatedRiskAssessmentReports = signal(true);

  relatedRiskAssessmentReports = signal<
    RelatedRiskAssessmentReportsTableModel.RelatedRiskAssessmentReport[]
  >([]);

  subscriptions = new Subscription();

  currentRecordCount = computed(() => {
    if (this.table().isLoading()) {
      return undefined;
    }

    return this.table().totalElements();
  });

  relatedRecordsCount = computed(() => {
    if (!this.relatedTable() || this.relatedTable().isLoading()) {
      return undefined;
    }

    return this.relatedTable().totalElements();
  });

  constructor() {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  openDialog() {
    this.dialogRef = this.dialog.open<
      AddRiskAssessmentReportDialogComponent,
      AddRiskAssessmentReportDialogData
    >(AddRiskAssessmentReportDialogComponent, {
      data: {
        orgId: this.activeOrganisationId(),
        resourceType: this.resourceType(),
        onSubmit: (template) => this.submit(template),
      },
      autoFocus: "dialog",
    });
  }

  async submit(template: string) {
    try {
      const report = await this.reportsApi.create({ template, recordUris: [this.recordUri()] });

      this.notification.showSuccess($localize`Risk assessment report created`);

      await this.table().getAll();

      this.overlayService().reportsCounter.set(this.table().totalElements());
      this.dialogRef.close();
      this.openReport(report, true);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  openReport(report: RiskAssessmentReport, isEdit?: boolean) {
    this.router.navigate(
      this.router.getRiskAssessmentReportLink(report.id, true, {
        name: this.recordName(),
        edit: isEdit,
      }),
    );
  }
}
