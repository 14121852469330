import { CdkDragDrop } from "@angular/cdk/drag-drop";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ConfirmDialogComponent } from "src/app/components/shared";
import { CommonConstants, TextConstants } from "src/app/shared/constants";
import {
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
} from "src/app/shared/enums";
import { IAttachment, IAttachmentPayload, IProduct } from "src/app/shared/interfaces";
import {
  AttachmentsService,
  AuthenticationService,
  ProductsService,
} from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-related-products",
  templateUrl: "./related-products.component.html",
  styleUrl: "./related-products.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedProductsComponent implements OnChanges, AfterViewInit {
  @ViewChild("attachmentsRightMenu") attachmentsRightMenu: TemplateRef<unknown>;

  @Input()
  public productId: string;

  @Output()
  public relatedProductsChanged = new EventEmitter<void>();

  public selectedTabIndex = 0;

  public isLoading = signal(true);

  public isLoadingProducts = signal(true);

  public availableProducts: IProduct[] = [];

  public createdFromProducts: IProduct[] = [];

  public usedInProducts: IProduct[] = [];

  public searchAvailableText: string = undefined;

  private createdFromAttachments: IAttachment[] = [];

  private usedInAttachments: IAttachment[] = [];

  private allProducts: IProduct[] = [];

  private filteredProducts: IProduct[] = [];

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly cardContentTypeEnum = CardContentTypeEnum;

  public readonly translations: any = {
    relatedProductsTp: $localize`Specify how this product is related with other products.`,
    addNewTp: TextConstants.ADD_NEW,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
    private routerService: RouterService,
    private dialog: MatDialog,
    private attachmentsService: AttachmentsService,
    private productsService: ProductsService,
    private overlay: SlideOverlayPageService,
  ) {}

  public ngAfterViewInit(): void {
    this.overlay.openSideMenu(this.attachmentsRightMenu);
  }

  public async ngOnChanges(simpleChanges: SimpleChanges): Promise<void> {
    if (simpleChanges["productId"] && this.productId) {
      await this.reloadProductsData(true);
    }
  }

  private reloadProductsData = async (loadAll: boolean = false): Promise<void> => {
    await this.onReloadProducts();
    await this.onReloadRelatedProducts(loadAll);
  };

  public onTabChanged = async (id: number): Promise<void> => {
    this.selectedTabIndex = id;

    await this.reloadProductsData();
  };

  private async loadCreatedFromProducts(productUri: string): Promise<void> {
    this.createdFromAttachments = await this.attachmentsService.getAll(
      this.attachmentTypeEnum.PRODUCT,
      productUri,
    );
    await this.setCreatedFromProducts();
  }

  private async loadUsedInProducts(productUri: string): Promise<void> {
    this.usedInAttachments = await this.attachmentsService.getAll(
      this.attachmentTypeEnum.PRODUCT,
      null,
      productUri,
    );
    await this.setUsedInProducts();
  }

  private onReloadRelatedProducts = async (loadAll: boolean = false): Promise<void> => {
    this.isLoading.set(true);

    const productUri = `/organisations/${this.activeOrganisationId}/products/${this.productId}`;

    try {
      if (loadAll) {
        await Promise.all([
          this.loadCreatedFromProducts(productUri),
          this.loadUsedInProducts(productUri),
        ]);
      } else {
        switch (this.selectedTabIndex) {
          case 0:
            await this.loadCreatedFromProducts(productUri);
            break;
          case 1:
            await this.loadUsedInProducts(productUri);
            break;
        }
      }

      this.isLoading.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.availableProducts = this.getAvailableProducts();
    }
  };

  //TODO: once with the graphQl query, this should not be needed.
  public setCreatedFromProducts = async () => {
    const createdFromAttachmentsIds = this.createdFromAttachments.map((attachment) =>
      CommonUtils.getUriId(attachment.attachmentUri),
    );

    this.createdFromProducts = this.allProducts.filter((p) =>
      createdFromAttachmentsIds.includes(p.id),
    );
  };

  //TODO: once with the graphQl query, this should not be needed.
  public setUsedInProducts = async () => {
    const usedInAttachmentsIds = this.usedInAttachments.map((attachment) =>
      CommonUtils.getUriId(attachment.targetUri),
    );

    this.usedInProducts = this.allProducts.filter((p) => usedInAttachmentsIds.includes(p.id));
  };

  public onReloadProducts = async (): Promise<void> => {
    this.isLoadingProducts.set(true);
    try {
      this.allProducts = await this.productsService.getAll();
      this.filteredProducts = (
        await this.productsService.getPage(
          this.searchAvailableText || undefined,
          CommonConstants.MAX_API_GET_ITEMS_SIZE,
          0,
          undefined,
          RecordStateEnum.ACTIVE,
        )
      ).content;
      this.availableProducts = this.getAvailableProducts();
      this.isLoadingProducts.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onSearchAvailable = async (value: string): Promise<void> => {
    this.searchAvailableText = value || undefined;
    await this.onReloadProducts();
  };

  public onItemDropped = async (event: CdkDragDrop<IProduct>): Promise<void> => {
    await this.onAdd(event.item.data.id);
  };

  public onAddAll = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${this.availableProducts.length}:count:</b> products?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isLoading.set(true);
        const promises = this.availableProducts.map(async (availableProduct) => {
          await this.onAdd(availableProduct.id, false);
        });

        await Promise.all(promises);

        this.notificationService.showSuccess($localize`Relations added`);

        await this.reloadProductsData();
        this.relatedProductsChanged.emit();
        this.isLoading.set(false);
      }
    });
  };

  public onAdd = async (id: string, isSingleAdd = true): Promise<void> => {
    if (isSingleAdd) {
      this.isLoading.set(true);
    }

    const currentProductUri = `/organisations/${this.activeOrganisationId}/products/${this.productId}`;
    const selectedProductUri = `/organisations/${this.activeOrganisationId}/products/${id}`;

    try {
      let payload: IAttachmentPayload;

      switch (this.selectedTabIndex) {
        case 0: // created from: the target of which is the current product;
          payload = {
            targetUri: currentProductUri,
            attachmentUri: selectedProductUri,
          };
          break;
        case 1: // used in: the attachment of which is the current product;
          payload = {
            targetUri: selectedProductUri,
            attachmentUri: currentProductUri,
          };
          break;
      }
      await this.attachmentsService.create(payload);
      if (isSingleAdd) {
        this.notificationService.showSuccess($localize`Relation added`);
        await this.reloadProductsData();
        this.relatedProductsChanged.emit();
        this.isLoading.set(false);
      }
    } catch (error) {
      if (isSingleAdd) {
        this.notificationService.showError(error);
      }
    }
  };

  public onRemove = async (product: IProduct, isSingleRemove = true): Promise<void> => {
    this.isLoading.set(true);
    let attachmentToDelete: IAttachment;

    try {
      switch (this.selectedTabIndex) {
        case 0:
          attachmentToDelete = this.createdFromAttachments.find((attachment) =>
            attachment.attachmentUri.includes(product.id),
          );
          break;
        case 1:
          attachmentToDelete = this.usedInAttachments.find((attachment) =>
            attachment.targetUri.includes(product.id),
          );
          break;
      }
      await this.attachmentsService.delete(attachmentToDelete.id);
      if (isSingleRemove) {
        this.notificationService.showSuccess($localize`Relation removed`);
        await this.reloadProductsData();
      }
      this.relatedProductsChanged.emit();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onAddNewProduct = async (): Promise<void> => {
    this.routerService.openNewTab(this.routerService.getProductLink(undefined, false));
  };

  public getAvailableProducts = () => {
    return this.filteredProducts.filter((currentProduct) => {
      if (currentProduct.id !== this.productId) {
        switch (this.selectedTabIndex) {
          case 0:
            if (!this.createdFromProducts.some((p) => p.id === currentProduct.id)) {
              return currentProduct;
            }
            break;
          case 1:
            if (!this.usedInProducts.some((p) => p.id === currentProduct.id)) {
              return currentProduct;
            }
            break;
        }
      }

      return false;
    });
  };
}
