import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  standalone: false,
  selector: "[appNoScrollInput]",
})
export class NoScrollInputDirective {
  @Input()
  noScrollInputTypes: string[] = [];

  @HostListener("wheel", ["$event"])
  @HostListener("scroll", ["$event"])
  onWheel(event: WheelEvent | Event): void {
    if (
      !this.noScrollInputTypes.length ||
      this.noScrollInputTypes.includes((event.target as HTMLInputElement).type)
    ) {
      event.preventDefault();
      if (event["deltaY"]) {
        // Manually handle the scrolling of the page
        window.scrollBy(0, event["deltaY"]);
      }
    }
  }
}
