<mat-dialog-content class="no-max-height">
  @if (isOpened()) {
    @switch (flowChartType) {
      @case (flowChartTypeEnum.SUPPLY_CHAIN) {
        <app-supply-chain-flow-chart
          svgId="node-svg-fullscreen"
          [containerClass]="data.containerClass"
          [locations]="data.locations"
          [allDocumentTypes]="data.allDocumentTypes"
          [locationsRulesetsRecords]="data.locationsRulesetsRecords"
          [fullScreenOptionEnabled]="false"
          [links]="data.links"
          [height]="supplyChainHeight"
        ></app-supply-chain-flow-chart>
      }
      @case (flowChartTypeEnum.ITEM_SUPPLY_CHAIN) {
        <app-items-supply-chain
          svgId="node-svg-fullscreen"
          [height]="supplyChainHeight"
          [itemIds]="data.itemIds"
          [fullScreenOptionEnabled]="false"
          [containerClass]="data.containerClass"
          [allProcessTypes]="data.allProcessTypes"
          [inboundSharedSenderOrgId]="data.inboundSharedSenderOrgId"
          [isInboundShared]="data.isInboundShared"
        ></app-items-supply-chain>
      }
      @case (flowChartTypeEnum.PRODUCT_SUPPLY_CHAIN) {
        <app-product-supply-chain
          svgId="node-svg-fullscreen"
          [height]="supplyChainHeight"
          [productId]="data.productId"
          [shouldIncludeLocationsLinks]="data.shouldIncludeLocationsLinks"
          [shouldIncludeDocuments]="data.shouldIncludeDocuments"
          [shouldHandleProductReferences]="data.shouldHandleProductReferences"
          [fullScreenOptionEnabled]="false"
          [containerClass]="data.containerClass"
          [activeOrganisationId]="data.activeOrganisationId"
        ></app-product-supply-chain>
      }
    }
  }
</mat-dialog-content>
<mat-dialog-actions class="close-button">
  <app-button-group>
    <app-button (pressed)="onClose()" type="ghost" [tp]="translations.closeTp">X</app-button>
  </app-button-group>
</mat-dialog-actions>
