import { AsyncPipe, CommonModule, DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ErrorHandler, LOCALE_ID, NgModule, inject, provideAppInitializer } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { Router, RouterModule } from "@angular/router";

import {
  TippyDirective,
  popperVariation,
  provideTippyConfig,
  tooltipVariation,
} from "@ngneat/helipopper";
import * as Sentry from "@sentry/angular";
import { TraceService } from "@sentry/angular";
import { AgGridModule } from "ag-grid-angular";
import { LicenseManager } from "ag-grid-enterprise";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { QuillModule } from "ngx-quill";
import { ToastrModule } from "ngx-toastr";

import { ShareComponentsModule } from "@components/shared/share-components.module";
import { DefaultRouteGuard, LoggedInGuard, NotLoggedInGuard } from "@shared/guards";
import { ErrorInterceptor, JwtInterceptor } from "@shared/interceptors";
import { AppDatePipe, LocalTimePipe } from "@shared/pipes";
import { AppInitService } from "@shared/services";
import { LocalizationUtils } from "@shared/utils";

import { AngularMaterialModule } from "./angular-material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    TippyDirective,
    ShareComponentsModule,
    AngularMaterialModule,
    AgGridModule,
    PdfViewerModule,
    ToastrModule.forRoot({
      maxOpened: 5,
      autoDismiss: false,
      positionClass: "toast-bottom-left",
      closeButton: true,
      timeOut: 5000,
      newestOnTop: false,
      disableTimeOut: "extendedTimeOut",
      extendedTimeOut: 5000,
    }),
    QuillModule.forRoot({
      sanitize: true,
      modules: {
        toolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"],
          ["clean"],
        ],
      },
    }),
  ],
  providers: [
    AppDatePipe,
    LocalTimePipe,
    DecimalPipe,
    AsyncPipe,
    { provide: "Window", useValue: window },
    LoggedInGuard,
    NotLoggedInGuard,
    DefaultRouteGuard,
    { provide: LOCALE_ID, useValue: LocalizationUtils.getLocaleForAngularConfig() },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    provideAppInitializer(() => {
      const initializerFn = ((appInitService: AppInitService) => {
        return async (): Promise<void> => await appInitService.init();
      })(inject(AppInitService));

      return initializerFn();
    }),
    provideTippyConfig({
      defaultVariation: "whiteTooltip",
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        whiteTooltip: {
          ...tooltipVariation,
          theme: "white",
          arrow: true,
        },
        whiteTooltipNoArrow: {
          ...tooltipVariation,
          theme: "white",
          arrow: false,
        },
      },
    }),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
  ],
})
export class AppModule {
  constructor() {
    LicenseManager.setLicenseKey(
      "Using_this_{AG_Grid}_Enterprise_key_{AG-055860}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{iov42_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Interu}_only_for_{4}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Interu}_need_to_be_licensed___{Interu}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_March_2025}____[v3]_[01]_MTc0MzI5MjgwMDAwMA==a7a76da6410df5a4184925a1127ed7c1",
    );
  }
}
