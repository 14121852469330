@if (showTags && !isLoading() && isEditing()) {
  <div class="edit-tags-container">
    <div class="form-field-item">
      <div class="form-field-tags" matAutocompleteOrigin #origin="matAutocompleteOrigin">
        <mat-chip-grid #chipGrid>
          <mat-icon
            class="tag-icon"
            [class.has-tags]="selectedTags.length"
            [tp]="translations.tagsTp"
          >
            sell
          </mat-icon>
          @for (selectedTag of selectedTags; track selectedTag) {
            <mat-chip-row
              class="tag-chip {{ selectedTag.tagDefinition.color }}"
              [editable]="false"
              [tp]="
                selectedTag.tagDefinition.title.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW
                  ? selectedTag.tagDefinition.title
                  : ''
              "
              (removed)="onRemove(selectedTag)"
            >
              <div class="text">
                {{ selectedTag.tagDefinition.title }}
              </div>
              <mat-icon
                class="edit-tag-icon clickable"
                matChipTrailingIcon
                [tp]="translations.editTp"
                (click)="onEdit(selectedTag.tagDefinition)"
              >
                edit
              </mat-icon>
              @if (canRemove) {
                <button matChipRemove [tp]="translations.removeTp">
                  <mat-icon>cancel</mat-icon>
                </button>
              }
            </mat-chip-row>
          }
          <input
            [placeholder]="translations.typeNewTagPh"
            #tagInput
            matInput
            type="text"
            appAutocompletePositionUpdate
            [formControl]="formControl"
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matAutocompleteConnectedTo]="origin"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [matChipInputAddOnBlur]="false"
            (matChipInputTokenEnd)="onAddNew($event)"
          />

          <mat-icon
            class="exit-edit-mode-icon green clickable"
            [tp]="translations.doneTp"
            (click)="onToggleEditMode()"
          >
            check
          </mat-icon>
        </mat-chip-grid>
      </div>
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event)"
        class="dropdown-input-menu"
      >
        @for (tag of filteredTagsDefinitions | async; track tag) {
          <mat-option [value]="tag.id">
            <div
              class="tag-chip {{ tag.color }}"
              [tp]="tag.title.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW ? tag.title : ''"
              [tpIsLazy]="true"
            >
              <mat-icon
                class="edit-dropdown-option-icon"
                [tp]="translations.editTp"
                (click)="$event.stopPropagation(); onEdit(tag)"
              >
                edit
              </mat-icon>
              {{ tag.title }}
            </div>
          </mat-option>
        }
      </mat-autocomplete>
    </div>
  </div>
} @else {
  <div
    class="view-tags-container clickable container-space-between hover-visibility-container"
    (click)="onToggleEditMode()"
    (keyup)="onToggleEditMode()"
    tabindex="0"
  >
    <div class="container-flex">
      <mat-icon class="tag-icon" [tp]="translations.tagsTp">sell</mat-icon>
      @if (selectedTags?.length) {
        <mat-chip-grid #chipGrid>
          @for (selectedTag of selectedTags; track selectedTag) {
            <mat-chip-row
              class="tag-chip {{ selectedTag.tagDefinition.color }}"
              [tp]="
                selectedTag.tagDefinition.title.length > MAX_CHIPS_TEXT_LENGTH_TO_SHOW
                  ? selectedTag.tagDefinition.title
                  : ''
              "
              [tpIsLazy]="true"
            >
              {{ selectedTag.tagDefinition.title }}
            </mat-chip-row>
          }
        </mat-chip-grid>
        <input class="hide" [matChipInputFor]="chipGrid" />
      } @else {
        <ng-container i18n>Click to add tags</ng-container>
      }
    </div>
    <mat-icon class="on-hover-visibility-show edit-icon" [tp]="translations.editTagsTp">
      edit
    </mat-icon>
  </div>
}
