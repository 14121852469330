import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatAccordionTogglePosition } from "@angular/material/expansion";

import { IDocumentType, ILocationType, IStandard } from "src/app/shared/interfaces";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-supply-chain-locations",
  templateUrl: "./supply-chain-locations.component.html",
  styleUrls: ["./supply-chain-locations.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainLocationsComponent {
  @Input()
  public locations: any[] = [];

  @Input()
  public allStandards?: IStandard[] = [];

  @Input()
  public allDocumentTypes?: IDocumentType[] = [];

  @Input()
  public isShowCertificates = false;

  @Input()
  public isShowDocuments = false;

  @Input()
  public isShowLinks = false;

  @Input()
  public isShowTableSelectCheckbox = false;

  @Input()
  public areCertificatesButtonsEnabled = false;

  @Input()
  public expandArrowPosition: MatAccordionTogglePosition = "after";

  @Input()
  public canRemove = false;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  @Output()
  public selectedCertificatesChanged: EventEmitter<any> = new EventEmitter();

  @Output()
  public selectedDocumentsChanged: EventEmitter<any> = new EventEmitter();

  public readonly POINT_OF_ORIGIN_CHIP = TextConstants.POINT_OF_ORIGIN_CHIP;

  public readonly translations: any = {
    removeLocationTp: $localize`Remove location`,
  };

  buildTag(label: string): InputSelectOption {
    return { label };
  }

  buildLocationTypeTag = (locationType: ILocationType): InputSelectOption => ({
    label: locationType.type,
    value: locationType.type,
    icon: locationType.pointOfOrigin ? "target" : undefined,
    iconTooltip: locationType.pointOfOrigin ? TextConstants.POINT_OF_ORIGIN_CHIP : undefined,
  });

  public onRemove = (locationId: string): void => {
    this.remove.emit(locationId);
  };

  public onSetCertificates = (location: any, certificates: any[]): void => {
    location.certificates = certificates;
    this.selectedCertificatesChanged.emit(this.locations);
  };

  public onSetDocuments = (location: any, documents: any[]): void => {
    location.documents = documents;
    this.selectedDocumentsChanged.emit(this.locations);
  };
}
