<div class="container-space-between">
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>

  <app-button-group [visible]="!isLoading() && authenticationService.canAddModifyEntities()">
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-card class="active-organisation" (click)="onViewDetails(activeOrganisation.id)">
    <div class="details">
      <div class="title" i18n>Your organisation</div>
      <div class="subtitle">
        {{ activeOrganisation.name }}
        <app-flag [country]="activeOrganisation.address.country"></app-flag>
        {{ activeOrganisation.address.countryName }}
      </div>
    </div>
  </mat-card>

  <app-connections-table
    [recordState]="recordStateEnum.ACTIVE"
    [isSaveTableState]="true"
    [isFixedBottomPaginator]="true"
    [isBatchActionsEnabled]="true"
    [allowClickOnNameRow]="false"
  ></app-connections-table>
}
