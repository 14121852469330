import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params } from "@angular/router";

import { combineLatest, filter, tap } from "rxjs";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { TextConstants } from "@shared/constants";
import { CrossOrgShareDataTypeEnum, RouteEnum } from "@shared/enums";
import {
  ICheckExistenceRecord,
  IDeliveryExtended,
  IDocument,
  IDocumentType,
  IInboundMapping,
  IInboundShare,
  IItemExtended,
  ILocationExtended,
  ILocationType,
  IMaterialExtended,
  IOrganisation,
  IProcess,
  IProcessType,
  IProductExtended,
  ISelectOption,
} from "@shared/interfaces";
import {
  NotificationService,
  RecordSharingService,
  LocationTypesService,
  DocumentTypesService,
  ConnectionsService,
  DocumentsService,
  LocationsService,
  CommonService,
  DeliveriesService,
  CertificatesService,
  AuthenticationService,
  MaterialsService,
  ProductsService,
  ItemsService,
  ProcessTypesService,
  ProcessesService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

import { ITransferOrMapFormConfig } from "../../transfer-or-map-config.interface";
import { UnmappedDependenciesService } from "../../unmapped-dependencies.service";

@Component({
  standalone: false,
  templateUrl: "./transfer-or-map-data.component.html",
  styleUrl: "./transfer-or-map-data.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransferOrMapDataComponent implements OnInit {
  private recordSharingService = inject(RecordSharingService);

  private locationTypesService = inject(LocationTypesService);

  private documentTypesService = inject(DocumentTypesService);

  private documentsService = inject(DocumentsService);

  private connectionsService = inject(ConnectionsService);

  private locationsService = inject(LocationsService);

  private deliveriesService = inject(DeliveriesService);

  private certificatesService = inject(CertificatesService);

  private materialsService = inject(MaterialsService);

  private productsService = inject(ProductsService);

  private itemsService = inject(ItemsService);

  private notification = inject(NotificationService);

  private route = inject(ActivatedRoute);

  private destroyRef = inject(DestroyRef);

  private commonService = inject(CommonService);

  public unmappedDependenciesService = inject(UnmappedDependenciesService);

  private authenticationService = inject(AuthenticationService);

  private processTypesService = inject(ProcessTypesService);

  private processesService = inject(ProcessesService);

  public locationTypesOptions = signal<InputSelectOption[]>([]);

  public documentTypesOptions = signal<InputSelectOption[]>([]);

  public processTypesOptions = signal<InputSelectOption[]>([]);

  public processesOptions = signal<InputSelectOption[]>([]);

  public documentsOptions = signal<InputSelectOption[]>([]);

  public connectionsOptions = signal<InputSelectOption[]>([]);

  public locationsOptions = signal<InputSelectOption[]>([]);

  public deliveriesOptions = signal<InputSelectOption[]>([]);

  public certificatesOptions = signal<InputSelectOption[]>([]);

  public materialsOptions = signal<InputSelectOption[]>([]);

  public productsOptions = signal<InputSelectOption[]>([]);

  public itemsOptions = signal<InputSelectOption[]>([]);

  public sharedLocationTypes = signal<ILocationType[]>([]);

  public sharedDocumentTypes = signal<IDocumentType[]>([]);

  public sharedDocuments = signal<IDocument[]>([]);

  public sharedLocations = signal<ILocationExtended[]>([]);

  public sharedConnections = signal<IOrganisation[]>([]);

  public sharedDeliveries = signal<IDeliveryExtended[]>([]);

  public sharedCertificates = signal<IDeliveryExtended[]>([]);

  public sharedMaterials = signal<IMaterialExtended[]>([]);

  public sharedProducts = signal<IProductExtended[]>([]);

  public sharedItems = signal<IItemExtended[]>([]);

  public sharedProcessTypes = signal<IProcessType[]>([]);

  public sharedProcesses = signal<IProcess[]>([]);

  public countryOptions = signal<ISelectOption[]>([]);

  public isLoading = signal(false);

  public readonly inboundShareDataTypeEnum = CrossOrgShareDataTypeEnum;

  public readonly routingEnum = RouteEnum;

  private senderId: string;

  public inboundShare: IInboundShare;

  public hasUnmappedLocationDependencies = signal<boolean>(false);

  public hasUnmappedDeliveryDependencies = signal<boolean>(false);

  public hasUnmappedDocumentDependencies = signal<boolean>(false);

  public hasUnmappedItemDependencies = signal<boolean>(false);

  public hasUnmappedProductDependencies = signal<boolean>(false);

  public hasUnmappedProcessDependencies = signal<boolean>(false);

  public existingMappings: IInboundMapping[];

  private inboundLocationTypesIds: string[] = [];

  private inboundItemsIds: string[] = [];

  private inboundMaterialsIds: string[] = [];

  private inboundProductsIds: string[] = [];

  private inboundDocumentTypesIds: string[] = [];

  private inboundProcessTypesIds: string[] = [];

  private inboundConnectionsIds: string[] = [];

  private inboundLocationsIds: string[] = [];

  private inboundCertificatesIds: string[] = [];

  private existingRecords = signal<ICheckExistenceRecord>(null);

  configs: ITransferOrMapFormConfig[] = [];

  newRecordsConfig = signal<ITransferOrMapFormConfig[]>([]);

  existingRecordsConfig = signal<ITransferOrMapFormConfig[]>([]);

  mappedRecordsConfig = signal<ITransferOrMapFormConfig[]>([]);

  public readonly translations: any = {
    title: $localize`Transfer or map received data`,
    titleBackText: TextConstants.BACK_TO_INBOX,
    existingTp: $localize`The records in this section sent to you by an external organisation already exist in your local records. Please map them to be able to accept the shared data.`,
    newRecordsTp: $localize`The records in this section sent to you by an external organisation don't appear to exist in your local records.
            It is still recommended that you go through them to ensure that they don't simply have slightly different names / IDs or are in a different language before you add them to your records. Simply accepting the share will transfer them to your local records.`,
  };

  async ngOnInit(): Promise<void> {
    combineLatest([
      this.route.params,
      this.commonService.countriesOptionsObservable$.pipe(filter((countries) => !!countries)),
    ])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap(async ([params, countriesOptions]: [Params, ISelectOption[]]) => {
          if (params["id"]) {
            await this.loadData(params["id"]);
            this.generateConfigs();
          }
          this.countryOptions.set(countriesOptions);
        }),
      )
      .subscribe();
  }

  private generateConfigs(): void {
    this.configs = Object.values(this.inboundShareDataTypeEnum)
      .filter((type) => type !== this.inboundShareDataTypeEnum.SUPPLY_CHAINS)
      .map((type) => ({
        title:
          type === CrossOrgShareDataTypeEnum.CONNECTIONS
            ? "Organisations"
            : CommonUtils.enumToText(type).replace("-", " "),
        shareRecordType: type,
        sharedData: this.getSharedDataByType(type),
        localRecords: this.getLocalRecordsByType(type),
        inputSelectPlaceholder: (() => {
          const singularType = CommonUtils.singlifyEntity(type).toLowerCase().replace("-", " ");

          switch (type) {
            case CrossOrgShareDataTypeEnum.ITEMS:
              return $localize`Select an item`;
            case CrossOrgShareDataTypeEnum.CONNECTIONS:
              return $localize`Select an organisation`;
            default:
              return $localize`Select a ${singularType}:type:`;
          }
        })(),
        existingMappings: this.existingMappings,
        reloadFn: this.getReloadFnByType(type),
        additionalProps: this.getAdditionalPropsByType(type),
        isTransferButtonDisabled: this.getIsTransferButtonDisabled(type),
        inboundShare: this.inboundShare,
        unmappedDependenciesTooltip: this.getUnmappedDependenciesTooltip(type),
        isViewButtonDisabled: [
          this.inboundShareDataTypeEnum.LOCATION_TYPES,
          this.inboundShareDataTypeEnum.DOCUMENT_TYPES,
          this.inboundShareDataTypeEnum.PROCESS_TYPES,
        ].includes(type),
      }));
    this.newRecordsConfig.set(this.getNewConfigs());
    this.existingRecordsConfig.set(this.getExistingConfigs());
    this.mappedRecordsConfig.set(this.getMappedConfigs());
  }

  private getUnmappedDependenciesTooltip(type: CrossOrgShareDataTypeEnum) {
    switch (type) {
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        return $localize`This section is locked because you have some organisations and/or location types not mapped. Transfer or map them to unlock this section.`;
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        return $localize`This section is locked because you have some organisations and/or locations not mapped. Transfer or map them to unlock this section.`;
      case CrossOrgShareDataTypeEnum.ITEMS:
        return $localize`This section is locked because you have some products not mapped. Transfer or map them to unlock this section.`;
      case CrossOrgShareDataTypeEnum.PROCESSES:
        return $localize`This section is locked because you have some process types, locations, and/or items not mapped. Transfer or map them to unlock this section.`;
      case CrossOrgShareDataTypeEnum.DOCUMENTS:
        return $localize`This section is locked because you have some document types not mapped. Transfer or map them to unlock this section.`;
      case CrossOrgShareDataTypeEnum.PRODUCTS:
        return $localize`This section is locked because you have some materials not mapped. Transfer or map them to unlock this section.`;
      default:
        return "";
    }
  }

  private getIsTransferButtonDisabled(type: CrossOrgShareDataTypeEnum): boolean {
    switch (type) {
      case CrossOrgShareDataTypeEnum.LOCATIONS:
        return this.hasUnmappedLocationDependencies();
      case CrossOrgShareDataTypeEnum.DELIVERIES:
        return this.hasUnmappedDeliveryDependencies();
      case CrossOrgShareDataTypeEnum.ITEMS:
        return this.hasUnmappedItemDependencies();
      case CrossOrgShareDataTypeEnum.PROCESSES:
        return this.hasUnmappedProcessDependencies();
      case CrossOrgShareDataTypeEnum.DOCUMENTS:
        return this.hasUnmappedDocumentDependencies();
      case CrossOrgShareDataTypeEnum.PRODUCTS:
        return this.hasUnmappedProductDependencies();
      default:
        return false;
    }
  }

  private getReloadFnByType(type: CrossOrgShareDataTypeEnum): () => void {
    const propertyName =
      "reload" +
      type
        .split("-")
        .map((word, index) =>
          index === 0
            ? word.charAt(0).toUpperCase() + word.slice(1)
            : word.charAt(0).toUpperCase() + word.slice(1),
        )
        .join("");

    if (this[propertyName] && typeof this[propertyName] === "function") {
      return this[propertyName].bind(this);
    }

    return () => {};
  }

  private getAdditionalPropsByType(type: CrossOrgShareDataTypeEnum): Record<string, any> {
    switch (type) {
      case this.inboundShareDataTypeEnum.DOCUMENT_TYPES:
        return { countryOptions: this.countryOptions() };
      case this.inboundShareDataTypeEnum.PRODUCTS:
        return { materialsOptions: this.materialsOptions() };
      default:
        return {};
    }
  }

  private getSharedDataByType(type: CrossOrgShareDataTypeEnum): any[] {
    const propertyName =
      "shared" +
      type
        .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())
        .replace(/^./, (letter) => letter.toUpperCase());

    if (this[propertyName]()) {
      return this[propertyName]();
    }

    return [];
  }

  private getLocalRecordsByType(type: CrossOrgShareDataTypeEnum): InputSelectOption[] {
    const propertyName = type.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase()) + "Options";

    if (this[propertyName] && typeof this[propertyName] === "function") {
      return this[propertyName]();
    }

    return [];
  }

  async loadData(id: string) {
    this.isLoading.set(true);
    try {
      this.existingMappings = await this.recordSharingService.getAllMappings();
      await this.reloadOrganisations();

      this.inboundShare = await this.recordSharingService.getInboundShare(id);
      this.senderId = CommonUtils.getUriId(this.inboundShare.senderUri);
      const records = await this.recordSharingService.getCheckExistingRecord(
        this.inboundShare.rootRecordUri,
      );

      this.existingRecords.set(records);
      const allUris = [...this.inboundShare.recordUris, this.inboundShare.rootRecordUri];

      this.inboundLocationTypesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.LOCATION_TYPES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundDocumentTypesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DOCUMENT_TYPES))
        .map((uri) => CommonUtils.getUriId(uri));

      const inboundDocuments = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DOCUMENTS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundConnectionsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.CONNECTIONS))
        .map((uri) => CommonUtils.getUriId(uri));

      const inboundDeliveries = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.DELIVERIES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundCertificatesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.CERTIFICATES))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundLocationsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.LOCATIONS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundMaterialsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.MATERIALS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundProductsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.PRODUCTS))
        .map((uri) => CommonUtils.getUriId(uri));

      this.inboundItemsIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.ITEMS))
        .map((uri) => CommonUtils.getUriId(uri));
      this.checkUnmappedDependencies();

      this.inboundProcessTypesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.PROCESS_TYPES))
        .map((uri) => CommonUtils.getUriId(uri));
      const inboundProcessesIds = allUris
        .filter((uri) => uri.includes(CrossOrgShareDataTypeEnum.PROCESSES))
        .map((uri) => CommonUtils.getUriId(uri));

      if (this.inboundLocationTypesIds?.length) {
        await this.reloadLocationTypes();
        await this.fetchSharedLocationTypes(this.inboundLocationTypesIds);
      }
      if (this.inboundDocumentTypesIds?.length) {
        await this.reloadDocumentTypes();
        await this.fetchSharedDocumentTypes(this.inboundDocumentTypesIds);
      }
      if (this.inboundConnectionsIds?.length) {
        await this.fetchSharedConnections(this.inboundConnectionsIds);
      }
      if (inboundDocuments?.length) {
        await this.reloadDocuments();
        await this.fetchSharedDocuments(inboundDocuments);
      }
      if (this.inboundLocationsIds?.length) {
        await this.reloadLocations();
        await this.fetchSharedLocations(this.inboundLocationsIds);
      }
      if (inboundDeliveries?.length) {
        await this.reloadDeliveries();
        await this.fetchSharedDeliveries(inboundDeliveries);
      }
      if (this.inboundCertificatesIds?.length) {
        await this.reloadCertificates();
        await this.fetchSharedCertificates(this.inboundCertificatesIds);
      }
      if (this.inboundMaterialsIds.length) {
        await this.reloadMaterials();
        await this.fetchSharedMaterials(this.inboundMaterialsIds);
      }

      if (this.inboundProductsIds.length) {
        await this.reloadProducts();
        await this.fetchSharedProducts(this.inboundProductsIds);
      }

      if (this.inboundItemsIds.length) {
        await this.reloadItems();
        await this.fetchSharedItems(this.inboundItemsIds);
      }
      if (this.inboundProcessTypesIds.length) {
        await this.reloadProcessTypes();
        await this.fetchSharedProcessTypes(this.inboundProcessTypesIds);
      }

      if (inboundProcessesIds.length) {
        await this.reloadProcesses();
        await this.fetchSharedProcesses(inboundProcessesIds);
      }
    } catch (error) {
      this.notification.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  }

  private async fetchSharedLocationTypes(typesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      typesIds,
      CrossOrgShareDataTypeEnum.LOCATION_TYPES,
      this.sharedLocationTypes(),
    );
  }

  private async fetchSharedDocumentTypes(typesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      typesIds,
      CrossOrgShareDataTypeEnum.DOCUMENT_TYPES,
      this.sharedDocumentTypes(),
    );
  }

  private async fetchSharedDocuments(documentsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      documentsIds,
      CrossOrgShareDataTypeEnum.DOCUMENTS,
      this.sharedDocuments(),
    );
  }

  private async fetchSharedConnections(connectionsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      connectionsIds,
      CrossOrgShareDataTypeEnum.CONNECTIONS,
      this.sharedConnections(),
    );
  }

  private async fetchSharedLocations(locationsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      locationsIds,
      CrossOrgShareDataTypeEnum.LOCATIONS,
      this.sharedLocations(),
    );
  }

  private async fetchSharedDeliveries(deliveriesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      deliveriesIds,
      CrossOrgShareDataTypeEnum.DELIVERIES,
      this.sharedDeliveries(),
    );
  }

  private async fetchSharedCertificates(certificatesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      certificatesIds,
      CrossOrgShareDataTypeEnum.CERTIFICATES,
      this.sharedCertificates(),
    );
  }

  private async fetchSharedMaterials(materialsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      materialsIds,
      CrossOrgShareDataTypeEnum.MATERIALS,
      this.sharedMaterials(),
    );
  }

  private async fetchSharedProducts(productsIds: string[]): Promise<void> {
    await this.fetchSharedData(
      productsIds,
      CrossOrgShareDataTypeEnum.PRODUCTS,
      this.sharedProducts(),
    );
  }

  private async fetchSharedItems(itemsIds: string[]): Promise<void> {
    await this.fetchSharedData(itemsIds, CrossOrgShareDataTypeEnum.ITEMS, this.sharedItems());
  }

  private async fetchSharedProcessTypes(typesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      typesIds,
      CrossOrgShareDataTypeEnum.PROCESS_TYPES,
      this.sharedProcessTypes(),
    );
  }

  private async fetchSharedProcesses(processesIds: string[]): Promise<void> {
    await this.fetchSharedData(
      processesIds,
      CrossOrgShareDataTypeEnum.PROCESSES,
      this.sharedProcesses(),
    );
  }

  private async fetchSharedData(
    sharedRecordsIds: string[],
    dataType: CrossOrgShareDataTypeEnum,
    targetArray: any[],
  ): Promise<void> {
    try {
      const promises = sharedRecordsIds.map(async (recordId) => {
        const id = CommonUtils.getUriId(recordId);
        const record = await this.recordSharingService.getSharedRecord(this.senderId, dataType, id);

        const mappedRecordInfo = this.existingRecords().mappedRecordsInfo.find(
          (r) => CommonUtils.getUriId(r.inboundRecord) === id,
        );

        const inBoundInfo = this.existingRecords().newRecords.find(
          (nr) => CommonUtils.getUriId(nr) === id,
        );
        const isNew = this.existingRecords().newRecords.some(
          (nr) => CommonUtils.getUriId(nr) === id,
        );
        const isExisting = this.existingRecords().existingRecordsInfo.some(
          (mr) => CommonUtils.getUriId(mr.inboundRecord) === id,
        );

        targetArray.push({ ...record, inBoundInfo, isNew, isExisting, mappedRecordInfo });
      });

      await Promise.all(promises);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDocumentTypes(): Promise<void> {
    try {
      const documentTypes = await this.documentTypesService.getAll();

      this.documentTypesOptions.set(
        documentTypes.map((l) => ({
          label: l.name,
          value: l.id,
          recordState: l.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadLocationTypes(): Promise<void> {
    try {
      const locationTypes = await this.locationTypesService.getAll();

      this.locationTypesOptions.set(
        locationTypes.map((l) => ({
          label: l.type,
          value: l.id,
          recordState: l.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDocuments(): Promise<void> {
    try {
      const allDocuments = await this.documentsService.getAll();

      this.documentsOptions.set(
        allDocuments.map((d) => ({
          label: d.name,
          value: d.id,
          recordState: d.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadOrganisations(): Promise<void> {
    try {
      const allOrganisations = await this.connectionsService.getAll();
      const activeOrganisation =
        this.authenticationService.getActiveOrganisation() as IOrganisation;

      this.connectionsOptions.set(
        [activeOrganisation, ...allOrganisations].map((c) => ({
          label: c.name,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadLocations(): Promise<void> {
    try {
      const allLocations = await this.locationsService.getAllGraphQL();

      this.locationsOptions.set(
        allLocations.map((c) => ({
          label: c.name,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadDeliveries(): Promise<void> {
    try {
      const allDeliveries = await this.deliveriesService.getAllGraphQL();

      this.deliveriesOptions.set(
        allDeliveries.map((d) => ({
          label: d.deliveryId,
          value: d.id,
          recordState: d.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadCertificates(): Promise<void> {
    try {
      const allCertificates = await this.certificatesService.getAllGraphQL();

      this.certificatesOptions.set(
        allCertificates.map((c) => ({
          label: c.number,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadMaterials(): Promise<void> {
    try {
      const allMaterials = await this.materialsService.getAllGraphQL();

      this.materialsOptions.set(
        allMaterials.map((c) => ({
          label: c.name,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadProducts(): Promise<void> {
    try {
      const allProducts = await this.productsService.getAllGraphQL();

      this.productsOptions.set(
        allProducts.map((c) => ({
          label: c.name,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadItems(): Promise<void> {
    try {
      const allItems = await this.itemsService.getAllGraphQL();

      this.itemsOptions.set(
        allItems.map((c) => ({
          label: c.itemId,
          value: c.id,
          recordState: c.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadProcessTypes(): Promise<void> {
    try {
      const allProcessTypes = await this.processTypesService.getAll();

      this.processTypesOptions.set(
        allProcessTypes.map((t) => ({
          label: t.name,
          value: t.id,
          recordState: t.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async reloadProcesses(): Promise<void> {
    try {
      const allProcesses = await this.processesService.getAllGraphQL();

      this.processesOptions.set(
        allProcesses.map((t) => ({
          label: t.processId,
          value: t.id,
          recordState: t.recordState,
        })),
      );
    } catch (error) {
      this.notification.showError(error);
    }
  }

  public async checkUnmappedDependencies(): Promise<void> {
    try {
      this.existingMappings = await this.recordSharingService.getAllMappings();
      const idsToCheckForLocations = [
        ...this.inboundLocationTypesIds,
        ...this.inboundConnectionsIds,
      ];
      const idsToCheckForDeliveries = [...this.inboundConnectionsIds, ...this.inboundLocationsIds];

      const hasUnmappedLocationDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          idsToCheckForLocations,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedDeliveryDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          idsToCheckForDeliveries,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedDocumentDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          this.inboundDocumentTypesIds,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedItemDependencies = this.unmappedDependenciesService.hasUnmappedDependencies(
        this.inboundProductsIds,
        this.existingMappings,
        "inboundUri",
      );

      const hasUnmappedProductDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          this.inboundMaterialsIds,
          this.existingMappings,
          "inboundUri",
        );

      const hasUnmappedProcessDependencies =
        this.unmappedDependenciesService.hasUnmappedDependencies(
          [...this.inboundProcessTypesIds, ...this.inboundLocationsIds, ...this.inboundItemsIds],
          this.existingMappings,
          "inboundUri",
        );

      this.hasUnmappedLocationDependencies.set(hasUnmappedLocationDependencies);
      this.hasUnmappedDeliveryDependencies.set(hasUnmappedDeliveryDependencies);
      this.hasUnmappedDocumentDependencies.set(hasUnmappedDocumentDependencies);
      this.hasUnmappedItemDependencies.set(hasUnmappedItemDependencies);
      this.hasUnmappedProductDependencies.set(hasUnmappedProductDependencies);
      this.hasUnmappedProcessDependencies.set(hasUnmappedProcessDependencies);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private getExistingConfigs(): ITransferOrMapFormConfig[] {
    return this.configs
      .map((config) => ({
        ...config,
        sharedData: config.sharedData.filter((data) => data.isExisting),
      }))
      .filter((config) => config.sharedData.length > 0);
  }

  private getNewConfigs(): ITransferOrMapFormConfig[] {
    return this.configs
      .map((config) => ({
        ...config,
        sharedData: config.sharedData.filter((data) => data.isNew),
      }))
      .filter((config) => config.sharedData.length > 0);
  }

  private getMappedConfigs(): ITransferOrMapFormConfig[] {
    return this.configs
      .map((config) => ({
        ...config,
        sharedData: config.sharedData.filter((data) => data.mappedRecordInfo),
      }))
      .filter((config) => config.sharedData.length > 0);
  }

  public async inboundMappingRemoved(event: {
    recordId: string;
    recordType: CrossOrgShareDataTypeEnum;
  }) {
    const config = this.configs.find((c) => c.shareRecordType === event.recordType);

    await this.checkUnmappedDependencies();

    if (config) {
      const record = config.sharedData.find((data) => data.id === event.recordId);

      if (record) {
        Object.assign(record, {
          isNew: false,
          mappedRecordInfo: null,
          isExisting: true,
        });
        this.newRecordsConfig.set(this.getNewConfigs());
        this.mappedRecordsConfig.set(this.getMappedConfigs());
        this.existingRecordsConfig.set(this.getExistingConfigs());
      }
    }
  }

  public async inboundMappingAdded(event: {
    recordId: string;
    recordType: CrossOrgShareDataTypeEnum;
  }) {
    const records = await this.recordSharingService.getCheckExistingRecord(
      this.inboundShare.rootRecordUri,
    );

    this.existingRecords.set(records);
    const mappedRecordInfo = this.existingRecords().mappedRecordsInfo.find(
      (r) => CommonUtils.getUriId(r.inboundRecord) === event.recordId,
    );

    await this.checkUnmappedDependencies();

    const config = this.configs.find((c) => c.shareRecordType === event.recordType);

    if (config) {
      const record = config.sharedData.find((data) => data.id === event.recordId);

      if (record) {
        Object.assign(record, {
          isNew: false,
          mappedRecordInfo,
          isExisting: false,
        });
        this.newRecordsConfig.set(this.getNewConfigs());
        this.mappedRecordsConfig.set(this.getMappedConfigs());
        this.existingRecordsConfig.set(this.getExistingConfigs());
      }
    }
  }
}
