import { ChangeDetectionStrategy, Component, inject, input, OnInit } from "@angular/core";

import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { ILocationExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-delivery-report-location-details",
  templateUrl: "./delivery-report-location-details.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportLocationDetailsComponent implements OnInit {
  public locations = input<ILocationExtended[]>([]);

  public groupedLocations: {
    [countryCode: string]: { [locationType: string]: ILocationExtended[] };
  };

  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  public ngOnInit(): void {
    const groupedLocationsByCountry: { [countryCode: string]: ILocationExtended[] } =
      this.locations().reduce((acc, location) => {
        const country = location.address.country;

        if (!acc[country]) {
          acc[country] = [];
        }
        acc[country].push(location);

        return acc;
      }, {});

    this.groupedLocations = Object.keys(groupedLocationsByCountry).reduce((acc, country) => {
      acc[country] = groupedLocationsByCountry[country].reduce(
        (typeAcc, location) => {
          const typesKey = location.types
            .map((typeObj) => typeObj.type)
            .sort()
            .join(" + ");

          if (!typeAcc[typesKey]) {
            typeAcc[typesKey] = [];
          }
          typeAcc[typesKey].push(location);

          return typeAcc;
        },
        {} as { [locationTypes: string]: ILocationExtended[] },
      );

      return acc;
    }, {});
  }

  public countryName(countryCode: string): string {
    return this.deliveryReportService
      .countryOptions()
      .find((option) => option.value === countryCode)?.label;
  }
}
