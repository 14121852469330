import { Directive, EventEmitter, Input, Output, TemplateRef } from "@angular/core";

import { StepperService } from "@components/shared/stepper/stepper.service";

@Directive({
  standalone: false,
  selector: "[appStep]",
})
export class StepDirective {
  @Input() title: string = "";

  @Input() tooltip: string = "";

  @Input() canGoBackTo: boolean = false;

  @Input() canGoForwardTo: boolean = false;

  @Output() backClick: EventEmitter<void> = new EventEmitter();

  @Output() forwardClick: EventEmitter<void> = new EventEmitter();

  disabled: boolean;

  active: boolean;

  navigated: boolean;

  constructor(
    public templateRef: TemplateRef<unknown>,
    private stepperService: StepperService,
  ) {}

  public onClick(): void {
    const currentStepIndex = this.stepperService.currentStepIndex();
    const stepIndex = this.stepperService.steps.toArray().findIndex((step) => step === this);

    if (this.canGoBackTo && stepIndex === currentStepIndex - 1) {
      this.backClick.emit();
    } else if (this.canGoForwardTo && stepIndex === currentStepIndex + 1) {
      this.forwardClick.emit();
    }
  }
}
