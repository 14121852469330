import { QueriesUtils } from "@shared/queries";

import { CountryFieldsToInclude } from "../models";

export const getCountriesProperties = (include: CountryFieldsToInclude[]): string => `
  pageInfo {
    hasNextPage 
    hasPreviousPage
    startCursor
    endCursor
  }
  totalCount
  edges {
    cursor
    node {
      id
      name
      code
      ${QueriesUtils.getQueryIncludes(include)}
    }            
}`;
