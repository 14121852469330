<div class="row-container row row-with-index" [formGroup]="formGroup()">
  <div class="index">
    {{ index + 1 }}
  </div>

  <div>
    <app-form-label [label]="translations.materialLabel"></app-form-label>
    <app-form-link [link]="getMaterialLink()" [styledLink]="true">
      {{ materialName() }}
    </app-form-link>
  </div>

  <app-input
    formControlName="scientificName"
    tooltipIcon="help"
    [tooltip]="translations.scientificNameTooltip"
    [label]="reportsEudrModel.MaterialCustomFieldEnum.SCIENTIFIC_NAME"
  ></app-input>
  <app-input
    formControlName="commonName"
    tooltipIcon="help"
    [tooltip]="translations.commonNameTooltip"
    [label]="reportsEudrModel.MaterialCustomFieldEnum.COMMON_NAME"
  ></app-input>
</div>
