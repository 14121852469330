import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import { ResourceTypeEnum, TagSelectorTypeEnum, CriteriaTypeEnum } from "src/app/shared/enums";
import { ILocationExtended, ISelectOption } from "src/app/shared/interfaces";

import { TextConstants } from "@shared/constants";

@Directive({
  standalone: false,
})
export abstract class BaseRulesetInfoClass {
  @Input()
  public formGroup: UntypedFormGroup;

  @Input()
  public selectedLocationTypesIds: string[] = [];

  @Input()
  public selectedCountriesCodes: string[] = [];

  @Input()
  public allCountriesOption: ISelectOption[] = [];

  @Input()
  public allLocations: ILocationExtended[] = [];

  @Input()
  public resourceType: ResourceTypeEnum;

  @Output()
  public setSelectedLocationTypesIds: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  public setSelectedCountries: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Output()
  public openDocumentTypeDialog: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public newLocationTypeCreated: EventEmitter<void> = new EventEmitter<void>();

  public readonly tagSelectorTypeEnum = TagSelectorTypeEnum;

  public readonly resourceTypeEnum = ResourceTypeEnum;

  public readonly CriteriaTypeEnum = CriteriaTypeEnum;

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;
}
