<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Select active organisation</h2>

  <app-loader *ngIf="isLoading()" containerClass="on-dialog"></app-loader>

  <ng-container *ngIf="!isLoading()">
    <mat-dialog-content>
      <app-input-select
        [options]="organisationOptions"
        formControlName="organisation"
        [label]="translations.organisationLabel"
      ></app-input-select>
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-button [disabled]="isSubmitButtonDisabled()" accessibilityRole="submit">
        <mat-icon icon-left>check</mat-icon>

        <ng-container i18n>Select organisation</ng-container>
      </app-button>
    </mat-dialog-actions>
  </ng-container>
</form>
