import { CdkDragDrop } from "@angular/cdk/drag-drop";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  signal,
  ViewChild,
} from "@angular/core";
import { AbstractControl, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { Subscription } from "rxjs";
import { ConfirmDialogComponent, QuantitySummaryTableComponent } from "src/app/components/shared";
import {
  ConfirmDialogResponseEnum,
  DateTypeEnum,
  RecordStateEnum,
  StartEndEnum,
} from "src/app/shared/enums";
import {
  IBaseUnit,
  IItem,
  IItemDetails,
  IMaterial,
  IProcessInput,
  IProcessItem,
  IProcessOutput,
  IProductExtended,
  ISelectOption,
} from "src/app/shared/interfaces";
import {
  AuthenticationService,
  ItemsService,
  LocationsService,
  MaterialsService,
  ProcessesService,
} from "src/app/shared/services";
import { CommonUtils, FormUtils } from "src/app/shared/utils";

import { AddItemDialogComponent } from "@components/deliveries";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { ProcessOverlayService } from "@components/processes/pages/process-overlay/process-overlay.service";
import { DatepickerComponent, DateRangePickerComponent } from "@components/shared/datepicker";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { CustomValidators } from "../../../../shared/validators";

@Component({
  standalone: false,
  selector: "app-add-process-inputs-outputs",
  templateUrl: "./add-process-inputs-outputs.component.html",
  styleUrl: "./add-process-inputs-outputs.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddProcessInputsOutputsComponent implements OnInit, OnDestroy {
  @ViewChild("datePicker") datePickerComponent: DatepickerComponent | DateRangePickerComponent;

  @Input()
  public element: IProcessInput | IProcessOutput;

  @Input()
  public processId: string;

  @Input()
  public processLocation: ISelectOption;

  @Input()
  public type: string;

  @Input()
  public isReadOnly: boolean = false;

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Output()
  public addCompleted: EventEmitter<any> = new EventEmitter();

  @ViewChild(QuantitySummaryTableComponent) summaryTable: QuantitySummaryTableComponent;

  public availableItems = signal<IItemDetails[]>([]);

  public selectedItems = signal<IItemDetails[]>([]);

  public activeOrganisationId: string;

  public datesType = DateTypeEnum.EXACT;

  public readonly dateTypeEnum = DateTypeEnum;

  public isLoading = signal(true);

  public isLoadingItems = signal(false);

  public formGroup = new UntypedFormGroup({
    date: new UntypedFormControl(null, [CustomValidators.required, CustomValidators.date]),
    rangeDate: new UntypedFormControl([null, null]),
    note: new UntypedFormControl(null),
  });

  public searchAvailableText: string = null;

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;

  private subscriptions: Subscription = new Subscription();

  public readonly translations: any = {
    exactDate: $localize`Exact date`,
    rangeDate: TextConstants.RANGE_FROM_TO,
    noteLabel: TextConstants.NOTE,
  };

  constructor(
    private itemsService: ItemsService,
    private processesService: ProcessesService,
    private processOverlayService: ProcessOverlayService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private locationsService: LocationsService,
    private dialog: MatDialog,
    private overlay: SlideOverlayPageService,
    private materialsService: MaterialsService,
    private bulkAddItemsService: BulkAddItemsService,
  ) {
    this.activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    this.subscriptions.add(
      this.bulkAddItemsService.result$.subscribe(async (result) => {
        if (!result) {
          return;
        }

        await this.onReloadItems();

        const newItems = result.newRecords;

        for (const item of this.availableItems()) {
          const newItem = newItems.find((i) => i.id === item.id);

          if (!newItem) {
            continue;
          }

          await this.onAdd(item.id, null, newItem.selectedQuantity);
        }
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    this.isLoading.set(true);

    await this.onReloadItems();

    if (this.element) {
      await this.setEditProperties();
    }

    this.isLoading.set(false);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async setEditProperties(): Promise<void> {
    const { date, items } = this.element;

    let controls: { [key: string]: AbstractControl } = {
      date: new UntypedFormControl(null),
      rangeDate: new UntypedFormControl([]),
      note: new UntypedFormControl(null),
    };

    if (date.start && date.end) {
      this.datesType = DateTypeEnum.RANGE;

      controls = {
        ...controls,
        rangeDate: new UntypedFormControl(
          [date.start, date.end],
          [CustomValidators.required, CustomValidators.dateRange],
        ),
      };
    } else {
      controls = {
        ...controls,
        date: new UntypedFormControl(date.on, [CustomValidators.required, CustomValidators.date]),
      };
    }

    this.formGroup = new UntypedFormGroup(controls);

    for (const item of items) {
      const id = CommonUtils.getUriId(item.item);

      await this.onAdd(id, item);
    }
  }

  public get title(): string {
    return this.element ? `Edit ${this.type}` : `Add new ${this.type}`;
  }

  public onSearchAvailable = async (value: string): Promise<void> => {
    this.searchAvailableText = value;
    await this.onReloadItems();
  };

  public onItemDropped = async (event: CdkDragDrop<IItem>): Promise<void> => {
    await this.onAdd(event.item.data.id);
  };

  public onAddAll = async (): Promise<void> => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${this.availableItems().length}:count:</b> items?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        while (this.availableItems().length > 0) {
          await this.onAdd(this.availableItems()[0].id);
        }
      }
    });
  };

  public onAdd = async (
    id: string,
    existingItem?: IProcessItem,
    selectedQuantity?: number,
  ): Promise<void> => {
    const itemIndex = this.availableItems().findIndex((i) => i.id === id);
    const item = this.availableItems()[itemIndex];

    if (this.type === "output") {
      const processLocationId = this.processLocation.value;
      const itemLocationId = CommonUtils.getUriId(item.createdAtLocation);

      if (processLocationId !== itemLocationId) {
        item.isLocationDifferentFromProcess = true;
        const itemLocation = await this.locationsService.get(itemLocationId);

        item.createdAtLocationName = itemLocation.name;
      }
    }

    if (existingItem) {
      item.selectedQuantity = existingItem.selectedQuantity;
      item.isEditing = true;
      item.originalSelectedQuantity = existingItem.selectedQuantity;
    } else if (selectedQuantity) {
      item.selectedQuantity = selectedQuantity;
      item.originalSelectedQuantity = selectedQuantity;
    }

    this.selectedItems.set([...this.selectedItems(), { ...item }]);
    const availableItems = [...this.availableItems()];

    availableItems.splice(itemIndex, 1);
    this.availableItems.set(availableItems);
  };

  public onRemove = async (id: string): Promise<void> => {
    const itemIndex = this.selectedItems().findIndex((i) => i.id === id);

    const selectedItems = [...this.selectedItems()];

    selectedItems.splice(itemIndex, 1);
    this.selectedItems.set(selectedItems);
    await this.onReloadItems();
  };

  public onAddNewItem = async (): Promise<void> => {
    const result = await this.overlay.openDialog<
      { hasSaved: boolean; item: IItem },
      { dialogTitle: string }
    >(AddItemDialogComponent, {
      dialogTitle: $localize`Add item to process`,
    });

    if (result?.hasSaved) {
      await this.processOverlayService.onReloadProducts();
      const productId = CommonUtils.getUriId(result.item.product);

      if (!this.allProducts.some((p) => p.id === productId)) {
        this.allProducts = this.processOverlayService.allProducts();
      }

      const itemMaterialsIds = result.item.materials.map((m) => CommonUtils.getUriId(m));

      if (
        itemMaterialsIds.length &&
        itemMaterialsIds.some(
          (newMaterialId) => !this.allMaterials.some((am) => am.id === newMaterialId),
        )
      ) {
        this.allMaterials = await this.materialsService.getAll();
      }

      this.availableItems.set([...this.availableItems(), result.item]);
      await this.onAdd(result.item.id);
    }
  };

  public async onOpenBulkAddItemsSlideOver(): Promise<void> {
    await this.bulkAddItemsService.openSlideOver({ isToBeAttachedToProcessInput: true });
  }

  public onReloadItems = async (): Promise<void> => {
    try {
      this.isLoadingItems.set(true);

      const extendedItems = await this.itemsService.getAllGraphQL(
        { itemId: this.searchAvailableText, recordState: RecordStateEnum.ACTIVE },
        undefined,
        ["TAGS"],
      );

      const items: IItem[] = [];

      for (const extendedItem of extendedItems) {
        const item = CommonUtils.convertExtendedItemToItem(
          extendedItem,
          this.activeOrganisationId,
          false,
        );

        items.push(item);
      }

      const availableItems = items.filter((l) => !this.selectedItems().some((s) => s.id === l.id));

      this.availableItems.set(
        CommonUtils.getItemsWithProductAndAssignedUnit(availableItems, this.allProducts),
      );

      this.availableItems.set(
        await Promise.all(
          this.availableItems().map(async (item) => {
            const product = this.allProducts.find((p) => p.id === item.productId);
            const defaultUnit = product.unitOfMeasurement;

            if (defaultUnit?.id !== item.unitOfMeasurement.id) {
              item.productDefaultUnit = product.defaultCustomUnit;

              item.remainingQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
                item.remainingQuantity,
                item.productDefaultUnit,
                item.unitOfMeasurement,
              );

              item.initialQuantityFormatted = CommonUtils.formatQuantityWithDefaultUnit(
                item.initialQuantity,
                item.productDefaultUnit,
                item.unitOfMeasurement,
              );
            }

            return item;
          }),
        ),
      );

      this.isLoadingItems.set(false);
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  public onCancel = (): void => {
    this.addCompleted.emit();
  };

  public onSave = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    const isEditing = !!this.element;

    try {
      const payload = this.getSavePayload();

      if (!payload.items.length) {
        throw $localize`No item(s) added`;
      }

      switch (this.type) {
        case "input":
          {
            if (isEditing) {
              await this.processesService.deleteInput(this.processId, this.element.id);
            }
            await this.processesService.createInput(this.processId, payload);
          }
          break;
        case "output":
          {
            if (isEditing) {
              await this.processesService.deleteOutput(this.processId, this.element.id);
            }
            await this.processesService.createOutput(this.processId, payload);
          }
          break;
      }

      const suffixMessage = isEditing ? "updated" : "created";
      const notificationMessage = `${this.type.charAt(0).toUpperCase()}${this.type.slice(1)} ${suffixMessage}`;

      this.notificationService.showSuccess(notificationMessage);

      this.addCompleted.emit({ hasSaved: true });
    } catch (error) {
      this.isLoading.set(false);
      this.notificationService.showError(error);
    }
  };

  public onChangeDatesType = (datesType: DateTypeEnum): void => {
    this.datesType = datesType;
    const { controls } = this.formGroup;

    switch (datesType) {
      case DateTypeEnum.EXACT:
        controls["rangeDate"].clearValidators();
        if (controls["rangeDate"].value[0]) {
          controls["date"].patchValue(controls["rangeDate"].value[0]);
        }
        controls["date"].addValidators([CustomValidators.required, CustomValidators.date]);

        break;
      case DateTypeEnum.RANGE:
        controls["date"].clearValidators();
        if (controls["date"].value) {
          controls["rangeDate"].patchValue([controls["date"].value, null]);
          controls["rangeDate"].markAsTouched();
        }
        controls["rangeDate"].addValidators([
          CustomValidators.required,
          CustomValidators.dateRange,
        ]);
        break;
    }

    const date = controls["date"].value;

    controls["date"].updateValueAndValidity();
    controls["rangeDate"].updateValueAndValidity();
    this.formGroup.updateValueAndValidity();
    if (datesType === DateTypeEnum.RANGE || (datesType === DateTypeEnum.EXACT && !date)) {
      setTimeout(() => this.datePickerComponent.picker.open());
    }
  };

  private getSavePayload = (): IProcessInput | IProcessOutput => {
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
    const payload: any = {
      note: this.formGroup.controls["note"].value,
      date: {
        type: this.datesType,
      },
    };

    switch (this.datesType) {
      case DateTypeEnum.EXACT:
        payload.date.on = FormUtils.getDateValueForPayload(this.formGroup.controls["date"].value);
        break;
      case DateTypeEnum.RANGE:
        payload.date.start = FormUtils.getDateValueForPayload(
          this.formGroup.controls["rangeDate"].value[0],
        );
        payload.date.end = FormUtils.getDateValueForPayload(
          this.formGroup.controls["rangeDate"].value[1],
          StartEndEnum.END,
        );
        break;
    }

    switch (this.type) {
      case "input":
        payload.items = this.selectedItems().map((i) => ({
          item: `/organisations/${activeOrganisationId}/items/${i.id}`,
          selectedQuantity: i.selectedQuantity ?? 0,
        }));
        break;
      case "output":
        payload.items = this.selectedItems().map((i) => ({
          item: `/organisations/${activeOrganisationId}/items/${i.id}`,
        }));
        break;
    }

    return payload;
  };

  onSelectedQuantityChanged(item: IItem): void {
    const data = { id: item.id, deliveredQuantity: item.deliveredQuantity };

    this.summaryTable.refreshItemsQuantity(data);
  }
}
