<div class="accept-container">
  <mat-dialog-content cdkFocusRegionstart>
    <p class="text-center">{{ data.organisationName }} would like you to join the organisation</p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-button-group>
      <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
      <app-button (pressed)="onClose(false)" color="danger">
        <mat-icon icon-left>close</mat-icon>
        <ng-container i18n>Reject</ng-container>
      </app-button>
      <app-button (pressed)="onClose(true)">
        <mat-icon icon-left>check</mat-icon>
        <ng-container i18n>Accept</ng-container>
      </app-button>
    </app-button-group>
  </mat-dialog-actions>
</div>
