import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";

import { AddConnectionDialogComponent } from "@components/locations/components/add-connection-dialog/add-connection-dialog.component";
import { InputChipsComponent } from "@components/shared/inputs/input-chips/input-chips.component";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { AuthenticationService, LocationTypesService } from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";

interface ConnectionResult {
  hasSaved;
  savedOrganisation;
}

@Component({
  standalone: false,
  selector: "app-location-overlay-main-information",
  templateUrl: "./location-overlay-main-information.component.html",
  styleUrl: "./location-overlay-main-information.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayMainInformationComponent {
  @Input() formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  @Input() organisationOptions: ISelectOption[] = [];

  @Input() countryOptions: ISelectOption[] = [];

  @Input() isInboundShare = false;

  @Output() reloadOrganisations: EventEmitter<string> = new EventEmitter();

  @ViewChild("locationTypesInputChips") locationTypesInputChips: InputChipsComponent;

  protected readonly routingEnum = RouteEnum;

  public readonly isSharedUser = this.authenticationService.isSharedUser();

  public readonly translations: any = {
    typesPh: $localize`Type new value and hit enter`,
    typesLabel: $localize`Type(s)`,
    nameLabel: TextConstants.NAME,
    typesTooltip: TextConstants.LOCATION_TYPES_TOOLTIP,
    organisationLabel: TextConstants.ORGANISATION,
    organisationTp: $localize`Add new organisation`,
  };

  constructor(
    protected overlay: SlideOverlayPageService,
    public locationTypesService: LocationTypesService,
    private router: RouterService,
    private authenticationService: AuthenticationService,
  ) {}

  public async onAddOrganisationClicked(): Promise<void> {
    const result = await this.overlay.openDialog<
      ConnectionResult,
      { countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      countryOptions: this.countryOptions,
    });

    if (result?.hasSaved) {
      this.reloadOrganisations.emit(result.savedOrganisation.id);
    }
  }

  getOrganisationLink(organisationId: string): NavigationParams {
    // if (this.isInboundShare) {
    //     return <NavigationParams>this.router.getSharedOrganisationLink(organisationId, false, {
    //         organisationId: this.route.snapshot.queryParams["organisationId"],
    //     });
    // }
    return <NavigationParams>this.router.getOrganisationLink(organisationId, false);
  }
}
