import { ChangeDetectionStrategy, Component, OnInit, inject, signal } from "@angular/core";

import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { validResourceType } from "@components/shared/risk-assessment-templates/utils";
import { TextConstants } from "@shared/constants";
import { RecordStateEnum, RouteEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-settings-risk-assessment-templates",
  templateUrl: "./settings-risk-assessment-templates.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsRiskAssessmentTemplatesComponent implements OnInit {
  private readonly router = inject(RouterService);

  private readonly authService = inject(AuthenticationService);

  readonly canAddModifyEntities = this.authService.canAddModifyEntities();

  readonly recordState = RecordStateEnum;

  readonly resourceType = RiskAssessmentTemplateResourceType;

  readonly routing = RouteEnum;

  readonly selectedTabIndex = signal(0);

  public readonly translations = {
    title: $localize`Risk assessment templates`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  private readonly resourceTypeMap = {
    0: RiskAssessmentTemplateResourceType.COUNTRY,
    1: RiskAssessmentTemplateResourceType.ORGANISATION,
    2: RiskAssessmentTemplateResourceType.LOCATION,
    3: RiskAssessmentTemplateResourceType.MATERIAL,
    4: RiskAssessmentTemplateResourceType.PRODUCT,
    5: RiskAssessmentTemplateResourceType.DELIVERY,
  };

  ngOnInit() {
    const resourceType = this.router.getQueryParams()["type"];

    if (validResourceType(resourceType)) {
      this.selectedTabIndex.set(Object.values(this.resourceTypeMap).indexOf(resourceType));
    }
  }

  onAdd() {
    this.router.navigate(
      this.router.getRiskAssessmentTemplateLink(undefined, true, {
        type: this.resourceTypeMap[this.selectedTabIndex()],
      }),
    );
  }
}
