import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { CustomFieldsResourceTypeEnum } from "src/app/shared/enums";
import { ICustomField, IOrganisation, ISelectOption } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  ConnectionsService,
  CustomFieldsService,
} from "src/app/shared/services";
import { CustomFieldsUtils, FormUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  templateUrl: "./add-connection-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddConnectionDialogComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({});

  public isLoading = signal(true);

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private allCustomFields: ICustomField[] = [];

  public visibleCustomFields: ICustomField[] = [];

  private readonly activeOrganisationName =
    this.authenticationService.getActiveOrganisation()?.name;

  public readonly mainInformationText = TextConstants.MAIN_INFORMATION;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    websiteLabel: TextConstants.WEBSITE,
    addressLabel: TextConstants.ADDRESS,
    countryLabel: TextConstants.COUNTRY,
    zipCodeLabel: TextConstants.ZIP_POSTCODE,
    regionLabel: $localize`Region`,
  };

  constructor(
    public dialogRef: MatDialogRef<AddConnectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      countryOptions: ISelectOption[];
      connection?: IOrganisation;
    },
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private customFieldsService: CustomFieldsService,
    private authenticationService: AuthenticationService,
  ) {}

  public async ngOnInit(): Promise<void> {
    this.allCustomFields = await this.customFieldsService.getAll(
      CustomFieldsResourceTypeEnum.CONNECTION,
    );
    this.setupForm();
    this.isLoading.set(false);
  }

  public isSubmitButtonDisabled = (): boolean => this.formGroup.invalid || this.formGroup.pending;

  public onSubmit = async (): Promise<void> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }
    this.isLoading.set(true);

    const payload: IOrganisation = {
      name: this.formGroup.controls["name"].value.trim(),
      website: this.formGroup.controls["website"].value
        ? FormUtils.addUrlProtocol(this.formGroup.controls["website"].value)
        : undefined,
      address: {
        street: this.formGroup.controls["street"].value ?? undefined,
        region: this.formGroup.controls["region"].value ?? undefined,
        zipCode: this.formGroup.controls["zipCode"].value ?? undefined,
        country: this.formGroup.controls["country"].value.value,
      },
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    await this.connectionsService
      .createOrUpdate(payload)
      .then((response: IOrganisation) => {
        this.notificationService.showSuccess($localize`Organisation created`);
        this.onClose(true, response);
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(() => {
        this.isLoading.set(false);
      });
  };

  public onClose = (hasSaved = false, savedOrganisation?: IOrganisation): void => {
    this.dialogRef.close({ hasSaved, savedOrganisation });
  };

  private setupForm = (): void => {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(
        this.data?.connection?.name ?? null,
        [
          CustomValidators.forbiddenValueValidator(
            this.activeOrganisationName,
            TextConstants.SAME_ORG_NAME_ERROR,
          ),
          CustomValidators.required,
        ],
        [CustomValidators.entityAlreadyExists(this.connectionsService)],
      ),
      website: new UntypedFormControl(this.data?.connection?.website ?? null, [
        CustomValidators.url(),
      ]),
      street: new UntypedFormControl(this.data?.connection?.address?.street ?? null),
      region: new UntypedFormControl(this.data?.connection?.address?.region ?? null),
      zipCode: new UntypedFormControl(this.data?.connection?.address?.zipCode ?? null),
      country: new UntypedFormControl(
        this.data?.countryOptions.find(
          (c) => c.value === this.data?.connection?.address?.country,
        ) ?? null,
        [CustomValidators.required],
      ),
    });
    this.visibleCustomFields = CustomFieldsUtils.getVisible(this.allCustomFields, null);
    CustomFieldsUtils.addToFormGroup(this.formGroup, this.visibleCustomFields, null);
  };
}
