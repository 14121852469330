<div class="slide-over-content" #content>
  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <div class="container">
      <section class="container-flex flex-column preview-page-container">
        <h4 class="page-title">
          <ng-container i18n>Page</ng-container>
          : {{ selectedPage().value.number }}
        </h4>
        <pdf-viewer
          class="page-preview"
          [ngClass]="{
            'rotation-align':
              selectedPage().value.rotation === 90 || selectedPage().value.rotation === 270,
          }"
          [src]="pdfSrc"
          [page]="selectedPage().value.number"
          [show-all]="false"
          [original-size]="false"
          [rotation]="selectedPage().value.rotation"
          [fit-to-page]="true"
          [zoom]="1"
          [render-text]="false"
          [autoresize]="true"
          [show-borders]="true"
          [zoom-scale]="'page-fit'"
        ></pdf-viewer>
      </section>
      <section #documentPagesListContainer class="document-list-container flex-column">
        @for (
          documentForm of formGroup().controls[fieldEnum.DOCUMENTS].controls;
          track documentForm;
          let documentIndex = $index
        ) {
          <div class="document-container flex-column" #documentPagesList>
            <div>
              <h4 class="container-flex-center document-title">
                <ng-container i18n>Document</ng-container>
                : {{ documentIndex + 1 }}
              </h4>

              <div class="line"></div>
            </div>

            @for (page of pagesControls(documentForm).controls; track page.value.number) {
              <app-pdf-splitter-page-thumbnail
                (pageClick)="onPageClick(page)"
                (pageRotation)="onPageRotation($event)"
                (pageInclusion)="onPageInclusion($event)"
                [documentForm]="documentForm"
                [page]="page"
                [selectedPage]="selectedPage().value.number"
                [pdfSrc]="pdfSrc"
              ></app-pdf-splitter-page-thumbnail>

              @if (
                page.value.number <
                pagesControls(documentForm).value[pagesControls(documentForm).value.length - 1]
                  .number
              ) {
                <div
                  class="split-or-merge-action"
                  (click)="onSplit(documentForm, documentIndex, page)"
                  [tp]="translations.splitPagesTp"
                >
                  <div class="dashed-line"></div>
                  <mat-icon>content_cut</mat-icon>
                  <div class="dashed-line"></div>
                </div>
              }
            }
          </div>

          @if (documentIndex < formGroup().controls[fieldEnum.DOCUMENTS].controls.length - 1) {
            <div class="split-or-merge-action" (click)="onMerge(documentIndex)">
              ↑
              <ng-container i18n>Merge</ng-container>
              ↓
            </div>
          }
        }
      </section>
      <section [formGroup]="formGroup()">
        <h2 i18n>Documents</h2>

        <div [formArrayName]="fieldEnum.DOCUMENTS">
          @for (
            documentForm of formGroup().controls[fieldEnum.DOCUMENTS].controls;
            track documentForm;
            let documentIndex = $index
          ) {
            <app-pdf-splitter-document-fields
              (delete)="onDeleteDocument(documentIndex)"
              (scrollToPage)="onScrollToPages(documentIndex)"
              [formGroup]="documentForm"
              [pages]="documentForm.get(documentFieldEnum.PAGES).value"
              [documentTypeOptions]="documentTypeOptions()"
              [index]="documentIndex + 1"
              [canDelete]="formGroup().controls[fieldEnum.DOCUMENTS].length > 1"
            ></app-pdf-splitter-document-fields>
          }
        </div>
      </section>
    </div>
  }
</div>

<div class="slide-over-buttons">
  <app-button-group class="bottom-buttons flex-end-content">
    <app-button (pressed)="onCancel()" type="outlined" i18n>Cancel</app-button>
    <app-button (pressed)="onSubmit()" type="filled" [disabled]="isSubmitButtonDisabled()">
      {{ submitButtonText() }}
    </app-button>
  </app-button-group>
</div>
