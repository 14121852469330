import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { ISelectOption } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-edit-organisation-main-details",
  templateUrl: "./edit-organisation-main-details.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class EditOrganisationMainDetailsComponent {
  @Input() formGroup: FormGroup;

  public isEditing = input<boolean>(false);

  @Input() countryOptions: ISelectOption[];

  constructor(protected overlay: SlideOverlayPageService) {}

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    websiteLabel: TextConstants.WEBSITE,
  };
}
