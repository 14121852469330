<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add analysis</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input-select
        [hint]="computedHint()"
        [hintClass]="
          !typeControl.value?.enabled || !data.hasGpsData ? 'info-hint info-hint-bg-yellow' : ''
        "
        [options]="typeOptions"
        formControlName="type"
        [label]="translations.typeLabel"
      ></app-input-select>

      <app-input autofocus formControlName="name" [label]="translations.nameLabel"></app-input>

      <app-daterangepicker
        class="no-padding-top-input"
        formControlName="time"
        [maxDate]="maxDate"
        [label]="translations.timeLabel"
      ></app-daterangepicker>
      @if (error) {
        <small class="red">
          {{ error }}
        </small>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button
          [disabled]="isSubmitButtonDisabled()"
          accessibilityRole="submit"
          cdkFocusInitial
        >
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
