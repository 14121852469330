@if (templateValueChanges$ | async) {}
<form [formGroup]="form" (ngSubmit)="submit()">
  <h2 mat-dialog-title i18n>Select template</h2>

  @if (isLoading()) {
    <app-loader containerClass="on-dialog" />
  } @else {
    <mat-dialog-content>
      <app-input-select
        formControlName="template"
        [label]="translations.templateLabel"
        [options]="templateOptions()"
      >
        <ng-container action>
          <app-button
            (pressed)="addTemplate()"
            class="icon-add-circle"
            [tp]="translations.addNewTp"
            type="icon"
          >
            <mat-icon icon-small>add</mat-icon>
          </app-button>
        </ng-container>
      </app-input-select>

      <app-form-input-readonly
        [label]="translations.nameLabel"
        [value]="selectedTemplate()?.name"
        [copyMode]="copyMode.NONE"
      />

      <app-form-input-readonly
        [label]="translations.descriptionLabel"
        [value]="selectedTemplate()?.description"
        [copyMode]="copyMode.NONE"
      />

      <app-form-input-readonly
        [label]="translations.riskLevelSetLabel"
        [value]="riskLevelSetsState.selectedSet()?.name"
        [copyMode]="copyMode.NONE"
      />

      @if (riskLevelSetsState.selectedSet()?.name) {
        <app-basic-tags
          appearance="below-form-field"
          [tags]="riskLevelSetsState.selectedLevels()"
        />
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="close()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
