<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add organisation</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <app-input
              [autofocus]="true"
              formControlName="name"
              [label]="translations.nameLabel"
            ></app-input>
          </div>
          <div class="item">
            <app-input
              [type]="'url'"
              formControlName="website"
              [label]="translations.websiteLabel"
            ></app-input>
          </div>
        </div>
      </div>

      <h4 i18n>Headquarters</h4>
      <div class="row-container">
        <div class="row row-2">
          <div class="item">
            <app-input formControlName="street" [label]="translations.addressLabel"></app-input>
          </div>
          <div class="item">
            <app-input formControlName="region" [label]="translations.regionLabel"></app-input>
          </div>
          <div class="item">
            <app-input formControlName="zipCode" [label]="translations.zipCodeLabel"></app-input>
          </div>
          <div class="item">
            <app-input-select
              [options]="data.countryOptions"
              formControlName="country"
              [label]="translations.countryLabel"
              [prefixTemplate]="flag"
            ></app-input-select>
            <ng-template #flag let-option>
              <app-flag [country]="option.value"></app-flag>
            </ng-template>
          </div>
        </div>
      </div>

      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
