import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { CertificateOverlayService } from "@components/certificates/pages/certificate-overlay/certificate-overlay.service";
import { OverlayDocumentAttachmentsComponent } from "@components/shared";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  RouteEnum,
  StartEndEnum,
} from "@shared/enums";
import {
  IAttachment,
  ICertificate,
  ICertificatePayload,
  IRecordState,
  ISelectOption,
  IStandard,
  IStandardType,
} from "@shared/interfaces";
import {
  AttachmentsService,
  CertificatesService,
  StandardsService,
  StandardTypesService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-certificate-overlay",
  templateUrl: "./certificate-overlay.component.html",
  styleUrls: ["../certificate-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class CertificateOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy
{
  @ViewChild("documentSection") documentSection: OverlayDocumentAttachmentsComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    number: new UntypedFormControl(null, [CustomValidators.required]),
    standard: new UntypedFormControl(null, [CustomValidators.required]),
    standardType: new UntypedFormControl(null),
    issuanceDate: new UntypedFormControl(null),
    validFromDate: new UntypedFormControl(null),
    validToDate: new UntypedFormControl(null),
    url: new UntypedFormControl(null, [CustomValidators.url()]),
  });

  public standardOptions = signal<ISelectOption[]>([]);

  public standardTypeOptions = signal<ISelectOption[]>([]);

  public selectedAttachments: IAttachment[] = [];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.CERTIFICATE_DETAILS, isEnabled: true }],
      [OverlayTabEnum.ATTACHED_TO, { title: TextConstants.ATTACHED_TO, isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: false }],
      [
        OverlayTabEnum.COMMENTS,
        { title: TextConstants.COMMENTS, isEnabled: false, isHidden: !this.isRegularUser },
      ],
    ]),
  );

  public override element: ICertificate;

  public override entityType = EntityTypeEnum.CERTIFICATES;

  public override readonly attachmentTargetType = AttachmentTargetEnum.CERTIFICATE;

  public readonly attachmentTargetEnum = AttachmentTargetEnum;

  public readonly documentsHint: string = TextConstants.CERTIFICATES_DOCUMENTS_HINT;

  constructor(
    public certificateOverlay: CertificateOverlayService,
    private certificatesService: CertificatesService,
    private standardsService: StandardsService,
    private standardTypesService: StandardTypesService,
    private attachmentsService: AttachmentsService,
    private cdr: ChangeDetectorRef,
  ) {
    super();
  }

  public override get isSubmitButtonDisabled(): boolean {
    if (!this.isEditing()) {
      return false;
    }

    return this.formGroup?.invalid || this.formGroup?.pending || !this.hasFormValuesChanged;
  }

  public async ngOnInit(): Promise<void> {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_CERTIFICATE)) {
      return;
    }

    await this.setAllCustomFields(CustomFieldsResourceTypeEnum.CERTIFICATE);

    if (this.recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    } else {
      await this.onReloadStandardOptions();
      await this.setupForm();
    }
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadDocuments();
      this.loadComments();
    }
  }

  setCountersToLoadingState() {
    this.certificateOverlay.setCountersToLoadingState();
    this.selectedAttachments = null;
  }

  setCountersEmptyState() {
    this.certificateOverlay.setCountersEmptyState();
  }

  loadDocuments(): void {
    this.certificateOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.certificateOverlay.loadCommentCounter(this.entityUri);
  }

  public ngOnDestroy(): void {
    this.setCountersEmptyState();
    this.subscriptions.unsubscribe();
  }

  public override setupForm = async (): Promise<void> => {
    let standardValue: ISelectOption;
    let standardTypeValue: ISelectOption;

    if (this.isEditing()) {
      const standardId = CommonUtils.getUriId(this.element.standard);

      standardValue = this.standardOptions().find((s) => s.value === standardId);
      await this.updateStandardTypeOptions(standardValue);
      if (this.element.standardType) {
        const standardTypeId = CommonUtils.getUriId(this.element.standardType);

        standardTypeValue = this.standardTypeOptions().find((s) => s.value === standardTypeId);
      }
    }

    const entityExistsValidatorArgs = {
      searchPropertyName: "number",
      searchPropertyErrorDisplayName: "number",
    };

    this.formGroup = new UntypedFormGroup(
      {
        number: new UntypedFormControl(
          this.element?.number ?? null,
          [CustomValidators.required],
          [
            CustomValidators.entityAlreadyExists(
              this.certificatesService,
              null,
              entityExistsValidatorArgs,
            ),
          ],
        ),
        standard: new UntypedFormControl(standardValue ?? null, [CustomValidators.required]),
        standardType: new UntypedFormControl(standardTypeValue ?? null),
        issuanceDate: new UntypedFormControl(this.element?.issuanceDate ?? null),
        validFromDate: new UntypedFormControl(this.element?.validFromDate ?? null),
        validToDate: new UntypedFormControl(this.element?.validToDate ?? null),
        url: new UntypedFormControl(this.element?.url ?? null, [CustomValidators.url()]),
      },
      CustomValidators.dateRules(CommonConstants.CERTIFICATE_DATE_RULES),
    );
    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    this.subscriptions.add(
      this.formGroup.controls["standard"].valueChanges.subscribe(
        async (newStandard: ISelectOption) => {
          await this.updateStandardTypeOptions(newStandard);
        },
      ),
    );

    this.hasFormValuesChanged = false;
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged =
          !this.formGroup.pristine && this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );

    this.initialFormValue = this.formGroup.value;
    this.overlay.dismissLoading();
  };

  public override save = async (): Promise<boolean> => {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    const payload = this.getSavePayload();

    try {
      this.element = await this.certificatesService.createOrUpdate(payload, this.element?.id);
      this.hasFormValuesChanged = false;
      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Certificate modified` : $localize`Certificate created`,
      );

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  };

  public override async afterSave(isSaveOnly: boolean): Promise<void> {
    if (isSaveOnly) {
      if (!this.isEditing()) {
        await this.routerService.navigate(this.routerService.getCertificateLink(this.element.id), {
          replaceUrl: true,
        });
      }
    }
  }

  protected override recordName(): string {
    return this.element?.number;
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.certificatesService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.certificatesService.delete(id);
  }

  protected getSavePayload = (): ICertificatePayload => {
    const standardValue = this.formGroup.controls["standard"].value.value;
    const standardTypeValue = this.formGroup.controls["standardType"].value?.value;

    const payload = {
      id: this.element?.id ?? undefined,
      number: this.formGroup.controls["number"].value.trim(),
      standard: `/common/standards/${standardValue}`,
      standardType: standardTypeValue
        ? `/common/standards/${standardValue}/types/${standardTypeValue}`
        : null,
      issuanceDate: FormUtils.getDateValueForPayload(this.formGroup.controls["issuanceDate"].value),
      validFromDate: FormUtils.getDateValueForPayload(
        this.formGroup.controls["validFromDate"].value,
      ),
      validToDate: FormUtils.getDateValueForPayload(
        this.formGroup.controls["validToDate"].value,
        StartEndEnum.END,
      ),
      url: FormUtils.addUrlProtocol(this.formGroup.controls["url"].value),
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  };

  protected override reloadElement = async (id: string): Promise<void> => {
    try {
      this.element = await this.certificatesService.get(id);
      this.setEditMode();
      await this.loadAttachments();
      await this.onReloadStandardOptions();
      await this.setupForm();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private updateStandardTypeOptions = async (standard: ISelectOption): Promise<void> => {
    try {
      this.standardTypeOptions.set([]);
      if (this.formGroup) {
        this.formGroup.controls["standardType"].setValue(null);
      }

      if (!standard?.value) {
        return;
      }
      const selectedStandardTypeId = this.element?.standardType
        ? CommonUtils.getUriId(this.element.standardType)
        : null;

      const standardTypes = await this.standardTypesService.getPage(standard.value as string);

      this.standardTypeOptions.set(
        standardTypes
          .filter(
            (s) =>
              s.recordState === RecordStateEnum.ACTIVE ||
              (selectedStandardTypeId && s.id === selectedStandardTypeId),
          )
          .map((s: IStandardType) => ({
            label: s.fullName,
            value: s.id,
          })),
      );

      this.cdr.detectChanges();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private onReloadStandardOptions = async (): Promise<void> => {
    this.standardOptions.set([]);
    try {
      const standards = await this.standardsService.getAll();
      const selectedStandardId = this.element?.standard
        ? CommonUtils.getUriId(this.element.standard)
        : null;

      this.standardOptions.set(
        standards
          .filter(
            (s) =>
              s.recordState === RecordStateEnum.ACTIVE ||
              (selectedStandardId && s.id === selectedStandardId),
          )
          .map((s: IStandard) => ({
            label: s.name,
            value: s.id,
          })),
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private async loadAttachments(): Promise<void> {
    this.selectedAttachments = await this.attachmentsService.getAll(
      AttachmentTypeEnum.CERTIFICATE,
      null,
      `/organisations/${this.activeOrganisationId}/certificates/${this.element.id}`,
    );
  }
}
