@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="full-width-items-container">
    @for (item of items; track item.id) {
      <div class="item hover-visibility-container">
        <app-item-card-content
          [item]="item"
          [type]="contentCardTypeEnum.REMOVE"
          [displayType]="itemCardContentDisplayTypeEnum.MATERIAL_NAMES"
          [isShowTags]="false"
          class="columns-3-grid"
          (itemQuantityChanged)="onSelectedQuantityChange(item)"
          (remove)="onRemove(item.id)"
        >
          <div
            form-fields
            [formGroup]="item.formGroup"
            class="options gap-14 no-alignment no-margin"
          >
            @if (type === "input") {
              <app-input
                [suffix]="
                  item.productDefaultUnit
                    ? item.productDefaultUnit.symbol
                    : item.unitOfMeasurement?.symbol
                "
                class="no-padding"
                formControlName="selectedQty"
                [label]="translations.selectedQtyLabel"
                [hint]="
                  item.productDefaultUnit
                    ? (item.formGroup.controls['selectedQty'].value
                      | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                    : ''
                "
                type="number"
              ></app-input>
              <app-input
                type="number"
                [label]="translations.remainingQtyLabel"
                formControlName="remainingQty"
                [suffix]="
                  item.productDefaultUnit
                    ? item.productDefaultUnit.symbol
                    : item.unitOfMeasurement?.symbol
                "
                [hint]="
                  item.productDefaultUnit
                    ? (item.formGroup.controls['remainingQty'].value
                      | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                    : ''
                "
                class="no-padding"
              ></app-input>
            } @else {
              <app-input
                type="number"
                [label]="translations.initialQtyLabel"
                formControlName="initialQty"
                [hint]="
                  item.productDefaultUnit
                    ? (item.formGroup.controls['initialQty'].value
                      | unitConversion: item.productDefaultUnit : item.unitOfMeasurement)
                    : ''
                "
                [suffix]="
                  item.productDefaultUnit
                    ? item.productDefaultUnit.symbol
                    : item.unitOfMeasurement?.symbol
                "
                class="no-padding"
              ></app-input>
            }
          </div>
        </app-item-card-content>
        @if (item.isLocationDifferentFromProcess && type === "output") {
          <p class="red">
            <small>
              The item's "Created at" location {{ item.createdAtLocationName }} isn't the same as
              process's "Location" {{ processLocation.label }}.
            </small>
          </p>
        }
      </div>
    }
  </div>
}
