import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  OnInit,
  OnDestroy,
  computed,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute } from "@angular/router";

import { firstValueFrom, Subscription } from "rxjs";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { ConfirmDialogComponent } from "@components/shared";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, RouteEnum } from "@shared/enums";
import { IDelivery, ISelectOption } from "@shared/interfaces";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import {
  NotificationService,
  AuthenticationService,
  CommonService,
  DeliveriesService,
  EudrExtensionService,
} from "@shared/services";
import { EudrDdsService } from "@shared/services/api/eudr-dds.service";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-reports-eudr-dds",
  templateUrl: "./reports-eudr-dds.component.html",
  styleUrls: ["./reports-eudr-dds.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportsEudrDdsComponent implements OnInit, OnDestroy {
  public readonly translations: any = {
    delete: TextConstants.DELETE,
    eudrCredentialsTooltip: $localize`Please add EU Information System credentials first`,
    submit: $localize`Submit`,
    submitting: $localize`Submitting...`,
    referenceNumbersStatus: $localize`1. Reference numbers & Status`,
    referenceNumber: $localize`1. Reference number`,
    internalReferenceNumber: $localize`Internal reference number`,
    status: TextConstants.STATUS,
    tracesReferenceNumber: $localize`TRACES reference number`,
    tracesVerificationNumber: $localize`TRACES verification number`,
    activity: $localize`2. Activity`,
    operatorTrader: $localize`3. Operator / Trader name and address`,
    placeOfActivity: $localize`4. Place of activity`,
    countryOfExit: $localize`Country of exit`,
    isoCode: $localize`ISO code`,
    countryOfActivity: $localize`Country of activity`,
    countryOfEntry: $localize`Country of entry`,
    additionalInfo: $localize`5. Additional information`,
    totals: $localize`Totals`,
    netMass: TextConstants.NET_MASS_KG,
    volume: TextConstants.VOLUME_M3,
    totalArea: $localize`Total area (ha)`,
    commodities: $localize`6. Commodity(-ies) or Product(-s)`,
  };

  public statement = signal<EudrDds.IDetails>(null);

  public delivery = signal<IDelivery>(null);

  public isLoading = signal<boolean>(true);

  public isSubmitting = signal<boolean>(false);

  public hasEudrCredentials = signal<boolean>(false);

  public countryOfActivityName = signal<string>("");

  public borderCrossCountryName = signal<string>("");

  public hsCodes = signal<ReportsEudrModel.IHsCode[]>([]);

  private countryOptions = signal<ISelectOption[]>([]);

  public title = computed(() => `Delivery ${this.delivery().deliveryId} due diligence statement`);

  private eudrDdsService: EudrDdsService = inject(EudrDdsService);

  private deliveriesService: DeliveriesService = inject(DeliveriesService);

  private route: ActivatedRoute = inject(ActivatedRoute);

  private commonService: CommonService = inject(CommonService);

  private routerService: RouterService = inject(RouterService);

  private notificationService: NotificationService = inject(NotificationService);

  public eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  private eudrExtensionService: EudrExtensionService = inject(EudrExtensionService);

  private authenticationService: AuthenticationService = inject(AuthenticationService);

  private httpClient: HttpClient = inject(HttpClient);

  private dialog: MatDialog = inject(MatDialog);

  private subscriptions = new Subscription();

  public readonly canAddModifyEntities: boolean = this.authenticationService.canAddModifyEntities();

  public readonly operatorSectionText = ReportsEudrModel.operatorSectionText;

  public isPending = computed(() => this.statement()?.status === EudrDds.StatusEnum.PENDING);

  public isSubmitted = computed(() => this.statement()?.status === EudrDds.StatusEnum.SUBMITTED);

  public isExportActivity = computed(
    () => this.statement()?.statement.activityType === ReportsEudrModel.ActivityEnum.EXPORT,
  );

  public statusTagClass = computed(() =>
    this.eudrDdsPageService.getStatusTagColor(this.statement().status),
  );

  public readonly statusEnum: typeof EudrDds.StatusEnum = EudrDds.StatusEnum;

  @ViewChild("tabGroup") tabGroup!: MatTabGroup;

  public readonly serviceUnavailableMsg = TextConstants.SERVICE_UNAVAILABLE;

  public isDdsServiceUnavailable = false;

  constructor() {
    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions.set(countriesOptions);
        },
      ),
    );
  }

  public async onLoadStatement(): Promise<EudrDds.IDetails> {
    try {
      const statementId = this.route.snapshot.params["id"];

      return this.eudrDdsService.get(statementId);
    } catch (error) {
      if (error.status === 503) {
        this.isDdsServiceUnavailable = true;
      }

      return null;
    }
  }

  public async onSavedReferences(): Promise<void> {
    this.isLoading.set(true);

    const statement = await this.onLoadStatement();

    this.statement.set(statement);

    this.isLoading.set(false);
  }

  public async ngOnInit(): Promise<void> {
    const statementId = this.route.snapshot.params["id"];

    const [fetchedStatement, hsCodes] = await Promise.all([
      this.onLoadStatement(),
      firstValueFrom(
        this.httpClient.get<ReportsEudrModel.IHsCode[]>(ReportsEudrModel.hsCodesJsonPath),
      ),
      this.checkEudrCredentials(),
    ]);

    let statement = fetchedStatement;

    if (this.canAddModifyEntities && statement.status !== EudrDds.StatusEnum.PENDING) {
      statement = await this.eudrDdsService.updateStatus(statementId);
    }

    const delivery = await this.deliveriesService.get(CommonUtils.getUriId(statement.deliveryUri));

    this.statement.set(statement);
    this.delivery.set(delivery);
    this.hsCodes.set(hsCodes);

    this.countryOfActivityName.set(
      this.getCountryFromOptions(statement.statement.countryOfActivity),
    );

    this.borderCrossCountryName.set(
      this.getCountryFromOptions(statement.statement.borderCrossCountry),
    );

    this.isLoading.set(false);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private async checkEudrCredentials(): Promise<void> {
    try {
      await this.eudrExtensionService.get();

      this.hasEudrCredentials.set(true);
    } catch (error) {
      if (error.status !== 404) {
        this.notificationService.showError(error);
      }
    }
  }

  private getCountryFromOptions(isoCode: string): string {
    const countryOption = this.countryOptions().find((countryOption: ISelectOption) => {
      return countryOption.value === isoCode;
    });

    return countryOption.label;
  }

  public async onDelete(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want to delete this statement?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.eudrDdsService.delete(this.statement().id);

        this.notificationService.showSuccess($localize`Statement removed`);

        this.routerService.navigate(RouteEnum.ADMIN_EXTENSIONS_EUDR);
      }
    });
  }

  public async onSubmit(): Promise<void> {
    try {
      this.isSubmitting.set(true);

      const statement = await this.eudrDdsService.submit(this.statement().id);

      this.statement.set(statement);

      this.notificationService.showSuccess($localize`Statement submitted`);
    } catch {
      this.notificationService.showError(
        $localize`An unexpected error happened. Please try again later`,
      );
    } finally {
      this.isSubmitting.set(false);
    }
  }
}
