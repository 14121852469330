<app-slide-overlay-header
  [title]="title()"
  icon="deployed_code"
  [isInboundShare]="true"
></app-slide-overlay-header>
<app-slide-overlay-content
  #slideOverlayContent
  [contentClass]="overlay.contentRightSidePanel() ? 'has-right-side-panel' : ''"
>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="item">
          <app-input
            enableViewMode
            formControlName="name"
            [label]="translations.nameLabel"
          ></app-input>
        </div>
        <div class="item">
          <app-input-select
            enableViewMode
            [label]="translations.baseUnitLabel"
            formControlName="baseUnit"
            [options]="unitOfMeasurementTypesOptions()"
          ></app-input-select>
        </div>
      </div>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="[]"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    <app-form-field-group
      [name]="translations.unitsLabel"
      class="pagination-fix display-block"
      groupNameRowClass="group-name-row"
    >
      <app-table
        [isLoading]="false"
        [columnDefs]="unitsOfMeasurementColDefs"
        [isPaginatorEnabled]="false"
        [isSearchEnabled]="false"
        [isRecordStateFilterEnabled]="false"
        [rowData]="selectedUnitsOfMeasurement"
      ></app-table>
    </app-form-field-group>
    <app-form-field-group
      [name]="
        isOldMaterialsEnabled ? translations.allowedMaterialsLabel : translations.materialsLabel
      "
      class="pagination-fix display-block"
      groupNameRowClass="group-name-row"
    >
      <app-materials-table
        [materials]="isOldMaterialsEnabled ? element()?.allowedMaterials : element()?.materials"
        [columns]="['recordState', 'nameWithRemove', 'category']"
        [isPaginatorEnabled]="false"
        [isSearchEnabled]="false"
        [isRecordStateFilterEnabled]="false"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-materials-table>
    </app-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
</app-slide-overlay-content>
