import { ChangeDetectionStrategy, Component, signal, ViewChild } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { filter } from "rxjs";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { RouteEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { AuthenticationService, CommonService } from "@shared/services";

import {
  SettingsAddConnectionDialogComponent,
  SettingsExistingConnectionsTableComponent,
  SettingsRequestedConnectionsTableComponent,
} from "..";

@Component({
  standalone: false,
  templateUrl: "./settings-connections.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsConnectionsComponent {
  @ViewChild("existingConnectionsTable")
  existingConnectionsTable: SettingsExistingConnectionsTableComponent;

  @ViewChild("requestedConnectionsTable")
  requestedConnectionsTable: SettingsRequestedConnectionsTableComponent;

  public readonly routingEnum = RouteEnum;

  public readonly selectedTabIndex = signal(0);

  public activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  public countryOptions = signal<ISelectOption[]>([]);

  public readonly translations = {
    title: $localize`Connections`,
    titleBackText: TextConstants.BACK_TO_ADMIN,
  };

  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private overlayPageService: SlideOverlayPageService,
  ) {
    this.commonService.countriesOptionsObservable$
      .pipe(
        filter((countries) => !!countries),
        takeUntilDestroyed(),
      )
      .subscribe((countriesOptions: ISelectOption[]) => {
        this.countryOptions.set(countriesOptions);
      });
  }

  public onAdd = async (): Promise<void> => {
    const result = await this.overlayPageService.openDialog<
      { hasSaved: boolean },
      { activeOrganisationId: string; countryOptions: ISelectOption[] }
    >(SettingsAddConnectionDialogComponent, {
      activeOrganisationId: this.activeOrganisationId,
      countryOptions: this.countryOptions(),
    });

    if (result?.hasSaved) {
      this.selectedTabIndex.set(1);
      await this.requestedConnectionsTable.getAll();
    }
  };

  public onRequestAccepted = async (): Promise<void> => {
    this.selectedTabIndex.set(0);
    this.existingConnectionsTable.getAll();
  };
}
