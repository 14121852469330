import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  signal,
  ViewChild,
  OnInit,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { IAnalysis } from "src/app/shared/interfaces";

@Component({
  standalone: false,
  selector: "app-analysis-details-slide-over",
  templateUrl: "./analysis-details-slide-over.component.html",
  styleUrls: ["./analysis-details-slide-over.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalysisDetailsSlideOverComponent implements AfterViewInit, OnInit {
  @Input()
  public element: IAnalysis;

  @ViewChild("iframe", { static: false }) iframe: ElementRef;

  public isLoading = signal(true);

  public analysisSafeUrl = signal<SafeResourceUrl>(null);

  constructor(private domSanitizer: DomSanitizer) {}

  public ngOnInit(): void {
    this.analysisSafeUrl.set(
      this.domSanitizer.bypassSecurityTrustResourceUrl(this.element.providerResponse.response),
    );
  }

  public ngAfterViewInit(): void {
    this.iframe.nativeElement.onload = () => {
      this.isLoading.set(false);
    };
  }

  public onFullscreen = (): void => {
    this.iframe.nativeElement.requestFullscreen();
  };
}
