import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { TextConstants } from "@shared/constants";
import { EntityTypeEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import { IPublicOrganisation, ISelectOption } from "@shared/interfaces";
import { CommonService, OrganisationsService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-public-organisation-overlay",
  templateUrl: "./public-organisation-overlay.component.html",
  styleUrl: "../organisation-overlay-common.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PublicOrganisationOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit, OnDestroy
{
  formGroup: UntypedFormGroup = null;

  countryOptions: ISelectOption[] = [];

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.ORGANISATION_DETAILS, isEnabled: true }],
    ]),
  );

  override element = signal<IPublicOrganisation>(null);

  public override entityType = EntityTypeEnum.CONNECTIONS;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    websiteLabel: TextConstants.WEBSITE,
    addressLabel: TextConstants.ADDRESS,
    countryLabel: TextConstants.COUNTRY,
    zipCodeLabel: TextConstants.ZIP_POSTCODE,
    regionLabel: TextConstants.STATE_REGION,
  };

  constructor(
    private commonService: CommonService,
    private organisationService: OrganisationsService,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.countryOptions = countriesOptions;
        },
      ),
    );
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_PUBLIC_ORGANISATION)) {
      return;
    }
    if (!this.recordId) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);

      return;
    }
    await this.reloadElement(this.recordId);
    //await this.setMenuItemFromURLParam();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  override setupForm(): void {
    this.formGroup = new UntypedFormGroup({
      name: new UntypedFormControl(this.element()?.name ?? null),
      website: new UntypedFormControl(this.element()?.website ?? null),
      street: new UntypedFormControl(this.element()?.address?.street ?? null),
      region: new UntypedFormControl(this.element()?.address?.region ?? null),
      zipCode: new UntypedFormControl(this.element()?.address?.zipCode ?? null),
      country: new UntypedFormControl(
        this.countryOptions.find((c) => c.value === this.element().address?.country),
      ),
    });
  }

  protected override async reloadElement(id: string): Promise<void> {
    try {
      this.overlay.showLoading();
      const connection = await this.organisationService.getPublic(id);

      this.element.set(connection);
      this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  protected override recordName(): string {
    return this.element()?.name;
  }
}
