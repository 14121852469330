<div class="container-space-between">
  <app-page-title [title]="translations.title"></app-page-title>

  <app-button-group [visible]="canAddModifyEntities">
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<app-processes-table
  *ngIf="!isLoadingProcessTypes()"
  [allProcessTypes]="allProcessTypes"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
></app-processes-table>
