<div class="upload-documents-buttons-container">
  @if (initial) {
    <app-button-group>
      <app-button (pressed)="cancelUpload.emit()" [visible]="isShowCancelButton" type="ghost" i18n>
        Cancel
      </app-button>
      <app-button [disabled]="isSubmitButtonDisabled" (pressed)="tryUploadAll.emit()">
        <mat-icon icon-left>upload</mat-icon>

        <ng-container i18n>Upload documents</ng-container>
      </app-button>
    </app-button-group>
  }
  @if (hasError) {
    <app-button (pressed)="retryAll.emit()">
      <mat-icon icon-left>refresh</mat-icon>

      <ng-container i18n>Retry all failed uploads</ng-container>
    </app-button>
  }
  @if (finished) {
    <app-button [disabled]="isDoneButtonDisabled" (pressed)="done.emit()">
      <mat-icon icon-left>check</mat-icon>
      {{ doneButtonText }}
    </app-button>
  }
</div>
