<div class="container-space-between">
  <app-page-title
    title="User details"
    [goBackRoute]="routingEnum.ADMIN_USERS"
    backText="Back to Users"
  ></app-page-title>
</div>

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="true">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <h3>{{ mainInformationText }}</h3>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row-container">
        <div class="row row-4">
          <div class="item">
            <p class="details-label">First name</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.firstName"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Last name</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.lastName"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Email address</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.emailAddress"
            ></app-value-and-copy-button>
          </div>
          <div class="item">
            <p class="details-label">Organisation(s)</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.organisationNames"
            ></app-value-and-copy-button>
          </div>
        </div>
        <div class="row row-4">
          <div class="item">
            <p class="details-label">Last active</p>
            <app-value-and-copy-button
              valueClass="details-value"
              [value]="element.lastActive ? (element.lastActive | localTime) : null"
            ></app-value-and-copy-button>
          </div>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
}
