import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { NotificationService } from "@shared/services";

import { CommonConstants, TextConstants } from "../../../../shared/constants";
import { IDocumentType, ILocationType, IPageableContent } from "../../../../shared/interfaces";
import { ApiService } from "../../../../shared/services";
import { FormUtils } from "../../../../shared/utils";

@Injectable({
  providedIn: "root",
})
export class InputSelectService {
  constructor(
    private notificationService: NotificationService,
    private apiService: ApiService,
  ) {}

  getDropdownIcon(panelOpen: boolean): string {
    return panelOpen ? "keyboard_arrow_up" : "keyboard_arrow_down";
  }

  filterOptions(value: string, options: InputSelectOption[]): InputSelectOption[] {
    const filterValue = value.toLowerCase();

    return options.filter((option) => option.label.toLowerCase().includes(filterValue));
  }

  findSelectedValue(value: unknown, options: InputSelectOption[]): InputSelectOption {
    const valueToCompare = this.extractValue(value);

    return options.find((option) => option.value === valueToCompare) || null;
  }

  findSelectedValues(value: unknown, options: InputSelectOption[]): InputSelectOption[] {
    const valuesToCompare = this.extractValues(value);

    return (options || []).filter((option) => valuesToCompare.includes(option.value));
  }

  public async createTag(endpoint: string, payload: unknown): Promise<{ id }> {
    return this.apiService
      .post<ILocationType>(endpoint, payload)
      .toPromise()
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);

        return Promise.reject(error);
      });
  }

  public async getTags(endpoint: string): Promise<InputSelectOption[]> {
    const url = `${endpoint}?${FormUtils.addUrlParams({
      size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
      page: 0,
    })}`;

    return this.apiService
      .get(url)
      .toPromise()
      .then((response: IPageableContent<ILocationType | IDocumentType>) => {
        return response.content.map((item): InputSelectOption => {
          if ("type" in item) {
            return {
              label: item.type,
              value: item.id,
              recordState: item.recordState,
              icon: item.pointOfOrigin ? "target" : undefined,
              iconTooltip: item.pointOfOrigin ? TextConstants.POINT_OF_ORIGIN_CHIP : undefined,
            };
          } else if ("name" in item) {
            return { label: item.name, value: item.id, recordState: item.recordState };
          }
          throw new Error($localize`Invalid object type`);
        });
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);

        return Promise.reject(error);
      });
  }

  public removeTag(endpoint: string, id: unknown): Promise<void> {
    return this.apiService.delete<void>(`${endpoint}/${id}`).toPromise();
  }

  public extractValues(value: unknown): unknown[] {
    if (Array.isArray(value)) {
      return value.map(this.extractValue);
    } else {
      return [this.extractValue(value)];
    }
  }

  private extractValue(value: unknown): unknown {
    let valueToCompare = value;

    if (value !== null && typeof value === "object" && "value" in value) {
      valueToCompare = value.value;
    }

    return valueToCompare;
  }
}
