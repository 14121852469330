import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  input,
  Input,
  Output,
  signal,
} from "@angular/core";
import { ValidationErrors } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";

import { ConfirmDialogComponent } from "@components/index";
import { PdfSplitterModel } from "@components/shared/pdf-splitter/pdf-splitter.model";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, EntityTypeEnum, UploadFileErrorTypeEnum } from "@shared/enums";
import { IFileUpload, ISelectOption, ITagExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-document-list",
  templateUrl: "./document-list.component.html",
  styleUrl: "./document-list.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListComponent {
  files = input.required<IFileUpload[]>();

  allDocumentNames = input.required<string[]>();

  @Input() documentTypeOptions: ISelectOption[] = [];

  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly uploadFileErrorTypeEnum = UploadFileErrorTypeEnum;

  public readonly typeTooltipHint: string = TextConstants.DOCUMENT_TYPE_TOOLTIP_HINT;

  closeSplitOverlay = signal<boolean>(true);

  public selectedFile: IFileUpload;

  public fileIndex: number;

  private dialog = inject(MatDialog);

  public readonly translations: any = {
    nameLabel: $localize`Document name`,
    typeLabel: TextConstants.TYPE,
    tagsLabel: TextConstants.TAGS,
    isDatesEnabledLabel: $localize`Specify issuance date and/or validity period`,
    issuanceLabel: TextConstants.ISSUANCE_DATE,
    validityStartLabel: TextConstants.VALID_FROM,
    validityEndLabel: TextConstants.VALID_TO,
    viewFileTp: $localize`View file`,
    splitDocTp: $localize`Split document`,
    removeDocTp: $localize`Remove document`,
  };

  @Output() clickViewFile: EventEmitter<number> = new EventEmitter();

  @Output() clickSplitDocument: EventEmitter<number> = new EventEmitter();

  @Output() clickRemoveFile: EventEmitter<number> = new EventEmitter();

  @Output() splitFile: EventEmitter<PdfSplitterModel.ISplitData> = new EventEmitter();

  onClickViewFileEvent(index: number): void {
    this.clickViewFile.emit(index);
  }

  onClickDeleteFileEvent(index: number): void {
    this.clickRemoveFile.emit(index);
  }

  onClickSplitDocument(index: number): void {
    this.onOpenSplitSlideOver(index);
  }

  public canExitPdfSplitter(): true {
    return true;
  }

  validationError(file: IFileUpload): ValidationErrors {
    if (file.errors[0]?.type === UploadFileErrorTypeEnum.DUPLICATED_NAME) {
      return { fileDuplication: TextConstants.DOCUMENT_NAME_DUPLICATION_ERROR };
    }

    return {};
  }

  onTagsChanged(file: IFileUpload, tags: ITagExtended[]): void {
    file.formGroup.controls["tags"].setValue(tags);
  }

  public onOpenSplitSlideOver(index: number): void {
    this.selectedFile = this.files()[index];
    this.fileIndex = index;

    this.closeSplitOverlay.set(false);
  }

  public async onCloseSplitSlideOver(): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        contentText: $localize`Would you like to cancel the split action? All changes will be discarded.`,
        confirmButtonText: TextConstants.DISCARD_CHANGES_LEAVE,
        confirmButtonColor: "danger",
        confirmButtonIcon: "close",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    if (response === ConfirmDialogResponseEnum.CONFIRM) {
      this.closeSplitOverlay.set(true);

      return true;
    }

    return false;
  }

  public onSplit(documents: PdfSplitterModel.IDocument[]): void {
    this.splitFile.emit({ documents, index: this.fileIndex });
    this.closeSplitOverlay.set(true);
  }
}
