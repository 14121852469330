@if (displayAddAndDeleteButtons()) {
  <div class="container-flex-right mb-14">
    <app-button-group>
      <app-button (pressed)="onAddReference()">
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add new</ng-container>
      </app-button>
    </app-button-group>
  </div>
}

<app-table
  [rowData]="rowData()"
  [columnDefs]="colDefs()"
  [isLoading]="isLoading()"
  [isSearchEnabled]="false"
  [isRecordStateFilterEnabled]="false"
  [isPaginatorEnabled]="false"
></app-table>
