import { ChangeDetectionStrategy, Component } from "@angular/core";

import { EntityTypeEnum } from "@shared/enums";
import { RouterService } from "@shared/services/router.service";

interface IShortcutItem {
  title: string;
  icon: string;
  entityType: EntityTypeEnum;
  isSvgIcon?: boolean;
  iconClass?: string;
}

@Component({
  standalone: false,
  selector: "app-shortcut-add-menu",
  templateUrl: "./shortcut-add-menu.component.html",
  styleUrls: ["./shortcut-add-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortcutAddMenuComponent {
  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly groupedMenuItems: IShortcutItem[][] = [
    [
      {
        title: $localize`Add Location`,
        icon: "location_on",
        entityType: EntityTypeEnum.LOCATIONS,
      },
      {
        title: $localize`Add Organisation`,
        icon: "organisations",
        entityType: EntityTypeEnum.ORGANISATIONS,
        isSvgIcon: true,
      },
    ],
    [
      {
        title: $localize`Add Product`,
        icon: "deployed_code",
        entityType: EntityTypeEnum.PRODUCTS,
      },
      {
        title: $localize`Add Item`,
        icon: "package_2",
        entityType: EntityTypeEnum.ITEMS,
      },
      {
        title: $localize`Add Delivery`,
        icon: "local_shipping",
        entityType: EntityTypeEnum.DELIVERIES,
      },
      {
        title: $localize`Add Process`,
        icon: "sync",
        entityType: EntityTypeEnum.PROCESSES,
        iconClass: "rotate-horizontal",
      },
    ],
    [
      {
        title: $localize`Add Document`,
        icon: "docs",
        entityType: EntityTypeEnum.DOCUMENTS,
      },
      {
        title: $localize`Add Certificate`,
        icon: "verified_user",
        entityType: EntityTypeEnum.CERTIFICATES,
      },
    ],
  ];

  constructor(private routerService: RouterService) {}

  onClick = async (entityType: EntityTypeEnum): Promise<void> => {
    await this.routerService.navigateByType(entityType);
  };
}
