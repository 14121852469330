import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Router } from "@angular/router";

import { RecordStateEnum, RouteEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

@Component({
  standalone: false,
  templateUrl: "./supply-chains.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly routingEnum = RouteEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Supply chains`,
    titleHint: $localize`Access and manage all your existing supply chains. Edit, view details,
        and create new ones to streamline your operations.`,
  };

  constructor(
    private router: Router,
    public authenticationService: AuthenticationService,
  ) {}

  public onNavigate = async (route: RouteEnum): Promise<void> => {
    await this.router.navigate([`/${route}`]);
  };
}
