import { ICellRendererParams } from "ag-grid-community";

import { NavigationParams } from "@shared/services/router.service";

import { CommonUtils } from "./common.utils";

// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
const getNodeKeyOrFieldRenderer = (cell: ICellRendererParams, fieldRenderer: Function): any => {
  if (cell.node.group) {
    if (cell.column.getColId() === "ag-Grid-AutoColumn") {
      return cell.node.key;
    } else {
      return "";
    }
  }

  return fieldRenderer();
};

const capitaliseFirstLetter = (cell: ICellRendererParams): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if (!cell?.value) {
      return "-";
    }

    return CommonUtils.capitaliseFirstLetter(cell.value);
  });
};

const attachmentNameOrId = (cell: ICellRendererParams): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if (!cell.data?.targetName || !cell.data?.targetId) {
      return "-";
    }

    return cell.data.targetName ?? cell.data.targetId;
  });
};

const country = (
  cell: ICellRendererParams<{ address: { country: string; countryName: string } }>,
) => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if (!cell.data?.address?.country) {
      return "-";
    }

    return `<img src="assets/images/flags/${cell.data.address.country.toLowerCase()}.svg" class="flag small-flag" /> ${
      cell.data.address.countryName
    }`;
  });
};

const colorBox = (cell: ICellRendererParams): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if (!cell.data?.color) {
      return "-";
    }

    return `<div class="color-box ${cell.data.color}"></div>`;
  });
};

const recordState = (cell: ICellRendererParams): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if (cell.data?.recordState === "ARCHIVED") {
      return getIconHtml("inventory_2");
    }

    return "";
  });
};

const roleNames = (cell: ICellRendererParams): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    const roles = cell.data?.role ? [cell.data.role] : cell.data?.roles;

    if (roles) {
      return CommonUtils.getRolesNames(roles);
    }

    return "-";
  });
};

const showIconIfValue = (
  cell: ICellRendererParams,
  icon: string = "check",
  iconClass: string = "green",
  noValueDisplay: string = "-",
  showFunction: any = undefined,
): any => {
  return getNodeKeyOrFieldRenderer(cell, () => {
    if ((showFunction && showFunction(cell)) || (!showFunction && !!cell.value)) {
      return getIconHtml(icon, iconClass);
    }

    return noValueDisplay;
  });
};

const getIconHtml = (icon: string, iconClass: string = ""): string =>
  `<mat-icon class="icon-16 mat-icon material-symbols-outlined mat-icon-no-color ${iconClass}">${icon}</mat-icon>`;

const getLink = (params: ICellRendererParams, data: any): NavigationParams => {
  const linkRoute = params["linkRoute"];
  const linkRouteIdParam = params["linkRouteIdParam"];
  const linkRouteOrganisationIdParam = params["linkRouteOrganisationIdParam"];
  const tab = params["linkRouteTab"];
  const linkRouteNameParam = params["linkRouteNameParam"];
  let linkRouteFn = params["linkRouteFn"];

  linkRouteFn =
    typeof linkRouteFn == "string"
      ? CommonUtils.getObjectNestedProperty(linkRouteFn, data)
      : linkRouteFn;

  const routeIdValue = linkRouteIdParam
    ? CommonUtils.getObjectNestedProperty(linkRouteIdParam, data)
    : undefined;
  const organisationId = linkRouteOrganisationIdParam
    ? CommonUtils.getObjectNestedProperty(linkRouteOrganisationIdParam, data)
    : undefined;
  const name = linkRouteNameParam
    ? CommonUtils.getObjectNestedProperty(linkRouteNameParam, data)
    : undefined;

  const extraParams: any = {};

  if (tab) {
    extraParams["tab"] = tab;
  }
  if (organisationId) {
    extraParams["organisationId"] = organisationId;
  }
  if (name) {
    extraParams["name"] = name;
  }

  return linkRouteFn
    ? linkRouteFn(routeIdValue, false, extraParams)
    : {
        commands: linkRoute
          ? [`/${linkRoute}${routeIdValue ? `/${routeIdValue}` : ""}`]
          : undefined,
        extras: {},
      };
};

export const cellContainerClass = "cell-container";
export const cellContainerSelector = `.${cellContainerClass}`;

export const CellRendererUtils = {
  attachmentNameOrId,
  capitaliseFirstLetter,
  country,
  colorBox,
  recordState,
  roleNames,
  showIconIfValue,
  getLink,
  cellContainerClass,
  cellContainerSelector,
};
