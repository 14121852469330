import { Pipe, PipeTransform } from "@angular/core";

import { CommonConstants } from "@shared/constants";
import { DateUtils } from "@shared/utils";

import { AppDatePipe } from "./date.pipe";

@Pipe({
  standalone: false,
  name: "localTime",
})
export class LocalTimePipe implements PipeTransform {
  constructor(private appDatePipe: AppDatePipe) {}

  transform(utcTime: string, dateFormat: string = CommonConstants.DATE_TIME_FORMAT): string {
    return this.appDatePipe.transform(
      DateUtils.getFormattedDateForPipe(utcTime),
      dateFormat,
      this.getLocalTimeZone(),
    );
  }

  private getLocalTimeZone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
