@if (enableViewMode) {
  <app-form-input-readonly
    [label]="label"
    [value]="innerText"
    [isTextarea]="isTextarea"
    [copyMode]="viewModeCopyMode"
  />
} @else {
  @if (label) {
    <app-form-label [label]="label" />
  }

  <div
    [attr.data-placeholder]="placeholder"
    #editableDiv
    [contentEditable]="!isDisabled()"
    class="editable-div break-word"
  >
    @if (innerText && isDisabled()) {
      {{ innerText }}
    }
  </div>

  <app-form-hint
    [class]="hintErrorClass"
    [errors]="ngControl?.errors"
    [isDisabled]="isDisabled()"
    [isOptional]="isOptional"
    [showError]="hasValidationError"
  />
}
