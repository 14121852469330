export const BackendErrorConstants = {
  /** Connections */
  DELETE_CONNECTION_FORBIDDEN_SHARED: $localize`Organisation cannot be deleted because shared data already exists for it`,
  CONNECTION_IS_ARCHIVED: $localize`The connection is archived`,
  DUPLICATE_PUBLIC_URI: $localize`Public URI already exists`,
  INVALID_PUBLIC_URI: $localize`Public URI should be valid and not be your own`,
  ORGANISATIONS_NOT_CONNECTED: $localize`The organisation you are trying to share with is not connected with you`,
  PUBLIC_URI_NOT_MATCHING: $localize`Public uri does not match`,
  UPDATE_PUBLIC_URI_FORBIDDEN: $localize`User does not have permissions to update public uri`,
  UPDATE_PUBLIC_URI_NOT_ALLOWED: $localize`Public uri cannot be changed`,
  UPDATE_PUBLIC_URI_NOT_ALLOWED_SHARED: $localize`Public uri cannot be changed because shared data already exists for this connection`,
  DELETE_CONNECTION_FORBIDDEN: $localize`Organisation cannot be deleted as it is already mentioned in other records`,

  /** Risk assessment */

  DELETE_ENTITY_FORBIDDEN: $localize`The selected entity cannot be deleted because there is a risk assessment associated with it`,
  DUPLICATE_INDICATOR_GROUP_NAME: $localize`Indicator group names must be unique`,
  DUPLICATE_RISK_LEVEL_TITLE: $localize`Risk level titles must be unique`,
  INVALID_COMPLETED_DATE: $localize`Risk report completed date cannot be after validity date`,
  RISK_ASSESSMENT_TEMPLATE_ARCHIVED: $localize`The selected risk assessment template is archived`,

  /** Comments */

  DELETE_COMMENT_FORBIDDEN: $localize`User does not have permissions to delete comment`,
  UPDATE_COMMENT_FORBIDDEN: $localize`User does not have permissions to update comment`,

  /** Tags */

  TAG_FILTER_PARAMETERS_MISSING: $localize`Tag filter parameters cannot be empty`,

  /**Sharing */

  DUPLICATE_MAPPING: $localize`Mapping already exists for this inbound record`,
  MAPPING_NOT_ALLOWED: $localize`The selected record types cannot be mapped`,
  MAPPING_NOT_SUPPORTED: $localize`Mapping is not supported for this inbound record`,
  INBOUND_SHARE_ENTRY_NOT_PENDING: $localize`Inbound share entry is not in PENDING state`,

  /** EUDRDDS */

  DELETE_CREDENTIALS_SERVICE: $localize`Could not delete stored credentials`,
  DELETE_DDS_SERVICE: $localize`Could not delete stored statement`,
  GET_CREDENTIALS_SERVICE: $localize`Could not retrieve stored credentials`,
  GET_DDS_SERVICE: $localize`Could not retrieve stored statement`,
  GET_DDS_LIST_SERVICE: $localize`Could not retrieve list of stored statements`,
  PING_USING_CREDENTIALS: $localize`Could not validate the stored credentials against the EU IS service`,
  STORE_CREDENTIALS_SERVICE: $localize`Could not store credentials`,
  STORE_DDS_SERVICE: $localize`Could not add new due diligence statement`,
  SUBMIT_DDS_SERVICE: $localize`Could not submit the due diligence statement to TRACES`,
  UPDATE_DDS_SERVICE: $localize`Could not update due diligence statement`,

  /** Geolocation */

  MISSING_LOCATION_GEO_JSON_FILE: $localize`Selected location has no GeoJson file.`,
  INVALID_RADIUS_PROPERTY: $localize`The selected GeoJSON file contains a Point geometry with invalid or missing 'radius' property`,

  /**Users */

  ACCEPT_INVITATION_FORBIDDEN: $localize`User is not allowed to accept invitation`,
  ACTIVATE_ARCHIVED_USER_UNAVAILABLE: $localize`Archived users have to be re-invited`,
  ACTIVATE_USER_UNAVAILABLE: $localize`Individual is already active`,
  ADD_USER_UNAVAILABLE: $localize`User is already part of this organisation`,
  DELETE_INVITATION_FORBIDDEN: $localize`User does not have permission to delete invitation`,
  EMAIL_ALREADY_SENT: $localize`Email has already been send a few moments ago`,
  INVALID_NEW_PASSWORD: $localize`New password cannot be the same as old password`,
  INVITE_USER_FORBIDDEN: $localize`User does not have permission to invite user`,
  LAST_ACCOUNT_OWNER_CANNOT_LEAVE_ORGANISATION: $localize`User is the only remaining account owner in the organisation`,
  LEAVE_ORGANISATION_UNAVAILABLE: $localize`User has already left this organisation`,
  REJECTED_INVITATION: $localize`Invitation has already been rejected`,
  REJECT_INVITATION_FORBIDDEN: $localize`User is not allowed to reject invitation`,
  RESEND_INVITATION_FORBIDDEN: $localize`User does not have permission to resend invitation`,
  SELF_UPDATE_FORBIDDEN: $localize`User cannot self-update`,
  UPDATE_USER_ROLES_FORBIDDEN: $localize`User does not have permission to update user role`,
  USER_HAS_NO_ORGANISATIONS: $localize`Individual has no associated Organisations`,

  /** Locations */

  MISSING_TYPE: $localize`Location should have at least one type`,
  UNSUPPORTED_GEO_JSON_POINT_STRUCTURE: $localize`When you specify a point in a GeoJSON feature, only one feature is allowed`,

  /** Custom fields */

  CUSTOM_FIELD_DEFINITION_ARCHIVED: $localize`The custom field definition is archived`,
  INVALID_CUSTOM_FIELD_RESOURCE_TYPE: $localize`The custom field definition is not valid for that resource type`,
  MISSING_RESOURCE_TYPE: $localize`At least 1 resource type must be selected`,
  NOT_ALLOWED_TO_DELETE_CUSTOM_FIELD_DEFINITION: $localize`Cannot delete custom field definition which is used in records`,

  /**Processes */

  INVALID_ITEM_QUANTITY: $localize`Selected input quantity of an item with measurement type UNIT has to be an integer value`,
  INVALID_ITEM_QUANTITY_VALUE: $localize`Selected quantity of an item cannot be negative or 0`,
  INVALID_PROCESS_OUTPUT_LOCATION: $localize`Location of the output item has to match the location of the process`,
  INVALID_PROCESS_OUTPUT: $localize`The item was output of another process`,

  /** Items */

  INVALID_INITIAL_QUANTITY_VALUE: $localize`Item's initial quantity cannot be negative or 0`,
  NOT_ALLOWED_TO_EDIT_ITEM_CREATED_AT_LOCATION: $localize`Item's created at location value cannot be changed, as it is already part of a process output`,
  NOT_ALLOWED_TO_EDIT_ITEM_MATERIALS: $localize`Item's materials cannot be changed, as it is already part of one or more deliveries or processes`,
  NOT_ALLOWED_TO_EDIT_ITEM_PRODUCT: $localize`Item's product cannot be changed, as it is already part of one or more deliveries or processes`,

  /**Deliveries */

  INVALID_DELIVERED_DATE: $localize`Delivered date cannot be before sent date`,
  INVALID_DELIVERY_LOCATION: $localize`The delivery location cannot be the same as the current location`,

  /** Docs */

  DOCUMENT_CONTENT_ALREADY_EXISTS: $localize`Content has already been uploaded`,

  /** Supply chain */

  MISSING_SUPPLY_CHAIN_LOCATION: $localize`Supply chain should include at least 1 location`,

  /** Products */

  INVALID_DEFAULT_UNIT: $localize`The default unit is not in the custom units and not the base unit`,
  INVALID_PRODUCT_MAPPING: $localize`A product can only be mapped to another product with the same base unit.`,

  /** Connect requests */

  CONNECT_REQUEST_ALREADY_EXISTS: $localize`A connect request already exists for this organisation`,
  CONNECT_REQUEST_NOT_PENDING: $localize`Connect request is not in PENDING state`,
  NOT_ALLOWED_TO_DELETE_CONNECT_REQUEST: $localize`Cannot delete connect request that is not in PENDING state.`,

  /** General */

  INVALID_CHARACTERS: $localize`Field contains invalid characters`,
  INVALID_ISSUANCE_DATE: $localize`Issuance date cannot be after validity start date`,
  INVALID_MIN_MAX_VALUES: $localize`Number max value must be greater than min value`,
  INVALID_RANGE_DATE: $localize`Range start date cannot be after range end date`,
  INVALID_VALIDITY_START_DATE: $localize`Validity start date cannot be after validity end date`,
  INVALID_VALUE: $localize`Invalid value`,
  MANAGE_ATTACHMENTS_FORBIDDEN: $localize`User is not allowed to manage system attachments directly`,
  MODIFICATION_LOCK_FAILURE: $localize`Data has been modified by someone else, please try again`,
  MUST_NOT_BE_EMPTY: $localize`Must not be empty`,
  NO_UPDATE_VALUES_PROVIDED: $localize`No values send to update`,
  NOT_ALLOWED_TO_LINK_SAME_ENTITY: $localize`Link between the same entity cannot be created`,
  NOT_FOUND: $localize`The selected resource could not be found`,
  ORGANISATION_RECORD_MISMATCH: $localize`All referenced records have to belong to the same organisation`,
};
