<section
  [class.more-padding-bottom]="
    !(isRegularUser && enableTags() && instance?.entityType && instance?.recordId)
  "
  class="page-title-container"
>
  <div class="row gap-10">
    @if (isSvgIcon()) {
      <mat-icon class="page-icon" [svgIcon]="icon()" />
    } @else {
      <mat-icon class="page-icon" [ngClass]="iconClass()">{{ icon() }}</mat-icon>
    }
    <div class="page-title">
      {{ title() }}
      @if (isRecordActive) {
        <app-tag-group class="ml-12 no-padding" [tags]="['Archived']"></app-tag-group>
      }
    </div>

    <section class="right-side-buttons">
      @if (overlay.viewMode()) {
        @if (!this.overlay.loading()) {
          <ng-content select="[right-side-button]"></ng-content>
        }
        @if (shouldShowMoreActionsButton()) {
          <app-button class="page-button-more" size="large" type="icon" [matMenuTriggerFor]="menu">
            <mat-icon>more_horiz</mat-icon>
          </app-button>
        }
      }
    </section>
  </div>

  @if (isRegularUser && instance?.entityType && enableTags()) {
    <div class="entity-tag-container">
      <app-entity-tags
        [initialTags]="initialTags()"
        [entityType]="instance.entityType"
        [elementId]="instance.recordId"
        (tagsChanged)="onTagsChanged($event)"
      ></app-entity-tags>
    </div>
  }
</section>

<mat-menu #menu class="dropdown-menu">
  <app-dropdown-menu>
    @if (canDuplicate()) {
      <app-dropdown-menu-item (pressed)="duplicateHandler()">
        <mat-icon>file_copy</mat-icon>
        <ng-container i18n>Duplicate</ng-container>
        <mat-icon class="duplicate-icon" [tp]="translations.duplicateTp">help</mat-icon>
      </app-dropdown-menu-item>
    }
    @if (canArchive()) {
      <app-dropdown-menu-item (pressed)="toggleArchive()">
        <mat-icon>{{ isRecordActive ? "inventory" : "archive" }}</mat-icon>

        @if (isRecordActive) {
          <ng-container i18n>Unarchive</ng-container>
        } @else {
          <ng-container i18n>Archive</ng-container>
        }
      </app-dropdown-menu-item>
    }
    @if (canShare()) {
      <app-dropdown-menu-item (pressed)="shareHandler()">
        <mat-icon>share</mat-icon>
        <ng-container i18n>Share</ng-container>
      </app-dropdown-menu-item>
    }
    @if (canSend()) {
      <app-dropdown-menu-item (pressed)="sendHandler()">
        <mat-icon>send</mat-icon>
        <ng-container i18n>Send</ng-container>
      </app-dropdown-menu-item>
    }
    @if (canDelete()) {
      <app-dropdown-menu-item (pressed)="deleteHandler()">
        <mat-icon>delete</mat-icon>
        <ng-container i18n>Delete</ng-container>
      </app-dropdown-menu-item>
    }
    <ng-content select="[additional-options]"></ng-content>
  </app-dropdown-menu>
</mat-menu>
