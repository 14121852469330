import { Pipe, PipeTransform } from "@angular/core";

import { IRecordResponse } from "../interfaces";
import { RulesetUtils } from "../utils/ruleset.utils";

@Pipe({
  standalone: false,
  name: "conditionsMetChecker",
})
export class ConditionsMetCheckerPipe implements PipeTransform {
  transform(value: string, records: IRecordResponse[]): boolean {
    const currentRecord = records?.find((record) => record.uri.includes(value));
    const expectations = RulesetUtils.getExpectationsGroups(currentRecord?.rulesets ?? []);
    const metAllConditions: boolean =
      !expectations.length || expectations.every((group) => group.conditionsMet === true);

    return metAllConditions;
  }
}
