import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  booleanAttribute,
  input,
} from "@angular/core";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { IBaseUnit, ICustomEvent, ICustomUnitOfMeasurement } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-units-of-measurement-list-items",
  templateUrl: "./units-of-measurement-list-items.component.html",
  styleUrl: "./units-of-measurement-list-items.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnitsOfMeasurementListItemsComponent {
  @Input()
  public unitsOfMeasurement: ICustomUnitOfMeasurement[] & IBaseUnit[];

  public isLoading = input<boolean>(false);

  @Output()
  remove: EventEmitter<string> = new EventEmitter<string>();

  public readonly cardContentTypeEnum = CardContentTypeEnum;

  @Input()
  defaultUnitOfMeasurementId: string;

  @Input({ transform: booleanAttribute })
  isToggleDisabled: boolean;

  @Output()
  defaultUnitChanged: EventEmitter<ICustomEvent> = new EventEmitter<ICustomEvent>();

  public readonly translations: any = {
    unitOfMeasurementTp: $localize`Determines which unit of measurement will be used to display the quantities of
                    items with this product by default.`,
  };

  onRemove(id: string): void {
    this.remove.emit(id);
  }

  onDefaultUnitChanged(event: ICustomEvent): void {
    this.defaultUnitChanged.emit(event);
  }
}
