<app-slide-overlay-header
  [isInboundShare]="true"
  [title]="title()"
  icon="package_2"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <app-item-overlay-main-information
        [formGroup]="formGroup"
        [element]="element()"
        [isSharedUser]="false"
        [isOldMaterialsEnabled]="isOldMaterialsEnabled"
        [materialOptions]="materialOptions()"
        [productOptions]="productOptions()"
        [unitOfMeasurementOptions]="unitOfMeasurementOptions()"
        [defaultUnitOfMeasurement]="defaultUnitOfMeasurement()"
        [itemDefaultUnitOfMeasurement]="itemDefaultUnitOfMeasurement()"
        [unitOfMeasurement]="unitOfMeasurement()"
        [currentLocationOptions]="currentLocationOptions()"
        [createdAtLocationOptions]="createdAtLocationOptions()"
        [hasDifferentDefaultUnit]="hasDifferentDefaultUnit()"
        [remainingQuantityFormatted]="remainingQuantityFormatted"
        [initialQuantityFormatted]="initialQuantityFormatted"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-item-overlay-main-information>
    </app-form-field-group>
  </ng-template>
  <ng-template
    [overlay-menu-indicator]="element()?.deliveries?.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DELIVERIES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="local_shipping"
  >
    <article class="field-group-container">
      <app-deliveries-table
        [columns]="['recordState', 'deliveryId', 'sent', 'from.name', 'to.name']"
        [deliveries]="element()?.deliveries"
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-deliveries-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
</app-slide-overlay-content>
