import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { CellClickedEvent, ColDef } from "ag-grid-community";
import { lastValueFrom, pairwise, startWith } from "rxjs";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { BulkAddItemsEnterRecordsModel as Model } from "@components/items/bulk-add-items/enter-records/bulk-add-items-enter-records.model";
import { ItemOverlayService } from "@components/items/pages/item-overlay/item-overlay.service";
import { ConfirmDialogComponent } from "@components/shared";
import { BulkAddEnterRecords } from "@components/shared/bulk-add/bulk-add-enter-records";
import {
  BulkAddCustomFieldEnum,
  BulkAddFixedField,
} from "@components/shared/bulk-add/bulk-add.interface";
import { BulkAddEditFieldDialogModel } from "@components/shared/bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.model";
import { InputCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  DateTypeEnum,
  FeatureFlagEnum,
} from "@shared/enums";
import {
  IAttachment,
  IBaseUnit,
  ICustomUnitOfMeasurement,
  IProduct,
  ISelectOption,
} from "@shared/interfaces";
import { AppDatePipe } from "@shared/pipes";
import { FeatureFlagService, ItemsService } from "@shared/services";
import { ColumnUtils, CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-bulk-add-items-enter-records",
  templateUrl: "./bulk-add-items-enter-records.component.html",
  styleUrls: ["./bulk-add-items-enter-records.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BulkAddItemsEnterRecordsComponent
  extends BulkAddEnterRecords<
    BulkAddItemsModel.SetValuesFormGroup,
    BulkAddItemsModel.ItemFormGroup,
    Model.IRowData
  >
  implements OnInit, OnDestroy
{
  private bulkAddItemsService: BulkAddItemsService = inject(BulkAddItemsService);

  private itemsService: ItemsService = inject(ItemsService);

  private appDatePipe = inject(AppDatePipe);

  private featureFlagService: FeatureFlagService = inject(FeatureFlagService);

  @Input() public formGroup: FormGroup<BulkAddItemsModel.SetValuesFormGroup>;

  @Input() public productOptions: ISelectOption[] = [];

  @Input() public locationOptions: ISelectOption[] = [];

  public rowData: Model.IRowData[] = [];

  public materialOptions: ISelectOption[] = [];

  protected fieldToCheckForDuplicates: string = BulkAddItemsModel.FieldEnum.ITEM_ID;

  protected isDuplicatedField: string = BulkAddItemsModel.FieldEnum.IS_ITEM_ID_DUPLICATED;

  public readonly maxRecordsCount: number = Model.maxItemsCount;

  public readonly fieldEnum = BulkAddItemsModel.FieldEnum;

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  public columnDefs = signal<ColDef[]>([]);

  private itemOverlayService = inject(ItemOverlayService);

  private materialAttachments: IAttachment[] = [];

  public ngOnInit(): void {
    this.materialOptions = this.bulkAddItemsService.allMaterials.map((material) => {
      return { label: `${material.category}: ${material.name}`, value: material.id };
    });

    this.setColumnDefs();
    this.buildRecords(true);
  }

  private get allProducts(): IProduct[] {
    return this.bulkAddItemsService.allProducts;
  }

  private get customUnitOfMeasurements(): ICustomUnitOfMeasurement[] {
    return this.bulkAddItemsService.customUnitOfMeasurements;
  }

  private get baseUnitOfMeasurements(): IBaseUnit[] {
    return this.bulkAddItemsService.baseUnitOfMeasurements;
  }

  protected override getFixedFieldForSpecialFields(key: string): BulkAddFixedField {
    if (key === this.fieldEnum.IS_FIXED_CREATION_DATE) {
      return this.getFixedFieldForCreationDate();
    }

    return null;
  }

  protected override getLabelForFixedField(key: string): string {
    return Model.fixedFieldMapping[key];
  }

  private getFixedFieldForCreationDate(): BulkAddFixedField {
    const formGroupValue = this.formGroup.getRawValue();

    const value =
      formGroupValue.dateType === DateTypeEnum.EXACT
        ? this.formatDate(formGroupValue.createdFrom)
        : formGroupValue.createdRange.map((date) => this.formatDate(date)).join(" - ");

    return { label: $localize`Creation date`, value };
  }

  public getFieldValue(value: any, key?: string): string {
    if (key === this.fieldEnum.IS_FIXED_TAGS) {
      return value.map((tag) => tag.tagDefinition.title).join(", ");
    }

    if (Array.isArray(value)) {
      return value.map((v) => this.getFieldValue(v)).join(", ");
    }

    if (value instanceof Object && value.label) {
      return value.label;
    }

    return `${value}`;
  }

  protected buildRecord(
    initialData?: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): FormGroup<BulkAddItemsModel.ItemFormGroup> {
    const formGroup = this.buildItemFormGroup(initialData);
    const { fieldEnum } = this;

    switch (formGroup.controls[fieldEnum.DATE_TYPE].value) {
      case DateTypeEnum.EXACT:
        formGroup.controls[fieldEnum.CREATED_FROM].addValidators([
          CustomValidators.required,
          CustomValidators.date,
        ]);
        formGroup.controls[fieldEnum.CREATED_RANGE].clearValidators();
        break;
      case DateTypeEnum.RANGE:
        formGroup.controls[fieldEnum.CREATED_RANGE].addValidators([
          CustomValidators.required,
          CustomValidators.dateRange,
        ]);
        formGroup.controls[fieldEnum.CREATED_FROM].clearValidators();
        break;
    }

    this.addSubscriptionsToItemFormGroup(formGroup);

    return formGroup;
  }

  private buildItemFormGroup(
    initialData?: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): FormGroup<BulkAddItemsModel.ItemFormGroup> {
    const { fieldEnum, formGroup } = this;

    const productValue =
      initialData?.controls[fieldEnum.PRODUCT]?.value ||
      this.getFieldValueForSelectField(fieldEnum.PRODUCT);

    const materialsValue =
      initialData?.controls[fieldEnum.MATERIALS]?.value ||
      formGroup.controls[fieldEnum.MATERIALS].value;

    const tagsData =
      initialData?.controls[fieldEnum.TAGS].value || formGroup.controls[fieldEnum.TAGS].value;

    const isDateRangeType = initialData
      ? initialData.controls[fieldEnum.IS_RANGE_DATE_TYPE].value
      : this.formGroup.controls[fieldEnum.IS_RANGE_DATE_TYPE].value;

    let initialQuantity =
      +initialData?.controls[fieldEnum.INITIAL_QUANTITY].value ||
      +this.getFieldValueForInput(fieldEnum.INITIAL_QUANTITY);

    if (initialQuantity === 0) {
      initialQuantity = null;
    }

    const customFieldsFormArray = this.getCustomFieldsFormArray(initialData);

    const controls: BulkAddItemsModel.ItemFormGroup = {
      [fieldEnum.ITEM_ID]: new FormControl(initialData?.controls[fieldEnum.ITEM_ID].value, {
        validators: [CustomValidators.required],
        asyncValidators: [
          CustomValidators.entityAlreadyExists(
            this.itemsService,
            null,
            BulkAddItemsModel.entityExistsValidatorArgs,
          ),
        ],
      }),
      [fieldEnum.TAGS]: new FormControl({
        value: tagsData,
        disabled: this.formGroup.controls[fieldEnum.IS_FIXED_TAGS].value,
      }),
      [fieldEnum.PRODUCT]: new FormControl(
        {
          value: productValue,
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_PRODUCT),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.INITIAL_QUANTITY]: new FormControl(
        {
          value: initialQuantity,
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_INITIAL_QUANTITY) || !productValue,
        },
        [CustomValidators.required, CustomValidators.greaterThan(0)],
      ),
      [fieldEnum.UNIT_OF_MEASUREMENT]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.UNIT_OF_MEASUREMENT].value ||
            this.getFieldValueForSelectField(fieldEnum.UNIT_OF_MEASUREMENT),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT) || !productValue,
        },
        [CustomValidators.required],
      ),
      [fieldEnum.MATERIALS]: new FormControl({
        value: materialsValue,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_MATERIALS) || !productValue,
      }),
      [fieldEnum.CREATED_AT_LOCATION]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.CREATED_AT_LOCATION].value ||
            this.getFieldValueForSelectField(fieldEnum.CREATED_AT_LOCATION),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATED_AT_LOCATION),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.CURRENT_LOCATION]: new FormControl(
        {
          value:
            initialData?.controls[fieldEnum.CURRENT_LOCATION].value ||
            this.getFieldValueForSelectField(fieldEnum.CURRENT_LOCATION),
          disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CURRENT_LOCATION),
        },
        [CustomValidators.required],
      ),
      [fieldEnum.DATE_TYPE]: new FormControl(
        initialData?.controls[fieldEnum.DATE_TYPE].value ||
          this.formGroup.controls[fieldEnum.DATE_TYPE].value,
      ),
      [fieldEnum.IS_RANGE_DATE_TYPE]: new FormControl(isDateRangeType),
      [fieldEnum.CREATED_FROM]: new FormControl({
        value:
          initialData?.controls[fieldEnum.CREATED_FROM].value ||
          this.formGroup.controls[fieldEnum.CREATED_FROM].value,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATION_DATE),
      }),
      [fieldEnum.CREATED_RANGE]: new FormControl({
        value:
          initialData?.controls[fieldEnum.CREATED_RANGE].value ||
          this.formGroup.controls[fieldEnum.CREATED_RANGE].value,
        disabled: this.isFieldDisabled(fieldEnum.IS_FIXED_CREATION_DATE),
      }),
      [fieldEnum.PREVIOUS_PRODUCT]: new FormControl(
        this.getFieldValueForSelectField(fieldEnum.PRODUCT),
      ),
      [fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS]: new FormControl(
        initialData?.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value ||
          this.getProductUnitOfMeasurements(
            this.getFieldValueForSelectField(fieldEnum.PRODUCT)?.value as string,
          ),
      ),
      [fieldEnum.BASE_INITIAL_QUANTITY]: new FormControl(
        initialData?.controls[fieldEnum.BASE_INITIAL_QUANTITY].value ||
          this.formGroup.controls[fieldEnum.BASE_INITIAL_QUANTITY].value,
      ),
      [fieldEnum.CUSTOM_FIELDS]: customFieldsFormArray,
      [fieldEnum.IS_ITEM_ID_DUPLICATED]: new FormControl(false),
    };

    if (this.bulkAddItemsService.delivery()) {
      controls[fieldEnum.BASE_DELIVERY_QUANTITY] = new FormControl(
        initialData?.controls[fieldEnum.BASE_DELIVERY_QUANTITY].value,
      );

      controls[fieldEnum.DELIVERY_QUANTITY] = new FormControl(
        {
          value: initialData?.controls[fieldEnum.DELIVERY_QUANTITY].value,
          disabled: !productValue,
        },
        [CustomValidators.required, CustomValidators.greaterThan(0)],
      );
    }

    if (this.bulkAddItemsService.isToBeAttachedToProcessInput()) {
      controls[fieldEnum.BASE_PROCESS_INPUT_QUANTITY] = new FormControl(
        initialData?.controls[fieldEnum.BASE_PROCESS_INPUT_QUANTITY].value,
      );

      controls[fieldEnum.PROCESS_INPUT_QUANTITY] = new FormControl(
        {
          value: initialData?.controls[fieldEnum.PROCESS_INPUT_QUANTITY].value,
          disabled: !productValue,
        },
        [CustomValidators.required, CustomValidators.greaterThan(0)],
      );
    }

    return new FormGroup(controls);
  }

  private addSubscriptionsToItemFormGroup(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
  ): void {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    const itemIdSubscription = controls[fieldEnum.ITEM_ID].valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(this.detectDuplicates.bind(this));

    const dateTypeSubscription = controls[fieldEnum.IS_RANGE_DATE_TYPE].valueChanges.subscribe(
      (isRange) => {
        if (isRange) {
          formGroup.controls[fieldEnum.DATE_TYPE].setValue(DateTypeEnum.RANGE);
          formGroup.controls[fieldEnum.CREATED_RANGE].enable();
          formGroup.controls[fieldEnum.CREATED_FROM].disable();
          formGroup.controls[fieldEnum.CREATED_RANGE].addValidators([
            CustomValidators.required,
            CustomValidators.dateRange,
          ]);
          formGroup.controls[fieldEnum.CREATED_FROM].clearValidators();
        } else {
          formGroup.controls[fieldEnum.DATE_TYPE].setValue(DateTypeEnum.EXACT);
          formGroup.controls[fieldEnum.CREATED_FROM].enable();
          formGroup.controls[fieldEnum.CREATED_RANGE].disable();
          formGroup.controls[fieldEnum.CREATED_FROM].addValidators([
            CustomValidators.required,
            CustomValidators.date,
          ]);
          formGroup.controls[fieldEnum.CREATED_RANGE].clearValidators();
        }

        this.setColumnDefs();
      },
    );

    const initialQuantitySubscription = this.bulkAddItemsService.buildInitialQuantitySubscription(
      formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
    );

    const unitOfMeasurementSubscription =
      this.bulkAddItemsService.buildUnitOfMeasurementOptionsSubscription(
        formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
        this.setColumnDefs.bind(this),
      );

    this.subscriptions.add(dateTypeSubscription);
    this.subscriptions.add(initialQuantitySubscription);
    this.subscriptions.add(unitOfMeasurementSubscription);
    this.subscriptions.add(itemIdSubscription);

    if (this.bulkAddItemsService.delivery()) {
      const deliveryQuantitySubscription =
        this.bulkAddItemsService.buildDeliveryQuantitySubscription(
          formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
        );

      this.subscriptions.add(deliveryQuantitySubscription);
    }

    if (this.bulkAddItemsService.isToBeAttachedToProcessInput()) {
      const processInputQuantitySubscription =
        this.bulkAddItemsService.buildProcessInputQuantitySubscription(
          formGroup as unknown as FormGroup<BulkAddItemsModel.IItemFieldSharedFormGroup>,
        );

      this.subscriptions.add(processInputQuantitySubscription);
    }
  }

  private async onProductSelected(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
    productOption: ISelectOption | null,
  ): Promise<void> {
    const previousProduct = formGroup.controls[this.fieldEnum.PREVIOUS_PRODUCT].value;

    if (!previousProduct || !productOption) {
      this.onChangeProduct(formGroup, productOption);

      return;
    }

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Resetting quantities and materials`,
        contentText: $localize`Changing the item’s product will reset all of the specified materials and might result in a change of the unit of measurement (which will reset the item’s initial quantity). Are you sure you want to change the product?`,
      },
    });

    const result = await lastValueFrom(dialogRef.afterClosed());

    if (result === ConfirmDialogResponseEnum.CONFIRM) {
      this.onChangeProduct(formGroup, productOption);
    } else {
      const previousProduct = formGroup.controls[this.fieldEnum.PREVIOUS_PRODUCT].value;

      formGroup.controls[this.fieldEnum.PRODUCT].setValue(previousProduct, {
        emitEvent: false,
      });
    }
  }

  private async getMaterialAttachments(productOption: ISelectOption): Promise<void> {
    if (this.isOldMaterialsEnabled || !productOption) {
      return;
    }
    this.materialAttachments = (await this.itemOverlayService.loadSelectedAttachments(
      AttachmentTypeEnum.MATERIAL,
      AttachmentTargetEnum.PRODUCT,
      productOption.value as string,
      true,
    )) as IAttachment[];
  }

  private onChangeProduct(
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>,
    productOption: ISelectOption,
  ): void {
    const { fieldEnum } = this;

    formGroup.controls[fieldEnum.MATERIALS].setValue([]);
    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(null);
    formGroup.controls[fieldEnum.BASE_INITIAL_QUANTITY].setValue(0);
    formGroup.controls[fieldEnum.BASE_DELIVERY_QUANTITY]?.setValue(0);
    formGroup.controls[fieldEnum.BASE_PROCESS_INPUT_QUANTITY]?.setValue(0);

    if (!productOption) {
      FormUtils.disableControls(formGroup, [
        fieldEnum.INITIAL_QUANTITY,
        fieldEnum.MATERIALS,
        fieldEnum.UNIT_OF_MEASUREMENT,
      ]);

      formGroup.controls[fieldEnum.DELIVERY_QUANTITY]?.disable();
      formGroup.controls[fieldEnum.PROCESS_INPUT_QUANTITY]?.disable();

      this.setColumnDefs();

      return;
    }

    FormUtils.enableControls(formGroup, [
      fieldEnum.INITIAL_QUANTITY,
      fieldEnum.MATERIALS,
      fieldEnum.UNIT_OF_MEASUREMENT,
    ]);

    formGroup.controls[fieldEnum.DELIVERY_QUANTITY]?.enable();
    formGroup.controls[fieldEnum.PROCESS_INPUT_QUANTITY]?.enable();

    formGroup.controls["previousProduct"].setValue(productOption);

    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].setValue(
      this.getProductUnitOfMeasurements(productOption.value as string),
    );

    const product = this.allProducts.find((product) => product.id === productOption.value);
    const defaulUnitId = CommonUtils.getUriId(product.defaultUnit);

    const defaultUnitOption = formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value.find(
      (unit) => unit.value === defaulUnitId,
    );

    formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].setValue(defaultUnitOption);

    this.cdr.detectChanges();

    this.setColumnDefs();
  }

  protected setColumnDefs(): void {
    const { fieldEnum, formGroup } = this;
    const { controls } = formGroup;
    const { fieldEnumToLabelMap } = BulkAddItemsModel;

    const columnDefs: ColDef[] = [
      {
        ...this.defaultColDefProperties(fieldEnum.ITEM_ID),
        headerName: fieldEnumToLabelMap[fieldEnum.ITEM_ID],
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        suppressKeyboardEvent: () => true,
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        minWidth: 250,
        cellRenderer: InputCellRendererComponent,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          return {
            field: fieldEnum.ITEM_ID,
            shouldDisplayWarning: params.value.controls[fieldEnum.IS_ITEM_ID_DUPLICATED].value,
            warningTooltipText: Model.duplicatedIdOnTableMessage,
            actions: [
              {
                icon: "file_copy",
                tooltip: $localize`Duplicate`,
                disabled:
                  this.formGroup.controls[fieldEnum.RECORDS].controls.length >= Model.maxItemsCount,
                click: this.onDuplicateRecord.bind(this),
              },
              {
                icon: "delete",
                tooltip: TextConstants.REMOVE,
                disabled: this.formGroup.controls[fieldEnum.RECORDS].controls.length <= 1,
                click: this.onRemoveRecord.bind(this),
              },
            ],
          };
        },
      },
    ];

    if (!controls[fieldEnum.IS_FIXED_INITIAL_QUANTITY].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.INITIAL_QUANTITY),
        headerName: fieldEnumToLabelMap[fieldEnum.INITIAL_QUANTITY],
        suppressKeyboardEvent: () => true,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          const formGroup = params.value;
          const unitOfMeasurementId =
            formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.value;

          let unit: IBaseUnit | ICustomUnitOfMeasurement;

          if (unitOfMeasurementId) {
            unit = [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
              (unit) => unit.id === unitOfMeasurementId,
            );
          }

          return {
            field: fieldEnum.INITIAL_QUANTITY,
            inputType: "number",
            suffix: unit?.symbol,
            warningTooltipText: $localize`Please select a product and unit of measurement first.`,
            shouldDisplayWarning: params.value.controls[fieldEnum.INITIAL_QUANTITY].disabled,
          };
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        cellRenderer: InputCellRendererComponent,
      });
    }

    if (this.bulkAddItemsService.delivery()) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.DELIVERY_QUANTITY),
        headerName: fieldEnumToLabelMap[fieldEnum.DELIVERY_QUANTITY],
        suppressKeyboardEvent: () => true,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          const formGroup = params.value;
          const unitOfMeasurementId =
            formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.value;

          let unit: IBaseUnit | ICustomUnitOfMeasurement;

          if (unitOfMeasurementId) {
            unit = [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
              (unit) => unit.id === unitOfMeasurementId,
            );
          }

          return {
            field: fieldEnum.DELIVERY_QUANTITY,
            inputType: "number",
            suffix: unit?.symbol,
          };
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        cellRenderer: InputCellRendererComponent,
      });
    }

    if (this.bulkAddItemsService.isToBeAttachedToProcessInput()) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.PROCESS_INPUT_QUANTITY),
        headerName: fieldEnumToLabelMap[fieldEnum.PROCESS_INPUT_QUANTITY],
        suppressKeyboardEvent: () => true,
        cellRendererParams: (params: { value: FormGroup<BulkAddItemsModel.ItemFormGroup> }) => {
          const formGroup = params.value;
          const unitOfMeasurementId =
            formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.value;

          let unit: IBaseUnit | ICustomUnitOfMeasurement;

          if (unitOfMeasurementId) {
            unit = [...this.baseUnitOfMeasurements, ...this.customUnitOfMeasurements].find(
              (unit) => unit.id === unitOfMeasurementId,
            );
          }

          return {
            field: fieldEnum.PROCESS_INPUT_QUANTITY,
            inputType: "number",
            suffix: unit?.symbol,
          };
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        cellRenderer: InputCellRendererComponent,
      });
    }

    if (!controls[fieldEnum.IS_FIXED_PRODUCT].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.PRODUCT),
        headerName: fieldEnumToLabelMap[fieldEnum.PRODUCT],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.PRODUCT].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditProduct(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.PRODUCT],
            ),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditProduct(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.UNIT_OF_MEASUREMENT),
        headerName: fieldEnumToLabelMap[fieldEnum.UNIT_OF_MEASUREMENT],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          if (event.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].disabled) {
            return;
          }

          this.onClickEditUnitOfMeasurement(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT],
            ),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                disabled: params.data.formGroup.controls[fieldEnum.UNIT_OF_MEASUREMENT].disabled,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditUnitOfMeasurement(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_MATERIALS].value && this.isOldMaterialsEnabled) {
      const cellRendererParams = (params: { data: Model.IRowData }) => {
        return {
          textParam: "label",
          cellContainerClass: "min-height-fix",
          actions: [
            {
              icon: "edit",
              tooltip: TextConstants.EDIT,
              disabled: params.data.formGroup.controls[fieldEnum.MATERIALS].disabled,
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditMaterials(data, event.currentTarget);
              },
            },
          ],
        };
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.MATERIALS),
        maxWidth: 250,
        autoHeight: true,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          if (event.data.formGroup.controls[fieldEnum.MATERIALS].disabled) {
            return;
          }

          this.onClickEditMaterials(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup.controls[fieldEnum.MATERIALS].value;
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.MATERIALS],
        "materials",
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    if (!controls[fieldEnum.IS_FIXED_CREATED_AT_LOCATION].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.CREATED_AT_LOCATION),
        headerName: fieldEnumToLabelMap[fieldEnum.CREATED_AT_LOCATION],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.CREATED_AT_LOCATION].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCreatedAtLocation(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.CREATED_AT_LOCATION],
            ),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditCreatedAtLocation(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_CURRENT_LOCATION].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.CURRENT_LOCATION),
        headerName: fieldEnumToLabelMap[fieldEnum.CURRENT_LOCATION],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.CURRENT_LOCATION].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCurrentLocation(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.CURRENT_LOCATION],
            ),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditCurrentLocation(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_TAGS].value) {
      const cellRendererParams = {
        textParam: "tagDefinition.title",
        classParam: "tagDefinition.color",
        cellContainerClass: "min-height-fix",
        actions: [
          {
            icon: "edit",
            tooltip: TextConstants.EDIT,
            click: (data: Model.IRowData, event: PointerEvent) => {
              this.onClickEditTags(data, event.currentTarget);
            },
          },
        ],
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.TAGS),
        minWidth: 250,
        autoHeight: true,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditTags(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup.controls[fieldEnum.TAGS].value;
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.TAGS],
        "tags",
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    if (!controls[fieldEnum.IS_FIXED_CREATION_DATE].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.DATE_TYPE),
        headerName: TextConstants.CREATION_DATE,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCreationDate(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          const { controls } = params.data.formGroup;

          if (controls[fieldEnum.DATE_TYPE].value === DateTypeEnum.EXACT) {
            return this.formatDate(controls[fieldEnum.CREATED_FROM].value);
          } else {
            return controls[fieldEnum.CREATED_RANGE].value
              .map(this.formatDate.bind(this))
              .join(" - ");
          }
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          const { controls } = params.data.formGroup;

          let control: FormControl = controls[fieldEnum.CREATED_FROM];

          if (controls[fieldEnum.DATE_TYPE].value === DateTypeEnum.RANGE) {
            control = controls[fieldEnum.CREATED_RANGE];
          }

          return {
            warningTooltipText: this.getWarningTooltipText(control),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditCreationDate(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    this.formGroup.controls[fieldEnum.CUSTOM_FIELDS].controls.forEach((control) => {
      if (!control.get(BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD).value) {
        columnDefs.push(this.defaultColDefPropertiesForCustomField(control));
      }
    });

    this.columnDefs.set(columnDefs);
  }

  private getProductUnitOfMeasurements(productId: string): ISelectOption[] {
    if (!productId) {
      return [];
    }

    const product = this.allProducts.find((product) => product.id === productId);

    if (!product) {
      return [];
    }

    const baseUnitId = CommonUtils.getUriId(product.baseUnit);
    const baseUnit = this.baseUnitOfMeasurements.find((unit) => unit.id === baseUnitId);

    const customUnits = (product.customUnits || []).map((customUnitUri) => {
      return this.customUnitOfMeasurements.find(
        (customUnit) => CommonUtils.getUriId(customUnitUri) === customUnit.id,
      );
    });

    return [baseUnit, ...customUnits].map((unit) => {
      return { label: unit.name, value: unit.id };
    });
  }

  private onClickEditProduct(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog<ISelectOption>(
      data,
      this.fieldEnum.PRODUCT,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.productOptions,
      },
      null,
      async (productOption: ISelectOption) => {
        await this.getMaterialAttachments(productOption);
        await this.onProductSelected(data.formGroup, productOption);
      },
    );
  }

  private onClickEditMaterials(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.MATERIALS,
      BulkAddEditFieldDialogModel.FieldTypeEnum.CHIPS,
      eventTarget,
      {
        options: this.getProductAllowedMaterials(
          data.formGroup?.controls[this.fieldEnum.PRODUCT]?.value?.value as string,
        ),
      },
    );
  }

  private onClickEditCreatedAtLocation(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.CREATED_AT_LOCATION,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.locationOptions,
        tooltip: $localize`Provide information about where the item was first created / got a new identifier.`,
      },
    );
  }

  private onClickEditCurrentLocation(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.CURRENT_LOCATION,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: this.locationOptions,
      },
    );
  }

  private onClickEditTags(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.TAGS,
      BulkAddEditFieldDialogModel.FieldTypeEnum.TAGS,
      eventTarget,
      {
        initialTags: data.formGroup.controls[this.fieldEnum.TAGS].value,
      },
    );
  }

  private onClickEditCreationDate(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      null,
      BulkAddEditFieldDialogModel.FieldTypeEnum.DATE_PICKER,
      eventTarget,
      {
        dateTypeFormControlName: this.fieldEnum.DATE_TYPE,
        isRangeDateTypeFormControlName: this.fieldEnum.IS_RANGE_DATE_TYPE,
        fromFormControlName: this.fieldEnum.CREATED_FROM,
        rangeFormControlName: this.fieldEnum.CREATED_RANGE,
      },
      (initialValues: BulkAddItemsModel.ItemFormRawValue) => {
        const dateTypeInitialValue = initialValues[this.fieldEnum.DATE_TYPE];
        const isDateRangeTypeInitialValue = initialValues[this.fieldEnum.IS_RANGE_DATE_TYPE];
        const createdFromInitialValue = initialValues[this.fieldEnum.CREATED_FROM];
        const createdRangeInitialValue = initialValues[this.fieldEnum.CREATED_RANGE];

        data.formGroup.controls[this.fieldEnum.IS_RANGE_DATE_TYPE].setValue(
          isDateRangeTypeInitialValue,
        );
        data.formGroup.controls[this.fieldEnum.DATE_TYPE].setValue(dateTypeInitialValue);
        data.formGroup.controls[this.fieldEnum.CREATED_FROM].setValue(createdFromInitialValue);
        data.formGroup.controls[this.fieldEnum.CREATED_RANGE].setValue(createdRangeInitialValue);
      },
    );
  }

  private onClickEditUnitOfMeasurement(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.UNIT_OF_MEASUREMENT,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        options: data.formGroup?.controls[this.fieldEnum.UNIT_OF_MEASUREMENT_OPTIONS].value,
      },
    );
  }

  private getProductAllowedMaterials(productId: string): ISelectOption[] {
    if (!productId) {
      return [];
    }

    const product = this.allProducts.find((product) => product.id === productId);

    if (!product) {
      return [];
    }

    if (this.isOldMaterialsEnabled) {
      return product.allowedMaterials
        .map((allowedMaterial) => {
          const allowedMaterialId = CommonUtils.getUriId(allowedMaterial);

          return this.materialOptions.find(
            (materialOption) => materialOption.value === allowedMaterialId,
          );
        })
        .filter((materialOption) => materialOption !== undefined) as ISelectOption[];
    } else {
      const attachedMaterialsOptions = this.materialAttachments
        .map((attachment) => {
          const materialId = CommonUtils.getUriId(attachment.attachmentUri);

          return this.materialOptions.find((materialOption) => materialOption.value === materialId);
        })
        .filter((materialOption) => !!materialOption) as ISelectOption[];

      return attachedMaterialsOptions;
    }
  }

  private formatDate(date: string): string {
    return this.appDatePipe.transform(date, CommonConstants.DATE_FORMAT);
  }
}
