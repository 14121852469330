import { booleanAttribute, ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-button-group",
  templateUrl: "./button-group.component.html",
  styleUrl: "./button-group.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
  @Input() class: string;

  @Input({ transform: booleanAttribute }) visible: boolean = true;
}
