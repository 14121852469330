<div class="main-container flex-column">
  <div class="upload-box flex-column hover-container">
    <div class="content flex-column">
      <app-button class="upload-button" (pressed)="fileInput.click()">
        <mat-icon icon-left>upload</mat-icon>
        <ng-container i18n>Upload</ng-container>
      </app-button>
      @if (multiple) {
        <ng-container i18n>or drag and drop file(s) here</ng-container>
      } @else {
        <ng-container i18n>or drag and drop a file here</ng-container>
      }
    </div>

    <input
      #fileInput
      [multiple]="multiple"
      class="file-upload-input"
      type="file"
      title=""
      id="files"
      (change)="onFilesSelected($event)"
    />
  </div>

  <div class="footer">
    <div class="footer-left">{{ footerLeftText }}</div>
    <div class="footer-right">{{ footerRightText }}</div>
  </div>
</div>
