<div class="container-flex-space-between">
  <div class="left-content">
    <div
      [ngClass]="{ ellipsis: type === cardContentTypeEnum.ADD }"
      class="name"
      [tp]="unitOfMeasurement.name"
      [tpDelay]="500"
      tpPlacement="top-start"
    >
      {{ unitOfMeasurement.name }} ({{ unitOfMeasurement.symbol }})
    </div>
    @if (isSystemUnit) {
      <div class="type gray" i18n>System unit of measurement</div>
    } @else {
      <div class="type gray">
        @if (type === cardContentTypeEnum.REMOVE) {
          1 {{ unitOfMeasurement.symbol }} =
        }
        {{ unitOfMeasurement.conversionFactor }}
        {{ unitOfMeasurement.systemUnit.symbol }}
      </div>
    }
  </div>
  <div class="container-flex-space-between gap-200">
    @if (type === cardContentTypeEnum.REMOVE) {
      <app-button-toggle
        (changeEvent)="onDefaultUnitChaged($event)"
        [disabled]="isToggleDisabled"
        [checkValue]="defaultUnitOfMeasurementId === unitOfMeasurement.id"
      ></app-button-toggle>
    }
    <app-button-group>
      @if (type === cardContentTypeEnum.ADD) {
        <app-button size="medium" type="icon">
          <mat-icon icon-medium-18>add</mat-icon>
        </app-button>
      } @else {
        <ng-container>
          @if (!isSystemUnit) {
            <app-button
              (pressed)="onClickRemove()"
              [tp]="translations.removeTp"
              size="medium"
              type="icon"
            >
              <mat-icon icon-medium-18>close</mat-icon>
            </app-button>
          } @else {
            <span class="icon-placeholder"></span>
          }
        </ng-container>
      }
    </app-button-group>
  </div>
</div>
