import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { Router } from "@angular/router";

import { ColDef } from "ag-grid-community";
import { LinkCellRendererComponent } from "src/app/shared/cell-renderers";
import { BatchActionTypeEnum, RecordStateEnum, RouteEnum, TableEnum } from "src/app/shared/enums";
import { ISupplyChain } from "src/app/shared/interfaces";
import { SupplyChainsService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

@Component({
  standalone: false,
  selector: "app-supply-chains-table",
  templateUrl: "./supply-chains-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplyChainsTableComponent implements AfterViewInit {
  @Input()
  public supplyChains: ISupplyChain[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.SUPPLY_CHAINS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: ISupplyChain[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private router: Router,
    private supplyChainsService: SupplyChainsService,
    private notificationService: NotificationService,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.supplyChainsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.supplyChainsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.supplyChainsService),
        ],
      ]),
    };
  };

  private setColumnDefs(): void {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: RouteEnum.SUPPLY_CHAINS_DETAILS,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: TextConstants.DESCRIPTION,
        field: "description",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  }

  public onViewDetails = async (row: ISupplyChain): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    await this.router.navigate([`/${RouteEnum.SUPPLY_CHAINS_DETAILS}/${row.id}`]);
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.supplyChains) {
      this.rowData = this.supplyChains;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.supplyChainsService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
