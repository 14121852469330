<div class="container-flex-space-between">
  <div class="left-content">
    <div class="location-types flex-wrap">
      @for (locationType of locationTypes; track $index) {
        <div class="location-type">
          <app-tag
            class="in-card"
            [tag]="buildLocationTypeTag(locationType)"
            [maxTextLengthToShow]="8"
          ></app-tag>
        </div>
      }
      @if (
        !!maxNumDisplayableLocationTypes &&
        this.location.types.length > maxNumDisplayableLocationTypes
      ) {
        <div class="location-type" [tp]="moreLocationTypes">
          <app-tag class="in-card" [tag]="buildTag('...')"></app-tag>
        </div>
      }
    </div>

    <div class="organisation-name break-word">{{ location.organisationName }}</div>

    <div [tp]="location.name" class="name">
      {{ location.name }}
    </div>
    <div>
      <app-flag [country]="location.address.country"></app-flag>
      <span class="country-name">
        {{ location.address.countryName }}
      </span>
    </div>
  </div>
  <app-button-group>
    @if (type === cardContentTypeEnum.ADD) {
      <app-button size="medium" type="icon" class="right">
        <mat-icon icon-medium-18>add</mat-icon>
      </app-button>
    } @else {
      <app-button
        (pressed)="onClickRemove()"
        [tp]="translations.removeTp"
        size="medium"
        type="icon"
      >
        <mat-icon icon-medium-18>close</mat-icon>
      </app-button>
    }
  </app-button-group>
</div>
