<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <app-input enableViewMode formControlName="number" [label]="translations.number"></app-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [options]="standardOptions()"
        enableViewMode
        formControlName="standard"
        [label]="translations.standard"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        [disabled]="!standardTypeOptions()?.length || formGroup.disabled"
        [options]="standardTypeOptions()"
        enableViewMode
        formControlName="standardType"
        [label]="translations.standardType"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        [maxDate]="
          formGroup.controls['validFromDate'].value ?? formGroup.controls['validToDate'].value
        "
        enableViewMode
        formControlName="issuanceDate"
        [label]="translations.issuanceDate"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        [maxDate]="formGroup.controls['validToDate'].value"
        [minDate]="formGroup.controls['issuanceDate'].value"
        enableViewMode
        formControlName="validFromDate"
        [label]="translations.validFrom"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        [minDate]="
          formGroup.controls['validFromDate'].value ?? formGroup.controls['issuanceDate'].value
        "
        enableViewMode
        formControlName="validToDate"
        [label]="translations.validTo"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input
        enableViewMode
        [viewModeTemplate]="urlTemplate"
        formControlName="url"
        label="URL"
        [tooltip]="translations.urlTooltip"
        type="url"
      ></app-input>
    </div>
  </div>
</div>
<ng-template #urlTemplate let-data>
  <div class="form-field-item hover-visibility-container">
    <app-form-label [label]="data.label"></app-form-label>
    <div class="form-field-input-readonly">
      @if (data.value) {
        <div class="one-line-wrap-ellipsis">
          <a
            class="blue-link"
            [href]="data.value"
            target="_blank"
            tpPlacement="top-start"
            [tp]="data.value"
          >
            {{ data.value }}
            <app-external-link-icon></app-external-link-icon>
          </a>
        </div>
      } @else {
        -
      }
    </div>
  </div>
</ng-template>
