<div class="location-detail">
  <mat-accordion>
    <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
      <mat-expansion-panel-header class="section-header">
        <mat-panel-title class="section-title">
          <div class="location-name-container">
            <app-form-link [link]="getLocationLink()" [styledLink]="true" [maintainFontSize]="true">
              <span class="value location-name">
                {{ location.name }}
              </span>
            </app-form-link>
            <app-tag-group [tags]="locationTypesTags"></app-tag-group>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="location-main-info">
        <div>
          <ng-container i18n>Organisation</ng-container>
          :
          <span class="organisation-name">{{ location.organisationName }}</span>
        </div>
      </div>
      <div>
        <h4 i18n>Certificates</h4>
        <app-certificates-table
          [certificatesIds]="certificateIds"
          [isRecordStateFilterEnabled]="false"
          [isSearchEnabled]="false"
          [isPaginatorEnabled]="false"
          [columns]="[
            'number',
            'standard.name',
            'standardType.fullName',
            'issuanceDate',
            'validFromDate',
            'validToDate',
            'documents',
          ]"
        ></app-certificates-table>
      </div>
      <div>
        <h4 i18n>Geo data</h4>
        <div class="geodata">
          <div class="fields">
            <div class="field">
              <div i18n>Address</div>
              <div class="value">
                {{ location.address.street || "-" }}
              </div>
            </div>
            <div class="field">
              <div i18n>ZIP / Post code</div>
              <div class="value">
                {{ location.address.zipCode || "-" }}
              </div>
            </div>
            <div class="field">
              <div i18n>State/Region</div>
              <div class="value">
                {{ location.address.region || "-" }}
              </div>
            </div>
            <div class="field">
              <div i18n>Country</div>
              <div class="value">
                @if (location.address.countryName) {
                  <app-flag [country]="location.address.country"></app-flag>
                  {{ location.address.countryName }}
                } @else {
                  -
                }
              </div>
            </div>
            <div class="field">
              <div i18n>Geo data type</div>
              <div class="value">
                {{ geoLocationType || "-" }}
              </div>
            </div>
            <div class="field">
              <div i18n>Area (ha)</div>
              <div class="value">
                @if (area) {
                  {{ area | number }}
                } @else {
                  -
                }
              </div>
            </div>
          </div>
          @if (location.geoLocation) {
            <app-map [geoLocation]="location.geoLocation"></app-map>
          } @else {
            <div i18n class="no-gps-data-box container-flex-center">
              No GPS data provided for this location.
            </div>
          }
        </div>
      </div>
      @if (location.customFieldsWithValues.length) {
        <div>
          <h4>{{ additionalInformationText }}</h4>
          <div class="information grid">
            @for (customField of location.customFieldsWithValues; track customField.label) {
              <div class="column">
                <div class="header">{{ customField.label }}</div>
                <div class="value">{{ customField.value }}</div>
              </div>
            }
          </div>
        </div>
      }
      <div>
        <h4 i18n>Documents</h4>
        @if (isLoadingDocumentTypesTable()) {
          <app-loader></app-loader>
        } @else {
          <app-document-types-table
            [documentTypes]="documentTypes"
            [areButtonsEnabled]="false"
            [isRecordStateFilterEnabled]="false"
            [isPaginatorEnabled]="false"
            [isSearchEnabled]="false"
            [rulesetWarningTooltipText]="documentTypesRulesetWarningTooltipText"
            [columns]="['documentType', 'documentNames']"
          ></app-document-types-table>
        }
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
