import {
  AfterViewInit,
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

import { RecordStateEnum } from "src/app/shared/enums";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TextConstants } from "@shared/constants";
import { FormUtils } from "@shared/utils";

import { InputSelectService } from "./input-select.service";

@Component({
  standalone: false,
  selector: "app-input-select",
  templateUrl: "./input-select.component.html",
  styleUrls: ["./input-select.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputSelectComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @ViewChild("autoInput") inputElementRef?: ElementRef;

  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  @Input() class: string;

  @Input() hint: string;

  @Input() hintClass: string;

  @Input() label: string;

  @Input() options: InputSelectOption[] = [];

  @Input() tooltip: string;

  @Input() placeholder: string;

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) emitRawValue: boolean;

  @Input({ transform: booleanAttribute }) inputOnlyMode: boolean;

  @Input({ transform: booleanAttribute }) showClear: boolean = true;

  @Input({ transform: booleanAttribute }) addOnWrite: boolean;

  @Input({ transform: booleanAttribute }) enableViewMode: boolean = false;

  @Input({ transform: booleanAttribute }) showOptionalHint: boolean = true;

  @Input() viewModeTemplate: TemplateRef<unknown>;

  @Input() prefixTemplate: TemplateRef<unknown>;

  ngControl: NgControl;

  filteredOptions: InputSelectOption[] = [];

  public isOptional = signal<boolean>(false);

  public isDisabled = signal<boolean>(false);

  searchText: string = "";

  searchOption: InputSelectOption = { label: "" };

  selectedOption: InputSelectOption | null = null;

  readonly recordStateEnum = RecordStateEnum;

  private isSelectionMade: boolean = false;

  private isInputSearchMade: boolean = false;

  public readonly translations: any = {
    clearTp: TextConstants.CLEAR,
  };

  constructor(
    private injector: Injector,
    private inputSelectService: InputSelectService,
    protected overlay: SlideOverlayPageService,
  ) {}

  get dropdownIndicatorIcon(): string {
    return this.inputSelectService.getDropdownIcon(this.autocompleteTrigger?.panelOpen);
  }

  get hasValidationError(): boolean {
    return this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched);
  }

  get shouldHideClearButton(): boolean {
    const isEmpty = !this.selectedOption?.label;

    return !this.showClear || this.isDisabled() || this.disabled || isEmpty;
  }

  get hasSelectedOption(): boolean {
    return !!this.selectedOption?.value;
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    if (this.ngControl) {
      this.isOptional.set(FormUtils.isOptional(this.ngControl.control));
    }
  }

  getDisplayLabel = (option: InputSelectOption): string => option.label ?? "-";

  async writeValue(value: unknown): Promise<void> {
    this.setSelectedOption(value, this.options || []);
  }

  registerOnChange(fn: (_: unknown | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled.set(isDisabled);
  }

  onAutocompleteOptionSelected(selectedOption: InputSelectOption): void {
    this.isSelectionMade = true;
    this.updateSelectedOption(selectedOption);

    setTimeout(() => this.inputElementRef.nativeElement.blur(), 10);
  }

  onInputClear(): void {
    this.onChange(null);
    this.selectedOption = null;
    this.onTouched();
    this.clearInput();
    this.focusInput();
  }

  onInputFocus(): void {
    if (this.autocompleteTrigger.panelOpen) {
      return;
    }

    this.resetFilterOptions();
  }

  onInputKeyUp(event: KeyboardEvent): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    if (event.key === "Enter") {
      if (this.addOnWrite) {
        this.handleKeyEnter(target.value);
      }

      return;
    }
    this.isInputSearchMade = true;
    this.handleSelectSearch(target.value);
  }

  onAutocompleteClose(): void {
    if (this.isSelectionMade) {
      this.isSelectionMade = false;

      return;
    }

    if (!this.addOnWrite) {
      if (this.isInputSearchMade && this.filteredOptions.length) {
        this.isInputSearchMade = false;
        this.updateSelectedOption(this.filteredOptions[0]);
      }
    }
    this.onInputBlur(true);
  }

  onInputBlur(force: boolean = false): void {
    if (this.autocompleteTrigger.panelOpen && !force) {
      return;
    }
    setTimeout(() => this.setInputValue(this.selectedOption), 150);
  }

  onToggleDropdown(): void {
    if (this.disabled) {
      return;
    }
    if (this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.closePanel();
    } else {
      this.focusInput();
    }
  }

  private setInputValue(option: InputSelectOption | null): void {
    if (this.inputElementRef) {
      this.inputElementRef.nativeElement.value = option?.label ?? "";
    }
  }

  private focusInput(): void {
    this.inputElementRef.nativeElement.focus();
  }

  private clearInput(): void {
    this.inputElementRef.nativeElement.value = "";
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouched: () => void = () => {};

  private setSelectedOption(value: unknown, options: InputSelectOption[]): void {
    this.selectedOption = this.inputSelectService.findSelectedValue(value, options);
    this.searchText = this.selectedOption?.label ?? "";
    this.setInputValue(this.selectedOption);
  }

  private updateSelectedOption(option: InputSelectOption): void {
    if (option !== this.selectedOption) {
      this.selectedOption = option;
      const selectedValue = this.emitRawValue ? option.value : option;

      this.onChange(selectedValue);
      this.onTouched();
    }
  }

  private resetFilterOptions(): void {
    this.clearInput();
    this.filteredOptions = this.options;
  }

  private handleSelectSearch(value: string): void {
    const label = value.trim();

    if (this.addOnWrite) {
      if (!label) {
        this.options = this.options.filter((option) => option !== this.searchOption);
        this.searchOption = { label: "" };
        this.selectedOption = null;
        this.filteredOptions = this.options || [];

        return;
      }

      this.isInputSearchMade = false;
      this.isSelectionMade = true;
      const option = this.options.find(
        (option) => option.label.toLowerCase() === label.toLowerCase(),
      );

      if (option) {
        this.updateSelectedOption(option);
      } else {
        if (this.searchOption.label === "") {
          this.options.push(this.searchOption);
        }
        this.searchOption.label = label;
        this.searchOption.value = undefined;
        this.updateSelectedOption(this.searchOption);
      }
    }

    this.filteredOptions = this.inputSelectService.filterOptions(label, this.options || []);
  }

  private handleKeyEnter(value: string): void {
    const label = value.trim();

    if (!label) {
      return;
    }

    const option = this.options.find(
      (option) => option.label.toLowerCase() === label.toLowerCase(),
    );

    this.isInputSearchMade = false;

    if (option) {
      this.onAutocompleteOptionSelected(option);
    } else {
      const newOption: InputSelectOption = {
        label,
        value: undefined,
      };

      this.options.push(newOption);
      this.onAutocompleteOptionSelected(newOption);
    }
    if (this.autocompleteTrigger.panelOpen) {
      this.autocompleteTrigger.closePanel();
    }
  }
}
