import { ChangeDetectionStrategy, Component, signal, viewChild } from "@angular/core";
import { MatDatepicker, MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatInput } from "@angular/material/input";

import { IDateAngularComp } from "ag-grid-angular";
import { IDateParams } from "ag-grid-community";
import moment from "moment";

import { TextConstants } from "@shared/constants";
import { LocalizationUtils } from "@shared/utils";

import { DatepickerHeaderComponent } from "../datepicker";

@Component({
  selector: "app-table-datepicker",
  templateUrl: "./table-datepicker.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableDatepickerComponent implements IDateAngularComp {
  readonly input = viewChild(MatInput);

  private readonly picker = viewChild(MatDatepicker<Date>);

  private readonly params = signal<IDateParams>(undefined);

  readonly date = signal<Date>(undefined);

  readonly datePickerDateFormat = LocalizationUtils.getLocaleDatepickerFormat();

  readonly datepickerHeader = DatepickerHeaderComponent;

  readonly translations = {
    clear: TextConstants.CLEAR,
  };

  agInit(params: IDateParams) {
    this.params.set(params);
  }

  getDate(): Date {
    return this.date();
  }

  setDate(date: Date) {
    this.date.set(date);
  }

  onDateChange(event: MatDatepickerInputEvent<Date> | Event) {
    let value: string | moment.Moment = (event.target as HTMLInputElement).value;

    if (moment.isMoment(value)) {
      value = value.format();
    } else if (typeof value === "string") {
      const momentValue = moment.utc(value, this.datePickerDateFormat, true);

      value = momentValue.isValid() ? momentValue.format() : null;
    }

    this.date.set(value ? new Date(value) : null);
    this.params().onDateChanged();
  }

  onDateClear() {
    this.input().value = "";
    this.date.set(null);
    this.params().onDateChanged();
  }

  onCalendarClick(event: Event) {
    event.preventDefault();
    this.picker().open();
  }
}
