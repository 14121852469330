@if (uploadStatusTexts.length) {
  <p class="status-text">
    @for (status of uploadStatusTexts; track index; let index = $index; let last = $last) {
      <span [ngClass]="status?.class ? status.class : ''">{{ status.text }}</span>
      @if (!last) {
        <br />
      }
    }
  </p>
}

<div class="files-container flex-column">
  @for (file of files; track index; let index = $index) {
    <div class="file file-uploading">
      <div class="left-content">
        <mat-icon class="icon">docs</mat-icon>
        <span
          class="name clickable underline"
          [tp]="translations.viewFileTp"
          tabindex="0"
          (click)="onClickViewFileEvent(index)"
          (keyup.enter)="onClickViewFileEvent(index)"
        >
          {{ file.formGroup.controls["name"].value }}
        </span>
      </div>
      <div class="right-content">
        @if (
          !file.status ||
          file.status === uploadFileStatusEnum.DOCUMENT_CREATED ||
          file.status === uploadFileStatusEnum.TAGS_CREATED ||
          file.status === uploadFileStatusEnum.FILE_UPLOAD_START
        ) {
          <mat-progress-bar [value]="file.uploadProgress"></mat-progress-bar>
          {{ file.uploadProgress }}%
        }

        @if (
          [
            uploadFileStatusEnum.DOCUMENT_CREATION_FAILED,
            uploadFileStatusEnum.FILE_UPLOAD_FAILED,
            uploadFileStatusEnum.FILE_ADD_TAGS_FAILED,
            uploadFileStatusEnum.FILE_ATTACH_FAILED,
          ].includes(file.status)
        ) {
          <span class="retry danger" i18n>Upload failed</span>
          <app-button
            type="ghost"
            [tp]="translations.retryTp"
            class="no-border"
            (pressed)="onClickRetryUpload(file)"
          >
            <mat-icon class="icon">refresh</mat-icon>
          </app-button>
        }

        @if (
          (!isAttachProcess && file.status === uploadFileStatusEnum.UPLOADED) ||
          (isAttachProcess && file.status === uploadFileStatusEnum.ATTACHED)
        ) {
          <mat-icon class="icon" svgIcon="action_completed"></mat-icon>
        }
      </div>
    </div>
  }
</div>
