<div class="auth-container sign-up">
  <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
  <div class="outer-container">
    <div class="container">
      @if (isLanguagesEnabled && !isLoading()) {
        <div class="auth-language-selector-container">
          <app-language-selector
            [showFullLanguageName]="false"
            class="no-padding"
          ></app-language-selector>
        </div>
      }
      <div class="text-center">
        @switch (currentStep) {
          @case ("initial") {
            <div class="title">
              @if (hasInvitation) {
                <h2>
                  <ng-container i18n>Sign up to accept invitation from</ng-container>
                  {{ invitation.organisationName }}
                </h2>
              } @else {
                <h1 i18n>Sign up</h1>
              }
            </div>

            <div class="subtitle" i18n>
              To create your account, first provide your personal details.
            </div>

            @if (isLoading()) {
              <app-loader containerClass="no-margin"></app-loader>
            } @else {
              <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="flex-column">
                <app-input
                  [label]="translations.firstNameLabel"
                  class="no-padding"
                  formControlName="firstName"
                  autofocus
                ></app-input>

                <app-input
                  [label]="translations.lastNameLabel"
                  class="no-padding"
                  formControlName="lastName"
                ></app-input>

                <app-input
                  type="email"
                  class="no-padding"
                  [label]="translations.emailLabel"
                  formControlName="email"
                  autocomplete="on"
                ></app-input>

                <app-input-password
                  [label]="translations.passwordLabel"
                  class="no-padding"
                  formControlName="password"
                  showRequirementsTooltip
                  autocomplete="new-password"
                ></app-input-password>

                @if (!hasInvitation) {
                  <h3 i18n>Organisation</h3>

                  <app-input
                    [label]="translations.nameLabel"
                    class="no-padding"
                    formControlName="organisationName"
                  ></app-input>

                  <app-input-select
                    [options]="countryOptions"
                    class="no-padding"
                    formControlName="country"
                    [label]="translations.countryLabel"
                    [prefixTemplate]="flag"
                  ></app-input-select>
                  <ng-template #flag let-option>
                    <app-flag [country]="option.value"></app-flag>
                  </ng-template>
                }

                <app-button
                  [disabled]="isSubmitButtonDisabled"
                  size="large"
                  class="fresh-green mt-20"
                  accessibilityRole="submit"
                  cdkFocusInitial
                >
                  @if (hasInvitation) {
                    <ng-container i18n>Sign up & accept invitation</ng-container>
                  } @else {
                    <ng-container i18n>Sign up</ng-container>
                  }
                </app-button>
              </form>

              <div class="switch-mode-container">
                <ng-container i18n>Already have an account?</ng-container>
                <a [routerLink]="'/' + routingEnum.LOGIN" i18n>Login</a>
              </div>
            }
          }
          @case ("verify") {
            <div class="title">
              <h1 i18n>Verify your email</h1>
            </div>

            @if (isLoading()) {
              <app-loader containerClass="no-margin"></app-loader>
            } @else {
              <div class="subtitle">
                <p>
                  <ng-container i18n>We sent a verification link to your email:</ng-container>
                  <br />
                  <b class="email-address">{{ formGroup.controls["email"].value }}</b>
                </p>
                <p class="mt-24">
                  <ng-container i18n>
                    If you haven’t received the email, please check your spam folder or give it a
                    while (up to 10 minutes). Alternatively, you can
                  </ng-container>
                  <a class="clickable underline" (click)="onResendEmail()" i18n>
                    request a new verification email
                  </a>
                  <ng-container i18n>(will make the previous one invalid).</ng-container>
                  <br />
                  <br />
                  <ng-container i18n>Entered the wrong email?</ng-container>
                  <ng-container i18n>Back to</ng-container>
                  <a class="clickable underline" (click)="onChangeStep('initial')" i18n>Sign up</a>
                </p>

                <div class="switch-mode-container">
                  <ng-container i18n>Back to</ng-container>
                  <a [routerLink]="'/' + routingEnum.LOGIN" i18n>Login</a>
                </div>
              </div>
            }
          }
        }
      </div>
    </div>
  </div>
</div>
