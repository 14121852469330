@if (draggable) {
  <div
    class="main-container flex-column"
    role="none"
    cdkDrag
    [cdkDragData]="item"
    [cdkDragPreviewClass]="'dragging-item'"
    [tabindex]="0"
    appDragCursor
    (click)="onClick()"
    (keyup.enter)="onClick()"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
} @else {
  <div
    class="main-container flex-column"
    (click)="onClick()"
    (keyup.enter)="onClick()"
    [tabindex]="0"
    role="none"
  >
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
  </div>
}

<ng-template #contentTemplate>
  <ng-content></ng-content>
</ng-template>
