<app-slide-overlay-header
  [canDelete]="true"
  [canArchive]="true"
  [enableTags]="true"
  [title]="title()"
  icon="docs"
>
  <ng-container right-side-button>
    @if (canViewContent) {
      <app-button
        (pressed)="viewContent()"
        class="page-button-more"
        size="large"
        type="icon"
        [tp]="contentTooltip('View')"
      >
        <mat-icon [class.red]="!hasContent">visibility</mat-icon>
      </app-button>
    }
    <app-button
      (pressed)="downloadContent()"
      class="page-button-more"
      size="large"
      type="icon"
      [tp]="contentTooltip('Download')"
    >
      <mat-icon [class.red]="!hasContent">download</mat-icon>
    </app-button>
  </ng-container>
</app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <app-edit-document-main-details
        (clickOnName)="onNameClick()"
        [documentTypesOptions]="documentTypesOptions"
        [fileExtension]="fileExtension"
        [formGroup]="formGroup"
        [hasContent]="hasContent"
        [isEditing]="isEditing()"
      ></app-edit-document-main-details>
    </app-form-field-group>
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.ATTACHED_TO).isEnabled"
    [overlay-menu-indicator]="selectedAttachments?.length"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.ATTACHED_TO).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="attach_file"
  >
    <article class="field-group-container no-padding-bottom">
      <app-attachments-table [attachments]="selectedAttachments"></app-attachments-table>
    </article>
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="documentOverlayService.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(documentOverlayService)"
        (deleted)="onCommentDeleted(documentOverlayService)"
        [entityId]="element?.id"
        [entityType]="entityType"
      ></app-comments>
    </article>
  </ng-template>
</app-slide-overlay-content>
