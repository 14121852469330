<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.USER_SETTINGS"
  ></app-page-title>

  <!-- @if (!isLoading()) {
    @if (isEditing()) {
      <app-button-group>
        <app-button (pressed)="onCancel()" type="ghost" i18n>Cancel</app-button>
        <app-button
          (pressed)="onSubmit()"
          [disabled]="formGroup().invalid || formGroup().pending"
          accessibilityRole="submit"
        >
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    } @else {
      <app-button (pressed)="toggleEdit()">
        <mat-icon icon-left>edit</mat-icon>
        <ng-container i18n>Edit</ng-container>
      </app-button>
    }
  } -->
</div>

<!-- @if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <br />

  <form (ngSubmit)="onSubmit()" [formGroup]="formGroup()">
    <div class="row-container">
      <div class="row">
        <app-language-selector
          #languageSelector
          [formGroup]="formGroup()"
          [label]="translations.language"
        ></app-language-selector>
      </div>
    </div>
  </form>
} -->

<br />
<div class="row-container">
  <div class="row">
    <app-language-selector [label]="translations.language"></app-language-selector>
  </div>
</div>
