@if (!isApiAvailable) {
  <app-api-unavailable></app-api-unavailable>
} @else {
  <div class="auth-container">
    <mat-icon class="logo-icon" svgIcon="logo-night"></mat-icon>
    <div class="outer-container">
      <div class="container">
        @if (isLanguagesEnabled && !isLoading()) {
          <div class="auth-language-selector-container">
            <app-language-selector
              [showFullLanguageName]="false"
              class="no-padding"
            ></app-language-selector>
          </div>
        }
        <div class="title">
          @if (hasInvitation) {
            <h2>
              <ng-container i18n>Please log in to accept invitation from</ng-container>
              {{ invitation.organisationName }}
            </h2>
          } @else {
            <h1 i18n>Login</h1>
          }
        </div>

        @if (isLoading()) {
          <app-loader containerClass="no-margin"></app-loader>
        } @else {
          <form (ngSubmit)="onSubmit()" [formGroup]="formGroup" class="flex-column">
            <app-input
              type="email"
              [emitOnInput]="isFirefox"
              formControlName="email"
              [label]="translations.emailLabel"
              autofocus
              autocomplete="on"
            ></app-input>
            <app-input-password
              [emitOnInput]="isFirefox"
              [label]="translations.passwordLabel"
              formControlName="password"
              autocomplete="current-password"
            ></app-input-password>

            <div class="details">
              <a
                class="underline"
                [routerLink]="['/' + routingEnum.FORGOT_PASSWORD]"
                [state]="{ email: formGroup.controls['email'].value }"
                i18n
              >
                Forgot your password?
              </a>
            </div>

            <div class="login-button">
              <app-button
                class="fresh-green"
                [disabled]="formGroup.invalid"
                accessibilityRole="submit"
                size="large"
                i18n
              >
                Log in
              </app-button>
            </div>
          </form>

          <div class="switch-mode-container text-center">
            <ng-container i18n>Don't have an account yet?</ng-container>
            <a [routerLink]="'/' + routingEnum.SIGN_UP" i18n>Sign up</a>
          </div>
        }
      </div>
    </div>
  </div>
}
