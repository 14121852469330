<div class="form-page-container">
  <div class="header-container">
    <app-page-title
      [title]="isEditing() ? translations.titleEdit : translations.titleAdd"
      [backText]="translations.titleBackText"
      [goBackRoute]="routingEnum.ADMIN_RULESETS"
    ></app-page-title>
  </div>
  <div class="element-content">
    @if (isLoading()) {
      <app-loader></app-loader>
    } @else {
      <app-ruleset-location-info
        #informationSection
        [formGroup]="formGroup"
        (openDocumentTypeDialog)="openDocumentTypeDialog()"
        [selectedLocationTypesIds]="selectedLocationTypesIds()"
        [allCountriesOption]="allCountries()"
        [selectedCountriesCodes]="selectedCountriesCodes()"
        (setSelectedCountries)="updateSelectedListsChangedState()"
        (setSelectedLocationTypesIds)="updateSelectedListsChangedState()"
        (newLocationTypeCreated)="reloadLocationTypes()"
        [resourceType]="resourceType"
      >
        <app-document-list-items
          [documents]="selectedDocumentTypes()"
          (remove)="removeDocumentType($event)"
        ></app-document-list-items>
      </app-ruleset-location-info>
    }
  </div>

  @if (!isLoading()) {
    <app-entity-save-footer
      (save)="onSave()"
      [isDisabled]="isSubmitButtonDisabled()"
    ></app-entity-save-footer>
  }
</div>
