import { animate, style, transition, trigger } from "@angular/animations";
import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
} from "@angular/core";
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
} from "@angular/forms";

import { InputType } from "@components/shared/form/form-input/form-input.model";
import { Requirement } from "@components/shared/inputs/input-password/input-password.model";

import { NEW_PASSWORD_MIN_LENGTH } from "../../../../shared/validators";

@Component({
  standalone: false,
  selector: "app-input-password",
  templateUrl: "./input-password.component.html",
  styleUrls: ["./input-password.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true,
    },
  ],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate("300ms ease-in-out", style({ opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputPasswordComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;

  @Input() class: string;

  @Input() autocomplete: string | boolean = "new-password";

  @Input({ transform: booleanAttribute }) disabled: boolean = false;

  @Input({ transform: booleanAttribute }) showRequirementsTooltip: boolean = false;

  @Input({ transform: booleanAttribute }) autofocus: boolean = false;

  @Input({ transform: booleanAttribute }) emitOnInput: boolean = false;

  inputType: InputType = "password";

  isInputFocused: boolean;

  isDisabled: boolean;

  ngControl: NgControl;

  textValue: string = "";

  passwordRequirements: Requirement[] = [
    {
      description: $localize`At least ${NEW_PASSWORD_MIN_LENGTH}:NEW_PASSWORD_MIN_LENGTH: characters.`,
      errorId: "minLength",
    },
    {
      description: $localize`At least one number.`,
      errorId: "noNumber",
    },
    {
      description: $localize`At least one letter.`,
      errorId: "noLetter",
    },
    {
      description: $localize`At least one special character.`,
      errorId: "noSpecialCharacter",
    },
  ];

  private requirementKeys = this.passwordRequirements.map((requirement) => requirement.errorId);

  constructor(private injector: Injector) {}

  get hasValidationErrors(): boolean {
    return this.ngControl.errors && (this.ngControl.dirty || this.ngControl.touched);
  }

  get hasFilteredValidationErrors(): boolean {
    return this.hasValidationErrors && this.filteredValidationErrors !== null;
  }

  get hasTooltipValidationErrors(): boolean {
    return (
      this.hasValidationErrors && this.requirementKeys.some((key) => this.ngControl.errors[key])
    );
  }

  get filteredValidationErrors(): unknown | ValidationErrors {
    if (!this.ngControl || !this.ngControl.errors) {
      return null;
    }

    if (this.showRequirementsTooltip) {
      return Object.keys(this.ngControl.errors)
        .filter((errorKey) => !this.requirementKeys.includes(errorKey))
        .reduce((filtered, errorKey) => {
          filtered[errorKey] = this.ngControl.errors[errorKey];

          return filtered;
        }, {});
    }

    return this.ngControl.errors;
  }

  get isTooltipVisible(): boolean {
    return this.showRequirementsTooltip && this.isInputFocused && this.hasTooltipValidationErrors;
  }

  get visibilityIcon(): string {
    return this.isInputObscure ? "visibility" : "visibility_off";
  }

  get isInputObscure(): boolean {
    return this.inputType === "password";
  }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  writeValue(value: string): void {
    this.textValue = value;
  }

  registerOnChange(fn: (_: string | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  handleInputChange(value: string): void {
    this.onChange(value);
    this.onTouched();
  }

  toggleInputVisibility(): void {
    this.inputType = this.isInputObscure ? "text" : "password";
  }

  private onChange: (_: unknown | null) => void = () => {};

  private onTouched: () => void = () => {};
}
