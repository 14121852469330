import { inject, Injectable, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";

import { RiskAssessmentReportsApiService } from "@components/shared/risk-assessment-reports/api";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  CommonErrorEnum,
  RecordStateEnum,
  RouteEnum,
} from "@shared/enums";
import {
  IAttachment,
  ICertificate,
  ICertificateExtended,
  IDocument,
  IDocumentExtended,
  IDocumentType,
  IStandard,
} from "@shared/interfaces";
import {
  NotificationService,
  AttachmentsService,
  AuthenticationService,
  CertificatesService,
  DocumentsService,
  DocumentTypesService,
  RulesetsService,
  StandardsService,
  StandardTypesService,
} from "@shared/services";
import { CommentsService } from "@shared/services/api/comments.service";
import { CommonUtils } from "@shared/utils";
import { RulesetUtils } from "@shared/utils/ruleset.utils";

import { InfoDialogComponent } from "../info-dialog/info-dialog.component";

@Injectable()
export abstract class OverlayCommonService {
  public documentCounter = signal<number>(0);

  public hasMissingDocumentTypes = signal<boolean>(false);

  public certificateCounter = signal<number>(0);

  public commentCounter = signal<number>(0);

  readonly reportsCounter = signal(0);

  authenticationService = inject(AuthenticationService);

  attachmentsService = inject(AttachmentsService);

  certificatesService = inject(CertificatesService);

  standardTypesService = inject(StandardTypesService);

  documentsService = inject(DocumentsService);

  standardsService = inject(StandardsService);

  documentTypesService = inject(DocumentTypesService);

  notificationService = inject(NotificationService);

  rulesetsService = inject(RulesetsService);

  commentsService = inject(CommentsService);

  private readonly sanitizer = inject(DomSanitizer);

  readonly reportsApi = inject(RiskAssessmentReportsApiService);

  dialog = inject(MatDialog);

  public async removeAttachment(attachmentType: string, attachmentId: string): Promise<void> {
    try {
      await this.attachmentsService.delete(attachmentId);
      this.notificationService.showSuccess(
        `${CommonUtils.capitaliseFirstLetter(attachmentType)} removed`,
      );
    } catch (error) {
      const supplyChainErrors = error?.error?.errors?.filter(
        (error) => error.code === CommonErrorEnum.NOT_ALLOWED_TO_DELETE_SUPPLY_CHAIN_ATTACHMENT,
      );

      if (supplyChainErrors?.length) {
        const entityName = supplyChainErrors[0].arguments.entity_name;

        const supplyChains = supplyChainErrors.map((error: any) => {
          return {
            name: error.arguments.other_entity_name,
            id: error.arguments.other_id,
          };
        });

        this.showSupplyChainErrorPopup(entityName, supplyChains);
      } else {
        this.notificationService.showError(error);
      }

      return Promise.reject(error);
    }
  }

  private showSupplyChainErrorPopup(
    entityName: string,
    supplyChains: { id: string; name: string }[],
  ): void {
    const contentText = $localize`${entityName}:name: cannot be removed from the location, as it is referenced in the following supply chain(s):`;
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: {
        title: $localize`Cannot remove record`,
        contentSafeHTML: this.sanitizer.bypassSecurityTrustHtml(`
        <p class="text-left">
        ${contentText}
        </p>
        ${supplyChains
          .map(
            (sc) =>
              `<a class="blue-link maintain-font-size" href='/${RouteEnum.SUPPLY_CHAINS_DETAILS}/${sc.id}' target='_blank'>${sc.name}</a>`,
          )
          .join("<br>")}
      `),
      },
    });

    dialogRef.afterClosed().subscribe();
  }

  public async loadSelectedAttachments(
    attachmentType: AttachmentTypeEnum,
    attachmentTarget: AttachmentTargetEnum,
    targetId: string,
    isReturnOnlyAttachments?: boolean,
  ): Promise<ICertificate[] | IDocument[] | IAttachment[]> {
    try {
      const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

      const attachments = await this.attachmentsService.getAll(
        attachmentType,
        CommonUtils.getTargetUri(activeOrganisationId, attachmentTarget, targetId),
        null,
      );

      if (isReturnOnlyAttachments) {
        return attachments;
      }

      if (!attachments?.length) {
        return [];
      }

      let result = [];

      if (attachmentType === AttachmentTypeEnum.CERTIFICATE) {
        const cetificatesIds = attachments.map((attachment) =>
          CommonUtils.getUriId(attachment["attachmentUri"]),
        );

        const certificates = await this.certificatesService.getByIdsGraphQL(cetificatesIds);

        result = certificates.map((certificate: ICertificateExtended) => ({
          ...certificate,
          linkId: attachments.find(
            (attachment) => CommonUtils.getUriId(attachment["attachmentUri"]) === certificate.id,
          ).id,
        })) as ICertificate[];
      } else if (attachmentType === AttachmentTypeEnum.DOCUMENT) {
        const documentsIds = attachments.map((attachment) =>
          CommonUtils.getUriId(attachment["attachmentUri"]),
        );

        const documents = await this.documentsService.getByIdsGraphQL(documentsIds);

        result = documents.map((document: IDocumentExtended) => ({
          id: document.id,
          name: document.name,
          type: document.type.name,
          contentType: document.contentType,
          validityStart: document.validityStart,
          validityEnd: document.validityEnd,
          recordState: document.recordState,
          linkId: attachments.find(
            (attachment) => CommonUtils.getUriId(attachment["attachmentUri"]) === document.id,
          ).id,
        })) as any[];
      }

      return result;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject(error);
    }
  }

  public async allMissingDocumentTypesByRuleSet(
    attachmentTargetType: AttachmentTargetEnum,
    attachmentTargetId: string,
    allDocumentTypes: IDocumentType[],
    conditionsMet: boolean = false,
  ): Promise<IDocumentType[]> {
    if (!this.authenticationService.isRegularUser()) {
      return [];
    }
    try {
      const organisationId = this.authenticationService.getActiveOrganisationId();
      const uri = CommonUtils.getTargetUri(
        organisationId,
        attachmentTargetType,
        attachmentTargetId,
      );
      const records = await this.rulesetsService.getInstantRulesetRecords(uri);
      const record = records[0];

      return RulesetUtils.getDocumentTypesWithRulesets(record, conditionsMet, allDocumentTypes);
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadStandards(): Promise<IStandard[]> {
    try {
      return await this.standardsService.getAll();
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadDocumentTypes(): Promise<IDocumentType[]> {
    try {
      return await this.documentTypesService.getAll();
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async loadCertificates(searchText?: string): Promise<ICertificateExtended[]> {
    try {
      const certificates = await this.certificatesService.getAllGraphQL({
        number: searchText || undefined,
        recordState: RecordStateEnum.ACTIVE,
      });

      return certificates;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  private convertExtendedDocumentToDocument(extendedDocument: IDocumentExtended): IDocument {
    return {
      ...extendedDocument,
      typeName: extendedDocument.type?.name,
      type: extendedDocument.type?.name,
      createdBy: null,
      createdTime: null,
    };
  }

  public async loadDocuments(searchText?: string): Promise<IDocument[]> {
    try {
      const extendedDocuments = await this.documentsService.getAllGraphQL(
        { name: searchText || undefined, recordState: RecordStateEnum.ACTIVE, hasContent: true },
        undefined,
        ["TAGS"],
      );

      const documents: IDocument[] = [];

      for (const extendedDocument of extendedDocuments) {
        const document = this.convertExtendedDocumentToDocument(extendedDocument);

        documents.push(document);
      }

      return documents;
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  public async createAttachment(
    attachmentTargetType: AttachmentTargetEnum,
    attachmentTargetId: string,
    attachmentType: AttachmentTypeEnum,
    attachmentId: string,
  ): Promise<void> {
    try {
      const activeOrganisationId = this.authenticationService.getActiveOrganisationId();
      const payload = CommonUtils.getSaveAttachmentPayload(
        activeOrganisationId,
        attachmentTargetType,
        attachmentTargetId,
        attachmentType,
        attachmentId,
      );

      await this.attachmentsService.create(payload);
    } catch (error) {
      this.notificationService.showError(error);

      return Promise.reject();
    }
  }

  setCountersToLoadingState(): void {
    this.hasMissingDocumentTypes.set(false);
    this.documentCounter.set(null);
    this.certificateCounter.set(null);
    this.commentCounter.set(null);
    this.reportsCounter.set(null);
  }

  setCountersEmptyState(): void {
    this.hasMissingDocumentTypes.set(false);
    this.documentCounter.set(0);
    this.certificateCounter.set(0);
    this.commentCounter.set(0);
    this.reportsCounter.set(0);
  }

  async loadDocumentCounter(
    attachmentTargetId: string,
    attachmentTargetType: AttachmentTargetEnum,
  ): Promise<void> {
    const allDocumentTypes = await this.loadDocumentTypes();

    const allMissingDocumentTypes = await this.allMissingDocumentTypesByRuleSet(
      attachmentTargetType,
      attachmentTargetId,
      allDocumentTypes,
    );

    this.hasMissingDocumentTypes.set(!!allMissingDocumentTypes.length);

    const documentAttachments = await this.loadSelectedAttachments(
      AttachmentTypeEnum.DOCUMENT,
      attachmentTargetType,
      attachmentTargetId,
      true,
    );

    this.documentCounter.set(documentAttachments.length);
  }

  async loadCertificateCounter(
    attachmentTargetId: string,
    attachmentTargetType: AttachmentTargetEnum,
  ): Promise<void> {
    const certificates = await this.loadSelectedAttachments(
      AttachmentTypeEnum.CERTIFICATE,
      attachmentTargetType,
      attachmentTargetId,
    );

    this.certificateCounter.set(certificates.length);
  }

  async loadCommentCounter(entityUri: string): Promise<void> {
    if (!this.authenticationService.isRegularUser()) {
      return;
    }
    const comments = (await this.commentsService.getAll(entityUri)).content;

    this.commentCounter.set(comments.length);
  }

  async loadReportsCounter(recordUri: string): Promise<void> {
    const { page } = await this.reportsApi.getAll({ recordUri, size: 1 });

    this.reportsCounter.set(page.totalElements);
  }
}
