export namespace SlideOverlaySupplyChainModel {
  export enum SupplyChainTabEnum {
    GRAPH = 0,
    LOCATIONS = 1,
    MAP = 2,
    DOCUMENTS = 3,
  }

  export type SupplyChainTabEnumValues =
    (typeof SupplyChainTabEnum)[keyof typeof SupplyChainTabEnum];
}
