import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";

import { lastValueFrom } from "rxjs";

import { ConfirmDialogComponent } from "@components/shared/confirm-dialog/confirm-dialog.component";
import { PdfSplitterModel } from "@components/shared/pdf-splitter/pdf-splitter.model";
import { TextConstants } from "@shared/constants";
import { ConfirmDialogResponseEnum, EntityTypeEnum } from "@shared/enums";
import { ISelectOption, ITagExtended } from "@shared/interfaces";

@Component({
  selector: "app-pdf-splitter-document-fields",
  templateUrl: "./pdf-splitter-document-fields.component.html",
  styleUrls: ["./pdf-splitter-document-fields.component.scss"],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfSplitterDocumentFieldsComponent {
  public formGroup = input.required<FormGroup<PdfSplitterModel.IDocumentFormField>>();

  public pages = input.required<PdfSplitterModel.IPage[]>();

  public index = input.required<number>();

  public canDelete = input.required<boolean>();

  public documentTypeOptions = input.required<ISelectOption[]>();

  private dialog: MatDialog = inject(MatDialog);

  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly typeTooltipHint: string = TextConstants.DOCUMENT_TYPE_TOOLTIP_HINT;

  public readonly documentFieldEnum: typeof PdfSplitterModel.DocumentFieldEnum =
    PdfSplitterModel.DocumentFieldEnum;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    typeLabel: TextConstants.TYPE,
    tagsLabel: TextConstants.TAGS,
    checkboxPeriodLabel: $localize`Specify issuance date and/or validity period`,
    issuanceLabel: TextConstants.ISSUANCE_DATE,
    validityStartLabel: TextConstants.VALID_FROM,
    validityEndLabel: TextConstants.VALID_TO,
    includesTp: $localize`Includes the document in the final output if checked. Excludes if unchecked.`,
  };

  @Output()
  public delete = new EventEmitter<void>();

  @Output()
  public scrollToPage = new EventEmitter<number>();

  public pageRangeText = computed(() => {
    const includedPages = this.pages().filter((page) => page.isIncluded);

    if (includedPages.length === 0) {
      return $localize`No pages included`;
    }

    if (includedPages.length === 1) {
      return $localize`Page: ${includedPages[0].number}:pages:`;
    }

    const ranges: string[] = [];
    let rangeStart = includedPages[0].number;
    let prev = includedPages[0].number;

    for (let i = 1; i < includedPages.length; i++) {
      const current = includedPages[i].number;

      if (current !== prev + 1) {
        ranges.push(rangeStart === prev ? `${rangeStart}` : `${rangeStart}-${prev}`);
        rangeStart = current;
      }
      prev = current;
    }

    ranges.push(rangeStart === prev ? `${rangeStart}` : `${rangeStart}-${prev}`);

    return ranges.length === 1
      ? $localize`Page range: ${ranges[0]}:range:`
      : $localize`Page ranges: ${ranges.join(", ")}:ranges:`;
  });

  public onTagsChanged(tags: ITagExtended[]): void {
    this.formGroup().get(PdfSplitterModel.DocumentFieldEnum.TAGS).setValue(tags);
  }

  public async onDelete(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Deleting the document will remove all of its pages along with it. Are you sure you want to proceed?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    const response = await lastValueFrom(dialogRef.afterClosed());

    if (response === ConfirmDialogResponseEnum.CONFIRM) {
      this.delete.emit();
    }
  }

  public scrollToPages(): void {
    this.scrollToPage.emit(this.index());
  }
}
