import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { ConnectionStatusEnum, RecordStateEnum } from "src/app/shared/enums";
import { IOrganisation, ISelectOption } from "src/app/shared/interfaces";
import { ConnectionsService, ConnectRequestsService } from "src/app/shared/services";

import { AddConnectionDialogComponent } from "@components/locations";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { NotificationService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./settings-accept-connection-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsAcceptConnectionDialogComponent implements OnInit {
  public isLoading = signal(true);

  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    connection: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public connectionsOptions: ISelectOption[] = [];

  public readonly translations: any = {
    connectionLabel: $localize`Organisations`,
  };

  constructor(
    public dialogRef: MatDialogRef<SettingsAcceptConnectionDialogComponent>,
    private connectionsService: ConnectionsService,
    private notificationService: NotificationService,
    private connectRequestsService: ConnectRequestsService,
    private overlayPageService: SlideOverlayPageService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      activeOrganisationId: string;
      connectionRequestId: string;
      publicOrganisation: any;
      countryOptions: ISelectOption[];
    },
  ) {}

  public async ngOnInit(): Promise<void> {
    const connections = await this.connectionsService.getAll(RecordStateEnum.ACTIVE);

    this.connectionsOptions = connections
      .filter((c) => c.connectStatus !== ConnectionStatusEnum.CONNECTED)
      .map((c) => ({ label: c.name, value: c.id }) as ISelectOption);

    this.isLoading.set(false);
  }

  public onAddNewConnection = async (): Promise<void> => {
    const result = await this.overlayPageService.openDialog<
      { savedOrganisation: IOrganisation },
      { connection: IOrganisation; countryOptions: ISelectOption[] }
    >(AddConnectionDialogComponent, {
      connection: this.data.publicOrganisation,
      countryOptions: this.data.countryOptions,
    });

    if (result?.savedOrganisation) {
      const newConnectionOption = {
        label: result.savedOrganisation.name,
        value: result.savedOrganisation.id,
      };

      this.connectionsOptions.unshift(newConnectionOption);
      this.formGroup.controls["connection"].setValue(newConnectionOption);
    }
  };

  public onSubmit = async (): Promise<void> => {
    try {
      this.isLoading.set(true);

      const connectionId = this.formGroup.controls["connection"].value.value;
      const payload: any = {
        connectionUri: `/organisations/${this.data.activeOrganisationId}/connections/${connectionId}`,
      };

      await this.connectRequestsService.acceptInbound(this.data.connectionRequestId, payload);
      this.notificationService.showSuccess($localize`Connection created`);

      this.onClose(true);
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
