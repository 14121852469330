import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { RecordStateEnum, RouteEnum } from "src/app/shared/enums";

import { EditStandardDialogComponent } from "./edit-standard-dialog/edit-standard-dialog.component";
import { StandardsTableComponent } from "../../shared";

@Component({
  standalone: false,
  selector: "app-settings-standards",
  templateUrl: "./settings-standards.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsStandardsComponent {
  @ViewChild("standardsTable") standardsTable: StandardsTableComponent;

  public readonly routingEnum = RouteEnum;

  public readonly recordStateEnum = RecordStateEnum;

  constructor(private dialog: MatDialog) {}

  public onAdd = async (): Promise<void> => {
    const dialogRef = this.dialog.open(EditStandardDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.standardsTable.getAll();
      }
    });
  };
}
