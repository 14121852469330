import { Inject, Injectable, inject } from "@angular/core";

import { Observable } from "rxjs";

import { getConnectionsByIdsGraphQLQuery, getConnectionsGraphQLQuery } from "@shared/queries";
import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { GraphService } from "./graph.service";
import { CommonConstants } from "../../constants";
import { ConnectionStatusEnum, RecordStateEnum } from "../../enums";
import {
  IConfig,
  IConnectionByIdsGraphQLResponse,
  IConnectionExtended,
  IConnectionGraphQLFilter,
  IConnectionGraphQLResponse,
  IOrganisation,
  IOrganisationPayload,
  IPageableContent,
  IRecordState,
} from "../../interfaces";
import { FormUtils } from "../../utils";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class ConnectionsService {
  private graphService = inject(GraphService);

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  private getBaseUrl = (): string =>
    `${this.environment.baseUrl}organisations/${this.authenticationService.getActiveOrganisationId()}/connections`;

  public getPage = async (
    name: string = undefined,
    size: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    recordState: RecordStateEnum = undefined,
    connectStatus: ConnectionStatusEnum = undefined,
    publicUri: string = undefined,
  ): Promise<IPageableContent<IOrganisation>> => {
    return await this.getPageSubscription({
      name,
      size,
      page,
      recordState,
      connectStatus,
      publicUri,
    }).toPromise();
  };

  public getPageSubscription = ({
    name = undefined,
    size = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    page = 0,
    recordState = undefined,
    connectStatus = undefined,
    publicUri = undefined,
  }: {
    name?: string;
    size?: number;
    page?: number;
    recordState?: RecordStateEnum;
    connectStatus?: ConnectionStatusEnum;
    publicUri?: string;
  }): Observable<IPageableContent<IOrganisation>> => {
    const url = `${this.getBaseUrl()}?${FormUtils.addUrlParams({ name, size, page, recordState, connectStatus, publicUri })}`;

    return this.apiService.get<IPageableContent<IOrganisation>>(url);
  };

  public async getAll(
    recordState: RecordStateEnum = undefined,
    connectStatus: ConnectionStatusEnum = undefined,
    publicUri: string = undefined,
  ): Promise<IOrganisation[]> {
    const result: IOrganisation[] = [];
    let page = 0;
    let hasNextPage: boolean = true;

    while (hasNextPage) {
      const {
        content,
        page: { totalElements },
      } = await this.getPage(
        undefined,
        CommonConstants.MAX_API_GET_ITEMS_SIZE,
        page,
        recordState,
        connectStatus,
        publicUri,
      );

      result.push(...content);

      page++;
      hasNextPage = result.length < totalElements;
    }

    return result;
  }

  public async getAllGraphQL(
    filter: IConnectionGraphQLFilter = null,
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IConnectionExtended[]> {
    const result: IConnectionExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getConnectionsGraphQLQuery(
        activeOrganisationId,
        filter,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IConnectionGraphQLResponse,
        IConnectionExtended
      >("loadConnections", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public async getByIdsGraphQL(
    ids: string[],
    first: number = CommonConstants.MAX_API_GET_ITEMS_SIZE,
    include: string[] = [],
  ): Promise<IConnectionExtended[]> {
    if (!ids.length) {
      return [];
    }
    const result: IConnectionExtended[] = [];
    let cursor: string | undefined = undefined;
    let hasNextPage: boolean = true;
    const activeOrganisationId = this.authenticationService.getActiveOrganisationId();

    while (hasNextPage) {
      const query = getConnectionsByIdsGraphQLQuery(
        activeOrganisationId,
        ids,
        first,
        null,
        cursor,
        null,
        include,
      );
      const { content, pageInfo, totalCount } = await this.graphService.fetchSinglePage<
        IConnectionByIdsGraphQLResponse,
        IConnectionExtended
      >("loadConnectionsByIds", query, this.graphQLPropertiesToAddFunction);

      result.push(...content);

      cursor = pageInfo.endCursor;
      hasNextPage = result.length < totalCount;
    }

    return result;
  }

  public get = async (id: string): Promise<IOrganisation> =>
    await this.apiService.get<IOrganisation>(`${this.getBaseUrl()}/${id}`).toPromise();

  public delete = async (id: string): Promise<void> =>
    await this.apiService.delete<void>(`${this.getBaseUrl()}/${id}`).toPromise();

  public createOrUpdate = async (
    payload: IOrganisationPayload,
    id?: string,
  ): Promise<IOrganisation> => {
    if (id) {
      return await this.apiService
        .put<IOrganisation>(`${this.getBaseUrl()}/${id}`, payload)
        .toPromise();
    } else {
      return await this.apiService.post<IOrganisation>(`${this.getBaseUrl()}`, payload).toPromise();
    }
  };

  public setRecordState = async (payload: IRecordState, id: string): Promise<IOrganisation> =>
    await this.apiService.put<IOrganisation>(`${this.getBaseUrl()}/${id}`, payload).toPromise();

  private graphQLPropertiesToAddFunction = (edge: any): any => {
    return {
      certificates: edge.node.certificates,
      documents: edge.node.documents,
    };
  };
}
