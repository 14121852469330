<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>

  <app-button-group>
    <app-button (pressed)="onAdd()" [visible]="isInviteUserEnabled">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Invite user</ng-container>
    </app-button>
  </app-button-group>
</div>

<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [mat-stretch-tabs]="false"
  [selectedIndex]="selectedTabIndex"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Existing</ng-container>

      <app-indicator [value]="usersTable.totalElements()"></app-indicator>
    </ng-template>
    <app-users-table
      #usersTable
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
    ></app-users-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Invited</ng-container>

      <app-indicator [value]="usersInvitedTable.totalElements()"></app-indicator>
    </ng-template>
    <app-users-invited-table
      #usersInvitedTable
      [isSaveTableState]="true"
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
    ></app-users-invited-table>
  </mat-tab>
</mat-tab-group>
