export enum CrossOrgShareDataTypeEnum {
  LOCATION_TYPES = "location-types",
  DOCUMENT_TYPES = "document-types",
  DOCUMENTS = "documents",
  CONNECTIONS = "connections",
  LOCATIONS = "locations",
  CERTIFICATES = "certificates",
  MATERIALS = "materials",
  PRODUCTS = "products",
  ITEMS = "items",
  DELIVERIES = "deliveries",
  PROCESS_TYPES = "process-types",
  PROCESSES = "processes",
  SUPPLY_CHAINS = "supply-chains",
}
