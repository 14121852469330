import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject,
  signal,
  viewChild,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup } from "@angular/forms";

import { Subscription, defaultIfEmpty, filter, lastValueFrom, tap } from "rxjs";

import {
  AddEditRiskLevelSetDialogComponent,
  AddEditRiskLevelSetDialogData,
} from "@components/settings/settings-risk-level-sets";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { RiskAssessmentTemplatesApiService } from "@components/shared/risk-assessment-templates/api";
import {
  RiskAssessmentTemplate,
  RiskAssessmentTemplatePayload,
} from "@components/shared/risk-assessment-templates/models";
import { validResourceType } from "@components/shared/risk-assessment-templates/utils";
import { RiskLevelSetsStateService } from "@components/shared/risk-level-sets/state";
import { TextConstants } from "@shared/constants";
import { EntityTypeEnum, OverlayTabEnum, RouteEnum } from "@shared/enums";
import { IRecordState, ISelectOption } from "@shared/interfaces";
import { CustomValidators } from "@shared/validators";

import { CategoryFormGroup, IndicatorFormGroup } from "./models";
import { RiskAssessmentTemplateOverlayIndicatorsFormService } from "./risk-assessment-template-overlay-indicators-form.service";
import { RiskAssessmentTemplateOverlayIndicatorsComponent } from "./risk-assessment-template-overlay-indicators.component";

interface RiskLevelOption extends ISelectOption {
  value: string;
}

@Component({
  standalone: false,
  selector: "app-risk-assessment-template-overlay",
  templateUrl: "./risk-assessment-template-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [RiskAssessmentTemplateOverlayIndicatorsFormService],
})
export class RiskAssessmentTemplateOverlayComponent
  extends SlideOverlayPageClass
  implements OnInit
{
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  override entityType = EntityTypeEnum.RISK_ASSESSMENT_TEMPLATES;

  private readonly destroyRef = inject(DestroyRef);

  private readonly templatesApi = inject(RiskAssessmentTemplatesApiService);

  readonly translations: any = {
    nameLabel: TextConstants.NAME,
    descriptionLabel: TextConstants.DESCRIPTION,
    riskLevelSetLabel: $localize`Risk level set`,
    descriptionPh: $localize`Type your description here…`,
    addNewTp: TextConstants.ADD_NEW,
  };

  private readonly indicatorsFormService = inject(
    RiskAssessmentTemplateOverlayIndicatorsFormService,
  );

  readonly riskLevelSetsState = inject(RiskLevelSetsStateService);

  private readonly indicatorFormGroupSubscriptions = new Map<FormGroup, Subscription>();

  readonly indicators = viewChild(RiskAssessmentTemplateOverlayIndicatorsComponent);

  readonly form = this.formBuilder.group({
    name: [
      "",
      [CustomValidators.required],
      [
        CustomValidators.entityAlreadyExists(this.templatesApi, this.element?.id, {
          appliesTo: this.resourceType,
        }),
      ],
    ],
    description: this.formBuilder.control<string>(null, [CustomValidators.maxLength(1000)]),
    indicators: this.formBuilder.array<FormGroup<IndicatorFormGroup | CategoryFormGroup>>([]),
    riskLevelSet: this.formBuilder.control<RiskLevelOption>(null, [CustomValidators.required]),
  });

  readonly formValueChanges$ = this.form.valueChanges.pipe(
    tap(() => this.updateHasFormValuesChanged(this.form, this.form.getRawValue())),
  );

  readonly riskLevelValueChanges$ = this.form.controls.riskLevelSet.valueChanges.pipe(
    tap((riskLevelSet) => this.riskLevelSetsState.setSelectedSet(riskLevelSet?.value)),
  );

  readonly indicatorAdded$ = this.indicatorsFormService.controlAdded$.pipe(
    tap(this.onIndicatorAdded.bind(this)),
  );

  readonly indicatorRemoved$ = this.indicatorsFormService.controlRemoved$.pipe(
    tap(this.onIndicatorRemoved.bind(this)),
  );

  get indicatorsCount() {
    let count = 0;

    this.element?.indicatorGroups?.forEach((group) => (count += group.indicators.length));

    return count;
  }

  get resourceType() {
    return this.route.snapshot.queryParams["type"];
  }

  override get isSubmitButtonDisabled() {
    return false;
  }

  override get requiresConfirmation(): boolean {
    return this.hasFormValuesChanged || this.hasInitialFormValueChanged(this.form.getRawValue());
  }

  override element: RiskAssessmentTemplate;

  override initialFormValue = this.form.getRawValue();

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.TEMPLATE_DETAILS, isEnabled: true }],
      [OverlayTabEnum.INDICATORS, { title: TextConstants.INDICATORS, isEnabled: false }],
    ]),
  );

  constructor() {
    super();
  }

  async ngOnInit() {
    this.overlay.showLoading();

    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_RISK_ASSESSMENT_TEMPLATE)) {
      return;
    }

    await this.validateResourceTypeUrlParam();
    await this.riskLevelSetsState.init();
    this.indicatorsFormService.init(this.form.controls.indicators);

    if (this.recordId) {
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
    }

    this.overlay.dismissLoading();
  }

  protected override async archiveRecord(id: string, payload: IRecordState) {
    await this.templatesApi.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string) {
    await this.templatesApi.delete(id);
  }

  protected override async reloadElement(id: string) {
    this.overlay.showLoading();

    try {
      this.element = await this.templatesApi.get(id);
      this.riskLevelSetsState.setSelectedSet(this.element.riskLevelSet);
      this.setEditMode();
      await this.indicatorsFormService.set(this.element.indicatorGroups);
      await this.setupForm();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  override async setupForm() {
    if (this.recordId) {
      const { description, name, riskLevelSet } = this.element;

      this.form.patchValue({
        name,
        description,
        riskLevelSet: this.riskLevelSetsState
          .options()
          .find((option) => option.value === riskLevelSet),
      });
      this.initialFormValue = this.form.getRawValue();
      this.hasFormValuesChanged = false;
    }
  }

  override async save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    try {
      this.element = await this.templatesApi.createOrUpdate(this.buildPayload(), this.recordId);
      this.hasFormValuesChanged = false;
      this.initialFormValue = this.form.getRawValue();

      this.notificationService.showSuccess(
        this.recordId
          ? $localize`Risk assessment template modified`
          : $localize`Risk assessment template created`,
      );

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  override async afterSave(isSaveOnly?: boolean) {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(
        this.routerService.getRiskAssessmentTemplateLink(this.element.id, true, {
          type: this.resourceType,
        }),
        { replaceUrl: true },
      );
    }
  }

  async addRiskLevelSet() {
    const dialogRef = this.dialog.open<
      AddEditRiskLevelSetDialogComponent,
      AddEditRiskLevelSetDialogData
    >(AddEditRiskLevelSetDialogComponent);

    const riskLevelSet = await lastValueFrom(
      dialogRef.afterClosed().pipe(filter(Boolean), defaultIfEmpty(null)),
    );

    await this.riskLevelSetsState.fetchData();

    setTimeout(() =>
      this.form.controls.riskLevelSet.setValue(
        this.riskLevelSetsState.options().find((option) => option.label === riskLevelSet.name),
      ),
    );
  }

  private buildPayload(): RiskAssessmentTemplatePayload {
    const { name, description, riskLevelSet } = this.form.getRawValue();

    return {
      name,
      description,
      riskLevelSet: riskLevelSet.value,
      appliesTo: this.resourceType,
      recordState: null,
      indicatorGroups: this.recordId
        ? this.indicatorsFormService.buildPayload()
        : [{ name: null, indicators: [{ guidance: "", indicator: "Name", mitigations: [] }] }],
    };
  }

  private onIndicatorAdded(formGroup: FormGroup) {
    const subscription = formGroup.valueChanges
      .pipe(
        tap((value) => this.updateHasFormValuesChanged(formGroup, value)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    this.indicatorFormGroupSubscriptions.set(formGroup, subscription);
  }

  private onIndicatorRemoved(formGroup: FormGroup) {
    const subscription = this.indicatorFormGroupSubscriptions.get(formGroup);

    if (subscription) {
      subscription.unsubscribe();
      this.indicatorFormGroupSubscriptions.delete(formGroup);
    }

    this.hasFormValuesChanged = true;
  }

  private updateHasFormValuesChanged(formGroup: FormGroup, value: object) {
    this.hasFormValuesChanged = !formGroup.pristine && this.hasInitialFormValueChanged(value);
  }

  private async validateResourceTypeUrlParam(): Promise<void> {
    if (!validResourceType(this.resourceType)) {
      await this.overlay.close(true);
      this.notificationService.showError(TextConstants.UNSUPPORTED_RESOURCE_TYPE);
      await this.routerService.navigate(RouteEnum.ADMIN_RISK_ASSESSMENT_TEMPLATES);

      return;
    }
  }
}
