import { Injectable } from "@angular/core";

import { OrganisationsService, UsersService } from "./api";
import { AuthenticationService } from "./authentication.service";
import { IAvailableOrganisation } from "../interfaces";
import { CommonUtils } from "../utils";

@Injectable({
  providedIn: "root",
})
export class SetUserOrganisationsService {
  constructor(
    private authenticationService: AuthenticationService,
    private usersService: UsersService,
    private organisationsService: OrganisationsService,
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  public setOrganisations = async (beforeSetFunction: Function = null): Promise<void> => {
    const organisations: IAvailableOrganisation[] = [];
    const userId = this.authenticationService.getUserId();
    const userOrganisationMemberships = (
      await this.usersService.getUserOrganisationMembershipsPage(userId)
    ).content;

    await Promise.all(
      userOrganisationMemberships.map(async (userOrgMembership) =>
        organisations.push(await this.getOrganisationInfo(userOrgMembership)),
      ),
    );

    if (beforeSetFunction) {
      beforeSetFunction();
    }
    this.authenticationService.setAvailableOrganisations(organisations);
  };

  private getOrganisationInfo = async (userOrgMembership: any): Promise<any> => {
    const organisationId = CommonUtils.getUriId(userOrgMembership.organisation);
    const membershipId = CommonUtils.getUriId(userOrgMembership.membership);
    let organisation = null;
    let member = null;

    await Promise.all([
      (organisation = await this.organisationsService.get(organisationId)),
      (member = await this.organisationsService.getOrganisationMember(
        organisationId,
        membershipId,
      )),
    ]);

    return {
      id: organisationId,
      name: organisation.name,
      isSystemAdmin: organisation.roles.indexOf("ADMIN") !== -1 || false,
      roles: member.roles,
      status: member.status,
    };
  };
}
