<span
  class="app-tag"
  [class]="class"
  [class.loading]="tag.loading"
  [class.blue-link]="isTagClickable"
  (click)="onClick()"
  (keydown)="onClick()"
  tabindex="1"
>
  @if (tag.loading) {
    <mat-icon class="rotate-animation">refresh</mat-icon>
  } @else if (canRemove) {
    <mat-icon (click)="clickRemoveTag()" [tp]="translations.removeTp">cancel</mat-icon>
  }
  <ng-content></ng-content>
  @if (tag.icon) {
    <mat-icon [tp]="tag.iconTooltip ?? ''">{{ tag.icon }}</mat-icon>
  }
  <div
    class="text"
    [tp]="tag.label?.length > maxTextLengthToShow ? tag.label : ''"
    [style.max-width]="maxTextLengthToShow + 'ch !important'"
  >
    {{ tag.label }}
  </div>
</span>
