import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-dropdown-menu",
  template: "<ng-content></ng-content>",
  styleUrls: ["./dropdown-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent {}
