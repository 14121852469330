<div class="grid-footer">
  <div class="page-size">
    <ng-container i18n>Records per page</ng-container>
    <ng-container>:</ng-container>
    <div
      class="page-size-select container-flex-center clickable"
      [matMenuTriggerFor]="recordsPerPageMenu"
    >
      {{ paginationPageSize() }}
      <mat-icon class="icon-16">keyboard_arrow_down</mat-icon>
    </div>
    <mat-menu #recordsPerPageMenu="matMenu">
      @for (pageSize of tableAvailablePageSizes(); track pageSize) {
        <button type="button" mat-menu-item (click)="onPaginationPageSizeChanged(pageSize)">
          {{ pageSize }}
        </button>
      }
    </mat-menu>
  </div>
  <div class="elements">
    {{ recordsInfo() }}
  </div>
  <div class="pages">
    <button
      type="button"
      mat-icon-button
      color="primary"
      [disabled]="!canGoToPrevPage()"
      (click)="grid().api.paginationGoToFirstPage()"
    >
      <mat-icon>first_page</mat-icon>
    </button>
    <button
      type="button"
      mat-icon-button
      color="primary"
      [disabled]="!canGoToPrevPage()"
      (click)="grid().api.paginationGoToPreviousPage()"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <div class="text">
      {{ pagesInfo() }}
    </div>

    <button
      type="button"
      mat-icon-button
      color="primary"
      [disabled]="!canGoToNextPage()"
      (click)="grid().api.paginationGoToNextPage()"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
    <button
      type="button"
      mat-icon-button
      color="primary"
      [disabled]="!canGoToNextPage()"
      (click)="grid().api.paginationGoToLastPage()"
    >
      <mat-icon>last_page</mat-icon>
    </button>
  </div>
</div>
