import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy } from "@angular/core";

import { ColDef, IRowNode } from "ag-grid-community";
import { Subscription } from "rxjs";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TableWithRiskAssessmentReportsService } from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import {
  FirstExistingValueCellRendererComponent,
  LinkCellRendererComponent,
} from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  RecordStateEnum,
  TableEnum,
} from "@shared/enums";
import { IProduct, IProductExtended } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { FeatureFlagService, ProductsService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { ColumnUtils, CommonUtils } from "@shared/utils";

import { TableBaseClass } from "../table/models";

@Component({
  standalone: false,
  selector: "app-products-table",
  templateUrl: "./products-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsTableComponent
  extends TableBaseClass<IProductExtended>
  implements AfterViewInit, OnDestroy
{
  @Input()
  public products: IProductExtended[] = null;

  @Input()
  public productsIds: string[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.PRODUCTS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public columns: string[] = [
    "recordState",
    "name",
    "unitOfMeasurement.name",
    "allowedMaterials",
    "materials",
    "tags",
  ];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  private subscriptions = new Subscription();

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor(
    private routerService: RouterService,
    private productsService: ProductsService,
    private overlay: SlideOverlayPageService,
    private featureFlagService: FeatureFlagService,
    private tableWithReports: TableWithRiskAssessmentReportsService,
  ) {
    super();

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.PRODUCT);
    }

    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(({ entityType }) => {
        if (
          entityType === EntityTypeEnum.PRODUCTS ||
          entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS
        ) {
          this.getAll();
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.productsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.productsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.productsService),
        ],
      ]),
    };
  };

  override setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getProductLink,
        },
      },
      {
        headerName: $localize`Unit of measurement`,
        field: "unitOfMeasurement.name",
        cellRenderer: FirstExistingValueCellRendererComponent,
        cellRendererParams: {
          propertiesOrderedByPriority: ["defaultCustomUnit.name", "unitOfMeasurement.name"],
        },
      },
    ];

    const materialsCellRendererParams = {
      linkRouteIdParam: "id",
      linkRouteFn: this.routerService.getMaterialLink,
      textParam: "name",
    };

    if (this.isOldMaterialsEnabled) {
      columnDefs.push(
        ColumnUtils.chips("Allowed materials", "allowedMaterials", materialsCellRendererParams),
      );
    } else {
      columnDefs.push(ColumnUtils.chips("Materials", "materials", materialsCellRendererParams));
    }

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags());
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.isRegularUser) {
      columnDefs.push(
        this.tableWithReports.lastRiskAssessedColDef,
        this.tableWithReports.residualRiskLevelColDef,
      );
    }

    this.columnDefs.set(columnDefs);
  };

  public onRowClick = (row: IProduct): void => {
    this.routerService.navigate(this.routerService.getProductLink(row.id));
  };

  override getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    const tagsFieldPresent = this.columnDefs().some((c) => c.field === "tags");
    const fieldsToInclude = ["DEFAULT_CUSTOM_UNIT"];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (this.isOldMaterialsEnabled) {
      fieldsToInclude.push("ALLOWED_MATERIALS");
    } else {
      fieldsToInclude.push("MATERIALS");
    }

    if (this.products) {
      this.rowData.set(this.products);

      this.isLoading.set(false);
    } else if (this.productsIds) {
      try {
        if (!this.productsIds.length) {
          this.rowData.set([]);
          this.isLoading.set(false);

          return;
        }

        const products = await this.productsService.getByIdsGraphQL(
          this.productsIds,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(products);

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const products = await this.productsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(products);

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  onRowsDataUpdated(rows: IRowNode<IProductExtended>[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement());
    }
  }
}
