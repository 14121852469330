<article class="field-group-container">
  @if (hasGeoLocationValue && !showCoordinateForm()) {
    <div class="wide-map-container">
      @if (!overlay.viewMode()) {
        <app-button-group class="cg-10">
          <app-button
            class="edit-button"
            size="large"
            type="icon"
            [disabled]="!canEditCoordinates()"
            [tp]="
              canEditCoordinates()
                ? translations.editCoordinatesTp
                : translations.editNotSupportedTp
            "
            (pressed)="editCoordinates()"
          >
            <mat-icon>edit</mat-icon>
          </app-button>

          <app-button
            class="edit-button"
            [tp]="translations.removeTp"
            size="large"
            type="icon"
            (pressed)="onDeleteMap(true)"
          >
            <mat-icon>delete</mat-icon>
          </app-button>
        </app-button-group>
      }
      <app-map
        [geoLocation]="
          canEditCoordinates()
            ? formGroup.controls['geoLocation'].value
            : geojson() || formGroup.controls['geoLocation'].value
        "
        [locationName]="formGroup.controls['name'].value"
        [scrollWheelEnabled]="true"
      ></app-map>
    </div>
  } @else {
    @if (showCoordinateForm() && !overlay.viewMode()) {
      <div class="form-container">
        <div class="coordinates-container">
          <div class="buttons-container container-flex-left">
            <div class="container-flex">
              <app-button-group>
                @if (getValidFormGroups().length) {
                  <ng-container>
                    <app-button
                      [tp]="translations.swapTp"
                      size="medium"
                      type="icon"
                      (pressed)="coordinatesFormComponent.swapColumns()"
                    >
                      <mat-icon icon-medium-18>sync_alt</mat-icon>
                    </app-button>
                  </ng-container>
                }

                <app-button
                  [disabled]="coordinatesformGroups.invalid || getValidFormGroups().length === 10"
                  (pressed)="addArea()"
                  size="medium"
                  type="icon"
                  [tp]="
                    coordinatesformGroups.invalid
                      ? 'Set valid coordinates to the current area to add more'
                      : getValidFormGroups().length === 10
                        ? 'You can only add up to 10 areas'
                        : 'Add new area'
                  "
                >
                  <mat-icon icon-medium-18>add</mat-icon>
                </app-button>
              </app-button-group>
              <mat-icon class="info-icon" [tp]="translations.pasteTp" tpPlacement="right">
                help
              </mat-icon>
            </div>
            @if (getValidFormGroups().length) {
              <ng-container>
                <app-button
                  [tp]="translations.removeGpsTp"
                  size="medium"
                  type="icon"
                  (pressed)="onDeleteMap()"
                  [disabled]="!hasGeoLocationValue"
                >
                  <mat-icon icon-medium-18>delete</mat-icon>
                </app-button>
              </ng-container>
            }
          </div>
          @if (getValidFormGroups().length) {
            @if (getValidFormGroups().length === 1) {
              <app-coordinates-form
                [formGroup]="getFormGroup(0)"
                (coordinatesChanged)="onCoordinatesChanged()"
              ></app-coordinates-form>
            } @else {
              <mat-tab-group
                animationDuration="0ms"
                mat-stretch-tabs="false"
                mat-align-tabs="start"
                [selectedIndex]="selectedTabIndex()"
                (selectedIndexChange)="selectedTabIndex.set($event)"
                class="no-content-margin no-overflow"
              >
                @for (area of getValidFormGroups(); let index = $index; track index) {
                  <mat-tab [label]="getAreaLabel(index)" labelClass="hover-visibility-container">
                    <ng-template mat-tab-label>
                      <div class="tab-label-container container-flex-space-between">
                        <span class="tab-label" [tp]="translations.areaTp">
                          {{ getAreaLabel(index) }}
                        </span>
                        @if (getValidFormGroups().length > 1) {
                          <app-button
                            type="icon"
                            [tp]="translations.removeAreaTp"
                            (pressed)="$event.stopPropagation(); onDeleteArea(index)"
                            class="delete-area-button on-hover-visibility-show"
                          >
                            <mat-icon icon-small>close</mat-icon>
                          </app-button>
                        }
                      </div>
                    </ng-template>
                    @if (selectedTabIndex() === index) {
                      <app-coordinates-form
                        [formGroup]="getFormGroup(index)"
                        (coordinatesChanged)="onCoordinatesChanged()"
                        [hasMultipleAreas]="true"
                      ></app-coordinates-form>
                    }
                  </mat-tab>
                }
              </mat-tab-group>
            }
          } @else {
            <p class="no-data-message" i18n>
              This location has no GPS data. Add a new area to start adding coordinates.
            </p>
          }
        </div>

        <div class="map-container">
          <google-map
            #googleMap
            (idle)="onMapIdle()"
            height="700px"
            width="425px"
            [zoom]="zoom"
            [options]="mapOptions"
          ></google-map>
          @if (errorMessageVisible) {
            <div class="map-warning-container container-flex-center">
              <mat-icon class="warn-icon" svgIcon="alert-triangle" />
              {{ errorMessage }}
            </div>
          }
        </div>
      </div>
    } @else {
      <p class="no-data-message" i18n>This location has no GPS data.</p>
    }
  }
</article>
