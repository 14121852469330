export enum AttachmentTargetEnum {
  LOCATION = "LOCATION",
  ORGANISATION = "ORGANISATION",
  CERTIFICATE = "CERTIFICATE",
  ITEM = "ITEM",
  DELIVERY = "DELIVERY",
  PROCESS = "PROCESS",
  PRODUCT = "PRODUCT",
  LINK = "LINK",
  COUNTRY = "COUNTRY",
}
