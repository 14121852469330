import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { Subscription } from "rxjs";

import { LanguageEnum, RouteEnum } from "@shared/enums";
import { ILanguageOption } from "@shared/interfaces";
import { LocalizationService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-language-selector",
  templateUrl: "./language-selector.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  redirectRoute = input<RouteEnum>();

  label = input<string>();

  setLanguageOnChange = input<boolean>(true);

  showFullLanguageName = input<boolean>(true);

  class = input<string>("");

  delayMs = input<number>(0);

  public formControlName = signal<string>("language");

  private localizationService = inject(LocalizationService);

  public readonly languageOptions: ILanguageOption[] = [];

  public formGroup: UntypedFormGroup;

  public isLoading = signal(true);

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.setAvailableLanguageOptions();
    const currentLanguageOption = this.languageOptions.find(
      (l) => l.value === this.localizationService.getLanguage(),
    );

    this.formGroup = new UntypedFormGroup({
      language: new UntypedFormControl(currentLanguageOption, [CustomValidators.required]),
    });

    if (this.setLanguageOnChange()) {
      this.subscriptions.add(
        this.formGroup.controls[this.formControlName()].valueChanges.subscribe(() => {
          this.changeLanguage(this.redirectRoute());
        }),
      );
    }
    this.isLoading.set(false);
  }

  public changeLanguage = async (redirectRoute: RouteEnum): Promise<void> => {
    const selectedLanguage = this.formGroup.controls[this.formControlName()].value.value;

    if (selectedLanguage !== this.localizationService.getLanguage()) {
      setTimeout(async () => {
        await this.localizationService.changeLanguage(selectedLanguage, redirectRoute);
      }, this.delayMs());
    }
  };

  private setAvailableLanguageOptions = (): void => {
    this.languageOptions.push({
      label: this.showFullLanguageName() ? "English" : LanguageEnum.ENGLISH.toUpperCase(),
      value: LanguageEnum.ENGLISH,
      flag: "gb",
    });

    if (this.localizationService.isLanguageAvailable(LanguageEnum.SPANISH)) {
      this.languageOptions.push({
        label: this.showFullLanguageName() ? "Español" : LanguageEnum.SPANISH.toUpperCase(),
        value: LanguageEnum.SPANISH,
        flag: "es",
      });
    }
    if (this.localizationService.isLanguageAvailable(LanguageEnum.FRENCH)) {
      this.languageOptions.push({
        label: this.showFullLanguageName() ? "Français" : LanguageEnum.FRENCH.toUpperCase(),
        value: LanguageEnum.FRENCH,
        flag: "fr",
      });
    }
  };

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
