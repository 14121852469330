import { Injectable, inject } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { ApiService, AuthenticationService } from "@shared/services";
import { APP_CONFIG } from "@shared/tokens";

import {
  RiskAssessmentReportIndicatorMitigation,
  RiskAssessmentReportIndicatorMitigationPayload,
} from "../models";

@Injectable({ providedIn: "root" })
export class RiskAssessmentReportIndicatorMitigationsApiService {
  private readonly api = inject(ApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/reports`;

  getAll(reportId: string, indicatorId: string) {
    return lastValueFrom(
      this.api.get<RiskAssessmentReportIndicatorMitigation[]>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/mitigations`,
      ),
    );
  }

  get(reportId: string, indicatorId: string, mitigationId: string) {
    return lastValueFrom(
      this.api.get<RiskAssessmentReportIndicatorMitigation>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/mitigations/${mitigationId}`,
      ),
    );
  }

  createOrUpdate(
    payload: RiskAssessmentReportIndicatorMitigationPayload,
    reportId: string,
    indicatorId: string,
    mitigationId?: string,
  ) {
    const request = mitigationId
      ? this.api.put<RiskAssessmentReportIndicatorMitigation>(
          `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/mitigations/${mitigationId}`,
          payload,
        )
      : this.api.post<RiskAssessmentReportIndicatorMitigation>(
          `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/mitigations`,
          payload,
        );

    return lastValueFrom(request);
  }

  delete(reportId: string, indicatorId: string, mitigationId: string) {
    return lastValueFrom(
      this.api.delete<void>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/mitigations/${mitigationId}`,
      ),
    );
  }
}
