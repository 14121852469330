<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [hint]="translations.titleHint"
    [goBackRoute]="routingEnum.USER_SETTINGS"
  ></app-page-title>
</div>

<app-memberships-table
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
></app-memberships-table>
