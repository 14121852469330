<div class="create-records flex-column">
  @if (!hasProcessCompleted()) {
    <div class="exit-warning-text">
      {{ warningText() }}
    </div>
  }

  <div class="progress-container">
    <mat-progress-bar [value]="completionPercentage"></mat-progress-bar>
    <div class="progress-numbers">
      {{ processedRecordsCount }} / {{ payloadsWithStatus().length }}
    </div>
  </div>

  <div class="list flex-column">
    @if (payloadsWithStatus().length) {
      @for (payloadWithStatus of payloadsWithStatus(); track payloadWithStatus) {
        <div class="record">
          @switch (payloadWithStatus.status) {
            @case (statusEnum.PENDING) {
              <mat-icon class="rotate-animation loading-icon">cached</mat-icon>
            }
            @case (statusEnum.SUCCESS) {
              <mat-icon class="icon" svgIcon="action_completed"></mat-icon>
            }
            @case (statusEnum.ERROR) {
              <mat-icon class="icon" svgIcon="action_pending"></mat-icon>
            }
            @case (statusEnum.CANCELED) {
              <mat-icon class="icon" svgIcon="action_pending"></mat-icon>
              <ng-container i18n>(Canceled)</ng-container>
            }
          }
          <ng-container
            *ngTemplateOutlet="recordTemplate; context: { $implicit: payloadWithStatus }"
          ></ng-container>
        </div>
      }
    }
  </div>
</div>
