import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

interface Icon {
  name: string;
  path: string;
}

@Injectable({
  providedIn: "root",
})
export class IconService {
  private svgIcons: Icon[] = [
    { name: "action_completed", path: "../../../assets/icons/action-completed.svg" },
    { name: "action_pending", path: "../../../assets/icons/action-pending.svg" },
    { name: "analysis", path: "../../../assets/icons/analysis.svg" },
    { name: "alert-triangle", path: "../../../assets/icons/alert-triangle.svg" },
    { name: "check-1", path: "../../../assets/icons/check-1.svg" },
    { name: "check-2", path: "../../../assets/icons/check-2.svg" },
    { name: "geo-pinpoint", path: "../../../assets/icons/geo-pinpoint.svg" },
    { name: "geo-pinpoint-bordered", path: "../../../assets/icons/geo-pinpoint-bordered.svg" },
    { name: "geo-pinpoint-dashed", path: "../../../assets/icons/geo-pinpoint-dashed.svg" },
    { name: "group-216", path: "../../../assets/icons/group-216.svg" },
    { name: "inbox-menu", path: "../../../assets/icons/inbox-menu.svg" },
    { name: "linked-locations", path: "../../../assets/icons/linked-locations.svg" },
    { name: "logo-night", path: "../../../assets/icons/logo-night.svg" },
    { name: "logo-symbol-night", path: "../../../assets/icons/logo-symbol-night.svg" },
    { name: "map-cluster", path: "../../../assets/icons/map-cluster.svg" },
    { name: "map", path: "../../../assets/icons/map.svg" },
    { name: "map-pin", path: "../../../assets/icons/map-pin.svg" },
    { name: "orbify", path: "../../../assets/icons/orbify.svg" },
    { name: "organisations", path: "../../../assets/icons/organisations.svg" },
    { name: "search", path: "../../../assets/icons/search.svg" },
    { name: "settings", path: "../../../assets/icons/settings.svg" },
    { name: "supply-chain", path: "../../../assets/icons/supply-chain.svg" },
    { name: "tape", path: "../../../assets/icons/tape.svg" },
    { name: "x-circle", path: "../../../assets/icons/x-circle.svg" },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  registerIcons(): void {
    this.matIconRegistry.setDefaultFontSetClass("material-symbols-outlined");

    this.svgIcons.forEach((svgIcon) => {
      this.matIconRegistry.addSvgIcon(
        svgIcon.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(svgIcon.path),
      );
    });
  }
}
