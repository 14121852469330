import { ChangeDetectionStrategy, Component } from "@angular/core";

import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

import { AppDatePipe } from "@shared/pipes";
import { DateUtils } from "@shared/utils";

import { CommonConstants } from "../constants";

@Component({
  standalone: false,
  template: `
    {{ getDateRange() }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeCellRendererComponent implements ICellRendererAngularComp {
  public params!: ICellRendererParams;

  constructor(private appDatePipe: AppDatePipe) {}

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  getDateRange = (): string => {
    if (!this.params.value) {
      return "-";
    }

    if (this.params.value.on) {
      return this.appDatePipe.transform(
        DateUtils.getFormattedDateForPipe(this.params.value.on),
        this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
      );
    } else if (this.params.value.start && this.params.value.end) {
      return `${this.appDatePipe.transform(
        DateUtils.getFormattedDateForPipe(this.params.value.start),
        this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
      )} - ${this.appDatePipe.transform(
        DateUtils.getFormattedDateForPipe(this.params.value.end),
        this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
      )}`;
    } else {
      const startDateParam = this.params["startDateParam"];
      const endDateParam = this.params["endDateParam"];
      const data = this.getData();

      if (!startDateParam || !endDateParam || !data[startDateParam] || !data[endDateParam]) {
        return "-";
      }

      return `${this.appDatePipe.transform(
        DateUtils.getFormattedDateForPipe(data[startDateParam]),
        this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT,
      )} - ${this.appDatePipe.transform(DateUtils.getFormattedDateForPipe(data[endDateParam]), this.params["dateFormat"] ?? CommonConstants.DATE_FORMAT)}`;
    }
  };

  private getData = (): any => this.params.data ?? this.params.node.allLeafChildren[0].data;
}
