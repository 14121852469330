import { AfterViewInit, Component, EventEmitter, Input, Output, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { QuickActionsMenuComponent } from "src/app/shared/cell-renderers";
import { CommonConstants, TextConstants } from "src/app/shared/constants";
import { ConfirmDialogResponseEnum, RouteEnum, TableEnum } from "src/app/shared/enums";
import { IShare } from "src/app/shared/interfaces";
import { SharesService, AuthenticationService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { NotificationService } from "@shared/services";

import { ConfirmDialogComponent } from "../../confirm-dialog/confirm-dialog.component";

@Component({
  standalone: false,
  selector: "app-shares-table",
  templateUrl: "./shares-table.component.html",
})
export class SharesTableComponent implements AfterViewInit {
  @Input()
  public shares: IShare[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  public readonly table = TableEnum.SHARES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isInOverlay: boolean = false; //TODO: not longer needed.

  @Input()
  public columns: string[] = ["recipient", "createdTime"];

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();

  public isLoading = signal(true);

  public rowData: IShare[] = [];

  public columnDefs = signal<ColDef[]>([]);

  public readonly sharedOnColDef: ColDef = ColumnUtils.dateColumn({
    headerName: $localize`Shared on`,
    field: "createdTime",
    cellRendererParams: {
      dateFormat: CommonConstants.DATE_TIME_FORMAT,
    },
  });

  private readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private sharesService: SharesService,
    private authenticationService: AuthenticationService,
  ) {}

  async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    await this.getAll();
  }

  private setColumnDefs = (): void => {
    if (this.isInOverlay) {
      this.setColumnDefsForOverlay();

      return;
    }
  };

  private setColumnDefsForOverlay = (): void => {
    const emailColDef: ColDef = {
      headerName: TextConstants.EMAIL,
      field: "recipient",
    };

    let emailColActionButtons: ColDef = {};

    if (this.canAddModifyEntities) {
      emailColActionButtons = {
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              click: this.onCopy,
              tooltip: $localize`Copy share link`,
              icon: "content_copy",
            },
            {
              click: this.onResend,
              icon: "email",
              tooltip: $localize`Re-send share link`,
            },
            {
              click: this.onDelete,
              icon: "delete",
              tooltip: TextConstants.DELETE,
            },
          ],
        },
      };
    }

    const columnDefs: ColDef[] = [
      {
        ...emailColDef,
        ...emailColActionButtons,
      },
      this.sharedOnColDef,
    ];

    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.shares) {
      this.rowData = this.shares;
      this.isLoading.set(false);
    } else {
      try {
        const shares = await this.sharesService.getAll();

        this.rowData = shares;
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  private onDelete = (share: IShare) => {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: TextConstants.DELETE_CONFIRMATION,
          contentHTML: $localize`Are you sure you want to delete this share?
                    This will remove access to this record for <b>${share.recipient}:recipient:<b>`,
          confirmButtonColor: "danger",
          confirmButtonText: TextConstants.DELETE,
          confirmButtonIcon: "delete",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.delete(share.id);
        }
      });
  };

  private onCopy = (share: IShare): void => {
    const sharedUrl = `${window.location.origin}/${RouteEnum.SHARING}?shareId=${share.id}&orgId=${this.authenticationService.getActiveOrganisationId()}&recipient=${share.recipient}`;

    CommonUtils.textToClipboard(sharedUrl);
    this.notificationService.showSuccess(TextConstants.COPIED);
  };

  private onResend = async (share: IShare): Promise<void> => {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: $localize`Re-send confirmation`,
          contentText: $localize`Are you sure you want to re-send this share link?`,
          confirmButtonText: $localize`Re-send`,
          confirmButtonIcon: "email",
        },
      })
      .afterClosed()
      .subscribe(async (response) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.resend(share);
        }
      });
  };

  private resend = async (share: IShare): Promise<void> => {
    try {
      await this.sharesService.reSendEmail(share.id);
      this.notificationService.showSuccess(
        $localize`Link re-sent to ${share.recipient}:recipientEmail:`,
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  };

  private delete = async (shareId: string): Promise<void> => {
    try {
      await this.sharesService.delete(shareId);
      this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
      this.deleted.emit();
    } catch (error) {
      this.notificationService.showError(error);
    }
  };
}
