<div class="form-label" [class]="class()">
  <label [for]="label()" class="input-select-label">{{ label() }}</label>
  @if (tooltip()) {
    @if (isSvgIcon()) {
      <mat-icon
        class="suffix-icon icon-20"
        [class]="iconClass()"
        [tp]="tooltip()"
        [tpIsLazy]="true"
        [svgIcon]="icon()"
      />
    } @else {
      <mat-icon
        class="suffix-icon icon-20"
        [class]="iconClass()"
        [tp]="tooltip()"
        [tpIsLazy]="true"
      >
        {{ icon() }}
      </mat-icon>
    }
  }
  <ng-content select="[action]"></ng-content>
</div>
