import { inject, Injectable, signal } from "@angular/core";

import { Observable } from "rxjs";

import { SendDialogComponent } from "@components/index";
import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";
import { FeatureFlagEnum, SendTargetTypeEnum } from "@shared/enums";
import { IProductExtended } from "@shared/interfaces";
import {
  FeatureFlagService,
  ItemsService,
  ProcessesService,
  ProductsService,
} from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Injectable({
  providedIn: "root",
})
export class ProcessOverlayService extends OverlayCommonService {
  private productsService: ProductsService = inject(ProductsService);

  private processesService: ProcessesService = inject(ProcessesService);

  private itemsService: ItemsService = inject(ItemsService);

  private featureFlagService = inject(FeatureFlagService);

  public inputCounter = signal<number>(0);

  public outputCounter = signal<number>(0);

  public allProducts = signal<IProductExtended[]>([]);

  public readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  constructor() {
    super();
  }

  public async onReloadProducts(includes = []): Promise<void> {
    if (!this.isOldMaterialsEnabled) {
      includes.push("MATERIALS");
    }
    try {
      const allProducts = await this.productsService.getAllGraphQL(undefined, undefined, includes);

      this.allProducts.set(allProducts);
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  async loadInputOutputCounter(processId: string, type: "input" | "output"): Promise<void> {
    const selectedItems = [];

    try {
      const selectedElements =
        type === "input"
          ? (await this.processesService.getManyInputs(processId)).content
          : (await this.processesService.getManyOutputs(processId)).content;

      for (const element of selectedElements) {
        for (const elementItem of element.items) {
          const itemId = CommonUtils.getUriId(elementItem.item);
          const item = await this.itemsService.get(itemId);
          const quantity = type === "input" ? elementItem.selectedQuantity : item.initialQuantity;

          selectedItems.push({ ...item, selectedQuantity: quantity });
        }
      }

      if (type === "input") {
        this.inputCounter.set(selectedElements.length);
      } else {
        this.outputCounter.set(selectedElements.length);
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  override setCountersToLoadingState() {
    super.setCountersToLoadingState();
    this.inputCounter.set(null);
    this.outputCounter.set(null);
  }

  override setCountersEmptyState() {
    super.setCountersEmptyState();
    this.inputCounter.set(0);
    this.outputCounter.set(0);
  }

  public send(activeOrganisationId: string, processId: string): Observable<{ hasSaved: boolean }> {
    return this.dialog
      .open(SendDialogComponent, {
        data: {
          sendTargetType: SendTargetTypeEnum.PROCESS,
          rootRecordUri: `/organisations/${activeOrganisationId}/processes/${processId}`,
        },
      })
      .afterClosed();
  }
}
