@if (isSelectedAttachmentsLoading()) {
  <app-loader></app-loader>
} @else {
  <div class="section-title" i18n>Product summary</div>

  @if (selectedItems?.length) {
    <app-quantity-summary-table
      [isSearchEnabled]="false"
      [isPaginatorEnabled]="false"
      [allProducts]="allProducts"
      [allMaterials]="allMaterials"
      [allUnitOfMeasurements]="allUnitOfMeasurements"
      [selectedItems]="selectedItems"
      [isInboundShared]="isInboundShared()"
      [inboundSharedSenderOrgId]="inboundSharedSenderOrgId()"
      [columns]="['productName', 'materialNames', 'totalQuantity', 'materialData']"
    ></app-quantity-summary-table>
  } @else {
    <div class="text-center empty-quantity-summary" i18n>
      Once you add items to this delivery, a summary of their quantities will show up here.
    </div>
  }

  <div class="section-title mt-24">Selected items</div>

  <section class="items-table">
    <app-table
      [columnDefs]="itemsColumnDefs()"
      [isLoading]="isSelectedAttachmentsLoading()"
      [isRecordStateFilterEnabled]="false"
      [rowData]="itemRowData"
      [isPaginatorEnabled]="!overlay.contentRightSidePanel()"
    ></app-table>
  </section>

  @if (!isReadOnly) {
    <ng-template #attachmentsRightMenu>
      <app-right-side-panel
        itemType="Item"
        itemTypePlural="Items"
        [isLoadingItems]="isLoadingItems()"
        [availableItems]="availableItems"
        [addMenu]="menu"
        (pressAdd)="onAddNewItem()"
        (pressAddAll)="onAddAll()"
        class="overlay-right-panel"
        (pressRefresh)="reloadItems.emit(searchAvailableText)"
        (searchEvent)="onSearchAvailable($event)"
      >
        <div class="items-container">
          @for (item of availableItems; track item.id) {
            <app-draggable-card (pressed)="onAdd(item.id)" [item]="item">
              <app-item-card-content [item]="item"></app-item-card-content>
            </app-draggable-card>
          }
        </div>
      </app-right-side-panel>
    </ng-template>
  }
}

<mat-menu #menu class="dropdown-menu">
  <app-dropdown-menu>
    <app-dropdown-menu-item (pressed)="onAddNewItem()">
      <mat-icon>package_2</mat-icon>
      <ng-container i18n>Add one item</ng-container>
    </app-dropdown-menu-item>
    <app-dropdown-menu-item (pressed)="onOpenBulkAddItemsSlideOver()">
      <mat-icon>library_add</mat-icon>
      <ng-container i18n>Add multiple items</ng-container>
    </app-dropdown-menu-item>
  </app-dropdown-menu>
</mat-menu>
