import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  signal,
} from "@angular/core";

import { DocumentsService } from "src/app/shared/services";
import { CommonUtils } from "src/app/shared/utils";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";

@Component({
  standalone: false,
  selector: "app-document-list-items",
  templateUrl: "./document-list-items.component.html",
  styleUrls: ["./document-list-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListItemsComponent implements OnChanges {
  @Input()
  public documents: any[];

  @Input()
  public allDocumentTypes: any[] = [];

  @Input()
  public propertyName: string;

  @Input()
  public isGroupingByType: boolean = false;

  @Input()
  public typeToolTips: { [id: string]: string } = {};

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  public isLoading = signal(true);

  public readonly contentCardTypeEnum = CardContentTypeEnum;

  constructor(private documentsService: DocumentsService) {}

  public async ngOnChanges(): Promise<any> {
    try {
      this.isLoading.set(true);
      if (this.documents?.length && this.allDocumentTypes?.length) {
        for (const item of this.documents) {
          const documentId = CommonUtils.getUriId(item[this.propertyName]);

          if (documentId) {
            const document = await this.documentsService.get(documentId);

            item.name = document.name;
            const documentType = this.allDocumentTypes.find(
              (t) => t.id === CommonUtils.getUriId(document.type),
            );

            item.type = documentType?.name;
          }
        }
      }
      this.isLoading.set(false);
    } catch (error) {
      console.log(error);
    }
  }

  public onRemove = (id: string): void => {
    this.remove.emit(id);
  };

  shouldDisplayType(typeId: string, index: number): boolean {
    return index === 0 || this.documents[index - 1].typeId !== typeId;
  }
}
