import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-share-button",
  styleUrl: "./share-button.component.scss",
  template: `
    <button class="share-button" type="button" mat-raised-button (click)="share.emit()">
      <mat-icon>share</mat-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShareButtonComponent {
  @Output()
  public share: EventEmitter<void> = new EventEmitter<void>();
}
