import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { RouterLink } from "@angular/router";

import { ExternalLinkIconComponent } from "@components/index";
import { NavigationParams } from "@shared/services/router.service";

@Component({
  selector: "app-form-link",
  templateUrl: "./form-link.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, ExternalLinkIconComponent],
  standalone: true,
})
export class FormLinkComponent {
  openInNewTab = input<boolean>(false);

  styledLink = input<boolean>(false);

  maintainFontSize = input<boolean>(false);

  link = input<NavigationParams>();
}
