<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <app-input enableViewMode formControlName="name" [label]="translations.nameLabel"></app-input>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input
        enableViewMode
        formControlName="website"
        [label]="translations.websiteLabel"
      ></app-input>
    </div>
  </div>
</div>
