import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { chunk } from "lodash";
import { ICustomField, IElementCustomField } from "src/app/shared/interfaces";
import { CommonUtils } from "src/app/shared/utils";

@Component({
  standalone: false,
  selector: "app-custom-fields-details",
  templateUrl: "./custom-fields-details.component.html",
  styleUrls: ["./custom-fields-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsDetailsComponent implements OnInit {
  @Input()
  public visibleCustomFields: ICustomField[];

  @Input()
  public elementCustomFields: IElementCustomField[];

  @Input()
  public columns: number = 4;

  public chunkVisibleCustomFields: any[] = [];

  public ngOnInit(): void {
    this.chunkVisibleCustomFields = chunk(this.visibleCustomFields, this.columns);
  }

  public getValue = (id: string): string =>
    this.elementCustomFields?.find((e) => CommonUtils.getUriId(e.uri) === id)?.value;
}
