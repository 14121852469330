import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  input,
  OnInit,
  signal,
} from "@angular/core";

import { chunk } from "lodash";

import { LocalTimePipe } from "@shared/pipes";
import { OrganisationsService, AuthenticationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

interface IRecordDetail {
  label: string;
  value: string;
  valueTooltip?: string;
}

@Component({
  selector: "app-record-details",
  templateUrl: "./record-details.component.html",
  styleUrls: ["./record-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecordDetailsComponent implements OnInit {
  @Input()
  public element: any;

  public columns = input<number>(1);

  public isLoading = signal(true);

  public chunkFields = signal<any[]>([]);

  private localTimePipe = inject(LocalTimePipe);

  private organisationsService = inject(OrganisationsService);

  private authenticationService = inject(AuthenticationService);

  private readonly activeOrganisationId = this.authenticationService.getActiveOrganisationId();

  private readonly formerUserText = $localize`Former user`;

  private readonly formerUserTooltip = $localize`This user has left the organisation`;

  async ngOnInit(): Promise<void> {
    const allFields = await this.getAllFieldsParsed();

    this.chunkFields.set(chunk(allFields, this.columns()));

    this.isLoading.set(false);
  }

  private getAllFieldsParsed = async (): Promise<IRecordDetail[]> => {
    let createdByName: string = undefined;
    let updatedByName: string = undefined;

    const createdById = CommonUtils.getUriId(this.element.createdBy);
    const updatedById = CommonUtils.getUriId(this.element.updatedBy);

    try {
      const createdBy = await this.organisationsService.getOrganisationMemberInfo(
        this.activeOrganisationId,
        createdById,
      );

      createdByName = `${createdBy.firstName} ${createdBy.lastName}`;
    } catch {
      console.info("CreatedBy user not found");
    }

    if (createdById === updatedById) {
      updatedByName = createdByName;
    } else {
      try {
        const updatedBy = await this.organisationsService.getOrganisationMemberInfo(
          this.activeOrganisationId,
          updatedById,
        );

        updatedByName = `${updatedBy.firstName} ${updatedBy.lastName}`;
      } catch {
        console.info("UpdatedBy user not found");
      }
    }

    const allFields: IRecordDetail[] = [
      {
        label: $localize`Created by`,
        value: createdByName ?? this.formerUserText,
        valueTooltip: createdByName ? "" : this.formerUserTooltip,
      },
      {
        label: $localize`Created at`,
        value: this.formatDate(this.element.createdTime),
      },
      {
        label: $localize`Updated by`,
        value: updatedByName ?? this.formerUserText,
        valueTooltip: updatedByName ? "" : this.formerUserTooltip,
      },
      {
        label: $localize`Updated at`,
        value: this.formatDate(this.element.updatedTime),
      },
    ];

    return allFields;
  };

  private formatDate = (date: string): string => (date ? this.localTimePipe.transform(date) : "-");
}
