import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  OnInit,
  Output,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { AddCertificateDialogComponent, ConfirmDialogComponent } from "@components/shared";
import { AddCertificateDialogModel } from "@components/shared/add-certificate-dialog/add-certificate-dialog.model";
import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { OverlayCommonService } from "@components/shared/overlay/overlay-common.service";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { slideOverAnimation } from "@shared/animations";
import { TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  AttachmentTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
} from "@shared/enums";
import { IAttachment, ICertificate, ICertificateExtended } from "@shared/interfaces";
import { NotificationService, AuthenticationService } from "@shared/services";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-overlay-certificate-attachments",
  templateUrl: "./overlay-certificate-attachments.component.html",
  styleUrl: "./overlay-certificate-attachments.component.scss",
  animations: [slideOverAnimation],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class OverlayCertificateAttachmentsComponent implements OnInit {
  @ViewChild("attachmentsRightMenu") attachmentsRightMenu: TemplateRef<unknown>;

  @Input() overlayService: OverlayCommonService;

  public isReadOnly = input<boolean>(false);

  attachmentTargetId = input.required<string>();

  attachmentTargetType = input.required<AttachmentTargetEnum>();

  @Output() changes: EventEmitter<void> = new EventEmitter();

  public readonly recordStateEnum = RecordStateEnum;

  isAvailableAttachmentsLoading = signal<boolean>(true);

  isSelectedAttachmentsLoading = signal<boolean>(true);

  selectedCertificatesIds = signal<string[]>([]);

  availableCertificates = signal<ICertificateExtended[]>([]);

  private selectedAttachments = signal<IAttachment[]>([]);

  attachTargetUri: string;

  protected readonly cardContentTypeEnum = CardContentTypeEnum;

  protected readonly attachmentTypeEnum = AttachmentTypeEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  private searchAvailableAttachmentsText: string = undefined;

  public readonly translations: any = {
    addNewTp: TextConstants.ADD_NEW,
  };

  constructor(
    private dialog: MatDialog,
    private notification: NotificationService,
    public overlay: SlideOverlayPageService,
    private authenticationService: AuthenticationService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.searchAvailableAttachmentsText = undefined;
    await this.loadSelectedAttachments();
    await this.loadAvailableAttachments();
  }

  public onAddAll = async (): Promise<void> => {
    const availableCertificates = this.availableCertificates();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${availableCertificates.length}:count:</b> certificates?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        this.isSelectedAttachmentsLoading.set(true);
        this.isAvailableAttachmentsLoading.set(true);

        const promises = availableCertificates.map(async (availableCertificate) => {
          await this.onAdd(availableCertificate, false);
        });

        await Promise.all(promises);

        this.notification.showSuccess($localize`Certificates added`);

        await this.loadSelectedAttachments();
        await this.loadAvailableAttachments();
        this.changes.emit();
      }
    });
  };

  public onSearchAvailableAttachments = async (searchText?: string): Promise<void> => {
    this.searchAvailableAttachmentsText = searchText || undefined;
    await this.loadAvailableAttachments();
  };

  private async loadAvailableAttachments(): Promise<void> {
    this.isAvailableAttachmentsLoading.set(true);
    const certificates = await this.overlayService.loadCertificates(
      this.searchAvailableAttachmentsText || undefined,
    );

    this.availableCertificates.set(
      certificates.filter(
        (certificate) =>
          !this.selectedCertificatesIds().some(
            (selectedCertificateId) => selectedCertificateId === certificate.id,
          ),
      ),
    );
    this.isAvailableAttachmentsLoading.set(false);
  }

  public async onAdd(attachment: ICertificateExtended, isSingleAdd = true): Promise<void> {
    if (isSingleAdd) {
      this.isSelectedAttachmentsLoading.set(true);
      this.isAvailableAttachmentsLoading.set(true);
    }

    try {
      await this.overlayService.createAttachment(
        this.attachmentTargetType(),
        this.attachmentTargetId(),
        this.attachmentTypeEnum.CERTIFICATE,
        attachment.id,
      );

      if (isSingleAdd) {
        this.notification.showSuccess($localize`Certificate added`);
        await this.loadSelectedAttachments();
        await this.loadAvailableAttachments();
        this.changes.emit();
      }
    } catch {
      if (isSingleAdd) {
        this.notification.showError($localize`Error when adding certificate`);
      }
    }
  }

  public async loadSelectedAttachments(): Promise<void> {
    this.isSelectedAttachmentsLoading.set(true);
    const attachmentTargetId = this.attachmentTargetId();

    const selectedAttachments = (await this.overlayService.loadSelectedAttachments(
      this.attachmentTypeEnum.CERTIFICATE,
      this.attachmentTargetType(),
      attachmentTargetId,
      true,
    )) as IAttachment[];

    this.selectedAttachments.set(selectedAttachments);

    this.selectedCertificatesIds.set(
      selectedAttachments.map((a) => CommonUtils.getUriId(a.attachmentUri)),
    );

    this.overlayService.certificateCounter.set(this.selectedCertificatesIds().length);
    this.isSelectedAttachmentsLoading.set(false);
  }

  public onAttachCertificateCompleted = async (
    result: AddCertificateDialogModel.IResult,
  ): Promise<void> => {
    if (result?.hasAttachedCertificate) {
      this.notification.showSuccess($localize`Certificate added`);
      await this.loadSelectedAttachments();
    } else if (result?.hasSavedCertificate) {
      await this.loadAvailableAttachments();
    }
  };

  public async addNewCertificate(): Promise<void> {
    const activeOrganisationId =
      this.overlayService.authenticationService.getActiveOrganisationId();

    this.attachTargetUri = CommonUtils.getTargetUri(
      activeOrganisationId,
      this.attachmentTargetType(),
      this.attachmentTargetId(),
    );

    const result = await this.overlay.openDialog<
      AddCertificateDialogModel.IResult,
      AddCertificateDialogModel.IDialogData
    >(AddCertificateDialogComponent, { attachTargetUri: this.attachTargetUri });

    await this.onAttachCertificateCompleted(result);
  }

  public async onRemove(certificate: ICertificate): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this certificate from the record?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isSelectedAttachmentsLoading.set(true);
          this.isAvailableAttachmentsLoading.set(true);
          const selectedAttachment = this.selectedAttachments().find(
            (a) => CommonUtils.getUriId(a.attachmentUri) === certificate.id,
          );

          await this.overlayService.removeAttachment("certificate", selectedAttachment.id);
          await this.loadSelectedAttachments();
          await this.loadAvailableAttachments();

          this.changes.emit();
        } catch {
          this.isSelectedAttachmentsLoading.set(false);
          this.isAvailableAttachmentsLoading.set(false);
        }
      }
    });
  }
}
