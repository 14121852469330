<div class="files-container">
  @for (file of files(); let index = $index; track file) {
    <div class="filename">
      <div class="left-content container-flex">
        <mat-icon class="icon">docs</mat-icon>
        <span
          class="clickable"
          [tp]="translations.viewFileTp"
          tabindex="0"
          (click)="onClickViewFileEvent(index)"
          (keyup.enter)="onClickViewFileEvent(index)"
        >
          {{ file.info.name }}
        </span>
      </div>
      <div class="right-content container-flex gap-col-12">
        @if (file.canBeSplit()) {
          <app-button
            (click)="onClickSplitDocument(index)"
            class="no-border"
            [tp]="translations.splitDocTp"
            size="medium"
            type="icon"
          >
            <mat-icon>content_cut</mat-icon>
          </app-button>
        }
        <app-button
          (click)="onClickDeleteFileEvent(index)"
          class="no-border"
          [tp]="translations.removeDocTp"
          size="medium"
          type="icon"
        >
          <mat-icon>delete</mat-icon>
        </app-button>
      </div>
    </div>
    <div class="file hover-visibility-container" [formGroup]="file.formGroup">
      <div class="general-details">
        <app-input
          class="flex-grow-1"
          formControlName="name"
          [label]="translations.nameLabel"
          [additionalErrors]="validationError(file)"
        ></app-input>

        <app-input-select
          [label]="translations.typeLabel"
          formControlName="type"
          [addOnWrite]="true"
          [options]="documentTypeOptions"
          [tooltip]="typeTooltipHint"
          class="flex-grow-1"
        ></app-input-select>
      </div>
      <app-form-label [label]="translations.tagsLabel"></app-form-label>
      <app-entity-tags
        (tagsChanged)="onTagsChanged(file, $event)"
        [entityType]="entityTypeEnum.DOCUMENTS"
        [initialTags]="file.formGroup.get('tags').value"
        [setEditMode]="true"
      ></app-entity-tags>
      <div class="date-details">
        <div>
          <app-checkbox
            formControlName="isDatesEnabled"
            [label]="translations.isDatesEnabledLabel"
          ></app-checkbox>
        </div>
        @if (file.formGroup.controls["isDatesEnabled"].value) {
          <div class="container-flex-space-between dates">
            <app-datepicker
              [label]="translations.issuanceLabel"
              formControlName="issuance"
              [maxDate]="
                file.formGroup.controls['validityStart'].value ??
                file.formGroup.controls['validityEnd'].value
              "
              class="datepicker"
            ></app-datepicker>
            <app-datepicker
              [label]="translations.validityStartLabel"
              formControlName="validityStart"
              [minDate]="file.formGroup.controls['issuance'].value"
              [maxDate]="file.formGroup.controls['validityEnd'].value"
              class="datepicker"
            ></app-datepicker>
            <app-datepicker
              [label]="translations.validityEndLabel"
              formControlName="validityEnd"
              [minDate]="
                file.formGroup.controls['validityStart'].value ??
                file.formGroup.controls['issuance'].value
              "
              class="datepicker"
            ></app-datepicker>
          </div>
        }
      </div>
    </div>
  }
</div>

<app-slide-overlay-wrapper
  class="wide"
  [close]="closeSplitOverlay()"
  (clickClose)="onCloseSplitSlideOver()"
>
  <div class="full-width">
    @if (closeSplitOverlay()) {
      <div class="slide-over-content full-height">
        <app-loader></app-loader>
      </div>
    } @else {
      <app-pdf-splitter
        (save)="onSplit($event)"
        (discard)="onCloseSplitSlideOver()"
        [file]="selectedFile"
        [index]="fileIndex"
        [documentTypeOptions]="documentTypeOptions"
        [allDocumentNames]="allDocumentNames()"
      ></app-pdf-splitter>
    }
  </div>
</app-slide-overlay-wrapper>
