import { FormGroup } from "@angular/forms";

import { BulkAddLocationsModel } from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { TextConstants } from "@shared/constants";

export namespace BulkAddLocationsEnterRecordsModel {
  export const fixedFieldMapping = {
    [BulkAddLocationsModel.FieldEnum.IS_FIXED_TAGS]: TextConstants.TAGS,
    [BulkAddLocationsModel.FieldEnum.IS_FIXED_ORGANISATION]: TextConstants.ORGANISATION,
    [BulkAddLocationsModel.FieldEnum.IS_FIXED_LOCATION_TYPES]: $localize`Location Type(s)`,
    [BulkAddLocationsModel.FieldEnum.IS_FIXED_COUNTRY]: TextConstants.COUNTRY,
  };

  export interface IRowData {
    formGroup: FormGroup<BulkAddLocationsModel.LocationFormGroup>;
  }

  export const duplicatedNameOnTableMessage: string = $localize`This name is duplicated`;
  export const maxRecordsCount: number = 100;
  export const editDialogMaxWidth: number = 350;
  export const editDialogHeight: number = 400;
  export const editDialogViewportMargin: number = 10;
}
