import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
  standalone: false,
  selector: "app-api-unavailable",
  templateUrl: "./api-unavailable.component.html",
  styleUrls: ["./api-unavailable.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiUnavailableComponent {}
