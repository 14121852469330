<div class="form-page-container">
  <app-page-title [title]="translations.title"></app-page-title>

  @if (isLoading()) {
    <app-loader></app-loader>
  } @else {
    <div class="main-delivery-info">
      <h1>{{ delivery.deliveryId }}</h1>

      <div class="information">
        <div class="column">
          <div class="header" i18n>Status</div>
          <div class="value">{{ delivery.status | enumToText }}</div>
        </div>

        <div class="column">
          <div class="header" i18n>Sent date</div>
          <div class="value">{{ delivery.sent | appDate }}</div>
        </div>

        <div class="column">
          <div class="header" i18n>Delivered date</div>
          <div class="value">{{ delivery.delivered | appDate }}</div>
        </div>
      </div>
    </div>

    <div class="element-content element-content-padded no-bottom-margin">
      <div class="sections flex-column">
        <section>
          <app-delivery-report-main-locations-table
            [sender]="sender"
            [receiver]="receiver"
          ></app-delivery-report-main-locations-table>
        </section>

        @if (delivery.agents.length) {
          <section>
            <h3 i18n>Trader information</h3>

            <app-connections-table
              [connections]="traders"
              [subscribeToOverlayRefreshTable]="false"
              [isRecordStateFilterEnabled]="false"
              [isSearchEnabled]="false"
              [isPaginatorEnabled]="false"
              [columns]="[
                'name',
                'address.countryName',
                'address.street',
                'address.zipCode',
                'address.region',
                'certificates',
                'eori',
                'email',
              ]"
            ></app-connections-table>
          </section>
        }

        <section>
          <h3 i18n>Product information</h3>

          <app-quantity-summary-table
            [isSearchEnabled]="false"
            [isPaginatorEnabled]="false"
            [allProducts]="allProducts"
            [allMaterials]="allMaterials"
            [allUnitOfMeasurements]="allUnitOfMeasurements"
            [selectedItems]="items"
            [columns]="[
              'productName',
              'materialNames',
              'materialData',
              'totalQuantity',
              'customFields',
            ]"
          ></app-quantity-summary-table>
        </section>

        <section>
          <h3>{{ additionalInformationText }}</h3>

          <div class="information grid">
            @for (customFieldValue of customDeliveryFieldsValues; track customFieldValue.label) {
              <div class="column">
                <div class="header">{{ customFieldValue.label }}</div>
                <div class="value">{{ customFieldValue.value }}</div>
              </div>
            }
          </div>
        </section>

        @if (documentTypes.length) {
          <section>
            <h3 i18n>Delivery documents</h3>

            <app-document-types-table
              [documentTypes]="documentTypes"
              [areButtonsEnabled]="false"
              [isRecordStateFilterEnabled]="false"
              [isPaginatorEnabled]="false"
              [isSearchEnabled]="false"
              [columns]="['documentType', 'documentNames']"
            ></app-document-types-table>
          </section>
        }

        @if (isLoadingSupplyChainInfo()) {
          <app-loader></app-loader>
        } @else {
          <app-delivery-report-processes
            [itemIds]="deliveryItemIds"
          ></app-delivery-report-processes>
        }

        <section>
          <h3 i18n>Supply chain information</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <mat-tab-group animationDuration="0ms" class="full-width">
              <mat-tab>
                <ng-template mat-tab-label>Graph</ng-template>
                <app-items-supply-chain
                  [allProcessTypes]="allProcessTypes"
                  [height]="supplyChainHeight"
                  [itemIds]="deliveryItemIds"
                  [supplyChainItems]="supplyChainItems"
                  [isZoomPromptEnabled]="true"
                  svgContentClass="unset-height"
                ></app-items-supply-chain>
              </mat-tab>
              <mat-tab>
                <ng-template mat-tab-label>Map</ng-template>
                <app-items-supply-chain-map
                  [itemIds]="deliveryItemIds"
                  [delivery]="deliveryDetails"
                  [isZoomPromptEnabled]="true"
                />
              </mat-tab>
            </mat-tab-group>
          }
        </section>

        <section>
          <h3 i18n>Supply chain entities</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <app-delivery-report-supply-chain-entities
              [supplyChainItems]="supplyChainItems"
            ></app-delivery-report-supply-chain-entities>
          }
        </section>

        <section>
          <h3 i18n>Location information</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <app-locations-table
              [locations]="supplyChainLocations"
              [isRecordStateFilterEnabled]="false"
              [isPaginatorEnabled]="false"
              [isSearchEnabled]="false"
              [subscribeToOverlayRefreshTable]="false"
              [columns]="[
                'name',
                'types',
                'organisationName',
                'address.countryName',
                'address.street',
                'address.zipCode',
                'certificates',
              ]"
            ></app-locations-table>
          }
        </section>

        <section>
          <h3 i18n>Location details</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <app-delivery-report-location-details
              [locations]="supplyChainLocations"
            ></app-delivery-report-location-details>
          }
        </section>

        <section>
          <h3 i18n>Organisation certificates & documents</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <app-delivery-report-organisation-certificates-documents
              [locations]="supplyChainLocations"
            ></app-delivery-report-organisation-certificates-documents>
          }
        </section>

        <section>
          <h3 i18n>Other deliveries in this supply chain</h3>

          @if (isLoadingSupplyChainInfo()) {
            <app-loader></app-loader>
          } @else {
            <app-delivery-report-other-deliveries
              [deliveries]="supplyChainDeliveries"
            ></app-delivery-report-other-deliveries>
          }
        </section>
      </div>
    </div>
  }
</div>
