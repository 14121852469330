import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  signal,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { CellClickedEvent, ColDef, ICellRendererParams } from "ag-grid-community";
import { pairwise, startWith } from "rxjs";

import { BulkAddLocationsModel as BulkAddModel } from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { BulkAddLocationsService } from "@components/locations/bulk-add-locations/bulk-add-locations.service";
import { BulkAddLocationsEnterRecordsModel as Model } from "@components/locations/bulk-add-locations/enter-records/bulk-add-locations-enter-records.model";
import { BulkAddEnterRecords } from "@components/shared/bulk-add/bulk-add-enter-records";
import { BulkAddCustomFieldEnum } from "@components/shared/bulk-add/bulk-add.interface";
import { BulkAddEditFieldDialogModel } from "@components/shared/bulk-add/edit-field-dialog/bulk-add-edit-field-dialog.model";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { InputCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { IGeoLocation } from "@shared/interfaces";
import { LocationsService, LocationTypesService } from "@shared/services";
import { ColumnUtils, GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-bulk-add-locations-enter-records",
  templateUrl: "./bulk-add-locations-enter-records.component.html",
  styleUrls: ["./bulk-add-locations-enter-records.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddLocationsEnterRecordsComponent
  extends BulkAddEnterRecords<
    BulkAddModel.SetValuesFormGroup,
    BulkAddModel.LocationFormGroup,
    Model.IRowData
  >
  implements OnInit, OnDestroy
{
  private locationsService: LocationsService = inject(LocationsService);

  private bulkAddLocationsService: BulkAddLocationsService = inject(BulkAddLocationsService);

  private locationTypesService: LocationTypesService = inject(LocationTypesService);

  @Input() public formGroup: FormGroup<BulkAddModel.SetValuesFormGroup>;

  @ViewChild("addNewOrganisation") addNewOrganisation!: TemplateRef<any>;

  @ViewChild("countryFlag") countryFlag!: TemplateRef<{ $implicit: string }>;

  public rowData: Model.IRowData[] = [];

  protected fieldToCheckForDuplicates: string = BulkAddModel.FieldEnum.NAME;

  protected isDuplicatedField: string = BulkAddModel.FieldEnum.IS_NAME_DUPLICATED;

  protected readonly maxRecordsCount: number = Model.maxRecordsCount;

  public readonly fieldEnum = BulkAddModel.FieldEnum;

  public readonly customFieldEnum = BulkAddCustomFieldEnum;

  public columnDefs = signal<ColDef[]>([]);

  public ngOnInit(): void {
    this.setColumnDefs();
    this.buildRecords(true);
  }

  protected override getLabelForFixedField(key: string): string {
    return Model.fixedFieldMapping[key];
  }

  protected override getFieldValue(value: any, key?: string): string {
    if (key === this.fieldEnum.IS_FIXED_TAGS) {
      return value.map((tag) => tag.tagDefinition.title).join(", ");
    }

    if (Array.isArray(value)) {
      return value.map((v) => this.getFieldValue(v)).join(", ");
    }

    if (value instanceof Object && value.label) {
      return value.label;
    }

    return `${value}`;
  }

  protected buildRecord(
    initialData?: FormGroup<BulkAddModel.LocationFormGroup>,
  ): FormGroup<BulkAddModel.LocationFormGroup> {
    const formGroup = this.buildRecordFormGroup(initialData);

    this.addSubscriptionsToRecordFormGroup(formGroup);

    return formGroup;
  }

  private addSubscriptionsToRecordFormGroup(
    formGroup: FormGroup<BulkAddModel.LocationFormGroup>,
  ): void {
    const { fieldEnum } = this;
    const { controls } = formGroup;

    const locationNameSubscription = controls[fieldEnum.NAME].valueChanges
      .pipe(startWith(null), pairwise())
      .subscribe(this.detectDuplicates.bind(this));

    this.subscriptions.add(locationNameSubscription);
  }

  private buildRecordFormGroup(
    initialData?: FormGroup<BulkAddModel.LocationFormGroup>,
  ): FormGroup<BulkAddModel.LocationFormGroup> {
    const { fieldEnum, formGroup } = this;

    const tagsData =
      initialData?.controls[fieldEnum.TAGS].value || formGroup.controls[fieldEnum.TAGS].value;

    const locationTypesData =
      initialData?.controls[fieldEnum.LOCATION_TYPES].value ||
      formGroup.controls[fieldEnum.LOCATION_TYPES].value;

    const customFieldsFormArray = this.getCustomFieldsFormArray(initialData);

    const controls: BulkAddModel.LocationFormGroup = {
      [fieldEnum.NAME]: new FormControl(initialData?.controls[fieldEnum.NAME].value, {
        validators: [CustomValidators.required],
        asyncValidators: [CustomValidators.entityAlreadyExists(this.locationsService, null)],
      }),
      [fieldEnum.ORGANISATION]: new FormControl(
        initialData?.controls[fieldEnum.ORGANISATION].value ||
          this.getFieldValueForSelectField(fieldEnum.ORGANISATION),
        [CustomValidators.required],
      ),
      [fieldEnum.COUNTRY]: new FormControl(
        initialData?.controls[fieldEnum.COUNTRY].value ||
          this.getFieldValueForSelectField(fieldEnum.COUNTRY),
        [CustomValidators.required],
      ),
      [fieldEnum.LOCATION_TYPES]: new FormControl(locationTypesData, [CustomValidators.required]),
      [fieldEnum.TAGS]: new FormControl(tagsData),
      [fieldEnum.REGION]: new FormControl(initialData?.controls[fieldEnum.REGION].value),
      [fieldEnum.ZIP_CODE]: new FormControl(initialData?.controls[fieldEnum.ZIP_CODE].value),
      [fieldEnum.ADDRESS]: new FormControl(initialData?.controls[fieldEnum.ADDRESS].value),
      [fieldEnum.GEOLOCATION]: new FormControl(),
      [fieldEnum.CUSTOM_FIELDS]: customFieldsFormArray,
      [fieldEnum.IS_NAME_DUPLICATED]: new FormControl(false),
    };

    return new FormGroup(controls);
  }

  setColumnDefs(): void {
    const { fieldEnum, formGroup } = this;
    const { controls } = formGroup;
    const { fieldEnumToLabelMap } = BulkAddModel;

    const columnDefs: ColDef[] = [
      {
        ...this.defaultColDefProperties(fieldEnum.NAME),
        headerName: fieldEnumToLabelMap[fieldEnum.NAME],
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup;
        },
        suppressKeyboardEvent: () => true,
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
        minWidth: 250,
        cellRenderer: InputCellRendererComponent,
        cellRendererParams: (params: { value: FormGroup<BulkAddModel.LocationFormGroup> }) => {
          return {
            field: fieldEnum.NAME,
            shouldDisplayWarning: params.value.controls[fieldEnum.IS_NAME_DUPLICATED].value,
            warningTooltipText: Model.duplicatedNameOnTableMessage,
            actions: [
              {
                icon: "file_copy",
                tooltip: $localize`Duplicate`,
                disabled:
                  this.formGroup.controls[fieldEnum.RECORDS].controls.length >=
                  Model.maxRecordsCount,
                click: this.onDuplicateRecord.bind(this),
              },
              {
                icon: "delete",
                tooltip: TextConstants.REMOVE,
                disabled: this.formGroup.controls[fieldEnum.RECORDS].controls.length <= 1,
                click: this.onRemoveRecord.bind(this),
              },
            ],
          };
        },
      },
    ];

    if (!controls[fieldEnum.IS_FIXED_ORGANISATION].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.ORGANISATION),
        headerName: fieldEnumToLabelMap[fieldEnum.ORGANISATION],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.ORGANISATION].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditOrganisation(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.ORGANISATION],
            ),
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditOrganisation(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    if (!controls[fieldEnum.IS_FIXED_LOCATION_TYPES].value) {
      const cellRendererParams = (params: { data: Model.IRowData }) => {
        return {
          textParam: "label",
          showIcon: (locationTypeOption: InputSelectOption) => {
            return !!locationTypeOption.icon;
          },
          warningTooltipText: this.getWarningTooltipText(
            params.data.formGroup.controls[fieldEnum.LOCATION_TYPES],
          ),
          icon: "target",
          cellContainerClass: "min-height-fix",
          actions: [
            {
              icon: "edit",
              tooltip: TextConstants.EDIT,
              click: (data: Model.IRowData, event: PointerEvent) => {
                this.onClickEditLocationTypes(data, event.currentTarget);
              },
            },
          ],
        };
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.LOCATION_TYPES),
        maxWidth: 250,
        autoHeight: true,
        headerName: fieldEnumToLabelMap[fieldEnum.LOCATION_TYPES],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.LOCATION_TYPES].value;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditLocationTypes(event.data, event.event?.target);
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.LOCATION_TYPES],
        fieldEnum.LOCATION_TYPES,
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    if (!controls[fieldEnum.IS_FIXED_COUNTRY].value) {
      columnDefs.push({
        ...this.defaultColDefProperties(fieldEnum.COUNTRY),
        headerName: fieldEnumToLabelMap[fieldEnum.COUNTRY],
        valueGetter: (params) => {
          return params.data.formGroup.controls[fieldEnum.COUNTRY].value?.label;
        },
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditCountry(event.data, event.event?.target);
        },
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: (params: { data: Model.IRowData }) => {
          let templateWithContext: { template: TemplateRef<any>; context: any } = null;

          if (params.data.formGroup.controls[fieldEnum.COUNTRY].value) {
            templateWithContext = {
              template: this.countryFlag,
              context: {
                $implicit: params.data.formGroup.controls[fieldEnum.COUNTRY].value?.value,
              },
            };
          }

          return {
            warningTooltipText: this.getWarningTooltipText(
              params.data.formGroup.controls[fieldEnum.COUNTRY],
            ),
            templateWithContext,
            actions: [
              {
                icon: "edit",
                tooltip: TextConstants.EDIT,
                click: (data: Model.IRowData, event: PointerEvent) => {
                  this.onClickEditCountry(data, event.currentTarget);
                },
              },
            ],
          };
        },
      });
    }

    columnDefs.push(this.defaultColDefPropertiesForInput(fieldEnum.REGION));
    columnDefs.push(this.defaultColDefPropertiesForInput(fieldEnum.ZIP_CODE));
    columnDefs.push(this.defaultColDefPropertiesForInput(fieldEnum.ADDRESS));

    columnDefs.push({
      ...this.defaultColDefProperties(fieldEnum.GEOLOCATION),
      headerName: fieldEnumToLabelMap[fieldEnum.GEOLOCATION],
      valueGetter: (params: { data: Model.IRowData }) => {
        const formValue = params.data.formGroup.controls[fieldEnum.GEOLOCATION].value;

        return formValue?.fileName || "-";
      },
      onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
        this.onClickEditGeoLocationField(event?.event);
      },
      ...ColumnUtils.quickActionsMenuColumnCommonValues,
      cellRenderer: QuickActionsMenuComponent,
      cellRendererParams: (
        params: ICellRendererParams<{ formGroup: FormGroup<BulkAddModel.LocationFormGroup> }>,
      ) => {
        const geoLocationControl = params.data.formGroup.controls[fieldEnum.GEOLOCATION];

        let actions = [];

        if (geoLocationControl.value) {
          actions = [
            {
              icon: "delete",
              tooltip: TextConstants.REMOVE,
              click: () => {
                geoLocationControl.setValue(null);
                params.refreshCell();
              },
            },
          ];
        } else {
          actions = [
            {
              isUpload: true,
              icon: "upload",
              tooltip: $localize`Upload`,
              onFileSelected: (event: Event) => {
                this.onGeoLocationFileSelected(event, params);
              },
              click: (_, event: PointerEvent) => {
                this.onClickEditGeoLocationField(event);
              },
            },
          ];
        }

        return {
          field: fieldEnum.GEOLOCATION,
          actions,
        };
      },
    });

    if (!controls[fieldEnum.IS_FIXED_TAGS].value) {
      const cellRendererParams = {
        textParam: "tagDefinition.title",
        classParam: "tagDefinition.color",
        cellContainerClass: "min-height-fix",
        actions: [
          {
            icon: "edit",
            tooltip: TextConstants.EDIT,
            click: (data: Model.IRowData, event: PointerEvent) => {
              this.onClickEditTags(data, event.currentTarget);
            },
          },
        ],
      };

      const options: Partial<ColDef> = {
        ...this.defaultColDefProperties(fieldEnum.TAGS),
        minWidth: 250,
        autoHeight: true,
        onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
          this.onClickEditTags(event.data, event.event?.target);
        },
        valueGetter: (params: { data: Model.IRowData }) => {
          return params.data.formGroup.controls[fieldEnum.TAGS].value;
        },
      };

      const colDef = ColumnUtils.chips(
        fieldEnumToLabelMap[fieldEnum.TAGS],
        "tags",
        cellRendererParams,
        options,
      );

      columnDefs.push(colDef);
    }

    this.formGroup.controls[fieldEnum.CUSTOM_FIELDS].controls.forEach((control) => {
      if (!control.get(BulkAddCustomFieldEnum.IS_FIXED_CUSTOM_FIELD).value) {
        columnDefs.push(this.defaultColDefPropertiesForCustomField(control));
      }
    });

    this.columnDefs.set(columnDefs);
  }

  public async onClickAddNewOrganisation(): Promise<void> {
    await this.bulkAddLocationsService.openAddOrganisationDialog((option) => {
      this.dialogRef.componentInstance.replaceOptions(
        this.bulkAddLocationsService.organisationOptions(),
        option,
      );
    });
  }

  private onClickEditGeoLocationField(event: Event): void {
    const target = (event.currentTarget || event.target) as HTMLElement;

    target.querySelector("input").click();
  }

  private onGeoLocationFileSelected(
    event: Event,
    params: ICellRendererParams<{ formGroup: FormGroup<BulkAddModel.LocationFormGroup> }>,
  ): void {
    const input = event.target as HTMLInputElement;

    if (!input.files || !input.files.length) {
      return;
    }

    const file = input.files[0];
    const reader = new FileReader();

    reader.readAsText(file);

    reader.onload = () => {
      try {
        const geojson = JSON.parse(reader.result.toString()) as IGeoLocation;

        if (!GeoJSONUtils.isValid(geojson)) {
          const geojsonError = GeoJSONUtils.getGeoJsonError(geojson);

          this.notificationService.showError(geojsonError);

          return;
        }

        params.data.formGroup.controls[this.fieldEnum.GEOLOCATION].setValue({
          geoLocation: geojson,
          fileName: file.name,
        });

        params.refreshCell();
      } catch {
        this.notificationService.showError(TextConstants.FILE_NOT_SUPPORTED_ERROR);
      }
    };
  }

  private defaultColDefPropertiesForInput(
    fieldEnum: keyof BulkAddModel.LocationFormGroup,
  ): Partial<ColDef> {
    return {
      ...this.defaultColDefProperties(fieldEnum),
      headerName: BulkAddModel.fieldEnumToLabelMap[fieldEnum],
      valueGetter: (params) => {
        return params.data.formGroup.controls[fieldEnum].value;
      },
      onCellClicked: (event: CellClickedEvent<Model.IRowData>) => {
        this.onClickEditInputField(event.data, event.event?.target, fieldEnum);
      },
      ...ColumnUtils.quickActionsMenuColumnCommonValues,
      cellRenderer: QuickActionsMenuComponent,
      cellRendererParams: {
        actions: [
          {
            icon: "edit",
            tooltip: TextConstants.EDIT,
            click: (data: Model.IRowData, event: PointerEvent) => {
              this.onClickEditInputField(data, event.currentTarget, fieldEnum);
            },
          },
        ],
      },
    };
  }

  private onClickEditInputField(
    data: Model.IRowData,
    eventTarget: EventTarget,
    fieldEnum: keyof BulkAddModel.LocationFormGroup,
  ): void {
    this.openEditFieldDialog(
      data,
      fieldEnum,
      BulkAddEditFieldDialogModel.FieldTypeEnum.INPUT,
      eventTarget,
      {
        label: BulkAddModel.fieldEnumToLabelMap[fieldEnum],
      },
    );
  }

  private onClickEditOrganisation(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.ORGANISATION,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        label: BulkAddModel.fieldEnumToLabelMap[this.fieldEnum.ORGANISATION],
        options: this.bulkAddLocationsService.organisationOptions(),
        templateRef: this.addNewOrganisation,
      },
    );
  }

  private onClickEditCountry(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.COUNTRY,
      BulkAddEditFieldDialogModel.FieldTypeEnum.SELECT,
      eventTarget,
      {
        label: BulkAddModel.fieldEnumToLabelMap[this.fieldEnum.COUNTRY],
        options: this.bulkAddLocationsService.countryOptions(),
        prefixTemplate: "flag",
      },
    );
  }

  private onClickEditLocationTypes(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.LOCATION_TYPES,
      BulkAddEditFieldDialogModel.FieldTypeEnum.CHIPS,
      eventTarget,
      {
        label: BulkAddModel.fieldEnumToLabelMap[this.fieldEnum.LOCATION_TYPES],
        options: this.bulkAddLocationsService.locationTypeOptions(),
        allowCreateTag: true,
        tagUrl: this.locationTypesService.getBaseUrl,
      },
    );
  }

  private onClickEditTags(data: Model.IRowData, eventTarget: EventTarget): void {
    this.openEditFieldDialog(
      data,
      this.fieldEnum.TAGS,
      BulkAddEditFieldDialogModel.FieldTypeEnum.TAGS,
      eventTarget,
      {
        initialTags: data.formGroup.controls[this.fieldEnum.TAGS].value,
      },
    );
  }
}
