import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { CardContentComponent } from "@components/shared/cards/card-content/card-content.component";

import { RiskMitigation } from "../models";

@Component({
  standalone: false,
  selector: "app-risk-mitigation-content-card",
  templateUrl: "./risk-mitigation-content-card.component.html",
  styleUrl: "./risk-mitigation-content-card.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMitigationContentCardComponent extends CardContentComponent {
  @Input() riskMitigation: RiskMitigation;
}
