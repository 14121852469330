<app-table
  *ngIf="columnDefs().length"
  [table]="table"
  [isLoading]="isLoading()"
  [rowData]="rowData()"
  [columnDefs]="columnDefs()"
  [areButtonsEnabled]="areButtonsEnabled"
  [isSearchEnabled]="isSearchEnabled"
  [isRecordStateFilterEnabled]="false"
  [isPaginatorEnabled]="isPaginatorEnabled"
  [isFixedBottomPaginator]="isFixedBottomPaginator"
  [isSaveState]="isSaveTableState"
  (rowClick)="onEdit($event)"
  [batchActionSettings]="batchActionSettings"
  (getAll)="getAll()"
></app-table>
