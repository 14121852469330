@if (displayTitle) {
  <h2>{{ type | titlecase }}s</h2>
}

@if (isLoading()) {
  <app-loader></app-loader>
} @else {
  <h3 i18n>Quantity summary</h3>

  @if (selectedItems?.length) {
    @if (summaryComponentType === summaryComponentTypeEnum.PROCESS_INPUTS_OUTPUTS) {
      <app-process-in-out-table
        class="no-min-height"
        [processId]="processId"
        [type]="type"
        [allProducts]="allProducts"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [isSearchEnabled]="false"
        [isInboundShared]="isInboundShared"
        [inboundSharedSenderOrgId]="inboundSharedSenderOrgId"
        [inboundItems]="inboundItems"
        [inboundProcessResources]="inboundProcessResources"
      ></app-process-in-out-table>
    } @else if (summaryComponentType === summaryComponentTypeEnum.QUANTITY_SUMMARY) {
      <app-quantity-summary-table
        [selectedItems]="selectedItems"
        [allProducts]="allProducts"
        [allMaterials]="allMaterials"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [isSearchEnabled]="false"
        [isPaginatorEnabled]="true"
      ></app-quantity-summary-table>
    }
  } @else {
    <div class="text-center empty-quantity-summary">
      Once you add {{ type }}s to this process, a summary of their quantities will show up here.
    </div>
  }

  <br />

  <div class="subtitle-with-button">
    <h3>Selected {{ type }}s</h3>
    @if (!isReadOnly && canAddModifyEntities) {
      <app-button
        (pressed)="onAdd()"
        [tp]="translations.addNewTp"
        tpPlacement="bottom"
        size="medium"
        type="icon"
      >
        <mat-icon icon-medium-18>add</mat-icon>
      </app-button>
    }
  </div>

  <app-process-selected-items
    [type]="type"
    [elements]="selectedElements"
    [allMaterials]="allMaterials"
    [allProducts]="allProducts"
    [allUnitOfMeasurements]="allUnitOfMeasurements"
    [canEdit]="!isReadOnly && canAddModifyEntities"
    [canRemove]="!isReadOnly && canAddModifyEntities"
    (edit)="onAdd($event)"
    (remove)="onRemove($event)"
    [isInboundShared]="isInboundShared"
    [inboundItems]="inboundItems"
    [inboundSharedSenderOrgId]="inboundSharedSenderOrgId"
  ></app-process-selected-items>
}

<app-slide-overlay-wrapper [close]="!isSlideOverVisible()" (clickClose)="onCloseSlideOverlay()">
  <div class="full-width">
    @if (isSlideOverVisible()) {
      <app-add-process-inputs-outputs
        [element]="processInputOrOutput"
        [processId]="processId"
        [processLocation]="processLocation"
        [type]="type"
        [isReadOnly]="isReadOnly"
        [allUnitOfMeasurements]="allUnitOfMeasurements"
        [allProducts]="allProducts"
        [allMaterials]="allMaterials"
        (addCompleted)="onAddCompleted($event)"
      ></app-add-process-inputs-outputs>
    } @else {
      <div class="slide-over-content full-height">
        <app-loader></app-loader>
      </div>
    }
  </div>
</app-slide-overlay-wrapper>
