import { ChangeDetectionStrategy, Component, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { AuthUserTypeEnum, FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import { IParsedSharedToken } from "src/app/shared/interfaces";
import {
  AuthenticationService,
  AuthService,
  FeatureFlagService,
  SharesService,
} from "src/app/shared/services";
import { TokenUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./sharing.component.html",
  styleUrls: ["./sharing.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharingComponent implements OnInit {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    verificationCode: new UntypedFormControl(null, [CustomValidators.required]),
  });

  public isLoading = signal(false);

  public shareId: string;

  public recipient: string;

  public organisationId: string;

  public readonly translations: any = {
    verificationCodeLabel: $localize`Verification code`,
  };

  readonly isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

  constructor(
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private sharesService: SharesService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.authenticationService.logout(false);
  }

  public async ngOnInit(): Promise<void> {
    this.shareId = this.route.snapshot.queryParams["shareId"];
    this.recipient = this.route.snapshot.queryParams["recipient"];
    this.organisationId = this.route.snapshot.queryParams["orgId"];

    if (!this.shareId || !this.recipient || !this.organisationId) {
      this.notificationService.showError(TextConstants.MISSING_PARAMETERS);
      await this.router.navigate([`/${RouteEnum.LOGIN}`]);

      return;
    }

    await this.onSendVerificationCode();
  }

  public onSendVerificationCode = async (): Promise<void> => {
    this.isLoading.set(true);
    try {
      await this.sharesService.sendVerificationCode(this.organisationId, this.shareId);
    } catch (error) {
      this.notificationService.showError(error);
      if (error.status !== 425) {
        // 425 -> Too early.
        await this.router.navigate([`/${RouteEnum.LOGIN}`]);
      }
    } finally {
      this.isLoading.set(false);
    }
  };

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);
    const verificationCode = this.formGroup.controls["verificationCode"].value;

    try {
      const response = await this.authService.shareLogin(this.shareId, verificationCode);

      if (!response?.token) {
        this.notificationService.showError(null);

        return;
      }
      const parsedToken = TokenUtils.parseJwt(response.token) as IParsedSharedToken;

      if (!parsedToken?.sr) {
        this.notificationService.showError(null);

        return;
      }
      await this.authenticationService.setTokens(AuthUserTypeEnum.SHARED, response.token);
      await this.router.navigate([`/${RouteEnum.SHARED_LINK}`]);
      await this.authenticationService.goToMainShareLink();
    } catch (error) {
      this.notificationService.showError(error);
    } finally {
      this.isLoading.set(false);
    }
  };
}
