import { WritableSignal } from "@angular/core";

import { RiskAssessmentReport } from "@components/shared/risk-assessment-reports/models";

export namespace RelatedRiskAssessmentReportsTableModel {
  export interface RelatedRiskAssessmentReport extends RiskAssessmentReport {
    additionalData: {
      recordType: RecordType;
      name: string;
      id: string;
    };
  }

  export interface Record {
    id: string;
    label: string;
    type: RelatedRiskAssessmentReportsTableModel.RecordType;
    uri: string;
  }

  export interface Params {
    records: Record[];
  }

  export interface FilterModel {
    filterType: string;
    type: string;
    filter: string;
  }

  export interface ResidualRiskTag {
    label: string;
    value: string | number;
    icon: WritableSignal<string>;
    count: number;
    classes?: WritableSignal<string[]>;
  }

  export enum RecordType {
    COUNTRIES = "Country(ies)",
    ORGANISATIONS = "Organisation(s)",
    LOCATIONS = "Location(s)",
    MATERIALS = "Material(s)",
    PRODUCTS = "Product(s)",
    DELIVERIES = "Delivery(ies)",
  }

  export const NOT_SELECTED_TAG_CLASS = "half-opacity";

  export const CHECKED_ICON = "checked";

  export const REVERSE_ORDER_CLASS = "reverse-order";

  export const ALL_RISK_LEVELS = {
    label: $localize`All`,
    value: "[[[ALL]]]",
  };

  export const NONE_RISK_LEVEL = {
    label: $localize`None`,
    value: "[[[NONE]]]",
  };
}
