import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  booleanAttribute,
} from "@angular/core";
import { MatRadioChange } from "@angular/material/radio";

@Component({
  standalone: false,
  selector: "app-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrl: "./radio-button.component.scss",
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class RadioButtonComponent {
  @Input() label: string;

  @Input() value: string;

  @Input() name: string;

  @Input({ transform: booleanAttribute }) checked: boolean;

  @Input({ transform: booleanAttribute }) disabled: boolean;

  @Output() changeEvent: EventEmitter<MatRadioChange> = new EventEmitter<MatRadioChange>();

  onRadioChange(event: MatRadioChange): void {
    this.changeEvent.emit(event);
  }
}
