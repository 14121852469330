<app-slide-overlay-header
  [isInboundShare]="true"
  [title]="title()"
  [isSvgIcon]="true"
  icon="organisations"
></app-slide-overlay-header>
<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    [overlay-menu-show-edit-button]="false"
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="name"
              [label]="translations.nameLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              [viewModeTemplate]="urlTemplate"
              formControlName="website"
              [label]="translations.websiteLabel"
            ></app-input>
          </div>
        </div>
      </div>
    </app-form-field-group>
    <app-form-field-group [name]="translations.addressLabel">
      <div [formGroup]="formGroup" class="row-container">
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="street"
              [label]="translations.addressLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="region"
              [label]="translations.regionLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input
              enableViewMode
              formControlName="zipCode"
              [label]="translations.zipCodeLabel"
            ></app-input>
          </div>
        </div>
        <div class="row">
          <div class="item">
            <app-input-select
              [options]="countryOptions"
              [viewModeTemplate]="countryViewMode"
              enableViewMode
              formControlName="country"
              [label]="translations.countryLabel"
            ></app-input-select>
            <ng-template #countryViewMode let-label="label" let-option>
              <app-form-input-readonly [label]="label" [value]="option.label">
                <app-flag [country]="option.value" input-readonly-prefix></app-flag>
              </app-form-input-readonly>
            </ng-template>
          </div>
        </div>
      </div>
    </app-form-field-group>
  </ng-template>
  <ng-template #urlTemplate let-data>
    <div class="form-field-item hover-visibility-container">
      <app-form-label [label]="data.label"></app-form-label>
      <div class="form-field-input-readonly">
        @if (data.value) {
          <div class="one-line-wrap-ellipsis">
            <a
              class="blue-link"
              [href]="data.value"
              target="_blank"
              tpPlacement="top-start"
              [tp]="data.value"
            >
              {{ data.value }}
              <app-external-link-icon></app-external-link-icon>
            </a>
          </div>
        } @else {
          -
        }
      </div>
    </div>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="element()?.documents?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <article class="field-group-container">
      <app-documents-table
        [isInboundShared]="true"
        [documents]="element()?.documents"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
      ></app-documents-table>
    </article>
  </ng-template>
  <ng-template
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.CERTIFICATES).isEnabled"
    [overlay-menu-indicator]="element()?.certificates?.length"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.CERTIFICATES).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="verified_user"
  >
    <article class="field-group-container">
      <app-certificates-table
        [isInboundShared]="true"
        [inboundSharedSenderOrgId]="organisationIdQueryParam"
        [certificates]="element()?.certificates"
      ></app-certificates-table>
    </article>
  </ng-template>
</app-slide-overlay-content>
