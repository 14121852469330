<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      @if (overlay.viewMode()) {
        <app-form-input-readonly
          (clickOnValue)="onClickName()"
          [copyMode]="hasContent ? copyModeEnum.ICON : copyModeEnum.CONTAINER"
          [value]="formGroup.controls['name'].value + '.' + fileExtension"
          class="name"
          [label]="translations.nameLabel"
        ></app-form-input-readonly>
      } @else {
        <app-input
          [autofocus]="!isEditing()"
          [suffix]="'.' + fileExtension"
          formControlName="name"
          [label]="translations.nameLabel"
        ></app-input>
      }
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-input-select
        enableViewMode
        [options]="documentTypesOptions"
        [disabled]="!documentTypesOptions.length"
        formControlName="type"
        [label]="translations.typeLabel"
        [addOnWrite]="true"
      ></app-input-select>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        enableViewMode
        [label]="translations.issuanceLabel"
        formControlName="issuance"
        [maxDate]="
          formGroup.controls['validityStart'].value ?? formGroup.controls['validityEnd'].value
        "
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        enableViewMode
        [label]="translations.validityStartLabel"
        formControlName="validityStart"
        [minDate]="formGroup.controls['issuance'].value"
        [maxDate]="formGroup.controls['validityEnd'].value"
      ></app-datepicker>
    </div>
  </div>
  <div class="row">
    <div class="item">
      <app-datepicker
        enableViewMode
        [label]="translations.validityEndLabel"
        formControlName="validityEnd"
        [minDate]="
          formGroup.controls['validityStart'].value ?? formGroup.controls['issuance'].value
        "
      ></app-datepicker>
    </div>
  </div>
</div>
