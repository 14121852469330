import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  input,
  Input,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";

import { ISidebarMenuItem } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-sidebar-item",
  templateUrl: "./sidebar-item.component.html",
  styleUrls: ["./sidebar-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidebarItemComponent {
  @ViewChild("contentRef") set content(value: ElementRef) {
    this.textElement = value;
    this.cdr.detectChanges();
  }

  textElement: ElementRef;

  public menuItem = input.required<ISidebarMenuItem>();

  @Input() active: boolean;

  @Input() class: string;

  @Input() showTooltip: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) {}

  get tooltipContent(): string {
    return this.textElement?.nativeElement?.textContent;
  }

  get badge(): number {
    return this.menuItem().badgeFn ? this.menuItem().badgeFn() : undefined;
  }

  public async navigateToRoute(): Promise<void> {
    const menuItem = this.menuItem();
    const { route } = menuItem;

    await this.router.navigate([route]);
  }
}
