export enum BatchActionTypeEnum {
  ARCHIVE,
  DELETE,
  UNARCHIVE,
  DOWNLOAD_DOCUMENT,
  ACTIVATE_RULESET,
  DEACTIVATE_RULESET,
  RESEND_USER_INVITATION,
  ARCHIVE_USER,
  ACTIVATE_USER,
  DEACTIVATE_USER,
  DELETE_INBOUND_SHARE,
  ACCEPT,
  REJECT,
  DELETE_CONNECTION_REQUEST,
  ACCEPT_CONNECTION_REQUEST,
  REJECT_CONNECTION_REQUEST,
}
