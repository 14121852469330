<app-slide-overlay-header
  icon="language"
  [title]="title()"
  [canDelete]="true"
  [enableTags]="true"
/>

<app-slide-overlay-content #slideOverlayContent>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="form" class="full-width">
        <app-input formControlName="name" [label]="translations.nameLabel" enableViewMode />
      </div>
    </app-form-field-group>
  </ng-template>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="countryOverlay.documentCounter()"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
  >
    <app-overlay-document-attachments
      #documentView
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetType"
      [overlayService]="countryOverlay"
    />
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentView?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  @if (!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isHidden) {
    <ng-template
      appSlideOverlayMenuItem
      overlay-menu-icon="select_check_box"
      [overlay-menu-title]="menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).title"
      [overlay-menu-buttons-template]="riskAssessmentController"
      [overlay-menu-show-edit-button]="false"
      [overlay-menu-show-save-button]="false"
      [overlay-menu-indicator]="countryOverlay.reportsCounter()"
      [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isEnabled"
    >
      <app-overlay-risk-assessment-reports
        #riskAssessmentsView
        [activeOrganisationId]="activeOrganisationId"
        [recordUri]="entityUri"
        [recordName]="element.name"
        [resourceType]="templateResourceType"
        [overlayService]="countryOverlay"
      />
    </ng-template>
    <ng-template #riskAssessmentController>
      @if (canAddModifyEntities) {
        <app-button
          type="ghost"
          [disabled]="overlay.loading()"
          (pressed)="riskAssessmentsView.openDialog()"
        >
          <mat-icon class="action-icon" icon-left>add</mat-icon>
          <ng-container i18n>Add</ng-container>
        </app-button>
      }
    </ng-template>
  }
  @if (!menuItems().get(overlayTabEnum.COMMENTS).isHidden) {
    <ng-template
      appSlideOverlayMenuItem
      overlay-menu-icon="chat_bubble"
      [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
      [overlay-menu-show-edit-button]="false"
      [overlay-menu-show-save-button]="false"
      [overlay-menu-indicator]="countryOverlay.commentCounter()"
      [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    >
      <article class="field-group-container">
        <app-comments
          (added)="onCommentAdded(countryOverlay)"
          (deleted)="onCommentDeleted(countryOverlay)"
          [entityId]="element?.id"
          [entityType]="entityType"
        />
      </article>
    </ng-template>
  }
</app-slide-overlay-content>
