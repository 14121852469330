import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnChanges,
  Output,
  signal,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { ConfirmDialogComponent } from "src/app/components/shared";
import { ConfirmDialogResponseEnum, TableEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  IItem,
  IItemDetails,
  IMaterial,
  IProductExtended,
} from "src/app/shared/interfaces";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { AddItemDialogComponent } from "@components/deliveries/components/add-item-dialog/add-item-dialog.component";
import { DeliveryOverlayService } from "@components/deliveries/pages/delivery-overlay/delivery-overlay.service";
import { EditItemQuantityDialogComponent } from "@components/index";
import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { LinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import { NotificationService, AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

export interface IItemQuantityDialogPayload {
  id: string;
  deliveredQuantity: number;
}

@Component({
  standalone: false,
  selector: "app-slide-overlay-delivery-items",
  templateUrl: "./slide-overlay-delivery-items.component.html",
  styleUrls: ["./slide-overlay-delivery-items.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayDeliveryItemsComponent implements OnChanges, AfterViewInit {
  @ViewChild("attachmentsRightMenu") attachmentsRightMenu: TemplateRef<unknown>;

  @Input() public availableItems: IItemDetails[] = [];

  @Input() public selectedItems: IItem[] = [];

  @Input() public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input() public allMaterials: IMaterial[] = [];

  public isLoadingItems = input<boolean>(false);

  @Input() public isReadOnly: boolean = false;

  @Output() public reloadItems: EventEmitter<string> = new EventEmitter();

  @Output() public addNewItem: EventEmitter<IItem> = new EventEmitter();

  @Output() public setSelectedItems: EventEmitter<IItem[]> = new EventEmitter();

  @Output() public updateDeliveredQuantity: EventEmitter<IItemQuantityDialogPayload> =
    new EventEmitter();

  @Output() public openBulkAddItemsSlideOver: EventEmitter<void> = new EventEmitter();

  public readonly contentCardTypeEnum = CardContentTypeEnum;

  isSelectedAttachmentsLoading = signal<boolean>(true);

  public searchAvailableText: string;

  public itemsColumnDefs = signal<ColDef[]>([]);

  public itemRowData: IItemDetails[] = [];

  protected readonly tableType = TableEnum.QUANTITY_SUMMARY;

  private readonly canAddModifyEntities = this.autheticationService.canAddModifyEntities();

  public isInboundShared = input<boolean>(false);

  public inboundSharedSenderOrgId = input<string>("");

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private router: RouterService,
    private unitConversionPipe: UnitConversionPipe,
    private deliveryOverlayService: DeliveryOverlayService,
    private autheticationService: AuthenticationService,
    public overlay: SlideOverlayPageService,
  ) {}

  public async ngAfterViewInit(): Promise<void> {
    this.setColumnDefs();
    await this.setRowsData(this.selectedItems);
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes["selectedItems"]) {
      await this.setRowsData(this.selectedItems);
    }
  }

  public onSearchAvailable = (value: string): void => {
    this.searchAvailableText = value;
    this.reloadItems.emit(this.searchAvailableText);
  };

  public onAddAll = (): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.ADD_ALL_CONFIRMATION,
        contentHTML: $localize`Are you sure you want to add all <b>${this.availableItems.length}:count:</b> items?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const selectedItems = [...this.selectedItems];

        for (let i = 0; i < this.availableItems.length; i++) {
          selectedItems.push(this.onAdd(this.availableItems[i].id, false));
        }

        this.setSelectedItems.emit([...selectedItems]);
        this.notificationService.showSuccess($localize`Items added`);
      }
    });
  };

  public onAdd = (id: string, isSingleAdd = true): IItemDetails => {
    if (this.isReadOnly) {
      return null;
    }

    const item = this.availableItems.find((i) => i.id === id);
    const newItem: IItemDetails = {
      ...item,
      deliveredQuantity: item.deliveredQuantity ?? item.initialQuantity,
    };
    const selectedItems = [...this.selectedItems, newItem];

    if (isSingleAdd) {
      this.setSelectedItems.emit([...selectedItems]);
      this.notificationService.showSuccess($localize`Item added`);
    }

    return newItem;
  };

  public onRemove = (id: string): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want to remove this item from the delivery?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        const selectedItems = this.selectedItems.filter((i) => i.id !== id);

        this.setSelectedItems.emit([...selectedItems]);
        this.notificationService.showSuccess($localize`Item removed`);
      }
    });
  };

  public onDuplicate = async (item: IItemDetails): Promise<void> => {
    await this.onAddNewItem(item);
  };

  public async onAddNewItem(item?: IItemDetails): Promise<void> {
    const result = await this.overlay.openDialog<
      { hasSaved: boolean; item: IItem },
      { dialogTitle: string; originalItem: IItemDetails }
    >(AddItemDialogComponent, {
      dialogTitle: $localize`Add item to delivery`,
      originalItem: item,
    });

    if (result?.hasSaved) {
      await this.deliveryOverlayService.onReloadProducts();
      this.addNewItem.emit(result.item);
    }
  }

  public onEditItem = (item: IItemDetails): void => {
    const dialogRef = this.dialog.open(EditItemQuantityDialogComponent, {
      autoFocus: false,
      data: { item, allProducts: this.allProducts },
    });

    dialogRef
      .afterClosed()
      .subscribe(async (result: { data: IItemQuantityDialogPayload; hasSaved: boolean }) => {
        if (result?.hasSaved) {
          const currentQuantity = String(result.data.deliveredQuantity);
          let convertedValue = currentQuantity;
          const product = this.allProducts.find((p) => p.id === item.productId);
          const defaultCustomUnit = product?.defaultCustomUnit;

          if (defaultCustomUnit) {
            item.productDefaultUnit = defaultCustomUnit;
            convertedValue = this.unitConversionPipe.transform(
              currentQuantity,
              item.productDefaultUnit,
              item.unitOfMeasurement,
              true,
            );
          }

          result.data.deliveredQuantity = +convertedValue;

          this.updateDeliveredQuantity.emit(result.data);
          this.reloadItems.emit();
        }
      });
  };

  private async setRowsData(items: IItem[]): Promise<void> {
    this.itemRowData = await this.getParsedItemData(items);
    this.isSelectedAttachmentsLoading.set(false);
  }

  private async getParsedItemData(selectedItems: IItem[]): Promise<IItemDetails[]> {
    if (!selectedItems) {
      return [];
    }

    const items = CommonUtils.getItemsWithProductAndAssignedUnit(selectedItems, this.allProducts);

    const newItems: IItem[] = [];

    for (const item of items) {
      const itemProductId = CommonUtils.getUriId(item.product);

      const product = this.allProducts.find((p) => p.id === itemProductId);
      const productDefaultUnit = product?.defaultCustomUnit;
      const baseUnit = product?.unitOfMeasurement;

      let newItem = { ...item, productDefaultUnit: null, valueWithDefaultUnit: null };

      if (productDefaultUnit) {
        const valueWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          item.deliveredQuantity ?? item.selectedQuantity ?? item.remainingQuantity,
          productDefaultUnit,
          baseUnit,
        );

        newItem = {
          ...newItem,
          productDefaultUnit,
          valueWithDefaultUnit,
          deliveredQuantity: item.deliveredQuantity,
          remainingQuantity: item.remainingQuantity,
        };
      }

      newItems.push(newItem);
    }

    return newItems;
  }

  private setColumnDefs(): void {
    this.itemsColumnDefs.set([
      {
        headerName: $localize`ID`,
        field: "itemId",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.router.getSharedItemLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.router.getItemLink,
          linkRouteIdParam: "id",
          actions: [
            {
              icon: "file_copy",
              tooltip: $localize`Duplicate`,
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onDuplicate(data),
            },
            {
              icon: "close",
              tooltip: TextConstants.REMOVE,
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onRemove(data.id),
            },
          ],
        },
      },
      {
        headerName: TextConstants.PRODUCT,
        field: "productName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.router.getSharedProductLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.router.getProductLink,
          linkRouteIdParam: "productId",
        },
      },
      {
        headerName: $localize`Delivery q-ty`,
        field: "deliveredQuantity",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          hasUnitOfMeasurement: true,
          precisionParam: "unitOfMeasurement.precision",
          symbolParam: "unitOfMeasurement.symbol",
          formattedQuantityParam: "valueWithDefaultUnit",
          actions: [
            {
              icon: "edit",
              tooltip: TextConstants.EDIT,
              show: () => !this.isReadOnly && this.canAddModifyEntities,
              click: (data: IItemDetails) => this.onEditItem(data),
            },
          ],
        },
      },
    ]);
  }

  public onOpenBulkAddItemsSlideOver(): void {
    this.openBulkAddItemsSlideOver.emit();
  }
}
