import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  computed,
  inject,
  signal,
} from "@angular/core";
import { AbstractControlOptions, FormControl, FormGroup } from "@angular/forms";

import { forkJoin, lastValueFrom, tap } from "rxjs";

import { ConfirmDialogComponent } from "@components/shared";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { MAX_INDICATOR_MITIGATIONS_ALLOWED } from "@components/shared/risk-assessment-templates/constants";
import { RiskLevel } from "@components/shared/risk-level-sets/models";
import { RiskMitigation } from "@components/shared/risk-mitigations/models";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  EntityTypeEnum,
  OverlayTabEnum,
  RouteEnum,
} from "@shared/enums";
import { IRecordState, ISelectOption } from "@shared/interfaces";
import { CommonUtils, FormUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

import {
  RiskAssessmentReportIndicatorMitigationsApiService,
  RiskAssessmentReportIndicatorsApiService,
  RiskAssessmentReportsApiService,
} from "../api";
import {
  RiskAssessmentReportIndicatorStatusEnum,
  RiskAssessmentReportIndicatorMitigationStatus,
  RiskAssessmentReportStatus,
} from "../constants";
import {
  RiskAssessmentReport,
  RiskAssessmentReportIndicator,
  RiskAssessmentReportIndicatorMitigation,
  RiskAssessmentReportIndicatorMitigationPayload,
  RiskAssessmentReportPutPayload,
} from "../models";
import { RiskAssessmentReportIndicatorMitigationsMapService } from "../services";

interface IndicatorFormGroup {
  _categoryId: FormControl<string>;
  _categoryName: FormControl<string>;
  _guidance: FormControl<string>;
  _id: FormControl<string>;
  _indicator: FormControl<string>;
  _mitigations: FormControl<RiskAssessmentReportIndicatorMitigation[]>;
  _showGuidance: FormControl<boolean>;
  note: FormControl<string>;
}

interface StatusOption extends ISelectOption {
  value: RiskAssessmentReportStatus;
}

@Component({
  standalone: false,
  selector: "app-risk-assessment-report-overlay",
  templateUrl: "./risk-assessment-report-overlay.component.html",
  styleUrl: "./risk-assessment-report-overlay.component.scss",
  changeDetection: ChangeDetectionStrategy.Default,
})
export class RiskAssessmentReportOverlayComponent extends SlideOverlayPageClass implements OnInit {
  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("mitigationsPanel") readonly mitigationsPanel: TemplateRef<unknown>;

  override menuItems = signal(
    new Map([[OverlayTabEnum.DETAILS, { title: TextConstants.INDICATORS, isEnabled: true }]]),
  );

  override element: RiskAssessmentReport;

  override entityType = EntityTypeEnum.RISK_ASSESSMENT_REPORTS;

  override goBackOnDelete = true;

  private readonly reportsApi = inject(RiskAssessmentReportsApiService);

  private readonly reportIndicatorsApi = inject(RiskAssessmentReportIndicatorsApiService);

  private readonly reportIndicatorMitigationsApi = inject(
    RiskAssessmentReportIndicatorMitigationsApiService,
  );

  private readonly mitigationsMapService = inject(
    RiskAssessmentReportIndicatorMitigationsMapService,
  );

  private readonly indicators = signal<RiskAssessmentReportIndicator[]>([]);

  private readonly riskLevels = signal<RiskLevel[]>([]);

  private readonly currentIndicatorFormGroup = signal<FormGroup<IndicatorFormGroup>>(undefined);

  public loadingIndicatorStatus = signal<boolean>(false);

  public readonly riskAssessmentIndicatorStatusEnum = RiskAssessmentReportIndicatorStatusEnum;

  public readonly translations: any = {
    noteLabel: $localize`Note`,
    optionalNotePh: `${$localize`Type your note here...`} (${TextConstants.OPTIONAL})`,
    notePh: $localize`Type your note here...`,
    mitigationsLabel: $localize`Mitigations`,
    statusLabel: TextConstants.STATUS,
    calculatedRiskLabel: $localize`Calculated risk`,
    residualRiskLabel: $localize`Residual risk`,
    completedDateLabel: $localize`Date completed`,
    validUntilDateLabel: $localize`Valid until`,
    resetResponseTp: $localize`Reset response`,
  };

  public riskAssessmentIndicatorStatusOptions = [
    { value: RiskAssessmentReportIndicatorStatusEnum.RISK_ABSENT, label: $localize`Risk absent` },
    { value: RiskAssessmentReportIndicatorStatusEnum.RISK_PRESENT, label: $localize`Risk present` },
    {
      value: RiskAssessmentReportIndicatorStatusEnum.NOT_APPLICABLE,
      label: $localize`Not applicable`,
    },
    { value: null, label: "" },
  ];

  private indicatorStates = new Map<
    string,
    {
      allMitigationsCompleted: boolean | null;
      riskAssessmentIndicatorStatus: RiskAssessmentReportIndicatorStatusEnum | null;
    }
  >();

  private readonly riskLevelsMap = computed(
    () => new Map(this.riskLevels().map((level) => [level.title, level])),
  );

  readonly riskLevelOptions = computed<ISelectOption[]>(() =>
    this.riskLevels().map((level) => ({
      label: level.title,
      value: level.color,
    })),
  );

  private readonly riskLevelsOptionsMap = computed(
    () => new Map(this.riskLevelOptions().map((level) => [level.label, level])),
  );

  readonly completedIndicatorsCount = computed(() => {
    let total = 0;
    let completed = 0;

    this.indicators().forEach((item) => {
      const indicators = item.indicators;

      total += indicators.length;

      completed += indicators.reduce((acc, indicator) => {
        const isRiskAbsent =
          indicator.status === RiskAssessmentReportIndicatorStatusEnum.RISK_ABSENT;

        const isNotApplicable =
          indicator.status === RiskAssessmentReportIndicatorStatusEnum.NOT_APPLICABLE;

        const mitigations: RiskAssessmentReportIndicatorMitigation[] =
          this.mitigationsMapService.map()?.get(indicator.id) || [];
        const hasMitigations = !!mitigations.length;

        const noPendingMitigations = mitigations.every((m) => m.status !== null);
        const atLeastOneCompletedMitigation = mitigations.some(
          (m) => m.status === RiskAssessmentReportIndicatorMitigationStatus.COMPLETED,
        );

        const isRiskPresentWithMitigationsCompleted =
          hasMitigations &&
          indicator.status === RiskAssessmentReportIndicatorStatusEnum.RISK_PRESENT &&
          noPendingMitigations &&
          atLeastOneCompletedMitigation;

        return (
          acc + (isRiskAbsent || isNotApplicable || isRiskPresentWithMitigationsCompleted ? 1 : 0)
        );
      }, 0);
    });

    return `${completed} / ${total}`;
  });

  readonly form = this.formBuilder.group(
    {
      status: this.formBuilder.control<StatusOption>(null, CustomValidators.required),
      calculatedRisk: this.formBuilder.control<ISelectOption>(null),
      residualRisk: this.formBuilder.control<ISelectOption>(null),
      completedDate: [""],
      validUntilDate: [""],
      note: ["", CustomValidators.maxLength(5000)],
      indicators: this.formBuilder.array<FormGroup<IndicatorFormGroup>>([]),
    },
    {
      validators: CustomValidators.dateRules([
        {
          name: "validUntilDate",
          rules: [
            {
              type: "<",
              target: "completedDate",
              errorMessage: $localize`Cannot be before the completed date`,
            },
          ],
        },
      ]),
    } as AbstractControlOptions,
  );

  readonly statusOptions: ISelectOption[] = Object.keys(RiskAssessmentReportStatus).map((key) => ({
    label: CommonUtils.enumToText(key),
    value: key,
  }));

  readonly formValueChanges$ = this.form.valueChanges.pipe(
    tap(
      () =>
        (this.hasFormValuesChanged =
          !this.form.pristine && this.hasInitialFormValueChanged(this.form.getRawValue())),
    ),
  );

  private get nameQueryParam() {
    return this.route.snapshot.queryParams["name"];
  }

  private get editQueryParam(): boolean {
    return this.route.snapshot.queryParams["edit"] === "true";
  }

  override get isSubmitButtonDisabled() {
    return false;
  }

  override get requiresConfirmation(): boolean {
    return this.hasFormValuesChanged || this.hasInitialFormValueChanged(this.form.getRawValue());
  }

  override initialFormValue = this.form.getRawValue();

  readonly mitigationStatus = RiskAssessmentReportIndicatorMitigationStatus;

  readonly indicatorsCountTooltip = $localize`The indicator count shows how many indicators have been completed. 
    These are the ones marked as “Risk absent” or “Not applicable” and the ones marked as “Risk present”, but with no unresolved mitigations.`;

  async ngOnInit() {
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_RISK_ASSESSMENT_REPORT)) {
      return;
    }

    if (this.recordId) {
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();

      if (this.editQueryParam) {
        this.overlay.enableEditMode();
        this.routerService.updateCurrentUrlParams({ edit: null });
      }
    }
  }

  override title(): string {
    if (this.overlay.loading()) {
      return $localize`Loading...`;
    }

    return `${$localize`Risk assessment`}: ${this.nameQueryParam}`;
  }

  override async archiveRecord(id: string, payload: IRecordState) {
    await this.reportsApi.setRecordState(id, payload);
  }

  override async deleteRecord(id: string) {
    await this.reportsApi.delete(id);
  }

  override async reloadElement(id: string) {
    this.overlay.showLoading();

    try {
      const [report, riskLevels, indicators] = await Promise.all([
        this.reportsApi.get(id),
        this.reportsApi.getRiskLevels(id),
        this.reportIndicatorsApi.getAll(id),
      ]);

      this.element = report;
      this.riskLevels.set(riskLevels);
      this.indicators.set(indicators);
      await this.mitigationsMapService.setMap(id, indicators);
      await this.setupForm();
      this.setUpRiskIndicatorStatesMap();
      this.overlay.dismissLoading();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  override async setupForm() {
    const { calculatedRisk, completedDate, note, residualRisk, status, validUntilDate } =
      this.element;

    this.form.patchValue({
      status: { label: CommonUtils.enumToText(status), value: status },
      calculatedRisk: calculatedRisk ? this.riskLevelsOptionsMap().get(calculatedRisk.title) : null,
      residualRisk: residualRisk ? this.riskLevelsOptionsMap().get(residualRisk.title) : null,
      completedDate,
      validUntilDate,
      note,
    });

    this.form.controls.indicators.clear();

    this.indicators().forEach((item) => {
      item.indicators.forEach((indicator) => {
        const group = this.formBuilder.group({
          _id: [indicator.id],
          _categoryId: [item.id],
          _categoryName: [item.name],
          _indicator: [indicator.indicator],
          _guidance: [indicator.guidance],
          _showGuidance: [false],
          _mitigations: [this.mitigationsMapService.map().get(indicator.id)],
          note: [CommonUtils.nl2br(indicator.note)],
        });

        this.form.controls.indicators.push(group);
      });
    });

    this.initialFormValue = this.form.getRawValue();
    this.hasFormValuesChanged = false;
  }

  override async handleDiscardChanges() {
    this.overlay.closeSideMenu();
    await this.setupForm();
  }

  override async save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    const hasUnsavedNotes = FormUtils.hasInitialFormValueChanged(
      this.initialFormValue.indicators,
      this.form.getRawValue().indicators,
    );

    if (hasUnsavedNotes) {
      const dialog = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: $localize`Save risk assessment report`,
          contentText: $localize`You have unsaved responses. Would you like to submit them?`,
          confirmButtonText: $localize`Save`,
        },
      });

      const response = await lastValueFrom(dialog.afterClosed());

      if (response === ConfirmDialogResponseEnum.CANCEL) {
        return false;
      }
    }

    try {
      const indicatorRequests = forkJoin(
        this.form
          .getRawValue()
          .indicators.map(({ _id, note }) =>
            this.reportIndicatorsApi.update(this.recordId, _id, { note }),
          ),
      );

      const indicators = await lastValueFrom(indicatorRequests);

      this.updateIndicators(indicators);

      this.element = await this.reportsApi.update(this.recordId, this.buildPayload());

      this.hasFormValuesChanged = false;
      this.initialFormValue = this.form.getRawValue();

      this.notificationService.showSuccess($localize`Risk assessment report modified`);
      this.overlay.closeSideMenu();

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    }
  }

  async saveNote(index: number) {
    const group = this.form.controls.indicators.at(index);
    const { _id, note } = group.getRawValue();

    try {
      const indicator = await this.reportIndicatorsApi.update(this.recordId, _id, { note });

      this.notificationService.showSuccess($localize`Indicator response modified`);
      this.updateIndicators([indicator]);
      this.initialFormValue.indicators = this.form.getRawValue().indicators;
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  toggleShowGuidance(index: number) {
    const control = this.form.controls.indicators.at(index).controls._showGuidance;

    control.setValue(!control.value);
  }

  async openMitigationsPanel(index: number) {
    const group = this.form.controls.indicators.at(index);
    const { _mitigations } = group.getRawValue();

    if (_mitigations.length === MAX_INDICATOR_MITIGATIONS_ALLOWED) {
      this.notificationService.showError(
        $localize`No more than ${MAX_INDICATOR_MITIGATIONS_ALLOWED}:maxIndicatorMitigationsAllowedCount: mitigations are allowed per indicator`,
      );

      return;
    }

    this.currentIndicatorFormGroup.set(group);
    this.overlay.openSideMenu(this.mitigationsPanel);
  }

  async onAddIndicatorMitigation(riskMitigation: RiskMitigation) {
    try {
      const { _id, _mitigations } = this.currentIndicatorFormGroup().getRawValue();

      const mitigation = await this.reportIndicatorMitigationsApi.createOrUpdate(
        this.buildRiskMitigationPayload(riskMitigation),
        this.recordId,
        _id,
      );

      await this.updateRiskIndicatorStatus(
        RiskAssessmentReportIndicatorStatusEnum.RISK_PRESENT,
        _id,
      );
      this.notificationService.showSuccess($localize`Risk mitigation added`);
      this.mitigationsMapService.updateMap(_id, mitigation.id, mitigation);
      this.setUpRiskIndicatorStatesMap();

      if (_mitigations.length === MAX_INDICATOR_MITIGATIONS_ALLOWED) {
        this.overlay.closeSideMenu();
      }
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  onRemoveIndicatorMitigation(indicatorId: string, mitigationId: string) {
    const onSubmit = async () => {
      try {
        await this.reportIndicatorMitigationsApi.delete(this.recordId, indicatorId, mitigationId);
        this.notificationService.showSuccess($localize`Risk mitigation removed`);
        this.mitigationsMapService.updateMap(indicatorId, mitigationId);
        this.setUpRiskIndicatorStatesMap();
      } catch (error) {
        this.notificationService.showError(error);
      }
    };

    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.REMOVE_CONFIRMATION,
        contentText: $localize`Are you sure you want remove this mitigation?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.REMOVE,
        confirmButtonIcon: "delete",
        onSubmit,
      },
    });
  }

  async onUpdateIndicatorMitigationStatus(
    indicatorId: string,
    riskMitigation: RiskAssessmentReportIndicatorMitigation,
    status: RiskAssessmentReportIndicatorMitigationStatus,
  ) {
    try {
      const mitigation = await this.reportIndicatorMitigationsApi.createOrUpdate(
        this.buildRiskMitigationPayload(riskMitigation, status),
        this.recordId,
        indicatorId,
        riskMitigation.id,
      );

      this.notificationService.showSuccess($localize`Risk mitigation updated`);
      this.mitigationsMapService.updateMap(indicatorId, riskMitigation.id, mitigation);
      const indicators = await this.reportIndicatorsApi.getAll(this.element.id);

      // this makes completedIndicatorsCount() to update the value
      this.indicators.set(indicators);
      this.setUpRiskIndicatorStatesMap();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  private buildPayload(): RiskAssessmentReportPutPayload {
    const { calculatedRisk, completedDate, note, residualRisk, status, validUntilDate } =
      this.form.getRawValue();

    return {
      note,
      status: status.value,
      calculatedRisk: this.riskLevelsMap().has(calculatedRisk?.label)
        ? this.riskLevelsMap().get(calculatedRisk.label)
        : null,
      residualRisk: this.riskLevelsMap().has(residualRisk?.label)
        ? this.riskLevelsMap().get(residualRisk.label)
        : null,
      completedDate: FormUtils.getDateValueForPayload(completedDate),
      validUntilDate: FormUtils.getDateValueForPayload(validUntilDate),
      recordUris: this.element.recordUris,
      recordState: null,
    };
  }

  private buildRiskMitigationPayload(
    riskMitigation: RiskMitigation | RiskAssessmentReportIndicatorMitigation,
    status?: RiskAssessmentReportIndicatorMitigationStatus,
  ): RiskAssessmentReportIndicatorMitigationPayload {
    const { mitigation, description } = riskMitigation;

    return {
      mitigation,
      description,
      status: status ? status : null,
    };
  }

  private updateIndicators(indicators: RiskAssessmentReportIndicator["indicators"]) {
    const indicatorsMap = new Map(indicators.map((indicator) => [indicator.id, indicator]));

    this.indicators.update((currentIndicators) =>
      currentIndicators.map((item) => ({
        ...item,
        indicators: item.indicators.map((indicator) =>
          indicatorsMap.has(indicator.id) ? indicatorsMap.get(indicator.id) : indicator,
        ),
      })),
    );
  }

  async onIndicatorRiskStatusChange(
    status: RiskAssessmentReportIndicatorStatusEnum,
    indicatorId: string,
  ) {
    this.initializeRiskIndicatorState(indicatorId);
    const currentState = this.getRiskIndicatorState(indicatorId)?.riskAssessmentIndicatorStatus;

    const mitigations: RiskAssessmentReportIndicatorMitigation[] =
      this.mitigationsMapService.map().get(indicatorId) || [];

    const requiresDialog =
      (status === RiskAssessmentReportIndicatorStatusEnum.NOT_APPLICABLE ||
        status === RiskAssessmentReportIndicatorStatusEnum.RISK_ABSENT) &&
      mitigations.some(
        (m) =>
          m.status === RiskAssessmentReportIndicatorMitigationStatus.COMPLETED ||
          m.status === RiskAssessmentReportIndicatorMitigationStatus.NOT_APPLICABLE,
      );

    if (requiresDialog) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: $localize`Change risk presence`,
          contentText: $localize`This indicator already has one or more mitigations marked as completed or not applicable. Stating that the risk is absent or the indicator is not applicable will remove the mitigations and indicate that the risk was never present in the first place. Are you sure you want to proceed?`,
          confirmButtonText: $localize`Proceed`,
        },
      });

      dialogRef.afterClosed().subscribe(async (response: ConfirmDialogResponseEnum) => {
        if (response === ConfirmDialogResponseEnum.CONFIRM) {
          await this.updateRiskIndicatorStatus(status, indicatorId);
          await this.reloadElement(this.element.id);
          this.updateRiskIndicatorState(indicatorId, { riskAssessmentIndicatorStatus: status });
        } else {
          this.loadingIndicatorStatus.set(true);
          this.updateRiskIndicatorState(indicatorId, {
            riskAssessmentIndicatorStatus: currentState,
          });
          this.changeDetectorRef.detectChanges();
          // TODO: find a better way to update the radio btns
          setTimeout(() => {
            this.loadingIndicatorStatus.set(false);
          }, 200);
        }
      });
    } else {
      await this.updateRiskIndicatorStatus(status, indicatorId);
      this.updateRiskIndicatorState(indicatorId, { riskAssessmentIndicatorStatus: status });

      const allCompleted =
        mitigations.length &&
        mitigations.every(
          (m) => m.status === RiskAssessmentReportIndicatorMitigationStatus.COMPLETED,
        );

      this.updateRiskIndicatorState(indicatorId, {
        allMitigationsCompleted: allCompleted,
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  async updateRiskIndicatorStatus(
    status: RiskAssessmentReportIndicatorStatusEnum,
    indicatorId: any,
  ): Promise<void> {
    try {
      await this.reportIndicatorsApi.updateStatus(this.element.id, indicatorId, { status: status });
      this.notificationService.showSuccess($localize`Indicator status updated`);

      const indicators = await this.reportIndicatorsApi.getAll(this.element.id);

      // this makes completedIndicatorsCount() to update the value
      this.indicators.set(indicators);
      this.setUpRiskIndicatorStatesMap();
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  async resetIndicatorResponse(indicatorId: string) {
    this.initializeRiskIndicatorState(indicatorId);

    const currentState = this.getRiskIndicatorState(indicatorId);
    const currentStatus = currentState?.riskAssessmentIndicatorStatus;

    if (currentStatus !== RiskAssessmentReportIndicatorStatusEnum.RISK_ABSENT) {
      this.updateRiskIndicatorState(indicatorId, { riskAssessmentIndicatorStatus: null });
      await this.updateRiskIndicatorStatus(null, indicatorId);
    } else {
      const mitigations: RiskAssessmentReportIndicatorMitigation[] =
        this.mitigationsMapService.map().get(indicatorId) || [];

      const hasNonNullStatus = mitigations.some(
        (mitigation) =>
          mitigation.status === RiskAssessmentReportIndicatorMitigationStatus.COMPLETED ||
          mitigation.status === RiskAssessmentReportIndicatorMitigationStatus.NOT_APPLICABLE,
      );

      if (hasNonNullStatus) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: $localize`Reset indicator response`,
            contentText: $localize`This action will reset the indicator response and all indicator mitigation responses. Are you sure you want to reset it?`,
            confirmButtonText: $localize`Reset`,
            confirmButtonColor: "danger",
            confirmButtonIcon: "restart_alt",
          },
        });

        dialogRef.afterClosed().subscribe(async (response: ConfirmDialogResponseEnum) => {
          if (response === ConfirmDialogResponseEnum.CONFIRM) {
            await this.updateRiskIndicatorStatus(null, indicatorId);
            this.updateRiskIndicatorState(indicatorId, { riskAssessmentIndicatorStatus: null });
          }
        });
      } else {
        await this.updateRiskIndicatorStatus(null, indicatorId);
        this.updateRiskIndicatorState(indicatorId, { riskAssessmentIndicatorStatus: null });
      }
    }
  }

  initializeRiskIndicatorState(indicatorId: string): void {
    if (!this.indicatorStates.has(indicatorId)) {
      this.indicatorStates.set(indicatorId, {
        riskAssessmentIndicatorStatus: null,
        allMitigationsCompleted: null,
      });
    }
  }

  updateRiskIndicatorState(
    id: string,
    updates: Partial<{
      allMitigationsCompleted: boolean | null;
      riskAssessmentIndicatorStatus: RiskAssessmentReportIndicatorStatusEnum | null;
    }>,
  ) {
    const state = this.indicatorStates.get(id);

    this.indicatorStates.set(id, { ...state, ...updates });
  }

  setUpRiskIndicatorStatesMap() {
    const indicators = this.indicators().map((i) => i.indicators);

    indicators.flat().forEach((indicator) => {
      const mitigations: RiskAssessmentReportIndicatorMitigation[] =
        this.mitigationsMapService.map().get(indicator.id) || [];

      const noPendingMitigations = mitigations.every((m) => m.status !== null);

      const atLeastOneCompletedMitigation = mitigations.some(
        (m) => m.status === RiskAssessmentReportIndicatorMitigationStatus.COMPLETED,
      );

      const allCompleted = noPendingMitigations && atLeastOneCompletedMitigation;

      this.indicatorStates.set(indicator.id, {
        riskAssessmentIndicatorStatus: indicator.status,
        allMitigationsCompleted: allCompleted,
      });
    });
  }

  getRiskIndicatorState(indicatorId: string) {
    return this.indicatorStates.get(indicatorId);
  }

  getIndicatorLabel(
    status: RiskAssessmentReportIndicatorStatusEnum,
    mitigationsCompleted: boolean,
  ) {
    return `${this.riskAssessmentIndicatorStatusOptions.find((r) => r.value === status).label} ${mitigationsCompleted ? "(mitigated)" : ""}`.trim();
  }
}
