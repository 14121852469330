import { ChangeDetectionStrategy, Component } from "@angular/core";

import { IHeaderAngularComp } from "ag-grid-angular";

@Component({
  standalone: false,
  template: `
    <div class="container-flex">
      {{ params["title"] }}
      @if (params["icon"]) {
        <mat-icon
          class="col-header-icon"
          [svgIcon]="params['icon']"
          [tp]="params['iconTooltip'] ?? ''"
        ></mat-icon>
      }
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnHeaderRendererComponent implements IHeaderAngularComp {
  public params!: any;

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }
}
