import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { IItemDetails, IProductExtended } from "src/app/shared/interfaces";

import { IItemQuantityDialogPayload } from "@components/index";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  templateUrl: "./edit-item-quantity-dialog.component.html",
  styleUrls: ["./edit-item-quantity-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditItemQuantityDialogComponent implements OnInit {
  public item: IItemDetails;

  public allProducts: IProductExtended[] = [];

  public formGroup: UntypedFormGroup;

  public isLoading = signal(true);

  public readonly translations: any = {
    remainingQtyLabel: $localize`Remaining quantity`,
    deliveredQtyLabel: $localize`Delivery quantity`,
  };

  constructor(
    public dialogRef: MatDialogRef<EditItemQuantityDialogComponent>,
    private unitConversionPipe: UnitConversionPipe,
    @Inject(MAT_DIALOG_DATA) public data: { item: IItemDetails; allProducts: IProductExtended[] },
  ) {
    this.item = data.item;

    this.allProducts = data.allProducts;
  }

  public async ngOnInit(): Promise<void> {
    const { item, allProducts } = this;

    let itemDeliveredQuantity = item.deliveredQuantity;
    let itemInitialQuanitity = item.initialQuantity;
    let remainingQuantity = item.remainingQuantity;
    const product = allProducts.find((p) => p.id === item.productId);

    if (product?.defaultCustomUnit) {
      itemDeliveredQuantity = this.transformQuantity(item, itemDeliveredQuantity);
      itemInitialQuanitity = this.transformQuantity(item, itemInitialQuanitity);
      remainingQuantity = this.transformQuantity(item, remainingQuantity);
    }

    this.formGroup = new UntypedFormGroup({
      remainingQty: new UntypedFormControl({ value: remainingQuantity, disabled: true }),
      deliveredQty: new UntypedFormControl(itemDeliveredQuantity ?? itemInitialQuanitity, [
        CustomValidators.required,
        CustomValidators.greaterThan(0),
      ]),
    });

    this.isLoading.set(false);
  }

  private transformQuantity(item: IItemDetails, quantity: number): number {
    return +this.unitConversionPipe.transform(
      `${quantity}`,
      item.productDefaultUnit,
      item.unitOfMeasurement,
      false,
      false,
      true,
    );
  }

  public get isSubmitButtonDisabled(): boolean {
    return this.formGroup.invalid || this.formGroup.pristine;
  }

  public onSubmit = async (): Promise<void> => {
    const data: IItemQuantityDialogPayload = {
      id: this.item.id,
      deliveredQuantity: Number(this.formGroup.get("deliveredQty").value),
    };

    this.dialogRef.close({ hasSaved: true, data });
  };

  public onClose = (hasSaved = false): void => {
    this.dialogRef.close({ hasSaved });
  };
}
