import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { CustomValidators } from "src/app/shared/validators";

import { InputChipsComponent } from "@components/shared/inputs/input-chips/input-chips.component";
import { InputSelectComponent } from "@components/shared/inputs/input-select/input-select.component";
import { TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  selector: "app-tags-selector-dialog",
  templateUrl: "./tags-selector-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsSelectorDialogComponent {
  @ViewChild("inputChips") inputChips: InputChipsComponent;

  public isNewTagCreated: boolean;

  public readonly translations: any = {
    tagsHint: $localize`Select from the list or type it in and hit enter to add a new type`,
    tagsTooltip: TextConstants.LOCATION_TYPES_TOOLTIP,
  };

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<InputSelectComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      tagUrl: string;
      options: string;
      placeholder: string;
      canDelete: boolean;
      title: string;
      isNewValueAllowed: boolean;
      selectedIds: string[];
    },
  ) {
    this.form = this.formBuilder.group({
      tags: [this.data.selectedIds, [CustomValidators.required]],
    });
  }

  close() {
    this.dialogRef.close({ hasSaved: false });
  }

  async save() {
    const unsavedTags = this.form.get("tags").value.filter((item) => !item.value);

    if (unsavedTags.length) {
      for (let i = 0; i < unsavedTags.length; i++) {
        const item = unsavedTags[i];

        await this.inputChips.createTag(item, true);
      }
      this.isNewTagCreated = true;
    }
    this.dialogRef.close({
      hasSaved: true,
      selectedIds: this.form.get("tags").value.map((tag) => tag.value),
      isNewTagCreated: this.isNewTagCreated,
    });
  }
}
