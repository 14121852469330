import { Injectable, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { CountriesApiService } from "@components/shared/countries/api";
import { RelatedRiskAssessmentReportsTableModel } from "@components/shared/risk-assessment-reports/models";
import { ILocationLinkExtended, IMaterial } from "@shared/interfaces";
import {
  AuthenticationService,
  CommonService,
  LocationsService,
  ProductsService,
} from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class RelatedProductsRiskAssessmentReportsService {
  private readonly commonService = inject(CommonService);

  private readonly authService = inject(AuthenticationService);

  private readonly productsService = inject(ProductsService);

  private readonly locationsService = inject(LocationsService);

  private readonly countriesApi = inject(CountriesApiService);

  private allCountriesMap: Map<string, string>;

  readonly tableParams = signal<RelatedRiskAssessmentReportsTableModel.Params>(null);

  constructor() {
    this.commonService.countriesOptionsObservable$
      .pipe(takeUntilDestroyed())
      .subscribe(
        (countries) =>
          (this.allCountriesMap = new Map(
            countries.map((country) => [country.value.toString(), country.label]),
          )),
      );
  }

  async setRelatedRiskAssessmentReportTableParams(
    productId: string,
    productMaterials: IMaterial[],
  ): Promise<void> {
    const orgId = this.authService.getActiveOrganisationId();

    const relatedProducts = (
      await this.productsService.getProductSupplyChain(orgId, productId, ["FULL_LOCATION_LINKS"])
    ).loadProductSupplyChain?.products
      .map(({ product }) => product)
      .filter(({ id }) => id !== productId);

    const locationLinks: ILocationLinkExtended[] = Object.values(
      relatedProducts.reduce((acc, { locationLinks }) => {
        locationLinks.forEach((location) => {
          acc[location.id] = location;
        });

        return acc;
      }, {}),
    );

    const countryCodes = [
      ...new Set(
        locationLinks.flatMap(({ from, to }) => [from.address.country, to.address.country]),
      ),
    ];

    // TODO: use a new API to get all Countries by codes
    const countries: { id: string; name: string }[] = await Promise.all(
      countryCodes.map(async (code) => {
        const { content } = await this.countriesApi.getAll({ code, size: 1 });

        return content.length
          ? { id: content[0].id, name: content[0].name }
          : { id: null, name: this.allCountriesMap.get(code) };
      }),
    );

    const locations = await this.locationsService.getByIdsGraphQL(
      [...new Set(locationLinks.flatMap(({ from, to }) => [from.id, to.id]))],
      undefined,
    );

    const organisations: { id: string; name: string }[] = Array.from(
      new Map(
        locations.map(({ organisationId, organisationName }) => [
          organisationId,
          { id: organisationId, name: organisationName },
        ]),
      ).values(),
    );

    const mapMaterial = (material: IMaterial): [string, { id: string; name: string }] => {
      return [material.id, { id: material.id, name: material.name }];
    };

    const materials = Array.from(
      new Map([
        ...productMaterials.map((material) => mapMaterial(material)),
        ...relatedProducts.flatMap((product) =>
          product.allowedMaterials.map((material) => mapMaterial(material)),
        ),
      ]).values(),
    );

    const baseUrl = `/organisations/${orgId}`;

    this.tableParams.set({
      records: [
        ...countries.map((country) => ({
          type: RelatedRiskAssessmentReportsTableModel.RecordType.COUNTRIES,
          id: country?.id,
          label: country?.name,
          uri: country?.id ? `${baseUrl}/countries/${country.id}` : null,
        })),

        ...organisations.map((org) => ({
          type: RelatedRiskAssessmentReportsTableModel.RecordType.ORGANISATIONS,
          id: org.id,
          label: org.name,
          uri: `${baseUrl}/connections/${org.id}`,
        })),

        ...locations.map((location) => ({
          type: RelatedRiskAssessmentReportsTableModel.RecordType.LOCATIONS,
          id: location.id,
          label: location.name,
          uri: `${baseUrl}/locations/${location.id}`,
        })),

        ...materials.map((material) => ({
          type: RelatedRiskAssessmentReportsTableModel.RecordType.MATERIALS,
          id: material.id,
          label: material.name,
          uri: `${baseUrl}/materials/${material.id}`,
        })),

        ...relatedProducts.map((product) => ({
          type: RelatedRiskAssessmentReportsTableModel.RecordType.PRODUCTS,
          id: product.id,
          label: product.name,
          uri: `${baseUrl}/products/${product.id}`,
        })),
      ],
    });
  }
}
