import { getProductFields } from "./queries.constants";
import { QueriesUtils } from "./queries.utils";
import { IItemGraphQLFilter } from "../interfaces";

/**
 * Generates a GraphQL query to load items based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which items are queried.
 * @param {IItemGraphQLFilter} filter - An object containing filtering criteria for items.
 * @param {number} first - The number of items to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of items to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading items.
 */

export const getItemsGraphQLQuery = (
  orgId: string,
  filter: IItemGraphQLFilter,
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const filterClause = QueriesUtils.generateFilterClause(filter);
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadItems(   
    orgId: "${orgId}",
    ${filterClause ? `${filterClause},` : ""}
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {
    ${getItemProperties(include, ["DEFAULT_CUSTOM_UNIT", "MATERIALS"])}       
    }
}
`;
};

/**
 * Generates a GraphQL query to load items based on specified parameters.
 *
 * @param {string} orgId - (Required) The organization ID for which items are queried.
 * @param {string[]} ids - (Required) A list of items ids
 * @param {number} first - The number of items to retrieve in a single page (for forward pagination).
 * @param {number} last - The number of items to retrieve in a single page (for backward pagination).
 * @param {string} after - The cursor indicating the end of the previous page for forward pagination.
 * @param {string} before - The cursor indicating the start of the next page for backward pagination.
 * @param {string[]} include - Additional fields to include in the query.
 * @returns {string} The generated GraphQL query for loading items.
 */

export const getItemsByIdsGraphQLQuery = (
  orgId: string,
  ids: string[],
  first: number,
  last: number,
  after: string,
  before: string,
  include: string[],
): string => {
  const afterCursorClause = first > 0 ? QueriesUtils.generateClause("after", after) : "";
  const firstClause = first > 0 ? QueriesUtils.generateClause("first", first) : "";
  const beforeCursorClause = last > 0 ? QueriesUtils.generateClause("before", before) : "";
  const lastClause = last > 0 ? QueriesUtils.generateClause("last", last) : "";

  return `
{
    loadItemsByIds(   
    orgId: "${orgId}",
    ids: ${QueriesUtils.getParsedIds(ids)},
    ${afterCursorClause ? `${afterCursorClause},` : ""}
    ${beforeCursorClause ? `${beforeCursorClause},` : ""}
    ${firstClause ? `${firstClause},` : ""}
    ${lastClause ? `${lastClause},` : ""}
) {       
  ${getItemProperties(include, ["DEFAULT_CUSTOM_UNIT"])}
    }
}
`;
};

export const getItemProperties = (include: string[], productFieldsInclude: string[]): string => `
        pageInfo {
            hasNextPage 
            hasPreviousPage
            startCursor
            endCursor
        }
        totalCount
        edges {
            cursor
            node {
                id
                itemId
                initialQuantity
                remainingQuantity
                createdAtLocation {
                    id
                    name
                }
                currentLocation {
                    id
                    name
                }
                materials {
                    id
                    name
                    category
                }
                created {
                    ... on ExactDateTime {
                        on
                    }
                    ... on DateTimeRange {
                        start
                        end
                    }
                }
                product ${getProductFields(productFieldsInclude)}
                recordState
                ${QueriesUtils.getQueryIncludes(include)}
            }            
        }
`;
