import { HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectionStrategy, Component, signal } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { FeatureFlagEnum, RouteEnum } from "src/app/shared/enums";
import { AuthService, FeatureFlagService } from "src/app/shared/services";

import { TextConstants } from "@shared/constants";
import { NotificationService } from "@shared/services";

import { CustomValidators } from "../../../shared/validators";

@Component({
  standalone: false,
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent {
  public formGroup: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(null, [CustomValidators.required, CustomValidators.email]),
  });

  public readonly routingEnum = RouteEnum;

  public currentStep = "initial";

  public isLoading = signal(false);

  public readonly translations: any = {
    emailLabel: TextConstants.EMAIL,
  };

  readonly isLanguagesEnabled = this.featureFlagService.isEnabled(FeatureFlagEnum.LANGUAGES);

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {
    const navState = this.router.getCurrentNavigation()?.extras?.state;

    if (navState && navState["email"]) {
      this.formGroup.controls["email"].setValue(navState["email"]);
    }
  }

  public onChangeStep = (step: string): void => {
    this.currentStep = step;
  };

  get isSubmitButtonDisabled(): boolean {
    return this.formGroup.invalid;
  }

  public onSubmit = async (): Promise<void> => {
    this.isLoading.set(true);

    const payload = {
      email: this.formGroup.controls["email"].value.trim(),
    };

    await this.authService
      .forgotPassword(payload)
      .then(() => {
        this.notificationService.showSuccess($localize`Success`);
        this.onChangeStep("email-sent");
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      })
      .finally(async () => {
        this.isLoading.set(false);
      });
  };
}
