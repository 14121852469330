<div class="container-space-between">
  <app-page-title [title]="translations.title"></app-page-title>
</div>

<ng-container
  [ngTemplateOutletContext]="{ settings: userGeneralSettings }"
  [ngTemplateOutlet]="settingsTemplate"
></ng-container>

<ng-template #settingsTemplate let-settings="settings">
  <div class="settings-container flex-wrap">
    @for (setting of settings; track $index) {
      <app-settings-card (pressed)="onGoToRoute(setting.route)" [setting]="setting" />
    }
  </div>
</ng-template>
