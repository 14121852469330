import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";

import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { ILocationExtended } from "@shared/interfaces";

@Component({
  standalone: false,
  selector: "app-delivery-report-supply-chain-entity",
  templateUrl: "./delivery-report-supply-chain-entity.component.html",
  styleUrls: ["./delivery-report-supply-chain-entity.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportSupplyChainEntityComponent implements OnInit {
  @Input()
  public locations: ILocationExtended[];

  public countryName: string;

  public country: string;

  public locationTypesTags: InputSelectOption[];

  public ngOnInit(): void {
    this.countryName = this.locations[0].address.countryName;
    this.country = this.locations[0].address.country;

    const locationTypesTags = this.locations.flatMap((location) =>
      location.types.map((type) => {
        return { label: type.type, icon: type.pointOfOrigin ? "target" : null };
      }),
    );

    this.locationTypesTags = locationTypesTags.filter(
      (type, index, self) => self.findIndex((t) => t.label === type.label) === index,
    );
  }
}
