export enum FileExtensionEnum {
  PDF = "PDF",
  PNG = "PNG",
  JPG = "JPG",
  JPEG = "JPEG",
  DOC = "DOC",
  DOCX = "DOCX",
  XLS = "XLS",
  XLSX = "XLSX",
  PPT = "PPT",
  PPTX = "PPTX",
  CSV = "CSV",
  TXT = "TXT",
  GEOJSON = "GEOJSON",
  JSON = "JSON",
}
