import { GridApi } from "ag-grid-community";

const getFirstElementShownNumber = (gridApi: GridApi<any>): number =>
  gridApi.paginationGetPageSize() * gridApi.paginationGetCurrentPage() +
  (gridApi.paginationGetRowCount() ? 1 : 0);

const geLastElementShownNumber = (gridApi: GridApi<any>): number =>
  Math.min(
    gridApi.paginationGetRowCount(),
    gridApi.paginationGetPageSize() * (gridApi.paginationGetCurrentPage() + 1),
  );

const getCellHtmlElement = (rowIndex: number, field: string): HTMLElement | null => {
  return document.querySelector(`.ag-row[row-index="${rowIndex}"] .ag-cell[col-id="${field}"]`);
};

const updateCellValidationState = (cellHtmlElement: HTMLElement | null, isValid: boolean): void => {
  if (!cellHtmlElement) {
    return;
  }

  if (isValid) {
    cellHtmlElement.classList.remove("invalid-cell");
  } else {
    cellHtmlElement.classList.add("invalid-cell");
  }
};

export const TableUtils = {
  getFirstElementShownNumber,
  geLastElementShownNumber,
  getCellHtmlElement,
  updateCellValidationState,
};
