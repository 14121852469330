import { IGeoLocation } from "@shared/interfaces";

import { GeoJsonTypeEnum } from "../enums";

const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;

const isValidCoordinates = (lat: string, lon: string): boolean =>
  isValidLatitude(lat) && isValidLongitude(lon);

const isValidLatitude = (lat: string): boolean => regexLat.test(lat);

const isValidLongitude = (lon: string): boolean => regexLon.test(lon);

const getObject = (type: string, coordinates: any[]): any => ({
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type,
        coordinates,
      },
      properties: {
        name: type,
      },
    },
  ],
});

const isValid = (geojson: any): boolean => {
  const supportedTypes = [
    GeoJsonTypeEnum.POINT,
    GeoJsonTypeEnum.POLYGON,
    GeoJsonTypeEnum.MULTI_POLYGON,
  ];

  if (geojson?.type === "FeatureCollection" && geojson.features?.length) {
    const featureTypes = geojson.features.map((feature: any) => feature.geometry?.type);

    if (!featureTypes.every((type) => supportedTypes.includes(type))) {
      return false;
    }

    const hasPoint = featureTypes.includes(GeoJsonTypeEnum.POINT);
    const hasPolygonOrMultiPolygon = featureTypes.some(
      (type) => type === GeoJsonTypeEnum.POLYGON || type === GeoJsonTypeEnum.MULTI_POLYGON,
    );

    if (hasPoint && hasPolygonOrMultiPolygon) {
      return false;
    }

    return true;
  }

  if (geojson?.geometry?.type) {
    const type = geojson.geometry.type;

    if (!supportedTypes.includes(type)) {
      return false;
    }

    return true;
  }

  return false;
};

const isValidPolygonOrMultiPolygon = (geojson: IGeoLocation): boolean =>
  isValid(geojson) &&
  geojson.features?.length &&
  [GeoJsonTypeEnum.POLYGON, GeoJsonTypeEnum.MULTI_POLYGON].includes(
    geojson.features[0].geometry?.type,
  );

const isPolygon = (geojson: IGeoLocation): boolean =>
  geojson?.features?.length && geojson.features[0].geometry?.type === GeoJsonTypeEnum.POLYGON;

const isPoint = (geojson: IGeoLocation): boolean =>
  geojson?.features[0]?.geometry?.type === GeoJsonTypeEnum.POINT &&
  !!geojson?.features[0]?.geometry?.coordinates?.length;

const wgs84 = {
  RADIUS: 6371008.8,
};

function rad(degrees: number): number {
  return (degrees * Math.PI) / 180;
}

/**
 * This method calculates the area of a geodetic ring in square meters.
 * This method is based on the algorithm described in:
 * Robert G. Chamberlain and William H. Duquette, "Some Algorithms for
 * Polygons on a Sphere", JPL Publication 07-03.
 *
 * @param coords - Coordinates of the ring.
 * @returns The area of the ring in square meters.
 */
function ringArea(coords: number[][]): number {
  let area = 0;
  const coordsLength = coords.length;

  if (coordsLength > 2) {
    for (let i = 0; i < coordsLength; i++) {
      let lowerIndex, middleIndex, upperIndex;

      if (i === coordsLength - 2) {
        lowerIndex = coordsLength - 2;
        middleIndex = coordsLength - 1;
        upperIndex = 0;
      } else if (i === coordsLength - 1) {
        lowerIndex = coordsLength - 1;
        middleIndex = 0;
        upperIndex = 1;
      } else {
        lowerIndex = i;
        middleIndex = i + 1;
        upperIndex = i + 2;
      }

      const p1 = coords[lowerIndex];
      const p2 = coords[middleIndex];
      const p3 = coords[upperIndex];

      area += (rad(p3[0]) - rad(p1[0])) * Math.sin(rad(p2[1]));
    }

    area = (area * wgs84.RADIUS * wgs84.RADIUS) / 2;
  }

  return area;
}

function polygonArea(coords: number[][][]): number {
  let area = 0;

  if (coords && coords.length > 0) {
    area += Math.abs(ringArea(coords[0]));
    for (let i = 1; i < coords.length; i++) {
      area -= Math.abs(ringArea(coords[i]));
    }
  }

  return area;
}
/**
 * This method calculates the total area of a polygon or multipolygon
 * based on a GeoJSON object in square meters.
 *
 * @param geojson - GeoJSON object representing a polygon or multipolygon.
 * @returns The total area in square meters.
 */
const geometry = (geojson: any): number => {
  let area = 0;

  switch (geojson.type) {
    case "Polygon":
      return polygonArea(geojson.coordinates);
    case "MultiPolygon":
      for (let i = 0; i < geojson.coordinates.length; i++) {
        area += polygonArea(geojson.coordinates[i]);
      }

      return area;
    case "Point":
    case "MultiPoint":
    case "LineString":
    case "MultiLineString":
      return 0;
    case "GeometryCollection":
      for (let i = 0; i < geojson.geometries.length; i++) {
        area += geometry(geojson.geometries[i]);
      }

      return area;
  }

  return area;
};

/**
 * Determines the geometry type of a GeoJSON object.
 *
 * @param geojson - The GeoJSON object to evaluate.
 * @returns The geometry type as a GeoJsonTypeEnum, or null if not valid.
 */
const determineGeometryType = (geojson: any): GeoJsonTypeEnum | null => {
  const features = geojson?.features || [];

  if (features.length === 0) {
    return null;
  }

  const geometryType = geojson.geometry?.type || features[0]?.geometry?.type;

  if (geometryType === GeoJsonTypeEnum.POINT) {
    const allPoints = features.every((feature) => feature.geometry?.type === GeoJsonTypeEnum.POINT);

    return allPoints && features.length > 1 ? GeoJsonTypeEnum.MULTI_POINT : GeoJsonTypeEnum.POINT;
  } else if (geometryType === GeoJsonTypeEnum.POLYGON) {
    const allPolygons = features.every(
      (feature) => feature.geometry?.type === GeoJsonTypeEnum.POLYGON,
    );

    return allPolygons && features.length > 1
      ? GeoJsonTypeEnum.MULTI_POLYGON
      : GeoJsonTypeEnum.POLYGON;
  } else if (geometryType === GeoJsonTypeEnum.MULTI_POLYGON) {
    const allMultiPolygons = features.every(
      (feature) => feature.geometry?.type === GeoJsonTypeEnum.MULTI_POLYGON,
    );

    return allMultiPolygons ? GeoJsonTypeEnum.MULTI_POLYGON : null;
  }

  return null;
};

const convertGeometryCollectionToFeatureCollection = (geojson: any): any => {
  if (
    geojson?.type === "FeatureCollection" &&
    geojson.features?.length &&
    geojson.features.some((feature) => feature.geometry?.type === "GeometryCollection")
  ) {
    const feature = geojson.features[0];
    const newFeatures = feature.geometry.geometries.map((geometry: any) => ({
      type: "Feature",
      properties: { ...feature.properties },
      geometry: geometry,
    }));

    return {
      type: "FeatureCollection",
      features: newFeatures,
    };
  }

  return geojson;
};

const GeoJsonError = {
  UNSUPPORTED_GEOMETRY_TYPE: $localize`The location's geo data contains unsupported geometry types. Supported types: Point, Polygon, MultiPolygon`,
  UNSUPPORTED_GEOMETRY_TYPE_COMBINATION: $localize`The location's geo data contains unsupported combination of geometry types. Can only have one Point or one / multiple Polygons / MultiPolygons`,
  DEFAULT: $localize`File not supported`,
};

const getGeoJsonError = (geojson: any): string => {
  const supportedTypes = [
    GeoJsonTypeEnum.POINT,
    GeoJsonTypeEnum.POLYGON,
    GeoJsonTypeEnum.MULTI_POLYGON,
  ];

  if (geojson?.type === "GeometryCollection") {
    return GeoJsonError.UNSUPPORTED_GEOMETRY_TYPE;
  }

  if (geojson?.type === "FeatureCollection" && geojson.features?.length) {
    const types = geojson.features.map((feature: any) => feature.geometry?.type);

    if (!types.every((type) => supportedTypes.includes(type || ""))) {
      return GeoJsonError.UNSUPPORTED_GEOMETRY_TYPE;
    }

    const uniqueTypes = Array.from(new Set(types));

    if (
      uniqueTypes.includes(GeoJsonTypeEnum.POINT) &&
      (uniqueTypes.includes(GeoJsonTypeEnum.POLYGON) ||
        uniqueTypes.includes(GeoJsonTypeEnum.MULTI_POLYGON))
    ) {
      return GeoJsonError.UNSUPPORTED_GEOMETRY_TYPE_COMBINATION;
    }
  }

  if (geojson?.geometry?.type) {
    const type = geojson.geometry.type;

    if (!supportedTypes.includes(type)) {
      return GeoJsonError.UNSUPPORTED_GEOMETRY_TYPE;
    }
  }

  return GeoJsonError.DEFAULT;
};

export const GeoJSONUtils = {
  isValidCoordinates,
  isValidLatitude,
  isValidLongitude,
  getObject,
  isValid,
  isValidPolygonOrMultiPolygon,
  isPolygon,
  isPoint,
  geometry,
  determineGeometryType,
  getGeoJsonError,
  convertGeometryCollectionToFeatureCollection,
};
