<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>

  <app-button-group>
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [mat-stretch-tabs]="false"
  [selectedIndex]="selectedTabIndex()"
  (selectedIndexChange)="selectedTabIndex.set($event)"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Existing</ng-container>

      <app-indicator [value]="existingConnectionsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-settings-existing-connections-table
      #existingConnectionsTable
      [isBatchActionsEnabled]="true"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Requested</ng-container>

      <app-indicator [value]="requestedConnectionsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-settings-requested-connections-table
      #requestedConnectionsTable
      [isBatchActionsEnabled]="true"
    />
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Received</ng-container>

      <app-indicator [value]="receivedConnectionsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-settings-received-connections-table
      #receivedConnectionsTable
      [activeOrganisationId]="activeOrganisationId"
      [countryOptions]="countryOptions()"
      [isBatchActionsEnabled]="true"
      (requestAccepted)="onRequestAccepted()"
    />
  </mat-tab>
</mat-tab-group>
