<div class="row-container">
  <form [formGroup]="formGroup">
    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>{{ mainInformationText }}</h3>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row row-2 bottom-row">
          <div class="item">
            <app-input formControlName="name" [label]="translations.nameLabel"></app-input>
          </div>
          <div class="item">
            <app-input
              formControlName="description"
              [label]="translations.descriptionLabel"
            ></app-input>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              <ng-container i18n>Criteria</ng-container>
              <mat-icon class="info-icon" [tp]="translations.criteriaTp">help</mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <p class="dark-gray no-margin-top">
          <small i18n>
            Specifying no criteria will make the ruleset applicable to all locations.
          </small>
        </p>
        <app-location-criteria-info
          #locationCriteria
          (newLocationTypeCreated)="newLocationTypeCreated.emit()"
          [allCountriesOption]="allCountriesOption"
          [formGroup]="formGroup"
        ></app-location-criteria-info>
      </mat-expansion-panel>
    </mat-accordion>

    <mat-accordion>
      <mat-expansion-panel [expanded]="true" class="mat-elevation-z0 section-box">
        <mat-expansion-panel-header class="section-header">
          <mat-panel-title class="section-title">
            <h3>
              <ng-container i18n>Expectations</ng-container>

              <mat-icon class="info-icon" [tp]="translations.expectationsTp">help</mat-icon>
            </h3>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <h4 class="dark-gray no-margin-top">
          <ng-container i18n>Document types</ng-container>

          <app-button
            (pressed)="openDocumentTypeDialog.emit()"
            class="icon-add-circle"
            type="icon"
            [tp]="translations.addDocumentTypeTp"
          >
            <mat-icon icon-medium-18>add</mat-icon>
          </app-button>
        </h4>
        <p class="dark-gray">
          <small i18n>
            Specify which documents should be attached to a location based on the ruleset criteria.
            At least one should be specified.
          </small>
        </p>
        <ng-content></ng-content>
      </mat-expansion-panel>
    </mat-accordion>
  </form>
</div>
