import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditStandardDialogComponent } from "src/app/components/settings";

import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
  TableEnum,
} from "@shared/enums";
import { IStandard, IStandardWithTypes } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, StandardTypesService, StandardsService } from "@shared/services";
import { ColumnUtils } from "@shared/utils";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  standalone: false,
  selector: "app-standards-table",
  templateUrl: "./standards-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardsTableComponent implements AfterViewInit {
  @Input()
  public standards: IStandardWithTypes[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.ADMIN_STANDARDS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: IStandardWithTypes[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private standardsService: StandardsService,
    private standardTypesService: StandardTypesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.standardsService, true),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.UNARCHIVE,
            this.standardsService,
            true,
          ),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        suppressSizeToFit: false,
        suppressAutoSize: false,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onToggleArchiveStatus,
                  tooltip: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE
                      ? TextConstants.ARCHIVE
                      : TextConstants.UNARCHIVE,
                  icon: (row) =>
                    row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory",
                },
                {
                  click: this.onEdit,
                  tooltip: TextConstants.EDIT,
                  icon: "edit",
                },
              ]
            : [],
        },
      },
      ColumnUtils.chips("Standard types", "typesNames"),
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  public onEdit = async (standard: IStandardWithTypes): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (standard.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentText: TextConstants.EDIT_ARCHIVED_RECORD_ERROR,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditStandardDialogComponent, {
      data: {
        standard,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IStandardWithTypes): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.standardsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  private getParsedRowData = async (standards: IStandard[]): Promise<IStandardWithTypes[]> => {
    const result = [];

    const promises = standards.map(async (standard) => {
      const standardTypes = await this.standardTypesService.getPage(standard.id);
      const typesNames = standardTypes.map((s) => s.fullName);

      result.push({ ...standard, standardTypes, typesNames });
    });

    await Promise.all(promises);

    return result;
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.standards) {
      this.rowData = await this.getParsedRowData(this.standards);
      this.isLoading.set(false);
    } else {
      try {
        const standards = await this.standardsService.getAll();

        this.rowData = await this.getParsedRowData(standards);
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
