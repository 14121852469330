import { ChangeDetectionStrategy, Component, inject, input, signal, OnInit } from "@angular/core";

import { CountriesApiService } from "@components/shared/countries/api";
import { ISelectOption } from "@shared/interfaces";
import { AuthenticationService } from "@shared/services";
import { NavigationParams, RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-country",
  templateUrl: "./country.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryComponent implements OnInit {
  option = input<ISelectOption>();

  public link = signal<NavigationParams>(undefined);

  private countriesApiService = inject(CountriesApiService);

  private routerService = inject(RouterService);

  private authenticationService = inject(AuthenticationService);

  private readonly isRegularUser = this.authenticationService.isRegularUser();

  async ngOnInit(): Promise<void> {
    if (this.isRegularUser && this.option()) {
      const countriesResponse = await this.countriesApiService.getAll({
        code: this.option().value.toString(),
      });
      const organisationCountry = countriesResponse.content[0];

      if (organisationCountry) {
        this.link.set(
          this.routerService.getCountryLink(organisationCountry.id, false) as NavigationParams,
        );
      }
    }
  }
}
