<div class="slide-over-content">
  @if (element.validityStatus === "INVALID") {
    <div class="invalid-report text-center bold" i18n>This report is no longer valid</div>
  }
  @if (isLoading()) {
    <app-loader></app-loader>
  }
  @if (analysisSafeUrl()) {
    <iframe
      #iframe
      [src]="analysisSafeUrl()"
      [style.opacity]="isLoading() ? '0' : '1'"
      allow="fullscreen"
      frameBorder="0"
      height="99%"
      width="100%"
    ></iframe>
  }
</div>
<div class="slide-over-buttons">
  <app-button-group>
    <app-button (pressed)="onFullscreen()" type="ghost">
      <mat-icon icon-left>open_in_full</mat-icon>
      <ng-container i18n>Full screen</ng-container>
    </app-button>
  </app-button-group>
</div>
