import { Injectable } from "@angular/core";

import { ToastrService } from "ngx-toastr";

import {
  NotificationFailureComponent,
  NotificationSuccessComponent,
} from "@components/shared/notification";
import { BackendErrorConstants, CommonConstants, TextConstants } from "@shared/constants";
import { NotificationError } from "@shared/services";

@Injectable({
  providedIn: "root",
})
export class NotificationService {
  constructor(private notification: ToastrService) {}

  public showSuccess(message: string): void {
    this.notification.success(message, undefined, {
      toastComponent: NotificationSuccessComponent,
      timeOut: CommonConstants.NOTIFICATION_DURATION_MS,
    });
  }

  public showError(error: NotificationError | string, isFetchingEntity: boolean = false): void {
    const message = this.getFormattedErrorMessage(error, isFetchingEntity);

    this.notification.error(message, undefined, {
      toastComponent: NotificationFailureComponent,
      timeOut: CommonConstants.NOTIFICATION_ERROR_DURATION_MS,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private isRecordNotFoundError = (error: any): boolean =>
    error.status === 404 ||
    (error.status === 400 &&
      error?.error &&
      error.error.detail?.startsWith("Failed to convert") &&
      error.error.instance &&
      error.error.type === "about:blank");

  private getFormattedErrorMessage(
    error: NotificationError | string,
    isFetchingEntity: boolean = false,
  ): string {
    let message: string = $localize`An unexpected error happened. Please try again later`;

    if (typeof error === "string") {
      message = error;
    } else {
      if (isFetchingEntity && this.isRecordNotFoundError(error)) {
        message = $localize`Record not found`;
      } else {
        switch (error?.status) {
          case 0:
          case 503:
            message = TextConstants.SERVICE_UNAVAILABLE;
            break;
          case 403:
            message = $localize`Your user profile has no permissions for this`;
            break;
          default: {
            const errorMessages = error?.error?.errors;

            if (errorMessages?.length) {
              const errorCode = errorMessages[0].code;

              if (BackendErrorConstants[errorCode]) {
                message = BackendErrorConstants[errorCode];
              } else {
                message = errorMessages[0].message || message;
              }
            }
            break;
          }
        }
      }
    }

    if (message === "Invalid JWT") {
      message = TextConstants.SESSION_HAS_EXPIRED;
    }

    return message;
  }
}
