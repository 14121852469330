import {
  HostListener,
  OnDestroy,
  signal,
  Component,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { debounceTime, Subject, Subscription } from "rxjs";
import { ConfirmDialogResponseEnum } from "src/app/shared/enums";

import { FlowChartTypeEnum } from "@components/shared/fullscreen-supply-chain-dialog/fullscreen-supply-chain-dialog.model";
import { CommonConstants, TextConstants } from "@shared/constants";

@Component({
  standalone: false,
  templateUrl: "./fullscreen-supply-chain-dialog.component.html",
  styleUrls: ["./fullscreen-supply-chain-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenSupplyChainDialogComponent implements OnDestroy {
  public supplyChainHeight;

  public isOpened = signal<boolean>(false);

  public flowChartType: FlowChartTypeEnum = FlowChartTypeEnum.SUPPLY_CHAIN;

  public readonly flowChartTypeEnum = FlowChartTypeEnum;

  private autoSizeSubject = new Subject();

  private subscriptions = new Subscription();

  public readonly translations: any = {
    closeTp: TextConstants.CLOSE,
  };

  constructor(
    public dialogRef: MatDialogRef<FullScreenSupplyChainDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.setSupplyChainHeight();
    this.flowChartType = data.flowChartType || this.flowChartType;

    this.subscriptions.add(
      this.autoSizeSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SUPPLY_CHAIN_RESIZE_TIME_MS))
        .subscribe(() => this.adjustSize()),
    );
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    this.autoSizeSubject.next(true);
  }

  public setIsOpened(isOpened: boolean): void {
    this.isOpened.set(isOpened);
    this.autoSizeSubject.next(true);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClose(): void {
    this.dialogRef.close(ConfirmDialogResponseEnum.CANCEL);
  }

  private setSupplyChainHeight = (): void => {
    this.supplyChainHeight = window.innerHeight - 80;
  };

  private adjustSize(): void {
    const width = window.innerWidth + "px";
    const height = window.innerHeight - 25 + "px";

    this.dialogRef.updateSize(width, height);
    this.setSupplyChainHeight();
    this.changeDetectorRef.detectChanges();
  }
}
