import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
} from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { Subscription } from "rxjs";
import {
  IBaseUnit,
  IItem,
  IItemDetails,
  IMaterial,
  IProductExtended,
  ISelectOption,
} from "src/app/shared/interfaces";
import { CommonUtils } from "src/app/shared/utils";
import { CustomValidators } from "src/app/shared/validators";

import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { ItemCardContentDisplayTypeEnum } from "@components/shared/cards/item-card-content/item-card-content.model";
import { TextConstants } from "@shared/constants";
import { UnitConversionPipe } from "@shared/pipes/unit-conversion.pipe";
import { UnitsOfMeasurementService } from "@shared/services/api";

@Component({
  standalone: false,
  selector: "app-add-process-selected-items",
  templateUrl: "./add-process-selected-items.component.html",
  styleUrls: ["./add-process-selected-items.component.scss"],
  providers: [UnitConversionPipe],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AddProcessSelectedItemsComponent implements OnChanges, OnDestroy {
  @Input()
  public type: string;

  @Input()
  public processLocation: ISelectOption;

  @Input()
  public items: IItemDetails[];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public canRemove = true;

  @Output()
  public remove: EventEmitter<string> = new EventEmitter();

  @Output()
  public selectedQuantityChanged: EventEmitter<IItem> = new EventEmitter();

  readonly contentCardTypeEnum = CardContentTypeEnum;

  readonly itemCardContentDisplayTypeEnum = ItemCardContentDisplayTypeEnum;

  public isLoading = signal(true);

  private subscriptions = new Subscription();

  public readonly translations: any = {
    selectedQtyLabel: TextConstants.SELECTED_QTY,
    remainingQtyLabel: $localize`Remaining q-ty`,
    initialQtyLabel: TextConstants.INITIAL_QTY,
  };

  constructor(
    public unitsOfMeasurementService: UnitsOfMeasurementService,
    private unitConversionPipe: UnitConversionPipe,
  ) {}

  public async ngOnChanges(): Promise<void> {
    this.isLoading.set(true);
    if (this.items?.length) {
      this.items = CommonUtils.getItemsWithProductAndAssignedUnit(this.items, this.allProducts);

      this.items = CommonUtils.getElementsWithMaterialNames(
        this.allMaterials,
        this.items,
        "materials",
      );
      for (const item of this.items) {
        if (!item.selectedQuantity) {
          item.selectedQuantity = item.initialQuantity;
        }
        let itemInitialQuantity = item.initialQuantity;
        let itemSelectedQuantity = item.selectedQuantity;

        let itemRemainingQuantity = item.remainingQuantity - (itemSelectedQuantity ?? 0);

        if (item.isEditing) {
          itemRemainingQuantity = item.remainingQuantity;
        }

        const product = this.allProducts.find((p) => p.id === CommonUtils.getUriId(item.productId));
        const defaultUnit = product?.defaultCustomUnit;

        if (defaultUnit) {
          item.productDefaultUnit = defaultUnit;
          itemSelectedQuantity = this.transformQuantity(item, itemSelectedQuantity);
          itemRemainingQuantity = this.transformQuantity(item, itemRemainingQuantity);
        }

        switch (this.type) {
          case "input":
            item.formGroup = new UntypedFormGroup({
              selectedQty: new UntypedFormControl(itemSelectedQuantity ?? 0, [
                CustomValidators.required,
                CustomValidators.greaterThan(0),
              ]),
              remainingQty: new UntypedFormControl({
                value: itemRemainingQuantity,
                disabled: true,
              }),
            });

            this.subscriptions.add(
              item.formGroup.controls["selectedQty"].valueChanges.subscribe(
                (selectedQty: number) => {
                  selectedQty = Math.max(selectedQty, 0);
                  if (!selectedQty) {
                    selectedQty = 0;
                  }
                  if (item.productDefaultUnit) {
                    // Convert selectedQty to product default unit
                    const convertedSelectedQty = this.transformQuantityInverse(item, selectedQty);

                    item.selectedQuantity = +convertedSelectedQty;

                    // Recalculate remaining quantity in default unit
                    let remainingQtyInDefaultUnit = item.remainingQuantity - convertedSelectedQty;

                    if (item.isEditing) {
                      remainingQtyInDefaultUnit =
                        item.remainingQuantity -
                        (item.selectedQuantity - item.originalSelectedQuantity);
                    }

                    // Convert remaining quantity back to the unit of measurement
                    const convertedRemainingQty = this.transformQuantity(
                      item,
                      remainingQtyInDefaultUnit,
                    );

                    item.formGroup.controls["remainingQty"].setValue(+convertedRemainingQty);
                  } else {
                    item.selectedQuantity = +selectedQty;

                    let newRemainingQty = item.remainingQuantity - item.selectedQuantity;

                    if (item.isEditing) {
                      newRemainingQty =
                        item.remainingQuantity -
                        (item.selectedQuantity - item.originalSelectedQuantity);
                    }

                    item.formGroup.controls["remainingQty"].setValue(newRemainingQty);
                  }
                },
              ),
            );
            break;
          case "output":
            if (item.productDefaultUnit) {
              itemInitialQuantity = this.transformQuantity(item, itemInitialQuantity);
            }
            item.formGroup = new UntypedFormGroup({
              initialQty: new UntypedFormControl({ value: itemInitialQuantity, disabled: true }),
            });
            break;
        }
      }
    }
    this.isLoading.set(false);
  }

  public onRemove = (id: string): void => {
    this.remove.emit(id);
  };

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private transformQuantity = (item: IItemDetails, quantity: number): number => {
    return +this.unitConversionPipe.transform(
      `${quantity}`,
      item.productDefaultUnit,
      item.unitOfMeasurement,
      false,
      false,
      true,
    );
  };

  private transformQuantityInverse = (item: IItemDetails, quantity: number): number => {
    return +this.unitConversionPipe.transform(
      `${quantity}`,
      item.productDefaultUnit,
      item.unitOfMeasurement,
      true,
      true,
    );
  };

  onSelectedQuantityChange(item: IItem) {
    this.selectedQuantityChanged.emit(item);
  }
}
