@if (hasValue) {
  @if (copyMode === copyModeEnum.NONE) {
    <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
  } @else {
    <div class="value clickable" (click)="copyMode === copyModeEnum.CONTAINER && onClick()">
      <ng-container [ngTemplateOutlet]="valueTemplate"></ng-container>
      @if (showCopyIcon()) {
        <mat-icon
          class="copy-icon"
          [tp]="translations.copy"
          (click)="copyMode === copyModeEnum.ICON && onClick()"
        >
          content_copy
        </mat-icon>
      }
    </div>
  }
  <ng-template #valueTemplate>
    <span
      [class]="valueClass"
      (click)="copyMode === copyModeEnum.ICON && onValueClick()"
      [tp]="valueTooltip()"
      [tpIsLazy]="true"
    >
      {{ value() }}
    </span>
  </ng-template>
} @else {
  <div class="value" [class]="valueClass">-</div>
}
