import { TextConstants } from "@shared/constants";
import { BatchActionTypeEnum, MemberStatusEnum, RecordStateEnum } from "@shared/enums";

import { IIdName } from "./id-name.interface";

export namespace BatchActionModel {
  export interface IBatchRecord extends IIdName {
    status: BatchStatusEnum;
    serviceFn: any;
  }

  export interface IBatchActionData {
    records: IBatchRecord[];
    actionDetails: IBatchActionDetails;
  }

  export enum BatchStatusEnum {
    PENDING = "Pending",
    SUCCESS = "Success",
    ERROR = "Error",
  }

  interface IBatchActionMessages {
    completed: string;
    allFailed: string;
    someFailed: string;
    pending: string;
    errorMessage?: string; // it has default value if not specified
    confirmation?: (recordsCount: number) => string; // it has default value if not specified
  }

  export interface IBatchActionDetails {
    title: string;
    icon: string;
    confirmButtonColor: string;
    messages: IBatchActionMessages;
    serviceFn: any;
    doesNotNeedAddEditRights?: boolean;
    isHiddenBySelectedRowsFn?: any;
  }

  export interface IBatchActionSettings {
    recordLabelProperty?: string; //defaults to 'name' if not specified.
    actions: Map<BatchActionTypeEnum, IBatchActionDetails>;
  }

  const defaultAcceptMessages = {
    allFailed: $localize`Accepting failed`,
    someFailed: $localize`Accepting partially failed`,
    pending: $localize`Accepting`,
    completed: $localize`Accepted`,
  };

  const defaultRejectMessages = {
    allFailed: $localize`Rejecting failed`,
    someFailed: $localize`Rejecting partially failed`,
    pending: $localize`Rejecting`,
    completed: $localize`Rejected`,
  };

  const defaultArchiveMessages = {
    allFailed: $localize`Archiving failed`,
    someFailed: $localize`Archiving partially failed`,
    pending: $localize`Archiving`,
    completed: TextConstants.ARCHIVED,
  };

  const defaultActivateMessages = {
    allFailed: $localize`Activation failed`,
    someFailed: $localize`Activation partially failed`,
    pending: $localize`Activating`,
    completed: $localize`Activated`,
  };

  const defaultDeactivateMessages = {
    allFailed: $localize`Deactivation failed`,
    someFailed: $localize`Deactivation partially failed`,
    pending: $localize`Deactivating`,
    completed: $localize`Deactivated`,
  };

  const defaultDeleteMessages = {
    allFailed: $localize`Deletion failed`,
    someFailed: $localize`Deletion partially failed`,
    pending: $localize`Deleting`,
    completed: $localize`Deleted`,
  };

  const defaultDownloadMessages = {
    allFailed: $localize`Download failed`,
    someFailed: $localize`Download partially failed`,
    pending: $localize`Downloading`,
    completed: $localize`Downloaded`,
  };

  const defaultMessages = {
    [BatchActionTypeEnum.ARCHIVE]: defaultArchiveMessages,
    [BatchActionTypeEnum.UNARCHIVE]: {
      allFailed: $localize`Unarchiving failed`,
      someFailed: $localize`Unarchiving partially failed`,
      pending: $localize`Unarchiving`,
      completed: TextConstants.UNARCHIVE,
    },
    [BatchActionTypeEnum.DELETE]: defaultDeleteMessages,
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: defaultDownloadMessages,
    [BatchActionTypeEnum.ACTIVATE_RULESET]: defaultActivateMessages,
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: defaultDeactivateMessages,
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: {
      allFailed: $localize`Resend invitations failed`,
      someFailed: $localize`Resend invitations partially failed`,
      pending: $localize`Resending invitations`,
      completed: $localize`Invitations resent`,
      errorMessage: $localize`Invitations to these records could not be resent:`,
      confirmation: (recordsCount: number) => {
        return `Are you sure you want to resend invitations to the <b>${recordsCount}:recordsCount:</b> selected records?`;
      },
    },
    [BatchActionTypeEnum.ARCHIVE_USER]: defaultArchiveMessages,
    [BatchActionTypeEnum.ACTIVATE_USER]: defaultActivateMessages,
    [BatchActionTypeEnum.DEACTIVATE_USER]: defaultDeactivateMessages,
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: defaultDeleteMessages,
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: {
      ...defaultDeleteMessages,
      confirmation: (recordsCount: number) => {
        return `Are you sure you want to delete the <b>${recordsCount}:recordsCount:</b> selected connection requests?`;
      },
    },
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: defaultAcceptMessages,
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: defaultRejectMessages,
  };

  const defaultAcceptTitle = TextConstants.ACCEPT;
  const defaultRejectTitle = $localize`Reject`;
  const defaultAchiveTitle = TextConstants.ARCHIVE;
  const defaultActivateTitle = TextConstants.ACTIVATE;
  const defaultDeactivateTitle = TextConstants.DEACTIVATE;
  const defaultDeleteTitle = TextConstants.DELETE;

  const defaultTitle = {
    [BatchActionTypeEnum.ARCHIVE]: defaultAchiveTitle,
    [BatchActionTypeEnum.UNARCHIVE]: TextConstants.UNARCHIVE,
    [BatchActionTypeEnum.DELETE]: defaultDeleteTitle,
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: $localize`Download`,
    [BatchActionTypeEnum.ACTIVATE_RULESET]: defaultActivateTitle,
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: defaultDeactivateTitle,
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: $localize`Resend invitation`,
    [BatchActionTypeEnum.ARCHIVE_USER]: defaultAchiveTitle,
    [BatchActionTypeEnum.ACTIVATE_USER]: defaultActivateTitle,
    [BatchActionTypeEnum.DEACTIVATE_USER]: defaultDeactivateTitle,
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: defaultDeleteTitle,
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: $localize`Delete connection requests`,
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: defaultAcceptTitle,
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: defaultRejectTitle,
  };

  const defaultAcceptIcon = "check_circle";
  const defaultRejectIcon = "block";
  const defaultAchiveIcon = "archive";
  const defaultActivateIcon = "check_circle";
  const defaultDeactivateIcon = "block";
  const defaultDeleteIcon = "delete";

  const defaultIcon = {
    [BatchActionTypeEnum.ARCHIVE]: defaultAchiveIcon,
    [BatchActionTypeEnum.UNARCHIVE]: "inventory",
    [BatchActionTypeEnum.DELETE]: defaultDeleteIcon,
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: "download",
    [BatchActionTypeEnum.ACTIVATE_RULESET]: defaultActivateIcon,
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: defaultDeactivateIcon,
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: "mail",
    [BatchActionTypeEnum.ARCHIVE_USER]: defaultAchiveIcon,
    [BatchActionTypeEnum.ACTIVATE_USER]: defaultActivateIcon,
    [BatchActionTypeEnum.DEACTIVATE_USER]: defaultDeactivateIcon,
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: defaultDeleteIcon,
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: defaultDeleteIcon,
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: defaultAcceptIcon,
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: defaultRejectIcon,
  };

  const primaryColor = "primary";
  const dangerColor = "danger";

  const defaultConfirmButtonColor = {
    [BatchActionTypeEnum.ARCHIVE]: dangerColor,
    [BatchActionTypeEnum.UNARCHIVE]: primaryColor,
    [BatchActionTypeEnum.DELETE]: dangerColor,
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: primaryColor,
    [BatchActionTypeEnum.ACTIVATE_RULESET]: primaryColor,
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: dangerColor,
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: primaryColor,
    [BatchActionTypeEnum.ARCHIVE_USER]: dangerColor,
    [BatchActionTypeEnum.ACTIVATE_USER]: primaryColor,
    [BatchActionTypeEnum.DEACTIVATE_USER]: dangerColor,
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: dangerColor,
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: dangerColor,
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: primaryColor,
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: dangerColor,
  };

  const defaultIsHiddenBySelectedRowsFn = {
    [BatchActionTypeEnum.ARCHIVE]: (selectedRows: any[]) =>
      selectedRows.some((row) => row.recordState === RecordStateEnum.ARCHIVED),
    [BatchActionTypeEnum.UNARCHIVE]: (selectedRows: any[]) =>
      selectedRows.some((row) => row.recordState === RecordStateEnum.ACTIVE),
    [BatchActionTypeEnum.DELETE]: undefined,
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: (selectedRows: any[]) =>
      selectedRows.some((row) => !row.contentType),
    [BatchActionTypeEnum.ACTIVATE_RULESET]: (selectedRows: any[]) =>
      selectedRows.some((row) => row.active || row.recordState === RecordStateEnum.ARCHIVED),
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: (selectedRows: any[]) =>
      selectedRows.some((row) => !row.active || row.recordState === RecordStateEnum.ARCHIVED),
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: undefined,
    [BatchActionTypeEnum.ARCHIVE_USER]: (selectedRows: any[]) =>
      selectedRows.some((row) => !row.canLoggedInUserEdit),
    [BatchActionTypeEnum.ACTIVATE_USER]: (selectedRows: any[]) =>
      selectedRows.some(
        (row) => !row.canLoggedInUserEdit || row.status === MemberStatusEnum.ACTIVE,
      ),
    [BatchActionTypeEnum.DEACTIVATE_USER]: (selectedRows: any[]) =>
      selectedRows.some(
        (row) => !row.canLoggedInUserEdit || row.status === MemberStatusEnum.DEACTIVATED,
      ),
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: (selectedRows: any[]) =>
      selectedRows.some((row) => !row.canDelete),
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: undefined,
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: undefined,
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: undefined,
  };

  const defaultServiceFn = (service: any) => ({
    [BatchActionTypeEnum.ARCHIVE]: (id: string) =>
      service.setRecordState({ recordState: RecordStateEnum.ARCHIVED }, id),
    [BatchActionTypeEnum.UNARCHIVE]: (id: string) =>
      service.setRecordState({ recordState: RecordStateEnum.ACTIVE }, id),
    [BatchActionTypeEnum.DELETE]: (id: string) => service.delete(id),
    [BatchActionTypeEnum.DOWNLOAD_DOCUMENT]: (document: IIdName) => service.add(document),
    [BatchActionTypeEnum.ACTIVATE_RULESET]: (id: string) =>
      service.setActiveState({ active: true }, id),
    [BatchActionTypeEnum.DEACTIVATE_RULESET]: (id: string) =>
      service.setActiveState({ active: false }, id),
    [BatchActionTypeEnum.RESEND_USER_INVITATION]: (id: string) => service.resend(id),
    [BatchActionTypeEnum.ARCHIVE_USER]: (id: string) =>
      service.setMemberRecordState(id, MemberStatusEnum.ARCHIVED),
    [BatchActionTypeEnum.ACTIVATE_USER]: (id: string) =>
      service.setMemberRecordState(id, MemberStatusEnum.ACTIVE),
    [BatchActionTypeEnum.DEACTIVATE_USER]: (id: string) =>
      service.setMemberRecordState(id, MemberStatusEnum.DEACTIVATED),
    [BatchActionTypeEnum.DELETE_INBOUND_SHARE]: (id: string) => service.deleteInboundShare(id),
    [BatchActionTypeEnum.DELETE_CONNECTION_REQUEST]: (id: string) => service.deleteOutbound(id),
    [BatchActionTypeEnum.ACCEPT_CONNECTION_REQUEST]: (
      id: string,
      payload: { connectionUri: string },
    ) => service.acceptInbound(id, payload),
    [BatchActionTypeEnum.REJECT_CONNECTION_REQUEST]: (id: string) => service.rejectInbound(id),
  });

  export const getBatchAction = (
    action: BatchActionTypeEnum,
    service: any,
    doesNotNeedAddEditRights?: boolean,
    title?: string,
    isHiddenBySelectedRowsFn?: any,
    icon?: string,
    confirmButtonColor?: string,
    messages?: IBatchActionMessages,
    serviceFn?: any,
  ): IBatchActionDetails => ({
    title: title ?? defaultTitle[action],
    doesNotNeedAddEditRights: doesNotNeedAddEditRights ?? false,
    isHiddenBySelectedRowsFn: isHiddenBySelectedRowsFn ?? defaultIsHiddenBySelectedRowsFn[action],
    icon: icon ?? defaultIcon[action],
    confirmButtonColor: confirmButtonColor ?? defaultConfirmButtonColor[action],
    messages: messages ?? defaultMessages[action],
    serviceFn: serviceFn ?? defaultServiceFn(service)[action],
  });
}
