import { Component, ChangeDetectionStrategy, input, inject, computed, signal } from "@angular/core";

import { EudrDdsPageService } from "@components/reports/eudr/dds/eudr-dds-page.service";
import { TextConstants } from "@shared/constants";
import { EudrDds } from "@shared/interfaces/eudr-dds.interface";
import { NavigationParams, RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-delivery-eudr-dds-details",
  templateUrl: "./delivery-eudr-dds-details.component.html",
  styleUrls: ["./delivery-eudr-dds-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryEudrDdsDetailsComponent {
  public readonly translations: any = {
    internalReferenceNumber: $localize`Internal reference number`,
    status: TextConstants.STATUS,
    tracesReferenceNumber: $localize`TRACES reference number`,
    tracesVerificationNumber: $localize`TRACES verification number`,
  };

  public hasDdsStatusUpdateFailed = input.required<boolean>();

  public eudrDdsReport = input.required<EudrDds.IDetails>();

  public canAddModifyEntities = input.required<boolean>();

  public displayVerificationNumber = signal<boolean>(false);

  private eudrDdsPageService: EudrDdsPageService = inject(EudrDdsPageService);

  private routerService = inject(RouterService);

  isPending = computed(() => this.eudrDdsReport().status === EudrDds.StatusEnum.PENDING);

  isSubmitted = computed(() => this.eudrDdsReport().status === EudrDds.StatusEnum.SUBMITTED);

  visibilityIcon = computed(() =>
    this.displayVerificationNumber() ? "visibility_off" : "visibility",
  );

  statusTagClass = computed(() =>
    this.eudrDdsPageService.getStatusTagColor(this.eudrDdsReport().status),
  );

  link = computed(
    () => this.routerService.getEudrDdsLink(this.eudrDdsReport().id, false) as NavigationParams,
  );

  public toggleDisplayVerificationNumber(): void {
    this.displayVerificationNumber.set(!this.displayVerificationNumber());
  }
}
