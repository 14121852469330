<div class="form-field-item no-padding">
  <div class="input-container with-datepicker-toggle">
    <input
      matInput
      type="text"
      [matDatepicker]="picker"
      [placeholder]="datePickerDateFormat"
      [value]="date()"
      (dateInput)="onDateChange($event)"
      (input)="onDateChange($event)"
    />

    <mat-icon
      matSuffix
      class="suffix-icon clear-icon"
      [tp]="translations.clear"
      [hidden]="!input().value"
      (click)="onDateClear()"
    >
      cancel
    </mat-icon>

    <button type="button" class="datepicker-toggle clickable" (click)="onCalendarClick($event)">
      <mat-icon>calendar_month</mat-icon>
    </button>
  </div>

  <mat-datepicker
    #picker
    color="accent"
    [panelClass]="['design-makeover', 'ag-custom-component-popup']"
    [calendarHeaderComponent]="datepickerHeader"
  />
</div>
