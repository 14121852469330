import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from "@angular/core";

import { ColDef } from "ag-grid-community";

import { EnumsCellRendererComponent } from "@shared/cell-renderers";
import { CommonConstants, TextConstants } from "@shared/constants";
import { RecordStateEnum, TableEnum } from "@shared/enums";
import { NotificationService } from "@shared/services";
import { ColumnUtils } from "@shared/utils";

import { RiskAssessmentReportsApiService } from "../api";
import { RiskAssessmentReport } from "../models";

@Component({
  standalone: false,
  selector: "app-risk-assessment-reports-table",
  templateUrl: "./risk-assessment-reports-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskAssessmentReportsTableComponent implements AfterViewInit {
  private readonly api = inject(RiskAssessmentReportsApiService);

  private readonly notification = inject(NotificationService);

  readonly areButtonsEnabled = input(true);

  readonly isFixedBottomPaginator = input(false);

  readonly isPaginatorEnabled = input(true);

  readonly isRecordStateFilterEnabled = input(true);

  readonly isSaveTableState = input(false);

  readonly isSearchEnabled = input(true);

  readonly recordState = input(RecordStateEnum.ALL);

  readonly recordUri = input.required<string>();

  readonly view = output<RiskAssessmentReport>();

  readonly isLoading = signal(true);

  readonly columnDefs = signal<ColDef[]>([]);

  readonly rowData = signal<RiskAssessmentReport[]>([]);

  readonly totalElements = computed(() => this.rowData().length);

  readonly table = TableEnum.RELATED_RISK_ASSESSMENT_REPORTS;

  async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  async getAll() {
    this.isLoading.set(true);

    try {
      const { content } = await this.api.getAll({
        recordUri: this.recordUri(),
        page: 0,
        size: CommonConstants.MAX_API_GET_ITEMS_SIZE,
      });

      this.rowData.set(content);
      this.isLoading.set(false);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  onView(row: RiskAssessmentReport) {
    if (this.areButtonsEnabled()) {
      this.view.emit(row);
    }
  }

  private setColumnDefs() {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      ColumnUtils.dateColumn({
        headerName: $localize`Date completed`,
        field: "completedDate",
        sort: "desc",
      }),
      ColumnUtils.dateColumn({
        headerName: $localize`Valid until`,
        field: "validUntilDate",
      }),
      {
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: EnumsCellRendererComponent,
      },
      ColumnUtils.tags($localize`Calculated risk`, "calculatedRisk"),
      ColumnUtils.tags($localize`Residual risk`, "residualRisk"),
    ];

    this.columnDefs.set(columnDefs);
  }
}
