import { ComponentType } from "@angular/cdk/overlay";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  signal,
  viewChild,
  ViewContainerRef,
} from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { distinctUntilChanged, filter, Subscription } from "rxjs";

import { OverlayBridgeService } from "@components/shared/overlay/overlay-bridge.service";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { slideOverAnimation } from "@shared/animations";
import { TextConstants } from "@shared/constants";
import { NotificationService, AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-slide-overlay-page",
  templateUrl: "./slide-overlay-page.component.html",
  animations: [slideOverAnimation],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayPageComponent implements AfterViewInit, OnDestroy {
  protected content = viewChild("dynamicContent", { read: ViewContainerRef });

  protected overlayHidden = signal<boolean>(true);

  protected contentHidden = signal<boolean>(false);

  private subscriptions = new Subscription();

  public isShowGoBackButton = false;

  public isClosingOverlay = false;

  constructor(
    private router: Router,
    private overlay: SlideOverlayPageService,
    private routerService: RouterService,
    private bridge: OverlayBridgeService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService,
  ) {
    this.subscriptions.add(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(async () => {
          this.isShowGoBackButton = this.routerService.isPreviousHistoryADetailsOverlay();
        }),
    );
  }

  ngAfterViewInit(): void {
    this.subscriptions.add(
      this.overlay.trigger$
        .pipe(
          filter((overlay) => overlay !== null),
          distinctUntilChanged(
            (previous, current) => previous?.view === current?.view && previous?.id === current?.id,
          ),
        )
        .subscribe((overlay) => {
          if (this.authenticationService.haveTokensExpired()) {
            this.notificationService.showError(TextConstants.SESSION_HAS_EXPIRED);
            this.overlay.close(true);
            this.authenticationService.logout();

            return;
          }

          const component = this.bridge.getComponent(overlay?.view);

          if (component) {
            this.clear();
            this.createView(component, overlay.id);
            this.overlayHidden.set(false);
            this.contentHidden.set(false);

            return;
          }

          this.contentHidden.set(true);

          this.overlayHidden.set(true);
          this.clear();
          this.overlay.closeSideMenu();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public async canExit(): Promise<boolean> {
    return await this.overlay.componentCanDeactivate();
  }

  protected async dismiss(): Promise<void> {
    const canExit = await this.overlay.canClose();

    if (canExit) {
      this.isClosingOverlay = true;
      this.routerService.closeViewOutlet();
    }
  }

  private createView(component: ComponentType<SlideOverlayPageClass>, id?: string): void {
    this.isClosingOverlay = false;
    const componentRef = this.content().createComponent(component);

    this.overlay.assignComponentRef(componentRef);

    if (id) {
      this.overlay.disableEditMode();
      this.overlay.showLoading();
    } else {
      this.overlay.dismissLoading();
      this.overlay.enableEditMode();
    }
  }

  private clear(): void {
    this.overlay.disableEditMode();
    this.overlay.componentRef.set(undefined);
    this.content().clear();
  }

  onBack = async (): Promise<void> => {
    if (this.routerService.hasHistory()) {
      this.routerService.goBackInHistory();
    } else {
      await this.dismiss();
    }
  };
}
