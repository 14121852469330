import { HttpErrorResponse } from "@angular/common/http";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  Input,
  signal,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { ConfirmDialogComponent } from "src/app/components";

import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import {
  ConfirmDialogResponseEnum,
  RouteEnum,
  TableEnum,
  InvitationUserTypeEnum,
  BatchActionTypeEnum,
} from "@shared/enums";
import { IInvitation } from "@shared/interfaces";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService, AuthenticationService, InvitationsService } from "@shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-users-invited-table",
  templateUrl: "./users-invited-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersInvitedTableComponent implements AfterViewInit {
  @Input()
  public invitations: IInvitation[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  public readonly table = TableEnum.USERS_INVITED;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public readonly totalElements = computed(() =>
    this.isLoading() ? undefined : this.rowData().length,
  );

  public rowData = signal<IInvitation[]>([]);

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private invitationsService: InvitationsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      recordLabelProperty: "email",
      actions: new Map([
        [
          BatchActionTypeEnum.RESEND_USER_INVITATION,
          BatchActionModel.getBatchAction(
            BatchActionTypeEnum.RESEND_USER_INVITATION,
            this.invitationsService,
          ),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.invitationsService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: TextConstants.EMAIL,
        field: "email",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: this.areButtonsEnabled
            ? [
                {
                  click: this.onCopy,
                  tooltip: $localize`Copy invitation link`,
                  icon: "content_copy",
                },
                {
                  click: this.onResendInvitation,
                  tooltip: $localize`Resend invitation`,
                  icon: "mail",
                },
                {
                  click: this.onDelete,
                  tooltip: $localize`Delete invitation`,
                  icon: "delete",
                },
              ]
            : [],
        },
      },
      { headerName: $localize`Roles`, field: "roles", cellRenderer: CellRendererUtils.roleNames },
      {
        headerName: TextConstants.STATUS,
        field: "status",
        cellRenderer: CellRendererUtils.capitaliseFirstLetter,
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }
    this.columnDefs.set(columnDefs);
  };

  public onCopy = (invitation: IInvitation): void => {
    const { email, orgName, userType } = invitation;
    const emailUrlEncoded = encodeURIComponent(email);
    const orgNameUrlEncoded = encodeURIComponent(orgName);
    const invitationLink =
      userType === InvitationUserTypeEnum.NEW
        ? `${window.location.origin}/${RouteEnum.SIGN_UP}?invitationId=${invitation.id}&email=${emailUrlEncoded}&org=${orgNameUrlEncoded}`
        : `${window.location.origin}/${RouteEnum.INVITATIONS}?acceptInvitationId=${invitation.id}&org=${orgNameUrlEncoded}`;

    CommonUtils.textToClipboard(invitationLink);
    this.notificationService.showSuccess(TextConstants.COPIED);
  };

  public onDelete = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Delete invitation`,
        contentText: $localize`Are you sure that you want to delete this invitation?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.invitationsService
          .delete(invitation.id)
          .then(async () => {
            this.notificationService.showSuccess($localize`Invitation deleted`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public onResendInvitation = (invitation: IInvitation): void => {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: $localize`Resend confirmation`,
        contentText: $localize`Are you sure that you want to resend the invitation email?`,
        confirmButtonText: $localize`Resend`,
        confirmButtonIcon: "mail",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        await this.invitationsService
          .resend(invitation.id)
          .then(async () => {
            this.notificationService.showSuccess($localize`Invitation resent`);
            await this.getAll();
          })
          .catch((error: HttpErrorResponse) => {
            this.notificationService.showError(error);
          });
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.invitations) {
      this.rowData.set(this.invitations);
      this.isLoading.set(false);
    } else {
      try {
        this.rowData.set(
          await this.invitationsService.getAll(
            undefined,
            this.authenticationService.getActiveOrganisationId(),
          ),
        );
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
