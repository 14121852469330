import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms";

import { ReportsEudrModel } from "@components/reports/eudr/reports-eudr.model";
import { GeoJsonTypeEnum } from "@shared/enums";
import { ICustomField, ILocationExtended, IOrganisation } from "@shared/interfaces";
import { CommonUtils, GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

export class EudrProducer {
  public organisation: IOrganisation;

  public locations: ILocationExtended[];

  public areaCustomField: ICustomField;

  constructor(organisation: IOrganisation, locations: ILocationExtended[]) {
    this.organisation = organisation;
    this.locations = locations;
  }

  public buildForm(): UntypedFormGroup {
    const locationForms = this.locations.map((location) => {
      return new UntypedFormGroup({
        description: new UntypedFormControl({ value: location.name, disabled: true }, [
          CustomValidators.required,
        ]),
        area: new UntypedFormControl(
          {
            value: CommonUtils.getLocationAreaInHa(location),
            disabled: GeoJSONUtils.isPolygon(location.geoLocation),
          },
          [CustomValidators.required],
        ),
        type: new UntypedFormControl(
          { value: this.getPlaceDescriptionType(location), disabled: true },
          [
            CustomValidators.isInValues(
              [GeoJsonTypeEnum.POLYGON, GeoJsonTypeEnum.POINT],
              "This location has no GPS data",
            ),
          ],
        ),
        geoData: new UntypedFormControl(location.geoLocation),
      });
    });

    return new UntypedFormGroup({
      producerName: new UntypedFormControl({ value: this.organisation.name, disabled: true }, [
        CustomValidators.required,
      ]),
      producerCountry: new UntypedFormControl(
        { value: this.locations[0].address.countryName, disabled: true },
        [CustomValidators.required],
      ),
      productionPlaces: new UntypedFormArray(locationForms),
    });
  }

  private getPlaceDescriptionType(location: ILocationExtended): string {
    const geolocation = location.geoLocation;

    if (!geolocation) {
      return ReportsEudrModel.noGPSDataValue;
    }

    return geolocation.features[0].geometry.type || ReportsEudrModel.noGPSDataValue;
  }
}
