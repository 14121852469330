<h2 mat-dialog-title i18n>The following documents will not be uploaded</h2>

<mat-dialog-content>
  <ul class="errors">
    <li *ngFor="let file of data.filesWithErrors" [tp]="file.tooltip">
      {{ file.formGroup.controls["name"].value }}.{{ file.extension }}
      <mat-icon>help</mat-icon>
    </li>
  </ul>
  <p class="text-center" i18n>Would you like to continue?</p>
</mat-dialog-content>

<mat-dialog-actions>
  <app-button-group>
    <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
    <app-button (pressed)="onClose(true)" cdkFocusInitial>
      <mat-icon icon-left>check</mat-icon>
      <ng-container i18n>Continue</ng-container>
    </app-button>
  </app-button-group>
</mat-dialog-actions>
