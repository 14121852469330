<div class="container-space-between">
  <app-page-title [title]="translations.title" [hint]="translations.titleHint"></app-page-title>

  <app-button-group [visible]="canAddModifyEntities">
    <app-button (pressed)="onAdd()">
      <mat-icon icon-left>add</mat-icon>
      <ng-container i18n>Add new</ng-container>
    </app-button>
  </app-button-group>
</div>

<app-certificates-table
  *ngIf="!isLoadingStandards()"
  [allStandards]="allStandards"
  [recordState]="recordStateEnum.ACTIVE"
  [isSaveTableState]="true"
  [isFixedBottomPaginator]="true"
  [isBatchActionsEnabled]="true"
  [columns]="[
    'recordState',
    'number',
    'standard.name',
    'standardType.fullName',
    'issuanceDate',
    'validFromDate',
    'validToDate',
    'tags',
  ]"
></app-certificates-table>
