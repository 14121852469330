import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef, IRowNode } from "ag-grid-community";
import { Subscription } from "rxjs";
import { ConfirmDialogComponent } from "src/app/components";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  EntityTypeEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IMaterial, IMaterialExtended } from "src/app/shared/interfaces";
import { MaterialsService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TableWithRiskAssessmentReportsService } from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { LinkCellRendererComponent, QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { RouterService } from "@shared/services/router.service";

import { TableBaseClass } from "../table/models";

@Component({
  standalone: false,
  selector: "app-materials-table",
  templateUrl: "./materials-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaterialsTableComponent
  extends TableBaseClass<IMaterial | IMaterialExtended>
  implements AfterViewInit, OnDestroy
{
  @Input()
  public materials: IMaterial[] = null;

  materialsIds = input<string[]>(null);

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.MATERIALS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public columns: string[] = ["recordState", "name", "category", "tags"];

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  @Output()
  public removeClicked = new EventEmitter<IMaterial>();

  private subscriptions = new Subscription();

  public isInboundShared = input<boolean>(false);

  public inboundSharedSenderOrgId = input<string>(null);

  constructor(
    private materialsService: MaterialsService,
    private dialog: MatDialog,
    private overlay: SlideOverlayPageService,
    private routerService: RouterService,
    private tableWithReports: TableWithRiskAssessmentReportsService,
  ) {
    super();

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.MATERIAL);
    }

    this.subscriptions.add(
      this.overlay.refreshTable$.subscribe(({ entityType }) => {
        if (
          entityType === EntityTypeEnum.MATERIALS ||
          entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS
        ) {
          this.getAll();
        }
      }),
    );
  }

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.materialsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.materialsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.materialsService),
        ],
      ]),
    };
  };

  override setColumnDefs = (): void => {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.isInboundShared()
            ? (id) =>
                this.routerService.getSharedMaterialLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId(),
                })
            : this.routerService.getMaterialLink,
        },
      },
      {
        colId: "nameWithRemove",
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              icon: "close",
              tooltip: TextConstants.REMOVE,
              show: () => this.overlay.editMode(),
              click: (material: IMaterial) => this.removeClicked.emit(material),
            },
          ],
        },
      },
      { headerName: $localize`Category`, field: "category" },
    ];

    if (this.isRegularUser) {
      columnDefs.push(ColumnUtils.tags());
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns);

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    if (this.isRegularUser) {
      columnDefs.push(
        this.tableWithReports.lastRiskAssessedColDef,
        this.tableWithReports.residualRiskLevelColDef,
      );
    }

    this.columnDefs.set(columnDefs);
  };

  public onRowClick = async (element: IMaterial): Promise<void> => {
    const materialLink = this.isInboundShared()
      ? this.routerService.getSharedMaterialLink(element.id, false, {
          organisationId: this.inboundSharedSenderOrgId(),
        })
      : this.routerService.getMaterialLink(element.id);

    await this.routerService.navigate(materialLink);
  };

  public onToggleArchiveStatus = async (element: IMaterial): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.materialsService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  override getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    const columnDefs = this.columnDefs();
    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }

    if (this.materials) {
      this.rowData.set(this.materials);

      this.isLoading.set(false);
    } else if (this.materialsIds()) {
      try {
        const materials = await this.materialsService.getByIdsGraphQL(
          this.materialsIds(),
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(materials);

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const materials = await this.materialsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(materials);

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  onRowsDataUpdated(rows: IRowNode<IMaterial | IMaterialExtended>[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement());
    }
  }
}
