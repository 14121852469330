import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  OnInit,
  effect,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { tap } from "rxjs";

import { RiskAssessmentTemplatesApiService } from "@components/shared/risk-assessment-templates/api";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { RiskAssessmentTemplate } from "@components/shared/risk-assessment-templates/models";
import { RiskLevelSetsStateService } from "@components/shared/risk-level-sets/state";
import { CopyModeEnum } from "@components/shared/value-and-copy-button/value-and-copy-button.model";
import { TextConstants } from "@shared/constants";
import { RecordStateEnum } from "@shared/enums";
import { ISelectOption } from "@shared/interfaces";
import { NotificationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

export interface AddRiskAssessmentReportDialogData {
  onSubmit: (template: string) => Promise<void>;
  orgId: string;
  resourceType: RiskAssessmentTemplateResourceType;
}

interface TemplateOption extends ISelectOption {
  value: string; // URI
}

@Component({
  standalone: false,
  templateUrl: "./add-risk-assessment-report-dialog.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddRiskAssessmentReportDialogComponent implements OnInit {
  private readonly fb = inject(FormBuilder);

  private readonly dialogRef: MatDialogRef<AddRiskAssessmentReportDialogComponent> =
    inject(MatDialogRef);

  private readonly data: AddRiskAssessmentReportDialogData = inject(MAT_DIALOG_DATA);

  private readonly templatesApi = inject(RiskAssessmentTemplatesApiService);

  private readonly notification = inject(NotificationService);

  private readonly router = inject(RouterService);

  readonly riskLevelSetsState = inject(RiskLevelSetsStateService);

  readonly form = this.fb.group({
    template: this.fb.control<TemplateOption>(null, [CustomValidators.required]),
  });

  readonly isLoading = signal(true);

  readonly templates = signal<RiskAssessmentTemplate[]>([]);

  readonly selectedTemplate = signal<RiskAssessmentTemplate>(undefined);

  readonly templateOptions = computed<TemplateOption[]>(() =>
    this.templates().map((item) => ({
      label: item.name,
      value: `/organisations/${this.data.orgId}/risk-assessment/templates/${item.id}`,
    })),
  );

  readonly templateValueChanges$ = this.form.controls.template.valueChanges.pipe(
    tap((template) => this.setSelectedTemplate(template?.value)),
  );

  readonly copyMode = CopyModeEnum;

  public readonly translations: any = {
    nameLabel: TextConstants.NAME,
    descriptionLabel: TextConstants.DESCRIPTION,
    riskLevelSetLabel: $localize`Risk level set`,
    templateLabel: $localize`Template`,
    addNewTp: $localize`Add new template`,
  };

  constructor() {
    effect(() => this.riskLevelSetsState.setSelectedSet(this.selectedTemplate()?.riskLevelSet));
  }

  async ngOnInit() {
    await this.fetchTemplates();
    await this.riskLevelSetsState.init();
    this.isLoading.set(false);
  }

  async submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return;
    }

    this.isLoading.set(true);

    const { template } = this.form.getRawValue();

    await this.data.onSubmit(template.value);
    this.isLoading.set(false);
  }

  close() {
    this.dialogRef.close();
  }

  addTemplate() {
    this.close();

    this.router.navigate(
      this.router.getRiskAssessmentTemplateLink(undefined, true, {
        type: this.data.resourceType,
      }),
    );
  }

  private async fetchTemplates() {
    try {
      const { content } = await this.templatesApi.getAll({
        appliesTo: this.data.resourceType,
        recordState: RecordStateEnum.ACTIVE,
      });

      this.templates.set(content);
    } catch (error) {
      this.notification.showError(error);
    }
  }

  private setSelectedTemplate(templateUri: string) {
    if (!templateUri) {
      this.selectedTemplate.set(undefined);

      return;
    }

    const templateId = CommonUtils.getUriId(templateUri);

    const template = this.templates().find((item) => item.id === templateId);

    if (template) {
      this.selectedTemplate.set(template);
    }
  }
}
