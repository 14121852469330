import { Injectable, inject } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { ApiService, AuthenticationService } from "@shared/services";
import { APP_CONFIG } from "@shared/tokens";

import {
  RiskAssessmentReportIndicator,
  RiskAssessmentReportIndicatorPayload,
  RiskAssessmentReportIndicatorStatusPayload,
} from "../models";

@Injectable({ providedIn: "root" })
export class RiskAssessmentReportIndicatorsApiService {
  private readonly api = inject(ApiService);

  private readonly authService = inject(AuthenticationService);

  private readonly environment = inject(APP_CONFIG);

  private readonly getBaseUrl = () =>
    `${this.environment.baseUrl}organisations/${this.authService.getActiveOrganisationId()}/risk-assessment/reports`;

  getAll(reportId: string) {
    return lastValueFrom(
      this.api.get<RiskAssessmentReportIndicator[]>(`${this.getBaseUrl()}/${reportId}/indicators`),
    );
  }

  get(reportId: string, indicatorId: string) {
    return lastValueFrom(
      this.api.get<RiskAssessmentReportIndicator["indicators"][0]>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}`,
      ),
    );
  }

  update(reportId: string, indicatorId: string, payload: RiskAssessmentReportIndicatorPayload) {
    return lastValueFrom(
      this.api.put<RiskAssessmentReportIndicator["indicators"][0]>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}`,
        payload,
      ),
    );
  }

  updateStatus(
    reportId: string,
    indicatorId: string,
    payload: RiskAssessmentReportIndicatorStatusPayload,
  ) {
    return lastValueFrom(
      this.api.put<RiskAssessmentReportIndicatorStatusPayload>(
        `${this.getBaseUrl()}/${reportId}/indicators/${indicatorId}/status`,
        payload,
      ),
    );
  }
}
