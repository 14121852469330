import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";

import { AddOrganisationDialogComponent } from "../../components";
import { RouteEnum, UserRoleEnum } from "../enums";
import { IOrganisation, IUserData } from "../interfaces";
import { AuthenticationService } from "./authentication.service";

@Injectable({
  providedIn: "root",
})
export class OrganisationService {
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private dialog: MatDialog,
  ) {}

  async changeOrganisation(index: number, userData: IUserData, currentUrl: string): Promise<void> {
    if (userData.activeOrganisationIndex !== index) {
      await this.authService.setActiveOrganisationIndex(index);
      if (currentUrl === `/${RouteEnum.ORGANISATIONS}`) {
        location.reload();
      } else {
        await this.router.navigate([`/${RouteEnum.ORGANISATIONS}`]);
      }
    }
  }

  createOrganisation(): void {
    const dialogRef = this.dialog.open(AddOrganisationDialogComponent);

    dialogRef.afterClosed().subscribe(async (result: { savedOrganisation?: IOrganisation }) => {
      if (result?.savedOrganisation) {
        //todo Perhaps also use SetUserOrganisationsService here or not needed?
        const organisation = {
          id: result.savedOrganisation.id,
          roles: [UserRoleEnum.ACCOUNT_OWNER],
          isSystemAdmin: false,
          name: result.savedOrganisation.name,
          status: "ACTIVE", //todo do we need this status?
        };

        await this.authService.addAvailableOrganisation(organisation, true);
      }
    });
  }

  getOrganisationAcronym(name: string): string {
    if (!name || typeof name !== "string") {
      return "-";
    }

    const trimmedName: string = name.trim();
    const words: string[] = trimmedName.split(" ");

    if (words.length > 1) {
      const [firstWord, secondWord] = words;

      return `${firstWord.charAt(0)}${secondWord.charAt(0)}`;
    } else {
      return trimmedName.slice(0, 2);
    }
  }
}
