import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";

import { ColDef } from "ag-grid-community";
import {
  BadgeLinkCellRendererComponent,
  LinkCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { FeatureFlagEnum, TableEnum } from "src/app/shared/enums";
import {
  IBaseUnit,
  IItemExtended,
  IMaterial,
  IProcessItem,
  IProductExtended,
} from "src/app/shared/interfaces";
import { AuthenticationService, FeatureFlagService, ItemsService } from "src/app/shared/services";
import { ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { TextConstants } from "@shared/constants";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-process-selected-items-table",
  templateUrl: "./process-selected-items-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessSelectedItemsTableComponent implements AfterViewInit {
  @Input()
  public type: string;

  @Input()
  public items: IProcessItem[] = [];

  @Input()
  public allMaterials: IMaterial[] = [];

  @Input()
  public allProducts: IProductExtended[] = [];

  @Input()
  public allUnitOfMeasurements: IBaseUnit[] = [];

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  public readonly table = TableEnum.PROCESS_SELECTED_ITEMS;

  @Input()
  public isSaveTableState = false;

  @Input()
  public columns: string[] = [
    "itemId",
    "productName",
    "materialData",
    "initialQuantity",
    "selectedQuantity",
  ];

  public isLoading = signal(true);

  public rowData: any[] = [];

  public columnDefs = signal<ColDef[]>([]);

  public readonly isRegularUser = this.authenticationService.isRegularUser();

  private readonly isOldMaterialsEnabled = !this.featureFlagService.isEnabled(
    FeatureFlagEnum.NEW_MATERIALS_BEHAVIOUR,
  );

  @Input()
  public isInboundShared = false;

  @Input()
  public inboundSharedSenderOrgId: string = null;

  @Input()
  public inboundItems: IItemExtended[] = [];

  constructor(
    private itemService: ItemsService,
    private router: RouterService,
    private authenticationService: AuthenticationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  public async ngAfterViewInit() {
    this.setColumnDefs();
    await this.getAll();
  }

  public onViewDetails = async (row: any): Promise<void> => {
    const link = this.isInboundShared
      ? this.router.getSharedItemLink(row.id, false, {
          organisationId: this.inboundSharedSenderOrgId,
        })
      : this.router.getItemLink(row.id);

    await this.router.navigate(link);
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      {
        headerName: $localize`Item ID`,
        field: "itemId",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteFn: this.isInboundShared
            ? (id) => {
                return this.router.getSharedItemLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                });
              }
            : this.router.getItemLink,
          linkRouteIdParam: "id",
        },
      },
      {
        headerName: TextConstants.INITIAL_QTY,
        field: "initialQuantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "precision",
          symbolParam: "symbol",
          formattedQuantityParam: "initialQuantityWithDefaultUnit",
        },
      },
    ];

    if (this.type === "input") {
      columnDefs.push({
        headerName: TextConstants.SELECTED_QTY,
        field: "selectedQuantity",
        cellRenderer: UnitOfMeasurementCellRendererComponent,
        cellRendererParams: {
          precisionParam: "precision",
          symbolParam: "symbol",
          formattedQuantityParam: "selectedQuantityWithDefaultUnit",
        },
      });
    }
    if (this.isOldMaterialsEnabled) {
      columnDefs.push(
        {
          headerName: TextConstants.PRODUCT,
          field: "productName",
          cellRenderer: LinkCellRendererComponent,
          cellRendererParams: {
            linkRouteFn: this.isInboundShared
              ? (id) =>
                  this.router.getSharedProductLink(id, false, {
                    organisationId: this.inboundSharedSenderOrgId,
                  })
              : this.router.getProductLink,
            linkRouteIdParam: "productId",
          },
        },
        ColumnUtils.chips("Materials", "materialData", {
          textParam: "name",
          linkRouteIdParam: "id",
          linkRouteFn: (id) =>
            this.isInboundShared
              ? this.router.getSharedMaterialLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
              : this.router.getMaterialLink(id, false),
        }),
      );
    } else {
      columnDefs.push({
        headerName: TextConstants.PRODUCT,
        field: "productName",
        cellClass: "container-flex-left",
        lockVisible: true,
        valueGetter: (cell: any) => String(cell.data?.productName ?? "-"),
        cellRenderer: BadgeLinkCellRendererComponent,
        cellRendererParams: {
          tooltipArray: (row) => {
            if (!row?.materials?.length) {
              return undefined;
            }

            return row.materials.map((m) => `${m.category}: ${m.name}`);
          },
          badgeValue: (row) => row.productName,
          badgeIcon: "category",
          tooltipTemplate: "keyCount",
          tooltipHeader: "Materials",
          linkRouteIdParam: "productId",
          linkRouteFn: this.isInboundShared
            ? (id) =>
                this.router.getSharedProductLink(id, false, {
                  organisationId: this.inboundSharedSenderOrgId,
                })
            : this.router.getProductLink,
        },
      });
    }

    this.columnDefs.set(CommonUtils.getVisibleColumnDefs(columnDefs, this.columns));
  };

  private getParsedRowData = async (selectedProcessItems: IProcessItem[]): Promise<any[]> => {
    if (!selectedProcessItems) {
      return [];
    }
    const result = [];

    for (const processItem of selectedProcessItems) {
      const itemId = CommonUtils.getUriId(processItem.item);
      let item: any = await this.itemService.get(itemId);

      item = CommonUtils.getElementsWithMaterialData(this.allMaterials, item, "materials");
      const productId = CommonUtils.getUriId(item.product);
      const product = this.allProducts.find((p) => p.id === productId);
      const defaultUnit = product?.defaultCustomUnit;
      const unitOfMeasurement = product?.unitOfMeasurement;
      let initialQuantityWithDefaultUnit: string;
      let selectedQuantityWithDefaultUnit: string;

      if (this.isRegularUser && defaultUnit) {
        const productDefaultUnit = defaultUnit;

        initialQuantityWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          item.initialQuantity,
          productDefaultUnit,
          unitOfMeasurement,
        );
        selectedQuantityWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          processItem.selectedQuantity,
          productDefaultUnit,
          unitOfMeasurement,
        );
      }
      const dataElement: any = {
        id: itemId,
        itemId: item.itemId,
        productId: product?.id,
        productName: product?.name,
        materialData: item.materialData,
        materials: product?.materials,
        initialQuantity: item.initialQuantity,
        precision: unitOfMeasurement?.precision,
        symbol: unitOfMeasurement?.symbol,
        initialQuantityWithDefaultUnit,
      };

      if (this.type === "input") {
        dataElement.selectedQuantity = processItem.selectedQuantity;
        if (selectedQuantityWithDefaultUnit) {
          dataElement.selectedQuantityWithDefaultUnit = selectedQuantityWithDefaultUnit;
        }
      }
      result.push(dataElement);
    }

    return result;
  };

  getParsedInboundData() {
    const result = [];

    for (const processItem of this.items) {
      const item = this.inboundItems.find((i) => i.id === CommonUtils.getUriId(processItem.item));
      const product = item?.product as any as IProductExtended;
      const defaultUnit = product?.defaultCustomUnit;
      const unitOfMeasurement = product?.unitOfMeasurement;
      let initialQuantityWithDefaultUnit: string;
      let selectedQuantityWithDefaultUnit: string;

      if (this.isRegularUser && defaultUnit) {
        const productDefaultUnit = defaultUnit;

        initialQuantityWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          item.initialQuantity,
          productDefaultUnit,
          unitOfMeasurement,
        );
        selectedQuantityWithDefaultUnit = CommonUtils.formatQuantityWithDefaultUnit(
          processItem.selectedQuantity,
          productDefaultUnit,
          unitOfMeasurement,
        );
      }
      const dataElement: any = {
        id: item?.id,
        itemId: item?.itemId,
        productId: product?.id,
        productName: product?.name,
        materialData: item?.materials,
        materials: product?.materials,
        initialQuantity: item?.initialQuantity,
        precision: unitOfMeasurement?.precision,
        symbol: unitOfMeasurement?.symbol,
        initialQuantityWithDefaultUnit,
      };

      if (this.type === "input") {
        dataElement.selectedQuantity = processItem.selectedQuantity;
        if (selectedQuantityWithDefaultUnit) {
          dataElement.selectedQuantityWithDefaultUnit = selectedQuantityWithDefaultUnit;
        }
      }
      result.push(dataElement);
    }

    return result;
  }

  private getAll = async (): Promise<void> => {
    this.isLoading.set(true);
    this.rowData = this.isInboundShared
      ? this.getParsedInboundData()
      : await this.getParsedRowData([...this.items]);
    this.isLoading.set(false);
  };
}
