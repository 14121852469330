import { ChangeDetectionStrategy, Component } from "@angular/core";

import { TextConstants } from "@shared/constants";
import { BulkAddItemsService } from "@components/items/bulk-add-items/bulk-add-items.service";
import { RecordStateEnum } from "@shared/enums";
import { AuthenticationService } from "@shared/services";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  templateUrl: "./items.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Items`,
    addNewLabel: TextConstants.ADD_NEW,
  };

  constructor(
    private routerService: RouterService,
    public authenticationService: AuthenticationService,
    private bulkAddItemsService: BulkAddItemsService,
  ) {}

  public onAdd = async (): Promise<void> => {
    await this.routerService.navigate(this.routerService.getItemLink());
  };

  public async onOpenBulkAddItemsSlideOver(): Promise<void> {
    await this.bulkAddItemsService.openSlideOver();
  }
}
