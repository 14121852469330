import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";

import { chunk } from "lodash";
import { ICustomField, IElementCustomField } from "src/app/shared/interfaces";

@Component({
  standalone: false,
  selector: "app-custom-fields-edit",
  templateUrl: "./custom-fields-edit.component.html",
  styleUrls: ["./custom-fields-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomFieldsEditComponent implements OnInit {
  @Input()
  public formGroup: FormGroup;

  @Input()
  public visibleCustomFields: ICustomField[];

  @Input()
  public elementCustomFields: IElementCustomField[];

  @Input()
  public columns: number = 1;

  @Input()
  public enableViewMode: boolean = false;

  public chunkVisibleCustomFields: any[] = [];

  public ngOnInit(): void {
    this.chunkVisibleCustomFields = chunk(this.visibleCustomFields, this.columns);
  }
}
