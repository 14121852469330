<h2 mat-dialog-title i18n>Add GPS data</h2>
<mat-dialog-content class="container-flex dialog-content">
  @if (shouldShowConversionPrompt()) {
    <ng-container>
      <div class="confirmation-message">
        <p i18n>
          The selected file contains a type of geometry that needs to be converted so it can be
          displayed properly on the map.
        </p>
        <p i18n>Would you like to proceed with the conversion?</p>
      </div>
    </ng-container>
  } @else {
    <ng-container>
      <div class="container-flex-center btn-container">
        <app-button (pressed)="onClose(true)" i18n>Enter coordinates manually</app-button>
      </div>
      <div class="divider">
        <span>or</span>
      </div>
      <app-upload-files-box
        [footerLeftText]="uploadBoxFooterLeftText"
        [footerRightText]="uploadBoxFooterRightText"
        [maxSizeInMb]="commonConstants.MAX_GEO_JSON_SIZE_IN_MB"
        [supportedFileTypes]="commonConstants.GEOJSON_UPLOAD_ALLOWED_FILE_EXTENSIONS"
        [validate]="true"
        [multiple]="false"
        (filesSelected)="onFileSelected($event[0])"
      ></app-upload-files-box>
    </ng-container>
  }
</mat-dialog-content>

<mat-dialog-actions>
  <app-button-group>
    @if (shouldShowConversionPrompt()) {
      <ng-container>
        <app-button (pressed)="onCancel()" type="ghost" i18n>Cancel</app-button>
        <app-button (pressed)="onConfirm()" i18n>Convert and proceed</app-button>
      </ng-container>
    } @else {
      <ng-container>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
      </ng-container>
    }
  </app-button-group>
</mat-dialog-actions>
