import { ChangeDetectionStrategy, Component } from "@angular/core";

import { RecordStateEnum } from "src/app/shared/enums";
import { AuthenticationService } from "src/app/shared/services";

import { TextConstants } from "@shared/constants";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  templateUrl: "./locations.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsComponent {
  public readonly recordStateEnum = RecordStateEnum;

  public readonly canAddModifyEntities = this.authenticationService.canAddModifyEntities();

  public readonly translations: any = {
    title: $localize`Locations`,
    titleHint: $localize`Access and manage all your and external locations. Edit, view details, and create new ones to streamline your operations.`,
    addNewLabel: TextConstants.ADD_NEW,
  };

  constructor(
    private router: RouterService,
    public authenticationService: AuthenticationService,
  ) {}

  public onAdd(): void {
    this.router.navigate(this.router.getLocationLink());
  }

  public async onOpenBulkAddLocationsSlideOver(): Promise<void> {
    await this.router.navigate(this.router.getBulkAddLocationsLink());
  }
}
