import { ChangeDetectionStrategy, Component, input, TemplateRef } from "@angular/core";

import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { RouterService } from "@shared/services/router.service";

@Component({
  standalone: false,
  selector: "app-slide-overlay-content-header",
  templateUrl: "./slide-overlay-content-header.component.html",
  styleUrls: ["./slide-overlay-content-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SlideOverlayContentHeaderComponent {
  title = input.required<string>();

  showSaveButton = input<boolean>(false);

  showEditButton = input<boolean>(false);

  controllersTemplate = input<TemplateRef<unknown>>();

  tag = input<string>();

  infoIconTooltip = input<string>();

  constructor(
    protected overlay: SlideOverlayPageService,
    private router: RouterService,
  ) {}

  get recordIsActive(): boolean {
    return this.overlay?.componentRef()?.instance?.isRecordActive;
  }

  get isSubmitButtonDisabled(): boolean {
    let isDisabled = true;

    if (this.overlay.componentRef()) {
      const instance = this.overlay.componentRef().instance;

      isDisabled = instance.isSubmitButtonDisabled;
    }

    return this.overlay.loading() || isDisabled;
  }

  async cancel(): Promise<void> {
    if (this.overlay.componentRef()) {
      const instance = this.overlay.componentRef().instance;

      if (instance.recordId) {
        await this.overlay.enableViewMode(false);

        return;
      }
    }
    const canExit = await this.overlay.canClose();

    if (canExit) {
      this.router.closeViewOutlet();
    }
  }
}
