import { FormGroup } from "@angular/forms";

import { BulkAddItemsModel } from "@components/items/bulk-add-items/bulk-add-items.component.model";
import { TextConstants } from "@shared/constants";

export namespace BulkAddItemsEnterRecordsModel {
  export const fixedFieldMapping = {
    [BulkAddItemsModel.FieldEnum.IS_FIXED_TAGS]: TextConstants.TAGS,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_PRODUCT]: TextConstants.PRODUCT,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_MATERIALS]: TextConstants.MATERIALS,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_INITIAL_QUANTITY]: $localize`Initial quantity`,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_UNIT_OF_MEASUREMENT]: $localize`Unit of measurement`,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CREATED_AT_LOCATION]: $localize`Created at location`,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CREATION_DATE]: TextConstants.CREATION_DATE,
    [BulkAddItemsModel.FieldEnum.IS_FIXED_CURRENT_LOCATION]: $localize`Current location`,
  };

  export interface IRowData {
    formGroup: FormGroup<BulkAddItemsModel.ItemFormGroup>;
  }

  export const duplicatedIdOnTableMessage: string = $localize`This ID is duplicated`;
  export const maxItemsCount: number = 100;
  export const editDialogMaxWidth: number = 350;
  export const editDialogHeight: number = 400;
  export const editDialogViewportMargin: number = 10;
}
