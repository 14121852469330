<div [formGroup]="formGroup" class="row-container">
  <div class="row">
    <div class="item">
      <app-input
        enableViewMode
        formControlName="street"
        [label]="translations.addressLabel"
      ></app-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <app-input
        enableViewMode
        formControlName="region"
        [label]="translations.regionLabel"
      ></app-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <app-input
        enableViewMode
        formControlName="zipCode"
        [label]="translations.zipCodeLabel"
      ></app-input>
    </div>
  </div>

  <div class="row">
    <div class="item">
      <app-input-select
        [options]="countryOptions"
        [viewModeTemplate]="countryViewMode"
        enableViewMode
        formControlName="country"
        [label]="translations.countryLabel"
        [prefixTemplate]="flag"
      ></app-input-select>
      <ng-template #flag let-option>
        <app-flag [country]="option.value"></app-flag>
      </ng-template>
      <ng-template #countryViewMode let-label="label" let-option>
        <div class="form-field-item">
          <app-form-label [label]="label"></app-form-label>
          <div class="form-field-input-readonly">
            <app-country [option]="option"></app-country>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
