import { ChangeDetectionStrategy, Component, inject, input, OnInit, signal } from "@angular/core";

import { DeliveryReportService } from "@components/reports/delivery-report/delivery-report.service";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { IBaseUnit, IDocumentType, IProcessExtended, IProductExtended } from "@shared/interfaces";
import { NavigationParams, RouterService } from "@shared/services/router.service";
import { CommonUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-delivery-report-process-info",
  templateUrl: "./delivery-report-process-info.component.html",
  styleUrls: ["./delivery-report-process-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryReportProcessInfoComponent implements OnInit {
  public process = input<IProcessExtended>();

  public allProducts = input<IProductExtended[]>([]);

  public allUnitOfMeasurements = input<IBaseUnit[]>([]);

  public locationTypes = signal<InputSelectOption[]>([]);

  public documentTypes: IDocumentType[] = [];

  public locationCountryName = signal<string>(null);

  private deliveryReportService: DeliveryReportService = inject(DeliveryReportService);

  private routerService: RouterService = inject(RouterService);

  public ngOnInit(): void {
    const locationTypes = DeliveryReportService.getLocationTypesForLocation(
      this.process().location,
    );

    const locationContryName = this.deliveryReportService
      .countryOptions()
      .find((country) => country.value === this.process().location.address.country).label;

    this.locationTypes.set(locationTypes);
    this.locationCountryName.set(locationContryName);
    this.documentTypes = CommonUtils.getDocumentTypesByDocuments(this.process().documents);
  }

  public getLocationLink(): NavigationParams {
    return this.routerService.getLocationLink(
      this.process().location.id,
      false,
    ) as NavigationParams;
  }

  public getProcessLink(): NavigationParams {
    return this.routerService.getProcessLink(this.process().id, false) as NavigationParams;
  }
}
