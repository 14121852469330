import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";

import { Subscription } from "rxjs";

import { BulkAddLocationsModel } from "@components/locations/bulk-add-locations/bulk-add-locations.component.model";
import { BulkAddLocationsService } from "@components/locations/bulk-add-locations/bulk-add-locations.service";
import { BulkAddCustomFieldEnum } from "@components/shared/bulk-add/bulk-add.interface";
import { InputChipsComponent } from "@components/shared/inputs/input-chips/input-chips.component";
import { TextConstants } from "@shared/constants";
import { EntityTypeEnum } from "@shared/enums";
import { ISelectOption, ITagExtended } from "@shared/interfaces";
import { NotificationService, LocationTypesService } from "@shared/services";
import { FormUtils } from "@shared/utils";

@Component({
  standalone: false,
  selector: "app-bulk-add-locations-set-values",
  templateUrl: "./bulk-add-locations-set-values.component.html",
  styleUrls: ["./bulk-add-locations-set-values.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkAddLocationsSetValuesComponent implements OnInit, OnDestroy {
  public bulkAddLocationsService: BulkAddLocationsService = inject(BulkAddLocationsService);

  public locationTypesService: LocationTypesService = inject(LocationTypesService);

  public readonly entityTypeEnum = EntityTypeEnum;

  public readonly fieldEnum = BulkAddLocationsModel.FieldEnum;

  public readonly customFieldEnum = BulkAddCustomFieldEnum;

  private subscriptions = new Subscription();

  private notificationsService: NotificationService = inject(NotificationService);

  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);

  public readonly translations: any = {
    locationTypesPh: $localize`Type new value and hit enter`,
    locationTypesLabel: $localize`Location type(s)`,
    locationTypesTooltip: TextConstants.LOCATION_TYPES_TOOLTIP,
    organisationLabel: TextConstants.ORGANISATION,
    organisationPh: $localize`Select organisation`,
    organisationTp: $localize`Add new organisation`,
    countryLabel: TextConstants.COUNTRY,
    countryPh: $localize`Select country`,
    fixed: $localize`Fixed`,
    tags: TextConstants.TAGS,
  };

  @Input() formGroup: FormGroup<BulkAddLocationsModel.SetValuesFormGroup>;

  @ViewChild("locationTypesInputChips") locationTypesInputChips: InputChipsComponent;

  public async ngOnInit(): Promise<void> {
    const { controls } = this.formGroup;
    const { fieldEnum } = this;

    const tagsSubscription = controls[fieldEnum.TAGS].valueChanges.subscribe((tags) => {
      if (tags.length) {
        controls[fieldEnum.IS_FIXED_TAGS].setValue(true, { emitEvent: false });
      }
    });

    this.subscriptions.add(tagsSubscription);

    this.subscriptions.add(
      this.getIsFixedFieldDependencySubscription(
        controls[fieldEnum.ORGANISATION],
        controls[fieldEnum.IS_FIXED_ORGANISATION],
      ),
    );

    this.subscriptions.add(
      this.getIsFixedFieldDependencySubscription(
        controls[fieldEnum.COUNTRY],
        controls[fieldEnum.IS_FIXED_COUNTRY],
      ),
    );

    this.subscriptions.add(
      this.getIsFixedFieldDependencySubscription(
        controls[fieldEnum.LOCATION_TYPES],
        controls[fieldEnum.IS_FIXED_LOCATION_TYPES],
      ),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onTagsChanged(tags: ITagExtended[]): void {
    this.formGroup.controls[this.fieldEnum.TAGS].setValue(tags);
  }

  public async onAddOrganisationClicked(): Promise<void> {
    await this.bulkAddLocationsService.openAddOrganisationDialog((option) => {
      this.cdr.detectChanges();
      this.formGroup.controls[this.fieldEnum.ORGANISATION].setValue(option);
      this.cdr.detectChanges();
    });
  }

  public async onClickNext(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);

      return false;
    }

    const unsavedTypes = this.formGroup
      .get(this.fieldEnum.LOCATION_TYPES)
      .value.filter((option) => !option.value);

    if (!unsavedTypes.length) {
      return true;
    }

    const promises: Promise<void>[] = [];

    for (let i = 0; i < unsavedTypes.length; i++) {
      const promise = this.locationTypesInputChips.createTag(unsavedTypes[i], true);

      promises.push(promise);
    }

    try {
      await Promise.all(promises);
      await this.bulkAddLocationsService.setLocationTypes();

      return true;
    } catch (error) {
      this.notificationsService.showError(error);

      return false;
    }
  }

  private getIsFixedFieldDependencySubscription(
    control: FormControl<ISelectOption | ISelectOption[]>,
    isFixedControl: FormControl<boolean>,
  ): Subscription {
    return control.valueChanges.subscribe((value) => {
      const hasValue = Array.isArray(value) ? value.length : !!value;

      if (hasValue && control.valid) {
        isFixedControl.enable({ emitEvent: false });
        isFixedControl.setValue(true, { emitEvent: false });
      } else {
        isFixedControl.setValue(false, { emitEvent: false });
        isFixedControl.disable({ emitEvent: false });
      }
    });
  }
}
