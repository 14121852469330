import { HttpErrorResponse } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  OnDestroy,
  OnInit,
  signal,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup } from "@angular/forms";

import { debounceTime, Subject } from "rxjs";

import {
  LocationOverlayGpsComponent,
  LocationOverlayLinksComponent,
  LocationOverlayMainInformationComponent,
} from "@components/locations";
import { LocationOverlayService } from "@components/locations/pages/location-overlay/location-overlay.service";
import {
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
} from "@components/shared";
import { AddGpsDataDialogComponent } from "@components/shared/add-gps-data-dialog/add-gps-data-dialog.component";
import { CardContentTypeEnum } from "@components/shared/cards/card-content/card-content.model";
import { CountriesApiService } from "@components/shared/countries/api";
import { InputSelectOption } from "@components/shared/inputs/input-select/input-select.model";
import { SlideOverlayContentComponent } from "@components/shared/overlay/slide-overlay-content/slide-overlay-content.component";
import { SlideOverlayPageClass } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.class";
import { RelatedRiskAssessmentReportsTableModel } from "@components/shared/risk-assessment-reports/models";
import { OverlayRiskAssessmentReportsComponent } from "@components/shared/risk-assessment-reports/ui";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { AnalysesTableService } from "@components/shared/tables/analyses-table/analyses-table.service";
import { CommonConstants, TextConstants } from "@shared/constants";
import {
  AttachmentTargetEnum,
  CustomFieldsResourceTypeEnum,
  EntityTypeEnum,
  FeatureFlagEnum,
  GeoJsonTypeEnum,
  OverlayTabEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RouteEnum,
} from "@shared/enums";
import {
  IAttachment,
  IAttachmentPayload,
  ILocationDetails,
  ILocationPayload,
  ILocationType,
  IOrganisation,
  IRecordResponse,
  IRecordState,
  ISelectOption,
} from "@shared/interfaces";
import {
  AttachmentsService,
  CommonService,
  ConnectionsService,
  FeatureFlagService,
  LocationsService,
  OrganisationsService,
  RulesetsService,
} from "@shared/services";
import { CommonUtils, CustomFieldsUtils, FormUtils, GeoJSONUtils } from "@shared/utils";
import { CustomValidators } from "@shared/validators";

@Component({
  standalone: false,
  selector: "app-location-overlay",
  templateUrl: "./location-overlay.component.html",
  styleUrls: ["./location-overlay.component.scss", "../location-overlay-common.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class LocationOverlayComponent extends SlideOverlayPageClass implements OnInit, OnDestroy {
  @ViewChild("informationView") informationView: LocationOverlayMainInformationComponent;

  @ViewChild("linkedLocationView") linkedLocationView: LocationOverlayLinksComponent;

  @ViewChild("slideOverlayContent") override slideOverlayContent: SlideOverlayContentComponent;

  @ViewChild("certificateView") certificateView: OverlayCertificateAttachmentsComponent;

  @ViewChild("documentView") documentView: OverlayDocumentAttachmentsComponent;

  @ViewChild("locationGpsView") locationGpsView: LocationOverlayGpsComponent;

  @ViewChild("riskAssessmentsView")
  readonly riskAssessmentsView: OverlayRiskAssessmentReportsComponent;

  readonly isCrossOrgSharingEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING,
  );

  readonly isCrossOrgSharingLocationEnabled = this.featureFlagService.isEnabled(
    FeatureFlagEnum.CROSS_ORGANISATION_SHARING_LOCATION,
  );

  formGroup: UntypedFormGroup;

  coordinatesformGroups: FormArray = this.fb.array([]);

  type: GeoJsonTypeEnum;

  private initialCoordinatesFormValue = null;

  override menuItems = signal(
    new Map([
      [OverlayTabEnum.DETAILS, { title: TextConstants.LOCATION_DETAILS, isEnabled: true }],
      [OverlayTabEnum.GPS_DATA, { title: TextConstants.GPS_DATA, isEnabled: false }],
      [
        OverlayTabEnum.LINKED_LOCATIONS,
        { title: TextConstants.LINKED_LOCATIONS, isEnabled: false, isHidden: !this.isRegularUser },
      ],
      [OverlayTabEnum.CERTIFICATES, { title: TextConstants.CERTIFICATES, isEnabled: false }],
      [OverlayTabEnum.DOCUMENTS, { title: TextConstants.DOCUMENTS, isEnabled: false }],
      [OverlayTabEnum.ANALYSES, { title: TextConstants.ANALYSES, isEnabled: false }],
      [
        OverlayTabEnum.SHARES,
        {
          title: TextConstants.SHARES,
          isEnabled: false,
          isHidden: !this.isRegularUser,
        },
      ],
      [
        OverlayTabEnum.RISK_ASSESSMENT_REPORTS,
        {
          title: TextConstants.RISK_ASSESSMENT_REPORTS,
          isEnabled: false,
          isHidden: !this.isRegularUser,
        },
      ],
      [
        OverlayTabEnum.COMMENTS,
        { title: TextConstants.COMMENTS, isEnabled: false, isHidden: !this.isRegularUser },
      ],
    ]),
  );

  override element: ILocationDetails = null;

  override entityType = EntityTypeEnum.LOCATIONS;

  override attachmentTargetType = AttachmentTargetEnum.LOCATION;

  override readonly deleteConfirmationContentText = $localize`Deleting a location will completely remove it from the system. Any attached documents and/or certificates will remain`;

  public isLoadingShares = signal(false);

  organisationOptions: ISelectOption[] = [];

  allOrganisations: IOrganisation[] = [];

  allLocationTypes: ILocationType[] = [];

  allCountries: ISelectOption[] = [];

  readonly attachmentTargetEnum = AttachmentTargetEnum;

  rulesetsRecords: IRecordResponse[];

  readonly resourceTypeEnum = ResourceTypeEnum;

  protected readonly cardContentTypeEnum = CardContentTypeEnum;

  readonly templateResourceType = RiskAssessmentTemplateResourceType.LOCATION;

  private organisationAttachment: IAttachment = null;

  private setGeoJsonWithCoordinatesPointSubject = new Subject();

  public geojsonFile = signal<any>(null);

  public showCoordinateForm = signal<boolean>(false);

  public shouldShowCoordinatesForm = computed(
    () => this.overlay.editMode() && this.showCoordinateForm(),
  );

  public canEditCoordinates = signal<boolean>(true);

  public hasCoordinatesFormChanged = false;

  relatedRiskAssessmentReportsTableParams =
    signal<RelatedRiskAssessmentReportsTableModel.Params>(null);

  public readonly translations: any = {
    missingTypesTp: TextConstants.MISSING_DOC_TYPES_TOOLTIP,
  };

  constructor(
    private locationsService: LocationsService,
    private attachmentsService: AttachmentsService,
    private connectionsService: ConnectionsService,
    private commonService: CommonService,
    private organisationsService: OrganisationsService,
    private rulesetsService: RulesetsService,
    public locationOverlay: LocationOverlayService,
    public analysesTableService: AnalysesTableService,
    private featureFlagService: FeatureFlagService,
    private countriesApiService: CountriesApiService,
    private fb: FormBuilder,
  ) {
    super();

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$.subscribe(
        (countriesOptions: ISelectOption[]) => {
          this.allCountries = countriesOptions;
        },
      ),
    );

    this.subscriptions.add(
      this.setGeoJsonWithCoordinatesPointSubject
        .pipe(debounceTime(CommonConstants.DEBOUNCE_SEARCH_TIME_MS))
        .subscribe(() => {
          this.setGeoJsonWithCoordinatesPoint();
        }),
    );
  }

  override get isSubmitButtonDisabled(): boolean {
    return (
      this.formGroup?.invalid ||
      this.formGroup?.pending ||
      !this.hasFormValuesChanged ||
      this.coordinatesformGroups?.invalid
    );
  }

  async ngOnInit(): Promise<void> {
    this.overlay.showLoading();
    if (!this.isOnCorrectOverlay(RouteEnum.OVERLAY_LOCATION)) {
      return;
    }

    await Promise.all([
      this.reloadOrganisations(),
      this.setAllCustomFields(CustomFieldsResourceTypeEnum.LOCATION),
      this.loadLocationTypes(),
    ]);

    this.setupForm();

    if (this.recordId) {
      this.setCountersToLoadingState();
      await this.reloadElement(this.recordId);
      await this.setMenuItemFromURLParam();
      this.loadCounters();
    }

    this.overlay.dismissLoading();
  }

  private async setRelatedRiskAssessmentReportTableParams(): Promise<void> {
    const countries = await this.countriesApiService.getAll({ code: this.element.address.country });

    if (!countries?.content?.length) {
      CommonUtils.getElementsWithCountryName(this.allCountries, [this.element]);
    }

    const country = countries.content[0];

    let organisationId: string;
    let organisationName = "-";

    if (this.organisationAttachment) {
      organisationId = CommonUtils.getUriId(this.organisationAttachment.targetUri);

      organisationName = this.organisationOptions.find((c) => c.value === organisationId).label;
    }

    this.relatedRiskAssessmentReportsTableParams.set({
      records: [
        {
          type: RelatedRiskAssessmentReportsTableModel.RecordType.COUNTRIES,
          id: country?.id,
          label: country?.name || this.element.address.countryName,
          uri: country?.id
            ? `/organisations/${this.activeOrganisationId}/countries/${country?.id}`
            : null,
        },
        {
          type: RelatedRiskAssessmentReportsTableModel.RecordType.ORGANISATIONS,
          id: organisationId,
          label: organisationName,
          uri: `/organisations/${this.activeOrganisationId}/connections/${organisationId}`,
        },
      ],
    });
  }

  override async save(): Promise<boolean> {
    if (this.formGroup.invalid) {
      FormUtils.findAndMarkInvalidControls(this.formGroup);
      this.notificationService.showError(TextConstants.FILL_REQUIRED_FIELDS);

      return false;
    }

    const payload = await this.getSavePayload();

    try {
      this.element = await this.locationsService.createOrUpdate(payload, this.element?.id);
      this.analysesTableService.setLocationId(this.element.id);
      this.analysesTableService.setHasGpsData(!!this.element.geoLocation);
      this.analysesTableService.setIsBatchActionsEnabled(false);
      this.analysesTableService.setBatchActionSettings();
      this.analysesTableService.setColumnDefs();
      this.hasFormValuesChanged = false;
      await this.updateOrganisationAttachment();
      this.notificationService.showSuccess(
        this.isEditing() ? $localize`Location modified` : $localize`Location created`,
      );

      return true;
    } catch (error) {
      this.notificationService.showError(error);

      return false;
    } finally {
      this.setupForm();
      this.showCoordinateForm.set(false);
      this.changeDetectorRef.detectChanges();
    }
  }

  override async afterSave(isSaveOnly?: boolean): Promise<void> {
    if (isSaveOnly && !this.isEditing()) {
      await this.routerService.navigate(this.routerService.getLocationLink(this.element.id), {
        replaceUrl: true,
      });
    }
    if (this.isEditing() && this.shouldUpdateTable) {
      this.loadDocuments();
    }
  }

  reloadOrganisations = async (autoSelectOrganisationId?: string): Promise<void> => {
    this.organisationOptions = [];

    await this.connectionsService
      .getAll()
      .then(async (response: IOrganisation[]) => {
        const selectedOrganisationId = this.organisationAttachment?.targetUri
          ? CommonUtils.getUriId(this.organisationAttachment.targetUri)
          : null;

        this.allOrganisations = response.filter(
          (o) =>
            o.recordState === RecordStateEnum.ACTIVE ||
            (selectedOrganisationId && o.id === selectedOrganisationId),
        );

        await this.setOrganisationOptions(
          this.allOrganisations.map(
            (c: IOrganisation) => <ISelectOption>{ label: c.name, value: c.id },
          ),
        );
        if (autoSelectOrganisationId) {
          this.selectOrganisation(autoSelectOrganisationId);
        }
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
  };

  async setRulesetRecords(): Promise<void> {
    if (!this.isRegularUser) {
      this.rulesetsRecords = [];

      return;
    }
    try {
      this.rulesetsRecords = await this.rulesetsService.getInstantRulesetRecords(
        `/organisations/${this.activeOrganisationId}/locations/${this.element.id}`,
      );
    } catch (error) {
      this.notificationService.showError(error);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.setCountersEmptyState();
  }

  loadCounters(): void {
    if (this.recordId) {
      this.loadLinkedLocations();
      this.loadDocuments();
      this.loadCertificates();
      this.loadAnalyses();
      this.loadShares(true);
      this.loadComments();

      if (this.isRegularUser) {
        this.locationOverlay.loadReportsCounter(this.entityUri);
      }
    }
  }

  setCountersToLoadingState() {
    this.locationOverlay.setCountersToLoadingState();
  }

  setCountersEmptyState() {
    this.locationOverlay.setCountersEmptyState();
  }

  override setupForm = (): void => {
    let countryValue = null;
    let organisationValue = null;
    const types: InputSelectOption[] = [];

    if (this.isEditing()) {
      if (this.element.address?.country) {
        countryValue = this.allCountries.find((c) => c.value === this.element?.address?.country);
      }
      if (this.organisationAttachment) {
        const organisationId = CommonUtils.getUriId(this.organisationAttachment.targetUri);

        organisationValue = this.organisationOptions.find((c) => c.value === organisationId);
      }

      for (const typeUri of this.element.types) {
        const typeId = CommonUtils.getUriId(typeUri);
        const type = this.allLocationTypes.find((t) => t.id === typeId);

        types.push({
          label: type.id,
          value: type.id,
          icon: type.pointOfOrigin ? "target" : undefined,
          iconTooltip: type.pointOfOrigin ? TextConstants.POINT_OF_ORIGIN_CHIP : undefined,
        });
      }
    }

    this.formGroup = this.formBuilder.group({
      organisation: [organisationValue ?? null, [CustomValidators.required]],
      name: [
        this.element?.name ?? null,
        [CustomValidators.required],
        [
          CustomValidators.entityAlreadyExists(
            this.locationsService,
            this.element?.id ?? this.route.snapshot.params["id"] ?? null,
          ),
        ],
      ],
      street: [this.element?.address?.street ?? null],
      sameAsOrganisationAddress: [false],
      region: [this.element?.address?.region ?? null],
      zipCode: [this.element?.address?.zipCode ?? null],
      country: [countryValue, [CustomValidators.required]],
      geoLocation: [this.element?.geoLocation ?? null],
      gpsX: [null, [CustomValidators.longitude]],
      gpsY: [null, [CustomValidators.latitude]],
      types: [types, [CustomValidators.required]],
    });
    this.visibleCustomFields = CustomFieldsUtils.getVisible(
      this.allCustomFields,
      this.element?.customFields,
    );
    CustomFieldsUtils.addToFormGroup(
      this.formGroup,
      this.visibleCustomFields,
      this.element?.customFields,
    );

    if (this.isEditing()) {
      const type = GeoJSONUtils.determineGeometryType(this.element.geoLocation);

      if (type === GeoJsonTypeEnum.POINT) {
        const gpsX = this.element.geoLocation.features[0].geometry.coordinates[0];
        const gpsY = this.element.geoLocation.features[0].geometry.coordinates[1];

        this.formGroup.controls["gpsX"].setValue(`${gpsX}`, {
          emitEvent: false,
        });
        this.formGroup.controls["gpsY"].setValue(`${gpsY}`, {
          emitEvent: false,
        });
        FormUtils.setControlsRequiredValidator(this.formGroup, ["gpsX", "gpsY"]);
        const newFormGroup = this.fb.group({
          rows: this.fb.array(
            [this.createCoordinateGroup(+gpsY, +gpsX)],
            [CustomValidators.polygonClosedValidator],
          ),
        });

        this.coordinatesformGroups.clear();
        this.coordinatesformGroups.push(newFormGroup);
      } else {
        this.processGeoJson(this.element?.geoLocation);
      }
    }
    //todo do not add subscriptions on setupForm (as this is called after saving..)
    this.subscriptions.add(
      this.formGroup.controls["gpsX"].valueChanges.subscribe(() => {
        this.setGeoJsonWithCoordinatesPointSubject.next(true);
      }),
    );
    this.subscriptions.add(
      this.formGroup.controls["gpsY"].valueChanges.subscribe(() => {
        this.setGeoJsonWithCoordinatesPointSubject.next(true);
      }),
    );

    this.initialFormValue = this.formGroup.value;
    this.initialCoordinatesFormValue = this.coordinatesformGroups.value;
    this.hasFormValuesChanged = false;
    this.subscriptions.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.hasFormValuesChanged = this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );

    this.subscriptions.add(
      this.coordinatesformGroups.valueChanges.subscribe(() => {
        this.hasCoordinatesFormChanged = this.hasInitialFormValueChanged(this.formGroup.value);
      }),
    );
  };

  confirmShareDialog() {
    this.locationOverlay
      .shareLocation(this.activeOrganisationId, this.element.id)
      .subscribe(async (result) => {
        if (result?.hasSaved) {
          await this.loadShares();
          this.changeDetectorRef.detectChanges();
          await this.changeMenuItem(OverlayTabEnum.SHARES);
        }
      });
  }

  confirmSendDialog() {
    this.locationOverlay.sendLocation(this.activeOrganisationId, this.element.id);
  }

  async loadShares(isFirstLoad = false): Promise<void> {
    if (!this.recordId || !this.isRegularUser) {
      return;
    }

    this.isLoadingShares.set(true);
    await this.locationOverlay.loadShares(this.element.id);
    this.isLoadingShares.set(false);

    if (
      isFirstLoad &&
      this.tabQueryParam &&
      CommonUtils.enumToText(this.tabQueryParam) === OverlayTabEnum.SHARES &&
      this.locationOverlay.shareCounter()
    ) {
      this.changeDetectorRef.detectChanges();
      await this.setMenuItemFromURLParam();
    }
  }

  loadAnalyses(): void {
    this.analysesTableService.getAll();
  }

  loadLinkedLocations(): void {
    this.locationOverlay.loadLinkedLocationCounter(this.element.id);
  }

  loadDocuments(): void {
    this.locationOverlay.loadDocumentCounter(this.element.id, this.attachmentTargetType);
  }

  loadCertificates(): void {
    this.locationOverlay.loadCertificateCounter(this.element.id, this.attachmentTargetType);
  }

  loadComments(): void {
    this.locationOverlay.loadCommentCounter(this.entityUri);
  }

  public async onShareDeleted(): Promise<void> {
    await this.loadShares();

    if (!this.locationOverlay.shares().length) {
      await this.slideOverlayContent.selectDefaultMenuItem();
    }
  }

  async loadLocationTypes(): Promise<void> {
    this.allLocationTypes = await this.locationOverlay.loadLocationTypes();
  }

  protected override async archiveRecord(id: string, payload: IRecordState): Promise<void> {
    await this.locationsService.setRecordState(payload, id);
  }

  protected override async deleteRecord(id: string): Promise<void> {
    await this.locationsService.delete(id);
  }

  protected override async reloadElement(id: string): Promise<void> {
    this.overlay.showLoading();

    await this.locationsService
      .get(id)
      .then(async (element: ILocationDetails) => {
        this.element = element;
        this.type = GeoJSONUtils.determineGeometryType(element.geoLocation);
        this.analysesTableService.setHasGpsData(!!element.geoLocation);
        this.analysesTableService.setLocationId(this.element.id);
        this.analysesTableService.setIsBatchActionsEnabled(false);
        this.analysesTableService.setBatchActionSettings();
        this.analysesTableService.setColumnDefs();
        this.setEditMode();
        this.organisationAttachment = await this.locationOverlay.getOrganisationAttachment(
          this.activeOrganisationId,
          this.element.id,
        );

        await this.reloadOrganisations();
        await this.setRelatedRiskAssessmentReportTableParams();
        this.setupForm();
        this.overlay.dismissLoading();
      })
      .catch((error) => {
        this.notificationService.showError(error);
      });
    if (this.isEditing() && this.isRegularUser) {
      await this.setRulesetRecords();
    }
  }

  private async getSavePayload(): Promise<ILocationPayload> {
    const unsavedTypes = this.formGroup.get("types").value.filter((item) => !item.value);

    if (unsavedTypes.length) {
      for (let i = 0; i < unsavedTypes.length; i++) {
        const item = unsavedTypes[i];

        await this.informationView.locationTypesInputChips.createTag(item, true);
      }
    }
    const payload = {
      id: this.element?.id ?? undefined,
      name: this.formGroup.controls["name"].value.trim(),
      types: this.formGroup
        .get("types")
        .value.map(
          (locationType: InputSelectOption) =>
            `/organisations/${this.activeOrganisationId}/location-types/${locationType.value}`,
        ),
      address: {
        street: this.formGroup.controls["street"].value,
        region: this.formGroup.controls["region"].value,
        zipCode: this.formGroup.controls["zipCode"].value,
        country: this.formGroup.controls["country"].value.value,
      },
      geoLocation: LocationOverlayService.transformGeoLocationForPayload(
        this.formGroup.controls["geoLocation"].value,
      ) as any,
    };

    CustomFieldsUtils.addToPayload(
      payload,
      this.activeOrganisationId,
      this.formGroup,
      this.visibleCustomFields,
    );

    return payload;
  }

  private async updateOrganisationAttachment(): Promise<void> {
    if (!this.organisationAttachment) {
      await this.createOrganisationAttachment();

      return;
    }

    const currentOrganisationId = CommonUtils.getUriId(this.organisationAttachment.targetUri);
    const newOrganisationId = this.formGroup.controls["organisation"].value.value;

    if (currentOrganisationId !== newOrganisationId) {
      await this.attachmentsService.delete(this.organisationAttachment.id);
      await this.createOrganisationAttachment();
    }
  }

  private isLoggedInUserOrganisation(): boolean {
    return this.formGroup.controls["organisation"].value.value === this.activeOrganisationId;
  }

  private createOrganisationAttachment = async (): Promise<void> => {
    const payload: IAttachmentPayload = {
      targetUri: this.isLoggedInUserOrganisation()
        ? `/organisations/${this.activeOrganisationId}`
        : `/organisations/${this.activeOrganisationId}/connections/${this.formGroup.controls["organisation"].value.value}`,
      attachmentUri: `/organisations/${this.activeOrganisationId}/locations/${this.element.id}`,
    };

    await this.attachmentsService
      .create(payload)
      .then(async (response: IAttachment) => {
        this.organisationAttachment = response;
      })
      .catch((error: HttpErrorResponse) => {
        this.notificationService.showError(error);
      });
  };

  private selectOrganisation = (organisationId: string): void => {
    const organisation = this.organisationOptions.find((o) => o.value === organisationId);

    if (organisation) {
      this.formGroup.controls["organisation"].setValue(organisation);
    }
  };

  private async setOrganisationOptions(
    organisationOptionsToAdd: ISelectOption[] = null,
  ): Promise<void> {
    this.organisationOptions = [];

    if (this.isRegularUser) {
      const loggedInUserOrganisation = await this.organisationsService.get(
        this.activeOrganisationId,
      );

      this.organisationOptions.push({
        label: loggedInUserOrganisation.name,
        value: loggedInUserOrganisation.id,
      });
    }
    if (organisationOptionsToAdd?.length) {
      this.organisationOptions.push(...organisationOptionsToAdd);
    }

    this.organisationOptions = [...this.organisationOptions]; // to trigger onchanges
  }

  private setGeoJsonWithCoordinatesPoint(): void {
    if (this.type !== GeoJsonTypeEnum.POINT) {
      return;
    }
    const gpsX = this.formGroup.controls["gpsX"].value
      ? this.formGroup.controls["gpsX"].value.trim()
      : null;
    const gpsY = this.formGroup.controls["gpsY"].value
      ? this.formGroup.controls["gpsY"].value.trim()
      : null;

    const areBothCoordinatesFilled = gpsX !== null && gpsY !== null;
    const isSomeCoordinateFilled = gpsX !== null || gpsY !== null;

    FormUtils.setControlsRequiredValidator(
      this.formGroup,
      ["gpsX", "gpsY"],
      isSomeCoordinateFilled,
      false,
    );

    if (!areBothCoordinatesFilled) {
      return;
    }

    if (GeoJSONUtils.isValidCoordinates(gpsY, gpsX)) {
      this.formGroup.controls["geoLocation"].setValue(
        GeoJSONUtils.getObject(GeoJsonTypeEnum.POINT, [+gpsX, +gpsY]),
        { emitEvent: false },
      );
      this.geojsonFile.set(this.formGroup.controls["geoLocation"].value);
    } else {
      this.formGroup.controls["geoLocation"].setValue(null, { emitEvent: false });
    }
  }

  public addGpsData(): void {
    const ref = this.dialog.open(AddGpsDataDialogComponent, {
      width: "700px",
    });

    ref
      .afterClosed()
      .subscribe((result: { enterCoordinatesManually: boolean; geojsonFile?: any }) => {
        if (!result.geojsonFile && !result.enterCoordinatesManually) {
          return;
        }
        this.coordinatesformGroups.clear();

        if (result.enterCoordinatesManually) {
          this.overlay.enableEditMode();
          this.showCoordinateForm.set(result.enterCoordinatesManually);
          this.type = GeoJsonTypeEnum.POINT;
          const newFormGroup = this.fb.group({
            rows: this.fb.array(
              [this.createCoordinateGroup()],
              [CustomValidators.polygonClosedValidator],
            ),
          });

          this.coordinatesformGroups.push(newFormGroup);

          return;
        }
        if (result.geojsonFile) {
          try {
            this.geojsonFile.set(result.geojsonFile);
            this.processGeoJson(this.geojsonFile());
            this.formGroup.controls["geoLocation"].setValue(result.geojsonFile);
          } catch (error) {
            this.notificationService.showError(error);
          }
          this.overlay.enableEditMode();
        }
      });
  }

  private processGeoJson(geojson: any): void {
    const geometryType = GeoJSONUtils.determineGeometryType(geojson);

    if (!geometryType) {
      return;
    }
    this.setCanEditCoordinates();

    if (!this.canEditCoordinates()) {
      this.formGroup.controls["geoLocation"].setValue(geojson);

      return;
    }

    this.type = geometryType;
    this.coordinatesformGroups?.clear();
    if (this.type === GeoJsonTypeEnum.MULTI_POLYGON && geojson.features.length === 1) {
      try {
        const featureCoordinates = geojson.features[0].geometry.coordinates as any[];

        featureCoordinates.forEach((fc) => {
          const coordinates = fc.flat(1);

          const featureFormGroup = this.fb.group({
            rows: this.fb.array(
              coordinates.map((coord) => this.createCoordinateGroup(+coord[1], +coord[0])),
              [CustomValidators.polygonClosedValidator],
            ),
          });

          this.coordinatesformGroups.push(featureFormGroup);
        });
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      geojson.features.forEach((feature: any) => {
        const coordinates = this.extractCoordinatesFromFeature(feature);

        const featureFormGroup = this.fb.group({
          rows: this.fb.array(
            coordinates.map((coord) => this.createCoordinateGroup(+coord[1], +coord[0])),
            [CustomValidators.polygonClosedValidator],
          ),
        });

        this.coordinatesformGroups.push(featureFormGroup);
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  private createCoordinateGroup(latitude: number = null, longitude: number = null): FormGroup {
    return this.fb.group({
      latitude: [latitude, [CustomValidators.latitude, CustomValidators.required]],
      longitude: [longitude, [CustomValidators.longitude, CustomValidators.required]],
    });
  }

  private extractCoordinatesFromFeature(feature: any): any[] {
    const geometry = feature.geometry;

    if (geometry.type === GeoJsonTypeEnum.POINT) {
      return [[geometry.coordinates[0], geometry.coordinates[1]]];
    }

    if (geometry.type === GeoJsonTypeEnum.MULTI_POINT) {
      return geometry.coordinates.map((coord) => [coord[0], coord[1]]);
    }

    if (geometry.type === GeoJsonTypeEnum.POLYGON) {
      return geometry.coordinates[0];
    }
    if (geometry.type === GeoJsonTypeEnum.MULTI_POLYGON) {
      return geometry.coordinates.map((polygon) => polygon);
    }

    return [];
  }

  public onRemoveMap(): void {
    this.formGroup.controls["gpsX"].setValue(null);
    this.formGroup.controls["gpsY"].setValue(null);
    this.formGroup.controls["gpsX"].clearValidators();
    this.formGroup.controls["gpsY"].clearValidators();
    this.formGroup.controls["gpsX"].updateValueAndValidity();
    this.formGroup.controls["gpsY"].updateValueAndValidity();
    this.formGroup.controls["geoLocation"].setValue(null);
    if (this.geojsonFile()) {
      this.geojsonFile.set(null);
      this.showCoordinateForm.set(false);
    }
  }

  override hasInitialFormValueChanged(currentFormValue: object): boolean {
    return (
      FormUtils.hasInitialFormValueChanged(this.initialFormValue, currentFormValue) ||
      FormUtils.hasInitialFormValueChanged(
        this.initialCoordinatesFormValue,
        this.coordinatesformGroups.value,
      )
    );
  }

  switchToEditMode() {
    this.geojsonFile.set(this.element.geoLocation);
    this.setCanEditCoordinates();
    this.overlay.enableEditMode();
  }

  async cancelEditing(): Promise<void> {
    const response = await this.overlay.enableViewMode();

    if (response) {
      this.showCoordinateForm.set(false);
      this.setCanEditCoordinates();
      this.changeDetectorRef.detectChanges();
    }
  }

  setCanEditCoordinates() {
    const geoLocationdata = this.geojsonFile() || this.formGroup.controls["geoLocation"].value;
    const features = geoLocationdata?.features || [];

    if (features.length === 0) {
      return null;
    }

    const geometryType = geoLocationdata.geometry?.type || features[0]?.geometry?.type;

    this.canEditCoordinates.set(geometryType !== GeoJsonTypeEnum.MULTI_POLYGON);
  }
}
