@if (downloadProgress === 100) {
  <div class="downloaded-container">
    <div class="title-container">
      <div>{{ document.name }}</div>
      <a [href]="documentUrl" [download]="document.name" [tp]="translations.downloadTp">
        <mat-icon>download</mat-icon>
      </a>
    </div>
    @switch (documentExtension) {
      @case (fileExtensionEnum.PDF) {
        <iframe [src]="documentUrl" frameBorder="0"></iframe>
      }
      @case (
        [fileExtensionEnum.PNG, fileExtensionEnum.JPG, fileExtensionEnum.JPEG].includes(
          documentExtension
        ) && documentExtension
      ) {
        <div class="text-center">
          <img [src]="documentUrl" [alt]="document.name" />
        </div>
      }
      @case (fileExtensionEnum.TXT) {
        <div class="scrollable-container text-center">
          {{ documentBodyAsText }}
        </div>
      }
      @case (
        [fileExtensionEnum.JSON, fileExtensionEnum.GEOJSON].includes(documentExtension) &&
        documentExtension
      ) {
        <div class="scrollable-container">
          <pre><code>{{ documentBodyAsText }}</code></pre>
        </div>
      }
      @default {
        <div class="text-center">
          {{ DOCUMENT_CANT_BE_DISPLAYED_TEXT }}
        </div>
      }
    }
  </div>
} @else {
  <div class="downloading-container">
    <div class="info">
      @if (errorMessage) {
        <div class="red">{{ errorMessage }}</div>
      } @else {
        <ng-container i18n>Opening</ng-container>
        {{ document ? document.name : "document" }}
        <div class="progress-container">
          <mat-progress-bar [value]="downloadProgress"></mat-progress-bar>
          {{ downloadProgress }}%
        </div>
      }
    </div>
  </div>
}
