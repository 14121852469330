<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add certificate</h2>

  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-form-hint customClass="info-hint no-margin" [hint]="documentsHint"></app-form-hint>

      <h4>{{ mainInformationText }}</h4>
      <div class="row-container">
        <div class="item">
          <app-input formControlName="number" [label]="translations.numberLabel"></app-input>
        </div>
        <div class="item">
          <app-input-select
            [options]="standardOptions()"
            formControlName="standard"
            [label]="translations.standardLabel"
          ></app-input-select>
        </div>
        <div class="item">
          <app-input-select
            [formGroup]="formGroup"
            [options]="standardTypeOptions()"
            [disabled]="!standardTypeOptions()?.length"
            formControlName="standardType"
            [label]="translations.standardTypeLabel"
          ></app-input-select>
        </div>
        <div class="item">
          <app-datepicker
            [maxDate]="
              formGroup.controls['validFromDate'].value ?? formGroup.controls['validToDate'].value
            "
            formControlName="issuanceDate"
            [label]="translations.issuanceDateLabel"
          ></app-datepicker>
        </div>
        <div class="item">
          <app-datepicker
            [maxDate]="formGroup.controls['validToDate'].value"
            [minDate]="formGroup.controls['issuanceDate'].value"
            formControlName="validFromDate"
            [label]="translations.validFromDateLabel"
          ></app-datepicker>
        </div>
        <div class="item">
          <app-datepicker
            [minDate]="
              formGroup.controls['validFromDate'].value ?? formGroup.controls['issuanceDate'].value
            "
            formControlName="validToDate"
            [label]="translations.validToDateLabel"
          ></app-datepicker>
        </div>

        <div class="item">
          <app-input
            formControlName="url"
            [label]="translations.urlLabel"
            [tooltip]="translations.urlTooltip"
            type="url"
          ></app-input>
        </div>
      </div>
      @if (visibleCustomFields?.length) {
        <app-custom-fields-edit
          [formGroup]="formGroup"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      }
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group class="bottom-buttons">
        <app-button (pressed)="onCancel()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit" [disabled]="isSubmitButtonDisabled()">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
