import { AfterViewInit, ChangeDetectionStrategy, Component, Input, signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { ColDef } from "ag-grid-community";
import { EditProcessTypeDialogComponent } from "src/app/components/settings";
import {
  BatchActionTypeEnum,
  ConfirmDialogResponseEnum,
  RecordStateEnum,
  TableEnum,
} from "src/app/shared/enums";
import { IProcessType } from "src/app/shared/interfaces";
import { ProcessTypesService } from "src/app/shared/services";
import { ColumnUtils } from "src/app/shared/utils";

import { QuickActionsMenuComponent } from "@shared/cell-renderers";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { NotificationService } from "@shared/services";

import { ConfirmDialogComponent, InfoDialogComponent } from "../..";

@Component({
  standalone: false,
  selector: "app-process-types-table",
  templateUrl: "./process-types-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessTypesTableComponent implements AfterViewInit {
  @Input()
  public processTypes: IProcessType[] = null;

  @Input()
  public areButtonsEnabled = true;

  @Input()
  public isSearchEnabled = true;

  @Input()
  public isRecordStateFilterEnabled = true;

  @Input()
  public isPaginatorEnabled = true;

  @Input()
  public isFixedBottomPaginator = false;

  @Input()
  public recordState: RecordStateEnum = RecordStateEnum.ALL;

  public readonly table = TableEnum.PROCESS_TYPES;

  @Input()
  public isSaveTableState = false;

  @Input()
  public isBatchActionsEnabled = false;

  public batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  public isLoading = signal(true);

  public rowData: IProcessType[] = [];

  public columnDefs = signal<ColDef[]>([]);

  constructor(
    private processTypesService: ProcessTypesService,
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) {}

  public async ngAfterViewInit() {
    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.processTypesService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.processTypesService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.processTypesService),
        ],
      ]),
    };
  };

  private setColumnDefs = (): void => {
    const columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        headerName: TextConstants.NAME,
        field: "name",
        ...ColumnUtils.quickActionsMenuColumnCommonValues,
        suppressSizeToFit: false,
        suppressAutoSize: false,
        cellRenderer: QuickActionsMenuComponent,
        cellRendererParams: {
          actions: [
            {
              click: this.onDelete,
              tooltip: TextConstants.DELETE,
              icon: "delete",
            },
            {
              click: this.onToggleArchiveStatus,
              tooltip: (row) =>
                row.recordState === RecordStateEnum.ACTIVE
                  ? TextConstants.ARCHIVE
                  : TextConstants.UNARCHIVE,
              icon: (row) => (row.recordState === RecordStateEnum.ACTIVE ? "archive" : "inventory"),
            },
            {
              click: this.onEdit,
              tooltip: TextConstants.EDIT,
              icon: "edit",
            },
          ],
        },
      },
    ];

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  };

  private onDelete = async (element: IProcessType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: TextConstants.DELETE_CONFIRMATION,
        contentText: $localize`Are you sure you want delete this process type?`,
        confirmButtonColor: "danger",
        confirmButtonText: TextConstants.DELETE,
        confirmButtonIcon: "delete",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          await this.processTypesService.delete(element.id);
          this.notificationService.showSuccess(TextConstants.RECORD_DELETED);
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public onEdit = async (element: IProcessType): Promise<void> => {
    if (!this.areButtonsEnabled) {
      return;
    }
    if (element.recordState === RecordStateEnum.ARCHIVED) {
      this.dialog.open(InfoDialogComponent, {
        data: {
          icon: "error",
          iconColor: "red",
          contentText: TextConstants.EDIT_ARCHIVED_RECORD_ERROR,
        },
      });

      return;
    }
    const dialogRef = this.dialog.open(EditProcessTypeDialogComponent, {
      data: {
        processType: element,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: { hasSaved: boolean }) => {
      if (result?.hasSaved) {
        await this.getAll();
      }
    });
  };

  public onToggleArchiveStatus = async (element: IProcessType): Promise<void> => {
    const isArchived = element.recordState === RecordStateEnum.ARCHIVED;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION
          : TextConstants.ARCHIVE_CONFIRMATION,
        contentText: isArchived
          ? TextConstants.UNARCHIVE_CONFIRMATION_TEXT
          : TextConstants.ARCHIVE_CONFIRMATION_TEXT,
        confirmButtonColor: isArchived ? undefined : "danger",
        confirmButtonText: isArchived ? TextConstants.UNARCHIVE : TextConstants.ARCHIVE,
        confirmButtonIcon: isArchived ? "inventory" : "archive",
      },
    });

    dialogRef.afterClosed().subscribe(async (result: ConfirmDialogResponseEnum) => {
      if (result === ConfirmDialogResponseEnum.CONFIRM) {
        try {
          this.isLoading.set(true);
          const wasArchived = element.recordState === RecordStateEnum.ARCHIVED;

          await this.processTypesService.setRecordState(
            {
              recordState: wasArchived ? RecordStateEnum.ACTIVE : RecordStateEnum.ARCHIVED,
            },
            element.id,
          );
          this.notificationService.showSuccess(wasArchived ? "Unarchived" : "Archived");
          await this.getAll();
        } catch (error) {
          this.notificationService.showError(error);
          this.isLoading.set(false);
        }
      }
    });
  };

  public getAll = async (): Promise<void> => {
    this.isLoading.set(true);

    if (this.processTypes) {
      this.rowData = this.processTypes;
      this.isLoading.set(false);
    } else {
      try {
        this.rowData = await this.processTypesService.getAll();
        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };
}
