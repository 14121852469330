<form (ngSubmit)="onSubmit()" [formGroup]="formGroup">
  <h2 mat-dialog-title i18n>Add document</h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input
        [autofocus]="!isLoading()"
        formControlName="name"
        [suffix]="'.' + fileExtension"
        [label]="translations.nameLabel"
      ></app-input>
      <app-input-select
        [options]="data?.documentTypesOptions"
        [disabled]="!data?.documentTypesOptions.length"
        formControlName="type"
        [label]="translations.typeLabel"
        [addOnWrite]="true"
      ></app-input-select>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="onClose()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
