import { Inject, Injectable } from "@angular/core";

import { lastValueFrom } from "rxjs";

import { APP_CONFIG } from "@shared/tokens";

import { ApiService } from "./api.service";
import { IConfig, IEudrCredentials } from "../../interfaces";
import { AuthenticationService } from "../authentication.service";

@Injectable({
  providedIn: "root",
})
export class EudrExtensionService {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    @Inject(APP_CONFIG) private environment: IConfig,
  ) {}

  public get = async (): Promise<IEudrCredentials> =>
    await lastValueFrom(this.apiService.get<IEudrCredentials>(`${this.getBaseUrl()}/credentials`));

  public createOrUpdate = async (payload: IEudrCredentials): Promise<IEudrCredentials> =>
    await lastValueFrom(
      this.apiService.post<IEudrCredentials>(`${this.getBaseUrl()}/credentials`, payload),
    );

  public ping = async (payload: IEudrCredentials): Promise<void> =>
    await lastValueFrom(this.apiService.post<void>(`${this.getBaseUrl()}/ping`, payload));

  public delete = async (): Promise<void> =>
    await lastValueFrom(this.apiService.delete<void>(`${this.getBaseUrl()}/credentials`));

  private getBaseUrl = (): string =>
    `${
      this.environment.baseUrl
    }organisations/${this.authenticationService.getActiveOrganisationId()}/extensions/eudr`;
}
