@if (visible) {
  <div [class]="class" class="buttons">
    <ng-content
      select="
        ng-container,
        app-button,
        app-dropdown-button,
        app-more-actions-buttons,
        app-upload-documents-buttons,
        mat-button-toggle-group"
    ></ng-content>
  </div>
}
