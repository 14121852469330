<div class="container-space-between">
  <app-page-title
    [title]="translations.title"
    [backText]="translations.titleBackText"
    [goBackRoute]="routingEnum.ADMIN"
  ></app-page-title>
  @if (userCanAddOrModifyRulesets) {
    <app-button-group>
      <app-button (pressed)="onAdd()">
        <mat-icon icon-left>add</mat-icon>
        <ng-container i18n>Add new</ng-container>
      </app-button>
    </app-button-group>
  }
</div>
<mat-tab-group
  animationDuration="0ms"
  mat-align-tabs="start"
  [selectedIndex]="selectedTabIndex"
  (selectedIndexChange)="selectedTabIndex = $event"
  [mat-stretch-tabs]="false"
>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Locations</ng-container>

      <app-indicator [value]="rulesetsLocationsTable.totalElements()"></app-indicator>
    </ng-template>
    <app-rulesets-table
      [resourceType]="resourceTypeEnum.LOCATION"
      #rulesetsLocationsTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
    ></app-rulesets-table>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <ng-container i18n>Deliveries</ng-container>

      <app-indicator [value]="rulesetsDeliveriesTable.totalElements()"></app-indicator>
    </ng-template>
    <app-rulesets-table
      [resourceType]="resourceTypeEnum.DELIVERY"
      #rulesetsDeliveriesTable
      [isFixedBottomPaginator]="true"
      [isBatchActionsEnabled]="true"
    ></app-rulesets-table>
  </mat-tab>
</mat-tab-group>
