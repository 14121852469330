<form [formGroup]="form" (ngSubmit)="submit()">
  <h2 mat-dialog-title>
    @if (isEditing()) {
      <ng-container i18n>Edit risk level set</ng-container>
    } @else {
      <ng-container i18n>Add risk level set</ng-container>
    }
  </h2>
  @if (isLoading()) {
    <app-loader containerClass="on-dialog"></app-loader>
  } @else {
    <mat-dialog-content>
      <app-input [label]="translations.nameLabel" formControlName="name" />

      <div class="container-flex">
        <h4 i18n>Levels</h4>
        <app-button
          class="add-btn"
          type="icon"
          size="medium"
          [disabled]="isAddButtonDisabled"
          [tp]="
            isAddButtonDisabled
              ? 'A maximum of ' + maxAmountOfRiskLevels + ' risk levels can be added'
              : 'Add new risk level'
          "
          [tpIsLazy]="true"
          (pressed)="add()"
        >
          <mat-icon icon-medium-18>add</mat-icon>
        </app-button>
      </div>

      @for (group of riskLevels.controls; track group; let index = $index) {
        <div [formGroup]="group" class="levels-container">
          <app-input formControlName="title" [placeholder]="translations.titlePh" #titleField />

          <app-input-select
            formControlName="color"
            [placeholder]="translations.colorPh"
            [options]="colorOptions"
            [prefixTemplate]="colorBox"
          ></app-input-select>

          <app-button
            class="delete-btn"
            type="icon"
            size="medium"
            [tp]="
              index < minAmountOfRiskLevels
                ? 'Risk level set requires a minimum of ' + minAmountOfRiskLevels + ' levels.'
                : 'Remove'
            "
            [disabled]="index < minAmountOfRiskLevels"
            [tpIsLazy]="true"
            (pressed)="remove(index)"
          >
            <mat-icon icon-medium-18>delete</mat-icon>
          </app-button>
        </div>
      }
      <ng-template #colorBox let-option>
        <div [class]="option.value" class="color-box"></div>
      </ng-template>
    </mat-dialog-content>

    <mat-dialog-actions>
      <app-button-group>
        <app-button (pressed)="close()" type="ghost" i18n>Cancel</app-button>
        <app-button accessibilityRole="submit">
          <mat-icon icon-left>check</mat-icon>
          <ng-container i18n>Save</ng-container>
        </app-button>
      </app-button-group>
    </mat-dialog-actions>
  }
</form>
