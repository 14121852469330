import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  input,
  signal,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

import { ColDef, IRowNode } from "ag-grid-community";
import { Subscription } from "rxjs";
import {
  LinkCellRendererComponent,
  RulesetIconNameCellRendererComponent,
} from "src/app/shared/cell-renderers";
import {
  BatchActionTypeEnum,
  EntityTypeEnum,
  RecordStateEnum,
  ResourceTypeEnum,
  RouteEnum,
  TableEnum,
} from "src/app/shared/enums";
import { ILocation, ILocationExtended, ISelectOption } from "src/app/shared/interfaces";
import { CommonService, LocationsService, TableWithRulesetsService } from "src/app/shared/services";
import { CellRendererUtils, ColumnUtils, CommonUtils } from "src/app/shared/utils";

import { BulkAddLocationsService } from "@components/locations/bulk-add-locations/bulk-add-locations.service";
import { SlideOverlayPageService } from "@components/shared/overlay/slide-overlay-page/slide-overlay-page.service";
import { TableWithRiskAssessmentReportsService } from "@components/shared/risk-assessment-reports/services";
import { RiskAssessmentTemplateResourceType } from "@components/shared/risk-assessment-templates/constants";
import { TextConstants } from "@shared/constants";
import { BatchActionModel } from "@shared/interfaces/batch-action-record.interface";
import { RouterService } from "@shared/services/router.service";

import { TableBaseClass } from "../table/models";

@Component({
  standalone: false,
  selector: "app-locations-table",
  templateUrl: "./locations-table.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationsTableComponent
  extends TableBaseClass<ILocationExtended>
  implements AfterViewInit, OnDestroy
{
  locations = input<ILocation[] | ILocationExtended[]>(null);

  locationsIds = input<string[]>(null);

  areButtonsEnabled = input<boolean>(true);

  isSearchEnabled = input<boolean>(true);

  isRecordStateFilterEnabled = input<boolean>(true);

  isPaginatorEnabled = input<boolean>(true);

  isFixedBottomPaginator = input<boolean>(false);

  recordState = input<RecordStateEnum>(RecordStateEnum.ALL);

  table = input<TableEnum>(TableEnum.LOCATIONS);

  subscribeToOverlayRefreshTable = input<boolean>(true);

  isSaveTableState = input<boolean>(false);

  columns = input<string[]>([
    "recordState",
    "name",
    "organisationName",
    "types",
    "address.countryName",
    "tags",
    "riskAssessmentReport.completedDate",
    "riskAssessmentReport.residualRisk",
  ]);

  isBatchActionsEnabled = input(false);

  batchActionSettings: BatchActionModel.IBatchActionSettings = undefined;

  private readonly shouldGetRulesets = computed(
    () => this.columns().includes("nameWithRulesets") && this.isRegularUser,
  );

  private countryOptions = signal<ISelectOption[]>([]);

  private subscriptions = new Subscription();

  constructor(
    private routerService: RouterService,
    private locationsService: LocationsService,
    private commonService: CommonService,
    private overlay: SlideOverlayPageService,
    private bulkAddLocationsService: BulkAddLocationsService,
    private tableWithRulesets: TableWithRulesetsService,
    private tableWithReports: TableWithRiskAssessmentReportsService,
  ) {
    super();

    this.tableWithRulesets.init(ResourceTypeEnum.LOCATION);

    if (this.isRegularUser) {
      this.tableWithReports.init(RiskAssessmentTemplateResourceType.LOCATION);
    }

    this.subscriptions.add(
      this.commonService.countriesOptionsObservable$
        .pipe(takeUntilDestroyed())
        .subscribe((countriesOptions: ISelectOption[]) =>
          this.countryOptions.set(countriesOptions),
        ),
    );

    this.subscriptions.add(
      this.bulkAddLocationsService.refreshTable$.subscribe(() => {
        this.getAll();
      }),
    );
  }

  public async ngAfterViewInit(): Promise<void> {
    if (this.subscribeToOverlayRefreshTable()) {
      this.subscriptions.add(
        this.overlay.refreshTable$.subscribe(async ({ entityType, element }) => {
          if (
            entityType === EntityTypeEnum.LOCATIONS ||
            entityType === EntityTypeEnum.RISK_ASSESSMENT_REPORTS
          ) {
            await this.getAll(true);
            if (this.rowData().some((d) => d.id === element.id)) {
              this.tableWithRulesets.newRecord.set(element);
            }
          }
        }),
      );
    }

    this.setBatchActionSettings();
    this.setColumnDefs();
    await this.getAll();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setBatchActionSettings = (): void => {
    if (!this.isBatchActionsEnabled) {
      return;
    }
    this.batchActionSettings = {
      actions: new Map([
        [
          BatchActionTypeEnum.ARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.ARCHIVE, this.locationsService),
        ],
        [
          BatchActionTypeEnum.UNARCHIVE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.UNARCHIVE, this.locationsService),
        ],
        [
          BatchActionTypeEnum.DELETE,
          BatchActionModel.getBatchAction(BatchActionTypeEnum.DELETE, this.locationsService),
        ],
      ]),
    };
  };

  override setColumnDefs(): void {
    let columnDefs: ColDef[] = [
      ColumnUtils.recordState(),
      {
        colId: "name",
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRoute: this.isSystemAdminOrganisation ? RouteEnum.ADMIN_LOCATIONS_DETAILS : undefined,
          linkRouteIdParam: "id",
          linkRouteFn: this.isSystemAdminOrganisation
            ? undefined
            : this.routerService.getLocationLink,
        },
        suppressSizeToFit: true,
      },
      {
        colId: "nameWithRulesets",
        headerName: TextConstants.NAME,
        field: "name",
        lockVisible: true,
        cellClass: "container-flex-left",
        cellRenderer: RulesetIconNameCellRendererComponent,
        cellRendererParams: {
          rulesetRecordsSubject: this.tableWithRulesets.recordsSubject,
          iconTooltip: this.tableWithRulesets.getIconTooltip(),
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getLocationLink,
        },
        suppressSizeToFit: true,
      },
      {
        headerName: TextConstants.ORGANISATION,
        field: "organisationName",
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "organisationId",
          linkRouteFn: this.routerService.getOrganisationLink,
        },
      },
      ColumnUtils.chips("Type(s)", "types", {
        textParam: "type",
        showIcon: (locationType) => locationType.pointOfOrigin,
        icon: "target",
        iconTooltip: TextConstants.POINT_OF_ORIGIN_CHIP,
      }),
      {
        headerName: $localize`Certificates`,
        field: "certificates",
        valueGetter: (cell: { data: ILocationExtended }) => {
          return cell.data.certificates.map((certificate) => {
            const values: string[] = [
              certificate.standard.name,
              certificate.standardType?.fullName,
              certificate.number,
            ];

            const name = values.filter((value) => !!value).join(" - ");

            return { name, id: certificate.id };
          });
        },
        cellRenderer: LinkCellRendererComponent,
        cellRendererParams: {
          linkRouteIdParam: "id",
          linkRouteFn: this.routerService.getCertificateLink,
          textParam: "name",
        },
      },
      {
        headerName: TextConstants.COUNTRY,
        field: "address.countryName",
        cellRenderer: CellRendererUtils.country,
      },
      {
        headerName: $localize`ZIP / Post code`,
        field: "address.zipCode",
        valueFormatter: ColumnUtils.defaultValueFormatter(),
      },
      {
        headerName: $localize`Region / State`,
        field: "address.region",
      },
      { headerName: $localize`Link type`, field: "linkType" },
      {
        headerName: $localize`Geolocation`,
        field: "geoLocation",
        cellRenderer: CellRendererUtils.showIconIfValue,
        valueGetter: (cell: any) => !!cell.data?.geoLocation,
      },
    ];

    if (this.isRegularUser && !this.isSystemAdminOrganisation) {
      columnDefs.push(
        ColumnUtils.tags(),
        this.tableWithReports.lastRiskAssessedColDef,
        this.tableWithReports.residualRiskLevelColDef,
      );
    }

    columnDefs = CommonUtils.getVisibleColumnDefs(columnDefs, this.columns());

    if (this.batchActionSettings) {
      columnDefs.unshift(ColumnUtils.selectCheckbox());
    }

    this.columnDefs.set(columnDefs);
  }

  override getAll = async (force?: boolean): Promise<void> => {
    this.isLoading.set(true);

    let locations = this.locations();
    const locationsIds = this.locationsIds();
    const columnDefs = this.columnDefs();

    const tagsFieldPresent = columnDefs.some((c) => c.field === "tags");
    const geolocationFieldPresent = columnDefs.some((c) => c.field === "geoLocation");
    const fieldsToInclude = [];

    if (tagsFieldPresent) {
      fieldsToInclude.push("TAGS");
    }
    if (geolocationFieldPresent) {
      fieldsToInclude.push("GEOLOCATION");
    }

    if (locations && !force) {
      this.rowData.set(await this.getParsedRowData(locations));

      this.isLoading.set(false);
    } else if (locationsIds) {
      try {
        locations = await this.locationsService.getByIdsGraphQL(
          locationsIds,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(await this.getParsedRowData(locations));

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    } else {
      try {
        const locations = await this.locationsService.getAllGraphQL(
          undefined,
          undefined,
          fieldsToInclude,
        );

        this.rowData.set(await this.getParsedRowData(locations));

        this.isLoading.set(false);
      } catch (error) {
        this.notificationService.showError(error);
      }
    }
  };

  onRowsVisibleUpdated(rows: IRowNode<ILocationExtended>[]) {
    if (this.shouldGetRulesets()) {
      this.tableWithRulesets.onRowsVisibleUpdated(rows);
    }
  }

  onRowDataUpdated(rows: IRowNode<ILocationExtended>[]) {
    if (this.isRegularUser) {
      this.tableWithReports.updateRowData(rows, this.tableElement());
    }
  }

  public onViewDetails = async (row: ILocationExtended): Promise<void> => {
    if (!this.areButtonsEnabled()) {
      return;
    }
    if (this.isSystemAdminOrganisation) {
      this.routerService.navigate([`${RouteEnum.ADMIN_LOCATIONS_DETAILS}/${row.id}`]);
    } else {
      this.routerService.navigate(this.routerService.getLocationLink(row.id));
    }
  };

  private getParsedRowData = async (rowData: any[]) => {
    rowData = CommonUtils.getElementsWithCountryName(this.countryOptions(), rowData);

    return rowData;
  };
}
