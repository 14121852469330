<app-slide-overlay-header
  [canArchive]="true"
  [enableTags]="true"
  [canDelete]="true"
  [title]="title()"
  icon="deployed_code"
></app-slide-overlay-header>
<app-slide-overlay-content
  #slideOverlayContent
  [contentClass]="overlay.contentRightSidePanel() ? 'has-right-side-panel' : ''"
>
  <ng-template
    appSlideOverlayMenuItem
    overlay-menu-icon="info"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DETAILS).title"
  >
    <app-form-field-group [name]="mainInformationText">
      <div [formGroup]="formGroup" class="row-container">
        <div class="item">
          <app-input
            enableViewMode
            formControlName="name"
            [label]="translations.nameLabel"
          ></app-input>
        </div>
        <div class="item">
          <app-input-select
            enableViewMode
            [label]="translations.baseUnitLabel"
            formControlName="baseUnit"
            emitRawValue
            [options]="unitOfMeasurementTypesOptions()"
          ></app-input-select>
        </div>
      </div>
    </app-form-field-group>
    @if (visibleCustomFields?.length) {
      <app-form-field-group [name]="additionalInformationText">
        <app-custom-fields-edit
          [elementCustomFields]="element?.customFields"
          [formGroup]="formGroup"
          [enableViewMode]="true"
          [visibleCustomFields]="visibleCustomFields"
        ></app-custom-fields-edit>
      </app-form-field-group>
    }
    <app-form-field-group
      [name]="translations.unitsLabel"
      class="pagination-fix display-block"
      groupNameRowClass="group-name-row"
    >
      @if (overlay.editMode()) {
        <app-button
          (pressed)="overlay.openSideMenu(unitsOfMeasurementRightSidePanel)"
          [tp]="translations.unitsOfMeasurementTp"
          type="icon"
          group-name-content
        >
          <mat-icon icon-small>add</mat-icon>
        </app-button>
      }

      <app-table
        [isLoading]="isLoadingUnitsOfMeasurement()"
        [columnDefs]="unitsOfMeasurementColDefs"
        [isPaginatorEnabled]="false"
        [isSearchEnabled]="false"
        [isRecordStateFilterEnabled]="false"
        [rowData]="selectedUnitsOfMeasurement"
      ></app-table>
    </app-form-field-group>
    <app-form-field-group
      [name]="
        isOldMaterialsEnabled ? translations.allowedMaterialsLabel : translations.materialsLabel
      "
      class="pagination-fix display-block"
      groupNameRowClass="group-name-row"
    >
      @if (overlay.editMode()) {
        <app-button
          (pressed)="overlay.openSideMenu(materialRightSidePanel)"
          [tp]="translations.addMaterialsTp"
          type="icon"
          group-name-content
        >
          <mat-icon icon-small>add</mat-icon>
        </app-button>
      }

      @if (isLoadingSelectedMaterials()) {
        <app-loader></app-loader>
      } @else {
        <app-materials-table
          (removeClicked)="onRemoveAllowedMaterial($event)"
          [materials]="selectedMaterials()"
          [columns]="['recordState', 'nameWithRemove', 'category']"
          [isPaginatorEnabled]="false"
          [isSearchEnabled]="false"
          [isRecordStateFilterEnabled]="false"
        ></app-materials-table>
      }
    </app-form-field-group>
    @if (element && isRegularUser && overlay.viewMode()) {
      <app-form-field-group [name]="recordDetailsText">
        <app-record-details [element]="element"></app-record-details>
      </app-form-field-group>
    }
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="documentController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.DOCUMENTS).isEnabled"
    [overlay-menu-indicator]="productOverlay.documentCounter()"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.DOCUMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="docs"
  >
    <app-overlay-document-attachments
      #documentSection
      [attachmentTargetId]="element?.id"
      [attachmentTargetType]="attachmentTargetType"
      [isReadOnly]="isRecordArchived"
      [overlayService]="productOverlay"
    ></app-overlay-document-attachments>
  </ng-template>
  <ng-template
    [overlay-menu-buttons-template]="relatedProducsController"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RELATED_PRODUCTS).isEnabled"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.RELATED_PRODUCTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="deployed_code"
  >
    @if (overlay.editMode()) {
      <app-related-products
        #relatedProductsSection
        (relatedProductsChanged)="reloadSupplyChain(element.id)"
        [productId]="element.id"
      ></app-related-products>
    } @else {
      @if (relatedProductsLength) {
        <app-product-supply-chain
          [height]="supplyChainHeight"
          [productId]="element.id"
          [activeOrganisationId]="activeOrganisationId"
        ></app-product-supply-chain>
      } @else {
        <app-empty-search-results></app-empty-search-results>
      }
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.SUPPLY_CHAIN).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.SUPPLY_CHAIN).isEnabled"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.SUPPLY_CHAIN).title"
    appSlideOverlayMenuItem
    overlay-menu-svg-icon="supply-chain"
  >
    <app-product-supply-chain
      [height]="supplyChainHeight"
      [shouldIncludeLocationsLinks]="true"
      [shouldHandleProductReferences]="false"
      [shouldIncludeDocuments]="true"
      [productId]="element.id"
      [activeOrganisationId]="activeOrganisationId"
    ></app-product-supply-chain>
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isHidden"
    appSlideOverlayMenuItem
    overlay-menu-icon="select_check_box"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).title"
    [overlay-menu-buttons-template]="riskAssessmentController"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-indicator]="productOverlay.reportsCounter()"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.RISK_ASSESSMENT_REPORTS).isEnabled"
  >
    <app-overlay-risk-assessment-reports
      #riskAssessmentsView
      [activeOrganisationId]="activeOrganisationId"
      [recordUri]="entityUri"
      [recordName]="element.name"
      [resourceType]="templateResourceType"
      [overlayService]="productOverlay"
      [relatedRiskAssessmentReportTableParams]="relatedRiskAssessmentReportsService.tableParams()"
    />
  </ng-template>
  <ng-template #riskAssessmentController>
    @if (isRecordActive && canAddModifyEntities) {
      <app-button
        type="ghost"
        [disabled]="overlay.loading()"
        (pressed)="riskAssessmentsView.openDialog()"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template
    *ngIf="!menuItems().get(overlayTabEnum.COMMENTS).isHidden"
    [overlay-menu-disabled]="!menuItems().get(overlayTabEnum.COMMENTS).isEnabled"
    [overlay-menu-indicator]="productOverlay.commentCounter()"
    [overlay-menu-show-edit-button]="false"
    [overlay-menu-show-save-button]="false"
    [overlay-menu-title]="menuItems().get(overlayTabEnum.COMMENTS).title"
    appSlideOverlayMenuItem
    overlay-menu-icon="chat_bubble"
  >
    <article class="field-group-container">
      <app-comments
        (added)="onCommentAdded(productOverlay)"
        (deleted)="onCommentDeleted(productOverlay)"
        [entityId]="element?.id"
        [entityType]="entityTypeEnum.PRODUCTS"
      ></app-comments>
    </article>
  </ng-template>
  <ng-template #documentController>
    @if (!overlay.contentRightSidePanel() && isRecordActive && canAddModifyEntities) {
      <app-button
        (pressed)="overlay.openSideMenu(documentSection?.attachmentsRightMenu)"
        [disabled]="overlay.loading()"
        type="ghost"
      >
        <mat-icon class="action-icon" icon-left>add</mat-icon>
        <ng-container i18n>Add</ng-container>
      </app-button>
    }
  </ng-template>
  <ng-template #materialRightSidePanel>
    <app-right-side-panel
      (pressAdd)="onAddNewMaterial()"
      (pressAddAll)="onAddAllMaterials()"
      (pressRefresh)="onReloadMaterials()"
      (searchEvent)="onSearchAvailable($event)"
      [availableItems]="availableMaterials()"
      [isLoadingItems]="isLoadingAvailableMaterials()"
      class="overlay-right-panel"
      itemType="Material"
      itemTypePlural="Materials"
    >
      <div class="items-container">
        @for (item of availableMaterials(); track item.id) {
          <app-draggable-card (pressed)="onAddMaterial(item.id)" [draggable]="false" [item]="item">
            <app-material-card-content [material]="item" />
          </app-draggable-card>
        }
      </div>
    </app-right-side-panel>
  </ng-template>
  <ng-template #relatedProducsController>
    @if (isRecordActive && canAddModifyEntities) {
      @if (overlay.editMode()) {
        <app-button
          type="ghost"
          (pressed)="overlay.disableEditMode(); overlay.closeSideMenu()"
          [disabled]="overlay.loading()"
          i18n
        >
          Done
        </app-button>
      } @else {
        <app-button
          type="ghost"
          (pressed)="overlay.enableEditMode()"
          [disabled]="overlay.loading()"
          i18n
        >
          Edit
        </app-button>
      }
    }
  </ng-template>
  <ng-template #unitsOfMeasurementRightSidePanel>
    <app-right-side-panel
      class="overlay-right-panel"
      (pressAdd)="onAddNewUnitOfMeasurement()"
      (pressAddAll)="onAddAllUnitsOfMeasurement()"
      (pressRefresh)="reloadUnitsOfMeasurement()"
      (searchEvent)="onSearchAvailable($event)"
      [availableItems]="availableUnitsOfMeasurement"
      [isLoadingItems]="isLoadingUnitsOfMeasurement()"
      [noItemsFoundText]="translations.noItemsFound"
      itemType="Unit of measurement"
      itemTypePlural="Units of measurement"
    >
      <div class="items-container">
        @for (unit of availableUnitsOfMeasurement; track unit.id) {
          <app-draggable-card
            (pressed)="onAddUnitOfMeasurement(unit.id)"
            [draggable]="false"
            [item]="unit"
          >
            <app-unit-of-measurement-card [unitOfMeasurement]="unit"></app-unit-of-measurement-card>
          </app-draggable-card>
        }
      </div>
    </app-right-side-panel>
  </ng-template>
</app-slide-overlay-content>
